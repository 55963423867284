import React from "react";
import styles from "../Manegecourses.module.css";
import { TextField } from "@mui/material";
import toggleRadio from "../../../../../../assets/images/toggle-radio.svg";
import toggleRadioSelected from "../../../../../../assets/images/toggle-radio-selected.svg";
import SwitchInput from "../../../../../../components/Switch/SwitchInput";

function Regular(props: any) {
  const {
    regularPrice,
    setRegularPrice,
    setRegularPriceError,
    setEnableInstallment,
    regularPriceError,
    enableInstallment,
  } = props;

  const handleTotalPrice = (value: string) => {
    setRegularPrice(value);
    if (value === "") {
      setRegularPriceError(true);
    } else {
      setRegularPriceError(false);
    }
  };

  const handleEnableInstallment = () => {
    setEnableInstallment(!enableInstallment);
  };
  return (
    <div style={{ display: "flex", gap: "20px", alignItems: "flex-end" }}>
      <div className={styles.regularTotalPriceWrap}>
        <div className={styles.regularTotalPriceLabel}>Total Price</div>
        <div>
          <TextField
            id="totalPrice"
            variant="outlined"
            type="number"
            inputProps={{
              sx: {
                width: "240px",
                "&::placeholder": {
                  fontWeight: "400",
                  fontSize: "14px",
                },

                "&.MuiOutlinedInput-input": {
                  padding: "8px",
                },
              },
            }}
            value={regularPrice}
            onChange={(event) => handleTotalPrice(event.target.value)}
            error={regularPriceError}
            helperText={regularPriceError ? "Please fill valid amount" : ""}
          />
        </div>
      </div>
      <div style={{ marginBottom: regularPriceError ? "22PX" : "0px" }}>
        {/* <button
          className={styles.emiEnableBtn}
          style={{
            border: enableInstallment
              ? "1px solid rgba(17, 200, 155, 0.50)"
              : "none",
            background: "rgba(243, 243, 248, 0.44)",
          }}
          onClick={() => {
            handleEnableInstallment();
          }}
        >
          <img
            src={enableInstallment ? toggleRadioSelected : toggleRadio}
            alt="toggle"
            style={{ marginRight: "8PX" }}
          />
          enable
        </button> */}
        <div
          className={styles.regularTotalPriceLabel}
          style={{ marginBottom: "0px" }}
        >
          Installment
        </div>
        <SwitchInput
          label="Enable"
          switchStatus={enableInstallment}
          handleSwitch={handleEnableInstallment}
        />
      </div>
    </div>
  );
}

export default Regular;
