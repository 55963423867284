import React from "react";
import styles from "../../Customize.module.css";
import dateIcon from "../../../../../../../assets/images/date.svg";
import checkBoxIcon from "../../../../../../../assets/images/checkbox.svg";
import textIcon from "../../../../../../../assets/images/text.svg";
import phoneIcon from "../../../../../../../assets/images/phone.svg";
import dropdownIcon from "../../../../../../../assets/images/double-alt-arrow-down.svg";
import radioIcon from "../../../../../../../assets/images/radio-button.svg";

const customFields = [
  {
    fieldType: "text",
    fieldName: "Text Field",
    fieldImage: textIcon,
    fieldPlaceholder: "Enter",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
    id: "",
  },

  {
    fieldType: "phone",
    fieldName: "Phone Number",
    fieldImage: phoneIcon,
    fieldPlaceholder: "Enter Phone",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
    id: "",
  },
  {
    fieldType: "select",
    fieldName: "Dropdown",
    fieldImage: dropdownIcon,
    fieldPlaceholder: "Select",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
    id: "",
  },

  {
    fieldType: "date",
    fieldName: "Date",
    fieldImage: dateIcon,
    fieldPlaceholder: "Select Date",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
    id: "",
  },

  {
    fieldType: "radio",
    fieldName: "Radio Button",
    fieldImage: radioIcon,
    fieldPlaceholder: "",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
    id: "",
  },

  {
    fieldType: "checkbox",
    fieldName: "Checkbox",
    fieldImage: checkBoxIcon,
    fieldPlaceholder: "",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
    id: "",
  },
  {
    fieldType: "profilePic",
    fieldName: "Profile Picture",
    fieldImage: checkBoxIcon,
    fieldPlaceholder: "",
    options: [],
    default: "",
    required: false,
    index: 1,
    loadDatafromDB: false,
    DBModel: "",
    isCustom: true,
    isFromCustom: true,
    id: "",
  },
  // {
  //   fieldType: "file",
  //   fieldName: "Profile Picture",
  //   fieldImage: checkBoxIcon,
  //   fieldPlaceholder: "",
  //   options: [],
  //   default: "",
  //   required: false,
  //   index: 1,
  //   loadDatafromDB: false,
  //   DBModel: "",
  //   isCustom: true,
  //   isFromCustom: true,
  //   id: "",
  // },
];

function Fields(props: any) {
  const { setDraggedField, draggedFields, hasCustomizePermission } = props;
  function handleOnDrag(e: any, field: any) {
    setDraggedField(field);
  }
  return (
    <div
      className={styles.customFieldWrap}
      style={{
        maxHeight: "calc(100vh - 180px)",
        minHeight: "calc(100vh - 180px)",
        overflowY: "auto",
      }}
    >
      <div className={styles.customFieldTitle}>Custom</div>
      <div className={styles.customFields}>
        {customFields
          .filter(
            (field) =>
              !(
                field.fieldType === "profilePic" &&
                draggedFields.some((f: any) => f.fieldType === "profilePic")
              )
          )
          .map((field, index) => (
            <div
              key={index}
              className={styles.customFieldItem}
              draggable={hasCustomizePermission}
              onDragStart={(e) => handleOnDrag(e, field)}
            >
              <div className={styles.customFieldItemIcon}>
                <img src={field.fieldImage} alt="icon" />
              </div>
              <div className={styles.customFieldName}>{field.fieldName}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Fields;
