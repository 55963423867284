import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import {
  getStudentByInstituteId,
  openSearchStudentListing,
} from "../service/studentSlice";
import StudentListing from "../StudentListing/StudentListing";
import {
  OpenSearchStudentsDTO,
  StudentInitialStateDTO,
} from "../service/types";

import { getLeadListByInstituteAndContact } from "../../../Nurture/service/nurtureSlice";

interface DispatchProps {
  GetStudentByInstituteId(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string,
    branchId: string,
    assigneeId: string
  ): void;
  GetLeadListByInstituteAndContact(data: any): void;
  GetOpenSearchStudentListing(data: OpenSearchStudentsDTO): void;
}

const mapStateToProps = (state: RootState): StudentInitialStateDTO => {
  return {
    error: state.student.error,
    success: state.student.success,
    loading: state.student.loading,
    studentDetails: state.student.studentDetails,
    studentListLoading: state.student.studentListLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetStudentByInstituteId: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string,
      branchId: string,
      assigneeId: string
    ) => {
      dispatch(
        getStudentByInstituteId({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          branchId,
          assigneeId,
        })
      );
    },
    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
    GetOpenSearchStudentListing: (data: OpenSearchStudentsDTO) => {
      dispatch(openSearchStudentListing(data));
    },
  };
};

export interface StudentProps extends StudentInitialStateDTO, DispatchProps {}
export const StudentComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentListing);
