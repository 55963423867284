import React, { useCallback, useEffect, useState } from "react";
import { Tab, Tabs, Box, Avatar, Badge, FormGroup } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import styles from "./NotificationPage.module.css";
import notificationIcon from "../../Main/components/NotificatonPopover/notificationIcon.svg";
import announcement from "../../Main/components/NotificatonPopover/announcement.svg";
import FilterDrawer from "../../../../components/FilterDrawer/FilterDrawer";
import FilterChipsWrap from "../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterTypeWrap from "../../../../components/FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import { useAuthUser } from "react-auth-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import PuffLoader from "react-spinners/PuffLoader";
import { GetNotificationsByPagination } from "./service/Notification.request";
import { formatRelativeTime } from "../../../../components/Formatter/Formatter";
import ClearFilter from "../../../../components/ClearFilter/ClearFilter";
import FilterChips from "../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterLabel from "../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import { useNavigate } from "react-router";
import {
  getLeadListByInstituteAndContact,
  setLeadModalState,
} from "../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { checkSubDomainAccess } from "../../../../components/RolePermissionAccess/RolePermissionAccess";

export default function Notification(props: any) {
  interface CheckedNotificationType {
    subType: string;
  }

  const [value, setValue] = React.useState("0");
  const {
    GetNotificationByPagination,
    notifications,
    MarkAsRead,
    markAsReadSuccess,
    MarkAllAsRead,
    markAllAsReadSuccess,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userFromLocalStorage = authUser;
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const whatsNew = [
    {
      id: 1,
      heading: "Introducing seamless Email integration for nurture",
      email: "Simplify and streamline your communication today!",
      time: "2h ago",
      status: "unread",
    },
    {
      id: 2,
      heading:
        "Effortlessly import contacts with our enhanced Bulk Import feature on nurture.",
      email: "Try it now!",
      time: "4h ago",
      status: "unread",
    },
    {
      id: 3,
      heading:
        "Boost customer engagement with our new Whatsapp integration on nurture.",
      email: "Connect and communicate seamlessly!",
      time: "3h ago",
      status: "read",
    },
  ];

  function handleChange(event: React.SyntheticEvent, newValue: string) {
    setValue(newValue);
  }

  const [notificationItems, setNotificationItems] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState(false);
  const [checkedFilterSubType, setCheckedFilterSubType] = useState<
    CheckedNotificationType[]
  >([]);
  const [filterCheckedSubType, setFilterCheckedSubType] = useState<
    CheckedNotificationType[]
  >([]);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  React.useEffect(() => {
    setNotificationItems(notifications);
  }, [notifications]);

  React.useEffect(() => {
    const lastEvaluatedKey = "";
    const limit = 10;
    const lastEvaluatedSortKey = "";
    if (userFromLocalStorage) {
      GetNotificationByPagination(
        userFromLocalStorage?.institute[0]?.id,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey
      );
    }
  }, [
    GetNotificationsByPagination,
    userFromLocalStorage,
    markAsReadSuccess,
    markAllAsReadSuccess,
  ]);

  const handleNextPage = () => {
    const userFromLocalStorage = authUser;
    const lastEvaluatedKey = encodeURIComponent(
      notificationItems?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      notificationItems?.lastEvaluatedKeyReceived?.GSI1SK?.S
    );
    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      GetNotificationsByPagination(
        userFromLocalStorage?.institute[0]?.id,
        lastEvaluatedKey,
        10,
        lastEvaluatedSortKey
      )
        .then((res: any) => {
          setHasMoreData(true);
          setNotificationItems((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],

            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  useEffect(() => {
    if (
      notificationItems &&
      notificationItems?.lastEvaluatedKeyReceived &&
      notificationItems?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [notificationItems]);

  // const handleMarkAsRead = (val: any) => {
  //   MarkAsRead(val);
  // };

  const openLeadExpandModal = useCallback(
    (
      instituteId: string,
      phone: string,
      email: string,
      id: string,
      tab: string
    ) => {
      if (hasLeadCardPermission) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("leadModal", "true");
        queryParams.set("leadInstituteId", instituteId);
        queryParams.set("leadId", id);
        if (email) {
          queryParams.set("leadEmail", email);
        } else {
          queryParams.delete("leadEmail");
        }
        if (phone) {
          queryParams.set("leadPhone", phone);
        } else {
          queryParams.delete("leadPhone");
        }
        queryParams.set("tabOpen", tab);

        // Update the URL with the modified query parameter
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
        dispatch(setLeadModalState());
        if (instituteId && (phone || email)) {
          leadPopupApiCall(instituteId, phone, email);
        }
      }
    },
    []
  );

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const handleMarkAsRead = (val: any, notificationData: any) => {
    MarkAsRead(val);

    const currentUrl = window.location.href.replace(window.location.hash, "");

    if (notificationData?.navigationScreen === "EXPANDED_LEAD_CARD") {
      const { leadContact, leadEmail, leadId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "overview"
      );
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_TASK_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, taskId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = new URLSearchParams(window.location.search);
      if (taskId) {
        queryParams.set("leadTaskId", taskId);
      } else {
        queryParams.delete("leadTaskId");
      }
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);

      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "task"
      );
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_NOTE_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, noteId } =
        notificationData?.otherDetails?.impactedDetails;
      let queryParams = new URLSearchParams(window.location.search);
      if (noteId) {
        queryParams.set("leadNoteId", noteId);
      } else {
        queryParams.delete("leadNoteId");
      }
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "notes"
      );
    } else if (
      notificationData?.navigationScreen ===
        "EXPANDED_LEAD_CARD_PAYMENT_SECTION" ||
      notificationData?.navigationScreen ===
        "EXPANDED_LEAD_CARD_NEGOTIATION_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, paymentId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `/app/Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}&tabOpen=payment`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (paymentId) queryParams += `&leadPaymentId=${paymentId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "payment"
      );
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_DEMO_SECTION"
    ) {
      const { leadContact, leadEmail, leadId, demoId } =
        notificationData?.otherDetails;

      let queryParams = `/app/Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (demoId) queryParams += `&leadDemoId=${demoId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "overview"
      );
    } else if (notificationData?.navigationScreen === "BATCH") {
      const { batchId } = notificationData?.otherDetails?.impactedDetails;

      let queryParams = `/app/manage/Batches?batchId=${batchId}`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "COURSE") {
      const { courseId } = notificationData?.otherDetails?.impactedDetails;

      let queryParams = `/app/manage/Courses/details/${courseId}`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BRANCH") {
      const { branchId } = notificationData?.otherDetails?.impactedDetails;

      let queryParams = `/app/manage/Branches?selectedBranch=${branchId}`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "INSTITUTE_PROFILE") {
      let queryParams = `/app/manage/InstituteProfile`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "EXPANDED_SUBSCRIPTION") {
      let queryParams = `/app/manage/Billing/ManageSubscription`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BILLING_SUBSCRIPTION") {
      let queryParams = `/app/manage/Billing`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BILLING_ADDRESS") {
      let queryParams = `/app/manage/Billing?billingTab=address`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "BILLING_ADMIN") {
      let queryParams = `/app/manage/Billing?billingTab=billingAdmins`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "TEAM_MEMBER") {
      const { userId } = notificationData?.otherDetails?.impactedDetails;

      let queryParams = `/app/manage/Teams?selectedStaff=${userId}`;

      navigate(queryParams);
    } else if (
      notificationData?.navigationScreen === "EXPANDED_LEAD_CARD_ACTIVITY"
    ) {
      const { leadContact, leadEmail, leadId, activityId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `/app/Nurture?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}&tabOpen=activity`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (activityId) queryParams += `&leadActivityId=${activityId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "activity"
      );
    } else if (notificationData?.navigationScreen === "INBOUND_CONTACT") {
      let queryParams = `/app/Inbound/Contacts`;

      navigate(queryParams);
    } else if (notificationData?.navigationScreen === "TASK_COMMENT") {
      const { leadContact, leadEmail, leadId, taskId, commentId } =
        notificationData?.otherDetails?.impactedDetails;

      let queryParams = `?leadModal=true&leadInstituteId=${notificationData?.institutionId}&leadId=${leadId}&tabOpen=tasks`;

      if (leadContact) queryParams += `&leadPhone=${leadContact}`;
      if (leadEmail) queryParams += `&leadEmail=${leadEmail}`;
      if (taskId) queryParams += `&leadTaskId=${taskId}`;
      if (commentId) queryParams += `&leadTaskCommentId=${commentId}`;

      // navigate(queryParams);
      openLeadExpandModal(
        notificationData?.institutionId,
        leadContact,
        leadEmail,
        leadId,
        "task"
      );
    }
  };

  const handleMarkAllAsRead = () => {
    const userId = userFromLocalStorage?.institute[0]?.id;
    MarkAllAsRead(userId);
  };

  const handleFilterBatchStatusChecked = (subType: string) => {
    const batchStatusToAdd: CheckedNotificationType = { subType };

    if (
      checkedFilterSubType.some(
        (item) => item.subType.toLowerCase() === subType.toLowerCase()
      )
    ) {
      setCheckedFilterSubType(
        checkedFilterSubType.filter(
          (item) => item.subType.toLowerCase() !== subType.toLowerCase()
        )
      );
    } else {
      setCheckedFilterSubType([...checkedFilterSubType, batchStatusToAdd]);
    }
  };

  const handleFilterBatches = React.useCallback(
    (batchStatusArray: any) => {
      setFilterCheckedSubType(batchStatusArray);
    },
    [setFilterCheckedSubType]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterBatches(checkedFilterSubType);

    setFilterApplied(checkedFilterSubType?.length === 0 ? false : true);
  }, [setFilterApplied, checkedFilterSubType]);

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setFilterCheckedSubType([]);
    setCheckedFilterSubType([]);
    localStorage.setItem("BatchFilterActive", "");
  }, [setFilterApplied, setFilterCheckedSubType, setCheckedFilterSubType]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedFilterSubType(filterCheckedSubType);
  }, [filterCheckedSubType]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const filteredValue = (
    <>
      {filterCheckedSubType?.length > 0 &&
        filterCheckedSubType?.map((item) => (
          <div key={item.subType} className="filteredDiv">
            {item.subType}
          </div>
        ))}
    </>
  );

  const clearCheckedBatchStatus = (batchStatus: string) => {
    setCheckedFilterSubType(
      checkedFilterSubType.filter((item) => item.subType !== batchStatus)
    );
  };

  const filteredNotificationList =
    notificationItems &&
    notificationItems?.returnData?.filter((notify: any) => {
      const notificationCategoryIsChecked =
        filterCheckedSubType?.length === 0 ||
        filterCheckedSubType?.some((item) =>
          notify.category?.includes(item?.subType)
        );

      return notificationCategoryIsChecked;
    });

  return (
    <div style={{ padding: "36px 22px 22px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.notificationHead}>Notification</div>
        <FilterDrawer
          handleFilterApply={handleFilterApply}
          handleResetClick={handleResetClick}
          activeFilter={filterApplied}
          cancelClick={cancelClick}
          closeSetFilter={handleFilterClose}
        >
          <div
            style={{
              padding: "15px 32px 0px 32px",
              overflowY: "auto",
              maxHeight: "calc(100% - 110px)",
            }}
          >
            <FilterChipsWrap>
              {checkedFilterSubType?.length > 0 &&
                checkedFilterSubType?.map((item) => (
                  <FilterChips
                    keyVal={item?.subType}
                    chipName={item?.subType?.toLowerCase()}
                    clearValue={item?.subType}
                    closeFunction={clearCheckedBatchStatus}
                  />
                ))}
            </FilterChipsWrap>
          </div>
          <div
            style={{
              padding: "22px 32px",
              overflowY: "auto",
              maxHeight: "calc(100% - 110px)",
            }}
          >
            <FilterTypeWrap>
              <FilterLabel labelValue="SubTypes" />
              <FilterCheckBoxWrap scroll={false}>
                <FormGroup>
                  {[
                    { option: "LEAD" },
                    { option: "TASK" },
                    { option: "DEMO" },
                    { option: "MENTION" },
                    { option: "IMPORT" },
                    { option: "SUBSCRIPTION" },
                    { option: "MANAGE" },
                  ].map((item: any) => (
                    <FilterCheckbox
                      label={item.option?.toLowerCase()}
                      keyVal={item?.option}
                      checked={checkedFilterSubType?.some(
                        (data) =>
                          data?.subType?.toLowerCase() ===
                          item?.option?.toLowerCase()
                      )}
                      checkboxFunction={() =>
                        handleFilterBatchStatusChecked(item?.option)
                      }
                    />
                  ))}
                </FormGroup>
              </FilterCheckBoxWrap>
            </FilterTypeWrap>
          </div>
        </FilterDrawer>
      </div>
      <div>
        <TabContext value={value}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ marginBottom: "16px", borderBottom: "1px solid #E9E9E9" }}
          >
            <Tab value="0" label="All" sx={{ textTransform: "capitalize" }} />
            {false && (
              <Tab
                value="1"
                label="What’s New"
                sx={{ textTransform: "capitalize" }}
              />
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "14px",
                color: "#667085",
              }}
              onClick={handleMarkAllAsRead}
            >
              Mark All As Read
            </div>
          </Tabs>

          <TabPanel
            sx={{
              padding: "0",
              border: "1px solid #F1F1F1",
              // height: "500px",
              overflow: "auto",
              height: "calc(100vh - 250px)",
            }}
            value="0"
          >
            <InfiniteScroll
              style={{ paddingBottom: "100px" }}
              dataLength={
                notificationItems && notificationItems?.returnData?.length
                  ? notificationItems?.returnData?.length
                  : notifications && notifications?.returnData?.length
              }
              next={handleNextPage}
              hasMore={hasMoreData}
              loader={
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PuffLoader color="#36d7b7" />
                </Box>
              }
              // height={500}
              height={`calc(100vh - 250px)`}
            >
              {filteredNotificationList?.map((notification: any) => (
                <div
                  key={notification.id}
                  className={`${styles.notificationItem} ${
                    notification.readStatus === "UNREAD" ? styles.unread : ""
                  }`}
                  // onClick={() => handleMarkAsRead(notification?.id)}
                  onClick={() =>
                    handleMarkAsRead(notification?.id, notification)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        {notification.iconType === "ALPHABET" ? (
                          <Badge
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            color="primary"
                            overlap="circular"
                            badgeContent={
                              notification.readStatus === "UNREAD" ? (
                                <span />
                              ) : null
                            }
                            variant={
                              notification.readStatus === "UNREAD"
                                ? "dot"
                                : "standard"
                            }
                          >
                            <Avatar
                              alt="avatar"
                              sx={{
                                width: "30px",
                                height: "30px",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                textTransform: "capitalize",
                                background: "#667085",
                                color: " #fff",
                                fontWeight: "400",
                              }}
                            >
                              <Box>{notification.name.charAt(0)}</Box>
                            </Avatar>
                          </Badge>
                        ) : (
                          <Badge
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            color="primary"
                            overlap="circular"
                            badgeContent={
                              notification.readStatus === "UNREAD" ? (
                                <span />
                              ) : null
                            }
                            variant={
                              notification.readStatus === "UNREAD"
                                ? "dot"
                                : "standard"
                            }
                          >
                            <Avatar
                              alt="avatar"
                              sx={{
                                width: "30px",
                                height: "30px",
                                background: "rgba(102, 112, 133, 0.11)",
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <img
                                  src={notificationIcon}
                                  width="18px"
                                  height="18px"
                                  alt="notification"
                                />
                              </Box>
                            </Avatar>
                          </Badge>
                        )}
                      </div>
                      <div className={styles.email}>
                        {notification.notificationTitle}
                      </div>
                    </div>
                    <div className={styles.timeDisplay}>
                      {formatRelativeTime(notification.createdTime)}
                    </div>
                  </div>
                </div>
              ))}
              {notificationItems && filteredNotificationList.length === 0 && (
                <Box
                  sx={{ textAlign: "center", position: "relative", top: "50%" }}
                >
                  <div className={styles.NoResult}>No results found</div>
                </Box>
              )}
            </InfiniteScroll>
          </TabPanel>

          <TabPanel
            sx={{
              padding: "0",
              border: "1px solid #F1F1F1",
              height: "500px",
              overflow: "auto",
            }}
            value="1"
          >
            {whatsNew.map((item) => (
              <div
                key={item.id}
                className={`${styles.notificationItem} ${
                  item.status === "unread" ? styles.unread : ""
                }`}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div>
                      <Badge
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        color="primary"
                        overlap="circular"
                        badgeContent={
                          item.status === "unread" ? <span /> : null
                        }
                        variant={item.status === "unread" ? "dot" : "standard"}
                      >
                        <Avatar
                          alt="avatar"
                          sx={{
                            width: "30px",
                            height: "30px",
                            background: "rgba(102, 112, 133, 0.11)",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img
                              src={announcement}
                              width="20px"
                              height="20px"
                              alt="notification"
                            />
                          </Box>
                        </Avatar>
                      </Badge>
                    </div>
                    <div>
                      <div className={styles.itemHeading}>{item.heading}</div>
                      <div className={styles.email}>{item.email}</div>
                    </div>
                  </div>
                  <div className={styles.timeDisplay}>{item.time}</div>
                </div>
              </div>
            ))}
          </TabPanel>
        </TabContext>
      </div>
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
    </div>
  );
}
