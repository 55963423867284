import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import Dashboard from "../Dashboard";
import {
  dashboard,
  dashboardDatas,
  getLeadSourceGraphData,
  getOverduePayments,
  getRevenueAnalyticsGraphData,
  privacyPolicy,
  termsAndConditions,
} from "../../Dashboard/service/dashboardSlice";
import { PrivacyPolicyData, TermsAndConditionData } from "../service/types";
import { SetActiveInstitute } from "../../../../service/activeInstituteSlice";
import { getStaffList } from "../../Manage/ManageStaff/service/Staffslice";
import { getBranchList } from "../../Manage/ManageBranch/service/Branchslice";
import { getLeadListByInstituteAndContact } from "../../Nurture/service/nurtureSlice";

interface StateProps {
  error: boolean | string | undefined;
  loading: boolean;
  success: boolean;
  dashboardDetails: any;
  revenuGraphData: any;
  leadSourceGraphData: any;
  revenuGraphloading: boolean;
  overduePaymentsData: any;
  overduePaymentsloading: boolean;
  leadSourceGraphloading: boolean;
  termsAndConditionsLoading: boolean;
  termsAndConditionsSuccess: boolean;
  termsAndConditionsError: boolean;
  privacyPolicyLoading: boolean;
  privacyPolicySuccess: boolean;
  privacyPolicyError: boolean;
}
interface DispatchProps {
  dashboardData(data: string): void;
  GetRevenueAnalyticsGraphData(data: string): void;
  GetLeadSourceGraphData(data: string): void;
  GetOverduePayments(data: string): void;
  AcceptTnC(data: TermsAndConditionData): void;
  AcceptPrivacyPolicy(data: PrivacyPolicyData): void;
  setActiveInsitute(data: string): void;
  GetLeadListByInstituteAndContact(data: any): void;
  GetStaffList(id: string): void;
  GetBranchList(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error: state.dashboard.error,
    loading: state.dashboard.loading,
    success: state.dashboard.success,
    dashboardDetails: state.dashboard.dashboardData,
    revenuGraphData: state.dashboard.revenuGraphData,
    leadSourceGraphData: state.dashboard.leadSourceGraphData,
    overduePaymentsData: state.dashboard.overduePaymentsData,
    overduePaymentsloading: state.dashboard.overduePaymentsloading,
    revenuGraphloading: state.dashboard.revenuGraphloading,
    leadSourceGraphloading: state.dashboard.leadSourceGraphloading,
    termsAndConditionsLoading: state.dashboard.termsAndConditionsLoading,
    termsAndConditionsSuccess: state.dashboard.termsAndConditionsSuccess,
    termsAndConditionsError: state.dashboard.termsAndConditionsError,
    privacyPolicyLoading: state.dashboard.privacyPolicyLoading,
    privacyPolicySuccess: state.dashboard.privacyPolicySuccess,
    privacyPolicyError: state.dashboard.privacyPolicyError,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    dashboardData: (data: string) => {
      dispatch(dashboardDatas(data));
    },
    GetRevenueAnalyticsGraphData: (data: string) => {
      dispatch(getRevenueAnalyticsGraphData(data));
    },
    GetLeadSourceGraphData: (data: string) => {
      dispatch(getLeadSourceGraphData(data));
    },
    GetOverduePayments: (data: string) => {
      dispatch(getOverduePayments(data));
    },
    AcceptTnC: (data: TermsAndConditionData) => {
      dispatch(termsAndConditions(data));
    },
    AcceptPrivacyPolicy: (data: PrivacyPolicyData) => {
      dispatch(privacyPolicy(data));
    },
    setActiveInsitute: (data: string) => {
      dispatch(SetActiveInstitute(data));
    },

    GetStaffList: (id: string) => {
      dispatch(getStaffList(id));
    },

    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },

    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
  };
};

export interface DashboardProps extends StateProps, DispatchProps {}
export const DashboardComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
