import React from "react";
import styles from "../DemoBooking.module.css";
import {
  Button,
  Box,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  CircularProgress,
} from "@mui/material";
import dateIcon from "../Assets/calander.svg";
import UserAssign from "../../../../../../components/UserAssign/UserAssign";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";

function DemoSelectedConfirm(props: any) {
  const {
    employees,
    setStage,
    setWay,
    batchSelected,
    demoSelected,
    selectedDate,
    setDateDisable,
    demoDetails,
    CreateDemo,
    setDemoDetails,
    reschedule,
    EditDemo,
    createDemoSuccess,
    handleLastStageBack,
    createDemoLoading,
    demosByCourse,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const editDemoLoading = useSelector(
    (state: any) => state.nurture.editDemoLoading
  );

  const [showAssigneeErrMsz, setShowAssigneeErrMsz] =
    React.useState<boolean>(false);
  const [selectTimeErrMsz, setselectTimePastErrMsz] =
    React.useState<boolean>(false);

  const currentCalendar = batchSelected?.batchCalendar?.find(
    (calendarItem: any) => {
      if (!calendarItem || !calendarItem.date) {
        return false; // Handle null or undefined values
      }

      const demoTimestamp = calendarItem.date; // Assuming date is in Unix timestamp (milliseconds)
      const demoDate = new Date(demoTimestamp);
      const selectedDateObj = new Date(selectedDate);

      return (
        demoDate?.getFullYear() === selectedDateObj?.getFullYear() &&
        demoDate?.getMonth() === selectedDateObj?.getMonth() &&
        demoDate?.getDate() === selectedDateObj?.getDate()
      );
    }
  );

  const demoCurrentCalendar = demoSelected?.batchCalendar?.find(
    (calendarItem: any) => {
      if (!calendarItem || !calendarItem.date) {
        return false; // Handle null or undefined values
      }

      const demoTimestamp = calendarItem.date; // Assuming date is in Unix timestamp (milliseconds)
      const demoDate = new Date(demoTimestamp);
      const selectedDateObj = new Date(selectedDate);

      return (
        demoDate?.getFullYear() === selectedDateObj?.getFullYear() &&
        demoDate?.getMonth() === selectedDateObj?.getMonth() &&
        demoDate?.getDate() === selectedDateObj?.getDate()
      );
    }
  );

  const batchSheduleItem =
    batchSelected !== null
      ? currentCalendar &&
        batchSelected?.batchSchedule?.find((item: any) => {
          return (
            item?.day ===
            moment
              .unix(currentCalendar?.date / 1000)
              .format("ddd")
              ?.toUpperCase()
          );
        })
      : demoSelected?.batchSchedule?.find((item: any) => {
          return (
            item?.day ===
            moment
              .unix(demoCurrentCalendar?.date / 1000)
              .format("ddd")
              ?.toUpperCase()
          );
        });

  const batchSheduleItemCustom =
    currentCalendar &&
    batchSelected?.customDays
      ?.filter((item: any) => item?.batchSessions?.length > 0)
      ?.find((item: any) => {
        return item?.date === currentCalendar?.date;
      });
  const handleLeadAssignee = React.useCallback(
    (leadId: any, user: any) => {
      if (user) {
        setDemoDetails({
          ...demoDetails,
          assignedToId: user?.id,
          assignedToName: user?.firstName + " " + user?.lastName,
        });
        setAssigny(user);
      }
    },

    [demoDetails]
  );

  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleSelectTime = React.useCallback(
    (item: any) => {
      setSelectedItem(item);
      setDemoDetails({
        ...demoDetails,
        demoStartTime: item.startTime,
        demoEndTime: item.endTime,
        // demoDuration: item.endTime - item.startTime / 3600,
        // demoTiming: selectedDate,
      });
      if (item !== null) {
        setselectTimePastErrMsz(false);
      } else {
        setselectTimePastErrMsz(true);
      }
    },
    [demoDetails]
  );

  const handleDemoConfirm = async (event: any) => {
    event.preventDefault();
    try {
      if (demoDetails.assignedToId === "") {
        setShowAssigneeErrMsz(true);
      } else {
        setShowAssigneeErrMsz(false);
      }

      if (
        demoDetails.demoEndTime === 0 ||
        demoDetails.demoStartTime === 0 ||
        demoDetails.demoEndTime === demoDetails.demoStartTime ||
        !selectedItem
      ) {
        setselectTimePastErrMsz(true);
      } else {
        setselectTimePastErrMsz(false);
      }
      selectedItem &&
        demoDetails.assignedToId &&
        demoDetails.demoEndTime &&
        demoDetails.demoStartTime &&
        (!reschedule ? CreateDemo(demoDetails) : EditDemo(demoDetails));
    } catch (error) {}
  };
  React.useEffect(() => {
    if (batchSheduleItem?.batchSessions?.length === 1) {
      setSelectedItem(batchSheduleItem?.batchSessions?.[0]);
      // setDemoDetails({
      //   ...demoDetails,
      //   demoStartTime: batchSheduleItem?.batchSessions?.[0]?.startTime,
      //   demoEndTime: batchSheduleItem?.batchSessions?.[0]?.endTime,
      //   demoDuration:
      //     (batchSheduleItem?.batchSessions?.[0]?.endTime -
      //       batchSheduleItem?.batchSessions?.[0]?.startTime) /
      //     3600,
      //   demoTiming: selectedDate,
      // });
    } else if (
      batchSheduleItem?.batchSessions?.length === 0 &&
      batchSheduleItemCustom?.batchSessions?.length === 1
    ) {
      setSelectedItem(batchSheduleItemCustom?.batchSessions?.[0]);
      setDemoDetails({
        ...demoDetails,
        demoStartTime: batchSheduleItemCustom?.batchSessions?.[0]?.startTime,
        demoEndTime: batchSheduleItemCustom?.batchSessions?.[0]?.endTime,
      });
    }
  }, [demoDetails]);

  const [assigny, setAssigny] = React.useState<any>("");

  const filteredEmployees = employees?.filter((employee: any) => {
    const batch = batchSelected != null ? batchSelected : demoSelected;
    return batch?.assignees?.some((assignee: any) => {
      return employee?.id === assignee?.assignedToId;
    });
  });

  React.useEffect(() => {
    if (filteredEmployees?.length === 1) {
      setDemoDetails({
        ...demoDetails,
        assignedToId: filteredEmployees?.[0]?.id,
        assignedToName:
          filteredEmployees?.[0]?.firstName +
          " " +
          filteredEmployees?.[0]?.lastName,
        instituteId: authUser?.institute[0]?.instituteId,
      });
      setAssigny(filteredEmployees?.[0]?.id);
    } else {
      setDemoDetails({
        ...demoDetails,
        assignedToId: "",
        assignedToName: "",
        instituteId: authUser?.institute[0]?.instituteId,
      });
      setAssigny("");
    }
  }, []);

  function isSameDate(timestamp1: number, timestamp2: number): boolean {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    // Compare year, month, and day
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  function calculateDemoBookedCount(batchId: string, batchCalendar: any) {
    const batch = batchCalendar?.find(
      (batch: any) => batch.date === selectedDate
    );
    // const demosArray = demosByCourse?.filter(
    //   (item: any) =>
    //     item.batchId === batchId && item.demoTiming === selectedDate
    // );
    const demosArray = demosByCourse?.filter(
      (item: any) =>
        item.batchId === batchId && isSameDate(item.demoTiming, selectedDate)
    );

    return demosArray?.length;
  }

  return (
    <div className={styles.demoSelectedWrapper}>
      <Box
        sx={{
          padding: "10px 12px 0 12px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <button
          className={styles.BackBtn}
          onClick={() => {
            setStage(1);
            setWay(0);
            setDateDisable(false);
            handleLastStageBack();
          }}
        >
          <ArrowBackIcon
            sx={{
              marginRight: "8px",
              fontSize: "15PX",
              color: "#667085",
            }}
          />
          Back
        </button>
      </Box>
      <div className={styles.demoSelectedWrapperChild}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div>
            <div
              className={styles.demoCardHead}
              style={{ marginBottom: "9px" }}
            >
              <div className={styles.demoCardName}>
                {batchSelected !== null
                  ? batchSelected?.batchName
                  : demoSelected?.demoName}
              </div>
            </div>

            <div
              className={styles.demoCardBatchDetails}
              style={{ marginBottom: "15px" }}
            >
              <div className={styles.demoCardBatchItem}>
                <div className={styles.demoCardBatchItemIcon}>
                  <img src={dateIcon} alt="dateIcon" />
                </div>
                <div className={styles.demoCardBatchItemValue}>
                  {batchSelected !== null
                    ? moment
                        .unix(currentCalendar?.date / 1000)
                        .format("DD MMM YYYY, ddd")
                    : demoSelected &&
                      moment
                        .unix(demoCurrentCalendar?.date / 1000)
                        .format("DD MMM YYYY, ddd")}
                </div>
              </div>
            </div>
            <div className={styles.demoCardBottom}>
              <div className={styles.demoCardCount}>
                <div className={styles.demoCardCountLabel}>Demo Booked</div>
                <div className={styles.demoCardCountValue}>
                  {batchSelected !== null
                    ? calculateDemoBookedCount(
                        batchSelected.id,
                        batchSelected?.batchCalendar
                      )
                    : calculateDemoBookedCount(
                        demoSelected.id,
                        demoSelected?.batchCalendar
                      )}
                </div>
              </div>
              <div className={styles.demoCardBook}></div>
            </div>
            <div style={{ marginTop: "12px" }}>
              <UserAssign
                users={employees && filteredEmployees}
                size="large"
                handleLeadAssignee={handleLeadAssignee}
                isForEnquiryModal={true}
                selectedAssignyId={assigny?.id}
              />
              {showAssigneeErrMsz && (
                <FormHelperText error={true}>
                  {showAssigneeErrMsz ? "Select an assignee" : ""}
                </FormHelperText>
              )}
            </div>
          </div>

          <div>
            <div className={styles.demoCardType}>
              {batchSelected !== null
                ? "classroom"
                : demoSelected?.batchType[0]?.batchType?.courseTypeName}
            </div>
            <div className={styles.scheduleSelectTtle}>Select Time</div>
            <div className={styles.demoCardScheduleWrap}>
              {batchSheduleItem?.batchSessions?.map(
                (item: any, index: number) => (
                  <>
                    <FormControlLabel
                      sx={{ margin: " 0 0 5px 0" }}
                      key={index}
                      control={
                        <Checkbox
                          sx={{
                            strokeWidth: 1,
                            color: "#D0D5DD",
                            zIndex: "99999999999",
                            padding: 0,

                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                            "&:focus": {
                              backgroundColor: "transparent",
                            },
                          }}
                          checked={item === selectedItem}
                          onChange={() => handleSelectTime(item)}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#667085",
                            lineHeight: "20px",
                            textTransform: "capitalize",
                          }}
                        >
                          {batchSelected !== null
                            ? batchSheduleItem &&
                              moment.unix(item?.startTime).format("hh:mm A")
                            : moment
                                .unix(item?.startTime)
                                .format("hh:mm A")}{" "}
                          -{" "}
                          {batchSelected !== null
                            ? batchSheduleItem &&
                              moment.unix(item?.endTime).format("hh:mm A")
                            : moment.unix(item?.endTime).format("hh:mm A")}
                        </Typography>
                      }
                    />
                  </>
                )
              )}
              {batchSheduleItemCustom?.batchSessions?.map(
                (item: any, index: number) => (
                  <>
                    <FormControlLabel
                      sx={{ margin: " 0 0 5px 0" }}
                      key={index}
                      control={
                        <Checkbox
                          sx={{
                            strokeWidth: 1,
                            color: "#D0D5DD",
                            zIndex: "99999999999",
                            padding: 0,

                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                            "&:focus": {
                              backgroundColor: "transparent",
                            },
                          }}
                          checked={item === selectedItem}
                          onChange={() => handleSelectTime(item)}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#667085",
                            lineHeight: "20px",
                            textTransform: "capitalize",
                          }}
                        >
                          {batchSelected !== null
                            ? batchSheduleItem &&
                              moment.unix(item?.startTime).format("hh:mm A")
                            : moment
                                .unix(item?.startTime)
                                .format("hh:mm A")}{" "}
                          -{" "}
                          {batchSelected !== null
                            ? batchSheduleItem &&
                              moment.unix(item?.endTime).format("hh:mm A")
                            : moment.unix(item?.endTime).format("hh:mm A")}
                        </Typography>
                      }
                    />
                  </>
                )
              )}
            </div>
            {selectTimeErrMsz && (
              <FormHelperText error={true}>
                {selectTimeErrMsz ? "Select one Time" : ""}
              </FormHelperText>
            )}
          </div>
        </div>
      </div>

      <div className={styles.existingDemoScreenBottom} style={{ gap: "5px" }}>
        <Button
          sx={{
            textTransform: "capitalize",
            color: "#9A9EA6",
            borderColor: "#D0D5DD",
            border: "1px solid",
            borderRadius: "4PX",
            ":hover": {
              color: "#9A9EA6",
              borderColor: "#D0D5DD",
              backgroundColor: "#9a9ea612",
            },
          }}
          variant="outlined"
          onClick={() => {
            setStage(1);
            setWay(0);
            setDateDisable(false);
            handleLastStageBack();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            fontSize: 14,
            textTransform: "capitalize",
            boxShadow: "none",
            height: "38px",
          }}
          size={"small"}
          onClick={handleDemoConfirm}
        >
          {createDemoLoading || (reschedule && editDemoLoading) ? (
            <CircularProgress size={18} color={"inherit"} />
          ) : (
            "Confirm"
          )}
        </Button>
      </div>
    </div>
  );
}

export default DemoSelectedConfirm;
