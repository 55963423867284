import React, { useEffect, useState, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { FormControl, MenuItem, Select } from "@mui/material";
import styles from "./LeadOverview.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LeadGraph(props: any) {
  const { selectedLeadData } = props;
  const [leadGraphDuration, setLeadGraphDuration] = useState(15);
  const stages = [
    "",
    "New Enquiry",
    "Contacted",
    "Demo",
    "Negotiation",
    "Converted",
  ];

  // const transformData = (stateChangeGraph: any, leadGraphDuration: number) => {
  //   const allGraphMarkings = stateChangeGraph.reduce((acc: any, curr: any) => {
  //     return acc.concat(curr.graphMarking);
  //   }, []);

  //   const sortedGraphMarkings = allGraphMarkings
  //     .flat()
  //     .sort(
  //       (a: any, b: any) =>
  //         new Date(a.creationDate).getTime() -
  //         new Date(b.creationDate).getTime()
  //     );

  //   if (sortedGraphMarkings.length === 1) {
  //     const singleDataPointDate = new Date(sortedGraphMarkings[0].creationDate);
  //     const formattedDate = singleDataPointDate.toLocaleString("en-US", {
  //       month: "short",
  //       day: "2-digit",
  //     });
  //     const formattedData = [
  //       { x: formattedDate, y: sortedGraphMarkings[0].currentStage },
  //     ];

  //     // Extend the labels to show future dates without plotting data points
  //     const endDate = new Date(singleDataPointDate);
  //     endDate.setDate(singleDataPointDate.getDate() + leadGraphDuration);
  //     for (
  //       let d = new Date(singleDataPointDate.getTime() + 86400000);
  //       d <= endDate;
  //       d.setDate(d.getDate() + 1)
  //     ) {
  //       const futureDate = d.toLocaleString("en-US", {
  //         month: "short",
  //         day: "2-digit",
  //       });
  //       formattedData.push({ x: futureDate, y: null }); // Assign null to y value
  //     }

  //     return formattedData;
  //   } else {
  //     const today = new Date();
  //     let startDate = new Date();
  //     startDate.setDate(today.getDate() - leadGraphDuration);

  //     // Ensure startDate doesn't go before the date of the 1st entry
  //     if (startDate < new Date(sortedGraphMarkings[0].creationDate)) {
  //       startDate = new Date(sortedGraphMarkings[0].creationDate);
  //     }

  //     const formattedData = [];
  //     let lastKnownCurrentStage = null;

  //     // Find the last known stage before the start date
  //     for (let marking of sortedGraphMarkings) {
  //       const markingDate = new Date(marking.creationDate);
  //       if (markingDate < startDate) {
  //         lastKnownCurrentStage = marking.currentStage;
  //       }
  //     }

  //     if (lastKnownCurrentStage === null && sortedGraphMarkings.length > 0) {
  //       // If no activities in the selected duration, use the last entry's state
  //       lastKnownCurrentStage =
  //         sortedGraphMarkings[sortedGraphMarkings.length - 1].currentStage;
  //     }

  //     // Plotting data points
  //     for (
  //       let d = new Date(startDate);
  //       d <= today;
  //       d.setDate(d.getDate() + 1)
  //     ) {
  //       const formattedDate = formatDate(d);

  //       const activitiesOnThisDay = sortedGraphMarkings.filter((marking: any) =>
  //         isSameDay(new Date(marking.creationDate), d)
  //       );

  //       if (activitiesOnThisDay.length > 0) {
  //         lastKnownCurrentStage =
  //           activitiesOnThisDay[activitiesOnThisDay.length - 1].currentStage;
  //       }

  //       if (lastKnownCurrentStage !== null) {
  //         formattedData.push({ x: formattedDate, y: lastKnownCurrentStage });
  //       }
  //     }

  //     return formattedData;
  //   }
  // };

  // const formatDate = (date: any) => {
  //   return date.toLocaleString("en-US", { month: "short", day: "2-digit" });
  // };

  // const isSameDay = (date1: any, date2: any) => {
  //   return (
  //     date1.getDate() === date2.getDate() &&
  //     date1.getMonth() === date2.getMonth() &&
  //     date1.getFullYear() === date2.getFullYear()
  //   );
  // };

  const transformData = (stateChangeGraph: any, leadGraphDuration: number) => {
    const allGraphMarkings = stateChangeGraph.reduce((acc: any, curr: any) => {
      return acc.concat(curr.graphMarking);
    }, []);

    const sortedGraphMarkings = allGraphMarkings
      .flat()
      .sort(
        (a: any, b: any) =>
          new Date(a.creationDate).getTime() -
          new Date(b.creationDate).getTime()
      );

    const today = new Date();
    let startDate = new Date();
    startDate.setDate(today.getDate() - leadGraphDuration);

    if (sortedGraphMarkings.length === 0) {
      return fillDatesWithEmptyData(startDate, today);
    }

    // Handling a single entry
    if (sortedGraphMarkings.length === 1) {
      return handleSingleEntry(
        sortedGraphMarkings[0],
        startDate,
        leadGraphDuration
      );
    }

    const formattedData = [];
    let lastKnownCurrentStage = null;

    // Determine last known current stage before the start date
    for (let marking of sortedGraphMarkings) {
      if (new Date(marking.creationDate) < startDate) {
        lastKnownCurrentStage = marking.currentStage;
      }
    }

    for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
      const formattedDate = formatDate(d);
      const activitiesOnThisDay = sortedGraphMarkings.filter((marking: any) =>
        isSameDay(new Date(marking.creationDate), d)
      );

      if (activitiesOnThisDay.length > 0) {
        lastKnownCurrentStage =
          activitiesOnThisDay[activitiesOnThisDay.length - 1].currentStage;
      }

      formattedData.push({ x: formattedDate, y: lastKnownCurrentStage });
    }

    return formattedData;
  };

  const formatDate = (date: any) => {
    return date.toLocaleString("en-US", { month: "short", day: "2-digit" });
  };

  const isSameDay = (date1: any, date2: any) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const fillDatesWithEmptyData = (startDate: any, endDate: any) => {
    const data = [];
    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      data.push({ x: formatDate(d), y: null });
    }
    return data;
  };

  const handleSingleEntry = (entry: any, startDate: any, duration: any) => {
    const singleDataPointDate = new Date(entry.creationDate);
    const formattedDate = formatDate(singleDataPointDate);
    const formattedData = [{ x: formattedDate, y: entry.currentStage }];

    const endDate = new Date(singleDataPointDate);
    endDate.setDate(singleDataPointDate.getDate() + duration);
    for (
      let d = new Date(singleDataPointDate.getTime() + 86400000);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      const futureDate = formatDate(d);
      formattedData.push({ x: futureDate, y: null });
    }

    return formattedData;
  };

  const [chartData, setChartData] = React.useState([
    {
      data:
        selectedLeadData?.overViewData &&
        transformData(
          selectedLeadData?.overViewData?.stateChangeGraph,
          leadGraphDuration
        ),
      borderColor: "#11C89B",
      backgroundColor: "#11C89B ",
    },
  ]);

  const labels = props?.labels ? props.labels : [];

  const data = {
    labels,
    datasets: chartData,
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        fullSize: false,
        display: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
        maxWidth: 150,
      },
      title: {
        display: false,
        text: "Activity Growth",
        align: "start",
        color: "#142C44",
        font: {
          size: 16,
          weight: "500",
          family: "Poppins",
        },
      },
    },
    scales: {
      y: {
        type: "category",
        labels: stages,
        position: "left",
        reverse: true,
      },
      x: {
        type: "category",
        position: "bottom",
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        cubicInterpolationMode: () => "monotone",
      },
    },
  };

  const handleChange = useCallback(
    (event: any) => {
      setLeadGraphDuration(event.target.value);
      const newData =
        selectedLeadData &&
        transformData(
          selectedLeadData?.overViewData?.stateChangeGraph,
          event.target.value
        );
      setChartData([
        { data: newData, borderColor: "#11C89B", backgroundColor: "#11C89B" },
      ]);
    },
    [selectedLeadData]
  );

  useEffect(() => {
    const updateData = () => {
      const newData =
        selectedLeadData &&
        transformData(
          selectedLeadData?.overViewData?.stateChangeGraph,
          leadGraphDuration
        );
      setChartData([
        { data: newData, borderColor: "#11C89B", backgroundColor: "#11C89B" },
      ]);
    };

    updateData();
  }, [leadGraphDuration, selectedLeadData]);

  return (
    <div style={{ maxHeight: "100%" }}>
      <div className={styles.leadGraphHead}>
        <div className={styles.leadGraphTitle}>Lead Journey</div>
        <div className={styles.leadGraphSelect}>
          <FormControl size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={leadGraphDuration}
              onChange={handleChange}
              sx={{
                width: "128px",
                "& .MuiSelect-outlined": {
                  fontSize: "14px",
                  color: "#667B8B",
                  fontFamily: "poppins",
                },
              }}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                    borderRadius: "4px",
                    border: "1px solid  #E4E4E7",
                    fontSize: "13PX",
                  },
                },
                style: {
                  zIndex: 999999,
                  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                  border: "1px solid  #E4E4E7",
                  fontSize: "13PX",
                },
              }}
            >
              <MenuItem value={15} sx={{ fontSize: "13PX", color: "667085" }}>
                15 days
              </MenuItem>
              <MenuItem value={30} sx={{ fontSize: "13PX", color: "667085" }}>
                30 days
              </MenuItem>
              <MenuItem value={60} sx={{ fontSize: "13PX", color: "667085" }}>
                60 days
              </MenuItem>
              <MenuItem value={90} sx={{ fontSize: "13PX", color: "667085" }}>
                90 days
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div
        style={{
          maxHeight: "calc(308px - 72px)",
          minHeight: "calc(308px - 72px)",
        }}
      >
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

export default LeadGraph;
