import { Avatar, Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import styles from "./LeadSession.module.css";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import { getCombinedAssignees } from "../../../../../../components/CommonFunctions/CommonFunctions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function AttendanceCofirmPopup(props: any) {
  const {
    open,
    onClose,
    noBtnClick,
    yesBtnClick,
    loader,
    leadData,
    setCommentDetails,
    commentDetails,
    editScheduleData,
  } = props;

  const employees = useSelector((state: any) => state.staff.staffList);

  const combinedEmployees = getCombinedAssignees(employees, leadData?.branchId);

  const activeEmployees = combinedEmployees?.filter((item: any) => {
    const isActiveAndHasLeadCardAccess =
      item.status === "ACTIVE" && item?.leadCardAccess === true;

    return isActiveAndHasLeadCardAccess;
  });
  const modifiedActiveEmployees = activeEmployees?.map((employee: any) => ({
    id: employee.id, // Unique identifier
    display: `${employee.firstName} ${employee.lastName}`,
  }));

  function getNameById(id: any) {
    const employee = activeEmployees?.find((e: any) => e.id === id);

    return employee
      ? `${
          employee.firstName.charAt(0).toUpperCase() +
          employee.firstName.slice(1)
        } ${
          employee.lastName.charAt(0).toUpperCase() + employee.lastName.slice(1)
        }`
      : "Unknown";
  }

  const handleChange = React.useCallback(
    (event: any) => {
      setCommentDetails({
        ...commentDetails,
        description: event.target.value,
      });
    },
    [commentDetails]
  );

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div onClick={onClose} className={styles.closeModalButton}>
              <CloseIcon />
            </div>
            <div className={styles.modalTitle}>{"Mark attendance"}</div>
            <div className={styles.modalDesc}>Add comment</div>
            <div
              style={{
                padding: "10px 0px 10px 0",
                position: "relative",
              }}
            >
              <MentionsInput
                value={commentDetails.description}
                onChange={handleChange}
                style={{
                  control: {
                    backgroundColor: "#fff",
                    border: "1px solid #E8E8E8",
                    fontSize: "14px",
                    color: "#69696B",
                    height: "auto",
                    minHeight: "79px",
                    maxHeight: "150px",
                    overflow: "auto",
                    borderRadius: "4px",
                    padding: "10px 10px",
                  },
                  highlighter: {
                    overflow: "hidden",
                  },
                  input: {
                    margin: 0,
                    border: "none",
                    top: "10px",
                    left: "10px",
                    bottom: "10px",
                    width: "calc(100% - 22px)",
                    height: "auto",
                    outline: "none",
                  },
                  "&singleLine": {
                    margin: 0,
                  },
                  suggestions: {
                    width: "222px",
                    zIndex: 2,
                    overflow: "auto",
                    maxHeight: "140px",
                    backgroundColor: "#fff",
                    border: "1px solid #F0F0F0",
                    borderRadius: "6px",
                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
                    fontSize: "14px",
                    position: "absolute",
                    padding: "10px",
                    color: "#142C44",
                    fontWeight: "500",
                    lineHeight: "16px",
                    top:
                      commentDetails.description?.toString()?.split("\n")
                        ?.length <= 1
                        ? 0
                        : commentDetails.description?.toString()?.split("\n")
                            ?.length === 2
                        ? "10%"
                        : commentDetails.description?.toString()?.split("\n")
                            ?.length === 3
                        ? "30%"
                        : commentDetails.description?.toString()?.split("\n")
                            ?.length === 4
                        ? "40%"
                        : commentDetails.description?.toString()?.split("\n")
                            ?.length === 5
                        ? "50%"
                        : commentDetails.description?.toString()?.split("\n")
                            ?.length === 6
                        ? "60%"
                        : "100%",
                  },
                }}
              >
                <Mention
                  data={modifiedActiveEmployees}
                  trigger="@"
                  markup="@{{__id__}}"
                  style={{
                    backgroundColor: "#F5F5F7",
                    padding: "5px",
                    marginRight: "5px",
                    borderRadius: "20px",
                  }}
                  displayTransform={(id: any, firstName: any) => {
                    return "@" + getNameById(id);
                  }}
                  onAdd={(id: any) => {
                    setCommentDetails((prevDetails: any) => {
                      if (!prevDetails?.mentionedIds?.includes(id)) {
                        return {
                          ...prevDetails,
                          mentionedIds: [...prevDetails?.mentionedIds, id],
                        };
                      }
                      return prevDetails;
                    });
                  }}
                  renderSuggestion={(suggestion: any) => (
                    <div
                      style={{
                        paddingBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: "34px",
                          height: "34px",
                          fontSize: "16px",
                          alignItems: "center",
                          textTransform: "capitalize",
                          fontWeight: "500",
                          background: "#F5F5F7",
                          border: "1px solid #EEEEF4",
                          color: "#667085",
                        }}
                      >
                        <Box>{suggestion?.display?.charAt(0)}</Box>
                      </Avatar>
                      {suggestion?.display}
                    </div>
                  )}
                />
              </MentionsInput>
            </div>

            <div className={styles.activityArea}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <div className={styles.buttonSection}>
                  <CancelBtn onClick={noBtnClick} buttonText={"Cancel"} />
                  <SaveBtn
                    onClick={yesBtnClick}
                    disabled={loader}
                    loader={loader}
                    buttonText={"Save"}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AttendanceCofirmPopup;
