import React from "react";
import editIcon from "../../assets/images/edit-icon.svg";

function EditIconBtn(props: any) {
  const { disabled, onClick, maxWidth, icon } = props;
  return (
    <>
      <button
        style={{
          border: "none",
          background: "transparent",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          maxWidth: maxWidth ? maxWidth : "26PX",
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <img
          src={icon ? icon : editIcon}
          alt="edit"
          style={{ maxWidth: "100%" }}
        />
      </button>
    </>
  );
}

export default EditIconBtn;
