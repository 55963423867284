import styles from "./RecordPayment.module.css";
import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Receipt from "../../../../../components/Receipt/Receipt";
import { useAuthUser } from "react-auth-kit";
import AddIcon from "@mui/icons-material/Add";
import ViewInvoice from "../../../../../components/ViewInvoice/ViewInvoice";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";

function PaymentRecordPopup(props: any) {
  const {
		RecordPayment,
		leadData,
		discountMetric,
		discount,
		finalPrice,
		basePrice,
		balanceAmountToBePaid,
		amountToBePaid,
		emiId,
		isDownPayment,
		paymentType,
		finalBatchPrice,
		totalPaidAmount,
		autoDebitRaised,
		remainingEmiAmount,
		selectedCurrency,
	} = props;
	const auth = useAuthUser();
	const authUser = auth();
	const { userRoleById, userById } = useSelector(
		(state: any) => state.userDetails
	);
	const hasLeadAccess = getSubDomainAccessStatus(
		'NURTURE_BOARD',
		'LEAD_CARD',
		'ALL_ACCESS',
		// userRoleById?.permissions
		userById?.rolePermission
	);
	const { createPaymentLoading, createPaymentSuccess } = useSelector(
		(state: any) => state.nurture
	);

	const paymentList = useSelector(
		(state: any) => state.nurture.leadPaymentList
	);
	const instituteFromRedux = useSelector(
		(state: any) => state.institute.instituteDetails
	);

	const [paymentDetails, setPaymentDetails] = React.useState({
		enquiryId: '',
		basePrice: '',
		discountMetric: discountMetric,
		discount: discount,
		amountReceived: 0,
		balance: 0,
		description: '',
		gstNumber: '',
		gstCompanyDetails: '',
		paymentDate: '',
		isFirstPayment: true,
		paymentSource: '',
		createdByName:
			authUser?.institute[0]?.firstName +
			' ' +
			authUser?.institute[0]?.lastName,
		createdById: authUser?.institute[0]?.id,
		sendReceipt: false,
	});

	const [open, setOpen] = useState<boolean>(false);
	const [emiAmountError, setEmiAmountError] = useState<string>('');
	const [descriptionError, setDescriptionError] = useState<string>('');
	const [paymentSourceError, setPaymentSourceError] = useState<string>('');
	const [enteredAmount, setEnteredAmount] = React.useState<number>(0);
	const [checkedSendReceipt, setCheckedSendreceipt] = useState<boolean>(false);
	const [showReceipt, setShowReceipt] = useState<boolean>(false);
	const [showInvoice, setShowInvoice] = useState<boolean>(false);

	const balanceAmount =
		paymentType === 'MEMBERSHIP'
			? remainingEmiAmount
			: parseFloat((balanceAmountToBePaid - enteredAmount).toFixed(2));

	const openPaymentRecordPopup = () => {
		setOpen(true);
	};

	const handleAmountChange = (event: any) => {
		const enteredAmount = parseFloat(event.target.value);
		setEnteredAmount(enteredAmount);
		if (enteredAmount < 0) {
			setEmiAmountError('Amount Received cannot be negative');
		} else if (enteredAmount === 0) {
			setEmiAmountError('Please enter a number greater than zero');
		} else if (
			enteredAmount >
			(paymentType === 'MEMBERSHIP'
				? remainingEmiAmount
				: balanceAmountToBePaid)
		) {
			setEmiAmountError('Entered amount exceeds the available balance');
		} else {
			setEmiAmountError('');
		}
	};

	const handleChangePaymentSource = React.useCallback(
		(event: any) => {
			setPaymentDetails({
				...paymentDetails,
				paymentSource: event.target.value,
			});
			setPaymentSourceError('');
		},
		[paymentDetails]
	);

	const handleChange = React.useCallback(
		(event: any) => {
			setPaymentDetails({
				...paymentDetails,
				[event.target.id]: event.target.value,
			});
			// if (event.target.id === "description") {
			//   if (event.target.value === "") {
			//     setDescriptionError("Description is required");
			//   } else {
			//     setDescriptionError("");
			//   }
			// }
		},
		[paymentDetails]
	);

	const handleSendReceiptCheckboxChange = (event: any) => {
		setCheckedSendreceipt(event.target.checked);
		setPaymentDetails({
			...paymentDetails,
			sendReceipt: event.target.checked,
		});
	};

	const handleClick = () => {
		setShowReceipt(!showReceipt);
	};

	const handleInvoiceClick = () => {
		setShowInvoice(!showInvoice);
	};

	const closePaymentRecordPopup = () => {
		setOpen(false);
		setDescriptionError('');
		setEmiAmountError('');
		setPaymentSourceError('');
		setPaymentDetails({
			...paymentDetails,
			enquiryId: '',
			basePrice: '',
			balance: 0,
			isFirstPayment: paymentList?.length === 0 ? true : false,
			description: '',
			paymentSource: '',
		});
	};

	const handleSaveClick = async (event: any) => {
		event.preventDefault();

		if (!enteredAmount) {
			setEmiAmountError('Field is required');
			return;
		}

		if (enteredAmount < 0) {
			setEmiAmountError('Amount Received cannot be negative');
			return;
		}

		if (enteredAmount === 0) {
			setEmiAmountError('Please enter a number greater than zero');
			return;
		}

		if (paymentDetails.paymentSource === '') {
			setPaymentSourceError('Field is required');
			return;
		}

		// if (!paymentDetails.description.trim()) {
		//   setDescriptionError("Description is required");
		//   return;
		// }

		const currentTimestamp = moment().valueOf();

		const updatedPaymentDetails = {
			...paymentDetails,
			amountReceived: enteredAmount,
			balance: paymentType === 'MEMBERSHIP' ? 0 : balanceAmount?.toFixed(2),
			paymentDate: currentTimestamp,
		};

		try {
			await RecordPayment({
				...updatedPaymentDetails,
				emiId: emiId,
				paymentType: paymentType,
				paymentStatus: 'PAID',
			});
		} catch (error) {
			// Handle the error
		}
	};

	React.useEffect(() => {
		if (leadData) {
			setPaymentDetails({
				...paymentDetails,
				enquiryId: leadData.id,
				basePrice: basePrice,
				balance: balanceAmount,
				isFirstPayment: paymentList?.length === 0 ? true : false,
			});
		}
	}, [
		leadData,
		setPaymentDetails,
		finalPrice,
		paymentList,
		basePrice,
		balanceAmountToBePaid,
		enteredAmount,
		open,
	]);
	React.useEffect(() => {
		if (amountToBePaid) {
			const amount =
				typeof amountToBePaid === 'string'
					? parseFloat(amountToBePaid)
					: amountToBePaid;

			setEnteredAmount(amount);
		}
	}, [amountToBePaid, open]);

	React.useEffect(() => {
		if (createPaymentSuccess !== true && createPaymentSuccess !== false) {
			setOpen(false);
			closePaymentRecordPopup();
		}
	}, [createPaymentSuccess]);

	return (
		<>
			<Tooltip
				arrow
				title={
					hasLeadAccess === false
						? 'Oops! No permission.'
						: autoDebitRaised === true
						? 'Auto debit raised'
						: ''
				}
				PopperProps={{ style: { zIndex: 9999999 } }}
				componentsProps={{
					tooltip: {
						sx: {
							bgcolor: '#101828',
							color: '#fff',
							fontSize: '12px',
							fontWeight: '500',
							padding: '8px 12px',
							zIndex: '9999999',
							'& .MuiTooltip-arrow': {
								color: '#101828',
								zIndex: '9999999',
							},
						},
					},
				}}>
				<span>
					<Button
						color='primary'
						variant='contained'
						sx={{
							fontSize: '13px',
							fontWeight: '500',
							textTransform: 'capitalize',
							boxShadow: 'none',
							lineHeight: '24px',
							padding: '7px 12px',
							borderRadius: '4PX',
						}}
						size={'small'}
						onClick={openPaymentRecordPopup}
						disabled={
							hasLeadAccess === false ? true : false || autoDebitRaised
						}>
						<AddIcon sx={{ marginRight: '10px', fontSize: '18px' }} />
						Record Payment
					</Button>
				</span>
			</Tooltip>

			<Dialog
				open={open}
				onClose={closePaymentRecordPopup}
				sx={{
					zIndex: '9999999',
					minWidth: '408px',
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '400px',
						maxHeight: '535PX',
						borderRadius: '12px',
						boxShadow:
							'0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
						left: 0,
						background: '#FFFFFF',
						overflow: 'hidden',
						padding: '0px',
					},
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}>
					<div
						className={styles.recordPaymentForm}
						style={{ padding: '28px 28px 20px 28px' }}>
						<div className={styles.cross_button}>
							<Button
								sx={{
									color: '#667085',
									padding: '0px',
									display: 'flex',
									width: 'fit-content',
									minWidth: '0px',
								}}
								onClick={() => closePaymentRecordPopup()}>
								<CloseOutlinedIcon />
							</Button>
						</div>
						<div className={styles.formHeading}>Record Payment</div>
						<form action=''>
							<div
								style={{
									maxHeight: '370px',
									overflowY: 'auto',
									paddingRight: '10px',
								}}
								className={styles.recordPayWrap}>
								<FormControl sx={{ m: 1, margin: '6px 0', width: '100%' }}>
									<Typography
										sx={{
											color: '#142C44',
											fontSize: '14px',
											fontWeight: '400',
											lineHeight: '18px',
											marginBottom: '6px',
										}}>
										Amount Received <span style={{ color: '#EC3939' }}>*</span>
									</Typography>
									<TextField
										type='number'
										id='amountReceived'
										required
										placeholder='₹'
										variant='outlined'
										onChange={handleAmountChange}
										error={!!emiAmountError}
										helperText={emiAmountError || ''}
										inputProps={{ min: '0' }}
										sx={{
											'& .MuiInputLabel-root': {
												color: '#B1B1B1',
												fontWeight: '300',
												fontSize: '12px',
											},
											'& .MuiOutlinedInput-input': {
												padding: '10px 16px',
											},
										}}
										value={enteredAmount}
										disabled={isDownPayment === true ? true : false}
									/>
								</FormControl>

								{paymentType !== 'MEMBERSHIP' && (
									<FormControl sx={{ m: 1, margin: '6px 0', width: '100%' }}>
										<Typography
											sx={{
												color: '#142C44',
												fontSize: '14px',
												fontWeight: '400',
												lineHeight: '18px',
												marginBottom: '6px',
											}}>
											Balance
										</Typography>
										<TextField
											required
											value={paymentDetails?.balance}
											placeholder='₹ 5,000.00'
											id='balance'
											variant='outlined'
											disabled
											sx={{
												'& .MuiInputLabel-root': {
													color: '#B1B1B1',
													fontWeight: '300',
													fontSize: '12px',
												},
												'& .MuiOutlinedInput-input': {
													padding: '10px 16px',
												},
											}}
										/>
									</FormControl>
								)}

								<FormControl sx={{ m: 1, margin: '6px 0', width: '100%' }}>
									<Typography
										sx={{
											color: '#142C44',
											fontSize: '14px',
											fontWeight: '400',
											lineHeight: '18px',
											marginBottom: '6px',
										}}>
										Payment source <span style={{ color: '#EC3939' }}>*</span>
									</Typography>
									<Select
										MenuProps={{
											style: {
												zIndex: 9999999,
												maxHeight: 400,
											},
											PaperProps: {
												style: {
													boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.06)',
												},
											},
										}}
										placeholder='Select'
										variant='outlined'
										id='paymentSource'
										value={paymentDetails.paymentSource}
										onChange={handleChangePaymentSource}
										error={!!paymentSourceError}
										sx={{
											'& .MuiOutlinedInput-input': {
												padding: '10px 16px',
											},
										}}>
										<MenuItem
											sx={{
												fontSize: '13PX',
												color: '#18181B',
											}}
											value='Cash'>
											Cash
										</MenuItem>
										<MenuItem
											sx={{
												fontSize: '13PX',
												color: '#18181B',
											}}
											value='POS'>
											POS
										</MenuItem>
										<MenuItem
											sx={{
												fontSize: '13PX',
												color: '#18181B',
											}}
											value='UPI'>
											UPI
										</MenuItem>
									</Select>
									{paymentSourceError && (
										<FormHelperText error> {paymentSourceError}</FormHelperText>
									)}
								</FormControl>
								<FormControl sx={{ m: 1, margin: '6px 0', width: '100%' }}>
									<Typography
										sx={{
											color: '#142C44',
											fontSize: '14px',
											fontWeight: '400',
											lineHeight: '18px',
											marginBottom: '6px',
										}}>
										Description
									</Typography>
									<TextField
										placeholder='Enter here'
										id='description'
										multiline
										variant='outlined'
										onChange={handleChange}
										inputProps={{ maxLength: 100 }}
										error={!!descriptionError}
										helperText={descriptionError || ''}
										sx={{
											'& .MuiInputLabel-root': {
												color: '#B1B1B1',
												fontWeight: '300',
												fontSize: '12px',
											},
											'& .MuiOutlinedInput-root': {
												padding: '14px 10px 58px 14px',
											},
										}}
									/>
								</FormControl>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{enteredAmount !== 0 && (
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
											}}>
											<FormControlLabel
												control={
													<Checkbox
														size='small'
														checked={checkedSendReceipt}
														onChange={handleSendReceiptCheckboxChange}
														sx={{
															strokeWidth: 1,
															color: '#D0D5DD',
															'&:hover': {
																backgroundColor: 'transparent',
															},
															'&:focus': {
																backgroundColor: 'transparent',
															},
														}}
													/>
												}
												label={
													<Typography
														style={{ color: '#667085', fontSize: '14px' }}>
														{instituteFromRedux?.isStudentTaxInvoiceEnabled
															? 'Send Invoice'
															: 'Send Receipt'}
													</Typography>
												}
											/>
											{checkedSendReceipt && (
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<div
														style={{
															color: '#11C89B',
															fontSize: '14px',
															fontWeight: '400',
															lineHeight: '20px',
															cursor: 'pointer',
														}}
														onClick={
															instituteFromRedux?.isStudentTaxInvoiceEnabled
																? handleInvoiceClick
																: handleClick
														}>
														{instituteFromRedux?.isStudentTaxInvoiceEnabled
															? 'View Invoice'
															: 'View Receipt'}
													</div>
												</div>
											)}
											{showReceipt && (
												<Receipt
													showReceipt={showReceipt}
													setShowReceipt={setShowReceipt}
													paymentDetails={paymentDetails}
													enteredAmount={enteredAmount}
													leadById={leadData}
													finalPrice={finalBatchPrice}
													totalPaidAmount={totalPaidAmount}
													selectedCurrency={selectedCurrency}
												/>
											)}
											{showInvoice && (
												<ViewInvoice
													showInvoice={showInvoice}
													setShowInvoice={setShowInvoice}
													paymentDetails={paymentDetails}
													enteredAmount={enteredAmount}
													leadById={leadData}
													finalPrice={finalBatchPrice}
													totalPaidAmount={totalPaidAmount}
													selectedCurrency={selectedCurrency}
												/>
											)}
										</div>
									)}
								</div>
							</div>
						</form>
					</div>
					<div className={styles.buttonSave}>
						<CancelBtn onClick={() => closePaymentRecordPopup()} />
						<SaveBtn
							onClick={handleSaveClick}
							disabled={createPaymentLoading || emiAmountError !== ''}
							loader={createPaymentLoading}
						/>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default PaymentRecordPopup;
