import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import { SavingImportDTO, SavingImportedData } from "../service/types";
import { getAllCourseByInstituteRequest } from "../../Manage/ManageCourse/service/courseSlice";
import ImportPreview from "../components/ImportPreview/ImportPreview";
import { saveImportedFile } from "../service/importSlice";
import { getContactList } from "../service/inboundSlice";

interface StateProps extends SavingImportDTO {}

interface DispatchProps {
  GetCourseList(id: string): void;
  SaveImportedFile(data: SavingImportedData): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    saveImportedDataSuccess: state.importData.saveImportedDataSuccess,
    saveImportedDataLoading: state.importData.saveImportedDataLoading,
    saveImportedDataError: state.importData.saveImportedDataError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    SaveImportedFile: (data: SavingImportedData) => {
      dispatch(saveImportedFile(data));
    },
  };
};
export interface ImportPreviewProps extends StateProps, DispatchProps {}
export const ImportPreviewComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportPreview);
