import React, { useEffect } from "react";
import styles from "./Whatsapp.module.css";
import gupshuplogo from "./assets/gupshuplogo.svg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Button } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BasicModal from "../../../../../components/Modal/Modal";
import { Box, Typography, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import partnerIdOne from "./assets/partnerIdOne.jpg";
import partnerIdTwo from "./assets/partnerIdTwo.jpg";
import callbackurl from "./assets/callbackurl.jpg";
import appid from "./assets/appid.jpg";
import appname from "./assets/appname.jpg";
import apikey from "./assets/apikey.jpg";
import { useAuthUser } from "react-auth-kit";

function GupshupConfigComponent(props: any) {
  const {
    GetWhatsappConfig,
    gupshupConfig,
    SaveWhatsappConfiguration,
    setShowConfigComponent,
    setCopied,
    whatsAppConfig,
    setWhatsAppConfig,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();

  const [openInfoModal, setOpenInfoModal] = React.useState(false);
  const [infoData, setInfoData] = React.useState<any>();

  useEffect(() => {
    GetWhatsappConfig();
  }, []);

  const handleApiKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newApiKey = event.target.value;
    setWhatsAppConfig((prevConfig: any) => ({
      ...prevConfig,
      apiKey: newApiKey,
    }));
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPhoneNumber = event.target.value.replace(/\D/g, "");
    setWhatsAppConfig((prevConfig: any) => ({
      ...prevConfig,
      phoneNumber: newPhoneNumber,
    }));
  };

  const handleAppNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAppName = event.target.value;
    setWhatsAppConfig((prevConfig: any) => ({
      ...prevConfig,
      appName: newAppName,
    }));
  };

  const handleAppKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAppId = event.target.value;
    setWhatsAppConfig((prevConfig: any) => ({
      ...prevConfig,
      appId: newAppId,
    }));
  };

  const handleContinueClick = () => {
    SaveWhatsappConfiguration({
      ...whatsAppConfig,
      createdByName:
        authUser?.institute[0].firstName +
        " " +
        authUser?.institute[0].lastName,
      createdById: authUser?.institute[0]?.id,
    });
  };

  const partnerIdInfo = () => {
    return (
      <>
        <Box className={styles.askAQuestionHeader}>
          <Typography sx={{ color: "#142C44", fontSize: "18px" }}>
            Not sure what to do with Partner Id?
          </Typography>
          <CloseIcon
            onClick={() => {
              setOpenInfoModal(false);
              setInfoData(null);
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <div className="step">
          <div
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: "#142C44",
              margin: "10px 0px",
            }}
          >
            Follow these steps:
          </div>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Login to your Gupshup Account.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Choose your app.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Go to Settings Tab.
          </li>
          <div>
            <img
              src={partnerIdOne}
              alt="Gupshup Logo"
              style={{ width: "100%", height: "250px" }}
            />
          </div>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Click on the Add Partner button.
          </li>
          <img
            src={partnerIdTwo}
            alt="Gupshup Logo"
            style={{ width: "100%", height: "250px" }}
          />
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Paste this Partner ID in the fields and Confirm.
          </li>
        </div>
      </>
    );
  };

  const callBackInfo = () => {
    return (
      <>
        <Box className={styles.askAQuestionHeader}>
          <Typography sx={{ color: "#142C44", fontSize: "18px" }}>
            Not sure what to do with Callback URL?
          </Typography>
          <CloseIcon
            onClick={() => {
              setOpenInfoModal(false);
              setInfoData(null);
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <div className="step">
          <div
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: "#142C44",
              margin: "10px 0px",
            }}
          >
            Follow these steps:
          </div>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Login to your Gupshup Account.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Choose your app.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Go to Webhooks Tab.
          </li>
          <img
            src={callbackurl}
            alt="Gupshup Logo"
            style={{ width: "100%", height: "250px" }}
          />
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Paste the Callback URL in the field.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Toggle the button against Callback URL section.
          </li>
        </div>
      </>
    );
  };

  const appIdInfo = () => {
    return (
      <>
        <Box className={styles.askAQuestionHeader}>
          <Typography sx={{ color: "#142C44", fontSize: "18px" }}>
            Not sure where to find App ID?
          </Typography>
          <CloseIcon
            onClick={() => {
              setOpenInfoModal(false);
              setInfoData(null);
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <div className="step">
          <div
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: "#142C44",
              margin: "10px 0px",
            }}
          >
            Follow these steps:
          </div>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Login to your Gupshup Account.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Choose your app.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Go to Settings Tab.
          </li>
          <img
            src={appid}
            alt="Gupshup Logo"
            style={{ width: "100%", height: "250px" }}
          />
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Copy the App ID and paste it here.
          </li>
        </div>
      </>
    );
  };

  const appNameInfo = () => {
    return (
      <>
        <Box className={styles.askAQuestionHeader}>
          <Typography sx={{ color: "#142C44", fontSize: "18px" }}>
            Not sure where to find App Name?
          </Typography>
          <CloseIcon
            onClick={() => {
              setOpenInfoModal(false);
              setInfoData(null);
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <div className="step">
          <div
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: "#142C44",
              margin: "10px 0px",
            }}
          >
            Follow these steps:
          </div>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Login to your Gupshup Account.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Choose your app.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Go to Settings Tab.
          </li>
          <img
            src={appname}
            alt="Gupshup Logo"
            style={{ width: "100%", height: "250px" }}
          />
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Copy the App Name and paste it here.
          </li>
        </div>
      </>
    );
  };
  const apiKeyInfo = () => {
    return (
      <>
        <Box className={styles.askAQuestionHeader}>
          <Typography sx={{ color: "#142C44", fontSize: "18px" }}>
            Not sure where to find Api Key?
          </Typography>
          <CloseIcon
            onClick={() => {
              setOpenInfoModal(false);
              setInfoData(null);
            }}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <div className="step">
          <div
            style={{
              fontSize: "15px",
              fontWeight: "600",
              color: "#142C44",
              margin: "10px 0px",
            }}
          >
            Follow these steps:
          </div>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Login to your Gupshup Account.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Choose your app.
          </li>
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            Go to Settings Tab.
          </li>
          <img
            src={apikey}
            alt="Gupshup Logo"
            style={{ width: "100%", height: "250px" }}
          />
          <li
            style={{
              fontSize: "13px",
              fontWeight: "400",
              color: "#667085",
              margin: "10px 0px",
            }}
          >
            From the App Key section,copy the API Key and paste it here.
          </li>
        </div>
      </>
    );
  };

  // const errorPhoneMessage =
  //   whatsAppConfig?.phoneNumber?.length !== 12 ||
  //   whatsAppConfig?.phoneNumber === "" ? (
  //     <p style={{ color: "red" }}>Phone Number required</p>
  //   ) : (
  //     ""
  //   );

  const errorAppNameMessage =
    whatsAppConfig?.appName === "" ? (
      <p style={{ color: "red" }}>App Name required</p>
    ) : (
      ""
    );

  const errorAppIdMessage =
    whatsAppConfig?.appId === "" ? (
      <p style={{ color: "red" }}>App Id required</p>
    ) : (
      ""
    );

  const errorApiKeyMessage =
    whatsAppConfig?.apiKey === "" ? (
      <p style={{ color: "red" }}>Api Key required</p>
    ) : (
      ""
    );

  return (
    <div className={styles.gupshupContainer}>
      <div style={{ width: "60%" }}>
        <div
          className={styles.leftPanelHeader}
          onClick={() => {
            setShowConfigComponent(false);
          }}
        >
          <div>
            <KeyboardBackspaceIcon />
          </div>
          <div style={{ marginLeft: "5px" }}>Back</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // marginLeft: "200px",
            alignItems: "center",
          }}
        >
          <div className={styles.logoSection}>
            <img
              src={gupshuplogo}
              alt="Gupshup Logo"
              style={{ width: "100px" }}
            />
            <h2>WhatsApp Messaging by Gupshup</h2>
          </div>
          <div className={styles.inputSection}>
            <div
              className={styles.inputSectionFileds}
              style={{ marginBottom: "70px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "330px",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#667085",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  PartnerId{" "}
                  <HelpOutlineIcon
                    style={{
                      marginLeft: "5px",
                      color: "#2664DC",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenInfoModal(true);
                      setInfoData(partnerIdInfo);
                    }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  value={gupshupConfig.partnerId}
                  InputProps={{
                    endAdornment: (
                      <>
                        <CopyToClipboard
                          text={gupshupConfig.partnerId}
                          onCopy={() => setCopied(true)}
                        >
                          <IconButton
                            color="primary"
                            aria-label="copy to clipboard"
                            sx={{
                              border: "1px solid #F0F0F0",
                              fontSize: "15px",
                              borderRadius: "8px",
                              height: "30px",
                              color: "#667085",
                            }}
                          >
                            Copy
                          </IconButton>
                        </CopyToClipboard>
                      </>
                    ),
                  }}
                  sx={{ height: "15px" }}
                  disabled={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "330px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#667085",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  Callback Url{" "}
                  <HelpOutlineIcon
                    style={{
                      marginLeft: "5px",
                      color: "#2664DC",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      setOpenInfoModal(true);
                      setInfoData(callBackInfo);
                    }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  value={gupshupConfig.webhookURL}
                  InputProps={{
                    endAdornment: (
                      <CopyToClipboard
                        text={gupshupConfig.webhookURL}
                        onCopy={() => setCopied(true)}
                      >
                        <IconButton
                          color="primary"
                          aria-label="copy to clipboard"
                          sx={{
                            border: "1px solid #F0F0F0",
                            fontSize: "15px",
                            borderRadius: "8px",
                            height: "30px",
                            color: "#667085",
                          }}
                        >
                          Copy
                        </IconButton>
                      </CopyToClipboard>
                    ),
                  }}
                  sx={{ height: "15px" }}
                  disabled={true}
                />
              </div>
            </div>
            {/* <div
              className={styles.inputSectionFileds}
              style={{ marginBottom: "80px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "330px",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#667085",
                    fontSize: "15px",
                  }}
                >
                  Phone Number{" "}
                </div>
                <TextField
                  variant="outlined"
                  value={whatsAppConfig?.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  sx={{ height: "15px" }}
                  placeholder="Enter Phone Number"
                  helperText={errorPhoneMessage}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 12,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "330px",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#667085",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  App Name{" "}
                  <HelpOutlineIcon
                    style={{
                      marginLeft: "5px",
                      color: "#2664DC",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenInfoModal(true);
                      setInfoData(appNameInfo);
                    }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  value={whatsAppConfig.appName}
                  onChange={handleAppNameChange}
                  sx={{ height: "15px" }}
                  placeholder="Enter App Name"
                  helperText={errorAppNameMessage}
                />
              </div>
            </div> */}
            <div
              className={styles.inputSectionFileds}
              style={{ marginBottom: "80px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "330px",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#667085",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  App Id{" "}
                  <HelpOutlineIcon
                    style={{
                      marginLeft: "5px",
                      color: "#2664DC",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenInfoModal(true);
                      setInfoData(appIdInfo);
                    }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  value={whatsAppConfig?.appId}
                  onChange={handleAppKeyChange}
                  sx={{
                    height: "15px",
                  }}
                  placeholder="Enter App ID"
                  helperText={errorAppIdMessage}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "330px",
                }}
              >
                <div
                  style={{
                    marginBottom: "10px",
                    color: "#667085",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Api Key{" "}
                  <HelpOutlineIcon
                    style={{
                      marginLeft: "5px",
                      color: "#2664DC",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenInfoModal(true);
                      setInfoData(apiKeyInfo);
                    }}
                  />
                </div>
                <TextField
                  variant="outlined"
                  value={whatsAppConfig?.apiKey}
                  onChange={handleApiKeyChange}
                  sx={{ height: "15px" }}
                  placeholder="Paste Key here"
                  helperText={errorApiKeyMessage}
                />
              </div>
            </div>
            <div className={styles.inputSubmitButton}>
              <Button
                sx={{
                  width: "100%",
                  padding: "8px 20px",
                }}
                variant="contained"
                type="submit"
                onClick={handleContinueClick}
                disabled={errorApiKeyMessage !== "" || errorAppIdMessage !== ""}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "35%" }}>
        <div style={{ fontSize: "20px" }}>Setup Guide</div>
        <p style={{ fontSize: "13px", fontWeight: "500", color: "#142C44" }}>
          Login to your Gupshup Account.
        </p>
        <p style={{ fontSize: "13px", fontWeight: "500", color: "#142C44" }}>
          Choose your App
        </p>
        <div className="step">
          <div
            style={{ fontSize: "15px", fontWeight: "500", color: "#142C44" }}
          >
            Step 1: Partner ID
          </div>
          <ul>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Go to Settings Tab.
            </li>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Click on the Add Partner button.
            </li>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Paste this Partner ID in the fields and Confirm.
            </li>
          </ul>
        </div>
        <div className="step">
          <div
            style={{ fontSize: "15px", fontWeight: "500", color: "#142C44" }}
          >
            Step 2: Callback URL
          </div>
          <ul>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Go to Webhooks Tab.
            </li>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Paste the Callback URL in the field.
            </li>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Toggle the button against Callback URL section.
            </li>
          </ul>
        </div>
        {/* <div className="step">
          <div
            style={{ fontSize: "15px", fontWeight: "500", color: "#142C44" }}
          >
            Step 3: Phone Number
          </div>
          <ul>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Provide your registered WhatsApp Business API Phone number.
            </li>
          </ul>
        </div>
        <div className="step">
          <div
            style={{ fontSize: "15px", fontWeight: "500", color: "#142C44" }}
          >
            Step 4: App Name
          </div>
          <ul>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Go to Settings Tab.
            </li>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Copy the App Name and Paste it here.
            </li>
          </ul>
        </div> */}
        <div className="step">
          <div
            style={{ fontSize: "15px", fontWeight: "500", color: "#142C44" }}
          >
            Step 3: App ID
          </div>
          <ul>
            <li
              style={{
                fontSize: "13px",
                fontWeight: "400",
                color: "#667085",
              }}
            >
              Go to Settings Tab.
            </li>
            <li
              style={{
                fontSize: "13px",
                fontWeight: "400",
                color: "#667085",
              }}
            >
              Copy the App ID and Paste it here.
            </li>
          </ul>
        </div>
        <div className="step">
          <div
            style={{ fontSize: "15px", fontWeight: "500", color: "#142C44" }}
          >
            Step 4: App API Key
          </div>
          <ul>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Go to Settings Tab.
            </li>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              From the App API Key section, copy the API Key.
            </li>
            <li
              style={{ fontSize: "13px", fontWeight: "400", color: "#667085" }}
            >
              Paste the API Key in this page and click Continue.
            </li>
          </ul>
        </div>
        <a href="#">Click here</a> to know more about Gupshup.
      </div>
      <BasicModal
        body={infoData}
        isModalOpen={openInfoModal}
        setIsModalOpen={setOpenInfoModal}
        width={736}
      />
    </div>
  );
}

export default GupshupConfigComponent;
