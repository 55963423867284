import React, { useCallback, useEffect } from "react";
import styles from "./DemoTaskDetailsPage.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import demoCalander from "../Assets/democalender.svg";
import demoCourse from "../Assets/demoCourse.svg";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import eyeIcon from "../Assets/eye.svg";
import moment from "moment";
import clockIcon from "../Assets/clock.svg";
import checkIcon from "../Assets/checkNormal.svg";
import checkIconGreen from "../Assets/checkGreen.svg";
import { useAuthUser } from "react-auth-kit";
import edit from "../Assets/edit.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetTaskUpdateSuccess } from "../../service/tasksSlice";
import checkComplete from "../Assets/checkComplete.svg";
import { setLeadModalState } from "../../../Nurture/service/nurtureSlice";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import getTeamMember from "../../../../../components/CommonFunctions/CommonFunctions";
import { useLocation } from "react-router-dom";

const progressArray = [
  {
    name: "to do",
    icon: false,
    value: "TO_DO",
  },
  {
    name: "in progress",
    icon: false,
    value: "IN_PROGRESS",
  },
  {
    name: "blocked",
    icon: false,
    value: "BLOCKED",
  },
  {
    name: "completed",
    icon: false,
    value: "COMPLETED",
  },
];

function DemoTaskDetailsPage(props: any) {
  const {
    GetTasksById,
    taskById,
    UpdateTaskStatus,
    GetLeadById,
    GetInboxByLeadId,
    GetLeadNoteList,
    GetLeadTaskList,
    GetLeadActivityList,
    GetLeadPaymentList,
    GetDemoCalanderByCourseAndInstitute,
    GetEmiDataOfLead,
    GetLeadListByInstituteAndContact,
    UpdateLead,
    UpdateLeadSwimlaneStatus,
    UpdateLeadEnquiyStatus,
    UpdateLeadEmiDetails,
    nurtureAddEnquiry,
    CreateNote,
    CreateTask,
    RecordPayment,
    CreateDemo,
    ComposeEmailSent,
    CreateBatch,
    SendPaymentLink,
    DownloadPaymentReceipt,
    EditTask,
    EditNote,
    EditDemo,
    DeleteNote,
    DeleteTask,
    DeleteDemoBookedForLead,
    EditRecordPayment,
    CallLead,
    GetAppsByInstitute,
    CreateSource,
    CallDispositionForm,
    GetStaffList,
    GetCourseList,
    GetBatchList,
    GetBranchList,
    GetNurtureLeadBoard,
    GetInstituteById,
    GetAllTasksType,
    CreateComment,
    DeleteComment,
    EditComment,
  } = props;
  const navigate = useNavigate();
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const location = useLocation();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const { taskStatusUpdateSuccess, taskStatusUpdateLoading, taskByIdLoading } =
    useSelector((state: any) => state.tasks);
  const employees = useSelector((state: any) => state.staff.staffList);

  const [leadSearchTerm, setLeadSearchTerm] = React.useState<string>("");
  const [demoLeads, setDemoLeads] = React.useState<any>([]);
  const [stage, setStage] = React.useState<string>("");
  const [editBtn, setEditBtn] = React.useState<boolean>(false);
  const [markAllAsAttended, setMarkAllAsAttended] = React.useState(false);

  const handleCheckBoxChange = () => {
    setMarkAllAsAttended((prev) => !prev);

    if (!markAllAsAttended) {
      const updatedLeads = demoLeads.map((lead: any) => ({
        ...lead,
        status: "COMPLETED",
      }));
      setDemoLeads(updatedLeads);
    } else {
      setDemoLeads(taskById?.demoData);
    }
  };

  const handleLeadSearch = (event: any) => {
    setLeadSearchTerm(event.target.value);
  };

  const filteredLeads = demoLeads?.filter((lead: any) => {
    const searchTermMatch =
      lead.studentFullName
        .toLowerCase()
        .indexOf(leadSearchTerm.toLowerCase()) !== -1;

    const phoneMatch =
      leadSearchTerm === "" || lead?.studentContact.includes(leadSearchTerm);

    return searchTermMatch || phoneMatch;
  });

  const handleAttendanceButtonClick = (demoId: string) => {
    const updatedLeads = demoLeads?.map((demo: any) => {
      if (demo.id === demoId) {
        return {
          ...demo,
          status: demo.status === "COMPLETED" ? "ABSENT" : "COMPLETED",
        };
      }
      return demo;
    });

    setDemoLeads(updatedLeads);
    const allCompleted = updatedLeads?.every(
      (demo: any) => demo.status === "COMPLETED"
    );
    setMarkAllAsAttended(allCompleted);
  };
  const currentTime = new Date();
  const filteredProgressArray = progressArray?.filter((taskStatus) =>
    taskById?.date && new Date(taskById?.date) > currentTime
      ? taskStatus.value !== "COMPLETED"
      : true
  );

  const handleEditBtn = React.useCallback(() => {
    setEditBtn(true);
  }, []);

  const handleChange = React.useCallback(
    (event: any) => {
      if (taskById) {
        setStage(event.target.value as string);
        if (event.target.value !== "COMPLETED") {
          const demoStudentUpdate = demoLeads?.map((item: any) => {
            return {
              demoStudentId: item.id,
              status: item?.status,
            };
          });
          if (demoStudentUpdate?.length > 0) {
            UpdateTaskStatus({
              taskId: taskById.id && taskById.id,
              taskStatus: event.target.value,
              updatedById: authUser?.institute?.[0]?.id,
              updatedByName: authUser?.firstName,
              demoStudentUpdate: demoStudentUpdate,
            });
          }
        }
      }
    },

    [setStage, taskById, UpdateTaskStatus, demoLeads]
  );

  const handleAttendanceSave = () => {
    const demoStudentUpdate = demoLeads?.map((item: any) => {
      const demoStatus = item?.status === "COMPLETED" ? "COMPLETED" : "ABSENT";
      return {
        demoStudentId: item.id,
        status: demoStatus,
      };
    });
    UpdateTaskStatus({
      taskId: taskById.id && taskById?.id,
      taskStatus: stage,
      updatedById: authUser?.institute?.[0]?.id,
      updatedByName: authUser?.firstName + " " + authUser?.lastName,
      demoStudentUpdate: demoStudentUpdate,
    });
  };

  const cancelAttendanceSave = () => {
    setEditBtn(false);
    if (taskById) {
      setStage(taskById.status);
      if (taskById?.demoData?.length > 0) {
        setDemoLeads(taskById?.demoData);
        const allCompleted = taskById?.demoData?.every(
          (demo: any) => demo.status === "COMPLETED"
        );
        setMarkAllAsAttended(allCompleted);
      }
    }
    const queryParams = new URLSearchParams(window.location.search);
    const taskOverdue = queryParams.get("taskOverdue");
    if (taskOverdue) {
      queryParams.delete("taskOverdue");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  };

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;

      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          GetLeadListByInstituteAndContact(data);
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          GetLeadListByInstituteAndContact(data);
        }
      }
    },
    [GetLeadListByInstituteAndContact]
  );

  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  const assigny = employees?.find(
    (item: any) => item?.id === taskById?.assignedToId
  );

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const taskId = pathname.substring(pathname.lastIndexOf("/") + 1);

    GetTasksById(taskId);
  }, [GetTasksById]);

  React.useEffect(() => {
    if (taskById) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const taskOverdue = urlSearchParams.get("taskOverdue");

      if (taskOverdue === "true") {
        setStage("COMPLETED");
      } else {
        setStage(taskById.status);
      }
      if (taskById?.demoData?.length > 0) {
        setDemoLeads(taskById?.demoData);
        const allCompleted = taskById?.demoData?.every(
          (demo: any) => demo.status === "COMPLETED"
        );
        setMarkAllAsAttended(allCompleted);
      }
    }
  }, [taskById]);

  useEffect(() => {
    const instituteFromLocalStorage = authUser?.institute[0];
    if (instituteFromLocalStorage) {
      GetInstituteById(instituteFromLocalStorage?.instituteId);
    }
  }, [GetStaffList]);

  React.useEffect(() => {
    if (taskStatusUpdateSuccess !== true && taskStatusUpdateSuccess !== false) {
      const pathname = window.location.pathname;
      const taskId = pathname.substring(pathname.lastIndexOf("/") + 1);
      if (taskId) {
        GetTasksById(taskId);
      }
      dispatch(resetTaskUpdateSuccess());
      setEditBtn(false);
      const queryParams = new URLSearchParams(window.location.search);
      const taskOverdue = queryParams.get("taskOverdue");
      if (taskOverdue) {
        queryParams.delete("taskOverdue");
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    }
  }, [taskStatusUpdateSuccess]);

  const goBack = () => {
    if (location?.state?.fromCompletedPageUrl) {
      navigate(`/app/Tasks${location?.state?.fromCompletedPageUrl}`);
    } else {
      navigate(`/app/Tasks?selectedUsers=${authUser?.institute?.[0]?.id}`);
    }
  };

  return (
    <div
      style={{
        padding: "14px 16px",
        backgroundColor: "#F5F5F5",
        overflowY: "hidden",
        minHeight: "calc(100vh - 65px)",
        maxHeight: "calc(100vh - 65px)",
        position: "relative",
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: "22px 0px 22px 30px",
          width: "100%",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          overflowY: "hidden",
          minHeight: "calc(100vh - 90px)",
          maxHeight: "calc(100vh - 90px)",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            overflowY: "auto",
            minHeight:
              (stage === "COMPLETED" && taskById?.status !== "COMPLETED") ||
              editBtn
                ? "calc(100vh - 215px)"
                : "calc(100vh - 140px)",
            maxHeight:
              (stage === "COMPLETED" && taskById?.status !== "COMPLETED") ||
              editBtn
                ? "calc(100vh - 215px)"
                : "calc(100vh - 140px)",
            paddingRight: "30px",
          }}
        >
          {taskByIdLoading ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={30}
                    width={150}
                    sx={{ marginBottom: "14px" }}
                  />
                </div>

                <div>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={30}
                    width={150}
                    sx={{ marginBottom: "14px" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.PageBackBtnWrap}>
                <button className={styles.PageBackBtn} onClick={goBack}>
                  <ArrowBackIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "15PX",
                      color: "#667085",
                    }}
                  />
                  Back
                </button>
                <button
                  className={styles.viewBatchDetailsBtn}
                  onClick={() =>
                    navigate(`/app/manage/Batches?batchId=${taskById?.batchId}`)
                  }
                >
                  View Batch Details
                </button>
              </div>
            </>
          )}
          {taskByIdLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                height="30px"
                width="150px"
                style={{
                  marginBottom: "10px",
                  borderRadius: "3px",
                }}
              />
            </>
          ) : (
            <>
              <div className={styles.batchDetailsTitle}>Batch Details</div>
            </>
          )}

          <div className={styles.batchDetailsSplit}>
            <div className={styles.batchDetailsBox}>
              {taskByIdLoading ? (
                <>
                  <div>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height="99px"
                      width="100%"
                      style={{
                        marginBottom: "10px",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "40px",
                      padding: "25px 40px",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height="50px"
                        width="100%"
                        style={{
                          marginBottom: "10px",
                          borderRadius: "3px",
                        }}
                      />
                    </div>

                    <div style={{ width: "30%" }}>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height="50px"
                        width="100%"
                        style={{
                          marginBottom: "10px",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.batchDetailsBoxTop}>
                    <div className={styles.batchName}>
                      {taskById?.batchName}
                    </div>
                    <div className={styles.branchName}>
                      {taskById?.enquiryData?.branchName}
                    </div>
                  </div>
                  <div className={styles.batchDetailsBoxBottom}>
                    <div className={styles.batchDetailsBoxBottomSplit}>
                      <div className={styles.batchDetailsBoxBottomIcon}>
                        <img src={demoCourse} alt="demoCourse" />
                      </div>
                      <div>
                        <div className={styles.batchDetailsBoxBottomMain}>
                          {/* {moment(taskById?.demoData?.[0]?.demoTiming).format(
                            "DD MMM"
                          )} */}
                          {moment(taskById?.date).format("DD MMM")}
                        </div>
                        <div className={styles.batchDetailsBoxBottomSub}>
                          {taskById?.status === "DELETED" ? (
                            <>
                              {taskById?.demoStartTime?.toString()?.length ===
                              10
                                ? moment
                                    .unix(taskById?.demoStartTime)
                                    .format("hh:mm A")
                                : moment(taskById?.demoStartTime).format(
                                    "hh:mm A"
                                  )}
                              {" - "}
                              {taskById?.demoEndTime?.toString()?.length === 10
                                ? moment
                                    .unix(taskById?.demoEndTime)
                                    .format("hh:mm A")
                                : moment(taskById?.demoEndTime).format(
                                    "hh:mm A"
                                  )}
                            </>
                          ) : (
                            <>
                              {" "}
                              {taskById?.demoData?.[0]?.demoStartTime?.toString()
                                ?.length === 10
                                ? moment
                                    .unix(
                                      taskById?.demoData?.[0]?.demoStartTime
                                    )
                                    .format("hh:mm A")
                                : moment(
                                    taskById?.demoData?.[0]?.demoStartTime
                                  ).format("hh:mm A")}
                              {" - "}
                              {taskById?.demoData?.[0]?.demoEndTime?.toString()
                                ?.length === 10
                                ? moment
                                    .unix(taskById?.demoData?.[0]?.demoEndTime)
                                    .format("hh:mm A")
                                : moment(
                                    taskById?.demoData?.[0]?.demoEndTime
                                  ).format("hh:mm A")}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.batchDetailsBoxBottomSplit}>
                      <div className={styles.batchDetailsBoxBottomIcon}>
                        <img src={demoCalander} alt="demoCourse" />
                      </div>
                      <div>
                        <div
                          className={styles.batchDetailsBoxBottomMain}
                          style={{ fontSize: "16px" }}
                        >
                          Course
                        </div>
                        <div className={styles.batchDetailsBoxBottomSub}>
                          {taskById?.enquiryData?.courseName}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.demoDetailsBox}>
              {taskByIdLoading ? (
                <>
                  {Array.from(new Array(3)).map((_, index) => (
                    <div key={index}>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height="30px"
                        width="40%"
                        style={{
                          marginBottom: "10px",
                          borderRadius: "3px",
                        }}
                      />

                      <Skeleton
                        animation="wave"
                        variant="text"
                        height="20px"
                        width="60%"
                        style={{
                          marginBottom: "10px",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className={styles.demoDetailsBoxItem}>
                    <div className={styles.demoDetailsBoxItemLabel}>
                      Task Due Date
                    </div>
                    <div className={styles.demoDetailsBoxItemValue}>
                      <div className={styles.demoDetailsBoxDateIcon}>
                        <img src={clockIcon} alt="clockIcon" />
                      </div>

                      <div>
                        {moment(taskById?.date).format("MMM DD, hh:mm A")}
                      </div>
                    </div>
                  </div>
                  <div className={styles.demoDetailsBoxItem}>
                    <div className={styles.demoDetailsBoxItemLabel}>
                      Assignee
                    </div>
                    <div className={styles.demoDetailsBoxItemValue}>
                      <div className={styles.demoAssignee}>
                        {/* {taskById?.assignedToName
                          ?.split(" ")
                          ?.slice(0, 2)
                          ?.map((word: any, index: number) => (
                            <span key={index}>{word?.charAt(0)}</span>
                          ))} */}
                        {getTeamMember(taskById?.assignedToId, employees)
                          ?.firstName?.charAt(0)
                          ?.toUpperCase() +
                          "" +
                          getTeamMember(taskById?.assignedToId, employees)
                            ?.lastName?.charAt(0)
                            ?.toUpperCase()}
                      </div>
                      <div className={styles.demoAssigneeName}>
                        {/* {taskById?.assignedToName} */}
                        {getTeamMember(taskById?.assignedToId, employees)
                          ?.firstName +
                          " " +
                          getTeamMember(taskById?.assignedToId, employees)
                            ?.lastName}
                      </div>
                    </div>
                  </div>
                  <div className={styles.demoDetailsBoxItem}>
                    <div className={styles.demoDetailsBoxItemLabel}>
                      Task Stage
                    </div>
                    <div
                      className={styles.demoDetailsBoxItemValue}
                      style={{ color: "#667085", fontSize: "14px" }}
                    >
                      {taskById?.status === "DELETED" ? (
                        <div style={{ color: "#EC3939" }}> Task Deleted</div>
                      ) : (
                        <FormControl sx={{ minWidth: 140 }}>
                          <Select
                            value={stage}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  boxShadow:
                                    "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                                },
                              },
                            }}
                            sx={{
                              color: "#667085",
                              fontSize: "14px",
                              fontWeight: "500",
                              padding: "5px",
                              minWidth: "174px",
                              textTransform: "capitalize",
                              "& .MuiSvgIcon-root": {
                                color: "#B1B1B1",
                                fontWeight: "300",
                                fontSize: "18px",
                                marginRight: "11px",
                              },
                              "& .MuiSelect-select": {
                                padding: "2px",
                              },
                              //   "& .MuiPaper-root": {
                              //     top: "300px",
                              //   },
                            }}
                            disabled={
                              authUser?.email?.toLowerCase() !==
                              assigny?.email?.toLowerCase()
                                ? true
                                : false
                            }
                          >
                            {filteredProgressArray?.map((taskStatus: any) => (
                              <MenuItem
                                key={taskStatus?.value}
                                value={taskStatus.value}
                                sx={{
                                  fontSize: "12px",
                                  textTransform: "capitalize",
                                  color: "#667085",
                                }}
                              >
                                {taskStatus.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </div>
                  {taskById?.status === "COMPLETED" && (
                    <div className={styles.demoDetailsBoxItem}>
                      <div className={styles.demoDetailsCompleteBar}>
                        <img
                          src={checkComplete}
                          alt="checkComplete"
                          style={{ marginRight: "6px" }}
                        />
                        Completed on
                        {moment(taskById?.taskCompletedDate).format(
                          " DD MMM YYYY, hh:mm A"
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {taskByIdLoading ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={30}
                    width={150}
                    sx={{ marginBottom: "6px" }}
                  />
                </div>

                <div>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    height={30}
                    width={150}
                    sx={{ marginBottom: "6px" }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {demoLeads?.length > 0 && (
                <div className={styles.demoStudentsHead}>
                  <div className={styles.demoStudentsHeadTitle}>Leads</div>
                  <div className={styles.demoStudentsHeadRight}>
                    {(stage === "COMPLETED" &&
                      taskById?.status !== "COMPLETED") ||
                    editBtn ? (
                      <div>
                        <FormControlLabel
                          sx={{ color: "#667085", fontSize: "12px" }}
                          control={
                            <Checkbox
                              checked={markAllAsAttended}
                              onChange={handleCheckBoxChange}
                              sx={{
                                strokeWidth: 1,
                                color: "#D0D5DD",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                                "&:focus": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            />
                          }
                          label={"Mark all as Attended"}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <TextField
                      type="search"
                      value={leadSearchTerm}
                      onChange={handleLeadSearch}
                      placeholder="Search leads"
                      sx={{
                        my: 2,
                        width: "380px",
                        borderRadius: "4px",
                      }}
                      size="small"
                      inputProps={{
                        sx: {
                          width: "380px",
                          "&::placeholder": {
                            fontWeight: "400",
                            fontSize: "12px",
                          },

                          "& .MuiOutlinedInput-input": {
                            padding: "10.5px 14px",
                          },
                        },
                      }}
                    />
                    {taskById?.status === "COMPLETED" && (
                      <button
                        className={styles.demoAttendanceEditBtn}
                        onClick={handleEditBtn}
                      >
                        <img src={edit} alt="edit" />
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          <div className={styles.demoStudentsList}>
            {taskByIdLoading ? (
              <>
                {Array.from(new Array(2)).map((_, index) => (
                  <div key={index} style={{ height: "70px" }}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      height="70px"
                      width="100%"
                      style={{
                        marginBottom: "10px",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {filteredLeads?.map((item: any) => {
                  return (
                    <div className={styles.demoStudentCard} key={item.id}>
                      <div className={styles.demoStudentCardLeft}>
                        <div className={styles.demoStudentCardMainSplit}>
                          <div className={styles.demoStudentCardAvatar}>
                            {item?.studentFullName?.charAt(0)}
                          </div>
                          <div className={styles.demoStudentCardMain}>
                            <div className={styles.demoStudentCardName}>
                              {item?.studentFullName}
                            </div>
                            <div className={styles.demoStudentCardEmail}>
                              {item?.studentEmail}
                            </div>
                          </div>
                        </div>

                        <div className={styles.demoStudentCardPhone}>
                          Ph no: {/* Space added after "Ph no:" */}
                          {item?.studentContact.length === 10
                            ? `+91 ${item?.studentContact}`
                            : item?.studentContact.length > 10
                            ? `+${item?.studentContact.substring(
                                0,
                                2
                              )} ${item?.studentContact.substring(2)}`
                            : "-"}
                        </div>
                      </div>
                      <div className={styles.demoStudentCardRight}>
                        <div
                          style={{
                            marginRight:
                              taskById?.status === "COMPLETED" ? "210PX" : "0",
                          }}
                        >
                          {taskById?.status === "COMPLETED" && !editBtn ? (
                            <div
                              className={styles.demoStudentStatus}
                              style={{
                                color:
                                  item?.status === "COMPLETED"
                                    ? "#11C89B"
                                    : item?.status === "ABSENT"
                                    ? "#E7453A"
                                    : "inherit",
                                background:
                                  item?.status === "COMPLETED"
                                    ? "rgba(17, 200, 155, 0.10)"
                                    : item?.status === "ABSENT"
                                    ? "#FEF3F2"
                                    : "inherit",
                              }}
                            >
                              {item?.status === "COMPLETED"
                                ? "Attended"
                                : item?.status === "ABSENT"
                                ? "Absentee"
                                : ""}
                            </div>
                          ) : taskById?.status === "COMPLETED" && editBtn ? (
                            <button
                              className={styles.demoStudentCardAttendanceBtn}
                              style={{
                                background:
                                  item?.status === "COMPLETED"
                                    ? "#E1F5F4"
                                    : "#fff",
                                color:
                                  item?.status === "COMPLETED"
                                    ? "#11C89B"
                                    : "#667085",
                              }}
                              onClick={() =>
                                handleAttendanceButtonClick(item.id)
                              }
                            >
                              <img
                                style={{ marginRight: "6px" }}
                                src={
                                  item?.status === "COMPLETED"
                                    ? checkIconGreen
                                    : checkIcon
                                }
                                alt="checkIcon"
                              />
                              Mark as Attended
                            </button>
                          ) : (
                            <></>
                          )}
                          {stage === "COMPLETED" &&
                            taskById?.status !== "COMPLETED" && (
                              <button
                                className={styles.demoStudentCardAttendanceBtn}
                                style={{
                                  background:
                                    item?.status === "COMPLETED"
                                      ? "#E1F5F4"
                                      : "#fff",
                                  color:
                                    item?.status === "COMPLETED"
                                      ? "#11C89B"
                                      : "#667085",
                                }}
                                onClick={() =>
                                  handleAttendanceButtonClick(item.id)
                                }
                              >
                                <img
                                  style={{ marginRight: "6px" }}
                                  src={
                                    item?.status === "COMPLETED"
                                      ? checkIconGreen
                                      : checkIcon
                                  }
                                  alt="checkIcon"
                                />
                                Mark as Attended
                              </button>
                            )}
                        </div>
                        <button
                          className={styles.demoStudentCardLeadBtn}
                          onClick={() =>
                            hasLeadCardPermission
                              ? openLeadExpandModal(
                                  item?.instituteId
                                    ? item?.instituteId
                                    : item?.enquiryData?.instituteId,
                                  item?.studentContact
                                    ? item?.studentContact
                                    : item?.enquiryData?.studentContact,
                                  item?.studentEmail
                                    ? item?.studentEmail
                                    : item?.enquiryData?.studentEmail,
                                  item?.enquiryId
                                )
                              : dispatch(
                                  setToastNotification({
                                    message: String(
                                      "You do not have permission to access the lead card"
                                    ),
                                    type: "error",
                                    snackOpen: true,
                                  })
                                )
                          }
                        >
                          <img
                            style={{ marginRight: "4px" }}
                            src={eyeIcon}
                            alt="eyeIcon"
                          />
                          View Lead Card
                        </button>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          {(stage === "COMPLETED" && taskById?.status !== "COMPLETED") ||
          editBtn ? (
            <div className={styles.attendanceBtnsWrap}>
              <button className="CancelBtn" onClick={cancelAttendanceSave}>
                cancel
              </button>
              <button
                className="SaveBtn"
                onClick={handleAttendanceSave}
                disabled={taskStatusUpdateLoading}
                style={{ minWidth: "90px" }}
              >
                {taskStatusUpdateLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default DemoTaskDetailsPage;
