import DayCard from "./DayCard";

const DayCardList = ({
  data,
  firstDayOfMonth,
  batchDetails,
  setBatchDetails,
  add,
  startDate,
  endDate,
  setIsDateChanged,
}: any) => {
  const DAYS: any = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
  };

  const daysFromPrevMonth = Array.from(
    { length: DAYS[firstDayOfMonth] },
    (_, i) => i + 1
  );

  const totalDays = daysFromPrevMonth.length + data.length;
  const numRows = Math.ceil(totalDays / 7);

  return (
    <div
      style={{
        display: "grid",
        flexGrow: 1,
        gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
        gridRow: numRows,
        gap: "1px",
        backgroundColor: "rgb(229 231 235)",
        marginTop: "1px",
        paddingTop: "1px",
        height: "600px",
        width: "100%",
      }}
    >
      {daysFromPrevMonth.map((day) => (
        <div key={`day-${day}`} style={{ padding: "4px 8px" }}></div>
      ))}

      {data.map((dayData: any) => (
        <DayCard
          key={dayData.date}
          {...dayData}
          batchDetails={batchDetails}
          setBatchDetails={setBatchDetails}
          add={add}
          startDate={startDate}
          endDate={endDate}
          setIsDateChanged={setIsDateChanged}
        />
      ))}
    </div>
  );
};

export default DayCardList;
