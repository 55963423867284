import { Box, Typography, Modal } from "@mui/material";
import {
  POLICYTEXT,
  PRIVACY,
  TERMS,
} from "../../pages/auth/signin/container/SigninString";
import styles from "./TermsAndCondition.module.css";
import * as React from "react";
import { TermsOfServices } from "../../pages";
import CloseIcon from "@mui/icons-material/Close";
import { PrivacyPolicy } from "../../pages/app/TermsOfServices/components/PrivacyPolicy";

const style = {
  position: "absolute" as "absolute",

  bgcolor: "background.paper",
  border: "2px solid #fff",
  p: 2,
  top: "2%",
  left: "2%",
  right: "2%",
  bottom: "2%",
  overflow: "hidden",
  outline: "none",
};

export function TermsAndCondition(props: any) {
  const { tnc,isVersionMatching } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);

  return (
    <Box>
      <Typography
        className={styles.policyText}
        sx={{
          fontSize: {
            lg: 14,
            md: 14,
            sm: 14,
            xs: 10,
          },
        }}
      >
        {POLICYTEXT}
      </Typography>

      <Box
        style={{
          textAlign: "left",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontWeight: "400",
          color: "#3F3E43",
        }}
        sx={{
          fontSize: {
            lg: 14,
            md: 14,
            sm: 14,
            xs: 10,
          },
        }}
        className={styles.ServiceText}
      >
        <span className={styles.link} onClick={handleOpen}>
          {TERMS}
        </span>{" "}
        and{" "}
        <span className={styles.link} onClick={handleOpenPrivacy}>
          {PRIVACY}
        </span>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </div>
          <TermsOfServices tnc={tnc} isVersionMatching={isVersionMatching}/>
        </Box>
      </Modal>
      <Modal open={openPrivacy} onClose={handleClosePrivacy}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleClosePrivacy}
            >
              <CloseIcon />
            </button>
          </div>
          <PrivacyPolicy tnc={tnc} isVersionMatching={isVersionMatching}/>
        </Box>
      </Modal>
    </Box>
  );
}
