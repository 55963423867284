import { Box, CircularProgress, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ImportModal.module.css";
import { Info } from "@mui/icons-material";
import { AiOutlineInfoCircle } from "react-icons/ai";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};
const ImportModal = ({
  open,
  closeModal,
  importButtonClick,
  cancelButtonClick,
  title = "Can we continue import?",
  subTitle = " The changes you made will be discarded.",
  des = "You can find the import history in the settings page.",
  saveImportedDataLoading,
}: any) => {
  return (
    <Modal
      open={open}
      // onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          {saveImportedDataLoading ? (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "40px 20px",
                    width: "500px",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Box
                      position="relative"
                      display="inline-flex"
                      sx={{
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <CircularProgress
                        style={{
                          height: "70px",
                          width: "70px",
                        }}
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                      marginBottom={2}
                    >
                      <div style={{ fontWeight: "500", fontSize: "20px" }}>
                        Hold on tight...
                      </div>
                      <p
                        style={{
                          color: "#8A8A8A",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                      >
                        While we unleash a rush of leads, eager to be nurtured
                        to success!
                      </p>
                    </Box>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div onClick={closeModal} className={styles.closeModalButton}>
                <CloseIcon />
              </div>
              <div className={styles.modalTitle}>{title}</div>
              <div className={styles.modalDes}>{subTitle}</div>
              <div className={styles.modalDescription}>
                <AiOutlineInfoCircle style={{ marginRight: "5px" }} />
                {des}
              </div>
              <div className={styles.buttonSection}>
                <button onClick={cancelButtonClick} className={styles.noButton}>
                  Cancel
                </button>
                <button
                  onClick={importButtonClick}
                  style={{
                    background: saveImportedDataLoading
                      ? "rgba(17, 200, 155, 0.5)"
                      : "#11c89b",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    borderRadius: "4px",
                    padding: "10px 18px",
                    gap: "8px",
                    width: "100%",
                    color: " #ffffff",
                    border: "0",
                    cursor: "pointer",
                  }}
                  disabled={saveImportedDataLoading === true}
                >
                  Import
                </button>
              </div>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};
export default ImportModal;
