import { axios, axios_logout } from "../../../../api/setup.intersepter";
import { LoginDTO, LogoutDTO, RevokeSessionDTO } from "./types";

export const SignInRequest = (body: LoginDTO) => {
  return axios({
    method: "POST",
    url: `/login`,
    data: body,
  });
};

export const RevokeSession = (body: RevokeSessionDTO) => {
  return axios({
    method: "POST",
    url: `/revokeSession`,
    data: body,
  });
};

export const LogoutRequest = (body: LogoutDTO) => {
  return axios_logout({
    method: "POST",
    url: `/logout`,
    data: body,
  });
};
