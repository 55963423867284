import React, { useEffect } from "react";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./EmailSyncing.module.css";
import { useNavigate } from "react-router";
import EmailSyncingBox from "./EmailSyncingBox";
import { useAuthUser } from "react-auth-kit";

function EmailSyncing(props: any) {
  const {
    SaveEmailConfigurationDetails,
    emailConfigSuccess,
    emailConfigError,
    emailConfigLoading,
    ImapEmailSync,
  } = props;
  const navigate = useNavigate();
  const auth = useAuthUser();

  useEffect(() => {
    if (emailConfigSuccess) {
      navigate("/app/inbound");
    }
  }, [emailConfigSuccess, navigate]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#F5F5F5",
          padding: "14px",
          overflowY: "auto",
          minHeight: "calc(100vh - 65px)",
        }}
      >
        <div
          style={{
            background: "#fff",
            padding: "14px",
            overflowY: "auto",
            width: "100%",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              padding: "16px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <button className={styles.BackBtn} onClick={() => navigate(-1)}>
              <ArrowBackIcon
                sx={{
                  marginRight: "8px",
                  fontSize: "15PX",
                  color: "#667085",
                }}
              />
              Back
            </button>
          </Box>
          <EmailSyncingBox
            emailConfigLoading={emailConfigLoading}
            SaveEmailConfigurationDetails={SaveEmailConfigurationDetails}
            emailConfigError={emailConfigError}
          />
        </div>
      </Box>
    </>
  );
}

export default EmailSyncing;
