import { RootState, TypedDispatch } from "../../../../../redux/store";
import { batchInitialStateDTO, createBatchDTO } from "../service/types";
import { connect } from "react-redux";
import BatchEditor from "../BatchEditor/BatchEditor";
import { getBranchList } from "../../ManageBranch/service/Branchslice";
import {
  createBatch,
  getBatchById,
  getNurtureLeadBoard,
  updateBatch,
} from "../service/Batchslice";
import { getAllCourseByInstituteRequest } from "../../ManageCourse/service/courseSlice";
import { SetActiveInstitute } from "../../../../../service/activeInstituteSlice";
import { appEntery } from "../../../Home/service/homeSlice";
import { GetCourseConfig } from "../../ManageCourse/service/courseConfigSlice";

interface StateProps extends batchInitialStateDTO {}

interface DispatchProps {
  CreateBatch(data: createBatchDTO): void;
  GetBatchById(id: string): void;
  UpdateBatch(data: createBatchDTO): void;
  GetBranchList(id: string): void;
  GetCourseList(id: string): void;
  GetActiveInstitute(id: string): void;
  UpdateInstituteInLocal(id: string): void;
  BatchConfig(): void;
  GetNurtureLeadBoard(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.batch.success,
    error: state.batch.error,
    loading: state.batch.loading,
    batchList: state.batch.batchList,
    batchById: state.batch.batchById,
    batchId: state.batch.batchId,
    batchBookedSession: state.batch.batchBookedSession,
    batchByIdWithCalander: state.batch.batchByIdWithCalander,
    batchListLoading: state.batch.batchListLoading,
    batchCreateSuccess: state.batch.batchCreateSuccess,
    batchCreateError: state.batch.batchCreateError,
    batchCreateLoading: state.batch.batchCreateLoading,
    assigneeSuccess: state.batch.assigneeSuccess,
    assigneeLoading: state.batch.assigneeLoading,
    assigneeError: state.batch.assigneeError,
    assignees: state.batch.assignees,
    batchByIdLoading: state.batch.batchByIdLoading,
    batchUpdateLoading: state.batch.batchUpdateLoading,
    batchUpdateSuccess: state.batch.batchUpdateSuccess,
    batchUpdateError: state.batch.batchUpdateError,

    batchPayUpdateLoading: state.batch.batchPayUpdateLoading,
    batchPayUpdateSuccess: state.batch.batchPayUpdateSuccess,
    batchPayUpdateError: state.batch.batchPayUpdateError,
    demoStudentDeleteLoading: state.batch.demoStudentDeleteLoading,
    demoStudentDeleteSuccess: state.batch.demoStudentDeleteSuccess,
    demoStudentDeleteError: state.batch.demoStudentDeleteError,
    batchSessionLoading: state.batch.batchSessionLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateBatch: (data: createBatchDTO) => {
      dispatch(createBatch(data));
    },
    GetBatchById: (id: string) => {
      dispatch(getBatchById(id));
    },
    UpdateBatch: (data: createBatchDTO) => {
      dispatch(updateBatch(data));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    GetActiveInstitute: (id: string) => {
      dispatch(SetActiveInstitute(id));
    },
    UpdateInstituteInLocal: (id: string) => {
      dispatch(appEntery(id));
    },
    BatchConfig: () => {
      dispatch(GetCourseConfig());
    },
    GetNurtureLeadBoard: (id: string) => {
      dispatch(getNurtureLeadBoard(id));
    },
  };
};

export interface BranchProps extends StateProps, DispatchProps {}

export const BatchComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchEditor);
