import React from "react";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import styles from "../Manegecourses.module.css";
import deleteIcon from "../../../../../../assets/images/delete-dustbin.svg";
import AddIcon from "@mui/icons-material/Add";

const emiModesItems = [
  {
    id: 1,
    emiModeValue: "MONTHLY",
    emiModeLabel: "Monthly",
  },
  {
    id: 2,
    emiModeValue: "QUARTERLY",
    emiModeLabel: "Quarterly",
  },
  {
    id: 3,
    emiModeValue: "HALF_YEARLY",
    emiModeLabel: "Half yearly",
  },
  {
    id: 4,
    emiModeValue: "YEARLY",
    emiModeLabel: "Yearly",
  },
];

function Membership(props: any) {
  const { rows, setRows, setSelectedEmiModes, selectedEmiModes } = props;

  const handleAddRow = () => {
    if (rows?.length < emiModesItems?.length) {
      setRows([
        ...rows,
        {
          id: rows.length + 1,
          emiModeValue: "",
          emiPrice: 0,
          isEmiModeValid: true,
          isPriceValid: true,
        },
      ]);
    }
  };

  const handleRemoveRow = (id: number) => {
    const updatedRows = rows
      ?.filter((row: any) => row.id !== id)
      ?.map((row: any, index: number) => ({
        ...row,
        id: index + 1,
      }));
    const updatedSelectedEmiModes = updatedRows?.map(
      (row: any) => row.emiModeValue
    );
    setRows(updatedRows);
    setSelectedEmiModes(updatedSelectedEmiModes);
  };

  const handleEmiModeChange = (id: number, value: string) => {
    const updatedRows = rows?.map((row: any) =>
      row.id === id
        ? { ...row, emiModeValue: value, isEmiModeValid: true }
        : row
    );
    setRows(updatedRows);
    setSelectedEmiModes(updatedRows?.map((row: any) => row.emiModeValue));
  };

  const handleEmiPriceChange = (id: number, value: string) => {
    const updatedRows = rows?.map((row: any) =>
      row.id === id
        ? {
            ...row,
            emiPrice: parseFloat(value),
            isPriceValid: value !== "" && parseFloat(value) > 0 ? true : false,
          }
        : row
    );
    setRows(updatedRows);
  };

  const isModeUniqueCheck = (rows: any, row: any) => {
    return !rows?.some(
      (r: any) => r?.id !== row?.id && r?.emiModeValue === row?.emiModeValue
    );
  };
  return (
    <div>
      {rows?.map((item: any, index: number) => (
        <div className={styles.membershipTypeRow} key={index}>
          <div>
            <div className={styles.membershipTypeFieldLabel}>Frequency</div>
            <FormControl
              sx={{
                minWidth: 240,
                ".MuiOutlinedInput-input": {
                  padding: "7px 14px",
                },
              }}
            >
              <Select
                id={`emi-mode-select-${item.id}`}
                MenuProps={{
                  style: {
                    zIndex: 999999,
                    fontSize: "14px",
                  },
                  PaperProps: {
                    style: {
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                    },
                  },
                }}
                sx={{
                  "& .MuiSelect-outlined": {
                    fontSize: "14px",
                    color: "#142C44",
                  },
                }}
                value={item.emiModeValue}
                onChange={(event) =>
                  handleEmiModeChange(item.id, event.target.value as string)
                }
                error={!item?.isEmiModeValid || !isModeUniqueCheck(rows, item)}
              >
                {emiModesItems
                  ?.filter(
                    (modeType) =>
                      !selectedEmiModes.includes(modeType.emiModeValue) ||
                      modeType.emiModeValue === item.emiModeValue
                  )
                  ?.map((modeType) => (
                    <MenuItem
                      key={modeType.id}
                      value={modeType.emiModeValue}
                      sx={{
                        fontSize: "12PX",
                        color: "#667085",
                        textTransform: "capitalize",
                        fontWeight: "400",
                      }}
                    >
                      {modeType.emiModeLabel}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <div className={styles.membershipTypeFieldLabel}>
              Membership Fees
            </div>

            <TextField
              id="emiPrice"
              variant="outlined"
              type="number"
              inputProps={{
                sx: {
                  width: "240px",
                  "&::placeholder": {
                    fontWeight: "400",
                    fontSize: "14px",
                  },

                  "&.MuiOutlinedInput-input": {
                    padding: "8px",
                  },
                },
              }}
              value={item.emiPrice}
              onChange={(event) =>
                handleEmiPriceChange(item.id, event.target.value)
              }
              error={!item?.isPriceValid}
              helperText={!item?.isPriceValid ? "Please fill valid amount" : ""}
            />
          </div>
          <div>
            {rows?.length > 1 && (
              <button
                className={styles.deleteTypeBtn}
                onClick={() => handleRemoveRow(item.id)}
                style={{ marginTop: "40PX" }}
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            )}
          </div>
        </div>
      ))}
      <div className={styles.addMoreTypeBtnWrap}>
        {rows?.length < 4 && (
          <button
            className={styles.addMoreTypeBtn}
            onClick={handleAddRow}
            style={{ marginBottom: "10PX" }}
          >
            <AddIcon sx={{ fontSize: "18px", marginRight: "6PX" }} /> Add more
          </button>
        )}
      </div>
    </div>
  );
}

export default Membership;
