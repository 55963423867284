import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import styles from "../LeadPopup.module.css";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import LeadCallDispositionTask from "./LeadCallDispositionTask";
import LeadCallDispositionNote from "./LeadCallDispositionNote";
import {
  CallLeadStatus,
  SetOzonetelDisposition,
} from "../../../pages/app/Integrations/service/integrations.request";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 504,
    xl: 504,
    md: 504,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};

function LeadCallDispositionForm(props: any) {
  const {
    selectedLeadData,
    employees,
    taskTypes,
    CallDispositionForm,
    callDispositionShowStatus,
    setCallDispositionShowStatus,
    callUcid,
    setCallUcid,
    callDID,
    setCallDID,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const { callDispositionLoading, callDispositionSuccess, appsList } =
    useSelector((state: any) => state.appIntegrations);
  const ozonetelApp = appsList?.find((app: any) => app.appCode === "OZONETEL");
  const ozonetelAppApiKey = ozonetelApp?.appConfigData?.apiKey;
  const ozonetelAppUserName = ozonetelApp?.appConfigData?.userName;
  const ozonetelEmployee = employees?.find(
    (item: any) => item.employee === authUser?.institute[0]?.employee
  );
  const userAgentId = ozonetelEmployee?.ozonetelConfig?.agentId;
  const mainDispositionReasons = instituteFromRedux?.IVRdispositionReasons;

  const [dispositionPopup, setDispositionPopup] =
    React.useState<boolean>(false);

  const handleOpenDisposoitionPopup = () => {
    setDispositionPopup(true);
  };

  const [reasonStageOne, setReasonStageOne] = React.useState<any>("");
  const [reasonStageTwo, setReasonStageTwo] = React.useState<any>("");
  const [reasonStageThree, setReasonStageThree] = React.useState<any>("");
  const [reasonStageOneError, setReasonStageOneError] = React.useState<any>("");
  const [reasonStageTwoError, setReasonStageTwoError] = React.useState<any>("");
  const [reasonStageThreeError, setReasonStageThreeError] =
    React.useState<any>("");
  const [taskRequiredError, setTaskRequiredError] = React.useState<any>("");
  const [popupTime, setPopupTime] = React.useState<number | null>();

  const onReasonChange = React.useCallback((event: any) => {
    setReasonStageOne(event.target.value);
    setReasonStageTwo("");
    setReasonStageThree("");
    setReasonStageOneError("");
  }, []);

  const onReasonTwoChange = React.useCallback((event: any) => {
    setReasonStageTwo(event.target.value);
    setReasonStageThree("");
    setReasonStageTwoError("");
  }, []);

  const onReasonThreeChange = React.useCallback((event: any) => {
    setReasonStageThree(event.target.value);
    setReasonStageThreeError("");
  }, []);

  const [taskDetails, setTaskDetails] = React.useState({
    description: "",
    mentionedIds: [],
  });

  const [addTask, setAddTask] = useState<any>("");
  const [assigny, setAssigny] = useState<any>("");
  const [priority, setPriority] = useState<any>("NORMAL");
  const [date, setDate] = useState<any>();
  const [taskType, setTaskType] = useState<string>("CALL");

  const [noteDetails, setNoteDetails] = React.useState({
    description: "",
    mentionedIds: [],
  });

  const handleCloseDisposoitionPopup = () => {
    setDispositionPopup(false);
    setPopupTime(0);
    setCallDispositionShowStatus("");
    setNoteDetails({
      ...noteDetails,
      description: "",
      mentionedIds: [],
    });
    setTaskDetails({
      ...taskDetails,
      description: "",
      mentionedIds: [],
    });
    setReasonStageOne("");
    setReasonStageTwo("");
    setReasonStageThree("");
    setCallDID("");
    setPriority("NORMAL");
    setTaskType("CALL");
    setAddTask("");
    const userFromLocalStorage = authUser;
    if (employees && userFromLocalStorage) {
      const user = employees?.find(
        (item: any) =>
          item.email.toLowerCase() === userFromLocalStorage.email.toLowerCase()
      );
      setAssigny(user);
    }
  };
  const handleFormSave = async (event: any) => {
    event.preventDefault();
    if (reasonStageOne === "") {
      setReasonStageOneError("Please select a reason");
      return;
    }

    if (reasonStageOne?.subReasons?.length > 0 && reasonStageTwo === "") {
      setReasonStageTwoError("Please select a reason");
      return;
    }

    if (reasonStageTwo?.subReasons?.length > 0 && reasonStageThree === "") {
      setReasonStageThreeError("Please select a reason");
      return;
    }

    if (addTask !== "") {
      if (
        taskType === "" ||
        assigny === "" ||
        priority === "" ||
        date === "" ||
        date === undefined
      ) {
        setTaskRequiredError(
          "Please select task related mandatory field as description is added."
        );
      }
    } else {
      setTaskRequiredError("");
    }

    let reasonsSet = reasonStageOne?.key;
    if (reasonStageTwo) reasonsSet += `#${reasonStageTwo?.key}`;
    if (reasonStageThree) reasonsSet += `#${reasonStageThree?.key}`;

    const dispositionReason = `${reasonStageOne?.key}${
      reasonStageTwo ? `#${reasonStageTwo?.key}` : ""
    }${reasonStageThree ? `#${reasonStageThree?.key}` : ""}`;
    const dispositioFormDetails = {
      enquiryId: selectedLeadData?.id,
      studentName:
        selectedLeadData?.studentFirstName +
        " " +
        selectedLeadData?.studentLastName,
      reason: reasonsSet,
      taskAssignedToName: assigny?.firstName + " " + assigny?.lastName,
      taskAssignedToId: assigny?.id,
      taskPriority: priority,
      taskType: taskType,
      taskDate: date,
      taskDescription: addTask,
      taskMentionedIds: taskDetails?.mentionedIds,
      noteDescription: noteDetails?.description,
      noteMentionedIds: noteDetails?.mentionedIds,
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      swimlaneState: selectedLeadData?.enquirySwimlaneStatus,
      ucid: callUcid,
    };
    try {
      if (taskRequiredError === "") {
        await CallDispositionForm(dispositioFormDetails);
        await SetOzonetelDisposition(
          ozonetelAppApiKey,
          ozonetelAppUserName,
          userAgentId,
          callDID,
          callUcid,
          dispositioFormDetails?.reason
          // `${reasonStageOne?.key}${
          //   reasonStageTwo ? `#${reasonStageTwo?.key}` : ""
          // }${reasonStageThree ? `#${reasonStageThree?.key}` : ""}`
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    const userFromLocalStorage = authUser;
    if (employees && userFromLocalStorage) {
      const user = employees?.find(
        (item: any) =>
          item.email.toLowerCase() === userFromLocalStorage.email.toLowerCase()
      );
      setAssigny(user);
    }
  }, [setAssigny, employees]);

  useEffect(() => {
    if (callDispositionSuccess) {
      setDispositionPopup(false);
      setCallDispositionShowStatus("");
      setCallUcid("");
      setReasonStageOne("");
      setReasonStageTwo("");
      setReasonStageThree("");
      setNoteDetails({
        ...noteDetails,
        description: "",
        mentionedIds: [],
      });
      setTaskDetails({
        ...taskDetails,
        description: "",
        mentionedIds: [],
      });
      // setCallDID("");
      setPriority("NORMAL");
      setTaskType("CALL");
      setAddTask("");
      const userFromLocalStorage = authUser;
      if (employees && userFromLocalStorage) {
        const user = employees?.find(
          (item: any) =>
            item.email.toLowerCase() ===
            userFromLocalStorage.email.toLowerCase()
        );
        setAssigny(user);
      }
    }
  }, [callDispositionSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CallLeadStatus(
          ozonetelAppApiKey,
          ozonetelAppUserName
        );
        if (response.data) {
          const filterAgent = response.data.message?.find(
            (item: any) => item?.AgentId === userAgentId
          );

          if (filterAgent && filterAgent?.AgentState === "ACW") {
            setDispositionPopup(true);
            setPopupTime(120);
            setCallDispositionShowStatus("");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchDataInterval = setInterval(() => {
      if (callDispositionShowStatus === "queued successfully") {
        fetchData();
      }
    }, 10 * 1000);

    return () => clearInterval(fetchDataInterval);
  }, [callDispositionShowStatus]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setPopupTime((prevTime: any) => {
        if (prevTime !== null) {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            setDispositionPopup(false);
            clearInterval(countdownInterval);
            return null;
          }
          return newTime;
        }
        return prevTime;
      });
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [popupTime]);

  const formatTime = (timeInSeconds: number | null) => {
    if (timeInSeconds !== null) {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`;
    }
    return "00:00";
  };

  return (
    <div>
      <Modal
        open={dispositionPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "999999" }}
      >
        <Box sx={style}>
          <div className={styles.dispositionPopupHead}>
            <div className={styles.dispositionPopupHeadTitle}>
              Post call Feedback
            </div>
            <div className={styles.dispositionPopupHeadTimeDesc}>
              <div>Time Limit Exceeds in: </div>
              <div className={styles.dispositionPopupHeadTime}>
                {popupTime && formatTime(popupTime)}
              </div>
            </div>
          </div>

          <div
            style={{
              overflowY: "auto",
              maxHeight: "550px",
            }}
          >
            <div className={styles.dispositionPopupFieldLabel}>
              Choose Reason
            </div>
            <FormControl
              fullWidth
              variant="outlined"
              className="formControl"
              size="small"
              sx={{ marginBottom: "18px" }}
            >
              <Select
                labelId="stage"
                id={""}
                value={reasonStageOne}
                label=""
                onChange={onReasonChange}
                sx={{
                  color: "#667085",
                  borderRadius: "4PX",
                  overflow: "hidden",

                  "&& fieldset": {
                    border: "1px solid rgba(17, 200, 155, 0.10)",
                    borderRadius: "2PX",
                    minHeight: "44px",
                    color: "667085",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid rgba(17, 200, 155, 0.10)",
                      borderRadius: "2PX",
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid rgba(17, 200, 155, 0.10)",
                      borderRadius: "2PX",
                    },
                  "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid rgba(17, 200, 155, 0.10)",
                      borderRadius: "2PX",
                    },

                  "&.MuiOutlinedInput-root img": {
                    display: "none",
                  },
                }}
                MenuProps={{
                  style: { zIndex: 999999999, fontSize: "14px" },
                  PaperProps: {
                    style: {
                      boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                      border: "1px solid #D4D4D8",
                      borderRadius: "6PX",
                    },
                  },
                }}
              >
                {mainDispositionReasons?.map((item: any, index: number) => (
                  <MenuItem
                    key={index}
                    value={item}
                    sx={{
                      fontSize: "14PX",
                      color: "#667085",
                      textTransform: "capitalize",
                      fontWeight: "400",
                    }}
                  >
                    {item?.value}
                  </MenuItem>
                ))}
              </Select>
              {reasonStageOneError !== "" && (
                <FormHelperText
                  error={true}
                  sx={{ marginLeft: "5PX", fontSize: "10PX" }}
                >
                  {reasonStageOneError}
                </FormHelperText>
              )}
            </FormControl>

            {reasonStageOne?.subReasons?.length > 0 && (
              <FormControl
                fullWidth
                variant="outlined"
                className="formControl"
                size="small"
                sx={{ marginBottom: "18px" }}
              >
                <Select
                  labelId="stage"
                  id={""}
                  value={reasonStageTwo}
                  label=""
                  onChange={onReasonTwoChange}
                  sx={{
                    color: "#667085",
                    borderRadius: "4PX",
                    overflow: "hidden",

                    "&& fieldset": {
                      border: "1px solid rgba(17, 200, 155, 0.10)",
                      borderRadius: "2PX",
                      minHeight: "44px",
                      color: "667085",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid rgba(17, 200, 155, 0.10)",
                        borderRadius: "2PX",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid rgba(17, 200, 155, 0.10)",
                        borderRadius: "2PX",
                      },
                    "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid rgba(17, 200, 155, 0.10)",
                        borderRadius: "2PX",
                      },

                    "&.MuiOutlinedInput-root img": {
                      display: "none",
                    },
                  }}
                  MenuProps={{
                    style: { zIndex: 999999999, fontSize: "14px" },
                    PaperProps: {
                      style: {
                        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                        border: "1px solid #D4D4D8",
                        borderRadius: "6PX",
                      },
                    },
                  }}
                >
                  {reasonStageOne?.subReasons?.map(
                    (item: any, index: number) => (
                      <MenuItem
                        key={index}
                        value={item}
                        sx={{
                          fontSize: "14PX",
                          color: "#667085",
                          textTransform: "capitalize",
                          fontWeight: "400",
                        }}
                      >
                        {item?.value}
                      </MenuItem>
                    )
                  )}
                </Select>
                {reasonStageTwoError && (
                  <FormHelperText
                    error={true}
                    sx={{ marginLeft: "5PX", fontSize: "10PX" }}
                  >
                    {reasonStageTwoError}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            {reasonStageTwo?.subReasons?.length > 0 && (
              <FormControl
                fullWidth
                variant="outlined"
                className="formControl"
                size="small"
                sx={{ marginBottom: "18px" }}
              >
                <Select
                  labelId="stage"
                  id={""}
                  value={reasonStageThree}
                  label=""
                  onChange={onReasonThreeChange}
                  sx={{
                    color: "#667085",
                    borderRadius: "4PX",
                    overflow: "hidden",

                    "&& fieldset": {
                      border: "1px solid rgba(17, 200, 155, 0.10)",
                      borderRadius: "2PX",
                      minHeight: "44px",
                      color: "667085",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid rgba(17, 200, 155, 0.10)",
                        borderRadius: "2PX",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid rgba(17, 200, 155, 0.10)",
                        borderRadius: "2PX",
                      },
                    "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid rgba(17, 200, 155, 0.10)",
                        borderRadius: "2PX",
                      },

                    "&.MuiOutlinedInput-root img": {
                      display: "none",
                    },
                  }}
                  MenuProps={{
                    style: { zIndex: 999999999, fontSize: "14px" },
                    PaperProps: {
                      style: {
                        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                        border: "1px solid #D4D4D8",
                        borderRadius: "6PX",
                      },
                    },
                  }}
                >
                  {reasonStageTwo?.subReasons?.map(
                    (item: any, index: number) => (
                      <MenuItem
                        key={index}
                        value={item}
                        sx={{
                          fontSize: "14PX",
                          color: "#667085",
                          textTransform: "capitalize",
                          fontWeight: "400",
                        }}
                      >
                        {item?.value}
                      </MenuItem>
                    )
                  )}
                </Select>
                {reasonStageThreeError && (
                  <FormHelperText
                    error={true}
                    sx={{ marginLeft: "5PX", fontSize: "10PX" }}
                  >
                    {reasonStageThreeError}
                  </FormHelperText>
                )}
              </FormControl>
            )}

            <div className={styles.dispositionPopupFieldLabel}>Task</div>

            <LeadCallDispositionTask
              taskTypes={taskTypes}
              employees={employees}
              setTaskDetails={setTaskDetails}
              setTaskType={setTaskType}
              taskType={taskType}
              setAddTask={setAddTask}
              setAssigny={setAssigny}
              assigny={assigny}
              setPriority={setPriority}
              priority={priority}
              setDate={setDate}
              date={date}
              addTask={addTask}
            />
            {taskRequiredError !== "" && (
              <FormHelperText
                error={true}
                sx={{ marginLeft: "5PX", fontSize: "10PX" }}
              >
                {taskRequiredError}
              </FormHelperText>
            )}
            <div
              className={styles.dispositionPopupFieldLabel}
              style={{ marginTop: "20PX" }}
            >
              Notes
            </div>
            <LeadCallDispositionNote
              noteDetails={noteDetails}
              setNoteDetails={setNoteDetails}
              employees={employees}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "20PX",
                gap: "10px",
              }}
            >
              <Button
                fullWidth
                onClick={handleCloseDisposoitionPopup}
                sx={{
                  textTransform: "capitalize",
                  color: "#9A9EA6",
                  borderColor: "#D0D5DD",
                  border: "1px solid",
                  borderRadius: "4PX",
                  maxWidth: "100px",
                  ":hover": {
                    color: "#9A9EA6",
                    borderColor: "#D0D5DD",
                    backgroundColor: "#9a9ea612",
                  },
                }}
                variant="outlined"
              >
                cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFormSave}
                sx={{
                  textTransform: "capitalize",
                }}
                disabled={callDispositionLoading}
              >
                {callDispositionLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default LeadCallDispositionForm;
