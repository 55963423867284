import React, { useState } from "react";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import styles from "./LeadRandomPay.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import {
  createRandomPayments,
  sendPaymentLink,
} from "../../../service/nurtureSlice";
import { FormControl, TextField } from "@mui/material";
import { useSelector } from "react-redux";

function RandomPayLink(props: any) {
  const {
    leadData,
    selectedPay,
    onClose,
    setSelectedPay,
    hasNurturePay,
    add,
    loader,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { sendPaymentLinkLoading } = useSelector((state: any) => state.nurture);

  const [paymentName, setPaymentName] = React.useState<string>("");
  const [enteredAmount, setEnteredAmount] = React.useState<number>(0);
  const [emiAmountError, setEmiAmountError] = useState<string>("");

  const closePayRecordPopup = () => {
    setSelectedPay("");
    onClose();
  };

  const handleSent = async (event: any) => {
    event.preventDefault();
    if (add) {
      const body = {
        enquiryId: leadData?.id,
        instituteId: authUser?.institute[0]?.instituteId,
        amount: enteredAmount,
        paymentName: paymentName,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
        createdById: authUser?.institute[0]?.id,
        capturePayment: true,
        captureMethod: "sendPaymentLink",
      };
      dispatch<any>(createRandomPayments(body));
    } else {
      if (!enteredAmount) {
        setEmiAmountError("Field is required");
        return;
      }

      if (enteredAmount < 0) {
        setEmiAmountError("Amount Received cannot be negative");
        return;
      }

      if (enteredAmount === 0) {
        setEmiAmountError("Please enter a number greater than zero");
        return;
      }

      const body = {
        leadId: leadData?.id,
        contact: leadData?.studentContact,
        paymentType: "RANDOM_PAYMENT",
        amount: enteredAmount,
        subMerchantId: "",
        module: "ENQUIRY",
        email: leadData?.studentEmail,
        randomPaymentId: selectedPay?.randomId,
        actionToPerform: hasNurturePay
          ? "SEND_PAYMENT_LINK"
          : "SEND_REMAINDER_EMAIL",
        paymentStatus: "REQUESTED",
        createdById: authUser?.institute?.[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
        emiId: "",
      };

      if (body) {
        dispatch<any>(sendPaymentLink(body));
      }
    }
  };

  React.useEffect(() => {
    if (selectedPay) {
      setPaymentName(selectedPay?.paymentName);
      setEnteredAmount(selectedPay?.price);
    }
  }, [selectedPay]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ padding: "16px 16px 16px 16px" }}>
          <div className={styles.popupHead}>
            <div className={styles.clsBtnWrap}>
              <button
                className={styles.clsBtn}
                onClick={() => closePayRecordPopup()}
              >
                <CloseIcon sx={{ fontSize: "20px", color: "#667085" }} />
              </button>
            </div>
            <div className={styles.formHeading}>Send Payment Link</div>
          </div>

          <form action="" className={styles.formWrap}>
            <div
              style={{
                maxHeight: "370px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
              className={styles.recordPayWrap}
            >
              <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                <div className={styles.fieldLabel}>Payment Name</div>
                <TextField
                  type="text"
                  id="name"
                  required
                  placeholder="₹"
                  variant="outlined"
                  inputProps={{ min: "0" }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#B1B1B1",
                      fontWeight: "300",
                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 16px",
                    },
                  }}
                  value={paymentName}
                  disabled
                />
              </FormControl>

              <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                <div className={styles.fieldLabel}>Amount </div>
                <TextField
                  type="number"
                  required
                  value={enteredAmount}
                  id="amount"
                  variant="outlined"
                  disabled
                  error={!!emiAmountError}
                  //   helperText={emiAmountError || " "}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#B1B1B1",
                      fontWeight: "300",
                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 16px",
                    },
                  }}
                />
              </FormControl>
            </div>
          </form>
        </div>
        <div className={styles.btnBottomSplit}>
          <CancelBtn onClick={closePayRecordPopup} />
          <SaveBtn
            onClick={handleSent}
            disabled={loader || emiAmountError !== ""}
            loader={loader}
            buttonText={"Send"}
          />
        </div>
      </div>
    </div>
  );
}

export default RandomPayLink;
