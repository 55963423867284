import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LensIcon from "@mui/icons-material/Lens";
import styles from "./Whatsapp.module.css";
import { Popover } from "@mui/material";
import { Box, Modal } from "@mui/material";
import setting from "../../../../../assets/images/setting.svg";
import { useNavigate } from "react-router";
import { useAuthUser } from "react-auth-kit";

function GupshupInfo({
  gupshupConfig,
  mailConfigsList,
  DeleteGupshup,
  SaveWhatsappConfiguration,
  whatsAppConfig,
  setWhatsAppConfig,
}: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const auth = useAuthUser();
  const authUser = auth();

  const formatter = (val: any) => {
    const date = new Date(val);
    const options: any = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };

  const open = Boolean(anchorEl);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleDeleteGupshup = () => {
    const data = {
      ...whatsAppConfig,
      apiKey: gupshupConfig[0]?.apiKey,
      appId: gupshupConfig[0]?.appId,
      appName: gupshupConfig[0]?.appName,
      phoneNumber: gupshupConfig[0]?.phoneNumber,
      status: "ARCHIVE",
      id: gupshupConfig[0]?.id,
      updatedByName:
        authUser?.institute[0].firstName +
        " " +
        authUser?.institute[0].lastName,
      updatedById: authUser?.institute[0]?.id,
    };
    DeleteGupshup(data);
    setOpenDeleteModal(false);
  };

  return (
    <div className={styles?.integratedGupshupContainer}>
      <div className={styles?.headerContainer}>
        <div className={styles?.integratedHeader}>Gupshup Integration</div>

        <div
          style={{
            border: "1px solid #d0d5dd",
            background: "#FFFFFF",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            height: "38px",
            width: "40px",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={"7px"}
            paddingBottom={"7px"}
            paddingLeft={"7px"}
            onClick={() => navigate("Settings", { state: mailConfigsList })}
            paddingRight={"7px"}
          >
            <img src={setting} alt="" />
          </Box>
        </div>
      </div>
      <div className={styles?.integratedGupshupModal}>
        <div className={styles?.gupShupConfigDetails}>
          <div
            style={{
              color: "#3F3E43",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {gupshupConfig[0]?.createdByName}
          </div>
          <div style={{ cursor: "pointer" }}>
            <MoreVertIcon
              style={{
                color: "#BDBFC3",
              }}
              onClick={handleOpenPopover}
            />
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  width: "120px",
                },
              }}
            >
              <div
                style={{
                  padding: "10px",
                  textAlign: "center",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setAnchorEl(null);
                }}
              >
                Remove
              </div>
            </Popover>
          </div>
        </div>
        <div className={styles?.gupShupConfigDetails}>
          <div
            style={{
              color: "#142C44",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Messaging Limit:NA
          </div>
          <div style={{ color: "#11C89B", fontSize: "14px" }}>
            <LensIcon
              style={{
                color: "#11C89B",
                fontSize: "8px",
                marginRight: "5px",
              }}
            />
            Live
          </div>
        </div>
        <div className={styles?.gupShupConfigDetails}>
          <div
            style={{
              color: "#667085",
              fontSize: "13px",
              fontWeight: "400",
            }}
          >
            {formatter(gupshupConfig[0]?.creationDate)}
          </div>
          <div
            style={{
              color: "#142C44",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Ph no:{gupshupConfig[0]?.phoneNumber}
          </div>
        </div>
      </div>
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "57%",
            left: "58%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            borderRadius: "5px",
            p: 3,
          }}
        >
          <div>
            <div className={styles.editFormPopupTitle}>
              Remove Gupshup Integration{" "}
            </div>
            <div className={styles.editFormPopupContent}>
              Are you sure you want to Remove this integration? All the messages
              related to this account will be removed.
            </div>
            <div className={styles.editFormPopupBtnsSplit}>
              <button
                className={styles.editFormPopupCancelBtn}
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
              >
                cancel
              </button>
              <button
                className={styles.editFormPopupRemoveBtn}
                onClick={handleDeleteGupshup}
              >
                Delete
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default GupshupInfo;
