import React, { useEffect, useState } from "react";
import styles from "./EmailSyncing.module.css";
import {
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ConstructionOutlined } from "@mui/icons-material";
import { getGoogleUrl } from "../test/getGoogleUrl";
import { useNavigate } from "react-router";

function EmailSyncingBox(props: any) {
  const {
    SaveEmailConfigurationDetails,
    emailConfigLoading,
    emailConfigError,
    emailConfigSuccess,
  } = props;
  const [emailConfigData, setEmailConfigData] = useState<any>(null);
  const [syncTime, setSyncTime] = useState<number>(0);
  const auth = useAuthUser();
  const navigate = useNavigate();

  const durations = [
    {
      duration: 0,
      durationLabel: "Now",
    },
    {
      duration: 30,
      durationLabel: "1 Month ago",
    },
    {
      duration: 90,
      durationLabel: "3 Months ago",
    },
    {
      duration: 180,
      durationLabel: "6 Months ago",
    },
  ];

  useEffect(() => {
    const user = auth();
    // const user = JSON.parse(localStorage.getItem("USER_DATA")!);
    const userFromLocalStorage = user?.institute[0];
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    let emailData: any = window?.location?.href?.split("&");
    if (code) {
      let data = {
        instituteId:
          userFromLocalStorage.instituteId && userFromLocalStorage.instituteId,
        emailProvider: "GMAIL",
        gmailCode: code,
        incomingImapObject: {
          user: user?.email,
          password: code,
          host: "23423423434",
          port: 12,
          tls: true,
          authTimeout: 3000,
        },
        outgoingImapObject: {
          user: user?.email,
          password: code,
          host: "23423423434",
          port: 12,
          tls: true,
          authTimeout: 3000,
        },
        status: "ACTIVE",
        connectionStatus: "AUTHENTICATED",
        scopes: [
          emailData[2]?.split("%20")[2],
          emailData[2]?.split("%20")[4],
          emailData[2]?.split("%20")[5],
        ],
        syncTime: syncTime,
      };
      setEmailConfigData(data);
    }
  }, [syncTime]);

  const handleSubmit = () => {
    // Perform any additional actions with emailConfigData
    SaveEmailConfigurationDetails(emailConfigData);
    setTimeout(() => {
      navigate("/app/inbound/Email", { state: 1 });
    }, 4000);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSyncTime = Number(event.target.value.split(" ")[0]);
    setSyncTime(selectedSyncTime);
  };

  const handleRoutingToGmailConsent = () => {
    const domain = window.location.host;
    const targetUrl = `https://${domain}/app/Inbound/EmailIntegration/EmailSyncing`;
    window.location.href = getGoogleUrl(targetUrl);
  };

  return (
    <>
      <div className={styles.emsilSyncBox}>
        {emailConfigLoading ? (
          <div>
            <p>Email sync in progress</p>
            <CircularProgress />
          </div>
        ) : (
          <>
            {emailConfigError ? (
              <Box>
                <div className={styles.emsilConsentBoxHead}>
                  <AiOutlineExclamationCircle
                    style={{
                      color: "red",
                      marginRight: "5px",
                      marginBottom: "3px",
                    }}
                  />
                  <div className={styles.emsilSyncBoxHead}>Access Denied</div>
                </div>
                <div className={styles.emsilSyncBoxDesc}>
                  Sorry,we can't process your Email Integration request since
                  you have not provided consent for Data access request on
                  Google Oauth screen.To integrate the email,please try again!
                </div>
                <Box sx={{ textAlign: "CENTER" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "capitalize" }}
                    fullWidth
                    onClick={handleRoutingToGmailConsent}
                  >
                    Try Again
                  </Button>
                </Box>
              </Box>
            ) : (
              <div>
                <div className={styles.emsilSyncBoxHead}>Email Integration</div>
                <div className={styles.emsilSyncBoxDesc}>
                  We have successfully connected your email (
                  {emailConfigData &&
                    emailConfigData?.incomingImapObject &&
                    emailConfigData?.incomingImapObject?.user}
                  ) to Nurture. Just select the frequency and you are good to
                  go.
                </div>
                <div className={styles.emsilSyncBoxRadioHead}>Sync From</div>
                <RadioGroup
                  aria-labelledby="status-label"
                  name="radio-buttons-group"
                  style={{
                    color: "#667085",
                    marginBottom: "10px",
                    position: "relative",
                  }}
                  value={`${syncTime} Months ago`}
                  onChange={handleRadioChange}
                >
                  {durations.map((item: any, index: number) => (
                    <FormControlLabel
                      key={index}
                      value={`${item.duration} Months ago`}
                      control={
                        <Radio sx={{ strokeWidth: 1, color: "#D0D5DD" }} />
                      }
                      label={
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#667085",
                            lineHeight: "20px",
                            textTransform: "capitalize",
                          }}
                        >
                          {item.durationLabel}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
                <Box sx={{ textAlign: "CENTER" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "capitalize" }}
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Start Syncing
                  </Button>
                </Box>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default EmailSyncingBox;
