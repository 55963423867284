import React from "react";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import styles from "../Managebatches.module.css";
import deleteIcon from "../../../../../../assets/images/delete-dustbin.svg";
import AddIcon from "@mui/icons-material/Add";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { updateBatchPayment } from "../../service/Batchslice";

const emiModesItems = [
  {
    id: 1,
    emiModeValue: "MONTHLY",
    emiModeLabel: "Monthly",
    days: 30,
  },
  {
    id: 2,
    emiModeValue: "QUARTERLY",
    emiModeLabel: "Quarterly",
    days: 60,
  },
  {
    id: 3,
    emiModeValue: "HALF_YEARLY",
    emiModeLabel: "Half yearly",
  },
  {
    id: 4,
    emiModeValue: "YEARLY",
    emiModeLabel: "Yearly",
  },
];

function EditBatchMembership(props: any) {
  const {
    membershipPayEdit,
    setMembershipPayEdit,
    batchData,
    loading,
    success_updateCourse,
    typeSelected,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const batchStartDateMilliSeconds =
    batchData?.startDate?.toString()?.length !== 13
      ? batchData?.startDate * 1000
      : batchData?.startDate;
  const batchEndDateMilliSeconds =
    batchData?.endDate?.toString()?.length !== 13
      ? batchData?.endDate * 1000
      : batchData?.endDate;

  const numberOfDays =
    Math.floor(
      (batchEndDateMilliSeconds - batchStartDateMilliSeconds) /
        (1000 * 60 * 60 * 24)
    ) + 1;

  const availableEmiModes = (() => {
    switch (true) {
      case numberOfDays > 360:
        return emiModesItems;

      case numberOfDays > 180:
        return emiModesItems.filter((item) => item.emiModeValue !== "YEARLY");

      case numberOfDays >= 60:
        return emiModesItems.filter(
          (item) =>
            item.emiModeValue === "MONTHLY" || item.emiModeValue === "QUARTERLY"
        );

      case numberOfDays >= 30:
        return emiModesItems.filter((item) => item.emiModeValue === "MONTHLY");

      default:
        return []; // No modes available if number of days is less than 30
    }
  })();

  const [rows, setRows] = React.useState([
    {
      id: 1,
      emiModeValue: "",
      emiPrice: 0,
      isEmiModeValid: true,
      isPriceValid: true,
    },
  ]);
  const [selectedEmiModes, setSelectedEmiModes] = React.useState<string[]>([]);
  const [deletePayment, setDeletePayment] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const handleAddRow = () => {
    if (rows?.length < emiModesItems?.length) {
      setRows([
        ...rows,
        {
          id: rows.length + 1,
          emiModeValue: "",
          emiPrice: 0,
          isEmiModeValid: true,
          isPriceValid: true,
        },
      ]);
    }
  };

  const handleRemoveRow = (id: number) => {
    const updatedRows = rows
      ?.filter((row) => row.id !== id)
      ?.map((row: any, index: number) => ({
        ...row,
        id: index + 1,
      }));
    const updatedSelectedEmiModes = updatedRows?.map((row) => row.emiModeValue);
    setRows(updatedRows);
    setSelectedEmiModes(updatedSelectedEmiModes);
  };

  const handleEmiModeChange = (id: number, value: string) => {
    const updatedRows = rows?.map((row) =>
      row.id === id
        ? { ...row, emiModeValue: value, isEmiModeValid: true }
        : row
    );
    setRows(updatedRows);
    setSelectedEmiModes(updatedRows?.map((row) => row.emiModeValue));
  };

  const handleEmiPriceChange = (id: number, value: string) => {
    const updatedRows = rows?.map((row) =>
      row.id === id
        ? {
            ...row,
            emiPrice: parseFloat(value),
            isPriceValid: value !== "" && parseFloat(value) > 0 ? true : false,
          }
        : row
    );
    setRows(updatedRows);
  };

  const cancelButtonClick = () => {
    setRows([
      {
        id: 1,
        emiModeValue: "",
        emiPrice: 0,
        isEmiModeValid: true,
        isPriceValid: true,
      },
    ]);
    setSelectedEmiModes([]);
    setMembershipPayEdit(false);
  };

  const isModeUniqueCheck = (rows: any, row: any) => {
    return !rows?.some(
      (r: any) => r?.id !== row?.id && r?.emiModeValue === row?.emiModeValue
    );
  };
  const hasEmptyPaymentMode = rows?.some((row) => row.emiModeValue === "");
  const hasPriceNotGreaterThanZero = rows?.every((row) => row.emiPrice > 0);

  const handleMembershipPayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows?.map((item: any) => ({
        frequency: item.emiModeValue,
        emiAmount: parseFloat(item.emiPrice),
        finalEmiAmount: parseFloat(item.emiPrice),
        discount: item.discount ? item.discount : 0,
        discountMetric: item.discountMetric ? item.discountMetric : "",
      }));

      const body = {
        batchId: batchData?.id,
        paymentType: "MEMBERSHIP",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      if (!hasEmptyPaymentMode && hasPriceNotGreaterThanZero) {
        dispatch<any>(updateBatchPayment(body));
      }
    } catch (error) {}
  };

  const handleDelete = (item: any) => {
    setDeletePayment(item);
    setDeleteModal(true);
  };
  const handleDeleteClosePopup = () => {
    setDeletePayment("");
    setDeleteModal(false);
  };

  const handleDeletePayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows
        ?.filter((item: any) => item?.id !== deletePayment?.id)
        ?.map((item: any) => ({
          frequency: item.emiModeValue,
          emiAmount: parseFloat(item.emiPrice),
          discount: item.discount,
          discountMetric: item.discountMetric,
        }));

      const body = {
        batchId: batchData?.id,
        paymentType: "MEMBERSHIP",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      dispatch<any>(updateBatchPayment(body));
    } catch (error) {}
  };

  React.useEffect(() => {
    if (batchData?.paymentDetails?.paymentData?.paymentStructure?.length > 0) {
      const defaultPaymentStructure =
        batchData?.paymentDetails?.paymentData?.paymentStructure?.map(
          (item: any, index: number) => ({
            id: index + 1,
            emiModeValue: item.frequency,
            emiPrice: item.emiAmount,
            isEmiModeValid: true,
            isPriceValid: true,
            discount: item.discount,
            discountMetric: item.discountMetric,
          })
        );
      setRows(defaultPaymentStructure);
      const defaultModes =
        batchData?.paymentDetails?.paymentData?.paymentStructure?.map(
          (item: any) => item.frequency
        );
      setSelectedEmiModes(defaultModes);
    }
  }, [batchData]);

  React.useEffect(() => {
    if (success_updateCourse !== true && success_updateCourse !== false) {
      setDeletePayment("");
      setDeleteModal(false);
      //   setMembershipPayEdit(false);
    }
  }, [success_updateCourse]);

  return (
    <div>
      <div>
        {rows?.map((item: any, index: number) => (
          <div className={styles.membershipTypeRow} key={index}>
            <div>
              <div className={styles.membershipTypeFieldLabel}>Frequency</div>
              <FormControl
                sx={{
                  minWidth: 240,
                  ".MuiOutlinedInput-input": {
                    padding: "7px 14px",
                  },
                }}
              >
                <Select
                  id={`emi-mode-select-${item.id}`}
                  MenuProps={{
                    style: {
                      zIndex: 999999,
                      fontSize: "14px",
                    },
                    PaperProps: {
                      style: {
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiSelect-outlined": {
                      fontSize: "14px",
                      color: "#142C44",
                    },
                  }}
                  value={item.emiModeValue}
                  onChange={(event) =>
                    handleEmiModeChange(item.id, event.target.value as string)
                  }
                  error={
                    !item?.isEmiModeValid || !isModeUniqueCheck(rows, item)
                  }
                >
                  {availableEmiModes
                    ?.filter(
                      (modeType) =>
                        !selectedEmiModes.includes(modeType.emiModeValue) ||
                        modeType.emiModeValue === item.emiModeValue
                    )
                    ?.map((modeType) => (
                      <MenuItem
                        key={modeType.id}
                        value={modeType.emiModeValue}
                        sx={{
                          fontSize: "12PX",
                          color: "#667085",
                          textTransform: "capitalize",
                          fontWeight: "400",
                        }}
                      >
                        {modeType.emiModeLabel}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <div className={styles.membershipTypeFieldLabel}>
                Membership Fees
              </div>

              <TextField
                id="emiPrice"
                variant="outlined"
                type="number"
                inputProps={{
                  sx: {
                    width: "240px",
                    "&::placeholder": {
                      fontWeight: "400",
                      fontSize: "14px",
                    },

                    "&.MuiOutlinedInput-input": {
                      padding: "8px",
                    },
                  },
                }}
                value={item.emiPrice}
                onChange={(event) =>
                  handleEmiPriceChange(item.id, event.target.value)
                }
                error={!item?.isPriceValid}
                helperText={
                  !item?.isPriceValid ? "Please fill valid amount" : ""
                }
              />
            </div>
            <div>
              {rows?.length > 1 && (
                <button
                  className={styles.deleteTypeBtn}
                  onClick={() => handleRemoveRow(item.id)}
                  style={{ marginTop: "40PX" }}
                >
                  <img src={deleteIcon} alt="delete" />
                </button>
              )}
            </div>
          </div>
        ))}
        <div className={styles.addMoreTypeBtnWrap}>
          {rows?.length < 4 && (
            <button className={styles.addMoreTypeBtn} onClick={handleAddRow}>
              <AddIcon sx={{ fontSize: "18px", marginRight: "6PX" }} /> Add more
            </button>
          )}
        </div>
        <div className={styles.editSectionBtnWrap}>
          <CancelBtn onClick={() => cancelButtonClick()} />
          <SaveBtn
            onClick={handleMembershipPayment}
            disabled={
              hasEmptyPaymentMode || !hasPriceNotGreaterThanZero || loading
            }
            loader={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default EditBatchMembership;
