import React, { useState, useCallback, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./Subscription.module.css";
import {
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Popover,
  Skeleton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PaymentPopup from "../PaymentPopup/PaymentPopup";
import PayNowPopup from "./PayNowPopup";
import dropdownIcon from "../Assets/accordion-icon.svg";
import RemoveBranchPopup from "./RemovePopup/RemoveBranchPopup";
import { useSelector } from "react-redux";
import RemoveUserPopup from "./RemovePopup/RemoveUserPopup";
import { useNavigate } from "react-router-dom";
import RemoveBranchLicensePopup from "./RemovePopup/RemoveBranchLicensePopup";
import RemoveUserLicensePopup from "./RemovePopup/RemoveUserLicensePopup";
import moment from "moment";
import ManageSubscriptionCancelPopup from "./ManageSubscriptionCancelPopup";
import ManageSubscriptionBackPopup from "./ManageSubscriptionBackPopup";
import AccountGracePeriod from "./WarningMessages/AccountGracePeriod";
import AccountArchived from "./WarningMessages/AccountArchived";
import AccountBlocked from "./WarningMessages/AccountBlocked";
import { useAuthUser } from "react-auth-kit";
import {
  resetBranchRemovalSuccess,
  resetUserRemovalSuccess,
} from "../../service/Billingslice";
import { useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountsUnarchived from "./WarningMessages/AccountsUnarchived";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { getStaffListWithCount } from "../../../ManageStaff/service/Staffslice";

function ManageSubscription(props: any) {
  const {
    GetStaffList,
    GetBranchList,
    GetBillingByInstituteId,
    instituteBillingData,
    GetSubscriptionByInstituteId,
    instituteSubscriptionData,
    CreateBilling,
    CheckPromocodeByName,
    promocodeByName,
    RemoveUserOrUserLicense,
    RemoveBranchOrBranchLicense,
    branchRemovalSuccess,
    userRemovalSuccess,
    userRemovalError,
    branchRemovalError,
    errorBilling,
    successBilling,
    userRemovalLoading,
    branchRemovalLoading,
    loadingBilling,
    GetInstituteById,
    promocodeError,
    promocodeloading,
    promocodesuccess,
    billingPaymentLink,
  } = props;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const auth = useAuthUser();
  const authUser = auth();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasBillingAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
  const instituteDetail = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const branches = useSelector((state: any) => state.branch.branchList);
  const staffs = useSelector((state: any) => state.staff.staffListWithCount);
  const inactiveUsers =
    staffs && staffs?.filter((item: any) => item.status === "INACTIVE");

  const [cycle, setCycle] = useState("Monthly");
  const upcomingMonthBillDate = instituteDetail?.upcomingBillingPaidEndDate
    ? instituteDetail?.upcomingBillingPaidEndDate
    : 0;
  let daysInNextMonth = 0;
  if (upcomingMonthBillDate !== 0) {
    const date = moment(upcomingMonthBillDate);
    const previousMonth = date?.clone()?.subtract(1, "month");
    daysInNextMonth = previousMonth?.daysInMonth();
  }

  const [expanded, setExpanded] = useState<string | false>(false);
  const [userExpanded, setUserExpanded] = useState<string | false>(false);
  const [removeBranchModal, setRemoveBranchModal] = React.useState(false);
  const [removeUserModal, setRemoveUserModal] = React.useState(false);
  const [cancelModal, setCancelModal] = React.useState(false);
  const [backModal, setBackModal] = React.useState(false);

  const [userEditSelect, setUserEditSelect] = React.useState<any>("");
  const [branchEditSelect, setBranchEditSelect] = React.useState<any>("");

  const [removeBranchLicenseModal, setRemoveBranchLicenseModal] =
    React.useState(false);
  const [removeUserLicenseModal, setRemoveUserLicenseModal] =
    React.useState(false);
  const [branchCount, setBranchCount] = React.useState<number>(0);
  const [freeUserCount, setFreeUserCount] = React.useState<number>(0);

  const [userCount, setUserCount] = React.useState<number>(0);

  const [removeAction, setRemoveAction] = useState<string>("");
  const [removeBranchError, setRemoveBranch] = useState<boolean>(false);
  const [userBranchError, setUserBranchError] = useState<boolean>(false);
  const [branchAssignyError, setBranchAssignyError] = useState<boolean>(false);

  const [assignyError, setAssignyError] = useState<boolean>(false);

  const [removeUserDetails, setRemoveUserDetails] = React.useState({
    licenserId: "",
    benefitCode: "USER",
    instituteId: "",
    deleteLicense: false,
    assigedToId: "",
    assignedToName: "",
    branchId: null,
    branchName: null,
    updatedById: authUser?.institute[0]?.id,
    updatedByName: authUser?.firstName + " " + authUser?.lastName,
  });

  const [removeUserLicense, setRemoveUserLicense] = React.useState({
    licenserId: "",
    benefitCode: "USER",
    instituteId: "",
    deleteLicense: true,
    assigedToId: "",
    assignedToName: "",
    branchId: null,
    branchName: null,
    updatedById: authUser?.institute[0]?.id,
    updatedByName: authUser?.firstName + " " + authUser?.lastName,
  });

  const [removeBranchDetails, setRemoveBranchDetails] = React.useState({
    licenserId: "",
    benefitCode: "BRANCH",
    instituteId: "",
    deleteLicense: false,
    assigedToId: "",
    assignedToName: "",
    branchId: null,
    branchName: null,
  });

  const [removeBranchLicense, setRemoveBranchLicense] = React.useState({
    licenserId: "",
    benefitCode: "BRANCH",
    instituteId: "",
    deleteLicense: true,
    assigedToId: "",
    assignedToName: "",
    branchId: null,
    branchName: null,
  });

  // Cancel and Back modal
  const cancelModalClose = () => setCancelModal(false);
  const cancelModalOpen = () => setCancelModal(true);
  const cancelConfirm = () => {
    setCancelModal(false);
    setUserCount(0);
    setBranchCount(0);
  };

  const backModalClose = () => setBackModal(false);
  const backModalOpen = () => setBackModal(true);
  const backConfirm = () => {
    setBackModal(false);
  };

  function handleGoBack() {
    navigate("/app/manage/Billing");
  }

  // Branch and User modal

  const openRemoveBranchModal = () => {
    setRemoveBranchModal(true);
  };

  const closeRemoveBranchModal = () => {
    setRemoveBranchModal(false);
    setRemoveBranchDetails({
      ...removeBranchDetails,
      branchId: null,
      branchName: null,
      assigedToId: "",
      assignedToName: "",
      deleteLicense: false,
    });
    setRemoveAction("");
  };

  const openRemoveBranchLicenseModal = () => {
    setRemoveBranchLicenseModal(true);
  };

  const closeRemoveBranchLicenseModal = () => {
    setRemoveBranchLicenseModal(false);
  };

  const openRemoveUserModal = () => {
    setRemoveUserModal(true);
  };

  const closeRemoveUserModal = () => {
    setRemoveUserModal(false);
    setAssignyError(false);
  };

  const openUserLicenseModal = () => {
    setRemoveUserLicenseModal(true);
  };

  const closeUserLicenseModal = () => {
    setRemoveUserLicenseModal(false);
    setAssignyError(false);
  };

  // Branch Popovers
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [unUsedBranchAnchorEl, setUnUsedBranchAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const openEditPopover = Boolean(anchorEl);
  const handleOpenEditPopover = (
    event: React.MouseEvent<HTMLElement>,
    branch: any
  ) => {
    setAnchorEl(event.currentTarget);
    setBranchEditSelect(branch);
    setRemoveBranchDetails({
      ...removeBranchDetails,
      licenserId: branch?.licensedEntityId,
    });
  };
  const handleCloseEditPopver = () => {
    setAnchorEl(null);
  };

  const openUnUsedBranchPopover = Boolean(unUsedBranchAnchorEl);
  const openUnUsedBranchEditPopover = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setUnUsedBranchAnchorEl(event.currentTarget);
    // setBranchEditSelect(branch);
  };
  const closeUnUsedBranchEditPopver = () => {
    setUnUsedBranchAnchorEl(null);
  };

  // User Popovers
  const [userAnchorEl, setUserAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const [unUsedUserAnchorEl, setUnUsedUserAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [unUsedAddOnUserAnchorEl, setUnUsedAddOnUserAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const openEditUserPopover = Boolean(userAnchorEl);
  const handleOpenEditUserPopover = (
    event: React.MouseEvent<HTMLElement>,
    user: any
  ) => {
    setUserAnchorEl(event.currentTarget);
    setUserEditSelect(user);
    setRemoveUserDetails({
      ...removeUserDetails,
      licenserId: user?.licensedEntityId,
    });
  };
  const handleCloseEditUserPopover = () => {
    setUserAnchorEl(null);
  };

  const openUnUsedUserPopover = Boolean(unUsedUserAnchorEl);
  const openUnUsedUserEditPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>, user: any) => {
      setUnUsedUserAnchorEl(event.currentTarget);
      setRemoveUserLicense({
        ...removeUserLicense,
        licenserId: user?.licensedEntityId,
      });
    },
    [removeUserLicense]
  );
  const closeUnUsedUserEditPopover = () => {
    setUnUsedUserAnchorEl(null);
  };

  const openUnUsedAddOnUserPopover = Boolean(unUsedAddOnUserAnchorEl);
  const openUnUsedAddOnUserEditPopover = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setUnUsedAddOnUserAnchorEl(event.currentTarget);
  };
  const closeUnUsedAddOnUserEditPopover = () => {
    setUnUsedAddOnUserAnchorEl(null);
  };

  // Billing Cycle
  const handleChangeCycle = (event: SelectChangeEvent) => {
    setCycle(event.target.value as string);
  };

  const instituteDetails = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );
  const instituteBillingStatus =
    instituteDetail && instituteDetail.billingStatus;

  const instituteBillingStatusLocal =
    instituteDetails && instituteDetails.billingStatus;

  // Subscription Status
  const billingStatus =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails.billingStatus;

  const trialPlan =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails.isTrail;

  const dueDate = instituteBillingData && instituteBillingData[0]?.endDate;

  const benefitLicenses =
    instituteBillingData && instituteBillingData[0]?.benefitLicenses;

  const addOnUserLicenseCount =
    benefitLicenses &&
    benefitLicenses.filter(
      (item: any) => item.benefitCode === "USER" && item.benefitType === "ADDON"
    ).length;

  const usedUserLicenses =
    benefitLicenses &&
    benefitLicenses.filter(
      (item: any) =>
        item.benefitCode === "USER" && item.licensedEntityName !== ""
    );

  const usedBranchLicenses =
    benefitLicenses &&
    benefitLicenses.filter(
      (item: any) =>
        item.benefitCode === "BRANCH" && item.licensedEntityName !== ""
    );

  const [unUsedBranchLicenses, setUnUsedBranchLicenses] =
    React.useState<any>(null);
  const [unUsedBranchRemoveCount, setUnUsedBranchRemoveCount] =
    React.useState<number>(0);

  const [unUsedUserLicenses, setUnUsedUserLicenses] = React.useState<any>(null);
  const [unUsedAddOnUserLicenses, setUnUsedAddOnUserLicenses] =
    React.useState<any>(null);
  const [unUsedUserRemoveCount, setUnUsedUserRemoveCount] =
    React.useState<number>(0);
  const [convertedAddOnUserCount, setConvertedAddOnUser] =
    React.useState<number>(0);
  const [removedBundledUserCount, setRemovedBundledUserCount] =
    React.useState<number>(0);

  React.useEffect(() => {
    const data = benefitLicenses?.filter(
      (item: any) =>
        item.benefitCode === "BRANCH" && item.licensedEntityName === ""
    );
    if (data && data?.length > 0) {
      setUnUsedBranchLicenses(data);
    } else {
      setUnUsedBranchLicenses(null);
    }
  }, [benefitLicenses]);

  React.useEffect(() => {
    const data = benefitLicenses?.filter(
      (item: any) =>
        item.benefitCode === "USER" &&
        item.licensedEntityName === "" &&
        item.benefitType === "ADDON"
    );
    if (data && data?.length > 0) {
      setUnUsedAddOnUserLicenses(data);
    } else {
      setUnUsedAddOnUserLicenses(null);
    }
  }, [benefitLicenses]);

  React.useEffect(() => {
    const data = benefitLicenses?.filter(
      (item: any) =>
        item.benefitCode === "USER" &&
        item.licensedEntityName === "" &&
        item.benefitType === "BUNDLED"
    );
    if (data && data?.length > 0) {
      setUnUsedUserLicenses(data);
    } else {
      setUnUsedUserLicenses(null);
    }
  }, [benefitLicenses]);

  const removeUnUsedBranchArchived = () => {
    if (unUsedBranchLicenses && unUsedBranchLicenses.length > 0) {
      // Remove the first item from the array
      const updatedLicenses = [...unUsedBranchLicenses];
      updatedLicenses.shift();

      // Update the state to reflect the changes

      setUnUsedBranchLicenses(updatedLicenses);

      // Increment the count
      setUnUsedBranchRemoveCount(unUsedBranchRemoveCount + 1);
    }

    if (unUsedUserLicenses && unUsedUserLicenses?.length >= 3) {
      // Remove the first 3 items from the array
      const updatedLicenses = [...unUsedUserLicenses];
      updatedLicenses.splice(0, 3);

      // Update the state to reflect the changes
      setUnUsedUserLicenses(updatedLicenses);
      setRemovedBundledUserCount(removedBundledUserCount + 3);
    } else if (unUsedUserLicenses?.length === 2) {
      const updatedLicenses = [...unUsedUserLicenses];
      updatedLicenses.splice(0, 2);

      setUnUsedUserLicenses(updatedLicenses);
      setConvertedAddOnUser(convertedAddOnUserCount + 1);
      setRemovedBundledUserCount(removedBundledUserCount + 2);
    } else if (unUsedUserLicenses?.length === 1) {
      const updatedLicenses = [...unUsedUserLicenses];
      updatedLicenses.splice(0, 1);

      setUnUsedUserLicenses(updatedLicenses);
      setConvertedAddOnUser(convertedAddOnUserCount + 2);
      setRemovedBundledUserCount(removedBundledUserCount + 1);
    } else if (unUsedUserLicenses?.length === 0) {
      setConvertedAddOnUser(convertedAddOnUserCount + 3);
    }
  };

  const removeUnUsedUserArchived = () => {
    if (unUsedAddOnUserLicenses && unUsedAddOnUserLicenses.length > 0) {
      // Remove the first item from the array
      const updatedLicenses = [...unUsedAddOnUserLicenses];
      updatedLicenses.shift();

      // Update the state to reflect the changes
      setUnUsedAddOnUserLicenses(updatedLicenses);

      // Increment the count
      setUnUsedUserRemoveCount(unUsedUserRemoveCount + 1);
    }
  };

  // Default Prices
  // const monthlyBranchLicensePrice = 2499;
  // const monthlyUserLicensePrice = 399;
  const monthlyBranchLicensePrice = instituteBillingData?.[0]
    ?.instituteSubscriptionDetails?.branchCost
    ? instituteBillingData?.[0]?.instituteSubscriptionDetails?.branchCost
    : 2499;
  const monthlyUserLicensePrice = instituteBillingData?.[0]
    ?.instituteSubscriptionDetails?.userCost
    ? instituteBillingData?.[0]?.instituteSubscriptionDetails?.userCost
    : 399;

  // Locked and Grace total Prices

  const billableBranchLicensePrice = instituteBillingData?.[0]?.branchCost;
  const billableUserLicensePrice = instituteBillingData?.[0]?.userCost;

  // Days Calculation
  const currentDate = moment();
  const daysInCurrentMonth = currentDate.daysInMonth();
  const daysRemainingInMonth = daysInCurrentMonth - currentDate.date() + 1;

  const startDateFromBillingData =
    instituteBillingData && instituteBillingData[0]?.startDate;
  const lockedTrialPlanEndDate =
    instituteBillingData && instituteBillingData[0]?.endDate;
  let daysInLockedTrialPlan = 0;

  if (startDateFromBillingData) {
    // Convert Unix timestamp to a Moment.js object
    const startDate = moment(startDateFromBillingData);
    const daysInlockedTrialMonth = startDate?.daysInMonth();

    daysInLockedTrialPlan = daysInlockedTrialMonth - startDate.date() + 1;
  }

  // Branch Cost for Month
  const dailyBranchCostByMonth = monthlyBranchLicensePrice / daysInCurrentMonth;
  const dailyBranchCostForNextMonth =
    monthlyBranchLicensePrice / daysInNextMonth;

  const branchCostForCurrentMonth =
    dailyBranchCostByMonth * daysRemainingInMonth;
  const branchCostForNextMonth = dailyBranchCostForNextMonth * daysInNextMonth;

  const branchCostForLocked = dailyBranchCostByMonth * daysInLockedTrialPlan;

  // User Cost for Month
  const dailyUserCostByMonth = monthlyUserLicensePrice / daysInCurrentMonth;
  const dailyUserCostForNextMonth = monthlyUserLicensePrice / daysInNextMonth;

  const userCostForCurrentMonth = dailyUserCostByMonth * daysRemainingInMonth;
  const userCostForNextMonth = dailyUserCostForNextMonth * daysInNextMonth;

  const userCostForLocked = dailyUserCostByMonth * daysInLockedTrialPlan;

  // Newly Added total user count
  const totalUserCount = freeUserCount + userCount;

  // Plan Benefits Array
  const planBenefits =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails &&
    instituteBillingData[0]?.instituteSubscriptionDetails?.plans[0]
      .planBenefits;

  const filteredUsersCount =
    benefitLicenses &&
    benefitLicenses.filter((item: any) => item.benefitCode === "USER").length;

  const filteredExtraUsersCount =
    benefitLicenses &&
    benefitLicenses.filter(
      (item: any) => item.benefitCode === "USER" && item.benefitCost !== 0
    ).length;

  const totalFilteredExtraUsersCount =
    filteredExtraUsersCount +
    (billingStatus === "ARCHIVED" && convertedAddOnUserCount > 0
      ? convertedAddOnUserCount
      : 0);

  const filteredExtraUsers =
    benefitLicenses &&
    benefitLicenses.filter(
      (item: any) => item.benefitCode === "USER" && item.benefitCost !== 0
    );

  const filteredExtraUsersPrice = filteredExtraUsers
    ? filteredExtraUsers.reduce(
        (total: number, item: any) => total + item.benefitMonthlyCost,
        0
      )
    : 0;

  // Current Branch Data
  const filteredBranchCount =
    planBenefits &&
    planBenefits.filter((item: any) => item.benefitCode === "BRANCH").length;

  const filteredBranch =
    benefitLicenses &&
    benefitLicenses.filter((item: any) => item.benefitCode === "BRANCH");

  const totalBranchPrice = filteredBranch
    ? filteredBranch.reduce(
        (total: number, item: any) => total + item.benefitMonthlyCost,
        0
      )
    : 0;
  // For billing user and branch count

  const [paymentBranchCountData, setPaymentBranchCountData] =
    useState<number>(0);
  const [paymentUserCountData, setPaymentUserCountData] = useState<number>(0);

  useEffect(() => {
    if (filteredBranchCount) {
      const newPaymentBranchCountData =
        filteredBranchCount || branchCount
          ? filteredBranchCount +
            branchCount -
            (billingStatus === "ARCHIVED" ? unUsedBranchRemoveCount : 0)
          : 0;
      setPaymentBranchCountData(newPaymentBranchCountData);
    }
  }, [branchCount, filteredBranchCount, unUsedBranchRemoveCount]);

  useEffect(() => {
    if (filteredUsersCount) {
      const newPaymentUserCountData =
        filteredUsersCount || totalUserCount
          ? filteredUsersCount +
            totalUserCount -
            (billingStatus === "ARCHIVED"
              ? unUsedUserRemoveCount + removedBundledUserCount
              : 0)
          : 0;

      setPaymentUserCountData(newPaymentUserCountData);
    }
  }, [
    totalUserCount,
    filteredUsersCount,
    unUsedUserRemoveCount,
    removedBundledUserCount,
  ]);

  // Price Caluculation

  // Default Branch License Price
  const trialBranchLicensePrice =
    branchCostForCurrentMonth !== null && branchCostForCurrentMonth !== 0
      ? +(1 * branchCostForCurrentMonth).toFixed(2)
      : 0;

  // Locked and in trial plan Branch and User License Price

  // Archived Branch and User License Price

  const archiveBranchLicensePrice =
    branchCostForCurrentMonth !== null && branchCostForCurrentMonth !== 0
      ? +(filteredBranchCount * branchCostForCurrentMonth).toFixed(2)
      : 0;

  const archiveUserLicensePrice =
    userCostForCurrentMonth !== null && userCostForCurrentMonth !== 0
      ? +(filteredExtraUsersCount * userCostForCurrentMonth).toFixed(2)
      : 0;

  const archivedRemovedBranchLicensePrice =
    branchCostForCurrentMonth !== null &&
    branchCostForCurrentMonth !== 0 &&
    unUsedBranchRemoveCount > 0
      ? +(unUsedBranchRemoveCount * branchCostForCurrentMonth).toFixed(2)
      : 0;

  const archivedRemovedBranchLicensePriceMonthly =
    unUsedBranchRemoveCount > 0
      ? +(unUsedBranchRemoveCount * monthlyBranchLicensePrice).toFixed(2)
      : 0;

  const archivedRemoveUserLicensePrice =
    userCostForCurrentMonth !== null && userCostForCurrentMonth !== 0
      ? +(unUsedUserRemoveCount * userCostForCurrentMonth).toFixed(2)
      : 0;

  const archivedRemoveUserLicensePriceMonthly =
    unUsedUserRemoveCount > 0
      ? +(unUsedUserRemoveCount * monthlyUserLicensePrice).toFixed(2)
      : 0;

  const convertedAddOnUserCountPrice =
    userCostForCurrentMonth !== null && userCostForCurrentMonth !== 0
      ? +(convertedAddOnUserCount * userCostForCurrentMonth).toFixed(2)
      : 0;

  const convertedAddOnUserCountPriceMonthly =
    convertedAddOnUserCount > 0
      ? +(convertedAddOnUserCount * monthlyUserLicensePrice).toFixed(2)
      : 0;

  const archivedTotalMonthlyPrice =
    parseFloat(archiveBranchLicensePrice.toFixed(2)) +
    parseFloat(archiveUserLicensePrice.toFixed(2)) -
    (billingStatus === "ARCHIVED"
      ? parseFloat(archivedRemovedBranchLicensePrice.toFixed(2))
      : 0) -
    (billingStatus === "ARCHIVED"
      ? parseFloat(archivedRemoveUserLicensePrice.toFixed(2))
      : 0) +
    (billingStatus === "ARCHIVED" && convertedAddOnUserCount > 0
      ? parseFloat(convertedAddOnUserCountPrice.toFixed(2))
      : 0);

  // Added Branch License Price
  const addedBranchLicensePrice =
    branchCostForCurrentMonth !== null && branchCostForCurrentMonth !== 0
      ? +(branchCount * branchCostForCurrentMonth).toFixed(2)
      : 0;

  const addedBranchLicensePriceMonthly =
    branchCostForCurrentMonth !== null && branchCostForCurrentMonth !== 0
      ? +(branchCount * monthlyBranchLicensePrice).toFixed(2)
      : 0;

  const addedBranchLicensePriceNextMonth =
    branchCostForNextMonth !== null && branchCostForNextMonth !== 0
      ? +(branchCount * branchCostForNextMonth).toFixed(2)
      : 0;

  // Added User License Price
  const addedUserLicensePrice =
    userCostForCurrentMonth !== null && userCostForCurrentMonth !== 0
      ? +(userCount * userCostForCurrentMonth).toFixed(2)
      : 0;

  const addedUserLicensePriceMonthly =
    userCostForCurrentMonth !== null && userCostForCurrentMonth !== 0
      ? +(userCount * monthlyUserLicensePrice).toFixed(2)
      : 0;

  const addedUserLicensePriceNextMonth =
    userCostForNextMonth !== null && userCostForNextMonth !== 0
      ? +(userCount * userCostForNextMonth).toFixed(2)
      : 0;

  // Branch License Total
  const totalBranchLicensePrice = (
    parseFloat(totalBranchPrice.toFixed(2)) +
    parseFloat(addedBranchLicensePriceMonthly.toFixed(2)) -
    (billingStatus === "ARCHIVED"
      ? parseFloat(archivedRemovedBranchLicensePriceMonthly.toFixed(2))
      : 0) +
    (upcomingMonthBillDate !== 0
      ? parseFloat(addedBranchLicensePriceNextMonth?.toFixed(2))
      : 0)
  ).toFixed(2);

  // Billable User License Total
  const totalUserLicensePrice = (
    parseFloat(filteredExtraUsersPrice.toFixed(2)) +
    parseFloat(addedUserLicensePriceMonthly.toFixed(2)) -
    (billingStatus === "ARCHIVED"
      ? parseFloat(archivedRemoveUserLicensePriceMonthly.toFixed(2))
      : 0) +
    (billingStatus === "ARCHIVED"
      ? parseFloat(convertedAddOnUserCountPriceMonthly.toFixed(2))
      : 0) +
    (upcomingMonthBillDate !== 0
      ? parseFloat(addedUserLicensePriceNextMonth?.toFixed(2))
      : 0)
  ).toFixed(2);

  // Total Current Monthly Bill
  const totalCurrentMonthlyBilling = totalBranchPrice + filteredExtraUsersPrice;

  const lockedBranchCurrentMonthlyBilling =
    branchCostForLocked * filteredBranchCount;
  const lockedUserCurrentMonthlyBilling =
    userCostForLocked * filteredExtraUsersCount;

  // const lockedCurrentMonthlyBilling =
  //   parseFloat(lockedBranchCurrentMonthlyBilling.toFixed(2)) +
  //   parseFloat(lockedUserCurrentMonthlyBilling.toFixed(2));

  const lockedCurrentBillableAmount =
    parseFloat(billableBranchLicensePrice?.toFixed(2)) +
    parseFloat(billableUserLicensePrice?.toFixed(2));

  // Total Monthly Bill Estimate
  const totalMonthlyBilling = (
    parseFloat(totalCurrentMonthlyBilling.toFixed(2)) +
    parseFloat(addedBranchLicensePriceMonthly.toFixed(2)) +
    parseFloat(addedUserLicensePriceMonthly.toFixed(2)) -
    (billingStatus === "ARCHIVED"
      ? parseFloat(archivedRemovedBranchLicensePriceMonthly.toFixed(2)) +
        parseFloat(archivedRemoveUserLicensePriceMonthly.toFixed(2))
      : 0) +
    (billingStatus === "ARCHIVED"
      ? parseFloat(convertedAddOnUserCountPriceMonthly.toFixed(2))
      : 0) +
    (upcomingMonthBillDate !== 0
      ? parseFloat(addedBranchLicensePriceNextMonth?.toFixed(2))
      : 0) +
    (upcomingMonthBillDate !== 0
      ? parseFloat(addedUserLicensePriceNextMonth?.toFixed(2))
      : 0)
  ).toFixed(2);

  // Due Now Price

  const dueNowPrice =
    // ((billingStatus === "GRACE_PERIOD" || billingStatus === "LOCKED") &&
    // !trialPlan
    //   ? parseFloat(lockedCurrentMonthlyBilling.toFixed(2))
    //   : 0) +
    (
      ((billingStatus === "GRACE_PERIOD" || billingStatus === "LOCKED") &&
      !trialPlan
        ? parseFloat(lockedCurrentBillableAmount?.toFixed(2))
        : 0) +
      (instituteBillingData &&
      billingStatus === "ARCHIVED" &&
      (!trialPlan || trialPlan)
        ? parseFloat(archivedTotalMonthlyPrice.toFixed(2))
        : 0) +
      ((billingStatus === "ACTIVE" || billingStatus === "LOCKED") && trialPlan
        ? parseFloat(trialBranchLicensePrice.toFixed(2))
        : 0) +
      parseFloat(addedBranchLicensePrice.toFixed(2)) +
      parseFloat(addedUserLicensePrice.toFixed(2)) +
      (upcomingMonthBillDate !== 0
        ? parseFloat(addedBranchLicensePriceNextMonth?.toFixed(2))
        : 0) +
      (upcomingMonthBillDate !== 0
        ? parseFloat(addedUserLicensePriceNextMonth?.toFixed(2))
        : 0)
    ).toFixed(2);

  //For Due Now Popup

  // Branch License Due

  const totalBranchLicenseDue =
    // (instituteBillingData && billingStatus === "LOCKED" && !trialPlan
    //   ? parseFloat(lockedBranchCurrentMonthlyBilling.toFixed(2))
    //   : 0) +
    // (instituteBillingData && billingStatus === "GRACE_PERIOD" && !trialPlan
    //   ? parseFloat(lockedBranchCurrentMonthlyBilling.toFixed(2))
    //   : 0) +
    (
      (instituteBillingData && billingStatus === "LOCKED" && !trialPlan
        ? parseFloat(billableBranchLicensePrice?.toFixed(2))
        : 0) +
      (instituteBillingData && billingStatus === "GRACE_PERIOD" && !trialPlan
        ? parseFloat(billableBranchLicensePrice?.toFixed(2))
        : 0) +
      ((billingStatus === "ACTIVE" || billingStatus === "LOCKED") && trialPlan
        ? parseFloat(trialBranchLicensePrice.toFixed(2))
        : 0) +
      (billingStatus === "ARCHIVED" && (!trialPlan || trialPlan)
        ? parseFloat(archiveBranchLicensePrice.toFixed(2)) -
          parseFloat(archivedRemovedBranchLicensePrice.toFixed(2))
        : 0) +
      parseFloat(addedBranchLicensePrice.toFixed(2)) +
      (upcomingMonthBillDate !== 0
        ? parseFloat(addedBranchLicensePriceNextMonth?.toFixed(2))
        : 0)
    ).toFixed(2);

  // Billable User License Due

  const totalUserLicenseDue =
    // (instituteBillingData && billingStatus === "LOCKED" && !trialPlan
    //   ? parseFloat(lockedUserCurrentMonthlyBilling.toFixed(2))
    //   : 0) +
    // (instituteBillingData && billingStatus === "GRACE_PERIOD" && !trialPlan
    //   ? parseFloat(lockedUserCurrentMonthlyBilling.toFixed(2))
    //   : 0) +
    (
      (instituteBillingData && billingStatus === "LOCKED" && !trialPlan
        ? parseFloat(billableUserLicensePrice?.toFixed(2))
        : 0) +
      (instituteBillingData && billingStatus === "GRACE_PERIOD" && !trialPlan
        ? parseFloat(billableUserLicensePrice?.toFixed(2))
        : 0) +
      (billingStatus === "ACTIVE" && trialPlan
        ? parseFloat(filteredExtraUsersPrice.toFixed(2))
        : 0) +
      (billingStatus === "ARCHIVED" && (!trialPlan || trialPlan)
        ? parseFloat(archiveUserLicensePrice.toFixed(2)) -
          parseFloat(archivedRemoveUserLicensePrice.toFixed(2))
        : 0) +
      (billingStatus === "ARCHIVED" && (!trialPlan || trialPlan)
        ? parseFloat(convertedAddOnUserCountPrice.toFixed(2))
        : 0) +
      parseFloat(addedUserLicensePrice.toFixed(2)) +
      (upcomingMonthBillDate !== 0
        ? parseFloat(addedUserLicensePriceNextMonth?.toFixed(2))
        : 0)
    ).toFixed(2);

  // Branch License Increment and Decrement

  const branchIncrement = () => {
    setBranchCount(branchCount + 1);
  };

  const branchDecrement = () => {
    setBranchCount(Math.max(branchCount - 1, 1));

    if (branchCount > 0) setBranchCount(branchCount - 1);
    else {
      setBranchCount(0);
      alert("min limit reached");
    }
  };

  // User License Increment and Decrement

  const staffIncrement = () => {
    setUserCount(userCount + 1);
  };

  const staffDecrement = () => {
    setUserCount(Math.max(userCount - 1, 1));

    if (userCount > 0) setUserCount(userCount - 1);
    else {
      setUserCount(0);
      alert("min limit reached");
    }
  };

  useEffect(() => {
    setFreeUserCount(branchCount * 3);
  }, [setFreeUserCount, branchCount]);

  // Branch Accordion
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  // User Accordion
  const handleUserAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setUserExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (userFromLocalStorage) {
      dispatch<any>(getStaffListWithCount(userFromLocalStorage?.id));
      GetBranchList(userFromLocalStorage.id && userFromLocalStorage.id);
      GetBillingByInstituteId(
        userFromLocalStorage.id && userFromLocalStorage.id,
        instituteBillingStatusLocal === "ARCHIVED" ? "DEACTIVATED" : "ACTIVE"
      );
      GetInstituteById(userFromLocalStorage.id && userFromLocalStorage.id);
      setRemoveUserDetails({
        ...removeUserDetails,
        instituteId: userFromLocalStorage.id,
      });
      setRemoveUserLicense({
        ...removeUserLicense,
        instituteId: userFromLocalStorage.id,
      });

      setRemoveBranchDetails({
        ...removeBranchDetails,
        instituteId: userFromLocalStorage.id,
      });
      setRemoveBranchLicense({
        ...removeBranchLicense,
        instituteId: userFromLocalStorage.id,
      });
    }
  }, [
    GetStaffList,
    GetBranchList,
    GetBillingByInstituteId,
    userFromLocalStorage,
    GetInstituteById,
    instituteBillingStatusLocal,
  ]);

  const handleLeadAssignee = React.useCallback(
    (user: any) => {
      setRemoveUserDetails({
        ...removeUserDetails,
        assigedToId: user?.id,
        assignedToName: user?.firstName + " " + user?.lastName,
      });
    },

    [removeUserDetails]
  );

  const handleRemoveLicenseCheck = React.useCallback(
    (event: any) => {
      setRemoveUserDetails({
        ...removeUserDetails,
        deleteLicense: event.target.checked,
      });
    },

    [removeUserDetails]
  );

  const handleBranchLeadAssignee = React.useCallback(
    (user: any) => {
      setRemoveBranchDetails({
        ...removeBranchDetails,
        assigedToId: user?.id,
        assignedToName: user?.firstName + " " + user?.lastName,
        branchId: null,
        branchName: null,
      });
    },

    [removeBranchDetails]
  );

  const handleRemoveBranchLicenseCheck = React.useCallback(
    (event: any) => {
      setRemoveBranchDetails({
        ...removeBranchDetails,
        deleteLicense: event.target.checked,
      });
    },

    [removeBranchDetails]
  );

  const removeUserData =
    staffs &&
    userEditSelect !== "" &&
    staffs?.find((user: any) => user.id === userEditSelect?.licensedEntityId);

  const removeBranchData =
    branches &&
    branchEditSelect !== "" &&
    branches?.find(
      (item: any) => item.id === branchEditSelect?.licensedEntityId
    );

  const handleRemoveUser = async (event: any) => {
    event.preventDefault();

    try {
      let isValid = true;

      if (
        (removeUserData?.leadCount > 0 || removeUserData?.taskCount > 0) &&
        removeUserDetails?.assigedToId === ""
      ) {
        setAssignyError(true);
        isValid = false;
      }

      if (isValid) {
        RemoveUserOrUserLicense(removeUserDetails);
      }
    } catch (error) {}
  };

  const handleRemoveUserLicense = async (event: any) => {
    event.preventDefault();

    try {
      RemoveUserOrUserLicense(removeUserLicense);
    } catch (error) {}
  };

  const handleRemoveBranchLicense = async (event: any) => {
    event.preventDefault();

    try {
      RemoveBranchOrBranchLicense(removeBranchLicense);
    } catch (error) {}
  };

  const handleRemoveBranch = async (event: any) => {
    event.preventDefault();

    try {
      let isValid = true;

      if (removeBranchData?.teamCount > 0) {
        if (
          removeAction === "Move to another branch" &&
          (removeBranchDetails?.branchId === null ||
            removeBranchDetails?.branchId === "")
        ) {
          setUserBranchError(true);
          setRemoveBranch(false);
          setBranchAssignyError(false);

          isValid = false;
        } else if (
          removeAction === "Remove all users" &&
          (removeBranchDetails?.assigedToId === null ||
            removeBranchDetails?.assigedToId === "")
        ) {
          if (removeBranchData?.leadCount > 0) {
            setBranchAssignyError(true);
            isValid = false;
          }
          setRemoveBranch(false);
          setUserBranchError(false);
        } else if (removeAction === "") {
          setUserBranchError(false);
          setBranchAssignyError(false);
          setRemoveBranch(true);
          isValid = false;
        }
      } else {
        setUserBranchError(false);
        setBranchAssignyError(false);
        setRemoveBranch(false);
        isValid = true;
      }

      if (isValid) {
        RemoveBranchOrBranchLicense(removeBranchDetails);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (userRemovalSuccess !== true && userRemovalSuccess !== false) {
      setRemoveUserModal(false);
      setRemoveUserLicenseModal(false);
      if (userFromLocalStorage) {
        GetBillingByInstituteId(
          userFromLocalStorage.id && userFromLocalStorage.id,
          instituteBillingStatus === "ARCHIVED" ? "DEACTIVATED" : "ACTIVE"
        );
        GetStaffList(userFromLocalStorage.id && userFromLocalStorage.id);
      }
    }
  }, [userRemovalSuccess, GetBillingByInstituteId, GetStaffList]);

  useEffect(() => {
    if (branchRemovalSuccess !== true && branchRemovalSuccess !== false) {
      setRemoveBranchModal(false);
      setRemoveBranchLicenseModal(false);
      if (userFromLocalStorage) {
        GetBillingByInstituteId(
          userFromLocalStorage.id && userFromLocalStorage.id,
          billingStatus === "ARCHIVED" ? "DEACTIVATED" : "ACTIVE"
        );
      }
    }
  }, [branchRemovalSuccess, GetBillingByInstituteId]);

  React.useEffect(() => {
    if (branchRemovalSuccess !== true && branchRemovalSuccess !== false) {
      setTimeout(() => {
        dispatch(resetBranchRemovalSuccess());
      }, 3000);
    }
  }, [branchRemovalSuccess]);

  React.useEffect(() => {
    if (userRemovalSuccess !== true && userRemovalSuccess !== false) {
      setTimeout(() => {
        dispatch(resetUserRemovalSuccess());
      }, 3000);
    }
  }, [userRemovalSuccess]);

  const [paymentStatusPopup, setPaymentStatusPopup] = useState<boolean>(false);
  const [paymentStatusType, setPaymentStatusType] = useState<string>("");

  React.useEffect(() => {
    if (successBilling !== true && successBilling !== false) {
      if (userFromLocalStorage) {
        GetBillingByInstituteId(
          userFromLocalStorage.id && userFromLocalStorage.id,
          instituteBillingStatus === "ARCHIVED" ? "DEACTIVATED" : "ACTIVE"
        );
        GetInstituteById(userFromLocalStorage.id && userFromLocalStorage.id);
      }
      setFreeUserCount(0);
      setBranchCount(0);
      setUserCount(0);
      // setPaymentStatusPopup(true);
      // setPaymentStatusType("sucessfull");
      if (
        billingPaymentLink &&
        typeof billingPaymentLink === "string" &&
        billingPaymentLink.trim() !== ""
      ) {
        window.open(billingPaymentLink, "_blank");
      }
    }
  }, [successBilling]);

  React.useEffect(() => {
    if (instituteBillingStatus) {
      if (userFromLocalStorage) {
        GetBillingByInstituteId(
          userFromLocalStorage.id && userFromLocalStorage.id,
          instituteBillingStatus === "ARCHIVED" ? "DEACTIVATED" : "ACTIVE"
        );
      }
    }
  }, [instituteBillingStatus]);

  React.useEffect(() => {
    if (errorBilling) {
      // setPaymentStatusPopup(true);
      // setPaymentStatusType("unsucessfull");
    }
  }, [errorBilling]);

  const billableUsersCount =
    filteredExtraUsersCount -
    (billingStatus === "ARCHIVED" ? unUsedUserRemoveCount : 0) +
    (billingStatus === "ARCHIVED" && convertedAddOnUserCount > 0
      ? convertedAddOnUserCount
      : 0);

  const billableUsersCountPrice =
    parseFloat(filteredExtraUsersPrice.toFixed(2)) -
    (billingStatus === "ARCHIVED"
      ? parseFloat(archivedRemoveUserLicensePriceMonthly.toFixed(2))
      : 0) +
    (billingStatus === "ARCHIVED" && convertedAddOnUserCount > 0
      ? parseFloat(convertedAddOnUserCountPriceMonthly.toFixed(2))
      : 0);
  const currentTotalUserCount =
    filteredUsersCount -
    (billingStatus === "ARCHIVED"
      ? unUsedUserRemoveCount + removedBundledUserCount
      : 0);

  const currentTotalBranchCount =
    filteredBranchCount -
    (billingStatus === "ARCHIVED" ? unUsedBranchRemoveCount : 0);

  return (
    <div
      style={{
        padding: "28px 28px",
        maxHeight: "calc(100vh - 94px)",
        overflowY: "auto",
      }}
    >
      {instituteBillingData === null ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="100px"
          style={{
            marginBottom: "9px",
            borderRadius: "3px",
          }}
        />
      ) : (
        <>
          <button
            className={styles.PageBackBtn}
            onClick={() => {
              if (totalUserCount > 0) {
                backModalOpen();
              } else {
                handleGoBack();
              }
            }}
          >
            <ArrowBackIcon
              sx={{ marginRight: "8px", fontSize: "15PX", color: "#667085" }}
            />
            Back
          </button>
          <div className={styles.manageSubscriptionHead}>
            <div className={styles.manageSubscriptionTitle}>
              Manage subscription
            </div>
            <div className={styles.manageSubscriptionHeadRight}>
              {/* <div className={styles.manageSubscriptionCycleLabel}>
                Billing Cycle:
              </div> */}
              {/* <div>
                <div className={styles.manageSubscriptionCycleValue}>
                  Monthly
                </div>
                <FormControl size="small">
                  <Select
                    id="billling-cycle-select"
                    value={cycle}
                    onChange={handleChangeCycle}
                    sx={{
                      width: "150px",
                      border: "none",
                      color: "#131313",

                      "& fieldset": {
                        borderWidth: "0PX",
                        color: "#131313",
                      },
                      "&. Mui-disabled": {
                        backgroundColor: "#fff ",
                        color: "#131313",
                        border: "none",
                      },
                      "& .MuiSelect-icon": {
                        display: "none", // Hide the dropdown icon
                      },
                      "& .Mui-disabled .MuiOutlinedInput-input": {
                        color: "#131313",
                      },
                    }}
                
                    readOnly
                    disabled
                  >
                    <MenuItem value={"Monthly"}>Monthly</MenuItem>
                    <MenuItem value={"Yearly"}>Yearly</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
            </div>
          </div>
        </>
      )}

      {instituteBillingData === null ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="100px"
          style={{
            marginBottom: "9px",
            borderRadius: "3px",
          }}
        />
      ) : (
        <>
          <div className={styles.manageSubscriptionPricingWrapper}>
            <div className={styles.manageSubscriptionPricingTitle}>Pricing</div>
            <div className={styles.manageSubscriptionPriceWrapSplit}>
              <div className={styles.manageSubscriptionPriceItem}>
                <div className={styles.manageSubscriptionPriceItemTop}>
                  <div className={styles.manageSubscriptionPriceItemType}>
                    Branch license
                  </div>
                  <div className={styles.manageSubscriptionPriceItemPrice}>
                    ₹ {monthlyBranchLicensePrice}
                    <span
                      className={styles.manageSubscriptionPriceItemPriceType}
                    >
                      /Month
                    </span>
                  </div>
                </div>
                <p className={styles.manageSubscriptionPriceItemDesc}>
                  The monthly rate for each Branch is{" "}
                  {monthlyBranchLicensePrice}/-. You will get 3 free User
                  licenses with each Branch license.
                </p>
              </div>
              <div className={styles.manageSubscriptionPriceItem}>
                <div className={styles.manageSubscriptionPriceItemTop}>
                  <div className={styles.manageSubscriptionPriceItemType}>
                    User License
                  </div>
                  <div className={styles.manageSubscriptionPriceItemPrice}>
                    ₹ {monthlyUserLicensePrice}
                    <span
                      className={styles.manageSubscriptionPriceItemPriceType}
                    >
                      /Month
                    </span>
                  </div>
                </div>
                <p className={styles.manageSubscriptionPriceItemDesc}>
                  The monthly rate for each user is {monthlyUserLicensePrice}/-.
                  If you wish to add extra users than your free user licenses,
                  you can purchase User Licenses.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {instituteBillingData === null ? (
        <></>
      ) : (
        <>
          {billingStatus === "GRACE_PERIOD" ? (
            <AccountGracePeriod instituteBillingData={instituteBillingData} />
          ) : billingStatus === "ARCHIVED" ? (
            <AccountArchived />
          ) : billingStatus === "LOCKED" ? (
            <AccountBlocked />
          ) : billingStatus === "UNARCHIVED" ? (
            <AccountsUnarchived />
          ) : (
            <></>
          )}
        </>
      )}

      <Box sx={{ mt: "15px" }}>
        {instituteBillingData === null ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="100px"
            style={{
              marginBottom: "9px",
              borderRadius: "3px",
            }}
          />
        ) : (
          <>
            <Accordion
              sx={{
                "& .MuiButtonBase-root": {
                  margin: "0",
                },
              }}
              style={{
                background: "#ffffff",
                boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                border: "1px solid #EAECF0 ",
                marginBottom: "12px",
              }}
              expanded={expanded === "panel1"}
              onChange={handleAccordionChange("panel1")}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "6px",
                  margin: "10px 0 0 0",
                }}
              >
                <div className={styles.accordionHead}>
                  <div className={styles.accordionHeadLeft}>
                    <div className={styles.accordionHeadIcon}>
                      <img src={dropdownIcon} alt="" />
                    </div>
                    <div className={styles.accordionHeadLabel}>
                      Total Branch Licenses: {currentTotalBranchCount}
                    </div>
                  </div>

                  <div className={styles.accordionHeadPrice}>
                    ₹ {totalBranchPrice && totalBranchPrice.toFixed(2)}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingBottom: "0" }}>
                {usedBranchLicenses?.map((item: any, index: number) => {
                  return (
                    <div className={styles.manageSubscriptionBranchTableBody}>
                      <div
                        className={styles.manageSubscriptionBranchTableBodyLeft}
                      >
                        <div
                          className={
                            styles.manageSubscriptionBranchTableBodyBranch
                          }
                        >
                          {item.licensedEntityName}
                        </div>
                      </div>
                      <div
                        className={
                          styles.manageSubscriptionBranchTableBodyRight
                        }
                      >
                        <div>
                          {/* <button
                        className={styles.branchEditBtn}
                        onClick={(event) => handleOpenEditPopover(event, item)}
                      >
                        Edit
                      </button> */}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {unUsedBranchLicenses?.map((item: any, index: number) => {
                  return (
                    <div className={styles.manageSubscriptionBranchTableBody}>
                      <div
                        className={styles.manageSubscriptionBranchTableBodyLeft}
                      >
                        <div className={styles.manageSubscriptionBranchUnused}>
                          Unused Branch License
                        </div>
                      </div>
                      <div
                        className={
                          styles.manageSubscriptionBranchTableBodyRight
                        }
                      >
                        <div>
                          {billingStatus === "UNARCHIVED" ? (
                            ""
                          ) : (
                            <>
                              <Tooltip
                                arrow
                                title={
                                  hasBillingAccess === false
                                    ? "Oops! No permission."
                                    : ""
                                }
                                PopperProps={{ style: { zIndex: 9999999 } }}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: "#101828",
                                      color: "#fff",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      padding: "8px 12px",
                                      "& .MuiTooltip-arrow": {
                                        color: "#101828",
                                      },
                                    },
                                  },
                                }}
                              >
                                <span>
                                  <button
                                    className={styles.branchEditBtn}
                                    onClick={(event) =>
                                      openUnUsedBranchEditPopover(event)
                                    }
                                    disabled={!hasBillingAccess}
                                  >
                                    {/* Edit */}
                                    <MoreVertIcon />
                                  </button>
                                </span>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <Popover
                  anchorEl={anchorEl}
                  open={openEditPopover}
                  onClose={handleCloseEditPopver}
                  PaperProps={{
                    style: {
                      boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.10)",
                      borderRadius: "4PX",
                      border: "1px solid #F2F2F2",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: -5,
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#EC3939",
                      fontFamily: "Poppins",
                      padding: "5px 14px",
                    }}
                    onClick={() => {
                      openRemoveBranchModal();
                      handleCloseEditPopver();
                    }}
                  >
                    Remove Branch
                  </MenuItem>
                </Popover>

                <Popover
                  anchorEl={unUsedBranchAnchorEl}
                  open={openUnUsedBranchPopover}
                  onClose={closeUnUsedBranchEditPopver}
                  PaperProps={{
                    style: {
                      boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.10)",
                      borderRadius: "4PX",
                      border: "1px solid #F2F2F2",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: -5,
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#667085",
                      fontFamily: "Poppins",
                      padding: "5px 14px",
                    }}
                    onClick={() => {
                      navigate("Create-Branch");
                      closeUnUsedBranchEditPopver();
                    }}
                  >
                    Add Branch
                  </MenuItem>

                  {billingStatus !== "ARCHIVED" ? (
                    <MenuItem
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#EC3939",
                        fontFamily: "Poppins",
                        padding: "5px 14px",
                      }}
                      onClick={() => {
                        openRemoveBranchLicenseModal();
                        closeUnUsedBranchEditPopver();
                      }}
                    >
                      Delete Branch License
                    </MenuItem>
                  ) : (
                    <MenuItem
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#EC3939",
                        fontFamily: "Poppins",
                        padding: "5px 14px",
                      }}
                      onClick={() => {
                        removeUnUsedBranchArchived();
                        closeUnUsedBranchEditPopver();
                      }}
                    >
                      Delete Branch License
                    </MenuItem>
                  )}
                </Popover>

                <div
                  className={styles.manageSubscriptionUserCountWrap}
                  style={{ borderTop: "1px solid #eaecf0" }}
                >
                  <div className={styles.manageSubscriptionUsersLeft}>
                    <div
                      className={styles.manageSubscriptionUsersPriceLeftDetails}
                    >
                      <div className={styles.manageSubscriptionUserCountLabel}>
                        Add Branch License
                      </div>
                      {/* <div className={styles.manageSubscriptionUserDesc}>
              You have 9 free users for 3 branches
            </div> */}
                    </div>
                    <div className={styles.manageBillingUserCountBtnsWrap}>
                      {hasBillingAccess === true && (
                        <ButtonGroup
                          sx={{ border: "0.905797px solid #E6E6E6" }}
                          className={styles.manageBillingUserCountBtns}
                        >
                          <Button
                            // disabled={branchCount < 2}
                            aria-label="reduce"
                            onClick={() => {
                              branchDecrement();
                            }}
                            className={styles.manageBillingUserCountBtn}
                            sx={{
                              padding: "7px 7px",
                              minWidth: "28px !important",
                              border: "none !important",
                              background: " #F8F8F8",
                              color: "#667085",
                              maxWidth: "28px",
                              height: "31px",
                              " &:hover ": {
                                background: " #F8F8F8",
                                border: "none",
                              },
                              "& .MuiButtonGroup-root.MuiButtonGroup-grouped": {
                                minWidth: "28px",
                              },
                            }}
                          >
                            <RemoveIcon fontSize="small" />
                          </Button>
                          <div className={styles.manageBillingUserCount}>
                            {branchCount}
                          </div>

                          <Button
                            aria-label="increase"
                            onClick={() => {
                              branchIncrement();
                            }}
                            sx={{
                              padding: "7px 7px",
                              minWidth: "28px !important",
                              border: "none",
                              background: " #F8F8F8",
                              color: "#667085",
                              maxWidth: "28px",
                              height: "31px",
                              borderLeft: "1px solid #e6e6e6",
                              " &:hover ": {
                                background: " #F8F8F8",
                                border: "none",
                                borderLeft: "1px solid #e6e6e6",
                              },
                              "& .MuiButtonGroup-root.MuiButtonGroup-grouped": {
                                minWidth: "28px",
                              },
                            }}
                          >
                            <AddIcon fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      )}

                      {/* <div className={styles.manageBillingUserExtra}>
                    Extra Users: 3
                  </div> */}
                    </div>
                  </div>
                  <div className={styles.manageSubscriptionUsersPrice}>
                    ₹{" "}
                    {addedBranchLicensePriceMonthly &&
                      addedBranchLicensePriceMonthly.toFixed(2)}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>

      <Box>
        {instituteBillingData === null ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="100px"
            style={{
              marginBottom: "9px",
              borderRadius: "3px",
            }}
          />
        ) : (
          <>
            <Accordion
              sx={{
                "& .MuiButtonBase-root": {
                  margin: "0",
                },
              }}
              style={{
                background: "#ffffff",
                boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                border: "1px solid #EAECF0 ",
                marginBottom: "12px",
              }}
              expanded={userExpanded === "userPanel1"}
              onChange={handleUserAccordionChange("userPanel1")}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  backgroundColor: "#ffffff",
                  borderRadius: "6px",
                  margin: "10px 0 0 0",
                }}
              >
                <div className={styles.accordionHead}>
                  <div className={styles.accordionHeadLeft}>
                    <div className={styles.accordionHeadIcon}>
                      <img src={dropdownIcon} alt="" />
                    </div>
                    <div className={styles.accordionHeadLabel}>
                      Total user Licenses: {currentTotalUserCount}
                    </div>
                    <div className={styles.accordianUserCurrentStatus}>
                      (You have{" "}
                      {currentTotalBranchCount && currentTotalBranchCount * 3}{" "}
                      free User Licenses for{" "}
                      {currentTotalBranchCount && currentTotalBranchCount}{" "}
                      branches)
                    </div>
                  </div>
                  <div className={styles.accordionHeadRight}>
                    <div className={styles.accordionHeadBillableUser}>
                      Billable User: {billableUsersCount}
                    </div>

                    <div className={styles.accordionHeadPrice}>
                      ₹ {billableUsersCountPrice}
                    </div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingBottom: "0" }}>
                {usedUserLicenses
                  ?.filter((item: any) => item.benefitType === "BUNDLED")
                  .filter((item: any) => item.licensedEntityName !== "")
                  .map((item: any, index: number) => {
                    return (
                      <div className={styles.manageSubscriptionBranchTableBody}>
                        <div
                          className={
                            styles.manageSubscriptionBranchTableBodyLeft
                          }
                        >
                          <div
                            className={
                              styles.manageSubscriptionBranchTableBodyBranch
                            }
                          >
                            {item.licensedEntityName}
                          </div>
                        </div>
                        <div
                          className={
                            styles.manageSubscriptionBranchTableBodyRight
                          }
                        >
                          <div>
                            {item?.licenseEntityRole !== "ADMIN" &&
                              billingStatus !== "UNARCHIVED" && (
                                <Tooltip
                                  arrow
                                  title={
                                    hasBillingAccess === false
                                      ? "Oops! No permission."
                                      : ""
                                  }
                                  PopperProps={{ style: { zIndex: 9999999 } }}
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        bgcolor: "#101828",
                                        color: "#fff",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        padding: "8px 12px",
                                        "& .MuiTooltip-arrow": {
                                          color: "#101828",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <span>
                                    <button
                                      className={styles.branchEditBtn}
                                      onClick={(event) =>
                                        handleOpenEditUserPopover(event, item)
                                      }
                                      disabled={!hasBillingAccess}
                                    >
                                      <MoreVertIcon />
                                    </button>
                                  </span>
                                </Tooltip>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {usedUserLicenses
                  ?.filter((item: any) => item.benefitType === "ADDON")
                  .filter((item: any) => item.licensedEntityName !== "")
                  .map((item: any, index: number) => {
                    return (
                      <div className={styles.manageSubscriptionBranchTableBody}>
                        <div
                          className={
                            styles.manageSubscriptionBranchTableBodyLeft
                          }
                        >
                          {/* <div className={styles.manageSubscriptionBranchUnused}>
                        Unused User License 2
                      </div> */}
                          <div
                            className={
                              styles.manageSubscriptionBranchTableBodyBranch
                            }
                          >
                            {item.licensedEntityName}
                          </div>
                        </div>
                        <div
                          className={
                            styles.manageSubscriptionBranchTableBodyRight
                          }
                        >
                          <div>
                            {billingStatus === "UNARCHIVED" ? (
                              ""
                            ) : (
                              <Tooltip
                                arrow
                                title={
                                  hasBillingAccess === false
                                    ? "Oops! No permission."
                                    : ""
                                }
                                PopperProps={{ style: { zIndex: 9999999 } }}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: "#101828",
                                      color: "#fff",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      padding: "8px 12px",
                                      "& .MuiTooltip-arrow": {
                                        color: "#101828",
                                      },
                                    },
                                  },
                                }}
                              >
                                <span>
                                  <button
                                    className={styles.branchEditBtn}
                                    onClick={(event) =>
                                      handleOpenEditUserPopover(event, item)
                                    }
                                    disabled={!hasBillingAccess}
                                  >
                                    <MoreVertIcon />
                                  </button>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {unUsedUserLicenses?.map((item: any, index: number) => {
                  return (
                    <div className={styles.manageSubscriptionBranchTableBody}>
                      <div
                        className={styles.manageSubscriptionBranchTableBodyLeft}
                      >
                        <div className={styles.manageSubscriptionBranchUnused}>
                          Unused User License
                        </div>
                      </div>
                      <div
                        className={
                          styles.manageSubscriptionBranchTableBodyRight
                        }
                      >
                        <div>
                          {billingStatus === "UNARCHIVED" ? (
                            ""
                          ) : (
                            <Tooltip
                              arrow
                              title={
                                hasBillingAccess === false
                                  ? "Oops! No permission."
                                  : ""
                              }
                              PopperProps={{ style: { zIndex: 9999999 } }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#101828",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    padding: "8px 12px",
                                    "& .MuiTooltip-arrow": {
                                      color: "#101828",
                                    },
                                  },
                                },
                              }}
                            >
                              <span>
                                <button
                                  className={styles.branchEditBtn}
                                  onClick={(event) =>
                                    openUnUsedUserEditPopover(event, item)
                                  }
                                  disabled={!hasBillingAccess}
                                >
                                  <MoreVertIcon />
                                </button>
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {unUsedAddOnUserLicenses?.map((item: any, index: number) => {
                  return (
                    <div className={styles.manageSubscriptionBranchTableBody}>
                      <div
                        className={styles.manageSubscriptionBranchTableBodyLeft}
                      >
                        <div className={styles.manageSubscriptionBranchUnused}>
                          Unused User License
                        </div>
                      </div>
                      <div
                        className={
                          styles.manageSubscriptionBranchTableBodyRight
                        }
                      >
                        <div>
                          {billingStatus === "UNARCHIVED" ? (
                            ""
                          ) : (
                            <Tooltip
                              arrow
                              title={
                                hasBillingAccess === false
                                  ? "Oops! No permission."
                                  : ""
                              }
                              PopperProps={{ style: { zIndex: 9999999 } }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#101828",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    padding: "8px 12px",
                                    "& .MuiTooltip-arrow": {
                                      color: "#101828",
                                    },
                                  },
                                },
                              }}
                            >
                              <span>
                                <button
                                  className={styles.branchEditBtn}
                                  onClick={(event) =>
                                    openUnUsedAddOnUserEditPopover(event)
                                  }
                                  disabled={!hasBillingAccess}
                                >
                                  <MoreVertIcon />
                                </button>
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <Popover
                  anchorEl={userAnchorEl}
                  open={openEditUserPopover}
                  onClose={handleCloseEditUserPopover}
                  PaperProps={{
                    style: {
                      boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.10)",
                      borderRadius: "4PX",
                      border: "1px solid #F2F2F2",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: -5,
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#EC3939",
                      fontFamily: "Poppins",
                      padding: "5px 14px",
                    }}
                    onClick={() => {
                      openRemoveUserModal();
                      handleCloseEditUserPopover();
                    }}
                  >
                    Deactivate User
                  </MenuItem>
                </Popover>

                <Popover
                  anchorEl={unUsedUserAnchorEl}
                  open={openUnUsedUserPopover}
                  onClose={closeUnUsedUserEditPopover}
                  PaperProps={{
                    style: {
                      boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.10)",
                      borderRadius: "4PX",
                      border: "1px solid #F2F2F2",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: -5,
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#667085",
                      fontFamily: "Poppins",
                      padding: "5px 14px",
                    }}
                    onClick={() => {
                      {
                        inactiveUsers?.length > 0
                          ? navigate("Teams")
                          : navigate("Create-Team");
                      }
                    }}
                  >
                    Add User
                  </MenuItem>
                </Popover>

                <Popover
                  anchorEl={unUsedAddOnUserAnchorEl}
                  open={openUnUsedAddOnUserPopover}
                  onClose={closeUnUsedAddOnUserEditPopover}
                  PaperProps={{
                    style: {
                      boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.10)",
                      borderRadius: "4PX",
                      border: "1px solid #F2F2F2",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: -5,
                    horizontal: "right",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#667085",
                      fontFamily: "Poppins",
                      padding: "5px 14px",
                    }}
                    onClick={() => {
                      {
                        inactiveUsers?.length > 0
                          ? navigate("Teams")
                          : navigate("Create-Team");
                      }
                    }}
                  >
                    Add User
                  </MenuItem>
                  {billingStatus !== "ARCHIVED" ? (
                    <MenuItem
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#EC3939",
                        fontFamily: "Poppins",
                        padding: "5px 14px",
                      }}
                      onClick={() => {
                        openUserLicenseModal();
                        closeUnUsedAddOnUserEditPopover();
                      }}
                    >
                      Delete User License
                    </MenuItem>
                  ) : (
                    <MenuItem
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#EC3939",
                        fontFamily: "Poppins",
                        padding: "5px 14px",
                      }}
                      onClick={() => {
                        removeUnUsedUserArchived();
                        closeUnUsedAddOnUserEditPopover();
                      }}
                    >
                      Delete User License
                    </MenuItem>
                  )}
                </Popover>

                <div
                  className={styles.manageSubscriptionUserCountWrap}
                  style={{ borderTop: "1px solid #eaecf0" }}
                >
                  <div className={styles.manageSubscriptionUsersLeft}>
                    <div
                      className={styles.manageSubscriptionUsersPriceLeftDetails}
                    >
                      <div className={styles.manageSubscriptionUserCountLabel}>
                        Add user License
                      </div>
                    </div>
                    <div className={styles.manageBillingUserCountBtnsWrap}>
                      {hasBillingAccess === true && (
                        <ButtonGroup
                          sx={{ border: "0.905797px solid #E6E6E6" }}
                          className={styles.manageBillingUserCountBtns}
                        >
                          <Button
                            disabled={
                              (freeUserCount > 0 &&
                                freeUserCount === totalUserCount) ||
                              totalUserCount === 0
                            }
                            aria-label="reduce"
                            onClick={() => {
                              staffDecrement();
                            }}
                            className={styles.manageBillingUserCountBtn}
                            sx={{
                              padding: "7px 7px",
                              minWidth: "28px !important",
                              border: "none",
                              background: " #F8F8F8",
                              color: "#667085",
                              maxWidth: "28px",
                              height: "31px",
                              " &:hover ": {
                                background: " #F8F8F8",
                                border: "none",
                              },
                              "& .MuiButtonGroup-root.MuiButtonGroup-grouped": {
                                minWidth: "28px",
                              },
                            }}
                          >
                            <RemoveIcon fontSize="small" />
                          </Button>
                          <div className={styles.manageBillingUserCount}>
                            {totalUserCount}
                          </div>

                          <Button
                            aria-label="increase"
                            onClick={() => {
                              staffIncrement();
                            }}
                            sx={{
                              padding: "7px 7px",
                              minWidth: "28px !important",
                              border: "none",
                              background: " #F8F8F8",
                              color: "#667085",
                              maxWidth: "28px",
                              height: "31px",
                              borderLeft: "1px solid #e6e6e6",
                              " &:hover ": {
                                background: " #F8F8F8",
                                border: "none",
                                borderLeft: "1px solid #e6e6e6",
                              },
                              "& .MuiButtonGroup-root.MuiButtonGroup-grouped": {
                                minWidth: "28px",
                              },
                            }}
                          >
                            <AddIcon fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      )}

                      {/* <div className={styles.manageBillingUserExtra}>
                    Extra Users: 3
                  </div> */}
                    </div>
                  </div>
                  <div className={styles.manageSubscriptionUsersPrice}>
                    ₹
                    {addedUserLicensePriceMonthly &&
                      addedUserLicensePriceMonthly.toFixed(2)}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>

      {instituteBillingData === null ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="100px"
          style={{
            marginBottom: "9px",
            borderRadius: "3px",
          }}
        />
      ) : (
        <>
          <div className={styles.manageSubscriptionSummaryWrap}>
            <div className={styles.manageSubscriptionSummaryItem}>
              <div className={styles.manageSubscriptionSummaryItemLabel}>
                Branch License Total:
              </div>
              <div className={styles.manageSubscriptionSummaryItemValue}>
                ₹ {totalBranchLicensePrice && totalBranchLicensePrice}
              </div>
            </div>
            <div className={styles.manageSubscriptionSummaryItem}>
              <div className={styles.manageSubscriptionSummaryItemLabel}>
                Billable User License Total:
              </div>
              <div className={styles.manageSubscriptionSummaryItemValue}>
                ₹ {totalUserLicensePrice && totalUserLicensePrice}
              </div>
            </div>
            <div className={styles.manageSubscriptionSummaryFooter}>
              <div className={styles.manageSubscriptionSummaryFooterItemLabel}>
                Total monthly bill :
              </div>
              <div className={styles.manageSubscriptionSummaryFooterItemValue}>
                ₹ {totalMonthlyBilling && totalMonthlyBilling}
              </div>
            </div>
            {parseFloat(dueNowPrice) > 0 && (
              <div className={styles.manageSubscriptionSummaryFooter}>
                <div
                  className={styles.manageSubscriptionSummaryFooterItemLabel}
                >
                  Due Now:
                </div>
                <div
                  className={styles.manageSubscriptionSummaryFooterItemValue}
                >
                  ₹ {dueNowPrice}
                </div>
              </div>
            )}
          </div>

          <div className={styles.manageSubscriptionPayBtn}>
            <div className={styles.manageSubscriptionGstDetails}>
              All rates are exclusive of GST.
            </div>

            <div style={{ display: "flex", gap: "6px" }}>
              {hasBillingAccess === true && (
                <>
                  {totalUserCount > 0 && (
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        color: "#BFBFBF",
                        border: "1px solid #BFBFBF",
                      }}
                      variant="outlined"
                      onClick={() => cancelModalOpen()}
                    >
                      Cancel
                    </Button>
                  )}
                  {parseFloat(dueNowPrice) > 0 && (
                    <PayNowPopup
                      subscriptionPg={false}
                      instituteBillingData={instituteBillingData}
                      extraUsersCount={userCount}
                      branchCount={branchCount}
                      branchLicenseDue={totalBranchLicenseDue}
                      userLicenseDue={totalUserLicenseDue}
                      trialPlan={trialPlan}
                      dueDate={dueDate}
                      CreateBilling={CreateBilling}
                      billingStatus={billingStatus}
                      filteredBranchCount={filteredBranchCount}
                      filteredExtraUsersCount={filteredExtraUsersCount}
                      CheckPromocodeByName={CheckPromocodeByName}
                      promocodeByName={promocodeByName}
                      paymentUserCountData={paymentUserCountData}
                      paymentBranchCountData={paymentBranchCountData}
                      successBilling={successBilling}
                      loadingBilling={loadingBilling}
                      addOnUserLicenseCount={addOnUserLicenseCount}
                      userCount={userCount}
                      // outstandingAmount={lockedCurrentMonthlyBilling}
                      outstandingAmount={lockedCurrentBillableAmount}
                      addedBranchLicensePrice={parseFloat(
                        addedBranchLicensePrice.toFixed(2)
                      )}
                      addedUserLicensePrice={parseFloat(
                        addedUserLicensePrice.toFixed(2)
                      )}
                      promocodeError={promocodeError}
                      promocodeloading={promocodeloading}
                      startDateFromBillingData={startDateFromBillingData}
                      daysInLockedTrialPlan={daysInLockedTrialPlan}
                      unUsedUserRemoveCount={unUsedUserRemoveCount}
                      unUsedBranchRemoveCount={unUsedBranchRemoveCount}
                      convertedAddOnUserCount={convertedAddOnUserCount}
                      upcomingMonthBillDate={upcomingMonthBillDate}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}

      {/* <PaymentPopup type={paymentStatusType} show={paymentStatusPopup} /> */}
      <RemoveBranchPopup
        open={removeBranchModal}
        onClose={closeRemoveBranchModal}
        branches={branches && branches}
        staffs={staffs && staffs}
        removeAction={removeAction}
        setRemoveAction={setRemoveAction}
        branchEditSelect={branchEditSelect && branchEditSelect}
        removeBranchData={removeBranchData}
        handleBranchLeadAssignee={handleBranchLeadAssignee}
        handleRemoveBranchLicenseCheck={handleRemoveBranchLicenseCheck}
        removeBranchDetails={removeBranchDetails}
        setRemoveBranchDetails={setRemoveBranchDetails}
        removeButtonClick={handleRemoveBranch}
        removeBranchError={removeBranchError}
        userBranchError={userBranchError}
        branchAssignyError={branchAssignyError}
        branchRemovalLoading={branchRemovalLoading}
      />
      <RemoveBranchLicensePopup
        open={removeBranchLicenseModal}
        onClose={closeRemoveBranchLicenseModal}
        deleteButtonClick={handleRemoveBranchLicense}
        branchRemovalLoading={branchRemovalLoading}
      />
      <RemoveUserPopup
        open={removeUserModal}
        onClose={closeRemoveUserModal}
        staffs={staffs && staffs}
        userEditSelect={userEditSelect && userEditSelect}
        handleLeadAssignee={handleLeadAssignee}
        handleRemoveLicenseCheck={handleRemoveLicenseCheck}
        removeUserDetails={removeUserDetails}
        removeButtonClick={handleRemoveUser}
        assignyError={assignyError}
        userRemovalLoading={userRemovalLoading}
        billing={true}
      />
      <RemoveUserLicensePopup
        open={removeUserLicenseModal}
        onClose={closeUserLicenseModal}
        deleteButtonClick={handleRemoveUserLicense}
        userRemovalLoading={userRemovalLoading}
      />
      <ManageSubscriptionCancelPopup
        open={cancelModal}
        onClose={cancelModalClose}
        yesButtonClick={cancelConfirm}
      />
      <ManageSubscriptionBackPopup
        open={backModal}
        onClose={backModalClose}
        yesBtnClick={handleGoBack}
      />
    </div>
  );
}

export default ManageSubscription;
