import React, { useState, useEffect, useRef } from "react";
import styles from "./TaskEditPopup.module.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../RolePermissionAccess/RolePermissionAccess";
import { Tooltip } from "@mui/material";

function TaskEditPopup(props: any) {
  const { handleDeleteButtonClick, handleEditButtonClick, data } = props;
  const popupWrapRef = useRef<HTMLDivElement>(null);
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupWrapRef.current &&
        !popupWrapRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popupWrapRef]);

  return (
    <div className={styles.taskEditPopupWrap} ref={popupWrapRef}>
      {/* <Tooltip
        arrow
        title={hasLeadAccess === false ? "Oops! No permission." : ""}
        PopperProps={{ style: { zIndex: 9999999 } }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#101828",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 12px",
              "& .MuiTooltip-arrow": {
                color: "#101828",
                zIndex: "99999999",
              },
            },
          },
        }}
      >
        <span
          style={{
            width: "100%",
            display: "block",
            textAlign: "center",
          }}
        >
          <button
            className={styles.taskEditPopupBtnOption}
            onClick={toggleDropdown}
            disabled={hasLeadAccess === false ? true : false}
          >
            <MoreHorizIcon sx={{ color: isOpen ? "#69696B" : "#BDBFC3" }} />
          </button>
        </span>
      </Tooltip> */}
      <button
        className={styles.taskEditPopupBtnOption}
        onClick={toggleDropdown}
      >
        <MoreHorizIcon sx={{ color: isOpen ? "#69696B" : "#BDBFC3" }} />
      </button>
      {isOpen && (
        <div className={styles.taskEditPopup}>
          <div>
            <button
              className={styles.taskEditPopupBtn}
              onClick={() => {
                handleEditButtonClick(data);
                setIsOpen(false);
              }}
            >
              Edit
            </button>
          </div>
          <div>
            <button
              className={styles.taskEditPopupBtn}
              style={{ color: "#EC3939" }}
              onClick={() => {
                handleDeleteButtonClick();
                setIsOpen(false);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskEditPopup;
