import React from "react";
import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function BatchTimeSkeleton() {
  return (
    <div
      className={styles.batchBasicDetailsWrap}
      style={{ padding: "20px 30px" }}
    >
      <div
        style={{
          width: "20%",
          marginBottom: "20px",
        }}
      >
        <Skeleton animation="wave" variant="text" width="100%" height={25} />
      </div>

      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
        <Skeleton animation="wave" variant="text" width="100%" height={50} />
      </div>
    </div>
  );
}

export default BatchTimeSkeleton;
