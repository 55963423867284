import React from "react";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableRow,
  styled,
  Box,
  Tooltip,
} from "@mui/material";
import styles from "./SystemRolePermissions.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tableCellClasses } from "@mui/material/TableCell";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import helpIcon from "../../../../../../../assets/images/helpIcon.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function SystemRolePermissions(props: any) {
  const { treeStructure } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        padding: "0px 20px 0px 20px",
      }}
    >
      {treeStructure?.map((item: any, index: any) => (
        <Box sx={{ mt: "15px" }} key={index}>
          <Accordion
            style={{
              background: "#ffffff",
              boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
              border: "1px solid #E3E3E3 ",
            }}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "6px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    // width: "33%",
                    flexShrink: 0,
                    color: "#142C44",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginRight: "5PX",
                  }}
                >
                  {item?.roleDomainName}
                </Typography>
                {item?.roleDomainCode === "TASKS" && (
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      "Users will have All access to own tasks. Use this section to grant all access to other User's task."
                    }
                    PopperProps={{ style: { zIndex: 9999999 } }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <img src={helpIcon} alt="helpIcon" />
                    </span>
                  </Tooltip>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px" }}>
              <TableContainer
                sx={{
                  minWidth: 700,
                  borderRadius: "0px 0px 8px 8PX",
                  border: "1px solid  #EAECF0",
                }}
              >
                <Table
                  sx={{
                    minWidth: 700,
                  }}
                  aria-label="customized table"
                >
                  <>
                    <>
                      <TableBody>
                        {item?.accessPermissions?.length !== 0 &&
                          item?.accessPermissions?.map(
                            (parameter: any, parameterIndex: number) => (
                              <TableRow key={parameterIndex}>
                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                    textTransform: "capitalize",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                    maxWidth: "400px",
                                    justifyContent: "space-between",
                                    borderBottom: "none",
                                    padding: "10px 16px",
                                  }}
                                >
                                  {parameter?.permissionAttributeName}
                                  {parameter?.accessStatus ? (
                                    <CheckIcon sx={{ color: "#11C89B" }} />
                                  ) : (
                                    <ClearIcon />
                                  )}
                                </StyledTableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </>
                    <>
                      {item?.subDomain?.length !== 0 &&
                        item?.subDomain?.map(
                          (detail: any, detailIndex: any) => (
                            <React.Fragment key={detailIndex}>
                              <div
                                style={{
                                  backgroundColor: "#F9FAFB",
                                  color: "#667085",
                                  padding: "16px",
                                  width: "100%",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {detail?.roleDomainName}
                              </div>
                              <TableBody>
                                {detail?.accessPermissions?.length !== 0 &&
                                  detail?.accessPermissions?.map(
                                    (
                                      parameter: any,
                                      parameterIndex: number
                                    ) => (
                                      <TableRow key={parameterIndex}>
                                        <StyledTableCell
                                          align="left"
                                          sx={{
                                            color: "#667085",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "12px",
                                            maxWidth: "400px",
                                            justifyContent: "space-between",
                                            borderBottom: "none",
                                            padding: "10px 16px",
                                          }}
                                        >
                                          {parameter?.permissionAttributeName}
                                          {parameter?.accessStatus ? (
                                            <CheckIcon
                                              sx={{ color: "#11C89B" }}
                                            />
                                          ) : (
                                            <ClearIcon />
                                          )}
                                        </StyledTableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                            </React.Fragment>
                          )
                        )}
                    </>
                  </>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  );
}

export default SystemRolePermissions;
