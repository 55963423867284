import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../components/Manegecourses.module.css";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import EditIconBtn from "../../../../../components/Buttons/EditIconBtn";
import CopyBtn from "../../../../../components/Buttons/CopyBtn";
import PageBackBtn from "../../../../../components/Buttons/PageBackBtn";
import accordionDropdown from "../../../../../assets/images/accordion-dropdown.svg";
import PaymentTypeBox from "../components/Payments/PaymentTypeBox";
import Upload from "../../../../../assets/images/uploadCourse.svg";
import { CreateCourseDTO } from "../service/types";
import { useDispatch } from "react-redux";
import {
  fileUpload,
  resetFileUploadSuccess,
} from "../../../Main/components/FileUpload/service/fileUploadSlice";
import { resetCourseStatus, updateCourse } from "../service/courseSlice";
import { resetSuccessCreateTag } from "../service/tagSlice";
import SwitchInput from "../../../../../components/Switch/SwitchInput";
import CourseDescription from "../components/BasicDetails/CourseDescription";
import CoursePayment from "../components/Payments/CoursePayment";
import AddCustomPayment from "../components/Payments/AddCustomPayment";
import EditCustomPayment from "../components/Payments/EditCustomPayment";
import CourseBasicDetails from "../components/BasicDetails/CourseBasicDetails";
import CourseImage from "../components/BasicDetails/CourseImage";
import CourseTag from "../components/BasicDetails/CourseTag";
import { resetSuccessCreateCategory } from "../service/categorySlice";
import CourseDetailSkeleton from "../../../../../components/Skeletons/Course/CourseDetailSkeleton";
import CourseImageSkeleton from "../../../../../components/Skeletons/Course/CourseImageSkeleton";
import CourseTagSkeleton from "../../../../../components/Skeletons/Course/CourseTagSkeleton";
import CoursePaymentSkeleton from "../../../../../components/Skeletons/Course/CoursePaymentSkeleton";
import CourseDescriptionSkeleton from "../../../../../components/Skeletons/Course/CourseDescriptionSkeleton";

export default function CourseDetail(props: any) {
  const {
    CourseConfig,
    GetCourseById,
    courseById,
    getInstituteById,
    courseTagList,
    courseType,
    courseLevel,
    courseCategories,
    success_createCategory,
    add_category_id,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageUrl = useSelector(
    (state: any) => state.fileUpload.imageUrl && state.fileUpload.imageUrl
  );
  const { success_createTag } = useSelector(
    (state: any) => state.tag && state.tag
  );
  const { success_updateCourse, loading_updateCourse, courseByIdLoading } =
    useSelector((state: any) => state.courses && state.courses);

  const fileUploadSuccess = useSelector(
    (state: any) =>
      state.fileUpload.fileUploadSuccess && state.fileUpload.fileUploadSuccess
  );

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasCourseAccess = getSubDomainAccessStatus(
    "MANAGE",
    "COURSES",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const [courseDetails, setCourseDetails] = React.useState<CreateCourseDTO>({
    courseName: "",
    courseDescription: "",
    courseTags: [],
    instituteId: "",
    instituteName: "",
    coursePrice: "",
    courseCategory: {
      courseCategoryName: "",
      courseCategoryId: "",
    },
    courseType: [],
    courseLevel: {
      courseLevelName: "",
      id: "",
    },
    courseSyllabus: [],
    isFirstCourse: false,
    status: "",
    isCertificateProvided: false,
    courseImage: "",
    createdById: "",
    createdByName: "",
    updatedById: authUser?.institute?.[0]?.id,
    updatedByName: authUser?.firstName + " " + authUser?.lastName,
    paymentType: "",
    id: "",
  });
  const [image, setImage] = React.useState<any>(Upload);

  const [imageDetails, setImageDetails] = useState<any>({
    fileBase64String: "",
    fileName: "",
    fileType: "COURSE_IMAGE",
    instituteId: "",
    courseId: "",
  });
  const [isTagsEditOpen, setIsTagsEditOpen] = useState<boolean>(false);
  const [isInfoEditOpen, setIsInfoEditOpen] = useState<boolean>(false);
  const [isDescEditOpen, setIsDescEditOpen] = useState<boolean>(false);
  const [isPayEditOpen, setIsPayEditOpen] = useState<boolean>(false);
  const [customPayEdit, setCustomPayEdit] = React.useState<boolean>(false);
  const [customPayEnable, setCustomPayEnable] = React.useState<boolean>(false);

  const plainTextDescription = courseById
    ? courseById.courseDescription.replace(/<[^>]+>/g, "")
    : "";

  function generateSyllabusText(courseSyllabus: any) {
    const syllabusText = courseSyllabus
      .map((item: any) => {
        const cleanedChapterDetails = item.chapterDetails.replace(
          /<[^>]+>/g,
          ""
        );
        const chapterText = `${item.chapterName}\n${cleanedChapterDetails}\n`;
        return chapterText;
      })
      .join("\n");
    return syllabusText;
  }

  const handleImageUpload = React.useCallback(
    async (cropImage: any, image: any) => {
      let image_Details: any = {
        fileBase64String: cropImage,
        fileName: image.name,
        fileType: "COURSE_IMAGE",
        instituteId: imageDetails?.instituteId,
        courseId: imageDetails?.courseId,
      };
      if (cropImage && image) {
        dispatch<any>(fileUpload(image_Details));
      }
    },
    [setImage, imageDetails]
  );

  const handleCustomPayEdit = () => {
    setCustomPayEdit(true);
  };

  const setCustomPayment = (event: any) => {
    setCustomPayEnable(event.target.checked);
    setIsPayEditOpen(false);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const courseId = pathname.substring(pathname.lastIndexOf("/") + 1);
    GetCourseById(courseId);
    getInstituteById(authUser?.institute[0]?.instituteDetails?.id);
    CourseConfig();
    setIsInfoEditOpen(false);
    setIsTagsEditOpen(false);
  }, [fileUploadSuccess]);

  useEffect(() => {
    setImageDetails({
      ...imageDetails,
      instituteId: courseById?.instituteId,
      courseId: courseById?.id,
    });
    setCourseDetails({
      courseName: courseById?.courseName,
      courseDescription: courseById?.courseDescription,
      courseTags: courseById?.courseTags,
      instituteId: courseById?.instituteId,
      instituteName: courseById?.instituteName,
      coursePrice: courseById?.coursePrice,
      courseCategory: {
        courseCategoryName: courseById?.courseCategory?.courseCategoryName,
        courseCategoryId: courseById?.courseCategory?.id,
      },
      courseType: courseById?.courseType,
      courseLevel: {
        courseLevelName: courseById?.courseLevel?.courseLevelName,
        id: courseById?.courseLevel?.id,
      },
      courseSyllabus: courseById?.courseSyllabus,
      isFirstCourse: false,
      status: courseById?.status,
      isCertificateProvided: courseById?.isCertificateProvided,
      courseImage: courseById?.courseImage,
      createdById: courseById?.createdById,
      createdByName: courseById?.createdByName,
      updatedById: authUser?.institute?.[0]?.id,
      updatedByName: authUser?.firstName + " " + authUser?.lastName,
      paymentType: courseById?.paymentType,
    });
  }, [courseById, courseType]);

  useEffect(() => {
    if (fileUploadSuccess) {
      if (imageUrl.fileType === "COURSE_IMAGE") {
        dispatch<any>(
          updateCourse({
            ...courseDetails,
            courseImage: imageUrl.location,
            id: courseById?.id,
          })
        );
        dispatch<any>(resetFileUploadSuccess());
      }
    }
  }, [fileUploadSuccess]);

  useEffect(() => {
    if (success_createTag) {
      dispatch(resetSuccessCreateTag());
    }
  }, [success_createTag]);

  React.useEffect(() => {
    if (success_updateCourse !== true && success_updateCourse !== false) {
      const pathname = window.location.pathname;
      const courseId = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetCourseById(courseId);
      setIsInfoEditOpen(false);
      setIsTagsEditOpen(false);
      dispatch(resetCourseStatus());
      setCustomPayEdit(false);
      setIsPayEditOpen(false);
      setIsDescEditOpen(false);
    }
  }, [success_updateCourse]);

  useEffect(() => {
    if (success_createCategory !== true && success_createCategory !== false) {
      CourseConfig();
    }
  }, [success_createCategory, CourseConfig]);

  useEffect(() => {
    if (add_category_id && courseCategories) {
      const category = courseCategories.find(
        (item: any) => item?.id === add_category_id
      );

      if (category) {
        const updatedCourseDetails = {
          ...courseDetails,
          courseCategory: {
            ...courseDetails.courseCategory,
            courseCategoryId: category?.id,
            courseCategoryName: category?.courseCategoryName,
          },
        };

        setCourseDetails(updatedCourseDetails);
        dispatch(resetSuccessCreateCategory());
      }
    }
  }, [add_category_id, courseCategories, courseDetails]);

  return (
    <Container
      sx={{
        overflowY: "auto",
        maxHeight: "100%",
        padding: "15px 0",
        minHeight: "calc(100vh - 93px)",
      }}
    >
      <div style={{ marginBottom: "14px", paddingTop: "16px" }}>
        <PageBackBtn
          onClick={() => {
            navigate(`/app/manage/Courses`);
          }}
        />
      </div>

      <div className={styles.courseDetailsFirstRow}>
        {courseById !== null && !courseByIdLoading ? (
          <CourseBasicDetails
            isInfoEditOpen={isInfoEditOpen}
            courseById={courseById}
            setIsInfoEditOpen={setIsInfoEditOpen}
            courseDetails={courseDetails}
            courseLevel={courseLevel}
            setCourseDetails={setCourseDetails}
            courseCategories={courseCategories}
            courseType={courseType}
            hasCourseAccess={hasCourseAccess}
          />
        ) : (
          <CourseDetailSkeleton />
        )}
        <div className={styles.courseDetailsImageSection}>
          {courseById !== null && !courseByIdLoading ? (
            <CourseImage
              image={image}
              setImage={setImage}
              handleImageUpload={handleImageUpload}
              courseDetails={courseDetails}
              courseById={courseById}
              hasCourseAccess={hasCourseAccess}
            />
          ) : (
            <CourseImageSkeleton />
          )}
        </div>
      </div>
      <div className={styles.mainSection}>
        {courseById !== null && !courseByIdLoading ? (
          <div className={styles.leftSection}>
            <div className={styles.courseBoxTitleSplit}>
              <div style={{ display: "flex", gap: "10px" }}>
                <div className={styles.courseBoxTitle}>Payment Details</div>
                {courseById?.paymentDetails?.paymentType && (
                  <div
                    className={styles.paymentTypeBoxHeadValue}
                    style={{ textTransform: "capitalize" }}
                  >
                    {courseById?.paymentDetails?.paymentType?.toLowerCase()}
                  </div>
                )}
              </div>
            </div>
            {courseById?.courseCategory?.courseCategoryName !== "" && (
              <div className={styles.courseBoxTitleSplit}>
                {hasCourseAccess && (
                  <div className={styles.coursePayEditSectionTitle}>
                    Select a payment mode
                  </div>
                )}

                <div className={styles.courseBoxBtn}>
                  {hasCourseAccess &&
                    courseById?.paymentType !== "" &&
                    !isPayEditOpen && (
                      <EditIconBtn
                        onClick={() => {
                          setIsPayEditOpen(true);
                          setCustomPayEdit(false);
                          setCustomPayEnable(false);
                        }}
                      />
                    )}
                </div>
              </div>
            )}

            {isPayEditOpen ? (
              <CoursePayment
                courseById={courseById}
                setIsPayEditOpen={setIsPayEditOpen}
              />
            ) : (
              <>
                {courseById?.paymentType === "" ? (
                  <div
                    style={{
                      minHeight: "calc(100vh - 480px)",
                      height: "160px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {courseById?.courseCategory?.courseCategoryName === "" ? (
                      <div className={styles.noDetailsText}>
                        Please add Category to proceed with Payment
                      </div>
                    ) : (
                      <div>
                        <div className={styles.noDetailsText}>
                          No Payment details yet
                        </div>
                        {hasCourseAccess === true && (
                          <div
                            className={styles.noSchedulesText}
                            onClick={() => {
                              setIsPayEditOpen(true);
                              setCustomPayEdit(false);
                              setCustomPayEnable(false);
                            }}
                          >
                            + Add Payment
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {courseById?.paymentType !== "" && (
                      <PaymentTypeBox courseById={courseById} />
                    )}
                  </>
                )}
              </>
            )}
            {courseById?.courseCategory?.courseCategoryName !== "" && (
              <div style={{ marginTop: "10PX" }}>
                <div className={styles.courseBoxTitleSplit}>
                  <div className={styles.coursePayEditSectionTitle}>
                    Capture additional payments
                  </div>

                  <div className={styles.courseBoxBtn}>
                    {hasCourseAccess &&
                      courseById?.randomPayments?.length > 0 &&
                      !customPayEdit && (
                        <EditIconBtn
                          onClick={() => {
                            handleCustomPayEdit();
                            setIsPayEditOpen(false);
                          }}
                          disabled={!hasCourseAccess}
                        />
                      )}
                  </div>
                </div>

                {courseById?.randomPayments?.length > 0 ? (
                  <div className={styles.paymentTypeBox}>
                    <EditCustomPayment
                      customPayEdit={customPayEdit}
                      setCustomPayEdit={setCustomPayEdit}
                      courseById={courseById}
                      loading_updateCourse={loading_updateCourse}
                      success_updateCourse={success_updateCourse}
                      hasCourseAccess={hasCourseAccess}
                    />
                  </div>
                ) : (
                  <div className={styles.coursePayCustomBox}>
                    <div className={styles.coursePayCustomHead}>
                      <div className={styles.coursePayCustomHeadLeft}>
                        <div className={styles.coursePayCustomHeadTitle}>
                          Collect additional Payments
                        </div>
                        <div className={styles.coursePayCustomHeadDesc}>
                          An additional payment can be used to collect an
                          admission fee, advance payment, etc.
                        </div>
                      </div>
                      <div className={styles.coursePayCustomHeadRight}>
                        {hasCourseAccess && (
                          <SwitchInput
                            switchStatus={customPayEnable}
                            handleSwitch={setCustomPayment}
                          />
                        )}
                      </div>
                    </div>
                    {customPayEnable && (
                      <AddCustomPayment
                        courseById={courseById}
                        loading_updateCourse={loading_updateCourse}
                        success_updateCourse={success_updateCourse}
                        setCustomPayEnable={setCustomPayEnable}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <CoursePaymentSkeleton />
        )}
        {courseById !== null && !courseByIdLoading ? (
          <CourseTag
            isTagsEditOpen={isTagsEditOpen}
            courseDetails={courseDetails}
            setIsTagsEditOpen={setIsTagsEditOpen}
            courseTagList={courseTagList}
            setCourseDetails={setCourseDetails}
            courseById={courseById}
            loading_updateCourse={loading_updateCourse}
            success_createTag={success_createTag}
            CourseConfig={CourseConfig}
            hasCourseAccess={hasCourseAccess}
          />
        ) : (
          <CourseTagSkeleton />
        )}
      </div>
      <div>
        {courseById !== null && !courseByIdLoading ? (
          <div className={styles.rightSection}>
            <div className={styles.courseBoxTitleSplit}>
              <div className={styles.courseBoxTitle}>Course Details</div>
              <div className={styles.courseBoxBtn}>
                {!isDescEditOpen && hasCourseAccess && (
                  <>
                    {(courseById?.courseDescription !== "" ||
                      courseById?.courseSyllabus?.length > 0) && (
                      <EditIconBtn
                        onClick={() => {
                          setIsDescEditOpen(true);
                        }}
                        disabled={!hasCourseAccess}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            {isDescEditOpen ? (
              <CourseDescription setIsDescEditOpen={setIsDescEditOpen} />
            ) : (
              <>
                {courseById?.courseDescription === "" &&
                courseById?.courseSyllabus?.length === 0 ? (
                  <div
                    style={{
                      minHeight: "calc(100vh - 480px)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div className={styles.noBatchTimingContainer}>
                      <div className={styles.noDetailsText}>No Details Yet</div>
                      {hasCourseAccess === true && (
                        <div
                          className={styles.noCourseDescriptionText}
                          onClick={() => {
                            setIsDescEditOpen(true);
                          }}
                        >
                          + Add description and syllabus
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {courseById?.courseDescription && (
                      <>
                        <div className={styles.courseBoxCopySplit}>
                          <div className={styles.courseBoxCopyTitle}>
                            Description
                          </div>
                          <div className={styles.courseBoxCopyBtn}>
                            <CopyBtn copyText={plainTextDescription} />
                          </div>
                        </div>
                        <div
                          className={styles.courseDescription}
                          dangerouslySetInnerHTML={{
                            __html: courseById?.courseDescription,
                          }}
                        />
                      </>
                    )}
                    {courseById?.courseSyllabus?.length > 0 && (
                      <>
                        <div className={styles.courseBoxCopySplit}>
                          <div className={styles.courseBoxCopyTitle}>
                            Syllabus
                          </div>
                          <div className={styles.courseBoxCopyBtn}>
                            <CopyBtn
                              copyText={
                                courseById?.courseSyllabus &&
                                generateSyllabusText(courseById?.courseSyllabus)
                              }
                            />
                          </div>
                        </div>

                        <div>
                          {courseById?.courseSyllabus?.map(
                            (item: any, index: any) => (
                              <Accordion
                                className={styles.accordion}
                                key={item.chapterName + index}
                              >
                                <AccordionSummary
                                  sx={{
                                    color: "#142C44",
                                    backgroundColor: "transparent",
                                    border: "1px solid #E9E9E9",
                                    borderBottom: "none",
                                    borderRadius:
                                      index === 0 ? "8px 8px 0px 0" : "0px",
                                    borderTop:
                                      index === 0
                                        ? "1px solid #E9E9E9"
                                        : "none",
                                  }}
                                  expandIcon={
                                    <img src={accordionDropdown} alt="edit" />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={styles.summary}>
                                    {item?.chapterName}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                  className={styles.summaryDesc}
                                  sx={{
                                    border: "1px solid #E9E9E9",
                                    borderTop: "none",
                                    borderRadius:
                                      index ===
                                      courseById?.courseSyllabus?.length - 1
                                        ? "0 0 8px 8px"
                                        : "0px",
                                    borderBottom: "none",
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item?.chapterDetails,
                                    }}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            )
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ) : (
          <CourseDescriptionSkeleton />
        )}
      </div>
    </Container>
  );
}
