import { Skeleton } from "@mui/material";
import React from "react";

function SplitSkeleton() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "1%",
        marginBottom: "1%",
      }}
    >
      <div
        style={{
          width: "66.4%",
          padding: "24px 26px",
          height: "430px",
          background: "#FFF",
          borderRadius: "8PX",
        }}
      >
        <div>
          <Skeleton animation="wave" variant="text" width="200px" height={23} />
        </div>
        <div>
          <Skeleton animation="wave" variant="text" width="300px" height={80} />
        </div>
        <div style={{ marginBottom: "30PX" }}>
          <Skeleton animation="wave" variant="text" width="300px" height={23} />
        </div>
        <div>
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={200}
          />
        </div>
      </div>
      <div
        style={{
          width: "32.6%",
          padding: "24px 26px",
          height: "430px",
          background: "#FFF",
          borderRadius: "8PX",
        }}
      >
        <div>
          <Skeleton animation="wave" variant="text" width="200px" height={23} />
        </div>
        <div>
          <Skeleton animation="wave" variant="text" width="300px" height={80} />
        </div>
        <div style={{ marginBottom: "30PX" }}>
          <Skeleton animation="wave" variant="text" width="300px" height={23} />
        </div>
        <div>
          <Skeleton
            animation="wave"
            variant="rounded"
            width="100%"
            height={200}
          />
        </div>
      </div>
    </div>
  );
}

export default SplitSkeleton;
