import React, { useCallback, useState } from "react";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import styles from "../../Managebatches.module.css";
import clockIcon from "../../../../../../../assets/images/blue-clock.svg";
import calanderIcon from "../../../../../../../assets/images/blue-calender.svg";
import { getBatchBookedSession } from "../../../service/Batchslice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getLeadListByInstituteAndContact,
  setLeadModalState,
} from "../../../../../Nurture/service/nurtureSlice";
import { setToastNotification } from "../../../../../../../components/ToastNotification/service/toastNotificationSlice";
import { checkSubDomainAccess } from "../../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import scheduledIcon from "../../../../../../../assets/images/schedule-icon.svg";
import bookedIcon from "../../../../../../../assets/images/booked-icon.svg";
import helpIcon from "../../../../../../../assets/images/helpIcon.svg";
import { getDescriptionWithMentionedNames } from "../../../../../../../components/CommonFunctions/CommonFunctions";

function BookedBatchSession(props: any) {
  const { batchData } = props;
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const { batchBookedSession } = useSelector((state: any) => state.batch);
  const employees = useSelector((state: any) => state.staff.staffList);

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    // userRoleById?.permissions,
    userById?.rolePermission
  );
  const batchStartDateMilliSeconds =
    batchData?.startDate?.toString()?.length !== 13
      ? batchData?.startDate * 1000
      : batchData?.startDate;
  const batchEndDateMilliSeconds =
    batchData?.endDate?.toString()?.length !== 13
      ? batchData?.endDate * 1000
      : batchData?.endDate;

  const [batchDates, setBatchDates] = useState<number[]>([]);
  const [bookedDates, setBookedDates] = useState<number[]>([]);

  const [selectedDate, setSelectedDate] = React.useState<number | null>(
    dayjs().startOf("day").unix() * 1000
  );
  const [monthString, setMonthString] = useState(dayjs().format("YYYY-MM"));

  const filteredSessions = batchBookedSession?.filter((session: any) => {
    const sessionDateStartOfDay =
      dayjs(session.sessionDate).startOf("day").unix() * 1000;
    const selectedDateStartOfDay =
      dayjs(selectedDate).startOf("day").unix() * 1000;
    return sessionDateStartOfDay === selectedDateStartOfDay;
  });

  const groupedSessions = filteredSessions?.reduce((acc: any, session: any) => {
    const key = `${session.startTime}-${session.endTime}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(session);
    return acc;
  }, {});

  const handleDateChange = async (date: any) => {
    if (date !== null) {
      const startOfDayUnix = date.startOf("day").unix() * 1000;
      setSelectedDate(startOfDayUnix);
    }
  };

  const handleMonthChange = (newMonth: any) => {
    setMonthString(newMonth.format("YYYY-MM"));
  };

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "session");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  React.useEffect(() => {
    const filteredBatchDates = batchData?.batchCalendar
      ?.filter((dateObj: any) => {
        const date = dayjs(dateObj.date);
        return date.format("YYYY-MM") === monthString;
      })
      ?.filter((item: any) => item?.status === "TRUE")
      ?.map((dateObj: any) => dayjs(dateObj.date).format("YYYY-MM-DD"));

    setBatchDates(filteredBatchDates);
  }, [monthString, batchData]);

  React.useEffect(() => {
    document.querySelectorAll(".MuiPickersDay-root").forEach((dayElement) => {
      const dayNumber = dayElement.textContent;
      if (dayNumber) {
        const dayNumberAsNumber = parseInt(dayNumber, 10);

        if (
          batchDates &&
          !isNaN(dayNumberAsNumber) &&
          batchDates?.some((date) => {
            const dateObj = new Date(date);
            return dateObj.getDate() === dayNumberAsNumber;
          })
        ) {
          dayElement.classList.add("batch-day");
        } else {
          dayElement.classList.remove("batch-day");
        }
      }
    });
  }, [batchDates, selectedDate]);

  React.useEffect(() => {
    if (batchBookedSession) {
      const filteredBookedDates = batchBookedSession
        ?.filter((dateObj: any) => {
          const date = dayjs(dateObj.sessionDate);
          return date.format("YYYY-MM") === monthString;
        })
        .map((dateObj: any) => dayjs(dateObj.sessionDate).format("YYYY-MM-DD"));

      setBookedDates(filteredBookedDates);
    }
  }, [monthString, batchBookedSession]);

  React.useEffect(() => {
    document.querySelectorAll(".MuiPickersDay-root").forEach((dayElement) => {
      const dayNumber = dayElement.textContent;
      if (dayNumber) {
        const dayNumberAsNumber = parseInt(dayNumber, 10);

        if (
          bookedDates &&
          !isNaN(dayNumberAsNumber) &&
          bookedDates.some((date) => {
            const dateObj = new Date(date);
            return dateObj.getDate() === dayNumberAsNumber;
          })
        ) {
          dayElement.classList.add("booked-day");
        } else {
          dayElement.classList.remove("booked-day");
        }
      }
    });
  }, [batchDates, selectedDate]);

  console.log("bookedDates", bookedDates);
  return (
    <div className={styles.bookedSessionsWrapper}>
      <div className={styles.bookedSessionsCalendar}>
        <div className={styles.bookedCalendarHead}>
          <div className={styles.bookedCalendarHeadLeft}>Schedule</div>
          <div className={styles.bookedCalendarHeadRight}>
            <div className={styles.bookedCalendarHeadRightItem}>
              <img src={scheduledIcon} alt="scheduledIcon" />
              Scheduled
            </div>
            <div className={styles.bookedCalendarHeadRightItem}>
              <img src={bookedIcon} alt="bookedIcon" />
              Booked
            </div>
          </div>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            value={dayjs(selectedDate)}
            onChange={(date: any) => {
              handleDateChange(date);
            }}
            displayStaticWrapperAs="desktop"
            shouldDisableDate={(date) => {
              const isBeforeStart = dayjs(date).isBefore(
                batchStartDateMilliSeconds,
                "day"
              );

              const oneDayABeforeBatchEnd = dayjs(
                batchEndDateMilliSeconds
              ).subtract(1, "day");
              const isAfterEnd = dayjs(date).isAfter(oneDayABeforeBatchEnd);
              return isBeforeStart || isAfterEnd;
            }}
            onMonthChange={(newMonth) => {
              handleMonthChange(newMonth);
            }}
            className={`${styles.demoCalander} custom-calendar-button`}
            sx={{
              "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
                backgroundColor: "#fff",
                border: "1px solid #11C89B",
                color: "#11C89B",
              },
              "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-disabled":
                {
                  backgroundColor: "#fff",
                  border: "1px solid #11C89B",
                  color: "#11C89B",
                },
              "& .MuiButtonBase-root.MuiPickersDay-today": {
                border: "0px",
                color: "#11C89B",
              },
              "& .MuiButtonBase-root.MuiPickersDay-today.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.38)",
              },
              "& .MuiButtonBase-root.MuiPickersDay-today.Mui-selected": {
                border: "1px solid #11C89B",
                color: "#11C89B",
              },
              "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.batch-day":
                {
                  color: "#11C89B",
                  backgroundColor: "rgba(17, 200, 155, 0.16)",
                },
              "& .MuiButtonBase-root.MuiPickersDay-root.batch-day": {
                backgroundColor: "rgba(17, 200, 155, 0.16)",
              },
              "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled":
                {
                  backgroundColor: "#fff",
                  color: "rgba(0, 0, 0, 0.38)",
                },
              "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled.Mui-selected":
                {
                  backgroundColor: "#fff",
                  border: "1px solid #11C89B",
                  color: "#11C89B",
                },
              "& .MuiButtonBase-root.MuiPickersDay-root.booked-day": {
                position: "relative",
              },
              "& .MuiButtonBase-root.MuiPickersDay-root.booked-day.Mui-selected::after":
                {
                  position: "absolute",
                  width: "3px",
                  height: " 3px",
                  background: "#0570DB",
                  borderRadius: "50%",
                  bottom: "4px",
                  content: '""',
                },
              "& .MuiButtonBase-root.MuiPickersDay-root.booked-day::after": {
                position: "absolute",
                width: "3px",
                height: " 3px",
                background: "#0570DB",
                borderRadius: "50%",
                bottom: "4px",
                content: '""',
              },
              "& .MuiButtonBase-root.MuiPickersDay-root.Mui-disabled.booked-day::after":
                {
                  display: "none",
                },
            }}
          />
        </LocalizationProvider>
      </div>
      <div className={styles.bookedSessionsWrap}>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "16px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <img src={calanderIcon} alt="calanderIcon" />
          {moment(selectedDate).format("DD MMM YYYY, ddd")}
        </div>
        {filteredSessions?.length > 0 ? (
          <div
            className={styles.bookedSessionsItems}
            style={{
              overflowY: "auto",
              maxHeight: "305px",
              paddingRight: "4px",
            }}
          >
            {groupedSessions &&
              Object.entries(groupedSessions).map(
                ([timeKey, sessions]: any) => {
                  const [startTime, endTime] = timeKey.split("-");
                  return (
                    <div key={timeKey} className={styles.groupedSession}>
                      <div
                        className={styles.sessionTime}
                        style={{
                          color: "#667085",
                          fontSize: "12px",
                          marginBottom: "8px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <img src={clockIcon} alt="clockIcon" />
                        {moment
                          .unix(parseInt(startTime))
                          .format("hh:mm A")} to{" "}
                        {moment.unix(parseInt(endTime)).format("hh:mm A")}
                      </div>
                      <div
                        className={styles.sessionDetails}
                        style={{ marginBottom: "16px" }}
                      >
                        {sessions.map((item: any, index: number) => (
                          <div
                            key={index}
                            className={styles.bookedSessionsItem}
                            style={{
                              borderLeft: "3px solid #0570db",
                              borderRadius: "0px",
                            }}
                          >
                            <div className={styles.bookedSessionPiece}>
                              <div
                                style={{
                                  width: "26px",
                                  height: "26px",
                                  fontSize: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  textTransform: "capitalize",
                                  background: "#667085",
                                  color: "#fff",
                                  fontWeight: "400",
                                  padding: "4px",
                                  borderRadius: "50%",
                                  justifyContent: "center",
                                }}
                              >
                                {item?.studentFirstName
                                  ?.charAt(0)
                                  ?.toUpperCase()}
                                {item?.studentLastName
                                  ?.charAt(0)
                                  ?.toUpperCase()}
                              </div>
                              <button
                                className={styles.bookedViewLeadBtn}
                                onClick={() =>
                                  hasLeadCardPermission
                                    ? openLeadExpandModal(
                                        item?.instituteId,
                                        item?.studentContact,
                                        item?.studentEmail,
                                        item?.enquiryId
                                      )
                                    : dispatch(
                                        setToastNotification({
                                          message: String(
                                            "You do not have permission to access the lead card"
                                          ),
                                          type: "error",
                                          snackOpen: true,
                                        })
                                      )
                                }
                              >
                                {item?.studentFirstName +
                                  " " +
                                  item?.studentLastName}
                              </button>
                            </div>
                            {item?.hasOwnProperty("description") &&
                              item?.description !== "" && (
                                <div
                                  style={{ position: "relative" }}
                                  className={styles.sessionTabDescWrap}
                                >
                                  <img
                                    src={helpIcon}
                                    alt="helpIcon"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <div
                                    className={styles.sessionTabDescWrapPop}
                                    dangerouslySetInnerHTML={{
                                      __html: getDescriptionWithMentionedNames(
                                        item?.description,
                                        employees
                                      ).replace(/\n/g, "<br>"),
                                    }}
                                  ></div>
                                </div>
                              )}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }
              )}
            {/* {filteredSessions?.map((item: any) => (
            <div className={styles.bookedSessionsItem}>
              <div className={styles.bookedSessionPiece}>
                <img src={calanderIcon} alt="calanderIcon" />
                <div className={styles.bookedSessionPieceDetail}>
                  {moment(item?.sessionDate).format("DD MMM YYYY, ddd")}
                </div>
              </div>

              <div className={styles.bookedSessionPiece}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    fontSize: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                    background: "#667085",
                    color: " #fff",
                    fontWeight: "400",
                    padding: "4px",
                    borderRadius: "50%",
                    justifyContent: "center",
                  }}
                >
                  {item?.studentFirstName?.charAt(0)?.toUpperCase()}
                  {item?.studentLastName?.charAt(0)?.toUpperCase()}
                </div>
                <div className={styles.bookedSessionPieceDetail}>
                  {item?.studentFirstName + " " + item?.studentLastName}
                </div>
              </div>
              <div className={styles.bookedSessionPiece}>
                <button className={styles.bookedViewLeadBtn}>
                  View Lead Card
                </button>
              </div>
              <div className={styles.bookedSessionPiece}>
                <img src={clockIcon} alt="clockIcon" />
                <div className={styles.bookedSessionPieceDetail}>
                  {moment.unix(item?.startTime).format("hh:mm A") +
                    " to " +
                    moment.unix(item?.endTime).format("hh:mm A")}
                </div>
              </div>
            </div>
          ))} */}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              textAlign: "center",
              top: "30%",
              color: "#0570DB",
              fontSize: "14px",
            }}
          >
            No Sessions Scheduled.
          </div>
        )}
      </div>
    </div>
  );
}

export default BookedBatchSession;
