import React from "react";
import { Box, Button } from "@mui/material";
import styles from "./NurturePayDetails.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import stageLock from "../Assets/nurtureStageLock.svg";
import checkCircle from "../Assets/payCheckCircle.svg";
import npaypending from "../Assets/npaypending.svg";
import { useAuthUser } from "react-auth-kit";
import { resetNurturePayConnectStatuses } from "../../service/IntegrationsSlice";
import { useDispatch } from "react-redux";

function NurturePayStages(props: any) {
  const {
    GetAppsByInstitute,
    InstallNurturePay,
    appsList,
    nurturePayInstalledSuccess,
    nurturePayInstalledError,
  } = props;
  const dispatch = useDispatch();

  const nurturePayApp = appsList?.find(
    (app: any) => app.appName === "NURTURE_PAY_EASEBUZZ"
  );

  const auth = useAuthUser();
  const authUser = auth();
  const instituteDetails = authUser?.institute?.[0]?.instituteDetails;

  React.useEffect(() => {
    if (instituteDetails) {
      GetAppsByInstitute(instituteDetails?.id);
    }
  }, [GetAppsByInstitute]);

  const nurturepay = {
    appName: "NURTURE_PAY_EASEBUZZ",
    appCode: "NURTURE_PAY_EASEBUZZ",
    appConfigData: {
      // apiKey: "",
      // userName: "",
    },
    instituteId: instituteDetails?.id,
    createdById: authUser?.institute?.[0]?.id,
    createdByName: authUser?.firstName + " " + authUser?.lastName,
    updatedById: "",
    updatedByName: "",
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      nurturePayInstalledSuccess !== true &&
      nurturePayInstalledSuccess !== false
    ) {
      if (instituteDetails) {
        GetAppsByInstitute(instituteDetails?.id);
        dispatch(resetNurturePayConnectStatuses());
      }
    }
  }, [nurturePayInstalledSuccess, GetAppsByInstitute]);

  React.useEffect(() => {
    if (
      nurturePayInstalledError !== true &&
      nurturePayInstalledError !== false
    ) {
      dispatch(resetNurturePayConnectStatuses());
    }
  }, [nurturePayInstalledError]);

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          padding: "14px 16px",
          background: "#EEEEEE",
          maxHeight: "calc(100vh - 65px)",
          position: "relative",
        }}
      >
        <Box
          sx={{
            overflowY: "auto",
            background: "#fff",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
            minHeight: "calc(100vh - 93px)",
            maxHeight: "calc(100vh - 93px)",
          }}
        >
          <div className={styles.PageBackBtnWrap}>
            <button
              className={styles.PageBackBtn}
              onClick={() => navigate(`/app/Integrations`)}
            >
              <ArrowBackIcon
                sx={{
                  marginRight: "8px",
                  fontSize: "15PX",
                  color: "#667085",
                }}
              />
              Back
            </button>
          </div>

          <div className={styles.nurturePayStagesBody}>
            <div className={styles.nurturePayStagesHead}>
              Setup nurturePay integration
            </div>
            <div className={styles.nurturePayStagesDesc}>
              You must follow these 3 steps to set up nurturePay integration.
            </div>

            <div className={styles.nurturePayStagesItemsWrap}>
              <div className={styles.nurturePayStagesItem}>
                <div
                  className={styles.nurturePayStagesItemChild}
                  style={{
                    border: "1px solid rgba(17, 200, 155, 0.5)",
                    background: "#fafefd",
                  }}
                >
                  <div className={styles.nurturePayStagesItemTitle}>
                    Request nurturePay
                  </div>
                  <div className={styles.nurturePayStagesItemDesc}>
                    You need to request nurturePay integration in order to get
                    started.
                  </div>

                  <div className={styles.nurturePayStagesItemFooter}>
                    {nurturePayApp?.status === "UNDER_REVIEW" ||
                    nurturePayApp?.status === "SUBMITTED_FOR_KYC" ||
                    nurturePayApp?.status === "ACTIVE" ? (
                      <div
                        className={
                          styles.nurturePayStagesItemSuccessMessageWrap
                        }
                      >
                        <div
                          className={
                            styles.nurturePayStagesItemSuccessMessageImg
                          }
                        >
                          <img src={checkCircle} alt="checkCircle" />
                        </div>
                        <div className={styles.nurturePayStagesItemSuccessMsg}>
                          request successfully registered
                        </div>
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                          maxWidth: "150px",
                        }}
                        onClick={() => InstallNurturePay(nurturepay)}
                        fullWidth
                      >
                        Request
                      </Button>
                    )}
                  </div>
                </div>
                <div
                  className={styles.nurturePayStagesItemArrow}
                  style={{
                    borderLeft: "none",
                    borderRight: "1px solid rgba(17, 200, 155, 0.5)",
                    borderTop: "none",
                    borderBottom: "1px solid rgba(17, 200, 155, 0.5)",
                    background: "#fafefd",
                  }}
                ></div>
              </div>

              <div className={styles.nurturePayStagesItem}>
                <div
                  className={styles.nurturePayStagesItemChild}
                  style={{
                    border: `1px solid ${
                      nurturePayApp?.status === "ACTIVE"
                        ? "rgba(17, 200, 155, 0.5)"
                        : "#E9E9E9"
                    }`,
                    background:
                      nurturePayApp?.status === "ACTIVE" ? "#fafefd" : "#fff",
                  }}
                >
                  <div
                    className={styles.nurturePayStagesItemTitle}
                    style={{
                      color:
                        nurturePayApp?.status === "SUBMITTED_FOR_KYC" ||
                        nurturePayApp?.status === "ACTIVE"
                          ? "inherit"
                          : "#BEBEBE",
                    }}
                  >
                    KYC Review
                  </div>
                  <div
                    className={styles.nurturePayStagesItemDesc}
                    style={{
                      color:
                        nurturePayApp?.status === "SUBMITTED_FOR_KYC" ||
                        nurturePayApp?.status === "ACTIVE"
                          ? "inherit"
                          : "#BEBEBE",
                    }}
                  >
                    Your KYC is being reviewed, please wait until it is approved
                  </div>

                  <div className={styles.nurturePayStagesItemFooter}>
                    {nurturePayApp?.status === "SUBMITTED_FOR_KYC" ? (
                      <div
                        className={styles.nurturePayStagesItemPendingMsgWrap}
                      >
                        <div
                          className={styles.nurturePayStagesItemPendingMsgImg}
                        >
                          <img src={npaypending} alt="npaypending" />
                        </div>
                        <div className={styles.nurturePayStagesItemPendingMsg}>
                          Under KYC review
                        </div>
                      </div>
                    ) : nurturePayApp?.status === "ACTIVE" ? (
                      <div
                        className={
                          styles.nurturePayStagesItemSuccessMessageWrap
                        }
                      >
                        <div
                          className={
                            styles.nurturePayStagesItemSuccessMessageImg
                          }
                        >
                          <img src={checkCircle} alt="checkCircle" />
                        </div>
                        <div className={styles.nurturePayStagesItemSuccessMsg}>
                          KYC successfully Approved
                        </div>
                      </div>
                    ) : (
                      <img src={stageLock} alt="stageLock" />
                    )}
                  </div>
                </div>
                <div
                  className={styles.nurturePayStagesItemArrow}
                  style={{
                    borderLeft: "none",
                    borderRight: "1px solid rgba(17, 200, 155, 0.5)",
                    borderTop: "none",
                    borderBottom: "1px solid rgba(17, 200, 155, 0.5",
                    background: "#fafefd",
                  }}
                ></div>
              </div>

              <div className={styles.nurturePayStagesItem}>
                <div
                  className={styles.nurturePayStagesItemChild}
                  style={{
                    border: `1px solid ${
                      nurturePayApp?.status === "ACTIVE"
                        ? "rgba(17, 200, 155, 0.5)"
                        : "#E9E9E9"
                    }`,
                    background:
                      nurturePayApp?.status === "ACTIVE" ? "#fafefd" : "#fff",
                  }}
                >
                  <div
                    className={styles.nurturePayStagesItemTitle}
                    style={{
                      color:
                        nurturePayApp?.status === "ACTIVE"
                          ? "inherit"
                          : "#BEBEBE",
                    }}
                  >
                    Payment Gateway Activated
                  </div>
                  <div
                    className={styles.nurturePayStagesItemDesc}
                    style={{
                      color:
                        nurturePayApp?.status === "ACTIVE"
                          ? "inherit"
                          : "#BEBEBE",
                    }}
                  >
                    Now that the payment gateway is active, you can collect
                    money through Nurturepay
                  </div>

                  <div className={styles.nurturePayStagesItemFooter}>
                    {nurturePayApp?.status === "ACTIVE" ? (
                      <div
                        className={
                          styles.nurturePayStagesItemSuccessMessageWrap
                        }
                      >
                        <div
                          className={
                            styles.nurturePayStagesItemSuccessMessageImg
                          }
                        >
                          <img src={checkCircle} alt="checkCircle" />
                        </div>
                        <div className={styles.nurturePayStagesItemSuccessMsg}>
                          payment gateway successfully activated
                        </div>
                      </div>
                    ) : (
                      <img src={stageLock} alt="stageLock" />
                    )}
                  </div>
                </div>
                {/* <div
                  className={styles.nurturePayStagesItemArrow}
                  style={{
                    borderLeft: "none",
                    borderRight: "1px solid #E6EDF0",
                    borderTop: "none",
                    borderBottom: "1px solid #E6EDF0",
                    background: "#fff",
                  }}
                ></div> */}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default NurturePayStages;
