import { axios_institute_private } from "../../../../../api/setup.intersepter";
import { createBatchDTO, UpdateBatchPaymentDTO } from "./types";

export const CreateBatch = (body: createBatchDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/batch`,
    data: body,
  });
};

export const GetBatchList = (data: any) => {
  let url = `/batch/byInstitute/${data?.id}?batchConductType=${data?.batchConductType}`;
  if (data?.loadStudent) {
    url += `&loadStudent=${data?.loadStudent}`;
  }
  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const GetBatchById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/batch/id/${id}`,
  });
};

export const UpdateBatch = (body: createBatchDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/batch`,
    data: body,
  });
};

export const UpdateBatchPayment = (body: UpdateBatchPaymentDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/batch?actionType=updatePaymentDetail`,
    data: body,
  });
};

export const DeleteDemoStudent = (body: any) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/nurture/lead/demo`,
    data: body,
  });
};

export const GetBatchBookedSession = (data: any) => {
  return axios_institute_private({
    method: "GET",
    // url: `/nurture/lead/userSession?batchId=${data?.id}&sessionDate=${data?.date}`,
    url: `/nurture/lead/userSession?batchId=${data?.id}`,
  });
};

export const GetBatchByIdWithCalander = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/batch/id/${id}?loadCalender=true`,
  });
};
