import React from "react";
import noAccessIcon from "../../assets/images/noAccess.svg";

function NoAccess(props: any) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        maxHeight: "calc(100vh - 65px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          height: "100%",
        }}
      >
        <div style={{ maxWidth: "468px", padding: "30px" }}>
          <img src={noAccessIcon} alt="noAccessIcon" />
          <div
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#142C44",
              marginBottom: "15px",
            }}
          >
            You don’t have permission to access the requested page
          </div>
          <div style={{ fontSize: "13px", color: "#667085" }}>
            Either go back to the previous page or log in as a different user.
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoAccess;
