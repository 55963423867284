import React from "react";
import { Dialog, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./CancelFormPopup.module.css";
import { useNavigate } from "react-router";

const CancelFormPopup = (props: any) => {
  const {
    showCancelPopup,
    onClose,
    getFormByInstituteData,
    UpdateWebform,
    rightSectionFields,
    DeleteWebform,
  } = props;
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleSaveAsDraft = () => {
    const updatedFormData = {
      ...getFormByInstituteData,
      status: "DRAFT",
      fields: rightSectionFields,
      formCreationComplete: true,
      type: "UNIVERSAL",
    };

    UpdateWebform(updatedFormData);
  };
  return (
    <Dialog
      open={showCancelPopup}
      onClose={onClose}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "400px",
          maxHeight: "290px",
          minHeight: "149px",
          padding: "10px 24px 24px 24px",
          borderRadius: "12px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "8%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#667085" }} onClick={handleClose} />
        </div>
        <div className={styles.modalTitle}>
          Are you sure you want to Cancel?
        </div>

        <div className={styles.buttonSection}>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              color: "#344054",
              fontSize: "15px",
              fontWeight: "500",
              lineHeight: "10px 24px 24px 24px",
              textTransform: "capitalize",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              border: "1px solid #D0D5DD",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
              "&:hover": {
                color: "#344054",
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "10px 24px 24px 24px",
                textTransform: "capitalize",
                borderRadius: "4px",
                backgroundColor: "#FFF",
                border: "1px solid #D0D5DD",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
              },
            }}
            onClick={handleSaveAsDraft}
          >
            Save As Draft
          </Button>

          <Button
            variant="contained"
            fullWidth
            sx={{
              color: "#FFF",
              fontSize: "15px",
              fontWeight: "500",
              lineHeight: "10px 24px 24px 24px",
              textTransform: "capitalize",
              borderRadius: "4px",
              backgroundColor: "#EC3939",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
              "&:hover": {
                color: "#FFF",
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "10px 24px 24px 24px",
                textTransform: "capitalize",
                borderRadius: "4px",
                backgroundColor: "#EC3939",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
              },
            }}
            onClick={() => {
              if (getFormByInstituteData?.formCreationComplete === false) {
                DeleteWebform(getFormByInstituteData?.id);
              }
              // navigate("/app/Inbound/Webforms", { state: 2 });
              navigate("/app/Inbound/Webforms");
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default CancelFormPopup;
