import React from "react";
import styles from "../DemoBooking.module.css";
import DemoCard from "./DemoCard";
import { Button, Box, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function PickDemo(props: any) {
  const {
    setStage,
    setWay,
    handleWaySelect,
    demos,
    batches,
    selectedDate,
    handleBatchSelect,
    handleDemoSelect,
    setReschedule,
    reschedule,
    editDemoId,
    editDemoBatchId,
    editDemoDate,
    handlePickDemoBack,
    batchList,
    demosByCourse,
    hasLeadAccess,
  } = props;

  return (
    <div className={styles.pickTimeWrapper}>
      <div className={styles.existingDemoScreen}>
        <Box
          sx={{
            padding: "10px 12px 0 12px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <button
            className={styles.BackBtn}
            onClick={() => {
              setStage(0);
              setWay(0);
              setReschedule(false);
              handlePickDemoBack();
            }}
          >
            <ArrowBackIcon
              sx={{
                marginRight: "8px",
                fontSize: "15PX",
                color: "#667085",
              }}
            />
            Back
          </button>
        </Box>
        <div className={styles.existingDemoList}>
          {demos?.map((demo: any, index: number) => (
            <DemoCard
              key={index}
              setStage={setStage}
              setWay={setWay}
              handleWaySelect={handleWaySelect}
              demoCreated={true}
              item={demo}
              selectedDate={selectedDate}
              handleBook={handleDemoSelect}
              reschedule={reschedule}
              editDemoId={editDemoId}
              editDemoBatchId={editDemoBatchId}
              editDemoDate={editDemoDate}
              demosByCourse={demosByCourse}
            />
          ))}
          {batches?.map((batch: any, index: number) => (
            <DemoCard
              key={index}
              setStage={setStage}
              setWay={setWay}
              handleWaySelect={handleWaySelect}
              demoCreated={false}
              item={batch}
              selectedDate={selectedDate}
              handleBook={handleBatchSelect}
              reschedule={reschedule}
              editDemoId={editDemoId}
              editDemoBatchId={editDemoBatchId}
              editDemoDate={editDemoDate}
              demosByCourse={demosByCourse}
            />
          ))}
        </div>

        <div className={styles.existingDemoScreenBottom}>
          <Tooltip
            arrow
            title={hasLeadAccess === false ? "Oops! No permission." : ""}
            PopperProps={{ style: { zIndex: 9999999 } }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#101828",
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "500",
                  padding: "8px 12px",
                  zIndex: "9999999",
                  "& .MuiTooltip-arrow": {
                    color: "#101828",
                    zIndex: "9999999",
                  },
                },
              },
            }}
          >
            <span>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  fontSize: 14,
                  textTransform: "capitalize",
                  boxShadow: "none",
                  height: "38px",
                }}
                size={"small"}
                onClick={() => {
                  setWay(2);
                  setStage(2);
                  handleWaySelect();
                }}
                disabled={hasLeadAccess === false ? true : false}
              >
                <AddIcon sx={{ marginRight: "10px", fontSize: "18px" }} />
                Pick a New Time
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default PickDemo;
