import { IconButton, MenuItem, Popover } from "@mui/material";
import React from "react";
import threeDotsIcon from "../../assets/images/three-dots.svg";
import downloadIcon from "../../assets/images/download.svg";

function IconDropdown(props: any) {
  const { options, disabled, isDownloadButton = false } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleCancelOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCancelClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleCancelOpen}
        sx={{
          width: "36px",
          height: "36px",
          borderRadius: "4PX",
          background: "transparent",
        }}
        disabled={disabled}
      >
        <img src={threeDotsIcon} alt="" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleCancelClose}
        PaperProps={{
          style: {
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.06)",
            borderRadius: "4PX",
            border: "1px solid #E4E4E7",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: -5,
          horizontal: "right",
        }}
      >
        {options?.map((item: any) => (
          <MenuItem
            sx={{
              fontSize: "13px",
            }}
            onClick={() => {
              handleCancelClose();
              item?.onClick();
            }}
            style={item?.style}
          >
            {item?.icon && (
              <img
                src={item?.icon}
                alt={item?.label}
                style={{ marginRight: "8PX" }}
              />
            )}
            {isDownloadButton && (
              <div>
                <img
                  src={downloadIcon}
                  height={"20px"}
                  style={{ marginRight: "5px", marginTop: "2px" }}
                />
              </div>
            )}
            {item?.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

export default IconDropdown;
