import React, { useState } from "react";
import styles from "./ActionableNotification.module.css";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import minimizeIcon from "../../assets/images/minimizeIcon.svg";
import maximizeIcon from "../../assets/images/maximizeIcon.svg";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearTaskReminders,
  clearAllPaymentReminder,
} from "../../service/websocketNotificationsSlice";
import PaymentReminder from "./PaymentReminder";
import TaskReminder from "./TaskReminder";
import { useAuthUser } from "react-auth-kit";

function ActionableNotification(props: any) {
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { courses } = useSelector((state: any) => state.courses);
  const { taskReminders } = useSelector(
    (state: any) => state.websocketNotifications
  );
  const paymentReminders = useSelector(
    (state: any) => state?.websocketNotifications?.paymentReminderList
  );
  const paymentReminderCount = useSelector(
    (state: any) => state?.websocketNotifications?.paymentReminderCount
  );

  const [isContentMinimized, setIsContentMinimized] = useState(false);
  const [showActionableNotification, setShowActionableNotification] =
    useState(true);

  const handleClose = () => {
    const userFromLocalStorage = authUser?.institute[0];
    setShowActionableNotification(false);
    dispatch<any>(clearTaskReminders());
    const userId = userFromLocalStorage.id;
    dispatch<any>(clearAllPaymentReminder(userId));
  };

  const handleToggleContentMinimization = () => {
    setIsContentMinimized(!isContentMinimized);
  };

  const [activeAccordion, setActiveAccordion] = useState<string | null>(
    paymentReminders?.emiList?.length > 0
      ? "payment"
      : taskReminders?.length > 0
      ? "task"
      : null
  );
  const handleAccordionChange = (accordionName: string) => {
    setActiveAccordion(
      activeAccordion === accordionName ? null : accordionName
    );
  };

  return (
    <div className={styles.actionableNotificationContainer}>
      {showActionableNotification && (
        <div className={styles.popup}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #F2F2F2",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                padding: "14px",
              }}
            >
              <div className={styles.timerIcon}>
                <AccessTimeIcon style={{ color: "#4C7DDC" }} />
              </div>
              <div className={styles.notificationHeader}>
                Reminders (
                {(taskReminders?.length || 0) +
                  (paymentReminderCount?.count || 0)}
                )
              </div>
            </div>
            <div
              style={{ display: "flex", padding: "14px 14px 0 0", gap: "8px" }}
            >
              <div className={styles.minimizeIcon}>
                <img
                  src={isContentMinimized ? maximizeIcon : minimizeIcon}
                  alt={isContentMinimized ? "maximizeIcon" : "minimizeIcon"}
                  width="24px"
                  onClick={handleToggleContentMinimization}
                />
              </div>
              <div className={styles.closeButton} onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
          </div>

          {!isContentMinimized && (
            <div>
              {taskReminders?.length > 0 && (
                <TaskReminder
                  courses={courses}
                  setIsContentMinimized={setIsContentMinimized}
                  paymentReminders={paymentReminders}
                  active={activeAccordion === "task"}
                  onClick={() => handleAccordionChange("task")}
                />
              )}
              {paymentReminders?.emiList?.length > 0 && (
                <PaymentReminder
                  setIsContentMinimized={setIsContentMinimized}
                  active={activeAccordion === "payment"}
                  onClick={() => handleAccordionChange("payment")}
                  setShowActionableNotification={setShowActionableNotification}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ActionableNotification;
