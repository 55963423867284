import React, { useState } from "react";
import AddLeadBanner from "./AddLeadBanner";
import AddLeadForms from "./AddLeadForms";
import styles from "../../Customize.module.css";
import { FormGroup, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import FilterDrawer from "../../../../../../../components/FilterDrawer/FilterDrawer";
import FilterTypeWrap from "../../../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterChipsWrap from "../../../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../../../../components/FilterDrawer/FilterChips/FilterChips";
import ClearFilter from "../../../../../../../components/ClearFilter/ClearFilter";
import ShowAllBtn from "../../../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterSearchField from "../../../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import { getSubDomainAccessStatus } from "../../../../../../../components/RolePermissionAccess/RolePermissionAccess";

interface CheckedStatus {
  formStatus: string;
}

interface CheckedCreator {
  id: string;
  name: string;
}

function AddLeadTab(props: any) {
  const { leadForms } = useSelector((state: any) => state.appSettings);
  const employees = useSelector((state: any) => state.staff.staffList);
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasCustomizePermission = getSubDomainAccessStatus(
    "SETTINGS",
    "CUSTOMIZE",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [checkedStatuses, setCheckedStatuses] = useState<CheckedStatus[]>([]);
  const [filterCheckedStatuses, setFilterCheckedStatuses] = useState<
    CheckedStatus[]
  >([]);
  const [checkedCreators, setCheckedCreators] = useState<CheckedCreator[]>([]);
  const [filterCheckedCreators, setFilterCheckedCreators] = useState<
    CheckedCreator[]
  >([]);
  const [clearFilterPopup, setClearFilterPopup] = useState<boolean>(false);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [creatorSearchTerm, setCreatorSearchTerm] = useState<string>("");
  const [showAllCreators, setShowAllCreators] = React.useState<boolean>(false);
  const filteredForms = leadForms?.filter((form: any) => {
    const nameMatch =
      form?.formName?.toLowerCase()?.indexOf(searchTerm?.toLowerCase()) !== -1;

    const creatorIsChecked =
      filterCheckedCreators.length === 0 ||
      filterCheckedCreators.some((item) => item.id === form.createdById);

    const statusIsChecked =
      filterCheckedStatuses.length === 0 ||
      filterCheckedStatuses.some(
        (item) => item.formStatus.toLowerCase() === form.status.toLowerCase()
      );

    return nameMatch && statusIsChecked && creatorIsChecked;
  });

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("searchTerm", event.target.value);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const handleFilterStatusChecked = (formStatus: string) => {
    const statusToAdd: CheckedStatus = { formStatus };

    if (
      checkedStatuses.some(
        (item) => item.formStatus.toLowerCase() === formStatus.toLowerCase()
      )
    ) {
      setCheckedStatuses(
        checkedStatuses.filter(
          (item) => item.formStatus.toLowerCase() !== formStatus.toLowerCase()
        )
      );
    } else {
      setCheckedStatuses([...checkedStatuses, statusToAdd]);
    }
  };

  const clearCheckedStaffStatus = (formStatus: string) => {
    setCheckedStatuses(
      checkedStatuses.filter((item) => item.formStatus !== formStatus)
    );
  };

  const handleFilterCreatorChecked = (id: string, name: string) => {
    const creatorToAdd: CheckedCreator = { id, name };
    if (checkedCreators.some((branch: any) => branch.id === id)) {
      setCheckedCreators(
        checkedCreators.filter((branch: any) => branch.id !== id)
      );
    } else {
      setCheckedCreators([...checkedCreators, creatorToAdd]);
    }
  };

  const clearCheckedCreator = (id: string) => {
    setCheckedCreators(checkedCreators.filter((item: any) => item.id !== id));
  };

  const handleFilterForms = React.useCallback(
    (createdByArray: any, statusArray: any) => {
      setFilterCheckedCreators(createdByArray);
      setFilterCheckedStatuses(statusArray);

      // const queryParams = new URLSearchParams();
      // if (branchArray && branchArray.length > 0) {
      //   const branchParam = branchArray
      //     .map((branch: any) => branch.id)
      //     .join("|");
      //   queryParams.set("filterBranch", branchParam);
      // } else {
      //   queryParams.delete("filterBranch");
      // }

      // if (staffStatusArray && staffStatusArray.length > 0) {
      //   const staffStatusParam = staffStatusArray
      //     .map((item: any) => item.staffStatus)
      //     .join("|");
      //   queryParams.set("filteredStatus", staffStatusParam);
      // } else {
      //   queryParams.delete("filteredStatus");
      // }

      // const queryString = queryParams.toString();
      // const newUrl = `${window.location.pathname}${
      //   queryString ? `?${queryString}` : ""
      // }`;
      // window.history.pushState({}, "", newUrl);
    },
    [setFilterCheckedStatuses, setFilterCheckedCreators]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterForms(checkedCreators, checkedStatuses);

    setFilterApplied(
      checkedCreators?.length === 0 && checkedStatuses?.length === 0
        ? false
        : true
    );
  }, [handleFilterForms, setFilterApplied, checkedStatuses, checkedCreators]);

  const handleResetClick = React.useCallback(() => {
    setCheckedStatuses([]);
    setFilterCheckedStatuses([]);
    setCheckedCreators([]);
    setFilterCheckedCreators([]);
    setFilterApplied(false);
    handleFilterForms([], []);
  }, [
    setFilterApplied,
    setFilterCheckedStatuses,
    setCheckedStatuses,
    handleFilterForms,
    setCheckedCreators,
    setFilterCheckedCreators,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedStatuses(filterCheckedStatuses);
    setCheckedCreators(filterCheckedCreators);
  }, [filterCheckedStatuses, filterCheckedCreators]);

  const clearFiltersPopupOpen = () => {
    setClearFilterPopup(true);
  };

  const clearFiltersPopupClose = () => {
    setClearFilterPopup(false);
  };

  const removeFilters = React.useCallback(() => {
    setCheckedStatuses([]);
    setFilterCheckedStatuses([]);
    setFilterApplied(false);
    setClearFilterPopup(false);
    handleFilterForms([], []);
    setCheckedCreators([]);
    setFilterCheckedCreators([]);
    // const queryParams = new URLSearchParams(window.location.search);
    // queryParams.delete("filterBranch");
    // queryParams.delete("filterRole");
    // queryParams.delete("filteredStatus");

    // const newUrl = `${
    //   window.location.pathname
    // }?${queryParams.toString()}`;
    // window.history.pushState({}, "", newUrl);
  }, [
    setCheckedStatuses,
    setFilterCheckedStatuses,
    setFilterApplied,
    setClearFilterPopup,
    setCheckedCreators,
    setFilterCheckedCreators,
  ]);

  const unique = leadForms?.filter(
    (obj: any, index: any, self: any) =>
      index ===
      self?.findIndex((el: any) => el?.createdById === obj?.createdById)
  );

  const filteredCreators = employees
    ?.filter((item: any) => {
      const formattedRole = item.role?.toUpperCase().replace(/\s+/g, "_");
      return formattedRole === "ADMIN" || formattedRole === "SUPER_ADMIN";
    })
    ?.filter((item: any) => {
      const fullName = `${item.firstName ?? ""} ${
        item.lastName ?? ""
      }`.toLowerCase();
      const searchTermMatch = fullName.includes(
        creatorSearchTerm.toLowerCase()
      );
      return searchTermMatch;
    });

  const displayedCreatedBy = showAllCreators
    ? employees && filteredCreators
    : employees && filteredCreators?.slice(0, 5);

  const handleShowMoreCreators = () => {
    setShowAllCreators(!showAllCreators);
  };

  const filteredValue = (
    <>
      {filterCheckedCreators?.length > 0 &&
        filterCheckedCreators?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.name}
          </div>
        ))}

      {filterCheckedStatuses?.length > 0 &&
        filterCheckedStatuses?.map((item) => (
          <div key={item.formStatus} className="filteredDiv">
            {item?.formStatus}
          </div>
        ))}
    </>
  );

  return (
    <div className={styles.addLeadTabWrap}>
      <AddLeadBanner hasCustomizePermission={hasCustomizePermission} />
      <div className={styles.splitHead}>
        <div className={styles.secHead}>Add Lead Forms</div>
        <div className={styles.filterWrapSplit}>
          <div className={styles.searchFieldWrap}>
            <TextField
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              inputProps={{
                style: {
                  background: "#ffffff",
                  padding: "8px 12px",
                  width: "300px",
                },
              }}
              type="search"
            />
          </div>
          <FilterDrawer
            handleFilterApply={handleFilterApply}
            handleResetClick={handleResetClick}
            activeFilter={filterApplied}
            cancelClick={clearFiltersPopupOpen}
            closeSetFilter={handleFilterClose}
          >
            <div
              style={{
                padding: "25px 30px",
                overflowY: "auto",
                maxHeight: "calc(100% - 110px) ",
              }}
            >
              <FilterChipsWrap>
                {checkedStatuses?.length > 0 &&
                  checkedStatuses.map((item) => (
                    <FilterChips
                      keyVal={item?.formStatus}
                      chipName={item?.formStatus.toLowerCase()}
                      clearValue={item?.formStatus}
                      closeFunction={clearCheckedStaffStatus}
                    />
                  ))}
                {checkedCreators?.length > 0 &&
                  checkedCreators.map((item: any) => (
                    <>
                      <FilterChips
                        keyVal={item.id}
                        chipName={item.name}
                        clearValue={item.id}
                        closeFunction={clearCheckedCreator}
                      />
                    </>
                  ))}
              </FilterChipsWrap>
              <FilterTypeWrap>
                <FilterLabel labelValue="Status" />

                <FilterCheckBoxWrap>
                  <FormGroup>
                    {[
                      { status: "ACTIVE" },
                      { status: "INACTIVE" },
                      { status: "DRAFT" },
                    ].map((item: any) => (
                      <FilterCheckbox
                        keyVal={item.status}
                        checked={checkedStatuses?.some(
                          (data) =>
                            data.formStatus.toLowerCase() ===
                            item.status.toLowerCase()
                        )}
                        checkboxFunction={() =>
                          handleFilterStatusChecked(item.status)
                        }
                        label={item.status.toLowerCase()}
                      />
                    ))}
                  </FormGroup>
                </FilterCheckBoxWrap>
              </FilterTypeWrap>
              <FilterTypeWrap>
                <FilterLabel labelValue="Created by" />

                <FilterSearchField
                  value={creatorSearchTerm}
                  onChange={(e: any) => {
                    setCreatorSearchTerm(e.target.value);
                  }}
                  placeholder="Search name"
                />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {leadForms &&
                      displayedCreatedBy?.map((item: any) => (
                        <FilterCheckbox
                          keyVal={item.id}
                          checked={checkedCreators.some(
                            (data: any) => data.id === item.id
                          )}
                          checkboxFunction={() =>
                            handleFilterCreatorChecked(
                              item.id,
                              ` ${item.firstName + " " + item.lastName}`
                            )
                          }
                          label={
                            item.firstName.toLowerCase() +
                            " " +
                            item.lastName.toLowerCase()
                          }
                        />
                      ))}
                  </FormGroup>
                </FilterCheckBoxWrap>
                <ShowAllBtn
                  clickFunction={handleShowMoreCreators}
                  showAll={showAllCreators}
                  filteredState={filteredCreators}
                />
              </FilterTypeWrap>
            </div>
          </FilterDrawer>
        </div>
      </div>
      <AddLeadForms
        filteredForms={filteredForms}
        searchTerm={searchTerm}
        filterApplied={filterApplied}
        hasCustomizePermission={hasCustomizePermission}
      />
      <ClearFilter
        open={clearFilterPopup}
        noButtonClick={clearFiltersPopupClose}
        yesButtonClick={removeFilters}
        onClose={clearFiltersPopupClose}
        filteredValue={filteredValue}
      />
    </div>
  );
}

export default AddLeadTab;
