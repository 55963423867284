import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import styles from "./SystemRoleDetail.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Users from "../Users/Users";
import SystemRolePermissions from "../SystemRolePermissions/SystemRolePermissions";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { resetRoleById } from "../../../../service/settingsSlice";
import { useDispatch } from "react-redux";

function SystemRoleDetail(props: any) {
  const { GetRoleById, roleById, GetStaffList } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { staffList } = useSelector((state: any) => state.staff);
  const { roleByIdLoading } = useSelector((state: any) => state.appSettings);

  const [treeStructure, setTreeStructure] = useState<any>();

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const roleId = pathname.substring(pathname.lastIndexOf("/") + 1);
    GetRoleById(roleId);
    if (authUser) {
      const userFromLocalStorage = authUser?.institute?.[0]?.instituteDetails;
      GetStaffList(userFromLocalStorage?.id);
    }
  }, []);

  React.useEffect(() => {
    if (roleById) {
      setTreeStructure(roleById?.permissions);
    }
  }, [roleById]);

  return (
    <Box sx={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
      <div className={styles.PageBackBtnWrap}>
        {!roleByIdLoading && (
          <button
            className={styles.PageBackBtn}
            onClick={() => {
              dispatch(resetRoleById());
              navigate(`/app/Settings/RolesandPermission`);
            }}
          >
            <ArrowBackIcon
              sx={{
                marginRight: "8px",
                fontSize: "15PX",
                color: "#667085",
              }}
            />
            Back
          </button>
        )}
      </div>
      <Box sx={{ padding: "0px 20px 0px 20px" }}>
        {roleByIdLoading ? (
          <>
            <Box>
              <Skeleton
                animation="wave"
                variant="text"
                height={30}
                width={150}
                sx={{ marginBottom: "6px" }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                height={15}
                width={250}
                sx={{ marginBottom: "10px" }}
              />
            </Box>
          </>
        ) : (
          <>
            <div className={styles.roleName}>{roleById?.roleName}</div>
            <div className={styles.roleDescription}>
              {roleById?.description}
            </div>
          </>
        )}
      </Box>
      {roleByIdLoading ? (
        <Box sx={{ padding: "0px 20px 0px 20px" }}>
          {Array.from(new Array(4)).map((_, index) => (
            <div key={index} style={{ height: "70px" }}>
              <Skeleton
                animation="wave"
                variant="text"
                height="70px"
                width="100%"
                style={{
                  marginBottom: "10px",
                  borderRadius: "3px",
                }}
              />
            </div>
          ))}
        </Box>
      ) : (
        <>
          <Users staffList={staffList} roleById={roleById} />
          <SystemRolePermissions treeStructure={treeStructure} />
        </>
      )}
    </Box>
  );
}

export default SystemRoleDetail;
