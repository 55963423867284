import React, { useEffect, useState } from "react";
import styles from "./LeadSession.module.css";
import AddIcon from "@mui/icons-material/Add";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import moment from "moment";
import { bookSession, editBookedSession } from "../../../service/nurtureSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { CircularProgress } from "@mui/material";
import clockIcon from "../../../../../../assets/images/clockTask.svg";
import dateIcon from "../../../../../../assets/images/date.svg";
import DeleteBtn from "../../../../../../components/Buttons/DeleteBtn";

function ScheduleSessions(props: any) {
  const {
    setSchedule,
    batchCalendar,
    customDays,
    leadData,
    editSchedule,
    editScheduleData,
    setEditSchedule,
    allSessions,
    setAddMore,
    addMore,
    bookedSessions,
    sessionBookSuccess,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { sessionBookLoading } = useSelector((state: any) => state.nurture);

  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const batchStartDate = leadData?.batchStartDate;
  const batchEndDate = leadData?.bacthEndDate;
  const batchEndDateMilliSeconds =
    batchEndDate?.toString()?.length !== 13
      ? batchEndDate * 1000
      : batchEndDate;
  const batchStartDateMilliSeconds =
    batchStartDate?.toString()?.length !== 13
      ? batchStartDate * 1000
      : batchStartDate;

  const [sessionName, setSessionName] = React.useState<string>("");
  const [selectedDate, setSelectedDate] = React.useState<number | null>(
    dayjs().unix() * 1000
  );
  const [selectedSlot, setSelectedSlot] = React.useState<any>({
    startTime: 0,
    endTime: 0,
  });
  const [batchDates, setBatchDates] = useState<number[]>([]);
  const [customDates, setCustomDates] = useState<number[]>([]);
  const [monthString, setMonthString] = useState(dayjs().format("YYYY-MM"));
  const [validStateSessionName, setValidStateSessionName] =
    React.useState<boolean>(true);
  const [sessions, setSessions] = useState<any>([]);

  const filteredBatchCalendar = batchCalendar
    ?.filter((item: any) => item?.status === "TRUE")
    ?.filter((item: { date: number }) => {
      const itemDateAtMidnight = dayjs(item.date).startOf("day").valueOf();
      const selectedDateAtMidnight = dayjs(selectedDate)
        .startOf("day")
        .valueOf();
      return itemDateAtMidnight === selectedDateAtMidnight;
    });

  const filteredSessions = allSessions?.filter((session: any) => {
    const sessionDateStartOfDay =
      dayjs(session.sessionDate).startOf("day").unix() * 1000;
    const selectedDateStartOfDay =
      dayjs(selectedDate).startOf("day").unix() * 1000;
    return sessionDateStartOfDay === selectedDateStartOfDay;
  });

  const handleName = React.useCallback((event: any) => {
    const updatedValue = event.target.value.trimStart();
    setSessionName(updatedValue);

    setValidStateSessionName(updatedValue !== "");
  }, []);

  const handleDateChange = (date: any) => {
    if (date !== null) {
      const dateUnix = date.unix() * 1000;
      setSelectedDate(dateUnix);
      setSelectedSlot({
        ...selectedSlot,
        startTime: 0,
        endTime: 0,
      });
    }
  };

  const handleMonthChange = (newMonth: any) => {
    setMonthString(newMonth.format("YYYY-MM"));
  };

  const handleTimeSlot = (startTime: number, endTime: number) => {
    setSelectedSlot({
      ...selectedSlot,
      startTime: startTime,
      endTime: endTime,
    });
  };

  const handleAddMore = () => {
    !sessionName && setValidStateSessionName(false);
    if (sessionName !== "") {
      const newSession = {
        sessionName: sessionName,
        sessionDate: selectedDate
          ? new Date(selectedDate).setHours(23, 59, 59, 0)
          : 0,
        startTime: selectedSlot.startTime,
        endTime: selectedSlot.endTime,
        isScheduled: true,
      };

      setSessions((prevSessions: any) => [...prevSessions, newSession]);
      setAddMore(true);
      setSessionName("");
      setSelectedDate(dayjs().unix() * 1000);
      setSelectedSlot({ startTime: 0, endTime: 0 });
    }
  };
  const handleDelete = (item: any) => {
    setSessions((prevSessions: any) =>
      prevSessions.filter(
        (session: any) =>
          session.sessionName !== item.sessionName ||
          session.sessionDate !== item.sessionDate ||
          session.startTime !== item.startTime ||
          session.endTime !== item.endTime
      )
    );
  };

  const cancelBtnClick = () => {
    setSelectedDate(dayjs().unix() * 1000);
    setSelectedSlot({
      ...selectedSlot,
      startTime: 0,
      endTime: 0,
    });
    setSessionName("");
    setValidStateSessionName(true);
    setSchedule(false);
    setEditSchedule(false);
    setSessions([]);
    setAddMore(false);
  };

  const handleBookSession = async (event: any) => {
    event.preventDefault();

    try {
      !sessionName && !addMore && setValidStateSessionName(false);
      const additionalSession = {
        sessionName: sessionName,
        sessionDate: selectedDate
          ? new Date(selectedDate).setHours(23, 59, 59, 0)
          : 0,
        startTime: selectedSlot.startTime,
        endTime: selectedSlot.endTime,
        isScheduled: true,
      };
      const isValidAdditionalSession =
        additionalSession.sessionName !== "" &&
        additionalSession.sessionDate > 0 &&
        selectedSlot.startTime > 0 &&
        selectedSlot.endTime > 0;
      const body = {
        enquiryId: leadData?.id,
        instituteId: activeInstitute?.id,
        batchId: leadData?.batchId,
        scheduleSession: addMore
          ? [
              ...(sessions || []),
              ...(isValidAdditionalSession ? [additionalSession] : []),
            ]
          : [additionalSession],
        createdById: authUser?.institute[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
      };
      if (addMore && sessions?.length > 0) {
        dispatch<any>(bookSession(body));
      } else if (
        sessionName &&
        selectedSlot.startTime > 0 &&
        selectedSlot.endTime > 0 &&
        selectedDate
      ) {
        dispatch<any>(bookSession(body));
      }
    } catch (error) {}
  };

  const resheduleBookedSession = async (event: any) => {
    event.preventDefault();

    try {
      !sessionName && setValidStateSessionName(false);

      const body = {
        id: editScheduleData?.id,
        enquiryId: editScheduleData?.enquiryId,
        instituteId: editScheduleData?.instituteId,
        batchId: editScheduleData?.batchId,
        sessionName: sessionName,
        sessionDate: selectedDate
          ? new Date(selectedDate).setHours(23, 59, 59, 0)
          : 0,
        startTime: selectedSlot.startTime,
        endTime: selectedSlot.endTime,
        isScheduled: true,
        createdById: editScheduleData?.createdById,
        createdByName: editScheduleData?.createdByName,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        sessionStatus: editScheduleData?.sessionStatus,
      };

      sessionName &&
        selectedSlot.startTime > 0 &&
        selectedSlot.endTime > 0 &&
        selectedDate &&
        dispatch<any>(editBookedSession(body));
    } catch (error) {}
  };

  React.useEffect(() => {
    const filteredBatchDates = batchCalendar
      .filter((dateObj: any) => {
        const date = dayjs(dateObj.date);
        return date.format("YYYY-MM") === monthString;
      })
      ?.filter((item: any) => item?.status === "TRUE")
      ?.map((dateObj: any) => dayjs(dateObj.date).format("YYYY-MM-DD"));

    setBatchDates(filteredBatchDates);

    const filteredCustomDates = customDays
      ?.filter(
        (dateObj: any) => dayjs(dateObj.date).format("YYYY-MM") === monthString
      )
      .map((dateObj: any) => dayjs(dateObj.date).format("YYYY-MM-DD"));

    setCustomDates(filteredCustomDates);
  }, [monthString, batchCalendar, customDays]);

  React.useEffect(() => {
    const combinedBatchDates = [...batchDates, ...customDates];

    document.querySelectorAll(".MuiPickersDay-root").forEach((dayElement) => {
      const dayNumber = dayElement.textContent;
      if (dayNumber) {
        const dayNumberAsNumber = parseInt(dayNumber, 10);

        if (
          combinedBatchDates &&
          !isNaN(dayNumberAsNumber) &&
          combinedBatchDates.some((date) => {
            const dateObj = new Date(date);
            return dateObj.getDate() === dayNumberAsNumber;
          })
        ) {
          dayElement.classList.add("batch-day");
        } else {
          dayElement.classList.remove("batch-day");
        }
      }
    });
  }, [batchDates, customDates, selectedDate]);

  React.useEffect(() => {
    if (editSchedule && editScheduleData) {
      setSelectedDate(editScheduleData?.sessionDate);
      setSelectedSlot({
        ...selectedSlot,
        startTime: editScheduleData?.startTime,
        endTime: editScheduleData?.endTime,
      });
      setSessionName(editScheduleData?.sessionName);
      setValidStateSessionName(true);
      const formattedMonth = dayjs(editScheduleData?.sessionDate).format(
        "YYYY-MM"
      );
      setMonthString(formattedMonth);
    }
  }, [editSchedule, editScheduleData]);

  useEffect(() => {
    if (sessionBookSuccess !== true && sessionBookSuccess !== false) {
      setSelectedDate(dayjs().unix() * 1000);
      setSelectedSlot({
        ...selectedSlot,
        startTime: 0,
        endTime: 0,
      });
      setSessionName("");
      setValidStateSessionName(true);
      setSchedule(false);
      setEditSchedule(false);
      setSessions([]);
      setAddMore(false);
    }
  }, [sessionBookSuccess]);

  return (
    <div>
      <div className={styles.scheduleSessionWrapper}>
        <div className={styles.scheduleSessionChild}>
          <div className={styles.scheduleSessionLeft}>
            <div className={styles.scheduleSessionLeftChild}>
              <div className={styles.scheduleSessionColLabel}>Session Name</div>
              <NewFormTextInput
                onChange={handleName}
                error={!validStateSessionName}
                value={sessionName}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </div>
            {selectedSlot?.startTime > 0 &&
              !editSchedule &&
              bookedSessions?.userSessionData?.balanceSession > 1 &&
              bookedSessions?.userSessionData?.balanceSession -
                sessions?.length >
                1 && (
                <button
                  className={styles.addMoreBtn}
                  onClick={(e: any) => {
                    handleAddMore();
                    // handleBookSession(e);
                  }}
                  disabled={selectedSlot.startTime === 0 || sessionBookLoading}
                >
                  {sessionBookLoading && addMore ? (
                    <CircularProgress size={20} color={"inherit"} />
                  ) : (
                    <>
                      <AddIcon sx={{ marginRight: "6px", fontSize: "18px" }} />
                      Add More
                    </>
                  )}
                </button>
              )}
          </div>

          <div className={styles.scheduleSessionRight}>
            <div className={styles.scheduleSessionCalendar}>
              <div className={styles.scheduleSessionColLabel}>
                Select a Date & Time
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  value={dayjs(selectedDate)}
                  onChange={(date: any) => {
                    handleDateChange(date);
                  }}
                  displayStaticWrapperAs="desktop"
                  disablePast
                  shouldDisableDate={(date) => {
                    const isBeforeStart = dayjs(date).isBefore(
                      batchStartDateMilliSeconds,
                      "day"
                    );

                    const oneDayABeforeBatchEnd = dayjs(
                      batchEndDateMilliSeconds
                    ).subtract(1, "day");
                    const isAfterEnd = dayjs(date).isAfter(
                      oneDayABeforeBatchEnd
                    );
                    return isBeforeStart || isAfterEnd;
                  }}
                  onMonthChange={(newMonth) => {
                    handleMonthChange(newMonth);
                  }}
                  className={`${styles.demoCalander} custom-calendar-button`}
                  sx={{
                    "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
                      backgroundColor: "#fff",
                      border: "1px solid #11C89B",
                      color: "#11C89B",
                    },
                    "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.Mui-disabled":
                      {
                        backgroundColor: "#fff",
                        border: "1px solid #11C89B",
                        color: "#11C89B",
                      },
                    "& .MuiButtonBase-root.MuiPickersDay-today": {
                      border: "0px",
                      color: "#11C89B",
                    },
                    "& .MuiButtonBase-root.MuiPickersDay-today.Mui-disabled": {
                      color: "rgba(0, 0, 0, 0.38)",
                    },
                    "& .MuiButtonBase-root.MuiPickersDay-today.Mui-selected": {
                      border: "1px solid #11C89B",
                      color: "#11C89B",
                    },
                    "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.batch-day":
                      {
                        color: "#11C89B",
                        backgroundColor: "rgba(17, 200, 155, 0.16)",
                      },
                    "& .MuiButtonBase-root.MuiPickersDay-root.batch-day": {
                      backgroundColor: "rgba(17, 200, 155, 0.16)",
                    },
                    "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled":
                      {
                        backgroundColor: "#fff",
                        color: "rgba(0, 0, 0, 0.38)",
                      },
                    "& .MuiButtonBase-root.MuiPickersDay-root.batch-day.Mui-disabled.Mui-selected":
                      {
                        backgroundColor: "#fff",
                        border: "1px solid #11C89B",
                        color: "#11C89B",
                      },
                    "& .MuiButtonBase-root.MuiPickersDay-root.demo-day": {
                      position: "relative",
                    },
                    "& .MuiButtonBase-root.MuiPickersDay-root.demo-day.Mui-selected::after":
                      {
                        position: "absolute",
                        width: "3px",
                        height: " 3px",
                        background: "#11c89b",
                        borderRadius: "50%",
                        bottom: "4px",
                        content: '""',
                      },
                    "& .MuiButtonBase-root.MuiPickersDay-root.demo-day::after":
                      {
                        position: "absolute",
                        width: "3px",
                        height: " 3px",
                        background: "#11c89b",
                        borderRadius: "50%",
                        bottom: "4px",
                        content: '""',
                      },
                    "& .MuiButtonBase-root.MuiPickersDay-root.Mui-disabled.demo-day::after":
                      {
                        display: "none",
                      },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className={styles.scheduleSessionTime}>
              <div className={styles.scheduleSessionColLabel}>Time slot</div>
              {filteredBatchCalendar?.map((item: any) => {
                const matchingSessions = sessions?.filter((session: any) =>
                  moment(session.sessionDate).isSame(item.date, "day")
                );
                const disableArray =
                  addMore && sessions?.length > 0
                    ? [...filteredSessions, ...matchingSessions]
                    : filteredSessions;
                const isDisabled = disableArray?.some(
                  (session: any) =>
                    session.startTime === item.startTime &&
                    session.endTime === item.endTime
                );

                const isSelected = matchingSessions?.some(
                  (session: any) =>
                    session.startTime === item.startTime &&
                    session.endTime === item.endTime
                );

                return (
                  <button
                    key={`${item.startTime}-${item.endTime}`}
                    className={styles.scheduleSessionTimeItem}
                    onClick={() =>
                      handleTimeSlot(item?.startTime, item?.endTime)
                    }
                    disabled={isDisabled}
                    style={{
                      background:
                        isSelected && addMore
                          ? // ? "#11C89B"
                            " rgba(0, 0, 0, 0.12)"
                          : item?.startTime === selectedSlot?.startTime &&
                            !isDisabled
                          ? "#11C89B"
                          : "#fff",
                      border:
                        item?.startTime === selectedSlot?.startTime &&
                        !isDisabled
                          ? "1px solid #11C89B"
                          : "1px solid #EEEEEE",
                      color:
                        isSelected && addMore
                          ? "rgba(0, 0, 0, 0.26)"
                          : item?.startTime === selectedSlot?.startTime &&
                            !isDisabled
                          ? "#fff"
                          : isDisabled
                          ? "#B0B0B0"
                          : "#667085",
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {moment.unix(item?.startTime).format("hh:mm A") +
                      " - " +
                      moment.unix(item?.endTime).format("hh:mm A")}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        {sessions?.map((item: any, index: number) => (
          <div className={styles.selectedSessionItem} key={index}>
            <div className={styles.selectedSessionItemName}>
              {item?.sessionName?.length > 16
                ? item.sessionName.substring(0, 20) + "..."
                : item.sessionName}
            </div>
            <div className={styles.selectedSessionItemDate}>
              <img
                src={dateIcon}
                alt="dateIcon"
                style={{ marginBottom: "2px" }}
              />
              {moment(item?.sessionDate).format("DD MMM YYYY, ddd")}
            </div>
            <div className={styles.selectedSessionItemTime}>
              <img
                src={clockIcon}
                alt="clockIcon"
                style={{ marginBottom: "2px" }}
              />

              {moment.unix(item?.startTime).format("hh:mm A") +
                " - " +
                moment.unix(item?.endTime).format("hh:mm A")}
            </div>
            <div>
              <DeleteBtn onClick={() => handleDelete(item)} />
            </div>
          </div>
        ))}

        <div className={styles.scheduleSessionBtnsWrap}>
          <CancelBtn onClick={() => cancelBtnClick()} />
          <SaveBtn
            onClick={
              editSchedule && editScheduleData
                ? resheduleBookedSession
                : handleBookSession
            }
            disabled={
              (!addMore && selectedSlot.startTime === 0) || sessionBookLoading
            }
            loader={sessionBookLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default ScheduleSessions;
