import { Box, Button, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import styles from "./components/InstituteProfile.module.css";
import CancelModal from "../../../../components/CancelModal/CancelModal";
import React from "react";
import CustomDescriptionModal from "./CustomDescriptionModal";

const AddDescriptionModal = (props: any) => {
  const {
    open,
    closeModal,
    handleAboutChange,
    about,
    onSubmit,
    instituteDetails,
    resetDescription,
  } = props;
  const [openCancelModal, setOpenCancelModal] = React.useState<boolean>(false);
  const handleCancelModalClose = () => {
    setOpenCancelModal(false);
  };
  const noButtonClick = () => {
    setOpenCancelModal(false);
  };
  const yesButtonClick = () => {
    setOpenCancelModal(false);
    resetDescription();
    closeModal();
  };
  const areObjectsEqual = () => {
    const isSame = instituteDetails.about === about;
    if (isSame) {
      closeModal();
    } else {
      setOpenCancelModal(true);
    }
  };

  return (
    <>
      <CustomDescriptionModal open={open} onClose={open}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          marginBottom={"17px"}
        >
          <Box className={styles.modalTitle}>Add description</Box>
          <Box onClick={() => areObjectsEqual()} style={{ cursor: "pointer" }}>
            <Close />
          </Box>
        </Box>
        <RichTextEditor
          defaultText={about}
          handleTextChange={(event: any, editor: any, editorRef: any) => {
            if (editor.getContent({ format: "text" }).length <= 5000) {
              handleAboutChange(event, editorRef);
            }
          }}
          inputProps={{
            maxLength: 50,
          }}
          toolBar="bold italic underline alignleft aligncenter numlist link emoticons | undo redo"
        />
        <Box style={{ textAlign: "end" }} sx={{ mt: 1 }}>
          <Button
            sx={{
              width: "106px",
              padding: "11px 26px",
              marginRight: "8px",
              color: "#BFBFBF",
              border: "1px solid #BFBFBF",
              "&:hover": {
                border: "1px solid #BFBFBF",
                backgroundColor: "rgb(180 181 181 / 4%)",
              },
            }}
            variant="outlined"
            onClick={() => {
              areObjectsEqual();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: "106px",
              padding: "11px 34px",
            }}
            variant="contained"
            type="submit"
            onClick={onSubmit}
          >
            Save
          </Button>
        </Box>
      </CustomDescriptionModal>
      <CancelModal
        open={openCancelModal}
        onClose={handleCancelModalClose}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
      />
    </>
  );
};
export default AddDescriptionModal;
