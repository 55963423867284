import React, { useState } from "react";
import Fields from "./Fields";
import Parameters from "./Parameters";
import styles from "../../Customize.module.css";
import {
  getCustomFields,
  resetSettingsStatuses,
} from "../../../../service/settingsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../../../components/RolePermissionAccess/RolePermissionAccess";

function CustomizeTab(props: any) {
  const { existingFields } = props;
  const dispatch = useDispatch();
  const { updateCustomFieldsSuccess } = useSelector(
    (state: any) => state.appSettings
  );
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasCustomizePermission = getSubDomainAccessStatus(
    "SETTINGS",
    "CUSTOMIZE",
    "ALL_ACCESS",
    userById?.rolePermission
  );
  const [customFields, setcustomFields] = useState<any[]>([]);
  const [oldCustomFields, setOldCustomFields] = useState<any[]>([]);

  const [draggedField, setDraggedField] = useState<any>(null);

  React.useEffect(() => {
    if (existingFields?.fields?.length > 0) {
      const updatedFields = existingFields?.fields?.map(
        (item: any, index: number) => ({
          fieldType: item?.field?.fieldType,
          fieldName: item?.field?.fieldName,
          fieldPlaceholder: item?.field?.fieldPlaceholder,
          options: item?.field?.options,
          default: item?.field?.default,
          required: item?.field?.required,
          index: index + 1,
          loadDatafromDB: item?.field?.loadDatafromDB,
          DBModel: item?.field?.DBModel,
          isCustom: item?.field?.isCustom,
          isFromCustom: item?.field?.isFromCustom,
          id: item?.id,
        })
      );

      setcustomFields(updatedFields);
      setOldCustomFields(updatedFields);
    } else {
      setcustomFields([]);
      setOldCustomFields([]);
    }
  }, [existingFields]);

  React.useEffect(() => {
    if (
      updateCustomFieldsSuccess !== true &&
      updateCustomFieldsSuccess !== false
    ) {
      dispatch<any>(getCustomFields(activeInstitute?.id));
      dispatch(resetSettingsStatuses());
    }
  }, [updateCustomFieldsSuccess]);

  return (
    <div className={styles.customizeTabWrap}>
      <div
        style={{
          width: " 330px",
        }}
      >
        <Fields
          setDraggedField={setDraggedField}
          draggedFields={customFields}
          hasCustomizePermission={hasCustomizePermission}
        />
      </div>
      <div
        style={{
          width: "calc(100% - 330px)",
        }}
      >
        <Parameters
          customFields={customFields}
          setcustomFields={setcustomFields}
          setDraggedField={setDraggedField}
          draggedField={draggedField}
          oldCustomFields={oldCustomFields}
          setOldCustomFields={setOldCustomFields}
          existingFields={existingFields}
          hasCustomizePermission={hasCustomizePermission}
        />
      </div>
    </div>
  );
}

export default CustomizeTab;
