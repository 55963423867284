import { Route, Routes } from "react-router-dom";
import { OzonetelStages, NurturePayDetails } from "../pages";
import React from "react";
import OzonetelDetails from "../pages/app/Integrations/components/OzonetelDetails/OzonetelDetails";
import AgentsListing from "../pages/app/Integrations/components/OzonetelDetails/AgentsListing/AgentsListing";
import NurturePayStages from "../pages/app/Integrations/components/NurturePayDetail/NurturePayStages";
import ZapierDetails from "../pages/app/Integrations/components/Zapier/ZapierDetails";
import ZapierIntegration from "../pages/app/Integrations/components/Zapier/ZapierIntegration";

export default function ManageIntegrationRouter(props: any) {
  return (
    <Routes>
      <Route path={"/OzonetelDetails"} element={<OzonetelDetails />} />
      <Route path={"/OzonetelStages"} element={<OzonetelStages />} />
      <Route
        path={"/OzonetelStages/AgentsListing"}
        element={<AgentsListing />}
      />
      <Route path={"/NurturePayDetails"} element={<NurturePayDetails />} />
      <Route path={"/NurturePayStages"} element={<NurturePayStages />} />
      <Route path={"/ZapierDetails"} element={<ZapierDetails />} />
      <Route path={"/ZapierIntegration"} element={<ZapierIntegration />} />
    </Routes>
  );
}
