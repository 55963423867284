import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import {
  AddEnquiryDataFromContact,
  DeactivateInboundData,
  DeleteContactData,
  DeleteInboundEmailData,
  EmailConfigDTO,
  EmailConfigData,
  ImapSyncData,
  ImportCSVData,
  ImportInitialStateDTO,
  InboundInitialStateDTO,
} from "./types";
import {
  ConfigEmail,
  CreateLeadFromContacts,
  DeactivateInbound,
  DeleteContactList,
  DeleteInboundEmail,
  DownloadCSVTemplate,
  GetContactList,
  GetMailConfigsList,
  GetMailDetailsById,
  GetMailEntriesByInstitute,
  ImportCSVFile,
  SaveEmailConfigDetails,
  SaveImapEmailConfigDetails,
  SyncImapEmailConfigDetails,
} from "./inbound.request";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: ImportInitialStateDTO = {
  templateDownloadError: false,
  templateDownloadLoading: false,
  templateDownloadSuccess: false,
  templateData: null,
  importCsvLoading: false,
  importCsvSuccess: false,
  importCsvError: false,
  importCsvData: null,
  contactListLoading: false,
  contactListSuccess: false,
  contactListError: false,
  contactList: null,
  deleteContactLoading: false,
  deleteContactSuccess: false,
  deleteContactError: false,
  addedEnquiryContactLoading: false,
  addedEnquiryContactSuccess: false,
  addedEnquiryContactError: false,
  mailConfigsList: null,
  mailConfigListSuccess: false,
  mailConfigListError: false,
  mailConfigListLoading: false,
  getAllFormByInstituteData: null,
  leadCreatedId: null,
  addedEnquiryZapierLoading: false,
  addedEnquiryZapierSuccess: false,
  addedEnquiryZapierError: false,
  leadCreatedIdForZapier: null,
};

export const getMailConfigsList = createAsyncThunk(
  "user/getMailConfigsList",
  async (id: string, thunkAPI: any) => {
    return GetMailConfigsList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getCsvTemplate = createAsyncThunk(
  "user/inboundCsv",
  async (data: any) => {
    const { id, countryCode } = data;
    return DownloadCSVTemplate(id, countryCode)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const importCsvFile = createAsyncThunk(
  "user/inboundImportCsv",
  async (body: ImportCSVData, thunkAPI) => {
    return ImportCSVFile(body)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getContactList = createAsyncThunk(
  "user/contactList",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
      enquirySource: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey, enquirySource } =
      data;
    return GetContactList(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey,
      enquirySource
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const deleteContactList = createAsyncThunk(
  "user/deleteContactList",
  async (data: DeleteContactData, thunkAPI) => {
    return DeleteContactList(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const addEnquiryForContact = createAsyncThunk(
  "user/addEnquiryForContact",
  async (data: AddEnquiryDataFromContact, thunkAPI) => {
    try {
      const response = await CreateLeadFromContacts(data);
      if (response) {
        thunkAPI.dispatch(
          setToastNotification({
            message: response?.data?.message,
            type: "success",
            snackOpen: true,
          })
        );
      }

      return response?.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setToastNotification({
          message: error?.response?.data?.message,
          type: "error",
          snackOpen: true,
        })
      );
      throw error.response.data;
    }
  }
);

export const addEnquiryForZapier = createAsyncThunk(
  "user/addEnquiryForZapier",
  async (data: AddEnquiryDataFromContact, thunkAPI) => {
    try {
      const response = await CreateLeadFromContacts(data);
      if (response) {
        thunkAPI.dispatch(
          setToastNotification({
            message: response?.data?.message,
            type: "success",
            snackOpen: true,
          })
        );
      }

      return response?.data;
    } catch (error: any) {
      thunkAPI.dispatch(
        setToastNotification({
          message: error?.response?.data?.message,
          type: "error",
          snackOpen: true,
        })
      );
      throw error.response.data;
    }
  }
);

export const inboundImportSlice = createSlice({
  name: "inbound",
  initialState,
  reducers: {
    reset: () => initialState,
    resetDeleteContactSuccess: (state) => {
      state.deleteContactSuccess = false;
    },
    resetAddEnquirySuccess: (state) => {
      state.addedEnquiryContactSuccess = false;
      state.leadCreatedId = null;
      state.addedEnquiryZapierSuccess = false;
      state.leadCreatedIdForZapier = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCsvTemplate.pending, (state) => {
      state.templateDownloadLoading = true;
      state.templateDownloadSuccess = false;
      state.templateDownloadError = false;
    });
    builder.addCase(getCsvTemplate.fulfilled, (state, action: AnyAction) => {
      state.templateDownloadLoading = false;
      state.templateDownloadSuccess = true;
      state.templateDownloadError = false;
      state.templateData = action?.payload;
    });
    builder.addCase(getCsvTemplate.rejected, (state, action: AnyAction) => {
      state.templateDownloadLoading = false;
      state.templateDownloadSuccess = false;
      state.templateDownloadError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(importCsvFile.pending, (state) => {
      state.importCsvLoading = true;
      state.importCsvSuccess = false;
      state.importCsvError = false;
    });
    builder.addCase(importCsvFile.fulfilled, (state, action: AnyAction) => {
      state.importCsvLoading = false;
      state.importCsvSuccess = true;
      state.importCsvError = false;
      state.importCsvData = action?.payload;
    });
    builder.addCase(importCsvFile.rejected, (state, action: AnyAction) => {
      state.importCsvLoading = false;
      state.importCsvSuccess = false;
      state.importCsvError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getContactList.pending, (state) => {
      state.contactListLoading = true;
      state.contactListSuccess = false;
      state.contactListError = false;
    });
    builder.addCase(getContactList.fulfilled, (state, action: AnyAction) => {
      state.contactListLoading = false;
      state.contactListSuccess = true;
      state.contactListError = false;
      state.contactList = action.payload;
    });
    builder.addCase(getContactList.rejected, (state, action: AnyAction) => {
      state.contactListLoading = false;
      state.contactListSuccess = false;
      state.contactListError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(deleteContactList.pending, (state) => {
      state.deleteContactLoading = true;
      state.deleteContactSuccess = false;
      state.deleteContactError = false;
    });
    builder.addCase(deleteContactList.fulfilled, (state, action: AnyAction) => {
      state.deleteContactLoading = false;
      state.deleteContactSuccess = true;
      state.deleteContactError = false;
    });
    builder.addCase(deleteContactList.rejected, (state, action: AnyAction) => {
      state.deleteContactLoading = false;
      state.deleteContactSuccess = false;
      state.deleteContactError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(addEnquiryForContact.pending, (state) => {
      state.addedEnquiryContactLoading = true;
      state.addedEnquiryContactSuccess = false;
      state.addedEnquiryContactError = false;
    });
    builder.addCase(
      addEnquiryForContact.fulfilled,
      (state, action: AnyAction) => {
        state.addedEnquiryContactLoading = false;
        state.addedEnquiryContactSuccess =
          action?.payload?.message || "Lead Created";
        state.addedEnquiryContactError = false;
        state.leadCreatedId = action?.payload?.data?.enquiryData;
      }
    );
    builder.addCase(
      addEnquiryForContact.rejected,
      (state, action: AnyAction) => {
        state.addedEnquiryContactLoading = false;
        state.addedEnquiryContactSuccess = false;
        state.addedEnquiryContactError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getMailConfigsList.pending, (state) => {
      state.mailConfigListLoading = true;
      state.mailConfigListSuccess = false;
      state.mailConfigListError = false;
    });
    builder.addCase(
      getMailConfigsList.fulfilled,
      (state, action: AnyAction) => {
        state.mailConfigListLoading = false;
        state.mailConfigListSuccess = true;
        state.mailConfigListError = false;
        state.mailConfigsList = action?.payload;
      }
    );
    builder.addCase(getMailConfigsList.rejected, (state, action: AnyAction) => {
      state.mailConfigListLoading = false;
      state.mailConfigListSuccess = false;
      state.mailConfigListError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(addEnquiryForZapier.pending, (state) => {
      state.addedEnquiryZapierLoading = true;
      state.addedEnquiryZapierSuccess = false;
      state.addedEnquiryZapierError = false;
    });
    builder.addCase(
      addEnquiryForZapier.fulfilled,
      (state, action: AnyAction) => {
        state.addedEnquiryZapierLoading = false;
        state.addedEnquiryZapierSuccess =
          action?.payload?.message || "Lead Created";
        state.addedEnquiryZapierError = false;
        state.leadCreatedIdForZapier = action?.payload?.data?.enquiryData;
      }
    );
    builder.addCase(
      addEnquiryForZapier.rejected,
      (state, action: AnyAction) => {
        state.addedEnquiryZapierLoading = false;
        state.addedEnquiryZapierSuccess = false;
        state.addedEnquiryZapierError =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default inboundImportSlice.reducer;
export const { reset, resetAddEnquirySuccess, resetDeleteContactSuccess } =
  inboundImportSlice.actions;
