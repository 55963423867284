import React, { useEffect } from "react";
import styles from "../../components/Manegecourses.module.css";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";
import TagManager from "../Manager/TagManager";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { useDispatch } from "react-redux";
import {
  createTag,
  removeNewTag,
  resetSuccessCreateTag,
} from "../../service/tagSlice";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { updateCourse } from "../../service/courseSlice";

function CourseTag({
  isTagsEditOpen,
  courseDetails,
  setIsTagsEditOpen,
  courseTagList,
  setCourseDetails,
  courseById,
  loading_updateCourse,
  CourseConfig,
  hasCourseAccess,
}: any) {
  const [validStateTag, setValidStateTag] = React.useState<boolean>(true);

  const { newAddedSelectedTag, success_createTag } = useSelector(
    (state: any) => state.tag && state.tag
  );

  const auth = useAuthUser();
  const authUser = auth();
  const activeInstituteForCategory = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );
  const activeUserForCategory = authUser;
  const dispatch = useDispatch();

  useEffect(() => {
    if (success_createTag) {
      CourseConfig();
      dispatch(resetSuccessCreateTag());
    }
  }, [success_createTag, CourseConfig]);

  useEffect(() => {
    setCourseDetails({
      ...courseDetails,
      courseTags:
        courseDetails &&
        courseDetails?.courseTags?.concat(
          newAddedSelectedTag.filter(
            (item: any) => courseDetails.courseTags.indexOf(item) < 0
          )
        ),
    });
  }, [success_createTag, newAddedSelectedTag]);

  const handleCreateTag = React.useCallback(
    (courseTag: string) => {
      const array = courseTag.split(",");
      array.map(async (obj: any) => {
        dispatch<any>(
          await createTag({
            tagName: obj,
            instituteId:
              activeInstituteForCategory.id && activeInstituteForCategory.id,
            instituteName:
              activeInstituteForCategory.instituteName &&
              activeInstituteForCategory.instituteName,
            approverId: "",
            approverName: "",
            addedById:
              activeUserForCategory?.userId && activeUserForCategory?.userId,
            addedByName:
              activeUserForCategory?.firstName &&
              activeUserForCategory?.firstName +
                " " +
                activeUserForCategory?.lastName,
          })
        );
      });
    },
    [createTag, activeInstituteForCategory, activeUserForCategory]
  );

  const handleTagsChange = React.useCallback(
    (courseTags: any) => {
      const updatedArray = newAddedSelectedTag?.filter((obj2: any) => {
        return courseTags
          .filter((obj: any) => typeof obj === "object")
          ?.some((obj1: any) => obj1.id === obj2.id);
      });
      dispatch(removeNewTag(updatedArray));

      setValidStateTag(true);
      setCourseDetails({
        ...courseDetails,
        courseTags: courseTags.filter((obj: any) => typeof obj === "object"),
      });
    },
    [courseDetails]
  );

  const handleTagsSubmit = () => {
    dispatch<any>(
      updateCourse({
        ...courseDetails,
        courseTags: courseDetails?.courseTags?.concat(
          newAddedSelectedTag?.filter(
            (item: any) => courseDetails?.courseTags?.indexOf(item) < 0
          )
        ),
        id: courseById?.id,
      })
    );
  };

  return (
    <>
      <div
        className={styles.tagsSection}
        style={{ height: isTagsEditOpen ? "400px" : "200px" }}
      >
        <div className={styles.tagsHeader}>
          <div className={styles.courseBoxTitle}>Tags</div>
          {courseDetails.courseTags?.length > 0 && hasCourseAccess && (
            <EditIconBtn
              onClick={() => {
                setIsTagsEditOpen(true);
              }}
            />
          )}
        </div>
        {isTagsEditOpen ? (
          <>
            <TagManager
              CreateTag={handleCreateTag}
              handleTagsChange={handleTagsChange}
              selectedTags={courseDetails.courseTags}
              tagsList={courseTagList}
              validStateTag={validStateTag}
            />
            <div className={styles.suggestedTags}>
              Suggested Tags: Programming, Management, Design, Skill
              Development, Web Development
            </div>
            <div className={styles.buttonSection}>
              <CancelBtn onClick={() => setIsTagsEditOpen(false)} />

              <div style={{ marginLeft: "5px" }}>
                <SaveBtn
                  onClick={handleTagsSubmit}
                  loader={loading_updateCourse}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {courseById?.courseTags?.length > 0 ? (
              <div className={styles.courseDetailsTagsValues}>
                {courseById?.courseTags?.map((item: any, index: number) => (
                  <div
                    className={styles.courseDetailsTagsValue}
                    key={index}
                    style={{
                      color:
                        item?.status === "REVIEW_REJECTED" ||
                        item?.status === "UNDER_REVIEW"
                          ? "red"
                          : "#142c44",
                    }}
                  >
                    {item?.tagname}
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.noBatchTimingContainer}>
                <div className={styles.noDetailsText}>No Details Yet</div>
                {hasCourseAccess && (
                  <div
                    className={styles.noSchedulesText}
                    onClick={() => {
                      setIsTagsEditOpen(true);
                    }}
                  >
                    + Add Tags
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CourseTag;
