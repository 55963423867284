import { Box, ListItem, ListItemText } from "@mui/material";
import styles from "./ProfileSetup.module.css";

export function ProfileSetup(props: any) {
  const { index, item } = props;
  return (
    <ListItem
      className={
        item.status === false
          ? styles.list_item_progress
          : item.status === true
          ? styles.list_item_completed
          : styles.list_item
      }
      sx={{
        width: "19%",
      }}
      key={item}
      disablePadding
    >
      <Box className={styles.number_wrapperdiv}>
        <Box
          className={
            item.status === false
              ? styles.list_font_staging_progress
              : item.status === true
              ? styles.list_font_staging_completed
              : styles.list_font_staging
          }
        >
          {index + 1}
        </Box>
      </Box>
      <ListItemText
        primaryTypographyProps={{
          fontSize: "14px",
        }}
        className={
          item.status === false
            ? styles.list_font_progress
            : item.status === true
            ? styles.list_font_completed
            : styles.list_font
        }
        primary={item.name}
      />
    </ListItem>
  );
}
