import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DiscountInput from "../../../../../../components/FormTextInput/DiscountInput";
import styles from "./LeadRegularPay.module.css";
import { timeZoneConverter } from "../../../../../../components/Formatter/Formatter";
import { useDispatch } from "react-redux";
import toggleRadio from "../../../../../../assets/images/toggle-radio.svg";
import toggleRadioSelected from "../../../../../../assets/images/toggle-radio-selected.svg";
import { updateLeadEmiDetails } from "../../../service/nurtureSlice";
import SwitchInput from "../../../../../../components/Switch/SwitchInput";

interface EmiType {
  emiNumber: number;
  emiName: string;
  emiAmount: number;
  dueDate: number | null;
  isNameValid: boolean;
  isPriceValid: boolean;
}

const emiModesItems = [
  {
    id: "1",
    emiModeValue: "MONTHLY",
    emiModeLabel: "Monthly",
  },
  {
    id: "2",
    emiModeValue: "QUARTERLY",
    emiModeLabel: "Quarterly",
  },
  {
    id: "3",
    emiModeValue: "HALF_YEARLY",
    emiModeLabel: "Half yearly",
  },
  {
    id: "4",
    emiModeValue: "YEARLY",
    emiModeLabel: "Yearly",
  },
  {
    id: "5",
    emiModeValue: "CUSTOM",
    emiModeLabel: "Custom",
  },
];

function EditRegularPayDetails(props: any) {
  const {
		leadData,
		leadEmiDetailsLoading,
		isPayEditOpen,
		setIsPayEditOpen,
		selectedCurrency,
	} = props;
	const auth = useAuthUser();
	const authUser = auth();
	const dispatch = useDispatch();

	const batchStartDateMilliSeconds =
		leadData?.batchStartDate?.toString()?.length !== 13
			? leadData?.batchStartDate * 1000
			: leadData?.batchStartDate;
	const batchEndDateMilliSeconds =
		leadData?.bacthEndDate?.toString()?.length !== 13
			? leadData?.bacthEndDate * 1000
			: leadData?.bacthEndDate;
	const paidEmis = leadData?.paymentDetails?.EMIDetails?.EMIS?.filter(
		(item: any) =>
			item?.emiPaymentStatus === 'FULLY_PAID' ||
			item?.emiPaymentStatus === 'PARTIALLY_PAID'
	);
	const lastPaidEmi = paidEmis?.[paidEmis.length - 1];

	const currentDateInMilliseconds = new Date().getTime();

	const numberOfDays =
		Math.floor(
			(batchEndDateMilliSeconds - batchStartDateMilliSeconds) /
				(1000 * 60 * 60 * 24)
		) + 1;

	const availableEmiModes = (() => {
		switch (true) {
			case numberOfDays > 360:
				return emiModesItems;

			case numberOfDays > 180:
				return emiModesItems.filter((item) => item.emiModeValue !== 'YEARLY');

			case numberOfDays >= 60:
				return emiModesItems.filter(
					(item) =>
						item.emiModeValue === 'MONTHLY' ||
						item.emiModeValue === 'QUARTERLY' ||
						item.emiModeValue === 'CUSTOM'
				);

			case numberOfDays >= 30:
				return emiModesItems.filter((item) => item.emiModeValue === 'MONTHLY');

			default:
				return [];
		}
	})();

	const [regularPrice, setRegularPrice] = React.useState<string>('');
	const [discountMetric, setDiscountMetric] = React.useState<string>('');
	const [discount, setDiscount] = React.useState<string>('');
	const [finalPrice, setFinalPrice] = React.useState<string>('');
	const [regularPriceError, setRegularPriceError] =
		React.useState<boolean>(false);
	const [enableInstallment, setEnableInstallment] =
		React.useState<boolean>(false);
	const [setupInstallment, setSetupInstallment] =
		React.useState<boolean>(false);

	const [installmentNumber, setInstallmentNumber] = React.useState<number>(0);
	const [selectedEmiMode, setSelectedEmiMode] = React.useState<string>('');
	const [selectedEmiModeDays, setSelectedEmiModeDays] =
		React.useState<number>(0);
	const [selectedEmiModeError, setSelectedEmiModeError] =
		React.useState<boolean>(false);

	const [startDate, setStartDate] = React.useState<any>(null);
	const [emiStartDate, setEmiStartDate] = React.useState<number>(
		moment().valueOf()
	);

	const [rows, setRows] = React.useState<any>([]);
	const balanceAmount =
		parseFloat(finalPrice) -
		(leadData?.batchAmountPaid ? leadData?.batchAmountPaid : 0);

	const handleTotalPrice = (value: string) => {
		setRegularPrice(value);
		if (value === '') {
			setRegularPriceError(true);
		} else {
			setRegularPriceError(false);
		}
		if (discountMetric === 'AMOUNT') {
			setFinalPrice((parseFloat(value) - parseFloat(discount)).toString());
		} else if (discountMetric === 'PERCENTAGE') {
			setFinalPrice(
				(parseFloat(value) * (1 - parseFloat(discount) / 100)).toString()
			);
		} else {
			setFinalPrice(value);
		}
	};

	const handleDiscountMetric = (value: string) => {
		setDiscountMetric(value);
		setDiscount('');
		setFinalPrice(leadData?.paymentDetails?.finalAmount);
	};

	const handleDiscount = (value: string) => {
		if (value !== '') {
			if (discountMetric === 'AMOUNT') {
				if (parseFloat(value) > parseFloat(regularPrice)) {
					setFinalPrice(
						(parseFloat(regularPrice) - parseFloat(regularPrice)).toString()
					);
					setDiscount(regularPrice);
				} else {
					setDiscount(value);
					setFinalPrice(
						(parseFloat(regularPrice) - parseFloat(value)).toString()
					);
				}
			} else if (discountMetric === 'PERCENTAGE') {
				if (parseFloat(value) > 100) {
					setFinalPrice(
						(parseFloat(regularPrice) * (1 - 100 / 100)).toString()
					);
					setDiscount('100');
				} else {
					setDiscount(value);
					setFinalPrice(
						(
							parseFloat(regularPrice) *
							(1 - parseFloat(value) / 100)
						).toString()
					);
				}
			}
		} else {
			setFinalPrice(regularPrice);
			setDiscount('');
		}
	};

	const handleEnableInstallment = () => {
		setEnableInstallment(!enableInstallment);
		setSetupInstallment(false);
		setRows([]);
		setInstallmentNumber(0);
		setSelectedEmiMode('');
		setSelectedEmiModeDays(0);
		setStartDate(null);
	};

	const handleSetupEmi = (event: any) => {
		setSetupInstallment(event.target.checked);
		setRows([]);
		setInstallmentNumber(0);
		setSelectedEmiMode('');
		setSelectedEmiModeDays(0);
		setStartDate(null);
	};

	const handleEmiMode = React.useCallback(
		(emiMode: any) => {
			setSelectedEmiMode(emiMode);
			// setSelectedEmiModeError(false);
			let emiDays: number;

			switch (emiMode) {
				case 'MONTHLY':
					emiDays = 30;
					break;
				case 'QUARTERLY':
					emiDays = 90;
					break;
				case 'HALF_YEARLY':
					emiDays = 180;
					break;
				case 'YEARLY':
					emiDays = 360;
					break;
				default:
					emiDays = 0;
			}
			setSelectedEmiModeDays(emiDays);
			if (rows?.length > 0 && emiDays > 0) {
				let dueDate = emiStartDate;
				// const amountPerEmi = parseFloat(finalPrice) / installmentNumber;
				const amountPerEmi = balanceAmount / installmentNumber;

				const newEmis: EmiType[] = [];

				for (let index = 0; index < installmentNumber; index++) {
					if (index > 0) {
						dueDate += emiDays * 24 * 60 * 60 * 1000;
					}
					newEmis.push({
						emiNumber: index + 1,
						emiName: `EMI ${index + 1}`,
						emiAmount: Number(amountPerEmi.toFixed(2)),
						dueDate: dueDate,
						isPriceValid: true,
						isNameValid: true,
					});
				}

				setRows(newEmis);
			} else {
				if (emiStartDate && startDate !== null && installmentNumber > 0) {
					let dueDate = emiStartDate;
					const amountPerEmi = balanceAmount / installmentNumber;

					const newEmis: EmiType[] = [];

					for (let index = 0; index < installmentNumber; index++) {
						if (index > 0) {
							dueDate = 0;
						} else {
							dueDate = new Date(emiStartDate).setHours(23, 59, 59, 0);
						}
						newEmis.push({
							emiNumber: index + 1,
							emiName: `EMI ${index + 1}`,
							emiAmount: Number(amountPerEmi.toFixed(2)),
							dueDate: dueDate === 0 ? null : dueDate,
							isPriceValid: true,
							isNameValid: true,
						});
					}
					setRows(newEmis);
				} else {
					setRows([]);
				}
			}
		},
		[selectedEmiModeDays, rows, emiStartDate, finalPrice, installmentNumber]
	);

	const handleDateChange = React.useCallback(
		(date: any) => {
			const dateUnix = date.unix() * 1000;
			setEmiStartDate(dateUnix);
			let dueDate = dateUnix;
			const amountPerEmi = balanceAmount / installmentNumber;
			const newEmis: EmiType[] = [];

			for (let index = 0; index < installmentNumber; index++) {
				if (index > 0) {
					if (selectedEmiMode === 'CUSTOM') {
						dueDate = 0;
					} else {
						dueDate += selectedEmiModeDays * 24 * 60 * 60 * 1000;
					}
				} else {
					dueDate = new Date(dueDate).setHours(23, 59, 59, 0);
				}

				newEmis.push({
					emiNumber: index + 1,
					emiName: `EMI ${index + 1}`,
					emiAmount: Number(amountPerEmi.toFixed(2)),
					dueDate: dueDate === 0 ? null : dueDate,
					isPriceValid: true,
					isNameValid: true,
				});
			}

			setRows(newEmis);
		},
		[finalPrice, installmentNumber, selectedEmiModeDays]
	);

	const handleEmiCount = React.useCallback(
		(emiCount: any) => {
			setInstallmentNumber(emiCount);
			const amountPerEmi = balanceAmount / emiCount;
			if (amountPerEmi !== 0) {
				let currentDueDateTimestamp = emiStartDate;

				const newEmis: EmiType[] = [];

				for (let index = 0; index < emiCount; index++) {
					if (index > 0) {
						if (selectedEmiMode === 'CUSTOM') {
							currentDueDateTimestamp = 0;
						} else {
							currentDueDateTimestamp +=
								selectedEmiModeDays * 24 * 60 * 60 * 1000;
						}
					} else {
						currentDueDateTimestamp = new Date(emiStartDate).setHours(
							23,
							59,
							59,
							0
						);
					}
					newEmis.push({
						emiNumber: index + 1,
						emiName: `EMI ${index + 1}`,
						emiAmount: amountPerEmi,
						dueDate:
							currentDueDateTimestamp === 0 ? null : currentDueDateTimestamp,
						isPriceValid: true,
						isNameValid: true,
					});
				}
				setRows(newEmis);
			}
		},
		[selectedEmiMode, selectedEmiModeDays, emiStartDate, finalPrice]
	);

	const isNameUniqueCheck = (rows: any, row: any) => {
		return !rows?.some(
			(r: any) =>
				r?.emiNumber !== row?.emiNumber &&
				r?.emiName?.toLowerCase() === row?.emiName?.toLowerCase()
		);
	};
	// isNameValid:
	//   value !== "" && isNameUniqueCheck(updatedEmis, updatedEmis[index])
	//     ? true
	//     : false,
	const handleEmiNameChange = React.useCallback(
		(index: number, event: any) => {
			const { value } = event.target;

			const updatedEmis = [...rows];

			const isNameUnique = !updatedEmis.some(
				(r, i) =>
					i !== index && r.emiName?.toLowerCase() === value?.toLowerCase()
			);

			updatedEmis[index].emiName = value;

			// updatedEmis[index] = {
			//   ...updatedEmis[index],
			//   emiName: value,
			//   isNameValid: value !== "" && isNameUnique ? true : false,
			// };

			updatedEmis.forEach((row, i) => {
				const isNameUnique = isNameUniqueCheck(updatedEmis, row);
				updatedEmis[i] = {
					...updatedEmis[i],
					isNameValid: row.emiName !== '' && isNameUnique,
				};
			});

			setRows(updatedEmis);
		},
		[rows]
	);

	const handleEmiPriceChange = React.useCallback(
		(index: number, event: any) => {
			const { value } = event.target;

			const updatedEmis = [...rows];

			updatedEmis[index] = {
				...updatedEmis[index],
				emiAmount: parseFloat(value),
				isPriceValid: value !== '' && parseFloat(value) > 0 ? true : false,
			};

			setRows(updatedEmis);
		},
		[rows]
	);

	const handleEmiDueDate = React.useCallback(
		(index: number, date: any) => {
			const dateUnix = date.unix() * 1000;
			const newDate = new Date(dateUnix);
			newDate.setHours(23, 59, 59, 999);

			const updatedEmis = [...rows];

			updatedEmis[index] = {
				...updatedEmis[index],
				dueDate: newDate.getTime(),
			};

			setRows(updatedEmis);
			if (index === 0) {
				setEmiStartDate(dateUnix);
				setStartDate(dateUnix);
			}
		},
		[rows]
	);

	const isNameExistInPaid = (row: any) => {
		return !paidEmis?.some(
			(r: any) => r?.emiName?.toLowerCase() === row?.emiName?.toLowerCase()
		);
	};

	const cumulativeEmiAmount = rows?.reduce((total: any, emiItem: any) => {
		return total + (emiItem.emiAmount || 0);
	}, 0);

	const balanceAmountInEmi = balanceAmount - cumulativeEmiAmount;

	const checkCumulativeEmiAmount = React.useCallback(() => {
		if (cumulativeEmiAmount < Number(balanceAmount) - 1) {
			return 'Cumulative EMI amount is less than the regular price.';
		} else if (cumulativeEmiAmount > Number(balanceAmount) + 0.99) {
			return 'Cumulative EMI amount is greater than the regular price.';
		} else {
			return null;
		}
	}, [rows, balanceAmount]);

	const isDueDateAfterPrevious = (index: number, rows: any[]): boolean => {
		if (index > 0) {
			const currentDueDate = dayjs(rows[index]?.dueDate).startOf('day');
			const previousDueDate = dayjs(rows[index - 1]?.dueDate).startOf('day');
			return currentDueDate.isAfter(previousDueDate);
		}
		return true;
	};

	const hasEmptyPaymentName = rows?.some((row: any) => row.emiName === '');
	const hasPriceNotGreaterThanZero = rows.every(
		(row: any) => row.emiAmount > 0
	);
	const hasDuplicatePaymentName = rows?.some(
		(item: any, index: number) =>
			rows?.findIndex(
				(i: any) => i?.emiName?.toLowerCase() === item?.emiName?.toLowerCase()
			) !== index
	);

	const hasDuplicatePaidPaymentName = rows?.some((row: any) =>
		paidEmis?.some((paidEmi: any) => row.emiName === paidEmi.emiName)
	);

	const hasDueDateEmpty = rows?.some((row: any) => row.dueDate === null);

	// const isDueDatesValid = (customEmis: any): boolean => {
	//   let prevDueDate = 0;

	//   for (const emi of customEmis) {
	//     const dueDate = emi.dueDate;
	//     if (dueDate <= prevDueDate) {
	//       return false;
	//     }

	//     prevDueDate = dueDate;
	//   }

	//   return true;
	// };

	const isDueDatesValid = (customEmis: any[]): boolean => {
		let prevDueDate: dayjs.Dayjs | null = null;

		for (const emi of customEmis) {
			const dueDate = dayjs(emi.dueDate).startOf('day');

			if (
				!dueDate.isValid() ||
				(prevDueDate && !dueDate.isAfter(prevDueDate))
			) {
				return false;
			}

			prevDueDate = dueDate;
		}

		return true;
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		try {
			const emiStartDate = rows[0]?.dueDate;
			const emiEndDate = rows[rows.length - 1]?.dueDate;
			let emiModeError = false;
			if (selectedEmiMode === '') {
				setSelectedEmiModeError(true);
				emiModeError = true;
			} else {
				setSelectedEmiModeError(false);
				emiModeError = false;
			}

			let endDateError = false;
			if (emiEndDate > batchEndDateMilliSeconds) {
				//  setEndDateError(true);
				endDateError = true;
			} else if (emiEndDate < batchEndDateMilliSeconds) {
				//  setEndDateError(false);
				endDateError = false;
			}

			let discountError = false;
			if (discountMetric !== '' && parseFloat(discount) === 0) {
				discountError = true;
			} else if (emiEndDate < batchEndDateMilliSeconds) {
				discountError = false;
			}

			const updatedEmis = rows?.map((emi: any) => {
				const currentDate = new Date().setHours(0, 0, 0, 0);
				const dueDate = new Date(emi.dueDate).setHours(23, 59, 59, 0);
				const paymentStatus = dueDate < currentDate ? 'OVERDUE' : 'UPCOMING';
				return {
					emiNumber: emi.emiNumber,
					emiName: emi.emiName,
					emiAmount: emi.emiAmount,
					dueDate: dueDate,
					paymentStatus: paymentStatus,
					paymentId: '',
				};
			});
			const body = {
				id: leadData?.id,
				paymentType: 'REGULAR',
				totalAmount: parseFloat(regularPrice),
				discountMetric:
					discountMetric !== '' && parseFloat(discount) > 0
						? discountMetric
						: '',
				discount:
					discountMetric !== '' && parseFloat(discount) > 0
						? parseFloat(discount)
						: 0,
				finalAmount: parseFloat(finalPrice),
				discountAdded: false,
				enableInstallment: enableInstallment,
				scheduleInstallment: setupInstallment,
				numberOfInstallment: installmentNumber,
				emiMode: selectedEmiMode,
				startDate:
					rows?.length > 0
						? new Date(rows[0]?.dueDate).setHours(0, 0, 0, 0)
						: 0,
				endDate:
					rows?.length > 0
						? new Date(rows[rows.length - 1]?.dueDate).setHours(23, 59, 59, 0)
						: 0,
				EMIS: setupInstallment ? updatedEmis : [],
				paymentStructure: [],
				updatedById: authUser?.institute[0]?.id,
				updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
			};
			if (
				enableInstallment &&
				setupInstallment &&
				updatedEmis?.length > 0 &&
				!hasEmptyPaymentName &&
				hasPriceNotGreaterThanZero &&
				checkCumulativeEmiAmount() === null &&
				!endDateError &&
				!discountError
			) {
				dispatch<any>(updateLeadEmiDetails(body));
			} else if (
				!enableInstallment &&
				!setupInstallment &&
				// checkCumulativeEmiAmount() === null &&
				// !endDateError &&
				!discountError
			) {
				dispatch<any>(updateLeadEmiDetails(body));
			}
		} catch (error) {}
	};

	React.useEffect(() => {
		if (leadData) {
			setRegularPrice(leadData?.paymentDetails?.totalAmount);
			setFinalPrice(leadData?.paymentDetails?.finalAmount);
			if (
				leadData?.paymentDetails?.discountMetric !== '' &&
				leadData?.paymentDetails?.discount > 0
			) {
				setDiscount(leadData?.paymentDetails?.discount);
				setDiscountMetric(leadData?.paymentDetails?.discountMetric);
			}
			setEnableInstallment(leadData?.paymentDetails?.enableInstallment);
			setSetupInstallment(leadData?.paymentDetails?.scheduleInstallment);
			const emiMode = leadData?.paymentDetails?.EMIDetails?.emiMode;
			setSelectedEmiMode(leadData?.paymentDetails?.EMIDetails?.emiMode);
			let emiDays: number;

			switch (emiMode) {
				case 'MONTHLY':
					emiDays = 30;
					break;
				case 'QUARTERLY':
					emiDays = 90;
					break;
				case 'HALF_YEARLY':
					emiDays = 180;
					break;
				case 'YEARLY':
					emiDays = 360;
					break;
				default:
					emiDays = 0;
			}
			setSelectedEmiModeDays(emiDays);
			const unpaidEmis = leadData?.paymentDetails?.EMIDetails?.EMIS?.filter(
				(item: any) =>
					item?.paymentStatus !== 'PAID' && item?.paymentStatus !== 'PASSED_ON'
			);
			if (paidEmis?.length > 0) {
				setStartDate(lastPaidEmi?.dueDate + 86400000);
				setEmiStartDate(lastPaidEmi?.dueDate + 86400000);
				let dueDate = lastPaidEmi?.dueDate + 86400000;
				if (leadData?.paymentDetails?.EMIDetails?.EMIS?.length > 0) {
					const setEmis = unpaidEmis?.map((emi: any, index: number) => {
						if (index > 0) {
							dueDate += emiDays * 24 * 60 * 60 * 1000;
						}
						const isNameUnique = !unpaidEmis?.some(
							(r: any, i: number) =>
								i !== index &&
								r.emiName?.toLowerCase() === emi?.emiName?.toLowerCase()
						);
						return {
							emiNumber: index + 1,
							emiName: emi.emiName,
							emiAmount: emi.emiAmount,
							dueDate: new Date(dueDate).setHours(23, 59, 59, 0),
							isNameValid: !isNameExistInPaid(emi) ? false : true,
							isPriceValid: true,
						};
					});
					setRows(setEmis);
					setInstallmentNumber(setEmis?.length > 0 ? setEmis?.length : 0);
				}
			} else {
				setStartDate(leadData?.paymentDetails?.startDate);
				setEmiStartDate(leadData?.paymentDetails?.startDate);
				if (leadData?.paymentDetails?.EMIDetails?.EMIS?.length > 0) {
					const setEmis = unpaidEmis?.map((emi: any, index: number) => {
						return {
							emiNumber: index + 1,
							emiName: emi.emiName,
							emiAmount: emi.emiAmount,
							dueDate: emi.dueDate,
							isNameValid: true,
							isPriceValid: true,
						};
					});
					setRows(setEmis);
					setInstallmentNumber(setEmis?.length > 0 ? setEmis?.length : 0);
				}
			}
		}
	}, [leadData, isPayEditOpen]);

	return (
		<div>
			<div className={styles.leadEditRegularPayWrap}>
				<div
					className={styles.leadEditRegularPayRow}
					style={{ justifyContent: 'space-between' }}>
					<div
						className={styles.leadEditRegularPayPrice}
						style={{ maxWidth: '200px' }}>
						<div className={styles.leadEmiEditMainRowLabel}>Total Price</div>
						<TextField
							id='totalPrice'
							variant='outlined'
							type='number'
							fullWidth
							inputProps={{
								sx: {
									width: '200px',
									'&::placeholder': {
										fontWeight: '400',
										fontSize: '14px',
									},

									'&.MuiOutlinedInput-input': {
										padding: '8px',
									},
								},
							}}
							value={regularPrice}
							onChange={(event) => handleTotalPrice(event.target.value)}
							error={regularPriceError}
							helperText={regularPriceError ? 'Please fill valid amount' : ''}
							disabled
						/>
					</div>
					<div>
						<div className={styles.leadEmiEditMainRowLabel}>Discount</div>
						<DiscountInput
							discountMetric={discountMetric}
							discount={discount}
							handleDiscountMetric={handleDiscountMetric}
							handleDiscount={handleDiscount}
							totalPrice={regularPrice}
							selectedCurrency={selectedCurrency}
						/>
					</div>

					<div style={{ minWidth: '200px' }}>
						<div className={styles.leadEmiEditMainRowLabel}>Final Price</div>
						<div
							style={{
								fontFamily: 'Roboto',
								fontSize: '18px',
								color: '#11C89B',
								fontWeight: '500',
							}}>
							{/* 				{parseFloat(finalPrice)?.toLocaleString('en-IN', {
								style: 'currency',
								currency: 'INR',
							})} */}
							{selectedCurrency + ' ' + parseFloat(finalPrice)}
						</div>
					</div>
				</div>
				<div className={styles.leadEditRegularPayRow}>
					<div style={{ minWidth: '200px' }}>
						<div className={styles.leadEmiEditMainRowLabel}>Balance</div>
						<div
							style={{
								fontFamily: 'Roboto',
								fontSize: '16px',
								color: '#142c44',
								fontWeight: '500',
							}}>
							{/* 		{balanceAmount?.toLocaleString('en-IN', {
								style: 'currency',
								currency: 'INR',
							})} */}
							{selectedCurrency + ' ' + balanceAmount}
						</div>
					</div>
					<div>
						<div className={styles.leadEmiEditMainRowLabel}>Installment</div>
						<div className={styles.leadEditRegularEmiStatus}>
							<SwitchInput
								label='Enable'
								switchStatus={enableInstallment}
								handleSwitch={handleEnableInstallment}
							/>
							{enableInstallment && (
								<FormControlLabel
									control={
										<Checkbox
											checked={setupInstallment}
											onChange={handleSetupEmi}
											sx={{
												strokeWidth: 1,
												color: '#D0D5DD',
												'&:hover': {
													backgroundColor: 'transparent',
												},
												'&:focus': {
													backgroundColor: 'transparent',
												},
											}}
										/>
									}
									label={
										<Typography
											style={{
												fontSize: '12px',
												color: '#667085',
												lineHeight: '16px',
											}}>
											Configure installment
										</Typography>
									}
								/>
							)}
						</div>
					</div>
				</div>
				{setupInstallment && (
					<>
						<div className={styles.leadEmiTableTitle}>Setup Installment</div>
						<div className={styles.leadEmiEditMainRow}>
							<div>
								<div className={styles.leadEmiEditMainRowLabel}>Mode</div>
								<div style={{ maxWidth: '180px', minWidth: '180px' }}>
									<Select
										id='emi-mode-select'
										fullWidth
										MenuProps={{
											style: {
												zIndex: 999999,
												fontSize: '14px',
											},
											PaperProps: {
												style: {
													boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
												},
											},
										}}
										sx={{
											maxHeight: '39PX',

											'& .MuiSelect-outlined': {
												fontSize: '14px',
												color: '#142C44',
											},
										}}
										value={selectedEmiMode}
										onChange={(event) =>
											handleEmiMode(event.target.value as string)
										}>
										{availableEmiModes?.map((item) => (
											<MenuItem
												key={item.id}
												value={item.emiModeValue}
												sx={{
													fontSize: '12PX',
													color: '#667085',
													textTransform: 'capitalize',
													fontWeight: '400',
												}}>
												{item.emiModeLabel}
											</MenuItem>
										))}
									</Select>
								</div>
							</div>

							{selectedEmiMode !== '' && (
								<>
									<div style={{ minWidth: '180px', maxWidth: '180px' }}>
										<div className={styles.leadEmiEditMainRowLabel}>
											Start Date
										</div>
										<div>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DesktopDatePicker
													sx={{
														zIndex: '99999999 !important',
														width: '180px',
														fontSize: '14px',
														'& .MuiOutlinedInput-input': {
															fontSize: '14px',
															color: '#142C44',
															padding: '9px',
														},
													}}
													format='DD/MM/YYYY'
													onChange={(date: any) => {
														setStartDate(date);
														handleDateChange(date);
													}}
													//   value={dayjs(startDate)}
													value={startDate ? dayjs(startDate) : null}
													shouldDisableDate={(date) => {
														const adjustedLastPaidEmiDate =
															paidEmis?.length > 0
																? dayjs(lastPaidEmi?.dueDate).add(1, 'day') // Add 1 day here
																: batchStartDateMilliSeconds;

														const isBeforeStart = dayjs(date).isBefore(
															adjustedLastPaidEmiDate,
															'day'
														);
														// const isBeforeStart = dayjs(date).isBefore(
														//   paidEmis?.length > 0
														//     ? lastPaidEmi?.dueDate
														//     : batchStartDateMilliSeconds,
														//   "day"
														// );

														const oneDayABeforeBatchEnd = dayjs(
															batchEndDateMilliSeconds
														).subtract(1, 'day');
														const isAfterEnd = dayjs(date).isAfter(
															oneDayABeforeBatchEnd
														);
														return isBeforeStart || isAfterEnd;
													}}
												/>
											</LocalizationProvider>
										</div>
									</div>

									<div style={{ minWidth: '180px' }}>
										{startDate !== null && (
											<>
												<div className={styles.leadEmiEditMainRowLabel}>
													No. of Installments
												</div>
												<div style={{ maxWidth: '200px', overflow: 'hidden' }}>
													<TextField
														id='totalPrice'
														variant='outlined'
														type='number'
														inputProps={{
															sx: {
																//   maxWidth: "200px",
																//   width: "200px",
																'&::placeholder': {
																	fontWeight: '400',
																	fontSize: '14px',
																},

																'&.MuiOutlinedInput-input': {
																	padding: '8px',
																},
															},
														}}
														value={installmentNumber}
														onChange={(event: any) =>
															handleEmiCount(event.target.value)
														}
														//   error={regularPriceError}
														//   helperText={regularPriceError ? "Please fill valid amount" : ""}
													/>
												</div>
											</>
										)}
									</div>
								</>
							)}
						</div>
						{rows?.length > 0 && (
							<>
								<div>
									<div className={styles.leadEmiTableTitle}>Breakdowns</div>
									<div
										className={styles.leadEmiTable}
										style={{ border: 'none' }}>
										<div className={styles.leadEmiTableHead}>
											<div style={{ width: '5%' }}>#</div>
											<div style={{ width: '37%' }}>Installment Name</div>
											<div style={{ width: '36%' }}>Installment Amount</div>
											<div style={{ width: '20%' }}>Due Date</div>
										</div>
										<div className={styles.leadEmiTableBody}>
											{rows?.map((emiItem: any, index: number) => (
												<div className={styles.leadEmiTableRow} key={index}>
													<div style={{ width: '5%', marginTop: '8PX' }}>
														{emiItem?.emiNumber}
													</div>
													<div style={{ width: '37%' }}>
														<TextField
															id='emiName'
															variant='outlined'
															type='text'
															inputProps={{
																sx: {
																	width: '120px',
																	maxWidth: '170px',
																	'&::placeholder': {
																		fontWeight: '400',
																		fontSize: '14px',
																	},

																	'&.MuiOutlinedInput-input': {
																		padding: '8px',
																	},
																},
															}}
															value={emiItem?.emiName}
															onChange={(event) =>
																handleEmiNameChange(index, event)
															}
															error={
																!emiItem?.isNameValid ||
																!isNameUniqueCheck(rows, emiItem) ||
																!isNameExistInPaid(emiItem)
															}
															// helperText={
															//   !isNameExistInPaid(emiItem)
															//     ? "Payment name already exist in Paid EMI"
															//     : !isNameUniqueCheck(rows, emiItem) &&
															//       !emiItem?.isNameValid
															//     ? "Payment name already exist"
															//     : isNameUniqueCheck(rows, emiItem) &&
															//       !emiItem?.isNameValid
															//     ? "Please fill the field"
															//     : ""
															// }
														/>
														{(!emiItem?.isNameValid ||
															!isNameUniqueCheck(rows, emiItem) ||
															!isNameExistInPaid(emiItem)) && (
															<FormHelperText error>
																{!isNameExistInPaid(emiItem)
																	? 'Payment name already exist in Paid EMI'
																	: !isNameUniqueCheck(rows, emiItem) &&
																	  !emiItem?.isNameValid
																	? 'Payment name already exist'
																	: isNameUniqueCheck(rows, emiItem) &&
																	  !emiItem?.isNameValid
																	? 'Please fill the field'
																	: ''}
															</FormHelperText>
														)}
													</div>
													<div style={{ width: '36%' }}>
														<TextField
															id='emiPrice'
															variant='outlined'
															type='number'
															inputProps={{
																sx: {
																	width: '120px',
																	maxWidth: '170px',
																	'&::placeholder': {
																		fontWeight: '400',
																		fontSize: '14px',
																	},

																	'&.MuiOutlinedInput-input': {
																		padding: '8px',
																	},
																},
															}}
															value={emiItem?.emiAmount}
															onChange={(event) =>
																handleEmiPriceChange(index, event)
															}
															error={
																!emiItem?.isPriceValid ||
																emiItem?.emiAmount > regularPrice
															}
															helperText={
																!emiItem?.isPriceValid
																	? 'Please fill valid amount'
																	: emiItem?.isPriceValid &&
																	  emiItem?.emiAmount > regularPrice
																	? 'Amount greater than total price'
																	: ''
															}
														/>
													</div>
													<div style={{ width: '20%', marginTop: '8PX' }}>
														{selectedEmiMode !== 'CUSTOM' ? (
															<>
																{moment(emiItem?.dueDate).format('DD/MM/YYYY')}
															</>
														) : (
															<>
																<LocalizationProvider
																	dateAdapter={AdapterDayjs}>
																	<DesktopDatePicker
																		sx={{
																			// zIndex: "99999999 !important",
																			width: '160px',
																			fontSize: '14px',
																			'& .MuiOutlinedInput-input': {
																				fontSize: '14px',
																				color: '#142C44',
																				padding: '9px',
																			},
																		}}
																		format='DD/MM/YYYY'
																		onChange={(date: any) => {
																			handleEmiDueDate(index, date);
																		}}
																		value={
																			emiItem?.dueDate
																				? dayjs.unix(emiItem.dueDate / 1000)
																				: null
																		}
																		// shouldDisableDate={(date) => {
																		//   const isBeforeStart = dayjs(
																		//     date
																		//   ).isBefore(
																		//     batchStartDateMilliSeconds,
																		//     "day"
																		//   );

																		//   const oneDayABeforeBatchEnd = dayjs(
																		//     batchEndDateMilliSeconds
																		//   ).subtract(1, "day");
																		//   const isAfterEnd = dayjs(date).isAfter(
																		//     oneDayABeforeBatchEnd
																		//   );
																		//   return isBeforeStart || isAfterEnd;
																		// }}
																		minDate={dayjs(batchStartDateMilliSeconds)}
																		maxDate={dayjs(
																			batchEndDateMilliSeconds
																		).subtract(1, 'day')}
																	/>
																</LocalizationProvider>
																{!isDueDateAfterPrevious(index, rows) &&
																	emiItem?.dueDate !== null && (
																		<FormHelperText error>
																			Please select date past the previous due
																			date
																		</FormHelperText>
																	)}
															</>
														)}
													</div>
												</div>
											))}
										</div>
									</div>
									<div className={styles.balance}>
										<div>Balance:</div>
										<div>
											{balanceAmountInEmi >= -1 && balanceAmountInEmi <= 1
												? 0
												: balanceAmountInEmi}
										</div>
									</div>
								</div>
								{rows?.[rows.length - 1]?.dueDate > batchEndDateMilliSeconds ? (
									<FormHelperText error>
										Last EMI due date exceeds batch end date
									</FormHelperText>
								) : (
									<>
										{checkCumulativeEmiAmount !== null && (
											<FormHelperText error>
												{checkCumulativeEmiAmount()}
											</FormHelperText>
										)}
									</>
								)}
							</>
						)}
					</>
				)}

				<div className={styles.editSectionBtnWrap}>
					<CancelBtn onClick={() => setIsPayEditOpen(false)} />
					<SaveBtn
						onClick={handleSubmit}
						disabled={
							leadEmiDetailsLoading ||
							regularPrice === '' ||
							parseFloat(regularPrice) <= 0 ||
							parseFloat(finalPrice) <= 0 ||
							(rows?.length > 0 && checkCumulativeEmiAmount() !== null) ||
							hasDuplicatePaymentName ||
							(paidEmis?.length > 0 && hasDuplicatePaidPaymentName) ||
							hasDueDateEmpty ||
							!isDueDatesValid(rows)
						}
						loader={leadEmiDetailsLoading}
					/>
				</div>
			</div>
		</div>
	);
}

export default EditRegularPayDetails;
