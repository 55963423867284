import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import checkicon from "../../../../assets/images/checked_icon.png";
import logo from "../../../../assets/images/logo_white.png";
import { CONTINUEBTN } from "../container/SignupString";
import styles from "./Registration.module.css";
import leftPanalimg from "../../../../assets/images/leftimg.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function Verified(props: any) {
  const navigate = useNavigate();
  const { onVerify, credentials } = props;
  const isLoggedIn = useSelector((state: any) => state.login.isLogin);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/app/home");
    }
  }, [isLoggedIn, navigate]);

  const handleOnclick = () => {
    onVerify(credentials);
  };

  return (
    <>
      <div className={styles.page}>
        <Box
          className={styles.Header}
          sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" } }}
        >
          <img
            src={logo}
            className={styles.appLogo}
            height={33}
            width={145}
            alt="Logo"
          />
        </Box>
        <Box
          className={styles.leftPanal}
          style={{ border: "1px solid #142c44" }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <img
              src={leftPanalimg}
              alt="bcksbckab"
              style={{ height: 384, width: 447 }}
            />

            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Poppins",
                fontWeight: "700",
                fontSize: "32px",
                color: "#FFFFFF",
                lineHeight: "48px",
                mt: 2,
              }}
            >
              You’re in trusted hands
            </Box>

            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                color: "#FFFFFF",
                textAlign: "center",
                mt: 1,
                marginBottom: "30px",
                px: { sm: "60px", xl: "160px" },
              }}
            >
              Nurture helps entrepreneurs scale their institutions with
              <br /> a proven process.
            </Box>
          </Box>
        </Box>

        <div className={styles.rightPanal}>
          <Box className={styles.content_wrapper}>
            <Box className={styles.content_div}>
              <Box
                sx={{
                  maxWidth: {
                    xs: "70rem",
                    lg: "100rem",
                    md: "90rem",
                    sm: "80rem",
                  },
                }}
              >
                <img src={checkicon} alt="checkicon" />
              </Box>
              <Box className={styles.heading}>
                <Typography
                  paragraph
                  sx={{
                    fontSize: {
                      lg: 30,
                      md: 30,
                      sm: 25,
                      xs: 14,
                    },
                    color: "#142C44",
                    fontWeight: "600",
                    margin: "10px 0px 10px",
                    textAlign: "center",
                  }}
                >
                  Your Account is Verified
                </Typography>
              </Box>
              <Box>
                <Typography
                  paragraph
                  sx={{
                    fontSize: {
                      lg: 16,
                      md: 16,
                      sm: 12,
                      xs: 9,
                    },
                    color: "#8A8A8A",
                    textAlign: "center",
                  }}
                >
                  Welcome to Nurture. You are in trusted hands.
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={handleOnclick}
                >
                  {CONTINUEBTN}
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
