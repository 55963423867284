import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormGroup,
  styled,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import downloadSvg from "../../Assets/import.svg";
import FilterDrawer from "../../../../../../components/FilterDrawer/FilterDrawer";
import UploadFile from "../../UploadFile/UploadFile";
import formatTimestamp from "../../../../../../components/Formatter/Formatter";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuthUser } from "react-auth-kit";
import { GetImportHistory } from "../../../service/inbound.request";
import PuffLoader from "react-spinners/PuffLoader";
import styles from "../EmailSettings/EmailSettings.module.css";
import { useDispatch } from "react-redux";
import { resetdownloadHistorySuccess } from "../../../service/deactivateInboundSlice";
import FilterChips from "../../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterChipsWrap from "../../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterTypeWrap from "../../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import ClearFilter from "../../../../../../components/ClearFilter/ClearFilter";
import {
  downloadImportHistoryData,
  getImportHistory,
  resetImportStatuses,
} from "../../../service/inboundSlice";
import { useSelector } from "react-redux";
import TableSkeleton from "../../../../../../components/Skeletons/TableSkeleton";
import importIcon from "../../Assets/importIcon.svg";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import getTeamMember from "../../../../../../components/CommonFunctions/CommonFunctions";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0px 12px",
  fontSize: "12px",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
  "@media only screen and (min-width: 992px)": {
    fontSize: "12px",
  },
  "@media only screen and (min-width: 1200px)": {
    fontSize: "14px",
  },
}));

const CustomTableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: "16px 12px",
  fontSize: "14px",
  color: "#69696B",
  fontWeight: "500",
}));

const ImportSettings = (props: any) => {
  const {
    // importHistoryData,
    DownloadHistoryData,
    // downloadHistoryData,
    downloadHistoryDataError,
    downloadHistoryDataLoading,
    // downloadHistoryDataSuccess,
  } = props;
  const { downloadHistoryData, downloadHistoryDataSuccess } = useSelector(
    (state: any) => state.inbound
  );
  const [openImportModal, setImportModal] = useState(false);
  const [filteredArray, setFilteredArray] = useState<any>([]);
  const [filteredArrayPaginated, setFilteredArrayPaginated] = useState<any>([]);

  const [columns, setColumns] = useState([
    { id: "hash", label: "#", minWidth: 30, align: "inherit", show: true },
    {
      id: "date",
      label: "Date & Time",
      minWidth: 170,
      align: "inherit",
      show: true,
    },
    {
      id: "importBy",
      label: "Imported by",
      minWidth: 170,
      align: "inherit",
      show: true,
    },
    {
      id: "importedLead",
      label: "Imported Leads",
      minWidth: 170,
      align: "inherit",
      show: true,
    },
    {
      id: "failedContacts",
      label: "Failed Contacts",
      minWidth: 170,
      align: "left",
      show: true,
    },
    {
      id: "excelFile",
      label: "Excel File",
      minWidth: 170,
      align: "left",
      show: true,
    },
  ]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { importHistoryData, importHistoryLoading } = useSelector(
    (state: any) => state.inbound
  );
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasImportAccess = getSubDomainAccessStatus(
    "INBOUND",
    "IMPORT",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const employees = useSelector((state: any) => state.staff.staffList);

  useEffect(() => {
    if (importHistoryData) {
      setFilteredArray(importHistoryData);
      setFilteredArrayPaginated(importHistoryData);
    }
  }, [importHistoryData]);

  const navigate = useNavigate();

  const onPageChange = (val: any) => {
    navigate("filter", { state: val });
  };

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      filteredArray?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      filteredArray?.lastEvaluatedKeyReceived?.GSI2SK?.S
    );
    const id = userFromLocalStorage.instituteId;
    if (lastEvaluatedKey) {
      GetImportHistory(id, lastEvaluatedKey, 20, lastEvaluatedSortKey)
        .then((res) => {
          setHasMoreData(true);
          setFilteredArray((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
          setFilteredArrayPaginated((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  useEffect(() => {
    if (
      filteredArray &&
      filteredArray?.lastEvaluatedKeyReceived &&
      filteredArray?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [filteredArray]);

  const handleDownloadFile = (val: string) => {
    const userFromLocalStorage = authUser?.institute[0];
    if (val && userFromLocalStorage) {
      const data = {
        id: val,
        instituteId: userFromLocalStorage?.instituteId,
      };
      // DownloadHistoryData(data);
      dispatch<any>(downloadImportHistoryData(data));
    }
  };

  const base64ToBlob = (base64Data: string, contentType: any) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  React.useEffect(() => {
    if (downloadHistoryDataSuccess) {
      // dispatch(resetdownloadHistorySuccess());
      dispatch(resetImportStatuses());

      const blob = base64ToBlob(
        downloadHistoryData?.fileBase64String,
        "application/vnd.ms-excel"
      );
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Template.xlsx";
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    }
  }, [downloadHistoryDataSuccess]);

  // const unique = filteredArray?.returnData?.filter(
  //   (obj: any, index: any, self: any) =>
  //     index ===
  //     self?.findIndex((el: any) => el?.uploadedByName === obj?.uploadedByName)
  // );
  const uniqueData =
    filteredArrayPaginated?.length === 0
      ? importHistoryData?.returnData
      : filteredArrayPaginated?.returnData;

  const unique = filteredArrayPaginated?.returnData?.filter(
    (obj: any, index: any, self: any) =>
      index ===
      self?.findIndex((el: any) => el?.uploadedById === obj?.uploadedById)
  );

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  interface CheckedContactsImportedBy {
    id: string;
    name: string;
  }
  const [checkedContactsImportedBy, setCheckedContactsImportedBy] = useState<
    CheckedContactsImportedBy[]
  >([]);
  const [filterCheckedContactsImportedBy, setFilterCheckedContactsImportedBy] =
    useState<CheckedContactsImportedBy[]>([]);

  const handleFilterImportedByChecked = (id: string, name: string) => {
    const importedByAdd: CheckedContactsImportedBy = { id, name };
    if (checkedContactsImportedBy.some((item) => item.id === id)) {
      setCheckedContactsImportedBy(
        checkedContactsImportedBy.filter((item) => item.id !== id)
      );
    } else {
      setCheckedContactsImportedBy([
        ...checkedContactsImportedBy,
        importedByAdd,
      ]);
    }
  };

  const clearCheckedImportedBy = (id: string) => {
    setCheckedContactsImportedBy(
      checkedContactsImportedBy.filter((item) => item.id !== id)
    );
  };

  const handleFilterContacts = React.useCallback(
    (importedByArray: any) => {
      setFilterCheckedContactsImportedBy(importedByArray);
      let filterOption: any = filteredArrayPaginated?.returnData;

      //  if (importedFilter) {
      //    filterOption = filterOption.filter((obj: any) => {
      //      return obj?.uploadedByName
      //        .toLocaleLowerCase()
      //        .includes(importedFilter?.toLocaleLowerCase());
      //    });
      //  }
      // setFilteredArray({ ...filteredArray, returnData: filterOption });

      if (importedByArray.length > 0 && filterOption) {
        filterOption = filterOption?.filter((obj: any) => {
          return importedByArray.some(
            (item: any) => item.id === obj.uploadedById
          );
        });
        setFilteredArray({ returnData: filterOption });
      } else {
        setFilteredArray({ returnData: importHistoryData?.returnData });
      }

      // Return filterOption directly if importedByArray has no items
      // if (importedByArray.length === 0) {
      //   setFilteredArray({ returnData: importHistoryData?.returnData });
      // } else {
      //   // Otherwise, set the filtered array
      //   setFilteredArray({ returnData: filterOption });
      // }
    },
    [setFilterCheckedContactsImportedBy, filteredArrayPaginated]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterContacts(checkedContactsImportedBy);

    setFilterApplied(checkedContactsImportedBy?.length === 0 ? false : true);
  }, [handleFilterContacts, setFilterApplied, checkedContactsImportedBy]);

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setCheckedContactsImportedBy([]);
    handleFilterContacts([]);
    setFilterCheckedContactsImportedBy([]);
  }, [
    setFilterApplied,
    setCheckedContactsImportedBy,
    handleFilterContacts,
    setFilterCheckedContactsImportedBy,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedContactsImportedBy(filterCheckedContactsImportedBy);
  }, [filterCheckedContactsImportedBy]);

  const filteredValue = (
    <>
      {filterCheckedContactsImportedBy?.length > 0 &&
        filterCheckedContactsImportedBy?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.name}
          </div>
        ))}
    </>
  );

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const uniqueUploadedByIds = Array.from(
    new Set(
      filteredArrayPaginated?.returnData?.map((item: any) => item.uploadedById)
    )
  );

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const id = userFromLocalStorage?.instituteId;
    const lastEvaluatedKey = "";
    const limit = 20;
    const lastEvaluatedSortKey = "";
    if (userFromLocalStorage) {
      dispatch<any>(
        getImportHistory({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
        })
      );
    }
  }, [GetImportHistory]);

  return (
    <>
      {importHistoryLoading ? (
        <Box
          sx={{
            width: "97%",
            backgroundColor: "#FFF",
            margin: 2,
            padding: 2,
            borderRadius: "8px",
          }}
        >
          <TableSkeleton />
        </Box>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            {importHistoryData &&
            filteredArray &&
            filteredArray?.returnData?.length > 0 ? (
              <Box
                sx={{
                  width: "97%",
                  backgroundColor: "#FFF",
                  margin: 2,
                  padding: 2,
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#142C44",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>History</Box>
                  <Box display="flex" alignItems="center">
                    <ClearFilter
                      open={isOpenFilter}
                      noButtonClick={noButtonClick}
                      yesButtonClick={yesButtonClick}
                      onClose={closeClearFilter}
                      filteredValue={filteredValue}
                    />
                    <FilterDrawer
                      handleFilterApply={handleFilterApply}
                      handleResetClick={handleResetClick}
                      activeFilter={filterApplied}
                      cancelClick={cancelClick}
                      closeSetFilter={handleFilterClose}
                    >
                      <div
                        style={{
                          padding: "22px 32px",
                          overflowY: "auto",
                          maxHeight: "calc(100% - 110px)",
                        }}
                      >
                        <FilterChipsWrap>
                          {checkedContactsImportedBy.length > 0 &&
                            checkedContactsImportedBy.map((item) => (
                              <FilterChips
                                keyVal={item.id}
                                chipName={item.name.toLowerCase()}
                                clearValue={item.id}
                                closeFunction={clearCheckedImportedBy}
                              />
                            ))}
                        </FilterChipsWrap>
                        <FilterTypeWrap>
                          <FilterLabel labelValue="Imported By" />
                        </FilterTypeWrap>
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {filteredArray &&
                              unique?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.uploadedById}
                                  checked={checkedContactsImportedBy.some(
                                    (data) => data.id === item.uploadedById
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterImportedByChecked(
                                      item.uploadedById,
                                      item.uploadedByName
                                    )
                                  }
                                  label={item.uploadedByName.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </div>
                    </FilterDrawer>
                    <Box marginLeft={2}>
                      <Tooltip
                        arrow
                        title={
                          hasImportAccess === false
                            ? "Oops! No permission."
                            : ""
                        }
                        sx={{ width: "100%" }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            sx={{
                              maxWidth: "200px",
                              boxShadow: "none",
                              textTransform: "capitalize",
                            }}
                            onClick={() => setImportModal(true)}
                            disabled={hasImportAccess === false}
                          >
                            Import Contacts
                          </Button>
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Box marginTop={2}>
                  <TableContainer
                    style={{
                      maxHeight: "100%",
                      border: `1px solid #EAECF0`,
                    }}
                  >
                    <InfiniteScroll
                      dataLength={
                        filteredArray && filteredArray?.returnData
                          ? filteredArray?.returnData?.length
                          : importHistoryData?.returnData?.length
                      }
                      next={handleNextPage}
                      hasMore={hasMoreData}
                      loader={
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <PuffLoader color="#36d7b7" />
                        </Box>
                      }
                      height={700}
                    >
                      <Table>
                        <TableHead
                          sx={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <TableRow sx={{ height: "49px" }}>
                            {columns?.map((column: any) => {
                              return (
                                <>
                                  {column.show && (
                                    <CustomTableHeaderCell
                                      key={column.id}
                                      align={column?.align}
                                      style={{ minWidth: column.minWidth }}
                                      sx={{
                                        background: "#F9FAFB",
                                        color: "#667085",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {column.label}
                                    </CustomTableHeaderCell>
                                  )}
                                </>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {filteredArray &&
                            filteredArray !== null &&
                            filteredArray?.returnData?.map(
                              (history: any, index: any) => {
                                return (
                                  <TableRow
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                    sx={{
                                      padding: "0px",
                                      height: "72px",
                                      cursor: "pointer",
                                    }}
                                    className="student_table_body"
                                  >
                                    <CustomTableCell>
                                      {index + 1}
                                    </CustomTableCell>
                                    {columns[0].show && (
                                      <CustomTableCell
                                        sx={{}}
                                        onClick={() => {
                                          onPageChange(history?.id);
                                        }}
                                      >
                                        <div>
                                          <p
                                            style={{
                                              color: "#101828",
                                              margin: "0px",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {history?.uploadTime
                                              ? formatTimestamp(
                                                  history?.uploadTime
                                                )
                                              : "-"}
                                          </p>
                                        </div>
                                      </CustomTableCell>
                                    )}

                                    {columns[1].show && (
                                      <CustomTableCell
                                        sx={{}}
                                        onClick={() => {
                                          onPageChange(history?.id);
                                        }}
                                      >
                                        <Box>
                                          <>
                                            <p
                                              style={{
                                                borderRadius: "16px",
                                                fontSize: "12px",
                                                lineHeight: "18px",
                                                fontWeight: "500",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {/* {history?.uploadedByName
                                                ? history?.uploadedByName
                                                : "-"} */}

                                              {getTeamMember(
                                                history?.uploadedById,
                                                employees
                                              )?.firstName === undefined
                                                ? "-"
                                                : getTeamMember(
                                                    history?.uploadedById,
                                                    employees
                                                  )?.firstName +
                                                  " " +
                                                  getTeamMember(
                                                    history?.uploadedById,
                                                    employees
                                                  )?.lastName}
                                            </p>
                                          </>
                                        </Box>
                                      </CustomTableCell>
                                    )}

                                    {columns[2].show && (
                                      <CustomTableCell
                                        sx={{}}
                                        onClick={() => {
                                          onPageChange(history?.id);
                                        }}
                                      >
                                        <>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <p
                                              style={{
                                                color: "#667085",
                                                margin: "0px",
                                                textTransform: "capitalize",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {history?.data
                                                ? history?.data
                                                    ?.rowWithoutErrorCount
                                                : "-"}
                                            </p>
                                          </Box>
                                        </>
                                      </CustomTableCell>
                                    )}

                                    {columns[3].show && (
                                      <CustomTableCell
                                        sx={{}}
                                        onClick={() => {
                                          onPageChange(history?.id);
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <p
                                            style={{
                                              color: "#667085",
                                              margin: "0px",
                                              textTransform: "capitalize",
                                              fontSize: "14px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {history?.data
                                              ? history?.data?.rowWithErrorCount
                                              : "-"}
                                          </p>
                                        </Box>
                                      </CustomTableCell>
                                    )}
                                    {columns[4].show && (
                                      <CustomTableCell
                                        sx={{}}
                                        onClick={() => {
                                          handleDownloadFile(history?.id);
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {history?.fileName ? (
                                            <p
                                              style={{
                                                color: "blue",
                                                margin: "0px",
                                                textTransform: "capitalize",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {history?.fileName}
                                            </p>
                                          ) : (
                                            <p
                                              style={{
                                                color: "#667085",
                                                margin: "0px",
                                                textTransform: "capitalize",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {"-"}
                                            </p>
                                          )}
                                        </Box>
                                      </CustomTableCell>
                                    )}
                                  </TableRow>
                                );
                              }
                            )}
                        </TableBody>
                      </Table>
                    </InfiniteScroll>
                  </TableContainer>
                </Box>
              </Box>
            ) : (
              <>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    arrow
                    title={
                      hasImportAccess === false ? "Oops! No permission." : ""
                    }
                    sx={{ width: "100%" }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          if (hasImportAccess) {
                            setImportModal(true);
                          }
                        }}
                        style={{
                          cursor: "pointer",
                          width: "387px",
                          borderRadius: "6PX",
                          border: " 1px solid #E3E3E3",
                          boxShadow:
                            "0px 20px 60px 0px rgba(241, 244, 248, 0.50)",
                          padding: "24px 30px",
                          textAlign: "center",
                        }}
                      >
                        <img src={importIcon} alt="Import" />
                        <div
                          style={{
                            fontSize: "15PX",
                            fontWeight: "600",
                            color: "#3F3E43",
                            marginBottom: "4px",
                            marginTop: "16px",
                          }}
                        >
                          Import
                        </div>
                        <div
                          style={{
                            fontSize: "14PX",
                            color: "#667085",
                          }}
                        >
                          Simplify your lead and contact management with our
                          seamless Import feature, effortlessly bringing in bulk
                          contacts into nurture.
                        </div>
                      </div>
                    </span>
                  </Tooltip>
                </div>
                {/* <div
                  className={styles.emailSettingsTitle}
                  style={{
                    marginBottom: "20px",
                    marginTop: "20px",
                    marginLeft: "30px",
                  }}
                >
                  Contacts
                </div>
                <Box
                  style={{
                    border: "1px solid #E3E3E3",
                    width: "50%",
                    padding: "30px",
                    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                    borderRadius: "6px",
                    margin: "30px",
                  }}
                >
                  <div className={styles.noEmailsBox}>
                    Currently you don't have any contacts imported.
                    <br />
                    Please click on below button to Import Contacts
                  </div>
                  <div
                    className={styles.editFormPopupBtnsSplit}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      sx={{
                        width: "50%",
                        boxShadow: "none",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setImportModal(true);
                      }}
                    >
                      Import
                    </Button>
                  </div>
                </Box> */}
              </>
            )}
          </div>
          {openImportModal && (
            <UploadFile
              setImportModal={setImportModal}
              openImportModal={openImportModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default ImportSettings;
