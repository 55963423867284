import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../Inbound/components/ImportModal/ImportModal.module.css";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, FormControl } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 4,
};
const CalenderTimingsModel = ({
  open,
  setOpenTimingsModel,
  yesButtonClick,
  noButtonClick,
  title = "Select the Timings for the day",
  date,
  batchDetails,
  setBatchDetails,
  add,
  setIsDateChanged,
}: any) => {
  const dateofDayName = new Date(date);
  const options: any = { weekday: "long" };

  const dayName = dateofDayName.toLocaleString("en-US", options);

  const [timings, setTimings] = useState<any>([
    ...batchDetails?.batchSchedule?.find(
      (schedule: any) => schedule?.day === dayName?.slice(0, 3)?.toUpperCase()
    ).batchSessions,
  ]);
  const [showRepeatedSessionError, setShowRepeatedSessionError] =
    useState(false);
  const [isStartAndEndTimeSame, setIsStartAndEndTimeSame] = useState(false);

  useEffect(() => {
    const updatedTimings = timings?.map((session: any) => ({
      ...session,
      startTime: session.startTime === 0 ? moment().unix() : session.startTime,
      endTime: session.endTime === 0 ? moment().unix() : session.endTime,
    }));
    setTimings(updatedTimings);
  }, []);

  const handleAddTiming = () => {
    setTimings([...timings, { sessionName: "", startTime: 0, endTime: 0 }]);
  };

  const handleRemoveTiming = (index: number) => {
    const newTimings = [...timings];
    if (newTimings && newTimings?.length === 1) {
      newTimings.splice(index, 1);
      setTimings([{ sessionName: "", startTime: 0, endTime: 0 }]);
    } else {
      newTimings.splice(index, 1);
      setTimings(newTimings);
    }
    setShowRepeatedSessionError(false);
  };

  const hasDuplicateSessions = (sessions: any) => {
    const sessionMap = new Map();

    for (const session of sessions) {
      const normalizedStartTime = getHourMinute(session.startTime);
      const normalizedEndTime = getHourMinute(session.endTime);
      const key = `${normalizedStartTime}-${normalizedEndTime}`;
      if (sessionMap.has(key)) {
        return true;
      }
      sessionMap.set(key, true);
    }

    return false;
  };

  const getHourMinute = (timestamp: any) => {
    const date = new Date(timestamp * 1000);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return `${hours}:${minutes}`;
  };

  const updateBatchDetails = () => {
    const hasSameStartEndTime = timings.some(
      (session: any) => session.startTime === session.endTime
    );

    if (hasDuplicateSessions(timings)) {
      setShowRepeatedSessionError(true);
      return;
    }

    if (hasSameStartEndTime) {
      setIsStartAndEndTimeSame(true);
      return;
    }

    const updatedBatchDetails = { ...batchDetails };
    const updatedBatchSchedule = updatedBatchDetails.batchSchedule.map(
      (schedule: any) => {
        if (schedule.day === dayName?.slice(0, 3)?.toUpperCase()) {
          schedule.batchSessions = [...timings];
        }
        return schedule;
      }
    );
    updatedBatchDetails.batchSchedule = updatedBatchSchedule;
    setBatchDetails(updatedBatchDetails);
    setIsDateChanged(true);
    yesButtonClick();
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpenTimingsModel(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div
            onClick={() => {
              setOpenTimingsModel(false);
            }}
            className={styles.closeModalButton}
          >
            <CloseIcon />
          </div>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalDes}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: 600 }}>{dayName}</div>
              <div
                className={styles.timingSlot}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  height: "100%",
                }}
              >
                <div>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      marginTop: "5px !important",
                    }}
                  >
                    {timings.map((timing: any, index: any) => (
                      <>
                        <div style={{ marginBottom: "5px" }}>
                          <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                value={
                                  timing.startTime !== 0
                                    ? dayjs.unix(timing.startTime)
                                    : dayjs()
                                }
                                onChange={(event) => {
                                  const newTimings = [...timings];
                                  newTimings[index].startTime = event?.unix();
                                  setTimings(newTimings);
                                }}
                                sx={{
                                  width: "140px",
                                  "& .MuiInputBase-input": {
                                    height: "15px",
                                  },
                                }}
                                closeOnSelect={false}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </div>
                        <p
                          style={{
                            color: "#C7C7C7",
                            fontFamily: "Poppins",
                            marginLeft: "3.8px",
                            marginRight: "3.8px",
                          }}
                        >
                          to
                        </p>
                        <div style={{ marginBottom: "5px" }}>
                          <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                value={
                                  timing.endTime !== 0
                                    ? dayjs.unix(timing.endTime)
                                    : dayjs()
                                }
                                onChange={(event) => {
                                  const newTimings = [...timings];
                                  newTimings[index].endTime = event?.unix();
                                  setTimings(newTimings);
                                }}
                                sx={{
                                  width: "140px",
                                  "& .MuiInputBase-input": {
                                    height: "15px",
                                  },
                                }}
                                closeOnSelect={false}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <DeleteIcon
                            style={{
                              fontSize: "25px",
                              color: "#667085",
                              cursor: "pointer",
                              marginTop: "10px",
                            }}
                            onClick={() => handleRemoveTiming(index)}
                          />
                        </div>
                      </>
                    ))}
                  </Grid>
                </div>
                <div>
                  <AddIcon
                    style={{
                      fontSize: "25px",
                      color: "#667085",
                      cursor: "pointer",
                      marginTop: "12px",
                    }}
                    onClick={handleAddTiming}
                  />
                </div>
              </div>
              {showRepeatedSessionError && (
                <div style={{ color: "red" }}>
                  {"Session already exists for the same date and time."}
                </div>
              )}
              {isStartAndEndTimeSame && (
                <div style={{ color: "red" }}>
                  {"Session Timings are same.Please change it."}
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonSection}>
            <button onClick={noButtonClick} className={styles.noButton}>
              Cancel
            </button>
            <button
              onClick={() => {
                updateBatchDetails();
              }}
              style={{
                background: "#11c89b",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "4px",
                padding: "10px 18px",
                gap: "8px",
                width: "100%",
                color: " #ffffff",
                border: "0",
                cursor: "pointer",
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default CalenderTimingsModel;
