import React from "react";
import styles from "./LeadOverviewMessages.module.css";
import successIcon from "../Assets/successs-overview.svg";

function LeadConvertionSuccess(props: any) {
  const { leadData } = props;

  return (
    <div className={styles.leadConvertionSuccessWrap}>
      <div className={styles.leadConvertionSuccessIcon}>
        <img src={successIcon} alt="successIcon" />
      </div>
      <div className={styles.leadConvertionSuccessValue}>
        {leadData?.studentFirstName + " " + leadData?.studentLastName + " "}
        is successfully nurtured & converted to Student.
      </div>
    </div>
  );
}

export default LeadConvertionSuccess;
