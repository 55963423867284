import React from "react";
import { Container } from "@mui/system";
import {
  Button,
  Snackbar,
  SnackbarOrigin,
  IconButton,
  Alert,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import checkCircle from "../../../../assets/images/check-circle.svg";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

export function TermsOfServices(props: any) {
  const {
    tnc,
    isVersionMatching,
    termsAndConditionsLoading,
    termsAndConditionsSuccess,
    termsAndConditionsError,
    AcceptTnC,
    setOpen,
  } = props;
  const auth = useAuthUser();
  const user = auth();
  const termsAndConditions =
    tnc && tnc?.filter((d: any) => d?.tncType === "TERMS_AND_CONDITIONS");

  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "info",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="error"
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleAcceptTnc = () => {
    const data = {
      tncType: "TERMS_AND_CONDITIONS",
      version: termsAndConditions[0]?.version,
      userId: user?.institute[0]?.id,
    };
    AcceptTnC(data);
  };

  React.useEffect(() => {
    if (termsAndConditionsSuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "Terms and Conditions Accepted",
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [termsAndConditionsSuccess]);

  React.useEffect(() => {
    if (termsAndConditionsError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(termsAndConditionsError),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
  }, [termsAndConditionsError]);

  return (
    <div style={{ height: "90vh", overflow: "auto" }}>
      {/* <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackOpen}
        onClose={closeSnack}
        key={vertical + horizontal}
        autoHideDuration={3000}
        sx={{
          zIndex: "99999999",
          borderRadius: "8PX",
          background:
            snackState?.snackSeverity === "success" ? "#DCFCE7" : "inherit",
          color:
            snackState?.snackSeverity === "success" ? "#14532D" : "inherit",
          fontSize: "14px",
        }}
      >
        <Alert
          severity={snackSeverity}
          action={action}
          icon={
            snackState?.snackSeverity === "success" ? (
              <img src={checkCircle} alt="check circle" />
            ) : null
          }
        >
          {message}
        </Alert>
      </Snackbar> */}
      <Container fixed sx={{ marginBottom: "50px" }}>
        <h1>Terms & Conditions</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: tnc?.[0]?.termsNConditionsString,
          }}
        />
      </Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 225px",
        }}
      >
        <>
          {false && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="By checking this box, I agree to the Terms and Conditions"
              />
            </FormGroup>
          )}
          {isVersionMatching?.tnc === false && (
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={handleAcceptTnc}
              variant="contained"
              color="primary"
              disabled={checked === false}
            >
              Next
            </Button>
          )}
        </>
      </div>
    </div>
  );
}
