import {
  Box,
  Button,
  Stack,
  Skeleton,
  FormGroup,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import ViewCourseDetailsPopup from "./ViewCourseDetailsPopup/ViewCourseDetailsPopup";
import AddIcon from "@mui/icons-material/Add";
import FilterDrawer from "../../../../components/FilterDrawer/FilterDrawer";
import ClearFilter from "../../../../components/ClearFilter/ClearFilter";
import FilterTypeWrap from "../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterSearchField from "../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import FilterCheckBoxWrap from "../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import ShowAllBtn from "../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterChipsWrap from "../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../components/FilterDrawer/FilterChips/FilterChips";
import { useMediaQuery, useTheme } from "@mui/material";
import kanbanViewImg from "../components/Assets/kanbanViewImg.svg";
import listViewImg from "../components/Assets/listViewImg.svg";
import activeKanbanViewImg from "../components/Assets/activeKanbanViewImg.svg";
import activeListViewImg from "../components/Assets/activeListViewImg.svg";
import { useAuthUser } from "react-auth-kit";
import { getSubDomainAccessStatus } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
import leadActiveDefault from "../../../../assets/images/activeLeadDefault.svg";
import leadActiveIcon from "../../../../assets/images/activeLead.svg";
import leadLostDefault from "../../../../assets/images/lostLeadDefault.svg";
import leadLostIcon from "../../../../assets/images/lostLead.svg";
import OpenSearch from "./OpenSearch/OpenSearch";
import { resetLeadsOnNurture } from "../service/nurtureSlice";

export default function NurtureKanbanHeader(props: any) {
  const {
    employees,
    handleFilterLeads,
    setShowEnquiryModal,
    showEnquiryModal,
    GetCourseById,
    sourceList,
    handleLeadSearch,
    leadSearchTerm,
    selectedCourse,
    allLeadsEmpty,
    branchList,
    batchList,
    sortedSwimLane,
    filteredValue,
    GetInstituteById,
    isKanbanView,
    setIsKanbanView,
    setLeadItems,
    setNurtureLeadStatus,
    defaultLeadsCall,
    setLeadListItems,
    setleadPaginationLoad,
    setFilterCheckedBatches,
    filterCheckedAssignees,
    filterCheckedPriorities,
    filterCheckedBranches,
    filterCheckedBatches,
    filterCheckedSources,
    filterCheckedStages,
    filterApplied,
    setFilterApplied,
    filterLeadsCall,
    nurtureLeadStatus,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const { leadBoard, onLeadsLoading } = useSelector(
    (state: any) => state.nurture
  );
  const swimlaneData = leadBoard?.swimlaneData;
  const leadStagesData =
    swimlaneData &&
    [...swimlaneData]?.sort((a: any, b: any) => {
      return a?.orderIndex - b?.orderIndex;
    });
  const [leadStatusCheck, setLeadStatusCheck] = React.useState<boolean>(false);

  const [assigneeSearchTerm, setAssigneeSearchTerm] =
    React.useState<string>("");
  const [batchSearchTerm, setBatchSearchTerm] = React.useState<string>("");
  const [branchSearchTerm, setBranchSearchTerm] = React.useState<string>("");
  const [showAllEmployees, setShowAllEmployees] =
    React.useState<boolean>(false);
  const [sourceSearchTerm, setSourceSearchTerm] = React.useState<string>("");
  const [showAllSources, setShowAllSources] = React.useState<boolean>(false);
  const [showAllBranch, setShowAllBranch] = React.useState<boolean>(false);
  const [showAllBatch, setShowAllBatch] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  const handleAssigneeSearch = (event: any) => {
    setAssigneeSearchTerm(event.target.value);
  };
  const loginUserBranch = authUser?.institute?.[0]?.branchId;

  const filteredEmployees = employees
    ?.filter((item: any) =>
      !hasAllBranchAccess ? item.branchId === loginUserBranch : true
    )
    ?.filter((item: any) => item.status === "ACTIVE")
    ?.filter((employee: any) => {
      const searchTermMatch =
        employee.firstName
          .toLowerCase()
          .indexOf(assigneeSearchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const displayedEmployees = showAllEmployees
    ? employees && filteredEmployees
    : employees && filteredEmployees.slice(0, 5);

  const handleShowMoreEmployees = () => {
    setShowAllEmployees(!showAllEmployees);
  };

  const filteredSources = sourceList?.filter((item: any) => {
    const searchTermMatch =
      item.value.toLowerCase().indexOf(sourceSearchTerm.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const displayedSources = showAllSources
    ? sourceList && filteredSources
    : sourceList && filteredSources.slice(0, 5);

  const handleShowMoreSources = () => {
    setShowAllSources(!showAllSources);
  };

  const filteredBranch = branchList?.filter((employee: any) => {
    const searchTermMatch =
      employee.branchName
        .toLowerCase()
        .indexOf(branchSearchTerm.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const displayedBranch = showAllBranch
    ? branchList && filteredBranch
    : branchList && filteredBranch.slice(0, 5);

  const handleShowMoreBranch = () => {
    setShowAllBranch(!showAllBranch);
  };

  const filteredBatch = batchList
    ?.filter(
      (item: any) =>
        (!selectedCourse || item.courseId === selectedCourse.courseId) &&
        (!hasAllBranchAccess ? item.branchId === loginUserBranch : true)
    )
    ?.filter(
      (item: any) =>
        item?.batchConductType === "REGULAR_BATCH" &&
        item?.batchStatus !== "COMPLETED" &&
        item?.batchStatus !== "CANCELLED" &&
        item?.status !== "INACTIVE"
    )
    ?.filter((batch: any) => {
      const searchTermMatch =
        batch.batchName.toLowerCase().indexOf(batchSearchTerm.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedBatch = showAllBatch
    ? batchList && filteredBatch
    : batchList && filteredBatch?.slice(0, 5);

  const handleShowMoreBatch = () => {
    setShowAllBatch(!showAllBatch);
  };

  interface CheckedBranch {
    id: string;
    branchName: string;
  }

  interface CheckedBatch {
    id: string;
    batchName: string;
  }

  interface CheckedSources {
    source: string;
  }

  interface CheckedAssignees {
    id: string;
    firstName: string;
    lastName: string;
  }
  interface CheckedPriorities {
    priority: string;
  }

  interface CheckedLeadStages {
    leadStage: string;
  }
  const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);

  const [checkedBatches, setCheckedBatches] = useState<CheckedBatch[]>([]);

  const [checkedPriorities, setCheckedPriorities] = useState<
    CheckedPriorities[]
  >([]);

  const [checkedSources, setCheckedSources] = useState<CheckedSources[]>([]);

  const [checkedAssignees, setCheckedAssignees] = useState<CheckedAssignees[]>(
    []
  );
  const [checkedStages, setCheckedStages] = useState<CheckedLeadStages[]>([]);

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    const branchToAdd: CheckedBranch = { id, branchName };
    if (checkedBranches.some((branch) => branch.id === id)) {
      setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBranches([...checkedBranches, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
  };

  const handleFilterBatchChecked = (id: string, batchName: string) => {
    const batchToAdd: CheckedBatch = { id, batchName };
    if (checkedBatches.some((branch) => branch.id === id)) {
      setCheckedBatches(checkedBatches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBatches([...checkedBatches, batchToAdd]);
    }
  };

  const clearCheckedBatch = (id: string) => {
    setCheckedBatches(checkedBatches.filter((item) => item.id !== id));
  };

  const handleFilterAssigneeChecked = (
    id: string,
    firstName: string,
    lastName: string
  ) => {
    const assigneeToAdd: CheckedAssignees = {
      id,
      firstName,
      lastName,
    };
    if (checkedAssignees.some((branch) => branch.id === id)) {
      setCheckedAssignees(
        checkedAssignees.filter((branch) => branch.id !== id)
      );
    } else {
      setCheckedAssignees([...checkedAssignees, assigneeToAdd]);
    }
  };

  const clearCheckedAssignee = (id: string) => {
    setCheckedAssignees(checkedAssignees.filter((item) => item.id !== id));
  };

  const handleFilterSourceChecked = (source: string) => {
    const sourceToAdd: CheckedSources = {
      source,
    };

    if (
      checkedSources.some(
        (item) => item.source.toLowerCase() === source.toLowerCase()
      )
    ) {
      setCheckedSources(
        checkedSources.filter(
          (item) => item.source.toLowerCase() !== source.toLowerCase()
        )
      );
    } else {
      setCheckedSources([...checkedSources, sourceToAdd]);
    }
  };

  const clearCheckedSource = (source: string) => {
    setCheckedSources(checkedSources.filter((item) => item.source !== source));
  };

  const handleFilterPriorityChecked = (priority: string) => {
    const priorityToAdd: CheckedPriorities = { priority };

    if (
      checkedPriorities.some(
        (item) => item.priority.toLowerCase() === priority.toLowerCase()
      )
    ) {
      setCheckedPriorities(
        checkedPriorities.filter(
          (item) => item.priority.toLowerCase() !== priority.toLowerCase()
        )
      );
    } else {
      setCheckedPriorities([...checkedPriorities, priorityToAdd]);
    }
  };

  const clearCheckedPriority = (priority: string) => {
    setCheckedPriorities(
      checkedPriorities.filter(
        (item) => item.priority.toLowerCase() !== priority.toLowerCase()
      )
    );
  };

  const handleFilterLeadStagesChecked = (leadStage: string) => {
    const leadStagesToAdd: CheckedLeadStages = { leadStage };
    setCheckedStages([leadStagesToAdd]);
    if (checkedStages.some((item) => item.leadStage === leadStage)) {
      setCheckedStages(
        checkedStages.filter((item) => item.leadStage !== leadStage)
      );
    } else {
      setCheckedStages([...checkedStages, leadStagesToAdd]);
    }
  };

  const clearCheckedLeadStages = (leadStage: string) => {
    setCheckedStages(
      checkedStages.filter((item) => item.leadStage !== leadStage)
    );
  };

  const handleFilterApply = React.useCallback(() => {
    handleFilterLeads(
      checkedBranches,
      checkedBatches,
      checkedPriorities,
      checkedAssignees,
      checkedSources,
      checkedStages
    );

    setFilterApplied(
      checkedBranches?.length === 0 &&
        checkedBatches?.length === 0 &&
        checkedPriorities?.length === 0 &&
        checkedAssignees?.length === 0 &&
        checkedSources?.length === 0 &&
        checkedStages?.length === 0
        ? false
        : true
    );
  }, [
    handleFilterLeads,
    setFilterApplied,
    checkedBranches,
    checkedBatches,
    checkedPriorities,
    checkedAssignees,
    checkedSources,
    checkedStages,
  ]);

  const handleResetClick = React.useCallback(() => {
    handleFilterLeads([], [], [], [], [], []);
    setCheckedBranches([]);
    setCheckedBatches([]);
    setCheckedPriorities([]);
    setCheckedAssignees([]);
    setCheckedSources([]);
    setCheckedStages([]);
    setFilterApplied(false);
  }, [
    handleFilterLeads,
    setCheckedBranches,
    setCheckedBatches,
    setCheckedPriorities,
    setCheckedSources,
    setFilterApplied,
    setCheckedAssignees,
    setCheckedStages,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedBranches(filterCheckedBranches);
    setCheckedBatches(filterCheckedBatches);
    setCheckedPriorities(filterCheckedPriorities);
    setCheckedAssignees(filterCheckedAssignees);
    setCheckedSources(filterCheckedSources);
    setCheckedStages(filterCheckedStages);
  }, [
    filterCheckedSources,
    filterCheckedAssignees,
    filterCheckedStages,
    filterCheckedBatches,
    filterCheckedBranches,
    filterCheckedPriorities,
  ]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleFilterLeads([], [], [], [], [], []);
    setCheckedBranches([]);
    setCheckedBatches([]);
    setCheckedPriorities([]);
    setCheckedAssignees([]);
    setCheckedSources([]);
    setCheckedStages([]);
    setFilterApplied(false);
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const handleKanbanViewClick = () => {
    dispatch(resetLeadsOnNurture());
    setLeadItems("");
    setLeadListItems("");
    setleadPaginationLoad(false);
    setIsKanbanView(true);
    setNurtureLeadStatus("ACTIVE");
    const queryParams = new URLSearchParams(window.location.search);
    const nurtureViewFromUrl = queryParams.get("nurtureView");
    queryParams.delete("nurtureView");
    queryParams.delete("nurtureLeadStatus");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    defaultLeadsCall(false, "ACTIVE", selectedCourse?.courseId);
    if (filterApplied && nurtureViewFromUrl) {
      handleResetClick();
      dispatch(
        setToastNotification({
          message: String("Switched to kanaban view, filters removed!"),
          type: "success",
          snackOpen: true,
        })
      );
    }
  };

  const handleListViewClick = () => {
    setLeadItems("");
    setLeadListItems("");
    setleadPaginationLoad(false);
    dispatch(resetLeadsOnNurture());
    setIsKanbanView(false);
    setNurtureLeadStatus("ACTIVE");

    const queryParams = new URLSearchParams(window.location.search);
    const nurtureViewFromUrl = queryParams.get("nurtureView");
    queryParams.set("nurtureView", "LIST");
    queryParams.delete("nurtureLeadStatus");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    defaultLeadsCall(true, "ACTIVE", selectedCourse?.courseId);
    if (filterApplied && !nurtureViewFromUrl) {
      handleResetClick();
      dispatch(
        setToastNotification({
          message: String("Switched to list view, filters removed!"),
          type: "success",
          snackOpen: true,
        })
      );
    }
  };

  const handleLeadStatusChange = useCallback(
    (event: any, courseId: string, nurtureView: boolean) => {
      setLeadItems("");
      setLeadListItems("");
      setleadPaginationLoad(false);

      const value = event;
      setLeadStatusCheck(value);
      if (value) {
        setNurtureLeadStatus("LOST");
      } else {
        setNurtureLeadStatus("ACTIVE");
      }

      defaultLeadsCall(nurtureView, value ? "LOST" : "ACTIVE", courseId);

      const queryParams = new URLSearchParams(window.location.search);
      if (value === true) {
        queryParams.set("nurtureLeadStatus", "LOST");
      } else {
        queryParams.delete("nurtureLeadStatus");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },
    [defaultLeadsCall]
  );

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const branchParam = urlSearchParams.get("filteredBranches");
    const batchParam = urlSearchParams.get("filteredBatches");
    const assigneeParam = urlSearchParams.get("filteredAssignees");
    const priorityParam = urlSearchParams.get("filteredPriorities");
    const sourceParam = urlSearchParams.get("filteredSources");
    const stagesParam = urlSearchParams.get("filteredStages");
    const leadStatusParam = urlSearchParams.get("nurtureLeadStatus");

    if (leadStatusParam === "LOST") {
      setLeadStatusCheck(true);
    }
    if (branchParam && branchList) {
      const branchIds = branchParam.split("|");

      const selectedBranches = branchIds
        .map((id) => {
          const branch = branchList?.find((branch: any) => branch.id === id);
          return branch ? { id, branchName: branch.branchName } : null;
        })
        .filter(Boolean) as CheckedBranch[];

      setCheckedBranches(selectedBranches);
    }

    if (batchParam && batchList) {
      const batchIds = batchParam.split("|");

      const selectedBatches = batchIds
        .map((id) => {
          const batch = batchList?.find((batch: any) => batch.id === id);
          return batch ? { id, batchName: batch.batchName } : null;
        })
        .filter(Boolean) as CheckedBatch[];

      setCheckedBatches(selectedBatches);
    }

    if (assigneeParam && employees) {
      const assigneeIds = assigneeParam.split("|");

      const selectedAssignees = assigneeIds
        .map((id) => {
          const assignee = employees?.find((type: any) => type.id === id);
          return assignee
            ? {
                id,
                firstName: assignee.firstName,
                lastName: assignee.lastName,
              }
            : null;
        })
        .filter(Boolean) as CheckedAssignees[];

      setCheckedAssignees(selectedAssignees);
    }

    if (sourceParam && sourceList) {
      const sources = sourceParam.split("|");

      const selectedSources = sources
        .map((value) => {
          const source = sourceList?.find(
            (type: any) => type.value.toLowerCase() === value.toLowerCase()
          );
          return source
            ? {
                source: source.value,
              }
            : null;
        })
        .filter(Boolean) as CheckedSources[];

      setCheckedSources(selectedSources);
    }

    if (priorityParam) {
      const priorities = priorityParam.split("|");

      const selectedPriorities = priorities
        .map((value) => {
          const priority = [
            { priority: "URGENT" },
            { priority: "HIGH" },
            { priority: "NORMAL" },
            { priority: "LOW" },
          ].find(
            (type: any) => type.priority.toLowerCase() === value.toLowerCase()
          );
          return priority
            ? {
                priority: priority.priority,
              }
            : null;
        })
        .filter(Boolean) as CheckedPriorities[];

      setCheckedPriorities(selectedPriorities);
    }

    if (stagesParam) {
      const stages = stagesParam.split("|");

      const selectedStages = stages
        .map((value) => {
          const status = leadStagesData?.find(
            (type: any) => type.stateName === value
          );
          return status
            ? {
                leadStage: status.stateName,
              }
            : null;
        })
        .filter(Boolean) as CheckedLeadStages[];

      setCheckedStages(selectedStages);
    }

    if (
      branchParam ||
      batchParam ||
      assigneeParam ||
      priorityParam ||
      sourceParam ||
      stagesParam
    ) {
      setFilterApplied(true);
    }
  }, [branchList, batchList, employees, sourceList]);

  const theme = useTheme();
  const isTabSize = useMediaQuery(theme.breakpoints.down("lg"));

  const queryParams = new URLSearchParams(window.location.search);
  const batchModalOpen = queryParams.get("batchDrawer");
  const courseModalOpen = queryParams.get("courseDrawer");
  return (
    <Stack
      sx={{
        backgroundColor: "#fff",
        padding: "12px 24px",
      }}
    >
      <Box
        display="flex"
        flexGrow={1}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div>
          {selectedCourse === null || sortedSwimLane === null ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="150px"
              height={38}
              style={{
                borderRadius: "2px",
              }}
            />
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
              <ViewCourseDetailsPopup
                GetCourseById={GetCourseById}
                selectedCourse={selectedCourse}
                GetInstituteById={GetInstituteById}
              />
              <div style={{ gap: "8px", display: "flex" }}>
                <Tooltip title="Kanban view" arrow>
                  <Button
                    onClick={() => {
                      handleKanbanViewClick();
                    }}
                    disabled={onLeadsLoading}
                    sx={{
                      minWidth: "unset",
                      padding: "12px",
                      backgroundColor: isKanbanView ? "#E8FAF5" : "inherit",
                    }}
                  >
                    <img
                      src={isKanbanView ? activeKanbanViewImg : kanbanViewImg}
                      alt="kanbanViewImg"
                    />
                  </Button>
                </Tooltip>
                <Tooltip title="List view" arrow>
                  <Button
                    onClick={() => {
                      handleListViewClick();
                    }}
                    sx={{
                      minWidth: "unset",
                      padding: "12px",
                      backgroundColor: !isKanbanView ? "#E8FAF5" : "inherit",
                    }}
                    disabled={onLeadsLoading}
                  >
                    <img
                      src={!isKanbanView ? activeListViewImg : listViewImg}
                      alt="listViewImg"
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {sortedSwimLane === null ? (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="150px"
                height="38px"
                style={{
                  borderRadius: "2px",
                }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                height="38px"
                width="100px"
                style={{
                  borderRadius: "2px",
                }}
              />
            </>
          ) : (
            <>
              <OpenSearch fromNurture={true} selectedCourse={selectedCourse} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "4PX",
                  border: "1px solid #D0D5DD",
                  background: "#fff",
                  height: "38px",
                  overflow: "hidden",
                }}
              >
                <Tooltip title="Active Leads" arrow>
                  <span>
                    <button
                      style={{
                        cursor: onLeadsLoading ? "auto" : "pointer",
                        border: "none",
                        background: leadStatusCheck
                          ? "#fff"
                          : "rgba(17, 200, 155, 0.10)",
                        height: "37px",
                        width: "37px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                      }}
                      disabled={onLeadsLoading}
                      onClick={() =>
                        handleLeadStatusChange(
                          false,
                          selectedCourse?.courseId,
                          isKanbanView ? false : true
                        )
                      }
                    >
                      <img
                        src={
                          !leadStatusCheck ? leadActiveIcon : leadActiveDefault
                        }
                        alt="leadActiveIcon"
                      />
                    </button>
                  </span>
                </Tooltip>

                <div
                  style={{
                    background: "#D0D5DD",
                    width: "1px",
                    height: "100%",
                  }}
                ></div>
                <Tooltip title="Lost Leads" arrow>
                  <span>
                    <button
                      style={{
                        cursor: onLeadsLoading ? "auto" : "pointer",
                        border: "none",
                        background: leadStatusCheck
                          ? "rgba(236, 57, 57, 0.12)"
                          : "#fff",
                        height: "37px",
                        width: "37px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                      }}
                      disabled={onLeadsLoading}
                      onClick={() =>
                        handleLeadStatusChange(
                          true,
                          selectedCourse?.courseId,
                          isKanbanView ? false : true
                        )
                      }
                    >
                      <img
                        src={!leadStatusCheck ? leadLostDefault : leadLostIcon}
                        alt="leadLostIcon"
                      />
                    </button>
                  </span>
                </Tooltip>
              </div>
              <FilterDrawer
                handleFilterApply={handleFilterApply}
                handleResetClick={handleResetClick}
                activeFilter={filterApplied}
                cancelClick={cancelClick}
                closeSetFilter={handleFilterClose}
              >
                <div
                  style={{
                    padding: "22px 32px",
                    overflowY: "auto",
                    maxHeight: "calc(100% - 110px)",
                  }}
                >
                  <FilterChipsWrap>
                    {checkedStages.length > 0 &&
                      checkedStages.map((item) => (
                        <FilterChips
                          keyVal={item.leadStage}
                          chipName={item.leadStage
                            .toLowerCase()
                            ?.replace(/_/g, " ")}
                          clearValue={item.leadStage}
                          closeFunction={clearCheckedLeadStages}
                        />
                      ))}
                    {checkedPriorities.length > 0 &&
                      checkedPriorities.map((item) => (
                        <FilterChips
                          keyVal={item.priority}
                          chipName={item.priority.toLowerCase()}
                          clearValue={item.priority}
                          closeFunction={clearCheckedPriority}
                        />
                      ))}

                    {checkedBranches.length > 0 &&
                      checkedBranches.map((item) => (
                        <FilterChips
                          keyVal={item.id}
                          chipName={item.branchName}
                          clearValue={item.id}
                          closeFunction={clearCheckedBranch}
                        />
                      ))}

                    {checkedSources.length > 0 &&
                      checkedSources.map((item) => (
                        <FilterChips
                          keyVal={item.source}
                          chipName={item.source}
                          clearValue={item.source}
                          closeFunction={clearCheckedSource}
                        />
                      ))}
                    {checkedBatches.length > 0 &&
                      checkedBatches.map((item) => (
                        <FilterChips
                          keyVal={item.id}
                          chipName={item.batchName}
                          clearValue={item.id}
                          closeFunction={clearCheckedBatch}
                        />
                      ))}
                    {checkedAssignees.length > 0 &&
                      checkedAssignees.map((item) => (
                        <FilterChips
                          keyVal={item.id}
                          chipName={item.firstName + " " + item.lastName}
                          clearValue={item.id}
                          closeFunction={clearCheckedAssignee}
                        />
                      ))}
                  </FilterChipsWrap>
                  {hasAllBranchAccess && (
                    <FilterTypeWrap>
                      <FilterLabel labelValue="Branch" />

                      <FilterSearchField
                        value={branchSearchTerm}
                        onChange={(e: any) => {
                          setBranchSearchTerm(e.target.value);
                        }}
                        placeholder="Search Branch"
                      />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {branchList &&
                            displayedBranch?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.id}
                                checked={checkedBranches.some(
                                  (data) => data.id === item.id
                                )}
                                checkboxFunction={() =>
                                  handleFilterBranchChecked(
                                    item.id,
                                    item.branchName
                                  )
                                }
                                label={item.branchName.toLowerCase()}
                              />
                            ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                      <ShowAllBtn
                        clickFunction={handleShowMoreBranch}
                        showAll={showAllBranch}
                        filteredState={filteredBranch}
                      />
                    </FilterTypeWrap>
                  )}

                  <FilterTypeWrap>
                    <FilterLabel labelValue="Batch" />
                    <FilterSearchField
                      value={batchSearchTerm}
                      onChange={(e: any) => setBatchSearchTerm(e.target.value)}
                      placeholder="Search Batch"
                    />
                    <FilterCheckBoxWrap>
                      <FormGroup>
                        {batchList &&
                          displayedBatch?.map((item: any) => (
                            <FilterCheckbox
                              keyVal={item.id}
                              checked={checkedBatches.some(
                                (data) => data.id === item.id
                              )}
                              checkboxFunction={() =>
                                handleFilterBatchChecked(
                                  item.id,
                                  item.batchName
                                )
                              }
                              label={item.batchName.toLowerCase()}
                            />
                          ))}
                      </FormGroup>
                    </FilterCheckBoxWrap>
                    <ShowAllBtn
                      clickFunction={handleShowMoreBatch}
                      showAll={showAllBatch}
                      filteredState={filteredBatch}
                    />
                  </FilterTypeWrap>

                  <FilterTypeWrap>
                    <FilterLabel labelValue="Priority" />
                    <FilterCheckBoxWrap>
                      <FormGroup>
                        {[
                          { priority: "URGENT" },
                          { priority: "HIGH" },
                          { priority: "NORMAL" },
                          { priority: "LOW" },
                        ].map((item: any) => (
                          <FilterCheckbox
                            keyVal={item.priority}
                            checked={checkedPriorities.some(
                              (data) =>
                                data.priority.toLowerCase() ===
                                item.priority.toLowerCase()
                            )}
                            checkboxFunction={() =>
                              handleFilterPriorityChecked(item.priority)
                            }
                            label={item.priority.toLowerCase()}
                          />
                        ))}
                      </FormGroup>
                    </FilterCheckBoxWrap>
                  </FilterTypeWrap>

                  <FilterTypeWrap>
                    <FilterLabel labelValue=" Source" />
                    <FilterSearchField
                      value={sourceSearchTerm}
                      onChange={(e: any) => {
                        setSourceSearchTerm(e.target.value);
                      }}
                      placeholder="Search Source"
                    />
                    <FilterCheckBoxWrap>
                      <FormGroup>
                        {sourceList &&
                          displayedSources.map((item: any) => (
                            <FilterCheckbox
                              keyVal={item.key}
                              checked={checkedSources.some(
                                (data) =>
                                  data.source.toLowerCase() ===
                                  item.value.toLowerCase()
                              )}
                              checkboxFunction={() =>
                                handleFilterSourceChecked(item.value)
                              }
                              label={item.value.toLowerCase()}
                            />
                          ))}
                      </FormGroup>
                    </FilterCheckBoxWrap>
                    <ShowAllBtn
                      clickFunction={handleShowMoreSources}
                      showAll={showAllSources}
                      filteredState={filteredSources}
                    />
                  </FilterTypeWrap>

                  {!isKanbanView && (
                    <FilterTypeWrap>
                      <FilterLabel labelValue="Stages" />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {leadStagesData?.map((item: any) => (
                            <FilterCheckbox
                              keyVal={item.stateName}
                              checked={checkedStages.some(
                                (data) => data.leadStage === item.stateName
                              )}
                              checkboxFunction={() =>
                                handleFilterLeadStagesChecked(item.stateName)
                              }
                              label={item?.displayStateName?.toLowerCase()}
                            />
                          ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                    </FilterTypeWrap>
                  )}

                  <FilterTypeWrap>
                    <FilterLabel labelValue="Assignee" />
                    <FilterSearchField
                      value={assigneeSearchTerm}
                      onChange={handleAssigneeSearch}
                      placeholder="Search Assignee"
                    />
                    <FilterCheckBoxWrap>
                      <FormGroup>
                        {employees &&
                          displayedEmployees?.map((item: any) => (
                            <FilterCheckbox
                              keyVal={item.id}
                              checked={checkedAssignees.some(
                                (data) => data.id === item.id
                              )}
                              checkboxFunction={() =>
                                handleFilterAssigneeChecked(
                                  item.id,
                                  item.firstName,
                                  item.lastName
                                )
                              }
                              label={
                                item.firstName.toLowerCase() +
                                " " +
                                item.lastName.toLowerCase()
                              }
                            />
                          ))}
                      </FormGroup>
                    </FilterCheckBoxWrap>
                    <ShowAllBtn
                      clickFunction={handleShowMoreEmployees}
                      showAll={showAllEmployees}
                      filteredState={filteredEmployees}
                    />
                  </FilterTypeWrap>
                </div>
              </FilterDrawer>
              {allLeadsEmpty === false && (
                <>
                  <Tooltip
                    arrow
                    title={""}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontSize: 14,
                          textTransform: "capitalize",
                          boxShadow: "none",
                          height: "38px",
                        }}
                        size={"small"}
                        onClick={() => setShowEnquiryModal(!showEnquiryModal)}
                      >
                        <AddIcon
                          sx={{ marginRight: "10px", fontSize: "18px" }}
                        />

                        {isTabSize
                          ? "lead"
                          : batchModalOpen === "true" &&
                            courseModalOpen === "true"
                          ? "lead"
                          : "Add lead"}
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
            </>
          )}
        </div>
      </Box>
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
    </Stack>
  );
}
