import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import deleteIcon from "../../assets/images/delete.svg";
import Popover from "../PopOver/PopOver";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const TableComponent = (props: any) => {
  const {
    column,
    array,
    action = false,
    id = false,
    onEdit,
    onDelete,
    headerColor = "#F9FAFB",
    backgroundColor = "#FFF",
    borderColor = "#EAECF0",
    height,
    onRowClick = null,
    idCalc = 0,
    maximumHeight,
    zIndexValue,
    setZIndexValue,
    dataWithError,
  } = props;
  const objKey =
    typeof array !== "undefined" && array && array.length > 0
      ? Object.keys(array[0])
      : undefined;

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FBE1E1",
      color: "red",
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }));

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          border: `1px solid ${borderColor}`,
          maxHeight: maximumHeight,
          height: height,
          backgroundColor: backgroundColor,
        }}
      >
        <Table
          sx={{
            minWidth: 300,
            position: "relative",
          }}
          aria-label="table"
        >
          <TableHead
            sx={{
              background: headerColor,
              position: "sticky",
              top: 0,
              zIndex: zIndexValue && dataWithError ? "9990" : "0",
            }}
          >
            <TableRow>
              {column?.map((obj: any, key: number) => (
                <TableCell
                  key={key}
                  sx={{
                    borderBottom: `1px solid ${borderColor}`,
                    color: "#667085",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "18px",
                  }}
                >
                  {obj}
                </TableCell>
              ))}
              {action && (
                <TableCell
                  sx={{
                    borderBottom: `1px solid ${borderColor}`,
                    color: "#667085",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "18px",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {array &&
              array.length !== 0 &&
              array?.map((obj: any, key: number) => {
                return (
                  <TableRow
                    key={key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: onRowClick && "pointer",
                    }}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick();
                      }
                    }}
                  >
                    {objKey?.map((a: any, key: any) => (
                      <TableCell
                        key={key}
                        sx={{
                          borderBottom: `1px solid ${borderColor}`,
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                        onClick={(e) => {
                          if (a === "file") {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                      >
                        {a === "file" ? (
                          <div
                            style={{
                              color: "#456DFB",
                              borderBottom: "1px solid #456DFB",
                              width: "fit-content",
                              cursor: "pointer",
                            }}
                          >
                            {obj[a]}
                          </div>
                        ) : a === "status" ? (
                          obj[a].toLowerCase() === "failed" ? (
                            <Box
                              color="#EC3939"
                              width="fit-content"
                              borderRadius="16px"
                              padding="2px 15px"
                              sx={{ background: "#FCF0F0" }}
                            >
                              {obj[a]}
                            </Box>
                          ) : (
                            <Box
                              color="#20CCA1"
                              width="fit-content"
                              borderRadius="16px"
                              padding="2px 15px"
                              sx={{ background: "#EEFBF8" }}
                            >
                              {obj[a]}
                            </Box>
                          )
                        ) : a === "error" ? (
                          <>
                            {obj[a] === "" ? (
                              <Box color={"#667085"}>-</Box>
                            ) : (
                              <Box color="#EC3939">{obj[a]}</Box>
                            )}
                          </>
                        ) : a === "validation" ? (
                          <>
                            {obj?.validation && obj?.validation?.length > 1 ? (
                              <LightTooltip
                                title={
                                  obj?.validation?.length > 1
                                    ? obj?.validation?.length - 1
                                      ? `${obj?.validation}`
                                      : `${obj?.validation},`
                                    : obj?.validation
                                }
                                placement="bottom"
                                PopperProps={{ disablePortal: true }}
                                sx={{ zIndex: 10000 }}
                              >
                                <Box
                                  color={"red"}
                                  style={{ cursor: "pointer" }}
                                >
                                  {obj[a] === ""
                                    ? "-"
                                    : obj?.validation &&
                                      obj?.validation?.length > 1
                                    ? `${obj?.validation[0]}+${
                                        obj?.validation?.length - 1
                                      }`
                                    : obj?.validation[0]}
                                </Box>
                              </LightTooltip>
                            ) : (
                              <Box color={"red"}>{obj?.validation[0]}</Box>
                            )}
                          </>
                        ) : (
                          <>
                            {
                              <Box color={"rgba(0, 0, 0, 0.87)"}>
                                {obj[a] === "" ? "-" : obj[a]}
                              </Box>
                            }
                          </>
                        )}
                      </TableCell>
                    ))}
                    {action && (
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${borderColor}`,
                          color: "#667085",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <Box
                            onClick={() => {
                              onEdit(obj);
                              setZIndexValue(false);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <FiEdit />
                          </Box>
                          <Box
                            marginLeft={2}
                            onClick={() => onDelete(obj)}
                            sx={{ cursor: "pointer" }}
                          >
                            <img src={deleteIcon} alt="" />
                          </Box>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TableComponent;
