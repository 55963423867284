import React, { useState } from "react";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import styles from "./LeadRandomPay.module.css";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import {
  createRandomPayments,
  recordPayment,
} from "../../../service/nurtureSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

function RandomRecordPay(props: any) {
  const { leadData, selectedPay, onClose, setSelectedPay, add, loader } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { createPaymentLoading } = useSelector((state: any) => state.nurture);
  const [paymentName, setPaymentName] = React.useState<string>("");
  const [enteredAmount, setEnteredAmount] = React.useState<number>(0);
  const [emiAmountError, setEmiAmountError] = useState<string>("");
  const [paymentSource, setPaymentSource] = useState<string>("");
  const [paymentSourceError, setPaymentSourceError] = useState<string>("");

  const closePayRecordPopup = () => {
    setSelectedPay("");
    onClose();
  };

  const handleChangePaymentSource = React.useCallback((event: any) => {
    setPaymentSource(event.target.value);

    setPaymentSourceError("");
  }, []);

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    if (add) {
      const body = {
        enquiryId: leadData?.id,
        instituteId: authUser?.institute[0]?.instituteId,
        amount: enteredAmount,
        paymentName: paymentName,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
        createdById: authUser?.institute[0]?.id,
        capturePayment: true,
        captureMethod: "recordPayment",
      };
      dispatch<any>(createRandomPayments(body));
    } else {
      if (!enteredAmount) {
        setEmiAmountError("Field is required");
        return;
      }

      if (enteredAmount < 0) {
        setEmiAmountError("Amount Received cannot be negative");
        return;
      }

      if (enteredAmount === 0) {
        setEmiAmountError("Please enter a number greater than zero");
        return;
      }

      if (paymentSource === "") {
        setPaymentSourceError("Field is required");
        return;
      }

      const currentTimestamp = moment().valueOf();
      const body = {
        enquiryId: leadData?.id,
        basePrice: enteredAmount,
        discountMetric: "",
        discount: 0,
        amountReceived: enteredAmount,
        balance: 0,
        description: "",
        gstNumber: "",
        gstCompanyDetails: "",
        paymentDate: currentTimestamp,
        isFirstPayment: false,
        paymentSource: paymentSource,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
        createdById: authUser?.institute[0]?.id,
        sendReceipt: false,
        paymentType: "RANDOM_PAYMENT",
        paymentStatus: "PAID",
        emiId: "",
        randomPaymentId: selectedPay?.randomId,
      };
      if (body) {
        dispatch<any>(recordPayment(body));
      }
    }
  };

  React.useEffect(() => {
    if (selectedPay) {
      setPaymentName(selectedPay?.paymentName);
      setEnteredAmount(selectedPay?.price);
    }
  }, [selectedPay]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ padding: "16px 16px 16px 16px" }}>
          <div className={styles.popupHead}>
            <div className={styles.clsBtnWrap}>
              <button
                className={styles.clsBtn}
                onClick={() => closePayRecordPopup()}
              >
                <CloseIcon sx={{ fontSize: "20px", color: "#667085" }} />
              </button>
            </div>
            <div className={styles.formHeading}>Record Payment</div>
          </div>

          <form action="" className={styles.formWrap}>
            <div
              style={{
                maxHeight: "370px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
              className={styles.recordPayWrap}
            >
              <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                <div className={styles.fieldLabel}>Payment Name</div>
                <TextField
                  type="text"
                  id="name"
                  required
                  placeholder="₹"
                  variant="outlined"
                  inputProps={{ min: "0" }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#B1B1B1",
                      fontWeight: "300",
                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 16px",
                    },
                  }}
                  value={paymentName}
                  disabled
                />
              </FormControl>

              <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                <div className={styles.fieldLabel}>Amount </div>
                <TextField
                  type="number"
                  required
                  value={enteredAmount}
                  id="amount"
                  variant="outlined"
                  disabled
                  error={!!emiAmountError}
                  //   helperText={emiAmountError || " "}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#B1B1B1",
                      fontWeight: "300",
                      fontSize: "12px",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 16px",
                    },
                  }}
                />
              </FormControl>

              <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
                <div className={styles.fieldLabel}>
                  Payment source <span style={{ color: "#EC3939" }}>*</span>
                </div>
                <Select
                  MenuProps={{
                    style: {
                      zIndex: 9999999999,
                      maxHeight: 400,
                    },
                    PaperProps: {
                      style: {
                        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                      },
                    },
                  }}
                  placeholder="Select"
                  variant="outlined"
                  id="paymentSource"
                  value={paymentSource}
                  onChange={handleChangePaymentSource}
                  error={!!paymentSourceError}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 16px",
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: "13PX",
                      color: "#18181B",
                    }}
                    value="Cash"
                  >
                    Cash
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "13PX",
                      color: "#18181B",
                    }}
                    value="POS"
                  >
                    POS
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontSize: "13PX",
                      color: "#18181B",
                    }}
                    value="UPI"
                  >
                    UPI
                  </MenuItem>
                </Select>
                {paymentSourceError && (
                  <FormHelperText error> {paymentSourceError}</FormHelperText>
                )}
              </FormControl>
            </div>
          </form>
        </div>
        <div className={styles.btnBottomSplit}>
          <CancelBtn onClick={closePayRecordPopup} />
          <SaveBtn
            onClick={handleSaveClick}
            disabled={loader || emiAmountError !== ""}
            loader={loader}
          />
        </div>
      </div>
    </div>
  );
}

export default RandomRecordPay;
