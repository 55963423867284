import React from "react";
import { Skeleton } from "@mui/material";

function LeadPaySkeleton() {
  return (
    <div>
      <div
        style={{
          width: "20%",
          marginBottom: "20px",
        }}
      >
        <Skeleton animation="wave" variant="text" width="100%" height={25} />
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          padding: "14px 20px",
          background: "#fafafc",
          border: "1px solid #f0f0f5",
          borderRadius: "4PX",
        }}
      >
        <div
          style={{
            width: "20%",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={20} />
          <Skeleton animation="wave" variant="text" width="100%" height={30} />
        </div>
        <div
          style={{
            width: "20%",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={20} />
          <Skeleton animation="wave" variant="text" width="100%" height={30} />
        </div>
      </div>
      <div
        style={{
          width: "20%",
          marginBottom: "20px",
        }}
      >
        <Skeleton animation="wave" variant="text" width="100%" height={25} />
      </div>
      <div style={{ border: "1px solid #f0f0f5", borderRadius: "4PX" }}>
        <div
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
            padding: "14px 20px",
            background: "#fafafc",
          }}
        >
          <div
            style={{
              width: "20%",
            }}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={20}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={30}
            />
          </div>
          <div
            style={{
              width: "20%",
            }}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={20}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={30}
            />
          </div>
        </div>
        <div style={{ padding: "10px" }}>
          <div
            style={{
              border: "1px solid #f0f0f5",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              borderRadius: "4PX",
            }}
          >
            <div style={{ width: "20%" }}>
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height={30}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                gap: "10px",
              }}
            >
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height={30}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height={30}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadPaySkeleton;
