import React, { useEffect, useState } from "react";
import {
  getAllCourseByInstituteRequest,
  getCourseByIdRequest,
  resetCourseStatus,
  updateCoursePayment,
} from "../../service/courseSlice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styles from "../Manegecourses.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
} from "@mui/material";
import accordionDropdown from "../../../../../../assets/images/accordion-dropdown.svg";
import accordionUnselect from "../../../../../../assets/images/accordion-unselect.svg";
import accordionSelect from "../../../../../../assets/images/accordion-select.svg";
import rightArrowIcon from "../../../../../../assets/images/right-arrow.svg";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import Membership from "./Membership";
import Regular from "./Regular";
import { GetInstituteByIdRequest } from "../../../InstituteProfile/service/institute.request";
import { appEntery } from "../../../../Home/service/homeSlice";
import { useNavigate } from "react-router-dom";
import Session from "./Session";
import { getInstituteById } from "../../../InstituteProfile/service/instituteSlice";

const paymentTypes = [
  { paymentType: "REGULAR" },
  { paymentType: "MEMBERSHIP" },
  { paymentType: "SESSION" },
];

function CoursePayment(props: any) {
  const { setIsPayEditOpen, courseById } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading_updateCourse, success_updateCourse } = useSelector(
    (state: any) => state.courses
  );
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [paymentType, setPaymentType] = React.useState<any>([]);
  const [payTypeOpen, setPayTypeOpen] = React.useState<boolean>(false);
  const [payTypeDetails, setPayTypeDetails] = React.useState<string>("");

  const [regularPrice, setRegularPrice] = React.useState<string>("");
  const [regularPriceError, setRegularPriceError] =
    React.useState<boolean>(false);
  const [enableInstallment, setEnableInstallment] =
    React.useState<boolean>(false);

  const [rows, setRows] = React.useState([
    {
      id: 1,
      emiModeValue: "",
      emiPrice: 0,
      isEmiModeValid: true,
      isPriceValid: true,
    },
  ]);
  const [selectedEmiModes, setSelectedEmiModes] = React.useState<string[]>([]);

  const [sessionRows, setSessionRows] = React.useState([
    {
      id: 1,
      emiModeValue: "",
      emiPrice: 0,
      isEmiModeValid: true,
      isPriceValid: true,
    },
  ]);
  const [selectedSessionModes, setSelectedSessionModes] = React.useState<
    string[]
  >([]);

  const [
    validatingFirstTimeBranchCreation,
    setValidatingFirstTimeBranchCreation,
  ] = useState<any>();

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    if (activeInstitute?.isProfileCompleted === false && activeInstitute?.id) {
      GetInstituteByIdRequest(activeInstitute?.id)
        ?.then((res: any) => {
          setValidatingFirstTimeBranchCreation(res?.data?.data);
        })
        .catch((err: any) => {});
    }
  }, []);

  const handleChange =
    (panel: string, payType: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (expanded !== panel) {
        setExpanded(isExpanded ? panel : false);
        setPaymentType((prevPaymentType: any) =>
          prevPaymentType?.includes(payType) ? [] : [payType]
        );
        if (panel === "panel1") {
          if (courseById?.paymentDetails?.paymentType === "REGULAR") {
            setRegularPrice(
              courseById?.paymentDetails?.paymentData?.totalAmount
            );
            setEnableInstallment(
              courseById?.paymentDetails?.paymentData?.enableInstallment
            );
          } else {
            setRegularPrice("");
            setRegularPriceError(false);
            setEnableInstallment(false);
          }
        } else if (panel === "panel2") {
          if (courseById?.paymentDetails?.paymentType === "MEMBERSHIP") {
            if (
              courseById?.paymentDetails?.paymentData?.paymentStructure
                ?.length > 0
            ) {
              const defaultPaymentStructure =
                courseById?.paymentDetails?.paymentData?.paymentStructure?.map(
                  (item: any, index: number) => ({
                    id: index + 1,
                    emiModeValue: item.frequency,
                    emiPrice: item.emiAmount,
                    isEmiModeValid: true,
                    isPriceValid: true,
                  })
                );
              setRows(defaultPaymentStructure);
            }
          } else {
            setRows([
              {
                id: 1,
                emiModeValue: "",
                emiPrice: 0,
                isEmiModeValid: true,
                isPriceValid: true,
              },
            ]);
            setSelectedEmiModes([]);
          }
        } else {
          if (courseById?.paymentDetails?.paymentType === "SESSION") {
          } else {
            setSessionRows([
              {
                id: 1,
                emiModeValue: "PER_SESSION",
                emiPrice: 0,
                isEmiModeValid: true,
                isPriceValid: true,
              },
            ]);
          }
        }
      }
    };

  const cancelBtnClick = () => {
    if (
      courseById?.paymentDetails?.hasOwnProperty("paymentType") &&
      courseById?.paymentDetails?.paymentType !== ""
    ) {
      if (courseById?.paymentDetails?.paymentType === "REGULAR") {
        setRegularPrice(courseById?.paymentDetails?.paymentData?.totalAmount);
        setEnableInstallment(
          courseById?.paymentDetails?.paymentData?.enableInstallment
        );
        setPaymentType(["REGULAR"]);
        setExpanded("panel1");
      } else {
        setPaymentType(["MEMBERSHIP"]);
        setExpanded("panel2");

        if (
          courseById?.paymentDetails?.paymentData?.paymentStructure?.length > 0
        ) {
          const defaultPaymentStructure =
            courseById?.paymentDetails?.paymentData?.paymentStructure?.map(
              (item: any, index: number) => ({
                id: index + 1,
                emiModeValue: item.frequency,
                emiPrice: item.emiAmount,
                isEmiModeValid: true,
                isPriceValid: true,
              })
            );
          setRows(defaultPaymentStructure);
        }
      }
    } else {
      if (paymentType?.includes("REGULAR")) {
        setRegularPrice("");
        setRegularPriceError(false);
        setEnableInstallment(false);
      } else if (paymentType?.includes("MEMBERSHIP")) {
        setRows([
          {
            id: 1,
            emiModeValue: "",
            emiPrice: 0,
            isEmiModeValid: true,
            isPriceValid: true,
          },
        ]);
        setSelectedEmiModes([]);
      }
      setExpanded(false);
      setPaymentType([]);
    }
    setIsPayEditOpen(false);
  };

  const handleRegularPayment = async (event: any) => {
    event.preventDefault();

    try {
      let priceError = false;
      if (parseFloat(regularPrice) > 0) {
        priceError = false;
        setRegularPriceError(false);
      } else {
        priceError = true;
        setRegularPriceError(true);
      }
      const body = {
        courseId: courseById?.id,
        paymentType: "REGULAR",
        totalAmount: parseFloat(regularPrice),
        enableInstallment: enableInstallment,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        isFirstCourse:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.courseCreated === false
            ? true
            : false,
      };
      if (!priceError) {
        dispatch<any>(updateCoursePayment(body));
      }
    } catch (error) {}
  };

  const hasEmptyPaymentMode = rows?.some((row) => row.emiModeValue === "");
  const hasPriceNotGreaterThanZero = rows?.every((row) => row.emiPrice > 0);

  const hasEmptySessionMode = sessionRows?.some(
    (row) => row.emiModeValue === ""
  );
  const hasSessionPriceGreaterThanZero = sessionRows?.every(
    (row) => row.emiPrice > 0
  );

  const handleMembershipPayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows?.map((item: any) => ({
        frequency: item.emiModeValue,
        emiAmount: parseFloat(item.emiPrice),
      }));

      const body = {
        courseId: courseById?.id,
        paymentType: "MEMBERSHIP",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        isFirstCourse:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.courseCreated === false
            ? true
            : false,
      };
      if (!hasEmptyPaymentMode && hasPriceNotGreaterThanZero) {
        dispatch<any>(updateCoursePayment(body));
      }
    } catch (error) {}
  };

  const handleSessionPayment = async (event: any) => {
    event.preventDefault();
    console.log(
      "hasSessionPriceZero",
      hasSessionPriceGreaterThanZero,
      hasEmptySessionMode
    );
    try {
      const updatedSessionStructure = sessionRows?.map((item: any) => ({
        frequency: item.emiModeValue,
        session_fee: parseFloat(item.emiPrice),
      }));

      const body = {
        courseId: courseById?.id,
        paymentType: "SESSION",
        paymentStructure: updatedSessionStructure,
        frequency: updatedSessionStructure?.[0]?.frequency,
        session_fee: updatedSessionStructure?.[0]?.session_fee,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        isFirstCourse:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.courseCreated === false
            ? true
            : false,
      };
      if (!hasEmptySessionMode && hasSessionPriceGreaterThanZero) {
        dispatch<any>(updateCoursePayment(body));
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (courseById?.paymentDetails?.paymentType === "REGULAR") {
      setPaymentType(["REGULAR"]);
      setRegularPrice(courseById?.paymentDetails?.paymentData?.totalAmount);
      setEnableInstallment(
        courseById?.paymentDetails?.paymentData?.enableInstallment
      );
      setExpanded("panel1");
    } else if (courseById?.paymentDetails?.paymentType === "MEMBERSHIP") {
      setPaymentType(["MEMBERSHIP"]);
      setExpanded("panel2");
      if (
        courseById?.paymentDetails?.paymentData?.paymentStructure?.length > 0
      ) {
        const defaultPaymentStructure =
          courseById?.paymentDetails?.paymentData?.paymentStructure?.map(
            (item: any, index: number) => ({
              id: index + 1,
              emiModeValue: item.frequency,
              emiPrice: item.emiAmount,
              isEmiModeValid: true,
              isPriceValid: true,
            })
          );
        setRows(defaultPaymentStructure);
        const defaultSelectedEmiModes =
          courseById?.paymentDetails?.paymentData?.paymentStructure?.map(
            (row: any) => row.frequency
          );
        setSelectedEmiModes(defaultSelectedEmiModes);
      }
    } else if (courseById?.paymentDetails?.paymentType === "SESSION") {
      setPaymentType(["SESSION"]);
      setExpanded("panel3");
      const defaultPaymentStructure =
        courseById?.paymentDetails?.paymentData?.paymentStructure?.map(
          (item: any, index: number) => ({
            id: index + 1,
            emiModeValue: item.frequency,
            emiPrice: item.session_fee,
            isEmiModeValid: true,
            isPriceValid: true,
          })
        );
      setSessionRows(defaultPaymentStructure);
    }
  }, [courseById]);

  React.useEffect(() => {
    if (success_updateCourse !== true && success_updateCourse !== false) {
      const pathname = window.location.pathname;
      const courseId = pathname.substring(pathname.lastIndexOf("/") + 1);
      dispatch<any>(getCourseByIdRequest(courseId));
      dispatch(resetCourseStatus());
      setIsPayEditOpen(false);
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        dispatch<any>(
          getAllCourseByInstituteRequest({
            id: userFromLocalStorage?.id,
            loadExtraInfo: true,
          })
        );
        if (
          userFromLocalStorage &&
          validatingFirstTimeBranchCreation?.courseCreated === false
        ) {
          dispatch<any>(getInstituteById(activeInstitute?.id));
        }

        // dispatch<any>(
        //   appEntery(userFromLocalStorage && userFromLocalStorage.id)
        // );
        if (
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.batchCreated === false
        ) {
          navigate("/app/manage/Batches");
        }
      }
    }
  }, [success_updateCourse, courseById]);

  return (
    <div style={{ maxWidth: "100%", overflowY: "scroll" }}>
      <div className={styles.coursePayEditSectionWrap}>
        <div>
          {paymentTypes?.map((item: any, index: number) => (
            <>
              <Accordion
                key={item.chapterName + index}
                expanded={expanded === `panel${index + 1}`}
                onChange={handleChange(`panel${index + 1}`, item?.paymentType)}
                sx={{
                  border: "1px solid #E9E9E9",
                  boxShadow: "none",
                  borderRadius: "6PX",
                  marginBottom: "10PX",

                  transition: "0.1s ease-in-out",
                  "&:before": {
                    display: "none !important",
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    color: "#142C44",
                    backgroundColor: paymentType?.includes(item?.paymentType)
                      ? "#F8FAFC"
                      : "transparent",
                    borderRadius: "6px 6px 0px 0",
                    border: "none",
                  }}
                  // expandIcon={<img src={accordionDropdown} alt="edit" />}
                  expandIcon={null}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={styles.payTypeAccordionHead}>
                    <div className={styles.payTypeAccordionHeadLeft}>
                      <img
                        src={
                          paymentType?.includes(item?.paymentType)
                            ? accordionSelect
                            : accordionUnselect
                        }
                        alt="select"
                      />
                    </div>
                    <div className={styles.payTypeAccordionHeadRight}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                          marginBottom: "6PX",
                        }}
                      >
                        <div className={styles.payTypeAccordionHeadName}>
                          {item?.paymentType?.toLowerCase()}
                        </div>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            setPayTypeOpen(true);
                            setPayTypeDetails(item?.paymentType);
                          }}
                          className={styles.moreDetailsBtn}
                        >
                          More Details
                          <img src={rightArrowIcon} alt="rightArrowIcon" />
                        </button>
                      </div>

                      {item?.paymentType === "REGULAR" ? (
                        <div className={styles.payTypeAccordionHeadDesc}>
                          Regular payment mode can be used when the course has a
                          fixed total price.
                        </div>
                      ) : item?.paymentType === "MEMBERSHIP" ? (
                        <div className={styles.payTypeAccordionHeadDesc}>
                          Membership mode can be used when a fixed course fee is
                          collected at regular intervals.
                        </div>
                      ) : (
                        <div className={styles.payTypeAccordionHeadDesc}>
                          Session mode can be used when a fixed course fee is
                          collected at regular sessions.
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  className={styles.summaryDesc}
                  sx={{
                    borderRadius: "0 0 6px 6px",
                    border: "none",
                  }}
                >
                  {item?.paymentType === "REGULAR" ? (
                    <div className={styles.paymentTypeBoxBody}>
                      <Regular
                        regularPrice={regularPrice}
                        setRegularPrice={setRegularPrice}
                        setRegularPriceError={setRegularPriceError}
                        setEnableInstallment={setEnableInstallment}
                        regularPriceError={regularPriceError}
                        enableInstallment={enableInstallment}
                      />
                    </div>
                  ) : item?.paymentType === "MEMBERSHIP" ? (
                    <div className={styles.paymentTypeBoxBody}>
                      <Membership
                        rows={rows}
                        setRows={setRows}
                        setSelectedEmiModes={setSelectedEmiModes}
                        selectedEmiModes={selectedEmiModes}
                      />
                    </div>
                  ) : (
                    <div className={styles.paymentTypeBoxBody}>
                      <Session
                        sessionRows={sessionRows}
                        setSessionRows={setSessionRows}
                        selectedSessionModes={selectedSessionModes}
                        setSelectedSessionModes={setSelectedSessionModes}
                      />
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          ))}
          <div className={styles.editSectionBtnWrap}>
            <CancelBtn onClick={() => cancelBtnClick()} />
            <SaveBtn
              onClick={
                paymentType?.includes("REGULAR")
                  ? handleRegularPayment
                  : paymentType?.includes("MEMBERSHIP")
                  ? handleMembershipPayment
                  : handleSessionPayment
              }
              disabled={
                loading_updateCourse ||
                (paymentType?.includes("REGULAR")
                  ? regularPriceError || parseFloat(regularPrice) <= 0
                  : paymentType?.includes("MEMBERSHIP")
                  ? hasEmptyPaymentMode || !hasPriceNotGreaterThanZero
                  : !hasSessionPriceGreaterThanZero || hasEmptySessionMode)
              }
              loader={loading_updateCourse}
            />
          </div>
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={payTypeOpen}
        onClose={() => {
          setPayTypeOpen(false);
          setPayTypeDetails("");
        }}
        sx={{ height: "100%", zIndex: 99999 }}
      >
        <button
          className={styles.moreDetailsCloeBtn}
          onClick={() => {
            setPayTypeOpen(false);
            setPayTypeDetails("");
          }}
        >
          x
        </button>
        <div style={{ maxWidth: "350PX", overflow: "hidden" }}>
          {payTypeDetails === "REGULAR" ? (
            <>
              <div className={styles.moreDetailsTitle}>Regular</div>

              <div className={styles.payTypeAccordionDescWrap}>
                <div className={styles.payTypeAccordionDescription}>
                  Regular payment mode can be used when the course has a fixed
                  total price. It allows flexibility in payment options:
                </div>
                <div className={styles.payTypeAccordionDescTitle}>
                  Total Payment
                </div>
                <div className={styles.payTypeAccordionDescription}>
                  The entire course fee can be paid partially or at once.
                </div>
                <div className={styles.payTypeAccordionDescTitle}>
                  EMIs (Equal Monthly Installments)
                </div>
                <div className={styles.payTypeAccordionDescription}>
                  The total course fee can be divided into fixed or custom
                  installments. 
                </div>
                <div className={styles.payTypeAccordionDescription}>
                  At any point during the payment schedule, students can switch
                  from EMIs to paying the total amount due. This mode is suited
                  for courses where students may prefer either upfront payment
                  or spreading payments over time, offering them the freedom to
                  choose the most convenient payment method.
                </div>
              </div>
            </>
          ) : payTypeDetails === "MEMBERSHIP" ? (
            <>
              <div className={styles.moreDetailsTitle}>Membership</div>
              <div className={styles.payTypeAccordionDescWrap}>
                <div className={styles.payTypeAccordionDescription}>
                  Membership mode can be used when a fixed course fee is
                  collected at regular intervals (e.g., monthly, quarterly,
                  half-yearly, yearly) and is ideal for ongoing services where
                  students pay scheduled amounts throughout their enrollment
                  period.
                </div>
                <div className={styles.payTypeAccordionDescTitle}>
                  Payment Frequency:
                </div>
                <div className={styles.payTypeAccordionDescription}>
                  Students can choose intervals (monthly, quarterly, etc.) for
                  regular payments during enrollment. Please note that only the
                  Frequencies selected here will be available in the Lead card. 
                </div>
                <div className={styles.payTypeAccordionDescTitle}>
                  Pause and Resume:
                </div>
                <div className={styles.payTypeAccordionDescription}>
                  Students can pause and resume their membership at any time
                  during the batch duration providing flexibility to manage
                  payments.
                </div>
                <div className={styles.payTypeAccordionDescription}>
                  At any point during the payment schedule, students can switch
                  between frequencies. These features enhance flexibility and
                  convenience for students, enabling them to manage their course
                  payments according to their individual needs and
                  circumstances.
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.moreDetailsTitle}>Session</div>
              <div className={styles.payTypeAccordionDescWrap}>
                <div className={styles.payTypeAccordionDescription}>
                  Welcome to the Sessions module, a powerful tool designed to
                  facilitate interactive and engaging learning experiences on
                  our education platform. This feature enables educators to
                  create, manage, and deliver one to one sessions and meetings,
                  fostering collaboration and personalized instruction.
                </div>
                <div className={styles.payTypeAccordionDescTitle}>
                  Key Features:
                </div>
                <ul
                  style={{
                    listStyle: "decimal",
                    color: "#667085",
                    fontSize: "13px",
                    lineHeight: " 22px",
                    paddingLeft: "20px",
                  }}
                >
                  <li>
                    Create and manage schedules for classes, workshops, or
                    meetings.
                  </li>
                  <li>
                    Organize students into classes, assign instructors, and
                    track attendance.
                  </li>
                  <li> Assign physical or virtual rooms for sessions.</li>
                  <li>
                    Manage equipment, materials, or software required for
                    sessions.
                  </li>
                  <li>Efficient session management and Data-driven insights</li>
                </ul>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default CoursePayment;
