import { Box, Button, InputLabel, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import styles from "./OzonetelDetails.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ozonetel from "../Assets/ozonetel.svg";
import help from "../Assets/help.svg";
import UsernamePopup from "./HelpPopup/UsernamePopup";
import ApiKeyPopup from "./HelpPopup/ApiKeyPopup";
import CallbackUrlPopup from "./HelpPopup/CallbackUrlPopup";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import {
  resetAgentStatuses,
  resetDeactivateConnectStatuses,
  resetOzonetelConnectStatuses,
} from "../../service/IntegrationsSlice";
import { useDispatch } from "react-redux";
import AddAgents from "./AddAgents";
import AgentsListingComponent from "./AgentsListing/AgentsListingComponent";
import tickIcon from "../Assets/tickozonetel.svg";
import ConnectSuccessPopup from "./ConnectSuccessPopup";

function OzonetelStages(props: any) {
  const {
    ConnectOzonetel,
    GetStaffList,
    GetInstituteById,
    DeactivateOzonetel,
    GetAppsByInstitute,
    ValidateAgentId,
    AddOzonetelAgents,
    UpdateOzonetelAgents,

    appsList,
    validatedAgent,

    ozonetelConnectSuccess,
    ozonetelConnectError,
    ozonetelConnectLoading,
    deactivateOzonetelSuccess,
    deactivateOzonetelError,
    agentUpdatedSuccess,
    agentUpdatedError,
    agentAddedSuccess,
    agentAddedError,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const authUser = auth();
  const instituteDetails = authUser?.institute?.[0]?.instituteDetails;
  const instituteFromUser = authUser?.institute?.[0];
  const ozonetelCallbackUrl = process.env.REACT_APP_OZONETEL_CALLBACK_URL || "";
  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const hasOzonetel =
    instituteFromRedux &&
    Array?.isArray(instituteFromRedux?.integrationsOpted) &&
    instituteFromRedux?.integrationsOpted?.includes("OZONETEL");

  const employees = useSelector((state: any) => state.staff.staffList);
  const ozonetelEmployees = employees?.filter(
    (item: any) => item.ozonetelConfig?.agentId !== ""
  );

  const ozonetelConfigEmployees = employees?.filter(
    (item: any) => item.ozonetelConfig
  );

  const [ozonetelDetails, setOzonetelDetails] = React.useState({
    appName: "OZONETEL",
    appCode: "OZONETEL",
    appConfigData: {
      apiKey: "",
      userName: "",
    },
    instituteId: "",
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
  });

  const [connectionStage, setConnectionStage] = React.useState<number>(0);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateApiKey, setValidStateApiKey] = React.useState<boolean>(true);
  const [validStateUserName, setValidStateUserName] =
    React.useState<boolean>(true);
  const [isCopied, setIsCopied] = useState(false);
  const [successPopup, setSuccessPopup] = React.useState<boolean>(false);
  const handleSuccessPopupClose = () => {
    setSuccessPopup(false);
  };
  const setValidateStatusApiKey = React.useCallback((val: boolean) => {
    setValidStateApiKey(val);
  }, []);
  const setValidateStatusUserName = React.useCallback((val: boolean) => {
    setValidStateUserName(val);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      !ozonetelDetails.appConfigData.apiKey && setValidStateApiKey(false);
      !ozonetelDetails.appConfigData.userName && setValidStateUserName(false);

      ozonetelDetails.appConfigData.apiKey &&
        ozonetelDetails.appConfigData.userName &&
        ConnectOzonetel(ozonetelDetails);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (instituteDetails) {
      GetStaffList(instituteDetails?.id);
      GetInstituteById(instituteDetails?.id);
      GetAppsByInstitute(instituteDetails?.id);
      setOzonetelDetails({
        ...ozonetelDetails,
        instituteId: instituteDetails?.id,
        createdByName:
          instituteFromUser?.firstName + " " + instituteFromUser?.lastName,
        createdById: instituteFromUser?.id,
      });
    }
  }, [instituteDetails, GetStaffList, GetInstituteById, GetAppsByInstitute]);

  React.useEffect(() => {
    if (ozonetelConnectSuccess !== true && ozonetelConnectSuccess !== false) {
      if (instituteDetails) {
        GetInstituteById(instituteDetails?.id);
        GetAppsByInstitute(instituteDetails?.id);
      }
      dispatch(resetOzonetelConnectStatuses());
      setSuccessPopup(true);
    }
  }, [ozonetelConnectSuccess, GetInstituteById]);

  React.useEffect(() => {
    if (ozonetelConnectError !== true && ozonetelConnectError !== false) {
      dispatch(resetOzonetelConnectStatuses());
    }
  }, [ozonetelConnectError]);

  React.useEffect(() => {
    if (deactivateOzonetelSuccess) {
      if (instituteDetails) {
        GetInstituteById(instituteDetails?.id);
        GetStaffList(instituteDetails?.id);
        dispatch(resetDeactivateConnectStatuses());
      }
    }
  }, [deactivateOzonetelSuccess, GetInstituteById, GetStaffList]);

  React.useEffect(() => {
    if (deactivateOzonetelError !== true && deactivateOzonetelError !== false) {
      dispatch(resetDeactivateConnectStatuses());
    }
  }, [deactivateOzonetelError]);

  React.useEffect(() => {
    if (agentAddedSuccess !== true && agentAddedSuccess !== false) {
      if (instituteDetails) {
        GetStaffList(instituteDetails?.id);
      }
      dispatch(resetAgentStatuses());
    }
  }, [agentAddedSuccess, GetStaffList]);

  React.useEffect(() => {
    if (agentAddedError !== true && agentAddedError !== false) {
      dispatch(resetAgentStatuses());
    }
  }, [agentAddedError]);

  React.useEffect(() => {
    if (agentUpdatedSuccess !== true && agentUpdatedSuccess !== false) {
      if (instituteDetails) {
        GetStaffList(instituteDetails?.id);
        dispatch(resetAgentStatuses());
      }
    }
  }, [agentUpdatedSuccess, GetStaffList]);

  React.useEffect(() => {
    if (agentUpdatedError !== true && agentUpdatedError !== false) {
      dispatch(resetAgentStatuses());
    }
  }, [agentUpdatedError]);

  const [showCallbackUrlPopup, setShowCallbackUrlPopup] =
    useState<boolean>(false);
  const [showUsernamePopup, setShowUsernamePopup] = useState<boolean>(false);
  const [showApiKeyPopup, setShowApiKeyPopup] = useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleUsernameHelpClick = () => {
    setShowUsernamePopup(true);
  };

  const handleApiKeyHelpClick = () => {
    setShowApiKeyPopup(true);
  };

  const handleCallbackUrlHelpClick = () => {
    setShowCallbackUrlPopup(true);
  };

  const copyCallbackUrlToClipboard = () => {
    navigator.clipboard.writeText(ozonetelCallbackUrl);
    setIsCopied(true);

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    // Save the timeout ID for cleanup
    setTooltipTimeout(timeout);
  };

  React.useEffect(() => {
    return () => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, [tooltipTimeout]);
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          padding: "14px 16px",
          background: "#EEEEEE",
          maxHeight: "calc(100vh - 65px)",
          position: "relative",
        }}
      >
        <ConnectSuccessPopup
          isOpen={successPopup}
          onClose={handleSuccessPopupClose}
        />
        <Box
          sx={{
            overflow: "hidden",
            background: "#fff",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
            minHeight: "calc(100vh - 93px)",
            maxHeight: "calc(100vh - 93px)",
          }}
        >
          <div
            className={styles.ozonetelDetailsSplit}
            style={{
              minHeight: "calc(100vh - 93px)",
              maxHeight: "calc(100vh - 93px)",
            }}
          >
            <div
              style={{
                width:
                  ozonetelConfigEmployees?.length === 0
                    ? "calc(100% - 430px)"
                    : "100%",
              }}
            >
              {!hasOzonetel && (
                <div className={styles.PageBackBtnWrap}>
                  <button
                    className={styles.PageBackBtn}
                    onClick={() => navigate(`/app/Integrations`)}
                  >
                    <ArrowBackIcon
                      sx={{
                        marginRight: "8px",
                        fontSize: "15PX",
                        color: "#667085",
                      }}
                    />
                    Back
                  </button>
                </div>
              )}

              <div
                className={styles.ozonetelDetailsSplitLeftBody}
                style={{
                  maxHeight: !hasOzonetel
                    ? "calc(100vh - 160px)"
                    : "calc(100vh - 120px)",
                  minHeight: "calc(100vh - 160px)",
                  overflowY: "auto",
                }}
              >
                {ozonetelConfigEmployees?.length === 0 ? (
                  <>
                    <div>
                      <img src={ozonetel} alt="ozonetel" />
                    </div>
                    <Box className={styles.ozonetelHead}>
                      Setup Ozonetel integration
                    </Box>
                    <div className={styles.setupStagesWrap}>
                      <div className={styles.setupStagesWrapItem}>
                        {!hasOzonetel ? (
                          <div
                            className={styles.setupStagesWrapItemNumber}
                            style={{
                              color: !hasOzonetel ? "#11C89B" : "#BDBFC3",
                              border: !hasOzonetel
                                ? "1px solid #11c89b"
                                : "1px solid #BDBFC3",
                            }}
                          >
                            1
                          </div>
                        ) : (
                          <div className={styles.setupStagesWrapItemIcon}>
                            <img src={tickIcon} alt="tickIcon" />
                          </div>
                        )}

                        <div
                          className={styles.setupStagesWrapItemLabel}
                          style={{
                            color: !hasOzonetel ? "#3F3E43" : "#BDBFC3",
                          }}
                        >
                          Authentication
                        </div>
                      </div>
                      <div className={styles.setupStagesWrapItemLine}></div>
                      <div className={styles.setupStagesWrapItem}>
                        {ozonetelConfigEmployees?.length === 0 ? (
                          <div
                            className={styles.setupStagesWrapItemNumber}
                            style={{
                              color: hasOzonetel ? "#11C89B" : "#BDBFC3",
                              border: hasOzonetel
                                ? "1px solid #11c89b"
                                : "1px solid #BDBFC3",
                            }}
                          >
                            2
                          </div>
                        ) : (
                          <div className={styles.setupStagesWrapItemIcon}>
                            <img src={tickIcon} alt="tickIcon" />
                          </div>
                        )}

                        <div
                          className={styles.setupStagesWrapItemLabel}
                          style={{
                            color: hasOzonetel ? "#3F3E43" : "#BDBFC3",
                          }}
                        >
                          Agent IDs
                        </div>
                      </div>
                    </div>
                    <Box
                      sx={{
                        maxWidth: "75%",
                        margin: "0 auto 20px auto",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {!hasOzonetel ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <InputLabel
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#667085",
                                fontFamily: "Poppins",
                                fontSize: "13px",
                                fontWeight: "400",
                                lineHeight: "22px",
                                textTransform: "capitalize",
                              }}
                            >
                              Callback URL{" "}
                              <div
                                onClick={handleCallbackUrlHelpClick}
                                style={{ display: "flex", marginLeft: "2px" }}
                              >
                                <img
                                  src={help}
                                  alt="help"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </InputLabel>

                            <div
                              style={{
                                borderRadius: "6px",
                                border: "1px solid #EAEAEA",
                                background: "#F1F5F8",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  color: "#667085",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  lineHeight: "26px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {ozonetelCallbackUrl}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <button
                                  style={{
                                    background: "#FFF",
                                    borderRadius: "4px",
                                    border: "1px solid #F0F0F0",
                                    cursor: "pointer",
                                    padding: "10px",
                                    color: "#667085",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    lineHeight: "18px",
                                    height: "36px",
                                    width: "70px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={copyCallbackUrlToClipboard}
                                >
                                  Copy
                                </button>
                                {isCopied && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "40px",
                                      left: "6px",
                                      background: "rgb(122 122 122 / 70%)",
                                      color: "#fff",
                                      padding: "6px 8px",
                                      borderRadius: "3px",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Copied
                                  </div>
                                )}
                              </div>
                            </div>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              gap: "20px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <InputLabel
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#667085",
                                  fontFamily: "Poppins",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                  textTransform: "capitalize",
                                }}
                              >
                                Username{" "}
                                <span style={{ color: "#EC3939" }}>*</span>
                                <div
                                  style={{ display: "flex", marginLeft: "2px" }}
                                  onClick={handleUsernameHelpClick}
                                >
                                  <img
                                    src={help}
                                    alt="help"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </InputLabel>
                              <NewFormTextInput
                                validate={true}
                                setValidStatus={setValidateStatusUserName}
                                type={"text"}
                                id="userName"
                                placeholder="Enter username"
                                value={ozonetelDetails?.appConfigData?.userName}
                                onChange={(event: any) => {
                                  setOzonetelDetails({
                                    ...ozonetelDetails,
                                    appConfigData: {
                                      ...ozonetelDetails.appConfigData,
                                      userName: event.target.value,
                                    },
                                    // [event.target.id]: event.target.value.replace(
                                    //   /\d/,
                                    //   ""
                                    // ),
                                  });
                                }}
                                error={!validStateUserName}
                                showErrorMessage={showErrMsz}
                                errorType={errorType}
                                autoComplete="true"
                                // require={true}
                                // inputProps={{ maxLength: 20 }}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              <InputLabel
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#667085",
                                  fontFamily: "Poppins",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                  textTransform: "capitalize",
                                }}
                              >
                                API Key
                                <span style={{ color: "#EC3939" }}>*</span>
                                <div
                                  style={{ display: "flex", marginLeft: "2px" }}
                                  onClick={handleApiKeyHelpClick}
                                >
                                  <img
                                    src={help}
                                    alt="help"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </InputLabel>
                              <NewFormTextInput
                                validate={true}
                                setValidStatus={setValidateStatusApiKey}
                                type={"text"}
                                id="apiKey"
                                placeholder="Enter API key"
                                value={ozonetelDetails?.appConfigData?.apiKey}
                                onChange={(event: any) => {
                                  setOzonetelDetails({
                                    ...ozonetelDetails,
                                    appConfigData: {
                                      ...ozonetelDetails.appConfigData,
                                      apiKey: event.target.value,
                                    },
                                  });
                                }}
                                error={!validStateApiKey}
                                showErrorMessage={showErrMsz}
                                errorType={errorType}
                                autoComplete="true"
                                // require={true}
                                // inputProps={{ maxLength: 20 }}
                              />
                            </Box>
                          </Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "20px",
                              marginBottom: "20px",
                              width: "100%",
                            }}
                          >
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                maxWidth: "100%",
                                mainWidth: "100%",
                                "&.Mui-disabled": {
                                  backgroundColor: "#11C89B ",
                                  color: "#fff",
                                },
                              }}
                              onClick={handleSubmit}
                              // disabled={
                              //   ozonetelDetails?.appConfigData?.userName ===
                              //     "" ||
                              //   ozonetelDetails?.appConfigData?.apiKey === "" ||
                              //   ozonetelConnectLoading
                              // }
                              disabled={ozonetelConnectLoading}
                            >
                              {ozonetelConnectLoading ? (
                                <CircularProgress size={20} color={"inherit"} />
                              ) : (
                                "Continue"
                              )}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <AddAgents
                            employees={employees}
                            ValidateAgentId={ValidateAgentId}
                            appsList={appsList}
                            validatedAgent={validatedAgent}
                            instituteDetails={instituteDetails}
                            AddOzonetelAgents={AddOzonetelAgents}
                          />
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <div style={{ padding: "20px 20px" }}>
                      <AgentsListingComponent
                        employees={employees}
                        appsList={appsList}
                        DeactivateOzonetel={DeactivateOzonetel}
                        ValidateAgentId={ValidateAgentId}
                        instituteDetails={instituteDetails}
                        UpdateOzonetelAgents={UpdateOzonetelAgents}
                        ozonetelEmployees={ozonetelEmployees}
                        AddOzonetelAgents={AddOzonetelAgents}
                        ozonetelConfigEmployees={ozonetelConfigEmployees}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {showApiKeyPopup && (
              <ApiKeyPopup
                isOpen={showApiKeyPopup}
                onClose={() => setShowApiKeyPopup(false)}
              />
            )}
            {showUsernamePopup && (
              <UsernamePopup
                isOpen={showUsernamePopup}
                onClose={() => setShowUsernamePopup(false)}
              />
            )}
            {showCallbackUrlPopup && (
              <CallbackUrlPopup
                isOpen={showCallbackUrlPopup}
                onClose={() => setShowCallbackUrlPopup(false)}
              />
            )}
            {ozonetelConfigEmployees?.length === 0 && (
              <>
                {" "}
                <div
                  className={styles.ozonetelDetailsSplitRight}
                  style={{
                    width: "430px",
                    maxHeight: "calc(100vh - 94px)",
                    overflowY: "auto",
                  }}
                >
                  <div className={styles.ozonetelDetailsSplitRightHead}>
                    Setup Guide
                  </div>
                  <div className={styles.ozonetelDetailsSplitRightSubHead}>
                    Login to your Ozonetel Admin account
                  </div>

                  <div className={styles.ozoneSetupGuideItem}>
                    <div className={styles.ozoneSetupGuideItemLeft}>
                      <div
                        className={styles.ozoneSetupGuideItemStepCount}
                        style={{
                          border: !hasOzonetel
                            ? "1px solid #11C89B"
                            : "1px solid #DEDEDE",
                          color: !hasOzonetel ? " #11C89B" : " #DEDEDE",
                        }}
                      >
                        1
                      </div>
                      <div
                        className={styles.ozoneSetupGuideItemStepBorder}
                        style={{
                          background: hasOzonetel
                            ? " rgb(222 222 222 / 38%)"
                            : " #DEDEDE",
                        }}
                      ></div>
                    </div>

                    <div className={styles.ozoneSetupGuideItemRight}>
                      <div
                        className={styles.ozoneSetupGuideItemRightTitle}
                        style={{
                          color: hasOzonetel ? " #DEDEDE" : " #142C44",
                        }}
                      >
                        Step 1: Authentication
                      </div>
                      <div
                        className={styles.ozoneSetupGuideItemRightSubTitle}
                        style={{
                          color: hasOzonetel ? " #BDBFC3" : " #142C44",
                        }}
                      >
                        Callback URL
                      </div>
                      <ul
                        className={styles.loginSteps}
                        style={{
                          color: hasOzonetel ? " #BDBFC3" : " #667085",
                        }}
                      >
                        <li>
                          Click on Profile icon on top right corner and Select
                          Edit Profile.
                        </li>
                        <li>
                          Scroll down to System Settings where you can find the
                          Callback URL field.
                        </li>
                        <li>Paste our Callback URL there and save.</li>
                      </ul>

                      <div
                        className={styles.ozoneSetupGuideItemRightSubTitle}
                        style={{
                          color: hasOzonetel ? " #BDBFC3" : " #142C44",
                        }}
                      >
                        Username and API key
                      </div>
                      <ul
                        className={styles.loginSteps}
                        style={{
                          color: hasOzonetel ? " #BDBFC3" : " #667085",
                        }}
                      >
                        <li>
                          Click on Profile icon on top right corner and Select
                          Edit Profile.
                        </li>
                        <li>
                          Under Edit User section, copy the User name and API
                          Key.
                        </li>
                        <li>
                          Paste the Username & API Key in the respective fields
                          here.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.ozoneSetupGuideItem}>
                    <div className={styles.ozoneSetupGuideItemLeft}>
                      <div
                        className={styles.ozoneSetupGuideItemStepCount}
                        style={{
                          border: hasOzonetel
                            ? "1px solid #11C89B"
                            : "1px solid #DEDEDE",
                          color: hasOzonetel ? "#11C89B" : "#DEDEDE",
                        }}
                      >
                        2
                      </div>
                      {hasOzonetel && (
                        <div
                          className={styles.ozoneSetupGuideItemStepBorder}
                        ></div>
                      )}
                    </div>
                    <div className={styles.ozoneSetupGuideItemRight}>
                      <div
                        className={styles.ozoneSetupGuideItemRightTitle}
                        style={{
                          color: hasOzonetel ? "#323B4B" : "#DEDEDE",
                        }}
                      >
                        Step 2: Agent IDs
                      </div>
                      <ul
                        className={styles.loginSteps}
                        style={{
                          color: hasOzonetel ? "#142C44" : "#BDBFC3",
                        }}
                      >
                        <li>
                          Click on Configuration on top level menu and select
                          Agents.
                        </li>
                        <li>Copy the Agent ID and paste it here.</li>
                        <li>Map the User against each Agent ID.</li>
                        <li>To add more agents, click on Add another Agent.</li>
                        <li>
                          At least one agent is required to Integrate Ozonetel
                          with nurture.
                        </li>
                        <li>
                          Only one user can be mapped against one Agent ID.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
}

export default OzonetelStages;
