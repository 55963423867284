import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  InputLabel,
  Avatar,
  CircularProgress,
} from "@mui/material";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import Previews from "../../InstituteProfile/DropZoneFile";
import { FiEdit } from "react-icons/fi";
import { UserDeatailsProps } from "../container/UserDetailsContainer";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import CountryCodePhoneInput from "../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import UserProfileBoxSkeleton from "../../../../../components/Skeletons/UserProfileBoxSkeleton";
import { getUserById } from "../service/userDetailsSlice";
import { getStaffList } from "../../ManageStaff/service/Staffslice";
import { getNurtureLeadBoard } from "../../../Nurture/service/nurtureSlice";

function UserDetails(props: UserDeatailsProps) {
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { userById } = useSelector((state: any) => state.userDetails);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [instituteName, setInstituteName] = React.useState<boolean>(true);
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStateContact, setValidStateContact] =
    React.useState<boolean>(true);

  const [userDetails, setUserDetails] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    contact: "",
    role: "",
    branchName: "",
    branchId: "",
    instituteId: "",
    instituteName: "",
    status: "ACTIVE",
    isFirstTeamMember: false,
    id: "",
    createdUser: "",
    createdUserId: "",
    userProfileImage: "",
    contactCountryCode: "91",
  });
  const [role, setRole] = useState<string>("");
  const [logoDetails, setLogoDetails] = React.useState<any>({
    fileBase64String: "",
    fileName: "",
    fileType: "USER_PROFILE_IMAGE",
    instituteId: "",
    userId: "",
  });
  const [image, setImage] = React.useState<any>("");
  const [instituteFormDisabled, setInstituteFormDisabled] =
    React.useState<boolean>(false);
  const [handle, setHandle] = useState<boolean>(false);

  const setValidateInstituteName = React.useCallback((val: boolean) => {
    setInstituteName(val);
  }, []);

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const handleImageUpload = React.useCallback(
    async (cropImage: any, image: any) => {
      handleLogoUpload(cropImage, image.name);
    },
    [logoDetails]
  );

  const handleLogoUpload = React.useCallback(
    async (imageUrl: any, fileName: any) => {
      if (imageUrl) {
        setLogoDetails({
          ...logoDetails,
          fileBase64String: imageUrl,
        });
        handleImageSubmit(fileName, imageUrl);
      }
    },
    [setImage, logoDetails]
  );

  const handleImageSubmit = React.useCallback(
    (fileName: any, convertedFile: any) => {
      let logo_Detail = {
        fileBase64String: convertedFile,
        fileName: "User",
        fileType: logoDetails?.fileType,
        instituteId: logoDetails?.instituteId,
        userId: logoDetails?.userId,
      };
      setLogoDetails(logo_Detail);
      try {
        logo_Detail.fileBase64String !== "" &&
          props?.UserImageUpload(logo_Detail);
      } catch (error) {}
    },
    [logoDetails]
  );

  const imgUrl = useSelector(
    (state: any) =>
      state.fileUpload.imageUrl &&
      props?.fileUploadSuccess &&
      state.fileUpload.imageUrl
  );

  const handleSumit = () => {
    props?.SaveUserDetails(userDetails);
    setHandle(true);
  };

  React.useEffect(() => {
    if (props?.fileUploadSuccess) {
      if (imgUrl.fileType === "USER_PROFILE_IMAGE") {
        setUserDetails({
          ...userDetails,
          userProfileImage: imgUrl.location,
        });
      }
    }
  }, [props?.fileUploadSuccess]);

  useEffect(() => {
    dispatch<any>(getUserById(authUser?.institute?.[0]?.id));
  }, []);

  React.useEffect(() => {
    if (props?.updateUserDetailsSuccess && handle) {
      setInstituteFormDisabled(false);
      setHandle(false);
      dispatch<any>(getUserById(authUser?.institute?.[0]?.id));
      dispatch<any>(getStaffList(authUser?.institute?.[0]?.instituteId));
      dispatch<any>(getNurtureLeadBoard(authUser?.institute?.[0]?.instituteId));
    }
  }, [props?.updateUserDetailsSuccess]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    if (userById && userFromLocalStorage) {
      setUserDetails({
        ...userDetails,
        email: userById?.email,
        contact: `${userById?.contactCountryCode || "91"}${
          userById?.contactCountryCode
            ? userById?.contact.replace(userById?.contactCountryCode, "")
            : userById?.contact.startsWith("91")
            ? userById?.contact.replace("91", "")
            : userById?.contact
        }`,
        firstName: userById?.firstName,
        lastName: userById?.lastName,
        userProfileImage: userById?.userProfileImage,
        instituteId: userFromLocalStorage?.id,
        instituteName: userFromLocalStorage?.instituteName,
        isFirstTeamMember:
          userFromLocalStorage?.teamAdded === false ? true : false,
        createdUser:
          authUser?.institute[0] &&
          authUser?.institute[0].firstName +
            " " +
            authUser?.institute[0].lastName,
        createdUserId: authUser?.institute[0] && authUser?.institute[0].id,
        role: authUser?.institute[0]?.role,
        branchId: authUser?.institute[0] && authUser?.institute[0].branchId,
        branchName: authUser?.institute[0] && authUser?.institute[0].branchName,
        id: authUser?.institute[0] && authUser?.institute[0].id,
      });
      setRole(authUser?.institute[0]?.role);
      setLogoDetails({
        ...logoDetails,
        instituteId: userFromLocalStorage?.id && userFromLocalStorage?.id,
        userId: authUser?.institute && authUser?.institute[0]?.id,
      });
      setImage(`${userById?.userProfileImage}?timestamp=${Date.now()}`);
    }
  }, [userById]);
  return (
    <>
      <div
        style={{
          border: !instituteFormDisabled ? "1px solid #EBEBED" : "",
          marginTop: "20px",
          marginLeft: "22px",
          marginRight: "10px",
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        {!userDetails.id ? (
          <UserProfileBoxSkeleton />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: !instituteFormDisabled ? "20px" : "",
                borderBottom: !instituteFormDisabled ? "1px solid #EBEBED" : "",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {instituteFormDisabled ? (
                  <Previews
                    setImage={setImage}
                    handleImageUpload={handleImageUpload}
                    imgUrl={image?.charAt(0) === "h" ? image : ""}
                  />
                ) : (
                  <>
                    {image?.charAt(0) === "h" ? (
                      <img
                        src={image}
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "100px",
                          marginRight: "20px",
                        }}
                      />
                    ) : (
                      <Avatar
                        src="/broken-image.jpg"
                        sx={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "100px",
                          marginRight: "20px",
                        }}
                      />
                    )}
                  </>
                )}
                {!instituteFormDisabled && (
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "16px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "600",
                        color: "#142C44",
                        textTransform: "capitalize",
                      }}
                    >
                      {userDetails?.firstName + " " + userDetails.lastName}
                    </Typography>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "500",
                        color: "#667085",
                        textTransform: "capitalize",
                      }}
                    >
                      {role?.toLowerCase()}
                    </Typography>
                  </Box>
                )}
              </Box>
              {!instituteFormDisabled && (
                <Box>
                  <Button
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      color: "#667085",
                      textTransform: "capitalize",
                      gap: "10px",
                      background: "#fff",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setInstituteFormDisabled(true)}
                  >
                    Edit
                    <FiEdit size={14} color="#142c44" />
                  </Button>
                </Box>
              )}
            </Box>

            {instituteFormDisabled ? (
              <>
                <Box sx={{ mt: 4, paddingX: "15px" }}>
                  <Grid
                    container
                    spacing={{ lg: 2, md: 2, xs: 1, sm: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={5} lg={5.5}>
                      <NewFormTextInput
                        validate={true}
                        disabled={false}
                        onChange={(e: any) => {
                          // e.preventdefault();
                          setUserDetails({
                            ...userDetails,
                            firstName: e?.target?.value.replace(
                              /[^A-Za-z\s]/g,
                              ""
                            ),
                          });
                          setShowErrMsz(true);
                          setErrorType("client");
                        }}
                        required
                        value={userDetails.firstName}
                        id="name"
                        name="userName"
                        label="First Name"
                        variant="standard"
                        type={"text"}
                        fullWidth
                        require={true}
                        error={!instituteName}
                        setValidStatus={setValidateInstituteName}
                        showErrorMessage={showErrMsz}
                        autoComplete="true"
                        errorType={errorType}
                        inputProps={{
                          sx: {
                            "&.MuiInputBase-input.MuiOutlinedInput-input": {
                              fontWeight: "400",
                              fontSize: "12px",
                            },
                          },
                          maxLength: 1000,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} lg={5.5}>
                      <NewFormTextInput
                        disabled={false}
                        onChange={(e: any) => {
                          setUserDetails({
                            ...userDetails,
                            lastName: e?.target?.value.replace(
                              /[^A-Za-z\s]/g,
                              ""
                            ),
                          });
                          setShowErrMsz(true);
                          setErrorType("client");
                        }}
                        required
                        value={userDetails.lastName}
                        id="name"
                        name="userName"
                        label="Last Name"
                        variant="standard"
                        fullWidth
                        require={true}
                        error={!instituteName}
                        setValidStatus={setValidateInstituteName}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        inputProps={{
                          sx: {
                            "&.MuiInputBase-input.MuiOutlinedInput-input": {
                              fontWeight: "400",
                              fontSize: "12px",
                            },
                          },
                          maxLength: 1000,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <NewFormTextInput
                        disabled={true}
                        validate={true}
                        label="Email Address"
                        setValidStatus={setValidateStatusEmail}
                        type={"email"}
                        name="email"
                        value={userDetails?.email}
                        onChange={(e: any) => {
                          setUserDetails({
                            ...userDetails,
                            email: e?.target.value,
                          });
                          setShowErrMsz(true);
                          setErrorType("client");
                        }}
                        error={!validStateEmail}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        require={true}
                        inputProps={{
                          sx: {
                            "&.MuiInputBase-input.MuiOutlinedInput-input": {
                              fontWeight: "400",
                              fontSize: "12px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <InputLabel
                        sx={{
                          fontSize: "12px",
                          color: "#142C44",
                          fontWeight: "500",
                          marginBottom: "5px",
                          fontFamily: "Poppins",
                          display: "flex",
                        }}
                      >
                        Phone Number
                        <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>
                      </InputLabel>
                      <CountryCodePhoneInput
                        value={userDetails?.contact}
                        onChange={(event: any, country: any) => {
                          setUserDetails({
                            ...userDetails,
                            contact: event,
                          });
                          if (event !== "") {
                            setValidStateContact(
                              isValidPhoneNumber(
                                event.replace(country.dialCode, ""),
                                country.countryCode.toUpperCase()
                              )
                            );
                          }
                        }}
                        error={validStateContact}
                        inputProps={{
                          sx: {
                            "&.form-control.custom-input-class": {
                              fontWeight: "400",
                              fontSize: "12px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box style={{ textAlign: "end" }} sx={{ mt: 5, mr: 25 }}>
                  <Button
                    sx={{
                      width: "175px",
                      padding: "8px 20px",
                      marginRight: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="outlined"
                    type="submit"
                    disabled={false}
                    onClick={() => setInstituteFormDisabled(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      width: "175px",
                      padding: "8px 20px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    type="submit"
                    disabled={
                      !instituteName ||
                      !validStateContact ||
                      props.updateUserDetailsLoading
                    }
                    onClick={handleSumit}
                  >
                    {props.updateUserDetailsLoading ? (
                      <CircularProgress size={20} color={"inherit"} />
                    ) : (
                      "Save changes"
                    )}
                  </Button>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingX: !instituteFormDisabled ? "30px" : "",
                  paddingY: !instituteFormDisabled ? "20px" : "",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#667085",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#142C44",
                      }}
                    >
                      {userDetails?.email}
                    </Typography>
                  </Box>
                  <Box sx={{ marginLeft: "25px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#667085",
                      }}
                    >
                      Phone Number
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "13px",
                        marginTop: "10px",
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#142C44",
                      }}
                    >
                      {userDetails?.contact
                        ? `+${userDetails?.contactCountryCode || "91"} ${
                            userDetails?.contactCountryCode
                              ? userDetails?.contact.replace(
                                  userDetails?.contactCountryCode,
                                  ""
                                )
                              : userDetails?.contact.startsWith("91")
                              ? userDetails?.contact.replace("91", "")
                              : userDetails?.contact
                          }`
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default UserDetails;
