import React, { useState, useRef, useEffect } from "react";
import { Box, Avatar, TextField, InputAdornment } from "@mui/material";
import styles from "./UserAssign.module.css";
import searchIcon from "../../assets/images/search-assign.svg";

interface User {
  id: string;
  firstName: string;
  lasttName: string;
}

function UserAssignField(props: any) {
  const { staffs, selectedAssignyId, handleLeadAssignee } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [searchTerm, setSearchTerm] = useState<any>("");
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (user: User) => {
    // handleLeadAssignee(leadId, user);
    handleLeadAssignee(user);

    setSelectedUser(user);
    setIsOpen(!isOpen);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // useEffect(() => {
  //   setSelectedUser(value);
  // }, [value]);

  const filteredUsers =
    staffs &&
    staffs
      ?.filter((user: any) => {
        const searchTermMatch =
          user.firstName?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !==
          -1;
        return searchTermMatch;
      })
      .filter((user: any) => {
        return user.status === "ACTIVE";
      });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  // useEffect(() => {
  //   if (selectedAssignyId !== null && selectedAssignyId !== undefined) {
  //     const defaultAssignedTo =
  //       staffs && staffs.find((user: any) => user.id === selectedAssignyId);
  //     setSelectedUser(defaultAssignedTo);
  //   }
  // }, [setSelectedUser, selectedAssignyId, staffs]);
  return (
    <>
      <div className={styles.userAssignWrap} ref={wrapperRef}>
        <div className={styles.selectAssigneeFieldBox} onClick={toggleDropdown}>
          {selectedUser ? (
            <div className={styles.selectAssigneeFieldSelected}>
              <Avatar
                sx={{
                  width: "30px",
                  height: "30px",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  textTransform: "capitalize",
                  background: isHovered ? "#ECECEC" : "#F5F5F7",
                  border: "1.11917px solid #F5F5F7",
                  color: "#667085",
                  fontWeight: "500",
                  ":hover": "backgroundColor:red",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {selectedUser && selectedUser.firstName.charAt(0)}
              </Avatar>
              <div className={styles.selectAssigneeFieldName}>
                {selectedUser &&
                  selectedUser.firstName + " " + selectedUser.lastName}
              </div>
            </div>
          ) : (
            <div> Choose a new user</div>
          )}
        </div>

        {isOpen && (
          <div className={styles.userAssignPopup}>
            <TextField
              placeholder="Search"
              sx={{ margin: "0 0 10px 0" }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" />
                  </InputAdornment>
                ),
              }}
              value={searchTerm}
              onChange={handleSearch}
              size="small"
            />
            <ul className={styles.userAssignPopupList}>
              {staffs &&
                filteredUsers
                  .filter((item: any) => item.id !== selectedAssignyId)
                  .map((user: any) => (
                    <div key={user.id}>
                      <li className={styles.userAssignItem}>
                        <div className={styles.userAssignItemLeft}>
                          <Avatar
                            sx={{
                              width: "34px",
                              height: "34px",
                              fontSize: "16px",
                              display: "flex",
                              alignItems: "center",
                              textTransform: "capitalize",
                              fontWeight: "500",
                              background: "#F5F5F7",
                              border: "1px solid #EEEEF4",
                              color: "#667085",
                            }}
                          >
                            <Box>{user && user.firstName.charAt(0)}</Box>
                          </Avatar>
                          <div className={styles.userAssignName}>
                            {user.firstName + " " + user.lastName}
                          </div>
                        </div>
                        {user.id === selectedUser.id ? (
                          <></>
                        ) : (
                          <div
                            className={styles.userAssignSelectBtn}
                            onClick={() => {
                              handleSelect(user);
                              setIsOpen(!isOpen);
                            }}
                          >
                            assign
                          </div>
                        )}
                      </li>
                    </div>
                  ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default UserAssignField;
