import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import logo from "../../../../assets/images/logo.png";
import {
  EMAILVERIFY,
  FORMINFOTXTOTP,
  FORMINFOTXTOTPINCORRECT,
  REDENDBTN,
  RESENDAGAINTXT,
} from "../container/ForgetPasswordString";
import { verifyOTPDTO } from "../service/types";
import styles from "./ForgetPassword.module.css";

export function OtpForm(props: {
  email: string;
  onSubmit: (data: verifyOTPDTO) => void;
  onResendClick: () => void;
  loading: boolean;
  message: any;
}) {
  const [OTP, setOTP] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      if (
        // props.message === "Invalid OTP" ||
        // props.message === "OTP sent successfully" ||
        OTP.length < 4
      ) {
        setIsError(true);
      }
      props.onSubmit({ email: props.email, otp: OTP });
    },
    [OTP, props]
  );
  const [seconds, setSeconds] = useState(180);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const resendOTP = () => {
    setSeconds(180);
    props.onResendClick();
  };

  React.useEffect(() => {
    if (props.message === "Invalid OTP") {
      setIsError(true);
    }
  }, [props.message]);

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Box
        className={styles.logo_box}
        sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
      >
        <img src={logo} height="35" width="150" alt="" />
      </Box>

      <Box className={styles.formHeading}>
        <Typography
          sx={{
            fontSize: {
              lg: 30,
              md: 30,
              sm: 25,
              xs: 19,
            },
            fontFamily: "Poppins",
            fontWeight: "600",
            textTransform: "capitalize",
          }}
        >
          {EMAILVERIFY}
        </Typography>
      </Box>

      {isError ? (
        <div className={styles.formDanger}>{FORMINFOTXTOTPINCORRECT}</div>
      ) : (
        <div className={styles.formInfo}>
          {FORMINFOTXTOTP} <br /> {props.email}
        </div>
      )}

      <div className={styles.otpInputWrapper}>
        <OtpInput
          value={OTP}
          onChange={setOTP}
          numInputs={4}
          isInputNum={true}
          inputStyle={styles.inputStyle}
          focusStyle={styles.borderHover}
          hasErrored={isError}
          errorStyle={{
            border: "1px solid #D32F2F",
            borderRadius: "4px",
          }}
        />
      </div>

      <Button
        variant="contained"
        fullWidth
        type="submit"
        sx={{ textTransform: "capitalize" }}
      >
        {props.loading ? (
          <CircularProgress size={25} color={"inherit"} />
        ) : (
          "Continue"
        )}
      </Button>

      {seconds > 0 && (
        <>
          <p className={styles.count_text_main}>
            {RESENDAGAINTXT}
            <span className={styles.count_text}> {seconds} seconds</span>
          </p>
        </>
      )}

      {seconds === 0 && (
        <>
          <p className={styles.sendotpTxt}>
            <Button
              onClick={resendOTP}
              className={styles.link}
              color="secondary"
              style={{ textTransform: "capitalize" }}
            >
              {REDENDBTN}
            </Button>
          </p>
        </>
      )}
    </form>
  );
}
