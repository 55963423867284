import React from "react";
import AddBatchCustomPay from "./AddBatchCustomPay";
import EditBatchCustomPay from "./EditBatchCustomPay";
import styles from "../Managebatches.module.css";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";

function BatchCustomPayDetails(props: any) {
  const {
		batchData,
		batchPayUpdateLoading,
		batchPayUpdateSuccess,
		customPayEdit,
		setCustomPayEdit,
		customPayAdd,
		setCustomPayAdd,
		setRegularPayEdit,
		setMembershipPayEdit,
		hasBatchAccess,
		selectedCurrency,
	} = props;

	React.useEffect(() => {
		if (batchPayUpdateSuccess !== true || batchPayUpdateSuccess !== false) {
			setCustomPayEdit(false);
		}
	}, [batchPayUpdateSuccess]);

	return (
		<div>
			<div className={styles.batchSecHeadSplit} style={{ marginTop: '10px' }}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					<div className={styles.batchCustomTitle}>Additional Payments</div>
				</div>
				<div>
					{batchData?.randomPayments?.length > 0 &&
						!customPayEdit &&
						!customPayAdd &&
						hasBatchAccess && (
							<div
								style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
								<button
									onClick={() => {
										setCustomPayAdd(true);
										setMembershipPayEdit(false);
										setRegularPayEdit(false);
									}}
									className={styles.addRandomBtn}>
									+ Add
								</button>
								<EditIconBtn
									onClick={() => {
										setCustomPayEdit(true);
										setMembershipPayEdit(false);
										setRegularPayEdit(false);
									}}
								/>
							</div>
						)}
				</div>
			</div>
			{batchData?.randomPayments?.length > 0 ? (
				<>
					{customPayAdd ? (
						<AddBatchCustomPay
							batchData={batchData}
							loader={batchPayUpdateLoading}
							success={batchPayUpdateSuccess}
							existingRandomPays={batchData?.randomPayments}
							setCustomPayAdd={setCustomPayAdd}
						/>
					) : (
						<EditBatchCustomPay
							customPayEdit={customPayEdit}
							setCustomPayEdit={setCustomPayEdit}
							batchData={batchData}
							loader={batchPayUpdateLoading}
							success={batchPayUpdateSuccess}
							hasBatchAccess={hasBatchAccess}
							selectedCurrency={selectedCurrency}
						/>
					)}
				</>
			) : (
				<>
					{customPayAdd ? (
						<AddBatchCustomPay
							batchData={batchData}
							loader={batchPayUpdateLoading}
							success={batchPayUpdateSuccess}
							existingRandomPays={batchData?.randomPayments}
							setCustomPayAdd={setCustomPayAdd}
						/>
					) : (
						<div className={styles.noBatchTimingContainer}>
							<div className={styles.noDetailsText}>
								No additional payments yet!
							</div>
							{hasBatchAccess && (
								<div
									className={styles.noSchedulesText}
									style={{ borderRadius: '4PX' }}
									onClick={() => {
										setCustomPayAdd(true);
										setMembershipPayEdit(false);
										setRegularPayEdit(false);
									}}>
									+ Add
								</div>
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
}

export default BatchCustomPayDetails;
