import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../Inbound/components/ImportModal/ImportModal.module.css";
import { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePicker from "@mui/lab/DatePicker";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};
const HolidaysModel = ({
  open,
  setOpenHolidaysModel,
  yesButtonClick,
  noButtonClick,
  selectedValue,
  setSelectedValue,
  title = "Exempt Indian Holidays",
}: any) => {
  const [holidays, setHolidays] = useState<any>([
    { date: "2023-12-25", name: "Christmas Day" },
    { date: "2023-11-26", name: "Thanksgiving" },
  ]);
  const [newHoliday, setNewHoliday] = useState({
    date: null,
    name: "",
  });

  const handleDelete = (index: any) => {
    const updatedHolidays = holidays.filter((_: any, i: any) => i !== index);
    setHolidays(updatedHolidays);
  };

  const handleAddHoliday = () => {
    if (newHoliday.date && newHoliday.name) {
      setHolidays([...holidays, newHoliday]);
      setNewHoliday({ date: null, name: "" });
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpenHolidaysModel(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div
            onClick={() => {
              setOpenHolidaysModel(false);
            }}
            className={styles.closeModalButton}
          >
            <CloseIcon />
          </div>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalDes}>
            <div>
              <TableContainer component={Paper} sx={{ padding: "8px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Day</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Holiday</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {holidays.map((holiday: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell>
                          {new Date(holiday.date).toLocaleDateString("en-US", {
                            weekday: "long",
                          })}
                        </TableCell>
                        <TableCell>{holiday.date}</TableCell>
                        <TableCell>{holiday.name}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDelete(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                variant="contained"
                color="primary"
                onClick={handleAddHoliday}
              >
                Add Holiday
              </Button>

              {newHoliday.date === null ? (
                <div>
                  <DatePicker
                    value={newHoliday.date}
                    onChange={(newDate: any) =>
                      setNewHoliday({ ...newHoliday, date: newDate })
                    }
                    renderInput={(params: any) => (
                      <TextField {...params} label="Select Date" />
                    )}
                  />
                  <TextField
                    label="Holiday Name"
                    value={newHoliday.name}
                    onChange={(e) =>
                      setNewHoliday({ ...newHoliday, name: e.target.value })
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className={styles.buttonSection}>
            <button onClick={noButtonClick} className={styles.noButton}>
              Cancel
            </button>
            <button
              onClick={yesButtonClick}
              style={{
                background: "#11c89b",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "4px",
                padding: "10px 18px",
                gap: "8px",
                width: "100%",
                color: " #ffffff",
                border: "0",
                cursor: "pointer",
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default HolidaysModel;
