import React, { useState, useEffect } from "react";
import { Box, Dialog, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../RenameFormPopup/RenameFormPopup.module.css";
import { useAuthUser } from "react-auth-kit";
import { Navigate, useLocation, useNavigate } from "react-router";

const ButtonRenamePopup = (props: any) => {
  const {
    showButtonRenamePopup,
    onClose,
    setUpdatedButtonName,
    updatedButtonName,
  } = props;

  const handleFormNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedButtonName(event.target.value);
  };

  const handleSave = () => {
    setUpdatedButtonName(updatedButtonName);
    onClose(); // Close the dialog after save
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={showButtonRenamePopup}
      onClose={onClose}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "400px",
          maxHeight: "290px",
          minHeight: "165px",
          padding: "24px",
          borderRadius: "12px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "8%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#555555" }} onClick={handleClose} />
        </div>
        <div className={styles.modalTitle}>Enter Button Name</div>
        <TextField
          variant="outlined"
          value={updatedButtonName}
          onChange={handleFormNameChange}
          placeholder="Enter Button name"
          fullWidth
          inputProps={{
            style: {
              padding: "10px 12px",
              backgroundColor: "#FFF",
              borderRadius: "4px",
            },
          }}
        />
        <div className={styles.buttonSection}>
          <Button
            variant="outlined"
            sx={{
              color: "#BFBFBF",
              fontWeight: "500",
              lineHeight: "10px 24px 24px 24px",
              textTransform: "capitalize",
              borderRadius: "4px",
              border: "1px solid #BFBFBF",
              backgroundColor: "#FFF",
              "&:hover": {
                backgroundColor: "#FFF",
                border: "1px solid #BFBFBF",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default ButtonRenamePopup;
