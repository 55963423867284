// EmailFrom
export const PASSWORDTXT = "Forgot Password?";
export const FORMINFOTXT =
  "Don’t worry. We will send you a link to help you reset your password.";
export const POLICYTEXT = "By creating an account, you agree to nurture’s";
export const TERMS = "Terms of Service";
export const PRIVACY = "Privacy Policy";
//OtpFrom
export const EMAILVERIFY = "Please verify your email";
export const FORMINFOTXTOTP =
  "Enter the 4-digit code sent on your email address ";
export const FORMINFOTXTOTPINCORRECT =
  "Incorrect code. Please verify the 4 digit code sent on your email.";
export const RESENDAGAINTXT = "Resend code in";
export const REDENDBTN = "Resend";
//Onbord
export const SETUPPASSWORD = "Setup your Password";
