import {
  Grid,
  Box,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styles from "../..//Managebatches.module.css";
import moment from "moment";
import dayjs from "dayjs";

export default function DemoWeekTimings(props: any) {
  const {
    batchSchedule,
    batchDetails,
    setBatchDetails,
    formDisabled,
    add,
    setIsDateChanged,
  } = props;
  const [disabledFields, setDisabledFields] = useState(
    new Array(batchSchedule.length).fill(true)
  );
  useEffect(() => {
    const defaultDisabledArray = disabledFields;
    batchSchedule.map((obj: any, key: number) => {
      if (
        (typeof obj.startTime === "string"
          ? parseInt(obj.startTime)
          : obj.startTime) === 0
      ) {
        defaultDisabledArray[key] = true;
      } else {
        defaultDisabledArray[key] = false;
      }
    });
  }, [formDisabled]);

  const handleButtonClick = React.useCallback(
    (index: number) => {
      const newDisabledFields = [...disabledFields];
      newDisabledFields[index] = !newDisabledFields[index];

      setDisabledFields(newDisabledFields);

      const newBatchSchedule = [...batchSchedule];
      const itemToUpdate = {
        ...newBatchSchedule[index],
      };

      if (!newDisabledFields[index]) {
        const now = moment().unix();
        itemToUpdate.startTime = now;

        itemToUpdate.endTime = now;
      } else {
        itemToUpdate.startTime = 0;
        itemToUpdate.endTime = 0;
      }
      newBatchSchedule[index] = itemToUpdate;
      setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
    },
    [batchDetails, disabledFields, batchSchedule, setBatchDetails]
  );

  const handleTimeChange = React.useCallback(
    (event: any, index: number, key: string) => {
      const unixTime = event.unix();

      const newBatchSchedule = [...batchDetails.batchSchedule];
      const updatedItem = {
        ...newBatchSchedule[index],
        [key]: unixTime,
      };
      newBatchSchedule[index] = updatedItem;
      setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
      setIsDateChanged(true);
    },
    [batchDetails, setBatchDetails]
  );

  return (
    <div>
      {batchSchedule &&
        batchSchedule.map((item: any, index: any) => {
          return (
            <div className={styles.scheduleItemsWrap} key={item.day + index}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Box>
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {add && (
                          <TimePicker
                            onChange={(event) => {
                              handleTimeChange(event, index, "startTime");
                            }}
                            value={
                              item.startTime !== 0
                                ? dayjs.unix(item.startTime)
                                : dayjs().add(5, "minutes")
                            }
                            sx={{ width: "157px" }}
                            closeOnSelect={false}
                          />
                        )}
                        {!add && (
                          <TimePicker
                            onChange={(event) => {
                              handleTimeChange(event, index, "startTime");
                            }}
                            value={
                              item.startTime !== 0
                                ? dayjs.unix(item.startTime)
                                : dayjs()
                            }
                            sx={{ width: "157px" }}
                            closeOnSelect={false}
                          />
                        )}
                      </LocalizationProvider>
                    </FormControl>
                  </Box>
                </div>

                <div style={{ margin: "0px 10px" }}>
                  <p style={{ color: "#C7C7C7", fontFamily: "Poppins" }}>to</p>
                </div>
                <div>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {add && (
                        <TimePicker
                          onChange={(event) => {
                            handleTimeChange(event, index, "endTime");
                          }}
                          value={
                            item.endTime !== 0
                              ? dayjs.unix(item.endTime)
                              : dayjs().add(5, "minutes")
                          }
                          sx={{ width: "157px" }}
                          closeOnSelect={false}
                        />
                      )}
                      {!add && (
                        <TimePicker
                          onChange={(event) => {
                            handleTimeChange(event, index, "endTime");
                          }}
                          value={
                            item.endTime !== 0
                              ? dayjs.unix(item.endTime)
                              : dayjs()
                          }
                          sx={{ width: "157px" }}
                          closeOnSelect={false}
                        />
                      )}
                    </LocalizationProvider>
                  </Box>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
