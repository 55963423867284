import React, { useState, useEffect, useCallback } from "react";
import { Box, Dialog, Button, Tabs, Tab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./SharePopup.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import inline from "../../../../../../assets/images/inline.svg";
import widget from "../../../../../../assets/images/widget.svg";
import popup from "../../../../../../assets/images/popup.svg";
import BasicModal from "../../../../../../components/Modal/Modal";
import InlineEmbed from "./InlineEmbed";
import WidgetPopup from "./WidegtPopup";
import TextPopup from "./Textpopup";
import { useAuthUser } from "react-auth-kit";

const SharePopup = (props: any) => {
  const {
    sharingFormData,
    showSharePopup,
    onClose,
    GetWebformForInstitute,
    getFormByInstituteData,
    UpdateWebform,
    setCopied,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [copyContent, setCopyContent] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const publicScriptUrl = process.env.REACT_APP_WEBFORM_SCRIPT_URL || "";
  const [openInlineEmbed, setOpenInlineEmbed] = useState(false);
  const [openWidgtPopup, setOpenWidgtPopup] = useState(false);
  const [openTextPopup, setOpenTextPopup] = useState(false);
  const [inlineEmbedColors, setInlineEmbedColors] = useState<any>({
    bgColor: "",
    textColor: "",
    buttonTextColor: "",
    buttonBgColor: "",
    type: "inlineEmbed",
  });
  const [widgetData, setWidgetData] = useState<any>({
    bgColor: "",
    textColor: "",
    buttonText: "",
    buttonTextColor: "",
    buttonBgColor: "",
    type: "widgetEmbed",
  });
  const [popupData, setPopupData] = useState<any>({
    bgColor: "",
    textColor: "",
    linkText: "",
    buttonTextColor: "",
    buttonBgColor: "",
    type: "popupEmbed",
  });
  const auth = useAuthUser();
  const authUser = auth();
  const [webformInfo, setWebformInfo] = useState<any>();
  React.useEffect(() => {
    if (getFormByInstituteData) {
      setWebformInfo(getFormByInstituteData);
    }
  }, [getFormByInstituteData]);

  // useEffect(() => {
  //   const userFromLocalStorage = authUser?.institute[0];
  //   if (userFromLocalStorage && sharingFormData) {
  //     GetWebformForInstitute(
  //       userFromLocalStorage?.instituteId,
  //       sharingFormData?.id
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (
      inlineEmbedColors.bgColor !== "" ||
      inlineEmbedColors.textColor !== "" ||
      inlineEmbedColors.buttonTextColor !== "" ||
      inlineEmbedColors.buttonBgColor !== ""
    ) {
      UpdateWebform({
        ...webformInfo,
        uiStyling: [inlineEmbedColors, widgetData, popupData],
        embededType: "inlineEmbed",
      });
    }
  }, [inlineEmbedColors]);

  useEffect(() => {
    if (
      widgetData.bgColor !== "" ||
      widgetData.textColor !== "" ||
      widgetData.buttonTextColor !== "" ||
      widgetData.buttonBgColor !== "" ||
      widgetData?.buttonText !== ""
    ) {
      UpdateWebform({
        ...webformInfo,
        uiStyling: [inlineEmbedColors, widgetData, popupData],
        embededType: "widgetEmbed",
      });
    }
  }, [widgetData]);

  useEffect(() => {
    if (
      popupData.bgColor !== "" ||
      popupData.textColor !== "" ||
      popupData.buttonTextColor !== "" ||
      popupData.buttonBgColor !== "" ||
      popupData?.linkText !== ""
    ) {
      UpdateWebform({
        ...webformInfo,
        uiStyling: [inlineEmbedColors, widgetData, popupData],
        embededType: "popupEmbed",
      });
    }
  }, [popupData]);

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrCodeEl") as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qr-code.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.error("Unable to find QR Code element");
    }
  };

  const getCodeContent = () => {
    return `<form id="api-form" data-formID="${sharingFormData.id}"></form>\n<script src="${publicScriptUrl}"></script>`;
  };

  const renderAddToWebsiteContent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            border: "1px solid rgba(233, 233, 233, 1)",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenInlineEmbed(true);
          }}
        >
          <div>
            <img src={inline} width="150px" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "25px",
            }}
          >
            <div style={{ color: "#394150", fontWeight: "500" }}>
              Inline Embed
            </div>
            <p style={{ color: "#9fa5b3" }}>Add the webform to your site</p>
          </div>
        </div>
        <div
          style={{
            border: "1px solid rgba(233, 233, 233, 1)",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            margin: "10px 0px",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenWidgtPopup(true);
          }}
        >
          <div>
            <img src={widget} width="150px" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "25px",
            }}
          >
            <div style={{ color: "#394150", fontWeight: "500" }}>
              Popup Widget
            </div>
            <p style={{ color: "#9fa5b3" }}>
              Add a floating button that opens the webform as a popup
            </p>
          </div>
        </div>
        <div
          style={{
            border: "1px solid rgba(233, 233, 233, 1)",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenTextPopup(true);
          }}
        >
          <div>
            <img src={popup} width="150px" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "25px",
            }}
          >
            <div style={{ color: "#394150", fontWeight: "500" }}>
              Popup Text
            </div>
            <p style={{ color: "#9fa5b3" }}>
              Add a text link that opens webform as a popup
            </p>
          </div>
        </div>
      </div>
    );
  };
  const handleClose = () => {
    onClose();
    setIsCopied(false);
  };

  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
    setIsCopied(false);
    if (newValue === 0) {
      setCopyContent(getDomainUrlWithSharingPath());
    } else {
      setCopyContent(getCodeContent()); // Update this with your desired value
    }
  };

  const getDomainUrlWithSharingPath = () => {
    const domainUrl = window.location.origin;
    return `${domainUrl}/publicwebform/${sharingFormData.id}`;
  };

  React.useEffect(() => {
    if (activeTab === 0) {
      setCopyContent(getDomainUrlWithSharingPath());
    }
  }, [sharingFormData, activeTab]);

  const renderActionButton = () => {
    if (activeTab === 0) {
      return (
        <CopyToClipboard
          text={copyContent}
          onCopy={() => {
            setIsCopied(true);
            setCopied(true);
            handleClose();
          }}
        >
          <Button variant="contained" sx={{ textTransform: "capitalize" }}>
            Copy Link
          </Button>
          {/* {isCopied && <div>Copied!</div>} */}
        </CopyToClipboard>
      );
    } else if (activeTab === 1) {
      return (
        <CopyToClipboard
          text={copyContent}
          onCopy={() => {
            setIsCopied(true);
            setCopied(true);
            handleClose();
          }}
        >
          <Button variant="contained" sx={{ textTransform: "capitalize" }}>
            Copy Code
          </Button>
          {/* {isCopied && <div>Copied!</div>} */}
        </CopyToClipboard>
      );
    } else if (activeTab === 2) {
      return (
        <Button
          variant="contained"
          onClick={downloadQRCode}
          sx={{ textTransform: "capitalize" }}
        >
          Download QR Code
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Dialog
        open={showSharePopup}
        onClose={handleClose}
        sx={{
          zIndex: "99999",
          minWidth: "400px",
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: openWidgtPopup || openTextPopup ? "550px" : "500px",
            maxHeight:
              openInlineEmbed || openWidgtPopup || openTextPopup
                ? "900px"
                : "375px",
            minHeight: "185px",
            padding: "20px",
            borderRadius: "8px",
            boxShadow:
              "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
            left: "5%",
            top: "8%",
            background: "#FFFFFF",
            overflow: "hidden",
            overflowY: "hidden",
          },
        }}
      >
        <div>
          {openInlineEmbed || openWidgtPopup || openTextPopup ? (
            <>
              {openInlineEmbed && (
                <InlineEmbed
                  setOpenInlineEmbed={setOpenInlineEmbed}
                  formName={sharingFormData?.formName}
                  setInlineEmbedColors={setInlineEmbedColors}
                  inlineEmbedColors={inlineEmbedColors}
                  getCodeContent={getCodeContent}
                />
              )}
              {openWidgtPopup && (
                <WidgetPopup
                  setOpenWidgtPopup={setOpenWidgtPopup}
                  formName={sharingFormData?.formName}
                  setWidgetData={setWidgetData}
                  widgetData={widgetData}
                  getCodeContent={getCodeContent}
                />
              )}
              {openTextPopup && (
                <TextPopup
                  setOpenTextPopup={setOpenTextPopup}
                  formName={sharingFormData?.formName}
                  setPopupData={setPopupData}
                  popupData={popupData}
                  getCodeContent={getCodeContent}
                />
              )}
            </>
          ) : (
            <>
              <div className={styles.closeModalButton}>
                <CloseIcon onClick={handleClose} sx={{ color: "#667085" }} />
              </div>
              <div className={styles.modalTitle}>Share</div>
              <Box sx={{ borderBottom: "1px solid #E9E9E9" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  sx={{
                    "& .MuiTab-root": {
                      alignItems: "flex-start",
                      minWidth: "65px",
                    },
                    "& .MuiTabs-flexContainer": {
                      gap: "20px",
                    },
                  }}
                >
                  <Tab
                    label="Copy link"
                    sx={{
                      color: "#B4B4B4",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      paddingLeft: "0",
                      paddingRight: "0",
                      textTransform: "capitalize",
                    }}
                  />
                  <Tab
                    label="Add to Website"
                    sx={{
                      color: "#B4B4B4",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      paddingLeft: "0",
                      paddingRight: "0",
                      textTransform: "capitalize",
                    }}
                  />
                  <Tab
                    label="QR Code"
                    sx={{
                      color: "#B4B4B4",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      paddingLeft: "0",
                      paddingRight: "0",
                      textTransform: "capitalize",
                    }}
                  />
                </Tabs>
              </Box>
              {/* <div
                style={{
                  color: "#142C44",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                  textTransform: "capitalize",
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                {sharingFormData?.formName}
              </div> */}
              {activeTab === 0 && (
                <div>
                  <div
                    style={{
                      color: "#142C44",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      textTransform: "capitalize",
                      marginBottom: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {sharingFormData?.formName}
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflow: "auto",
                      backgroundColor: "#f5f5f5", // A light grey background
                      borderRadius: "4px",
                      padding: "10px",
                      fontFamily: "monospace", // Monospace font for code
                      whiteSpace: "pre-wrap", // Preserve whitespaces and line breaks
                      wordBreak: "break-word", // To prevent horizontal scrolling
                      margin: "10px 0",
                      fontSize: "12px",
                    }}
                  >
                    {getDomainUrlWithSharingPath()}
                  </Box>
                </div>
              )}
              {activeTab === 1 && (
                <div style={{ overflowY: "auto", maxHeight: "280PX" }}>
                  <div
                    style={{
                      color: "#142C44",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      textTransform: "capitalize",
                      marginBottom: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {sharingFormData?.formName}
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      // overflow: "auto",
                      backgroundColor: "#f5f5f5", // A light grey background
                      borderRadius: "4px",
                      padding: "10px",
                      // fontFamily: "monospace", // Monospace font for code
                      // whiteSpace: "pre-wrap", // Preserve whitespaces and line breaks
                      // wordBreak: "break-word", // To prevent horizontal scrolling
                      margin: "10px 0",
                      fontSize: "12px",
                    }}
                  >
                    {/* <pre>{getCodeContent()}</pre> */}
                    {renderAddToWebsiteContent()}
                  </Box>
                </div>
              )}
              {activeTab === 2 && (
                <Box>
                  <div
                    style={{
                      color: "#142C44",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      textTransform: "capitalize",
                      marginBottom: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {sharingFormData?.formName}
                  </div>
                  <QRCode
                    id="qrCodeEl"
                    value={getDomainUrlWithSharingPath()}
                    size={150}
                    level={"H"}
                    includeMargin={true}
                  />
                </Box>
              )}
            </>
          )}
          {(openInlineEmbed ||
            openWidgtPopup ||
            openTextPopup ||
            activeTab === 0 ||
            activeTab === 2) && (
            <div
              className={styles.buttonSection}
              style={{
                marginBottom:
                  openWidgtPopup || openTextPopup
                    ? "120px"
                    : activeTab === 0
                    ? "10px"
                    : "50px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#BFBFBF",
                  fontWeight: "500",
                  lineHeight: "10px 24px 24px 24px",
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  border: "1px solid #BFBFBF",
                  backgroundColor: "#FFF",
                  "&:hover": {
                    backgroundColor: "#FFF",
                    border: "1px solid #BFBFBF",
                  },
                }}
                onClick={handleClose}
              >
                Close
              </Button>
              {renderActionButton()}
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
export default SharePopup;
