import React from "react";
import styles from "../Subscription.module.css";
import accountArchived from "./account-archived.svg";

function AccountArchived() {
  return (
    <>
      <div className={styles.lockedWarningWrap}>
        <div className={styles.lockedWarningWrapLeft}>
          <div className={styles.lockedWarningIcon}>
            <img src={accountArchived} alt="accountLocked" />
          </div>
          <div className={styles.lockedWarningLabel}>Account Archived</div>
        </div>
        <div className={styles.lockedWarningWrapRight}>
          Your account is archived, Please make the payment to activate it.
        </div>
      </div>
    </>
  );
}

export default AccountArchived;
