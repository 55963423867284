import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { getBranchList } from "../../Manage/ManageBranch/service/Branchslice";
import { SettingsInitialStateDTO } from "../service/types";
import RolesAndPermission from "../components/RolesAndPermission/components/RolesAndPermission";
import { deleteRole, getRolesByInstitute } from "../service/settingsSlice";
import { getStaffList } from "../../Manage/ManageStaff/service/Staffslice";

interface DispatchProps {
  GetRolesByInstitute(id: string): void;
  GetStaffList(id: string): void;
  DeleteRole(data: any): void;
}

const mapStateToProps = (state: RootState): SettingsInitialStateDTO => {
  return {
    notifySettings: state.appSettings.notifySettings,
    invoiceSettings: state.appSettings.invoiceSettings,
    rolesList: state.appSettings.rolesList,
    roleDomainStructure: state.appSettings.roleDomainStructure,
    roleById: state.appSettings.roleById,
    studentIdData: state.appSettings.studentIdData,
    customFields: state.appSettings.customFields,
    activeLeadForm: state.appSettings.activeLeadForm,
    leadFormById: state.appSettings.leadFormById,
    leadForms: state.appSettings.leadForms,
    createdLeadFormId: state.appSettings.createdLeadFormId,

    success: state.appSettings.success,
    error: state.appSettings.error,
    loading: state.appSettings.loading,
    notifySettingLoading: state.appSettings.notifySettingLoading,
    notifySettingUpdateLoading: state.appSettings.notifySettingUpdateLoading,
    notifySettingUpdateSuccess: state.appSettings.notifySettingUpdateSuccess,
    notifySettingUpdateError: state.appSettings.notifySettingUpdateError,
    invoiceSettingLoading: state.appSettings.invoiceSettingLoading,
    invoiceSettingCreateLoading: state.appSettings.invoiceSettingCreateLoading,
    invoiceSettingCreateSuccess: state.appSettings.invoiceSettingCreateSuccess,
    invoiceSettingCreateError: state.appSettings.invoiceSettingCreateError,
    invoiceSettingUpdateLoading: state.appSettings.invoiceSettingUpdateLoading,
    invoiceSettingUpdateSuccess: state.appSettings.invoiceSettingUpdateSuccess,
    invoiceSettingUpdateError: state.appSettings.invoiceSettingUpdateError,
    createCustomRoleError: state.appSettings.createCustomRoleError,
    createCustomRoleSuccess: state.appSettings.createCustomRoleSuccess,
    createCustomRoleLoading: state.appSettings.createCustomRoleLoading,
    rolesListLoading: state.appSettings.rolesListLoading,
    roleDomainStructureLoading: state.appSettings.roleDomainStructureLoading,
    updateCustomRoleError: state.appSettings.updateCustomRoleError,
    updateCustomRoleSuccess: state.appSettings.updateCustomRoleSuccess,
    updateCustomRoleLoading: state.appSettings.updateCustomRoleLoading,
    roleByIdLoading: state.appSettings.roleByIdLoading,
    deleteCustomRoleError: state.appSettings.deleteCustomRoleError,
    deleteCustomRoleSuccess: state.appSettings.deleteCustomRoleSuccess,
    deleteCustomRoleLoading: state.appSettings.deleteCustomRoleLoading,
    createStudentIdError: state.appSettings.createStudentIdError,
    createStudentIdSuccess: state.appSettings.createStudentIdSuccess,
    createStudentIdLoading: state.appSettings.createStudentIdLoading,
    updateStudentIdError: state.appSettings.updateStudentIdError,
    updateStudentIdSuccess: state.appSettings.updateStudentIdSuccess,
    updateStudentIdLoading: state.appSettings.updateStudentIdLoading,
    StudentIdDataLoading: state.appSettings.StudentIdDataLoading,
    customFieldsLoading: state.appSettings.customFieldsLoading,

    createLeadFormSuccess: state.appSettings.createLeadFormSuccess,
    createLeadFormLoading: state.appSettings.createLeadFormLoading,
    updateLeadFormSuccess: state.appSettings.updateLeadFormSuccess,
    updateLeadFormLoading: state.appSettings.updateLeadFormLoading,
    updateCustomFieldsSuccess: state.appSettings.updateCustomFieldsSuccess,
    updateCustomFieldsLoading: state.appSettings.updateCustomFieldsLoading,
    activeLeadFormLoading: state.appSettings.activeLeadFormLoading,
    leadFormByIdLoading: state.appSettings.leadFormByIdLoading,
    leadFormsLoading: state.appSettings.leadFormsLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetRolesByInstitute: (id: string) => {
      dispatch(getRolesByInstitute(id));
    },
    GetStaffList: (id: string) => {
      dispatch(getStaffList(id));
    },
    DeleteRole: (data: any) => {
      dispatch(deleteRole(data));
    },
  };
};

export interface RolesAndPermissionsProps
  extends SettingsInitialStateDTO,
    DispatchProps {}
export const RolesAndPermissionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesAndPermission);
