import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import { getReports, reportSlice, viewReports } from "../service/reportsSlice";
import { ReportCreationInitialStateDTO } from "../service/types";
import ReportTable from "../components/RecentReport/ReportTable";
import {
  getDefaultReportData,
  getDownloadedReport,
  saveReport,
} from "../service/reportCreationSlice";
import LeadReportCreation from "../components/ReportCreation/LeadReportCreation";

interface StateProps extends ReportCreationInitialStateDTO {}
interface DispatchProps {
  GetDefaultReport(body: any): void;
  SaveReport(body: any): void;
  DownloadReport(body: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getDefaultReportDataLoading:
      state.reportCreation.getDefaultReportDataLoading,
    getDefaultReportDataSuccess:
      state.reportCreation.getDefaultReportDataSuccess,
    getDefaultReportDataError: state.reportCreation.getDefaultReportDataError,
    getDefaultReportData: state.reportCreation.getDefaultReportData,
    saveReportLoading: state.reportCreation.saveReportLoading,
    saveReportSuccess: state.reportCreation.saveReportSuccess,
    saveReportError: state.reportCreation.saveReportError,
    downloadReportLoading: state.reportCreation.downloadReportLoading,
    downloadReportSuccess: state.reportCreation.downloadReportSuccess,
    downloadReportError: state.reportCreation.downloadReportError,
    downloadReportData: state.reportCreation.downloadReportData,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetDefaultReport: (body: any) => {
      dispatch(getDefaultReportData(body));
    },
    SaveReport: (id: string) => {
      dispatch(saveReport(id));
    },
    DownloadReport: (body: any) => {
      dispatch(getDownloadedReport(body));
    },
  };
};

export interface LeadReportCreationProps extends StateProps, DispatchProps {}
export const LeadReportCreationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadReportCreation);
