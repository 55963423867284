import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ title, result }: any) => {
  const revenueData = {
    labels: ["Received", "Pending"],
    data: [35, 20],
    backgroundColor: ["#11C89B", "#EC3939"],
    borderColor: ["#11C89B", "#EC3939"],
  };

  const leadData = {
    labels: result?.pieChartData?.datasets?.map((d: any) => d?.label),
    data: result?.pieChartData?.datasets?.map((d: any) => d?.data),
    backgroundColor: ["#F5AC1B", "#2283CA", "#11C89B"],
    borderColor: ["#F5AC1B", "#2283CA", "#11C89B"],
  };

  const data = {
    labels: title === "lead" ? leadData?.labels : revenueData?.labels,
    datasets: [
      {
        label: "Sample Pie Chart",
        data: title === "lead" ? leadData?.data : revenueData?.data,
        backgroundColor:
          title === "lead"
            ? leadData?.backgroundColor
            : revenueData?.backgroundColor,
        borderColor:
          title === "lead" ? leadData?.borderColor : revenueData?.borderColor,
        borderWidth: 1,
      },
    ],
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          display: false,
        },
        maxWidth: 150,
      },
      tooltip: {
        callbacks: {
          // Display both label and percentage in tooltip
          label: function (tooltipItem: any) {
            const label = data?.labels[tooltipItem.dataIndex];
            const value = data?.datasets[0]?.data[tooltipItem.dataIndex];
            const total = data?.datasets[0]?.data.reduce(
              (acc: any, curr: any) => acc + curr,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${percentage}% (${value})`;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <Pie data={data} options={options} />
      <div
        style={{
          textAlign: "center",
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {data &&
          data?.labels?.map((label: any, i: number) => (
            <div key={i} style={{ display: "inline-block", margin: "0 10px" }}>
              <div
                style={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    color: "#7D8592",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                >
                  <div
                    style={{
                      height: "12px",
                      width: "12px",
                      borderRadius: "3px",
                      borderWidth: "1px",
                      backgroundColor:
                        label === "student"
                          ? "#11C89B"
                          : label === "leads"
                          ? "#2283CA"
                          : label === "contacts"
                          ? "#F5AC1B"
                          : label === "Received"
                          ? "#11C89B"
                          : "#EC3939",
                      marginRight: "5px",
                    }}
                  ></div>
                  {label}
                </div>
              </div>
              <div
                style={{
                  fontWeight: "600",
                  color: "#142C44",
                  fontSize: "12px",
                }}
              >
                {data.datasets[0].data[i]}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PieChart;
