import { Box } from "@mui/material";
import React, { useRef } from "react";
import styles from "./ActivityList.module.css";
import note from "./assets/note.svg";
import phone from "../../assets/images/phone.png";
import call from "./assets/call.svg";
import meetings from "./assets/meeting.svg";
import email from "./assets/email.svg";
import demoIcon from "./assets/demo.svg";
import TaskListItem from "./TaskListItem";

function TasksList(props: any) {
  const {
    data,
    activeTab,
    DeleteTask,
    setFromTaskEdit,
    setTaskForEditing,
    setShowAddTask,
    showAddTask,
    UpdateTaskStatus,
    employees,
    selectedLeadData,
    updateTaskStatusSuccess,
    CreateComment,
    DeleteComment,
    EditComment,
    leadById,
    setTaskIdForComment,
    taskIdForComment,
    listView,
  } = props;

  const sideIcon = (type: string) => {
    switch (type) {
      case "CALL":
        return call;

      case "MEETING":
        return meetings;

      case "EMAIL":
        return email;

      case "DEMO":
        return demoIcon;

      default:
        return;
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = (id: number) => {
    if (containerRef.current) {
      // Find the index of the item with the given id
      const index = data?.findIndex((item: any) => item.id === id);

      // Calculate the offsetTop of the item within the container
      const itemOffsetTop = index * 50; // Assuming each item has a height of 50px

      // Scroll to the top of the item
      containerRef.current.scrollTop = itemOffsetTop;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: data?.status === "COMPLETED" ? "15px" : 0,
        bgcolor: "white",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            paddingLeft: 2,
          }}
        >
          <Box
            sx={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              border: "1px solid #F0F0F0",
              textAlign: "center",
              backgroundColor: "#FFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <div className={styles.activitySideIcon}>
              <img
                src={
                  activeTab === "notes"
                    ? note
                    : data?.taskType === undefined || data?.taskType === ""
                    ? phone
                    : sideIcon(data?.taskType)
                }
                alt="?"
              />
            </div>
          </Box>
        </Box>

        <TaskListItem
          data={data}
          DeleteTask={DeleteTask}
          setFromTaskEdit={setFromTaskEdit}
          setTaskForEditing={setTaskForEditing}
          setShowAddTask={setShowAddTask}
          showAddTask={showAddTask}
          UpdateTaskStatus={UpdateTaskStatus}
          employees={employees}
          selectedLeadData={selectedLeadData}
          updateTaskStatusSuccess={updateTaskStatusSuccess}
          CreateComment={CreateComment}
          DeleteComment={DeleteComment}
          EditComment={EditComment}
          leadById={leadById}
          taskIdForComment={taskIdForComment}
          setTaskIdForComment={setTaskIdForComment}
          listView={listView}
        />
      </Box>
    </Box>
  );
}

export default TasksList;
