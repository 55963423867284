import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../../../../components/CancelModal/CancelModal.module.css";
import { color } from "@mui/system";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function CancelSubscriptionPopup(props: any) {
  const { open, onClose, noButtonClick, yesButtonClick, subscriptionStatus } =
    props;
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div onClick={onClose} className={styles.closeModalButton}>
              <CloseIcon />
            </div>
            <div className={styles.modalTitle}>
              {subscriptionStatus === "ACTIVE"
                ? "Are you sure you want deactivate your subscription?"
                : "Are you sure you want activate your subscription?"}
            </div>
            {/* <div className={styles.modalDes}>
              The changes you made will be discarded.
            </div> */}
            <div className={styles.buttonSection}>
              <button onClick={noButtonClick} className={styles.noButton}>
                No
              </button>
              <button
                onClick={yesButtonClick}
                className={styles.yesButton}
                style={{
                  background:
                    subscriptionStatus === "CANCELLED" ? "#11C89B" : "#ec3939",
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CancelSubscriptionPopup;
