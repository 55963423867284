import { FormControl } from "@mui/material";
import React from "react";
import { BasicButtonGroup } from "../../../../../../components/BasicButtonGroup/BasicButtonGroup";
import { useEffect, useState } from "react";
function BatchConductTypeManager(props: any) {
  const {
    handleConductType,
    selectedConductType,
    disabled,
    errorMsg,
    clone,
    validatingFirstTimeBranchCreation,
    setConductType,
    batchCreateSuccess,
  } = props;
  const batchConductTypes =
    validatingFirstTimeBranchCreation &&
    validatingFirstTimeBranchCreation?.batchCreated === false
      ? [{ typeName: "REGULAR" }]
      : [{ typeName: "REGULAR" }, { typeName: "DEMO" }];

  const [selected, setSelected] = useState<any[]>([]);

  const [isMulti] = useState<boolean>(true);

  const handleSelected = (value: never, index: any) => {
    if (!isMulti) {
      if (selected.includes(value)) {
        setSelected((prevSelected) => prevSelected.filter((s) => s !== value));
      } else {
        setSelected((prevSelected) => [...prevSelected, value]);
      }
    } else {
      if (selected.includes(value)) {
        setSelected((prevSelected) => prevSelected.filter((s) => s !== value));
      } else {
        setSelected((prevSelected) => [value]);
      }
    }
    if (selected.includes(value)) {
      handleConductType("");
    } else {
      handleConductType(batchConductTypes[value].typeName);
    }
  };

  const mappedData = batchConductTypes
    ? batchConductTypes?.map((item: any) => {
        return {
          label: item.typeName?.toLowerCase(),
          value: item.typeName,
        };
      })
    : [];

  useEffect(() => {
    if (clone && selectedConductType !== "") {
      if (selectedConductType === "REGULAR") {
        setSelected([0]);
      } else {
        setSelected([1]);
      }
    }
  }, [selectedConductType, setSelected, clone]);

  useEffect(() => {
    if (validatingFirstTimeBranchCreation) {
      if (validatingFirstTimeBranchCreation?.batchCreated === false) {
        setSelected([0]);
        setConductType("REGULAR");
      }
    }
  }, [validatingFirstTimeBranchCreation]);

  useEffect(() => {
    if (batchCreateSuccess !== true && batchCreateSuccess !== false) {
      setSelected([]);
    }
  }, [batchCreateSuccess]);

  return (
    <>
      <FormControl
        fullWidth
        variant="standard"
        className="formControl"
        sx={{ marginBottom: "24px" }}
      >
        <div
          style={{
            fontSize: "12px",
            color: "#142C44",
            fontWeight: "500",
            display: "flex",
            margin: "0px 0px 5px",
          }}
        >
          Batch Conduct Type
          <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
        </div>
        <BasicButtonGroup
          buttonList={mappedData}
          handleSelected={handleSelected}
          selected={selected}
          errorMsg={errorMsg}
          disabled={disabled}
        />
      </FormControl>
    </>
  );
}

export default BatchConductTypeManager;
