import React, { useState } from "react";
import styles from "./OzonetelWidget.module.css"; // Ensure this CSS module is properly set up
import CloseIcon from "@mui/icons-material/Close";
import call_ozonetel from "../../../../assets/images/call_ozonetel.svg";
import {
  setHideOzonetelWidget,
  setShowOzonetelWidget,
} from "../service/IntegrationsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

interface OzonetelWidgetProps {
  url: string; // URL to be loaded in the iframe
  buttonText: string; // Text for the button to open the popup
}

const OzonetelWidget: React.FC<OzonetelWidgetProps> = ({ url, buttonText }) => {
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [agentLoginDetails, setAgentLoginDetails] = useState(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setHideOzonetelWidget());
  };

  const handleOpen = () => {
    dispatch(setShowOzonetelWidget());
  };

  const { ozonetelWidgetShow } = useSelector(
    (state: any) => state.appIntegrations
  );

  return (
    <div className={styles.ozonetelWidgetContainer}>
      <div style={{ position: "relative", zIndex: 9999999 }}>
        <button
          className={styles.popupButton}
          // onClick={() => setIsPopupOpen(!isPopupOpen)}
          onClick={handleOpen}
        >
          <img src={call_ozonetel} alt="" />
        </button>
      </div>

      {/* Adjusting the style to hide/show the iframe instead of unmounting it */}
      <div
        className={styles.popup}
        style={{ display: ozonetelWidgetShow ? "block" : "none" }} // Control visibility with inline style
      >
        <iframe
          src={url}
          className={styles.iframe}
          title="Ozonetel Widget"
        ></iframe>
        <div
          className={styles.closeButton}
          // onClick={() => setIsPopupOpen(false)}
          onClick={handleClose}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default OzonetelWidget;
