import { connect } from "react-redux";
import {
  forgetPasswordInitialStateDTO,
  requsestOTPDTO,
  resetPasswordDTO,
  verifyOTPDTO,
} from "../../../../auth/ForgetPassword/service/types";
import {
  otpRequest,
  resetPassword,
  verifyOTP,
} from "../../../../auth/ForgetPassword/service/forgetPasswordSlice";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import ChangePassword from "../components/ChangePassword";
interface StateProps extends forgetPasswordInitialStateDTO {}

interface DispatchProps {
  RequestOTPforForgetPassword(data: requsestOTPDTO): void;
  VerifyOTPforForgetPassword(data: verifyOTPDTO): void;
  ResetPasswordforForgetPassword(data: resetPasswordDTO): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success_requestOTP: state.forgetPassword.success_requestOTP,
    success_verifyOTP: state.forgetPassword.success_verifyOTP,
    success_resetPassword: state.forgetPassword.success_resetPassword,
    error_requestOTP: state.forgetPassword.error_requestOTP,
    error_verifyOTP: state.forgetPassword.error_verifyOTP,
    error_resetPassword: state.forgetPassword.error_resetPassword,
    loading_requestOTP: state.forgetPassword.loading_requestOTP,
    loading_verifyOTP: state.forgetPassword.loading_verifyOTP,
    loading_resetPassword: state.forgetPassword.loading_resetPassword,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    RequestOTPforForgetPassword: (data: requsestOTPDTO) => {
      dispatch(otpRequest(data));
    },
    VerifyOTPforForgetPassword: (data: verifyOTPDTO) => {
      dispatch(verifyOTP(data));
    },
    ResetPasswordforForgetPassword: (data: resetPasswordDTO) => {
      dispatch(resetPassword(data));
    },
  };
};

export interface ChangePasswordProps extends StateProps, DispatchProps {}
export const ChangePasswordComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
