import { connect } from "react-redux";
import { TasksInitialStateDTO } from "../service/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { getTaskById, gettasksByDateForAssignee } from "../service/tasksSlice";
import { getStaffList } from "../../Manage/ManageStaff/service/Staffslice";
import DemoTaskDetailsPage from "../components/DemoTaskDetailsPage/DemoTaskDetailsPage";

import {
  UpdateTaskAssigneeDTO,
  UpdateTaskPriorityDTO,
  UpdateTaskStatusDTO,
} from "../service/types";
import {
  getAllTasksType,
  gettasksBoard,
  gettasksByAssignee,
  updateTaskAssignee,
  updateTaskPriority,
  updateTaskStatus,
} from "../service/tasksSlice";
import {
  composeEmail,
  createComment,
  createDemo,
  createNote,
  createSource,
  createTask,
  deleteComment,
  deleteDemoBookedForLead,
  deleteNote,
  deleteTask,
  downloadPaymentReceipt,
  editComment,
  editDemo,
  editNote,
  editRecordPayment,
  editTask,
  getDemoCalanderByCourseAndInstitute,
  getEmiDataOfLead,
  getInoxByLeadId,
  getLeadActivityList,
  getLeadById,
  getLeadListByInstituteAndContact,
  getLeadNoteList,
  getLeadPaymentList,
  getLeadTaskList,
  getNurtureLeadBoard,
  nurtureLead,
  recordPayment,
  sendPaymentLink,
  updateLead,
  updateLeadEmiDetails,
  updateLeadEnquiryStatus,
  updateLeadSwimlaneStatus,
} from "../../Nurture/service/nurtureSlice";
import {
  ComposeEmailDTO,
  CreateCommentDTO,
  CreateDemoDTO,
  CreateNoteDTO,
  CreateSourceDTO,
  CreateTaskDTO,
  DeleteCommentDTO,
  DeleteDemoBookedDTO,
  DeleteNoteDTP,
  DeleteTaskDTO,
  DownloadReceiptDTO,
  EditDemoDTO,
  EditNoteDTO,
  EditTaskDTO,
  LeadStatusUpdateDTO,
  LeadSwimlaneStatusUpdateDTO,
  NurtureLead,
  RecordPaymentDTO,
  leadEmiDTO,
  sendEmailDTO,
} from "../../Nurture/service/types";
import { createBatchDTO } from "../../Manage/ManageBatch/service/types";
import {
  createBatch,
  getBatchList,
} from "../../Manage/ManageBatch/service/Batchslice";
import { CallDispositionDTO } from "../../Integrations/service/types";
import {
  callLead,
  getAppsByInstitute,
  leadCallDispositionForm,
} from "../../Integrations/service/IntegrationsSlice";
import { getBranchList } from "../../Manage/ManageBranch/service/Branchslice";
import { getAllCourseByInstituteRequest } from "../../Manage/ManageCourse/service/courseSlice";
import { getInstituteById } from "../../Manage/InstituteProfile/service/instituteSlice";

interface StateProps extends TasksInitialStateDTO {}

interface DispatchProps {
  GetTasksByDateForAssignee(
    id: string,
    taskType: string,
    startDate: string,
    endDate: string
  ): void;
  GetStaffsByInstitute(id: string): void;
  GetTasksById(id: string): void;

  GetStaffList(id: string): void;
  UpdateTaskStatus(data: UpdateTaskStatusDTO): void;

  UpdateTaskPriority(data: UpdateTaskPriorityDTO): void;
  UpdateTaskAssignee(data: UpdateTaskAssigneeDTO): void;
  GetBranchList(id: string): void;
  GetCourseList(id: string): void;
  GetBatchList(data: any): void;
  GetLeadById(id: string): void;
  GetInboxByLeadId(id: string): void;
  GetLeadActivityList(id: string): void;
  GetLeadTaskList(id: string): void;
  GetLeadNoteList(id: string): void;
  GetLeadPaymentList(id: string): void;
  GetDemoCalanderByCourseAndInstitute(data: any): void;
  GetEmiDataOfLead(data: any): void;
  GetAppsByInstitute(id: string): void;
  GetLeadListByInstituteAndContact(data: any): void;
  GetNurtureLeadBoard(id: string): void;
  GetInstituteById(id: string): void;
  GetAllTasksType(id: string): void;

  nurtureAddEnquiry(data: NurtureLead): void;
  CreateNote(data: CreateNoteDTO): void;
  CreateTask(data: CreateTaskDTO): void;
  RecordPayment(data: RecordPaymentDTO): void;
  CreateDemo(data: CreateDemoDTO): void;
  ComposeEmailSent(data: ComposeEmailDTO): void;
  CreateBatch(data: createBatchDTO): void;
  SendPaymentLink(data: sendEmailDTO): void;
  DownloadPaymentReceipt(data: DownloadReceiptDTO): void;
  CreateSource(data: CreateSourceDTO): void;
  CallDispositionForm(data: CallDispositionDTO): void;
  CallLead(data: any): void;

  EditRecordPayment(data: RecordPaymentDTO): void;
  UpdateLead(data: NurtureLead): void;
  UpdateLeadSwimlaneStatus(data: LeadSwimlaneStatusUpdateDTO): void;
  UpdateLeadEmiDetails(data: leadEmiDTO): void;
  UpdateLeadEnquiyStatus(data: LeadStatusUpdateDTO): void;
  DeleteNote(data: DeleteNoteDTP): void;
  EditNote(data: EditNoteDTO): void;
  DeleteTask(data: DeleteTaskDTO): void;
  EditTask(data: EditTaskDTO): void;
  EditDemo(data: EditDemoDTO): void;
  DeleteDemoBookedForLead(data: DeleteDemoBookedDTO): void;
  CreateComment(data: CreateCommentDTO): void;
  DeleteComment(data: DeleteCommentDTO): void;
  EditComment(data: CreateCommentDTO): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.tasks.success,
    error: state.tasks.error,
    loading: state.tasks.loading,
    tasksByAssignee: state.tasks.tasksByAssignee,
    tasksTypes: state.tasks.tasksTypes,
    tasksboard: state.tasks.tasksboard,
    taskById: state.tasks.taskById,
    tasksByDateForAssignee: state.tasks.tasksByDateForAssignee,
    taskStatusUpdateSuccess: state.tasks.taskStatusUpdateSuccess,
    taskStatusUpdateError: state.tasks.taskStatusUpdateError,
    taskStatusUpdateLoading: state.tasks.taskStatusUpdateLoading,
    taskPriorityUpdateSuccess: state.tasks.taskPriorityUpdateSuccess,
    taskPriorityUpdateError: state.tasks.taskPriorityUpdateError,
    taskPriorityUpdateLoading: state.tasks.taskPriorityUpdateLoading,
    taskAssigneeUpdateSuccess: state.tasks.taskAssigneeUpdateSuccess,
    taskAssigneeUpdateError: state.tasks.taskAssigneeUpdateError,
    taskAssigneeUpdateLoading: state.tasks.taskAssigneeUpdateLoading,
    taskByIdLoading: state.tasks.taskByIdLoading,
    taskBoardloading: state.tasks.taskBoardloading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetTasksByDateForAssignee: (
      id: string,
      taskType: string,
      startDate: string,
      endDate: string
    ) => {
      dispatch(
        gettasksByDateForAssignee({
          id: id,
          taskType: taskType,
          startDate: startDate,
          endDate: endDate,
        })
      );
    },
    GetStaffsByInstitute: (id: string) => {
      dispatch(getStaffList(id));
    },
    GetTasksById: (id: string) => {
      dispatch(getTaskById(id));
    },

    GetStaffList: (id: string) => {
      dispatch(getStaffList(id));
    },
    GetBatchList: (data: any) => {
      dispatch(getBatchList(data));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    UpdateTaskStatus: (data: UpdateTaskStatusDTO) => {
      dispatch(updateTaskStatus(data));
    },
    UpdateTaskPriority: (data: UpdateTaskPriorityDTO) => {
      dispatch(updateTaskPriority(data));
    },
    UpdateTaskAssignee: (data: UpdateTaskAssigneeDTO) => {
      dispatch(updateTaskAssignee(data));
    },
    GetLeadById: (id: string) => {
      dispatch(getLeadById(id));
    },
    GetInboxByLeadId: (id: string) => {
      dispatch(getInoxByLeadId(id));
    },
    GetLeadActivityList: (id: string) => {
      dispatch(getLeadActivityList(id));
    },
    GetLeadTaskList: (id: string) => {
      dispatch(getLeadTaskList(id));
    },
    GetLeadNoteList: (id: string) => {
      dispatch(getLeadNoteList(id));
    },
    GetLeadPaymentList: (id: string) => {
      dispatch(getLeadPaymentList(id));
    },
    GetDemoCalanderByCourseAndInstitute: (data: any) => {
      dispatch(getDemoCalanderByCourseAndInstitute(data));
    },
    GetEmiDataOfLead: (data: any) => {
      dispatch(getEmiDataOfLead(data));
    },
    GetAppsByInstitute: (id: string) => {
      dispatch(getAppsByInstitute(id));
    },
    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
    GetNurtureLeadBoard: (id: string) => {
      dispatch(getNurtureLeadBoard(id));
    },

    nurtureAddEnquiry: (data: NurtureLead) => {
      dispatch(nurtureLead(data));
    },
    CreateNote: (data: CreateNoteDTO) => {
      dispatch(createNote(data));
    },
    CreateTask: (data: CreateTaskDTO) => {
      dispatch(createTask(data));
    },
    RecordPayment: (data: RecordPaymentDTO) => {
      dispatch(recordPayment(data));
    },
    CreateDemo: (data: CreateDemoDTO) => {
      dispatch(createDemo(data));
    },
    ComposeEmailSent: (data: ComposeEmailDTO) => {
      dispatch(composeEmail(data));
    },
    CreateBatch: (data: createBatchDTO) => {
      dispatch(createBatch(data));
    },
    SendPaymentLink: (data: sendEmailDTO) => {
      dispatch(sendPaymentLink(data));
    },
    DownloadPaymentReceipt: (data: DownloadReceiptDTO) => {
      dispatch(downloadPaymentReceipt(data));
    },
    CreateSource: (data: CreateSourceDTO) => {
      dispatch(createSource(data));
    },
    CallDispositionForm: (data: CallDispositionDTO) => {
      dispatch(leadCallDispositionForm(data));
    },
    CallLead: (data: any) => {
      dispatch(callLead(data));
    },
    EditRecordPayment: (data: RecordPaymentDTO) => {
      dispatch(editRecordPayment(data));
    },
    UpdateLeadEnquiyStatus: (data: LeadStatusUpdateDTO) => {
      dispatch(updateLeadEnquiryStatus(data));
    },
    UpdateLeadSwimlaneStatus: (data: LeadSwimlaneStatusUpdateDTO) => {
      dispatch(updateLeadSwimlaneStatus(data));
    },
    UpdateLead: (data: NurtureLead) => {
      dispatch(updateLead(data));
    },
    UpdateLeadEmiDetails: (data: leadEmiDTO) => {
      dispatch(updateLeadEmiDetails(data));
    },
    DeleteNote: (data: DeleteNoteDTP) => {
      dispatch(deleteNote(data));
    },
    EditNote: (data: EditNoteDTO) => {
      dispatch(editNote(data));
    },
    DeleteTask: (data: DeleteTaskDTO) => {
      dispatch(deleteTask(data));
    },
    EditTask: (data: EditTaskDTO) => {
      dispatch(editTask(data));
    },
    EditDemo: (data: EditDemoDTO) => {
      dispatch(editDemo(data));
    },
    DeleteDemoBookedForLead: (data: DeleteDemoBookedDTO) => {
      dispatch(deleteDemoBookedForLead(data));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
    GetAllTasksType: (id: string) => {
      dispatch(getAllTasksType(id));
    },
    CreateComment: (data: CreateCommentDTO) => {
      dispatch(createComment(data));
    },
    DeleteComment: (data: DeleteCommentDTO) => {
      dispatch(deleteComment(data));
    },
    EditComment: (data: CreateCommentDTO) => {
      dispatch(editComment(data));
    },
  };
};

export interface DemoTaskDetailsProps extends StateProps, DispatchProps {}
export const DemoTaskDetailsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(DemoTaskDetailsPage);
