import React from "react";
import mainLoaderGif from "../../assets/images/mainloader.gif";

function MainLoader() {
  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        width: "100%",
        background: "#fff",
        height: "100%",
        zIndex: "99999999",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ maxWidth: "8%", overflow: "hidden" }}>
        <img
          style={{ width: "100%" }}
          src={mainLoaderGif}
          alt="mainLoaderGif"
        />
      </div>
    </div>
  );
}

export default MainLoader;
