import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ClearFilter.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};
const ClearFilter = ({
  open,
  onClose,
  noButtonClick,
  yesButtonClick,
  filteredValue,
}: any) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: "99999999" }}
    >
      <Box sx={style}>
        <div>
          <div onClick={onClose} className={styles.closeModalButton}>
            <CloseIcon />
          </div>
          <div className={styles.modalTitle}>
            Are you sure you want to clear all the filters?
          </div>
          <div className={styles?.modalDes}>{filteredValue}</div>
          <div className={styles.buttonSection}>
            <button onClick={noButtonClick} className={styles.noButton}>
              No
            </button>
            <button onClick={yesButtonClick} className={styles.yesButton}>
              Yes
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default ClearFilter;
