import React, { useEffect, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import {
  resetEditTaskSuccess,
  resetTaskSuccess,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import TasksList from "../ActivityList/TasksList";
import AddIcon from "@mui/icons-material/Add";
import AddTask from "../../pages/app/Nurture/components/AddTask/AddTask";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";

function ListViewTasksTab(props: any) {
  const {
    CreateTask,
    EditTask,
    UpdateTaskStatus,
    DeleteTask,
    selectedLeadData,
    leadTaskList,
    employees,
    taskTypes,
    GetLeadTaskList,
    type,
  } = props;
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const [fromTaskEdit, setFromTaskEdit] = useState<boolean>(false);
  const [taskForEditing, setTaskForEditing] = useState<any>();
  const [showAddTask, setShowAddTask] = useState(false);

  const { editTaskSuccess, createTaskSuccess, updateTaskStatusSuccess } =
    useSelector((state: any) => state.nurture);

  const toggleShowAddTask = () => {
    setShowAddTask(!showAddTask);
    setFromTaskEdit(false);
  };

  React.useEffect(() => {
    if (editTaskSuccess) {
      setShowAddTask(false);
      setTimeout(() => {
        dispatch(resetEditTaskSuccess());
      }, 3000);
    }
  }, [editTaskSuccess]);

  React.useEffect(() => {
    if (createTaskSuccess !== true && createTaskSuccess !== false) {
      setShowAddTask(false);
      setTimeout(() => {
        dispatch(resetTaskSuccess());
      }, 3000);
    }
  }, [setShowAddTask, createTaskSuccess, resetTaskSuccess]);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const leadTaskId = urlParams.get("leadTaskId");
  //   if (leadTaskId) {
  //     const element = document.getElementById(leadTaskId);
  //     if (element) {
  //       // 👇 Will scroll smoothly to the top of the next section
  //       element.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "start",
  //       });
  //     }
  //   }
  // }, [leadTaskList]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFF",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "15px",
          position: "absolute",
          top: "10px",
          right: "0",
        }}
      >
        {leadTaskList?.length > 0 && !showAddTask && (
          <Box>
            <Tooltip
              arrow
              title={hasLeadAccess === false ? "Oops! No permission." : ""}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#101828",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "8px 12px",
                    "& .MuiTooltip-arrow": {
                      color: "#101828",
                    },
                  },
                },
              }}
            >
              <span
                style={{
                  width: "100%",
                  display: "block",
                  textAlign: "center",
                }}
              >
                <Button
                  onClick={toggleShowAddTask}
                  color="primary"
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#667085",
                    border: "none",
                    borderRadius: "4px",
                    padding: "4px 8px",
                    "&:hover": { border: "none" },
                  }}
                  disabled={hasLeadAccess === false ? true : false}
                >
                  <AddIcon sx={{ marginRight: "6px", fontSize: "18px" }} />
                  Add Task
                </Button>
              </span>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box>
        {leadTaskList?.length > 0 ? (
          <>
            {showAddTask ? (
              <Box>
                <AddTask
                  CreateTask={CreateTask}
                  employees={employees && employees}
                  leadById={selectedLeadData}
                  taskTypes={taskTypes && taskTypes}
                  handleCancelClick={toggleShowAddTask}
                  fromTaskEdit={fromTaskEdit}
                  taskForEditing={taskForEditing}
                  EditTask={EditTask}
                  type="listview"
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: "white",
                  pt: 2,
                }}
              >
                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: "calc(98vh - 496px)",
                    minHeight: "calc(98vh - 496px)",
                  }}
                >
                  {leadTaskList
                    ?.filter((res: any) => res?.status !== "COMPLETED")
                    ?.map((item: any, index: any) => (
                      <div key={index} id={item?.id}>
                        <TasksList
                          activeTab={"task"}
                          employees={employees}
                          data={item}
                          index={index}
                          listData={leadTaskList}
                          DeleteTask={DeleteTask}
                          setFromTaskEdit={setFromTaskEdit}
                          setTaskForEditing={setTaskForEditing}
                          setShowAddTask={setShowAddTask}
                          showAddTask={showAddTask}
                          UpdateTaskStatus={UpdateTaskStatus}
                          selectedLeadData={selectedLeadData}
                          updateTaskStatusSuccess={updateTaskStatusSuccess}
                          listView={true}
                        />
                      </div>
                    ))}
                </div>
              </Box>
            )}
          </>
        ) : !showAddTask ? (
          <div
            style={{
              padding: 20,
              background: "#fff",
              maxHeight: "calc(98vh - 480px)",
              minHeight: "calc(98vh - 480px)",
              overflowY: "auto",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  color: "#142C44",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  marginBottom: "20px",
                }}
              >
                Start adding tasks to stay organized and on track with your lead
                engagements.
              </Box>
              <Tooltip
                arrow
                title={hasLeadAccess === false ? "Oops! No permission." : ""}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <Button
                    onClick={toggleShowAddTask}
                    variant="contained"
                    color="primary"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    disabled={hasLeadAccess === false ? true : false}
                  >
                    Add Task
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </div>
        ) : (
          <Box>
            <AddTask
              CreateTask={CreateTask}
              employees={employees && employees}
              leadById={selectedLeadData}
              handleCancelClick={toggleShowAddTask}
              taskTypes={taskTypes}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default ListViewTasksTab;
