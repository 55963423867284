//institute individual
export const ACCOUNT = "Who is this Account for?";
export const FORMINFO = "Are you an institution or individual tutor?";
export const INDIVIDUAL = "Individual Tutor";
export const INDIVIDUALTUTOR = "I am individual tutor.";
export const INSTITUTE = "Institute";
export const INSTITUTEPART = "I am part of an institute.";
// registationIndividual
export const CREATEACOUNT = "Create Your Account";
export const POLICYTEXT = "By creating an account, you agree to nurture’s";
export const TERMS = "Terms of Service";
export const PRIVACY = "Privacy Policy";
export const EMAILVERIFY = "Verify Your Email";
export const FORMINFOTXTOTP =
  "We sent a 4-digit passcode on your email address ";
export const FORMINFOTXTOTPINCORRECT = "Incorrect code please check again.";
export const RESENDAGAINTXT = "to resend again";
export const REDENDBTN = "Resend";
//Onbord
export const SETUPPASSWORD = "Setup Your Password";
//Verified
export const ACCOUNTVERIFICATION = "Your Account has been verified";
export const WELCOMEMESSAGE =
  "Thank you for verifying your account. Welcome to Nurture. You are in trusted hands.";
export const CONTINUEBTN = "Continue";
