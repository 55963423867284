import { Button } from "@mui/material";
import { MouseEvent } from "react";

type ButtonType = "submit";

interface buttonProps {
  disabled?: boolean;
  text: any;
  variant: any;
  endIcon?: any;
  type: ButtonType;
  className?: string;
  width?: any;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void; // Define onClick prop
}

export default function ButtonLog(props: buttonProps) {
  const { text, variant, endIcon, type, className, width, onClick } = props;
  return (
    <div>
      <Button
        fullWidth
        endIcon={endIcon}
        type={type}
        variant={variant}
        className={className}
        sx={{ textTransform: "capitalize", width: width }}
        onClick={onClick}
      >
        {text}
      </Button>
    </div>
  );
}
