import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import styles from "../Home.module.css";
import React, { useRef, useEffect } from "react";
import logout from "./logout.svg";
import tick from "./tick.svg";
import rightArrow from "./rightArrow.svg";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import {
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { resetLeadModalState } from "../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";

export default function ProfileMenuPopover(props: any) {
  const {
    setIsUserprofileMenuOpen,
    isUserprofileMenuOpen,
    handleLogoutModalOpen,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const instituteAccess = checkSubDomainAccess(
    "MANAGE",
    "INSTITUTE_PROFILE",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const branchAccess = checkSubDomainAccess(
    "MANAGE",
    "BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const courseAccess = checkSubDomainAccess(
    "MANAGE",
    "COURSES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const teamAccess = checkSubDomainAccess(
    "MANAGE",
    "TEAMS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const batchAccess = checkSubDomainAccess(
    "MANAGE",
    "BATCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const hasBillingAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const instituteDetails = useSelector(
    (state: any) => state.instituteProfile.instituteDetails
  );
  const fallingBackToUserInfo = authUser?.institute[0]?.instituteDetails;
  const instituteDatas = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteDatas?.billingStatus;
  const profileCompletedStatus = instituteDatas?.isProfileCompleted;

  function handleManageNavigate() {
    if (accountStatus !== "ACTIVE" && hasBillingAccess) {
      navigate(`/app/manage/Billing/ManageSubscription`, { state: 0 });
    } else {
      if (profileCompletedStatus) {
        if (instituteAccess) {
          navigate(`/app/manage/InstituteProfile`, { state: 0 });
        } else if (branchAccess && !instituteAccess) {
          navigate(`/app/manage/Branches`, { state: 1 });
        } else if (!branchAccess && !instituteAccess && courseAccess) {
          navigate(`/app/manage/Courses`, { state: 2 });
        } else if (
          !branchAccess &&
          !instituteAccess &&
          !courseAccess &&
          teamAccess
        ) {
          navigate(`/app/manage/Teams`, { state: 3 });
        } else if (
          !branchAccess &&
          !instituteAccess &&
          !courseAccess &&
          !teamAccess &&
          batchAccess
        ) {
          navigate(`/app/manage/Batches`, { state: 4 });
        } else if (
          !branchAccess &&
          !instituteAccess &&
          !courseAccess &&
          !teamAccess &&
          !batchAccess
        ) {
          navigate(`/app/manage/UserDetails`, { state: 6 });
        }
      } else {
        navigate(`/app/manage/InstituteProfile/:id`, { state: 0 });
      }
    }
  }

  const profileMenuRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = React.useState(false);

  const [instituteData, setInstituteData] = React.useState({
    instituteLogo: "",
    instituteName: "",
  });

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isListBtnHovered, setIsListBtnHovered] =
    React.useState<boolean>(false);

  const handleMouseEnterListBtn = () => {
    setIsListBtnHovered(true);
  };

  const handleMouseLeaveListBtn = () => {
    setIsListBtnHovered(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target as Node)
      ) {
        setIsUserprofileMenuOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [profileMenuRef, setIsUserprofileMenuOpen]);

  return (
    <div ref={profileMenuRef} className={styles.userProfileMenuWrapper}>
      <Button
        onClick={() => setIsUserprofileMenuOpen(!isUserprofileMenuOpen)}
        style={{
          fontSize: "14px",
          color: "#142C44",
          fontWeight: "500",
          textTransform: "capitalize",
          background: isHovered ? "#E8FAF5" : "",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Avatar
          src={
            userById?.userProfileImage
              ? `${userById.userProfileImage}?${new Date().getTime()}`
              : ""
          }
          alt="profile"
          sx={{
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            marginRight: "9px",
            fontSize: "20px",
            alignItems: "center",
            textTransform: "capitalize",
            fontWeight: "500",
            background: "#F5F5F7",
            border: "2px solid #EEEEF4",
            color: "#667085",
          }}
        >
          {!userById?.userProfileImage &&
            userById?.firstName &&
            userById.firstName.charAt(0)}
        </Avatar>
        {userById?.firstName || userById?.lastName ? (
          userById?.firstName + " " + userById?.lastName
        ) : (
          <Skeleton variant="rounded" width={100} height={40} />
        )}
      </Button>
      {isUserprofileMenuOpen && (
        <>
          <div className={styles.userProfileMenu}>
            <div className={styles.activeUserProfileDataWrapper}>
              <div className={styles.activeUserProfile}>
                <div className={styles.userProfileImage}>
                  {userById?.userProfileImage ? (
                    <img
                      src={
                        userById?.userProfileImage
                          ? `${
                              userById?.userProfileImage
                            }?${new Date().getTime()}`
                          : "/broken-image.jpg"
                      }
                      alt="profile"
                      width="40px"
                      height="40px"
                      style={{ borderRadius: "50%", marginTop: "5px" }}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        width: "40px",
                        height: "40px",
                        fontSize: "20px",
                        alignItems: "center",
                        textTransform: "capitalize",
                        fontWeight: "500",
                        background: "#F5F5F7",
                        border: "2px solid #EEEEF4",
                        color: "#667085",
                        borderRadius: "50%",
                        marginTop: "5px",
                      }}
                    >
                      {userById?.firstName || userById?.lastName
                        ? userById?.firstName?.charAt(0)
                        : "u"}
                    </Avatar>
                  )}
                </div>
                <div className={styles.userProfile}>
                  <div className={styles.userProfileName}>
                    {userById?.firstName || userById?.lastName
                      ? userById?.firstName + " " + userById?.lastName
                      : "username"}
                  </div>
                  <div className={styles.userProfileRole}>{userById?.role}</div>
                </div>
              </div>

              {authUser?.institute[0]?.instituteDetails?.isProfileCompleted && (
                <div className={styles.rightArrow}>
                  <img
                    src={rightArrow}
                    alt="rightArrow"
                    onClick={() => {
                      setIsUserprofileMenuOpen(!isUserprofileMenuOpen);
                      dispatch(resetLeadModalState());
                      navigate(
                        authUser?.institute[0]?.instituteDetails
                          ?.isProfileCompleted && "/app/manage/UserDetails",
                        {
                          state: 6,
                        }
                      );
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              <div className={styles.institutes}>Institutes</div>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  borderBottom: "1px solid #EEEEEE",
                  padding: 0,
                }}
              >
                {authUser &&
                  authUser?.institute &&
                  authUser?.institute?.map((item: any, index: any) => (
                    <ListItem
                      disablePadding
                      key={item.id}
                      onClick={() => {
                        setIsUserprofileMenuOpen(!isUserprofileMenuOpen);
                        handleManageNavigate();
                        dispatch(resetLeadModalState());
                        navigate(
                          accountStatus !== "ACTIVE" &&
                            accountStatus !== "GRACE_PERIOD"
                            ? `/app/manage/Billing/ManageSubscription`
                            : profileCompletedStatus
                            ? `/app/manage/InstituteProfile`
                            : `/app/manage/InstituteProfile/:id`,
                          { state: 0 }
                        );
                      }}
                      style={{
                        pointerEvents:
                          (accountStatus !== "ACTIVE" &&
                            accountStatus !== "GRACE_PERIOD" &&
                            hasBillingAccess === false) ||
                          profileCompletedStatus === false
                            ? "none"
                            : "auto",
                      }}
                    >
                      <ListItemButton
                        className={styles.listItemButton}
                        style={{
                          background: isListBtnHovered
                            ? "rgb(169 169 169 / 4%)"
                            : "inherit",
                        }}
                        onMouseEnter={handleMouseEnterListBtn}
                        onMouseLeave={handleMouseLeaveListBtn}
                      >
                        <ListItemAvatar>
                          {item?.instituteDetails?.logo ||
                          instituteDetails?.logo ? (
                            <img
                              src={
                                item?.id === instituteDetails?.instituteId
                                  ? instituteDetails?.logo
                                  : item?.instituteDetails?.logo
                              }
                              alt="profile"
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                marginTop: "5px",
                              }}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                // width: "34px",
                                // height: "34px",
                                fontSize: "20px",
                                alignItems: "center",
                                textTransform: "capitalize",
                                fontWeight: "500",
                                background: "#F5F5F7",
                                border: "2px solid #EEEEF4",
                                color: "#667085",
                              }}
                            >
                              {item?.id === instituteDetails?.instituteId
                                ? instituteDetails?.instituteName?.charAt(0)
                                : item?.instituteDetails?.instituteName?.charAt(
                                    0
                                  )}
                            </Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              style={{
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "18px",
                                color: "#142C44",
                                marginBottom: "4px",
                              }}
                            >
                              {item.instituteName ? (
                                item?.id === instituteDetails?.instituteId ? (
                                  instituteDetails.instituteName
                                ) : (
                                  item.instituteName
                                )
                              ) : instituteData?.instituteName ? (
                                item?.id === instituteDetails?.instituteId ? (
                                  instituteDetails.instituteName
                                ) : (
                                  item.instituteName
                                )
                              ) : fallingBackToUserInfo?.instituteName !==
                                undefined ? (
                                item?.id === instituteDetails?.instituteId ? (
                                  instituteDetails.instituteName
                                ) : (
                                  item.instituteName
                                )
                              ) : (
                                <Skeleton
                                  variant="rounded"
                                  width={100}
                                  height={40}
                                />
                              )}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              style={{
                                fontWeight: 400,
                                fontSize: "13px",
                                lineHeight: "18px",
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.role}
                            </Typography>
                          }
                        />
                        <ListItemIcon className={styles.tickIcon}>
                          <img src={tick} alt="tick" />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </div>

            <div className={styles.buttonsWrapper}>
              <div
                className={styles.logoutButton}
                onClick={handleLogoutModalOpen}
              >
                <img src={logout} alt="logout" />
                <div className={styles.logout}>Logout</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
