import { Alert, IconButton, Snackbar, SnackbarOrigin } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import checkCircle from "../../assets/images/check-circle.svg";
import { useSelector } from "react-redux";
import {
  setLeadExistData,
  setToastNotification,
} from "./service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useAuthUser } from "react-auth-kit";
import { resetLeadCreateErrorData } from "../../pages/app/Nurture/service/nurtureSlice";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: any;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

function ToastNotification() {
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "",
    snackSeverity: "info",
  });
  const { vertical, horizontal } = snackState;

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color={snackState?.snackSeverity === "success" ? "success" : "error"}
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const { snackOpen, message, snackSeverity, leadUpdateErrorData } =
    useSelector((state: any) => state.toastNotification);
  const { leadCreateErrorData, leadCreateError } = useSelector(
    (state: any) => state.nurture
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuthUser();
  const authUser = auth();

  React.useEffect(() => {
    if (snackOpen === true) {
      const instituteFromLocal = authUser?.institute[0]?.instituteDetails;
      const existingContactData =
        leadCreateErrorData?.payload?.data || leadUpdateErrorData;
      let queryParams = `/app/Nurture?leadModal=true&leadInstituteId=${instituteFromLocal?.id}&leadId=${existingContactData?.enquiryDetails?.[0]?.id}`;

      if (
        (existingContactData?.enquiryExistsBy === "CONTACT and COURSE" ||
          existingContactData?.enquiryExistsBy === "CONTACT" ||
          existingContactData?.enquiryExistsBy === "BOTH and COURSE" ||
          existingContactData?.enquiryExistsBy === "BOTH") &&
        existingContactData?.enquiryDetails?.[0]?.studentContact
      )
        queryParams += `&leadPhone=${existingContactData?.enquiryDetails?.[0]?.studentContact}`;
      if (
        (existingContactData?.enquiryExistsBy === "EMAIL and COURSE" ||
          existingContactData?.enquiryExistsBy === "EMAIL" ||
          existingContactData?.enquiryExistsBy === "BOTH and COURSE" ||
          existingContactData?.enquiryExistsBy === "BOTH") &&
        existingContactData?.enquiryDetails?.[0]?.studentEmail
      )
        queryParams += `&leadEmail=${existingContactData?.enquiryDetails?.[0]?.studentEmail}`;

      const viewLeadCardHandler = () => {
        // navigate(queryParams);
        const leadCardUrl = queryParams;
        window.open(leadCardUrl, "_blank");
      };

      const toastMessage = (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div>{String(message)}</div>
          {(existingContactData?.enquiryExistsBy === "CONTACT and COURSE" ||
            existingContactData?.enquiryExistsBy === "CONTACT" ||
            existingContactData?.enquiryExistsBy === "EMAIL and COURSE" ||
            existingContactData?.enquiryExistsBy === "EMAIL" ||
            existingContactData?.enquiryExistsBy === "BOTH and COURSE" ||
            existingContactData?.enquiryExistsBy === "BOTH") && (
            <div
              style={{
                color: "#1A73E8",
                fontSize: "13px",
                textDecorationLine: "underline",
                cursor: "pointer",
              }}
              onClick={viewLeadCardHandler}
            >
              View lead
            </div>
          )}
        </div>
      );

      setSnackState((snackState: any) => ({
        ...snackState,
        message: toastMessage,
        snackOpen: snackOpen,
        snackSeverity: snackSeverity,
      }));

      const timeoutId = setTimeout(() => {
        dispatch(
          setToastNotification({
            message: "",
            type: "",
            snackOpen: false,
          })
        );
        dispatch(resetLeadCreateErrorData());
        dispatch(setLeadExistData(null));

        setSnackState((snackState: any) => ({
          ...snackState,
          message: "",
          snackOpen: false,
          snackSeverity: "",
        }));
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [
    snackOpen,
    message,
    snackSeverity,
    leadCreateErrorData,
    leadUpdateErrorData,
  ]);

  return (
    <>
      {snackState?.message !== undefined && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackState?.snackOpen}
          onClose={closeSnack}
          key={vertical + horizontal}
          autoHideDuration={3000}
          sx={{
            zIndex: "999999999",
            borderRadius: "8PX",
            background:
              snackState?.snackSeverity === "success" ? "#DCFCE7" : "inherit",
            color:
              snackState?.snackSeverity === "success" ? "#14532D" : "inherit",
            fontSize: "14px",
          }}
        >
          <Alert
            //   severity={snackSeverity}
            severity={snackState?.snackSeverity}
            action={action}
            icon={
              snackState?.snackSeverity === "success" ? (
                <img src={checkCircle} alt="check circle" />
              ) : null
            }
          >
            {snackState?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ToastNotification;
