import React from "react";
import close from "../../../assets/images/close.svg";
import styles from "../FilterDrawer.module.css";

function FilterChips(props: any) {
  const { closeFunction, keyVal, chipName, clearValue } = props;
  return (
    <>
      <div key={keyVal} className={styles.filterChipsWrap}>
        {chipName}
        <button
          className={styles.filterChipsClsBtn}
          onClick={() => closeFunction(clearValue)}
        >
          <img src={close} alt="close" />
        </button>
      </div>
    </>
  );
}

export default FilterChips;
