import { createAsyncThunk, AnyAction, createSlice } from "@reduxjs/toolkit";
import {
  AddEnquiryDataFromContact,
  DeactivateInboundData,
  DeleteContactData,
  DeleteInboundEmailData,
  EmailConfigDTO,
  EmailConfigData,
  ImapSyncData,
  ImportCSVData,
  InboundInitialStateDTO,
} from "./types";
import {
  ConfigEmail,
  CreateLeadFromContacts,
  DeactivateInbound,
  DeleteContactList,
  DeleteInboundEmail,
  DownloadCSVTemplate,
  DownloadImportedHistory,
  GetContactList,
  GetImportHistory,
  GetMailConfigsList,
  GetMailDetailsById,
  GetMailEntriesByInstitute,
  ImportCSVFile,
  SaveEmailConfigDetails,
  SaveImapEmailConfigDetails,
  SyncImapEmailConfigDetails,
} from "./inbound.request";
import { Form } from "react-router-dom";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: InboundInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  mailList: null,
  mailById: null,
  mailConfigsList: null,
  importHistoryData: null,
  templateData: null,
  contactList: null,
  importCsvData: null,
  downloadHistoryData: null,

  emailConfigSuccess: false,
  emailConfigError: false,
  emailConfigLoading: false,
  deleteEmailError: false,
  deleteEmailSuccess: false,
  deleteEmailLoading: false,
  imapSyncLoading: false,
  imapSyncSuccess: false,
  imapSyncError: false,
  emailDetailsLoading: false,
  emailDetailsSuccess: false,
  emailDetailsError: false,
  templateDownloadError: false,
  templateDownloadLoading: false,
  templateDownloadSuccess: false,
  importCsvLoading: false,
  importCsvSuccess: false,
  importCsvError: false,
  contactListLoading: false,
  contactListSuccess: false,
  contactListError: false,
  deleteContactLoading: false,
  deleteContactSuccess: false,
  deleteContactError: false,
  addedEnquiryContactLoading: false,
  addedEnquiryContactSuccess: false,
  addedEnquiryContactError: false,
  otherEmailConfigLoading: false,
  otherEmailConfigSuccess: false,
  otherEmailConfigError: false,
  importHistoryLoading: false,
  downloadHistoryDataSuccess: false,
  downloadHistoryDataError: false,
  downloadHistoryDataLoading: false,
  inboundDeactivateError: false,
  inboundDeactivateSuccess: false,
  inboundDeactivateLoading: false,
};

export const configEmail = createAsyncThunk(
  "user/configEmail",
  async (data: EmailConfigDTO, thunkAPI: any) => {
    return ConfigEmail(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getMailConfigsList = createAsyncThunk(
  "user/getMailConfigsList",
  async (id: string, thunkAPI: any) => {
    return GetMailConfigsList(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getMailEntriesByInstitute = createAsyncThunk(
  "user/getMailEntriesByInstitute",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;

    return GetMailEntriesByInstitute(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const saveEmailConfigDetails = createAsyncThunk(
  "/configDetails",
  async (data: EmailConfigData, thunkAPI) => {
    return SaveEmailConfigDetails(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getMailDetailsById = createAsyncThunk(
  "user/getMailDetailsById",
  async (id: string, thunkAPI: any) => {
    return GetMailDetailsById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const deactivateInbound = createAsyncThunk(
  "user/deactivateInbound",
  async (data: DeactivateInboundData, thunkAPI: any) => {
    return DeactivateInbound(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deleteInboundEmail = createAsyncThunk(
  "user/deleteInboundEmail",
  async (data: DeleteInboundEmailData, thunkAPI: any) => {
    return DeleteInboundEmail(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const imapSyncEmail = createAsyncThunk(
  "user/imapSyncEmail",
  async (data: ImapSyncData, thunkAPI: any) => {
    return SyncImapEmailConfigDetails(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getCsvTemplate = createAsyncThunk(
  "user/inboundCsv",
  async (data: any) => {
    const { id, countryCode } = data;
    return DownloadCSVTemplate(id, countryCode)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const importCsvFile = createAsyncThunk(
  "user/inboundImportCsv",
  async (body: ImportCSVData, thunkAPI: any) => {
    return ImportCSVFile(body)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getImportHistory = createAsyncThunk(
  "user/getImportHistory",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey } = data;

    return GetImportHistory(id, lastEvaluatedKey, limit, lastEvaluatedSortKey)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getContactList = createAsyncThunk(
  "user/contactList",
  async (
    data: {
      id: string;
      lastEvaluatedKey: string;
      limit: number;
      lastEvaluatedSortKey: string;
      formId?: string;
    },
    thunkAPI: any
  ) => {
    const { id, lastEvaluatedKey, limit, lastEvaluatedSortKey, formId } = data;
    return GetContactList(
      id,
      lastEvaluatedKey,
      limit,
      lastEvaluatedSortKey,
      formId
    )
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const deleteContactList = createAsyncThunk(
  "user/deleteContactList",
  async (data: DeleteContactData, thunkAPI: any) => {
    return DeleteContactList(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);
export const addEnquiryForContact = createAsyncThunk(
  "user/addEnquiryForContact",
  async (data: AddEnquiryDataFromContact, thunkAPI: any) => {
    return CreateLeadFromContacts(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const saveOtherEmailConfigDetails = createAsyncThunk(
  "/saveOtherEmailConfigDetails",
  async (data: EmailConfigData, thunkAPI) => {
    return SaveImapEmailConfigDetails(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const downloadImportHistoryData = createAsyncThunk(
  "user/downloadImportHistoryData",
  async (data: any, thunkAPI: any) => {
    return DownloadImportedHistory(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const inboundSlice = createSlice({
  name: "inbound",
  initialState,
  reducers: {
    reset: () => initialState,
    resetDeleteContactSuccess: (state) => {
      state.deleteContactSuccess = false;
    },
    resetAddEnquirySuccess: (state) => {
      state.addedEnquiryContactSuccess = false;
    },
    resetDeleteEmailSuccess: (state) => {
      state.deleteEmailSuccess = false;
    },
    resetOtherEmailStatuses: (state) => {
      state.otherEmailConfigSuccess = false;
      state.otherEmailConfigError = false;
    },
    resetImportStatuses: (state) => {
      state.downloadHistoryDataSuccess = false;
      state.downloadHistoryData = null;
      state.downloadHistoryDataError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(configEmail.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(configEmail.fulfilled, (state, action: AnyAction) => {
      state.loading = false;
      state.success = action?.payload?.message || "Email configured";
      state.error = false;
    });
    builder.addCase(configEmail.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getMailConfigsList.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getMailConfigsList.fulfilled,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.mailConfigsList = action?.payload;
      }
    );
    builder.addCase(getMailConfigsList.rejected, (state, action: AnyAction) => {
      state.loading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getMailEntriesByInstitute.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getMailEntriesByInstitute.fulfilled,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = true;
        state.error = false;
        state.mailList = action?.payload;
      }
    );
    builder.addCase(
      getMailEntriesByInstitute.rejected,
      (state, action: AnyAction) => {
        state.loading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(saveEmailConfigDetails.pending, (state) => {
      state.emailConfigLoading = true;
      state.emailConfigSuccess = false;
      state.emailConfigError = false;
    });
    builder.addCase(
      saveEmailConfigDetails.fulfilled,
      (state, action: AnyAction) => {
        state.emailConfigLoading = false;
        state.emailConfigSuccess =
          action?.payload?.message || "Saved Email config details";
        state.emailConfigError = false;
      }
    );
    builder.addCase(
      saveEmailConfigDetails.rejected,
      (state, action: AnyAction) => {
        state.emailConfigLoading = false;
        state.emailConfigSuccess = false;
        state.emailConfigError =
          action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(getMailDetailsById.pending, (state) => {
      state.emailDetailsLoading = true;
      state.emailDetailsSuccess = false;
      state.emailDetailsError = false;
    });
    builder.addCase(
      getMailDetailsById.fulfilled,
      (state, action: AnyAction) => {
        state.emailDetailsLoading = false;
        state.emailDetailsSuccess = true;
        state.emailDetailsError = false;
        state.mailById = action?.payload;
      }
    );
    builder.addCase(getMailDetailsById.rejected, (state, action: AnyAction) => {
      state.emailDetailsLoading = false;
      state.emailDetailsSuccess = false;
      state.emailDetailsError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(deleteInboundEmail.pending, (state) => {
      state.deleteEmailLoading = true;
      state.deleteEmailSuccess = false;
      state.deleteEmailError = false;
    });
    builder.addCase(
      deleteInboundEmail.fulfilled,
      (state, action: AnyAction) => {
        state.deleteEmailLoading = false;
        state.deleteEmailSuccess = action?.payload?.message || "Email removed";
        state.deleteEmailError = false;
      }
    );
    builder.addCase(deleteInboundEmail.rejected, (state, action: AnyAction) => {
      state.deleteEmailLoading = false;
      state.deleteEmailSuccess = false;
      state.deleteEmailError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(imapSyncEmail.pending, (state) => {
      state.imapSyncLoading = true;
      state.imapSyncSuccess = false;
      state.imapSyncError = false;
    });
    builder.addCase(imapSyncEmail.fulfilled, (state, action: AnyAction) => {
      state.imapSyncLoading = false;
      state.imapSyncSuccess = action?.payload?.message || "Email configured";
      state.imapSyncError = false;
    });
    builder.addCase(imapSyncEmail.rejected, (state, action: AnyAction) => {
      state.imapSyncLoading = false;
      state.imapSyncSuccess = false;
      state.imapSyncError = action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getCsvTemplate.pending, (state) => {
      state.templateDownloadLoading = true;
      state.templateDownloadSuccess = false;
      state.templateDownloadError = false;
    });
    builder.addCase(getCsvTemplate.fulfilled, (state, action: AnyAction) => {
      state.templateDownloadLoading = false;
      state.templateDownloadSuccess = true;
      state.templateDownloadError = false;
      state.templateData = action?.payload;
    });
    builder.addCase(getCsvTemplate.rejected, (state, action: AnyAction) => {
      state.templateDownloadLoading = false;
      state.templateDownloadSuccess = false;
      state.templateDownloadError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(importCsvFile.pending, (state) => {
      state.importCsvLoading = true;
      state.importCsvSuccess = false;
      state.importCsvError = false;
    });
    builder.addCase(importCsvFile.fulfilled, (state, action: AnyAction) => {
      state.importCsvLoading = false;
      state.importCsvSuccess = action?.payload?.message || "File imported";
      state.importCsvError = false;
      state.importCsvData = action?.payload;
    });
    builder.addCase(importCsvFile.rejected, (state, action: AnyAction) => {
      state.importCsvLoading = false;
      state.importCsvSuccess = false;
      state.importCsvError = action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getContactList.pending, (state) => {
      state.contactListLoading = true;
      state.contactListSuccess = false;
      state.contactListError = false;
    });
    builder.addCase(getContactList.fulfilled, (state, action: AnyAction) => {
      state.contactListLoading = false;
      state.contactListSuccess = true;
      state.contactListError = false;
      state.contactList = action.payload;
    });
    builder.addCase(getContactList.rejected, (state, action: AnyAction) => {
      state.contactListLoading = false;
      state.contactListSuccess = false;
      state.contactListError = action?.error?.message || "Something went wrong";
    });
    builder.addCase(deleteContactList.pending, (state) => {
      state.deleteContactLoading = true;
      state.deleteContactSuccess = false;
      state.deleteContactError = false;
    });
    builder.addCase(deleteContactList.fulfilled, (state, action: AnyAction) => {
      state.deleteContactLoading = false;
      state.deleteContactSuccess = action?.payload?.message || "Deleted";
      state.deleteContactError = false;
    });
    builder.addCase(deleteContactList.rejected, (state, action: AnyAction) => {
      state.deleteContactLoading = false;
      state.deleteContactSuccess = false;
      state.deleteContactError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(addEnquiryForContact.pending, (state) => {
      state.addedEnquiryContactLoading = true;
      state.addedEnquiryContactSuccess = false;
      state.addedEnquiryContactError = false;
    });
    builder.addCase(
      addEnquiryForContact.fulfilled,
      (state, action: AnyAction) => {
        state.addedEnquiryContactLoading = false;
        state.addedEnquiryContactSuccess =
          action?.payload?.message || "Lead added";
        state.addedEnquiryContactError = false;
      }
    );
    builder.addCase(
      addEnquiryForContact.rejected,
      (state, action: AnyAction) => {
        state.addedEnquiryContactLoading = false;
        state.addedEnquiryContactSuccess = false;
        state.addedEnquiryContactError =
          action?.payload?.message || "Something went wrong";
      }
    );
    builder.addCase(saveOtherEmailConfigDetails.pending, (state) => {
      state.otherEmailConfigLoading = true;
      state.otherEmailConfigSuccess = false;
      state.otherEmailConfigError = false;
    });
    builder.addCase(
      saveOtherEmailConfigDetails.fulfilled,
      (state, action: AnyAction) => {
        state.otherEmailConfigLoading = false;
        state.otherEmailConfigSuccess =
          action?.payload?.message || "Email synced successfully";
        state.otherEmailConfigError = false;
      }
    );
    builder.addCase(
      saveOtherEmailConfigDetails.rejected,
      (state, action: AnyAction) => {
        state.otherEmailConfigLoading = false;
        state.otherEmailConfigSuccess = false;
        state.otherEmailConfigError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getImportHistory.pending, (state) => {
      state.importHistoryLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getImportHistory.fulfilled, (state, action: AnyAction) => {
      state.importHistoryLoading = false;
      state.success = true;
      state.error = false;
      state.importHistoryData = action.payload;
    });
    builder.addCase(getImportHistory.rejected, (state, action: AnyAction) => {
      state.importHistoryLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });

    builder.addCase(downloadImportHistoryData.pending, (state) => {
      state.downloadHistoryDataLoading = true;
      state.downloadHistoryDataSuccess = false;
      state.downloadHistoryDataError = false;
    });
    builder.addCase(
      downloadImportHistoryData.fulfilled,
      (state, action: AnyAction) => {
        state.downloadHistoryDataLoading = false;
        state.downloadHistoryDataSuccess = true;
        state.downloadHistoryDataError = false;
        state.downloadHistoryData = action.payload;
      }
    );
    builder.addCase(
      downloadImportHistoryData.rejected,
      (state, action: AnyAction) => {
        state.downloadHistoryDataLoading = false;
        state.downloadHistoryDataSuccess = false;
        state.downloadHistoryDataError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(deactivateInbound.pending, (state) => {
      state.inboundDeactivateLoading = true;
      state.inboundDeactivateSuccess = false;
      state.inboundDeactivateError = false;
    });
    builder.addCase(deactivateInbound.fulfilled, (state, action: AnyAction) => {
      state.inboundDeactivateLoading = false;
      state.inboundDeactivateSuccess = true;
      state.inboundDeactivateError = false;
    });
    builder.addCase(deactivateInbound.rejected, (state, action: AnyAction) => {
      state.inboundDeactivateLoading = false;
      state.inboundDeactivateSuccess = false;
      state.inboundDeactivateError =
        action?.error?.message || "Something went wrong";
    });
  },
});

export default inboundSlice.reducer;
export const {
  reset,
  resetAddEnquirySuccess,
  resetDeleteContactSuccess,
  resetDeleteEmailSuccess,
  resetOtherEmailStatuses,
  resetImportStatuses,
} = inboundSlice.actions;
