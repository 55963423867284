import React from "react";
import styles from "../DemoBooking.module.css";
import dateIcon from "../Assets/calander.svg";
import clockIcon from "../Assets//clock.svg";
import { Button, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import demoCompleted from "../Assets/batch-dot.svg";
import demoAbsent from "../Assets/demoAbsent.svg";
import { convertToAdjustedTimestamp } from "../../../../../../components/Formatter/Formatter";
import getTeamMember from "../../../../../../components/CommonFunctions/CommonFunctions";
import { useSelector } from "react-redux";

function DemoBookedScreen(props: any) {
  const {
    demos,
    setStage,
    handleDemoEdit,
    handleCancelModalOpen,
    batchList,
    demosByCourse,
    hasLeadAccess,
  } = props;
  const employees = useSelector((state: any) => state.staff.staffList);

  function calculateDemoBookedCount(batchId: string, demoTiming: any) {
    const batch = batchList?.find((batch: any) => batch.id === batchId);
    const demosArray = demosByCourse?.filter(
      (item: any) => item.batchId === batchId && item.demoTiming === demoTiming
    );
    // if (batch) {
    //   if (batch.batchConductType === "DEMO_BATCH") {
    //     return batch?.demoStudents?.length || 0;
    //   } else if (batch.batchConductType === "REGULAR_BATCH") {
    //     return batch?.demoStudents?.length || 0;
    //   }
    // }

    return demosArray?.length;
  }

  return (
    <div className={styles.demoSelectedWrapper}>
      <div
        className={styles.demoBookedWrapperChild}
        style={{
          maxHeight: "calc(406px - 55px)",
          minHeight: "calc(406px - 55px)",
          overflowY: "auto",
        }}
      >
        {demos?.length > 0 &&
          demos?.map((item: any) => (
            <div
              className={styles.demoBookedCard}
              style={{
                border:
                  item?.status === "ABSENT"
                    ? "1px solid rgba(236, 57, 57, 0.20)"
                    : "1px solid rgba(17, 200, 155, 0.20)",
                background:
                  item?.status === "ABSENT"
                    ? "rgba(236, 57, 57, 0.02)"
                    : "rgba(17, 200, 155, 0.02)",
              }}
            >
              <div className={styles.demoBookedHighlightWrap}>
                {item?.status === "ABSENT" ||
                  (item.status === "COMPLETED" && (
                    <div className={styles.demoBookedHighlightIcon}>
                      <img
                        src={
                          item?.status === "ABSENT"
                            ? demoAbsent
                            : item.status === "COMPLETED"
                            ? demoCompleted
                            : ""
                        }
                        alt=""
                      />
                    </div>
                  ))}

                <div
                  className={styles.demoBookedHighlightLabel}
                  style={{
                    color: item.status === "ABSENT" ? "#EC3939" : "#11C89B",
                  }}
                >
                  {item?.status === "BOOKED"
                    ? "Booked Demo"
                    : item.status === "COMPLETED"
                    ? "Demo Booked Completed"
                    : item.status === "ABSENT"
                    ? "Demo Absentee"
                    : "Booked Demo"}
                </div>
              </div>
              <div
                className={styles.demoCardHead}
                style={{ marginBottom: "9px" }}
              >
                <div className={styles.demoCardName}>{item?.batchName}</div>
                <div className={styles.demoCardType}>{item?.demoType}</div>
              </div>

              <div
                className={styles.demoCardBatchDetails}
                style={{ marginBottom: "15px" }}
              >
                <div className={styles.demoCardBatchItem}>
                  <div className={styles.demoCardBatchItemIcon}>
                    <img src={dateIcon} alt="dateIcon" />
                  </div>
                  <div className={styles.demoCardBatchItemValue}>
                    {moment
                      .unix(item?.demoTiming / 1000)
                      .format("DD MMM YYYY, ddd")}
                  </div>
                </div>
                <div className={styles.demoCardBatchItem}>
                  <div className={styles.demoCardBatchItemIcon}>
                    <img src={clockIcon} alt="clockIcon" />
                  </div>
                  <div className={styles.demoCardBatchItemValue}>
                    <>
                      {(item?.demoStartTime?.toString().length === 10
                        ? moment.unix(item?.demoStartTime).format("hh:mm A")
                        : moment(item?.demoStartTime).format("hh:mm A")) +
                        " - " +
                        (item?.demoEndTime?.toString().length === 10
                          ? moment.unix(item?.demoEndTime).format("hh:mm A")
                          : moment(item?.demoEndTime)?.format("hh:mm A"))}
                    </>
                  </div>
                </div>
              </div>
              <div className={styles.demoCardBottom}>
                <div className={styles.demoCardCount}>
                  <div className={styles.demoCardCountLabel}>Demo Booked</div>
                  <div className={styles.demoCardCountValue}>
                    {calculateDemoBookedCount(item.batchId, item?.demoTiming)}
                  </div>
                </div>
                <div className={styles.demoCardBook}></div>
              </div>
              <div
                style={{
                  marginTop: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div className={styles.demoBookedCardSelectedAvatar}>
                  {getTeamMember(item?.assignedToId, employees)
                    ?.firstName?.charAt(0)
                    ?.toUpperCase()}
                  {getTeamMember(item?.assignedToId, employees)
                    ?.lastName?.charAt(0)
                    ?.toUpperCase()}
                  {/* {item?.assignedToName
                    ?.split(" ")
                    ?.slice(0, 2)
                    ?.map((word: any, index: number) => (
                      <span key={index}>{word?.charAt(0)}</span>
                    ))} */}
                </div>
                {item?.status === "BOOKED" ? (
                  <>
                    {" "}
                    <div className={styles.demoBookedCardBtns}>
                      <Tooltip
                        arrow
                        title={
                          hasLeadAccess === false ? "Oops! No permission." : ""
                        }
                        PopperProps={{ style: { zIndex: 9999999 } }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              zIndex: "9999999",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                                zIndex: "9999999",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <button
                            className={styles.resheduleBtn}
                            onClick={() => {
                              handleDemoEdit(item);
                            }}
                            disabled={hasLeadAccess === false ? true : false}
                          >
                            Reschedule
                          </button>
                        </span>
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={
                          hasLeadAccess === false ? "Oops! No permission." : ""
                        }
                        PopperProps={{ style: { zIndex: 9999999 } }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              zIndex: "9999999",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                                zIndex: "9999999",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <button
                            className={styles.demoCancelBtn}
                            onClick={() =>
                              handleCancelModalOpen(
                                item.id,
                                item.batchId,
                                item.enquiryId
                              )
                            }
                            disabled={hasLeadAccess === false ? true : false}
                          >
                            Cancel
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
      </div>

      <div className={styles.existingDemoScreenBottom} style={{ gap: "5px" }}>
        <Tooltip
          arrow
          title={hasLeadAccess === false ? "Oops! No permission." : ""}
          PopperProps={{ style: { zIndex: 9999999 } }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                zIndex: "9999999",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                  zIndex: "9999999",
                },
              },
            },
          }}
        >
          <span>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                fontSize: 14,
                textTransform: "capitalize",
                boxShadow: "none",
                height: "38px",
              }}
              size={"small"}
              onClick={() => {
                setStage(1);
              }}
              disabled={hasLeadAccess === false ? true : false}
            >
              <AddIcon sx={{ marginRight: "10px", fontSize: "18px" }} />
              Book a New Demo
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default DemoBookedScreen;
