import { Box, Button, Checkbox, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./AttendanceDetails.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
import { GetBatchById } from "../../Manage/ManageBatch/service/Batch.request";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import present from "../../../../assets/images/present.svg";
import absent from "../../../../assets/images/absent.svg";
import EditIconBtn from "../../../../components/Buttons/EditIconBtn";
import AttendanceDetailsSkeleton from "../../../../components/Skeletons/Attendance/AttendanceDetailsSkeleton";
import StudentListSkeleton from "../../../../components/Skeletons/Attendance/StudentListSkeleton";
import { resetAddUpdateAttendanceSuccess } from "../service/attendanceSlice";
import { getSubDomainAccessStatus } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import CancelBtn from "../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../components/Buttons/SaveBtn";

const AttendanceDetails = (props: any) => {
  const {
    GetAttendanceList,
    attendanceListData,
    GetAttendanceStudentList,
    GetAttendanceSave,
    GetAttendanceEdit,
    attendanceStudentListData,
    attendanceStudentListLoading,
    attendanceStudentSaveSuccess,
    attendanceStudentSaveLoading,
    attendanceStudentEditSuccess,
    attendanceStudentEditLoading,
  } = props;
  const [selectedDate, setSelectedDate] = React.useState<number | null>(
    dayjs().unix() * 1000
  );
  const [searchStudent, setSearchStudent] = useState("");
  const [batchDetails, setBatchDetails] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const user = auth();
  const { id } = useParams();
  const location = useLocation();
  const [studentData, setStudentData] = useState<any>();
  const [markAll, setMarkAll] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<any[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAttendanceAlreadyTaken, setIsAttendanceAlreadyTaken] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasAllBatchAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BATCHES",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const hasAllBatchViewAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BATCHES",
    "VIEW_ONLY",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  useEffect(() => {
    const privilagedUser =
      user?.institute[0]?.role === "Super Admin" ||
      user?.institute[0]?.role === "Admin";

    const data = {
      userId: privilagedUser
        ? user?.institute[0]?.instituteId
        : user?.institute[0]?.id,
      timestamp: selectedDate,
      isAdmin: privilagedUser,
    };
    GetAttendanceList(data);
  }, [GetAttendanceList]);

  useEffect(() => {
    const batchId: any = id;
    setLoading(true);
    GetBatchById(batchId)
      .then((res: any) => {
        setBatchDetails(res?.data?.data);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getStudentList();
  }, [GetAttendanceStudentList]);

  const getStudentList = () => {
    const date = new Date(location?.state?.selectedDate);
    const dateForAttendance = date.setUTCHours(0, 0, 0, 0);
    const data = {
      batchId: id,
      attendanceDate: dateForAttendance,
      batchStartTime: location?.state?.item?.batchStartTime,
      batchEndTime: location?.state?.item?.batchEndTime,
    };
    GetAttendanceStudentList(data);
    dispatch(resetAddUpdateAttendanceSuccess());
  };

  useEffect(() => {
    if (attendanceStudentListData) {
      setStudentData(attendanceStudentListData);
      setIsAttendanceAlreadyTaken(attendanceStudentListData?.attendanceDone);
      const markAllResult =
        attendanceStudentListData?.studentAttendanceList?.every(
          (student: any) => student?.attendanceStatus === "PRESENT"
        );
      setMarkAll(markAllResult);
    }
  }, [attendanceStudentListData]);

  useEffect(() => {
    if (
      attendanceStudentListData &&
      attendanceStudentListData?.attendanceDone === false
    ) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [setIsEditMode, attendanceStudentListData]);

  useEffect(() => {
    const result = attendanceStudentListData?.studentAttendanceList?.map(
      (student: any) => {
        if (student?.attendanceStatus === "PRESENT") {
          return student?.studentId;
        }
      }
    );
    setSelectedStudents(result);
  }, [isEditMode, attendanceStudentListData]);

  useEffect(() => {
    if (
      attendanceStudentSaveSuccess === true ||
      attendanceStudentEditSuccess === true
    ) {
      setIsEditMode(false);
      getStudentList();
    }
  }, [attendanceStudentSaveSuccess, attendanceStudentEditSuccess]);

  const toggleStudentSelection = (studentId: string) => {
    setSelectedStudents((prevSelected: any) => {
      let updatedSelectedStudents;
      if (prevSelected.includes(studentId)) {
        updatedSelectedStudents = prevSelected.filter(
          (id: any) => id !== studentId
        );
      } else {
        updatedSelectedStudents = [...prevSelected, studentId];
      }

      if (areAllStudentsSelected(updatedSelectedStudents)) {
        setMarkAll(true);
      } else {
        setMarkAll(false);
      }

      return updatedSelectedStudents;
    });
  };

  const areAllStudentsSelected = (selectedStudentIds: string[]) => {
    const studentIds = studentData?.studentAttendanceList?.map(
      (student: any) => student?.studentId
    );
    return studentIds?.every((id: string) => selectedStudentIds?.includes(id));
  };

  const handleMarkAll = () => {
    setMarkAll(!markAll);
    if (markAll === false) {
      const result = attendanceStudentListData?.studentAttendanceList?.map(
        (student: any) => {
          return student?.studentId;
        }
      );
      setSelectedStudents(result);
    } else {
      const result = attendanceStudentListData?.studentAttendanceList?.map(
        (student: any) => {
          return undefined;
        }
      );
      setSelectedStudents(result);
    }
  };

  const handleSubmit = async () => {
    const studentsList = studentData?.studentAttendanceList?.map((d: any) => {
      return {
        studentId: d?.studentId,
        studentName: d?.studentName,
        studentEmail: d?.studentEmail,
        studentContact: d?.studentContact,
        attendanceStatus: selectedStudents?.includes(d?.studentId)
          ? "PRESENT"
          : "ABSENT",
        studentBatchMappingId: d?.studentBatchMappingId,
      };
    });
    const date = new Date(location?.state?.selectedDate);
    const dateForAttendance = date.setUTCHours(0, 0, 0, 0);

    const data = {
      batchStartTime: location?.state?.item?.batchStartTime,
      batchEndTime: location?.state?.item?.batchEndTime,
      attendanceDate: dateForAttendance,
      batchId: location?.state?.item?.id,
      createdById: user?.institute[0]?.id,
      createdByName: `${user?.institute[0]?.firstName} ${user?.institute[0]?.lastName}`,
      studentList: studentsList,
    };
    const editedData = {
      batchStartTime: location?.state?.item?.batchStartTime,
      batchEndTime: location?.state?.item?.batchEndTime,
      attendanceDate: dateForAttendance,
      batchId: location?.state?.item?.id,
      updatedById: user?.institute[0]?.id,
      updatedByName: `${user?.institute[0]?.firstName} ${user?.institute[0]?.lastName}`,
      studentList: studentsList,
    };
    isAttendanceAlreadyTaken
      ? GetAttendanceEdit(editedData)
      : GetAttendanceSave(data);
  };

  const handleSearch = (event: any) => {
    setSearchStudent(event.target.value);
  };

  const filteredStudentList =
    studentData && studentData?.studentAttendanceList
      ? studentData?.studentAttendanceList?.filter((contact: any) => {
          const searchTermLower = searchStudent?.toLowerCase();
          const fullName = `${contact?.studentName}`;
          const nameMatch = fullName?.toLowerCase().includes(searchTermLower);
          const emailMatch = contact?.studentEmail
            .toLowerCase()
            .includes(searchTermLower);
          const phoneMatch =
            searchTermLower === "" ||
            (contact?.studentContact &&
              contact?.studentContact?.toString()?.includes(searchTermLower));

          return nameMatch || emailMatch || phoneMatch;
        })
      : [];

  const urlSearchParams = new URLSearchParams(window.location.search);
  const selectedDateForCalendar: any = urlSearchParams.get("selectedDate");

  const givenDate = new Date(Number(selectedDateForCalendar))
    .toISOString()
    .split("T")[0];

  const todayDate = new Date().toISOString().split("T")[0];

  const datesMatch = givenDate === todayDate;

  const result =
    batchDetails?.assignees?.length > 1
      ? batchDetails?.assignees
          ?.map((item: any) => item?.assignedToName)
          .join(", ")
      : batchDetails?.assignees[0]?.assignedToName;

  return (
    <Box
      sx={{
        overflow: "hidden",
        padding: "14px 16px",
        background: "#EEEEEE",
        maxHeight: "90vh",
        position: "relative",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          display: "flex",
          width: "100%",
          //   maxHeight: "100vh",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            width: "100%",
            padding: "20px",
          }}
        >
          <div className={styles.PageBackBtnWrap}>
            <button
              className={styles.PageBackBtn}
              onClick={() =>
                navigate(`/app/Attendance`, {
                  state: { selectedDate: selectedDateForCalendar },
                })
              }
            >
              <ArrowBackIcon
                sx={{
                  marginRight: "8px",
                  fontSize: "15PX",
                  color: "#667085",
                }}
              />
              Back
            </button>
          </div>
          <div
            style={{
              overflowY: "scroll",
              height: "78vh",
              scrollbarWidth: "none",
            }}
          >
            {loading ? (
              <AttendanceDetailsSkeleton />
            ) : (
              <div className={styles.batchDetailsContainer}>
                <div className={styles.batchDetailsHeader}>
                  <div className={styles.batchNameContainer}>
                    <div className={styles.batchName}>
                      {batchDetails?.batchName}
                    </div>
                    <div className={styles.batchCity}>{batchDetails?.city}</div>
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(236, 253, 243, 1)",
                      backgroundColor: "#ECFDF3",
                      padding: "2px",
                      borderRadius: "8px",
                      color: "#0CB78D",
                      fontSize: "12px",
                      width: "50px",
                      textAlign: "center",
                    }}
                  >
                    {batchDetails?.status === "ACTIVE"
                      ? "Active"
                      : batchDetails?.status === "DRAFT"
                      ? "Draft"
                      : "Inactive"}
                  </div>
                </div>
                <div
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    className={styles.batchDetailInfoContainer}
                    style={{ width: "33%" }}
                  >
                    <div className={styles.batchInfoHeader}>Course</div>
                    <div className={styles.courseName}>
                      {batchDetails?.courseName}
                    </div>
                  </div>
                  <div className={styles.batchDetailsSection}>
                    <div
                      className={styles.batchDetailInfoContainer}
                      style={{ width: "33%" }}
                    >
                      <div className={styles.batchInfoHeader}>Type</div>
                      <div className={styles.courseName}>
                        {batchDetails?.batchType[0]?.batchType?.courseTypeName}
                      </div>
                    </div>
                    <div
                      className={styles.batchDetailInfoContainer}
                      style={{ width: "33%" }}
                    >
                      <div className={styles.batchInfoHeader}>Status</div>
                      <div
                        style={{
                          backgroundColor: "#11C89B",
                          padding: "2px",
                          borderRadius: "8px",
                          color: "#fff",
                          fontSize: "12px",
                          width: "70px",
                          textAlign: "center",
                        }}
                      >
                        {batchDetails?.batchStatus === "ONGOING"
                          ? "Ongoing"
                          : "Upcoming"}
                      </div>
                    </div>

                    <div
                      className={styles.batchDetailInfoContainer}
                      style={{ width: "33%" }}
                    >
                      <div className={styles.batchInfoHeader}>Conduct Type</div>
                      <div
                        style={{
                          backgroundColor: "#F2F4F7",
                          padding: "2px",
                          borderRadius: "8px",
                          color: "#344054",
                          fontSize: "12px",
                          width: "70px",
                          textAlign: "center",
                        }}
                      >
                        {batchDetails?.batchConductType === "REGULAR_BATCH"
                          ? "Regular"
                          : batchDetails?.batchConductType === "DEMO_BATCH"
                          ? "Demo"
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.batchDetailsSection}>
                    <div
                      className={styles.batchDetailInfoContainer}
                      style={{ width: "33%" }}
                    >
                      <div className={styles.batchInfoHeader}>Min Seats</div>
                      <div className={styles.courseName}>
                        {batchDetails?.minimumSeats}
                      </div>
                    </div>
                    <div
                      className={styles.batchDetailInfoContainer}
                      style={{ width: "33%" }}
                    >
                      <div className={styles.batchInfoHeader}>Max Seats</div>
                      <div className={styles.courseName}>
                        {batchDetails?.maximumSeats}
                      </div>
                    </div>
                    <div
                      className={styles.batchDetailInfoContainer}
                      style={{ width: "33%" }}
                    >
                      <div className={styles.batchInfoHeader}>Assignee</div>
                      <div
                        className={styles.courseName}
                        style={{
                          fontSize:
                            batchDetails?.assignees?.length > 2
                              ? "12px"
                              : "14px",
                        }}
                      >
                        {result}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={styles.batchDetailsContainer}
              style={{ marginTop: "20px" }}
            >
              <div
                className={styles.studentSectionHeader}
                style={{ padding: "10px" }}
              >
                <div className={styles.studentHeaderTitle}>
                  Students (
                  {studentData?.studentAttendanceList?.length > 0
                    ? studentData?.studentAttendanceList?.length
                    : 0}
                  )
                </div>
                <div className={styles.studentSectionHeader}>
                  {isEditMode === true && filteredStudentList?.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            fontSize: "12px",
                            alignItems: "center",
                            margin: "10px 10px 10px 0px",
                            color: "#667085",
                          }}
                        >
                          <Checkbox
                            sx={{
                              strokeWidth: 1,
                              color: "rgba(102, 112, 133, 0.30)",
                            }}
                            checked={markAll}
                            onChange={(event: any) => {
                              setMarkAll(event.target.checked);
                              handleMarkAll();
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          Mark all as present
                        </div>
                        {/* <TextField
                          type="search"
                          value={searchStudent}
                          onChange={(e) => handleSearch(e)}
                          placeholder="Search students"
                          sx={{
                            width: "380px",
                            borderRadius: "4px",
                          }}
                          size="small"
                          inputProps={{
                            sx: {
                              width: "380px",
                              "&::placeholder": {
                                fontWeight: "400",
                                fontSize: "12px",
                              },

                              "& .MuiOutlinedInput-input": {
                                padding: "10.5px 14px",
                              },
                            },
                          }}
                        /> */}
                      </div>
                    </>
                  ) : (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                      <TextField
                        type="search"
                        value={searchStudent}
                        onChange={(e) => handleSearch(e)}
                        placeholder="Search students"
                        sx={{
                          width: "380px",
                          borderRadius: "4px",
                        }}
                        size="small"
                        inputProps={{
                          sx: {
                            width: "380px",
                            "&::placeholder": {
                              fontWeight: "400",
                              fontSize: "12px",
                            },

                            "& .MuiOutlinedInput-input": {
                              padding: "10.5px 14px",
                            },
                          },
                        }}
                      />
                      {filteredStudentList?.length > 0 &&
                        (user?.institute[0]?.role === "Super Admin" ||
                          user?.institute[0]?.role === "Admin" ||
                          ((hasAllBatchAccess || hasAllBatchViewAccess) &&
                            datesMatch)) && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              border: "1px solid #D0D5DD",
                              padding: "10px",
                              borderRadius: "6px",
                            }}
                          >
                            <EditIconBtn
                              onClick={() => {
                                setIsEditMode(true);
                                setStudentData(attendanceStudentListData);
                                setSearchStudent("");
                              }}
                            />
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </div>
              {attendanceStudentListLoading ? (
                <StudentListSkeleton />
              ) : (
                <>
                  <div className={styles.studentSectionBody}>
                    {filteredStudentList?.length > 0 ? (
                      filteredStudentList?.map((item: any) => {
                        return (
                          <div className={styles.studentData}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 5,
                                width: "50%",
                              }}
                            >
                              <div className={styles.nameView}>
                                <div className={styles.nameText}>
                                  {item.studentName?.charAt(0)}
                                </div>
                              </div>
                              <div>{item?.studentName}</div>
                            </div>

                            {item?.studentEmail || item?.studentContact ? (
                              <div
                                className={styles.emails}
                                style={{
                                  width: "50%",
                                }}
                              >{`${
                                item?.studentEmail ? item?.studentEmail : ""
                              } ${
                                item?.studentEmail && item?.studentContact
                                  ? "|"
                                  : ""
                              } ${item?.studentContact ? "+" : ""}${
                                item?.studentContact ? item?.studentContact : ""
                              }`}</div>
                            ) : (
                              "-"
                            )}
                            {isEditMode === true ? (
                              <div
                                onClick={() => {
                                  toggleStudentSelection(item?.studentId);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                {selectedStudents?.includes(item?.studentId) ? (
                                  <img src={present} height={60} width={60} />
                                ) : (
                                  <img src={absent} height={60} width={60} />
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                {item?.attendanceStatus === "PRESENT" ? (
                                  <div className={styles.presentTab}>
                                    <div style={{ color: "#11C89B" }}>
                                      Present
                                    </div>
                                  </div>
                                ) : (
                                  <div className={styles.absentTab}>
                                    <div style={{ color: "#EC3939" }}>
                                      Absent
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <Box
                        sx={{
                          textAlign: "center",
                          position: "relative",
                          top: "30%",
                        }}
                      >
                        <div className={styles.NoResult}>No Students found</div>
                      </Box>
                    )}
                  </div>
                  {isEditMode === true && filteredStudentList?.length > 0 && (
                    <div className={styles.studentSectionButtons}>
                      <CancelBtn
                        onClick={() => {
                          if (
                            attendanceStudentListData?.attendanceDone === false
                          ) {
                            navigate(`/app/Attendance`);
                          } else {
                            setIsEditMode(false);
                          }
                        }}
                      />
                      <div style={{ marginLeft: "10px" }}>
                        <SaveBtn
                          onClick={() => {
                            handleSubmit();
                          }}
                          loader={
                            attendanceStudentSaveLoading ||
                            attendanceStudentEditLoading
                          }
                          buttonText={"Confirm & Submit"}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default AttendanceDetails;
