import React from "react";
import styles from "../FilterDrawer.module.css";

function ShowAllBtn(props: any) {
  const { clickFunction, showAll, filteredState } = props;
  return (
    <>
      {filteredState && filteredState.length > 5 && (
        <button onClick={clickFunction} className={styles.showMoreBtn}>
          {!showAll ? (
            <>{filteredState && filteredState.length - 5} more</>
          ) : (
            "show less"
          )}
        </button>
      )}
    </>
  );
}

export default ShowAllBtn;
