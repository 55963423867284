import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

function ListPopover(props: any) {
  const {
    openState,
    anchorState,
    handleClosePopover,
    handleListSearch,
    popupList,
    handleItemSelect,
    listType,
  } = props;

  const renderList = () => {
    return popupList?.map((item: any) => (
      <ListItem
        key={item?.id}
        button
        onClick={() => handleItemSelect(item)}
        sx={{
          padding: "4px 14px ",
          // background: item?.id ===
        }}
      >
        <ListItemText
          primary={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
                color: "#667085",
                fontSize: "14PX",
              }}
            >
              {listType === "ASSIGNEE" ? (
                <div
                  style={{
                    width: 24,
                    height: 24,
                    backgroundColor: "#F5F5F7",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 6,
                    textTransform: "uppercase",
                    color: "#667085",
                    fontSize: "10px",
                    fontWeight: "500",
                    padding: "5px 7px 3px 7px",
                  }}
                >
                  {item?.firstName?.[0] + item?.lastName?.[0]}
                </div>
              ) : (
                <></>
              )}

              {listType === "COURSE"
                ? `${item?.courseName}`
                : listType === "BRANCH"
                ? `${item?.branchName}`
                : listType === "BATCH"
                ? `${item?.batchName}`
                : listType === "ASSIGNEE"
                ? `${item?.firstName + " " + item?.lastName}`
                : listType === "FORMTYPE"
                ? `${item?.name}`
                : ""}
            </div>
          }
        />
      </ListItem>
    ));
  };

  return (
    <>
      <Popover
        open={openState}
        anchorEl={anchorState}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ width: "100%" }}
        PaperProps={{
          sx: {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "4PX",
            border: "1px solid #EEE",
          },
        }}
      >
        {listType !== "FORMTYPE" && (
          <TextField
            placeholder="Search"
            sx={{
              padding: "20px 20px 0px 20px",
              width: "100%",
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleListSearch}
            size="small"
          />
        )}
        <div
          style={{
            height: listType === "FORMTYPE" ? "auto" : "250px",
            overflowY: "auto",
            minWidth: "180px",
            maxHeight: "250px",
          }}
        >
          {popupList?.length > 0 ? (
            <List>{renderList()}</List>
          ) : (
            <div style={{ padding: "10px 16px", fontSize: "13px" }}>
              {listType === "ASSIGNEE"
                ? "No users found with required permissions"
                : "No results found"}
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}

export default ListPopover;
