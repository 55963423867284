import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import highFlagIcon from "../FlagAssign/assets/High.svg";
import urgentFlagIcon from "../FlagAssign/assets/Urgent.svg";
import normalFlagIcon from "../FlagAssign/assets/Normal.svg";
import lowFlagIcon from "../FlagAssign/assets/Low.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetLeadModalState } from "../../pages/app/Nurture/service/nurtureSlice";
import getTeamMember, {
  getDescriptionWithMentionedNames,
} from "../CommonFunctions/CommonFunctions";

function ActivityTask(props: any) {
  const { data, employees } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modifiedDescription = getDescriptionWithMentionedNames(
    data?.description,
    employees
  ).replace(/\n/g, "<br>");

  const activityData =
    data?.activityData && Object?.keys(data?.activityData)?.length !== 0
      ? data?.activityData
      : data?.taskData;

  const urlParams = new URLSearchParams(window.location.search);
  const leadActivityId = urlParams.get("leadActivityId");

  const handleViewTask = (e: any) => {
    navigate(`/app/Tasks/DemoTaskDetails/${data?.activityData?.id}`);
    dispatch(resetLeadModalState());
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
          background: leadActivityId === data?.id ? "#e8faf6" : "transparent",
          padding: leadActivityId === data?.id ? "0 5px 5px 5px" : "0px",
          borderRadius: leadActivityId === data?.id ? "4px" : "0px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityTop}>
            <div className={styles.activityDateStyle}>
              {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
            </div>
            <div className={styles.activityHeadStage}>
              <div className={styles.activityHeadStageIcon}>
                <img src={stageDot} alt="" />
              </div>
              <div>{data?.currentStage}</div>
            </div>
            <div className={styles.activityHeadName}>
              <div className={styles.activityHeadStageIcon}>
                <img src={nameLine} alt="" />
              </div>
              <div>
                {data.activityType === "TASK" ||
                data.activityType === "TASK_ADDED"
                  ? getTeamMember(data?.createdById, employees)?.firstName ===
                    undefined
                    ? data?.createdByName
                    : getTeamMember(data?.createdById, employees)?.firstName +
                      " " +
                      getTeamMember(data?.createdById, employees)?.lastName
                  : getTeamMember(data?.updatedById, employees)?.firstName +
                    " " +
                    getTeamMember(data?.updatedById, employees)?.lastName}
              </div>
            </div>
          </div>

          <div
            className={styles.activityDemoTitle}
            style={{
              color:
                data.activityType === "TASK_DELETED" ? "#EC3939" : "#142C44",
              display: "flex",
              gap: "5px",
            }}
          >
            {data.activityType === "TASK_ADDED"
              ? "Task Added"
              : data.activityType === "TASK_EDITED"
              ? "Task Edited"
              : data.activityType === "TASK_DELETED"
              ? "Task Deleted"
              : data.activityType === "TASK_ADDED_POST_CALL_FEEDBACK"
              ? "Task Added - Post Call Feedback"
              : ""}
            {data.activityType === "TASK_EDITED" &&
              data?.activityData?.taskType === "DEMO" && (
                <div
                  style={{
                    color: "#3db985",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                  onClick={handleViewTask}
                >
                  View Task
                </div>
              )}
          </div>
          <div
            className={styles.activityDescription}
            dangerouslySetInnerHTML={{ __html: modifiedDescription }}
          />

          <div className={styles.activityBottom}>
            <div className={styles.activityBottomLeft}>
              {activityData?.assignedToName && (
                <div className={styles.activityBottomAssign}>
                  {getTeamMember(activityData?.assignedToId, employees)
                    ?.firstName?.charAt(0)
                    ?.toUpperCase()}
                  {getTeamMember(activityData?.assignedToId, employees)
                    ?.lastName?.charAt(0)
                    ?.toUpperCase()}
                  {/* {activityData?.assignedToName
                    ?.split(" ")
                    ?.slice(0, 2)
                    ?.map((word: any, index: number) => (
                      <span key={index}>{word?.charAt(0)}</span>
                    ))} */}
                </div>
              )}
              {activityData?.taskType !== "DEMO" && (
                <div className={styles.activityBottomFlag}>
                  {activityData?.priority === "URGENT" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={urgentFlagIcon} alt="" />
                    </div>
                  )}
                  {activityData?.priority === "HIGH" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={highFlagIcon} alt="" />
                    </div>
                  )}
                  {activityData?.priority === "NORMAL" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={normalFlagIcon} alt="" />
                    </div>
                  )}
                  {activityData?.priority === "LOW" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={lowFlagIcon} alt="" />
                    </div>
                  )}
                </div>
              )}

              {data &&
              data?.activityData &&
              Object?.keys(data?.activityData).length !== 0 ? (
                <>
                  <div className={styles.activityDateStyle}>
                    Due on{" "}
                    {moment(data?.activityData?.date).format(
                      "DD MMM YYYY, hh:mm A"
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.activityDateStyle}>
                    Due on{" "}
                    {data?.taskData?.taskType !== "DEMO"
                      ? moment(data?.date).format("DD MMM YYYY, hh:mm A")
                      : moment(data?.taskData?.date).format("DD MMM") +
                        " " +
                        (data?.taskData?.demoData?.[0]?.demoStartTime
                          ?.length === 10
                          ? moment
                              .unix(
                                data?.taskData?.demoData?.[0]?.demoStartTime
                              )
                              .format("hh:mm A")
                          : moment(
                              data?.taskData?.demoData?.[0]?.demoStartTime
                            ).format("hh:mm A"))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ActivityTask;
