import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import nameLine from "../../assets/images/name-line.svg";
import getTeamMember from "../CommonFunctions/CommonFunctions";

function ActivityLeadEnquired(props: any) {
  const { data, employees } = props;
  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityTop}>
            <div className={styles.activityDateStyle}>
              {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
            </div>
            <div className={styles.activityHeadName}>
              <div className={styles.activityHeadStageIcon}>
                <img src={nameLine} alt="" />
              </div>
              <div style={{ textTransform: "capitalize" }}>
                {/* {data?.createdByName} */}
                {getTeamMember(data?.createdById, employees)?.firstName +
                  " " +
                  getTeamMember(data?.createdById, employees)?.lastName}
              </div>
            </div>
          </div>
          <div className={styles.activityDemoTitle}>Lead Added </div>

          <div className={styles.activityEnquiry}>{data?.description}</div>
        </Box>
      </Box>
    </>
  );
}

export default ActivityLeadEnquired;
