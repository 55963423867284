import React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Autocomplete,
  Paper,
  TextField,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./DeleteRolePopup.module.css";
import { Box } from "@mui/system";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

const DeleteRolePopup = (props: any) => {
  const {
    onClose,
    showDeletePopup,
    loading,
    combinedRoles,
    selectedDeleteRole,
    DeleteRole,
    roleUserCount,
    deleteCustomRoleSuccess,
  } = props;
  const [selectedRole, setSelectedRole] = React.useState<any>(null);
  const [selectedRoleError, setSelectedRoleError] =
    React.useState<boolean>(false);

  const handleClose = () => {
    onClose();
    setSelectedRole(null);
    setSelectedRoleError(false);
  };

  const filteredRoles = combinedRoles?.filter(
    (item: any) => item.id !== selectedDeleteRole?.id
  );
  const defaultRole =
    combinedRoles && selectedRole
      ? filteredRoles?.find((item: any) => item.id === selectedRole?.id)
      : "";

  const onRoleChange = React.useCallback((event: object, value: any | null) => {
    if (value !== null) {
      setSelectedRole(value);
    } else {
      setSelectedRole(null);
    }
  }, []);

  // const onRoleChange = React.useCallback((event: any) => {
  //   const { value } = event.target;
  //   setSelectedRole(value);
  //   setSelectedRoleError(false);
  // }, []);

  const handleDeleteSubmit = async (event: any) => {
    event.preventDefault();
    try {
      let roleError = false;
      if (roleUserCount > 0 && !selectedRole) {
        setSelectedRoleError(true);
        roleError = true;
      } else {
        roleError = false;
      }
      const data = {
        // instituteId: authUser?.institute[0]?.instituteId,
        roleId: selectedDeleteRole?.id,
        newRoleId: roleUserCount > 0 ? selectedRole?.id : "",
        // updatedById: authUser?.institute[0]?.id,
        // updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      !roleError && selectedDeleteRole && DeleteRole(data);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (deleteCustomRoleSuccess !== true && deleteCustomRoleSuccess !== false) {
      setSelectedRole(null);
      setSelectedRoleError(false);
    }
  }, [deleteCustomRoleSuccess]);

  return (
    <>
      <Modal
        open={showDeletePopup}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.closeModalButton}>
              <CloseIcon sx={{ color: "#667085" }} onClick={handleClose} />
            </div>
            <div className={styles.modalTitle}>
              Are you sure you want to Delete this Role?
            </div>
            {roleUserCount > 0 && (
              <>
                <div className={styles.modalPara}>
                  There {roleUserCount > 1 ? "are" : "is"} {roleUserCount}{" "}
                  active users in this role. Please choose a new role for the
                  users.
                </div>
                <FormControl
                  fullWidth
                  variant="standard"
                  className="formControl"
                  sx={{
                    mb: "18px",
                  }}
                >
                  <div className={styles.defaultCourseDetailsLabel}>Roles</div>
                  {combinedRoles && (
                    <Autocomplete
                      disablePortal={true}
                      PaperComponent={(props) => (
                        <Paper
                          sx={{
                            background: "#FFF",
                            border: "1px solid #E4E4E7",
                            fontFamily: "poppins",
                            fontSize: "14px",
                            textTransform: "capitalize",
                            color: "142C44",
                            fontWeight: "400",
                            padding: "0 4px",
                            margin: "0",
                            boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                            maxHeight: "300px",
                            overflowY: "hidden",
                            zIndex: 99999999,
                          }}
                          {...props}
                        />
                      )}
                      id=""
                      options={filteredRoles}
                      getOptionLabel={(option: any) => option.roleName || ""}
                      fullWidth
                      onChange={onRoleChange}
                      value={defaultRole || null}
                      disableClearable={
                        selectedRole && selectedRole === null ? true : false
                      }
                      sx={{
                        border: "1px solid  #D4D4D8",
                        borderRadius: "6px",
                        zIndex: 99999999,
                        "&& fieldset": {
                          border: "none",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          {...params}
                          size="small"
                        />
                      )}
                    />
                  )}
                  {/* {validStateBatch === false && (
                <FormHelperText error={true}>Field required</FormHelperText>
              )} */}
                </FormControl>
              </>
            )}

            <div className={styles.buttonSection}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleClose}
                sx={{
                  color: "#344054",
                  fontSize: "15px",
                  fontWeight: "500",
                  lineHeight: "10px 24px 24px 24px",
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  backgroundColor: "#FFF",
                  border: "1px solid #D0D5DD",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
                  "&:hover": {
                    color: "#344054",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "10px 24px 24px 24px",
                    textTransform: "capitalize",
                    borderRadius: "4px",
                    backgroundColor: "#FFF",
                    border: "1px solid #D0D5DD",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
                  },
                }}
              >
                No
              </Button>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  color: "#FFF",
                  fontSize: "15px",
                  fontWeight: "500",
                  lineHeight: "10px 24px 24px 24px",
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  backgroundColor: "#EC3939",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
                  "&:hover": {
                    color: "#FFF",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "10px 24px 24px 24px",
                    textTransform: "capitalize",
                    borderRadius: "4px",
                    backgroundColor: "#EC3939",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05",
                  },
                }}
                onClick={handleDeleteSubmit}
                disabled={loading || (roleUserCount > 0 && !selectedRole)}
              >
                {loading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  <>{roleUserCount > 0 ? "Delete" : "Yes"}</>
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default DeleteRolePopup;
