import {
  Accordion,
  AccordionSummary,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import styles from "../Manegecourses.module.css";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import RichTextEditor from "../../../../../../components/RichTextEditor/RichTextEditor";
import CloseIcon from "@mui/icons-material/Close";
import closeIcon from "../../../../../../assets/images/close-icon.svg";

export default function CourseChapterManager(props: any) {
  const { courseSyllabus, handleAddChapter, handleUpdateSyllabus, disabled } =
    props;

  const handleChapterDescriptionChange = (event: any, index: number) => {
    const updatedChapter = {
      ...courseSyllabus[index],
      chapterDetails: event,
    };
    const updatedSyllabus = [...courseSyllabus];
    updatedSyllabus[index] = updatedChapter;
    handleUpdateSyllabus(updatedSyllabus);
  };
  const handleChapterNameChange = React.useCallback(
    (event: any, index: number) => {
      const updatedChapter = {
        ...courseSyllabus[index],
        chapterName: event.target.value,
      };
      const updatedSyllabus = [...courseSyllabus];
      updatedSyllabus[index] = updatedChapter;
      handleUpdateSyllabus(updatedSyllabus);
    },
    [courseSyllabus, handleUpdateSyllabus]
  );

  const handleDeleteAccordionItem = (index: any) => {
    const updatedChapters = courseSyllabus.filter(
      (_: any, i: any) => i !== index
    );
    handleUpdateSyllabus(updatedChapters);
  };

  return (
    <div className={""}>
      <div>
        {courseSyllabus?.map((item: any, index: any) => (
          <Accordion
            className={styles.accordionedit}
            key={item.chapterName + index}
          >
            <AccordionSummary
              sx={{
                color: "#142C44",
                backgroundColor: "transparent",
                border: "1px solid #D4D4D8",
                borderRadius: "6px",
              }}
              expandIcon={
                // <ExpandMoreIcon
                //   sx={{
                //     color: "#667085",
                //     pointerEvents: "auto",
                //   }}
                // />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteAccordionItem(index);
                    }}
                  >
                    <img src={closeIcon} />
                  </IconButton>
                  <ExpandMoreIcon sx={{ color: "#667085" }} />
                </div>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TextField
                disabled={disabled}
                required
                InputProps={{ disableUnderline: true }}
                id="courseName"
                placeholder="Course Name"
                variant="standard"
                fullWidth
                onBlur={(event) => handleChapterNameChange(event, index)}
                defaultValue={item.chapterName}
              />
            </AccordionSummary>
            <AccordionDetails>
              {!disabled ? (
                <RichTextEditor
                  handleTextChange={(event: any) =>
                    handleChapterDescriptionChange(event, index)
                  }
                  defaultText={item.chapterDetails}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.chapterDetails,
                  }}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div
        className={""}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {!disabled ? (
          <Button
            onClick={handleAddChapter}
            sx={{
              fontSize: "12px",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            + Add Chapter
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
