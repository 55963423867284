import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  styled,
  Avatar,
  Button,
  AvatarGroup,
  Tooltip,
} from "@mui/material";
import styles from "../components/RolesAndPermission.module.css";
import { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import deleteIcon from "./Assets/deleteIcon.svg";
import { useNavigate } from "react-router-dom";
import DeleteRolePopup from "./DeleteRolePopup/DeleteRolePopup";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { resetSettingsStatuses } from "../../../service/settingsSlice";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function CustomRole(props: any) {
  const {
    staffList,
    customRoles,
    DeleteRole,
    deleteCustomRoleSuccess,
    deleteCustomRoleLoading,
    combinedRoles,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasRolesAccess = getSubDomainAccessStatus(
    "SETTINGS",
    "ROLES_&_PERMISSIONS",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedDeleteRole, setSelectedDeleteRole] = useState<any>(null);

  const handleDeleteConfirmed = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteCancelled = () => {
    setShowDeletePopup(false);
    setSelectedDeleteRole(null);
  };

  React.useEffect(() => {
    if (deleteCustomRoleSuccess !== true && deleteCustomRoleSuccess !== false) {
      setShowDeletePopup(false);
      setSelectedDeleteRole(null);
      dispatch(resetSettingsStatuses());
    }
  }, [deleteCustomRoleSuccess]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "4px",
        }}
      >
        <div className={styles.systemRole} style={{ marginBottom: "0px" }}>
          Custom Role
        </div>
        <Tooltip
          arrow
          title={hasRolesAccess === false ? "Oops! No permission." : ""}
          sx={{ width: "100%" }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                },
              },
            },
          }}
        >
          <span>
            <Button
              component="label"
              sx={{
                textTransform: "capitalize",
                display: "flex",
                gap: "4px",
                color: "#11C89B",
                fontSize: "13px",
                fontWeight: "500",
              }}
              startIcon={<AddIcon />}
              onClick={() =>
                navigate(`/app/Settings/RolesandPermission/AddCustomRole`)
              }
              disabled={hasRolesAccess === false}
            >
              Add Custom Role
            </Button>
          </span>
        </Tooltip>
      </Box>

      <TableContainer
        sx={{
          minWidth: 700,
          borderRadius: "8PX",
          border: "1px solid  #EAECF0",
        }}
      >
        <Table
          sx={{
            minWidth: 700,
          }}
          aria-label="customized table"
        >
          <TableHead className={styles.batchPopupHead}>
            <TableRow>
              <StyledTableCell>Role</StyledTableCell>

              <StyledTableCell align="left" sx={{ maxWidth: "370px" }}>
                Description
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ minWidth: "180px" }}>
                Users
              </StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customRoles?.map((item: any, index: any) => (
              <TableRow key={index}>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                    minWidth: "260px",
                  }}
                >
                  {item?.roleName}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  className={styles.roleDescriptionTable}
                  sx={{
                    color: "#667085",
                    minWidth: "420px",
                  }}
                >
                  {item?.description}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <Box className={styles.userCardImg}>
                      <AvatarGroup>
                        {staffList
                          ?.filter((d: any) => d.roleId === item?.id)
                          ?.slice(0, 4)
                          ?.map((user: any, userIndex: any) => (
                            <Tooltip
                              title={user?.firstName + " " + user?.lastName}
                              placement="bottom"
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#101828",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    padding: "8px 12px",
                                    textTransform: "capitalize",
                                    "& .MuiTooltip-arrow": {
                                      color: "#101828",
                                    },
                                  },
                                },
                              }}
                              key={userIndex}
                            >
                              <Avatar
                                alt="avatar"
                                sx={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "50%",
                                  fontSize: "15px",
                                  background: "#667085",
                                  border: "2px solid #FFF",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  color: "#FFF",
                                  position: "relative",
                                  zIndex: userIndex,
                                }}
                                className={styles.userAvatar}
                              >
                                <Box>{user?.firstName?.charAt(0)}</Box>
                              </Avatar>
                            </Tooltip>
                          ))}
                      </AvatarGroup>
                    </Box>
                    {staffList?.filter((d: any) => d.roleId === item?.id)
                      ?.length > 4 && (
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          border: "2px solid #FFF",
                          background: "rgba(17, 200, 155, 0.06)",
                          padding: "4px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            color: "#11C89B",
                            textAlign: "center",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "18px",
                          }}
                        >
                          +
                          {staffList?.filter((d: any) => d.roleId === item?.id)
                            ?.length - 4}
                        </Box>
                        <ul className={styles.staffPopupWrap}>
                          {staffList
                            ?.filter((d: any) => d.roleId === item?.id)
                            ?.slice(4)
                            ?.map((user: any, userIndex: any) => (
                              <li
                                className={styles.staffPopupItem}
                                key={userIndex}
                              >
                                <div className={styles.staffPopupItemAvatar}>
                                  {user?.firstName?.charAt(0)}
                                </div>
                                <div className={styles.staffPopupItemName}>
                                  {user?.firstName + " " + user?.lastName}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </Box>
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "4px",
                      border: "1px solid #CFCFCF",
                      color: "#667085",
                      fontSize: "12px",
                      fontWeight: "400",
                      "&:hover": {
                        backgroundColor: "#FFF",
                        border: "1px solid #CFCFCF",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        `/app/Settings/Rolesandpermission/CustomRoleDetails/${item.id}`
                      );
                    }}
                  >
                    View
                  </Button>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  <Tooltip
                    arrow
                    title={
                      hasRolesAccess === false ? "Oops! No permission." : ""
                    }
                    sx={{ width: "100%" }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          hasRolesAccess === true
                            ? (setShowDeletePopup(!showDeletePopup),
                              setSelectedDeleteRole(item))
                            : null
                        }
                      >
                        <img src={deleteIcon} alt="delete" />
                      </div>
                    </span>
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showDeletePopup && (
        <DeleteRolePopup
          onClose={handleDeleteCancelled}
          showDeletePopup={showDeletePopup}
          loading={deleteCustomRoleLoading}
          combinedRoles={combinedRoles}
          selectedDeleteRole={selectedDeleteRole}
          DeleteRole={DeleteRole}
          // roleUserCount={
          //   staffList?.filter(
          //     (d: any) =>
          //       d.role?.toLowerCase()?.replace(/\s/g, "_") ===
          //       selectedDeleteRole?.roleName?.toLowerCase()?.replace(/\s/g, "_")
          //   )?.length
          // }
          roleUserCount={
            staffList?.filter((d: any) => d.roleId === selectedDeleteRole?.id)
              ?.length
          }
          deleteCustomRoleSuccess={deleteCustomRoleSuccess}
        />
      )}
    </Box>
  );
}

export default CustomRole;
