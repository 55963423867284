import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./LeadPopup.module.css";
import activityCall from "../ActivityList/assets/leadCallWhite.svg";
import closeIcon from "./Assets/popup-close.svg";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { CallLead } from "../../pages/app/Integrations/service/integrations.request";
import { setShowOzonetelWidget } from "../../pages/app/Integrations/service/IntegrationsSlice";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};

function LeadCallPopup(props: any) {
  const {
    selectedLeadData,
    employees,
    setCallDispositionShowStatus,
    setCallUcid,
    setCallDID,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const { appsList } = useSelector((state: any) => state.appIntegrations);
  const instituteCampaigns = instituteFromRedux?.ozonetelCampaigns?.message;
  const appEnvironment = process.env.REACT_APP_ENV?.toUpperCase() || "";
  const ozonetelApp = appsList?.find((app: any) => app.appCode === "OZONETEL");
  const ozonetelAppApiKey = ozonetelApp?.appConfigData?.apiKey;
  const ozonetelAppUserName = ozonetelApp?.appConfigData?.userName;
  const ozonetelEmployee = employees?.find(
    (item: any) => item.employee === authUser?.institute[0]?.employee
  );
  const userAgentId = ozonetelEmployee?.ozonetelConfig?.agentId;

  const [callPopup, setCallPopup] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<any>("");
  const [callStatus, setCallStatus] = React.useState<any>("");

  const handleOpenCallPopup = () => {
    setCallPopup(true);
    dispatch(setShowOzonetelWidget());
  };

  const handleCloseCallPopup = () => {
    setCallPopup(false);
    setCallStatus("");
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredCampaigns = instituteCampaigns?.filter((item: any) => {
    const searchTermMatch =
      item.Campaign?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !== -1;
    return searchTermMatch;
  });

  const [selectedCampaign, setSelectedCampaign] = useState<any>("");

  const handleSelectCampign = React.useCallback((event: any) => {
    const ozonetelApp = filteredCampaigns?.find(
      (item: any) => item.Campaign === event.target.value
    );

    setSelectedCampaign(ozonetelApp);
    setCallDID(ozonetelApp?.DID);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const response: any = await CallLead(
        ozonetelAppApiKey,
        ozonetelAppUserName,
        userAgentId,
        selectedCampaign?.Campaign,
        selectedLeadData?.studentContact,
        authUser?.institute[0]?.instituteId,
        selectedLeadData?.id,
        appEnvironment
      );
      if (response) {
        setCallStatus(response?.data?.status);
        if (response?.data?.status === "queued successfully") {
          dispatch(setShowOzonetelWidget());
          setCallPopup(false);
          setCallStatus("");
          setCallDispositionShowStatus("queued successfully");
          setCallUcid(response?.data?.ucid);
        } else {
          dispatch(setShowOzonetelWidget());
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (instituteCampaigns) {
      setSelectedCampaign(instituteCampaigns?.[0]);
      setCallDID(instituteCampaigns?.[0]?.DID);
    }
  }, [instituteCampaigns]);

  return (
    <div>
      <button className={styles.leadCallPopupBtn} onClick={handleOpenCallPopup}>
        <img src={activityCall} alt="activityCall" />
      </button>
      <Modal
        open={callPopup}
        onClose={handleCloseCallPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div className={styles.leadCallPopupClsBtnWrap}>
            <button className={styles.closeBtn} onClick={handleCloseCallPopup}>
              <img src={closeIcon} alt="closeIcon" />
            </button>
          </div>
          <div className={styles.leadCallPopupTitle}>Select Campaign</div>

          <TextField
            placeholder="Search Campaign"
            sx={{ margin: "0 0 10px 0" }}
            fullWidth
            value={searchTerm}
            onChange={handleSearch}
            size="small"
          />

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            sx={{ fontSize: "14px", color: "#667085" }}
            value={selectedCampaign?.Campaign}
            onChange={handleSelectCampign}
          >
            {filteredCampaigns?.map((item: any, index: number) => (
              <div
                key={index}
                style={{
                  borderRadius: "6px",
                  border: "1px solid #F2F2F2",
                  background: "#FFF",
                  marginBottom: "8px",
                  padding: "6px 14px",
                }}
              >
                <FormControlLabel
                  value={item?.Campaign}
                  control={
                    <Radio
                      sx={{
                        fontSize: "14px",
                        strokeWidth: 1,
                        color: "#D0D5DD",
                      }}
                    />
                  }
                  label={
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#667085",
                        lineHeight: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.Campaign}
                    </Typography>
                  }
                  sx={{ fontSize: "14px", color: "#667085" }}
                />
              </div>
            ))}
          </RadioGroup>
          <div>{callStatus}</div>
          <div className={styles.courseEditBtns}>
            <Button
              fullWidth
              onClick={handleCloseCallPopup}
              sx={{
                textTransform: "capitalize",
                color: "#9A9EA6",
                borderColor: "#D0D5DD",
                border: "1px solid",
                borderRadius: "4PX",
                ":hover": {
                  color: "#9A9EA6",
                  borderColor: "#D0D5DD",
                  backgroundColor: "#9a9ea612",
                },
              }}
              variant="outlined"
            >
              cancel
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              sx={{
                border: "1px solid",
                borderRadius: "4PX",
                marginBottom: "0",
                textTransform: "capitalize",
              }}
              onClick={handleSubmit}
            >
              Call
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default LeadCallPopup;
