import {
  Box,
  Modal,
  Autocomplete,
  TextField,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { useAuthUser } from "react-auth-kit";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./Dashboard.module.css";
import MyTask from "./components/MyTask/MyTask";
import call from "../../../assets/images/call.svg";
import meetings from "../../../assets/images/meet.svg";
import email from "../../../assets/images/mail.svg";
import demo from "../../../assets/images/demoIcon.svg";
import statusChange from "../../../assets/images/status.svg";
import created from "../../../assets/images/created.svg";
import payment from "../../../assets/images/rupee.svg";
import noteIcon from "../../../assets/images/noteIcon.svg";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import InactiveAccountPopup from "../../../components/CustomPopupBilling/InactiveAccountPopup";
import { useLocation } from "react-router";
import { TermsOfServices } from "../TermsOfServices/components/TermsOfServices";
import { PrivacyPolicy } from "../TermsOfServices/components/PrivacyPolicy";
import SalesPipelineOverview from "./components/SalesPipelineOverview/SalesPipelineOverview";
import DataTiles from "./components/DataTiles/DataTiles";
import RevenueAnalyticsGraph from "./components/RevenueAnalyticsGraph/RevenueAnalyticsGraph";
import LeadSourceData from "./components/LeadSourceData/LeadSourceData";
import StageVsExpectedRevenue from "./components/BarChart/StageVsExpectedRevenue";
import StageVsLeads from "./components/BarChart/StageVsLeads";
import StageVsLost from "./components/BarChart/StageVsLost";
import OverduePayments from "./components/OverduePayments/OverduePayments";
import { getSubDomainAccessStatus } from "../../../components/RolePermissionAccess/RolePermissionAccess";
import SplitSkeleton from "../../../components/Skeletons/SplitSkeleton";
import DashboardSkeleton from "../../../components/Skeletons/DashboardSkeleton";

const style = {
  position: "absolute" as "absolute",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  p: 2,
  top: "2%",
  left: "2%",
  right: "2%",
  bottom: "2%",
  overflow: "hidden",
  outline: "none",
};

type overdueData = {
  instituteId: any;
  branchId: any;
  limit: number;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

export default function Dashboard(props: any) {
	const {
		dashboardDetails,
		dashboardData,
		termsAndConditionsLoading,
		termsAndConditionsSuccess,
		termsAndConditionsError,
		privacyPolicyLoading,
		privacyPolicySuccess,
		privacyPolicyError,
		AcceptTnC,
		AcceptPrivacyPolicy,
		setActiveInsitute,
		GetLeadListByInstituteAndContact,
		GetLeadSourceGraphData,
		GetRevenueAnalyticsGraphData,
		revenuGraphData,
		leadSourceGraphData,
		GetOverduePayments,
		overduePaymentsData,
		GetBranchList,
		GetStaffList,

		overduePaymentsloading,
		loading,
	} = props;
	const auth = useAuthUser();
	const user = auth();
	const location = useLocation();
	const isTabSize = useMediaQuery('(max-width:1100px)');
	const { userRoleById, userById } = useSelector(
		(state: any) => state.userDetails
	);

	const hasAllBranchAccess = getSubDomainAccessStatus(
		'NURTURE_BOARD',
		'BOARD',
		'ALL_BRANCHES',
		// userRoleById?.permissions
		userById?.rolePermission
	);
	const { taskStatusUpdateSuccess } = useSelector((state: any) => state.tasks);
	const branches = useSelector((state: any) => state.branch.branchList);
	const employees = useSelector((state: any) => state.staff.staffList);
	const { createPaymentSuccess } = useSelector((state: any) => state.nurture);

	const instituteData = JSON.parse(localStorage.getItem('ACTIVE_INSTITUTE')!);
	const accountStatus = instituteData?.billingStatus;
	const isBillingAdmin = user?.institute[0]?.isBillingAdmin;

	const [selectedBranch, setSelectedBranch] = React.useState<any>('');
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [openPrivacy, setOpenPrivacy] = React.useState(false);
	const handleOpenPrivacy = () => setOpenPrivacy(true);
	const handleClosePrivacy = () => setOpenPrivacy(false);
	const [selectedCurrency, setSelectedCurrency] = React.useState('₹');

	const defaultBranch = branches
		? branches?.find((branch: any) => branch.id === selectedBranch?.id)
		: '';

	useEffect(() => {
		if (!defaultBranch?.hasOwnProperty('currency')) {
			setSelectedCurrency('₹');
		} else if (defaultBranch?.currency === '') setSelectedCurrency('₹');
		else {
			setSelectedCurrency(defaultBranch?.currency);
		}
	}, [defaultBranch]);

	const sideIcon = (type: string) => {
		switch (type) {
			case 'CALL':
				return call;
			case 'MEETING':
				return meetings;
			case 'EMAIL':
				return email;
			case 'MAIL':
				return email;
			case 'STATUS_CHANGE':
				return statusChange;
			case 'DEMO':
				return demo;
			case 'CREATED':
				return created;
			case 'NOTE':
				return noteIcon;
			case 'PAYMENT':
				return payment;
			default:
				return;
		}
	};

	const dashboardApiCall = useCallback(
		async (
			instituteId: string,
			userId: string,
			branchId: string
		): Promise<void> => {
			if (instituteId && userId && branchId) {
				const data = {
					instituteId: instituteId,
					userId: userId,
					branchId: branchId,
				};
				await dashboardData(data); // Ensure dashboardData returns a promise
			}
		},
		[dashboardData]
	);

	const RevenueAnalyticsGraphApiCall = useCallback(
		async (
			instituteId: string,
			dateFilterValue: number,
			branchId: string
		): Promise<void> => {
			if (instituteId && dateFilterValue && branchId) {
				const data = {
					instituteId: instituteId,
					dateFilterType: 'DAY',
					dateFilterValue: dateFilterValue,
					branchId: branchId,
				};
				await GetRevenueAnalyticsGraphData(data); // Ensure GetRevenueAnalyticsGraphData returns a promise
			}
		},
		[GetRevenueAnalyticsGraphData]
	);

	const LeadSourceGraphApiCall = useCallback(
		async (
			instituteId: string,
			dateFilterValue: number,
			branchId: string
		): Promise<void> => {
			if (instituteId && dateFilterValue && branchId) {
				const data = {
					instituteId: instituteId,
					dateFilterType: 'DAY',
					dateFilterValue: dateFilterValue,
					branchId: branchId,
				};
				await GetLeadSourceGraphData(data); // Ensure GetLeadSourceGraphData returns a promise
			}
		},
		[GetLeadSourceGraphData]
	);

	const OverduePaymentApiCall = useCallback(
		async (instituteId: string, branchId: string): Promise<void> => {
			if (instituteId && branchId) {
				const data = {
					instituteId: instituteId,
					branchId: branchId,
					limit: 15,
					lastEvaluatedKey: '',
					lastEvaluatedSortKey: '',
				};
				await GetOverduePayments(data); // Ensure GetOverduePayments returns a promise
			}
		},
		[GetOverduePayments]
	);

	// const dashboardApiCall = useCallback(
	//   (instituteId: string, userId: string, branchId: string) => {
	//     let data: any;
	//     if (instituteId && userId && branchId) {
	//       data = {
	//         instituteId: instituteId,
	//         userId: userId,
	//         branchId: branchId,
	//       };
	//       dashboardData(data);
	//     }
	//   },
	//   [dashboardData]
	// );

	// const RevenueAnalyticsGraphApiCall = useCallback(
	//   (instituteId: string, dateFilterValue: number, branchId: string) => {
	//     let data: any;
	//     if (instituteId && dateFilterValue && branchId) {
	//       data = {
	//         instituteId: instituteId,
	//         dateFilterType: "DAY",
	//         dateFilterValue: dateFilterValue,
	//         branchId: branchId,
	//       };
	//       GetRevenueAnalyticsGraphData(data);
	//     }
	//   },
	//   [GetRevenueAnalyticsGraphData]
	// );

	// const LeadSourceGraphApiCall = useCallback(
	//   (instituteId: string, dateFilterValue: number, branchId: string) => {
	//     let data: any;
	//     if (instituteId && dateFilterValue && branchId) {
	//       data = {
	//         instituteId: instituteId,
	//         dateFilterType: "DAY",
	//         dateFilterValue: dateFilterValue,
	//         branchId: branchId,
	//       };
	//       GetLeadSourceGraphData(data);
	//     }
	//   },
	//   [GetLeadSourceGraphData]
	// );

	// const OverduePaymentApiCall = useCallback(
	//   (instituteId: string, branchId: string) => {
	//     if (instituteId && branchId) {
	//       const data: overdueData = {
	//         instituteId: instituteId,
	//         branchId: branchId,
	//         limit: 15,
	//         lastEvaluatedKey: "",
	//         lastEvaluatedSortKey: "",
	//       };

	//       GetOverduePayments(data);
	//     }
	//   },
	//   []
	// );

	const onSelectBranchChange = React.useCallback(
		(event: object, value: any | null) => {
			const queryParams = new URLSearchParams(window.location.search);
			const revenueTimelineParam = queryParams.get('revenueAnalyticsTimeline');
			const leadSourceParam = queryParams.get('leadSourceTimeline');

			let revenueTimeline: any;
			let leadSourceTimeline: any;
			if (revenueTimelineParam) {
				revenueTimeline = parseInt(revenueTimelineParam);
			} else {
				revenueTimeline = 7;
			}
			if (leadSourceParam) {
				leadSourceTimeline = parseInt(leadSourceParam);
			} else {
				leadSourceTimeline = 7;
			}

			if (value !== null) {
				setSelectedBranch(value);
				queryParams.set('selectedBranch', value?.id);
				dashboardApiCall(
					user?.institute?.[0]?.instituteId,
					user?.institute?.[0]?.id,
					value?.id
				);
				RevenueAnalyticsGraphApiCall(
					user?.institute?.[0]?.instituteId,
					revenueTimeline,
					value?.id
				);
				LeadSourceGraphApiCall(
					user?.institute?.[0]?.instituteId,
					leadSourceTimeline,
					value?.id
				);
				OverduePaymentApiCall(user?.institute?.[0]?.instituteId, value?.id);
			} else {
				setSelectedBranch('');
				queryParams.delete('selectedBranch');
			}
			const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
			window.history.pushState({}, '', newUrl);
		},
		[]
	);

	React.useEffect(() => {
		if (user !== null) {
			const instituteId = user?.institute[0]?.instituteId;
			if (instituteId) {
				// setActiveInsitute(instituteId);
			}
			let branchId: any;
			let revenueTimeline: any;
			let leadSourceTimeline: any;

			const queryParams = new URLSearchParams(window.location.search);
			const branchParam = queryParams.get('selectedBranch');
			const revenueTimelineParam = queryParams.get('revenueAnalyticsTimeline');
			const leadSourceParam = queryParams.get('leadSourceTimeline');

			if (branchParam) {
				branchId = branchParam;
			} else if (user?.institute?.[0]?.branchId) {
				branchId = user?.institute?.[0]?.branchId;
			} else {
				const loginTeamember = employees?.find(
					(item: any) => item?.email === user?.email
				);
				if (loginTeamember) {
					branchId = loginTeamember?.branchId;
				}
			}
			if (revenueTimelineParam) {
				revenueTimeline = parseInt(revenueTimelineParam);
			} else {
				revenueTimeline = 7;
			}
			if (leadSourceParam) {
				leadSourceTimeline = parseInt(leadSourceParam);
			} else {
				leadSourceTimeline = 7;
			}
			if (branchId) {
				const fetchData = async () => {
					// await dashboardApiCall(
					//   instituteId,
					//   user?.institute?.[0]?.id,
					//   branchId
					// );

					// await RevenueAnalyticsGraphApiCall(
					//   instituteId,
					//   revenueTimeline,
					//   branchId
					// );

					// await LeadSourceGraphApiCall(
					//   instituteId,
					//   leadSourceTimeline,
					//   branchId
					// );

					// await OverduePaymentApiCall(instituteId, branchId);
					await dashboardData({
						instituteId: instituteId,
						userId: user?.institute?.[0]?.id,
						branchId: branchId,
					});
					await GetRevenueAnalyticsGraphData({
						instituteId: instituteId,
						dateFilterType: 'DAY',
						dateFilterValue: revenueTimeline,
						branchId: branchId,
					});
					await GetLeadSourceGraphData({
						instituteId: instituteId,
						dateFilterType: 'DAY',
						dateFilterValue: leadSourceTimeline,
						branchId: branchId,
					});
					await GetOverduePayments({
						instituteId: instituteId,
						branchId: branchId,
						limit: 15,
						lastEvaluatedKey: '',
						lastEvaluatedSortKey: '',
					});
				};

				fetchData();
				// dashboardApiCall(instituteId, user?.institute?.[0]?.id, branchId);
				// RevenueAnalyticsGraphApiCall(instituteId, revenueTimeline, branchId);
				// LeadSourceGraphApiCall(instituteId, leadSourceTimeline, branchId);
				// OverduePaymentApiCall(instituteId, branchId);
			}
		}
	}, []);

	React.useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const branchParam = queryParams.get('selectedBranch');
		if (branchParam) {
			const branch = branches?.find((item: any) => item?.id === branchParam);
			if (branch) {
				setSelectedBranch(branch);
			}
		} else if (user?.institute?.[0]?.branchId) {
			const branch = branches?.find(
				(item: any) => item?.id === user?.institute?.[0]?.branchId
			);
			if (branch) {
				setSelectedBranch(branch);
			}
		} else {
			const loginTeamember = employees?.find(
				(item: any) => item?.email === user?.email
			);

			if (loginTeamember) {
				const branch = branches?.find(
					(item: any) => item?.id === loginTeamember?.branchId
				);
				if (branch) {
					const instituteId = user?.institute[0]?.instituteId;
					setSelectedBranch(branch);
					dashboardApiCall(instituteId, user?.institute?.[0]?.id, branch?.id);
					RevenueAnalyticsGraphApiCall(instituteId, 7, branch?.id);
					LeadSourceGraphApiCall(instituteId, 7, branch?.id);
					OverduePaymentApiCall(instituteId, branch?.id);

					// const fetchData = async () => {
					//   await dashboardData({
					//     instituteId: instituteId,
					//     userId: user?.institute?.[0]?.id,
					//     branchId: branch?.id,
					//   });
					//   await GetRevenueAnalyticsGraphData({
					//     instituteId: instituteId,
					//     dateFilterType: "DAY",
					//     dateFilterValue: 7,
					//     branchId: branch?.id,
					//   });
					//   await GetLeadSourceGraphData({
					//     instituteId: instituteId,
					//     dateFilterType: "DAY",
					//     dateFilterValue: 7,
					//     branchId: branch?.id,
					//   });
					//   await GetOverduePayments({
					//     instituteId: instituteId,
					//     branchId: branch?.id,
					//     limit: 15,
					//     lastEvaluatedKey: "",
					//     lastEvaluatedSortKey: "",
					//   });
					// };

					// fetchData();
				}
			}
		}
	}, [branches, employees]);

	// React.useEffect(() => {
	//   const queryParams = new URLSearchParams(window.location.search);
	//   const branchParam = queryParams.get("selectedBranch");

	//   const fetchBranchAndData = async () => {
	//     let branch = null;

	//     if (branchParam) {
	//       branch = branches?.find((item: any) => item?.id === branchParam);
	//     } else if (user?.institute?.[0]?.branchId) {
	//       branch = branches?.find(
	//         (item: any) => item?.id === user?.institute?.[0]?.branchId
	//       );
	//     } else {
	//       const loginTeammember = employees?.find(
	//         (item: any) => item?.email === user?.email
	//       );
	//       if (loginTeammember) {
	//         branch = branches?.find(
	//           (item: any) => item?.id === loginTeammember?.branchId
	//         );
	//       }
	//     }

	//     if (branch) {
	//       setSelectedBranch(branch);
	//       const instituteId = user?.institute?.[0]?.instituteId;

	//       // Sequential API calls
	//       await dashboardData({
	//         instituteId: instituteId,
	//         userId: user?.institute?.[0]?.id,
	//         branchId: branch?.id,
	//       });

	//       await GetRevenueAnalyticsGraphData({
	//         instituteId: instituteId,
	//         dateFilterType: "DAY",
	//         dateFilterValue: 7,
	//         branchId: branch?.id,
	//       });

	//       await GetLeadSourceGraphData({
	//         instituteId: instituteId,
	//         dateFilterType: "DAY",
	//         dateFilterValue: 7,
	//         branchId: branch?.id,
	//       });

	//       await GetOverduePayments({
	//         instituteId: instituteId,
	//         branchId: branch?.id,
	//         limit: 15,
	//         lastEvaluatedKey: "",
	//         lastEvaluatedSortKey: "",
	//       });
	//     }
	//   };

	//   fetchBranchAndData();
	// }, [branches, employees]);

	useEffect(() => {
		if (
			location?.state?.isVersionMatching?.tnc === true &&
			location?.state?.isVersionMatching?.privacy === false
		) {
			setOpen(false);
			setOpenPrivacy(true);
		} else if (
			location?.state?.isVersionMatching?.tnc === false &&
			location?.state?.isVersionMatching?.privacy === true
		) {
			setOpen(true);
			setOpenPrivacy(false);
		} else if (
			location?.state?.isVersionMatching?.tnc === false &&
			location?.state?.isVersionMatching?.privacy === false
		) {
			setOpen(true);
			setOpenPrivacy(true);
		} else {
			setOpen(false);
			setOpenPrivacy(false);
		}
	}, [location]);

	React.useEffect(() => {
		if (taskStatusUpdateSuccess !== true && taskStatusUpdateSuccess !== false) {
			if (user !== null) {
				let branchId: any;
				const queryParams = new URLSearchParams(window.location.search);
				const branchParam = queryParams.get('selectedBranch');
				if (branchParam) {
					branchId = branchParam;
				} else if (user?.institute?.[0]?.branchId) {
					branchId = user?.institute?.[0]?.branchId;
				} else {
					const loginTeamember = employees?.find(
						(item: any) => item?.email === user?.email
					);
					if (loginTeamember) {
						branchId = loginTeamember?.branchId;
					}
				}
				if (branchId) {
					dashboardApiCall(
						user?.institute?.[0]?.instituteId,
						user?.institute?.[0]?.id,
						branchId
					);
				}
			}
		}
	}, [taskStatusUpdateSuccess]);
	React.useEffect(() => {
		if (createPaymentSuccess !== true && createPaymentSuccess !== false) {
			if (user !== null) {
				let branchId: any;
				let revenueTimeline: any;

				const queryParams = new URLSearchParams(window.location.search);
				const branchParam = queryParams.get('selectedBranch');
				const revenueTimelineParam = queryParams.get(
					'revenueAnalyticsTimeline'
				);

				if (revenueTimelineParam) {
					revenueTimeline = parseInt(revenueTimelineParam);
				} else {
					revenueTimeline = 7;
				}
				if (branchParam) {
					branchId = branchParam;
				} else if (user?.institute?.[0]?.branchId) {
					branchId = user?.institute?.[0]?.branchId;
				} else {
					const loginTeamember = employees?.find(
						(item: any) => item?.email === user?.email
					);
					if (loginTeamember) {
						branchId = loginTeamember?.branchId;
					}
				}
				if (branchId) {
					OverduePaymentApiCall(user?.institute?.[0]?.instituteId, branchId);
					RevenueAnalyticsGraphApiCall(
						user?.institute?.[0]?.instituteId,
						revenueTimeline,
						branchId
					);
					dashboardApiCall(
						user?.institute?.[0]?.instituteId,
						user?.institute?.[0]?.id,
						branchId
					);
				}
			}
		}
	}, [createPaymentSuccess]);

	return (
		<div
			style={{
				overflowY: 'hidden',
				maxHeight: 'calc(100vh - 65px)',
				minHeight: 'calc(100vh - 65px)',
			}}>
			<InactiveAccountPopup
				type={accountStatus}
				billingAdmin={isBillingAdmin}
			/>
			<div className={styles.dashboardHeadWrap}>
				<div className={styles.dashboardHeadTitle}>Dashboard</div>
				<div className={styles.dashboardHeadRight}>
					<div className={styles.dashboardHeadBranchFielLabel}>Select by</div>
					<div>
						{branches && (
							<Autocomplete
								style={{
									minWidth: '200PX',
								}}
								sx={{
									'&& input': {
										textTransform: 'capitalize',
										color: '#667085',
										fontWeight: '500',
									},
								}}
								disablePortal
								disableClearable
								disabled={hasAllBranchAccess ? false : true}
								id='branchId'
								options={branches}
								getOptionLabel={(option: any) => option.branchName || ''}
								fullWidth
								onChange={onSelectBranchChange}
								size='small'
								value={defaultBranch || null}
								PaperComponent={(props) => (
									<Paper
										sx={{
											background: '#FFF',
											border: '1px solid #E4E4E7',
											fontFamily: 'poppins',
											fontSize: '10px',
											textTransform: 'capitalize',
											color: '#142C44',
											fontWeight: '400',
											margin: '0',
											boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.06)',
										}}
										{...props}
									/>
								)}
								renderInput={(params) => {
									if (!defaultBranch) {
										params.InputProps.endAdornment = null;
									}
									return <TextField variant='outlined' {...params} />;
								}}
							/>
						)}
					</div>
				</div>
			</div>
			<div
				style={{
					overflowY:
						accountStatus === 'ACTIVE' || accountStatus === 'GRACE_PERIOD'
							? 'auto'
							: 'hidden',
					maxHeight: 'calc(100vh - 125px)',
					minHeight: 'calc(100vh - 125px)',
					padding: '14px 20px',
				}}>
				<div
					style={{
						display: 'flex',
						// justifyContent: "space-between",
						gap: isTabSize ? '10px' : '1%',
						marginBottom: isTabSize ? '10px' : '1%',
						flexDirection: isTabSize ? 'column' : 'row',
						flexWrap: 'wrap',
					}}>
					{(userById?.roleCode === 'ADMIN' ||
						userById?.roleCode === 'SUPER_ADMIN' ||
						userById?.roleCode === 'ACCOUNTS') && (
						<>
							{dashboardDetails === null ? (
								<div style={{ width: '100%' }}>
									<SplitSkeleton />
								</div>
							) : (
								<>
									<div
										style={{
											width: isTabSize ? '100%' : '66.4%',
											marginBottom: '10px',
										}}>
										<SalesPipelineOverview
											salesData={dashboardDetails?.salesPipelineData}
											selectedCurrency={selectedCurrency}
										/>
									</div>
									<div
										style={{
											width: isTabSize ? '100%' : '32.6%',
											marginBottom: '10px',
										}}>
										<DataTiles
											batchSlideData={dashboardDetails?.batchSectionData}
											selectedCurrency={selectedCurrency}
										/>
									</div>
								</>
							)}
						</>
					)}

					{(userById?.roleCode === 'ADMIN' ||
						userById?.roleCode === 'SUPER_ADMIN' ||
						userById?.roleCode === 'ACCOUNTS') && (
						<>
							<div
								style={{
									width: isTabSize ? '100%' : '66.4%',
									minHeight: '440px',
									marginBottom: '10px',
								}}>
								{revenuGraphData === null ? (
									<DashboardSkeleton />
								) : (
									<RevenueAnalyticsGraph
										RevenueAnalyticsGraphApiCall={RevenueAnalyticsGraphApiCall}
										revenuGraphData={revenuGraphData}
										selectedCurrency={selectedCurrency}
									/>
								)}
							</div>
						</>
					)}
					<div
						style={{
							width: isTabSize ? '100%' : '32.6%',
							minHeight: '440px',
							marginBottom: '10px',
						}}>
						{leadSourceGraphData === null ? (
							<DashboardSkeleton />
						) : (
							<LeadSourceData
								leadSourceData={leadSourceGraphData}
								LeadSourceGraphApiCall={LeadSourceGraphApiCall}
							/>
						)}
					</div>

					{(userById?.roleCode === 'ADMIN' ||
						userById?.roleCode === 'SUPER_ADMIN' ||
						userById?.roleCode === 'ACCOUNTS' ||
						userById?.roleCode === 'COURSE_COUNSELOR') && (
						<>
							<div
								style={{
									width: isTabSize ? '100%' : '66.4%',
									marginBottom: '10px',
								}}>
								{overduePaymentsData === null ? (
									<DashboardSkeleton />
								) : (
									<OverduePayments
										overduePaymentsData={overduePaymentsData}
										selectedBranch={selectedBranch}
										selectedCurrency={selectedCurrency}
									/>
								)}
							</div>
						</>
					)}

					<div
						style={{
							width: isTabSize ? '100%' : '32.6%',
							marginBottom: '10px',
						}}>
						{dashboardDetails === null ? (
							<DashboardSkeleton />
						) : (
							<MyTask
								data={dashboardDetails?.myTaskData?.myTaskData}
								sideIcon={sideIcon}
								GetLeadListByInstituteAndContact={
									GetLeadListByInstituteAndContact
								}
							/>
						)}
					</div>
					{(userById?.roleCode === 'ADMIN' ||
						userById?.roleCode === 'SUPER_ADMIN' ||
						userById?.roleCode === 'ACCOUNTS') && (
						<div
							style={{
								width: isTabSize ? '100%' : isTabSize ? '100%' : '32.6%',
								marginBottom: '10px',
							}}>
							{dashboardDetails === null ? (
								<DashboardSkeleton />
							) : (
								<StageVsExpectedRevenue
									labels={
										dashboardDetails?.stageGraphData?.revenueGraphData?.labels
									}
									data={
										dashboardDetails?.stageGraphData?.revenueGraphData?.datasets
									}
									yMaxValue={
										dashboardDetails?.stageGraphData?.revenueGraphData
											?.yMaxValue
									}
								/>
							)}
						</div>
					)}

					<div
						style={{
							width: isTabSize ? '100%' : '32.6%',
							marginBottom: '10px',
						}}>
						{dashboardDetails === null ? (
							<DashboardSkeleton />
						) : (
							<StageVsLeads
								labels={
									dashboardDetails?.stageGraphData?.activeLeadGraphData?.labels
								}
								data={
									dashboardDetails?.stageGraphData?.activeLeadGraphData
										?.datasets
								}
								yMaxValue={
									dashboardDetails?.stageGraphData?.activeLeadGraphData
										?.yMaxValue
								}
							/>
						)}
					</div>
					<div
						style={{
							width: isTabSize ? '100%' : '32.6%',
							marginBottom: '10px',
						}}>
						{dashboardDetails === null ? (
							<DashboardSkeleton />
						) : (
							<StageVsLost
								labels={
									dashboardDetails?.stageGraphData?.lostLeadGraphData?.labels
								}
								data={
									dashboardDetails?.stageGraphData?.lostLeadGraphData?.datasets
								}
								yMaxValue={
									dashboardDetails?.stageGraphData?.lostLeadGraphData?.yMaxValue
								}
							/>
						)}
					</div>
				</div>
			</div>

			<Modal open={false} onClose={handleClosePrivacy}>
				<Box sx={style}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}>
						{location?.state?.isVersionMatching?.privacy === true && (
							<button
								style={{
									background: 'none',
									border: 'none',
									cursor: 'pointer',
								}}
								onClick={handleClosePrivacy}>
								<CloseIcon />
							</button>
						)}
					</div>
					<PrivacyPolicy
						tnc={location?.state?.tnc}
						isVersionMatching={location?.state?.isVersionMatching}
						privacyPolicyLoading={privacyPolicyLoading}
						privacyPolicySuccess={privacyPolicySuccess}
						privacyPolicyError={privacyPolicyError}
						AcceptPrivacyPolicy={AcceptPrivacyPolicy}
						setOpenPrivacy={setOpenPrivacy}
					/>
				</Box>
			</Modal>
			<Modal open={false} onClose={handleClose}>
				<Box sx={style}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}>
						{location?.state?.isVersionMatching?.tnc === true && (
							<button
								style={{
									background: 'none',
									border: 'none',
									cursor: 'pointer',
								}}
								onClick={handleClose}>
								<CloseIcon />
							</button>
						)}
					</div>
					<TermsOfServices
						tnc={location?.state?.tnc}
						isVersionMatching={location?.state?.isVersionMatching}
						termsAndConditionsLoading={termsAndConditionsLoading}
						termsAndConditionsSuccess={termsAndConditionsSuccess}
						termsAndConditionsError={termsAndConditionsError}
						AcceptTnC={AcceptTnC}
						setOpen={setOpen}
					/>
				</Box>
			</Modal>
		</div>
	);
}
