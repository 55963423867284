import React from "react";
import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";
import TableSkeleton from "../TableSkeleton";

function LeadTransactionSkeleton() {
  return (
    <div style={{ background: "#fff", padding: "20px 24px" }}>
      <div
        style={{
          width: "20%",
          marginBottom: "20px",
        }}
      >
        <Skeleton animation="wave" variant="text" width="100%" height={25} />
      </div>
      <TableSkeleton />
    </div>
  );
}

export default LeadTransactionSkeleton;
