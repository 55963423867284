import CheckIcon from "@mui/icons-material/Check";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import styles from "./RegistrationStage.module.css";

function listClassName(
  currentIndex: number,
  status: boolean,
  itemList: [
    { id: number; listText: String; endPoint: string; status: boolean }
  ]
): "lockedList_completed" | "lockedList" | "lockedList_inProgress" {
  if (status) {
    return "lockedList_completed";
  } else {
    if (currentIndex === 0) {
      return "lockedList_inProgress";
    } else {
      if (itemList[currentIndex - 1]["status"] === true) {
        return "lockedList_inProgress";
      } else {
        return "lockedList";
      }
    }
  }
}
const boxStyleSubCondtion = (
  status: "lockedList_completed" | "lockedList" | "lockedList_inProgress"
) =>
  status === "lockedList_inProgress"
    ? { borderColor: "#11C89B" }
    : { color: "#69696B", borderColor: "#69696B" };
export function RegistrationStage(props: any) {
  const { itemList, item, index } = props;
  return (
    <ListItem
      className={styles[listClassName(index, item.status, itemList)]}
      key={item.id}
    >
      <Box
        style={{
          display: "contents",
        }}
      >
        <ListItemIcon>
          <Box className={styles.number_wrapperdiv}>
            <Box
              className={styles.lockedProfile_count}
              style={
                listClassName(index, item.status, itemList) ===
                "lockedList_completed"
                  ? {
                      color: "#11C89B",
                    }
                  : boxStyleSubCondtion(
                      listClassName(index, item.status, itemList)
                    )
              }
            >
              {index + 1}
            </Box>
          </Box>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography style={{ fontSize: "15px", fontWeight: "500" }}>
              {item.listText}
            </Typography>
          }
        />
        <ListItemIcon
          className={styles.listItemIcon}
          style={
            listClassName(index, item.status, itemList) ===
            "lockedList_completed"
              ? {
                  color: "#11C89B",
                }
              : {
                  color: "#585858",
                }
          }
        >
          {listClassName(index, item.status, itemList) ===
          "lockedList_completed" ? (
            <CheckIcon />
          ) : listClassName(index, item.status, itemList) ===
            "lockedList_inProgress" ? (
            <LockOpenIcon color="primary" />
          ) : (
            <LockOutlinedIcon />
          )}{" "}
        </ListItemIcon>
      </Box>
    </ListItem>
  );
}
