import { FormControl, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../components/Managebatches.module.css";
import { BasicButtonGroup } from "../../../../../../components/BasicButtonGroup/BasicButtonGroup";

export default function BatchTypeManager(props: any) {
  const {
    handleTypeChange,
    errorMszs,
    selectedType,
    batchTypeList,
    disabled,
    setBatchDetails,
    batchDetails,
    batchData,
  } = props;

  const [selected, setSelected] = useState<any[]>([]);
  const handleSelected = React.useCallback(
    (value: never) => {
      if (selected.includes(value)) {
        setSelected((prevSelected) => prevSelected?.filter((s) => s !== value));
      } else {
        setSelected((prevSelected) => [...prevSelected, value]);
      }

      setSelected((prevSelected) => {
        handleTypeChange(
          prevSelected?.map((value: any) => ({
            batchType: batchTypeList[value],
          }))
        );
        return prevSelected;
      });
    },
    [selected, handleTypeChange]
  );

  function getIndex(selectedType: any[]) {
    const indexes = selectedType.map((type: any) => {
      return (
        batchTypeList &&
        batchTypeList?.findIndex((obj: any) => obj?.id === type?.batchType?.id)
      );
    });
    return indexes;
  }

  const mappedData = batchTypeList
    ? batchTypeList?.map((item: any, index: number) => {
        return {
          label: item.courseTypeName,
          value: item.courseTypeName,
        };
      })
    : [];

  // useEffect(() => {
  //   if (selected.length === 0 && selectedType) {
  //     setSelected(getIndex(selectedType));
  //   }
  // }, [setSelected, selectedType]);

  useEffect(() => {
    setSelected([0]);
  }, []);

  useEffect(() => {
    const batchTypes = batchTypeList?.filter(
      (item: any) => item?.courseTypeName?.toLowerCase() === "classroom"
    );
    setBatchDetails((prevState: any) => ({
      ...prevState,
      batchType: [{ batchType: batchTypes[0] }],
    }));
  }, [selected]);

  return (
    <FormControl variant="standard" className="formControl">
      <Typography
        sx={{
          mb: 1,
          fontFamily: "Poppins",
          fontSize: "12px",
          color: "#142C44",
          fontWeight: "500",
        }}
      >
        Type
        <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
      </Typography>

      <BasicButtonGroup
        buttonList={mappedData}
        handleSelected={handleSelected}
        selected={selected}
        errorMsg={errorMszs}
        disabled={disabled}
      />
    </FormControl>
  );
}
