import {
  Box,
  Dialog,
  Pagination,
  PaginationItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  FormGroup,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import clock from "./Assets/clock.svg";
import calander from "./Assets/batchCalIcon.svg";
import FilterDrawer from "../../../../components/FilterDrawer/FilterDrawer";
import { useSelector } from "react-redux";
import { tableCellClasses } from "@mui/material/TableCell";
import styles from "./Nurture.module.css";
import searchClose from "./Assets/closeIcon.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dummyimage from "./Assets/coursedummyimage.png";
import ClearFilter from "../../../../components/ClearFilter/ClearFilter";
import ProgressBar from "./ProgressBar/ProgressBar";
import Skeleton from "@mui/material/Skeleton";
import FilterTypeWrap from "../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterSearchField from "../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import ShowAllBtn from "../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterChipsWrap from "../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../components/FilterDrawer/FilterChips/FilterChips";
import { convertToAdjustedTimestamp } from "../../../../components/Formatter/Formatter";
import { useAuthUser } from "react-auth-kit";
import { getSubDomainAccessStatus } from "../../../../components/RolePermissionAccess/RolePermissionAccess";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",

    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function NurtureKanbanBatchList(props: any) {
	const batch = useSelector((state: any) => state.batch.batchById);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const {
		handleDragOver,
		batchList,
		batchTypes,
		ConvertLeadToStudent,
		draggedLead,
		branchList,
		selectedCourse,
		GetBatchById,
		courseList,
		dropZoneActive,
		setDropZoneActive,
	} = props;
	console.log('branchList', branchList);
	const batchByIdLoading = useSelector(
		(state: any) => state?.batch?.batchByIdLoading
	);

	const [selectedBatch, setOpenSelectedBatch] = React.useState(null);

	const handleChange = (value: any) => {
		setPage(value - 1);
	};

	const handleBatchDrop = (
		event: React.DragEvent<HTMLDivElement>,
		batchId: any,
		batchName: string
	) => {
		ConvertLeadToStudent({
			enquiryId: draggedLead,
			batchId: batchId,
		});
		setDropZoneActive(false);
	};

  const [openBatchModal, setOpenBatchModal] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = React.useState("")
	const handleClose = () => {
		setOpenBatchModal(false);

		const queryParams = new URLSearchParams(window.location.search);
		queryParams.delete('boardBatchModal');
		queryParams.delete('boardBatchId');

		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	const handleOpenBatchModal = (batchId: number) => {
		const selectedBatchObj = batchList.find(
			(batch: any) => batch.batchId === batchId
		);

		setOpenSelectedBatch(selectedBatchObj);
		GetBatchById(batchId && batchId);

		setOpenBatchModal(true);

		const queryParams = new URLSearchParams(window.location.search);
		if (batchId) {
			queryParams.set('boardBatchModal', 'true');
			queryParams.set('boardBatchId', batchId.toString());

			const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
			window.history.pushState({}, '', newUrl);
		}
	};

  const BatchListData = (props: any) => {
 
		const { data, handleDragOver, handleBatchDrop } = props;
    console.log("data", data)
		let filteredSchedule: any = null;

		if (data.batchSchedule && Array.isArray(data.batchSchedule)) {
			const startDateConverted =
				data?.startDate?.toString()?.length === 13
					? moment(convertToAdjustedTimestamp(data?.startDate))
					: moment.unix(data?.startDate);

			const startDayFormatted = startDateConverted.format('ddd').toUpperCase();

			data.batchSchedule.forEach((schedule: any) => {
				// Check if the day matches

				if (schedule.day === startDayFormatted) {
					// Check if batchSessions is present and is an array
					if (schedule.batchSessions && Array.isArray(schedule.batchSessions)) {
						// Find the session based on startTime

						const session = schedule.batchSessions?.[0];

						if (session) {
							filteredSchedule = {
								day: schedule.day,
								time: session,
								// Add more properties as needed
							};
						}
					}
				}
			});
		}

		React.useEffect(() => {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const batchIdinUrl = urlSearchParams.get('boardBatchId');
			const boardBatchModalInUrl = urlSearchParams.get('boardBatchModal');

			if (batchIdinUrl && boardBatchModalInUrl) {
				setOpenBatchModal(true);
			}
		}, [setOpenBatchModal]);

		const { userRoleById, userById } = useSelector(
			(state: any) => state.userDetails
		);

		const hasAccess = getSubDomainAccessStatus(
			'NURTURE_BOARD',
			'LEAD_CARD',
			'ALL_ACCESS',
			// userRoleById?.permissions
			userById?.rolePermission
		);

		return (
			<Box
				sx={{
					width: '100%',
					backgroundColor: '##FFFFFF',
					mt: '10px',
					borderRadius: '6px',
					padding: '12px 12px',
					cursor: 'pointer',
					border: dropZoneActive ? '2px dashed #11c89b' : '1px solid #E6E6E6',
				}}
				className={styles.dropableBatchItem}
				onClick={() => handleOpenBatchModal(data?.id)}
				onDragOver={hasAccess ? handleDragOver : undefined}
				onDrop={(event) => {
					if (hasAccess) {
						handleBatchDrop(event, data.id, data.batchName);
					}
				}}>
				<div
					style={{
						fontSize: '15px',
						fontWeight: '600',
						lineHeight: '20px',
						textTransform: 'capitalize',
						color: '#3F3E43',
					}}>
					{data?.batchName}
				</div>
				<div
					style={{
						fontSize: '12px',
						fontWeight: '400',
						lineHeight: '22px',
						textTransform: 'capitalize',
						color: '#667085',
					}}>
					{data?.branchName}
				</div>
				<Box
					style={{
						display: 'flex',
						marginTop: '8px',
						marginBottom: '10px',
						alignItems: 'center',
						gap: '19px',
					}}>
					<div
						style={{
							display: 'flex',
							gap: '7px ',
							fontSize: '12px',
							lineHeight: '22px',
							color: '#667085',
							textTransform: 'capitalize',
							alignItems: 'center',
						}}>
						<div style={{ display: 'flex' }}>
							<img src={calander} alt='' />
						</div>
						<div>
							{data?.startDate?.toString()?.length === 13
								? moment(data.startDate).format('DD MMM YYYY')
								: moment.unix(data.startDate).format('DD MMM YYYY')}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							gap: '7px ',
							fontSize: '12px',
							lineHeight: '22px',
							color: '#667085',
						}}>
						<div style={{ display: 'flex' }}>
							<img src={clock} alt='' />
						</div>
						<div>{moment.unix(data?.batchStartTime).format('hh:mm A')}</div>
					</div>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box
						sx={{
							marginBottom: '5px',
							color: '#667085',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '22px',
						}}>
						Seats
					</Box>
					<Box sx={{ display: 'flex' }}>
						<Box>
							<h1
								style={{
									margin: '0px',
									color:
										data?.studentCount < data?.minimumSeats
											? ' #EC3939'
											: '#11C89B',
									fontSize: '14px',
									fontWeight: '400',
									lineHeight: '22PX',
								}}>
								{data?.studentCount}
							</h1>
						</Box>
						<Box>
							<h1
								style={{
									fontSize: '14px',
									fontWeight: '400',
									lineHeight: '22PX',
									color: '#667085',
								}}>
								/
							</h1>
						</Box>
						<Box>
							<h1
								style={{
									margin: '0px',
									color: '#667085',
									fontSize: '14px',
									fontWeight: '400',
									lineHeight: '22PX',
								}}>
								{data?.maximumSeats}
							</h1>
						</Box>
					</Box>
				</Box>
				<ProgressBar
					minimumSeats={data?.minimumSeats}
					maximumSeats={data?.maximumSeats}
					enrolledSeats={data?.studentCount}
				/>
			</Box>
		);
	};

	let startIndex = page * rowsPerPage;

	const urlSearchParams = new URLSearchParams(window.location.search);
	const boardBatchTypeInUrl = urlSearchParams.get('selectedBatchType');
	const selectedBranchTypeInUrl = urlSearchParams.get('selectedBranchId');

	// Batch Filter Starts

	const [branchSearchTerm, setBranchSearchTerm] = React.useState('');
	const [showAllBranch, setShowAllBranch] = React.useState<boolean>(false);

	const filteredBranch =
		branchList &&
		branchList?.filter((employee: any) => {
			const searchTermMatch =
				employee.branchName
					.toLowerCase()
					.indexOf(branchSearchTerm.toLowerCase()) !== -1;

			return searchTermMatch;
		});

	const displayedBranch = showAllBranch
		? branchList && filteredBranch
		: branchList && filteredBranch.slice(0, 5);

	const handleShowMoreBranch = () => {
		setShowAllBranch(!showAllBranch);
	};

	interface CheckedBranch {
		id: string;
		branchName: string;
	}

	interface batchType {
		id: string;
		batchType: string;
	}

	const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

	const [filterApplied, setFilterApplied] = React.useState<boolean>(false);

	const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);
	const [filterCheckedBranches, setFilterCheckedBranches] = useState<
		CheckedBranch[]
	>([]);

	const [checkedBatchType, setCheckedBatchType] = useState<batchType[]>([]);
	const [filterCheckedBatchType, setFilterCheckedBatchType] = useState<
		batchType[]
	>([]);

	const handleFilterBranchChecked = (id: string, branchName: string) => {
		const branchToAdd: CheckedBranch = { id, branchName };
		if (checkedBranches.some((branch) => branch.id === id)) {
			setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
		} else {
			setCheckedBranches([...checkedBranches, branchToAdd]);
		}
	};

	const clearCheckedBranch = (id: string) => {
		setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
	};

	const handleFilterBatchTypeChecked = (id: string, batchType: string) => {
		const batchTypeToAdd: batchType = { id, batchType };
		if (checkedBatchType.some((item) => item.id === id)) {
			setCheckedBatchType(checkedBatchType.filter((item) => item.id !== id));
		} else {
			setCheckedBatchType([...checkedBatchType, batchTypeToAdd]);
		}
	};

	const clearCheckedBatchType = (id: string) => {
		setCheckedBatchType(checkedBatchType.filter((item) => item.id !== id));
	};
	const auth = useAuthUser();
	const authUser = auth();
	const loginUserBranch = authUser?.institute?.[0]?.branchId;
	const { userRoleById, userById } = useSelector(
		(state: any) => state.userDetails
	);

	const hasAllBranchAccess = getSubDomainAccessStatus(
		'NURTURE_BOARD',
		'BOARD',
		'ALL_BRANCHES',
		// userRoleById?.permissions
		userById?.rolePermission
	);

	const filteredBatchList = batchList
		?.filter((item: any) => {
			return !hasAllBranchAccess ? item.branchId === loginUserBranch : true;
		})
		?.filter((batch: any) => {
			const courseMatch =
				selectedCourse === null || batch.courseId === selectedCourse.courseId;

			const branchIsChecked =
				filterCheckedBranches.length === 0 ||
				filterCheckedBranches.some((item) => item.id === batch.branchId);

			const batchTypeIsChecked =
				filterCheckedBatchType.length === 0 ||
				batch.batchType.some((type: any) =>
					filterCheckedBatchType.some(
						(checkedType) => checkedType.id === type.batchType?.id
					)
				);
			const isActive = batch.status === 'ACTIVE';
			const isActiveBatchStatus = batch.batchStatus !== 'COMPLETED';
			const isBatchConductType = batch.batchConductType === 'REGULAR_BATCH';

			return (
				courseMatch &&
				branchIsChecked &&
				batchTypeIsChecked &&
				isActive &&
				isActiveBatchStatus &&
				isBatchConductType
			);
		});

	const handleFilterBatches = React.useCallback(
		(branchArray: any, batchTypeArray: any) => {
			setFilterCheckedBranches(branchArray);
			setFilterCheckedBatchType(batchTypeArray);

			const queryParams = new URLSearchParams();
			if (branchArray && branchArray.length > 0) {
				const branchParam = branchArray
					.map((branch: any) => branch.id)
					.join('|');
				queryParams.set('selectedBranchId', branchParam);
			} else {
				queryParams.delete('selectedBranchId');
			}

			if (batchTypeArray && batchTypeArray.length > 0) {
				const batchTypeParam = batchTypeArray
					.map((item: any) => item.id)
					.join('|');
				queryParams.set('selectedBatchType', batchTypeParam);
			}

			const queryString = queryParams.toString();
			const newUrl = `${window.location.pathname}${
				queryString ? `?${queryString}` : ''
			}`;

			window.history.pushState({}, '', newUrl);
		},
		[setFilterCheckedBranches, setFilterCheckedBatchType]
	);

	const handleFilterApply = React.useCallback(() => {
		handleFilterBatches(checkedBranches, checkedBatchType);
		setFilterApplied(true);
	}, [
		setFilterApplied,
		handleFilterBatches,
		checkedBranches,
		checkedBatchType,
	]);

	const handleFilterReset = React.useCallback(() => {
		setFilterApplied(false);

		setCheckedBranches([]);
		setFilterCheckedBranches([]);
		setCheckedBatchType([]);
		setFilterCheckedBatchType([]);

		const queryParams = new URLSearchParams(window.location.search);
		queryParams.delete('selectedBranchId');
		queryParams.delete('selectedBatchType');

		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	}, [
		setFilterApplied,
		setCheckedBranches,
		setFilterCheckedBranches,
		setCheckedBatchType,
		setFilterCheckedBatchType,
	]);

	const handleFilterClose = React.useCallback(() => {
		setCheckedBranches(filterCheckedBranches);
		setCheckedBatchType(filterCheckedBatchType);
	}, [filterCheckedBranches, filterCheckedBatchType]);

	const filteredValue = (
		<>
			{filterCheckedBranches?.length > 0 &&
				filterCheckedBranches?.map((item) => (
					<div key={item.id} className='filteredDiv'>
						{item.branchName}
					</div>
				))}

			{filterCheckedBatchType?.length > 0 &&
				filterCheckedBatchType?.map((item) => (
					<div key={item.id} className='filteredDiv'>
						{item.batchType}
					</div>
				))}
		</>
	);

	const cancelClick = () => {
		setIsOpenFilter(true);
	};

	const closeClearFilter = () => {
		setIsOpenFilter(false);
	};

	const yesButtonClick = () => {
		handleFilterReset();
		setIsOpenFilter(false);
	};

	const noButtonClick = () => {
		setIsOpenFilter(false);
  };

  const findCurrency = (id: any) => {
		return branchList.find((branch: any) => branch.id === id).currency ?? '₹';
	};

	React.useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);

		const branchParam = urlSearchParams.get('selectedBranchId');
		const batchTypeParam = urlSearchParams.get('selectedBatchType');

		if (branchParam && branchList) {
			const branchIds = branchParam.split('|');

			const selectedBranches = branchIds
				.map((id) => {
					const branch = branchList?.find((branch: any) => branch.id === id);
					return branch ? { id, branchName: branch.branchName } : null;
				})
				.filter(Boolean) as CheckedBranch[];

			console.log('selectedBranches', selectedBranches);
			setFilterCheckedBranches(selectedBranches);
			setCheckedBranches(selectedBranches);
		}

		if (batchTypeParam && batchTypes) {
			const batchTypeIds = batchTypeParam.split('|');

			const selectedBatchTypes = batchTypeIds
				.map((id) => {
					const batchType = batchTypes?.find((type: any) => type.id === id);
					return batchType ? { id, batchType: batchType.courseTypeName } : null;
				})
				.filter(Boolean) as batchType[];

			setFilterCheckedBatchType(selectedBatchTypes);
			setCheckedBatchType(selectedBatchTypes);
		}

		if (branchParam || batchTypeParam) {
			setFilterApplied(true);
		}
	}, [setFilterApplied, branchList, batchTypes, setFilterApplied]);

	// Batch Filter Ends

	return (
		<Box
			sx={{
				p: '19px 20px',
				position: 'relative',
			}}>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
				}}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						gap: '4px',
					}}>
					{courseList === null ||
					batchList === null ||
					selectedCourse === null ? (
						<Skeleton
							variant='rectangular'
							width='100%'
							animation='wave'
							height={40}
						/>
					) : (
						<>
							<div
								style={{
									fontSize: '16px',
									fontWeight: '500',
									lineHeight: '24px',
									color: '#142C44',
								}}>
								Batches
							</div>
							<FilterDrawer
								handleFilterApply={handleFilterApply}
								handleResetClick={handleFilterReset}
								activeFilter={filterApplied}
								cancelClick={cancelClick}
								closeSetFilter={handleFilterClose}>
								<div
									style={{
										padding: '22px 32px',
										overflowY: 'auto',
										maxHeight: 'calc(100% - 110px)',
									}}>
									<FilterChipsWrap>
										{checkedBranches.length > 0 &&
											checkedBranches.map((item) => (
												<FilterChips
													keyVal={item.id}
													chipName={item.branchName}
													clearValue={item.id}
													closeFunction={clearCheckedBranch}
												/>
											))}

										{checkedBatchType.length > 0 &&
											checkedBatchType.map((item) => (
												<FilterChips
													keyVal={item.id}
													chipName={item.batchType}
													clearValue={item.id}
													closeFunction={clearCheckedBatchType}
												/>
											))}
									</FilterChipsWrap>

									{hasAllBranchAccess && (
										<FilterTypeWrap>
											<FilterLabel labelValue='Branch' />
											<FilterSearchField
												value={branchSearchTerm}
												onChange={(e: any) => {
													setBranchSearchTerm(e.target.value);
												}}
												placeholder='Search Branch'
											/>
											<FilterCheckBoxWrap>
												<FormGroup>
													{branchList &&
														displayedBranch?.map((item: any) => (
															<FilterCheckbox
																keyVal={item.id}
																checked={checkedBranches.some(
																	(data) => data.id === item.id
																)}
																checkboxFunction={() =>
																	handleFilterBranchChecked(
																		item.id,
																		item.branchName
																	)
																}
																label={item.branchName.toLowerCase()}
															/>
														))}
												</FormGroup>
											</FilterCheckBoxWrap>

											<ShowAllBtn
												clickFunction={handleShowMoreBranch}
												showAll={showAllBranch}
												filteredState={filteredBranch}
											/>
										</FilterTypeWrap>
									)}

									<FilterTypeWrap>
										<FilterLabel labelValue='Type' />

										<FilterCheckBoxWrap>
											<FormGroup>
												{batchTypes &&
													batchTypes?.map((item: any) => (
														<FilterCheckbox
															keyVal={item.id}
															checked={checkedBatchType.some(
																(data) => data.id === item.id
															)}
															checkboxFunction={() =>
																handleFilterBatchTypeChecked(
																	item.id,
																	item.courseTypeName
																)
															}
															label={item.courseTypeName.toLowerCase()}
														/>
													))}
											</FormGroup>
										</FilterCheckBoxWrap>
									</FilterTypeWrap>
								</div>
							</FilterDrawer>
						</>
					)}
				</div>

				<Box
					sx={{
						mt: '5px',
						height: 'calc(100vh - 180px)',
						maxHeight: 'calc(100vh - 180px)',
						overflow: 'auto',
					}}>
					{courseList === null ||
					batchList === null ||
					selectedCourse === null ? (
						Array.from({ length: 3 }, (_, index) => (
							<Skeleton
								key={index}
								animation='wave'
								variant='rectangular'
								height={140}
								width='100%'
								style={{ marginBottom: '10px', borderRadius: '2PX' }}
							/>
						))
					) : (
						<>
							{batchList && filteredBatchList?.length === 0 && (
								<div style={{ textAlign: 'center', justifyContent: 'center' }}>
									No Batches Found
								</div>
							)}
							{batchList &&
								filteredBatchList?.map((res: any) => (
									<BatchListData
										key={res.id}
										data={res}
										handleDragOver={handleDragOver}
										handleBatchDrop={handleBatchDrop}
									/>
								))}
						</>
					)}
				</Box>
				<Dialog
					open={openBatchModal}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
					sx={{
						zIndex: '99999',
						minWidth: {
							xs: '85%',
							sm: '85%',
							md: '85%',
							lg: '960px',
							xl: '960px',
						},
						'& .MuiDialog-paper': {
							width: '100%',
							padding: '30px 30px',
							overflow: 'hidden',
							maxWidth: {
								xs: '85%',
								sm: '85%',
								md: '85%',
								lg: '900px',
								xl: '900px',
							},
							maxHeight: {
								xs: '600px',
								sm: '600px',
								md: '600px',
								lg: '600px',
								xl: '600px',
							},
							minHeight: {
								xs: '600px',
								sm: '600px',
								md: '600px',
								lg: '600px',
								xl: '600px',
							},
						},
					}}>
					<div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								zIndex: '999999999',
								width: '100%',
							}}>
							<button
								style={{
									background: 'none',
									border: 'none',
									cursor: 'pointer',
								}}
								onClick={handleClose}>
								<img src={searchClose} alt='' />
							</button>
						</div>

						{batchByIdLoading ? (
							<div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										gap: '25px',
										marginBottom: '25px',
									}}>
									<div>
										<Skeleton
											animation='wave'
											variant='rounded'
											height={230}
											width={300}
										/>
									</div>
									<div
										style={{
											flex: 1,
										}}>
										<Skeleton
											animation='wave'
											variant='rounded'
											height={230}
											width='100%'
										/>
									</div>
								</div>
								<div>
									<Skeleton animation='wave' variant='rounded' height={200} />
								</div>
							</div>
						) : (
							<>
								<div className={styles.mainWrap}>
									<div className={styles.batchPopupTopSplit}>
										<div className={styles.batchPopupTopImg}>
											<img
												src={
													batch && batch?.courseImage
														? batch?.courseImage
														: dummyimage
												}
												alt={batch?.courseName}
											/>
										</div>
										<div className={styles.batchPopupTopSplitRight}>
											<h2 className={styles.batchPopupCourseName}>
												{batch?.courseName}
											</h2>
											<div className={styles.batchPopupDetailsBox}>
												<div className={styles.batchPopupDetailsBoxHead}>
													<div>
														<div
															className={styles.batchPopupDetailsBoxBatchName}>
															{batch?.batchName}
														</div>
														<div
															className={styles.batchPopupDetailsBoxBranchName}>
															{batch?.branchName}
														</div>
													</div>
													<Box sx={{ width: '40%' }}>
														<Box
															sx={{
																display: 'flex',
																justifyContent: 'space-between',
															}}>
															<Box
																sx={{ display: 'flex', alignItems: 'center' }}>
																<Box
																	sx={{
																		color: '#667085',
																		fontSize: '13px',
																		fontWeight: '400',
																		lineHeight: '22PX',
																		textTransform: 'capitalize',
																	}}>
																	seats
																</Box>
																<Box
																	sx={{
																		color: ' rgba(102, 112, 133, 0.3)',
																		fontSize: '11px',
																		fontWeight: '500',
																		lineHeight: '15PX',
																		marginRight: '4px',
																		marginLeft: '6px',
																		textTransform: 'capitalize',
																	}}>
																	|
																</Box>
																<Box
																	sx={{
																		margin: '0px',
																		color: ' #667085',
																		fontSize: '11px',
																		fontWeight: '400',
																		lineHeight: '14PX',
																		textTransform: 'capitalize',
																	}}>
																	Min - {batch?.minimumSeats}
																</Box>
															</Box>
															<Box sx={{ display: 'flex' }}>
																<Box>
																	<h1
																		style={{
																			margin: '0px',
																			color:
																				batch &&
																				batch?.studentCount <
																					batch?.minimumSeats
																					? ' #EC3939'
																					: '#11C89B',
																			fontSize: '14px',
																			fontWeight: '400',
																			lineHeight: '22PX',
																		}}>
																		{batch?.studentCount}
																	</h1>
																</Box>
																<Box>
																	<div
																		style={{
																			fontSize: '14x',
																			fontWeight: '400',
																			lineHeight: '22PX',
																			color: '#667085',
																		}}>
																		/
																	</div>
																</Box>
																<Box>
																	<h1
																		style={{
																			margin: '0px',
																			color: '#142C44',
																			fontSize: '14px',
																			fontWeight: '400',
																			lineHeight: '22PX',
																		}}>
																		{batch?.maximumSeats}
																	</h1>
																</Box>
															</Box>
														</Box>
														<ProgressBar
															minimumSeats={batch?.minimumSeats}
															maximumSeats={batch?.maximumSeats}
															enrolledSeats={batch?.studentCount}
														/>
													</Box>
												</div>

												<div className={styles.batchPopupDetailsBoxBottom}>
													<div className={styles.batchPopupDetailsBoxLeft}>
														<div className={styles.batchPopupDetailsBoxDates}>
															<div>
																Start Date:{' '}
																{batch?.startDate?.toString()?.length === 13
																	? moment(batch?.startDate).format(
																			'DD MMM YYYY'
																	  )
																	: moment
																			.unix(batch?.startDate)
																			.format('DD MMM YYYY')}
															</div>
															<div>
																End Date:{' '}
																{batch?.endDate?.toString()?.length === 13
																	? moment(batch?.endDate).format('DD MMM YYYY')
																	: moment
																			.unix(batch?.endDate)
																			.format('DD MMM YYYY')}
															</div>
														</div>
														<div
															className={
																styles.batchPopupDetailsBoxbottomSchedule
															}>
															<div className={styles.batchPopupScheduleTooltip}>
																<div
																	className={
																		styles.batchPopupScheduleTooltipLabel
																	}>
																	Scheduled Timing
																</div>
																<div
																	className={
																		styles.batchPopupScheduleTooltipWrap
																	}>
																	<div
																		className={
																			styles.batchPopupScheduleTooltipHead
																		}>
																		Timing
																	</div>
																	<ul
																		className={
																			styles.batchPopupScheduleTooltipWrapList
																		}>
																		{batch &&
																			batch?.batchSchedule
																				?.filter(
																					(item: any) =>
																						item?.batchSessions?.length > 0 &&
																						item?.batchSessions?.every(
																							(session: any) =>
																								session.startTime !== 0
																						)
																				)
																				?.map((item: any, index: number) => (
																					<li
																						key={index}
																						className={
																							styles.batchPopupScheduleTooltipWrapListItem
																						}>
																						<div
																							className={
																								styles.batchPopupScheduleTooltipWrapListItemDay
																							}>
																							{item.day &&
																								item?.day.toLowerCase()}
																						</div>
																						<div
																							style={{
																								display: 'flex',
																								flexDirection: 'column',
																								gap: '4px',
																							}}>
																							{item?.batchSessions?.map(
																								(item: any, index: number) => (
																									<div key={index}>
																										{moment
																											.unix(item?.startTime)
																											.format('hh:mm A') +
																											' ' +
																											'to' +
																											' ' +
																											moment
																												.unix(item?.endTime)
																												.format('hh:mm A')}
																									</div>
																								)
																							)}
																						</div>
																					</li>
																				))}
																	</ul>
																</div>
															</div>
														</div>
													</div>
													<div className={styles.batchPopupDetailsBoxRight}>
														<div className={styles.batchPopupDetailsBoxPrice}>
															{batch?.paymentDetails?.paymentType ===
															'REGULAR' ? (
																<>
																	{/* 		{batch?.paymentDetails?.paymentData?.totalAmount?.toLocaleString(
																		'en-IN',
																		{
																			style: 'currency',
																			currency: 'INR',
																		}
																	)} */}
																	{findCurrency(batch?.branchId) +
																		' ' +
																		batch?.paymentDetails?.paymentData
																			?.totalAmount}
																</>
															) : (
																<div>
																	{batch?.paymentDetails?.paymentData?.paymentStructure?.map(
																		(item: any) => (
																			<div>
																				{/* 		{item?.finalEmiAmount?.toLocaleString(
																					'en-IN',
																					{
																						style: 'currency',
																						currency: 'INR',
																					}
																				)} */}
																				{findCurrency(batch?.branchId) +
																					' ' +
																					item?.finalEmiAmount}
																				<span
																					className={
																						styles.batchMembershipItemPer
																					}>
																					/{' '}
																					{item?.frequency
																						?.toLowerCase()
																						?.replace(/_/g, ' ')}
																				</span>{' '}
																			</div>
																		)
																	)}
																</div>
															)}
															{/* ₹ {batch?.batchPrice} */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className={styles.batchPopupTableHeadWrap}>
										{batch?.students?.length > 0 ? (
											<>
												<div className={styles.batchPopupTableHead}>
													Enrolled Students
												</div>
												<div
													style={{
														border: '1px solid #EAECF0',
														borderRadius: '8PX',
													}}>
													<TableContainer>
														<Table
															sx={{ minWidth: 700 }}
															aria-label='customized table'>
															<TableHead className={styles.batchPopupHead}>
																<TableRow>
																	<StyledTableCell
																		className={styles.batchPopupHeadCell}>
																		#
																	</StyledTableCell>
																	<StyledTableCell
																		className={styles.batchPopupHeadCell}>
																		Name
																	</StyledTableCell>

																	<StyledTableCell
																		className={styles.batchPopupHeadCell}
																		align='left'>
																		Email
																	</StyledTableCell>
																	<StyledTableCell
																		className={styles.batchPopupHeadCell}
																		align='left'>
																		Phone number
																	</StyledTableCell>
																	<StyledTableCell
																		className={styles.batchPopupHeadCell}
																		align='left'>
																		Payment status
																	</StyledTableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																{batch &&
																	batch.students
																		.slice(
																			page * rowsPerPage,
																			page * rowsPerPage + rowsPerPage
																		)
																		.map((row: any, index: any) => (
																			<StyledTableRow
																				key={row.name}
																				className={
																					styles.batchPopupColumnCells
																				}>
																				<StyledTableCell
																					component='th'
																					scope='row'
																					className={
																						styles.batchPopupColumnCellIndex
																					}>
																					{startIndex + index + 1}
																				</StyledTableCell>
																				<StyledTableCell
																					align='left'
																					className={
																						styles.batchPopupColumnCellName
																					}
																					style={{
																						textTransform: 'capitalize',
																					}}>
																					{row.studentFirstName +
																						' ' +
																						row.studentLastName}
																				</StyledTableCell>
																				<StyledTableCell
																					align='left'
																					className={
																						styles.batchPopupColumnCellDefault
																					}>
																					{row.studentEmail}
																				</StyledTableCell>
																				<StyledTableCell
																					align='left'
																					className={
																						styles.batchPopupColumnCellDefault
																					}>
																					{row?.studentContact.length === 10
																						? `+91 ${row?.studentContact}`
																						: row?.studentContact.length > 10
																						? `+${row?.studentContact.substring(
																								0,
																								2
																						  )} ${row?.studentContact.substring(
																								2
																						  )}`
																						: '-'}
																				</StyledTableCell>
																				<StyledTableCell
																					align='left'
																					className={
																						styles.batchPopupColumnCellPayment
																					}
																					style={{
																						color:
																							row.paymentStatus ===
																								'Partly Paid' ||
																							row.paymentStatus ===
																								'EMI Paid' ||
																							row.paymentStatus ===
																								'Membership Fees Paid'
																								? '#EBA418'
																								: row.paymentStatus ===
																										'Fully Paid' ||
																								  row.paymentStatus ===
																										'Emi Fully Paid' ||
																								  row.paymentStatus ===
																										'Membership Fully Paid'
																								? '#27AF77'
																								: row.paymentStatus ===
																										'Membership Fees Due' ||
																								  row.paymentStatus ===
																										'EMI Due' ||
																								  row.paymentStatus ===
																										'Not Paid'
																								? '#EC3939'
																								: '#EBA418',
																					}}>
																					{row.paymentStatus}
																				</StyledTableCell>
																			</StyledTableRow>
																		))}
															</TableBody>
														</Table>
													</TableContainer>
												</div>
												{batch &&
													batch.students &&
													batch.students.length > 0 && (
														<Box
															sx={{
																padding: '16px 0px',
																display: 'flex',
																justifyContent: 'end',
															}}>
															{batch.students.length > rowsPerPage && (
																<Pagination
																	// count={10}
																	count={Math.ceil(
																		batch.students.length / rowsPerPage
																	)}
																	color='primary'
																	variant='outlined'
																	shape='rounded'
																	renderItem={(item) => (
																		<PaginationItem
																			slots={{
																				previous: ArrowBackIcon,
																				next: ArrowForwardIcon,
																			}}
																			{...item}
																		/>
																	)}
																	onChange={(e: any, page: any) =>
																		handleChange(page)
																	}
																	sx={{
																		'.css-c8u8r0-MuiButtonBase-root-MuiPaginationItem-root':
																			{
																				border: 'none',
																				height: 'fit-content',
																				minHeight: '32px',
																				padding: '10px 17px',
																			},
																		'& .MuiButtonBase-root.MuiPaginationItem-root ':
																			{
																				border: 'none',
																			},
																	}}
																/>
															)}
														</Box>
													)}
											</>
										) : (
											<div>No students yet </div>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</Dialog>
			</Box>
			<ClearFilter
				open={isOpenFilter}
				noButtonClick={noButtonClick}
				yesButtonClick={yesButtonClick}
				onClose={closeClearFilter}
				filteredValue={filteredValue}
			/>
		</Box>
	);
}
