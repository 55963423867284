import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import getTeamMember from "../CommonFunctions/CommonFunctions";

function ActivityCall(props: any) {
  const { data, employees } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const leadActivityId = urlParams.get("leadActivityId");

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 15px 22px 15px",
        background: leadActivityId === data?.id ? "#e8faf6" : "transparent",
        padding: leadActivityId === data?.id ? "0 5px 5px 5px" : "0px",
        borderRadius: leadActivityId === data?.id ? "4px" : "0px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className={styles.activityTop}>
          <div className={styles.activityDateStyle}>
            {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
          </div>
          <div className={styles.activityHeadStage}>
            <div className={styles.activityHeadStageIcon}>
              <img src={stageDot} alt="" />
            </div>
            <div>{data?.currentStage}</div>
          </div>
          <div className={styles.activityHeadName}>
            <div className={styles.activityHeadStageIcon}>
              <img src={nameLine} alt="" />
            </div>
            <div>
              {/* {data?.createdByName} */}
              {getTeamMember(data?.createdById, employees)?.firstName +
                " " +
                getTeamMember(data?.createdById, employees)?.lastName}
            </div>
          </div>
        </div>

        <div
          className={styles.activityStageDesc}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <div
            className={styles.activityDemoTitle}
            style={{
              marginBottom: 0,
              color:
                data?.activityType === "OZONE_MISSED_CALL"
                  ? "#EC3939"
                  : data?.activityType === "OZONETEL_MISSED_CALL"
                  ? "#EC3939"
                  : "#142C44",
            }}
          >
            {data?.activityType === "OZONETEL_RECORDED"
              ? "Call Initiated"
              : data?.activityType === "OZONE_MISSED_CALL"
              ? "Call Missed"
              : data?.activityType === "OZONETEL_MISSED_CALL"
              ? "Call Missed"
              : data?.activityType === "OZONETEL_INCOMING"
              ? "Call Incoming"
              : data?.activityType}
          </div>

          {data?.activityType !== "OZONETEL_MISSED_CALL" && (
            <div
              className={styles.activityStatusBox}
              style={{
                background:
                  data?.activityData.Status === "NotAnswered"
                    ? "#FEF3F3"
                    : "rgba(17, 200, 155, 0.06)",
                color:
                  data?.activityData.Status === "NotAnswered"
                    ? "#EC3939"
                    : "#11C89B",
              }}
            >
              {data?.activityData.Status === "NotAnswered"
                ? "Unanswered"
                : data?.activityData.Status}
            </div>
          )}
        </div>

        <div className={styles.activityDescription}>
          Feedback: {data?.activityData.Disposition}
        </div>
        {/* <div className={styles.activityDescription}>
          Comments: {data?.activityData.Comments}
        </div> */}
      </Box>
    </Box>
  );
}

export default ActivityCall;
