import React, { useEffect, useState } from "react";
import styles from "./CustomizedField.module.css";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import deleteImage from "./Images/delete.svg";
import CountryCodePhoneInput from "../../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import DeleteQuestionPopup from "../DeleteQuestionPopup/DeleteQuestionPopup";
import { useAuthUser } from "react-auth-kit";
import { isValidPhoneNumber } from "libphonenumber-js";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function CustomizedPhoneField(props: any) {
  const {
    onFieldChange,
    field,
    onDelete,
    CreateWebformCustomField,
    isPhoneFieldFocused,
    setIsPhoneFieldFocused,
    leadsFromResponseChecked,
  } = props;

  const originalFieldName = field.fieldName;
  const [isEditing, setIsEditing] = useState(false);
  const [question, setQuestion] = useState("");
  const [validStatePhone, setValidStatePhone] = React.useState<boolean>(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const auth = useAuthUser();
  const authUser = auth();
  const [saveForLater, setSaveForLater] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validStateContact, setValidStateContact] =
    React.useState<boolean>(true);
  const [isHovered, setIsHovered] = useState(false);
  const [mandatory, setMandatory] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = (e: any) => {
    onFieldChange({
      ...field,
      fieldName: question,
    });
    const userFromLocalStorage = authUser?.institute[0];
    const data = {
      instituteId: userFromLocalStorage?.instituteId,
      status: "ACTIVE",
      field: {
        fieldType: field?.fieldType,
        fieldName: field?.fieldName,
        fieldPlaceholder: field?.fieldPlaceholder,
        options: [],
        default: "",
        required: field?.required,
        index: 1,
        loadDatafromDB: false,
        DBModel: "",
        isCustom: true,
        isDeletable: true,
      },
      createdById: userFromLocalStorage?.id,
      createdByName:
        userFromLocalStorage?.firstName + " " + userFromLocalStorage?.lastName,
    };
    if (saveForLater === true) {
      CreateWebformCustomField(data);
    }
    setIsEditing(false);
  };

  const handleQuestionChange = (e: any) => {
    setQuestion(e.target.value);
  };

  const handleMandatoryChange = (e: any) => {
    const isMandatory = e.target.checked;
    setMandatory(isMandatory);
    onFieldChange({
      ...field,
      required: isMandatory,
    });
  };

  const handleDeleteConfirmed = () => {
    // Close the delete confirmation popup
    setShowDeletePopup(false);
    // Call the onDelete callback passed from the parent component
    onDelete(field);
  };

  const handleDeleteCancelled = () => {
    // Close the delete confirmation popup
    setShowDeletePopup(false);
  };

  const handleSaveForLater = (e: any) => {
    setSaveForLater(e.target.checked);
  };

  const capitalizeSentences = (text: string) => {
    let lowercasedText = text.toLowerCase();

    let capitalizedText = lowercasedText.replace(
      /(^\s*\w|[\.\!\?]\s*\w)/g,
      function (match) {
        return match.toUpperCase();
      }
    );

    return capitalizedText;
  };

  return (
    <Box
      className={styles.customizedFieldWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <div className={styles.label}>
          {/* {question ? question : originalFieldName} */}
          {capitalizeSentences(question ? question : originalFieldName)}
          {field.required === true && <span style={{ color: "red" }}>*</span>}
        </div>
        {isEditing
          ? null
          : field.isCustom === true && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <FiEdit
                  size={16}
                  color="#667085"
                  style={{ cursor: "pointer" }}
                  onClick={handleEditClick}
                />
                {leadsFromResponseChecked === true &&
                field?.fieldName === "phone" ? (
                  <></>
                ) : (
                  <img
                    src={deleteImage}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowDeletePopup(!showDeletePopup)}
                  />
                )}
                {showDeletePopup && (
                  <DeleteQuestionPopup
                    onClose={handleDeleteCancelled}
                    showDeletePopup={showDeletePopup}
                    onConfirm={handleDeleteConfirmed}
                  />
                )}
              </div>
            )}
      </div>
      <div
        style={{
          marginBottom: "10px",
        }}
      >
        {isEditing ? (
          <TextField
            placeholder="Write a question"
            disabled={!isEditing}
            fullWidth
            variant="outlined"
            size="small"
            value={question}
            onChange={handleQuestionChange}
            inputProps={{
              style: {
                padding: "8px 12px",
                backgroundColor: isEditing ? "#FFFFFF" : "#FAFAFA",
                borderRadius: "4px",
              },
            }}
            draggable={isPhoneFieldFocused === false}
            onFocus={() => {
              setIsPhoneFieldFocused(true);
            }}
            onBlur={() => {
              setIsPhoneFieldFocused(false);
            }}
          />
        ) : null}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <CountryCodePhoneInput
            value={phoneNumber}
            onChange={(event: any, country: any) => {
              setPhoneNumber(event);
              if (event !== "") {
                setValidStateContact(
                  isValidPhoneNumber(
                    event.replace(country.dialCode, ""),
                    country.countryCode.toUpperCase()
                  )
                );
              }
            }}
            error={validStateContact}
            fieldName={field.fieldName}
          />
        </div>
      </div>

      {isEditing && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={handleMandatoryChange}
                checked={mandatory}
              />
            }
            label="Mandatory"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "13px",
                color: "#667085",
                fontFamily: "Poppins",
                fontWeight: "400",
                lineHeight: "20px",
              },
            }}
          />
          {field?.isFromCustom === true && (
            <FormControlLabel
              control={<Checkbox size="small" onChange={handleSaveForLater} />}
              label="Save for later"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "20px",
                },
              }}
            />
          )}

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                borderRadius: "4px",
                border: "1px solid #BFBFBF",
                background: "#FFF",
                color: "#BFBFBF",
                "&:hover": {
                  border: "1px solid #BFBFBF",
                  background: "#FFF",
                },
              }}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              onClick={handleSaveClick}
              disabled={!question.trim()} // Disable if the question is empty or contains only whitespaces
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {isHovered && (
        <div
          style={{
            display: "flex",
            color: "#667085",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DragIndicatorIcon style={{ transform: "rotate(90deg)" }} />
        </div>
      )}
    </Box>
  );
}
export default CustomizedPhoneField;
