import React from "react";
import styles from "./LeadOverviewMessages.module.css";
import yellowWarnIcon from "../Assets/yellow-warn.svg";
import redwWarnIcon from "../Assets/red-warn.svg";
import orangeWarnIcon from "../Assets/orange-warn.svg";

function LeadWarningMessage(props: any) {
  const { type } = props;
  return (
    <div
      className={styles.leadWarningMessageWrap}
      style={{
        border:
          type === "WarningRed"
            ? "1px solid rgba(241, 179, 179, 0.80)"
            : type === "WarningOrange"
            ? "1px solid rgba(250, 153, 43, 0.40)"
            : type === "WarningYellow"
            ? "1px solid rgba(254, 202, 16, 0.40)"
            : " 1px solid rgba(254, 202, 16, 0.40)",
        background:
          type === "WarningRed"
            ? "#FBF8F8"
            : type === "WarningOrange"
            ? "linear-gradient(0deg, rgba(250, 153, 43, 0.06) 0%, rgba(250, 153, 43, 0.06) 100%), #FFF"
            : type === "WarningYellow"
            ? "linear-gradient(0deg, rgba(254, 202, 16, 0.06) 0%, rgba(254, 202, 16, 0.06) 100%), #FFF"
            : "linear-gradient(0deg, rgba(254, 202, 16, 0.06) 0%, rgba(254, 202, 16, 0.06) 100%), #FFF",
      }}
    >
      <div className={styles.leadWarningMessageIcon}>
        <img
          src={
            type === "WarningRed"
              ? redwWarnIcon
              : type === "WarningOrange"
              ? orangeWarnIcon
              : type === "WarningYellow"
              ? yellowWarnIcon
              : yellowWarnIcon
          }
          alt="warning"
        />
      </div>
      <div
        className={styles.leadWarningMessageValue}
        style={{
          color:
            type === "WarningRed"
              ? "#EC3939"
              : type === "WarningOrange"
              ? "#FA992B"
              : type === "WarningYellow"
              ? "#FECA10"
              : "#FECA10",
        }}
      >
        {type === "WarningRed"
          ? "Please assign a Batch to the Student"
          : type === "WarningOrange"
          ? "Please Assign a Batch to The Student and Complete the Payment."
          : type === "WarningYellow"
          ? "Please complete the payment."
          : "Please complete the payment."}
      </div>
    </div>
  );
}

export default LeadWarningMessage;
