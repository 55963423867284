import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../ImportModal/ImportModal.module.css";
import { Info } from "@mui/icons-material";
import { AiOutlineInfoCircle } from "react-icons/ai";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};
const CourseMapModel = ({
  open,
  setOpenDifferentCourseMappingPopup,
  yesButtonClick,
  noButtonClick,
  title = "Are you sure you want to Continue?",
  subTitle = " Seems like you are changing the course of the selected Contact",
}: any) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpenDifferentCourseMappingPopup(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div
            onClick={() => {
              setOpenDifferentCourseMappingPopup(false);
            }}
            className={styles.closeModalButton}
          >
            <CloseIcon />
          </div>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalDes}>{subTitle}</div>
          <div className={styles.buttonSection}>
            <button onClick={noButtonClick} className={styles.noButton}>
              No
            </button>
            <button
              onClick={yesButtonClick}
              style={{
                background: "#11c89b",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "4px",
                padding: "10px 18px",
                gap: "8px",
                width: "100%",
                color: " #ffffff",
                border: "0",
                cursor: "pointer",
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default CourseMapModel;
