import React from "react";
import { Box, Modal, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../PaymentType.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};
function PaymentEnablePopup(props: any) {
  const { open, onClose, loader, yesButtonClick } = props;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div onClick={onClose} className={styles.closeModalButton}>
              <CloseIcon />
            </div>
            {/* <div className={styles.enableAutoDebitTitle}>Enable Auto Debit</div> */}
            <div
              className={styles.enableAutoDebitTitle}
              style={{ textAlign: "left" }}
            >
              Are you sure you want to enable auto debit?
            </div>

            {/* <div
              className={styles.enableAutoDebitDesc}
            >
              You need to first
              <span>activate the auto debit facility</span>
              from the payment issuer’s app.
            </div> */}
            {/* <div className={styles.enableAutoDebitDesc}>
              Once you activate the facility,
              <span>the payment deduction will take place without OTPs</span>
              (One-Time Passwords) at its scheduled monthly date.{" "}
            </div>
            <div className={styles.enableAutoDebitDesc}>
              However, make sure you have
              <span>
                enough balance in your bank account to avoid overdraft charges
              </span>
              of insufficient balance. This is necessary to make a successful
              transaction of automatic deduction. You can also
              <span>set the maximum amount to be debited.</span> Hence, beyond
              that limit, no auto-deduction will be done from your bank.
            </div> */}
            <div className={styles.buttonSection}>
              <button
                onClick={yesButtonClick}
                className={styles.autoEnableBtn}
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Turn On Auto Debit"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default PaymentEnablePopup;
