import React from "react";
import { Box, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./HelpPopup.module.css";
import username from "../../Assets/username.svg";

const UsernamePopup = (props: any) => {
  const { isOpen, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "736px",
          maxHeight: "580px",
          minHeight: "559px",
          padding: "24px",
          borderRadius: "12px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "8%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#555555" }} onClick={handleClose} />
        </div>
        <div className={styles.modalHead}>
          Not sure where to find Username ?
        </div>
        <div className={styles.modalSubHead}>Follow these steps:</div>
        <ul className={styles.stepsToFollow}>
          <li>Login to your Ozonetel Admin account.</li>
          <li>Click on Profile icon on top right corner.</li>
          <li>Select edit profile.</li>
          <li>Under edit user section, find the User name</li>
          <li>Copy and paste it here.</li>
        </ul>

        <Box>
          <img src={username} alt="username" />
        </Box>
      </div>
    </Dialog>
  );
};
export default UsernamePopup;
