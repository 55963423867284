import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import logo from "../../../../assets/images/logo.png";
import ButtonLog from "../../../../components/Button/ButtonLog";
import { ErrorType } from "../../../../components/FormTextInput/types";
import { PASSWORDTXT, FORMINFOTXT } from "../container/ForgetPasswordString";
import styles from "./ForgetPassword.module.css";
import NewFormTextInput from "../../../../components/FormTextInput/NewFormTextInput";

export function EmailForm(props: {
  onSubmit: any;
  setEmailState: (email: string) => void;
  loading: boolean;
}) {
  const [email, setEmail] = React.useState("");
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const handleChangeEmail = React.useCallback(
    (e: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
      }
      setErrorType("client");
      setEmail(e.target.value);
    },
    [errorType]
  );
  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);
  return (
    <form
      className={styles.form}
      onSubmit={(e: any) => {
        e.preventDefault();
        props.setEmailState(email);
        validStateEmail && email && props.onSubmit({ email: email });
      }}
    >
      <Box className={styles.logo_box} sx={{ display: "none" }}>
        <img src={logo} height="35" width="150" alt="" />
      </Box>
      <Box>
        <Typography
          className={styles.password_text_head}
          sx={{
            fontSize: {
              lg: 32,
              md: 32,
              sm: 25,
              xs: 19,
            },
            fontFamily: "Poppins",
            fontWeight: "600",
          }}
        >
          {PASSWORDTXT}
        </Typography>
      </Box>

      <div className={styles.formInfo}>{FORMINFOTXT}</div>

      <NewFormTextInput
        validate={true}
        setValidStatus={setValidateStatusEmail}
        label="Email"
        type={"email"}
        id="email"
        error={!validStateEmail}
        value={email}
        onChange={handleChangeEmail}
        showErrorMessage={showErrMsz}
        errorType={errorType}
        require={true}
      />

      <Box sx={{ marginTop: "24px" }}>
        <ButtonLog
          variant="contained"
          type="submit"
          text={
            props.loading ? (
              <CircularProgress size={25} color={"inherit"} />
            ) : (
              "Continue"
            )
          }
        />
      </Box>
    </form>
  );
}
