import React, { useState } from "react";
import styles from "./CustomizedField.module.css";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import deleteImage from "./Images/delete.svg";
import DeleteBtn from "../../../../../../../../components/Buttons/DeleteBtn";
import DeletePopup from "../../../../../../../../components/CommonPopups/DeletePopup";

function CustomizedProfilePicField(props: any) {
  const {
    onFieldChange,
    field,
    onDelete,
    isTextFieldFocused,
    setIsTextFieldFocused,
    leadsFromResponseChecked,
    edit = false,
    deleteAction = false,
    mandatory = false,
    isNameUniqueCheck,
  } = props;
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  const handleDeleteConfirmed = () => {
    setShowDeletePopup(false);
    onDelete(field);
  };

  const handleDeleteCancelled = () => {
    setShowDeletePopup(false);
  };

  const handleMandatoryChange = (e: any) => {
    const isMandatory = e.target.checked;
    onFieldChange({
      ...field,
      required: isMandatory,
    });
  };

  return (
    <div className={styles.customizedFieldWrapper}>
      <div className={styles.customizedFieldTop}>
        <div className={styles.label}>
          {field.fieldName}
          {field.required === true && <span style={{ color: "red" }}>*</span>}
        </div>
        <div className={styles.customizedFieldTopRight}>
          {deleteAction && (
            <DeleteBtn
              icon={deleteImage}
              onClick={() => setShowDeletePopup(!showDeletePopup)}
            />
          )}

          {showDeletePopup && (
            <DeletePopup
              open={showDeletePopup}
              onClose={handleDeleteCancelled}
              noBtnClick={handleDeleteCancelled}
              yesBtnClick={handleDeleteConfirmed}
              title={"Are you sure you want to delete this question?"}
            />
          )}
        </div>
      </div>
      <div className={styles.description}>
        Maximum size 2MB and file should be in JPG/JPEG format
      </div>
      <TextField
        // placeholder={field.isCustom ? "Write a question" : ""}
        value={""}
        // onChange={handleQuestionChange}
        fullWidth
        variant="outlined"
        disabled
        size="small"
        inputProps={{
          style: {
            padding: "8px 12px",
            backgroundColor: "#FAFAFA",
            borderRadius: "4px",
          },
        }}
        // draggable={!isTextFieldFocused}
        // onFocus={() => {
        //   setIsTextFieldFocused(true);
        // }}
        // onBlur={() => {
        //   setIsTextFieldFocused(false);
        // }}
        // error={!isNameUniqueCheck(question, field?.index)}
        // helperText={
        //   !isNameUniqueCheck(question, field?.index)
        //     ? "Field label already exist"
        //     : ""
        // }
      />
      {mandatory && (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              onChange={handleMandatoryChange}
              checked={field?.required}
              sx={{
                strokeWidth: 1,
                color: "#D0D5DD",
              }}
            />
          }
          label="Mandatory"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "13px",
              color: "#667085",
              lineHeight: "20px",
            },
          }}
        />
      )}
    </div>
  );
}

export default CustomizedProfilePicField;
