import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";

export default function RichTextEditor(props: any) {
  const { handleTextChange, defaultText, toolBar } = props;
  const editorRef = useRef<Editor>(null);

  return (
    <>
      <Editor
        // apiKey="j56xc6f8gdh0pk5xwxu61y2jz2j0jya3vuvdphdg6oozi85k"
        apiKey="jp1y0l8pco9qi48lngleeuvza14v72da31u9p2tsv6gwm996"
        value={defaultText}
        ref={editorRef}
        onEditorChange={(newText, editor) =>
          handleTextChange(newText, editor, editorRef)
        }
        init={{
          height: 335,
          menubar: false,
          // menubar: " tools ",
          plugins: [
            "image",
            "code",
            "table",
            "link",
            "media",
            "codesample",
            "lists",
            "advlist",
            "emoticons",
            "wordcount",
            // "paste",
          ],
          toolbar:
            toolBar ||
            "undo redo | blocks | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | link ",
          // plugins: [
          //   "advlist",
          //   "anchor",
          //   "autolink",
          //   "codesample",
          //   "fullscreen",
          //   "help",
          //   "image",
          //   "tinydrive",
          //   "lists",
          //   "link",
          //   "media",
          //   "preview",
          //   "searchreplace",
          //   "table",
          //   "visualblocks",
          //   "wordcount",
          // ],
          // toolbar:
          //   "insertfile a11ycheck undo redo | bold italic | forecolor backcolor | codesample | alignleft aligncenter alignright alignjustify | bullist numlist | link image",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; z-index: 9999999, }",
        }}
      />
    </>
  );
}
