import { Box, Grid, List, Typography } from "@mui/material";
import { ProfileSetup } from "./../../../../components/ProfileSetup/ProfileSetup";
import styles from "./ManageRoot.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function CompleteProfileHeader(props: any) {
  let activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const [profileCompletion, setProfileCompletion] = useState({
    isProfileComplete: "",
    profileDetailsCompleted: "",
    isCourseSetup: "",
    isBranchAdded: "",
    isTeamAdded: "",
    isBatchAdded: "",
  });

  useEffect(() => {
    if (activeInstitute) {
      setProfileCompletion({
        ...profileCompletion,
        isProfileComplete: activeInstitute.isProfileCompleted,
        profileDetailsCompleted: activeInstitute.profileDetailsCompleted,
        isCourseSetup: activeInstitute.courseCreated,
        isBranchAdded: activeInstitute.branchCreated,
        isTeamAdded: activeInstitute.teamAdded,
        isBatchAdded: activeInstitute.batchCreated,
      });
    }
  }, [setProfileCompletion, instituteFromRedux]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box
      className={styles.flag}
      sx={{
        display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
      }}
    >
      <Grid
        container
        spacing={3}
        className={styles.list_item_flat}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={3.7}>
          <Typography className={styles.hader_txt}>
            nurture offers many features for users. To make use of it complete
            the profile
          </Typography>
        </Grid>
        <Grid item xs={8.3}>
          <List className={styles.list_item_flat}>
            {[
              {
                name: "Profile setup",
                status: profileCompletion.profileDetailsCompleted,
              },
              { name: "Branch setup", status: profileCompletion.isBranchAdded },
              { name: "Course setup", status: profileCompletion.isCourseSetup },
              { name: "Batch setup", status: profileCompletion.isBatchAdded },
            ].map((item, index) => (
              <ProfileSetup key={Math.random()} index={index} item={item} />
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}
