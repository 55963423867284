import {
  axios_institute_private,
  axios_signup,
} from "../../../../api/setup.intersepter";
import {
  AddEnquiryDataFromContact,
  DeactivateInboundData,
  DeleteContactData,
  DeleteInboundEmailData,
  EmailConfigDTO,
  EmailConfigData,
  ImapSyncData,
  ImportCSVData,
  SavingImportedData,
} from "./types";

export const ConfigEmail = (body: EmailConfigDTO) => {
  return axios_signup({
    method: "POST",
    url: `/inbound`,
    data: body,
  });
};

export const GetMailConfigsList = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/inbound?instituteId=${id}&status=ACTIVE`,
  });
};

export const GetMailEntriesByInstitute = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string
) => {
  let url = `/inbound/entry?instituteId=${id}&limit=${limit}`;

  if (lastEvaluatedKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}`;
  }

  if (lastEvaluatedSortKey) {
    url += `&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const SaveEmailConfigDetails = (body: EmailConfigData) => {
  return axios_institute_private({
    method: "POST",
    url: `/inbound`,
    data: body,
  });
};

export const GetMailDetailsById = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/inbound/entry?id=${id}`,
  });
};

export const DeactivateInbound = (body: DeactivateInboundData) => {
  return axios_institute_private({
    method: "POST",
    url: `/deactivateInbound`,
    data: body,
  });
};

export const DeleteInboundEmail = (body: DeleteInboundEmailData) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/inbound/entry`,
    data: body,
  });
};

export const SaveImapEmailConfigDetails = (body: EmailConfigData) => {
  return axios_institute_private({
    method: "POST",
    url: `/inbound`,
    data: body,
  });
};

export const SyncImapEmailConfigDetails = (body: ImapSyncData) => {
  return axios_institute_private({
    method: "POST",
    url: `/inbound/imapSync`,
    data: body,
  });
};

export const DownloadCSVTemplate = (
  instituteId: string,
  countryCode: number
) => {
  return axios_institute_private({
    method: "GET",
    url: `/excelTemplateFile?instituteId=${instituteId}&countryCode=${countryCode}`,
  });
};

export const ImportCSVFile = (body: ImportCSVData) => {
  return axios_institute_private({
    method: "POST",
    url: `/student/processFile`,
    data: body,
  });
};

export const SaveImportedFile = (body: SavingImportedData) => {
  return axios_institute_private({
    method: "POST",
    url: `/student/saveFileData`,
    data: body,
  });
};
export const GetContactList = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string,
  // formId?: string,
  enquirySource?: string
) => {
  let url = `/studentContact?instituteId=${id}&limit=${limit}&status=ACTIVE`;

  if (lastEvaluatedKey && lastEvaluatedSortKey) {
    url += `&lastEvaluatedKey=${lastEvaluatedKey}&lastEvaluatedSortKey=${lastEvaluatedSortKey}`;
  }
  if (enquirySource) {
    url += `&enquirySource=${enquirySource}`;
  }
  // if (formId) {
  //   url += `&webfomId=${formId}`;
  // }

  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const DeleteContactList = (body: DeleteContactData) => {
  return axios_institute_private({
    method: "DELETE",
    url: `/studentContact`,
    data: body,
  });
};

export const GetImportHistory = (
  id: string,
  lastEvaluatedKey: string,
  limit: number,
  lastEvaluatedSortKey: string
) => {
  if (lastEvaluatedKey && lastEvaluatedSortKey) {
    return axios_institute_private({
      method: "GET",
      url: `/excelDataImportHistory?instituteId=${id}&limit=${limit}&status=ACTIVE&lastEvaluatedKey=${lastEvaluatedKey}&lastEvaluatedSortKey=${lastEvaluatedSortKey}`,
    });
  } else {
    return axios_institute_private({
      method: "GET",
      url: `/excelDataImportHistory?instituteId=${id}&limit=${limit}&status=ACTIVE`,
    });
  }
};

export const GetImportHistoryDetails = (id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `/excelDataImportHistory?excelImportId=${id}`,
  });
};

export const DownloadImportedHistory = (data: any) => {
  const { id, instituteId } = data;
  return axios_institute_private({
    method: "GET",
    url: `/excelDataImportHistory?excelImportId=${id}&instituteId=${instituteId}`,
  });
};

export const CreateLeadFromContacts = (body: AddEnquiryDataFromContact) => {
  return axios_institute_private({
    method: "POST",
    url: `/nurture/lead/studentContactToEnquiry`,
    data: body,
  });
};

export const WhatsappNotificationChange = (body: any) => {
  return axios_institute_private({
    method: "PUT",
    url: `/institute/wa`,
    data: body,
  });
};
