import { parse, startOfDay } from "date-fns";
import AllDays from "../../../../../../components/CalenderComponents/AllDays";
import {
  getEventsByDayNumber,
  getMatchMonthAndYear,
} from "../../../../../../components/CalenderComponents/calendar";
import DayCardList from "./DayCardList";
import { timeZoneConverter } from "../../../../../../components/Formatter/Formatter";
import moment from "moment";

const Calender = ({
  date,
  batchDetails,
  setBatchDetails,
  add,
  setIsDateChanged,
}: any) => {
  const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const events: any = [];
  const startDate: any =
    batchDetails.startDate === 0
      ? new Date(timeZoneConverter(moment().unix()))
      : batchDetails.startDate?.length === 10
      ? new Date(timeZoneConverter(parseInt(batchDetails.startDate)))
      : new Date(batchDetails.startDate);
  const endDate: any = new Date(batchDetails.endDate);
  console.log("SD", startDate, batchDetails.startDate);
  for (
    let currentDate = startDate;
    currentDate <= endDate;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const isUnavailable = batchDetails.exemptedDates.some(
      (exemptedDate: any) =>
        startOfDay(new Date(exemptedDate.exemptedDate)).getTime() ===
        startOfDay(currentDate).getTime()
    );

    if (isUnavailable) {
      continue;
    }
    const dayIndex = currentDate.getDay();

    const daySchedule = batchDetails.batchSchedule.find(
      (schedule: any) => daysOfWeek[dayIndex] === schedule.day
    );

    if (daySchedule) {
      daySchedule.batchSessions.forEach((session: any) => {
        if (session.startTime !== 0 && session.endTime !== 0) {
          const startAt = new Date(currentDate);
          const startTime = moment.unix(session?.startTime).format("hh:mm A");
          const endTime = moment.unix(session?.endTime).format("hh:mm A");
          const isExempted =
            batchDetails &&
            batchDetails.exemptedDates &&
            batchDetails.exemptedDates.find(
              (exemptedDate: any) =>
                exemptedDate.exemptedDate === session.startTime * 1000
            );

          if (!isExempted) {
            const endAt = new Date(
              startAt.getTime() + (session.endTime - session.startTime) * 1000
            );

            events.push({
              _id: "generated_id",
              status: batchDetails.status,
              rsvpList: [],
              title: "Batch Session",
              description: "Batch Session",
              location: "Your Location",
              groupId: null,
              startAt: startAt.toISOString(),
              endAt: endAt.toISOString(),
              user: {
                _id: "batch_user_id",
                displayName: "Batch",
              },
              __v: 0,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              exemptedDates: [],
              startTime: startTime,
              endTime: endTime,
              isFromOccurences: true,
            });
          }
        }
      });
    }
  }

  if (
    batchDetails &&
    batchDetails?.exemptedDates &&
    batchDetails?.exemptedDates?.length > 0
  ) {
    batchDetails?.exemptedDates?.map((exemptedDate: any) => {
      events.push({
        _id: "generated_id",
        status: "ACTIVE",
        rsvpList: [],
        title: "Batch Session",
        description: "Batch Session",
        location: "Your Location",
        groupId: null,
        startAt: new Date(exemptedDate.exemptedDate).toISOString(),
        endAt: new Date(exemptedDate.exemptedDate).toISOString(),
        user: {
          _id: "batch_user_id",
          displayName: "Batch",
        },
        __v: 0,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        exemptedDates: [exemptedDate],
        startTime: "",
        endTime: "",
        isFromOccurences: false,
      });
    });
  }

  if (
    batchDetails &&
    batchDetails.customDays &&
    batchDetails.customDays?.length > 0
  ) {
    batchDetails.customDays.forEach((customDay: any) => {
      const { batchSessions, date } = customDay;

      batchSessions.forEach((session: any) => {
        const { startTime, endTime } = session;
        const sessionStartTime = moment.unix(startTime).format("hh:mm A");
        const sessionEndTime = moment.unix(endTime).format("hh:mm A");

        events.push({
          _id: "generated_id",
          status: "ACTIVE",
          rsvpList: [],
          title: "Batch Session",
          description: "Batch Session",
          location: "Your Location",
          groupId: null,
          startAt: new Date(date).toISOString(),
          endAt: new Date(date).toISOString(),
          user: {
            _id: "batch_user_id",
            displayName: "Batch",
          },
          __v: 0,
          createdAt: new Date(date).toISOString(),
          updatedAt: new Date(date).toISOString(),
          exemptedDates: [],
          startTime: sessionStartTime,
          endTime: sessionEndTime,
          isFromOccurences: false,
        });
      });
    });
  }

  const eventsInSelectedMonth = getMatchMonthAndYear(
    date.month,
    date.year,
    events
  );

  const days = Array.from({ length: date.daysInMonth }, (_, i) => {
    const currentDay = i + 1;
    const dateObject = parse(
      `${date.month}, ${currentDay}, ${date.year}`,
      "MMMM, d, yyyy",
      new Date()
    );

    return {
      date: dateObject,
      events: getEventsByDayNumber(currentDay, eventsInSelectedMonth),
    };
  });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <AllDays />
      {batchDetails?.startDate === batchDetails?.endDate ? (
        <div
          style={{
            color: "red",
            height: "100px",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          Please select start date and end date.
        </div>
      ) : (
        <DayCardList
          data={days}
          firstDayOfMonth={date.firstDay}
          batchDetails={batchDetails}
          setBatchDetails={setBatchDetails}
          add={add}
          startDate={startDate}
          endDate={endDate}
          setIsDateChanged={setIsDateChanged}
        />
      )}
    </div>
  );
};

export default Calender;
