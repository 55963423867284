import { Box, Grid } from "@mui/material";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import dayjs from "dayjs";
import styles from "./Managebatches.module.css";
import WeekDuration from "./WeekDuration";

function FixedSchedule(props: any) {
  const {
    add,
    batchDetails,
    setBatchDetails,
    startDate,
    endDate,

    handleDateChange,
    handleScheduleChange,
    setIsDateChanged,
    errorStartDayMsg,
    errorStartDateMszs,
    errorEndDateMsg,
    errorEndDatePastMsg,
    errorTimeMsg,
    errorEndDayMsg,
  } = props;
  return (
    <>
      <Grid marginTop={2}>
        <WeekDuration
          batchSchedule={batchDetails.batchSchedule}
          handleScheduleChange={handleScheduleChange}
          batchDetails={batchDetails}
          setBatchDetails={setBatchDetails}
          add={add}
          setIsDateChanged={setIsDateChanged}
        />
        {errorStartDayMsg && !errorTimeMsg && (
          <div
            style={{
              color: "red",
              padding: "5px 0",
              fontSize: "14px",
            }}
          >
            Oopps! Request can not be processed
            <br />
            Seems like you have not scheduled time for the selected start date.
          </div>
        )}

        {errorEndDayMsg && !errorTimeMsg && (
          <div
            style={{
              color: "red",
              padding: "5px 0",
              fontSize: "14px",
            }}
          >
            Oopps! Request can not be processed
            <br />
            Seems like you have not scheduled time for the selected end date.
          </div>
        )}

        {errorTimeMsg && (
          <div
            style={{
              color: "red",
              padding: "5px 0",
              fontSize: "14px",
            }}
          >
            Oopps! Request can not be processed
            <br />
            The start time and end time can not be the same and the end time
            should be greater than the start time.
          </div>
        )}
      </Grid>
    </>
  );
}

export default FixedSchedule;
