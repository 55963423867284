import React from "react";
import styles from "./Address.module.css";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import edit from "./edit.svg";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

function Address(props: any) {
  const { billingAddress } = props;
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasBillingAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  return (
    <div>
      <div className={styles.AddressDisplayHeading}>Billing address</div>
      <div className={styles.AddressDisplaySubHeading}>
        This address appears on your invoice.
      </div>

      <div className={styles.AddressDisplayBox}>
        <div className={styles.AddressDisplayBoxLeft}>
          <div className={styles.AddressDetailsItem}>
            <div className={styles.AddressDetailsItemLabel}>Institute name</div>
            <div className={styles.AddressDetailsItemValue}>
              {billingAddress && billingAddress.instituteName}
            </div>
          </div>
          <div className={styles.AddressDetailsItem}>
            <div className={styles.AddressDetailsItemLabel}>Address </div>
            <div className={styles.AddressDetailsItemValue}>
              {billingAddress && (
                <>
                  {billingAddress.buildingNumber}, {billingAddress.locality},{" "}
                  {billingAddress.landmark && `${billingAddress.landmark}, `}
                  {billingAddress.city}, {billingAddress.state},{" "}
                  {billingAddress.pin}
                </>
              )}
            </div>
          </div>

          {billingAddress && billingAddress.gstNumber && (
            <div className={styles.AddressDetailsItem}>
              <div className={styles.AddressDetailsItemLabel}>GSTIN</div>
              <div className={styles.AddressDetailsItemValue}>
                {billingAddress.gstNumber}
              </div>
            </div>
          )}
        </div>

        <div className={styles.AddressDisplayBoxRight}>
          <div>
            <Tooltip
              arrow
              title={hasBillingAccess === false ? "Oops! No permission." : ""}
              PopperProps={{ style: { zIndex: 9999999 } }}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#101828",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "8px 12px",
                    "& .MuiTooltip-arrow": {
                      color: "#101828",
                    },
                  },
                },
              }}
            >
              <span>
                {hasBillingAccess === false ? (
                  <>
                    <Button
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#667085",
                        textTransform: "capitalize",
                        gap: "10px",
                      }}
                      disabled={!hasBillingAccess}
                    >
                      Edit
                      <img src={edit} alt="edit" />
                    </Button>
                  </>
                ) : (
                  <>
                    <Link to={`/app/manage/Billing/EditAddress`}>
                      <Button
                        sx={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#667085",
                          textTransform: "capitalize",
                          gap: "10px",
                        }}
                      >
                        Edit
                        <img src={edit} alt="edit" />
                      </Button>
                    </Link>
                  </>
                )}
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
