import {
  Box,
  FormGroup,
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
  TableCell,
  TableContainer,
  Paper,
  Avatar,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./LeadReportCreation.module.css";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import dayjs from "dayjs";
import CustomFilterAccordion from "../../../../../components/CustomFilterAccordion/CustomFilterAccordion";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import ShowAllBtn from "../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import DonutChart from "../../../../../components/DonutChart/DonutChart";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import greenDot from "../../../../../assets/images/Ellipse 2842.svg";
import redDot from "../../../../../assets/images/Ellipse 2843.svg";
import { useLocation, useNavigate } from "react-router-dom";
import IconDropdown from "../../../../../components/Dropdowns/IconDropdown";
import SaveReportPopup from "../SaveReportPopup/SaveReportPopup";
import CancelModal from "../../../../../components/CancelModal/CancelModal";
import absent from "../../../../../assets/images/abs.svg";
import present from "../../../../../assets/images/pres.svg";
import backIcon from "../../../../../assets/images/back-btn-arrow.svg";

interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedCourse {
  id: string;
  courseName: string;
}

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "500",
  lineHeight: "18px",
  fontSize: "16px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: 150,
}));

const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "12px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: '150px'
  paddingTop: "15px",
  paddingBottom: "10px",
}));

const AttendanceReportCreation = (props: any) => {
  const [checkedCourses, setCheckedCourses] = useState<CheckedCourse[]>([]);
  const [filterCheckedCourses, setFilterCheckedCourses] = useState<
    CheckedCourse[]
  >([]);
  const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);
  const [filterCheckedBranches, setFilterCheckedBranches] = useState<
    CheckedBranch[]
  >([]);
  const [searchCourse, setSearchCourse] = useState<any>("");
  const [searchBranch, setSearchBranch] = useState<any>("");

  const [showAllCourses, setShowAllCourses] = useState<boolean>(false);
  const [showAllBranches, setShowAllBranches] = useState<boolean>(false);
  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const batchList = useSelector((state: any) => state.batch.batchList);
  const [isBranchOpen, setIsBranchOpen] = useState(false);
  const [isBatchOpen, setIsBatchOpen] = useState(false);
  const [isCourseOpen, setIsCourseOpen] = useState(false);
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [checkedTags, setCheckedTags] = useState<any[]>([]);
  const [assigneeSearchTerm, setAssigneeSearchTerm] =
    React.useState<string>("");
  const [batchSearchTerm, setBatchSearchTerm] = React.useState<string>("");
  const [branchSearchTerm, setBranchSearchTerm] = React.useState<string>("");
  const [showAllEmployees, setShowAllEmployees] =
    React.useState<boolean>(false);
  const [showAllBranch, setShowAllBranch] = React.useState<boolean>(false);
  const [showAllBatch, setShowAllBatch] = React.useState<boolean>(false);
  const [checkedBatches, setCheckedBatches] = useState<any[]>([]);
  const [courseSearchTerm, setCourseSearchTerm] = React.useState("");
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const employees = useSelector((state: any) => state.staff.staffList);
  const [checkedAssignees, setCheckedAssignees] = useState<any[]>([]);
  const [checkedDateRange, setCheckedDateRange] = useState<any[]>([]);
  const [startDate, setStartDate] = React.useState<number | null>(null);
  const [endDate, setEndDate] = React.useState<number | null>(null);
  const navigate = useNavigate();
  const [openSaveReportPopup, setOpenSaveReportPopup] = useState(false);
  const [discardCancel, setDiscardCancel] = useState<boolean>(false);
  const [isViewBatchAttendanceVisible, setIsViewBatchAttendanceVisible] =
    useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  const auth = useAuthUser();
  const user = auth();
  const location = useLocation();

  const handleFilterCourseChecked = (id: string, courseName: string) => {
    const courseToAdd: CheckedCourse = { id, courseName };
    if (checkedCourses.some((item) => item.id === id)) {
      setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
    } else {
      setCheckedCourses([...checkedCourses, courseToAdd]);
    }
  };

  const clearCheckedCourse = (id: string) => {
    setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
  };

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    const branchToAdd: CheckedBranch = { id, branchName };
    if (checkedBranches.some((branch) => branch.id === id)) {
      setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBranches([...checkedBranches, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
  };

  const handleCourseSearch = (event: any) => {
    setSearchCourse(event.target.value);
  };

  const handleBranchSearch = (event: any) => {
    setSearchBranch(event.target.value);
  };

  const filteredBranches =
    branches &&
    branches?.filter((branch: any) => {
      const searchTermMatch =
        branch.branchName.toLowerCase().indexOf(searchBranch.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedBranch = showAllBranches
    ? branches && filteredBranches
    : branches && filteredBranches.slice(0, 5);

  const handleShowMoreBranches = () => {
    setShowAllBranches(!showAllBranches);
  };

  const filteredCourses =
    courses &&
    courses?.filter((item: any) => {
      const searchTermMatch =
        item.courseName.toLowerCase().indexOf(searchCourse.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedCourses = showAllCourses
    ? courses && filteredCourses
    : courses && filteredCourses.slice(0, 5);

  const handleShowMoreCourses = () => {
    setShowAllCourses(!showAllCourses);
  };

  const handleShowMoreBranch = () => {
    setShowAllBranch(!showAllBranch);
  };

  const filteredBranch = branches?.filter((employee: any) => {
    const searchTermMatch =
      employee.branchName
        .toLowerCase()
        .indexOf(branchSearchTerm.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const filteredBatch = batchList
    ?.filter(
      (item: any) =>
        item?.batchConductType === "REGULAR_BATCH" &&
        item?.batchStatus !== "COMPLETED" &&
        item?.batchStatus !== "CANCELLED" &&
        item?.status !== "INACTIVE"
    )
    ?.filter((batch: any) => {
      const searchTermMatch =
        batch.batchName.toLowerCase().indexOf(batchSearchTerm.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedBatch = showAllBatch
    ? batchList && filteredBatch
    : batchList && filteredBatch?.slice(0, 5);

  const handleFilterBatchChecked = (id: string, batchName: string) => {
    const batchToAdd: any = { id, batchName };
    if (checkedBatches.some((branch) => branch.id === id)) {
      setCheckedBatches(checkedBatches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBatches([...checkedBatches, batchToAdd]);
    }
  };

  const handleShowMoreBatch = () => {
    setShowAllBatch(!showAllBatch);
  };

  const handleAssigneeSearch = (event: any) => {
    setAssigneeSearchTerm(event.target.value);
  };

  const filteredEmployees = employees
    ?.filter((item: any) => item.status === "ACTIVE")
    ?.filter((employee: any) => {
      const searchTermMatch =
        employee.firstName
          .toLowerCase()
          .indexOf(assigneeSearchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      const dateUnix = date.unix();
      const dateMilliseconds = dateUnix * 1000;
      if (id === "startDate") {
        setStartDate(dateMilliseconds);
      } else if (id === "endDate") {
        setEndDate(dateMilliseconds);
      }
    }
  };

  const data = [
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
    {
      name: "Raghu",
      attendancePercentage: "96%",
    },
  ];
  const onDiscardClose = () => {
    setDiscardCancel(false);
  };
  const noDiscardButtonClick = () => {
    setDiscardCancel(false);
  };
  const yesDiscardButtonClick = () => {
    setDiscardCancel(false);
  };

  const reportssData = [
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
    {
      name: "Olivia",
      total: "40/60",
      atendedOnFirst: true,
      atendedOnSecond: true,
      atendedOnThird: true,
      atendedOnFourth: false,
      atendedOnFifth: true,
      atendedOnSixth: false,
      atendedOnSeventh: true,
      atendedOnEigth: true,
      atendedOnNinth: true,
      attendedOnTength: false,
    },
  ];

  return (
    <Box
      sx={{
        overflow: "hidden",
        padding: "14px 16px",
        background: "#EEEEEE",
        // maxHeight: "calc(100vh - 65px)",
        // position: "relative",
        maxHeight: "90vh",
        position: "relative",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          display: "flex",
          width: "100%",
          maxHeight: "100vh",
          minHeight: "100vh",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <Box
          sx={{
            background: "#fff",
            minWidth: "219px",
            borderRight: "1px solid #E9E9E9",
            // overflowY: "hidden",
          }}
          className="datepicker-container"
        >
          <div className={styles.calendarHeader}>
            <div className={styles.headerText}>Custom</div>
            <div className={styles.clearFilterText}>Clear All</div>
          </div>
          <div className={styles.filterContainer}>
            <CustomFilterAccordion
              title="Branch"
              isOpen={isBranchOpen}
              setIsOpen={setIsBranchOpen}
            >
              <FilterTypeWrap>
                <FilterSearchField
                  value={branchSearchTerm}
                  onChange={(e: any) => {
                    setBranchSearchTerm(e.target.value);
                  }}
                  placeholder="Search Branch"
                />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {branches &&
                      [{ branchName: "All", id: 0 }, ...displayedBranch]?.map(
                        (item: any) => (
                          <FilterCheckbox
                            keyVal={item.id}
                            checked={checkedBranches.some(
                              (data) => data.id === item.id
                            )}
                            checkboxFunction={() =>
                              handleFilterBranchChecked(
                                item.id,
                                item.branchName
                              )
                            }
                            label={item.branchName.toLowerCase()}
                            fromReports={true}
                          />
                        )
                      )}
                  </FormGroup>
                </FilterCheckBoxWrap>
                <ShowAllBtn
                  clickFunction={handleShowMoreBranch}
                  showAll={showAllBranch}
                  filteredState={filteredBranch}
                />
              </FilterTypeWrap>
            </CustomFilterAccordion>
            <CustomFilterAccordion
              title="Course"
              isOpen={isCourseOpen}
              setIsOpen={setIsCourseOpen}
            >
              <FilterTypeWrap>
                <FilterSearchField
                  value={courseSearchTerm}
                  onChange={(e: any) => {
                    setCourseSearchTerm(e.target.value);
                  }}
                  placeholder="Search Course"
                />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {courseList &&
                      [{ courseName: "All", id: 0 }, ...displayedCourses]?.map(
                        (item: any) => (
                          <FilterCheckbox
                            keyVal={item?.id}
                            checked={checkedCourses?.some(
                              (data) => data?.id === item?.id
                            )}
                            checkboxFunction={() =>
                              handleFilterCourseChecked(
                                item.id,
                                item.courseName
                              )
                            }
                            label={item?.courseName?.toLowerCase()}
                            fromReports={true}
                          />
                        )
                      )}
                  </FormGroup>
                </FilterCheckBoxWrap>
                <ShowAllBtn
                  clickFunction={handleShowMoreCourses}
                  showAll={showAllCourses}
                  filteredState={filteredCourses}
                />
              </FilterTypeWrap>
            </CustomFilterAccordion>
            <CustomFilterAccordion
              title="Batch"
              isOpen={isBatchOpen}
              setIsOpen={setIsBatchOpen}
            >
              <FilterTypeWrap>
                <FilterSearchField
                  value={batchSearchTerm}
                  onChange={(e: any) => setBatchSearchTerm(e.target.value)}
                  placeholder="Search Batch"
                />
                <FilterCheckBoxWrap>
                  <FormGroup>
                    {batchList &&
                      [{ batchName: "All", id: 0 }, ...displayedBatch]?.map(
                        (item: any) => (
                          <FilterCheckbox
                            keyVal={item.id}
                            checked={checkedBatches.some(
                              (data) => data.id === item.id
                            )}
                            checkboxFunction={() =>
                              handleFilterBatchChecked(item.id, item.batchName)
                            }
                            label={item.batchName.toLowerCase()}
                            fromReports={true}
                          />
                        )
                      )}
                  </FormGroup>
                </FilterCheckBoxWrap>
                <ShowAllBtn
                  clickFunction={handleShowMoreBatch}
                  showAll={showAllBatch}
                  filteredState={filteredBatch}
                />
              </FilterTypeWrap>
            </CustomFilterAccordion>
            <CustomFilterAccordion
              title="Start Date"
              isOpen={isStartDateOpen}
              setIsOpen={setIsStartDateOpen}
            >
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      zIndex: "999999999 !important",
                      minHeight: "20px",
                      ".MuiInputBase-root": {
                        fontSize: "12px",
                        minHeight: "20px",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "12px",
                        lineHeight: "1",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "18px",
                      },
                      marginBottom: "10px",
                    }}
                    label="From"
                    format="DD/MM/YYYY"
                    onChange={(date) => handleDateChange(date, "startDate")}
                    value={
                      startDate !== null ? dayjs.unix(startDate / 1000) : null
                    }
                  />
                </LocalizationProvider>
              </Box>
            </CustomFilterAccordion>
            <CustomFilterAccordion
              title="End Date"
              isOpen={isEndDateOpen}
              setIsOpen={setIsEndDateOpen}
            >
              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      zIndex: "99999999 !important",
                      minHeight: "20px",
                      ".MuiInputBase-root": {
                        fontSize: "12px",
                        minHeight: "20px",
                      },
                      ".MuiFormLabel-root": {
                        fontSize: "12px",
                        lineHeight: "1",
                      },
                      ".MuiSvgIcon-root": {
                        fontSize: "18px",
                      },
                    }}
                    label="To"
                    format="DD/MM/YYYY"
                    onChange={(date) => handleDateChange(date, "endDate")}
                    value={endDate !== null ? dayjs.unix(endDate / 1000) : null}
                  />
                </LocalizationProvider>
              </Box>
            </CustomFilterAccordion>

            <div className={styles.applyButtonContainer}>
              <SaveBtn
                onClick={() => {
                  console.log("");
                }}
                buttonText={"Apply"}
              />
            </div>
          </div>
        </Box>

        <Box
          sx={{
            background: "#fff",
            width: "100%",
          }}
        >
          <>
            {!isViewBatchAttendanceVisible ? (
              <>
                <div className={styles.attendanceBatchHeader}>
                  <div className={styles.headerText}>Report-Attendance</div>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 5 }}
                  >
                    <CancelBtn
                      onClick={() => {
                        location?.state?.from === "RecentReports"
                          ? navigate("/app/Reports/RecentReports")
                          : location?.state?.from === "CreatedByMe"
                          ? navigate("/app/Reports/CreatedByMe")
                          : navigate("/app/Reports/AllReports");
                      }}
                    />
                    <SaveBtn
                      onClick={() => {
                        setOpenSaveReportPopup(true);
                      }}
                    />
                    <div style={{ marginLeft: "5px" }}>
                      <IconDropdown
                        options={[
                          {
                            label: "Download",
                            onClick: () => {
                              console.log("");
                            },
                            style: { color: "#667085" },
                          },
                        ]}
                        isDownloadButton={true}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    // overflowY: "scroll",
                    height: "78vh",
                  }}
                >
                  <div className={styles.attendanceContainer}>
                    <div className={styles.leftAttendanceSection}>
                      <div className={styles.topSection}>
                        <div className={styles.titleContainer}>
                          <div className={styles.leftTitle}>
                            <div className={styles.mainTitle}>
                              Morning Batch
                            </div>
                            <div className={styles.subTitle}>Mejestic</div>
                          </div>
                          <div
                            className={styles.rightTitle}
                            onClick={() => {
                              setIsViewBatchAttendanceVisible(true);
                            }}
                          >
                            View Batch Attendance
                          </div>
                        </div>
                      </div>
                      <div className={styles.bottomSection}>
                        <div
                          style={{
                            padding: "20px",
                          }}
                        >
                          <div
                            className={styles.batchDetailInfoContainer}
                            style={{ width: "33%" }}
                          >
                            <div className={styles.batchInfoHeader}>Course</div>
                            <div className={styles.courseName}>{"Angular"}</div>
                          </div>
                          <div className={styles.batchDetailsSection}>
                            <div
                              className={styles.batchDetailInfoContainer}
                              style={{ width: "33%" }}
                            >
                              <div className={styles.batchInfoHeader}>Type</div>
                              <div className={styles.courseName}>
                                {"Classroom"}
                              </div>
                            </div>
                            <div
                              className={styles.batchDetailInfoContainer}
                              style={{ width: "33%" }}
                            >
                              <div className={styles.batchInfoHeader}>
                                Status
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#11C89B",
                                  padding: "2px",
                                  borderRadius: "8px",
                                  color: "#fff",
                                  fontSize: "12px",
                                  width: "70px",
                                  textAlign: "center",
                                }}
                              >
                                {"Ongoing"}
                              </div>
                            </div>

                            <div
                              className={styles.batchDetailInfoContainer}
                              style={{ width: "33%" }}
                            >
                              <div className={styles.batchInfoHeader}>
                                Conduct Type
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#F2F4F7",
                                  padding: "2px",
                                  borderRadius: "8px",
                                  color: "#344054",
                                  fontSize: "12px",
                                  width: "70px",
                                  textAlign: "center",
                                }}
                              >
                                {"Regular"}
                              </div>
                            </div>
                          </div>
                          <div className={styles.batchDetailsSection}>
                            <div
                              className={styles.batchDetailInfoContainer}
                              style={{ width: "33%" }}
                            >
                              <div className={styles.batchInfoHeader}>
                                Min Seats
                              </div>
                              <div className={styles.courseName}>{"30"}</div>
                            </div>
                            <div
                              className={styles.batchDetailInfoContainer}
                              style={{ width: "33%" }}
                            >
                              <div className={styles.batchInfoHeader}>
                                Max Seats
                              </div>
                              <div className={styles.courseName}>{"50"}</div>
                            </div>
                            <div
                              className={styles.batchDetailInfoContainer}
                              style={{ width: "33%" }}
                            >
                              <div className={styles.batchInfoHeader}>
                                Assignee
                              </div>
                              <div
                                className={styles.courseName}
                                style={{
                                  fontSize:
                                    // batchDetails?.assignees?.length > 2
                                    // ? "12px"
                                    // :
                                    "14px",
                                }}
                              >
                                {"Abhijit"}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.overviewContainer}>
                        <div className={styles.overViewTitle}>
                          Attendance Overview
                        </div>
                        <div className={styles.attendanceGraphContainer}>
                          <div className={styles.graph}>
                            <DonutChart />
                          </div>
                          <div style={{ display: "flex", gap: 10 }}>
                            <div className={styles.statusContainer}>
                              <div className={styles.presentHeader}>
                                <img
                                  src={greenDot}
                                  style={{ marginRight: "5px" }}
                                />{" "}
                                Total Present
                              </div>
                              <div className={styles.presentCount}>90%</div>
                            </div>
                            <div className={styles.statusContainer}>
                              <div className={styles.presentHeader}>
                                <img
                                  src={redDot}
                                  style={{ marginRight: "5px" }}
                                />
                                Total Absent
                              </div>
                              <div className={styles.presentCount}>10%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.rightAttendanceSection}>
                      <div className={styles.attendanceListHeader}>
                        Students(221)
                      </div>
                      <div className={styles.attendanceListContainer}>
                        {data?.map((d: any) => {
                          return (
                            <div className={styles.studentNameContainer}>
                              <div className={styles.studentNameWithIcon}>
                                <div className={styles.studentIcon}>
                                  {d?.name?.charAt(0)}
                                </div>
                                <div className={styles.studentName}>
                                  {d?.name}
                                </div>
                              </div>
                              <div className={styles.progressBar}>
                                <CircularProgressbar
                                  value={66}
                                  text={`${66}%`}
                                  styles={buildStyles({
                                    textSize: "25px",
                                    textColor: "#143C4C",
                                    pathColor: `rgba(59, 212, 175, 1), ${
                                      66 / 100
                                    })`,
                                  })}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color: "#667085",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsViewBatchAttendanceVisible(false);
                  }}
                >
                  <img src={backIcon} style={{ marginRight: "5px" }} /> Back
                </div>
                <div
                  style={{
                    padding: "5px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className={styles.headerText}>Batch Attendance</div>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 5 }}
                  >
                    <SaveBtn
                      onClick={() => {
                        console.log("");
                      }}
                      buttonText={"Download"}
                      isDownloadButton={true}
                    />
                  </div>
                </div>
                <div
                  style={{
                    // overflowY: "scroll",
                    height: "78vh",
                  }}
                >
                  <div
                    style={{
                      margin: "10px 20px",
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      sx={{
                        // minWidth: 700,
                        borderRadius: "8px",
                        border: "1px solid #EAECF0",
                        // padding: ' 0px 12px 0px 12px',
                        // margin: " 0px 18px 0px 18px",
                        height: "calc(100vh - 100px)",
                        overflowY: "scroll",
                        boxShadow: "none",
                        flexGrow: 1,
                        width: "100%",
                        maxHeight: "calc(100vh - 220px)",
                        minHeight: "calc(100vh - 220px)",
                      }}
                    >
                      <Table sx={{ minWidth: 700 }}>
                        <TableHead sx={{ background: "#f5f5f5" }}>
                          <TableRow>
                            <StyledTableCellHead
                              sx={{ minWidth: 300, padding: "16px" }}
                            >
                              Name
                            </StyledTableCellHead>
                            <StyledTableCellHead sx={{ minWidth: 150 }}>
                              Total
                            </StyledTableCellHead>
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              01/10/2024
                            </StyledTableCellHead>
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              02/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              03/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              04/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              05/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              06/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              07/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              08/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              09/10/2024
                            </StyledTableCellHead>{" "}
                            <StyledTableCellHead sx={{ minWidth: 450 }}>
                              10/10/2024
                            </StyledTableCellHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reportssData.map((report, index) => (
                            <TableRow key={index}>
                              <StyledTableCellBody sx={{ color: "#142C44" }}>
                                {report.name}
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                {report.total}
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={report.atendedOnFirst ? present : absent}
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={
                                    report.atendedOnSecond ? present : absent
                                  }
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={report.atendedOnThird ? present : absent}
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={
                                    report.atendedOnFourth ? present : absent
                                  }
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={report.atendedOnFifth ? present : absent}
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={report.atendedOnSixth ? present : absent}
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={
                                    report.atendedOnSeventh ? present : absent
                                  }
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                {report.atendedOnEigth}
                                <img
                                  src={report.atendedOnEigth ? present : absent}
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={report.atendedOnNinth ? present : absent}
                                />
                              </StyledTableCellBody>
                              <StyledTableCellBody>
                                <img
                                  src={
                                    report.attendedOnTength ? present : absent
                                  }
                                />
                              </StyledTableCellBody>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </>
            )}
          </>
        </Box>
      </Box>
      {openSaveReportPopup && (
        <SaveReportPopup
          openSaveReportPopup={openSaveReportPopup}
          setOpenSaveReportPopup={setOpenSaveReportPopup}
          setDiscardCancel={setDiscardCancel}
        />
      )}
      <CancelModal
        open={discardCancel}
        onClose={onDiscardClose}
        noButtonClick={noDiscardButtonClick}
        yesButtonClick={yesDiscardButtonClick}
        subTitle="There are unsaved changes in your report. If you close the report, these changes are lost."
        title={`Are you sure you want to close?`}
      />
    </Box>
  );
};

export default AttendanceReportCreation;
