import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import {
  BillingInitialStateDTO,
  CreateBillingAddressDTO,
} from "../service/types";
import {
  getBillingAddress,
  updateBillingAddress,
} from "../service/Billingslice";
import EditAddress from "../components/Address/EditAddress";

interface DispatchProps {
  GetBillingAddress(id: string): void;
  UpdateBillingAddress(data: CreateBillingAddressDTO): void;
}

const mapStateToProps = (state: RootState): BillingInitialStateDTO => {
  return {
    error: state.billing.error,
    success: state.billing.success,
    loading: state.billing.loading,
    instituteBillingData: state.billing.instituteBillingData,
    instituteSubscriptionData: state.billing.instituteSubscriptionData,
    promocodeByName: state.billing.promocodeByName,
    billingAdmins: state.billing.billingAdmins,
    billingAddress: state.billing.billingAddress,
    paymentHistory: state.billing.paymentHistory,
    paymentInvoice: state.billing.paymentInvoice,
    billingPaymentLink: state.billing.billingPaymentLink,
    billingAdminError: state.billing.billingAdminError,
    billingAdminSuccess: state.billing.billingAdminSuccess,
    billingAdminLoading: state.billing.billingAdminLoading,
    userRemovalError: state.billing.userRemovalError,
    userRemovalSuccess: state.billing.userRemovalSuccess,
    userRemovalLoading: state.billing.userRemovalLoading,
    branchRemovalError: state.billing.branchRemovalError,
    branchRemovalSuccess: state.billing.branchRemovalSuccess,
    branchRemovalLoading: state.billing.branchRemovalLoading,
    errorBilling: state.billing.errorBilling,
    successBilling: state.billing.successBilling,
    loadingBilling: state.billing.loadingBilling,
    subscriptionStatusError: state.billing.subscriptionStatusError,
    subscriptionStatussuccess: state.billing.subscriptionStatussuccess,
    subscriptionStatusloading: state.billing.subscriptionStatusloading,
    promocodeError: state.billing.promocodeError,
    promocodesuccess: state.billing.promocodesuccess,
    promocodeloading: state.billing.promocodeloading,
    Invoicesuccess: state.billing.Invoicesuccess,
    billingDataLoading: state.billing.billingDataLoading,
    autoDebitError: state.billing.autoDebitError,
    autoDebitSuccess: state.billing.autoDebitSuccess,
    autoDebitLoading: state.billing.autoDebitLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetBillingAddress: (id: string) => {
      dispatch(getBillingAddress(id));
    },
    UpdateBillingAddress: (data: CreateBillingAddressDTO) => {
      dispatch(updateBillingAddress(data));
    },
  };
};

export interface BillingAdressProps
  extends BillingInitialStateDTO,
    DispatchProps {}
export const EditBillingAddressComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAddress);
