import { Box, Button, Grid, TextField, InputLabel } from "@mui/material";
import React from "react";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import styles from "./Address.module.css";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelModal from "../../../../../../components/CancelModal/CancelModal";
import { useAuthUser } from "react-auth-kit";
import { ErrorType } from "../../../../../../components/FormTextInput/types";
import { reset } from "../../service/Billingslice";
import axios from "axios";

function EditAddress(props: any) {
  const {
    GetBillingAddress,
    UpdateBillingAddress,
    billingAddress,
    success,
    error,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();

  const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
  const navigate = useNavigate();

  const [addressDetails, setAddressDetails] = React.useState({
    instituteId: "",
    instituteName: "",
    buildingNumber: "",
    state: "",
    city: "",
    landmark: "",
    locality: "",
    pin: "",
    status: "",
    gstNumber: "",
  });

  const [oldData, setOldData] = React.useState<any>({
    instituteId: "",
    instituteName: "",
    buildingNumber: "",
    state: "",
    city: "",
    landmark: "",
    locality: "",
    pin: "",
    status: "",
    gstNumber: "",
  });

  const pinRegex = /^\d{6}$/;
  const [pinError, setPinError] = React.useState<boolean>(false);
  const gstNumberRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  const [gstError, setGstError] = React.useState<boolean>(false);

  const [formDisabled, setFormDisabled] = React.useState<boolean>(true);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");

  const [validStateLocality, setValidStateLocality] =
    React.useState<boolean>(true);
  const [validStateBuildingNumber, setValidStateBuildingNumber] =
    React.useState<boolean>(true);

  const setValidateStatusLocality = React.useCallback((val: boolean) => {
    setValidStateLocality(val);
  }, []);
  const setValidateStatusBuildingNumber = React.useCallback((val: boolean) => {
    setValidStateBuildingNumber(val);
  }, []);

  function handleGoBack() {
    window.history.back();
  }

  const handleModalClose = () => setOpenCancelModal(false);

  const noButtonClick = () => {
    setOpenCancelModal(false);
  };

  const yesButtonClick = () => {
    reset();
    setOpenCancelModal(false);
    navigate("/app/manage/Billing?billingTab=address");
  };

  const areObjectsEqual = (obj1: any, obj2: any) => {
    const str1 = JSON.stringify(obj1);
    const str2 = JSON.stringify(obj2);

    return str1 === str2;
  };

  const cancelButtonClick = () => {
    const isSame = areObjectsEqual(addressDetails, oldData);

    if (isSame) {
      navigate("/app/manage/Billing?billingTab=address");
    } else {
      setOpenCancelModal(true);
    }
  };

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      setErrorType("client");

      if (event.target.id === "pin") {
        if (!pinRegex.test(event.target.value)) {
          setPinError(true);
        } else {
          setPinError(false);
          const pin = event.target.value;

          if (pin?.length >= 6) {
            fetchPincodeData(pin);
          }
        }
      }

      if (event.target.id === "gstNumber") {
        if (!gstNumberRegex.test(event.target.value)) {
          setGstError(true);
        } else {
          setGstError(false);
        }
      }

      const { id, value } = event.target;
      const lowercaseValue =
        id === "email" ? value.toLowerCase().trimStart() : value.trimStart();

      setAddressDetails({
        ...addressDetails,
        [id]: lowercaseValue,
      });
    },
    [pinRegex, addressDetails, errorType]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      let formIsValid = true;
      if (!addressDetails.buildingNumber) {
        setValidStateBuildingNumber(false);
        formIsValid = false;
      }
      if (!addressDetails.locality) {
        setValidStateLocality(false);
        formIsValid = false;
      }
      if (addressDetails.gstNumber !== "" && gstError) {
        formIsValid = false;
      } else {
        formIsValid = true;
      }
      formIsValid &&
        validStateBuildingNumber &&
        validStateLocality &&
        UpdateBillingAddress(addressDetails);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (userFromLocalStorage) {
      GetBillingAddress(userFromLocalStorage.id);
    }
  }, [GetBillingAddress, userFromLocalStorage]);

  React.useEffect(() => {
    if (billingAddress) {
      setAddressDetails(billingAddress);
      setOldData(billingAddress);
    }
  }, [billingAddress, setAddressDetails, setOldData]);

  interface PincodeData {
    Message: string;
    Status: string;
    PostOffice: { Name: string; District: string; State: string }[];
  }

  const [pincodeData, setPincodeData] = React.useState<PincodeData | null>(
    null
  );

  const fetchPincodeData = async (pin: string) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pin}`
      );
      if (response.data && response.data.length > 0) {
        const pinData = response.data[0];
        setPincodeData(pinData);

        const firstPostOffice = pinData?.PostOffice?.[0];
        if (firstPostOffice !== null || firstPostOffice !== undefined) {
          setAddressDetails((prevDetails) => ({
            ...prevDetails,
            city: firstPostOffice?.District,
            state: firstPostOffice?.State,
            locality: firstPostOffice?.Name,
          }));
        } else {
          setAddressDetails((prevDetails) => ({
            ...prevDetails,
            city: "",
            state: "",
            locality: "",
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching pincode data:", error);
    }
  };

  React.useEffect(() => {
    if (success !== true && success !== false) {
      navigate("/app/manage/Billing?billingTab=address");
    }
  }, [success, navigate]);

  return (
    <div style={{ overflowY: "auto", maxHeight: "100%", padding: "22px 30px" }}>
      <Box className={styles.addressEditorWrapper}>
        <button className={styles.PageBackBtn} onClick={handleGoBack}>
          <ArrowBackIcon
            sx={{ marginRight: "8px", fontSize: "15PX", color: "#667085" }}
          />
          Back
        </button>
        <div className={styles.addressEditHeading}>Edit Billing address</div>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={{ lg: 3, md: 3, xs: 1, sm: 1 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={6}>
              <NewFormTextInput
                disabled={formDisabled}
                validate={true}
                label="Institute Name "
                type={"text"}
                id={"email"}
                value={addressDetails.instituteName}
                onChange={handleChange}
                autoComplete="true"
                inputProps={{
                  maxLength: 50,
                  style: {
                    backgroundColor: formDisabled ? "#F2F2F2" : "inherit",
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: "#142C44",
                  fontWeight: "500",
                  marginBottom: "6px",
                }}
              >
                GSTIN
              </InputLabel>
              <TextField
                id={"gstNumber"}
                variant="outlined"
                fullWidth
                value={addressDetails.gstNumber}
                inputProps={{
                  maxLength: 15,
                }}
                error={gstError}
                helperText={gstError ? "Invalid GST Number" : ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <NewFormTextInput
                validate={true}
                setValidStatus={setValidateStatusBuildingNumber}
                type={"text"}
                id="buildingNumber"
                label="Building No / Block No"
                value={addressDetails.buildingNumber}
                onChange={handleChange}
                error={!validStateBuildingNumber}
                autoComplete="true"
                require={true}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={6}>
              <NewFormTextInput
                validate={true}
                setValidStatus={setValidateStatusLocality}
                label="Locality"
                type="text"
                id={"locality"}
                value={addressDetails.locality}
                onChange={handleChange}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                autoComplete="true"
                require={true}
                inputProps={{ maxLength: 50 }}
                error={!validStateLocality}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: "#142C44",
                  fontWeight: "500",
                  marginBottom: "6px",
                }}
              >
                Pin
              </InputLabel>
              <TextField
                id={"pin"}
                variant="outlined"
                fullWidth
                value={addressDetails.pin}
                inputProps={{
                  maxLength: 6,
                }}
                error={pinError}
                helperText={pinError ? "Invalid PIN code" : ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <NewFormTextInput
                onChange={handleChange}
                id={"city"}
                label="City"
                variant="standard"
                fullWidth
                inputProps={{ maxLength: 50 }}
                value={addressDetails.city}
              />
            </Grid>
            <Grid item xs={6}>
              <NewFormTextInput
                onChange={handleChange}
                id={"state"}
                label="State"
                variant="standard"
                fullWidth
                inputProps={{ maxLength: 50 }}
                value={addressDetails.state}
              />
            </Grid>
            <Grid item xs={6}>
              <NewFormTextInput
                onChange={handleChange}
                id={"landmark"}
                label="Landmark"
                fullWidth
                maxRows={1}
                inputProps={{
                  maxLength: 50,
                }}
                value={addressDetails.landmark}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "6px",
              marginTop: "24px",
            }}
          >
            <Button
              sx={{
                textTransform: "capitalize",
                color: "#BFBFBF",
                border: "1px solid #BFBFBF",
              }}
              type="reset"
              variant="outlined"
              onClick={() => cancelButtonClick()}
            >
              Cancel
            </Button>

            <Button
              sx={{ textTransform: "capitalize" }}
              variant="contained"
              type="submit"
            >
              Save changes
            </Button>
          </Box>
        </form>
        <CancelModal
          open={openCancelModal}
          onClose={handleModalClose}
          noButtonClick={noButtonClick}
          yesButtonClick={yesButtonClick}
        />
      </Box>
    </div>
  );
}

export default EditAddress;
