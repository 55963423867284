import { RootState, TypedDispatch } from "../../../../../redux/store";
import { connect } from "react-redux";
import {
  CreateCourseDTO,
  CreateCategoryDTO,
  CreateCourseInitialStateDTO,
  CreateTagDTO,
} from "../service/types";
import {
  createCourse,
  getCourseByIdRequest,
  updateCourse,
} from "../service/courseSlice";
import { createCategory } from "../service/categorySlice";
import { createTag } from "../service/tagSlice";
import CourseEditor from "../CourseEditor/CourseEditor";
import { GetCourseConfig } from "../service/courseConfigSlice";
import { appEntery } from "../../../Home/service/homeSlice";
import { fileUpload } from "../../../Main/components/FileUpload/service/fileUploadSlice";
import { FileUploadDTO } from "../../../Main/components/FileUpload/service/types";

interface StateProps extends CreateCourseInitialStateDTO {}

interface DispatchProps {
  CourseConfig(): void;
  CreateCourse(data: CreateCourseDTO): void;
  GetCourseById(id: string): void;
  UpdateCourse(data: CreateCourseDTO): void;
  CreateCategory(data: CreateCategoryDTO): void;
  CreateTag(data: CreateTagDTO): void;
  UpdateInstituteInLocal(id: string): void;
  CourseImageUpload(data: FileUploadDTO): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success_createCourse: state.courses.success_createCourse,
    error_createCourse: state.courses.error_createCourse,
    loading_createCourse: state.courses.loading_createCourse,
    success_updateCourse: state.courses.success_updateCourse,
    error_updateCourse: state.courses.error_updateCourse,
    loading_updateCourse: state.courses.loading_updateCourse,
    success_createCategory: state.category.success_createCategory,
    add_category_id: state.category.add_category_id,
    error_createCategory: state.category.error_createCategory,
    loading_createCategory: state.category.loading_createCategory,
    success_createTag: state.tag.success_createTag,
    error_createTag: state.tag.error_createTag,
    loading_createTag: state.tag.loading_createTag,
    success_CourseConfig: state.courseConfig.success_CourseConfig,
    error_CourseConfig: state.courseConfig.error_CourseConfig,
    loading_CourseConfig: state.courseConfig.loading_CourseConfig,
    courseCategories: state.courseConfig.courseConfigs.courseCategories,
    courseTagList: state.courseConfig.courseConfigs.courseTags,
    courseType: state.courseConfig.courseConfigs.courseType,
    courseLevel: state.courseConfig.courseConfigs.courseLevel,
    courseById: state.courses.courseById,
    fileUploadSuccess: state.fileUpload.fileUploadSuccess,
    success: state.courses.success,
    error: state.courses.error,
    loading: state.courses.loading,
    newAddedSelectedTag: state.tag.newAddedSelectedTag,
    courseByIdLoading: state.courses.courseByIdLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CourseConfig: () => {
      dispatch(GetCourseConfig());
    },
    CreateCourse: (data: CreateCourseDTO) => {
      dispatch(createCourse(data));
    },
    UpdateCourse: (data: CreateCourseDTO) => {
      dispatch(updateCourse(data));
    },
    GetCourseById: (id: string) => {
      dispatch(getCourseByIdRequest(id));
    },
    CreateCategory: (data: CreateCategoryDTO) => {
      dispatch(createCategory(data));
    },
    CreateTag: (data: CreateTagDTO) => {
      dispatch(createTag(data));
    },
    UpdateInstituteInLocal: (id: string) => {
      dispatch(appEntery(id));
    },
    CourseImageUpload: (data: FileUploadDTO) => {
      dispatch(fileUpload(data));
    },
  };
};

export interface CourseEditorProps extends StateProps, DispatchProps {}
export const CourseEditorComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseEditor);
