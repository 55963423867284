import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import nurtureLogo from "../components/Assets/nurtureLogo.svg";
import nurtureLogo44 from "../components/Assets/nurtureLogo44.svg";

const SuccessPage: React.FC = () => {
  const phoneNumber = "+919895114640"; // Replace with your actual phone number

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100vh"
      padding={3}
    >
      <Box textAlign="center">
        <img src={nurtureLogo44} alt="Logo" width={100} />

        <Box marginTop={1}>
          <Typography variant="h6" textAlign="center" sx={{ fontSize: "16px" }}>
            We will connect with you shortly.
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box marginTop={5}>
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{ fontSize: "14px" }}
          >
            For prompt assistance, connect here
          </Typography>
        </Box>

        <Box marginTop={2} width="100%">
          <a href={`tel:${phoneNumber}`} style={{ textDecoration: "none" }}>
            <Button variant="outlined" startIcon={<PhoneIcon />} fullWidth>
              Call
            </Button>
          </a>
        </Box>
        <Box marginTop={2} width="100%">
          <a
            href={`https://wa.me/${phoneNumber}`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" startIcon={<WhatsAppIcon />} fullWidth>
              WhatsApp
            </Button>
          </a>
        </Box>

        <Box sx={{ textAlign: "center", marginTop: "30px", fontSize: "12px" }}>
          <img src={nurtureLogo} alt="Nurture Logo" />
          <Typography
            sx={{ color: "#7C7C7C", lineHeight: 1, fontSize: "12px" }}
          >
            Powered by
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>nurturecrm.in</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessPage;
