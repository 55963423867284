import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import FormTextInput from "../../../../components/FormTextInput/FormTextInput";
import { ErrorType } from "../../../../components/FormTextInput/types";
import { SETUPPASSWORD } from "../container/ForgetPasswordString";
import { resetPasswordDTO } from "../service/types";
import styles from "./ForgetPassword.module.css";
import PasswordValidation from "./PasswordValidation";
export function Onboard(props: {
  email: string;
  onSubmit: (data: resetPasswordDTO) => void;
  loading: boolean;
  otp: string;
  success_resetPassword: boolean;
  isFromChangePassword?: boolean;
}) {
  const [credential, setCredential] = useState({
    password: "",
    conformPassword: "",
  });
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStatePassword, setValidStatePassword] =
    React.useState<boolean>(true);
  const [validStateConfirmPassword, setValidStateConfirmPassword] =
    React.useState<boolean>(true);
  const handleChange = useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStatePassword(true);
        setValidStateConfirmPassword(true);
      }
      setErrorType("client");
      setCredential({ ...credential, [event.target.id]: event.target.value });
    },
    [credential, errorType]
  );
  const setValidateStatusPassword = React.useCallback((val: boolean) => {
    setValidStatePassword(val);
  }, []);
  const setValidateStatusConfirmPassword = React.useCallback((val: boolean) => {
    setValidStateConfirmPassword(val);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    !credential.conformPassword && setValidStateConfirmPassword(false);
    !credential.password && setValidStatePassword(false);
    validStateConfirmPassword &&
      validStatePassword &&
      credential.conformPassword &&
      credential.password &&
      credential.password !== credential.conformPassword &&
      setErrorMsg("Passwords don't match");
    if (
      credential.password === credential.conformPassword &&
      credential.conformPassword &&
      credential.password
    ) {
      props.onSubmit({
        email: props.email,
        password: credential.password,
        otp: props.otp,
      });
      // setTimeout(() => {
      //   if (props?.isFromChangePassword) {
      //     navigate("/app/manage/UserDetails");
      //   } else {
      //     navigate("/");
      //   }
      // }, 2000);
    }
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Box
        className={styles.logo_box}
        sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
      >
        <img src={logo} height="35" width="150" alt="" />
      </Box>

      <Box className={styles.formHeadingalt}>
        <Typography sx={{ fontSize: { lg: 30, md: 30, sm: 25, xs: 21 } }}>
          {SETUPPASSWORD}
        </Typography>

        <Typography
          sx={{
            fontSize: { lg: 17, md: 17, sm: 14, xs: 12 },
            textTransform: "capitalize",
            fontWeight: "400",
            color: "#8A8A8A",
            marginTop: "10px",
          }}
        >
          Setup your unique password.
        </Typography>
      </Box>

      <InputLabel
        sx={{
          fontSize: "14px",
          color: "#142C44",
          fontWeight: "500",
          mb: "5px",
          fontFamily: "Poppins",
        }}
      >
        Password
      </InputLabel>
      <FormControl
        fullWidth
        variant="standard"
        className="formControl"
        sx={{ mb: 2 }}
      >
        <FormTextInput
          autoComplete="true"
          validate={true}
          id={"password"}
          variant="outlined"
          type={"password"}
          setValidStatus={setValidateStatusPassword}
          error={!validStatePassword}
          value={credential.password}
          onChange={handleChange}
          showErrorMessage={showErrMsz}
          errorType={errorType}
          disabled={props.success_resetPassword}
        />
      </FormControl>

      <InputLabel
        sx={{
          fontSize: "14px",
          color: "#142C44",
          fontWeight: "500",
          mb: "5px",
          fontFamily: "Poppins",
        }}
      >
        Re-enter password
      </InputLabel>
      <FormControl
        fullWidth
        variant="standard"
        className="formControl"
        sx={{ mb: 2 }}
      >
        <FormTextInput
          autoComplete="true"
          validate={true}
          id={"conformPassword"}
          variant="outlined"
          type={"password"}
          setValidStatus={setValidateStatusConfirmPassword}
          error={!validStateConfirmPassword}
          showErrorMessage={showErrMsz}
          value={credential.conformPassword}
          onChange={handleChange}
          errorType={errorType}
          disabled={props.success_resetPassword}
        />
        {errorMsg && <span style={{ color: "red" }}>{errorMsg}</span>}
      </FormControl>
      {isDisabled ? (
        <>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            disabled
            sx={{ textTransform: "capitalize" }}
            className={styles.submitButton}
          >
            {props.loading ? (
              <CircularProgress size={25} color={"inherit"} />
            ) : (
              "Continue"
            )}
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            sx={{ textTransform: "capitalize" }}
            className={styles.submitButton}
            disabled={props.success_resetPassword}
          >
            {props.loading ? (
              <CircularProgress size={25} color={"inherit"} />
            ) : (
              "Continue"
            )}
          </Button>
        </>
      )}
      <PasswordValidation
        copyText={credential.password}
        setIsDisabled={setIsDisabled}
      />
    </form>
  );
}
