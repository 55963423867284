import { connect } from "react-redux";
import { UserDetailsDTO } from "../../../../auth/signin/service/types";
import { setActiveInsitute } from "../../../../../service/activeInstituteSlice";
import ManageRoot from "../ManageRoot";
import { appEntery } from "../../../Home/service/homeSlice";
import { RootState, TypedDispatch } from "../../../../../redux/store";
interface StateProps {
  userDetails: UserDetailsDTO | null;
  error: boolean | string | undefined;
  loading: boolean;
  success: boolean;
  isProfileComplete: boolean;
  isCourseSetup: boolean;
  isBranchAdded: boolean;
  isTeamAdded: boolean;
  isBatchAdded: boolean;
  profileDetailsCompleted: boolean;
  activeInstitute: any;
}
interface DispatchProps {
  registrationStep(data: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error: state.home.error,
    loading: state.home.loading,
    userDetails: state.login.userDetails,
    success: state.home.success,
    isProfileComplete: state.home.isProfileComplete,
    profileDetailsCompleted: state.home.profileDetailsCompleted,
    isCourseSetup: state.home.isCourseSetup,
    isBranchAdded: state.home.isBranchAdded,
    isTeamAdded: state.home.isTeamAdded,
    isBatchAdded: state.home.isBatchAdded,
    activeInstitute: state.home.data,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    registrationStep: (data: string) => {
      dispatch(appEntery(data));
      dispatch(setActiveInsitute(data));
    },
  };
};
export interface HomeProps extends StateProps, DispatchProps {}
export const ManageRootComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRoot);
