import React, { useRef, useState } from "react";
import styles from "../../Customize.module.css";
import SaveBtn from "../../../../../../../components/Buttons/SaveBtn";
import CancelBtn from "../../../../../../../components/Buttons/CancelBtn";
import CustomizedTextField from "./CustomizedField/CustomizedTextField";
import CustomizedPhoneField from "./CustomizedField/CustomizedPhoneField";
import CustomizedEmailField from "./CustomizedField/CustomizedEmailField";
import CustomizedCheckboxField from "./CustomizedField/CustomizedCheckboxField";
import CustomizedDropdownField from "./CustomizedField/CustomizedDropdownField";
import CustomizedRadioField from "./CustomizedField/CustomizedRadioField";
import CustomizedDateField from "./CustomizedField/CustomizedDateField";
import { setToastNotification } from "../../../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import CustomizedProfilePicField from "./CustomizedField/CustomizedProfilePicField";
import { useAuthUser } from "react-auth-kit";
import { editCustomFields } from "../../../../service/settingsSlice";
import { useSelector } from "react-redux";
import { FormHelperText } from "@mui/material";
import { checkObjectsEqual } from "../../../../../../../components/CommonFunctions/CommonFunctions";
import CancelPopup from "../../../../../../../components/CommonPopups/CancelPopup";

function Parameters(props: any) {
  const {
    customFields,
    setcustomFields,
    setDraggedField,
    draggedField,
    oldCustomFields,
    setOldCustomFields,
    existingFields,
    hasCustomizePermission,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { updateCustomFieldsLoading } = useSelector(
    (state: any) => state.appSettings
  );

  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const containerRef = useRef<any>(null);

  const [draggedFieldIndex, setDraggedFieldIndex] = useState<number | null>(
    null
  );
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);

  function handleOnDragFields(e: any, field: any, index: number) {
    setDraggedField(field);
    setDraggedFieldIndex(index);
    setDragActive(true);
  }

  function handleOnDragEnter(e: any, field: any, index: number) {
    if (draggedField !== null && draggedFieldIndex !== null) {
      const updatedFields = [...customFields];
      updatedFields.splice(draggedFieldIndex, 1);
      console.log("hoyaa", updatedFields);

      updatedFields.splice(index, 0, draggedField);
      console.log("hoyaa1", updatedFields);
      const updatedFieldsWithIndex = updatedFields?.map(
        (item, index: number) => ({
          ...item,
          index: index + 1,
        })
      );

      setcustomFields(updatedFieldsWithIndex);
      setDraggedField(null);
      setDraggedFieldIndex(null);
    }
  }

  function handleOnDragOver(e: any) {
    e.preventDefault();
    // if (dragActive) {
    //   const y = e.clientY;
    //   const containerRect = containerRef.current.getBoundingClientRect();
    //   const scrollSpeed = 10;
    //   let scrollDirection = 0;

    //   if (y < containerRect.top + 100) {
    //     scrollDirection = -1;
    //   } else if (y > containerRect.bottom - 100) {
    //     scrollDirection = 1;
    //   }

    //   if (scrollDirection !== 0) {
    //     containerRef.current.scrollTop += scrollDirection * scrollSpeed;
    //   }
    // }
  }

  function handleOnDrop(e: any) {
    e.preventDefault();
    if (draggedField) {
      let fieldExist: any;
      console.log("hoyiii");

      fieldExist = customFields?.filter(
        (item: any) => item?.fieldName === draggedField?.fieldName
      );
      if (fieldExist?.length > 0) {
        dispatch(
          setToastNotification({
            message: String("Field exist with same label"),
            type: "error",
            snackOpen: true,
          })
        );
        setDraggedField(null);
      } else {
        const lastIndex =
          customFields.length > 0
            ? customFields[customFields.length - 1].index || 0
            : -1;

        const updatedDraggedField = {
          ...draggedField,
          index: lastIndex + 1,
        };
        setcustomFields([...customFields, updatedDraggedField]);
        setDraggedField(null);
      }
    }
  }

  const handleFieldChange = (updatedField: any, index: any) => {
    const newFields = [...customFields];
    newFields[index] = updatedField;
    setcustomFields(newFields);
  };

  const handleDeleteField = (fieldToDelete: any) => {
    const updatedFields = customFields
      ?.filter((field: any) => field !== fieldToDelete)
      ?.map((item: any, index: number) => ({
        ...item,
        index: index + 1,
      }));

    setcustomFields(updatedFields);
  };

  const isNameUniqueCheck = (fieldName: any, fieldIndex: number) => {
    return !customFields?.some(
      (r: any) =>
        r?.index !== fieldIndex &&
        r?.fieldName?.toLowerCase() === fieldName?.toLowerCase()
    );
  };

  const hasDuplicateFieldNames =
    new Set(customFields?.map((item: any) => item.fieldName)).size !==
    customFields?.length;
  const hasDuplicateIndices =
    new Set(customFields?.map((item: any) => item.index)).size !==
    customFields?.length;
  const hasInvalidOptions = customFields?.some(
    (item: any) =>
      (item.fieldType === "radio" ||
        item.fieldType === "checkbox" ||
        item.fieldType === "select") &&
      item.options?.length === 0
  );

  const isObjectsEqual = checkObjectsEqual(
    { fields: customFields },
    { fields: oldCustomFields }
  );
  console.log("isObjectsEqual", isObjectsEqual);
  const cancelButtonClick = () => {
    if (!isObjectsEqual) {
      setCancelModal(true);
    }
  };

  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const cancelYesBtn = () => {
    setCancelModal(false);
    if (existingFields?.fields?.length > 0) {
      const updatedFields = existingFields?.fields?.map(
        (item: any, index: number) => ({
          fieldType: item?.field?.fieldType,
          fieldName: item?.field?.fieldName,
          fieldPlaceholder: item?.field?.fieldPlaceholder,
          options: item?.field?.options,
          default: item?.field?.default,
          required: item?.field?.required,
          index: index + 1,
          loadDatafromDB: item?.field?.loadDatafromDB,
          DBModel: item?.field?.DBModel,
          isCustom: item?.field?.isCustom,
          isFromCustom: item?.field?.isFromCustom,
          id: item?.id,
        })
      );

      setcustomFields(updatedFields);
      setOldCustomFields(updatedFields);
    } else {
      setcustomFields([]);
      setOldCustomFields([]);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      if (!hasDuplicateIndices) {
        const updatedFields = customFields?.map((item: any, index: number) => ({
          field: {
            isDeletable: true,
            fieldPlaceholder: item?.fieldPlaceholder || "",
            default: item?.default || "",
            fieldName: item?.fieldName || "",
            DBModel: item?.DBModel || "",
            options: item?.options || [],
            isCustom: item?.isCustom || false,
            index: index + 1,
            loadDatafromDB: item?.loadDatafromDB || false,
            fieldType: item?.fieldType || "text",
            required: item?.required || false,
          },
          id: item?.id || "",
        }));
        const body = {
          instituteId: activeInstitute?.id,
          fields: updatedFields,
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
        };
        dispatch<any>(editCustomFields(body));
      }
    } catch (error) {}
  };
  console.log(
    "customFields",
    customFields,
    existingFields?.fields?.length,
    customFields?.length,
    existingFields?.fields?.length === 0 && customFields?.length === 0,
    existingFields?.fields?.length > 0 && customFields?.length === 0
  );

  return (
    <div
      className={styles.parameterFieldWrap}
      style={{
        maxHeight: "calc(100vh - 180px)",
        minHeight: "calc(100vh - 180px)",
        overflowY: "hidden",
        width: "100% ",
      }}
    >
      <div
        className={styles.parameterFieldWrapChild}
        style={{
          maxHeight: isObjectsEqual
            ? "calc(100vh - 170px)"
            : "calc(100vh - 247px)",
          minHeight: isObjectsEqual
            ? "calc(100vh - 170px)"
            : "calc(100vh - 247px)",
          overflowY: "auto",
        }}
        onDrop={handleOnDrop}
        onDragOver={handleOnDragOver}
      >
        <div className={styles.parameterFieldTitle}>Customize Parameter</div>
        {customFields?.length === 0 ? (
          <div className={styles.noCustomField}>
            {existingFields?.fields?.length === 0 && customFields?.length === 0
              ? "No customization options added yet, drag and drop to start"
              : existingFields?.fields?.length > 0 && customFields?.length === 0
              ? "Drag and drop to add custom fields"
              : ""}
          </div>
        ) : (
          <div>
            {customFields?.map((field: any, index: number) => (
              <div
                key={index}
                className={styles.primaryFields}
                draggable={hasCustomizePermission}
                onDragStart={(e) => handleOnDragFields(e, field, index)}
                // onDragOver={(e) => handleOnDragOver(e)}
                onDropCapture={(e) => handleOnDragEnter(e, field, index)}
              >
                {field.fieldType === "text" && (
                  <CustomizedTextField
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    field={field}
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isTextFieldFocused={isTextFieldFocused}
                    //   setIsTextFieldFocused={setIsTextFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
                {field.fieldType === "phone" && (
                  <CustomizedPhoneField
                    field={field}
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isPhoneFieldFocused={isPhoneFieldFocused}
                    //   setIsPhoneFieldFocused={setIsPhoneFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
                {field.fieldType === "email" && (
                  <CustomizedEmailField
                    field={field}
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}

                {field.fieldType === "checkbox" && (
                  <CustomizedCheckboxField
                    field={field}
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isCheckboxFieldFocused={isCheckboxFieldFocused}
                    //   setIsCheckboxFieldFocused={setIsCheckboxFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
                {field.fieldType === "select" && (
                  <CustomizedDropdownField
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    field={field}
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isDropdownFieldFocused={isDropdownFieldFocused}
                    //   setIsDropdownFieldFocused={setIsDropdownFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
                {field.fieldType === "radio" && (
                  <CustomizedRadioField
                    field={field}
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isRadioFieldFocused={isRadioFieldFocused}
                    //   setIsRadioFieldFocused={setIsRadioFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
                {field.fieldType === "date" && (
                  <CustomizedDateField
                    field={field}
                    onDelete={handleDeleteField}
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isDateFieldFocused={isDateFieldFocused}
                    //   setIsDateFieldFocused={setIsDateFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
                {field.fieldType === "file" && (
                  <CustomizedProfilePicField
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    field={field}
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isTextFieldFocused={isTextFieldFocused}
                    //   setIsTextFieldFocused={setIsTextFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
                {field.fieldType === "profilePic" && (
                  <CustomizedProfilePicField
                    onFieldChange={(updatedField: any) =>
                      handleFieldChange(updatedField, index)
                    }
                    field={field}
                    edit={hasCustomizePermission ? true : false}
                    deleteAction={hasCustomizePermission ? true : false}
                    mandatory={false}
                    onDelete={handleDeleteField}
                    isNameUniqueCheck={isNameUniqueCheck}
                    //   isTextFieldFocused={isTextFieldFocused}
                    //   setIsTextFieldFocused={setIsTextFieldFocused}
                    //   leadsFromResponseChecked={leadsFromResponseChecked}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {!isObjectsEqual && (
        <div className={styles.parameterFieldBottom}>
          {hasInvalidOptions && (
            <FormHelperText error>
              Please fill answers for fields which requires answers.
            </FormHelperText>
          )}
          <div className={styles.parameterFieldBtnsWrap}>
            {cancelModal && (
              <CancelPopup
                open={cancelModal}
                onClose={closeCancelModal}
                noBtnClick={closeCancelModal}
                yesBtnClick={cancelYesBtn}
              />
            )}
            <CancelBtn onClick={cancelButtonClick} />
            <SaveBtn
              onClick={handleSubmit}
              disabled={
                hasDuplicateIndices ||
                updateCustomFieldsLoading ||
                hasDuplicateFieldNames ||
                hasInvalidOptions
              }
              loader={updateCustomFieldsLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Parameters;
