import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
const MonthAndYear = ({
  month,
  year,
  handlePreviousMonth,
  handleNextMonth,
}: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center",marginBottom:"10px" }}>
        <NavigateBeforeIcon
          onClick={handlePreviousMonth}
          style={{ color: "#667085",cursor:"pointer",fontSize:"30px" }}
        />
        <div style={{ color: "#142C44", fontSize: "18px",fontWeight:"500" }}>
          {month}, {year}
        </div>
        <NavigateNextIcon
          onClick={handleNextMonth}
          style={{ color: "#667085",cursor:"pointer",fontSize:"30px" }}
        />
    </div>
  );
};

export default MonthAndYear;
