import {
  Button,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import styles from "./SourceManager.module.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createSource } from "../../pages/app/Nurture/service/nurtureSlice";
import { useAuthUser } from "react-auth-kit";

function SourceManager(props: any) {
  const {
    sourceList,
    onSourceChange,
    source,
    page,
    addLead,
    disabled,
    validSource,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const sourceManagerRef = useRef<HTMLDivElement | null>(null);
  const { createSourceSuccess, createSourceLoading } = useSelector(
    (state: any) => state.nurture
  );

  const [isValueInList, setIsValueInList] = React.useState(true);
  const [typedValue, setTypedValue] = React.useState("");

  const filterSourceBasedOnSearch =
    typedValue !== "" && source === ""
      ? sourceList?.filter((item: any) =>
          item?.value?.toLowerCase()?.includes(typedValue?.toLowerCase())
        )
      : typedValue !== "" &&
        source !== "" &&
        typedValue?.toLowerCase() !== source?.value?.toLowerCase()
      ? sourceList?.filter((item: any) =>
          item?.value?.toLowerCase()?.includes(typedValue?.toLowerCase())
        )
      : typedValue !== "" &&
        source !== "" &&
        typedValue?.toLowerCase() === source?.value?.toLowerCase()
      ? sourceList
      : sourceList;

  const [isTextFieldFocused, setIsTextFieldFocused] = React.useState(false);

  const handleTextFieldFocus = () => {
    setIsTextFieldFocused(true);
  };

  const handleTextFieldBlur = () => {
    setTimeout(() => {
      setIsTextFieldFocused(false);
    }, 200);
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      setTypedValue(inputValue);

      if (source) {
        if (source?.value?.toLowerCase() !== inputValue?.toLowerCase()) {
          onSourceChange(null);
        }
      }

      const isValueInList = sourceList.some(
        (item: any) => item.value === inputValue
      );
      setIsValueInList(isValueInList);
    },
    [setTypedValue, source, onSourceChange]
  );

  const handleSelectSource = useCallback(
    (sourceItem: any) => {
      if (sourceItem) {
        setTypedValue(sourceItem?.value);
        onSourceChange(sourceItem);
        setIsTextFieldFocused(false);
      }
    },
    [onSourceChange, setTypedValue]
  );

  const handleAddSource = async (event: any) => {
    event.preventDefault();

    try {
      const instituteId = authUser?.institute?.[0]?.instituteId;
      dispatch<any>(
        createSource({
          instituteId: instituteId,
          enquirySource: typedValue,
        })
      );

      // CreateSource({ instituteId: instituteId, enquirySource: typedValue });
    } catch (error) {}
  };

  React.useEffect(() => {
    if (createSourceSuccess !== true && createSourceSuccess !== false) {
      const newSourceValue = {
        key: typedValue?.toUpperCase().replace(/\s+/g, "_"),
        value: typedValue,
      };
      if (newSourceValue) {
        onSourceChange(newSourceValue);
        setTypedValue(newSourceValue?.value);
        setIsTextFieldFocused(false);
      }
    }
  }, [createSourceSuccess, onSourceChange, typedValue]);

  useEffect(() => {
    if (addLead) {
      if (page === "CONTACT") {
        const sourceItem = sourceList?.find(
          (item: any) => item.key === "EXCEL_IMPORT"
        );
        setTypedValue(sourceItem?.value);
      } else if (page === "WEBFORM") {
        const sourceItem = sourceList?.find(
          (item: any) => item.key === "WEBFORM"
        );
        setTypedValue(sourceItem?.value);
      } else if (page === "ZAPIER") {
        const sourceItem = sourceList?.find(
          (item: any) => item.key === "ZAPIER"
        );
        setTypedValue(sourceItem?.value);
      } else if (page === "EMAIL") {
        const sourceItem = sourceList?.find(
          (item: any) => item.key === "EMAIL"
        );
        setTypedValue(sourceItem?.value);
      }
    }
  }, [setTypedValue, addLead]);

  useEffect(() => {
    if (!addLead) {
      setTypedValue(source?.value);
    }
  }, [setTypedValue, addLead, source]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sourceManagerRef.current &&
        !sourceManagerRef.current.contains(event.target)
      ) {
        setIsTextFieldFocused(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sourceManagerRef]);

  return (
    <div ref={sourceManagerRef}>
      <div className={styles.sourceManagerWrap}>
        <TextField
          label={addLead ? "Type Source" : null}
          onChange={handleInputChange}
          variant="outlined"
          size="small"
          value={typedValue}
          onFocus={handleTextFieldFocus}
          // onBlur={handleTextFieldBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {createSourceLoading && (
                  <CircularProgress size={18} thickness={5} />
                )}
              </InputAdornment>
            ),
          }}
          disabled={createSourceLoading || disabled}
          fullWidth
          error={validSource ? true : false}
        />
        <div>
          {isTextFieldFocused && (
            <div className={styles.sourceListPopover}>
              <ul className={styles.sourceListPopoverItemsWrap}>
                {filterSourceBasedOnSearch?.length > 0 ? (
                  <>
                    {filterSourceBasedOnSearch?.map(
                      (item: any, index: number) => {
                        return (
                          <li
                            key={index}
                            className={styles.sourceListPopoverItem}
                            onClick={() => handleSelectSource(item)}
                            style={{
                              background:
                                source?.key === item?.key
                                  ? "rgb(226, 248, 243)"
                                  : "auto",
                            }}
                          >
                            {item.value}
                          </li>
                        );
                      }
                    )}
                  </>
                ) : (
                  <>
                    <li className={styles.sourceListPopoverItemAdd}>
                      <div className={styles.sourceListPopoverItemAddValue}>
                        {typedValue}{" "}
                      </div>
                      <Button
                        size="small"
                        onClick={handleAddSource}
                        sx={{ textTransform: "capitalize", minWidth: "80px" }}
                      >
                        Add new
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      {validSource && (
        <FormHelperText error={true}>Field required</FormHelperText>
      )}
    </div>
  );
}

export default SourceManager;
