import React, { useState } from "react";
import styles from "../../Customize.module.css";
import { Button, Dialog, TextField } from "@mui/material";
import CancelBtn from "../../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../../components/Buttons/SaveBtn";
import CloseIcon from "@mui/icons-material/Close";
import {
  createLeadForm,
  resetSettingsStatuses,
} from "../../../../service/settingsSlice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function AddLeadBanner(props: any) {
  const { hasCustomizePermission } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { createLeadFormLoading, createLeadFormSuccess, createdLeadFormId } =
    useSelector((state: any) => state.appSettings);

  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const [open, setOpen] = useState<boolean>(false);
  const [formName, setFormName] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
    setFormName("");
  };

  const handleChangeName = (e: any) => {
    setFormName(e.target.value);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      if (formName !== "") {
        const body = {
          instituteId: activeInstitute?.id,
          formName: formName,
          createdByName: authUser?.firstName + " " + authUser?.lastName,
          createdById: authUser?.institute[0]?.id,
        };
        dispatch<any>(createLeadForm(body));
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (
      createLeadFormSuccess !== true &&
      createLeadFormSuccess !== false &&
      createdLeadFormId
    ) {
      setOpen(false);
      navigate(`/app/Settings/Customize/EditLeadForm/${createdLeadFormId}`);
      dispatch(resetSettingsStatuses());
    }
  }, [createLeadFormSuccess, createdLeadFormId]);

  return (
    <div className={styles.welcomeWrapper}>
      <div className={styles.welcomeHead}>Customize Add Lead Form</div>
      <div className={styles.welcomePara}>
        Your ultimate tool for collecting valuable data and engaging with your
        audience effortlessly. Create, customize, and manage Add Lead Form with
        ease, all within nurture.
      </div>
      {hasCustomizePermission && (
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={() => setOpen(true)}
        >
          Create New
        </Button>
      )}

      {open && (
        <Dialog
          open={open}
          sx={{
            zIndex: "99999",
            minWidth: "400px",
            "& .MuiDialog-paper": {
              width: "100%",
              maxWidth: "400px",
              maxHeight: "290px",
              minHeight: "165px",
              padding: "24px",
              borderRadius: "12px",
              boxShadow:
                "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
              left: "5%",
              top: "8%",
              background: "#FFFFFF",
              overflow: "hidden",
            },
          }}
        >
          <div className={styles.createFormPopupWrap}>
            <div className={styles.createFormPopupHead}>
              <div className={styles.modalTitle}>Form Name</div>
              <div className={styles.clsBtn}>
                <CloseIcon
                  sx={{ color: "#555555", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>
            </div>

            <TextField
              variant="outlined"
              value={formName}
              placeholder="Enter name for your form"
              fullWidth
              onChange={handleChangeName}
              inputProps={{
                style: {
                  padding: "10px 12px",
                  backgroundColor: "#FFF",
                  borderRadius: "4px",
                },
              }}
            />
            <div className={styles.btnSplit}>
              <CancelBtn onClick={handleClose} />
              <SaveBtn
                onClick={handleSubmit}
                buttonText={"Next"}
                disabled={formName === "" || createLeadFormLoading}
                loader={createLeadFormLoading}
              />
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default AddLeadBanner;
