import { RootState, TypedDispatch } from "../../../../../redux/store";
import {
  createBranch,
  getBranchById,
  updateBranch,
} from "../service/Branchslice";
import { branchInitialStateDTO, createBranchDTO } from "../service/types";
import { connect } from "react-redux";
import BranchEditor from "../BranchEditor/BranchEditor";
import { appEntery } from "../../../Home/service/homeSlice";

interface StateProps extends branchInitialStateDTO {}

interface DispatchProps {
  CreateBranch(data: createBranchDTO): void;
  GetBranchById(id: string): void;
  UpdateBranch(data: createBranchDTO): void;
  UpdateInstituteInLocal(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    branchList: state.branch.branchList,
    branchById: state.branch.branchById,
    success: state.branch.success,
    error: state.branch.error,
    loading: state.branch.loading,
    branchListLoading: state.branch.branchListLoading,
    branchByIdLoading:state.branch.branchByIdLoading
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateBranch: (data: createBranchDTO) => {
      dispatch(createBranch(data));
    },
    GetBranchById: (id: string) => {
      dispatch(getBranchById(id));
    },
    UpdateBranch: (data: createBranchDTO) => {
      dispatch(updateBranch(data));
    },
    UpdateInstituteInLocal: (id: string) => {
      dispatch(appEntery(id));
    },
  };
};

export interface BranchProps extends StateProps, DispatchProps {}

export const BranchAddComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchEditor);
