import { Box } from "@mui/material";
import React from "react";
import leftPanalimg from "../../../../assets/images/leftimg.svg";
import logo from "../../../../assets/images/logo_white_new.svg";
import { EmailForm } from "./EmailForm";
import { OtpForm } from "./OtpForm";
import { Onboard } from "./Onboard";
import { ForgetPasswordProps } from "../container/ForgetPasswordContainer";
import {
  requsestOTPDTO,
  resetPasswordDTO,
  verifyOTPDTO,
} from "../service/types";
import styles from "./ForgetPassword.module.css";
import { resetForgotPassword } from "../service/forgetPasswordSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ToastNotification from "../../../../components/ToastNotification/ToastNotification";

export function ForgetPassword(props: ForgetPasswordProps) {
  const {
    success_requestOTP,
    success_verifyOTP,
    success_resetPassword,
    error_requestOTP,
    error_verifyOTP,
    error_resetPassword,
    loading_requestOTP,
    loading_verifyOTP,
    loading_resetPassword,
    RequestOTPforForgetPassword,
    VerifyOTPforForgetPassword,
    ResetPasswordforForgetPassword,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = React.useState<string>("");
  const [otp, setOTP] = React.useState<string>("");
  const [snackStateMessage, setSnackStateMessage] = React.useState<string>("");

  React.useEffect(() => {
    if (error_requestOTP || error_verifyOTP || error_resetPassword) {
      setSnackStateMessage(
        String(error_requestOTP || error_verifyOTP || error_resetPassword)
      );
      // setSnackState((snackState: SnackStateProp) => ({
      //   ...snackState,
      //   message: String(
      //     error_requestOTP || error_verifyOTP || error_resetPassword
      //   ),
      //   snackOpen: true,
      //   snackSeverity: "error",
      // }));
    }
  }, [error_requestOTP, error_resetPassword, error_verifyOTP]);

  React.useEffect(() => {
    if (success_requestOTP) {
      setSnackStateMessage("OTP sent successfully");
    }
  }, [success_requestOTP]);

  React.useEffect(() => {
    if (success_verifyOTP) {
      setSnackStateMessage("OTP Verification Successfull!");
    }
  }, [success_verifyOTP]);

  React.useEffect(() => {
    if (success_resetPassword) {
      setSnackStateMessage("Password is successfully set!");

      dispatch(resetForgotPassword());
      navigate("/");
    }
  }, [success_resetPassword]);

  const RequestOTP = React.useCallback(
    (data: requsestOTPDTO) => {
      RequestOTPforForgetPassword(data);
    },
    [RequestOTPforForgetPassword]
  );

  const VerifyOTP = React.useCallback(
    (data: verifyOTPDTO) => {
      setOTP(data?.otp);
      VerifyOTPforForgetPassword(data);
    },
    [VerifyOTPforForgetPassword]
  );

  const ResetPassword = React.useCallback(
    (data: resetPasswordDTO) => {
      ResetPasswordforForgetPassword(data);
    },
    [ResetPasswordforForgetPassword]
  );

  const resendOtp = React.useCallback(() => {
    RequestOTPforForgetPassword({ email: email, resend: true });
    // setSnackState((snackState: SnackStateProp) => ({
    //   ...snackState,
    //   message: "OTP sent successfully",
    //   snackOpen: true,
    //   snackSeverity: "success",
    // }));
  }, [RequestOTPforForgetPassword, email]);

  function checkStatus() {
    const isEmail =
      !success_requestOTP && !success_verifyOTP && !success_resetPassword;
    const isOTP =
      success_requestOTP &&
      !success_verifyOTP &&
      !success_resetPassword &&
      !error_requestOTP;
    const isSetpassword =
      success_requestOTP &&
      success_verifyOTP &&
      // !success_resetPassword &&
      !error_verifyOTP;
    const setThree = isSetpassword ? (
      <Onboard
        loading={loading_resetPassword}
        email={email}
        onSubmit={ResetPassword}
        otp={otp}
        success_resetPassword={success_resetPassword}
      />
    ) : (
      <span>ERROR</span>
    );
    const setTwo = isOTP ? (
      <OtpForm
        loading={loading_verifyOTP}
        email={email}
        onSubmit={VerifyOTP}
        // message={snackState.message}
        message={snackStateMessage}
        onResendClick={resendOtp}
      />
    ) : (
      setThree
    );
    return isEmail ? (
      <EmailForm
        loading={loading_requestOTP}
        setEmailState={setEmail}
        onSubmit={RequestOTP}
      />
    ) : (
      setTwo
    );
  }

  React.useEffect(() => {
    dispatch(resetForgotPassword());
  }, [location.pathname]);

  return (
    <div className={styles.page}>
      <ToastNotification />
      <Box
        className={styles.Header}
        // sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" } }}
        sx={{ display: "flex" }}
      >
        <img src={logo} className={styles.appLogo} alt="Logo" />
      </Box>

      <Box
        className={styles.leftPanal}
        style={{ border: "1px solid #142c44" }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            src={leftPanalimg}
            alt="bcksbckab"
            style={{ height: 384, width: 447 }}
          />

          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Poppins",
              fontWeight: "700",
              fontSize: { lg: "32px", md: "30px", sm: "26px", xs: "18px" },
              color: "#FFFFFF",
              mt: 2,
              textAlign: "center",
            }}
          >
            You’re in Trusted hands
          </Box>

          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: { lg: "14px", md: "12px", sm: "10px", xs: "10px" },
              color: "#FFFFFF",
              textAlign: "center",
              mt: 1,
              marginBottom: "30px",
              px: { sm: "60px", xl: "160px" },
            }}
          >
            Hundreds of Institutions rely on Nurture to help run their
            institutes.
            <br /> The right process always helps you scale.
          </Box>
        </Box>
      </Box>

      <div className={styles.rightPanal}>{checkStatus()}</div>
    </div>
  );
}
