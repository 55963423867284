import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import styles from "./ZapierDetails.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import zapierDetailPageImg from "../Assets/zapierImg.svg";
import interaction from "../Assets/interaction.svg";
import management from "../Assets/management.svg";
import call from "../Assets/call.svg";
import update from "../Assets/update.svg";
import contactInfo from "../Assets/contact-info.svg";
import zapierFav from "../Assets/zapierFav.svg";
import {
  createAppToken,
  resetAppTokenStatuses,
} from "../../service/IntegrationsSlice";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";

function ZapierDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const authUser = auth();
  const { appTokenSuccess, appTokenLoading } = useSelector(
    (state: any) => state.appIntegrations
  );

  const zapierAppToken = () => {
    const data = {
      instituteId: authUser?.institute[0]?.instituteId,
      appName: "ZAPIER",
      createdByName:
        authUser?.institute[0].firstName +
        " " +
        authUser?.institute[0].lastName,
      createdById: authUser?.institute[0]?.id,
    };
    dispatch<any>(createAppToken(data));
  };

  React.useEffect(() => {
    if (appTokenSuccess !== true && appTokenSuccess !== false) {
      dispatch<any>(resetAppTokenStatuses());
      navigate(`/app/Integrations/ZapierIntegration`);
    }
  }, [appTokenSuccess]);
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          padding: "14px 16px",
          background: "#EEEEEE",
          maxHeight: "calc(100vh - 65px)",
          position: "relative",
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            background: "#fff",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
            minHeight: "calc(100vh - 93px)",
            maxHeight: "calc(100vh - 93px)",
          }}
        >
          <div
            className={styles.ozonetelDetailsSplit}
            style={{
              minHeight: "calc(100vh - 93px)",
              maxHeight: "calc(100vh - 93px)",
            }}
          >
            <div
              style={{
                width: "calc(100% - 430px)",
              }}
            >
              <div className={styles.PageBackBtnWrap}>
                <button
                  className={styles.PageBackBtn}
                  onClick={() => navigate(`/app/Integrations`)}
                >
                  <ArrowBackIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "15PX",
                      color: "#667085",
                    }}
                  />
                  Back
                </button>
              </div>

              <div
                className={styles.ozonetelDetailsSplitLeftBody}
                style={{
                  maxHeight: "calc(100vh - 160px)",
                  overflowY: "auto",
                }}
              >
                <div>
                  <img src={zapierFav} alt="zapierFav" />
                </div>
                <Box className={styles.ozonetelHead}>Zapier</Box>
                <Box className={styles.ozonetelDescription}>
                  Zapier is essential for streamlining workflows through
                  automation and integration. By connecting various web
                  applications and services, Zapier enables users to automate
                  lead genration, saving time and reducing manual intervention. 
                </Box>
                <Box>
                  <img
                    src={zapierDetailPageImg}
                    alt="zapierDetailPageImg"
                    className={styles.zapierImage}
                  />
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: "capitalize", maxWidth: "150px" }}
                    fullWidth
                    // onClick={() =>
                    //   navigate(`/app/Integrations/ZapierIntegration`)
                    // }
                    onClick={zapierAppToken}
                    disabled={appTokenLoading}
                  >
                    {appTokenLoading ? (
                      <CircularProgress size={20} color={"inherit"} />
                    ) : (
                      "Let’s Start"
                    )}
                  </Button>
                </div>
              </div>
            </div>

            <div
              className={styles.ozonetelDetailsSplitRight}
              style={{
                width: "430px",
                maxHeight: "calc(100vh - 94px)",
                overflowY: "auto",
              }}
            >
              <div className={styles.ozonetelDetailsSplitRightHead}>
                Key Features:
              </div>
              <ul style={{ paddingLeft: "0px", listStyle: "none" }}>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={interaction}
                      alt="interaction"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>Automation</span>
                    <br />
                    Zapier allows users to automate repetitive tasks and
                    workflows by connecting different web applications and
                    services. This automation saves time and reduces the need
                    for manual intervention in various processes.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={management}
                      alt="management"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>Integration</span>
                    <br />
                    With Zapier, users can integrate a wide range of apps and
                    services, regardless of whether they natively support each
                    other. This enables seamless data flow between different
                    tools, enhancing efficiency and productivity.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={call}
                      alt="call"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>Accessibility</span>
                    <br />
                    Zapier's user-friendly interface makes automation accessible
                    to users with varying levels of technical expertise. This
                    democratization of automation empowers individuals and
                    businesses to streamline their workflows without requiring
                    extensive coding knowledge.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={update}
                      alt="update"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>Versatility</span>{" "}
                    <br />
                    Zapier supports thousands of integrations, covering a
                    diverse range of applications and services across different
                    industries. This versatility allows users to create custom
                    workflows tailored to their specific needs, from marketing
                    and sales to project management and customer support.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={contactInfo}
                      alt="contactInfo"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>Scalability</span>
                    <br />
                    Whether you're a freelancer, small business, or
                    enterprise-level organization, Zapier offers scalable
                    solutions to automate processes of any complexity. Users can
                    start with simple automation tasks and gradually scale up to
                    more sophisticated workflows as their needs evolve.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={contactInfo}
                      alt="contactInfo"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>Efficiency</span>
                    <br />
                    By automating routine tasks and eliminating manual data
                    entry, Zapier helps users optimize their workflows and
                    improve overall efficiency. This allows individuals and
                    teams to focus on more strategic tasks that require human
                    creativity and problem-solving abilities.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={contactInfo}
                      alt="contactInfo"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      Cost-Effectiveness
                    </span>
                    <br />
                    Zapier offers a range of pricing plans, including a free
                    tier with basic features, making it accessible to users with
                    different budget constraints.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ZapierDetails;
