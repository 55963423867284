import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import styles from "./EditContact.module.css";
import CountryCodePhoneInput from "../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";

function EditContact(props: any) {
  const {
    open,
    onClose,
    obj,
    setEditObj,
    onEditContact,
    courseList,
    selectedCourse,
    setSelectedCourse,
    dataWithoutError,
    setDataWithoutErrors,
    setDataWithErrors,
    dataWithError,
    isOpenEditDrawer,
    // setDataWithoutErrorForSearch,
  } = props;
  const dispatch = useDispatch();

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");

  const [validStateName, setValidStateName] = React.useState<boolean>(true);
  const setValidateStatusName = React.useCallback((val: boolean) => {
    setValidStateName(val);
  }, []);

  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const [validStatePhone, setValidStatePhone] = React.useState<boolean>(true);
  const onCloseDrawer = () => {
    setValidStateEmail(true);
    onClose();
  };
  const disabled =
    !validStateName ||
    obj?.name === "" ||
    selectedCourse === "" ||
    (obj?.email === "" && obj?.phoneNumber?.slice(2) === "");

  const handleNameChange = (e: any) => {
    setEditObj({ ...obj, name: e.target.value });
    const pattern = /^[a-zA-Z\s]+$/;
    if (pattern.test(e?.target?.value)) {
      setValidStateName(true);
    } else {
      setValidStateName(false);
    }
  };

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setSelectedCourse(value);
      } else {
        setSelectedCourse("");
      }
    },
    []
  );

  const onSubmit = () => {
    if (!obj.phoneNumber) {
      setValidStatePhone(false);
    }
    if (!obj?.name) {
      setValidStateName(false);
    }

    if (
      obj?.phoneNumber?.startsWith("971") &&
      obj?.phoneNumber?.length !== 12
    ) {
      setValidStatePhone(false);
      return;
    }

    // if (!obj.email) {
    //   setValidStateEmail(false);
    // }
    let emailReg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/;
    let phoneNumReg =
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;

    if (!obj?.phoneNumber?.slice(2) && !obj?.email) {
      dispatch(
        setToastNotification({
          message: String("Please enter either phone number or email"),
          type: "error",
          snackOpen: true,
        })
      );
    } else if (obj?.email && !emailReg?.test(obj?.email)) {
      dispatch(
        setToastNotification({
          message: String("Please enter valid email"),
          type: "error",
          snackOpen: true,
        })
      );
    } else if (
      obj?.phoneNumber?.slice(2) !== "" &&
      !phoneNumReg?.test(obj?.phoneNumber?.slice(2))
    ) {
      dispatch(
        setToastNotification({
          message: "Please enter valid phone number",
          type: "error",
          snackOpen: true,
        })
      );
    } else {
      const numberString = obj?.phoneNumber?.toString();
      const trimmedString =
        obj?.countryCode === "971"
          ? numberString.slice(3)
          : numberString.slice(2);
      const resultNumber = parseInt(trimmedString);
      if (obj?.validation?.length > 0) {
        const rowIndex = dataWithError?.findIndex(
          (row: any) => row?.id === obj?.id
        );
        if (rowIndex !== -1) {
          const rowToMOve = dataWithError?.splice(rowIndex, 1)[0];
          setDataWithoutErrors((prev: any) => [
            ...prev,
            {
              ...obj,
              course: selectedCourse?.courseName,
              validation: [],
              phoneNumber:
                resultNumber?.toString()?.length === 10 ||
                resultNumber?.toString()?.length === 9
                  ? resultNumber
                  : "",
            },
          ]);
        }
      } else {
        setDataWithoutErrors((prev: any) =>
          prev.map((item: any) =>
            item.id === obj?.id
              ? {
                  ...obj,
                  course: selectedCourse?.courseName,
                  phoneNumber:
                    resultNumber?.toString()?.length === 10 ||
                    resultNumber?.toString()?.length === 9
                      ? resultNumber
                      : "",
                }
              : item
          )
        );
      }
      isOpenEditDrawer(false);
    }
  };

  useEffect(() => {
    const pattern = /^[a-zA-Z\s]+$/;
    if (pattern.test(obj?.name)) {
      setValidStateName(true);
    } else {
      setValidStateName(false);
    }

    // const emailPattern = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/;

    // if (emailPattern?.test(obj?.email)) {
    //   setValidStateEmail(true);
    // } else {
    //   setValidStateEmail(false);
    // }

    if (
      obj?.phoneNumber?.slice(2) !== "" &&
      obj?.phoneNumber?.slice(2)?.length !== 10
    ) {
      setValidStatePhone(false);
    } else {
      setValidStatePhone(true);
    }
  }, [obj]);

  // const courses =
  //   courseList &&
  //   courseList?.map((element: any, index: number) => {
  //     if (element?.status === "ACTIVE") {
  //       return { value: element?.courseName, label: element?.courseName };
  //     }
  //   });

  return (
    <div>
      <Drawer
        className="filter_drawer"
        anchor="right"
        open={open}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: {
            width: 350,
            backgroundColor: "#ffff",
            zIndex: "999999",
          },
        }}
      >
        <button
          className={styles.filterCloeBtn}
          onClick={() => {
            onCloseDrawer();
          }}
        >
          x
        </button>
        <Box>
          <Box
            fontSize={"20px"}
            lineHeight={"24px"}
            fontWeight={500}
            color={"#142C44"}
            borderBottom={"1px solid #F4F4F4"}
            padding={3}
          >
            Edit
          </Box>
          <Box padding={"24px"}>
            <Box marginBottom={3}>
              <NewFormTextInput
                validate={true}
                setValidStatus={setValidateStatusName}
                label="Name"
                type="contactName"
                id={"name"}
                error={!validStateName}
                value={obj?.name}
                onChange={handleNameChange}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                autoComplete="true"
                require={true}
                inputProps={{ maxLength: 50 }}
              />
            </Box>
            <Box marginBottom={3}>
              <NewFormTextInput
                validate={true}
                setValidStatus={setValidateStatusEmail}
                label="Email Address"
                type={"email"}
                id="email"
                value={obj?.email}
                onChange={(e: any) => {
                  setShowErrMsz(true);
                  setEditObj({ ...obj, email: e.target.value.toLowerCase() });
                }}
                error={!validStateEmail}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                autoComplete="true"
                require={false}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Box>
            <Box marginBottom={3}>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: "#142C44",
                  fontWeight: "500",
                  marginBottom: "5px",
                  fontFamily: "Poppins",
                  display: "flex",
                }}
              >
                Phone Number
                {/* <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p> */}
              </InputLabel>
              <CountryCodePhoneInput
                value={obj?.phoneNumber}
                onChange={(event: any, country: any) => {
                  if (event !== "") {
                    setValidStatePhone(
                      isValidPhoneNumber(
                        event.replace(country.dialCode, ""),
                        country.countryCode.toUpperCase()
                      )
                    );
                  }
                  setEditObj({
                    ...obj,
                    phoneNumber: event,
                    contactCountryCode: country.dialCode,
                  });
                }}
                error={validStatePhone}
              />
            </Box>
            <Box>
              <InputLabel
                sx={{
                  fontSize: "14px",
                  color: "#142C44",
                  fontWeight: "500",
                  marginBottom: "5px",
                  fontFamily: "Poppins",
                  display: "flex",
                }}
              >
                Course Name
                <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>
              </InputLabel>
              <FormControl
                fullWidth
                variant="standard"
                sx={{ marginBottom: "12px" }}
              >
                {courseList && (
                  <Autocomplete
                    disablePortal
                    id="courseId"
                    value={selectedCourse}
                    options={courseList}
                    getOptionLabel={(option: any) => option?.courseName || ""}
                    fullWidth
                    onChange={onCourseChange}
                    disableClearable={selectedCourse !== "" ? false : true}
                    sx={{
                      border: "1px solid  #D4D4D8",
                      borderRadius: "6px",
                      textTransform: "capitalize",

                      "&& fieldset": {
                        border: "none",
                      },
                    }}
                    PaperComponent={(props) => (
                      <Paper
                        sx={{
                          background: "#FFF",
                          border: "1px solid #E4E4E7",
                          fontFamily: "poppins",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          color: "142C44",
                          fontWeight: "400",
                          padding: "0 4px !important",
                          margin: "0",
                          boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                          maxHeight: "200px",
                          // overflowY: "auto",
                          zIndex: 99999999,
                          width: "100%",
                        }}
                        {...props}
                      />
                    )}
                    componentsProps={{
                      popper: {
                        sx: {
                          zIndex: 99999999,
                          maxHeight: "200px",
                          // overflowY: "scroll",
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Select Course *"
                        variant="outlined"
                        {...params}
                        size="small"
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                          color: "#142C44",
                          "& .MuiInputLabel-root": {
                            fontSize: "13px",
                            background: "#fff",
                            padding: "0 5px",
                          },
                        }}
                      />
                    )}
                  />
                )}
              </FormControl>
            </Box>
          </Box>
          <Box padding={3} className={styles.buttonBottom}>
            <Button
              sx={{ textTransform: "capitalize", width: "100%" }}
              variant="contained"
              type="submit"
              disabled={disabled}
              onClick={onSubmit}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}

export default EditContact;
