import {
  Button,
  TextField,
  FormControl,
  Box,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import styles from "../DemoBooking.module.css";
import React, { useEffect } from "react";
import UserAssign from "../../../../../../components/UserAssign/UserAssign";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { BasicButtonGroup } from "../../../../../../components/BasicButtonGroup/BasicButtonGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuthUser } from "react-auth-kit";
import { timeZoneConverter } from "../../../../../../components/Formatter/Formatter";
import { getCombinedAssignees } from "../../../../../../components/CommonFunctions/CommonFunctions";

function AddNewDemo(props: any) {
  const {
    selectedLeadData,
    employees,
    setStage,
    demoDetails,
    setDemoDetails,
    demoTypes,
    setWay,
    setDateDisable,
    CreateBatch,
    selectedDate,
    batchCreateLoading,
    handleLastStageBack,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();

  let newstartDate: any = new Date(selectedDate).setHours(0, 0, 0, 0);
  let newEndDate: any = new Date(selectedDate).setHours(23, 59, 59, 0);

  const [demoBatch, setDemoBatch] = React.useState<any>({
    instituteName: "",
    instituteId: "",
    batchName: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    batchType: [
      {
        batchType: {
          courseTypeName: "",
          id: "",
        },
      },
    ],
    startDate: new Date(selectedDate).setHours(0, 0, 0, 0),
    endDate: new Date(selectedDate).setHours(23, 59, 59, 0),
    startTime: 0,
    endTime: 0,
    minimumSeats: "",
    maximumSeats: "",
    status: "ACTIVE",
    isFirstBatch: false,
    batchStatus: "UPCOMING",
    id: "",
    batchPrice: 0,
    coursePrice: 0,
    discountMetric: "",
    discount: 0,
    studentCount: 0,
    batchSchedule: [{ day: "", startTime: 0, endTime: 0 }],
    batchConductType: "DEMO_BATCH",
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
    createDemo: true,
  });

  const formattedDay = dayjs(selectedDate).format("ddd");

  const [newBatchRequest, setNewBatchRequest] = React.useState<any>([
    {
      day: "SUN",
      batchSessions: [
        {
          startTime: dayjs().unix(),
          endTime: dayjs().unix(),
          sessionName: "Demo",
        },
      ],
    },
    {
      day: "MON",
      batchSessions: [
        {
          startTime: dayjs().unix(),
          endTime: dayjs().unix(),
          sessionName: "Demo",
        },
      ],
    },
    {
      day: "TUE",
      batchSessions: [
        {
          startTime: dayjs().unix(),
          endTime: dayjs().unix(),
          sessionName: "Demo",
        },
      ],
    },
    {
      day: "WED",
      batchSessions: [
        {
          startTime: dayjs().unix(),
          endTime: dayjs().unix(),
          sessionName: "Demo",
        },
      ],
    },
    {
      day: "THU",
      batchSessions: [
        {
          startTime: dayjs().unix(),
          endTime: dayjs().unix(),
          sessionName: "Demo",
        },
      ],
    },
    {
      day: "FRI",
      batchSessions: [
        {
          startTime: dayjs().unix(),
          endTime: dayjs().unix(),
          sessionName: "Demo",
        },
      ],
    },
    {
      day: "SAT",
      batchSessions: [
        {
          startTime: dayjs().unix(),
          endTime: dayjs().unix(),
          sessionName: "Demo",
        },
      ],
    },
  ]);

  const [newBatchAssignee, setNewBatchAssignee] = React.useState<any>({
    assignedToId: "",
    assignedToName: "",
  });

  const [errorMszs, setErrorMszs] = React.useState<string>("");
  const [showAssigneeErrMsz, setShowAssigneeErrMsz] =
    React.useState<boolean>(false);
  const [timePastErrMsz, setTimePastErrMsz] = React.useState<boolean>(false);

  const handleChange = React.useCallback(
    (event: any) => {
      const updatedValue = event.target.value.trimStart();
      setDemoDetails({
        ...demoDetails,
        demoName: updatedValue,
        batchName: updatedValue,
      });
      setDemoBatch({
        ...demoBatch,
        [event.target.id]: updatedValue,
      });
      setValidStateDemoName(updatedValue !== "");
    },
    [demoDetails, demoBatch]
  );

  const handleLeadAssignee = React.useCallback(
    (leadId: any, user: any) => {
      if (user) {
        setDemoDetails({
          ...demoDetails,
          assignedToId: user?.id,
          assignedToName: user?.firstName + " " + user?.lastName,
          demoTiming: timeZoneConverter(newstartDate),
        });
        setNewBatchAssignee({
          ...newBatchAssignee,
          assignedToId: user?.id,
          assignedToName: user?.firstName + " " + user?.lastName,
        });
      }
    },

    [demoDetails, newBatchAssignee]
  );

  const [batchSessonObj, setBatchSessonObj] = React.useState<any>({
    startTime: dayjs().unix(),
    endTime: dayjs().unix(),
    sessionName: "Demo",
  });

  const handleTimeChange = React.useCallback(
    (event: any, key: any) => {
      const unixTime = event.unix();
      if (key === "demoStartTime") {
        setBatchSessonObj({
          ...batchSessonObj,
          startTime: unixTime,
        });
      } else if (key === "demoEndTime") {
        setBatchSessonObj({
          ...batchSessonObj,
          endTime: unixTime,
        });
      }

      setDemoDetails({
        ...demoDetails,
        [key]: unixTime,
      });

      setNewBatchRequest((prevBatchRequest: any) => {
        const updatedBatchRequest = [...prevBatchRequest]; // Create a copy of the original array

        // Find the index of the day that matches formattedDay
        const dayIndex = updatedBatchRequest.findIndex(
          (day) => day.day === formattedDay.toUpperCase()
        );

        if (dayIndex !== -1) {
          // Update the startTime or endTime based on the key
          if (key === "demoStartTime") {
            updatedBatchRequest[dayIndex].batchSessions[0].startTime =
              event.unix();
          } else if (key === "demoEndTime") {
            updatedBatchRequest[dayIndex].batchSessions[0].endTime =
              event.unix();
          }
        }

        return updatedBatchRequest; // Return the updated batch request
      });
    },
    [formattedDay, demoDetails]
  );

  const [selected, setSelected] = React.useState<any[]>([]);

  const handleSelected = React.useCallback(
    (value: never, index: any) => {
      if (selected.includes(value)) {
        setSelected((prevSelected) => prevSelected.filter((s) => s !== value));
      } else {
        setSelected([value]);
        setErrorMszs("");
      }
      setDemoDetails({
        ...demoDetails,
        demoType: selected.includes(value)
          ? ""
          : demoTypes[value].courseTypeName,
      });
      setDemoBatch({
        ...demoBatch,
        batchType: selected.includes(value)
          ? []
          : [
              {
                ...demoBatch.batchType[0],
                batchType: {
                  ...demoBatch.batchType[0].batchType,
                  courseTypeName: demoTypes[value].courseTypeName,
                  id: demoTypes[value].id,
                },
              },
            ],
      });
    },
    [selected, demoDetails, demoTypes, demoBatch]
  );

  const mappedData = demoTypes
    ? demoTypes?.map((item: any, index: number) => {
        return {
          label: item.courseTypeName,
          value: item.courseTypeName,
        };
      })
    : [];

  function getIndex(selectedLevel: any) {
    if (demoTypes)
      return demoTypes.findIndex((obj: any) => obj.id === selectedLevel.id);
  }

  React.useEffect(() => {
    if (selected.length === 0 && demoDetails && demoDetails.batchType)
      setSelected([getIndex(demoDetails.batchType)]);
  }, [setSelected, getIndex, demoDetails]);

  const [validStateDemoName, setValidStateDemoName] =
    React.useState<boolean>(true);

  const setValidateStatusDemoName = React.useCallback((val: boolean) => {
    setValidStateDemoName(val);
  }, []);

  const handleDemoSubmit = async (event: any) => {
    event.preventDefault();
    const foundDay = newBatchRequest.find(
      (item: any) => item.day === formattedDay.toUpperCase()
    );

    try {
      demoDetails.demoType === ""
        ? setErrorMszs("select atleast one")
        : setErrorMszs("");
      demoDetails.assignedToId === ""
        ? setShowAssigneeErrMsz(true)
        : setShowAssigneeErrMsz(false);

      demoDetails.demoEndTime <= demoDetails.demoStartTime
        ? setTimePastErrMsz(true)
        : setTimePastErrMsz(false);

      foundDay.batchSessions[0].endTime <= foundDay.batchSessions[0].startTime
        ? setTimePastErrMsz(true)
        : setTimePastErrMsz(false);

      !demoDetails.demoName && setValidStateDemoName(false);

      const updatedBatchRequest = newBatchRequest.map((item: any) => {
        if (item.day === foundDay.day) {
          // return item; // Keep batchSessions as is for the matching day
          const updatedBatchSessions = item.batchSessions.map(
            (session: any) => {
              const updatedStartTime = session.startTime;
              const updatedEndTime = session.endTime;

              return {
                ...session,
                startTime: updatedStartTime,
                endTime: updatedEndTime,
              };
            }
          );

          return {
            ...item,
            batchSessions: updatedBatchSessions,
          };
        } else {
          return {
            ...item,
            batchSessions: [], // Set batchSessions as empty for non-matching days
          };
        }
      });

      updatedBatchRequest &&
        demoDetails.assignedToId &&
        newBatchAssignee.assignedToId &&
        demoBatch.batchName &&
        demoDetails.demoType &&
        foundDay.batchSessions[0].endTime >
          foundDay.batchSessions[0].startTime &&
        CreateBatch({
          ...demoBatch,
          batchSchedule: updatedBatchRequest,
          demoRequest: {
            ...demoDetails,
            demoStartTime: demoDetails?.demoStartTime,
            demoEndTime: demoDetails?.demoEndTime,
          },
          assignees: [newBatchAssignee],
          startDate: timeZoneConverter(newstartDate),
          endDate: timeZoneConverter(newEndDate),
        });
    } catch (error) {}
  };
  const combinedEmployees = getCombinedAssignees(
    employees,
    selectedLeadData?.branchId
  );
  React.useEffect(() => {
    if (authUser && selectedLeadData) {
      const batchTypes = demoTypes?.filter(
        (item: any) => item?.courseTypeName?.toLowerCase() === "classroom"
      );
      setDemoBatch({
        ...demoBatch,
        instituteId: selectedLeadData?.instituteId,
        instituteName: selectedLeadData?.instituteName,
        courseId: selectedLeadData?.courseId,
        courseName: selectedLeadData?.courseName,
        branchId: selectedLeadData?.branchId,
        branchName: selectedLeadData?.branchName,
        createdById: authUser?.institute?.[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
        batchType: [{ batchType: batchTypes[0] }],
      });
      setDemoDetails({
        ...demoDetails,
        demoType: batchTypes[0]?.courseTypeName,
      });
    }
  }, [selectedLeadData]);

  useEffect(() => {
    setSelected([0]);
  }, []);

  return (
    <div className={styles.demoSelectedWrapper}>
      <Box
        sx={{
          padding: "10px 12px 0 12px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <button
          className={styles.BackBtn}
          onClick={() => {
            setStage(1);
            setWay();
            setDateDisable(false);
            handleLastStageBack();
          }}
        >
          <ArrowBackIcon
            sx={{
              marginRight: "8px",
              fontSize: "15PX",
              color: "#667085",
            }}
          />
          Back
        </button>
      </Box>
      <div className={styles.demoBookedWrapperChild}>
        <div className={styles.demoBookedTitle}>Add new Demo</div>

        <div className={styles.newDemoHead}>
          <div>
            <TextField
              sx={{ maxHeight: "40px", minHeight: "40px", width: "328px" }}
              placeholder="Demo Name"
              InputProps={{
                style: {
                  maxHeight: "40px",
                  minHeight: "40px",
                },
              }}
              id="batchName"
              onChange={handleChange}
              error={!validStateDemoName}
              value={demoBatch.batchName}
              inputProps={{
                maxLength: 100,
              }}
            />
            {!validStateDemoName && (
              <FormHelperText error={true}>
                {!validStateDemoName ? "Required Field" : ""}
              </FormHelperText>
            )}
          </div>
          <div>
            <UserAssign
              users={combinedEmployees}
              size="large"
              handleLeadAssignee={handleLeadAssignee}
              isForEnquiryModal={true}
              selectedAssignyId={
                demoDetails?.assignedToId ? demoDetails?.assignedToId : null
              }
            />
            {showAssigneeErrMsz && (
              <FormHelperText error={true}>
                {showAssigneeErrMsz ? "Select an assignee" : ""}
              </FormHelperText>
            )}
          </div>
        </div>

        <div className={styles.newDemoItemSplit}>
          <div className={styles.newDemoItemSplitLabel}>Demo Type</div>
          <div>
            <BasicButtonGroup
              // className={styles.batchType}
              buttonList={mappedData}
              handleSelected={handleSelected}
              selected={selected}
              errorMsg={errorMszs}
            />
          </div>
        </div>
        <div className={styles.newDemoItemSplit}>
          <div className={styles.newDemoItemSplitLabel}>Select Time </div>
          <div>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={styles.timeSlotWrap}>
                  <TimePicker
                    onChange={(event) => {
                      handleTimeChange(event, "demoStartTime");
                    }}
                    value={
                      demoDetails.demoStartTime !== 0
                        ? dayjs.unix(demoDetails.demoStartTime)
                        : dayjs()
                    }
                    sx={{
                      width: "157px",
                      maxHeight: "40px",
                      minHeight: "40px",
                      svg: { color: "#BDBFC3" },
                      input: { color: "#BDBFC3", fontWeight: "400" },
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                  <div>to</div>

                  <TimePicker
                    onChange={(event) => {
                      handleTimeChange(event, "demoEndTime");
                    }}
                    value={
                      demoDetails.demoEndTime !== 0
                        ? dayjs.unix(demoDetails.demoEndTime)
                        : dayjs()
                    }
                    sx={{
                      width: "157px",
                      maxHeight: "40px",
                      minHeight: "40px",
                      svg: { color: "#BDBFC3" },
                      input: { color: "#BDBFC3", fontWeight: "400" },
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </div>
              </LocalizationProvider>
            </FormControl>
            {timePastErrMsz && (
              <FormHelperText error={true}>
                {timePastErrMsz
                  ? "End time should be greater than start time"
                  : ""}
              </FormHelperText>
            )}
          </div>
        </div>
      </div>

      <div className={styles.existingDemoScreenBottom} style={{ gap: "5px" }}>
        <Button
          sx={{
            textTransform: "capitalize",
            color: "#9A9EA6",
            borderColor: "#D0D5DD",
            border: "1px solid",
            borderRadius: "4PX",
            ":hover": {
              color: "#9A9EA6",
              borderColor: "#D0D5DD",
              backgroundColor: "#9a9ea612",
            },
          }}
          variant="outlined"
          onClick={() => {
            setStage(1);
            setWay();
            setDateDisable(false);
            handleLastStageBack();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            fontSize: 14,
            textTransform: "capitalize",
            boxShadow: "none",
            height: "38px",
            "&.Mui-disabled": {
              backgroundColor: "transparent ",
              color: "#11C89B",
            },
          }}
          size={"small"}
          onClick={handleDemoSubmit}
        >
          {batchCreateLoading ? (
            <CircularProgress size={20} color={"inherit"} />
          ) : (
            "Confirm"
          )}
        </Button>
      </div>
    </div>
  );
}

export default AddNewDemo;
