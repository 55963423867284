import {
  Alert,
  Box,
  IconButton,
  Snackbar,
  SnackbarOrigin,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../../../assets/images/logo_white_new.svg";
import leftPanalimg from "../../../../assets/images/leftimg.svg";
import { EmailOtpVerification } from "./EmailOtpVerification";
import { InstituteIndividual } from "./InstututeIndividual";
import { Onboard } from "./Onboard";
import styles from "./Registration.module.css";
import RegistationIndividual from "./RegistrationIndividual";
import CloseIcon from "@mui/icons-material/Close";
import { createInstituteVerifyOTPDTO, setPasswordDTO } from "../service/types";
import { useLocation, useNavigate } from "react-router-dom";
import { ForgetPasswordOTPRequest } from "../../ForgetPassword/service/forgetPassword.request";
import Verified from "./Verified";
import DomainAndURLSetup from "./DomainAndURLSetup";
import checkCircle from "../../../../assets/images/check-circle.svg";
import ToastNotification from "../../../../components/ToastNotification/ToastNotification";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

export function Signup(props: any) {
  const [pageState, setPageState] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [status, setStatus] = React.useState<any>(2);
  const [verified, setVerified] = React.useState<any>(false);
  const [credentials, setCredentials] = React.useState<any>({
    email: "",
    password: "",
  });

  const {
    SignUpProps,
    CreateInstitute,
    error,
    success_createInstitute,
    error_createInstitute,
    loading_createInstitute,
    VerifyOTPforCreateInstitute,
    success_createInstitute_verifyOTP,
    error_createInstitute_verifyOTP,
    loading_createInstitute_verifyOTP,
    SetPasswordforInstitute,
    loading_createInstitute_setPassword,
    success_createInstitute_setPassword,
    error_createInstitute_setPassword,
    error_checkSubDomain,
    success_checkSubDomain,
    loading_checkSubDomain,
    userLogin,
    CheckSubDomain,
    GetTnc,
    tnc,
  } = props;

  const [instituteDetails, setInstituteDetails] = React.useState({
    instituteName: "",
    instituteContact: "",
    instituteEmail: "",
    isInstitute: true,
    website: "",
    status: "ACTIVE",
    courseCreated: false,
    batchCreated: false,
    branchCreated: false,
    teamAdded: false,
    firstName: "",
    lastName: "",
    middleName: "",
    subdomain: "",
  });

  const location = useLocation();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageStateFromQuery = params.get("pageState");
    const subdomainFromQuery = params.get("subdomain");
    const emailFromQuery = params.get("email");
    if (
      pageStateFromQuery === "5" &&
      subdomainFromQuery === "null" &&
      emailFromQuery
    ) {
      setPageState(5);
      setInstituteDetails({
        ...instituteDetails,
        instituteEmail: emailFromQuery,
      });
    }
  }, []);

  React.useEffect(() => {
    const lastPageState = localStorage.getItem("pageState");

    if (lastPageState === "2") {
      setPageState(2);
    }
  }, [pageState]);

  const [OTP, setOTP] = useState<string>("");
  const navigate = useNavigate();

  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "info",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="error"
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  React.useEffect(() => {
    if (
      error_createInstitute ||
      error_createInstitute_verifyOTP ||
      error_createInstitute_setPassword
    ) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          error_createInstitute ||
            error_createInstitute_verifyOTP ||
            error_createInstitute_setPassword
        ),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
  }, [
    error_createInstitute,
    error_createInstitute_verifyOTP,
    error_createInstitute_setPassword,
  ]);

  const VerifyOTP = React.useCallback(
    (data: createInstituteVerifyOTPDTO) => {
      VerifyOTPforCreateInstitute(data);
    },
    [VerifyOTPforCreateInstitute]
  );

  const verifyDomain = React.useCallback(
    (data: any) => {
      CheckSubDomain(data);
    },
    [CheckSubDomain]
  );

  const SetPassword = React.useCallback(
    (data: setPasswordDTO) => {
      SetPasswordforInstitute(data);
      setCredentials({ email: data.email, password: data.password });
    },
    [SetPasswordforInstitute]
  );

  const resendOtp = React.useCallback(() => {
    ForgetPasswordOTPRequest({
      email: instituteDetails.instituteEmail,
      resend: true,
    });
    // setSnackState((snackState: SnackStateProp) => ({
    //   ...snackState,
    //   message: "OTP sent successfully",
    //   snackOpen: true,
    //   snackSeverity: "success",
    // }));
  }, [instituteDetails.instituteEmail]);

  const onVerify = React.useCallback(
    (credentials: any) => {
      if (credentials.email && credentials.password !== "") {
        userLogin(credentials);
      } else {
        navigate(`/`);
      }
    },
    [userLogin]
  );

  React.useEffect(() => {
    if (success_createInstitute) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "OTP sent to your email address",
        snackOpen: true,
        snackSeverity: "success",
      }));
      setPageState(3);
      localStorage.setItem("pageState", String(3));
    }
  }, [success_createInstitute]);

  // React.useEffect(() => {
  //   if (success_checkSubDomain) {
  //     setSnackState((snackState: SnackStateProp) => ({
  //       ...snackState,
  //       message: "Sub Domain Verified",
  //       snackOpen: true,
  //       snackSeverity: "success",
  //     }));
  //     setVerified(true);
  //     localStorage.setItem("pageState", String(6));
  //   }
  // }, [success_checkSubDomain]);
  React.useEffect(() => {
    if (success_checkSubDomain) {
      // Showing the loader and related logic (if any) can be placed here before the setTimeout.

      const timeout = setTimeout(() => {
        setSnackState((snackState: SnackStateProp) => ({
          ...snackState,
          message: "Sub Domain Verified",
          snackOpen: true,
          snackSeverity: "success",
        }));
        setVerified(true);
        localStorage.setItem("pageState", String(6));
      }, 20000); // 20 seconds delay

      // Clean up to clear the timeout if the component unmounts.
      return () => clearTimeout(timeout);
    }
  }, [success_checkSubDomain]);

  React.useEffect(() => {
    if (success_createInstitute_verifyOTP) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "OTP Verification Successfull !",
        snackOpen: true,
        snackSeverity: "success",
      }));
      setPageState(4);
      localStorage.setItem("pageState", String(4));
    }
  }, [success_createInstitute_verifyOTP]);

  React.useEffect(() => {
    if (success_createInstitute_setPassword) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "Password is successfully set!",
        snackOpen: true,
        snackSeverity: "success",
      }));

      setPageState(5);
    }
  }, [success_createInstitute_setPassword, navigate]);

  React.useEffect(() => {
    const lastPage = localStorage.getItem("lastPage");
  }, []);

  React.useEffect(() => {
    GetTnc();
  }, [GetTnc]);

  return (
    <>
      {verified ? (
        <Verified credentials={credentials} onVerify={onVerify} />
      ) : (
        <div className={styles.page}>
          {/* <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackOpen}
            onClose={closeSnack}
            key={vertical + horizontal}
            sx={{
              zIndex: "99999999",
              borderRadius: "8PX",
              background:
                snackState?.snackSeverity === "success" ? "#DCFCE7" : "inherit",
              color:
                snackState?.snackSeverity === "success" ? "#14532D" : "inherit",
              fontSize: "14px",
            }}
          >
            <Alert
              severity={snackSeverity}
              action={action}
              icon={
                snackState?.snackSeverity === "success" ? (
                  <img src={checkCircle} alt="check circle" />
                ) : null
              }
            >
              {message}
            </Alert>
          </Snackbar> */}
          <ToastNotification />
          <Box
            className={styles.Header}
            sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" } }}
          >
            <img
              src={logo}
              className={styles.appLogo}
              height={33}
              width={145}
              alt="Logo"
            />
          </Box>
          <Box
            className={styles.leftPanal}
            style={{ border: "1px solid #142c44" }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                src={leftPanalimg}
                alt="bcksbckab"
                style={{ height: 384, width: 447 }}
              />

              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                  fontWeight: "700",
                  fontSize: { lg: "32px", md: "30px", sm: "26px", xs: "18px" },
                  color: "#FFFFFF",
                  lineHeight: "48px",
                  mt: 2,
                  ml: "4px",
                  mr: "4px",
                }}
              >
                You’re in trusted hands
              </Box>

              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: { lg: "14px", md: "12px", sm: "10px", xs: "10px" },
                  color: "#FFFFFF",
                  textAlign: "center",
                  mt: 1,
                  marginBottom: "30px",
                  ml: "5px",
                  mr: "5px",
                  px: { sm: "60px", xl: "160px" },
                }}
              >
                Nurture helps entrepreneurs scale their institutions with
                <br /> a proven process.
              </Box>
            </Box>
          </Box>

          <div className={styles.rightPanal}>
            {pageState === 1 ? (
              <InstituteIndividual
                setPageState={setPageState}
                setStatus={setStatus}
              />
            ) : pageState === 2 ? (
              <RegistationIndividual
                setPageState={setPageState}
                status={status}
                instituteDetails={instituteDetails}
                setInstituteDetails={setInstituteDetails}
                SignUpProps={SignUpProps}
                CreateInstitute={CreateInstitute}
                loading_createInstitute={loading_createInstitute}
                error={error}
                tnc={tnc}
              />
            ) : pageState === 3 ? (
              <EmailOtpVerification
                email={instituteDetails.instituteEmail}
                onSubmit={VerifyOTP}
                OTP={OTP}
                setOTP={setOTP}
                loading={loading_createInstitute_verifyOTP}
                setPageState={setPageState}
                message={snackState.message}
                success_createInstitute_verifyOTP={
                  success_createInstitute_verifyOTP
                }
                onResendClick={resendOtp}
              />
            ) : pageState === 4 ? (
              <Onboard
                setPageState={setPageState}
                loading={loading_createInstitute_setPassword}
                email={instituteDetails.instituteEmail}
                onSubmit={SetPassword}
                otp={OTP}
              />
            ) : pageState === 5 ? (
              <DomainAndURLSetup
                setPageState={setPageState}
                email={instituteDetails.instituteEmail}
                onSubmit={verifyDomain}
                loading={loading_checkSubDomain}
                success={success_checkSubDomain}
                message={error_checkSubDomain}
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}
