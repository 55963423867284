import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./SyncedDataTab.module.css";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import {
  Box,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Modal,
  FormGroup,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import greenIcon from "../Assets/green-dot.svg";
import greyIcon from "../Assets/grey-dot.svg";
import { useNavigate } from "react-router";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import dayjs from "dayjs";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import Checkbox from "@mui/material/Checkbox";
import { useAuthUser } from "react-auth-kit";
import {
  resetLeadCreateError,
  resetLeadCreateSuccess,
} from "../../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import modalStyles from "../Settings/EmailSettings/EmailSettings.module.css";
import InfiniteScroll from "react-infinite-scroll-component";
import PuffLoader from "react-spinners/PuffLoader";
import { GetMailEntriesByInstitute } from "../../service/inbound.request";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import {
  getMailEntriesByInstitute,
  resetDeleteEmailSuccess,
} from "../../service/inboundSlice";
import EmailIntegration from "../EmailIntegration/EmailIntegration";
import setting from "../../../../../assets/images/setting.svg";
import TableSkeleton from "../../../../../components/Skeletons/TableSkeleton";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import deleteIcon from "../../../../../assets/images/delete.svg";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import AddLeadPayment from "../../../Nurture/components/AddLeadPayment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function EmailData(props: any) {
  const {
    GetMailsEntriesByInstitute,
    GetMailConfigList,
    mailConfigsList,
    mailList,
    DeleteEmails,
    deleteEmailError,
    deleteEmailSuccess,
    success,
    ImapEmailSync,
    deleteEmailLoading,
    CreateEnquiry,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasEmailAccess = getSubDomainAccessStatus(
    "INBOUND",
    "EMAIL",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#667085",
      padding: hasEmailAccess === true ? "10px" : "19px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: hasEmailAccess === true ? "10px" : "14px",
    },
  }));

  const [page, setPage] = React.useState(1);
  const [senderSearchTerm, setSenderSearchTerm] = React.useState("");
  const [senderFilterTerm, setSenderFilterTerm] = React.useState("");
  const [newDate, setNewDate] = useState<any>(new Date());
  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const [filterStatus, setFilterStatus] = React.useState("");
  const [startDate, setStartDate] = React.useState<number | null>(null);
  const [startDateFilter, setStartDateFilter] = React.useState<number | null>(
    null
  );
  const [endDate, setEndDate] = React.useState<number | null>(null);
  const [endDateFilter, setEndDateFilter] = React.useState<number | null>(null);
  const [addFilter, setAddFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [items, setItems] = useState<any>();
  const containerRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  const leadSuccess = useSelector(
    (state: any) => state.nurture.leadCreateSuccess
  );
  const leadLoading = useSelector(
    (state: any) => state.nurture.leadCreateLoading
  );
  const [leadMail, setLeadMail] = useState<any>("");
  const [mailById, setMailById] = useState<any>();
  const [showAddLeadWideView, setAddLeadWideView] = useState(false);
  const { leadCreatedId } = useSelector((state: any) => state.nurture);

  React.useEffect(() => {
    if (leadSuccess !== true && leadSuccess !== false) {
      if (leadCreatedId === null || leadCreatedId === undefined) {
        setShowEnquiryModal(false);
      }
    }
  }, [leadSuccess, setShowEnquiryModal]);

  React.useEffect(() => {
    if (leadCreatedId && leadSuccess === "Converted lead is created.") {
      setAddLeadWideView(true);
      dispatch(
        setToastNotification({
          message: String("Please add the payment details for conversion"),
          type: "success",
          snackOpen: true,
        })
      );
    }
  }, [leadSuccess, setAddLeadWideView, leadCreatedId]);

  React.useEffect(() => {
    const regex = /^Congratulations! (.*) is a new Lead\.$/;
    if (regex.test(leadSuccess)) {
      const userFromLocalStorage = authUser?.institute[0];
      const data = {
        id: userFromLocalStorage?.instituteId,
        lastEvaluatedKey: "",
        limit: 15,
        lastEvaluatedSortKey: "",
      };

      dispatch<any>(getMailEntriesByInstitute(data));

      dispatch<any>(resetLeadCreateSuccess());
    }
  }, [leadSuccess]);

  const handleCheckboxToggle = (emailId: string, row: any) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(emailId)) {
        const updatedEmails = prevSelectedEmails.filter((id) => id !== emailId);
        setIsCheckboxChecked(updatedEmails.length > 0);
        return updatedEmails;
      } else {
        setIsCheckboxChecked(true);
        return [...prevSelectedEmails, emailId];
      }
    });
  };

  const extractEmail = useCallback((emailString: any) => {
    const emailRegex = /<([^>]+)>/;
    const newEmailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/;
    if (newEmailRegex.test(emailString)) {
      return emailString;
    } else {
      const matches = emailString?.match(emailRegex);
      const email = matches ? matches[1] : "";
      return email;
    }
  }, []);

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do YYYY, hh:mm A");
  }

  const uniqueArray = items?.returnData?.filter(
    (obj: any, index: any, self: any) =>
      index === self?.findIndex((el: any) => el?.id === obj?.id)
  );

  interface CheckedTypes {
    status: string;
  }

  const [checkedMailStatuses, setCheckedMailStatuses] = useState<
    CheckedTypes[]
  >([]);
  const [filterMailStatuses, setFilterMailStatuses] = useState<CheckedTypes[]>(
    []
  );

  const filteredMailList =
    items &&
    items?.returnData?.length > 0 &&
    uniqueArray?.filter((item: any) => {
      const emailString = item?.leadEmail;
      const emailRegex = /<([^>]+)>/;
      const matches = emailString?.match(emailRegex);
      const email = matches?.[1] || "";
      const searchTermMatch =
        email && email?.toLowerCase()?.indexOf(searchTerm.toLowerCase()) !== -1;
      const searchTermMatchSubject =
        item &&
        item.emailSubject?.toLowerCase()?.indexOf(searchTerm.toLowerCase()) !==
          -1;
      const senderSearchTermMatch = email
        ?.toLowerCase()
        ?.includes(senderFilterTerm?.toLowerCase());
      const statusMatch =
        filterStatus === "" || item.entryStatus === filterStatus;

      let dateRangeMatch = true;
      if (startDateFilter && endDateFilter) {
        const messageTimestamp = parseInt(item.messageTimestamp);
        dateRangeMatch =
          messageTimestamp >= startDateFilter &&
          messageTimestamp <= endDateFilter;
      }

      const emailStatusIsChecked =
        filterMailStatuses.length === 0 ||
        filterMailStatuses.some(
          (type) =>
            type.status.toLowerCase()?.replace(/_/g, " ") ===
            item.entryStatus?.toLowerCase()?.replace(/_/g, " ")
        );

      return (
        statusMatch &&
        emailStatusIsChecked &&
        senderSearchTermMatch &&
        dateRangeMatch &&
        (searchTermMatch || searchTermMatchSubject)
      );
    });
  const lastIndex = page * rowsPerPage;
  const firstIndex = lastIndex - rowsPerPage;

  const mailRecords =
    filteredMailList && filteredMailList?.slice(firstIndex, lastIndex);

  const noOfPages =
    filteredMailList && Math.ceil(filteredMailList?.length / rowsPerPage);

  const pageNumbers = [...Array(noOfPages)].map((_, index) => index + 1);

  const handleFilterMails = React.useCallback(
    (statusArray: any, senderSearch: any, startDate: any, endDate: any) => {
      // setFilterStatus(status);
      setSenderFilterTerm(senderSearch);
      setStartDateFilter(startDate);
      setEndDateFilter(endDate);
      setFilterMailStatuses(statusArray);
    },
    [setFilterStatus, setSenderFilterTerm, setStartDateFilter, setEndDateFilter]
  );

  const handleChangePage = (value: any) => {
    setPage(value - 1);
  };

  const clearSelectedStatus = () => {
    setSelectedStatus("");
  };

  const handleStatusSelect = (event: any) => {
    setSelectedStatus(event.target.value);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleSenderSearch = (event: any) => {
    setSenderSearchTerm(event.target.value);
  };

  const handleFilterApply = React.useCallback(() => {
    handleFilterMails(
      checkedMailStatuses,
      senderSearchTerm,
      startDate,
      endDate
    );
    if (
      checkedMailStatuses.length === 0 &&
      senderSearchTerm === "" &&
      startDate === null &&
      endDate === null
    ) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
  }, [
    handleFilterMails,
    selectedStatus,
    senderSearchTerm,
    startDate,
    endDate,
    setFilterApplied,
    setPage,
    checkedMailStatuses,
  ]);

  const handleFilterRemove = React.useCallback(() => {
    handleFilterMails([], "", null, null);
    setFilterApplied(false);
    setIsOpenFilter(false);
    handleResetClick();
  }, [handleFilterMails, setFilterApplied, setIsOpenFilter]);

  const handleResetClick = React.useCallback(() => {
    setSenderSearchTerm("");
    setSelectedStatus("");
    setStartDate(null);
    setEndDate(null);
    setAddFilter(false);
    setCheckedMailStatuses([]);
    setFilterMailStatuses([]);
    handleFilterMails([], "", null, null);
  }, []);

  const handleFilterClose = React.useCallback(() => {
    setCheckedMailStatuses(filterMailStatuses);
    setSenderSearchTerm(senderFilterTerm);
    setStartDate(startDateFilter);
    setEndDate(endDateFilter);
  }, [filterMailStatuses, senderFilterTerm, startDateFilter, endDateFilter]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      const dateUnix = date.unix();
      const dateMilliseconds = dateUnix * 1000;
      if (id === "startDate") {
        setStartDate(dateMilliseconds);
      } else if (id === "endDate") {
        setEndDate(dateMilliseconds);
      }
    }
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const data2: any = senderSearchTerm && senderSearchTerm;
  const data3: any =
    startDate && dayjs.unix(startDate / 1000).format("DD/MM/YYYY");
  const data4: any = endDate && dayjs.unix(endDate / 1000).format("DD/MM/YYYY");

  const filteredValue = (
    <>
      {data2 && <div className="filteredDiv">{data2}</div>}
      {data3 && <div className="filteredDiv">{data3}</div>}
      {data4 && <div className="filteredDiv">{data4}</div>}
      {filterMailStatuses?.length > 0 &&
        filterMailStatuses?.map((item) => (
          <div key={item.status} className="filteredDiv">
            {item.status.toLowerCase().replace(/_/g, " ")}
          </div>
        ))}
    </>
  );

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      items?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      items?.lastEvaluatedKeyReceived?.GSI3SK?.S
    );
    const id = userFromLocalStorage.instituteId;
    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      GetMailEntriesByInstitute(id, lastEvaluatedKey, 15, lastEvaluatedSortKey)
        ?.then((res: any) => {
          setHasMoreData(true);
          setItems((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  const handleDelete = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const mailData = filteredMailList.filter((d: any) => {
      return selectedEmails.includes(d?.id);
    });
    const mailDataLeadCheck = mailData?.map((ds: any) => {
      return ds?.courseId === true;
    });

    if (
      selectedEmails?.length <= 25 &&
      mailDataLeadCheck?.every((v: any) => v !== true)
    ) {
      const data = {
        instituteId:
          userFromLocalStorage?.instituteId &&
          userFromLocalStorage?.instituteId,
        emailEntryIds: selectedEmails,
      };

      DeleteEmails(data);
    } else {
      dispatch(
        setToastNotification({
          message:
            selectedEmails?.length <= 25
              ? String("You cannot Delete more than 25 emails")
              : String(
                  "You cannot Delete as this Email is Associated with Lead."
                ),
          type: "error",
          snackOpen: true,
        })
      );
    }
  };

  const handleFilterStatusChecked = (status: string) => {
    const statusToAdd: CheckedTypes = { status };

    if (
      checkedMailStatuses.some(
        (item) =>
          item.status.toLowerCase().replace(/_/g, " ") ===
          status.toLowerCase().replace(/_/g, " ")
      )
    ) {
      setCheckedMailStatuses(
        checkedMailStatuses.filter(
          (item) =>
            item.status.toLowerCase().replace(/_/g, " ") !==
            status.toLowerCase().replace(/_/g, " ")
        )
      );
    } else {
      setCheckedMailStatuses([...checkedMailStatuses, statusToAdd]);
    }
  };

  const clearCheckedStatuses = (status: string) => {
    setCheckedMailStatuses(
      checkedMailStatuses.filter(
        (item) =>
          item.status.toLowerCase().replace(/_/g, " ") !==
          status.toLowerCase().replace(/_/g, " ")
      )
    );
  };

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage) {
      GetMailConfigList(userFromLocalStorage.instituteId);
    }
  }, []);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";

    if (deleteEmailSuccess) {
      GetMailsEntriesByInstitute(
        userFromLocalStorage.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey
      );
    }
  }, [deleteEmailSuccess]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";

    if (userFromLocalStorage) {
      GetMailsEntriesByInstitute(
        userFromLocalStorage.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey
      );
    }
  }, [GetMailsEntriesByInstitute]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";

    if (userFromLocalStorage) {
      const interval = setInterval(() => {
        GetMailsEntriesByInstitute(
          userFromLocalStorage.instituteId,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey
        );
      }, 300000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [GetMailsEntriesByInstitute]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    const data = {
      instituteId: userFromLocalStorage && userFromLocalStorage?.id,
      emailId: "",
    };
    if (mailConfigsList && mailConfigsList[0]?.emailProvider === "IMAP") {
      setInterval(() => {
        ImapEmailSync(data);
      }, 60000);
    }
  }, []);

  useEffect(() => {
    setItems(mailList);
  }, [mailList]);

  useEffect(() => {
    if (
      items &&
      items?.lastEvaluatedKeyReceived &&
      items?.lastEvaluatedKeyReceived?.SK !== null &&
      searchTerm.length === 0 &&
      senderSearchTerm.length === 0 &&
      startDate === null &&
      endDate === null &&
      senderSearchTerm?.length === 0 &&
      filterMailStatuses?.length === 0 &&
      checkedMailStatuses?.length === 0
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [
    items,
    searchTerm,
    senderSearchTerm,
    startDate,
    endDate,
    filterMailStatuses,
    checkedMailStatuses,
  ]);

  useEffect(() => {
    dispatch(resetLeadCreateError());
  }, [resetLeadCreateError]);

  React.useEffect(() => {
    if (deleteEmailSuccess) {
      setOpenDeleteModal(false);
      setSelectedEmails([]);
      setIsCheckboxChecked(false);
      dispatch(resetDeleteEmailSuccess());
    }
  }, [deleteEmailSuccess]);

  const extractEmailFromText = (text: any) => {
    const emailRegex = /[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,})+/;
    const match = text?.match(emailRegex);
    return match ? match[0] : null;
  };

  return (
    <div
      style={{
        width: "100%",
        // padding: "30px 20px"
      }}
    >
      {mailConfigsList?.length === 0 || mailConfigsList === null ? (
        <EmailIntegration />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              padding: "30px 20px",
            }}
          >
            <div
              className={styles.funnelHeadSplit}
              style={{ justifyContent: "space-between" }}
            >
              <div className={styles.funnelHeadSplitTitle}>Email</div>
              <div className={styles.funnelHeadSplitRight}>
                {isCheckboxChecked ? (
                  <button
                    onClick={() => {
                      setOpenDeleteModal(true);
                    }}
                    style={{
                      cursor: "pointer",
                      border: "1px solid #D0D5DD",
                      padding: "4px",
                      borderRadius: "8px",
                      marginLeft: "10px",
                      height: "50px",
                      width: "50px",
                      display: "flex",
                      justifyContent: "center",
                      background: "#fff",
                      alignItems: "center",
                    }}
                  >
                    <img src={deleteIcon} width="20px" />
                  </button>
                ) : (
                  <>
                    <div className={styles.funnelHeadSplitSearch}>
                      <TextField
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearch}
                        inputProps={{
                          style: {
                            background: "#ffffff",
                            padding: "8px 12px",
                            width: "300px",
                          },
                        }}
                      />
                    </div>
                    <div
                      style={{
                        border: "1px solid #d0d5dd",
                        background: "#FFFFFF",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        height: "38px",
                      }}
                    >
                      <Tooltip
                        arrow
                        title={
                          hasEmailAccess === false ? "Oops! No permission." : ""
                        }
                        sx={{ width: "100%" }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                              },
                            },
                          },
                        }}
                      >
                        <span
                          style={{
                            width: "100%",
                            display: "block",
                            textAlign: "center",
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            paddingTop="7px"
                            paddingBottom="7px"
                            paddingLeft="7px"
                            onClick={() =>
                              hasEmailAccess
                                ? navigate("/app/inbound/Settings/Email")
                                : null
                            }
                            paddingRight="7px"
                          >
                            <img src={setting} alt="" />
                          </Box>
                        </span>
                      </Tooltip>
                    </div>
                    <FilterDrawer
                      handleFilterApply={handleFilterApply}
                      handleResetClick={handleResetClick}
                      activeFilter={filterApplied}
                      cancelClick={cancelClick}
                      closeSetFilter={handleFilterClose}
                      insidePopup={true}
                    >
                      <div
                        style={{
                          padding: "22px 32px",
                          overflowY: "auto",
                          maxHeight: "calc(100% - 110px)",
                        }}
                      >
                        <FilterChipsWrap>
                          {checkedMailStatuses.length > 0 &&
                            checkedMailStatuses.map((item) => (
                              <FilterChips
                                keyVal={item.status}
                                chipName={item.status
                                  .toLowerCase()
                                  .replace(/_/g, " ")}
                                clearValue={item.status}
                                closeFunction={clearCheckedStatuses}
                              />
                            ))}
                        </FilterChipsWrap>

                        <FilterTypeWrap>
                          <FilterLabel labelValue="Sender" />
                          <FilterSearchField
                            value={senderSearchTerm}
                            placeholder={"Search Sender"}
                            onChange={handleSenderSearch}
                          />
                        </FilterTypeWrap>

                        <FilterTypeWrap>
                          <FilterLabel labelValue="Priority" />
                          <FilterCheckBoxWrap scroll={false}>
                            <FormGroup>
                              {[
                                { status: "PENDING" },
                                { status: "NEW_ENQUIRY" },
                                { status: "CONTACTED" },
                                { status: "DEMO" },
                                { status: "NEGOTIATION" },
                                { status: "CONVERTED" },
                              ].map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.status}
                                  checked={checkedMailStatuses.some(
                                    (data) =>
                                      data.status
                                        .toLowerCase()
                                        .replace(/_/g, " ") ===
                                      item.status
                                        .toLowerCase()
                                        .replace(/_/g, " ")
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterStatusChecked(item.status)
                                  }
                                  label={item.status
                                    .toLowerCase()
                                    .replace(/_/g, " ")}
                                />
                              ))}
                            </FormGroup>
                          </FilterCheckBoxWrap>
                        </FilterTypeWrap>

                        <FilterTypeWrap>
                          <FilterLabel labelValue=" Date Range" />
                          <FilterCheckBoxWrap>
                            <Box
                              sx={{
                                marginTop: "5px",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  sx={{
                                    zIndex: "999999999 !important",
                                    paddingBottom: "12px",
                                  }}
                                  label="From"
                                  format="DD/MM/YYYY"
                                  onChange={(date) =>
                                    handleDateChange(date, "startDate")
                                  }
                                  value={
                                    startDate !== null
                                      ? dayjs.unix(startDate / 1000)
                                      : null
                                  }
                                />
                              </LocalizationProvider>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  sx={{ zIndex: "99999999 !important" }}
                                  label="To"
                                  format="DD/MM/YYYY"
                                  onChange={(date) =>
                                    handleDateChange(date, "endDate")
                                  }
                                  value={
                                    endDate !== null
                                      ? dayjs.unix(endDate / 1000)
                                      : null
                                  }
                                />
                              </LocalizationProvider>
                            </Box>
                          </FilterCheckBoxWrap>
                        </FilterTypeWrap>
                      </div>
                    </FilterDrawer>
                  </>
                )}
              </div>
            </div>
            <div>
              {items &&
              items?.returnData?.length > 0 &&
              filteredMailList &&
              filteredMailList?.length > 0 ? (
                <TableContainer
                  className={styles.emailTableContainer}
                  sx={{
                    minWidth: 700,
                    borderRadius: "8PX",
                    border: "1px solid  #EAECF0",
                  }}
                >
                  <InfiniteScroll
                    dataLength={
                      items && items?.returnData?.length
                        ? items?.returnData?.length
                        : mailList && mailList?.returnData?.length
                    }
                    next={handleNextPage}
                    hasMore={hasMoreData}
                    loader={
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PuffLoader color="#36d7b7" />
                      </Box>
                    }
                    // height={700}
                    height={"calc(100vh - 200px)"}
                  >
                    <Table
                      sx={{
                        minWidth: 700,
                      }}
                      aria-label="customized table"
                    >
                      <TableHead
                        className={styles.batchPopupHead}
                        sx={{
                          position: "sticky",
                          top: 0,
                          zIndex: openDeleteModal ? 1 : 999,
                        }}
                      >
                        <TableRow>
                          {hasEmailAccess === true && (
                            <StyledTableCell
                              className={styles.batchPopupHeadCell}
                            >
                              <Checkbox
                                sx={{
                                  strokeWidth: 1,
                                  color: "rgba(102, 112, 133, 0.30)",
                                }}
                                checked={
                                  selectedEmails?.length ===
                                  filteredMailList?.length
                                }
                                onChange={() => {
                                  if (
                                    selectedEmails?.length ===
                                    filteredMailList?.length
                                  ) {
                                    setSelectedEmails([]);
                                    setIsCheckboxChecked(false);
                                  } else {
                                    const allEmailIds = filteredMailList.map(
                                      (item: any) => {
                                        if (item?.entryStatus === "PENDING") {
                                          return item?.id;
                                        }
                                      }
                                    );
                                    setSelectedEmails(allEmailIds);
                                    setIsCheckboxChecked(true);
                                  }
                                }}
                              />
                            </StyledTableCell>
                          )}

                          <StyledTableCell
                            className={styles.batchPopupHeadCell}
                          >
                            {/* # */}
                          </StyledTableCell>
                          <StyledTableCell
                            className={styles.batchPopupHeadCell}
                            style={{ width: "20%" }}
                          >
                            Email Address
                          </StyledTableCell>

                          <StyledTableCell
                            className={styles.batchPopupHeadCell}
                            align="left"
                            sx={{ width: "20%" }}
                          >
                            Subject
                          </StyledTableCell>
                          <StyledTableCell
                            className={styles.batchPopupHeadCell}
                            align="left"
                            style={{ width: "20%", fontSize: "13px" }}
                          >
                            Status
                          </StyledTableCell>
                          <StyledTableCell
                            className={styles.batchPopupHeadCell}
                            align="left"
                            style={{ width: "20%", fontSize: "13px" }}
                          >
                            Lead Name
                          </StyledTableCell>
                          <StyledTableCell
                            className={styles.batchPopupHeadCell}
                            align="left"
                            style={{ width: "30%" }}
                          >
                            Date & Time
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {mailList &&
                          mailList?.returnData?.length > 0 &&
                          filteredMailList &&
                          filteredMailList?.map((row: any, index: any) => (
                            <StyledTableRow
                              key={row.id}
                              className={styles.batchPopupColumnCells}
                              sx={{ cursor: "pointer" }}
                              ref={containerRef}
                            >
                              {hasEmailAccess === true && (
                                <StyledTableCell component="th" scope="row">
                                  <Checkbox
                                    sx={{
                                      strokeWidth: 1,
                                      color: "rgba(102, 112, 133, 0.30)",
                                    }}
                                    checked={selectedEmails?.includes(row.id)}
                                    disabled={row.entryStatus !== "PENDING"}
                                    onChange={() =>
                                      handleCheckboxToggle(row.id, row)
                                    }
                                  />
                                </StyledTableCell>
                              )}

                              <StyledTableCell component="th" scope="row">
                                <img
                                  src={
                                    row.entryStatus === "PENDING"
                                      ? greenIcon
                                      : greyIcon
                                  }
                                  alt=""
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                sx={{
                                  color:
                                    row.entryStatus === "PENDING"
                                      ? "#142C44"
                                      : "#667085",
                                  fontWeight:
                                    row.entryStatus === "PENDING"
                                      ? "500"
                                      : "400",
                                  width: "20%",
                                }}
                                onClick={() => {
                                  if (row?.leadId) {
                                    navigate(`EmailDetail/${row && row.id}`);
                                  } else {
                                    const emailVal = extractEmailFromText(
                                      row?.leadEmail
                                    );
                                    if (emailVal) {
                                      setLeadMail(emailVal);
                                    }
                                    setMailById(row);
                                    setShowEnquiryModal(true);
                                  }
                                }}
                              >
                                {extractEmail(row.leadEmail)}
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                sx={{
                                  color:
                                    row.entryStatus === "PENDING"
                                      ? "#142C44"
                                      : "#667085",
                                  fontWeight:
                                    row.entryStatus === "PENDING"
                                      ? "500"
                                      : "400",
                                  width: "20%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                  }}
                                >
                                  {row.emailSubject}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                sx={{
                                  color:
                                    row.entryStatus === "PENDING"
                                      ? "red"
                                      : "#344054",
                                  textTransform: "capitalize",
                                  width: "20%",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      row.entryStatus === "PENDING"
                                        ? "rgba(211, 47, 47, 0.07)"
                                        : "#F2F4F7",
                                    padding: "8px 8px",
                                    borderRadius: "16PX",
                                    textAlign: "center",
                                    width: "fit-content",
                                    fontSize: "12px",
                                  }}
                                >
                                  {row.entryStatus
                                    ?.toLowerCase()
                                    ?.replace(/_/g, " ")}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                sx={{
                                  color:
                                    row.entryStatus === "PENDING"
                                      ? "#142C44"
                                      : "#667085",
                                  fontWeight:
                                    row.entryStatus === "PENDING"
                                      ? "500"
                                      : "400",
                                  fontSize: "13px !important",
                                  width: "20%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    overflow: "hidden",
                                    WebkitBoxOrient: "vertical",
                                    fontSize: "12px",
                                  }}
                                >
                                  {row?.leadName ? row?.leadName : "-"}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                align="left"
                                sx={{
                                  color: "#667085",
                                  width: "20%",
                                  fontSize: "12px !important",
                                }}
                              >
                                {getFormattedDate(
                                  parseInt(row.messageTimestamp)
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              ) : (
                searchTerm?.length === 0 &&
                checkedMailStatuses.length === 0 &&
                senderSearchTerm.length === 0 &&
                startDate === null &&
                endDate === null && <TableSkeleton />
              )}
            </div>
          </div>
        </>
      )}

      {filteredMailList && filteredMailList?.length === 0 ? (
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            textTransform: "capitalize",
            color: " #667085",
            display: "flex",
            alignItems: "center",
            gap: "2px",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <div style={{ color: " #142C44" }}>No results found.</div>
          Try another search
        </div>
      ) : (
        <></>
      )}
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={handleFilterRemove}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "57%",
            left: "58%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            borderRadius: "5px",
            p: 3,
          }}
        >
          <div>
            <div className={modalStyles.editFormPopupTitle}>Remove Gmail </div>
            <div className={modalStyles.editFormPopupContent}>
              Are you sure you want to delete this email?
            </div>
            <div className={modalStyles.editFormPopupBtnsSplit}>
              <button
                className={modalStyles.editFormPopupCancelBtn}
                onClick={() => {
                  setOpenDeleteModal(false);
                  setSelectedEmails([]);
                  setIsCheckboxChecked(false);
                }}
              >
                cancel
              </button>
              <button
                className={modalStyles.editFormPopupRemoveBtn}
                onClick={() => handleDelete()}
                disabled={deleteEmailLoading}
              >
                {deleteEmailLoading ? (
                  <CircularProgress size={25} color={"inherit"} />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      <AddLeadPayment
        show={showEnquiryModal}
        selectedValue={mailById && leadMail}
        messageId={mailById && mailById.id}
        onHide={() => {
          setShowEnquiryModal(false);
        }}
        nurtureAddEnquiry={CreateEnquiry}
        isEmailDetail={false}
        leadCreateSuccess={leadSuccess && leadSuccess}
        isSourceWidth={true}
        leadCreateLoading={leadLoading}
        page={"EMAIL"}
        showAddLeadWideView={showAddLeadWideView}
        setAddLeadWideView={setAddLeadWideView}
        leadCreatedId={leadCreatedId}
      />
    </div>
  );
}

export default EmailData;
