import React, { useEffect, useState } from "react";
import styles from "./BillingHistory.module.css";
import {
  TableContainer,
  Table,
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  PaginationItem,
  Tooltip,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import PuffLoader from "react-spinners/PuffLoader";
import { GetPaymentHistoryByPagination } from "../../service/Billing.request";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

function BillingHistory(props: any) {
  const { paymentHistory, paymentInvoice, GetPaymentInvoice, Invoicesuccess } =
    props;
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasBillingAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (value: any) => {
    setPage(value - 1);
  };

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(false);
  const [items, setItems] = useState<any>();

  useEffect(() => {
    setItems(paymentHistory);
  }, []);

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      items?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      items?.lastEvaluatedKeyReceived?.GSI1SK?.S
    );
    const id = userFromLocalStorage.instituteId;
    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      GetPaymentHistoryByPagination(
        id,
        lastEvaluatedKey,
        10,
        lastEvaluatedSortKey
      )
        .then((res: any) => {
          setHasMoreData(true);
          setItems((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],

            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  useEffect(() => {
    if (
      items &&
      items?.lastEvaluatedKeyReceived &&
      items?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [items]);

  const uniqueArray = items?.returnData?.filter(
    (obj: any, index: any, self: any) =>
      index === self?.findIndex((el: any) => el?.id === obj?.id)
  );

  const handleInvoiceDownload = React.useCallback((paymentId: string) => {
    if (paymentId) {
      GetPaymentInvoice(paymentId);
    }
  }, []);

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do Y,");
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 225px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div className={styles.billingHistoryHead}>Billing History</div>
        <div
          style={{
            margin: "10px 0px",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <TableContainer
            sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
          >
            <InfiniteScroll
              dataLength={
                items && items?.returnData?.length
                  ? items?.returnData?.length
                  : paymentHistory && paymentHistory?.returnData?.length
              }
              next={handleNextPage}
              hasMore={hasMoreData}
              loader={
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PuffLoader color="#36d7b7" />
                </Box>
              }
              height={470}
            >
              <Table className={styles.billingTable}>
                <TableHead className={styles.billingTableHead}>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085",
                      }}
                    >
                      Invoice Number
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#667085",
                      }}
                    >
                      Invoice
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentHistory?.returnData?.length > 0 &&
                    items?.returnData?.map((column: any) => (
                      <TableRow className={styles.billingHistoryTableRow}>
                        <TableCell
                          sx={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#667085",
                          }}
                        >
                          {moment(column.createdDate).format("MMM DD YYYY")}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#667085",
                          }}
                        >
                          {/* {column.invoiceNumber ? column.invoiceNumber : "-"} */}
                          {column?.invoiceNumber
                            ? column.invoiceNumber
                            : column?.proformaInvoiceNumber
                            ? column?.proformaInvoiceNumber
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#667085",
                          }}
                        >
                          <span className={styles.rupee}>₹</span>{" "}
                          {column?.totalCost}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "14px",
                            color:
                              column.status === "Fully Paid"
                                ? "#12BD93"
                                : "#EC3939",
                            fontWeight:
                              column.status === "Fully Paid" ? "500" : "400",
                          }}
                        >
                          {column.status}
                        </TableCell>
                        <TableCell className={styles.invoice}>
                          {column.invoiceNumber ||
                          column?.proformaInvoiceNumber ? (
                            <Tooltip
                              arrow
                              title={
                                hasBillingAccess === false
                                  ? "Oops! No permission."
                                  : ""
                              }
                              PopperProps={{ style: { zIndex: 9999999 } }}
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#101828",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    padding: "8px 12px",
                                    "& .MuiTooltip-arrow": {
                                      color: "#101828",
                                    },
                                  },
                                },
                              }}
                            >
                              <span>
                                <div
                                  className={styles.downloadBtn}
                                  onClick={() =>
                                    hasBillingAccess === true
                                      ? handleInvoiceDownload(column.id)
                                      : null
                                  }
                                >
                                  Download
                                </div>
                              </span>
                            </Tooltip>
                          ) : (
                            <span className={styles.empty}>-</span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </div>
      </div>

      {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={Math.ceil(columns.length / rowsPerPage)}
          color="primary"
          variant="outlined"
          shape="rounded"
          renderItem={(column) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...column}
            />
          )}
          onChange={(e: any, page: any) => handleChangePage(page)}
          sx={{
            color: "#667085",

            ".MuiButtonBase-root.MuiPaginationItem-root": {
              color: "#667085",
              width: "32px",
              height: "32px",
              borderRadius: "6px",
            },
            ".MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {
              color: "#11C89B",
            },

            "& .MuiButtonBase-root.MuiPaginationItem-root ": {
              border: "none",
            },
          }}
        />
      </Box> */}
    </div>
  );
}

export default BillingHistory;
