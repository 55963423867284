import React from "react";
import { Box } from "@mui/material";
import styles from "./components/InstituteProfile.module.css";

const customModalStyle = {
  position: "absolute" as "absolute",
  top: "365px",
  left: "760px",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 644,
    xl: 644,
    md: 644,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "8px",
  p: 3.5,
};

const CustomDescriptionModal = (props: any) => {
  const { open, onClose, children } = props;
  if (!open) return null;

  return (
    <div className={styles.customModalOverlay}>
      <div className={styles.customModal}>
        <Box sx={customModalStyle}>{children}</Box>
      </div>
    </div>
  );
};

export default CustomDescriptionModal;
