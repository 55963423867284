import React from "react";
import styles from "./SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function UserProfileBoxSkeleton() {
  return (
    <div className={styles.userProfileSkeletonWrap}>
      <div className={styles.userProfileSkeletonHeadWrap}>
        <div>
          <Skeleton
            animation="wave"
            variant="circular"
            width="80px"
            height="80px"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Skeleton
            animation="wave"
            variant="text"
            width="120px"
            height="25px"
            style={{
              borderRadius: "2px",
              marginBottom: "10px",
              display: "block",
            }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="120px"
            height="15px"
            style={{
              borderRadius: "2px",
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "40px", padding: "20px 30px" }}>
        <div>
          <Skeleton
            animation="wave"
            variant="text"
            width="120px"
            height="25px"
            style={{
              borderRadius: "2px",
              marginBottom: "10px",
              display: "block",
            }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="120px"
            height="15px"
            style={{
              borderRadius: "2px",
            }}
          />
        </div>
        <div>
          <Skeleton
            animation="wave"
            variant="text"
            width="120px"
            height="25px"
            style={{
              borderRadius: "2px",
              marginBottom: "10px",
              display: "block",
            }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="120px"
            height="15px"
            style={{
              borderRadius: "2px",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default UserProfileBoxSkeleton;
