import React from "react";
import styles from "./SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function SmallFormPopupSkeleton() {
  return (
    <div className={styles.smallFormPopupSkeletonWrap}>
      <div className={styles.smallFormPopupHead}>
        <Skeleton animation="wave" variant="text" width="100px" height={25} />
      </div>
      <div className={styles.smallFormPopupHeadSplit}>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>
      <div className={styles.smallFormPopupHeadItemWrap}>
        <div className={styles.smallFormPopupHeadItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>
      <div className={styles.smallFormPopupHeadSplit}>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>
    </div>
  );
}

export default SmallFormPopupSkeleton;
