import React, { useState } from "react";
import { Box, IconButton, Popover, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from "./ZapierDetails.module.css";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import help from "../Assets/help.svg";
import UninstallOzontelPopup from "../OzonetelDetails/DeletePopup/UninstallOzontelPopup";
import zapierLogo from "../Assets/zapierlogo.svg";
import installed from "../Assets/installed.svg";
import { useSelector } from "react-redux";
import {
  resetUninstallStatuses,
  uninstallTokenGeneratedApp,
} from "../../service/IntegrationsSlice";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { getInstituteById } from "../../../Manage/InstituteProfile/service/instituteSlice";
import { useNavigate } from "react-router-dom";

function ZapierInstalledStage(props: any) {
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();

  const { uninstallLoading, appTokenData, uninstallSuccess } = useSelector(
    (state: any) => state.appIntegrations
  );
  const [showUninstallPopup, setShowUninstallPopup] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isApiCopied, setIsApiCopied] = useState<boolean>(false);
  const [tooltipTimeoutApi, setTooltipTimeoutApi] =
    useState<NodeJS.Timeout | null>(null);
  const [showCallbackUrlPopup, setShowCallbackUrlPopup] =
    useState<boolean>(false);

  const handleUninstallPopupOpen = () => {
    setShowUninstallPopup(true);
  };
  const handleUninstallPopupClose = () => {
    setShowUninstallPopup(false);
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleUninstallPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const zapierAppApiKey = appTokenData ? appTokenData?.token : "";
  const zapierWebhookUrl = process.env.REACT_APP_ZAPIRE_WEBHOOK_URL || "";
  const handleCallbackUrlHelpClick = () => {
    setShowCallbackUrlPopup(true);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(zapierWebhookUrl);
    setIsCopied(true);

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    setTooltipTimeout(timeout);
  };

  const handleCopyClickApi = () => {
    navigator.clipboard.writeText(zapierAppApiKey);
    setIsApiCopied(true);

    const timeout = setTimeout(() => {
      setIsApiCopied(false);
    }, 1000);

    setTooltipTimeoutApi(timeout);
  };

  const appUninstall = () => {
    if (authUser) {
      const data = {
        id: authUser?.institute?.[0]?.instituteId,
        appName: "ZAPIER",
      };
      dispatch<any>(uninstallTokenGeneratedApp(data));
    }
  };

  React.useEffect(() => {
    return () => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, [tooltipTimeout]);

  React.useEffect(() => {
    return () => {
      if (tooltipTimeoutApi) {
        clearTimeout(tooltipTimeoutApi);
      }
    };
  }, [tooltipTimeoutApi]);

  React.useEffect(() => {
    if (uninstallSuccess !== true && uninstallSuccess !== false) {
      setShowUninstallPopup(false);
      dispatch<any>(getInstituteById(authUser?.institute?.[0]?.instituteId));
      dispatch(resetUninstallStatuses());
      navigate(`/app/Integrations`);
    }
  }, [uninstallSuccess]);

  return (
    <div
      style={{
        borderRadius: "6px",
        border: "1px solid #e3e3e3",
        background: "#fff",
        marginBottom: "19px",
      }}
    >
      <Box
        style={{
          display: "flex",
          borderBottom: "1px solid #E3E3E3",
          padding: "24px 24px 22px 24px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ display: "flex" }}>
            <img src={zapierLogo} alt="zapierLogo" />
          </div>
          <div style={{ display: "flex", marginTop: "4PX" }}>
            <img src={installed} alt="zapierLogo" />
          </div>
        </div>

        <IconButton
          aria-label="menu"
          id="long-button"
          onClick={toggleUninstallPopup}
        >
          <MoreVertIcon />
        </IconButton>

        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.10)",
              borderRadius: "4PX",
              border: "1px solid #F2F2F2",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: 5,
            horizontal: 25,
          }}
        >
          <MenuItem
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "21px",
              color: "#EC3939",
              padding: "10px 18px",
            }}
            onClick={() => {
              handleClose();
              handleUninstallPopupOpen();
            }}
          >
            Uninstall
          </MenuItem>
        </Popover>
      </Box>
      <Box style={{ padding: "24px" }}>
        <div className={styles.zapierAccountDetailsWrap}>
          <div className={styles.zapierAccountDetailsItem}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className={styles.zapierDetailsItemHeading}
            >
              Webhook URL
              {/* <div onClick={handleCallbackUrlHelpClick}>
                <img src={help} alt="help" style={{ cursor: "pointer" }} />
              </div> */}
            </div>

            <div style={{ display: "flex", gap: "4px" }}>
              <div className={styles.zapierDetailsItemContent}>
                {zapierWebhookUrl}
              </div>
              <div
                style={{ cursor: "pointer", position: "relative" }}
                onClick={handleCopyClick}
              >
                <ContentCopyRoundedIcon
                  sx={{ color: "#667085" }}
                  fontSize="small"
                />
                {isCopied && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "18px",
                      left: "-58px",
                      background: "rgb(122 122 122 / 70%)",
                      color: "#fff",
                      padding: "6px 8px",
                      borderRadius: "3px",
                      fontSize: "11px",
                    }}
                  >
                    Copied
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={styles.zapierAccountDetailsItem}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className={styles.zapierDetailsItemHeading}
            >
              API Key
            </div>

            <div style={{ display: "flex", gap: "4px" }}>
              <div className={styles.zapierDetailsItemContent}>
                {zapierAppApiKey}
              </div>
              <div
                style={{ cursor: "pointer", position: "relative" }}
                onClick={handleCopyClickApi}
              >
                <ContentCopyRoundedIcon
                  sx={{ color: "#667085" }}
                  fontSize="small"
                />
                {isApiCopied && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "18px",
                      left: "-58px",
                      background: "rgb(122 122 122 / 70%)",
                      color: "#fff",
                      padding: "6px 8px",
                      borderRadius: "3px",
                      fontSize: "11px",
                    }}
                  >
                    Copied
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <UninstallOzontelPopup
          isOpen={showUninstallPopup}
          onClose={handleUninstallPopupClose}
          appName="Zapier"
          yesBtnClick={appUninstall}
          loader={uninstallLoading}
        />
      </Box>
    </div>
  );
}

export default ZapierInstalledStage;
