import React, { useEffect, useState } from "react";
import IconDropdown from "../../../../../../components/Dropdowns/IconDropdown";
import checkCircle from "../../../../../../assets/images/payCheckCircle.svg";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import {
  editRecordPayment,
  resetCancelPaymentStatuses,
  sendPaymentLink,
} from "../../../service/nurtureSlice";
import CancelPopup from "../../../../../../components/CommonPopups/CancelPopup";
import ResendPaymentLinkPopup from "../../PaymentLinkPopup/ResendPaymentLinkPopup";
import { useSelector } from "react-redux";

function RandomPayResend(props: any) {
  const { leadData, selectedPay, hasNurturePay, filteredRandomPayRequests } =
    props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const {
    sendPaymentLinkSuccess,
    recordPaymentUpdateSuccess,
    recordPaymentUpdateLoading,
  } = useSelector((state: any) => state.nurture);
  const requestedPaymentData = filteredRandomPayRequests?.find(
    (item: any) => item?.randomPaymentId === selectedPay?.id
  );
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const [resendPopup, setResendPopup] = useState<boolean>(false);
  const openCancelPopup = () => {
    setCancelPopup(true);
  };
  const closeCancelPopup = () => {
    setCancelPopup(false);
  };

  const openResendPopup = () => {
    setResendPopup(true);
  };
  const closeResendPopup = () => {
    setResendPopup(false);
  };

  const cancelConfirm = () => {
    dispatch<any>(
      editRecordPayment({
        ...requestedPaymentData,
        paymentStatus: "CANCELLED",
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      })
    );
  };

  const resendConfirm = () => {
    const body = {
      leadId: leadData?.id,
      contact: leadData?.studentContact,
      paymentType: "RANDOM_PAYMENT",
      amount: selectedPay?.amount,
      subMerchantId: "",
      module: "ENQUIRY",
      email: leadData?.studentEmail,
      randomPaymentId: selectedPay?.id,
      actionToPerform: hasNurturePay
        ? "SEND_PAYMENT_LINK"
        : "SEND_REMAINDER_EMAIL",
      paymentStatus: "REQUEST_RESEND",
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      emiId: "",
    };

    if (body) {
      dispatch<any>(sendPaymentLink(body));
    }
  };

  useEffect(() => {
    if (
      recordPaymentUpdateSuccess !== true &&
      recordPaymentUpdateSuccess !== false
    ) {
      setCancelPopup(false);
      dispatch(resetCancelPaymentStatuses());
    }
  }, [recordPaymentUpdateSuccess]);

  React.useEffect(() => {
    if (sendPaymentLinkSuccess !== true && sendPaymentLinkSuccess !== false) {
      setResendPopup(false);
    }
  }, [sendPaymentLinkSuccess]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",

        justifyContent: "right",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          width: "216px",
          maxWidth: "216px",
          borderRadius: "4PX",
          background: "rgba(17, 200, 155, 0.10)",
          padding: "7px 14PX",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <img src={checkCircle} alt="checkCircle" />
        </div>
        <div
          style={{
            color: "#11C89B",
            fontSize: "13px",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          Payment link sent
        </div>
      </div>
      <div>
        <IconDropdown
          options={[
            {
              label: "Cancel Payment link",
              onClick: () => {
                openCancelPopup();
              },
              //   icon: editIcon,
              style: { color: "#667085" },
            },
            {
              label: "Resend Payment link",
              onClick: () => {
                openResendPopup();
              },
              //   icon: editIcon,
              style: { color: "#667085" },
            },
          ]}
        />

        <ResendPaymentLinkPopup
          open={resendPopup}
          onClose={closeResendPopup}
          noButtonClick={closeResendPopup}
          yesButtonClick={resendConfirm}
        />
        <CancelPopup
          open={cancelPopup}
          onClose={closeCancelPopup}
          noBtnClick={closeCancelPopup}
          yesBtnClick={cancelConfirm}
          title={"Are you sure you want to cancel the payment Link?"}
          description={
            "It will invalidate the link sent to the student and no payment will be further recorded using that link."
          }
          loader={recordPaymentUpdateLoading}
        />
      </div>
    </div>
  );
}

export default RandomPayResend;
