import { FormControlLabel, Switch, SwitchProps, Tooltip } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#11C89B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function SwitchInput(props: any) {
  const {
    switchStatus,
    handleSwitch,
    tooltipTitle,
    label,
    id,
    labelPlacement,
    disabled,
  } = props;
  return (
    <>
      <Tooltip
        title={tooltipTitle ? tooltipTitle : ""}
        placement="bottom"
        arrow
      >
        <FormControlLabel
          sx={{
            justifyContent: "flex-end",
            marginLeft: 0,
            color: "#667085",
            marginTop: "0px",
            gap: "10px",
            "& .MuiFormControlLabel-label": {
              fontSize: "13px",
              fontWeight: "500",
              color: "#667085",
            },
          }}
          control={
            <IOSSwitch
              sx={{ m: 1 }}
              checked={switchStatus}
              id={id ? id : "status"}
              onChange={handleSwitch}
              disabled={disabled}
            />
          }
          label={label ? label : ""}
          labelPlacement={labelPlacement ? labelPlacement : "start"}
        />
      </Tooltip>
    </>
  );
}

export default SwitchInput;
