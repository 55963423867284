import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useCallback, useState } from "react";
import logo from "../../../../assets/images/logo.png";
import FormTextInput from "../../../../components/FormTextInput/FormTextInput";
import { ErrorType } from "../../../../components/FormTextInput/types";
import PasswordValidation from "./PasswordValidation";
import styles from "./Registration.module.css";
export function Onboard(props: any) {
  const [credential, setCredential] = useState({
    password: "",
    conformPassword: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStatePassword, setValidStatePassword] =
    React.useState<boolean>(true);
  const [validStateConfirmPassword, setValidStateConfirmPassword] =
    React.useState<boolean>(true);

  const handleChange = useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStatePassword(true);
        setValidStateConfirmPassword(true);
      }
      setErrorType("client");
      setCredential({ ...credential, [event.target.id]: event.target.value });
    },
    [credential, errorType]
  );
  const setValidateStatusPassword = React.useCallback((val: boolean) => {
    setValidStatePassword(val);
  }, []);
  const setValidateStatusConfirmPassword = React.useCallback((val: boolean) => {
    setValidStateConfirmPassword(val);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      !credential.conformPassword && setValidStateConfirmPassword(false);
      !credential.password && setValidStatePassword(false);
      validStateConfirmPassword &&
        validStatePassword &&
        credential.conformPassword &&
        credential.password &&
        credential.password !== credential.conformPassword &&
        setErrorMsg("Password and re-entered password do not match");
      if (
        credential.password === credential.conformPassword &&
        credential.conformPassword &&
        credential.password
      ) {
        props.onSubmit({
          email: props.email,
          otp: props.otp,
          password: credential.password,
        });
      }
    } catch (error) {}
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Box
        className={styles.logo_box}
        sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
      >
        <img src={logo} height="35" width="150" alt="" />
      </Box>
      <Box className={styles.formHeadingalt}>
        <Typography
          sx={{
            fontSize: { lg: 30, md: 30, sm: 25, xs: 21 },
            textTransform: "capitalize",
            fontWeight: "600",
          }}
        >
          Create Your Password
        </Typography>
      </Box>
      <InputLabel
        sx={{
          fontSize: "14px",
          color: "#142C44",
          fontWeight: "500",
          mb: "10px",
        }}
      >
        Password
      </InputLabel>
      <FormControl
        fullWidth
        variant="standard"
        className="formControl"
        sx={{ mb: 2 }}
      >
        <FormTextInput
          autoComplete="true"
          validate={true}
          id={"password"}
          placeholder="************"
          variant="outlined"
          type={"password"}
          setValidStatus={setValidateStatusPassword}
          error={!validStatePassword}
          value={credential.password}
          onChange={handleChange}
          showErrorMessage={showErrMsz}
          errorType={errorType}
        />
      </FormControl>
      <InputLabel
        sx={{
          fontSize: "14px",
          color: "#142C44",
          fontWeight: "500",
          mb: "10px",
        }}
      >
        Re-enter password
      </InputLabel>
      <FormControl
        fullWidth
        variant="standard"
        className="formControl"
        sx={{ mb: 2 }}
      >
        <FormTextInput
          autoComplete="true"
          validate={true}
          id={"conformPassword"}
          type={"password"}
          placeholder="************"
          variant="outlined"
          setValidStatus={setValidateStatusConfirmPassword}
          error={!validStateConfirmPassword}
          showErrorMessage={showErrMsz}
          value={credential.conformPassword}
          onChange={handleChange}
          errorType={errorType}
        />
        {errorMsg && (
          <span style={{ color: "red", fontFamily: "Poppins" }}>
            {errorMsg}
          </span>
        )}
      </FormControl>

      <>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          sx={{ textTransform: "capitalize" }}
          className={styles.submitButton}
          disabled={isDisabled}
        >
          {props.loading ? (
            <CircularProgress size={25} color={"inherit"} />
          ) : (
            "Continue"
          )}
        </Button>
      </>

      <PasswordValidation
        copyText={credential.password}
        setIsDisabled={setIsDisabled}
      />
    </form>
  );
}
