import { Box } from "@mui/material";
import React from "react";
import styles from "./StudentSettings.module.css";
import StudentPrefix from "./components/StudentPrefix";
import CreateStudentPrefix from "./components/CreateStudentPrefix";
import { useAuthUser } from "react-auth-kit";
import { resetSettingsStatuses } from "../../service/settingsSlice";
import { useDispatch } from "react-redux";

function StudentSettings(props: any) {
  const {
    GetStudentIdSettings,
    studentIdData,
    UpdateStudentId,
    CreateStudentId,
    updateStudentIdLoading,
    updateStudentIdSuccess,
    createStudentIdSuccess,
    createStudentIdLoading,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (authUser) {
      GetStudentIdSettings(authUser?.institute?.[0]?.instituteId);
    }
  }, []);

  React.useEffect(() => {
    if (
      (updateStudentIdSuccess !== true && updateStudentIdSuccess !== false) ||
      (createStudentIdSuccess !== true && createStudentIdSuccess !== false)
    ) {
      GetStudentIdSettings(authUser?.institute?.[0]?.instituteId);
      dispatch(resetSettingsStatuses());
    }
  }, [updateStudentIdSuccess, createStudentIdSuccess]);

  return (
    <>
      <Box sx={{ padding: " 24px 20px 20px 20px" }}>
        <div className={styles.pageTitle}>Student ID</div>
        {studentIdData &&
        studentIdData?.prefix !== "" &&
        Object?.keys(studentIdData)?.length > 0 ? (
          <StudentPrefix
            UpdateStudentId={UpdateStudentId}
            studentIdData={studentIdData}
            updateStudentIdLoading={updateStudentIdLoading}
            updateStudentIdSuccess={updateStudentIdSuccess}
          />
        ) : (
          <CreateStudentPrefix
            CreateStudentId={CreateStudentId}
            createStudentIdLoading={createStudentIdLoading}
          />
        )}
      </Box>
    </>
  );
}

export default StudentSettings;
