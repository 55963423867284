import React, { useState } from "react";
import { Dialog, TextField, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./RenameFormPopup.module.css";
import { useSelector } from "react-redux";

const RenameFormPopup = (props: any) => {
  const {
    renameFormData,
    showRenamePopup,
    onClose,
    UpdateWebform,
    setShowRenamePopup,
  } = props;
  const { UpdateWebformLoading } = useSelector((state: any) => state.webform);

  const [updatedFormName, setUpdatedFormName] = useState(
    renameFormData?.formName || ""
  );

  const handleFormNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedFormName(event.target.value);
  };

  const handleSave = () => {
    const updatedData = { ...renameFormData, formName: updatedFormName };
    UpdateWebform(updatedData);
    setShowRenamePopup(false);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={showRenamePopup}
      onClose={onClose}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "400px",
          maxHeight: "290px",
          minHeight: "165px",
          padding: "24px",
          borderRadius: "12px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "8%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#555555" }} onClick={handleClose} />
        </div>
        <div className={styles.modalTitle}>Enter Form Name</div>
        <TextField
          variant="outlined"
          value={updatedFormName}
          onChange={handleFormNameChange}
          placeholder="Enter name for your form"
          fullWidth
          inputProps={{
            style: {
              padding: "10px 12px",
              backgroundColor: "#FFF",
              borderRadius: "4px",
            },
          }}
        />
        <div className={styles.buttonSection}>
          <Button
            variant="outlined"
            sx={{
              color: "#BFBFBF",
              fontWeight: "500",
              lineHeight: "10px 24px 24px 24px",
              textTransform: "capitalize",
              borderRadius: "4px",
              border: "1px solid #BFBFBF",
              backgroundColor: "#FFF",
              "&:hover": {
                backgroundColor: "#FFF",
                border: "1px solid #BFBFBF",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={handleSave}
            disabled={UpdateWebformLoading}
          >
            {UpdateWebformLoading ? (
              <CircularProgress size={18} color={"inherit"} />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default RenameFormPopup;
