import React from "react";
import styles from "../../../Nurture/components/DemoBooking/DemoBooking.module.css";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DeleteStudentPopup(props: any) {
  const {
    openModal,
    yesButtonClick,
    demoStudentDeleteLoading,
    handleCancelModalClose,
  } = props;
  return (
    <>
      {openModal && (
        <div className={styles.demoCancelPopupWrapper}>
          <div className={styles.demoCancelPopupWrap}>
            <div
              onClick={handleCancelModalClose}
              className={styles.closeModalButton}
            >
              <CloseIcon />
            </div>
            <div className={styles.modalTitle} style={{ marginBottom: "4px" }}>
              Do you really want to delete this student from the batch?
            </div>
            <div className={styles.buttonSection}>
              <button
                onClick={handleCancelModalClose}
                className={styles.noButton}
              >
                No
              </button>
              <button onClick={yesButtonClick} className={styles.yesButton}>
                {demoStudentDeleteLoading ? (
                  <CircularProgress size={25} color={"inherit"} />
                ) : (
                  "Yes"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteStudentPopup;
