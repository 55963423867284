import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Tooltip } from "@mui/material";
import AddNote from "../../pages/app/Nurture/components/AddNote/AddNote";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  resetEditNoteSuccess,
  resetNoteSuccess,
} from "../../pages/app/Nurture/service/nurtureSlice";
import NoteList from "../ActivityList/NoteList";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";
import styles from "./LeadNotesTab.module.css";

function LeadNotesTab(props: any) {
  const {
    CreateNote,
    EditNote,
    DeleteNote,
    selectedLeadData,
    leadNoteList,
    employees,
    type,
  } = props;
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const { editNoteSuccess, createNoteSuccess } = useSelector(
    (state: any) => state.nurture
  );

  const [showAddNote, setShowAddNote] = useState<boolean>(false);
  const [fromNotesEdit, setFromNotesEdit] = useState<boolean>(false);
  const [notesForEditing, setNotesForEditing] = useState<any>();
  const [isListView, setIsListView] = useState(false);

  const handleButtonClick = () => {
    setShowAddNote(!showAddNote);
    setFromNotesEdit(false);
  };

  React.useEffect(() => {
    if (createNoteSuccess !== true && createNoteSuccess !== false) {
      setShowAddNote(false);
      setTimeout(() => {
        dispatch(resetNoteSuccess());
      }, 3000);
    }
  }, [setShowAddNote, createNoteSuccess, resetNoteSuccess]);

  React.useEffect(() => {
    if (editNoteSuccess) {
      setShowAddNote(false);
      setTimeout(() => {
        dispatch(resetEditNoteSuccess());
      }, 3000);
    }
  }, [editNoteSuccess]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadNoteId = urlParams.get("leadNoteId");
    if (leadNoteId) {
      const element = document.getElementById(leadNoteId);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [leadNoteList]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          padding: 0,
        }}
      >
        {leadNoteList?.length > 0 ? (
          <>
            {showAddNote && (
              <Box
                sx={{
                  maxHeight:
                    type === "listview"
                      ? "calc(98vh - 480px)"
                      : "calc(98vh - 174px)",
                  minHeight:
                    type === "listview"
                      ? "calc(98vh - 480px)"
                      : "calc(98vh - 174px)",
                  overflowY: "auto",
                  background: "#FFF",
                }}
              >
                <AddNote
                  employees={employees}
                  CreateNote={CreateNote}
                  leadById={selectedLeadData}
                  handleCancelClick={handleButtonClick}
                  fromNotesEdit={fromNotesEdit}
                  notesForEditing={notesForEditing}
                  EditNote={EditNote}
                  hasLeadAccess={hasLeadAccess}
                />
              </Box>
            )}
            {!showAddNote && (
              <Box
                sx={{
                  height: type === "listview" ? "unset" : "100%",
                  backgroundColor: "white",
                  pt: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent:
                      type === "listview" ? "flex-end" : "space-between",
                    marginBottom: "6px",
                    padding: "0 6px 0 0",
                  }}
                >
                  <Box
                    sx={{
                      color: "#142C44",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      paddingLeft: "16px",
                      display: type === "listview" ? "none" : "flex",
                      alignItems: "center",
                    }}
                  >
                    Notes
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: type === "listview" ? "15px" : "unset",
                      position: type === "listview" ? "absolute" : "unset",
                      top: type === "listview" ? "10px" : "unset",
                      right: type === "listview" ? "0" : "unset",
                    }}
                  >
                    <Tooltip
                      arrow
                      title={
                        hasLeadAccess === false ? "Oops! No permission." : ""
                      }
                      PopperProps={{ style: { zIndex: 9999999 } }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#101828",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "8px 12px",
                            "& .MuiTooltip-arrow": {
                              color: "#101828",
                            },
                          },
                        },
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          display: "block",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          onClick={handleButtonClick}
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "400",
                            borderRadius: "4px",
                            border:
                              type === "listview"
                                ? "unset"
                                : "1px solid #E6E6E6",
                            color: "#667085",
                            lineHeight: "24px",
                            padding: "4px 8px",
                          }}
                          disabled={hasLeadAccess === false ? true : false}
                        >
                          <AddIcon
                            sx={{
                              marginRight: "6px",
                              fontSize: "18px",
                            }}
                          />
                          Add Note
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
                <div
                  style={{
                    background: "#fff",
                    maxHeight:
                      type === "listview"
                        ? "calc(98vh - 502px)"
                        : "calc(98vh - 170px)",
                    minHeight:
                      type === "listview"
                        ? "calc(98vh - 502px)"
                        : "calc(98vh - 170px)",
                    overflowY: "auto",
                  }}
                >
                  {leadNoteList?.map((item: any, index: any) => (
                    <div key={index}>
                      <NoteList
                        employees={employees}
                        data={item}
                        showAddNote={showAddNote}
                        setShowAddNote={setShowAddNote}
                        DeleteNote={DeleteNote}
                        selectedLeadData={selectedLeadData}
                        setFromNotesEdit={setFromNotesEdit}
                        setNotesForEditing={setNotesForEditing}
                      />
                    </div>
                  ))}
                </div>
              </Box>
            )}
          </>
        ) : !showAddNote ? (
          <div
            className={styles.noteScreen}
            style={{
              padding: 20,
              background: "#fff",
              maxHeight:
                type === "listview"
                  ? "calc(98vh - 480px)"
                  : "calc(98vh - 174px)",
              minHeight:
                type === "listview"
                  ? "calc(98vh - 480px)"
                  : "calc(98vh - 174px)",
              overflowY: "auto",
            }}
          >
            <>
              <Box
                sx={{
                  color: "#142C44",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  display: type === "listview" ? "none" : "unset",
                }}
              >
                Notes
              </Box>
              <Box
                sx={{
                  maxHeight:
                    type === "listview"
                      ? "calc(98vh - 500px)"
                      : "calc(98vh - 108px)",
                  minHeight:
                    type === "listview"
                      ? "calc(98vh - 500px)"
                      : "calc(98vh - 250px)",

                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%",
                    color: "#142C44",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    width: "36%",
                    margin: "0 auto",
                  }}
                >
                  Record lead engagements here and collaborate with team using
                  "@" mentions.
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Tooltip
                    arrow
                    title={
                      hasLeadAccess === false ? "Oops! No permission." : ""
                    }
                    PopperProps={{ style: { zIndex: 9999999 } }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        onClick={handleButtonClick}
                        variant="contained"
                        color="primary"
                        sx={{
                          marginLeft: 1,
                          marginTop: 2,
                          textTransform: "capitalize",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        disabled={hasLeadAccess === false ? true : false}
                      >
                        Add Note
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </>
          </div>
        ) : (
          <Box>
            <AddNote
              employees={employees}
              CreateNote={CreateNote}
              leadById={selectedLeadData}
              handleCancelClick={handleButtonClick}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default LeadNotesTab;
