import React from "react";
import deleteIcon from "../../assets/images/delete-dustbin.svg";

function DeleteBtn(props: any) {
  const { disabled, onClick, maxWidth, icon } = props;
  return (
    <>
      <button
        style={{
          border: "none",
          background: "transparent",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          maxWidth: maxWidth ? maxWidth : "26PX",
          padding: "0",
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <img
          src={icon ? icon : deleteIcon}
          alt="icon"
          style={{ maxWidth: "100%" }}
        />
      </button>
    </>
  );
}

export default DeleteBtn;
