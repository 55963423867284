import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import logo from "../../../../assets/images/logo.png";
import styles from "./Registration.module.css";
import FormTextInput from "../../../../components/FormTextInput/FormTextInput";
import { ErrorType } from "../../../../components/FormTextInput/types";
import CloseIcon from "@mui/icons-material/Close";
import { TermsOfServices } from "../../../app/TermsOfServices/components/TermsOfServices";
import NewFormTextInput from "../../../../components/FormTextInput/NewFormTextInput";
import { Link } from "react-router-dom";
import { PrivacyPolicy } from "../../../app/TermsOfServices/components/PrivacyPolicy";

const style = {
  position: "absolute" as "absolute",

  bgcolor: "background.paper",
  border: "2px solid #fff",
  p: 2,
  top: "2%",
  left: "2%",
  right: "2%",
  bottom: "2%",
  overflow: "hidden",
  outline: "none",
};

export default function RegistationIndividual(props: any) {
  const {
    status,
    instituteDetails,
    setInstituteDetails,
    CreateInstitute,
    error,
    loading_createInstitute,
    // SignUpProps,
    tnc,
  } = props;

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStateFirstName, setValidStateFirstName] =
    React.useState<boolean>(true);
  const [validStateLastName, setValidStateLastName] =
    React.useState<boolean>(true);
  const [validStateInstituteName, setValidStateInstituteName] =
    React.useState<boolean>(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);

  const ALPHA_REGEX = /^[a-zA-Z]/;
  const instituteRegex = /^[a-zA-Z]*/;

  React.useEffect(() => {
    if (error && typeof error === "string" && error !== "false") {
      // setSnackState((snackState: SnackStateProp) => ({
      //   ...snackState,
      //   message: String(error),
      //   snackOpen: true,
      //   snackSeverity: "error",
      // }));
      setShowErrMsz(false);
      setErrorType("server");
      setValidStateEmail(false);
      setValidStateFirstName(false);
      setValidStateLastName(false);
      setValidStateInstituteName(false);
    }
  }, [error]);

  const InstituteDetailsHandleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
        setValidStateFirstName(true);
        setValidStateLastName(true);
        setValidStateInstituteName(true);
      }
      setErrorType("client");

      const { id, value } = event.target;
      const lowercaseValue =
        id === "instituteEmail" ? value.toLowerCase() : value;

      setInstituteDetails({
        ...instituteDetails,
        [id]: lowercaseValue,
      });
    },
    [instituteDetails, errorType]
  );

  const setValidateStatusFirstName = React.useCallback((val: boolean) => {
    setValidStateFirstName(val);
  }, []);

  const setValidateStatusLastName = React.useCallback((val: boolean) => {
    setValidStateLastName(val);
  }, []);

  const setValidateStatusInstituteName = React.useCallback((val: boolean) => {
    setValidStateInstituteName(val);
  }, []);

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      let formIsValid = true;

      if (!instituteDetails.firstName) {
        setValidateStatusFirstName(false);
        formIsValid = false;
      } else if (!validStateFirstName) {
        formIsValid = false;
      }

      if (!instituteDetails.lastName) {
        setValidateStatusLastName(false);
        formIsValid = false;
      } else if (!validStateLastName) {
        formIsValid = false;
      }

      if (!instituteDetails.instituteEmail) {
        setValidStateEmail(false);
        formIsValid = false;
      } else if (!validStateEmail) {
        formIsValid = false;
      }

      if (!instituteDetails.instituteName) {
        setValidateStatusInstituteName(false);
        formIsValid = false;
      } else if (!validStateInstituteName) {
        formIsValid = false;
      }

      if (formIsValid) {
        CreateInstitute({
          firstName: instituteDetails.firstName,
          lastName: instituteDetails.lastName,
          instituteEmail: instituteDetails.instituteEmail,
          instituteName: instituteDetails.instituteName,
          website: instituteDetails.website,
          isInstitute: instituteDetails.isInstitute,
          courseCreated: instituteDetails.courseCreated,
          batchCreated: instituteDetails.batchCreated,
          branchCreated: instituteDetails.branchCreated,
          teamAdded: instituteDetails.teamAdded,
          status: instituteDetails.status,
          middleName: instituteDetails.middleName,
          instituteContact: instituteDetails.instituteContact,
          subdomain: instituteDetails.subdomain,
        });
      }
    } catch (error) {}
  };

  return (
    <>
      <Box
        sx={{
          px: {
            lg: 10,
            md: 6,
          },
        }}
      >
        <form className={styles.form_body} onSubmit={handleSubmit}>
          <Box
            className={styles.logo_box}
            sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
          >
            <img src={logo} height="35" width="150" alt="" />
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: {
                  lg: 30,
                  md: 26,
                  sm: 25,
                  xs: 19,
                },
                fontFamily: "Poppins",
                fontWeight: "600",
                color: "#142C44",
              }}
            >
              Create Your Account
            </Typography>

            <Box
              sx={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                color: "#71717A",
                lineHeight: 2,
                marginTop: "5px",
              }}
            >
              Start managing your leads better
            </Box>
          </Box>

          {status === 1 && (
            <>
              <Box>
                <Grid container>
                  <Grid item xs={6} style={{ paddingRight: "2%" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      id={"firstName"}
                      label="First Name"
                      variant="standard"
                      type="text"
                      inputProps={{ maxLength: 12 }}
                      onChange={InstituteDetailsHandleChange}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: "2%" }}>
                    <TextField
                      style={{ width: "100%" }}
                      required
                      id={"lastmane"}
                      label="Last Name"
                      variant="standard"
                      type="text"
                      onChange={InstituteDetailsHandleChange}
                    />
                  </Grid>
                </Grid>

                <FormTextInput
                  validate={true}
                  setValidStatus={setValidateStatusEmail}
                  label="Email *"
                  type="email"
                  id={"instituteEmail"}
                  error={!validStateEmail}
                  value={instituteDetails.instituteEmail}
                  onChange={InstituteDetailsHandleChange}
                  showErrorMessage={showErrMsz}
                  errorType={errorType}
                  autoComplete="true"
                />
              </Box>
            </>
          )}

          {status === 2 && (
            <>
              <Box sx={{ marginTop: "15px" }}>
                <Grid xs={6} marginTop="5px" paddingBottom="5px">
                  <NewFormTextInput
                    validate={true}
                    label="First Name"
                    type={"text"}
                    id={"firstName"}
                    onKeyDown={(event: any) => {
                      if (!ALPHA_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={InstituteDetailsHandleChange}
                    autoComplete="true"
                    placeholder="eg: Alex"
                    inputProps={{
                      maxLength: 20,
                    }}
                    require={true}
                    setValidStatus={setValidateStatusFirstName}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    error={!validStateFirstName}
                  />
                </Grid>

                <Grid marginTop="5px" paddingBottom="5px">
                  <NewFormTextInput
                    validate={true}
                    label="Last Name"
                    type={"text"}
                    id={"lastName"}
                    onKeyDown={(event: any) => {
                      if (!ALPHA_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={InstituteDetailsHandleChange}
                    placeholder="eg: Mathew"
                    inputProps={{
                      maxLength: 20,
                    }}
                    require={true}
                    setValidStatus={setValidateStatusLastName}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    error={!validStateLastName}
                  />
                </Grid>

                <Grid marginTop="5px" paddingBottom="5px">
                  <NewFormTextInput
                    validate={true}
                    label="Institute Name"
                    type="text"
                    id={"instituteName"}
                    placeholder="eg: React learning Hub"
                    onKeyDown={(event: any) => {
                      if (!instituteRegex.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={InstituteDetailsHandleChange}
                    inputProps={{
                      maxLength: 100,
                    }}
                    require={true}
                    setValidStatus={setValidateStatusInstituteName}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    error={!validStateInstituteName}
                  />
                </Grid>

                <Grid marginTop="5px" paddingBottom="5px">
                  <NewFormTextInput
                    validate={true}
                    label="Institute Email"
                    type="email"
                    id={"instituteEmail"}
                    error={!validStateEmail}
                    value={instituteDetails.instituteEmail}
                    onChange={InstituteDetailsHandleChange}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    placeholder="eg: contact@institute.com"
                    setValidStatus={setValidateStatusEmail}
                    require={true}
                  />
                </Grid>

                <Grid marginTop="5px" paddingBottom="5px">
                  <NewFormTextInput
                    validate={false}
                    label="Website"
                    type="text"
                    id={"website"}
                    placeholder="eg: www.reactlearninghub.com"
                    onChange={InstituteDetailsHandleChange}
                  />
                </Grid>
              </Box>
            </>
          )}

          <Button
            variant="contained"
            fullWidth
            className={styles.btn_continue}
            type="submit"
            sx={{ textTransform: "capitalize" }}
          >
            {loading_createInstitute ? (
              <CircularProgress size={25} color={"inherit"} />
            ) : (
              "Continue"
            )}
          </Button>

          <Box>
            <Typography
              className={styles.policyText}
              sx={{
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "14px",
                  xs: "10px",
                },
                fontFamily: "Poppins",
              }}
            >
              Already have an account?{" "}
              <Link to="/" className={styles.link}>
                Sign In
              </Link>
            </Typography>
            <Typography
              className={styles.policyText}
              sx={{
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "14px",
                  xs: "10px",
                },
                fontFamily: "Poppins",
              }}
            >
              By creating an account, you agree to nurture’s
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  lg: "14px",
                  md: "14px",
                  sm: "14px",
                  xs: "10px",
                },
                fontFamily: "Poppins",
              }}
              className={styles.ServiceText}
            >
              <span
                className={styles.link}
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                Terms of Service
              </span>{" "}
              and{" "}
              <span
                className={styles.link}
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={handleOpenPrivacy}
              >
                Privacy Policy.
              </span>
            </Typography>
          </Box>
        </form>

        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>
            <TermsOfServices tnc={tnc} />
          </Box>
        </Modal>

        <Modal open={openPrivacy} onClose={handleClosePrivacy}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleClosePrivacy}
              >
                <CloseIcon />
              </button>
            </div>
            <PrivacyPolicy tnc={tnc} />
          </Box>
        </Modal>
      </Box>
    </>
  );
}
