import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import { UserDetailsDTO } from "../../../../auth/signin/service/types";
import InstituteProfile from "../InstituteProfile";
import { institute, getInstituteById } from "../service/instituteSlice";
import { InstitutionsDTO, InstitutionsDetailsDTO } from "../service/types";
import { appEntery } from "../../../Home/service/homeSlice";
import { FileUploadDTO } from "../../../Main/components/FileUpload/service/types";
import { fileUpload } from "../../../Main/components/FileUpload/service/fileUploadSlice";

interface StateProps {
  error: string | boolean | undefined;
  success: boolean;
  loading: boolean;
  instituteDetails: InstitutionsDetailsDTO | null;
  userDetails: UserDetailsDTO | null;
  fileUploadSuccess: boolean;
  instituteDetailsLoading: boolean;
  instituteUpdateloading: boolean;
  instituteUpdateSuccess: boolean;
  instituteByIdLoading: boolean;
}

interface DispatchProps {
  institute(data: InstitutionsDTO): void;
  getInstituteById(id: string): void;
  UpdateInstituteInLocal(id: string): void;
  InstituteImageUpload(data: FileUploadDTO): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error: state.institute.error,
    success: state.institute.success,
    loading: state.institute.loading,
    instituteDetails: state.institute.instituteDetails,
    userDetails: state.login.userDetails,
    fileUploadSuccess: state.fileUpload.fileUploadSuccess,
    instituteDetailsLoading: state.institute.instituteDetailsLoading,
    instituteUpdateloading: state.institute.instituteUpdateloading,
    instituteUpdateSuccess: state.institute.instituteUpdateSuccess,
    instituteByIdLoading: state.institute.instituteByIdLoading,


  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    institute: (data: InstitutionsDTO) => {
      dispatch(institute(data));
    },
    getInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
    UpdateInstituteInLocal: (id: string) => {
      dispatch(appEntery(id));
    },
    InstituteImageUpload: (data: FileUploadDTO) => {
      dispatch(fileUpload(data));
    },
  };
};

export interface InstitutionsProps extends StateProps, DispatchProps {}
export const InstitutionsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstituteProfile);
