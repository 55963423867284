import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import Attendance from "../components/Attendance";
import { AttendanceInitialStateDTO } from "../service/types";
import { attendanceList, attendanceMarkers } from "../service/attendanceSlice";

interface StateProps extends AttendanceInitialStateDTO {}

interface DispatchProps {
  GetAttendanceList(data: any): void;
  GetAttendanceMarkers(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.attendance.success,
    error: state.attendance.error,
    loading: state.attendance.loading,
    attendanceListData: state.attendance.attendanceListData,
    attendanceMarkerError: state.attendance.attendanceMarkerError,
    attendanceMarkerLoading: state.attendance.attendanceMarkerLoading,
    attendanceMarkerSuccess: state.attendance.attendanceMarkerSuccess,
    attendanceMarkerData: state.attendance.attendanceMarkerData,
    attendanceStudentListError: state.attendance.attendanceStudentListError,
    attendanceStudentListLoading: state.attendance.attendanceStudentListLoading,
    attendanceStudentListSuccess: state.attendance.attendanceStudentListSuccess,
    attendanceStudentListData: state.attendance.attendanceStudentListData,
    attendanceStudentSaveError: state.attendance.attendanceStudentSaveError,
    attendanceStudentSaveLoading: state.attendance.attendanceStudentSaveLoading,
    attendanceStudentSaveSuccess: state.attendance.attendanceStudentSaveSuccess,
    attendanceStudentEditError: state.attendance.attendanceStudentEditError,
    attendanceStudentEditLoading: state.attendance.attendanceStudentEditLoading,
    attendanceStudentEditSuccess: state.attendance.attendanceStudentEditSuccess,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAttendanceList: (data: any) => {
      dispatch(attendanceList(data));
    },
    GetAttendanceMarkers: (data: any) => {
      dispatch(attendanceMarkers(data));
    },
  };
};

export interface AttendanceProps extends StateProps, DispatchProps {}
export const AttendanceComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Attendance);
