import React from "react";
import styles from "./SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function BatchSkeleton() {
  return (
    <div className={styles.smallFormPopupSkeletonWrap}>
      <div
        className={styles.smallFormPopupHead}
        style={{
          marginBottom: "24px",
          borderBottom: "1px solid rgb(237, 237, 237)",
        }}
      >
        <Skeleton animation="wave" variant="text" width="100px" height={25} />
      </div>

      <div
        className={styles.smallFormPopupHeadSplit}
        style={{ alignItems: "unset" }}
      >
        <div
          className={styles.smallFormPopupHeadSplitItem}
          style={{
            border: "1px solid #E9E9E9",
            padding: "24px 10px 24px 24px",
            borderRadius: "4px",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
          <div style={{ display: "flex", gap: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={50}
            />
          </div>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <div
            style={{
              display: "flex",
              gap: "20px",
              border: "1px solid #E9E9E9",
              padding: "24px",
              borderRadius: "4px",
            }}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={50}
            />
          </div>
          <div
            style={{
              border: "1px solid #E9E9E9",
              padding: "24px",
              borderRadius: "4px",
              marginTop: "24px",
            }}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="100px"
              height={25}
            />
            <div style={{ display: "flex", gap: "20px" }}>
              <Skeleton
                animation="wave"
                variant="text"
                width="100px"
                height={50}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="100px"
                height={50}
              />
            </div>

            <Skeleton
              animation="wave"
              variant="text"
              width="100px"
              height={25}
            />
          </div>
        </div>
      </div>

      <div className={styles.smallFormPopupHead} style={{ marginTop: "24px" }}>
        <Skeleton animation="wave" variant="text" width="100px" height={25} />
      </div>

      <div className={styles.buttonSplit}>
        <div className={styles.smallFormPopupHead}>
          <Skeleton animation="wave" variant="text" width="100px" height={50} />
        </div>
        <div className={styles.smallFormPopupHead}>
          <Skeleton animation="wave" variant="text" width="100px" height={50} />
        </div>
      </div>
    </div>
  );
}

export default BatchSkeleton;
