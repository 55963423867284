import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "../Manegecourses.module.css";
import { BasicButtonGroup } from "../../../../../../components/BasicButtonGroup/BasicButtonGroup";

export default function CourseLevelManager(props: any) {
  const {
    courseLevel,
    handleLevelChange,
    selectedLevel,
    disabled,
    errorMsg,
    isFromCourseSection,
  } = props;

  const [selected, setSelected] = useState<string[]>([]);

  const [isMulti] = useState<boolean>(true);

  const handleSelected = (value: never, index: any) => {
    if (!isMulti) {
      if (selected.includes(value)) {
        setSelected((prevSelected) => prevSelected.filter((s) => s !== value));
      } else {
        setSelected((prevSelected) => [...prevSelected, value]);
      }
    } else {
      if (selected.includes(value)) {
        setSelected((prevSelected) => prevSelected.filter((s) => s !== value));
      } else {
        setSelected((prevSelected) => [value]);
      }
    }
    handleLevelChange(
      courseLevel[value].courseLevelName,
      courseLevel[value].id
    );
  };

  function getIndex(selectedLevel: any) {
    if (courseLevel)
      return courseLevel.findIndex((obj: any) => obj.id === selectedLevel.id);
  }

  const mappedData = courseLevel
    ? courseLevel.map((item: any) => {
        return {
          label: item.courseLevelName,
          value: item.courseLevelName?.toLowerCase(),
        };
      })
    : [];

  useEffect(() => {
    if (selected.length === 0 && selectedLevel && selectedLevel.courseLevelName)
      setSelected([getIndex(selectedLevel)]);
  }, [setSelected, getIndex, selectedLevel]);

  return (
    <FormControl
      fullWidth
      variant="standard"
      className="formControl"
      sx={{ mb: 1 }}
    >
      <div
        style={{
          fontSize: "12px",
          color: "#142C44",
          fontWeight: "500",
          fontFamily: "Poppins",
          display: "flex",
          margin: "0px 0px 5px",
        }}
      >
        Course Level
        <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
      </div>
      <BasicButtonGroup
        className={isFromCourseSection ? "" : styles.courseType}
        buttonList={mappedData}
        handleSelected={handleSelected}
        selected={selected}
        errorMsg={errorMsg}
        disabled={disabled}
        isFromCourseSection={isFromCourseSection}
      />
    </FormControl>
  );
}
