import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./TaxInvoices.module.css";
import instituteLogo from "../../../../../assets/images/instituteLogo.png";
import { useSelector } from "react-redux";

function TaxInvoiceReceipt(props: any) {
	const { invoiceSettings, blurrEffect } = props;

	const instituteFromRedux = useSelector(
		(state: any) => state.institute.instituteDetails
	);
	const billingAddress = useSelector(
		(state: any) => state.billing.billingAddress
	);
	return (
		<Box
			sx={{
				maxWidth: '100%',
				padding: '32px',
				background: '#FFFFFF',
				borderRadius: '2px',
				border: '1px solid #E4E4E4',
				position: 'relative',
			}}>
			<div
				style={{
					position: 'absolute',
					height: '100%',
					width: ' 100%',
					background: 'rgb(255 255 255 / 59%)',
					left: '0px',
					top: '0px',
					display: blurrEffect ? 'block' : 'none',
				}}></div>
			<Box className={styles.receiptHead}>Tax invoice</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-end',
				}}>
				<Box>
					<div className={styles.instituteLogo}>
						{instituteFromRedux?.logo && (
							<img
								src={
									instituteFromRedux?.logo
										? instituteFromRedux?.logo
										: instituteLogo
								}
								alt='logo'
								width='87px'
							/>
						)}
					</div>

          <Box className={styles.instituteName}>
            {instituteFromRedux?.instituteName}
          </Box>
          <Box className={styles.instituteAddress}>
            {billingAddress?.buildingNumber}, {billingAddress?.city},{" "}
            {billingAddress?.state} {billingAddress?.pin}
            <br /> GSTIN : {instituteFromRedux?.studentTaxInvoiceGSTNumber}
          </Box>
        </Box>
        <Box
          sx={{
            isplay: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <div className={styles.receiptDetailsLabel}>Invoice Number : </div>
            <div className={styles.receiptDetailsValue}>
              {invoiceSettings?.prefix + "" + invoiceSettings?.nextNumber}
            </div>
          </Box>

					<Box sx={{ display: 'flex' }}>
						<div className={styles.receiptDetailsLabel}>Invoice Date : </div>
						<div className={styles.receiptDetailsValue}>-</div>
					</Box>
				</Box>
			</Box>
			<Box sx={{ marginTop: '16px', marginBottom: '18px' }}>
				<div className={styles.billTo}>Bill To :</div>
				<Box sx={{ display: 'flex', gap: '4px' }}>
					<div className={styles.studentName}>Student name :</div>
					<div className={styles.studentName}>-</div>
				</Box>
				<Box sx={{ display: 'flex', gap: '4px' }}>
					<div className={styles.paymentSource}>Payment Source :</div>
					<div className={styles.paymentSource}>-</div>
				</Box>
			</Box>
			<Box sx={{ display: 'flex' }}>
				<div className={styles.supply}>Place of supply :</div>
				<div className={styles.supplyPlace}> {billingAddress?.state}</div>
			</Box>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									maxWidth: '50px',
									padding: '10px 15px 8px 0',
									borderColor: '#000',
								}}
								width='50px'>
								#
							</TableCell>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									padding: '10px  0px 8px 0',
									borderColor: '#000',
								}}>
								Item & Description
							</TableCell>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									padding: '10px  0px 8px 0',
									textAlign: 'right',
									borderColor: '#000',
								}}>
								HSN/SAC
							</TableCell>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									padding: '10px  0px 8px 0',
									textAlign: 'right',
									borderColor: '#000',
								}}>
								Qty
							</TableCell>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									padding: '10px  0px 8px 0',
									textAlign: 'right',
									borderColor: '#000',
								}}>
								Rate
							</TableCell>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									padding: '10px  0px 8px 0',
									textAlign: 'right',
									borderColor: '#000',
								}}>
								SGST
							</TableCell>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									padding: '10px  0px 8px 0',
									textAlign: 'right',
									borderColor: '#000',
								}}>
								CGST
							</TableCell>
							<TableCell
								sx={{
									color: '#142C44',
									fontSize: '12px',
									fontWeight: '500',
									textTransform: 'capitalize',
									padding: '10px  0px 8px 0',
									textAlign: 'right',
									borderColor: '#000',
								}}>
								Amount
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow style={{ verticalAlign: 'baseline' }}>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									padding: '10px 0 16px 0px',
								}}>
								1
							</TableCell>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									padding: '10px 0 16px 0px',
								}}>
								Sample item
								<br /> (Description)
							</TableCell>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									textAlign: 'right',
									padding: '10px 0 16px 5px',
								}}>
								999299
							</TableCell>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									textAlign: 'right',
									padding: '10px 0 16px 5px',
								}}>
								-
							</TableCell>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									textAlign: 'right',
									padding: '10px 0 16px 5px',
								}}>
								-
							</TableCell>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									textAlign: 'right',
									padding: '10px 0 16px 5px',
								}}>
								- <br />
								9%
							</TableCell>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									textAlign: 'right',
									padding: '10px 0 16px 5px',
								}}>
								- <br />
								9%
							</TableCell>
							<TableCell
								sx={{
									color: '#667085',
									fontSize: '12px',
									fontWeight: '400',
									textTransform: 'capitalize',
									textAlign: 'right',
									padding: '10px 0 16px 5px',
								}}>
								-
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Box className={styles.amountDetailsWrapper}>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box className={styles.amountDetails}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								gap: '5px',
							}}>
							<div className={styles.amountDetailsLabel}>Sub Total</div>
							<div className={styles.amountDetailsValue}>-</div>
						</Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div className={styles.amountDetailsLabel}>SGST(9%)</div>
              <div className={styles.amountDetailsValue}>-</div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div className={styles.amountDetailsLabel}>CGST(9%)</div>
              <div className={styles.amountDetailsValue}>-</div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div className={styles.amountDetailsLabel}>Adjustment</div>
              <div className={styles.amountDetailsValue}>-</div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div className={styles.totalLabel}>Total</div>
              <div className={styles.totalValue}>-</div>
            </Box>
          </Box>
          <Box className={styles.amountDetails}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <div
                className={styles.balanceDueLabel}
                style={{ fontWeight: "500" }}
              >
                Balance Due
              </div>
              <div
                className={styles.balanceDuevalue}
                style={{ fontWeight: "500" }}
              >
                -
              </div>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "5px",
              marginTop: "12px",
            }}
          >
            <div className={styles.totalInWordsLabel}>Total In Words :</div>
            <div className={styles.totalInWordsValue}>-</div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TaxInvoiceReceipt;
