import React, { useState } from "react";
import styles from "./LeadDatePicker.module.css";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import dateIcon from "./dateCalander.svg";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

export default function LeadDatePicker(props: any) {
  const { setDate, date, setErrorDatePast } = props;

  const [newDate, setNewDate] = useState<any>();

  const onAccept = (date: any) => {
    if (date) {
      setDate(new Date(date).getTime());
      setNewDate(new Date(date).getTime());
      let currentDate = new Date();
      let selectedDate = new Date(date ?? 0);

      let isPast = selectedDate < currentDate;
      if (isPast) {
        setErrorDatePast(true);
      } else {
        setErrorDatePast(false);
      }
    }
  };

  return (
    <div>
      <label
        htmlFor="addTask"
        className={styles.leadDatePickerLabel}
        style={{
          minWidth: "30px",
          borderRadius: "50%",
          background: date !== undefined ? "#f5f5f7" : "transparent",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDateTimePicker
            value={dayjs(newDate)}
            onChange={onAccept}
            sx={{
              input: { display: "none" },
              fieldset: { display: "none" },
              marginLeft: "-15px",
              // zIndex: 99999999999,
              // zIndex: 99999999,
            }}
            onAccept={onAccept}
            disablePast
            closeOnSelect={false}
            slots={{
              openPickerIcon: CalendarTodayOutlinedIcon,
            }}
          />
        </LocalizationProvider>
      </label>
    </div>
  );
}
