import React from "react";
import styles from "../Subscription.module.css";
import accountLocked from "./account-locked.svg";

function AccountBlocked() {
  return (
    <div className={styles.lockedWarningWrap}>
      <div className={styles.lockedWarningWrapLeft}>
        <div className={styles.lockedWarningIcon}>
          <img src={accountLocked} alt="accountLocked" />
        </div>
        <div className={styles.lockedWarningLabel}>Account Locked</div>
      </div>
      <div className={styles.lockedWarningWrapRight}>
        Your account is locked, Please make the payment to activate it.
      </div>
    </div>
  );
}

export default AccountBlocked;
