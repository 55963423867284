import { connect } from "react-redux";
import { UserDetailsData, FileUploadDTO } from "../service/type";
import { updateUserDetails } from "../service/userDetailsSlice";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import UserDetails from "../components/UserDetails";
import { fileUpload } from "../../../Main/components/FileUpload/service/fileUploadSlice";
interface StateProps {
  error: string | boolean | undefined;
  success: boolean;
  loading: boolean;
  fileUploadSuccess: boolean;
  fileUploadError: boolean;
  updateUserDetailserror: boolean;
  updateUserDetailsSuccess: boolean;
  updateUserDetailsLoading: boolean;
}

interface DispatchProps {
  SaveUserDetails(data: UserDetailsData): void;
  UserImageUpload(data: FileUploadDTO): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error: state.userDetails.error,
    success: state.userDetails.success,
    loading: state.userDetails.loading,
    updateUserDetailserror: state.userDetails.updateUserDetailserror,
    updateUserDetailsSuccess: state.userDetails.updateUserDetailsSuccess,
    updateUserDetailsLoading: state.userDetails.updateUserDetailsLoading,
    fileUploadSuccess: state.fileUpload.fileUploadSuccess,
    fileUploadError: state.fileUpload.fileUploadError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    SaveUserDetails: (data: any) => {
      dispatch(updateUserDetails(data));
    },
    UserImageUpload: (data: FileUploadDTO) => {
      dispatch(fileUpload(data));
    },
  };
};

export interface UserDeatailsProps extends StateProps, DispatchProps {}
export const UserDetailsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetails);
