import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Tabs, Tab, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import {
  resetEditTaskSuccess,
  resetTaskSuccess,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import TasksList from "../ActivityList/TasksList";
import AddIcon from "@mui/icons-material/Add";
import AddTask from "../../pages/app/Nurture/components/AddTask/AddTask";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";

function LeadTasksTab(props: any) {
  const {
    CreateTask,
    EditTask,
    UpdateTaskStatus,
    DeleteTask,
    selectedLeadData,
    leadTaskList,
    employees,
    taskTypes,
    CreateComment,
    DeleteComment,
    EditComment,
  } = props;
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const [activeTab, setActiveTab] = useState<number>(0);
  const [fromTaskEdit, setFromTaskEdit] = useState<boolean>(false);
  const [taskForEditing, setTaskForEditing] = useState<any>();
  const [showAddTask, setShowAddTask] = useState(false);
  const [taskIdForComment, setTaskIdForComment] = useState<any>();

  const { editTaskSuccess, createTaskSuccess, updateTaskStatusSuccess } =
    useSelector((state: any) => state.nurture);

  const toggleShowAddTask = () => {
    setShowAddTask(!showAddTask);
    setFromTaskEdit(false);
  };

  const handleTaskTabChange = React.useCallback(
    (event: any, newValue: any) => {
      setActiveTab(newValue);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(
        "taskTabOpen",
        newValue === 0 ? "INCOMPLETE" : newValue === 1 ? "COMPLETED" : ""
      );
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    },
    [setActiveTab]
  );

  const dueTaskCount = leadTaskList?.filter(
    (res: any) => res?.status !== "COMPLETED"
  )?.length;

  const completedTaskCount = leadTaskList?.filter(
    (res: any) => res?.status === "COMPLETED"
  )?.length;

  React.useEffect(() => {
    if (editTaskSuccess) {
      setShowAddTask(false);
      setTimeout(() => {
        dispatch(resetEditTaskSuccess());
      }, 3000);
    }
  }, [editTaskSuccess]);

  React.useEffect(() => {
    if (createTaskSuccess !== true && createTaskSuccess !== false) {
      setShowAddTask(false);
      setTimeout(() => {
        dispatch(resetTaskSuccess());
      }, 3000);
    }
  }, [setShowAddTask, createTaskSuccess, resetTaskSuccess]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadTaskId = urlParams.get("leadTaskId");
    const taskTabOpen = urlParams.get("taskTabOpen");
    const highlightTask = leadTaskList?.find(
      (item: any) => item.id === leadTaskId
    );
    if (taskTabOpen === "COMPLETED") {
      setActiveTab(1);
    } else {
      if (highlightTask?.status === "COMPLETED") {
        setActiveTab(1);
      } else {
        setActiveTab(0);
      }
    }
    if (leadTaskId) {
      const element = document.getElementById(leadTaskId);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [leadTaskList]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            margin: "10px 24px 0 24px",
            borderBottom: "1px solid #E9E9E9",
            alignItems: "center",
          }}
        >
          <Box>
            <Tabs
              value={activeTab}
              // onChange={(event, newValue) => setActiveTab(newValue)}
              onChange={(event, newValue) =>
                handleTaskTabChange(event, newValue)
              }
            >
              <Tab
                label={`${"Due Tasks"} (${dueTaskCount})`}
                sx={{
                  textTransform: "capitalize",
                  color: "#B4B4B4",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              />
              <Tab
                // label={`${"Completed Tasks"} (${completedTaskCount})`}
                label={`${"Completed Tasks"}`}
                sx={{
                  textTransform: "capitalize",
                  color: "#B4B4B4",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  paddingLeft: "0",
                  paddingRight: "0",
                  marginLeft: "20px",
                }}
              />
            </Tabs>
          </Box>

          {leadTaskList?.length > 0 && !showAddTask && (
            <Box>
              <Tooltip
                arrow
                title={hasLeadAccess === false ? "Oops! No permission." : ""}
                PopperProps={{ style: { zIndex: 9999999 } }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      zIndex: "9999999",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                        zIndex: "9999999",
                      },
                    },
                  },
                }}
              >
                <span
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <Button
                    onClick={toggleShowAddTask}
                    color="primary"
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#667085",
                      border: "1px solid #E6E6E6",
                      borderRadius: "4px",
                    }}
                    disabled={hasLeadAccess === false ? true : false}
                  >
                    <AddIcon sx={{ marginRight: "6px", fontSize: "18px" }} />
                    Add Task
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ height: "100%" }}>
        {activeTab === 0 ? (
          leadTaskList?.length > 0 ? (
            <>
              {showAddTask ? (
                <Box>
                  <AddTask
                    CreateTask={CreateTask}
                    employees={employees && employees}
                    leadById={selectedLeadData}
                    taskTypes={taskTypes && taskTypes}
                    handleCancelClick={toggleShowAddTask}
                    fromTaskEdit={fromTaskEdit}
                    taskForEditing={taskForEditing}
                    EditTask={EditTask}
                  />
                </Box>
              ) : leadTaskList?.filter(
                  (res: any) => res?.status !== "COMPLETED"
                ).length === 0 ? (
                <div
                  style={{
                    padding: 20,
                    background: "#fff",
                    maxHeight: "calc(98vh - 160px)",
                    minHeight: "calc(98vh - 160px)",
                    overflowY: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        color: "#142C44",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        marginBottom: "20px",
                      }}
                    >
                      Start adding tasks to stay organized and on track with
                      your lead engagements.
                    </Box>
                    <Tooltip
                      arrow
                      title={
                        hasLeadAccess === false ? "Oops! No permission." : ""
                      }
                      PopperProps={{ style: { zIndex: 9999999 } }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#101828",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "8px 12px",
                            zIndex: "9999999",
                            "& .MuiTooltip-arrow": {
                              color: "#101828",
                              zIndex: "9999999",
                            },
                          },
                        },
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          display: "block",
                          textAlign: "center",
                        }}
                      >
                        <Button
                          onClick={toggleShowAddTask}
                          variant="contained"
                          color="primary"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                          disabled={hasLeadAccess === false ? true : false}
                        >
                          Add Task
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </div>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    backgroundColor: "white",
                    pt: 2,
                  }}
                >
                  <div
                    style={{
                      overflowY: "auto",
                      maxHeight: "calc(98vh - 234px)",
                      minHeight: "calc(98vh - 234px)",
                    }}
                  >
                    {leadTaskList
                      ?.filter((res: any) => res?.status !== "COMPLETED")
                      ?.map((item: any, index: any) => (
                        <div key={index} id={item?.id}>
                          <TasksList
                            activeTab={"task"}
                            employees={employees}
                            data={item}
                            index={index}
                            listData={leadTaskList}
                            DeleteTask={DeleteTask}
                            setFromTaskEdit={setFromTaskEdit}
                            setTaskForEditing={setTaskForEditing}
                            setShowAddTask={setShowAddTask}
                            showAddTask={showAddTask}
                            UpdateTaskStatus={UpdateTaskStatus}
                            selectedLeadData={selectedLeadData}
                            updateTaskStatusSuccess={updateTaskStatusSuccess}
                            CreateComment={CreateComment}
                            DeleteComment={DeleteComment}
                            EditComment={EditComment}
                            leadById={selectedLeadData}
                            setTaskIdForComment={setTaskIdForComment}
                            taskIdForComment={taskIdForComment}
                            listView={false}
                          />
                        </div>
                      ))}
                  </div>
                </Box>
              )}
            </>
          ) : !showAddTask ? (
            <div
              style={{
                padding: 20,
                background: "#fff",
                maxHeight: "calc(98vh - 160px)",
                minHeight: "calc(98vh - 160px)",
                overflowY: "auto",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    color: "#142C44",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    marginBottom: "20px",
                  }}
                >
                  Start adding tasks to stay organized and on track with your
                  lead engagements.
                </Box>
                <Tooltip
                  arrow
                  title={hasLeadAccess === false ? "Oops! No permission." : ""}
                  PopperProps={{ style: { zIndex: 9999999 } }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                          zIndex: "99999999",
                        },
                      },
                    },
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      onClick={toggleShowAddTask}
                      variant="contained"
                      color="primary"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                      disabled={hasLeadAccess === false}
                    >
                      Add Task
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            </div>
          ) : (
            <Box>
              <AddTask
                CreateTask={CreateTask}
                employees={employees && employees}
                leadById={selectedLeadData}
                handleCancelClick={toggleShowAddTask}
                taskTypes={taskTypes}
              />
            </Box>
          )
        ) : (
          <>
            {leadTaskList?.filter((res: any) => res?.status === "COMPLETED")
              .length === 0 ? (
              <>
                <div
                  style={{
                    padding: 20,
                    background: "#fff",
                    maxHeight: "calc(98vh - 160px)",
                    minHeight: "calc(98vh - 160px)",
                    overflowY: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        color: "#142C44",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        marginBottom: "20px",
                      }}
                    >
                      No tasks have been marked as Completed yet.
                    </Box>
                  </Box>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    marginTop: "0px",
                    overflowY: "auto",
                    maxHeight: "calc(98vh - 160px)",
                    minHeight: "calc(98vh - 160px)",
                    background: "#fff",
                    paddingBottom: "40px",
                  }}
                >
                  {Array.isArray(leadTaskList)
                    ? [...leadTaskList]
                        .sort(
                          (a, b) => b.taskCompletedDate - a.taskCompletedDate
                        )
                        .filter((res) => res?.status === "COMPLETED")
                        .map((item, index) => (
                          <div key={index}>
                            <TasksList
                              activeTab={"task"}
                              data={item}
                              index={index}
                              listData={leadTaskList}
                              DeleteTask={DeleteTask}
                              setFromTaskEdit={setFromTaskEdit}
                              setTaskForEditing={setTaskForEditing}
                              setShowAddTask={setShowAddTask}
                              showAddTask={showAddTask}
                              UpdateTaskStatus={UpdateTaskStatus}
                              selectedLeadData={selectedLeadData}
                              employees={employees}
                              CreateComment={CreateComment}
                              DeleteComment={DeleteComment}
                              EditComment={EditComment}
                              leadById={selectedLeadData}
                              setTaskIdForComment={setTaskIdForComment}
                              taskIdForComment={taskIdForComment}
                              listView={false}
                            />
                          </div>
                        ))
                    : null}
                </div>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default LeadTasksTab;
