const Event = ({ event }: any) => {
  return (
    <div style={{ display: "flex", alignItems: "center", padding: "0px 5px" }}>
      <span
        style={{
          marginLeft: "4px",
          whiteSpace: "nowrap",
          fontSize: "12px",
        }}
      >
        {event?.exemptedDates?.length > 0
          ? "Unavailable"
          : `${event.startTime} - ${event.endTime}`}
      </span>
      <span
        style={{
          marginLeft: "4px",
          whiteSpace: "nowrap",
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      ></span>
    </div>
  );
};

export default Event;
