import React from "react";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { Dialog, MenuItem, Select, TextField } from "@mui/material";
import closeIcon from "../../../../../../assets/images/close-icon.svg";
import styles from "./EditPopup.module.css";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { updateBatchPayment } from "../../service/Batchslice";

function EditMembershipDiscount(props: any) {
  const { isOpen, onClose, batchData, typeSelected, loader, selectedCurrency } =
		props;
	const auth = useAuthUser();
	const authUser = auth();
	const dispatch = useDispatch();
	const payStructure = batchData?.paymentDetails?.paymentData?.paymentStructure;

	const [price, setPrice] = React.useState<string>('');
	const [discountMetric, setDiscountMetric] = React.useState<string>('');
	const [discount, setDiscount] = React.useState<string>('');
	const [discountError, setDiscountError] = React.useState<boolean>(false);

	const handleDiscountMetric = (value: string) => {
		setDiscountMetric(value);
		setDiscount('');
	};

	const handleDiscount = (value: string) => {
		setDiscount(value);
		if (value) {
			if (discountMetric === 'AMOUNT') {
				setPrice((typeSelected?.emiAmount - parseFloat(value)).toString());
			} else if (discountMetric === 'PERCENTAGE') {
				setPrice(
					(typeSelected?.emiAmount * (1 - parseFloat(value) / 100)).toString()
				);
			}
		} else {
			setPrice(typeSelected?.emiAmount);
		}

		if (value === '' && typeSelected?.discount === 0) {
			setDiscountError(true);
		} else {
			setDiscountError(false);
		}
		// if (value !== "") {
		//   setDiscountError(false);
		// } else {
		//   setDiscountError(true);
		// }
	};

	const cancelButtonClick = () => {
		setPrice(typeSelected?.finalEmiAmount);
		setDiscount(typeSelected?.discount);
		setDiscountMetric(typeSelected?.discountMetric);
		setDiscountError(false);
		onClose();
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		try {
			const updatedPaymentStructure = payStructure?.map((item: any) => {
				if (item.frequency === typeSelected?.frequency) {
					return {
						...item,
						discount: parseFloat(discount),
						discountMetric: discountMetric,
						finalEmiAmount: parseFloat(price),
					};
				}
				return item;
			});

			const body = {
				batchId: batchData?.id,
				paymentType: 'MEMBERSHIP',
				paymentStructure: updatedPaymentStructure,
				updatedById: authUser?.institute[0]?.id,
				updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
				// totalAmount: 0,
				// scheduleInstallment: false,
				// enableInstallment: false,
				// numberOfInstallment: 0,
				discountAdded: true,
			};
			if (!discountError) {
				dispatch<any>(updateBatchPayment(body));
			}
		} catch (error) {}
	};

	React.useEffect(() => {
		if (typeSelected) {
			setDiscountMetric(typeSelected?.discountMetric);
			setDiscount(typeSelected?.discount);
			setPrice(typeSelected?.finalEmiAmount);
		}
	}, [typeSelected]);
	return (
		<>
			<Dialog
				open={isOpen}
				sx={{
					zIndex: '99999',
					minWidth: '408px',
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '408px',
						maxHeight: '440px',
						minHeight: '200px',
						padding: '16px',
						borderRadius: '8px',
						boxShadow:
							'0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
						left: '10%',
						background: '#FFFFFF',
						overflow: 'hidden',
					},
				}}>
				<div>
					<div
						className={styles.popupHead}
						style={{ padding: '0px', marginBottom: '14PX' }}>
						<div className={styles.popupHeadTitle}>Edit Discount</div>
						<button className={styles.popupHeadClsBtn} onClick={onClose}>
							<img src={closeIcon} alt='closeIcon' />
						</button>
					</div>
					<div className={styles.membershipEditBody}>
						<div className={styles.membershipEditBodyTop}>
							<div className={styles.membershipEditTypeLabel}>Final Price</div>
							<div
								className={styles.membershipTypeFinalPrice}
								style={{ fontFamily: 'Roboto' }}>
								{price !== '' && selectedCurrency + ' ' + parseFloat(price)}
							</div>
						</div>
						<div className={styles.membershipEditBodyBottom}>
							<div>
								<div className={styles.membershipEditTypeFieldLabel}>
									Discount Type
								</div>
								<div>
									<Select
										id='emi-mode-select'
										fullWidth
										MenuProps={{
											style: {
												zIndex: 999999,
												fontSize: '14px',
											},
											PaperProps: {
												style: {
													boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
												},
											},
										}}
										sx={{
											maxHeight: '39PX',
											textTransform: 'capitalize',
											'& .MuiSelect-outlined': {
												fontSize: '14px',
												color: '#142C44',
											},
										}}
										value={discountMetric}
										onChange={(event) =>
											handleDiscountMetric(event.target.value as string)
										}>
										{[
											{ discountType: 'AMOUNT' },
											{ discountType: 'PERCENTAGE' },
										]?.map((item, index) => (
											<MenuItem
												key={index}
												value={item.discountType}
												sx={{
													fontSize: '12PX',
													color: '#667085',
													textTransform: 'capitalize',
													fontWeight: '400',
												}}>
												{item?.discountType?.toLowerCase()}
											</MenuItem>
										))}
									</Select>
								</div>
							</div>
							<div>
								<div className={styles.membershipEditTypeFieldLabel}>
									Discount
								</div>
								<div>
									<TextField
										id='discountAmount'
										variant='outlined'
										type='number'
										inputProps={{
											sx: {
												width: '240px',
												'&::placeholder': {
													fontWeight: '400',
													fontSize: '14px',
												},

												'&.MuiOutlinedInput-input': {
													padding: '8px',
												},
											},
										}}
										value={discount}
										onChange={(event) => handleDiscount(event.target.value)}
										error={discountError}
										helperText={discountError ? 'Please fill valid amount' : ''}
										fullWidth
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.popupBtnSplit}>
						<CancelBtn onClick={() => cancelButtonClick()} />
						<SaveBtn
							onClick={handleSubmit}
							disabled={
								loader ||
								discountMetric === '' ||
								parseFloat(discount) <= 0 ||
								discount === ''
							}
							loader={loader}
						/>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default EditMembershipDiscount;
