import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = (props: any) => {
  const { result } = props;

  const chartValues =
    result &&
    result?.lineGraphData?.datasets?.map((d: any) => {
      if (d?.label === "contacts") {
        return {
          ...d,
          borderColor: "#F5AC1B",
          backgroundColor: "#F5AC1B",
        };
      } else if (d?.label === "leads") {
        return {
          ...d,
          borderColor: "#2283CA",
          backgroundColor: "#2283CA",
        };
      } else {
        return {
          ...d,
          borderColor: "#11C89B",
          backgroundColor: "#11C89B",
        };
      }
    });

  const [chartData, setChartData] = useState<any>(null);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (result && result?.lineGraphData) {
      const updatedChartValues = [...result?.lineGraphData?.datasets]?.map(
        (d: any) => {
          if (d?.label === "contacts") {
            return {
              ...d,
              borderColor: "#F5AC1B",
              backgroundColor: "#F5AC1B",
              data: [...d?.data]?.reverse(),
            };
          } else if (d?.label === "leads") {
            return {
              ...d,
              borderColor: "#2283CA",
              backgroundColor: "#2283CA",
              data: [...d?.data]?.reverse(),
            };
          } else {
            return {
              ...d,
              borderColor: "#11C89B",
              backgroundColor: "#11C89B",
              data: [...d?.data]?.reverse(),
            };
          }
        }
      );
      setChartData(updatedChartValues);

      const updatedLabels = [...result?.lineGraphData?.labels]
        .reverse()
        .map((dateString: any) => {
          const date = new Date(dateString);
          const day = date?.getDate();
          const month = date?.toLocaleString("default", { month: "short" });
          return `${day} ${month}`;
        });
      setLabels(updatedLabels);
    }
  }, [result]);

  const data = {
    labels,
    datasets: chartData || [],
  };

  useEffect(() => {
    let dataArray = props?.data;
    if (props?.data) {
      let data: any = [];
      dataArray &&
        dataArray?.map((obj: any, key: number) => {
          const newObj = {
            label: obj?.label,
            data: obj?.data,
            backgroundColor: key % 2 === 0 ? "#FDBB64" : "#11C89B",
            borderColor: key % 2 === 0 ? "#FDBB64" : "#11C89B",
          };
          data.push(newObj);
          setChartData(data);
          return null;
        });
    }
  }, [props.data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        align: "end" as const,
        fullSize: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
        maxWidth: 150,
      },
      title: {
        display: false,
        text: "Activity Growth",
        align: "start" as const,
        color: "#142C44",
        font: {
          size: 16,
          weight: "500",
          family: "Poppins",
        },
      },
    },
    scales: {
      y: {
        min: result?.lineGraphData?.yMinValue ?? 0,
        max: result?.lineGraphData?.yMaxValue ?? 4,
        ticks: {
          stepSize: 1,
        },
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkip: true,
        },
      },
    },
    elements: {
      line: {
        cubicInterpolationMode: () => "monotone" as const,
      },
    },
  };
  return (
    <>
      <Line options={options} data={data} />
    </>
  );
};

export default LineChart;
