import React from "react";
import styles from "./RemovePopup.module.css";
import { Box, CircularProgress, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function RemoveUserLicensePopup(props: any) {
  const { open, onClose, deleteButtonClick, userRemovalLoading } = props;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.closeModalBtnWrap}>
              <button onClick={onClose} className={styles.closeModalBtn}>
                <CloseIcon />
              </button>
            </div>
            <div className={styles.closeModalTitle}>
              Are you sure you want to delete this user license?
            </div>

            <div className={styles.buttonSection}>
              <button onClick={onClose} className={styles.cancelBtn}>
                cancel
              </button>
              <button
                onClick={deleteButtonClick}
                className={styles.removeBtn}
                disabled={userRemovalLoading}
              >
                {userRemovalLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default RemoveUserLicensePopup;
