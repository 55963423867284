import React from "react";
import styles from "../Manegecourses.module.css";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";

const sessioniModes = [
  {
    id: 1,
    emiModeValue: "PER_SESSION",
    emiModeLabel: "Per Session",
  },
];

function Session(props: any) {
  const {
    sessionRows,
    setSessionRows,
    setSelectedSessionModes,
    selectedSessionModes,
  } = props;
  const handleEmiModeChange = (id: number, value: string) => {
    const updatedRows = sessionRows?.map((row: any) =>
      row.id === id
        ? { ...row, emiModeValue: value, isEmiModeValid: true }
        : row
    );
    setSessionRows(updatedRows);
    setSelectedSessionModes(updatedRows?.map((row: any) => row.emiModeValue));
  };

  const handleEmiPriceChange = (id: number, value: string) => {
    const updatedRows = sessionRows?.map((row: any) =>
      row.id === id
        ? {
            ...row,
            emiPrice: parseFloat(value),
            isPriceValid: value !== "" && parseFloat(value) > 0 ? true : false,
          }
        : row
    );
    setSessionRows(updatedRows);
  };

  const isModeUniqueCheck = (sessionRows: any, row: any) => {
    return !sessionRows?.some(
      (r: any) => r?.id !== row?.id && r?.emiModeValue === row?.emiModeValue
    );
  };

  return (
    <div>
      {sessionRows?.map((item: any, index: number) => (
        <div className={styles.membershipTypeRow} key={index}>
          <div>
            <div className={styles.membershipTypeFieldLabel}>Frequency</div>
            <FormControl
              sx={{
                minWidth: 240,
                ".MuiOutlinedInput-input": {
                  padding: "7px 14px",
                },
              }}
            >
              <Select
                id={`emi-mode-select-${item.id}`}
                MenuProps={{
                  style: {
                    zIndex: 999999,
                    fontSize: "14px",
                  },
                  PaperProps: {
                    style: {
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                    },
                  },
                }}
                sx={{
                  "& .MuiSelect-outlined": {
                    fontSize: "14px",
                    color: "#142C44",
                  },
                }}
                value={item.emiModeValue}
                onChange={(event) =>
                  handleEmiModeChange(item.id, event.target.value as string)
                }
                error={
                  !item?.isEmiModeValid || !isModeUniqueCheck(sessionRows, item)
                }
                disabled
              >
                {sessioniModes
                  ?.filter(
                    (modeType) =>
                      !selectedSessionModes.includes(modeType.emiModeValue) ||
                      modeType.emiModeValue === item.emiModeValue
                  )
                  ?.map((modeType) => (
                    <MenuItem
                      key={modeType.id}
                      value={modeType.emiModeValue}
                      sx={{
                        fontSize: "12PX",
                        color: "#667085",
                        textTransform: "capitalize",
                        fontWeight: "400",
                      }}
                    >
                      {modeType.emiModeLabel}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <div className={styles.membershipTypeFieldLabel}>Session Fees</div>

            <TextField
              id="emiPrice"
              variant="outlined"
              type="number"
              inputProps={{
                sx: {
                  width: "240px",
                  "&::placeholder": {
                    fontWeight: "400",
                    fontSize: "14px",
                  },

                  "&.MuiOutlinedInput-input": {
                    padding: "8px",
                  },
                },
              }}
              value={item.emiPrice}
              onChange={(event) =>
                handleEmiPriceChange(item.id, event.target.value)
              }
              error={!item?.isPriceValid}
              helperText={!item?.isPriceValid ? "Please fill valid amount" : ""}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Session;
