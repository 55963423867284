import {
  Avatar,
  AvatarGroup,
  Box,
  List,
  ListItem,
  ListItemText,
  Popover,
  Radio,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./UserAssign.module.css";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";

function UserAvatarGroup(props: any) {
  const { handleItemSelect, selectedItem } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const staffList = useSelector((state: any) => state.staff.staffList);
  const [staffAnchorEl, setStaffAnchorEl] = useState<any>(null);
  const openStaff = Boolean(staffAnchorEl);
  const loggedInUserId = authUser?.institute[0]?.id;
  const sortedStaffList = [...staffList]
    ?.filter((item: any) => item?.status === "ACTIVE")
    ?.sort((a, b) =>
      a.id === loggedInUserId ? -1 : b.id === loggedInUserId ? 1 : 0
    );
  const handleOpenStaffPopover = (event: any) => {
    setStaffAnchorEl(event.currentTarget);
  };

  const handleCloseStaffPopover = () => {
    setStaffAnchorEl(null);
  };

  const renderList = () => {
    return sortedStaffList?.slice(5)?.map((item: any) => (
      <ListItem
        key={item?.id}
        button
        onClick={() => {
          handleItemSelect(item);
          handleCloseStaffPopover();
        }}
        sx={{
          padding: "4px 14px ",
          background:
            item?.id === selectedItem[0]?.id ? "#0000000a" : "inherit",
        }}
      >
        <ListItemText
          primary={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
                color: "#667085",
                fontSize: "14PX",
                gap: "4px",
              }}
            >
              <div>
                <Radio
                  sx={{
                    fontSize: "10px",
                    strokeWidth: 1,
                    color: "#D0D5DD",
                    padding: "4px",
                    "& .MuiSvgIcon-root": {
                      height: 15,
                      width: 15,
                    },
                  }}
                  checked={item.id === selectedItem[0]?.id}
                />
              </div>
              <div
                style={{
                  width: 24,
                  height: 24,
                  backgroundColor: "#F5F5F7",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 6,
                  textTransform: "uppercase",
                  color: "#667085",
                  fontSize: "9px",
                  fontWeight: "500",
                  padding: "5px 7px 3px 7px",
                }}
              >
                {item?.firstName?.[0] + item?.lastName?.[0]}
              </div>
              <div className={styles.staffPopupItemName}>
                {item?.firstName + " " + item?.lastName}
              </div>
            </div>
          }
        />
      </ListItem>
    ));
  };
  const isMatch =
    staffList?.length > 0 &&
    sortedStaffList
      ?.slice(0, 5)
      ?.some((item: any) => item?.id === selectedItem?.[0]?.id);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
        }}
      >
        <Box className={styles.userCardImg}>
          <AvatarGroup>
            {sortedStaffList?.slice(0, 5)?.map((user: any, userIndex: any) => (
              <Tooltip
                title={user?.firstName + " " + user?.lastName}
                placement="bottom"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      textTransform: "capitalize",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
                key={userIndex}
              >
                <Avatar
                  alt="avatar"
                  sx={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50%",
                    fontSize: "12px",
                    background: "#F5F5F7",
                    border:
                      selectedItem[0]?.id === user?.id
                        ? "2px solid #11C89B !important"
                        : "2px solid #FFF",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    color: "#667085",
                    position: "relative",
                    textTransform: "uppercase",
                    zIndex: userIndex,
                    cursor: "pointer",
                  }}
                  className={styles.userAvatar}
                  onClick={() => handleItemSelect(user)}
                >
                  <Box>
                    {user?.firstName?.charAt(0) +
                      "" +
                      user?.lastName?.charAt(0)}
                  </Box>
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
          {sortedStaffList?.length > 5 && (
            <Avatar
              alt="avatar"
              sx={{
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                fontSize: "12px",
                background: "rgba(17, 200, 155, 0.06)",
                border:
                  !isMatch && selectedItem?.length > 0
                    ? "2px solid #11C89B !important"
                    : "2px solid #FFF",
                fontFamily: "Poppins",
                fontWeight: "400",
                color: "#11C89B",
                position: "relative",
                textTransform: "uppercase",
                marginLeft: "-6px",
                zIndex: "9999",
                cursor: "pointer",
              }}
              className={styles.userAvatar}
              onClick={handleOpenStaffPopover}
            >
              <Box>+{sortedStaffList?.length - 5}</Box>
            </Avatar>
          )}

          <Popover
            open={openStaff}
            anchorEl={staffAnchorEl}
            onClose={handleCloseStaffPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{ width: "100%" }}
            PaperProps={{
              sx: {
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "4PX",
                border: "1px solid #EEE",
              },
            }}
          >
            {/* <TextField
              placeholder="Search"
              sx={{
                padding: "20px 20px 0px 20px",
                width: "100%",
              }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleListSearch}
              size="small"
            /> */}
            <div
              style={{
                height: "250px",
                overflowY: "scroll",
                maxWidth: "230px",
              }}
            >
              {sortedStaffList?.length > 0 ? (
                <List>{renderList()}</List>
              ) : (
                <div style={{ padding: "10px 16px" }}>No results found</div>
              )}
            </div>
          </Popover>
        </Box>
      </Box>
    </div>
  );
}

export default UserAvatarGroup;
