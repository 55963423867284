import React from "react";

const AllDays = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
        margin: "16px 0px",
        textAlign:"center"
      }}
    >
      <div style={{ fontSize: "14px", color: "3F3E43", paddingLeft: "4px" }}>
        Sun
      </div>
      <div style={{ fontSize: "14px", color: "3F3E43", paddingLeft: "4px" }}>
        Mon
      </div>
      <div style={{ fontSize: "14px", color: "3F3E43", paddingLeft: "4px" }}>
        Tue
      </div>
      <div style={{ fontSize: "14px", color: "3F3E43", paddingLeft: "4px" }}>
        Wed
      </div>
      <div style={{ fontSize: "14px", color: "3F3E43", paddingLeft: "4px" }}>
        Thu
      </div>
      <div style={{ fontSize: "14px", color: "3F3E43", paddingLeft: "4px" }}>
        Fri
      </div>
      <div style={{ fontSize: "14px", color: "3F3E43", paddingLeft: "4px" }}>
        Sat
      </div>
    </div>
  );
};

export default AllDays;
