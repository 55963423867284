import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import redDot from "./assets/red-dot.svg";
import greenDot from "./assets/dot-green.svg";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import getTeamMember from "../CommonFunctions/CommonFunctions";

function ActivityStatus(props: any) {
  const { data, employees } = props;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityTop}>
            <div className={styles.activityDateStyle}>
              {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
            </div>
            <div className={styles.activityHeadStage}>
              <div className={styles.activityHeadStageIcon}>
                <img src={stageDot} alt="" />
              </div>
              <div>{data?.currentStage}</div>
            </div>
            <div className={styles.activityHeadName}>
              <div className={styles.activityHeadStageIcon}>
                <img src={nameLine} alt="" />
              </div>
              <div>
                {/* {data.createdByName && data.createdByName} */}
                {getTeamMember(data?.createdById, employees)?.firstName +
                  " " +
                  getTeamMember(data?.createdById, employees)?.lastName}
              </div>
            </div>
          </div>

          {/* <div className={styles.dateStyle}>
            {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
          </div> */}
          <div className={styles.activityDemoTitle}>Status Changed</div>

          <div
            className={styles.activityStageDesc}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <div className={styles.activityStageDesc}>
              {data?.userName + " "} changed from
            </div>
            <div
              className={styles.activityStatusBox}
              style={{
                background:
                  data?.activityType === "STATUS_CHANGE_TO_LOST"
                    ? "rgba(17, 200, 155, 0.06)"
                    : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                    ? "#FEF3F3"
                    : "",
                color:
                  data?.activityType === "STATUS_CHANGE_TO_LOST"
                    ? "#11C89B"
                    : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                    ? "#EC3939"
                    : "",
              }}
            >
              <div className={styles.activityStatusIcon}>
                <img
                  style={{ display: "flex" }}
                  src={
                    data?.activityType === "STATUS_CHANGE_TO_LOST"
                      ? greenDot
                      : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                      ? redDot
                      : ""
                  }
                  alt=""
                />
              </div>
              <div>
                {" "}
                {data?.activityType === "STATUS_CHANGE_TO_LOST"
                  ? "Active"
                  : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                  ? "Lost"
                  : ""}
              </div>
            </div>
            <div>to</div>
            <div
              className={styles.activityStatusBox}
              style={{
                background:
                  data?.activityType === "STATUS_CHANGE_TO_LOST"
                    ? "#FEF3F3"
                    : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                    ? "rgba(17, 200, 155, 0.06)"
                    : "",
                color:
                  data?.activityType === "STATUS_CHANGE_TO_LOST"
                    ? "#EC3939"
                    : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                    ? "#11C89B"
                    : "",
              }}
            >
              <div className={styles.activityStatusIcon}>
                <img
                  style={{ display: "flex" }}
                  src={
                    data?.activityType === "STATUS_CHANGE_TO_LOST"
                      ? redDot
                      : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                      ? greenDot
                      : ""
                  }
                  alt=""
                />
              </div>
              <div>
                {data?.activityType === "STATUS_CHANGE_TO_LOST"
                  ? " Lost"
                  : data?.activityType === "STATUS_CHANGE_TO_ACTIVE"
                  ? "Active"
                  : ""}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ActivityStatus;
