import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Switch,
  Modal,
  SwitchProps,
  TextField,
  FormControlLabel,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../components/Managebatches.module.css";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import moment from "moment";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import cross from "./cross.svg";
import dayjs from "dayjs";
import { useAuthUser } from "react-auth-kit";
import DeactivationModal from "../../../../../components/DeactivationModal/DeactivationModal";
import Tooltip from "@mui/material/Tooltip";
import { GetInstituteByIdRequest } from "../../InstituteProfile/service/institute.request";
import DemoWeekTimings from "../components/Timing/components/DemoWeekTimings";
import ButtonLog from "../../../../../components/Button/ButtonLog";
import { CircularProgress } from "@mui/material";
import {
  convertToAdjustedTimestamp,
  timeZoneConverter,
} from "../../../../../components/Formatter/Formatter";
import deleteIcon from "../../../../../assets/images/delete.svg";
import { resetDemoStudentStatus } from "../service/Batchslice";
import { useDispatch } from "react-redux";
import DeleteStudentPopup from "../components/DeleteStudentPopup";
import BatchSkeleton from "../../../../../components/Skeletons/BatchSkeleton";
import ListPopover from "../../../../../components/ListPopover/ListPopover";
import { getStaffList } from "../../ManageStaff/service/Staffslice";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { getCombinedAssignees } from "../../../../../components/CommonFunctions/CommonFunctions";
import BatchTypeManager from "../components/Manager/BatchTypeManager";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function capitalize(str: any) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function DemoBatchEditor(props: any) {
  const {
    GetBranchList,
    GetCourseList,
    add,
    CreateBatch,
    UpdateBatch,
    GetBatchById,
    batchById,
    UpdateInstituteInLocal,
    setIsActive,
    BatchConfig,
    batchCreateSuccess,
    batchCreateLoading,
    batchUpdateLoading,
    batchUpdateSuccess,
    DeleteDemoStudent,
    demoStudentDeleteSuccess,
    demoStudentDeleteError,
    demoStudentDeleteLoading,
  } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const assigneeFieldRef = useRef<HTMLInputElement | null>(null);
  const assignees = useSelector((state: any) => state.staff.staffList);
  const branches = useSelector((state: any) => state.branch.branchList);
  const activeCourses = useSelector((state: any) => state.courses.courses);
  const courses =
    activeCourses &&
    activeCourses?.filter((item: any) => item?.status === "ACTIVE");

  const batchTypeList = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseType
  );
  const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

  const [showDeactivationModal, setShowDeactivationModal] = useState(false);

  const [batchDetails, setBatchDetails] = React.useState<any>({
    instituteName: "",
    instituteId: "",
    batchName: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    startDate: `${moment().unix()}`,
    endDate: `${moment().unix()}`,
    startTime: 0,
    endTime: 0,
    minimumSeats: "",
    maximumSeats: "",
    batchType: [],
    batchSchedule: [{ day: "", startTime: 0, endTime: 0 }],
    status: "ACTIVE",
    isFirstBatch: false,
    batchId: "",
    batchPrice: 0,
    coursePrice: 0,
    discountMetric: "",
    discount: 0,
    studentCount: 0,
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
    assignees: [],
  });
  const [formDisabled, setFormDisabled] = React.useState<boolean>(false);
  const [newBatchRequest, setNewBatchRequest] = useState<any>([
    {
      day: "SUN",
      batchSessions: [],
    },
    {
      day: "MON",
      batchSessions: [],
    },
    {
      day: "TUE",
      batchSessions: [],
    },
    {
      day: "WED",
      batchSessions: [],
    },
    {
      day: "THU",
      batchSessions: [],
    },
    {
      day: "FRI",
      batchSessions: [],
    },
    {
      day: "SAT",
      batchSessions: [],
    },
  ]);
  const [demoDayName, setDemoDayName] = useState("");

  const [openLogoutModal, setOpenLogoutModal] = React.useState<boolean>(false);
  const handleLogoutModalOpen = () => setOpenLogoutModal(true);
  const handleLogoutModalClose = () => setOpenLogoutModal(false);
  const [validAssignee, setValidAssignee] = React.useState<boolean>(false);
  const [selectedAssinees, setSelectedAssinees] = useState<any>(null);
  const [batchStatus, setBatchStatus] = React.useState<boolean>(
    batchDetails.status === "ACTIVE" ? true : false
  );
  const [validStateCourse, setValidStateCourse] = React.useState<boolean>(true);
  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);

  const [percentDiscount, setPercentDiscount] = useState(
    batchDetails.discountMetric === "PERCENTAGE" ? batchDetails.discount : 0
  );
  const [amountDiscount, setAmountDiscount] = useState(
    batchDetails.discountMetric === "AMOUNT" ? batchDetails.discount : 0
  );

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [errorMszs, setErrorMszs] = useState<string>("");
  const [errorStartDateMszs, setErrorStartDateMszs] = useState<boolean>(false);
  const [errorEndDateMsg, setErrorEndDateMsg] = useState<boolean>(false);
  const [errorMaxSeatMsg, setErrorMaxSeatMsg] = useState<boolean>(false);
  const [errorMinSeatMsg, setErrorMinSeatMsg] = useState<boolean>(false);
  const [errorStartDayMsg, setErrorStartDayMsg] = useState<boolean>(false);
  const [errorTimeMsg, setErrorTimeMsg] = useState<boolean>(false);
  const [errorEndDatePastMsg, setErrorEndDatePastMsg] =
    useState<boolean>(false);
  const [isDateChanged, setIsDateChanged] = useState(false);

  const [validStateBatchName, setValidStateBatchName] =
    React.useState<boolean>(true);

  const setValidateStatusBatchName = React.useCallback((val: boolean) => {
    setValidStateBatchName(val);
  }, []);

  const handleScheduleChange = React.useCallback(
    (batchSchedule: []) => {
      setBatchDetails({
        ...batchDetails,
        batchSchedule: batchSchedule,
      });
    },
    [batchDetails]
  );

  const [
    validatingFirstTimeBranchCreation,
    setValidatingFirstTimeBranchCreation,
  ] = useState<any>();

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    dispatch<any>(getStaffList(userFromLocalStorage?.id));
    GetInstituteByIdRequest(userFromLocalStorage?.id)
      ?.then((res: any) => {
        setValidatingFirstTimeBranchCreation(res?.data?.data);
      })
      .catch((err) => {});
  }, []);

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateBatchName(true);
      }
      setErrorType("client");
      setBatchDetails({
        ...batchDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [batchDetails]
  );

  const updateBatchPrice = (discount: number) => {
    const newBatchPrice = batchDetails.coursePrice - discount;
    setBatchDetails({
      ...batchDetails,
      discount,
      batchPrice: newBatchPrice,
    });
  };

  const [selectedDate, setSelectedDate] = useState<number | null>(null);

  const [startDate, setStartDate] = React.useState<any>(moment().unix());

  const [endDate, setEndDate] = React.useState<number | null>(moment().unix());

  const getNumberOfDays = () => {
    const start = moment.unix(parseFloat(batchDetails.startDate));
    const end = moment.unix(parseFloat(batchDetails.endDate));
    const diff = moment.duration(end.diff(start));
    const days = Math.floor(diff.asDays());
    return days;
  };

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      const dateUnix = date.unix();
      setSelectedDate(dateUnix);

      if (id === "startDate") {
        const newStartDate: any = new Date(date).setHours(0, 0, 0, 0);
        const newEndDate: any = new Date(date).setHours(23, 59, 59, 0);

        setStartDate(date.unix());
        setBatchDetails({
          ...batchDetails,
          startDate: timeZoneConverter(newStartDate),
          endDate: timeZoneConverter(newEndDate),
        });
        setIsDateChanged(true);

        const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        const dayOfWeek = new Date(date)?.getDay();
        const dayName = dayNames[dayOfWeek];
        setDemoDayName(dayName);
      } else if (id === "endDate") {
        const newEndDate = date.unix();
        setEndDate(newEndDate);

        setBatchDetails({
          ...batchDetails,
          endDate: `${newEndDate}`,
        });
      }
    }
  };

  const handleTypeChange = React.useCallback(
    (batchType: any) => {
      setBatchDetails({
        ...batchDetails,
        batchType: batchType,
      });
    },
    [setBatchDetails, batchDetails]
  );

  const selectedStartDate =
    batchDetails.startDate && new Date(parseInt(batchDetails.startDate) * 1000);
  const selectedEndDate =
    batchDetails.endDate && new Date(parseInt(batchDetails.endDate) * 1000);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newAssigneeArray = [];

    if (selectedAssinees) {
      const newAssignees = {
        assignedToId: selectedAssinees?.id,
        assignedToName: `${selectedAssinees?.firstName} ${selectedAssinees?.lastName}`,
      };
      newAssigneeArray.push(newAssignees);
    }

    const newState = [...newBatchRequest];
    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayOfWeek = new Date()?.getDay();
    const dayName = dayNames[dayOfWeek];

    const foundDay = newState.find(
      (item) => item.day === (demoDayName === "" ? dayName : demoDayName)
    );

    let updatedBatchRequests: any;

    if (foundDay && foundDay.batchSessions) {
      const newDemoObj = {
        startTime: batchDetails?.batchSchedule[0]?.startTime,
        endTime: batchDetails?.batchSchedule[0]?.endTime,

        sessionName: "Demo",
      };
      const validDayName = demoDayName === "" ? dayName : demoDayName;
      updatedBatchRequests = newState.map((day: any) => {
        if (day.day !== validDayName) {
          return { ...day, batchSessions: [] };
        }
        return {
          ...day,
          batchSessions: [newDemoObj],
        };
      });
    }
    try {
      selectedStartDate > selectedEndDate
        ? setErrorEndDatePastMsg(true)
        : setErrorEndDatePastMsg(false);
      batchDetails.batchType.length === 0
        ? setErrorMszs("select atleast one")
        : setErrorMszs("");
      batchDetails.startDate === ""
        ? setErrorStartDateMszs(true)
        : setErrorStartDateMszs(false);
      !batchDetails.courseId && setValidStateCourse(false);
      !batchDetails.branchId && setValidStateBranch(false);
      batchDetails.endDate === ""
        ? setErrorEndDateMsg(true)
        : setErrorEndDateMsg(false);
      batchDetails.minimumSeats === ""
        ? setErrorMinSeatMsg(true)
        : setErrorMinSeatMsg(false);
      batchDetails.minimumSeats >= batchDetails.maximumSeats
        ? setErrorMaxSeatMsg(true)
        : setErrorMaxSeatMsg(false);

      !batchDetails.batchName && setValidStateBatchName(false);

      const updatedData = updatedBatchRequests.map((item: any) => {
        if (
          item.batchSessions.length > 0 &&
          item.batchSessions[0].startTime === 0
        ) {
          const updatedStartTime = dayjs().add(5, "minutes").unix();
          item.batchSessions[0].startTime = updatedStartTime;
          return item;
        } else {
          return item;
        }
      });
      let daysName: any =
        batchDetails.startDate?.toString()?.length === 13
          ? convertToAdjustedTimestamp(batchDetails.startDate) / 1000
          : batchDetails?.startDate;

      const startDay = moment
        .unix(parseInt(daysName))
        .format("ddd")
        .toUpperCase();

      const matchingDay = updatedBatchRequests.find(
        (day: any) => day.day === startDay
      );

      let error: boolean = false;
      let errorTime: boolean = false;
      if (
        matchingDay &&
        (matchingDay.batchSessions[0]?.startTime === 0 ||
          matchingDay.batchSessions[0]?.endTime === 0)
      ) {
        setErrorStartDayMsg(true);
        error = true;
      } else {
        setErrorStartDayMsg(false);
        error = false;
      }
      const validBatchSessions =
        updatedBatchRequests &&
        updatedBatchRequests?.filter((item: any, index: any) => {
          if (
            item?.batchSessions?.length > 0 &&
            item?.batchSessions[0]?.startTime !== 0
          ) {
            return item;
          }
        });

      const getMatchingTime =
        validBatchSessions &&
        validBatchSessions?.filter(
          (obj: any) =>
            obj.batchSessions[0]?.startTime === obj.batchSessions[0]?.endTime ||
            obj.batchSessions[0]?.startTime >= obj.batchSessions[0]?.endTime
        );

      if (getMatchingTime?.length > 0) {
        setErrorTimeMsg(true);
        errorTime = true;
      } else {
        setErrorTimeMsg(false);
        errorTime = false;
      }

      if (newAssigneeArray?.length > 0) {
        setValidAssignee(false);
      } else {
        setValidAssignee(true);
      }

      const newArray: any = batchDetails;
      newArray.batchPrice =
        typeof batchDetails.batchPrice === "string"
          ? parseInt(batchDetails.batchPrice)
          : batchDetails.batchPrice;
      newArray.endTime =
        typeof batchDetails.endTime === "string"
          ? parseInt(batchDetails.endTime)
          : batchDetails.endTime;
      newArray.startTime =
        typeof batchDetails.startTime === "string"
          ? parseInt(batchDetails.startTime)
          : batchDetails.startTime;
      newArray.endDate = parseInt(batchDetails.endDate);
      newArray.startDate = parseInt(batchDetails.startDate);
      newArray.coursePrice =
        typeof batchDetails.coursePrice === "string"
          ? parseInt(batchDetails.coursePrice)
          : batchDetails.coursePrice;
      const dataArray: any = [];
      newArray.batchSchedule?.map((obj: any) => {
        const newObj = {
          day: obj.day,
          startTime:
            typeof obj.startTime === "string"
              ? parseInt(obj.startTime)
              : obj.startTime,
          endTime:
            typeof obj.endTime === "string"
              ? parseInt(obj.endTime)
              : obj.endTime,
        };
        dataArray.push(newObj);
        return null;
      });
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const selectedDate = new Date(selectedStartDate).setHours(0, 0, 0, 0);
      let newstartDate: any = new Date(batchDetails?.startDate * 1000).setHours(
        0,
        0,
        0,
        0
      );
      let newEndDate: any = new Date(batchDetails?.startDate * 1000).setHours(
        23,
        59,
        59,
        0
      );
      newArray.batchSchedule = dataArray;
      delete newArray.batchStatus;
      validStateBatchName &&
        batchDetails.batchName &&
        batchDetails.batchType.length > 0 &&
        selectedStartDate <= selectedEndDate &&
        batchDetails.startDate !== "" &&
        batchDetails.endDate !== "" &&
        batchDetails.courseName !== "" &&
        batchDetails.branchName !== "" &&
        !error &&
        !errorTime &&
        newAssigneeArray?.length > 0 &&
        // validAssignee === false &&
        selectedDate >= currentDate &&
        (add
          ? CreateBatch({
              ...newArray,
              batchConductType: "DEMO_BATCH",
              batchSchedule: updatedData,
              createDemo: false,
              demoRequest: {},
              assignees: newAssigneeArray,
              startDate:
                batchDetails?.startDate?.toString()?.length === 10
                  ? timeZoneConverter(newstartDate)
                  : batchDetails?.startDate,
              endDate:
                batchDetails?.startDate?.toString()?.length === 10
                  ? timeZoneConverter(newEndDate)
                  : batchDetails?.endDate,
              batchScheduleChanged: false,
            })
          : UpdateBatch({
              ...newArray,
              batchConductType: "DEMO_BATCH",
              batchSchedule: updatedBatchRequests,
              createDemo: false,
              demoRequest: {},
              assignees: newAssigneeArray,
              batchScheduleChanged: isDateChanged ? true : false,
              updatedById: authUser?.institute[0]?.id,
              updatedByName: authUser?.firstName + " " + authUser?.lastName,
            }));
    } catch (error) {
      console.log(error);
    }
  };

  const onBranchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setBatchDetails({
          ...batchDetails,
          branchId: value.id,
          branchName: value.branchName,
        });
        setValidStateBranch(true);
        setSelectedAssinees(null);
      } else {
        setBatchDetails({
          ...batchDetails,
          branchId: "",
          branchName: "",
        });
        setValidStateBranch(false);
        setSelectedAssinees(null);
      }
    },
    [batchDetails]
  );

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value) {
        const batchType = value.courseType.map((type: any) => ({
          batchType: {
            courseTypeName: type?.courseType?.courseTypeName,
            id: type.courseType.id,
          },
        }));
        setBatchDetails({
          ...batchDetails,
          courseId: value.id,
          courseName: value.courseName,
          coursePrice: value.coursePrice,
          batchPrice: value.coursePrice,
          batchType: batchType,
        });
      }
      setValidStateCourse(true);
    },
    [batchDetails]
  );

  //Status
  const handleCheckChange = (event: any) => {
    if (event.target.checked === false) {
      setShowDeactivationModal(true);
    } else if (event.target.checked === true) {
      setBatchStatus(event.target.checked);
      setBatchDetails({
        ...batchDetails,
        status: "ACTIVE",
      });
    }
  };

  const handlePopupYesButton = (event: any) => {
    setBatchStatus(false);
    setBatchDetails({
      ...batchDetails,
      status: "INACTIVE",
    });
  };

  const handlePopupNoButton = (event: any) => {
    setShowDeactivationModal(false);
  };

  useEffect(() => {
    const userFromLocalStorage = JSON.parse(
      localStorage.getItem("ACTIVE_INSTITUTE")!
    );
    const instituteId = authUser?.institute[0]?.instituteId;
    if (userFromLocalStorage) {
      GetBranchList(instituteId && instituteId);
      GetCourseList(instituteId && instituteId);
    }
  }, [GetBranchList, setBatchDetails]);

  useEffect(() => {
    const userFromLocalStorage = JSON.parse(
      localStorage.getItem("ACTIVE_INSTITUTE")!
    );
    const instituteId = authUser?.institute[0]?.instituteId;
    if (userFromLocalStorage && add) {
      setBatchDetails({
        ...batchDetails,
        instituteId: instituteId && instituteId,
        instituteName:
          userFromLocalStorage.instituteName &&
          userFromLocalStorage.instituteName,
        isFirstBatch:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.batchCreated === true
            ? false
            : true,
      });
    }
  }, [validatingFirstTimeBranchCreation, add]);

  useEffect(() => {
    if (authUser) {
      setBatchDetails({
        ...batchDetails,
        createdByName: add
          ? authUser.institute[0].firstName +
            " " +
            authUser.institute[0].lastName
          : "",
        createdById: add ? authUser.institute[0].id : "",
      });
    }
  }, []);

  const defaultBranches = branches
    ? branches.find(
        (branch: any) => branch.branchName === batchDetails.branchName
      )
    : "";

  const defaultCourses = courses
    ? courses.find(
        (course: any) => course.courseName === batchDetails.courseName
      )
    : "";

  React.useEffect(() => {
    if (!add) {
      const pathname = window.location.pathname;
      const batchId = pathname.substring(pathname.lastIndexOf("/") + 1);

      GetBatchById(batchId);
      setFormDisabled(true);
    }
  }, [add, GetBatchById, demoStudentDeleteSuccess]);

  useEffect(() => {
    const updateDemoDayName = () => {
      const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      const dayOfWeek = new Date(startDate * 1000).getDay();
      const dayName = dayNames[dayOfWeek];
      setDemoDayName(dayName);
    };

    if (startDate) {
      updateDemoDayName();
    }
  }, [startDate]);

  const checkAssigneeConditions = (assigneeIds: any, branchId: any) => {
    const matchingAssignees = assignees?.filter((assignee: any) =>
      assigneeIds?.includes(assignee.id)
    );
    const filteredAssignees = matchingAssignees.filter(
      (assignee: any) =>
        checkSubDomainAccess("MANAGE", "BATCHES", assignee.rolePermission) ===
          true &&
        assignee.status === "ACTIVE" &&
        assignee.branchId === branchId &&
        checkSubDomainAccess(
          "NURTURE_BOARD",
          "LEAD_CARD",
          assignee.rolePermission
        ) === true
    );

    return filteredAssignees;
  };

  React.useEffect(() => {
    if (!add && batchById) {
      const { students, courseImage, ...filteredBatchById } = batchById;

      const employee = assignees?.filter(
        (d: any) => d?.id === batchById?.assignees?.[0]?.assignedToId
      );

      setSelectedAssinees(
        batchById?.assignees && employee?.length > 0
          ? checkAssigneeConditions(
              employee.map((a: any) => a.id),
              batchById?.branchId
            )?.[0]
          : {}
      );
      setBatchStatus(batchById.status === "ACTIVE" ? true : false);
    }
  }, [add, batchById, setBatchStatus, assignees]);

  React.useEffect(() => {
    if (!add && batchById) {
      const userFromLocalStorage = JSON.parse(
        localStorage.getItem("ACTIVE_INSTITUTE")!
      );
      const instituteId = authUser?.institute[0]?.instituteId;
      const { students, courseImage, ...filteredBatchById } = batchById;
      const batchNewTimings = batchById?.batchSchedule?.filter(
        (d: any) => d?.batchSessions?.length > 0
      );
      setBatchDetails({
        ...filteredBatchById,
        batchSchedule: [
          {
            day: batchNewTimings[0]?.day,
            startTime: batchNewTimings[0]?.batchSessions[0]?.startTime,
            endTime: batchNewTimings[0]?.batchSessions[0]?.endTime,
          },
        ],
        branchName: batchById?.branchName,
        branchId: batchById?.branchId,
        batchName: batchById?.batchName,
        batchId: batchById?.batchId,
        courseName: batchById?.courseName,
        courseId: batchById?.courseId,
        batchType: batchById?.batchType,
        instituteId: instituteId && instituteId,
        instituteName:
          userFromLocalStorage.instituteName &&
          userFromLocalStorage.instituteName,
        isFirstBatch:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.batchCreated === true
            ? false
            : true,
      });
      const dayToUpdate = batchNewTimings[0]?.day;
      const foundDayIndex = newBatchRequest.findIndex(
        (day: any) => day.day === dayToUpdate
      );

      if (foundDayIndex !== -1) {
        const updatedBatchRequests = newBatchRequest.map(
          (day: any, index: any) => {
            if (index === foundDayIndex) {
              const foundBatchSession = (day.batchSessions || []).find(
                (session: any) =>
                  session.startTime ===
                    batchNewTimings[0]?.batchSessions[0]?.startTime &&
                  session.endTime ===
                    batchNewTimings[0]?.batchSessions[0]?.endTime
              );
              if (!foundBatchSession) {
                return {
                  ...day,
                  batchSessions: [
                    ...(day.batchSessions || []),
                    {
                      startTime:
                        batchNewTimings[0]?.batchSessions[0]?.startTime,
                      endTime: batchNewTimings[0]?.batchSessions[0]?.endTime,
                      sessionName: "Demo",
                    },
                  ],
                };
              }
            }
            return day;
          }
        );
        setNewBatchRequest(updatedBatchRequests);
      }
      setEndDate(convertToAdjustedTimestamp(batchById.endDate) / 1000);
      setStartDate(convertToAdjustedTimestamp(batchById.startDate) / 1000);
    }
  }, [batchById, validatingFirstTimeBranchCreation]);

  React.useEffect(() => {
    if (batchById) {
      if (batchDetails.discountMetric === "PERCENTAGE") {
        setPercentDiscount(batchDetails.discount);
        setAmountDiscount(0);
      } else if (batchDetails.discountMetric === "AMOUNT") {
        setAmountDiscount(batchDetails.discount);
        setPercentDiscount(0);
      }
    }
  }, [batchDetails]);

  useEffect(() => {
    BatchConfig();
  }, [BatchConfig]);

  React.useEffect(() => {
    if (demoStudentDeleteError) {
      dispatch(resetDemoStudentStatus());
    }
  }, [demoStudentDeleteError]);

  React.useEffect(() => {
    if (batchUpdateSuccess !== true && batchUpdateSuccess !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }

      if (batchDetails.isFirstBatch === true) {
        navigate("/app/home");
      } else {
        if (!add && batchById) {
          if (batchDetails.status === "ACTIVE") {
            navigate(`/app/manage/Batches?batchId=${batchById.id}`);
          } else {
            navigate(`/app/manage/Batches`);
          }
        } else {
          navigate(`/app/manage/Batches`);
        }
      }
      localStorage.setItem("ShowFullSection", JSON.stringify(false));
    }
  }, [batchUpdateSuccess, navigate, batchById]);

  React.useEffect(() => {
    if (batchCreateSuccess !== true && batchCreateSuccess !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }

      if (batchDetails.isFirstBatch === true) {
        navigate("/app/home");
      } else {
        if (!add && batchById) {
          navigate(`/app/manage/Batches?batchId=${batchById.id}`);
        } else {
          navigate(`/app/manage/Batches`);
        }
      }
    }
  }, [batchCreateSuccess, navigate, batchById]);

  useEffect(() => {
    if (window.location.href.includes("CreateBatch")) {
      setIsActive(4);
    }
  }, [setIsActive, setFormDisabled, setBatchDetails]);

  useEffect(() => {
    const element = document.getElementById("Batch");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, []);

  function handleGoBack() {
    navigate("/app/manage/Batches");
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState<string>("");

  const open = Boolean(anchorEl);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSearchText("");
  };

  const handleSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleCheckboxChange = (emp: any) => {
    setSelectedAssinees(emp);
    handleClosePopover();
    if (emp) {
      setValidAssignee(false);
    } else {
      setValidAssignee(true);
    }
  };

  const [deleteStudentModal, setDeleteStudentModal] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (demoStudentDeleteSuccess) {
      dispatch(resetDemoStudentStatus());
      setDeleteStudentModal(false);
    }
  }, [demoStudentDeleteSuccess]);
  const [deleteStudent, setDeleteStudent] = React.useState<any>({
    batchId: "",
    demoId: "",
    enquiryId: "",
    createdById: authUser?.institute?.[0]?.id,
    createdByName: authUser?.firstName + " " + authUser?.lastName,
    instituteId: authUser?.institute[0]?.instituteId,
  });

  const deleteStudentConfirm = async (event: any) => {
    event.preventDefault();
    try {
      DeleteDemoStudent(deleteStudent);
    } catch (error) {}
  };

  const handleCancelModalOpen = (batchId: any, demoId: any, enquiryId: any) => {
    setDeleteStudentModal(true);
    setDeleteStudent({
      ...deleteStudent,
      batchId: batchId,
      demoId: demoId,
      enquiryId: enquiryId,
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      instituteId: authUser?.institute[0]?.instituteId,
    });
  };
  const handleCancelModalClose = () => {
    setDeleteStudentModal(false);
    setDeleteStudent({
      ...deleteStudent,
      batchId: "",
      demoId: "",
      enquiryId: "",
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      instituteId: authUser?.institute[0]?.instituteId,
    });
  };

  const combinedAssignees = getCombinedAssignees(
    assignees,
    batchDetails?.branchId
  );

  return (
    <>
      <Container
        sx={{
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <Box className={styles.batchEditorWrapper}>
          <div
            className={""}
            style={{
              borderBottom: "1px solid #EDEDED",
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "800px",
              marginRight: "20px",
            }}
            id="Batch"
          >
            {add && (
              <p
                style={{
                  color: "#142C44",
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  margin: "10px 0px",
                }}
              >
                Add Batch
              </p>
            )}
            {!add && (
              <p
                style={{
                  color: "#142C44",
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  margin: "10px 0px",
                }}
              >
                Edit Batch
              </p>
            )}
          </div>

          {!add &&
          batchDetails?.courseName === "" &&
          batchDetails?.branchName === "" &&
          batchDetails?.batchName === "" ? (
            <BatchSkeleton />
          ) : (
            <Box sx={{ mt: 4 }}>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    border: "1px solid #E9E9E9",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControl
                      variant="standard"
                      className="formControl"
                      sx={{
                        ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          borderColor: !validStateCourse
                            ? "rgb(244 67 54)"
                            : "",
                        },
                        ".css-2wrs0q-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: !validStateCourse
                              ? "rgb(244 67 54)"
                              : "",
                            ":hover": {
                              borderColor: !validStateCourse
                                ? "rgb(244 67 54)"
                                : "",
                            },
                          },
                        ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                          {
                            ":hover": {
                              borderColor: !validStateCourse
                                ? "rgb(244 67 54)"
                                : "",
                            },
                          },
                        width: "400px",
                      }}
                      error={!validStateCourse}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#142C44",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          display: "flex",
                          margin: "0px 0px 5px",
                        }}
                      >
                        Select Course
                        <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>
                      </p>

                      {courses && (
                        <Autocomplete
                          disablePortal
                          id="courseId"
                          options={courses}
                          getOptionLabel={(option) =>
                            option.courseName
                              ? capitalize(option.courseName)
                              : ""
                          }
                          fullWidth
                          onChange={onCourseChange}
                          value={defaultCourses || ""}
                          disableClearable={
                            batchDetails.courseId === "" ? true : false
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              size="small"
                            />
                          )}
                        />
                      )}
                    </FormControl>
                    {validStateCourse === false && (
                      <FormHelperText error={true}>
                        Field required
                      </FormHelperText>
                    )}

                    <FormControl
                      variant="standard"
                      className="formControl"
                      sx={{
                        mt: "24px",
                        mb: "24px",
                        ".css-2wrs0q-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: !validStateBranch
                              ? "rgb(244 67 54)"
                              : "",
                            ":hover": {
                              borderColor: !validStateBranch
                                ? "rgb(244 67 54)"
                                : "",
                            },
                          },
                        ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                          {
                            ":hover": {
                              borderColor: !validStateBranch
                                ? "rgb(244 67 54)"
                                : "",
                            },
                          },
                        ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          borderColor: !validStateBranch
                            ? "rgb(244 67 54)"
                            : "",
                        },
                        width: "400px",
                        marginLeft: "40px",
                      }}
                      error={!validStateBranch}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#142C44",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          display: "flex",
                          margin: "0px 0px 5px",
                        }}
                      >
                        Branch
                        <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>
                      </p>

                      {branches && (
                        <Autocomplete
                          disablePortal
                          id="branchId"
                          options={branches}
                          getOptionLabel={(option: any) =>
                            option.branchName || ""
                          }
                          fullWidth
                          onChange={onBranchChange}
                          value={defaultBranches || ""}
                          disableClearable={
                            batchDetails.branchId === "" ? true : false
                          }
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              size="small"
                            />
                          )}
                        />
                      )}
                    </FormControl>
                    {validStateBranch === false && (
                      <FormHelperText error={true}>
                        Field required
                      </FormHelperText>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Grid item xs={5}>
                      <NewFormTextInput
                        validate={true}
                        setValidStatus={setValidateStatusBatchName}
                        label="Batch Name"
                        type="text"
                        id={"batchName"}
                        error={!validStateBatchName}
                        value={batchDetails.batchName}
                        onChange={handleChange}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        require={true}
                        inputProps={{ maxLength: 50 }}
                        sx={{
                          width:
                            window.innerWidth === window.outerWidth
                              ? "360px"
                              : "400px",
                        }}
                      />
                    </Grid>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {batchDetails?.assignees ? (
                        <Box>
                          <FormControl
                            variant="standard"
                            className="formControl"
                            sx={{
                              width:
                                window.innerWidth === window.outerWidth
                                  ? "360px"
                                  : "400px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#142C44",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                display: "flex",
                                margin: "0px 0px 5px",
                              }}
                            >
                              Assignee
                              <p
                                style={{ margin: "0px 4px", color: "#D32F2F" }}
                              >
                                *
                              </p>
                            </p>
                            <TextField
                              onClick={handleOpenPopover}
                              value={
                                selectedAssinees
                                  ? `${selectedAssinees?.firstName} ${selectedAssinees?.lastName}`
                                  : ""
                              }
                              variant="outlined"
                              size="small"
                              sx={{
                                "& .MuiOutlinedInput-root input": {
                                  textTransform: "capitalize",
                                },
                              }}
                              inputRef={assigneeFieldRef}
                            />
                            {validAssignee === true && (
                              <FormHelperText error={true}>
                                Field required
                              </FormHelperText>
                            )}
                            <ListPopover
                              openState={open}
                              anchorState={anchorEl}
                              handleClosePopover={handleClosePopover}
                              handleListSearch={handleSearch}
                              popupList={combinedAssignees
                                ?.filter((item: any) => {
                                  const fullName = `${item?.firstName ?? ""} ${
                                    item?.lastName ?? ""
                                  }`.toLowerCase();
                                  return fullName?.includes(
                                    searchText?.toLowerCase()
                                  );
                                })
                                ?.filter(
                                  (emp: any) =>
                                    checkSubDomainAccess(
                                      "MANAGE",
                                      "BATCHES",
                                      emp?.rolePermission
                                    ) === true
                                )}
                              handleItemSelect={handleCheckboxChange}
                              listType={"ASSIGNEE"}
                            />
                          </FormControl>
                        </Box>
                      ) : (
                        <Box>
                          <FormControl
                            variant="standard"
                            className="formControl"
                            sx={{
                              width:
                                window.innerWidth === window.outerWidth
                                  ? "360px"
                                  : "400px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#142C44",
                                fontWeight: "500",
                                fontFamily: "Poppins",
                                display: "flex",
                                margin: "0px 0px 5px",
                              }}
                            >
                              Assignee
                              <p
                                style={{ margin: "0px 4px", color: "#D32F2F" }}
                              >
                                *
                              </p>
                            </p>
                            <TextField
                              onClick={handleOpenPopover}
                              value={
                                selectedAssinees?.length === 0
                                  ? ""
                                  : `${selectedAssinees?.firstName} ${selectedAssinees?.lastName}`
                                  ? `${selectedAssinees?.firstName} ${selectedAssinees?.lastName}`
                                  : selectedAssinees?.assignedToName
                              }
                              variant="outlined"
                              size="small"
                            />
                            {validAssignee === true && (
                              <FormHelperText error={true}>
                                Field required
                              </FormHelperText>
                            )}
                            <ListPopover
                              openState={open}
                              anchorState={anchorEl}
                              handleClosePopover={handleClosePopover}
                              handleListSearch={handleSearch}
                              popupList={combinedAssignees
                                ?.filter((item: any) => {
                                  const fullName = `${item?.firstName ?? ""} ${
                                    item?.lastName ?? ""
                                  }`.toLowerCase();
                                  return fullName?.includes(
                                    searchText?.toLowerCase()
                                  );
                                })
                                ?.filter(
                                  (emp: any) =>
                                    checkSubDomainAccess(
                                      "MANAGE",
                                      "BATCHES",
                                      emp?.rolePermission
                                    ) === true
                                )}
                              handleItemSelect={handleCheckboxChange}
                              listType={"ASSIGNEE"}
                            />
                          </FormControl>
                        </Box>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ mt: "24px" }}>
                        <BatchTypeManager
                          add={add}
                          handleTypeChange={handleTypeChange}
                          errorMszs={errorMszs}
                          selectedType={batchDetails.batchType}
                          batchTypeList={batchTypeList}
                          key={batchDetails.courseId}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                <Grid>
                  <div className={styles.durationLabel}>Batch Duration</div>

                  <Box className={styles.durationWrapper}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                      }}
                      spacing={4}
                    >
                      <Grid item xs={12} lg={3} md={4}>
                        <Box
                          sx={{
                            marginTop: "5px",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{ zIndex: "99999999 !import" }}
                              label="Select Start Date"
                              format="DD MMM YYYY"
                              disablePast
                              onChange={(date: any) => {
                                handleDateChange(date, "startDate");
                              }}
                              value={
                                startDate ? dayjs.unix(startDate) : dayjs()
                              }
                            />
                          </LocalizationProvider>
                        </Box>
                        {errorStartDateMszs ? (
                          <div
                            style={{
                              color: "red",
                              padding: "5px 0",
                              fontSize: "12px",
                            }}
                          >
                            Select start date
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <Grid item xs={12} lg={3} md={4}>
                        <Box
                          sx={{
                            marginTop: "5px",
                          }}
                        >
                          <DemoWeekTimings
                            handleChange={handleChange}
                            batchSchedule={batchDetails.batchSchedule}
                            handleScheduleChange={handleScheduleChange}
                            batchDetails={batchDetails}
                            setBatchDetails={setBatchDetails}
                            add={add}
                            setIsDateChanged={setIsDateChanged}
                          />
                          {errorStartDayMsg && !errorTimeMsg && (
                            <div
                              style={{
                                color: "red",
                                padding: "5px 0",
                                fontSize: "14px",
                              }}
                            >
                              Oopps! Request can not be processed
                              <br />
                              Seems like you have not scheduled time for the
                              selected start date.
                            </div>
                          )}
                          {errorTimeMsg && (
                            <div
                              style={{
                                color: "red",
                                padding: "5px 0",
                                fontSize: "14px",
                              }}
                            >
                              Oopps! Request can not be processed
                              <br />
                              The start time and end time can not be the same
                              and the end time should be greater than the start
                              time.
                            </div>
                          )}
                        </Box>

                        {errorEndDateMsg ? (
                          <div
                            style={{
                              color: "red",
                              padding: "5px 0",
                              fontSize: "14px",
                            }}
                          >
                            Select end date
                          </div>
                        ) : (
                          <></>
                        )}
                        {errorEndDatePastMsg ? (
                          <div
                            style={{
                              color: "red",
                              padding: "5px 0",
                              fontSize: "14px",
                            }}
                          >
                            End date should be after or same as start date
                          </div>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid>
                  {batchDetails && batchDetails?.demoStudents?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className={styles.durationLabel}>Students</div>
                        {batchDetails &&
                          batchDetails?.demoStudents?.map((d: any) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: "1px solid #F2F2F2",
                                  padding: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      borderRadius: "50%",
                                      backgroundColor: "#989CA4",
                                      color: "white",
                                      width: "50px",
                                      height: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {batchDetails?.batchConductType ===
                                    "REGULAR_BATCH"
                                      ? d?.studentFirstName
                                          ?.charAt(0)
                                          .toUpperCase()
                                      : d?.studentFullName
                                          ?.charAt(0)
                                          .toUpperCase()}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: "#142C44",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {batchDetails?.batchConductType ===
                                      "REGULAR_BATCH"
                                        ? `${d?.studentFirstName} ${d?.studentLastName}`
                                        : d?.studentFullName}
                                    </div>
                                    <div
                                      style={{
                                        color: "#667085",
                                        fontSize: "12px",
                                      }}
                                    >
                                      New Enquiry
                                    </div>
                                  </div>
                                </div>
                                <div
                                  onClick={() =>
                                    handleCancelModalOpen(
                                      batchDetails?.id,
                                      d?.id,
                                      d?.enquiryId
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={deleteIcon} />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Box>
                  )}
                  <DeleteStudentPopup
                    openModal={deleteStudentModal}
                    yesButtonClick={deleteStudentConfirm}
                    demoStudentDeleteLoading={demoStudentDeleteLoading}
                    handleCancelModalClose={handleCancelModalClose}
                  />
                </Grid>
                {userFromLocalStorage?.isProfileCompleted && !add && (
                  <Box>
                    {batchById?.batchStatus === "ONGOING" ||
                    (batchById.demoStudents &&
                      batchById.demoStudents?.length > 0) ? (
                      <Tooltip
                        title={
                          batchById?.batchStatus === "ONGOING"
                            ? "An Ongoing batch cannot be deactivated"
                            : "Batch cannot be deactivated with active Students in it."
                        }
                        placement="bottom"
                        arrow
                      >
                        <FormControlLabel
                          style={{
                            justifyContent: "flex-end",
                            marginLeft: 0,
                            color: "#3F3E43",
                            marginTop: "10px",
                          }}
                          control={
                            <IOSSwitch
                              sx={{ m: 1 }}
                              checked={batchStatus}
                              disabled={
                                batchById?.batchStatus === "ONGOING" ||
                                (batchById.demoStudents &&
                                  batchById.demoStudents?.length > 0)
                              }
                              id="status"
                              onChange={handleCheckChange}
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                        />
                      </Tooltip>
                    ) : (
                      <FormControlLabel
                        style={{
                          justifyContent: "flex-end",
                          marginLeft: 0,
                          color: "#3F3E43",
                          marginTop: "10px",
                        }}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={batchStatus}
                            disabled={false}
                            id="status"
                            onChange={handleCheckChange}
                          />
                        }
                        label="Active"
                        labelPlacement="start"
                      />
                    )}
                    {showDeactivationModal && (
                      <DeactivationModal
                        type={"batch"}
                        handlePopupYesButton={handlePopupYesButton}
                        handlePopupNoButton={handlePopupNoButton}
                      />
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "6px",
                    marginTop: "24px",
                    marginBottom: add ? "70px" : "40px",
                    paddingRight: "20px",
                  }}
                >
                  {validatingFirstTimeBranchCreation?.isProfileCompleted && (
                    <Button
                      sx={{ textTransform: "capitalize" }}
                      variant="outlined"
                      onClick={handleLogoutModalOpen}
                    >
                      Cancel
                    </Button>
                  )}

                  {add && (
                    <ButtonLog
                      text={
                        batchCreateLoading ? (
                          <CircularProgress size={25} color={"inherit"} />
                        ) : (
                          "Save"
                        )
                      }
                      variant="contained"
                      type="submit"
                    />
                  )}

                  {!add && (
                    <ButtonLog
                      text={
                        batchUpdateLoading ? (
                          <CircularProgress size={25} color={"inherit"} />
                        ) : (
                          "Save changes"
                        )
                      }
                      variant="contained"
                      type="submit"
                    />
                  )}
                </Box>
              </form>
            </Box>
          )}

          <Modal
            open={openLogoutModal}
            onClose={handleLogoutModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "57%",
                left: "58%",
                transform: "translate(-50%, -50%)",
                width: 380,
                bgcolor: "white",
                border: "none",
                boxShadow:
                  "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
                borderRadius: "12px",
                padding: "12px 24px 24px 24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: "-22px",
                  marginTop: "-4px",
                }}
              >
                <Button
                  sx={{ minWidth: "40px" }}
                  onClick={handleLogoutModalClose}
                >
                  <img src={cross} alt="cross" />
                </Button>
              </div>
              <div>
                <div className={styles.cancelFormPopupTitle}>
                  Are you sure you want to Cancel?
                </div>
                <div className={styles.cancelFormPopupContent}>
                  The changes you made will be discarded.
                </div>
                <div className={styles.cancelFormPopupBtnsSplit}>
                  <button
                    className={styles.cancelFormPopupNoBtn}
                    onClick={handleLogoutModalClose}
                  >
                    No
                  </button>
                  <button
                    className={styles.cancelFormPopupYesBtn}
                    onClick={handleGoBack}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </Box>
      </Container>
    </>
  );
}
