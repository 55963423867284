import React from "react";
import styles from "../Managebatches.module.css";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";
import blueAdd from "../../../../../../assets/images/blue-add.svg";
import BatchPaymentMembershipDetail from "./BatchPaymentMembershipDetail";
import AddPriceDiscount from "../EditPopup/AddPriceDiscount";
import EditPriceDiscount from "../EditPopup/EditPriceDiscount";
import BatchCustomPayDetails from "./BatchCustomPayDetails";
import { useSelector } from "react-redux";
import EditBatchMembership from "./EditBatchMembership";
import EditBatchRegular from "./EditBatchRegular";
import moment from "moment";
import { useDispatch } from "react-redux";
import { resetBatchStatus } from "../../service/Batchslice";
import BatchPaymentSession from "./BatchPaymentSession";
import EditBatchPaymentSession from "./EditBatchPaymentSession";

function BatchPaymentDetails(props: any) {
	const { batchData, hasBatchAccess } = props;
	console.log('batchData at payment', batchData);
	const dispatch = useDispatch();
	const { batchPayUpdateSuccess, batchPayUpdateLoading } = useSelector(
		(state: any) => state.batch
	);
	const allBranches = useSelector((state: any) => state.branch);
	const [regularPayEdit, setRegularPayEdit] = React.useState<boolean>(false);
	const [membershipPayEdit, setMembershipPayEdit] =
		React.useState<boolean>(false);
	const [customPayEdit, setCustomPayEdit] = React.useState<boolean>(false);
	const [customPayAdd, setCustomPayAdd] = React.useState<boolean>(false);
	const [selectedCurrency, setSelectedCurrency] = React.useState('');
	const [addDiscountPopup, setAddDiscountPopup] =
		React.useState<boolean>(false);
	const [editDiscountPopup, setEditDiscountPopup] =
		React.useState<boolean>(false);

	const openAddDiscountPopup = (type: any) => {
		setAddDiscountPopup(true);
	};
	const closeAddDiscountPopup = (type: any) => {
		setAddDiscountPopup(false);
	};

  const [sessionPayEdit, setSessionPayEdit] = React.useState<boolean>(false);


	const closeEditDiscountPopup = (type: any) => {
		setEditDiscountPopup(false);
	};

	const hasValidSession = batchData?.batchSchedule?.some((day: any) =>
		day.batchSessions.some(
			(session: any) => session.startTime !== 0 && session.endTime !== 0
		)
	);

	React.useEffect(() => {
		if (batchPayUpdateSuccess !== true && batchPayUpdateSuccess !== false) {
			setAddDiscountPopup(false);
			setEditDiscountPopup(false);
			setRegularPayEdit(false);
			setMembershipPayEdit(false);
			dispatch(resetBatchStatus());
		}
	}, [batchData, batchPayUpdateSuccess]);

	React.useEffect(() => {
		const currency = allBranches?.branchList
			.filter((branch: any) => branch.id == batchData.branchId)
			.map((item: any) => item.currency);
		setSelectedCurrency(currency[0] || '₹');
	}, [batchData]);

  React.useEffect(() => {
    if (batchPayUpdateSuccess !== true && batchPayUpdateSuccess !== false) {
      setAddDiscountPopup(false);
      setEditDiscountPopup(false);
      setRegularPayEdit(false);
      setMembershipPayEdit(false);
      setSessionPayEdit(false);
      dispatch(resetBatchStatus());
    }
  }, [batchData, batchPayUpdateSuccess]);

  return (
    <div className={styles.batchPayWrapper}>
      <div className={styles.batchSecHeadSplit}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div className={styles.batchSecTitle}>Payment Details</div>
          {batchData?.paymentDetails?.paymentType !== "" &&
            batchData?.paymentDetails?.paymentType !== undefined && (
              <div className={styles.batchPayType}>
                {batchData?.paymentDetails?.paymentType?.toLowerCase()}
              </div>
            )}
        </div>
        {(hasValidSession || batchData?.customDays?.length > 0) &&
          !regularPayEdit &&
          !membershipPayEdit &&
          !sessionPayEdit &&
          hasBatchAccess && (
            <EditIconBtn
              onClick={() => {
                setCustomPayEdit(false);
                setCustomPayAdd(false);
                batchData?.paymentDetails?.paymentType === "REGULAR"
                  ? setRegularPayEdit(true)
                  : batchData?.paymentDetails?.paymentType === "MEMBERSHIP"
                  ? setMembershipPayEdit(true)
                  : setSessionPayEdit(true);
              }}
              disabled={hasBatchAccess === false}
            />
          )}
      </div>

      {hasValidSession || batchData?.customDays?.length > 0 ? (
        <>
          {batchData?.paymentDetails?.paymentType === "REGULAR" && (
            <>
              {!regularPayEdit ? (
                <div className={styles.batchRegularPayWrap}>
                  <div className={styles.batchRegularPayDetailsBox}>
                    <div className={styles.batchRegularPayDetailsBoxLeft}>
                      <div>
                        <div className={styles.batchRegularPayDetailsBoxLabel}>
                          Price
                        </div>
                        <div className={styles.batchRegularPayDetailsBoxValue}>
                     {/*      {batchData?.paymentDetails?.paymentData?.totalAmount?.toLocaleString(
                            "en-IN",
                            {
                              style: "currency",
                              currency: "INR",
                            }
												  )} */}
												  {selectedCurrency + " "+ batchData?.paymentDetails?.paymentData?.totalAmount }
                        </div>
                      </div>
                      <div>
                        <div className={styles.batchRegularPayDetailsBoxLabel}>
                          Installment
                        </div>
                        <div
                          className={styles.batchRegularPayDetailsBoxValue}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {batchData?.paymentDetails?.paymentData
                            ?.enableInstallment === true
                            ? "Enabled"
                            : "Disabled"}
                        </div>
                      </div>
                      <div>
                        <div className={styles.batchRegularPayDetailsBoxLabel}>
                          Discount
                        </div>
                        <div
                          className={styles.batchRegularPayDetailsBoxValue}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {batchData?.paymentDetails?.paymentData?.discount >
                          0 ? (
                            <>
                        {/*       {(
                                batchData?.paymentDetails?.paymentData
                                  ?.totalAmount -
                                batchData?.paymentDetails?.paymentData
                                  ?.finalPrice
                              )?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })} */}
															  {selectedCurrency}{' '}
															{batchData?.paymentDetails?.paymentData
																?.totalAmount -
																batchData?.paymentDetails?.paymentData
																	?.finalPrice}
                            </>
                          ) : (
                            <>
                              {hasBatchAccess && (
                                <button
                                  className={styles.batchRegularAddDiscountBtn}
                                  onClick={openAddDiscountPopup}
                                >
                                  <img
                                    src={blueAdd}
                                    alt="blueAdd"
                                    style={{
                                      marginRight: "4px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                  Add Discount
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.batchRegularPayDetailsBoxRight}>
                      <div className={styles.batchRegularPayDetailsBoxLabel}>
                        Final Price
                      </div>
                      <div className={styles.batchRegularPayFinalPrice}>
                    {/*     {batchData?.paymentDetails?.paymentData?.finalPrice?.toLocaleString(
                          "en-IN",
                          {
                            style: "currency",
                            currency: "INR",
                          }
                        )} */}
											  {selectedCurrency + " " + batchData?.paymentDetails?.paymentData?.finalPrice }
                      </div>
                    </div>
                  </div>
                  {batchData?.paymentDetails?.paymentData?.paymentStructure
                    ?.length > 0 && (
                    <div className={styles.batchEmiDetailsWrap}>
                      <div className={styles.batchEmiMainDetailsWrap}>
                        <div className={styles.batchEmiMainDetailsItem}>
                          <div className={styles.batchEmiMainDetailsLabel}>
                            No. of Installments
                          </div>
                          <div className={styles.batchEmiMainDetailsValue}>
                            {
                              batchData?.paymentDetails?.paymentData
                                ?.numberOfInstallment
                            }
                          </div>
                        </div>
                        <div className={styles.batchEmiMainDetailsItem}>
                          <div className={styles.batchEmiMainDetailsLabel}>
                            Mode
                          </div>
                          <div
                            className={styles.batchEmiMainDetailsValue}
                            style={{ textTransform: "capitalize" }}
                          >
                            {batchData?.paymentDetails?.paymentData?.emiMode?.toLowerCase()}
                          </div>
                        </div>
                        <div className={styles.batchEmiMainDetailsItem}>
                          <div className={styles.batchEmiMainDetailsLabel}>
                            Start Date
                          </div>
                          <div className={styles.batchEmiMainDetailsValue}>
                            {moment(
                              batchData?.paymentDetails?.paymentData?.startDate
                            ).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className={styles.batchEmiTableTitle}>
                        Breakdowns
                      </div>
                      <div className={styles.batchEmiTable}>
                        <div className={styles.batchEmiTableHead}>
                          <div style={{ width: "5%" }}>#</div>
                          <div style={{ width: "37%" }}>Installment Name</div>
                          <div style={{ width: "36%" }}>Installment Amount</div>
                          <div style={{ width: "20%" }}>Due Date</div>
                        </div>
                        <div className={styles.batchEmiTableBody}>
                          {batchData?.paymentDetails?.paymentData?.paymentStructure?.map(
                            (item: any) => (
                              <div className={styles.batchEmiTableRow}>
                                <div style={{ width: "5%" }}>
                                  {item.emiNumber}
                                </div>
                                <div style={{ width: "37%" }}>
                                  {item.emiName}
                                </div>
                                <div
                                  style={{ width: "36%", fontFamily: "Roboto" }}
                                >
                           {/*        {item?.emiAmount?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                  })}{" "} */}
										  {selectedCurrency + " " + item?.emiAmount}
                                </div>
                                <div style={{ width: "20%" }}>
                                  {moment(item?.dueDate).format("DD/MM/YYYY")}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <EditBatchRegular
                  batchData={batchData}
                  setRegularPayEdit={setRegularPayEdit}
                  regularPayEdit={regularPayEdit}
									  batchPayUpdateLoading={batchPayUpdateLoading}
									  selectedCurrency = {selectedCurrency}
				  
                />
              )}
            </>
          )}
          {batchData?.paymentDetails?.paymentType === "MEMBERSHIP" && (
            <>
              {!membershipPayEdit ? (
                <BatchPaymentMembershipDetail
                  batchData={batchData}
								  hasBatchAccess={hasBatchAccess}
								  selectedCurrency = {selectedCurrency}
                />
              ) : (
                <EditBatchMembership
                  membershipPayEdit={membershipPayEdit}
                  setMembershipPayEdit={setMembershipPayEdit}
                  batchData={batchData}
									  loading={batchPayUpdateLoading}
								
                />
              )}
            </>
          )}

          {batchData?.paymentDetails?.paymentType === "SESSION" && (
            <>
              {!sessionPayEdit ? (
                <BatchPaymentSession
                  batchData={batchData}
                  hasBatchAccess={hasBatchAccess}
								  batchPayUpdateLoading={batchPayUpdateLoading}
								  selectedCurrency = {selectedCurrency}
                />
              ) : (
                <EditBatchPaymentSession
                  sessionPayEdit={sessionPayEdit}
                  setSessionPayEdit={setSessionPayEdit}
                  batchData={batchData}
									  batchPayUpdateLoading={batchPayUpdateLoading}
									  selectedCurrency = {selectedCurrency}
                />
              )}
            </>
          )}
          <BatchCustomPayDetails
            batchData={batchData}
            batchPayUpdateLoading={batchPayUpdateLoading}
            batchPayUpdateSuccess={batchPayUpdateSuccess}
            customPayEdit={customPayEdit}
            setCustomPayEdit={setCustomPayEdit}
            customPayAdd={customPayAdd}
            setCustomPayAdd={setCustomPayAdd}
            setMembershipPayEdit={setMembershipPayEdit}
            setRegularPayEdit={setRegularPayEdit}
					  hasBatchAccess={hasBatchAccess}
					  selectedCurrency = {selectedCurrency}
          />
          <AddPriceDiscount
            isOpen={addDiscountPopup}
            onClose={closeAddDiscountPopup}
            batchData={batchData}
            loader={batchPayUpdateLoading}
					  paymentType="REGULAR"
					  selectedCurrency = {selectedCurrency}
          />
          <EditPriceDiscount
            isOpen={editDiscountPopup}
            onClose={closeEditDiscountPopup}
            batchData={batchData}
					  loader={batchPayUpdateLoading}
					  selectedCurrency = {selectedCurrency}
          />
        </>
      ) : (
        <div className={styles.noBatchTimingContainer}>
          <div className={styles.noDetailsText}>
            Please complete Batch Schedule details to proceed with Payment
          </div>
        </div>
      )}
    </div>
  );
}

export default BatchPaymentDetails;
