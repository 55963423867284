import React from "react";
import styles from "./LeadOverview.module.css";
import { Box, Skeleton } from "@mui/material";
import LeadOverviewGraph from "./LeadOverviewGraph";
import LeadConvertionSuccess from "./LeadOverviewMessages/LeadConvertionSuccess";
import LeadWarningMessage from "./LeadOverviewMessages/LeadWarningMessage";
import DemoBooking from "../DemoBooking/DemoBooking";
import Payment from "../../../../../components/Payment/Payment";

function LeadOverview(props: any) {
  const {
		employees,
		CreateDemo,
		demoTypes,
		selectedLeadData,
		RecordPayment,
		leadConvertionError,
		leadStatusUpdateError,
		UpdateLead,

		leadPaymentList,
		demoByCourseAndInstitute,
		createDemoLoading,
		CreateBatch,
		DeleteDemoBookedForLead,
		deleteDemoBookedLoading,
		deleteDemoBookedSuccess,
		createDemoSuccess,
		EditDemo,
		handleCancelModalOpen,
		leadListLoading,
		leadStages,
		paymentListLoading,
		UpdateLeadEmiDetails,
		SendPaymentLink,
		leadEmiDetailsLoading,
		leadEmiData,
		GetEmiDataOfLead,
		getEmiDataloading,
		DownloadPaymentReceipt,
		EditRecordPayment,
		selectedCurrency,
	} = props;
	return (
		<>
			<Box sx={{ maxHeight: 'calc(98vh - 174px)', overflowY: 'auto' }}>
				{!leadListLoading && leadStages !== null ? (
					<>
						{selectedLeadData?.enquirySwimlaneStatus === 'CONVERTED' && (
							<>
								{selectedLeadData.actionPending.batchAdded === true &&
									selectedLeadData.actionPending.studentCreated === true && (
										<LeadConvertionSuccess leadData={selectedLeadData} />
									)}
								{selectedLeadData.actionPending.paymentCompleted &&
								selectedLeadData.actionPending.studentCreated &&
								selectedLeadData.actionPending.batchAdded ? (
									''
								) : (
									<LeadWarningMessage
										type={
											selectedLeadData.actionPending.paymentCompleted ===
												true &&
											selectedLeadData.actionPending.studentCreated === false
												? 'WarningRed'
												: selectedLeadData.actionPending.batchAdded === false &&
												  selectedLeadData.actionPending.studentCreated ===
														false &&
												  selectedLeadData.actionPending.paymentCompleted ===
														false
												? 'WarningOrange'
												: selectedLeadData.actionPending.batchAdded === true &&
												  selectedLeadData.actionPending.paymentCompleted ===
														false
												? 'WarningYellow'
												: 'WarningYellow'
										}
									/>
								)}
							</>
						)}

						<Box
							sx={{
								background: '#fff',
								borderRadius: '3px',
								padding: '16px 16px',
								marginBottom: '8px',
							}}>
							<div className={styles.currentStageWrap}>
								<div className={styles.currentStageLabel}>Current Stage:</div>
								<div className={styles.currentStageValue}>
									{selectedLeadData?.enquirySwimlaneStatus
										?.toLowerCase()
										.replace(/_/g, ' ')}
								</div>
							</div>
							<LeadOverviewGraph selectedLeadData={selectedLeadData} />
						</Box>
						{demoByCourseAndInstitute === null &&
						selectedLeadData?.enquirySwimlaneStatus === 'DEMO' ? (
							<Skeleton animation='wave' variant='rounded' height={300} />
						) : (
							<>
								{selectedLeadData?.enquirySwimlaneStatus === 'DEMO' && (
									<>
										<DemoBooking
											selectedLeadData={selectedLeadData}
											employees={employees}
											CreateDemo={CreateDemo}
											demoTypes={demoTypes}
											demoByCourseAndInstitute={demoByCourseAndInstitute}
											createDemoLoading={createDemoLoading}
											CreateBatch={CreateBatch}
											DeleteDemoBookedForLead={DeleteDemoBookedForLead}
											deleteDemoBookedLoading={deleteDemoBookedLoading}
											deleteDemoBookedSuccess={deleteDemoBookedSuccess}
											EditDemo={EditDemo}
											createDemoSuccess={createDemoSuccess}
											handleCancelModalOpen={handleCancelModalOpen}
										/>
									</>
								)}
							</>
						)}

						{(selectedLeadData?.enquirySwimlaneStatus === 'NEGOTIATION' ||
							selectedLeadData?.enquirySwimlaneStatus === 'CONVERTED') && (
							<>
								{paymentListLoading || getEmiDataloading ? (
									<Skeleton animation='wave' variant='rounded' height={300} />
								) : (
									<Payment
										leadById={selectedLeadData}
										overviewTab={true}
										RecordPayment={RecordPayment}
										SendPaymentLink={SendPaymentLink}
										EditRecordPayment={EditRecordPayment}
										selectedCurrency={selectedCurrency}
									/>
								)}
							</>
						)}
					</>
				) : (
					<>
						<Skeleton animation='wave' variant='rounded' height={300} />
					</>
				)}
			</Box>
		</>
	);
}

export default LeadOverview;
