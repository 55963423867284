import React from "react";
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from "react-spinners";

function ListPopoverPagination(props: any) {
  const {
    openState,
    anchorState,
    handleClosePopover,
    handleListSearch,
    popupList,
    handleItemSelect,
    listType,
    handleNext,
    hasMore,
  } = props;

  const renderList = () => {
    return popupList?.map((item: any) => (
      <ListItem
        key={item?.id}
        button
        onClick={() => handleItemSelect(item)}
        sx={{ padding: "4px 14px " }}
      >
        <ListItemText
          primary={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
                color: "#667085",
                fontSize: "14PX",
              }}
            >
              {/* <div
                style={{
                  width: 24,
                  height: 24,
                  backgroundColor: "#EEEEF4",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 8,
                  textTransform: "uppercase",
                }}
              >
                {item?.formName?.[0]}
              </div> */}
              {`${item?.formName}`}
            </div>
          }
        />
      </ListItem>
    ));
  };

  return (
    <>
      <Popover
        open={openState}
        anchorEl={anchorState}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ width: "100%" }}
        PaperProps={{
          sx: {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "4PX",
            border: "1px solid #EEE",
          },
        }}
      >
        <TextField
          placeholder="Search"
          sx={{
            padding: "20px 20px 0px 20px",
            width: "100%",
          }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleListSearch}
          size="small"
        />
        <div
          style={{
            height: "250px",
            overflowY: "hidden",
          }}
        >
          {popupList && popupList?.length > 0 ? (
            <InfiniteScroll
              dataLength={popupList && popupList?.length}
              next={handleNext}
              hasMore={hasMore}
              loader={
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PuffLoader color="#36d7b7" />
                </Box>
              }
              //   height={300}
              height={250}
            >
              <List>{renderList()}</List>
            </InfiniteScroll>
          ) : (
            <></>
          )}
        </div>
      </Popover>
    </>
  );
}

export default ListPopoverPagination;
