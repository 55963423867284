import { connect } from "react-redux";
import { NurturePayDTO, NurturePayInitialStateDTO } from "../service/types";

import { RootState, TypedDispatch } from "../../../../redux/store";

import NurturePayDetails from "../components/NurturePayDetail/NurturePayDetails";
import NurturePayStages from "../components/NurturePayDetail/NurturePayStages";
import {
  getAppsByInstitute,
  installNurturePay,
} from "../service/IntegrationsSlice";

interface DispatchProps {
  InstallNurturePay(data: NurturePayDTO): void;
  GetAppsByInstitute(id: string): void;
}

const mapStateToProps = (state: RootState): NurturePayInitialStateDTO => {
  return {
    appsList: state.appIntegrations.appsList,
    nurturePayInstalledSuccess:
      state.appIntegrations.nurturePayInstalledSuccess,
    nurturePayInstalledError: state.appIntegrations.nurturePayInstalledError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    InstallNurturePay: (data: any) => {
      dispatch(installNurturePay(data));
    },
    GetAppsByInstitute: (id: string) => {
      dispatch(getAppsByInstitute(id));
    },
  };
};

export interface NurturePayStagesProps
  extends NurturePayInitialStateDTO,
    DispatchProps {}
export const NurturePayDetailsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(NurturePayStages);
