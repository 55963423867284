import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  Switch,
  SwitchProps,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "../components/Managestaffs.module.css";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import cross from "./cross.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuthUser } from "react-auth-kit";
import CountryCodePhoneInput from "../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { GetInstituteByIdRequest } from "../../InstituteProfile/service/institute.request";
import RemoveUserPopup from "../../Billing/components/subscription/RemovePopup/RemoveUserPopup";
import { resetUserRemovalSuccess } from "../../Billing/service/Billingslice";
import { useDispatch } from "react-redux";
import StaffSkeleton from "../../../../../components/Skeletons/StaffSkeleton";
import { getUserById } from "../../UserDetails/service/userDetailsSlice";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function StaffEditor(props: any) {
  const {
    CreateStaff,
    GetBranchList,
    add,
    GetStaffById,
    staffById,
    UpdateStaff,
    branchList,
    success,
    error,
    UpdateInstituteInLocal,
    setIsActive,
    backBtn,
    RemoveUserOrUserLicense,
    GetStaffList,
    createStaffLoading,
    updateStaffLoading,
    staffByIdLoading,
    GetRolesByInstitute,
    GetStaffListWithCount,
  } = props;
  const auth = useAuthUser();
  const user = auth();
  const navigate = useNavigate();
  const branches = useSelector((state: any) => state.branch.branchList);
  const staffs = useSelector((state: any) => state.staff.staffListWithCount);
  const userRemovalLoading = useSelector(
    (state: any) => state.billing.userRemovalLoading
  );
  const userRemovalSuccess = useSelector(
    (state: any) => state.billing.userRemovalSuccess
  );
  const { userById } = useSelector((state: any) => state.userDetails);
  const { rolesList } = useSelector((state: any) => state.appSettings);
  const combinedRoles = rolesList?.systemRoles?.concat(rolesList?.customRoles);

  const [staffDetails, setStaffDetails] = React.useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    role: "",
    roleId: "",
    branchName: "",
    branchId: "",
    instituteId: "",
    instituteName: "",
    status: "ACTIVE",
    isFirstTeamMember: false,
    id: "",
    createdUser: "",
    createdUserId: "",
    contactCountryCode: "91",
    isBillingAdmin: false,
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
  });
  const [selectedRole, setSelectedRole] = React.useState<any>(null);

  const [openLogoutModal, setOpenLogoutModal] = React.useState(false);
  const handleLogoutModalOpen = () => setOpenLogoutModal(true);
  const handleLogoutModalClose = () => setOpenLogoutModal(false);
  const userFromLocalStorage = user?.institute[0]?.instituteDetails;

  const [
    validatingFirstTimeBranchCreation,
    setValidatingFirstTimeBranchCreation,
  ] = React.useState<any>();

  React.useEffect(() => {
    const userFromLocalStorage = user?.institute[0]?.instituteDetails;

    GetInstituteByIdRequest(userFromLocalStorage?.id)
      ?.then((res: any) => {
        setValidatingFirstTimeBranchCreation(res?.data?.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (userFromLocalStorage && user && user.institute) {
      GetBranchList(userFromLocalStorage.id && userFromLocalStorage.id);
      GetStaffListWithCount(userFromLocalStorage.id && userFromLocalStorage.id);
      GetRolesByInstitute(userFromLocalStorage?.id);
    }
  }, [GetBranchList, branchList]);

  useEffect(() => {
    if (
      userFromLocalStorage &&
      user &&
      user.institute &&
      validatingFirstTimeBranchCreation
    ) {
      setStaffDetails({
        ...staffDetails,
        instituteId: userFromLocalStorage.id && userFromLocalStorage.id,
        instituteName:
          userFromLocalStorage.instituteName &&
          userFromLocalStorage.instituteName,
        isFirstTeamMember:
          validatingFirstTimeBranchCreation &&
          validatingFirstTimeBranchCreation?.teamAdded === true
            ? false
            : true,
        createdByName: add
          ? user.institute[0].firstName + " " + user.institute[0].lastName
          : "",
        createdById: add ? user.institute[0] && user.institute[0].id : "",
        createdUser: add
          ? user.institute[0].firstName + " " + user.institute[0].lastName
          : "",
        createdUserId: add ? user.institute[0] && user.institute[0].id : "",
      });
    }
  }, [validatingFirstTimeBranchCreation]);

  const [staffStatus, setStaffStatus] = React.useState<boolean>(
    staffDetails.status === "ACTIVE" ? true : false
  );
  const [formDisabled, setFormDisabled] = React.useState<boolean>(false);

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStatePhone, setValidStatePhone] = React.useState<boolean>(true);
  const [validStateLastName, setValidStateLastName] =
    React.useState<boolean>(true);
  const [validStateFirstName, setValidStateFirstName] =
    React.useState<boolean>(true);
  const [validStateRole, setValidStateRole] = React.useState<boolean>(true);
  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const setValidateStatusLastName = React.useCallback((val: boolean) => {
    setValidStateLastName(val);
  }, []);
  const setValidateStatusFirstName = React.useCallback((val: boolean) => {
    setValidStateFirstName(val);
  }, []);

  const defaultOption = branches
    ? branches?.find((branch: any) => branch.id === staffDetails.branchId)
    : "";
  const defaultRole = combinedRoles
    ? combinedRoles?.find((role: any) => role.id === staffDetails.roleId)
    : "";

  const handleChangeCheck = React.useCallback(
    async (event: any) => {
      setStaffStatus(!staffStatus);

      if (staffStatus) {
        setStaffDetails({
          ...staffDetails,
          [event.target.id]: "INACTIVE",
        });
      } else {
        setStaffDetails({
          ...staffDetails,
          [event.target.id]: "ACTIVE",
        });
      }
    },

    [staffStatus, staffDetails]
  );

  const onRoleChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setStaffDetails({
          ...staffDetails,
          role: value.roleName,
          roleId: value.id,
        });
        setValidStateRole(true);
      } else {
        setStaffDetails({
          ...staffDetails,
          role: "",
          roleId: "",
        });
        setValidStateRole(false);
      }
    },
    [staffDetails]
  );

  const onSelectChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setStaffDetails({
          ...staffDetails,
          branchId: value.id,
          branchName: value.branchName,
        });
        setValidStateBranch(true);
      } else {
        setStaffDetails({
          ...staffDetails,
          branchId: "",
          branchName: "",
        });
        setValidStateBranch(false);
      }
    },
    [staffDetails]
  );

  const handleBranchClose = React.useCallback(() => {
    setStaffDetails({
      ...staffDetails,
      branchName: "",
      branchId: "",
    });
  }, [staffDetails]);

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
      }
      setErrorType("client");

      const { id, value } = event.target;
      const lowercaseValue = id === "email" ? value.toLowerCase() : value;

      setStaffDetails({
        ...staffDetails,
        [id]: lowercaseValue,
      });
    },
    [staffDetails, errorType]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      !staffDetails.firstName && setValidStateFirstName(false);
      !staffDetails.lastName && setValidStateLastName(false);
      !staffDetails.email && setValidStateEmail(false);
      !staffDetails.branchName && setValidStateBranch(false);
      !staffDetails.role && setValidStateRole(false);

      const branchExists = branches.some(
        (branch: any) =>
          branch.branchName.toLowerCase() ===
          staffDetails.branchName.toLowerCase()
      );
      !branchExists && setValidStateBranch(false);

      branchExists &&
        staffDetails.role &&
        staffDetails.branchId &&
        staffDetails.firstName &&
        staffDetails.lastName &&
        (add
          ? CreateStaff(staffDetails)
          : UpdateStaff({
              ...staffDetails,
              updatedById: user?.institute[0]?.id,
              updatedByName: user?.firstName + " " + user?.lastName,
              contact:
                staffDetails?.contact.toString()?.length !== 12
                  ? ""
                  : staffDetails?.contact,
            }));
    } catch (error) {}
  };

  React.useEffect(() => {
    if (!add) {
      const pathname = window.location.pathname;
      const staffId = pathname.substring(pathname.lastIndexOf("/") + 1);
      GetStaffById(staffId);
      setFormDisabled(true);
    }
  }, [add, GetStaffById]);

  React.useEffect(() => {
    if (!add && staffById) {
      setStaffDetails({
        ...staffById,
        contact: `${staffById?.contactCountryCode || "91"}${
          staffById?.contactCountryCode
            ? staffById?.contact.replace(staffById?.contactCountryCode, "")
            : staffById?.contact.startsWith("91")
            ? staffById?.contact.replace("91", "")
            : staffById?.contact
        }`,
        contactCountryCode: staffById?.contactCountryCode || "91",
      });
      setStaffStatus(staffById.status === "ACTIVE" ? true : false);
    }
  }, [add, staffById]);

  const urlParams = new URLSearchParams(window.location.search);
  const defaultRoleId = urlParams.get("defaultRole");
  const defaultRoleData =
    defaultRoleId && combinedRoles
      ? combinedRoles?.find((item: any) => item.id == defaultRoleId)
      : null;
  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const defaultRole = urlSearchParams.get("defaultRole");

    if (!add && staffById && combinedRoles) {
      const role = combinedRoles?.find(
        (item: any) => item.id == staffById.roleId
      );
      if (role) {
        setSelectedRole(role);
      }
    } else if (add && combinedRoles && defaultRole) {
      const role = combinedRoles?.find((item: any) => item.id == defaultRole);
      if (role) {
        setSelectedRole(role);
        setStaffDetails({
          ...staffDetails,
          role: role?.roleName,
          roleId: role?.id,
        });
      }
    }
  }, [add, staffById, rolesList]);

  React.useEffect(() => {
    if (success !== true && success !== false) {
      if (staffDetails?.email && userById?.email) {
        dispatch<any>(getUserById(user?.institute?.[0]?.id));
      }

      if (userFromLocalStorage) {
        UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }

      if (window.location.pathname.includes("Billing")) {
        // If the URL contains "Billing"
        navigate("/app/manage/Billing/ManageSubscription");
      } else if (window.location.pathname.includes("Settings")) {
        navigate("/app/Settings/Rolesandpermission");
      } else {
        if (staffDetails.isFirstTeamMember === true) {
          navigate("/app/manage/CreateBatch", {
            state: {
              menuItemNumber: 4,
            },
          });
          localStorage.setItem("ShowFullSection", JSON.stringify(true));
        } else {
          if (!add) {
            navigate(`/app/manage/Teams?selectedStaff=${staffDetails?.id}`);
          } else {
            navigate(`/app/manage/Teams`);
          }
        }
      }
    }
  }, [success, navigate, staffDetails, UpdateInstituteInLocal]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!add && staffDetails !== staffById) {
        event.preventDefault();
        event.returnValue = null;
      }
    };
    if (!add && staffDetails !== staffById) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    const element = document.getElementById("Teams");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (window.location.href.includes("CreateTeam")) {
      setIsActive(3);
    }
    window.scrollTo(0, 0);
  }, [setIsActive]);

  const [removeUserDetails, setRemoveUserDetails] = React.useState({
    licenserId: "",
    benefitCode: "USER",
    instituteId: "",
    deleteLicense: false,
    assigedToId: "",
    assignedToName: "",
    branchId: null,
    branchName: null,
    updatedById: user?.institute[0]?.id,
    updatedByName: user?.firstName + " " + user?.lastName,
  });

  const [assignyError, setAssignyError] = React.useState<boolean>(false);
  const [removeUserModal, setRemoveUserModal] = React.useState(false);

  const openRemoveUserModal = () => {
    setRemoveUserModal(true);
  };

  const closeRemoveUserModal = () => {
    setRemoveUserModal(false);
    setAssignyError(false);
  };

  const handleLeadAssignee = React.useCallback(
    (user: any) => {
      setRemoveUserDetails({
        ...removeUserDetails,
        assigedToId: user?.id,
        assignedToName: user?.firstName + " " + user?.lastName,
      });
    },

    [removeUserDetails]
  );

  const handleRemoveUser = async (event: any) => {
    event.preventDefault();

    try {
      let isValid = true;

      if (
        (staffById?.leadCount > 0 || staffById?.taskCount > 0) &&
        removeUserDetails?.assigedToId === ""
      ) {
        setAssignyError(true);
        isValid = false;
      }

      if (isValid) {
        RemoveUserOrUserLicense(removeUserDetails);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!add && userFromLocalStorage && user && user.institute && staffById) {
      setRemoveUserDetails({
        ...removeUserDetails,
        instituteId: userFromLocalStorage.id && userFromLocalStorage.id,
        licenserId: staffById?.id,
      });
    }
  }, [staffById]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (userRemovalSuccess !== true && userRemovalSuccess !== false) {
      if (userFromLocalStorage) {
        UpdateInstituteInLocal(userFromLocalStorage && userFromLocalStorage.id);
      }

      navigate(`/app/manage/Teams?selectedStaff=${staffById?.id}`);
      setTimeout(() => {
        dispatch(resetUserRemovalSuccess());
      }, 3000);
    }
  }, [userRemovalSuccess, UpdateInstituteInLocal]);

  function handleCancel() {
    if (!add) {
      navigate(`/app/manage/Teams?selectedStaff=${staffById?.id}`);
    } else {
      navigate("/app/manage/Teams");
    }
  }

  return (
    <>
      <Container
        sx={{
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <Box className={styles.staffEditorWrapper}>
          {backBtn ? (
            <button className={styles.PageBackBtn} onClick={() => navigate(-1)}>
              <ArrowBackIcon
                sx={{
                  marginRight: "8px",
                  fontSize: "15PX",
                  color: "#667085",
                }}
              />
              Back
            </button>
          ) : (
            <></>
          )}

          {!add && staffByIdLoading ? (
            <StaffSkeleton />
          ) : (
            <div>
              <div
                className={""}
                style={{
                  borderBottom: "1px solid #EDEDED",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                id="Teams"
              >
                {add && (
                  <p
                    className={""}
                    style={{
                      color: "#142C44",
                      fontSize: "18px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      margin: "10px 0px",
                    }}
                  >
                    Add Team
                  </p>
                )}
                {!add && (
                  <p
                    className={""}
                    style={{
                      color: "#142C44",
                      fontSize: "20px",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      margin: "10px 0px",
                    }}
                  >
                    Edit Team
                  </p>
                )}
              </div>
              <Box sx={{ mt: 4 }}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={{ lg: 3, md: 3, xs: 1, sm: 1 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={6}>
                      <NewFormTextInput
                        validate={true}
                        setValidStatus={setValidateStatusFirstName}
                        type={"text"}
                        id="firstName"
                        label="First Name"
                        value={staffDetails.firstName}
                        onChange={(event: any) => {
                          setStaffDetails({
                            ...staffDetails,
                            [event.target.id]: event.target.value.replace(
                              /\d/,
                              ""
                            ),
                          });
                        }}
                        error={!validStateFirstName}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        require={true}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <NewFormTextInput
                        validate={true}
                        setValidStatus={setValidateStatusLastName}
                        type={"text"}
                        id="lastName"
                        label="Last Name"
                        value={staffDetails.lastName}
                        onChange={(event: any) => {
                          setStaffDetails({
                            ...staffDetails,
                            [event.target.id]: event.target.value.replace(
                              /\d/,
                              ""
                            ),
                          });
                        }}
                        error={!validStateLastName}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        require={true}
                        inputProps={{ maxLength: 20 }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontSize: "12px",
                          color: "#142C44",
                          fontWeight: "500",
                          marginBottom: "5px",
                          fontFamily: "Poppins",
                          display: "flex",
                        }}
                      >
                        Phone Number
                      </InputLabel>
                      <CountryCodePhoneInput
                        value={staffDetails.contact}
                        onChange={(event: any, country: any) => {
                          if (event !== "") {
                            setValidStatePhone(
                              isValidPhoneNumber(
                                event.replace(country.dialCode, ""),
                                country.countryCode.toUpperCase()
                              )
                            );
                          }
                          if (event.replace(country.dialCode, "") === "") {
                            setValidStatePhone(true);
                          }

                          setStaffDetails({
                            ...staffDetails,
                            contact: event,
                            contactCountryCode: country.dialCode,
                          });
                        }}
                        error={validStatePhone}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <NewFormTextInput
                        disabled={formDisabled}
                        validate={true}
                        setValidStatus={setValidateStatusEmail}
                        label="Email Address"
                        type="email"
                        id={"email"}
                        error={!validStateEmail}
                        value={staffDetails.email}
                        onChange={handleChange}
                        showErrorMessage={showErrMsz}
                        errorType={errorType}
                        autoComplete="true"
                        require={true}
                        inputProps={{
                          maxLength: 50,
                          style: {
                            backgroundColor: formDisabled
                              ? "#F2F2F2"
                              : "inherit",
                            borderRadius: "10px",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontSize: "12px",
                          color: "#142C44",
                          fontWeight: "500",
                          marginBottom: "5px",
                          fontFamily: "Poppins",
                          display: "flex",
                        }}
                      >
                        Role
                        <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>
                      </InputLabel>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="formControl"
                        size="small"
                        sx={{
                          ".css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                            {
                              padding: "0px 9px",
                            },
                          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                            borderColor: !validStateRole
                              ? "rgb(244 67 54)"
                              : "",
                          },
                          ".css-1oz3c3t-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: !validStateRole
                                ? "rgb(244 67 54)"
                                : "",
                              ":hover": {
                                borderColor: !validStateRole
                                  ? "rgb(244 67 54)"
                                  : "",
                              },
                            },
                          ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                            {
                              ":hover": {
                                borderColor: !validStateRole
                                  ? "rgb(244 67 54)"
                                  : "",
                              },
                            },
                        }}
                        error={!validStateRole}
                      >
                        {combinedRoles && (
                          <Autocomplete
                            disablePortal
                            id="role"
                            options={combinedRoles}
                            getOptionLabel={(option: any) =>
                              option.roleName || ""
                            }
                            fullWidth
                            onChange={onRoleChange}
                            size="small"
                            value={defaultRole || null}
                            renderInput={(params) => {
                              if (!defaultRole) {
                                params.InputProps.endAdornment = null;
                              }
                              return (
                                <TextField variant="outlined" {...params} />
                              );
                            }}
                            disabled={defaultRoleData !== null}
                          />
                        )}
                      </FormControl>

                      {validStateRole === false && (
                        <FormHelperText error={true}>
                          Field required
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          fontSize: "12px",
                          color: "#142C44",
                          fontWeight: "500",
                          marginBottom: "5px",
                          fontFamily: "Poppins",
                          display: "flex",
                        }}
                      >
                        Branch
                        <p style={{ margin: "0px 4px", color: "#D32F2F" }}>*</p>
                      </InputLabel>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="formControl"
                        size="small"
                        sx={{
                          ".css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                            {
                              padding: "0px 9px",
                            },
                          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                            borderColor: !validStateBranch
                              ? "rgb(244 67 54)"
                              : "",
                          },
                          ".css-1oz3c3t-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: !validStateBranch
                                ? "rgb(244 67 54)"
                                : "",
                              ":hover": {
                                borderColor: !validStateBranch
                                  ? "rgb(244 67 54)"
                                  : "",
                              },
                            },
                          ".MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root":
                            {
                              ":hover": {
                                borderColor: !validStateBranch
                                  ? "rgb(244 67 54)"
                                  : "",
                              },
                            },
                        }}
                        error={!validStateBranch}
                      >
                        {branches && (
                          <Autocomplete
                            disablePortal
                            id="branchId"
                            options={branches}
                            getOptionLabel={(option: any) =>
                              option.branchName || ""
                            }
                            fullWidth
                            onChange={onSelectChange}
                            size="small"
                            value={defaultOption || ""}
                            renderInput={(params) => {
                              if (!defaultOption) {
                                params.InputProps.endAdornment = null;
                              }
                              return (
                                <TextField variant="outlined" {...params} />
                              );
                            }}
                          />
                        )}
                      </FormControl>
                      {validStateBranch === false && (
                        <FormHelperText error={true}>
                          Field required
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      {userFromLocalStorage?.isProfileCompleted && !add && (
                        <Box>
                          <FormControl
                            fullWidth
                            variant="standard"
                            className="formControl"
                            sx={{ width: "auto" }}
                          >
                            <FormControlLabel
                              style={{
                                justifyContent: "flex-end",
                                marginLeft: 0,
                                color: "#3F3E43",
                                marginTop: "0px",
                                gap: 20,
                              }}
                              onClick={() => {
                                if (
                                  (staffById?.leadCount > 0 ||
                                    staffById?.taskCount > 0) &&
                                  staffStatus
                                ) {
                                  openRemoveUserModal();
                                }
                              }}
                              control={
                                <IOSSwitch
                                  sx={{ m: 1 }}
                                  checked={staffStatus}
                                  id="status"
                                  onChange={(e) => {
                                    // Check the condition before allowing the change
                                    if (
                                      !(
                                        staffById?.leadCount > 0 ||
                                        staffById?.taskCount > 0
                                      ) ||
                                      !staffStatus
                                    ) {
                                      handleChangeCheck(e); // Only allow the change if the condition is not met
                                    }
                                  }}
                                />
                              }
                              label="Active"
                              labelPlacement="start"
                            />
                          </FormControl>
                        </Box>
                      )}
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "6px",
                      marginTop: "24px",
                    }}
                  >
                    {validatingFirstTimeBranchCreation?.isProfileCompleted && (
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        variant="outlined"
                        onClick={handleLogoutModalOpen}
                      >
                        Cancel
                      </Button>
                    )}

                    {add && (
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        variant="contained"
                        type="submit"
                        disabled={createStaffLoading}
                      >
                        {createStaffLoading ? (
                          <CircularProgress size={20} color={"inherit"} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    )}

                    {!add && (
                      <Button
                        sx={{ textTransform: "capitalize", minWidth: "130px" }}
                        variant="contained"
                        type="submit"
                        disabled={updateStaffLoading}
                      >
                        {updateStaffLoading ? (
                          <CircularProgress size={20} color={"inherit"} />
                        ) : (
                          "Save changes"
                        )}
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </div>
          )}

          <Modal
            open={openLogoutModal}
            onClose={handleLogoutModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "57%",
                left: "58%",
                transform: "translate(-50%, -50%)",
                width: 380,
                bgcolor: "background.paper",
                border: "none",
                boxShadow:
                  "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
                borderRadius: "12px",
                padding: "12px 24px 24px 24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: "-22px",
                  marginTop: "-4px",
                }}
              >
                <Button
                  sx={{ minWidth: "40px" }}
                  onClick={handleLogoutModalClose}
                >
                  <img src={cross} alt="cross" />
                </Button>
              </div>
              <div>
                <div className={styles.cancelFormPopupTitle}>
                  Are you sure you want to Cancel?
                </div>
                <div className={styles.cancelFormPopupContent}>
                  The changes you made will be discarded.
                </div>
                <div className={styles.cancelFormPopupBtnsSplit}>
                  <button
                    className={styles.cancelFormPopupNoBtn}
                    onClick={handleLogoutModalClose}
                  >
                    No
                  </button>
                  <button
                    className={styles.cancelFormPopupYesBtn}
                    onClick={handleCancel}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </Box>
          </Modal>

          <RemoveUserPopup
            open={removeUserModal}
            onClose={closeRemoveUserModal}
            staffs={staffs && staffs}
            userEditSelect={staffById && staffById}
            handleLeadAssignee={handleLeadAssignee}
            // handleRemoveLicenseCheck={handleRemoveLicenseCheck}
            removeUserDetails={removeUserDetails}
            removeButtonClick={handleRemoveUser}
            assignyError={assignyError}
            userRemovalLoading={userRemovalLoading}
            billing={false}
          />
        </Box>
      </Container>
    </>
  );
}
