import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";

function AutoCompleteField(props: any) {
  const {
    options,
    onChange,
    disableClearable,
    value,
    required,
    label,
    optionLabel,
    id,
    validState,
    disabled,
  } = props;
  return (
    <>
      <FormControl fullWidth variant="standard" className="formControl">
        <div
          style={{
            fontSize: "12px",
            color: "#142C44",
            fontWeight: "500",
            fontFamily: "Poppins",
            display: "flex",
            margin: "0px 0px 5px",
          }}
        >
          {label}
          {required === true && (
            <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
          )}
        </div>

        {options && (
          <Autocomplete
            disablePortal
            id={id}
            options={options}
            getOptionLabel={(option: any) => option[optionLabel] || ""}
            fullWidth
            onChange={onChange}
            value={value || null}
            disableClearable={disableClearable}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                size="small"
                error={!validState}
              />
            )}
            ListboxProps={{
              style: {
                maxHeight: "230px",
                fontSize: "14px",
                color: "142C44",
                fontFamily: "poppins",
                textTransform: "capitalize",
              },
            }}
            // PaperComponent={(props) => (
            //   <Paper
            //     sx={{
            //       background: "#FFF",
            //       border: "1px solid #E4E4E7",
            //       fontFamily: "poppins",
            //       fontSize: "14px",
            //       textTransform: "capitalize",
            //       color: "142C44",
            //       fontWeight: "400",
            //       padding: "0 4px !important",
            //       margin: "0",
            //       boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
            //       maxHeight: "300px",
            //       overflowY: "auto",
            //     }}
            //     {...props}
            //   />
            // )}
            disabled={disabled}
          />
        )}
        {validState === false && (
          <FormHelperText error={true}>Field required</FormHelperText>
        )}
      </FormControl>
    </>
  );
}

export default AutoCompleteField;
