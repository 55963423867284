import React, { useEffect, useState } from "react";
import { CircularProgress, Radio, RadioGroup } from "@mui/material";
import nurtureLogo from "../components/Assets/nurtureLogo.svg";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import CountryCodePhoneInput from "../../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Container,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  SnackbarOrigin,
  FormLabel,
  FormGroup,
} from "@mui/material";
import { ErrorType } from "../../../../../../components/FormTextInput/types";
import { isValidPhoneNumber } from "libphonenumber-js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useAuthUser } from "react-auth-kit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import archivedImg from "../components/Assets/archive.svg";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

interface DynamicFormData {
  [key: string]: string;
}

interface PublicWebformField {
  fieldName: string;
  default: string;
  fieldType: string;
  options?: Array<{ id: string; name: string }>;
}

interface PublicWebform {
  fields: PublicWebformField[];
}
interface IUpdatedFormData {
  name?: string;
  firstName?: string;
  lastName?: string;
  createdById: string;
  createdByName: string;
  status: string;
  enquiryPriority: string;
  instituteId: string;
  contactNumber?: string;
  phone?: string;
  enquirySource: string;
  assignedToId: string;
  assignedToName: string;
  branchId: string;
  branchName: string;
  webformId: string;
  campaignId: string;
  otherDetails?: [];
  demoTiming?: string;
  instituteName?: string;
  courseName?: string;
  voucher?: string;
  webformName?: string;
}

const PublicWebformForm = (props: any) => {
  const {
    GetPublicWebformById,
    publicWebform,
    CreateContact,
    contactCreateLoading,
  } = props;

  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [fieldValidations, setFieldValidations] = useState<any>({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const [selectedBranch, setSelectedBranch] = useState<any>();
  const [selectedBatch, setSelectedBatch] = useState<any>();
  const [batchException, setBatchException] = useState(true);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const auth = useAuthUser();
  const authUser = auth();
  const institutionDetails = authUser?.institute[0]?.instituteDetails;

  React.useEffect(() => {
    const currentUrl = window.location.href;
    const parts = currentUrl.split("/");
    let lastPart = parts[parts.length - 1];
    lastPart = lastPart.split("?")[0];

    GetPublicWebformById(lastPart);
  }, [GetPublicWebformById]);

  const [snackState, setSnackState] = useState({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "",
    snackSeverity: "info",
  });

  React.useEffect(() => {
    const initialFormData: DynamicFormData = {};
    if (publicWebform && publicWebform.fields) {
      publicWebform.fields.forEach((field: any) => {
        initialFormData[field.fieldName] = field.default || "";
      });
      const isBatchExist = publicWebform.fields?.find(
        (d: any) => d?.DBModel === "BATCH" && d?.isCreateLead === true
      );
      if (isBatchExist) {
        setBatchException(true);
      } else {
        setBatchException(false);
      }
    }
    setFormData(initialFormData);
  }, [publicWebform.fields]);

  const toCamelCase = (str: any) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: any, index: any) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };

  const handleChange = (event: any, fieldName: string) => {
    const value = event.target.value;
    setFormData((prevData: any) => {
      if (!["name", "email"].includes(fieldName)) {
        const otherDetails = [...(prevData?.otherDetails || [])];
        const existingDetailIndex = otherDetails.findIndex(
          (detail) => fieldName in detail
        );

        if (existingDetailIndex !== -1) {
          otherDetails[existingDetailIndex][fieldName] = value;
        } else {
          otherDetails.push({ [fieldName]: value });
        }
        setFieldValidations((prevValidations: any) => ({
          ...prevValidations,
          [fieldName]: value.trim() !== "" || false,
        }));
        return {
          ...prevData,
          otherDetails,
        };
      } else {
        setFieldValidations((prevValidations: any) => ({
          ...prevValidations,
          [fieldName]: value.trim() !== "" || false,
        }));
        return {
          ...prevData,
          [fieldName]: value,
        };
      }
    });
  };

  const handleCBBChange = (event: any, field: any) => {
    const value = event.target.value;
    setFormData((prevData: any) => {
      if (!["name", "email"].includes(field?.fieldName)) {
        const otherDetails = [...(prevData?.otherDetails || [])];
        const existingDetailIndex = otherDetails.findIndex(
          (detail) => field?.fieldName in detail
        );
        if (existingDetailIndex !== -1) {
          otherDetails[existingDetailIndex][field?.fieldName] = value;
          // Add domainName to the existing detail object
          otherDetails[existingDetailIndex]["domainName"] = field?.DBModel;
        } else {
          otherDetails.push({
            [field?.fieldName]: value,
            domainName: field?.DBModel,
          });
        }
        setFieldValidations((prevValidations: any) => ({
          ...prevValidations,
          [field?.fieldName]: value.trim() !== "" || false,
        }));
        return {
          ...prevData,
          otherDetails,
        };
      } else {
        setFieldValidations((prevValidations: any) => ({
          ...prevValidations,
          [field?.fieldName]: value.trim() !== "" || false,
        }));
        return {
          ...prevData,
          [field?.fieldName]: value,
        };
      }
    });
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    val: any
  ) => {
    const checked = event.target.checked;

    setFormData((prevData: any) => {
      if (!["name", "email"].includes(fieldName)) {
        const otherDetails = [...(prevData?.otherDetails || [])];
        const existingDetailIndex = otherDetails.findIndex(
          (detail) => fieldName in detail
        );

        if (existingDetailIndex !== -1) {
          if (Array.isArray(otherDetails[existingDetailIndex][fieldName])) {
            if (checked) {
              otherDetails[existingDetailIndex][fieldName].push(val);
            } else {
              otherDetails[existingDetailIndex][fieldName] = otherDetails[
                existingDetailIndex
              ][fieldName].filter((value: any) => value !== val);
            }
          } else {
            otherDetails[existingDetailIndex][fieldName] = checked ? [val] : [];
          }
        } else {
          otherDetails.push({
            [fieldName]: checked ? [val] : [],
          });
        }

        setFieldValidations((prevValidations: any) => ({
          ...prevValidations,
          [fieldName]: checked || false,
        }));

        return {
          ...prevData,
          otherDetails,
        };
      }
      // Handle other fields like "name" and "email"
      return {
        ...prevData,
        [fieldName]: val,
      };
    });
  };

  function convertTimestampToDateFormat(timestamp: any) {
    const date = new Date(timestamp * 1000);
    const options: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      separator: "-",
    };
    const dateFormatter = new Intl.DateTimeFormat("en-IN", options);
    const formattedDate = dateFormatter
      .format(date)
      .replace(/\//g, options.separator);
    return formattedDate;
  }

  const handleDateChange = (val: any, fieldName: string) => {
    const value = val?.unix();
    setFormData((prevData: any) => {
      const otherDetails = [...(prevData?.otherDetails || [])];
      const existingDetailIndex = otherDetails.findIndex(
        (detail) => fieldName in detail
      );

      if (existingDetailIndex !== -1) {
        otherDetails[existingDetailIndex][fieldName] = value;
      } else {
        otherDetails.push({ [fieldName]: value });
      }
      setFieldValidations((prevValidations: any) => ({
        ...prevValidations,
        [fieldName]: value || false,
      }));
      return {
        ...prevData,
        otherDetails,
      };
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);
    setFormSubmitted(true);

    const updatedFormData: any = {
      ...formData,
      createdById: publicWebform.createdById,
      createdByName: publicWebform.createdByName,
      status: publicWebform.status,
      enquiryPriority: "NORMAL",
      instituteId: publicWebform.instituteId,
      enquirySource: "WEBFORM",
      webformId: publicWebform.id,
      campaignId: "",
      courseName: "",
      branchId:
        publicWebform?.type === "BRANCH_SPECIFIC"
          ? publicWebform?.branchId
          : selectedBranch,
      branchName: "",
      demoTiming: "",
      instituteName: "",
      webformName: publicWebform?.formName,
      isCreateLead: publicWebform?.isCreateLead,
      assignedToName: publicWebform?.assignedToName,
      assignedToId: publicWebform?.assignedToId,
      courseId: selectedCourse,
      batchId: selectedBatch,
    };

    if (updatedFormData.phone) {
      updatedFormData.contactNumber = updatedFormData.phone;
      delete updatedFormData.phone;
    }

    if (updatedFormData.name) {
      const nameParts = updatedFormData.name.split(" ");
      updatedFormData.firstName = nameParts.shift() || "";
      updatedFormData.lastName = nameParts.join(" ") || "";
      delete updatedFormData.name;
    }
    if (
      updatedFormData?.otherDetails &&
      updatedFormData?.otherDetails?.length > 0
    ) {
      const transformedData: any = {
        ...updatedFormData,
        otherDetails:
          updatedFormData.otherDetails &&
          updatedFormData.otherDetails.map((item: any) => {
            const transformedItem: any = {};
            for (const key in item) {
              if (typeof item[key] === "number") {
                transformedItem[key] = convertTimestampToDateFormat(item[key]);
              } else {
                transformedItem[key] = item[key];
              }
            }
            return transformedItem;
          }),
      };
      validStateContact === false
        ? setValidStateContact(false)
        : CreateContact(transformedData);
    } else {
      validStateContact === false
        ? setValidStateContact(false)
        : CreateContact(updatedFormData);
    }
    setLoading(false);
  };

  const closeSnack = () => {
    setSnackState({ ...snackState, snackOpen: false });
  };
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateContact, setValidStateContact] =
    React.useState<boolean>(true);

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const handleFocus = (field: any) => {
    if (field.required && filteredOptions?.length === 0) {
      setBatchException(false);
    } else if (
      field.required &&
      filteredOptions?.length > 0 &&
      selectedBatch !== undefined
    ) {
      setBatchException(false);
    } else if (field.required === false) {
      setBatchException(false);
    } else {
      setBatchException(true);
    }
  };

  useEffect(() => {
    let options = [];
    const field =
      publicWebform &&
      publicWebform?.fields &&
      publicWebform?.fields?.filter((field: any) => field?.DBModel === "BATCH");
    if (field && field?.length > 0) {
      if (selectedBranch !== undefined && selectedCourse !== undefined) {
        options =
          field &&
          field[0]?.options?.filter(
            (d: any) =>
              d?.courseId === selectedCourse && d?.branchId === selectedBranch
          );
      } else if (selectedCourse !== undefined && selectedBranch === undefined) {
        options =
          field &&
          field[0]?.options?.filter((d: any) => d?.courseId === selectedCourse);
      } else if (selectedCourse === undefined && selectedBranch !== undefined) {
        options =
          field &&
          field[0]?.options?.filter((d: any) => d?.branchId === selectedBranch);
      }
      setFilteredOptions(options);
    }
  }, [selectedCourse, selectedBranch]);

  const renderFormFields = () => {
    if (!publicWebform || !publicWebform.fields) {
      return null;
    }

    return publicWebform.fields.map((field: any, index: number) => {
      if (!field || typeof field.fieldName !== "string") {
        return null;
      }
      switch (field.fieldType) {
        case "phone":
          return (
            <div style={{ marginBottom: "10px" }} key={index}>
              <div
                style={{
                  fontSize: "12px",
                  color: "#142C44",
                  fontWeight: "500",
                  marginBottom: "5px",
                  textTransform: "capitalize",
                }}
              >
                {
                  <span>
                    {`${field.fieldName.toString().toUpperCase()} `}
                    <span style={{ color: "red" }}>
                      {field?.required ? "*" : ""}
                    </span>
                  </span>
                }
              </div>
              <CountryCodePhoneInput
                fieldName={field.fieldName}
                value={formData[field.fieldName]}
                onChange={(event: any, country: any) => {
                  setFormData((prevData: any) => {
                    if (field.fieldName === "phone") {
                      return {
                        ...prevData,
                        [field?.fieldName]: event,
                        countryCode: country.dialCode,
                      };
                    } else {
                      const otherDetails = [...(prevData?.otherDetails || [])];
                      const existingDetailIndex = otherDetails.findIndex(
                        (detail) => field?.fieldName in detail
                      );

                      if (existingDetailIndex !== -1) {
                        otherDetails[existingDetailIndex][field?.fieldName] =
                          event;
                      } else {
                        otherDetails.push({ [field?.fieldName]: event });
                      }
                      return {
                        ...prevData,
                        otherDetails,
                      };
                    }
                  });
                  if (event !== "" && field?.required) {
                    setValidStateContact(
                      isValidPhoneNumber(
                        event.replace(country.dialCode, ""),
                        country.countryCode.toUpperCase()
                      )
                    );
                  }
                }}
                error={field?.required ? validStateContact : true}
              />
            </div>
          );
        case "text":
          return (
            <Box sx={{ marginBottom: 1, width: "100%" }} key={index}>
              <NewFormTextInput
                key={index}
                name={field.fieldName}
                label={
                  <span>
                    {`${field.fieldName.toString().toUpperCase()} `}
                    <span style={{ color: "red" }}>
                      {field?.required ? "*" : ""}
                    </span>
                  </span>
                }
                value={
                  formData?.otherDetails
                    ? formData?.otherDetails?.find(
                        (detail: any) => detail[field.fieldName]
                      )?.[field.fieldName]
                    : formData[field.fieldName] || ""
                }
                onChange={(e: any) => {
                  const regex = /^[a-zA-Z][a-zA-Z\s]*$/;
                  (regex.test(e.target.value) || e.target.value === "") &&
                    handleChange(e, field.fieldName);
                }}
                placeholder={`Enter your ${field.fieldName}` || ""}
                type={field.fieldType}
              />
              {field?.required &&
                !fieldValidations[field.fieldName] &&
                formSubmitted && (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0px 0px 0px",
                      fontSize: "12px",
                    }}
                  >
                    Field Required
                  </div>
                )}
            </Box>
          );
        case "email":
          return (
            <Box sx={{ marginBottom: 2, width: "100%" }} key={index}>
              <NewFormTextInput
                validate={true}
                key={index}
                name={field.fieldName}
                label={
                  <span>
                    {`${field.fieldName.toString().toUpperCase()} `}
                    <span style={{ color: "red" }}>
                      {field?.required ? "*" : ""}
                    </span>
                  </span>
                }
                value={
                  formData?.otherDetails
                    ? formData?.otherDetails?.find(
                        (detail: any) => detail[field.fieldName]
                      )?.[field.fieldName]
                    : formData[field.fieldName] || ""
                }
                onChange={(e: any) => handleChange(e, field.fieldName)}
                placeholder={`Enter your ${field.fieldName}` || ""}
                type={field.fieldType}
                setValidStatus={setValidateStatusEmail}
                error={!validStateEmail}
                showErrorMessage={showErrMsz}
                errorType={errorType}
              />
            </Box>
          );
        case "select":
          return (
            <Box sx={{ margin: "5px 0px", width: "100%" }} key={index}>
              {field?.DBModel === "BATCH" ? (
                <FormControl
                  fullWidth
                  key={index}
                  margin="normal"
                  sx={{ marginTop: 0 }}
                >
                  <InputLabel>
                    <span
                      style={{
                        background: "white",
                        zIndex: "99999",
                        fontSize: "12px",
                      }}
                    >
                      {`${"Choose the "}${
                        field.fieldName.charAt(0).toUpperCase() +
                        field.fieldName.slice(1)
                      } `}
                      <span style={{ color: "red" }}>
                        {field?.required ? "*" : ""}
                      </span>
                    </span>
                  </InputLabel>
                  <Select
                    value={
                      formData?.otherDetails
                        ? formData?.otherDetails?.find(
                            (detail: any) => detail[field.fieldName]
                          )?.[field.fieldName]
                        : formData[field.fieldName] || ""
                    }
                    onChange={(e) => {
                      handleCBBChange(e, field);
                      field?.DBModel === "BATCH" &&
                        setSelectedBatch(e?.target?.value);
                    }}
                    label={field.fieldName.toUpperCase()}
                    placeholder={`Select ${field.fieldName}`}
                    variant="outlined"
                    sx={{
                      height: "48px",
                    }}
                    MenuProps={{
                      style: {
                        fontSize: "16px",
                      },
                      PaperProps: {
                        style: {
                          boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                          border: "1px solid #D4D4D8",
                          borderRadius: "6PX",
                          maxHeight: "250px",
                          overflowY: "scroll",
                        },
                      },
                    }}
                    onFocus={() => handleFocus(field)}
                  >
                    <MenuItem value="" disabled>
                      {`Select ${field.fieldName}`}
                    </MenuItem>
                    {filteredOptions &&
                      filteredOptions?.map((option: any) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl
                  fullWidth
                  key={index}
                  margin="normal"
                  sx={{ marginTop: 0 }}
                >
                  <InputLabel>
                    <span
                      style={{
                        background: "white",
                        zIndex: "99999",
                        fontSize: "12px",
                      }}
                    >
                      {`${"Choose the "}${
                        field.fieldName.charAt(0).toUpperCase() +
                        field.fieldName.slice(1)
                      } `}
                      <span style={{ color: "red" }}>
                        {field?.required ? "*" : ""}
                      </span>
                    </span>
                  </InputLabel>
                  <Select
                    value={
                      formData?.otherDetails
                        ? formData?.otherDetails?.find(
                            (detail: any) => detail[field.fieldName]
                          )?.[field.fieldName]
                        : formData[field.fieldName] || ""
                    }
                    onChange={(e: any) => {
                      handleCBBChange(e, field);
                      field?.DBModel === "BRANCH" &&
                        setSelectedBranch(e?.target?.value);
                      field?.DBModel === "COURSE" &&
                        setSelectedCourse(e?.target?.value);
                    }}
                    label={field.fieldName.toUpperCase()}
                    placeholder={`Select ${field.fieldName}`}
                    variant="outlined"
                    sx={{
                      height: "48px",
                    }}
                    MenuProps={{
                      style: {
                        fontSize: "16px",
                      },
                      PaperProps: {
                        style: {
                          boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                          border: "1px solid #D4D4D8",
                          borderRadius: "6PX",
                          maxHeight: "250px",
                          overflowY: "scroll",
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      {`Select ${field.fieldName}`}
                    </MenuItem>
                    {field.options?.map((option: any) => (
                      <MenuItem
                        key={option.id}
                        value={
                          field.DBModel === "COURSE" ||
                          field?.DBModel === "BATCH" ||
                          field?.DBModel === "BRANCH"
                            ? option.id
                            : option?.name
                        }
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {field?.required &&
                !fieldValidations[field.fieldName] &&
                formSubmitted && (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0px 0px 0px",
                      fontSize: "12px",
                    }}
                  >
                    Field Required
                  </div>
                )}
            </Box>
          );
        case "checkbox":
          return (
            <Box sx={{ marginBottom: 2, width: "100%" }} key={index}>
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  sx={{
                    fontSize: "12px",
                    color: "#142C44",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    "&.Mui-focused": {
                      color: "#142C44", // Ensures focused state doesn't change color
                    },
                  }}
                >
                  <span>
                    {`${
                      field.fieldName.charAt(0).toUpperCase() +
                      field.fieldName.slice(1)
                    } `}
                    <span style={{ color: "red" }}>
                      {field?.required ? "*" : ""}
                    </span>
                  </span>
                </FormLabel>
                {/* <FormGroup>
                  {field.options?.map((option: any) => (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Checkbox
                          checked={
                            formData?.otherDetails
                              ? // ? formData?.otherDetails?.find(
                                //     (detail: any) =>
                                //       detail[field.fieldName] === option?.name
                                //   )
                                formData?.otherDetails?.find(
                                  (detail: any) =>
                                    Array.isArray(detail[field.fieldName]) &&
                                    detail[field.fieldName].includes(
                                      option?.name
                                    )
                                )
                              : false
                          }
                          onChange={(e) =>
                            handleCheckBoxChange(
                              e,
                              field.fieldName,
                              option.name
                            )
                          }
                        />
                      }
                      label={option.name}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        "& .MuiTypography-root": {
                          marginBottom: "5px",
                          marginTop: "7px",
                        },
                      }}
                    />
                  ))}
                </FormGroup> */}
                <FormGroup>
                  {field.options?.map((option: any) => (
                    <div
                      key={option.id}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginLeft: "-12px",
                      }}
                    >
                      <Checkbox
                        checked={
                          formData?.otherDetails
                            ? formData?.otherDetails?.find(
                                (detail: any) =>
                                  Array.isArray(detail[field.fieldName]) &&
                                  detail[field.fieldName].includes(option?.name)
                              )
                            : false
                        }
                        onChange={(e) =>
                          handleCheckBoxChange(e, field.fieldName, option.name)
                        }
                      />
                      <span
                        style={{
                          marginBottom: "5px",
                          marginTop: "7px",
                          marginLeft: "8px",
                          cursor: "default",
                        }}
                      >
                        {option.name}
                      </span>
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
              {field?.required &&
                !fieldValidations[field.fieldName] &&
                formSubmitted && (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0px 0px 0px",
                      fontSize: "12px",
                    }}
                  >
                    Field Required
                  </div>
                )}
            </Box>
          );

        case "radio":
          return (
            <Box sx={{ marginBottom: 2, width: "100%" }} key={index}>
              <FormControl key={index} component="fieldset">
                <div
                  style={{
                    fontSize: "12px",
                    color: "#142C44",
                    fontWeight: "500",
                    marginBottom: "5px",
                    textTransform: "capitalize",
                  }}
                >
                  <span>
                    {`${
                      field.fieldName.charAt(0).toUpperCase() +
                      field.fieldName.slice(1)
                    } `}
                    <span style={{ color: "red" }}>
                      {field?.required ? "*" : ""}
                    </span>
                  </span>
                </div>
                <RadioGroup
                  aria-label={field.fieldName}
                  name={field.fieldName}
                  value={
                    formData?.otherDetails
                      ? formData?.otherDetails?.find(
                          (detail: any) => detail[field.fieldName]
                        )?.[field.fieldName]
                      : formData[field.fieldName] || ""
                  }
                  onChange={(e) => handleChange(e, field.fieldName)}
                >
                  <div style={{ marginTop: "0px" }}>
                    {field.options?.map((option: any, idx: any) => (
                      <FormControlLabel
                        key={idx}
                        value={option.id}
                        control={<Radio />}
                        label={option.name}
                      />
                    ))}
                  </div>
                </RadioGroup>
              </FormControl>
              {field?.required &&
                !fieldValidations[field.fieldName] &&
                formSubmitted && (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0px 0px 0px",
                      fontSize: "12px",
                    }}
                  >
                    Field Required
                  </div>
                )}
            </Box>
          );

        case "date":
          return (
            <Box key={index} sx={{ marginBottom: 2, width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    zIndex: "99999999 !import",
                    "& .MuiInputLabel-root": {
                      color: "#CACACA !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#CACACA !important",
                      },
                    },
                  }}
                  label={
                    <span>
                      {`${
                        field.fieldName.charAt(0).toUpperCase() +
                        field.fieldName.slice(1)
                      } `}
                      <span style={{ color: "red" }}>
                        {field?.required ? "*" : ""}
                      </span>
                    </span>
                  }
                  format="DD-MMM-YYYY"
                  onChange={(date) => handleDateChange(date, field.fieldName)}
                  value={
                    formData?.otherDetails?.find(
                      (detail: any) => detail[field.fieldName]
                    ) && formData?.otherDetails
                      ? dayjs.unix(
                          formData?.otherDetails?.find(
                            (detail: any) => detail[field.fieldName]
                          )?.[field.fieldName]
                        )
                      : dayjs()
                  }
                />
              </LocalizationProvider>
              {field?.required &&
                !fieldValidations[field.fieldName] &&
                formSubmitted && (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0px 0px 0px",
                      fontSize: "12px",
                    }}
                  >
                    Field Required
                  </div>
                )}
            </Box>
          );
        default:
          return null;
      }
    });
  };

  const requiredFields =
    publicWebform?.fields?.filter(
      (field: any) => field?.DBModel !== "BATCH" && field.required
    ) || [];

  const isAnyFieldInvalid = requiredFields.some((field: any) => {
    // if (field.fieldType === "checkbox") {
    //   return !formData?.otherDetails?.some(
    //     (detail: any) => detail[field.fieldName]
    //   );
    // }
    if (field.fieldType === "checkbox") {
      const checkboxField = formData?.otherDetails?.find(
        (detail: any) => detail[field.fieldName]
      );
      return !checkboxField || !checkboxField[field.fieldName]?.length;
    }

    return !(
      formData?.otherDetails?.find((detail: any) => detail[field.fieldName]) ||
      formData[field.fieldName]
    );
  });

  return (
    <Container
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        // overflowY: "scroll",
        overflowY: "auto",
        width: "100vw",
        minHeight: "100vh",
        paddingTop: "100px",
        marginBottom: "100px",
      }}
      onSubmit={handleSubmit}
    >
      {publicWebform?.status === "ARCHIVED" ? (
        <Box>
          <img src={archivedImg} alt="archivedImg" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // overflowY: "scroll",
              overflowY: "auto",
            }}
          >
            {renderFormFields()}
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={
              isAnyFieldInvalid ||
              loading ||
              batchException ||
              contactCreateLoading
            }
          >
            {" "}
            {contactCreateLoading ? (
              <CircularProgress size="1rem" />
            ) : (
              publicWebform && "Submit"
            )}
          </Button>

          <Box
            sx={{ textAlign: "center", marginTop: "30px", fontSize: "12px" }}
          >
            {institutionDetails.logo ? (
              <>
                <img
                  src={institutionDetails.logo}
                  alt="institutionLogo"
                  width="100px"
                  height="100px"
                />

                <Typography sx={{ fontSize: "12px" }}>
                  {institutionDetails.instituteName}
                </Typography>
              </>
            ) : (
              <>
                <img src={nurtureLogo} alt="nurtureLogo" />
              </>
            )}
            <Typography
              sx={{ color: "#7C7C7C", lineHeight: 1, fontSize: "12px" }}
            >
              Powered by
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>nurturecrm.in</Typography>
          </Box>
        </>
      )}
    </Container>
  );
};

export default PublicWebformForm;
