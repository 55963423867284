import React from "react";
import PaymentRecordPopup from "../RecordPayment/PaymentRecordPopup";
import PaymentLinkPopup from "../PaymentLinkPopup/PaymentLinkPopup";
import PaymentLinkSent from "../PaymentLinkPopup/PaymentLinkSent";
import styles from "./PaymentDetails.module.css";
import paidCheck from "../../../../../assets/images/paid-check.svg";

function BasicRegularDetails(props: any) {
  const {
		leadData,
		RecordPayment,
		SendPaymentLink,
		EditRecordPayment,
		isFirstPaymentRequestedOrFailed,
		filteredPaymentRequests,
		hasLeadAccess,
		selectedCurrency,
	} = props;
	return (
		<div className={styles.basicRegularWrap}>
			<div className={styles.basicRegularLeft}>
				<div className={styles.basicRegularAmount}>
					Balance : {''}
					{/*       {leadData?.balanceAmount?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })} */}
					{selectedCurrency + ' ' + leadData?.balanceAmount}
				</div>
			</div>
			<div className={styles.basicRegularRight}>
				{leadData?.balanceAmount > 0 ? (
					<>
						{hasLeadAccess && (
							<>
								<PaymentRecordPopup
									balanceAmountToBePaid={leadData?.balanceAmount}
									basePrice={leadData?.paymentDetails?.finalAmount}
									discountMetric={leadData?.paymentDetails?.discountMetric}
									discount={leadData?.paymentDetails?.discount}
									RecordPayment={RecordPayment}
									amountToBePaid={leadData?.balanceAmount}
									leadData={leadData}
									emiId={''}
									isTable={false}
									paymentType={'REGULAR'}
									finalBatchPrice={leadData?.paymentDetails?.finalAmount}
									totalPaidAmount={leadData?.batchAmountPaid}
									autoDebitRaised={false}
								/>
								{!isFirstPaymentRequestedOrFailed ? (
									<PaymentLinkPopup
										finalBatchPrice={leadData?.paymentDetails?.finalAmount}
										totalPaidAmount={leadData?.batchAmountPaid}
										isOneTime={false}
										isTable={false}
										paymentType={'REGULAR'}
										SendPaymentLink={SendPaymentLink}
										leadData={leadData}
										amountToBePaid={leadData?.balanceAmount}
										selectedCurrency={selectedCurrency}
									/>
								) : (
									<PaymentLinkSent
										filteredPaymentRequests={filteredPaymentRequests}
										EditRecordPayment={EditRecordPayment}
										SendPaymentLink={SendPaymentLink}
										finalBatchPrice={leadData?.paymentDetails?.finalAmount}
										totalPaidAmount={leadData?.batchAmountPaid}
										isOneTime={false}
										paymentType={'REGULAR'}
										leadData={leadData}
										isFirstPaymentRequestedOrFailed={
											isFirstPaymentRequestedOrFailed
										}
										amountToBePaid={
											isFirstPaymentRequestedOrFailed?.amountReceived
										}
									/>
								)}
							</>
						)}
					</>
				) : (
					<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						<div
							style={{
								background: ' rgba(17, 200, 155, 0.1)',
								borderRadius: '4px',
								padding: '4px 10PX',
								color: '#3DB985',
								fontSize: '12PX',
								fontWeight: '500',
							}}>
							Paid
						</div>
						<img src={paidCheck} alt='paidCheck' />
					</div>
				)}
			</div>
		</div>
	);
}

export default BasicRegularDetails;
