import {RootState, TypedDispatch} from '../../../../redux/store';
import {connect} from 'react-redux';
import { ForgetPassword } from '../components/ForgetPassword';
import { forgetPasswordInitialStateDTO, requsestOTPDTO, resetPasswordDTO, verifyOTPDTO } from '../service/types';
import { otpRequest, verifyOTP, resetPassword } from '../service/forgetPasswordSlice';
interface StateProps extends forgetPasswordInitialStateDTO {
}

interface DispatchProps {
    RequestOTPforForgetPassword(data: requsestOTPDTO): void;
    VerifyOTPforForgetPassword(data: verifyOTPDTO): void;
    ResetPasswordforForgetPassword(data: resetPasswordDTO): void;
}

const mapStateToProps = (state: RootState): StateProps => {
    return {
        success_requestOTP: state.forgetPassword.success_requestOTP,
        success_verifyOTP: state.forgetPassword.success_verifyOTP,
        success_resetPassword: state.forgetPassword.success_resetPassword,
        error_requestOTP: state.forgetPassword.error_requestOTP,
        error_verifyOTP: state.forgetPassword.error_verifyOTP,
        error_resetPassword: state.forgetPassword.error_resetPassword,
        loading_requestOTP: state.forgetPassword.loading_requestOTP,
        loading_verifyOTP: state.forgetPassword.loading_verifyOTP,
        loading_resetPassword: state.forgetPassword.loading_resetPassword
    };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
    return {
        RequestOTPforForgetPassword: (data: requsestOTPDTO) =>{
            dispatch(otpRequest(data));
        },
        VerifyOTPforForgetPassword: (data: verifyOTPDTO) =>{
            dispatch(verifyOTP(data));
        },
        ResetPasswordforForgetPassword: (data: resetPasswordDTO) =>{
            dispatch(resetPassword(data));
        },
  };
};

export interface ForgetPasswordProps extends StateProps, DispatchProps {}
export const ForgetPasswordConatiner = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgetPassword);