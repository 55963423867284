import { RootState, TypedDispatch } from "../../../../../redux/store";
import { createStaffDTO, staffInitialStateDTO } from "../service/types";
import StaffEditor from "../StaffEditor/StaffEditor";
import { connect } from "react-redux";
import {
  createStaff,
  getStaffById,
  getStaffList,
  getStaffListWithCount,
  updateStaff,
} from "../service/Staffslice";
import { getBranchList } from "../../ManageBranch/service/Branchslice";
import { appEntery } from "../../../Home/service/homeSlice";
import { UpdateUserDTO } from "../../Billing/service/types";
import { removeUserOrUserLicense } from "../../Billing/service/Billingslice";
import { getRolesByInstitute } from "../../../Settings/service/settingsSlice";

interface StateProps extends staffInitialStateDTO {}

interface DispatchProps {
  CreateStaff(data: createStaffDTO): void;
  GetStaffById(id: string): void;
  UpdateStaff(data: createStaffDTO): void;
  GetBranchList(id: string): void;
  UpdateInstituteInLocal(id: string): void;
  RemoveUserOrUserLicense(data: UpdateUserDTO): void;
  GetStaffList(id: string): void;
  GetRolesByInstitute(id: string): void;
  GetStaffListWithCount(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    staffList: state.staff.staffList,
    staffById: state.staff.staffById,
    staffListWithCount: state.staff.staffListWithCount,
    success: state.staff.success,
    error: state.staff.error,
    loading: state.staff.loading,
    staffListLoading: state.staff.staffListLoading,
    createStaffLoading: state.staff.createStaffLoading,
    updateStaffLoading: state.staff.updateStaffLoading,
    staffByIdLoading: state.staff.staffByIdLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateStaff: (data: createStaffDTO) => {
      dispatch(createStaff(data));
    },
    GetStaffById: (id: string) => {
      dispatch(getStaffById(id));
    },
    UpdateStaff: (data: createStaffDTO) => {
      dispatch(updateStaff(data));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    UpdateInstituteInLocal: (id: string) => {
      dispatch(appEntery(id));
    },
    RemoveUserOrUserLicense: (data: UpdateUserDTO) => {
      dispatch(removeUserOrUserLicense(data));
    },
    GetStaffList: (id: string) => {
      dispatch(getStaffList(id));
    },
    GetRolesByInstitute: (id: string) => {
      dispatch(getRolesByInstitute(id));
    },
    GetStaffListWithCount: (id: string) => {
      dispatch(getStaffListWithCount(id));
    },
  };
};

export interface BranchProps extends StateProps, DispatchProps {}

export const StaffComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffEditor);
