import {
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import {
  CreateBatch,
  DeleteDemoStudent,
  GetBatchBookedSession,
  GetBatchById,
  GetBatchByIdWithCalander,
  GetBatchList,
  UpdateBatch,
  UpdateBatchPayment,
} from "./Batch.request";

import { batchInitialStateDTO, createBatchDTO } from "./types";
import { GetNurtureLeadBoard } from "../../../Nurture/service/nurture.request";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

export const initialState: batchInitialStateDTO = {
  success: false,
  error: false,
  loading: false,
  batchList: null,
  batchById: null,
  batchId: null,
  batchBookedSession: null,
  batchByIdWithCalander: null,
  batchListLoading: false,
  batchCreateSuccess: false,
  batchCreateError: false,
  batchCreateLoading: false,
  assignees: null,
  assigneeLoading: false,
  assigneeSuccess: false,
  assigneeError: false,
  batchByIdLoading: false,
  batchUpdateLoading: false,
  batchUpdateSuccess: false,
  batchUpdateError: false,
  batchPayUpdateLoading: false,
  batchPayUpdateSuccess: false,
  batchPayUpdateError: false,
  demoStudentDeleteSuccess: false,
  demoStudentDeleteLoading: false,
  demoStudentDeleteError: false,
  batchSessionLoading: false,
};

export const getNurtureLeadBoard = createAsyncThunk(
  "batch/assignee",
  async (id: string, thunkAPI: any) => {
    return GetNurtureLeadBoard(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const createBatch = createAsyncThunk(
  "user/batch",
  async (data: createBatchDTO, thunkAPI: any) => {
    return CreateBatch(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getBatchList = createAsyncThunk(
  "user/batchList",
  async (data: any, thunkAPI: any) => {
    return GetBatchList(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getBatchById = createAsyncThunk(
  "user/batchById",
  async (id: string, thunkAPI: any) => {
    return GetBatchById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateBatch = createAsyncThunk(
  "user/updateBatch",
  async (data: any, thunkAPI: any) => {
    return UpdateBatch(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateBatchPayment = createAsyncThunk(
  "user/updateBatchPayment",
  async (data: any, thunkAPI: any) => {
    return UpdateBatchPayment(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deleteDemoStudent = createAsyncThunk(
  "user/deleteDemoStudent",
  async (data: any, thunkAPI: any) => {
    return DeleteDemoStudent(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getBatchBookedSession = createAsyncThunk(
  "user/batchBookedSession",
  async (data: any, thunkAPI: any) => {
    return GetBatchBookedSession(data)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getBatchByIdWithCalander = createAsyncThunk(
  "user/batchByIdCalendar",
  async (id: string, thunkAPI: any) => {
    return GetBatchByIdWithCalander(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    reset: () => initialState,
    resetBatchById: (state) => {
      state.batchById = null;
    },
    resetBatchCreateSuccess: (state) => {
      state.batchCreateSuccess = false;
    },
    resetBatchUpdateSuccess: (state) => {
      state.batchUpdateSuccess = false;
    },
    resetDemoStudentStatus: (state) => {
      state.demoStudentDeleteSuccess = false;
      state.demoStudentDeleteError = false;
    },
    resetBatchStatus: (state) => {
      state.batchCreateSuccess = false;
      state.batchCreateError = false;
      state.batchUpdateSuccess = false;
      state.batchUpdateError = false;
      state.demoStudentDeleteSuccess = false;
      state.demoStudentDeleteError = false;
      state.batchPayUpdateSuccess = false;
      state.batchPayUpdateError = false;
      state.batchId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBatch.pending, (state) => {
      state.batchCreateLoading = true;
      state.batchCreateSuccess = false;
      state.batchCreateError = false;
    });
    builder.addCase(createBatch.fulfilled, (state, action: AnyAction) => {
      state.batchCreateLoading = false;
      state.batchCreateSuccess = action?.payload?.message || "Batch Created";
      state.batchCreateError = false;
      state.batchId = action?.payload?.data?.id;
    });
    builder.addCase(createBatch.rejected, (state, action: AnyAction) => {
      state.batchCreateLoading = false;
      state.batchCreateSuccess = false;
      state.batchCreateError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getBatchList.pending, (state) => {
      state.batchListLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBatchList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.batchListLoading = false;
        state.success = true;
        state.error = false;
        state.batchList = action?.payload;
      }
    );
    builder.addCase(getBatchList.rejected, (state, action: AnyAction) => {
      state.batchListLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getBatchById.pending, (state) => {
      state.batchByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBatchById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.batchByIdLoading = false;
        state.success = true;
        state.error = false;
        state.batchById = action?.payload;
      }
    );
    builder.addCase(getBatchById.rejected, (state, action: AnyAction) => {
      state.batchByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateBatch.pending, (state) => {
      state.batchUpdateLoading = true;
      state.batchUpdateSuccess = false;
      state.batchUpdateError = false;
    });
    builder.addCase(updateBatch.fulfilled, (state, action: AnyAction) => {
      state.batchUpdateLoading = false;
      state.batchUpdateSuccess = action?.payload?.message || "Batch Updated";
      state.batchUpdateError = false;
    });
    builder.addCase(updateBatch.rejected, (state, action: AnyAction) => {
      state.batchUpdateLoading = false;
      state.batchUpdateSuccess = false;
      state.batchUpdateError =
        action?.payload?.message || "Something went wrong";
    });

    builder.addCase(updateBatchPayment.pending, (state) => {
      state.batchPayUpdateLoading = true;
      state.batchPayUpdateSuccess = false;
      state.batchPayUpdateError = false;
    });
    builder.addCase(
      updateBatchPayment.fulfilled,
      (state, action: AnyAction) => {
        state.batchPayUpdateLoading = false;
        state.batchPayUpdateSuccess =
          action?.payload?.message || "Batch Updated";
        state.batchPayUpdateError = false;
      }
    );
    builder.addCase(updateBatchPayment.rejected, (state, action: AnyAction) => {
      state.batchPayUpdateLoading = false;
      state.batchPayUpdateSuccess = false;
      state.batchPayUpdateError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getNurtureLeadBoard.pending, (state) => {
      state.assigneeLoading = true;
      state.assigneeSuccess = false;
      state.assigneeError = false;
    });
    builder.addCase(
      getNurtureLeadBoard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.assigneeLoading = false;
        state.assigneeSuccess = true;
        state.assigneeError = false;
        state.assignees = action?.payload;
      }
    );
    builder.addCase(
      getNurtureLeadBoard.rejected,
      (state, action: AnyAction) => {
        state.assigneeLoading = false;
        state.assigneeSuccess = false;
        state.assigneeError = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(deleteDemoStudent.pending, (state) => {
      state.demoStudentDeleteLoading = true;
      state.demoStudentDeleteSuccess = false;
      state.demoStudentDeleteError = false;
    });
    builder.addCase(
      deleteDemoStudent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.demoStudentDeleteLoading = false;
        state.demoStudentDeleteSuccess =
          action?.payload?.message || "Student deleted from batch";
        state.demoStudentDeleteError = false;
      }
    );
    builder.addCase(deleteDemoStudent.rejected, (state, action: AnyAction) => {
      state.demoStudentDeleteLoading = false;
      state.demoStudentDeleteSuccess = false;
      state.demoStudentDeleteError =
        action?.payload?.message || "Something went wrong";
    });
    builder.addCase(getBatchBookedSession.pending, (state) => {
      state.batchSessionLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBatchBookedSession.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.batchSessionLoading = false;
        state.success = true;
        state.error = false;
        state.batchBookedSession = action?.payload;
      }
    );
    builder.addCase(
      getBatchBookedSession.rejected,
      (state, action: AnyAction) => {
        state.batchSessionLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getBatchByIdWithCalander.pending, (state) => {
      state.batchByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getBatchByIdWithCalander.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.batchByIdLoading = false;
        state.success = true;
        state.error = false;
        state.batchByIdWithCalander = action?.payload;
      }
    );
    builder.addCase(
      getBatchByIdWithCalander.rejected,
      (state, action: AnyAction) => {
        state.batchByIdLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default batchSlice.reducer;
export const {
  reset,
  resetBatchById,
  resetBatchCreateSuccess,
  resetBatchUpdateSuccess,
  resetDemoStudentStatus,
  resetBatchStatus,
} = batchSlice.actions;
