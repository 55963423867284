import React, { useState } from "react";
import styles from "./Webform.module.css";
import { Box, Button, Tooltip } from "@mui/material";
import CreateFormPopup from "./CreateFormPopup/CreateFormPopup";
import { useNavigate } from "react-router-dom";

function WelcomeWebForm(props: any) {
  const {
    CreateWebform,
    CreateWebformSuccess,
    CreateWebformId,
    onClose,
    hasWebformsAccess,
  } = props;
  const [showCreateFormPopup, setShowCreateFormPopup] = useState(false);

  const handleCreateNewClick = () => {
    setShowCreateFormPopup(true);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div
      style={{
        borderRadius: "4px",
        border: "1px solid #E1E1E1",
        background: "#FFF",
        padding: "30px 90px",
      }}
    >
      <div style={{ textAlign: "center", maxWidth: "690px", margin: "0 auto" }}>
        <div className={styles.welcomeHead}>
          Welcome to nurture's Webform Builder
        </div>
        <div className={styles.welcomePara}>
          Your ultimate tool for collecting valuable data and engaging with your
          audience effortlessly. Create, customize, and manage webforms with
          ease, all within nurture.
        </div>
        <Tooltip
          arrow
          title={hasWebformsAccess === false ? "Oops! No permission." : ""}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                },
              },
            },
          }}
        >
          <span>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              onClick={handleCreateNewClick}
              disabled={hasWebformsAccess === false}
            >
              Create New
            </Button>
          </span>
        </Tooltip>
      </div>
      {showCreateFormPopup && (
        <CreateFormPopup
          CreateWebform={CreateWebform}
          CreateWebformSuccess={CreateWebformSuccess}
          CreateWebformId={CreateWebformId}
          isOpen={showCreateFormPopup}
          onClose={() => setShowCreateFormPopup(false)}
        />
      )}
    </div>
  );
}

export default WelcomeWebForm;
