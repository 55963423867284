import React from "react";
import styles from "../Subscription.module.css";
import accountUnArchived from "./account-unarchived.svg";

function AccountsUnarchived() {
  return (
    <>
      <div className={styles.accountUnarchivedWarningWrap}>
        <div className={styles.accountUnarchivedWarningWrapLeft}>
          <div className={styles.accountUnarchivedWarningIcon}>
            <img src={accountUnArchived} alt="accountLocked" />
          </div>
          <div className={styles.accountUnarchivedWarningLabel}>
            Account Unarchived
          </div>
        </div>
        <div className={styles.accountUnarchivedWarningWrapRight}>
          Your account will be activated soon.
        </div>
      </div>
    </>
  );
}

export default AccountsUnarchived;
