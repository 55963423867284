import React, { useState, useEffect } from "react";
import { Box, Dialog, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./CreateFormPopup.module.css";
import { useAuthUser } from "react-auth-kit";
import { Navigate, useLocation, useNavigate } from "react-router";

const CreateFormPopup = (props: any) => {
  const {
    CreateWebform,
    CreateWebformSuccess,
    CreateWebformId,
    type,
    editFormData,
    isOpen,
    onClose,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuthUser();
  const authUser = auth();

  const [webformDetails, setWebformDetails] = React.useState({
    status: "PUBLISHED",
    createdByName:
      authUser?.institute[0]?.firstName +
      " " +
      authUser?.institute[0]?.lastName,
    createdById: authUser?.institute[0]?.id,
    instituteId: authUser?.institute[0]?.instituteId,
    formName: "",
    fields: [],
  });
  const handleClose = () => {
    onClose();
  };
  const handleChangeName = (e: any) => {
    setWebformDetails({
      ...webformDetails,
      formName: e.target.value,
    });
  };
  const handleSubmit = () => {
    CreateWebform(webformDetails);
  };

  useEffect(() => {
    if (CreateWebformSuccess) {
      // navigate(`./editwebform/${CreateWebformId?.id}`, { replace: true });
    }
  }, [CreateWebformSuccess]);

  return (
    <Dialog
      open={isOpen}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "400px",
          maxHeight: "290px",
          minHeight: "165px",
          padding: "24px",
          borderRadius: "12px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "8%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#555555" }} onClick={handleClose} />
        </div>
        <div className={styles.modalTitle}>Form Name</div>
        <TextField
          variant="outlined"
          value={editFormData?.formName}
          placeholder="Enter name for your form"
          fullWidth
          onChange={handleChangeName}
          inputProps={{
            style: {
              padding: "10px 12px",
              backgroundColor: "#FFF",
              borderRadius: "4px",
            },
          }}
        />
        <div className={styles.buttonSection}>
          <Button
            variant="outlined"
            sx={{
              color: "#BFBFBF",
              fontWeight: "500",
              lineHeight: "10px 24px 24px 24px",
              textTransform: "capitalize",
              borderRadius: "4px",
              border: "1px solid #BFBFBF",
              backgroundColor: "#FFF",
              "&:hover": {
                backgroundColor: "#FFF",
                border: "1px solid #BFBFBF",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {type === "rename" ? (
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ textTransform: "capitalize" }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ textTransform: "capitalize" }}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default CreateFormPopup;
