import { Box, CircularProgress, Modal } from "@mui/material";
import React from "react";
import modalStyles from "./Remove.module.css";

function RemoveContactsModal(props: any) {
  const { open, setOpen, deleteConfirm, loading } = props;
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "57%",
            left: "58%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            borderRadius: "5px",
            p: 3,
          }}
        >
          <div>
            <div className={modalStyles.editFormPopupTitle}>
              Remove Contact{" "}
            </div>
            <div className={modalStyles.editFormPopupContent}>
              Are you sure you want to delete this contact?
            </div>
            <div className={modalStyles.editFormPopupBtnsSplit}>
              <button
                className={modalStyles.editFormPopupCancelBtn}
                onClick={() => setOpen(false)}
              >
                cancel
              </button>
              <button
                className={modalStyles.editFormPopupRemoveBtn}
                onClick={() => deleteConfirm()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default RemoveContactsModal;
