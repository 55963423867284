import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  InputAdornment,
  TextField,
} from "@mui/material";
import styles from "./UploadFile.module.css";
import { FiDownload } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import uploadImage from "../../../../../assets/images/uploadInCloud.svg";
import { useAuthUser } from "react-auth-kit";
import svgFile from "../../../../../assets/images/csv.svg";
import { MdClose } from "react-icons/md";
import PuffLoader from "react-spinners/PuffLoader";
import { useNavigate } from "react-router";
import {
  DownloadCSVTemplate,
  ImportCSVFile,
} from "../../service/inbound.request";
import { resetSaveImportedDataSuccess } from "../../service/importSlice";
import { useDispatch } from "react-redux";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ListPopover from "../../../../../components/ListPopover/ListPopover";

function UploadFile(props: any) {
  const { setImportModal, openImportModal, page } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

  const [base64Csv, setBase64Csv] = React.useState("");
  const [importedCsvBase64, setImportedCsvBase64] = React.useState<any>("");
  const [fileName, setFileName] = React.useState("");

  const [isFileImported, setIsFileImported] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [enableImportButton, setEnableImportButton] = useState<boolean>(false);
  const [downloadLoading, setdownloadLoading] = useState<boolean>(false);
  const [formTypeAnchorEl, setFormTypeAnchorEl] = useState(null);
  const [country, setCountry] = useState<any>("");
  const formTypeFieldRef = useRef<HTMLInputElement | null>(null);
  const openFormType = Boolean(formTypeAnchorEl);

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.onload = () => {
        const base64String =
          reader && reader?.result && reader?.result?.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error: any) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const onDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    acceptedFiles && setFileName(acceptedFiles[0]?.name);
    const file = acceptedFiles && acceptedFiles[0];
    const base64String = await convertToBase64(file);
    base64String
      ? setImportedCsvBase64(base64String)
      : setImportedCsvBase64("");
    const data: any = {
      instituteId: userFromLocalStorage?.id,
      fileBase64String: base64String,
      fileName: acceptedFiles[0]?.name,
    };
    setLoading(true);
    ImportCSVFile(data)
      .then((res: any) => {
        if (res?.status === 200) {
          localStorage.setItem(
            "ImportedContacts",
            JSON.stringify(res?.data?.data?.fileBase64String)
          );

          setLoading(false);
          if (
            res?.data?.data?.fileBase64String?.contactsWithError?.data?.length >
              0 ||
            res?.data?.data?.fileBase64String?.contactsWithoutError?.data
              ?.length > 0
          ) {
            setEnableImportButton(true);
            dispatch(
              setToastNotification({
                message: res?.data?.data?.message,
                type: "success",
                snackOpen: true,
              })
            );

            setIsFileImported(true);
          } else {
            dispatch(
              setToastNotification({
                message: String("Please Upload with Proper Data"),
                type: "error",
                snackOpen: true,
              })
            );
            setEnableImportButton(false);
          }
        } else {
          dispatch(
            setToastNotification({
              message: String("Failed to import File"),
              type: "error",
              snackOpen: true,
            })
          );

          setIsFileImported(false);
        }
      })
      .catch((err: any) => {
        dispatch(
          setToastNotification({
            message: err?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        setLoading(false);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "application/vnd.ms-excel": [".xlsx"] },
  });

  const base64ToBlob = (base64Data: string, contentType: any) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const downloadCSV = () => {
    setdownloadLoading(true);
    DownloadCSVTemplate(userFromLocalStorage?.id, country?.value)
      .then((res: any) => {
        {
          // setBase64Csv(res?.data?.data?.fileBase64String);
          const blob = base64ToBlob(
            res?.data?.data?.fileBase64String,
            "application/vnd.ms-excel"
          );
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "nurturecrm-import-template.xlsx";
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          setdownloadLoading(false);
        }
      })
      .catch((err) => {
        setdownloadLoading(false);
      });
  };

  useEffect(() => {
    const importString: any = localStorage?.getItem("ImportedContacts");
    if (importString) {
      localStorage?.setItem("ImportedContacts", "");
    }
    dispatch(resetSaveImportedDataSuccess());
  }, []);

  const handleOpenFormTypePopover = (event: any) => {
    setFormTypeAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseFormTypePopover = () => {
    setFormTypeAnchorEl(null);
  };

  const handleFormTypeSelect = (emp: any) => {
    setCountry(emp);
    handleCloseFormTypePopover();
  };

  return (
    <div>
      <Drawer
        className="filter_drawer"
        anchor="right"
        open={openImportModal}
        onClose={() => setImportModal(false)}
        PaperProps={{
          sx: {
            width: 350,
            backgroundColor: "#ffff",
            zIndex: "999999",
          },
        }}
      >
        <button
          className={styles.filterCloeBtn}
          onClick={() => {
            setImportModal(false);
            setCountry("");
          }}
        >
          x
        </button>
        <Box>
          <Box
            fontSize={"20px"}
            lineHeight={"24px"}
            fontWeight={500}
            color={"#142C44"}
            borderBottom={"1px solid #F4F4F4"}
            padding={3}
          >
            Upload File
          </Box>
          <Box padding={3}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "rgba(102, 112, 133, 1)",
                  marginRight: "10px",
                }}
              >
                Country:{" "}
              </div>

              <TextField
                onClick={handleOpenFormTypePopover}
                value={country ? country?.name : ""}
                variant="outlined"
                size="small"
                placeholder="Select"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root input": {
                    textTransform: "capitalize",
                    color: "#667085",
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                }}
                inputRef={formTypeFieldRef}
              />
              <ListPopover
                openState={openFormType}
                anchorState={formTypeAnchorEl}
                handleClosePopover={handleCloseFormTypePopover}
                handleListSearch={() => {}}
                popupList={[
                  { id: 0, name: "India(+91)", value: 91 },
                  { id: 1, name: "UAE(+971)", value: 971 },
                ]}
                handleItemSelect={handleFormTypeSelect}
                listType={"FORMTYPE"}
              />
            </div>
            <Box paddingBottom={3} paddingTop={2}>
              <Button
                sx={{
                  width: "100%",
                  color: "#667085",
                  border: "1px solid #E3E3E3",
                  ":hover": {
                    backgroundColor: "#e3e3e33b",
                  },
                }}
                onClick={downloadCSV}
                disabled={downloadLoading || country === ""}
              >
                {downloadLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  <>
                    <FiDownload
                      style={{
                        marginRight: "5px",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                    <Box
                      sx={{
                        color: "#142C44",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "30px",
                        textTransform: "capitalize",
                      }}
                    >
                      Download Template
                    </Box>
                  </>
                )}
              </Button>
            </Box>
            <Box marginTop={5} marginBottom={3}>
              {isFileImported ? (
                <Box
                  sx={{
                    backgroundColor: "#e8faf5",
                    border: "1px solid #11C89B",
                    cursor: "pointer",
                    height: "80px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <img src={svgFile} />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <p>{fileName}</p>
                    </div>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <MdClose
                      style={{ height: "25px", width: "25px" }}
                      onClick={() => {
                        setImportedCsvBase64("");
                        setIsFileImported(false);
                        setEnableImportButton(false);
                        const importString: any =
                          localStorage?.getItem("ImportedContacts");
                        if (importString) {
                          localStorage?.setItem("ImportedContacts", "");
                        }
                      }}
                    />
                  </div>
                </Box>
              ) : (
                <>
                  {loading ? (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50px",
                      }}
                    >
                      <PuffLoader color="#36d7b7" />
                    </Box>
                  ) : (
                    <Box
                      {...getRootProps()}
                      paddingTop={9.5}
                      paddingBottom={9.5}
                      paddingLeft={3}
                      paddingRight={3}
                      borderRadius={"4px"}
                      textAlign="center"
                      sx={{
                        backgroundColor: "#FBFBFB",
                        border: "1px dashed #DBDBDB",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      <img src={uploadImage} alt="" />
                      <Box
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        fontWeight={500}
                        color={"#142C44"}
                      >
                        Drag & drop .xlsx here
                      </Box>
                      <Box
                        fontSize={"13px"}
                        lineHeight={"20px"}
                        fontWeight={400}
                        color={"#667085"}
                      >
                        or click to browse , Maximum file: 2MB
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box paddingBottom={2} paddingTop={2}>
              <Box
                fontSize="14px"
                lineHeight="20px"
                fontWeight={500}
                color="#142C44"
              >
                Note:
              </Box>
              <Box
                fontSize="13px"
                lineHeight="20px"
                fontWeight={400}
                color="#667085"
              >
                Name and one Contact Detail are mandatory Please ensure that the
                file is in .xlsx format. Maximum number of entries: 500 Maximum
                file size: 2MB
              </Box>
            </Box>
            <Button
              sx={{ textTransform: "capitalize", width: "100%" }}
              variant="contained"
              type="submit"
              disabled={
                isFileImported === false && enableImportButton === false
              }
              onClick={(event: any) => {
                if (page === "IMPORT") {
                  navigate("/app/inbound/ImportPreview", {
                    state: { importFromPage: "IMPORT" },
                  });
                } else {
                  navigate("/app/inbound/ImportPreview");
                }

                setImportModal(false);
              }}
            >
              Continue
            </Button>
            <Button
              sx={{
                textTransform: "capitalize",
                color: "#9A9EA6",
                borderColor: "#9A9EA6",
                width: "100%",
                marginTop: "10px",
                ":hover": {
                  color: "#9A9EA6",
                  borderColor: "#9A9EA6",
                  backgroundColor: "#9a9ea612",
                },
              }}
              variant="outlined"
              onClick={() => {
                setImportModal(false);
              }}
              disabled={enableImportButton === false}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}

export default UploadFile;
