import React from "react";
import styles from "./ProgressBar.module.css";

function ProgressBar(props: any) {
  const { minimumSeats, maximumSeats, enrolledSeats } = props;
  const enrolled = (enrolledSeats / maximumSeats) * 100;
  const minimumseat = (minimumSeats / maximumSeats) * 100;

  return (
    <div>
      <div className={styles.progressBar}>
        <div
          className={styles.minimum}
          style={{ width: `${minimumseat}%` }}
        ></div>
        <div
          className={styles.fill}
          style={{
            width: `${enrolled}%`,
            backgroundColor: enrolled >= minimumseat ? "#11C89B" : "#EC3939",
            position: "relative",
          }}
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar;
