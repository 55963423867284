import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import NotificationPage from "../NotificationPage";
import {
  getNotificationsByPagination,
  markAllAsRead,
  markAsRead,
} from "../service/Notificationslice";
import { notificationInitialStateDTO } from "../service/types";

interface StateProps extends notificationInitialStateDTO {}

interface DispatchProps {
  GetNotificationByPagination(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string
  ): void;
  MarkAsRead(id: string): void;
  MarkAllAsRead(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.notification.success,
    error: state.notification.error,
    loading: state.notification.loading,
    notifications: state.notification.notifications,
    notificationsPopover: state.notification.notificationsPopover,
    markAsReadLoading: state.notification.markAsReadLoading,
    markAsReadSuccess: state.notification.markAsReadSuccess,
    markAsReadError: state.notification.markAsReadError,
    markAllAsReadLoading: state.notification.markAllAsReadLoading,
    markAllAsReadSuccess: state.notification.markAllAsReadSuccess,
    markAllAsReadError: state.notification.markAllAsReadError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetNotificationByPagination: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string
    ) => {
      dispatch(
        getNotificationsByPagination({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
        })
      );
    },
    MarkAsRead: (id: string) => {
      dispatch(
        markAsRead({
          id,
        })
      );
    },
    MarkAllAsRead: (id: string) => {
      dispatch(
        markAllAsRead({
          id,
        })
      );
    },
  };
};

export interface NotificationProps extends StateProps, DispatchProps {}
export const NotificationComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPage);
