import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  FormGroup,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import close from "../../../../../../assets/images/close.svg";
import FilterDrawer from "../../../../../../components/FilterDrawer/FilterDrawer";
import { useNavigate } from "react-router-dom";
import styles from "../Settings.module.css";
import { useLocation } from "react-router";
import { GetImportHistoryDetails } from "../../../service/inbound.request";
import { useAuthUser } from "react-auth-kit";
import formatTimestamp from "../../../../../../components/Formatter/Formatter";
import FilterChipsWrap from "../../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterTypeWrap from "../../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckbox from "../../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterCheckBoxWrap from "../../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import ClearFilter from "../../../../../../components/ClearFilter/ClearFilter";
import { useSelector } from "react-redux";
import { getCourse } from "../../../../../../components/CommonFunctions/CommonFunctions";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0px 12px",
  fontSize: "12px",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
  "@media only screen and (min-width: 992px)": {
    fontSize: "12px",
  },
  "@media only screen and (min-width: 1200px)": {
    fontSize: "14px",
  },
}));

const CustomTableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: "16px 12px",
  fontSize: "14px",
  color: "#69696B",
  fontWeight: "500",
}));

const options = [
  { id: "1", name: "Success" },
  { id: "2", name: "Failed" },
];

const FilterSettings = () => {
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const location = useLocation();
  const courseList = useSelector((state: any) => state.courses.courses);
  const [importedFilter, setImportedFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const [historyDetails, setHistoryDetails] = useState<any>([]);
  const [timeOfUpload, setTimeOfUpload] = useState<any>();
  const [dataForSearch, setDataForSearch] = useState<any>([]);
  let startIndex = page * rowsPerPage;
  const [columns, setColumns] = useState([
    { id: "hash", label: "#", minWidth: 30, align: "inherit", show: true },
    {
      id: "name",
      label: "Name",
      minWidth: 170,
      align: "inherit",
      show: true,
    },
    {
      id: "status",
      label: "Email Address",
      minWidth: 170,
      align: "inherit",
      show: true,
    },
    {
      id: "courseName",
      label: "Phone Number",
      minWidth: 170,
      align: "inherit",
      show: true,
    },
    {
      id: "courseName",
      label: "Course Name",
      minWidth: 180,
      align: "inherit",
      show: true,
    },
    {
      id: "batch",
      label: "Status",
      minWidth: 170,
      align: "left",
      show: true,
    },
    {
      id: "batch",
      label: "Failure Reason",
      minWidth: 170,
      align: "left",
      show: true,
    },
  ]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    setLoading(true);
    if (location && userFromLocalStorage) {
      GetImportHistoryDetails(location?.state).then((res: any) => {
        if (res?.status === 200) {
          setLoading(false);
          setTimeOfUpload(res?.data?.data?.uploadTime);
          const historyDataWithErrors =
            res?.data?.data?.data?.excelPreviewDataWithErrors;
          const historyDataWithoutErrors =
            res?.data?.data?.data?.excelPreviewDataWithoutErrors;
          setHistoryDetails([
            ...historyDataWithErrors,
            ...historyDataWithoutErrors,
          ]);
          setDataForSearch([
            ...historyDataWithErrors,
            ...historyDataWithoutErrors,
          ]);
        }
      });
    }
  }, []);

  const onImportedByFilter = (event: any) => {
    setImportedFilter(event.target.value);
  };

  const clearAllFilters = () => {
    setImportedFilter("");
  };

  const clearFilter = () => {
    setImportedFilter("");
  };
  const handleChange = (value: any) => {
    setPage(value - 1);
  };

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  interface CheckedContactStatus {
    contactStatus: string;
  }

  const [checkedContactStatuses, setCheckedContactStatuses] = useState<
    CheckedContactStatus[]
  >([]);
  const [filterCheckedContactStatuses, setFilterCheckedContactStatuses] =
    useState<CheckedContactStatus[]>([]);

  const handleFilterContactStatusChecked = (contactStatus: string) => {
    const contactStatusToAdd: CheckedContactStatus = { contactStatus };

    if (
      checkedContactStatuses.some(
        (item) =>
          item.contactStatus.toLowerCase() === contactStatus.toLowerCase()
      )
    ) {
      setCheckedContactStatuses(
        checkedContactStatuses.filter(
          (item) =>
            item.contactStatus.toLowerCase() !== contactStatus.toLowerCase()
        )
      );
    } else {
      setCheckedContactStatuses([
        ...checkedContactStatuses,
        contactStatusToAdd,
      ]);
    }
  };

  const clearCheckedContactStatus = (contactStatus: string) => {
    setCheckedContactStatuses(
      checkedContactStatuses.filter(
        (item) => item.contactStatus !== contactStatus
      )
    );
  };

  const handleFilterContacts = React.useCallback(
    (statusArray: any) => {
      setFilterCheckedContactStatuses(statusArray);

      if (statusArray.length > 0) {
        let filteredOption = dataForSearch.filter((item: any) => {
          const successFilter = statusArray.some(
            (statusObj: any) => statusObj.contactStatus === "Success"
          );
          const failedFilter = statusArray.some(
            (statusObj: any) => statusObj.contactStatus === "Failed"
          );

          if (successFilter && failedFilter) {
            return item.validation?.length === 0 || item.validation?.length > 0;
          } else if (successFilter) {
            return item.validation?.length === 0;
          } else if (failedFilter) {
            return item.validation?.length > 0;
          }
          return false;
        });

        setHistoryDetails(filteredOption);
      } else {
        setHistoryDetails(dataForSearch);
      }
    },
    [setFilterCheckedContactStatuses, dataForSearch]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterContacts(checkedContactStatuses);

    setFilterApplied(checkedContactStatuses?.length === 0 ? false : true);
  }, [handleFilterContacts, setFilterApplied, checkedContactStatuses]);

  // const handleFilterApply = () => {
  //   let filterOption: any = dataForSearch;
  //   if (importedFilter) {
  //     filterOption = filterOption.filter((obj: any) => {
  //       let success = obj?.validation?.length === 0;
  //       let failed = obj?.validation?.length > 0;
  //       let successTrue = importedFilter === "Success";
  //       let failedTrue = importedFilter === "Failed";
  //       if (successTrue === success) {
  //         return obj?.validation?.length === 0;
  //       } else if (failedTrue === failed) {
  //         return obj?.validation?.length > 0;
  //       }
  //     });
  //   }
  //   setHistoryDetails(filterOption);
  // };

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setCheckedContactStatuses([]);
    handleFilterContacts([]);
    setFilterCheckedContactStatuses([]);
  }, [
    setFilterApplied,
    setCheckedContactStatuses,
    handleFilterContacts,
    setFilterCheckedContactStatuses,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedContactStatuses(filterCheckedContactStatuses);
  }, [filterCheckedContactStatuses]);

  const filteredValue = (
    <>
      {filterCheckedContactStatuses?.length > 0 &&
        filterCheckedContactStatuses?.map((item) => (
          <div key={item.contactStatus} className="filteredDiv">
            {item.contactStatus}
          </div>
        ))}
    </>
  );

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100% - 65px)",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#F5F5F5",
        padding: "14px",
        overflowY: "auto",
        minHeight: "calc(100vh - 65px)",
        maxHeight: "calc(100vh - 65px)",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#FFF",
          overflow: "auto",
          border: "1px solid rgb(233, 233, 233)",
          borderRadius: "8px",
          padding: "14px 14px",
        }}
      >
        <Box
          sx={{
            paddingBottom: "15px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <button className={styles.PageBackBtn} onClick={() => navigate(-1)}>
            <ArrowBackIcon
              sx={{
                marginRight: "8px",
                fontSize: "15PX",
                color: "#667085",
              }}
            />
            Back
          </button>
        </Box>

        <Box padding={"0px 19px"}>
          <Box
            sx={{
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "24px",
              color: "#142C44",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>{formatTimestamp(timeOfUpload)}</Box>
            <Box display="flex" alignItems="center">
              <Box marginRight={2}>
                <TextField
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                    setHistoryDetails(
                      dataForSearch?.filter((obj: any) =>
                        obj?.name
                          .toLocaleLowerCase()
                          .includes(e.target.value.toLocaleLowerCase())
                      )
                    );
                  }}
                  size="small"
                  sx={{
                    width: "380px",
                    "&.MuiOutlinedInput-root": {
                      paddingLeft: "0 !important",
                    },
                  }}
                />
              </Box>
              <ClearFilter
                open={isOpenFilter}
                noButtonClick={noButtonClick}
                yesButtonClick={yesButtonClick}
                onClose={closeClearFilter}
                filteredValue={filteredValue}
              />
              <FilterDrawer
                handleFilterApply={handleFilterApply}
                handleResetClick={handleResetClick}
                activeFilter={filterApplied}
                cancelClick={cancelClick}
                closeSetFilter={handleFilterClose}
              >
                <div
                  style={{
                    padding: "22px 32px",
                    overflowY: "auto",
                    maxHeight: "calc(100% - 110px)",
                  }}
                >
                  <FilterChipsWrap>
                    {checkedContactStatuses.length > 0 &&
                      checkedContactStatuses.map((item) => (
                        <FilterChips
                          keyVal={item.contactStatus}
                          chipName={item.contactStatus.toLowerCase()}
                          clearValue={item.contactStatus}
                          closeFunction={clearCheckedContactStatus}
                        />
                      ))}
                  </FilterChipsWrap>
                  <FilterTypeWrap>
                    <FilterLabel labelValue="Status" />
                  </FilterTypeWrap>
                  <FilterCheckBoxWrap>
                    <FormGroup>
                      {options?.map((item: any) => (
                        <FilterCheckbox
                          keyVal={item.id}
                          checked={checkedContactStatuses.some(
                            (data) => data.contactStatus === item.name
                          )}
                          checkboxFunction={() =>
                            handleFilterContactStatusChecked(item.name)
                          }
                          label={item.name.toLowerCase()}
                        />
                      ))}
                    </FormGroup>
                  </FilterCheckBoxWrap>
                </div>
              </FilterDrawer>
            </Box>
          </Box>
          <Box marginTop={2}>
            <TableContainer
              style={{
                maxHeight: "100%",
                border: `1px solid #EAECF0`,
                position: "relative",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow sx={{ height: "49px" }}>
                    {columns.map((column: any) => {
                      return (
                        <>
                          {column.show && (
                            <CustomTableHeaderCell
                              key={column.id}
                              align={column?.align}
                              style={{ minWidth: column.minWidth }}
                              sx={{
                                background: "#F9FAFB",
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {column.label}
                            </CustomTableHeaderCell>
                          )}
                        </>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {historyDetails &&
                    historyDetails !== null &&
                    historyDetails
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((institute: any, index: any) => {
                        return (
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            sx={{
                              padding: "0px",
                              height: "72px",
                              cursor: "pointer",
                            }}
                            className="student_table_body"
                          >
                            <CustomTableCell>
                              {startIndex + index + 1}
                            </CustomTableCell>
                            {columns[0].show && (
                              <CustomTableCell sx={{}}>
                                <div>
                                  <p
                                    style={{
                                      color: "#667085",
                                      margin: "0px",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {institute?.name ? institute?.name : "-"}
                                  </p>
                                </div>
                              </CustomTableCell>
                            )}

                            {columns[1].show && (
                              <CustomTableCell sx={{}}>
                                <Box>
                                  <>
                                    <p
                                      style={{
                                        borderRadius: "16px",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        fontWeight: "500",
                                        color: "#667085",
                                      }}
                                    >
                                      {institute?.email
                                        ? institute?.email
                                        : "-"}
                                    </p>
                                  </>
                                </Box>
                              </CustomTableCell>
                            )}

                            {columns[2].show && (
                              <CustomTableCell sx={{}}>
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#667085",
                                        margin: "0px",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {institute?.phoneNumber
                                        ? institute?.phoneNumber
                                        : "-"}
                                    </p>
                                  </Box>
                                </>
                              </CustomTableCell>
                            )}
                            {columns[3].show && (
                              <CustomTableCell sx={{}}>
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#667085",
                                        margin: "0px",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {institute?.courseId
                                        ? getCourse(
                                            institute?.courseId,
                                            courseList
                                          )?.courseName
                                        : "-"}
                                    </p>
                                  </Box>
                                </>
                              </CustomTableCell>
                            )}

                            {columns[4].show && (
                              <CustomTableCell sx={{}}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      color:
                                        institute?.validation?.length === 0
                                          ? "#027A48"
                                          : "#EC3939",
                                      backgroundColor:
                                        institute?.validation?.length === 0
                                          ? "#ECFDF3"
                                          : "rgba(211, 47, 47, 0.07)",
                                      borderRadius: "16px",
                                      padding: "4px 8px",
                                      fontSize: "12px",
                                      lineHeight: "18px",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {institute?.validation?.length === 0
                                      ? "Success"
                                      : "Failed"}
                                  </p>
                                </Box>
                              </CustomTableCell>
                            )}
                            {columns[5].show && (
                              <CustomTableCell sx={{}}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      color:
                                        institute?.validation?.length === 0
                                          ? "#667085"
                                          : "#EC3939",
                                      margin: "0px",
                                      textTransform: "capitalize",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {institute?.validation?.length === 0
                                      ? "-"
                                      : institute?.validation[0]}
                                  </p>
                                </Box>
                              </CustomTableCell>
                            )}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            {dataForSearch &&
            dataForSearch?.length !== 0 &&
            historyDetails &&
            historyDetails?.length <= 0 ? (
              <div
                style={{
                  position: "relative",
                  minHeight: "400PX",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderLeft: `1px solid #EAECF0`,
                  borderRight: `1px solid #EAECF0`,
                  borderBottom: `1px solid #EAECF0`,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      color: " #667085",
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <div style={{ color: " #142C44" }}>No results found.</div>
                    Try another search
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "14PX",
            }}
          >
            {historyDetails && historyDetails.length > 0 && (
              <Box
                sx={{
                  padding: "16px 0px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {historyDetails.length > rowsPerPage && (
                  <Pagination
                    count={Math.ceil(historyDetails.length / rowsPerPage)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    onChange={(e: any, page: any) => handleChange(page)}
                    sx={{
                      color: "#667085",
                      ".css-c8u8r0-MuiButtonBase-root-MuiPaginationItem-root": {
                        border: "none",
                        height: "fit-content",
                        minHeight: "32px",
                        padding: "10px 17px",
                        color: "#667085",
                      },
                      ".MuiButtonBase-root.MuiPaginationItem-root": {
                        color: "#667085",
                        width: "40px",
                        height: "40px",
                        borderRadius: "6px",
                      },
                      ".MuiButtonBase-root.MuiPaginationItem-root.Mui-selected":
                        {
                          color: "#11C89B",
                        },

                      "& .MuiButtonBase-root.MuiPaginationItem-root ": {
                        border: "none",
                      },
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterSettings;
