import List from "@mui/material/List";
import styles from "./Registration.module.css";
import React from "react";
import { ListItemComp } from "./PasswordCriteriaComp";
export default function PasswordValidation(props: {
  copyText: string;
  setIsDisabled: any;
}) {
  const { copyText, setIsDisabled } = props;
  const [minimumEightChar, setMinimumEightChar] =
    React.useState<boolean>(false);
  const [onenumber, setOnenumber] = React.useState<boolean>(false);
  const [uppercasecharacter, setUppercasecharacter] =
    React.useState<boolean>(false);
  const [specialcharacter, setSpecialcharacter] =
    React.useState<boolean>(false);
  //validation

  React.useEffect(() => {
    const number = /(?=.*\d)/;
    const upper = /(?=.*[A-Z])/;
    // const sepcial = /(?=.*[@#$%^&*+=!`/,])/;
    const sepcial = /(?=.*?[#?!@$%^&*-])/;

    //number validation
    if (number.test(copyText)) {
      setOnenumber(true);
    } else {
      setOnenumber(false);
    }
    // uppercase Validation
    if (upper.test(copyText)) {
      setIsDisabled(true);
      setUppercasecharacter(true);
    } else {
      setUppercasecharacter(false);
    }
    // special Validation
    if (sepcial.test(copyText)) {
      //   setIsDisabled(true);
      setSpecialcharacter(true);
    } else {
      setSpecialcharacter(false);
    }
    //Length validation
    if (copyText.length >= 8) {
      //   setSpecialcharacter(true);

      setMinimumEightChar(true);
    } else {
      setMinimumEightChar(false);
    }
    if (
      minimumEightChar &&
      onenumber &&
      uppercasecharacter &&
      specialcharacter
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    copyText,
    minimumEightChar,
    onenumber,
    setIsDisabled,
    specialcharacter,
    uppercasecharacter,
  ]);
  return (
    <List className={styles.list}>
      <ListItemComp
        isError={minimumEightChar}
        copy={"Minimum eight characters"}
      />
      <ListItemComp isError={onenumber} copy={"Atleast one number"} />
      <ListItemComp
        isError={uppercasecharacter}
        copy={"Atleast one uppercase character"}
      />
      <ListItemComp
        isError={specialcharacter}
        copy={"Atleast one special character"}
      />
    </List>
  );
}
