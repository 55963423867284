import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./UserAssign.module.css";
import SearchIcon from "@mui/icons-material/Search";
import defaultAssignee from "../../pages/app/Nurture/components/Assets/AssigneeDefault.svg";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";
import { useAuthUser } from "react-auth-kit";

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

export default function UserAssign(props: any) {
  const {
    isForEnquiryModal,
    disabled,
    selectedAssignyId,
    users,
    handleLeadAssignee,
    leadId,
    size,
    isForDispositionForm,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [searchTerm, setSearchTerm] = useState<any>("");
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (user: User) => {
    handleLeadAssignee(leadId, user);
    setSelectedUser(user);
    setIsOpen(!isOpen);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };
  const filteredTeams = hasAllBranchAccess
    ? users
    : users?.filter(
        (item: any) => item?.branchId === authUser?.institute?.[0]?.branchId
      );

  // const branchEmployees =
  //   users?.filter(
  //     (item: any) => item?.branchId === authUser?.institute?.[0]?.branchId
  //   ) || [];

  // const otherBranchEmployees =
  //   users?.filter(
  //     (item: any) =>
  //       item?.branchId !== authUser?.institute?.[0]?.branchId &&
  //       item?.allBranchAccess === true
  //   ) || [];
  // const combinedEmployees = [...branchEmployees, ...otherBranchEmployees];

  const filteredUsers =
    users &&
    users
      ?.filter((user: any) => {
        const searchTermMatch =
          user.firstName?.toLowerCase().indexOf(searchTerm?.toLowerCase()) !==
          -1;
        return searchTermMatch;
      })
      ?.filter((user: any) => {
        return user.status === "ACTIVE" && user?.leadCardAccess === true;
      })
      ?.sort((userA: any, userB: any) => {
        if (userA.id === selectedAssignyId) {
          return -1;
        } else if (userB.id === selectedAssignyId) {
          return 1;
        } else {
          return 0;
        }
      });

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (selectedAssignyId !== null && selectedAssignyId !== undefined) {
      const defaultAssignedTo =
        users && users.find((user: any) => user.id === selectedAssignyId);
      setSelectedUser(defaultAssignedTo);
    } else {
      setSelectedUser("");
    }
  }, [setSelectedUser, selectedAssignyId, users]);

  return (
    <div className={styles.userAssignWrap} ref={wrapperRef}>
      <IconButton
        className={styles.userAssignBtn}
        onClick={toggleDropdown}
        disabled={disabled ? true : false}
        sx={{
          minWidth: "30px",
          borderRadius: "50%",
          background: selectedUser === "" ? "#fff" : "#f5f5f7",
          border: selectedUser === "" ? "1px solid #fff" : "1px solid #EEEEF4",
          width: size === "large" ? "42px" : size === "xl" ? "44px" : "30px",
          height: size === "large" ? "42px" : size === "xl" ? "44px" : "30px",
          padding: "0",
          overflow: "hidden",
        }}
      >
        {users && selectedUser !== null && selectedUser !== "" ? (
          <>
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                fontSize:
                  size === "large" ? "16px" : size === "xl" ? "14px" : "11px",
                display: "flex",
                alignItems: "center",
                textTransform: "uppercase",
                background: isHovered ? "#ECECEC" : "#F5F5F7",
                border: "1.11917px solid #F5F5F7",
                color: "#667085",
                fontWeight: "500",
                ":hover": "backgroundColor:red",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {selectedUser?.firstName?.charAt(0) +
                selectedUser?.lastName?.charAt(0)}
            </Avatar>
          </>
        ) : (
          <img src={defaultAssignee} alt="" />
        )}
      </IconButton>

      {isOpen && (
        <div
          className={
            isForEnquiryModal
              ? styles.isForEnquirymodal
              : isForDispositionForm
              ? styles.isForDispositionForm
              : styles.userAssignPopup
          }
        >
          <TextField
            placeholder="Search"
            sx={{ margin: "0 0 10px 0" }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchTerm}
            onChange={handleSearch}
            size="small"
          />
          <ul className={styles.userAssignPopupList}>
            {users &&
              filteredUsers?.map((user: any) => {
                return (
                  <div key={user.id}>
                    <li className={styles.userAssignItem}>
                      <div className={styles.userAssignItemLeft}>
                        <Avatar
                          sx={{
                            width: "34px",
                            height: "34px",
                            fontSize: "11px",
                            display: "flex",
                            alignItems: "center",
                            textTransform: "uppercase",
                            fontWeight: "500",
                            background: "#F5F5F7",
                            border: "1px solid #EEEEF4",
                            color: "#667085",
                          }}
                        >
                          <Box>
                            {user?.firstName?.charAt(0) +
                              user?.lastName?.charAt(0)}
                          </Box>
                        </Avatar>
                        <div className={styles.userAssignName}>
                          {user.firstName + " " + user.lastName}
                        </div>
                      </div>
                      {user.id === selectedAssignyId ? (
                        <></>
                      ) : (
                        <div
                          className={styles.userAssignSelectBtn}
                          onClick={() => {
                            handleSelect(user);
                            setIsOpen(!isOpen);
                          }}
                        >
                          assign
                        </div>
                      )}
                    </li>
                  </div>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
}
