import React, { useState, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Box, TextField, PaginationItem } from "@mui/material";
import setting from "../../../../../assets/images/setting.svg";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";
import EmailSettings from "../Settings/EmailSettings/EmailSettings";
import { useAuthUser } from "react-auth-kit";

const tempData = [
  {
    id: 1,
    gmail: "oliviarhye@gmail.com",
    status: "Pending",
    course: "Angular js essentials",
    date: "Jan 21st, 13:29 pm",
    mailStatus: "read",
  },
  {
    id: 2,
    gmail: "test12@gmail.com",
    status: "Contacted",
    course: "Node js basics",
    date: "Jan 21st, 13:29 pm",
    mailStatus: "unread",
  },
  {
    id: 3,
    gmail: "roy@gmail.com",
    status: "Nagotiating",
    course: "UI UX Design",
    date: "Jan 21st, 13:29 pm",
    mailStatus: "unread",
  },
  {
    id: 4,
    gmail: "Roma@gmail.com",
    status: "Demo",
    course: "Frontend developement",
    date: "Jan 21st, 13:29 pm",
    mailStatus: "unread",
  },
  {
    id: 5,
    gmail: "devil@gmail.com",
    status: "Pending",
    course: "Frontend developement",
    date: "Jan 21st, 13:29 pm",
    mailStatus: "unread",
  },
  {
    id: 6,
    gmail: "idon@gmail.com",
    status: "Pending",
    course: "React Js",
    date: "Jan 21st, 13:29 pm",
    mailStatus: "read",
  },
];

export default function EmailListing(props: any) {
  const { onBackClick, GetMailEntriesByInstitute, mailList } = props;
  const onRowClick = (rowData: any) => {};

  const [searchTerm, setSearchTerm] = useState("");
  const [showEmailSettings, setShowEmailSettings] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const auth = useAuthUser();
  const authUser = auth();

  const handleChangePage = (value: any) => {
    setPage(value - 1);
  };

  const handleSettingsClick = () => {
    setShowEmailSettings(true);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const filteredMailList =
    mailList &&
    mailList?.filter((item: any) => {
      const emailString = item.leadEmail;
      const emailRegex = /<([^>]+)>/;
      const matches = emailString.match(emailRegex);
      const email = matches ? matches[1] : "";
      const searchTermMatch =
        email && email.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const navigate = useNavigate();

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage) {
      GetMailEntriesByInstitute(userFromLocalStorage.instituteId);
    }
  }, [GetMailEntriesByInstitute]);

  function getFormattedDate(date: any) {
    return moment(date).format("MMM Do, hh:mm A");
    // return moment(date).format("DD MMM YYYY, hh:mm A");
  }
  const extractEmail = useCallback((emailString: any) => {
    const emailRegex = /<([^>]+)>/;
    const matches = emailString.match(emailRegex);
    const email = matches ? matches[1] : "";
    return email;
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {showEmailSettings ? (
        <EmailSettings />
      ) : (
        <Box
          sx={{
            width: "100%",
            background: "#ffffff",
          }}
        >
          <Box
            sx={{
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Email Inbox</div>

            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <TextField
                placeholder="Search Emails"
                value={searchTerm}
                onChange={handleSearch}
                inputProps={{
                  style: {
                    background: "#ffffff",
                    padding: "8px 12px",
                    width: "300px",
                  },
                }}
              />
              <button
                onClick={handleSettingsClick}
                style={{
                  background: "#ffffff",
                  border: "1px solid #D0D5DD",
                  cursor: "pointer",
                  borderRadius: "4px",
                  padding: "6px 4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={setting}
                  width={"22px"}
                  height={"22px"}
                  style={{ margin: "0px 8px" }}
                  alt={""}
                />
              </button>
            </div>
          </Box>
          {/* <Box
          sx={{
            padding: "16px",
          }}
        >
          <img
            src={backBTN}
            width={"22px"}
            height={"22px"}
            alt={""}
            // onClick={onBackClick}
            onClick={() => navigate(-1)}
          />
        </Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // height: "calc(100vh - 210px)",
              height: "calc(100vh - 160px)",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                // height: "calc(100vh - 200px)",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FFFFFF",
                margin: "6px",
                overflowY: "hidden",
                overflowX: "auto",
                // height: "100%",
                borderRadius: "8px",
                border: "1px solid #EAECF0",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#F9FAFB",
                    padding: "10px 0",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // width: "170px",
                      width: "280px",

                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "poppins",
                      color: "#667085",
                      marginLeft: "30px",
                    }}
                  >
                    Email
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "170px",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "poppins",
                      color: "#667085",
                      marginLeft: "30px",
                    }}
                  >
                    Course Name
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "170px",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "poppins",
                      color: "#667085",
                      marginLeft: "30px",
                    }}
                  >
                    Status
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "170px",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontFamily: "poppins",
                      color: "#667085",
                      marginLeft: "30px",
                    }}
                  >
                    Date & Time
                  </div>
                </div>
                <div
                  style={{
                    maxHeight: "calc(100vh - 210px)",

                    // overflowY: "scroll",
                    overflowY: "auto",
                  }}
                >
                  {mailList &&
                    filteredMailList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item: any) => (
                        // <button
                        //   style={{
                        //     width: "100%",
                        //     backgroundColor: "transparent",
                        //     border: "none",
                        //   }}
                        //   onClick={() => navigate("EmailDetail")}
                        // >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid lightgray",
                          }}
                        >
                          <div
                            style={{
                              borderRadius: "50%",
                              border: "3px solid",
                              margin: "18px 12px",
                              color:
                                item.mailStatus === "unread"
                                  ? "#11C89B"
                                  : "#D8D8D8",
                            }}
                          ></div>
                          {/* <button
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 0px",
                    // borderBottom: "1px solid lightgray",
                    // width: "fit-content",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                > */}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "5px 0px",
                              cursor: "pointer",
                              // borderBottom: "1px solid lightgray",
                              // width: "fit-content",
                              // backgroundColor: "transparent",
                              // border: "none",
                            }}
                            // onClick={() => navigate("EmailDetail", { state: item })}
                            onClick={() =>
                              navigate(`EmailDetail/${item && item.id}`)
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                // width: "170px",
                                width: "280px",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "14px",
                                  fontWeight:
                                    item.mailStatus === "unread"
                                      ? "600"
                                      : "400",
                                  fontFamily: "poppins",
                                  color: "#101828",
                                }}
                              >
                                {/* {item.leadEmail} */}
                                {extractEmail(item.leadEmail)}
                              </p>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "170px",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  fontFamily: "poppins",
                                  color: "#667085",
                                }}
                              >
                                {/* {item.course} */}-
                              </p>
                            </Box>

                            {item.status !== "" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "170px",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0px",
                                    backgroundColor: "#F2F4F7",
                                    color:
                                      item.entryStatus === "PENDING"
                                        ? "red"
                                        : "#344054",
                                    padding: "5px 10px",
                                    borderRadius: "16px",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    fontFamily: "poppins",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.entryStatus.toLowerCase()}
                                </p>
                              </Box>
                            )}
                            <Box
                              sx={{
                                width: "170px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  color: "#667085",
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {getFormattedDate(
                                  parseInt(item.messageTimestamp)
                                )}
                              </p>
                            </Box>
                          </div>
                        </Box>
                      ))}
                </div>
              </div>
            </Box>

            {mailList && filteredMailList && filteredMailList.length > 0 && (
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                {filteredMailList.length > rowsPerPage && (
                  <Pagination
                    count={Math.ceil(filteredMailList.length / rowsPerPage)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    onChange={(e: any, page: any) => handleChangePage(page)}
                    sx={{
                      "& .MuiButtonBase-root.MuiPaginationItem-root ": {
                        border: "none",
                      },
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
