import React from "react";
import styles from "../Managebatches.module.css";
import blueAdd from "../../../../../../assets/images/blue-add.svg";
import IconDropdown from "../../../../../../components/Dropdowns/IconDropdown";
import editIcon from "../../../../../../assets/images/edit-icon.svg";
import TypeEditPopup from "../EditPopup/TypeEditPopup";
import AddMembershipDiscount from "../EditPopup/AddMembershipDiscount";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";
import EditMembershipDiscount from "../EditPopup/EditMembershipDiscount";
import { useSelector } from "react-redux";
import whiteEditIcon from "../../../../../../assets/images/white-edit-icon.svg";
import addIcon from "../../../../../../assets/images/add-white-icon.svg";
import { selectClasses } from '@mui/material';

function BatchPaymentMembershipDetail(props: any) {
	const { batchData, hasBatchAccess, selectedCurrency } = props;
	const { batchPayUpdateSuccess, batchPayUpdateLoading } = useSelector(
		(state: any) => state.batch
	);
	const [showEditPopup, setShowEditPopup] = React.useState<boolean>(false);
	const [addDiscountPopup, setAddDiscountPopup] =
		React.useState<boolean>(false);
	const [editDiscountPopup, setEditDiscountPopup] =
		React.useState<boolean>(false);
	const [typeSelected, setTypeSelected] = React.useState<any>('');

	const handleOpenEditPopup = (type: any) => {
		setShowEditPopup(true);
		setTypeSelected(type);
	};
	const handleCloseEditPopup = (type: any) => {
		setShowEditPopup(false);
		setTypeSelected('');
	};

	const openAddDiscountPopup = (type: any) => {
		setAddDiscountPopup(true);
		setTypeSelected(type);
	};
	const closeAddDiscountPopup = (type: any) => {
		setAddDiscountPopup(false);
		setTypeSelected('');
	};

	const openEditDiscountPopup = (type: any) => {
		setEditDiscountPopup(true);
		setTypeSelected(type);
	};
	const closeEditDiscountPopup = (type: any) => {
		setEditDiscountPopup(false);
		setTypeSelected('');
	};

	React.useEffect(() => {
		if (batchPayUpdateSuccess !== true && batchPayUpdateSuccess !== false) {
			setAddDiscountPopup(false);
			setEditDiscountPopup(false);
			setShowEditPopup(false);
		}
	}, [batchPayUpdateSuccess]);

	return (
		<div className={styles.batchMembershipWrap}>
			{batchData?.paymentDetails?.paymentData?.paymentStructure?.map(
				(item: any) => (
					<div className={styles.batchMembershipItem}>
						<div className={styles.batchMembershipItemHead}>
							<div className={styles.batchMembershipItemHeadTop}>
								<div className={styles.batchMembershipItemName}>
									{item?.frequency?.toLowerCase()?.replace(/_/g, ' ')}
								</div>
								<div>
									{/* <IconDropdown
                    options={[
                      {
                        label: "Edit",
                        onClick: () => handleOpenEditPopup(item),
                        icon: editIcon,
                        style: { color: "#667085" },
                      },
                    ]}
                  /> */}
								</div>
							</div>

							<div className={styles.batchMembershipItems}>
								<div>
									<div
										className={styles.batchMembershipItemLabel}
										style={{ marginBottom: '2px' }}>
										Membership Fees
									</div>
									<div className={styles.batchMembershipItemPrice}>
										{/*      {item?.emiAmount?.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })} */}
										{selectedCurrency + ' ' + item?.emiAmount}
									</div>
								</div>
								<div>
									<div
										className={styles.batchMembershipItemLabel}
										style={{
											marginBottom: '2px',
											display: 'flex',
											alignItems: 'flex-end',
										}}>
										Discount
										{item?.discountMetric !== '' && hasBatchAccess && (
											<div style={{ marginBottom: '2PX' }}>
												<EditIconBtn
													onClick={() => openEditDiscountPopup(item)}
													maxWidth={'24PX'}
													icon={whiteEditIcon}
												/>
											</div>
										)}
									</div>
									<div className={styles.batchMembershipItemPrice}>
										{item?.discountMetric === '' && hasBatchAccess ? (
											<button
												className={styles.batchMembershipAddDiscountBtn}
												onClick={() => openAddDiscountPopup(item)}>
												<img
													src={addIcon}
													alt='addIcon'
													style={{ marginRight: '6px', marginBottom: '2px' }}
												/>
												Add Discount
											</button>
										) : (
											<>
												{/* {(
													item?.emiAmount - item?.finalEmiAmount
												)?.toLocaleString('en-IN', {
													style: 'currency',
													currency: 'INR',
												})} */}
												{selectedCurrency}
												{item?.emiAmount - item?.finalEmiAmount}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className={styles.batchMembershipItemBottom}>
							<div></div>
							<div>
								<div
									className={styles.batchMembershipItemLabel}
									style={{ marginBottom: '3px', color: '#667085' }}>
									Final Membership Fees
								</div>
								<div className={styles.batchMembershipItemFinalPrice}>
									{/* 	{item?.finalEmiAmount?.toLocaleString('en-IN', {
										style: 'currency',
										currency: 'INR',
									})} */}
									{selectedCurrency + ' ' + item?.finalEmiAmount}
									<span className={styles.batchMembershipItemPer}>
										/ {item?.frequency?.toLowerCase()?.replace(/_/g, ' ')}
									</span>{' '}
								</div>
							</div>
						</div>
					</div>
				)
			)}
			<TypeEditPopup
				isOpen={showEditPopup}
				onClose={handleCloseEditPopup}
				typeSelected={typeSelected}
				batchData={batchData}
				loader={batchPayUpdateLoading}
			/>
			<AddMembershipDiscount
				isOpen={addDiscountPopup}
				onClose={closeAddDiscountPopup}
				typeSelected={typeSelected}
				batchData={batchData}
				loader={batchPayUpdateLoading}
				selectedCurrency={selectedCurrency}
			/>
			<EditMembershipDiscount
				isOpen={editDiscountPopup}
				onClose={closeEditDiscountPopup}
				typeSelected={typeSelected}
				batchData={batchData}
				loader={batchPayUpdateLoading}
				selectedCurrency={selectedCurrency}
			/>
		</div>
	);
}

export default BatchPaymentMembershipDetail;
