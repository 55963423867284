import React, { useState } from "react";
import { Button, Dialog } from "@mui/material";
import styles from "./DeactivationModal.module.css";

function DeactivationModal(props: any) {
  const { type, handlePopupYesButton, handlePopupNoButton } = props;
  const [isOpen, setIsOpen] = useState(true);

  const renderPopupHeading = () => {
    if (type === "course") {
      return "Are you sure you want to Deactivate this course?";
    } else if (type === "batch") {
      return "Are you sure you want to Deactivate this Batch?";
    }
  };

  const renderPopupPara = () => {
    if (type === "course") {
      return "No further Batches can be added to this Course";
    } else if (type === "batch") {
      return "";
    }
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        zIndex: "99999",
        minWidth: "414px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "414px",
          maxHeight: "290px",
          minHeight: "165px",
          padding: "34px",
          borderRadius: "8px",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
          left: "10%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div className={styles.deactivationPopup}>
        <div className={styles.popupHead}>{renderPopupHeading()}</div>
        <div>
          <p className={styles.popupPara}>{renderPopupPara()}</p>
        </div>
        <div className={styles.popupButtonDiv}>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#ffffff",
              color: "#344054",
              border: "1px solid #D0D5DD",
            }}
            onClick={() => {
              handlePopupNoButton();
              setIsOpen(false);
            }}
            fullWidth
          >
            No
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#EC3939",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#EC3939",
              },
            }}
            onClick={() => {
              handlePopupYesButton();
              setIsOpen(false);
            }}
            fullWidth
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DeactivationModal;
