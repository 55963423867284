import React, { useState } from "react";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Button, Dialog } from "@mui/material";
import styles from "./CustomPopupBilling.module.css";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function CustomPopupBilling(props: any) {
  const { type, userType } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {};

  const renderPopupHeading = () => {
    if (type === "archived") {
      return "your account has been Archived";
    } else if (type === "unarchived") {
      return "your account has been Unarchived";
    } else if (type === "locked") {
      return "Your account is temporarily locked.";
    }
  };

  const renderPopupPara = () => {
    if ((type === "archived" || type === "locked") && userType === "user") {
      return "Contact your admin to renew Subscription";
    } else if (type === "archived" && userType === "admin") {
      return "Please settle the outstanding payment to continue using nurture.";
    } else if (type === "unarchived") {
      return "We will send you an email once the account is activated.";
    } else if (type === "locked") {
      return "Please make a payment to reactivate the account.";
    } else if (userType === "user") {
      return "Please make a payment to reactivate the account.";
    }
  };

  const renderIcon = () => {
    if (type === "archived") {
      return (
        <Inventory2OutlinedIcon className={styles.iconRed} fontSize="large" />
      );
    } else if (type === "unarchived") {
      return (
        <CheckCircleOutlinedIcon
          className={styles.iconGreen}
          fontSize="large"
        />
      );
    } else if (type === "locked") {
      return <LockOutlinedIcon className={styles.iconRed} fontSize="large" />;
    }
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        zIndex: "99999",
        minWidth: "414px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "414px",
          maxHeight: "290px",
          minHeight: "200px",
          padding: "34px",
          borderRadius: "8px",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
          left: "10%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div className={styles.customPopup}>
        {renderIcon()}
        <div
          className={
            type === "unarchived" ? styles.popupHeadGreen : styles.popupHeadRed
          }
        >
          {renderPopupHeading()}
        </div>
        <div>
          <p className={styles.popupPara}>{renderPopupPara()}</p>
        </div>
        {userType === "admin" && (type === "archived" || type === "locked") && (
          <div className={styles.popupButtonDiv}>
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={handleButtonClick}
              variant="contained"
              color="primary"
            >
              Pay Now
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default CustomPopupBilling;
