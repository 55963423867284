import React from "react";
import styles from "./Managebatches.module.css";
import EditBatchBasicDetails from "./EditBatchBasicDetails";
import BatchTimingDetails from "./Timing/BatchTimingDetails";
import BatchPaymentDetails from "./Payment/BatchPaymentDetails";
import BatchDetailsSkeleton from "../../../../../components/Skeletons/Batch/BatchDetailsSkeleton";
import BatchTimeSkeleton from "../../../../../components/Skeletons/Batch/BatchTimeSkeleton";
import BatchPaySkeleton from "../../../../../components/Skeletons/Batch/BatchPaySkeleton";
import BatchStudents from "./Student/BatchStudents";
import BatchStudentSkeleton from "../../../../../components/Skeletons/Batch/BatchStudentSkeleton";

function BatchDetails(props: any) {
  const { batchData, hasBatchAccess, batchByIdLoading, batchListLoading } =
    props;
  const [editScreen, setEditScreen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (batchByIdLoading === true) {
      setEditScreen(false);
    }
  }, [batchByIdLoading]);
  return (
    <div className={styles.rightSubSection}>
      {batchData !== null && !batchByIdLoading && !batchListLoading ? (
        <EditBatchBasicDetails
          batchData={batchData}
          hasBatchAccess={hasBatchAccess}
          editScreen={editScreen}
          setEditScreen={setEditScreen}
          batchByIdLoading={batchByIdLoading}
        />
      ) : (
        <BatchDetailsSkeleton />
      )}

      {batchData !== null && !batchByIdLoading && !batchListLoading ? (
        <BatchTimingDetails
          batchData={batchData}
          hasBatchAccess={hasBatchAccess}
          editScreen={editScreen}
          setEditScreen={setEditScreen}
        />
      ) : (
        <BatchTimeSkeleton />
      )}

      {batchData?.batchConductType === "REGULAR_BATCH" && (
        <>
          {batchData !== null && !batchByIdLoading && !batchListLoading ? (
            <BatchPaymentDetails
              batchData={batchData}
              hasBatchAccess={hasBatchAccess}
            />
          ) : (
            <BatchPaySkeleton />
          )}
        </>
      )}
      {batchData?.status !== "DRAFT" && (
        <>
          {batchData !== null && !batchByIdLoading && !batchListLoading ? (
            <BatchStudents
              batchData={batchData}
              hasBatchAccess={hasBatchAccess}
            />
          ) : (
            <BatchStudentSkeleton />
          )}
        </>
      )}
    </div>
  );
}

export default BatchDetails;
