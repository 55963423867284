import { Box } from "@mui/material";
import React from "react";
import call from "./assets/call.svg";
import meetings from "./assets/meeting.svg";
import email from "./assets/email.svg";
import ActivityTask from "./ActivityTask";
import ActivityStatus from "./ActivityStatus";
import ActivityLeadEnquired from "./ActivityLeadEnquired";
import ActivityDemo from "./ActivityDemo";
import demoIcon from "./assets/demo.svg";
import statusChange from "./assets/status-change.svg";
import created from "./assets/lead-created.svg";
import ActivityNote from "./ActivityNote";
import noteIcon from "./assets/note.svg";
import ActivityPayment from "./ActivityPayment";
import payment from "./rupee.svg";
import styles from "./ActivityList.module.css";
import ActivityNegotiation from "./ActivityNegotiation";
import ActivityStage from "./ActivityStage";
import ActivityLead from "./ActivityLead";
import leadEdit from "./assets/edit-activity.svg";
import leadStatus from "./assets/status-activity-change.svg";
import ActivityTaskStatusChange from "./ActivityTaskStatusChange";
import negotiation from "./assets/negotiation.svg";
import ActivityCall from "./ActivityCall";
import activityCall from "./assets/activityCall.svg";
import ActivityComment from "./ActivityComment";
import commentIcon from "./assets/acivityComment.svg";
import AmountEdited from "./EMI/AmountEdited";
import PauseMembership from "./EMI/PauseMembership";
import InstallmentEdited from "./EMI/InstallmentEdited";
import PlanChanged from "./EMI/PlanChanged";
import pauseIcon from "./assets/activityPause.svg";
import ActivitySession from "./ActivitySession";

function ActivityList(props: any) {
  const { data, activeTab, listData, index, employees, selectedCurrency } =
		props;

	const sideIcon = (type: string) => {
		switch (type) {
			case 'CALL':
				return call;

			case 'MEETING':
				return meetings;

			case 'EMAIL':
				return email;

			case 'DEMO':
				return demoIcon;

			default:
				return;
		}
	};

	return (
		<Box sx={{ width: '100%', p: 0, bgcolor: 'white' }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box
					sx={{
						paddingLeft: 2,
					}}>
					<Box
						sx={{
							height: '25px',
							width: '25px',
							borderRadius: '50%',
							border: '1px solid #F0F0F0',
							textAlign: 'center',
							backgroundColor: '#FFF',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							overflow: 'hidden',
						}}>
						<div className={styles.activitySideIcon}>
							{(data.activityType === 'TASK_ADDED' ||
								data.activityType === 'TASK_ADDED_POST_CALL_FEEDBACK' ||
								data.activityType === 'TASK_EDITED' ||
								data.activityType === 'TASK_DELETED') && (
								<img
									src={
										data &&
										data?.activityData &&
										Object?.keys(data?.activityData).length === 0
											? sideIcon(data?.taskData?.taskType)
											: sideIcon(data?.activityData?.taskType)
									}
									alt='task'
								/>
							)}
							{(data.activityType === 'TASK_COMPLETED' ||
								data.activityType === 'TASK_STATE_CHANGED') && (
								<img
									src={
										data &&
										data?.activityData &&
										Object?.keys(data?.activityData).length === 0
											? sideIcon(data?.taskData?.taskType)
											: sideIcon(data?.activityData?.taskType)
									}
									alt='task'
								/>
							)}
							{data.activityType === 'STATUS_CHANGE' && (
								<img src={statusChange} alt='?' />
							)}
							{(data.activityType === 'STAGE_CHANGED' ||
								data.activityType === 'PLAN_CHANGED') && (
								<img src={statusChange} alt='?' />
							)}
							{(data.activityType === 'DEMO' ||
								data.activityType === 'DEMO_BOOKED' ||
								data.activityType === 'DEMO_RESCHEDULED' ||
								data.activityType === 'DEMO_CANCELLED') && (
								<img src={demoIcon} alt='?' />
							)}
							{data.activityType === 'CREATED' && (
								<img src={created} alt='created' />
							)}
							{data.activityType === 'LEAD_ADDED' && (
								<img src={created} alt='created' />
							)}
							{data.activityType === 'NOTE' && <img src={noteIcon} alt='?' />}
							{data.activityType === 'NOTE_ADDED' && (
								<img src={noteIcon} alt='?' />
							)}
							{data.activityType === 'NOTE_EDITED' && (
								<img src={noteIcon} alt='?' />
							)}
							{data.activityType === 'NOTE_DELETED' && (
								<img src={noteIcon} alt='?' />
							)}
							{data.activityType === 'NOTE_ADDED_POST_CALL_FEEDBACK' && (
								<img src={noteIcon} alt='?' />
							)}
							{(data.activityType === 'PAYMENT' ||
								data.activityType === 'PAYMENT_RECORDED' ||
								data.activityType === 'REFUND_RECORDED' ||
								data.activityType === 'PAYMENT_CANCELLED' ||
								data.activityType === 'PAYMENT_REQUESTED' ||
								data.activityType === 'PAYMENT_EXPIRED' ||
								data.activityType === 'PAYMENT_FAILED' ||
								data.activityType === 'ADDITIONAL_PAYMENT_REQUESTED' ||
								data.activityType === 'ADDITIONAL_PAYMENT_RECORDED') && (
								<span
									style={{ width: '24px', height: '24px', color: '#667085' }}>
									{selectedCurrency}
								</span>
							)}
							{data.activityType === 'BRANCH_EDITED' && (
								<img src={leadEdit} alt='leadEdit' />
							)}
							{data.activityType === 'BATCH_ADDED' && (
								<img src={leadEdit} alt='leadEdit' />
							)}
							{data.activityType === 'BATCH_EDITED' && (
								<img src={leadEdit} alt='leadEdit' />
							)}
							{(data.activityType === 'BASIC_DETAILS_EDITED' ||
								data.activityType === 'INSTALLMENT_EDITED' ||
								data.activityType === 'AMOUNT_EDITED' ||
								data.activityType === 'INSTALLMENT_DISABLED') && (
								<img src={leadEdit} alt='leadEdit' />
							)}
							{data.activityType === 'STATUS_CHANGE_TO_LOST' && (
								<img src={leadStatus} alt='?' />
							)}
							{data.activityType === 'STATUS_CHANGE_TO_ACTIVE' && (
								<img src={leadStatus} alt='?' />
							)}
							{(data.activityType === 'NEGOTIATION_EDITED' ||
								data.activityType === 'NEGOTIATION_SAVED') && (
								<img src={negotiation} alt='negotiation' />
							)}
							{(data.activityType === 'OZONE_INITIATED_CALL' ||
								data.activityType === 'OZONE_MISSED_CALL' ||
								data.activityType === 'OZONETEL_RECORDED' ||
								data.activityType === 'OZONETEL_MISSED_CALL' ||
								data.activityType === 'OZONETEL_INCOMING') && (
								<img src={activityCall} alt='activityCall' />
							)}
							{(data.activityType === 'COMMENT_ADDED_TO_TASK' ||
								data.activityType === 'COMMENT_EDITED_ON_TASK' ||
								data.activityType === 'COMMENT_DELETED_FROM_TASK') && (
								<img src={commentIcon} alt='commentIcon' />
							)}
							{data.activityType === 'MEMBERSHIP_PAUSED' && (
								<img src={pauseIcon} alt='pauseIcon' />
							)}

							{(data.activityType === 'SESSION_ADDED' ||
								data.activityType === 'SESSION_COMPLETED' ||
								data.activityType === 'SESSION_CANCELLED') && (
								<img src={demoIcon} alt='?' />
							)}
						</div>
					</Box>
					{activeTab === 'activityList' && listData.length - 1 !== index && (
						<Box
							sx={{
								height: '100%',
								width: '1px',
								margin: '0px auto',
								// border: "1px dashed #C7C7C7",
								border: '1px solid rgba(102, 112, 133, 0.14)',
							}}
						/>
					)}
				</Box>

				{(data.activityType === 'STATUS_CHANGE' ||
					data.activityType === 'STAGE_CHANGED') && (
					<ActivityStage data={data} employees={employees} />
				)}

				{(data.activityType === 'STATUS_CHANGE_TO_LOST' ||
					data.activityType === 'STATUS_CHANGE_TO_ACTIVE') && (
					<ActivityStatus data={data} employees={employees} />
				)}

				{(data.activityType === 'CREATED' ||
					data.activityType === 'LEAD_ADDED') && (
					<ActivityLeadEnquired data={data} employees={employees} />
				)}

				{(data.activityType === 'TASK_ADDED' ||
					data.activityType === 'TASK_EDITED' ||
					data.activityType === 'TASK_DELETED' ||
					data.activityType === 'TASK_ADDED_POST_CALL_FEEDBACK' ||
					data.activityType === 'TASK') && (
					<ActivityTask data={data} activityList={true} employees={employees} />
				)}

				{(data.activityType === 'DEMO' ||
					data.activityType === 'DEMO_BOOKED' ||
					data.activityType === 'DEMO_RESCHEDULED' ||
					data.activityType === 'DEMO_CANCELLED') && (
					<ActivityDemo data={data} employees={employees} />
				)}

				{(data.activityType === 'NOTE' ||
					data.activityType === 'NOTE_ADDED' ||
					data.activityType === 'NOTE_ADDED_POST_CALL_FEEDBACK' ||
					data.activityType === 'NOTE_EDITED' ||
					data.activityType === 'NOTE_DELETED') && (
					<ActivityNote
						data={data}
						activityList={true}
						employees={employees}
						isNoteTab={false}
					/>
				)}

				{(data.activityType === 'PAYMENT' ||
					data.activityType === 'PAYMENT_RECORDED' ||
					data.activityType === 'REFUND_RECORDED' ||
					data.activityType === 'PAYMENT_CANCELLED' ||
					data.activityType === 'PAYMENT_REQUESTED' ||
					data.activityType === 'PAYMENT_EXPIRED' ||
					data.activityType === 'PAYMENT_FAILED' ||
					data.activityType === 'ADDITIONAL_PAYMENT_REQUESTED' ||
					data.activityType === 'ADDITIONAL_PAYMENT_RECORDED') && (
					<ActivityPayment
						data={data}
						employees={employees}
						selectedCurrency={selectedCurrency}
					/>
				)}

				{(data.activityType === 'NEGOTIATION_SAVED' ||
					data.activityType === 'NEGOTIATION_EDITED') && (
					<ActivityNegotiation
						data={data}
						employees={employees}
						selectedCurrency={selectedCurrency}
					/>
				)}

				{(data.activityType === 'BRANCH_EDITED' ||
					data.activityType === 'BATCH_ADDED' ||
					data.activityType === 'BATCH_EDITED' ||
					data.activityType === 'BASIC_DETAILS_EDITED') && (
					<ActivityLead data={data} employees={employees} />
				)}

				{(data.activityType === 'TASK_STATE_CHANGED' ||
					data.activityType === 'TASK_COMPLETED') && (
					<ActivityTaskStatusChange data={data} employees={employees} />
				)}
				{(data.activityType === 'OZONE_INITIATED_CALL' ||
					data.activityType === 'OZONE_MISSED_CALL' ||
					data.activityType === 'OZONETEL_RECORDED' ||
					data.activityType === 'OZONETEL_MISSED_CALL' ||
					data.activityType === 'OZONETEL_INCOMING') && (
					<ActivityCall data={data} employees={employees} />
				)}
				{(data.activityType === 'COMMENT_ADDED_TO_TASK' ||
					data.activityType === 'COMMENT_EDITED_ON_TASK' ||
					data.activityType === 'COMMENT_DELETED_FROM_TASK') && (
					<ActivityComment data={data} employees={employees} />
				)}
				{data.activityType === 'AMOUNT_EDITED' && (
					<AmountEdited data={data} employees={employees} />
				)}
				{data.activityType === 'MEMBERSHIP_PAUSED' && (
					<PauseMembership data={data} employees={employees} />
				)}
				{(data.activityType === 'INSTALLMENT_EDITED' ||
					data.activityType === 'INSTALLMENT_DISABLED') && (
					<InstallmentEdited data={data} employees={employees} />
				)}
				{data.activityType === 'PLAN_CHANGED' && (
					<PlanChanged data={data} employees={employees} />
				)}

				{(data.activityType === 'SESSION_ADDED' ||
					data.activityType === 'SESSION_COMPLETED' ||
					data.activityType === 'SESSION_CANCELLED') && (
					<ActivitySession data={data} employees={employees} />
				)}
			</Box>
		</Box>
	);
}

export default ActivityList;
