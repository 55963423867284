import React, { useState } from "react";
import { Button, CircularProgress, Dialog } from "@mui/material";
import styles from "./DeletePopup.module.css";
import { useSelector } from "react-redux";

function DeletePopup(props: any) {
  const { type, handlePopupYesButton, handlePopupNoButton, loading } = props;
  const [isOpen, setIsOpen] = useState(true);
  const { deleteNoteSuccess, deleteTaskSuccess, deleteCommentSuccess } =
    useSelector((state: any) => state.nurture);

  const renderPopupHeading = () => {
    if (type === "note") {
      return "Are you sure you want to Delete this Note?";
    } else if (type === "task") {
      return "Are you sure you want to Delete this Task?";
    } else if (type === "inbox") {
      return "Are you sure you want to discard this message?";
    } else if (type === "comment") {
      return "Are you sure you want to Delete this Comment?";
    }
  };

  React.useEffect(() => {
    if (type === "note") {
      if (deleteNoteSuccess !== true && deleteNoteSuccess !== false) {
        setIsOpen(false);
      }
    } else if (type === "task") {
      if (deleteTaskSuccess !== true && deleteTaskSuccess !== false) {
        setIsOpen(false);
      }
    } else if (type === "comment") {
      if (deleteCommentSuccess !== true && deleteCommentSuccess !== false) {
        setIsOpen(false);
      }
    }
  }, [deleteNoteSuccess, deleteCommentSuccess, deleteTaskSuccess]);

  return (
    <Dialog
      open={isOpen}
      sx={{
        zIndex: "9999999",
        minWidth: "414px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "400px",
          maxHeight: "250px",
          minHeight: "155px",
          padding: "34px",
          borderRadius: "8px",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
          left: "10%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div className={styles.deactivationPopup}>
        <div className={styles.popupHead}>{renderPopupHeading()}</div>
        <div className={styles.popupButtonDiv}>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#ffffff",
              color: "#344054",
              border: "1px solid #D0D5DD",
            }}
            onClick={() => {
              handlePopupNoButton();
              setIsOpen(false);
            }}
            fullWidth
          >
            No
          </Button>
          <Button
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#EC3939",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#EC3939",
              },
            }}
            onClick={() => {
              handlePopupYesButton();
              // setIsOpen(false);
            }}
            fullWidth
            disabled={loading}
          >
            {/* Yes */}
            {loading ? <CircularProgress size={18} color={"inherit"} /> : "Yes"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default DeletePopup;
