import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useState, useRef } from "react";

const mapContainerStyle = {
  width: "100%",
  height: "230px",
  marginTop: "10px",
  borderRadius: "10px",
};

const center = {
  lat: 12.9716,
  lng: 77.5946,
};
const libraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];
const MapAutocomplete = (props: any) => {
  const { handleGeoChange, latitude, longitude, add, disabled, placeholder } =
    props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB5EFMhRixVvHHxwtBH6Gxv-UWkpM1Md7U",
    libraries,
  });

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectError, setSelectError] = useState<boolean>(false);
  const [marker, setMarker] = useState<any>(null);

  React.useEffect(() => {
    if (!add && latitude !== null && longitude !== null) {
      setMarker({
        position: {
          lat: parseFloat(latitude) || center.lat,
          lng: parseFloat(longitude) || center.lng,
        },
      });
    }
  }, [add, latitude, longitude]);

  const geoLoc = {
    lat: (latitude && parseFloat(latitude)) || center.lat,
    lng: (longitude && parseFloat(longitude)) || center.lng,
  };

  const onLoad = (autocomplete: any) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const geo = autocomplete.getPlace();

      geoLoc.lat = geo.geometry.location.lat();
      geoLoc.lng = geo.geometry.location.lng();

      handleGeoChange(autocomplete.getPlace());
      setMarker({
        position: {
          lat: geo.geometry.location.lat(),
          lng: geo.geometry.location.lng(),
        },
      });
    } else {
      handleGeoChange(null);
      setSelectError(true);
    }
  };

  return isLoaded ? (
    <>
      <GoogleMap
        id="searchbox-example"
        mapContainerStyle={mapContainerStyle}
        zoom={20}
        center={geoLoc && geoLoc.lat !== null ? geoLoc : center}
      >
        {!disabled && (
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            className="dd"
          >
            <input
              ref={inputRef}
              type="text"
              placeholder={placeholder}
              style={{
                boxSizing: `border-box`,
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `16px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                color: "rgba(0, 0, 0, 0.87)",
                border: "1px solid #F2F2F2",
                width: `30%`,
                top: "10px",
                left: "10px",
              }}
            />
          </Autocomplete>
        )}

        {marker && <Marker position={marker.position} />}
      </GoogleMap>
      {selectError && (
        <div style={{ color: "red" }}>
          Location not selected. Please try again.
        </div>
      )}
    </>
  ) : (
    // </LoadScript>
    <></>
  );
};

export default MapAutocomplete;
