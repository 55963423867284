import React, { useState } from "react";
import styles from "../../components/Manegecourses.module.css";
import Beginner from "../../../../../../assets/images/begin.svg";
import Intermediate from "../../../../../../assets/images/inter.svg";
import Expert from "../../../../../../assets/images/expert.svg";
import Classroom from "../../../../../../assets/images/classroomIcon.svg";
import Category from "../../../../../../assets/images/categoryIcon.svg";
import Certificate from "../../../../../../assets/images/certificateIcon.svg";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";
import CopyBtn from "../../../../../../components/Buttons/CopyBtn";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import CourseLevelManager from "../../components/Manager/CourseLevelManager";
import CategoryManager from "../../components/Manager/CategoryManager";
import CourseTypeManager from "../../components/Manager/CourseTypeManager";
import SwitchInput from "../../../../../../components/Switch/SwitchInput";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { createCategory } from "../../service/categorySlice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { updateCourse } from "../../service/courseSlice";
import { useSelector } from "react-redux";
import { ErrorType } from "../../../../../../components/FormTextInput/types";
import { Box } from "@mui/material";
import DeactivationModal from "../../../../../../components/DeactivationModal/DeactivationModal";

const CourseBasicDetails = ({
  isInfoEditOpen,
  courseById,
  setIsInfoEditOpen,
  courseDetails,
  courseLevel,
  setCourseDetails,
  courseCategories,
  courseType,
  hasCourseAccess,
}: any) => {
  const auth = useAuthUser();
  const authUser = auth();
  const activeUserForCategory = authUser;
  const dispatch = useDispatch();

  const { loading_updateCourse } = useSelector(
    (state: any) => state.courses && state.courses
  );
  const activeInstituteForCategory = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );
  const activeInstituteData = authUser?.institute[0]?.instituteDetails;

  const [validStateCourseName, setValidStateCourseName] =
    React.useState<boolean>(true);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorMsgLevel, setErrorMsgLevel] = useState<string>("");
  const [validStateCategory, setValidStateCategory] =
    React.useState<boolean>(true);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);
  const [courseTypeError, setCourseTypeError] = useState(false);

  function generateCourseDetails() {
    if (!courseById) {
      return "";
    }

    const formattedCourseDetails = `
𝗖𝗼𝘂𝗿𝘀𝗲 𝗡𝗮𝗺𝗲: ${courseById?.courseName}
𝗖𝗼𝘂𝗿𝘀𝗲 𝗗𝗲𝘀𝗰𝗿𝗶𝗽𝘁𝗶𝗼𝗻:
${plainTextDescription}
𝗖𝗼𝘂𝗿𝘀𝗲 𝗦𝘆𝗹𝗹𝗮𝗯𝘂𝘀:
${generateSyllabusText(courseById?.courseSyllabus)}
  `;

    return formattedCourseDetails;
  }

  const plainTextDescription = courseById
    ? courseById.courseDescription.replace(/<[^>]+>/g, "")
    : "";

  function generateSyllabusText(courseSyllabus: any) {
    const syllabusText = courseSyllabus
      .map((item: any) => {
        const cleanedChapterDetails = item.chapterDetails.replace(
          /<[^>]+>/g,
          ""
        );
        const chapterText = `${item.chapterName}\n${cleanedChapterDetails}\n`;
        return chapterText;
      })
      .join("\n");
    return syllabusText;
  }

  const setValidateStatusCourseName = React.useCallback((val: boolean) => {
    setValidStateCourseName(val);
  }, []);

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      setErrorType("client");
      setCourseDetails({
        ...courseDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [courseDetails]
  );

  const handleTypeChange = React.useCallback(
    (courseType: any) => {
      const output = courseType?.map((item: any) => ({
        courseType: item.courseType,
      }));
      setErrorMsg("");
      setCourseTypeError(false);
      setCourseDetails({
        ...courseDetails,
        courseType: output,
      });
    },
    [courseDetails, setCourseDetails]
  );

  //Course Level
  const handleLevelChange = React.useCallback(
    (courseLevelName: any, courseId: string) => {
      setErrorMsgLevel("");
      setCourseDetails({
        ...courseDetails,
        courseLevel: {
          ...courseDetails.courseLevel,
          id: courseId,
          courseLevelName: courseLevelName,
        },
      });
    },
    [courseDetails, setCourseDetails]
  );

  const handleCertificateChange = React.useCallback(
    (event: any) => {
      setCourseDetails({
        ...courseDetails,
        [event.target.id]: event.target.checked,
      });
    },
    [courseDetails]
  );

  const handleCreateCategory = React.useCallback(
    (courseCategory: string) => {
      dispatch<any>(
        createCategory({
          courseCategoryName: courseCategory,
          parentCategoryId: "",
          instituteId:
            activeInstituteForCategory.id && activeInstituteForCategory.id,
          instituteName:
            activeInstituteForCategory.instituteName &&
            activeInstituteForCategory.instituteName,
          approverId: "",
          approverName: "",
          addedById:
            activeUserForCategory?.userId && activeUserForCategory?.userId,
          addedByName:
            activeUserForCategory?.firstName &&
            activeUserForCategory?.firstName +
              " " +
              activeUserForCategory?.lastName,
        })
      );
    },
    [createCategory, activeInstituteForCategory, activeUserForCategory]
  );

  const handleSetCategory = React.useCallback(
    (courseCategory: any) => {
      setValidStateCategory(true);
      if (typeof courseCategory === "string") {
        setCourseDetails({
          ...courseDetails,
          courseCategory: {
            ...courseDetails.courseCategory,
            courseCategoryName: courseCategory,
          },
        });
      } else {
        setCourseDetails({
          ...courseDetails,
          courseCategory: {
            ...courseDetails.courseCategory,
            courseCategoryId: courseCategory?.id,
            courseCategoryName: courseCategory?.courseCategoryName,
          },
        });
      }
    },
    [courseDetails]
  );

  const handleRemoveCategory = React.useCallback(
    (courseCategory: any) => {
      setCourseDetails({
        ...courseDetails,
        courseCategory: {
          courseCategoryName: "",
          courseCategoryId: "",
        },
      });
    },
    [courseDetails]
  );

  const handleCourseInfoChangesSubmit = () => {
    if (courseDetails.courseType?.length === 0) {
      setCourseTypeError(true);
      return;
    } else {
      setCourseTypeError(false);
    }

    if (courseDetails?.courseCategory?.courseCategoryId === "") {
      setValidStateCategory(false);
      return;
    } else {
      setValidStateCategory(true);
    }

    if (
      courseDetails?.courseName !== "" &&
      courseDetails?.courseCategory !== null
    ) {
      dispatch<any>(
        updateCourse({
          ...courseDetails,
          id: courseById?.id,
        })
      );
    }
  };

  const handleStatusChange = (event: any) => {
    if (event.target.checked === false) {
      setShowDeactivationModal(true);
    } else if (event.target.checked === true) {
      setButtonStatus(event.target.checked);
      setCourseDetails({
        ...courseDetails,
        status: "ACTIVE",
      });
    }
  };

  const handlePopupYesButton = (event: any) => {
    setButtonStatus(false);
    setCourseDetails({
      ...courseDetails,
      status: "INACTIVE",
    });
  };

  const handlePopupNoButton = (event: any) => {
    setShowDeactivationModal(false);
  };

  React.useEffect(() => {
    if (courseById) {
      setButtonStatus(courseById?.status === "ACTIVE" ? true : false);
    }
  }, [courseById, isInfoEditOpen]);

  return (
    <>
      {isInfoEditOpen ? (
        <>
          <div className={styles.courseEditDetailsSection}>
            <div>
              <NewFormTextInput
                value={courseDetails.courseName}
                validate={true}
                setValidStatus={setValidateStatusCourseName}
                error={!validStateCourseName}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                id="courseName"
                label="Course Name"
                name="Course"
                variant="standard"
                fullWidth
                onChange={handleChange}
                inputProps={{
                  maxLength: 100,
                }}
                require={true}
              />

              <div className={styles.courseLevelSection}>
                {courseLevel && (
                  <CourseLevelManager
                    handleLevelChange={handleLevelChange}
                    courseLevel={courseLevel}
                    errorMsg={errorMsgLevel}
                    selectedLevel={courseDetails.courseLevel}
                    isFromCourseSection={true}
                  />
                )}
              </div>

              <div className={styles.categorySection}>
                <div className={styles.category}>
                  <CategoryManager
                    createCategory={handleCreateCategory}
                    categoriesList={courseCategories}
                    handleSetCategory={handleSetCategory}
                    courseCategory={
                      courseDetails.courseCategory.courseCategoryName
                    }
                    handleRemoveCategory={handleRemoveCategory}
                    validStateCategory={validStateCategory}
                    courseDetail={courseDetails.courseCategory}
                  />
                </div>
                <div>
                  {courseType && (
                    <CourseTypeManager
                      handleTypeChange={handleTypeChange}
                      errorMsg={errorMsg}
                      courseType={courseType?.filter(
                        (item: any) =>
                          item.courseTypeName?.toLowerCase() === "classroom"
                      )}
                      selectedType={courseDetails.courseType}
                      isFromCourseSection={true}
                      setCourseDetails={setCourseDetails}
                      courseDetails={courseDetails}
                    />
                  )}
                  {courseTypeError && courseDetails?.courseType ? (
                    <div style={{ color: "red", fontSize: "10px" }}>
                      Please selecte Course Type
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.courseSwitchWrap}>
              <div className={styles.courseCertificateContainer}>
                <div className={styles.courseBasicInfoContainer}>
                  <div className={styles.courseInfoIcon}>
                    <img src={Certificate} alt={"Certificate"} />
                  </div>
                  <div>
                    <div className={styles.typeBody}>Certificate</div>
                    <div className={styles.certificateBody}>
                      Toggle on if this course provides certificates.
                    </div>
                  </div>
                </div>
                <SwitchInput
                  switchStatus={courseDetails?.isCertificateProvided}
                  handleSwitch={handleCertificateChange}
                  id={"isCertificateProvided"}
                />
              </div>
            </div>
            {activeInstituteData?.isProfileCompleted &&
              courseDetails.status !== "DRAFT" && (
                <Box>
                  <SwitchInput
                    switchStatus={buttonStatus}
                    handleSwitch={handleStatusChange}
                    id={"status"}
                    label={"Active"}
                    labelPlacement={"start"}
                  />

                  {showDeactivationModal && (
                    <DeactivationModal
                      type={"course"}
                      handlePopupYesButton={handlePopupYesButton}
                      handlePopupNoButton={handlePopupNoButton}
                    />
                  )}
                </Box>
              )}
            <div className={styles.buttonSection}>
              <CancelBtn onClick={() => setIsInfoEditOpen(false)} />

              <div style={{ marginLeft: "5px" }}>
                <SaveBtn
                  onClick={handleCourseInfoChangesSubmit}
                  loader={loading_updateCourse}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.courseDetailsSection}>
          <div className={styles.courseHeader}>
            <div className={styles.courseHeaderLeft}>
              <div className={styles.courseNameWrap}>
                <div className={styles.courseName}>
                  {courseById?.courseName}
                </div>
                <div>
                  <CopyBtn copyText={generateCourseDetails()} />
                </div>
              </div>

              <div className={styles.courseLevel}>
                <img
                  src={
                    courseById?.courseLevel?.courseLevelName === "Beginner"
                      ? Beginner
                      : courseById?.courseLevel?.courseLevelName ===
                        "Intermediate"
                      ? Intermediate
                      : Expert
                  }
                  alt={"level"}
                  className={styles.levelIcon}
                />
                {courseById?.courseLevel?.courseLevelName}
              </div>
            </div>
            <div className={styles.courseHeaderRight}>
              {hasCourseAccess && (
                <EditIconBtn
                  onClick={() => {
                    setIsInfoEditOpen(true);
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.courseBasicInfo}>
            <div className={styles.courseBasicInfoContainer}>
              <div className={styles.courseInfoIcon}>
                <img src={Classroom} alt={"classroom"} />
              </div>
              <div>
                <div className={styles.typeHeading}>Type</div>
                <div className={styles.typeBody}>Classroom</div>
              </div>
            </div>
            <div className={styles.courseBasicInfoContainer}>
              <div className={styles.courseInfoIcon}>
                <img src={Category} alt={"Category"} />
              </div>
              <div>
                <div className={styles.typeHeading}>Category</div>
                <div
                  className={styles.typeBody}
                  style={{
                    color:
                      courseById?.courseCategory?.status ===
                        "REVIEW_REJECTED" ||
                      courseById?.courseCategory?.status === "UNDER_REVIEW"
                        ? "red"
                        : "#142c44",
                  }}
                >
                  {courseById?.courseCategory?.courseCategoryName
                    ? courseById?.courseCategory?.courseCategoryName
                    : "-"}
                </div>
              </div>
            </div>

            <div className={styles.courseBasicInfoContainer}>
              <div className={styles.courseInfoIcon}>
                <img src={Certificate} alt={"Certificate"} />
              </div>
              <div>
                <div className={styles.typeHeading}>Certificate</div>
                <div className={styles.typeBody}>
                  {courseById?.isCertificateProvided
                    ? "Provided"
                    : "Not provided"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseBasicDetails;
