import React from "react";
import styles from "./LeadSession.module.css";
import DiscountInput from "../../../../../../components/FormTextInput/DiscountInput";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { updateLeadEmiDetails } from "../../../service/nurtureSlice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";

function EditLeadSession(props: any) {
  const {
		leadData,
		isPayEditOpen,
		setIsPayEditOpen,
		leadEmiDetailsLoading,
		selectedCurrency,
	} = props;
	const auth = useAuthUser();
	const authUser = auth();
	const dispatch = useDispatch();
	const [regularPrice, setRegularPrice] = React.useState<string>('');
	const [discountMetric, setDiscountMetric] = React.useState<string>('');
	const [discount, setDiscount] = React.useState<string>('');
	const [finalPrice, setFinalPrice] = React.useState<string>('');

	const handleDiscountMetric = (value: string) => {
		setDiscountMetric(value);
		setDiscount('');
		setFinalPrice(leadData?.paymentDetails?.finalAmount);
	};

	const handleDiscount = (value: string) => {
		if (value !== '') {
			if (discountMetric === 'AMOUNT') {
				if (parseFloat(value) > parseFloat(regularPrice)) {
					setFinalPrice(
						(parseFloat(regularPrice) - parseFloat(regularPrice)).toString()
					);
					setDiscount(regularPrice);
				} else {
					setDiscount(value);
					setFinalPrice(
						(parseFloat(regularPrice) - parseFloat(value)).toString()
					);
				}
			} else if (discountMetric === 'PERCENTAGE') {
				if (parseFloat(value) > 100) {
					setFinalPrice(
						(parseFloat(regularPrice) * (1 - 100 / 100)).toString()
					);
					setDiscount('100');
				} else {
					setDiscount(value);
					setFinalPrice(
						(
							parseFloat(regularPrice) *
							(1 - parseFloat(value) / 100)
						).toString()
					);
				}
			}
		} else {
			setFinalPrice(regularPrice);
			setDiscount('');
		}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		try {
			let discountError = false;
			if (discountMetric !== '' && parseFloat(discount) === 0) {
				discountError = true;
			}

			const body = {
				id: leadData?.id,
				paymentType: 'SESSION',
				totalAmount: parseFloat(regularPrice),
				discountMetric:
					discountMetric !== '' && parseFloat(discount) > 0
						? discountMetric
						: '',
				discount:
					discountMetric !== '' && parseFloat(discount) > 0
						? parseFloat(discount)
						: 0,
				finalAmount: parseFloat(finalPrice),
				discountAdded: false,

				updatedById: authUser?.institute[0]?.id,
				updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
				startDate: 0,
				endDate: 0,
			};
			if (!discountError) {
				dispatch<any>(updateLeadEmiDetails(body));
			}
		} catch (error) {}
	};

	React.useEffect(() => {
		if (leadData) {
			setRegularPrice(leadData?.paymentDetails?.totalAmount);
			setFinalPrice(leadData?.paymentDetails?.finalAmount);
			if (
				leadData?.paymentDetails?.discountMetric !== '' &&
				leadData?.paymentDetails?.discount > 0
			) {
				setDiscount(leadData?.paymentDetails?.discount);
				setDiscountMetric(leadData?.paymentDetails?.discountMetric);
			}
		}
	}, [leadData, isPayEditOpen]);
	return (
		<div>
			<div className={styles.paymentDetailsHead}>
				<div className={styles.paymentDetailsHeadLeft}>
					<div className={styles.paymentDetailsHeadLeftItem}>
						<div className={styles.paymentDetailsHeadItemLabel}>Price</div>
						<div
							className={styles.paymentDetailsHeadItemValue}
							style={{ fontFamily: 'Roboto' }}>
							{/*     {parseFloat(regularPrice)?.toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })} */}
							{selectedCurrency + ' ' + parseFloat(regularPrice)}
						</div>
					</div>

					<div className={styles.paymentDetailsHeadLeftItem}>
						<div className={styles.paymentDetailsHeadItemLabel}>Session</div>
						<div className={styles.paymentDetailsHeadItemValue}>1</div>
					</div>
					<div className={styles.paymentDetailsHeadLeftItem}>
						<div className={styles.paymentDetailsHeadItemLabel}>Discount</div>
						<div
							className={styles.paymentDetailsHeadItemValue}
							style={{ fontFamily: 'Roboto' }}>
							<DiscountInput
								discountMetric={discountMetric}
								discount={discount}
								handleDiscountMetric={handleDiscountMetric}
								handleDiscount={handleDiscount}
								totalPrice={regularPrice}
								selectedCurrency={selectedCurrency}
							/>
						</div>
					</div>
				</div>
				<div className={styles.paymentDetailsHeadRight}>
					<div className={styles.paymentDetailsHeadFinalPriceWrap}>
						<div className={styles.paymentDetailsHeadItemLabel}>
							Final Price
						</div>
						<div
							className={styles.paymentDetailsHeadFinalPrice}
							style={{ fontFamily: 'Roboto' }}>
							{/* 	{parseFloat(finalPrice)?.toLocaleString('en-IN', {
								style: 'currency',
								currency: 'INR',
							})} */}
							{selectedCurrency + ' ' + parseFloat(finalPrice)}
						</div>
					</div>
				</div>
			</div>

			<div className={styles.editSectionBtnWrap}>
				<CancelBtn onClick={() => setIsPayEditOpen(false)} />
				<SaveBtn
					onClick={handleSubmit}
					disabled={
						leadEmiDetailsLoading ||
						regularPrice === '' ||
						parseFloat(regularPrice) <= 0 ||
						parseFloat(finalPrice) <= 0
					}
					loader={leadEmiDetailsLoading}
				/>
			</div>
		</div>
	);
}

export default EditLeadSession;
