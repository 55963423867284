import { Box, CircularProgress, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../../components/CommonPopups/CommonPopups.module.css";
import React from "react";
import SaveBtn from "../../../../components/Buttons/SaveBtn";
import viewLead from "../../../../assets/images/viewLead.svg";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function ViewLeadCardPopUp(props: any) {
  const { open, onClose, noBtnClick, yesBtnClick, title, description, loader } =
    props;
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div onClick={onClose} className={styles.closeModalButton}>
              <CloseIcon />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={viewLead} alt="" />
            </div>
            <div
              className={styles.modalTitleForViewLead}
              style={{ textAlign: "center" }}
            >
              {title ? title : "Are you sure you want to Cancel?"}
            </div>

            <div className={styles.buttonSection}>
              <SaveBtn onClick={yesBtnClick} buttonText={"View Lead Card"} />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ViewLeadCardPopUp;
