import { RootState, TypedDispatch } from "../../../../../redux/store";
import { connect } from "react-redux";

import {
  bulkLeadCreation,
  createWebform,
  deleteWebformResponse,
  getAllWebForm,
  getStudentContactById,
  getWebformByInstitute,
  getWebformResponse,
  updateWebform,
} from "../service/webformSlice";
import { WebformInitialStateDTO, WebformDTO } from "../service/types";
import Webform from "../components/Webform";
import { CreateSourceDTO, NurtureLead } from "../../../Nurture/service/types";
import {
  createSource,
  getLeadListByInstituteAndContact,
  getNurtureLeadBoard,
  nurtureLead,
} from "../../../Nurture/service/nurtureSlice";
import { getBranchList } from "../../../Manage/ManageBranch/service/Branchslice";
import { getBatchList } from "../../../Manage/ManageBatch/service/Batchslice";
import { getAllCourseByInstituteRequest } from "../../../Manage/ManageCourse/service/courseSlice";

interface StateProps extends WebformInitialStateDTO {}

interface DispatchProps {
  CreateWebform(data: WebformDTO): void;
  UpdateWebform(data: WebformDTO): void;
  GetAllWebform(data: string): void;
  // GetAllWebform(
  //   id: string,
  //   lastEvaluatedKey: string,
  //   limit: number,
  //   lastEvaluatedSortKey: string,
  //   roleInfo: any,
  //   formType: any
  // ): void;
  GetWebformForInstitute(instituteId: string, webformId: string): void;
  // GetFormResponse(
  //   id: string,
  //   lastEvaluatedKey: string,
  //   limit: number,
  //   lastEvaluatedSortKey: string,
  //   formId: string,
  //   roleInfo: any
  // ): void;
  GetFormResponse(data: string): void;
  CreateEnquiry(data: NurtureLead): void;
  GetNurtureLeadBoard(id: string): void;
  GetBranchList(id: string): void;
  GetCourseList(id: string): void;
  GetBatchList(data: any): void;
  GetLeadListByInstituteAndContact(data: any): void;
  CreateSource(data: CreateSourceDTO): void;
  GetStudentContactById(id: string): void;
  BulkLeadCreation(data: any): void;
  DeleteWebformResponse(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    CreateWebformSuccess: state.webform.CreateWebformSuccess,
    CreateWebformError: state.webform.CreateWebformError,
    CreateWebformLoading: state.webform.CreateWebformLoading,
    UpdateWebformSuccess: state.webform.UpdateWebformSuccess,
    UpdateWebformError: state.webform.UpdateWebformError,
    UpdateWebformLoading: state.webform.UpdateWebformLoading,
    CreateCustomFieldWebformSuccess:
      state.webform.CreateCustomFieldWebformSuccess,
    CreateCustomFieldWebformError: state.webform.CreateCustomFieldWebformError,
    CreateCustomFieldWebformLoading:
      state.webform.CreateCustomFieldWebformLoading,
    GetAllWebformSuccess: state.webform.GetAllWebformSuccess,
    GetAllWebformError: state.webform.GetAllWebformError,
    GetAllWebformLoading: state.webform.GetAllWebformLoading,
    getAllFormByInstituteData: state.webform.getAllFormByInstituteData,
    CreateWebformId: state.webform.CreateWebformId,
    getFormByInstituteSuccess: state.webform.getFormByInstituteSuccess,
    getFormByInstituteLoading: state.webform.getFormByInstituteLoading,
    getFormByInstituteError: state.webform.getFormByInstituteError,
    getFormByInstituteData: state.webform.getFormByInstituteData,
    getPrimaryFieldsSuccess: state.webform.getPrimaryFieldsSuccess,
    getPrimaryFieldsLoading: state.webform.getPrimaryFieldsLoading,
    getPrimaryFieldsError: state.webform.getPrimaryFieldsError,
    getPrimaryFieldsData: state.webform.getPrimaryFieldsData,
    getFormResponseSuccess: state.webform.getFormResponseSuccess,
    getFormResponseLoading: state.webform.getFormResponseLoading,
    getFormResponseError: state.webform.getFormResponseError,
    getFormResponseData: state.webform.getFormResponseData,
    getStudentContactByIdSuccess: state.webform.getStudentContactByIdSuccess,
    getStudentContactByIdLoading: state.webform.getStudentContactByIdLoading,
    getStudentContactByIdError: state.webform.getStudentContactByIdError,
    getStudentContactByIdData: state.webform.getStudentContactByIdData,
    bulkLeadCreationSuccess: state.webform.bulkLeadCreationSuccess,
    bulkLeadCreationLoading: state.webform.bulkLeadCreationLoading,
    bulkLeadCreationError: state.webform.bulkLeadCreationError,
    deleteWebformResponseSuccess: state.webform.deleteWebformResponseSuccess,
    deleteWebformResponseLoading: state.webform.deleteWebformResponseLoading,
    deleteWebformResponseError: state.webform.deleteWebformResponseError,
    deleteWebformSuccess: state.webform.deleteWebformSuccess,
    deleteWebformLoading: state.webform.deleteWebformLoading,
    deleteWebformError: state.webform.deleteWebformError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateWebform: (data: any) => {
      dispatch(createWebform(data));
    },
    UpdateWebform: (data: any) => {
      dispatch(updateWebform(data));
    },
    GetAllWebform: (data: any) => {
      dispatch(getAllWebForm(data));
    },
    // GetAllWebform: (
    //   id: string,
    //   lastEvaluatedKey: string,
    //   limit: number,
    //   lastEvaluatedSortKey: string,
    //   roleInfo: any,
    //   formType: any
    // ) => {
    //   dispatch(
    //     getAllWebForm({
    //       id,
    //       lastEvaluatedKey,
    //       limit,
    //       lastEvaluatedSortKey,
    //       roleInfo,
    //       formType,
    //     })
    //   );
    // },
    GetWebformForInstitute: (instituteId: string, webformId: string) => {
      dispatch(getWebformByInstitute({ instituteId, webformId }));
    },
    GetFormResponse: (data: string) => {
      dispatch(getWebformResponse(data));
    },
    // GetFormResponse: (
    //   id: string,
    //   lastEvaluatedKey: string,
    //   limit: number,
    //   lastEvaluatedSortKey: string,
    //   formId: string,
    //   roleInfo: any
    // ) => {
    //   dispatch(
    //     getWebformResponse({
    //       id,
    //       lastEvaluatedKey,
    //       limit,
    //       lastEvaluatedSortKey,
    //       formId,
    //       roleInfo,
    //     })
    //   );
    // },
    CreateEnquiry: (data: NurtureLead) => {
      dispatch(nurtureLead(data));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetBatchList: (data: any) => {
      dispatch(getBatchList(data));
    },
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    GetNurtureLeadBoard: (id: string) => {
      dispatch(getNurtureLeadBoard(id));
    },
    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
    CreateSource: (data: CreateSourceDTO) => {
      dispatch(createSource(data));
    },
    GetStudentContactById: (id: string) => {
      dispatch(getStudentContactById(id));
    },
    BulkLeadCreation: (data: any) => {
      dispatch(bulkLeadCreation(data));
    },
    DeleteWebformResponse: (data: any) => {
      dispatch(deleteWebformResponse(data));
    },
  };
};
export interface EmailListProps extends StateProps, DispatchProps {}
export const WebformComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Webform);
