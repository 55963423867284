import React, { useState, useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";
import OtpFormForChangePassword from "../components/OtpFormForChangePassword";
import { requsestOTPDTO } from "../../../../auth/ForgetPassword/service/types";
import { ChangePasswordProps } from "../container/ChangePasswordContainer";
import { useAuthUser } from "react-auth-kit";
import styles from "./ChangePassword.module.css";

function ChangePassword(props: ChangePasswordProps) {
  const [email, setEmail] = useState("");

  const [showOtpForm, setShowOtpForm] = useState<boolean>(false);
  const [showPasswordPage, setShowPasswordPage] = useState<boolean>(false);
  const [showMainPage, setShowMainPage] = useState<boolean>(true);

  const auth = useAuthUser();
  const authUser = auth();

  // React.useEffect(() => {
  //   if (
  //     props?.error_requestOTP ||
  //     props?.error_verifyOTP ||
  //     props?.error_resetPassword
  //   ) {
  //     setSnackState((snackState: SnackStateProp) => ({
  //       ...snackState,
  //       message: String(
  //         props?.error_requestOTP ||
  //           props?.error_verifyOTP ||
  //           props?.error_resetPassword
  //       ),
  //       snackOpen: true,
  //       snackSeverity: "error",
  //     }));
  //   }
  // }, [
  //   props?.error_requestOTP,
  //   props?.error_resetPassword,
  //   props?.error_verifyOTP,
  // ]);

  React.useEffect(() => {
    if (props?.success_requestOTP) {
      // setSnackState((snackState: SnackStateProp) => ({
      //   ...snackState,
      //   message: "OTP sent successfully",
      //   snackOpen: true,
      //   snackSeverity: "success",
      // }));
    }
  }, [props?.success_requestOTP]);

  React.useEffect(() => {
    if (props?.error_requestOTP) {
      setShowPasswordPage(false);
      setShowOtpForm(false);
      setShowMainPage(true);
    }
  }, [props?.error_requestOTP]);

  const RequestOTP = React.useCallback(
    (data: requsestOTPDTO) => {
      props?.RequestOTPforForgetPassword(data);
    },
    [props?.RequestOTPforForgetPassword]
  );

  useEffect(() => {
    if (authUser !== null) {
      setEmail(authUser?.email);
    }
  }, []);

  return (
    <>
      {showMainPage && (
        <Box className={styles.changePasswordWrapper}>
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Looks like you want to change your Password!
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Please click on the continue button to reset your password.
            </Typography>
          </Box>
          <Box style={{ textAlign: "right" }} sx={{ marginTop: "30px" }}>
            <Button
              sx={{
                width: "120px",
                padding: "8px 20px",
                textTransform: "capitalize",
              }}
              variant="contained"
              type="submit"
              disabled={false}
              onClick={() => {
                setShowOtpForm(true);
                setShowMainPage(false);
                RequestOTP({ email: email });
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
      {(showOtpForm || showPasswordPage) && (
        <OtpFormForChangePassword
          setShowOtpForm={setShowOtpForm}
          email={email}
          showOtpForm={showOtpForm}
          setShowPasswordPage={setShowPasswordPage}
          showPasswordPage={showPasswordPage}
          showMainPage={showMainPage}
          setShowMainPage={setShowMainPage}
          {...props}
        />
      )}
    </>
  );
}

export default ChangePassword;
