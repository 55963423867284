import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

const CourseTagSkeleton = () => {
  return (
    <div className={styles.tagsSection}>
      <div className={styles.tagsHeader}>
        <Skeleton animation="wave" variant="text" width="20%" height={50} />
      </div>
      <div style={{ padding: "20px 0px" }}>
        <div
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>
    </div>
  );
};

export default CourseTagSkeleton;
