import { Box, Modal, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../LeadPopup.module.css";
import { useSelector } from "react-redux";
import React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};

function LeadPhoneChangeConfirmPopup(props: any) {
  const { open, onClose, yesButtonClick, leadEdit } = props;
  const { leadUpdateLoading, leadUpdateSuccess } = useSelector(
    (state: any) => state.nurture
  );
  let phoneConfirmationPopup = true;

  React.useEffect(() => {
    if (
      leadUpdateSuccess !== true &&
      leadUpdateSuccess !== false &&
      open === true
    ) {
      const queryParams = new URLSearchParams(window.location.search);

      if (leadEdit?.studentEmail) {
        queryParams.set("leadEmail", leadEdit.studentEmail);
      } else {
        queryParams.delete("leadEmail");
      }
      if (leadEdit?.studentContact) {
        queryParams.set("leadPhone", leadEdit.studentContact);
      } else {
        queryParams.delete("leadPhone");
      }

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  }, [leadUpdateSuccess]);

  return (
    <div>
      <Modal
        open={open}
        // onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div
                onClick={onClose}
                className={styles.closeModalButton}
                style={{ cursor: "pointer" }}
              >
                <CloseIcon sx={{ color: "#667085" }} />
              </div>
            </div>

            <div className={styles.phoneChangePopupTitle}>
              The phone number will be changed for the lead throughout all the
              courses. You wish to continue?
            </div>

            <div className={styles.phoneChangePopupBtnSection}>
              <button onClick={onClose} className={styles.phoneChangeNoButton}>
                No
              </button>
              <button
                onClick={yesButtonClick}
                className={styles.phoneChangeYesButton}
                disabled={phoneConfirmationPopup && leadUpdateLoading}
              >
                {phoneConfirmationPopup && leadUpdateLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Yes"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default LeadPhoneChangeConfirmPopup;
