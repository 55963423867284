import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Link, Skeleton, Tooltip } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { FiEdit } from "react-icons/fi";
import profile from "../../../../assets/images/profileBG.svg";
import facebook from "../../../../assets/images/facebook.svg";
import instagram from "../../../../assets/images/insta.svg";
import linkedin from "../../../../assets/images/linkedIn.svg";
import twitter from "../../../../assets/images/twitter.svg";
import styles from "./components/InstituteProfile.module.css";
import AddDescriptionModal from "./AddDescriptionModal";
import AddSocialMediaModal from "./AddSocialMediaModal";
import ProfileBannerSkeleton from "../../../../components/Skeletons/ProfileBannerSkeleton";
import { resetInstitiuteStatuses } from "./service/instituteSlice";
import { useDispatch } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import edit from "../ManageBatch/BatchListing/edit.svg";

const InstituteProfileDetail = (props: any) => {
  const {
    getInstituteById,
    instituteDetails,
    institute,
    error,
    success,
    instituteDetailsLoading,
    instituteUpdateloading,
    instituteUpdateSuccess,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasInstituteAccess = getSubDomainAccessStatus(
    "MANAGE",
    "INSTITUTE_PROFILE",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const localData: any = localStorage.getItem("ACTIVE_INSTITUTE");
  const localStoreData = JSON.parse(localData);

  const [profileData, setProfileData] = useState<any>();
  const [addDescription, setAddDescription] = useState<boolean>(false);
  const [addSocialMedia, setAddSocialMedia] = useState<boolean>(false);
  const [facebookError, setFacebookError] = useState<boolean>(false);
  const [instagramError, setInstagramError] = useState<boolean>(false);
  const [twitterError, setTwitterError] = useState<boolean>(false);
  const [linkedInError, setLinkedInError] = useState<boolean>(false);

  const [displaySocialMedia, setDisplaySocialMedia] = useState<any>([
    {
      socialMedia: "FACEBOOK",
      url: "",
      isVisible: false,
    },
    {
      socialMedia: "INSTAGRAM",
      url: "",
      isVisible: false,
    },
    {
      socialMedia: "TWITTER",
      url: "",
      isVisible: false,
    },
    {
      socialMedia: "LINKEDIN",
      url: "",
      isVisible: false,
    },
  ]);
  useEffect(() => {
    setProfileData(instituteDetails);

    const array =
      instituteDetails?.socialMediaHandles &&
      instituteDetails?.socialMediaHandles?.length !== 0
        ? instituteDetails?.socialMediaHandles
        : [
            {
              socialMedia: "FACEBOOK",
              url: "",
              isVisible: false,
            },
            {
              socialMedia: "INSTAGRAM",
              url: "",
              isVisible: false,
            },
            {
              socialMedia: "TWITTER",
              url: "",
              isVisible: false,
            },
            {
              socialMedia: "LINKEDIN",
              url: "",
              isVisible: false,
            },
          ];

    setDisplaySocialMedia(array);
    instituteDetails?.socialMediaHandles &&
      instituteDetails?.socialMediaHandles?.length !== 0 &&
      instituteDetails?.socialMediaHandles.map((item: any) => {
        const socialName =
          item.socialMedia.charAt(0) + item.socialMedia.slice(1).toLowerCase();
        if (socialName === "Facebook") {
          setFacebookError(
            /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(item?.url)
          );
        }
        if (socialName === "Instagram") {
          setInstagramError(
            /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(item?.url)
          );
        }
        if (socialName === "Twitter") {
          setTwitterError(/^(https?:\/\/)twitter.com\/.*/i.test(item?.url));
        }
        if (socialName === "Linkedin") {
          setLinkedInError(
            /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i.test(item?.url)
          );
        }
        return item;
      });
  }, [instituteDetails, getInstituteById]);

  React.useEffect(() => {
    getInstituteById(
      localStoreData?.instituteDetails
        ? localStoreData?.instituteDetails?.id
        : localStoreData?.id
    );
  }, []);

  const openModal = () => {
    setAddDescription(true);
  };
  const closeModal = () => {
    setAddDescription(false);
  };
  const openSocialModal = () => {
    setAddSocialMedia(true);
  };
  const closeSocialModal = () => {
    setAddSocialMedia(false);
  };
  const resetDescription = () => {
    const description = {
      ...profileData,
      about: instituteDetails.about,
    };
    setProfileData(description);
  };
  const handleChecked = (e: any) => {
    const updatedArray = displaySocialMedia?.map((item: any) => {
      const socialName =
        item.socialMedia.charAt(0) + item.socialMedia.slice(1).toLowerCase();
      if (e.target.value === socialName) {
        return { ...item, isVisible: e.target.checked };
      }
      return item;
    });

    setDisplaySocialMedia(updatedArray);
  };
  const handleChangeSocial = (e: any) => {
    if (e.target.name === "Facebook") {
      if (e.target.value !== "") {
        setFacebookError(
          /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(e.target.value)
        );
      } else {
        setFacebookError(true);
      }
    }
    if (e.target.name === "Instagram") {
      if (e.target.value !== "") {
        setInstagramError(
          /^(https?:\/\/)?((w{3}\.)?)instagram.com\/.*/i.test(e.target.value)
        );
      } else {
        setInstagramError(true);
      }
    }
    if (e.target.name === "Twitter") {
      if (e.target.value !== "") {
        setTwitterError(/^(https?:\/\/)twitter.com\/.*/i.test(e.target.value));
      } else {
        setTwitterError(true);
      }
    }
    if (e.target.name === "Linkedin") {
      if (e.target.value !== "") {
        setLinkedInError(
          /^(https?:\/\/)?((w{3}\.)?)linkedin.com\/.*/i.test(e.target.value)
        );
      } else {
        setLinkedInError(true);
      }
    }
    const updatedArray = displaySocialMedia?.map((item: any) => {
      const socialName =
        item.socialMedia.charAt(0) + item.socialMedia.slice(1).toLowerCase();
      if (e.target.name === socialName) {
        return { ...item, url: e.target.value };
      }
      return item;
    });

    setDisplaySocialMedia(updatedArray);
  };

  const handleSubmit = () => {
    institute(profileData);
  };
  const handleSubmitSocial = () => {
    const socialData = {
      ...profileData,
      socialMediaHandles: displaySocialMedia,
    };
    institute(socialData);
  };
  const resetSocial = () => {
    const array =
      instituteDetails?.socialMediaHandles.length === 0
        ? [
            {
              socialMedia: "FACEBOOK",
              url: "",
              isVisible: false,
            },
            {
              socialMedia: "INSTAGRAM",
              url: "",
              isVisible: false,
            },
            {
              socialMedia: "TWITTER",
              url: "",
              isVisible: false,
            },
            {
              socialMedia: "LINKEDIN",
              url: "",
              isVisible: false,
            },
          ]
        : instituteDetails?.socialMediaHandles;
    setDisplaySocialMedia(array);
  };

  React.useEffect(() => {
    if (instituteUpdateSuccess !== true && instituteUpdateSuccess !== false) {
      getInstituteById(
        localStoreData?.instituteDetails
          ? localStoreData?.instituteDetails?.id
          : localStoreData?.id
      );
      setTimeout(() => {
        closeModal();
        closeSocialModal();
        dispatch(resetInstitiuteStatuses());
      }, 500);
    }
  }, [instituteUpdateSuccess]);

  const handleAboutChange = React.useCallback(
    (event: string, editorRef: any) => {
      const updatedDescription = event;
      const description = {
        ...profileData,
        about: updatedDescription,
      };
      setProfileData(description);
    },
    [profileData]
  );

  const isVisibleExists = instituteDetails?.socialMediaHandles?.some(
    (obj: any) => obj?.isVisible === true
  );

  return (
    <Box sx={{ overflowY: "auto", maxHeight: "100%" }}>
      <Box
        sx={{
          padding: "22px 22px 0 22px",
        }}
      >
        {instituteDetailsLoading ? (
          <ProfileBannerSkeleton />
        ) : (
          <>
            <Box>
              <Box className={styles.imageRelative}>
                <img src={profile} alt="" width={"100%"} />
                <Box className={styles.imageSection}>
                  <img src={instituteDetails?.logo} alt="" />
                </Box>
              </Box>
            </Box>
            <Box className={styles.topBottom}>
              <Box className={styles.editButton}>
                <Tooltip
                  arrow
                  title={
                    hasInstituteAccess === false ? "Oops! No permission." : ""
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span>
                    <Button
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#667085",
                        textTransform: "capitalize",
                        gap: "10px",
                        padding: "0 !important",
                      }}
                      onClick={() => {
                        navigate(
                          `/app/manage/InstituteProfile/${localStoreData?.id}`
                        );
                      }}
                      disabled={hasInstituteAccess === false}
                    >
                      <p
                        style={{
                          margin: "0px 6px 0px 0px",
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#667085",
                        }}
                      >
                        Edit Profile
                      </p>
                      <img src={edit} alt="edit" />
                      {/* <FiEdit size={14} color="#667085" /> */}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
              <Box className={styles.name}>
                {instituteDetails?.instituteName}
              </Box>
              <Box className={styles.contactSection}>
                {instituteDetails?.website !== "" && (
                  <Box className={styles.website}>
                    <Link
                      href={`https://${instituteDetails?.website}`}
                      target="_blank"
                      rel="noreferrer noopener"
                      sx={{
                        color: "#377DFF",
                        textDecorationColor: "#377DFF",
                      }}
                    >
                      {`${instituteDetails?.website}`}
                    </Link>
                  </Box>
                )}
                <Box className={styles.email}>
                  <MailOutlineIcon
                    style={{
                      marginRight: "8px",
                      height: 20,
                      width: 20,
                    }}
                  />
                  {instituteDetails?.instituteEmail}
                </Box>
                <Box className={styles.contact}>
                  <PhoneInTalkIcon
                    style={{
                      marginRight: "8px",
                      marginLeft: "8px",
                      height: 20,
                      width: 20,
                    }}
                  />
                  {`+${instituteDetails?.contactCountryCode || "91"} ${
                    instituteDetails?.contactCountryCode
                      ? instituteDetails?.instituteContact.replace(
                          instituteDetails?.contactCountryCode,
                          ""
                        )
                      : instituteDetails?.instituteContact.startsWith("91")
                      ? instituteDetails?.instituteContact.replace("91", "")
                      : instituteDetails?.instituteContact
                  }`}
                </Box>
              </Box>
              <Box className={styles.domainAddressSection}>
                <Box>
                  <Box className={styles.title}>Subdomain:</Box>
                  <Link
                    href={`https://${instituteDetails?.appURL}`}
                    className={styles.des}
                    sx={{
                      color: "#142C44",
                      textDecorationColor: "#377DFF",
                    }}
                    underline="none"
                  >
                    {`https://${instituteDetails?.appURL}`}
                  </Link>
                </Box>
                <Box className={styles.rightSection}>
                  <Box className={styles.title}>Address:</Box>
                  <Box
                    className={styles.des}
                  >{`${instituteDetails?.buildingNumber}, ${instituteDetails?.locality}, ${instituteDetails?.city}, ${instituteDetails?.state}, ${instituteDetails?.pin}`}</Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>

      {instituteDetailsLoading ? (
        <Box className={styles.bottomSection}>
          <Box className={styles.aboutSection}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={150}
              style={{ borderRadius: "2px" }}
            />
          </Box>
          <Box className={styles.mediaSection}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={150}
              style={{ borderRadius: "2px" }}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box className={styles.bottomSection}>
            <Box className={styles.aboutSection}>
              <Box className={styles.mainTitle}>
                About
                {profileData?.about && profileData?.about !== "" && (
                  <Tooltip
                    arrow
                    title={
                      hasInstituteAccess === false ? "Oops! No permission." : ""
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <button
                        style={{ border: "none", background: "#fff" }}
                        onClick={() => openModal()}
                        disabled={hasInstituteAccess === false}
                      >
                        <FiEdit size={14} color="#667085" cursor="pointer" />
                      </button>
                    </span>
                  </Tooltip>
                )}
              </Box>

              {instituteDetails?.about && instituteDetails?.about !== "" ? (
                <Box fontSize={"13px"} fontWeight={"400"}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: instituteDetails?.about,
                    }}
                  />
                </Box>
              ) : (
                <Box className={styles.add}>
                  <Tooltip
                    arrow
                    title={
                      hasInstituteAccess === false ? "Oops! No permission." : ""
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <Button
                        sx={{
                          fontWeight: "500",
                          fontSize: "13px",
                          textTransform: "capitalize",
                          color: "#11c89b",
                        }}
                        onClick={() => openModal()}
                        disabled={hasInstituteAccess === false}
                      >
                        Add Description
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              )}
            </Box>

            <Box className={styles.mediaSection}>
              <Box className={styles.mainTitle}>
                Social Media
                {profileData?.socialMediaHandles &&
                  profileData?.socialMediaHandles?.length !== 0 &&
                  isVisibleExists && (
                    <Tooltip
                      arrow
                      title={
                        hasInstituteAccess === false
                          ? "Oops! No permission."
                          : ""
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#101828",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "8px 12px",
                            "& .MuiTooltip-arrow": {
                              color: "#101828",
                            },
                          },
                        },
                      }}
                    >
                      <span>
                        <button
                          style={{ border: "none", background: "#fff" }}
                          onClick={() => openSocialModal()}
                          disabled={hasInstituteAccess === false}
                        >
                          <FiEdit size={14} color="#667085" cursor="pointer" />
                        </button>
                      </span>
                    </Tooltip>
                  )}
              </Box>

              {instituteDetails?.socialMediaHandles &&
              instituteDetails?.socialMediaHandles?.length !== 0 &&
              isVisibleExists ? (
                instituteDetails?.socialMediaHandles?.map(
                  (obj: any, index: number) => {
                    const socialName =
                      obj.socialMedia.charAt(0) +
                      obj.socialMedia.slice(1).toLowerCase();

                    let imgName: any;
                    let displayname = "";
                    const url = obj.url.split("//");
                    const userName =
                      url && url.length > 1 ? url[1].split("/") : "";
                    switch (socialName) {
                      case "Facebook":
                        displayname = userName?.length > 1 ? userName[1] : "";
                        imgName = userName?.length > 1 && facebook;
                        break;
                      case "Linkedin":
                        displayname = userName?.length > 2 ? userName[2] : "";
                        imgName = userName?.length > 2 && linkedin;
                        break;
                      case "Twitter":
                        displayname = userName?.length > 1 ? userName[1] : "";
                        imgName = userName?.length > 1 && twitter;
                        break;
                      case "Instagram":
                        displayname = userName?.length > 1 ? userName[1] : "";
                        imgName = userName?.length > 1 && instagram;
                        break;
                      default:
                        imgName = facebook;
                        break;
                    }
                    return (
                      <Box key={index} display={"flex"} alignItems={"center"}>
                        <>
                          {obj.isVisible && (
                            <Box className={styles.iconSectionDisplay}>
                              <img src={imgName} alt="" />
                            </Box>
                          )}

                          {obj.isVisible ? (
                            <Box className={styles.url}>
                              <Link
                                href={obj.url}
                                target="_blank"
                                rel="noreferrer noopener"
                                sx={{
                                  color: "#142c44",
                                  textDecorationColor: "unset",
                                  textDecoration: "none",
                                }}
                              >
                                {displayname}
                              </Link>
                            </Box>
                          ) : (
                            // <Box className={styles.url}>-</Box>
                            <></>
                          )}
                        </>
                      </Box>
                    );
                  }
                )
              ) : (
                <Box className={styles.add}>
                  <Tooltip
                    arrow
                    title={
                      hasInstituteAccess === false ? "Oops! No permission." : ""
                    }
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <Button
                        sx={{
                          fontWeight: "500",
                          fontSize: "13px",
                          textTransform: "capitalize",
                          color: "#11c89b",
                        }}
                        onClick={() => openSocialModal()}
                        disabled={hasInstituteAccess === false}
                      >
                        Add Social Media
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}

      {addDescription && (
        <AddDescriptionModal
          open={addDescription}
          closeModal={closeModal}
          handleAboutChange={handleAboutChange}
          about={profileData?.about}
          onSubmit={handleSubmit}
          instituteDetails={instituteDetails}
          resetDescription={resetDescription}
        />
      )}

      {addSocialMedia && (
        <AddSocialMediaModal
          open={addSocialMedia}
          closeModal={closeSocialModal}
          handleChecked={handleChecked}
          displaySocialMedia={displaySocialMedia}
          handleSubmitSocial={handleSubmitSocial}
          handleChangeSocial={handleChangeSocial}
          instituteDetails={instituteDetails}
          resetSocial={resetSocial}
          facebookError={facebookError}
          instagramError={instagramError}
          twitterError={twitterError}
          linkedInError={linkedInError}
          instituteUpdateloading={instituteUpdateloading}
        />
      )}
    </Box>
  );
};
export default InstituteProfileDetail;
