import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FileUploadRequest } from "../../pages/app/Main/components/FileUpload/service/fileUpload.request";
import styles from "./FileDrop.module.css";
import { CircularProgress } from "@mui/material";
import { setToastNotification } from "../ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";

function FieldFileDropZone(props: any) {
  const {
    accept = { "image/*": [] },
    maxSize = 2 * 1024 * 1024, // Default 2MB
    maxResolution = { width: 1280, height: 720 },
    field,
    onFieldChange,
    disabled = false,
  } = props;
  const dispatch = useDispatch();

  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const [preview, setPreview] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const handleChange = async (file: File) => {
    if (!file) {
      dispatch(
        setToastNotification({
          message: "No file selected.",
          type: "error",
          snackOpen: true,
        })
      );
      return;
    }

    if (file.size > maxSize) {
      dispatch(
        setToastNotification({
          message: `File size must be less than ${maxSize / (1024 * 1024)} MB.`,
          type: "error",
          snackOpen: true,
        })
      );
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      const { width, height } = img;

      if (width > maxResolution.width || height > maxResolution.height) {
        dispatch(
          setToastNotification({
            message: `File resolution must not exceed ${maxResolution.width}x${maxResolution.height}.`,
            type: "error",
            snackOpen: true,
          })
        );
        return;
      }

      setPreview(img.src);

      // Convert image to base64
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;

        // Create the request body for the API
        const body = {
          fileBase64String: base64String,
          fileName: file.name,
          fileType: "ADDITIONAL_LEAD_DOCUMENT",
          // fileType: "COURSE_IMAGE",
          instituteId: activeInstitute?.id,
        };

        try {
          setLoader(true);
          const response = await FileUploadRequest(body);
          // Assuming the response contains the base64 string
          const fileBase64 = response.data?.data?.location;
          onFieldChange(field.id, fileBase64, true); // Update the field value with the base64 string
          dispatch(
            setToastNotification({
              message: response?.data?.message || "File uploaded successfully",
              type: "success",
              snackOpen: true,
            })
          );
        } catch (error: any) {
          dispatch(
            setToastNotification({
              message: error?.response?.data?.message || "An error occurred",
              type: "error",
              snackOpen: true,
            })
          );
        } finally {
          setLoader(false);
        }
      };

      reader.readAsDataURL(file); // Convert the file to base64
    };
    img.onerror = () => {
      setToastNotification({
        message: "Failed to load image. Please try again.",
        type: "error",
        snackOpen: true,
      });
    };
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      handleChange(file);
    },
  });

  return (
    <div>
      <div
        {...getRootProps({
          style: {
            border: "2px dashed #ccc",
            borderRadius: "8px",
            padding: "16px",
            textAlign: "center",
            cursor: disabled ? "not-allowed" : "pointer",
            backgroundColor: disabled ? "#f9f9f9" : "#fff",
          },
        })}
      >
        <input {...getInputProps()} />
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50px",
              width: "100%",
            }}
          >
            <CircularProgress size={24} />
          </div>
        ) : (
          <>
            <div className={styles.dragDropText}>
              Drag and drop an image, or{" "}
              <span style={{ color: "#1A73E8" }}>Browse</span>
            </div>
            <div className={styles.dragDropRequirement}>
              JPG or PNG (Max size: 2MB)
            </div>
            {preview && (
              <img
                src={preview}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  borderRadius: "8px",
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default FieldFileDropZone;
