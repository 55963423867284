// export const a = test;
import { RootState, TypedDispatch } from "../../../../redux/store";
import { Signup } from "../components/Signup";
import {
  createInstituteDTO,
  createInstituteInitialStateDTO,
  createInstituteVerifyOTPDTO,
  setPasswordDTO,
} from "../service/types";
import { connect } from "react-redux";
import {
  checkSubDomain,
  createInstitute,
  createInstituteVerifyOTP,
  setPassword,
} from "../service/Signupslice";
import { LoginDTO } from "../../signin/service/types";
import { login } from "../../signin/service/signinSlice";
import {GetTnc} from "../../../app/TermsOfServices/service/termsofservicesSlice"

interface StateProps extends createInstituteInitialStateDTO {  
  tnc: any;
}

interface DispatchProps {
  CreateInstitute(data: createInstituteDTO): void;
  VerifyOTPforCreateInstitute(data: createInstituteVerifyOTPDTO): void;
  SetPasswordforInstitute(data: setPasswordDTO): void;
  userLogin(data: LoginDTO): void;
  CheckSubDomain(data: string): void;
  GetTnc(): void;

}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success_createInstitute: state.signUp.success_createInstitute,
    error_createInstitute: state.signUp.error_createInstitute,
    loading_createInstitute: state.signUp.loading_createInstitute,
    success_createInstitute_verifyOTP:
      state.signUp.success_createInstitute_verifyOTP,
    error_createInstitute_verifyOTP:
      state.signUp.error_createInstitute_verifyOTP,
    loading_createInstitute_verifyOTP:
      state.signUp.loading_createInstitute_verifyOTP,
    success_createInstitute_setPassword:
      state.signUp.success_createInstitute_setPassword,
    error_createInstitute_setPassword:
      state.signUp.error_createInstitute_setPassword,
    loading_createInstitute_setPassword:
      state.signUp.loading_createInstitute_setPassword,
    loading_checkSubDomain: state.signUp.loading_checkSubDomain,
    success_checkSubDomain: state.signUp.success_checkSubDomain,
    error_checkSubDomain: state.signUp.error_checkSubDomain,
    tnc: state.tnc.tnc,

  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateInstitute: (data: createInstituteDTO) => {
      dispatch(createInstitute(data));
    },

    VerifyOTPforCreateInstitute: (data: createInstituteVerifyOTPDTO) => {
      dispatch(createInstituteVerifyOTP(data));
    },

    SetPasswordforInstitute: (data: setPasswordDTO) => {
      dispatch(setPassword(data));
    },

    userLogin: (data: LoginDTO) => {
      dispatch(login(data));
    },

    GetTnc: () => {
      dispatch(GetTnc()); // Corrected dispatch call
    },

    CheckSubDomain: (data: string) => {
      dispatch(checkSubDomain(data));
    },
  };
};

export interface SignUpProps extends StateProps, DispatchProps {}
export const SignUpComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
