import React from "react";
import { FormLabel } from "@mui/material";

function FilterLabel(props: any) {
  const { labelValue } = props;
  return (
    <>
      <FormLabel
        style={{
          color: "#142C44",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "poppins",
          marginBottom: "10px",
        }}
      >
        {labelValue}
      </FormLabel>
    </>
  );
}

export default FilterLabel;
