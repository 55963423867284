import {
  Box,
  Avatar,
  IconButton,
  Button,
  TextField,
  Popover,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import styles from "./AgentsListing.module.css";
import ozonetel from "../../Assets/ozonetel.svg";
import help from "../../Assets/help.svg";
import CallbackUrlPopup from "../HelpPopup/CallbackUrlPopup";
import { FiEdit } from "react-icons/fi";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import deleteImg from "../../Assets/deleteImg.svg";
import DeletePopup from "../DeletePopup/DeletePopup";
import UninstallOzontelPopup from "../DeletePopup/UninstallOzontelPopup";
import { useSelector } from "react-redux";
import { ValidateAgentId } from "../../../service/integrations.request";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import removeAgentIcon from "../../Assets/removeAgent.svg";

function AgentsListingComponent(props: any) {
  const {
    employees,
    appsList,
    DeactivateOzonetel,
    // ValidateAgentId,
    instituteDetails,
    UpdateOzonetelAgents,
    ozonetelEmployees,
    AddOzonetelAgents,
    ozonetelConfigEmployees,
  } = props;
  const {
    deactivateOzonetelLoading,
    deactivateOzonetelSuccess,
    agentUpdatedLoading,
    agentAddedSuccess,
    agentAddedLoading,
  } = useSelector((state: any) => state.appIntegrations);
  const nonOzonetelEmployees = employees?.filter(
    (item: any) => item.ozonetelConfig?.agentId === ""
  );

  const ozonetelCallbackUrl = process.env.REACT_APP_OZONETEL_CALLBACK_URL || "";
  const ozonetelApp = appsList?.find((app: any) => app.appCode === "OZONETEL");
  const ozonetelAppApiKey = ozonetelApp?.appConfigData?.apiKey;
  const ozonetelAppUserName = ozonetelApp?.appConfigData?.userName;
  const [emptyAgentField, setEmptyAgentField] = React.useState<boolean>(false);
  const [invalidAgentsError, setInvalidAgentsError] =
    React.useState<boolean>(false);

  const [showCallbackUrlPopup, setShowCallbackUrlPopup] =
    useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showUninstallPopup, setShowUninstallPopup] = useState<boolean>(false);
  const [deleteSelectedAgent, setDeleteSelectedAgent] = useState<any>("");
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const handleUninstallPopupOpen = () => {
    setShowUninstallPopup(true);
  };

  const handleUninstallPopupClose = () => {
    setShowUninstallPopup(false);
  };
  const handleDeleteAgentPopupClose = () => {
    setShowDeletePopup(false);
    setDeleteSelectedAgent("");
  };

  const handleDeleteAgentPopupOpen = () => {
    setShowDeletePopup(false);
    setDeleteSelectedAgent("");
  };

  const handleDeleteClick = (agent: any) => {
    setDeleteSelectedAgent(agent);
    setShowDeletePopup(true);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCallbackUrlHelpClick = () => {
    setShowCallbackUrlPopup(true);
  };

  const toggleUninstallPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const [agentsArray, setAgentsArray] = useState<any>([
    {
      userId: "",
      agentId: "",
      agentIdValid: false,
      agentIdUnique: true,
      userName: "olivia779069",
      agentName: "olivia779069",
    },
  ]);
  const [deletableAgentsArray, setDeletableAgentsArray] = useState<any>([]);

  const combinedArray = [...agentsArray, ...deletableAgentsArray];

  const handleDeleteNewlyAddedAgent = (indexToDelete: any) => {
    const updatedAgentsArray = agentsArray?.filter(
      (agent: any, index: number) => index !== indexToDelete
    );
    setAgentsArray(updatedAgentsArray);
  };

  const handleAddAgent = useCallback(() => {
    setAgentsArray([
      ...agentsArray,
      { userId: "", agentId: "", agentIdValid: false, agentIdUnique: true },
    ]);
  }, [agentsArray]);

  const handleAgentIdChange = useCallback(
    (value: string, index: number) => {
      const isAgentIdUnique = combinedArray?.every(
        (agent: any, i: number) => i === index || agent.agentId !== value
      );
      const updatedAgentsArray = [...agentsArray];
      updatedAgentsArray[index] = {
        ...updatedAgentsArray[index],
        agentId: value,
        agentIdUnique: isAgentIdUnique,
        agentIdValid: false,
      };
      setAgentsArray(updatedAgentsArray);
    },
    [agentsArray]
  );

  // const handleAgentIdChange = useCallback((value: string, userId: string) => {
  //   setAgentsArray((prevAgentsArray: any) => {
  //     return prevAgentsArray.map((agent: any) => {
  //       if (agent.userId === userId) {
  //         // Update the agentId for the matching userId
  //         return {
  //           ...agent,
  //           agentId: value,
  //           // agentIdUnique: // Your logic for uniqueness check,
  //           // agentIdValid: false,
  //         };
  //       }
  //       return agent;
  //     });
  //   });
  // }, []);

  const handleAgentIdBlur = useCallback(
    async (value: string, index: number) => {
      const valueExist = agentsArray?.some(
        (agent: any) => agent.agentId.toString() === value.toString()
      );

      if (value) {
        try {
          const response: any = await ValidateAgentId(
            value,
            instituteDetails?.id,
            ozonetelAppApiKey,
            ozonetelAppUserName
          );

          // Check if the API call was successful
          if (response && response?.data?.message === "Agent Id is valid") {
            const updatedAgentsArray = [...agentsArray];
            updatedAgentsArray[index] = {
              ...updatedAgentsArray[index],
              agentIdValid: true,
            };
            setAgentsArray(updatedAgentsArray);
          } else {
            const updatedAgentsArray = [...agentsArray];
            updatedAgentsArray[index] = {
              ...updatedAgentsArray[index],
              agentIdValid: false,
            };
            setAgentsArray(updatedAgentsArray);
          }
        } catch (error) {
          // If an error occurs during the API call, set agentIdValid to false
          const updatedAgentsArray = [...agentsArray];
          updatedAgentsArray[index] = {
            ...updatedAgentsArray[index],
            agentIdValid: false,
          };
          setAgentsArray(updatedAgentsArray);

          // Handle any errors that may occur during the API call
          console.error("Error during API call:", error);
        }
      }
    },
    [
      instituteDetails?.id,
      ozonetelAppApiKey,
      ozonetelAppUserName,
      ValidateAgentId,
      agentsArray,
    ]
  );

  const handleAgentLeadAssignee = useCallback(
    (user: any, index: number) => {
      if (user) {
        // Update the agentsArray when a user is selected
        const updatedAgentsArray = [...agentsArray];
        updatedAgentsArray[index].userId = user.id;
        setAgentsArray(updatedAgentsArray);
      }
    },
    [agentsArray]
  );

  const handleDeleteSubmit = async (event: any) => {
    event.preventDefault();

    const hasEmptyAgentId = deletableAgentsArray?.some(
      (item: any) => item.agentId.trim() === ""
    );

    const hasEmptyUserId = deletableAgentsArray?.some(
      (item: any) => item.userId.trim() === ""
    );

    const hasInvalidAgentId = deletableAgentsArray?.some(
      (item: any) => item.agentIdValid === false
    );

    const hasAgentIdUnique = deletableAgentsArray?.some(
      (item: any) => item.agentIdUnique === false
    );

    // if (hasEmptyAgentId) {
    //   setEmptyAgentField(true);
    //   return;
    // }

    try {
      const updatedAgentsArray = deletableAgentsArray?.map((item: any) => {
        if (item.userId === deleteSelectedAgent.userId) {
          return {
            userId: item.userId,
            agentId: "",
          };
        } else {
          return {
            userId: item.userId,
            agentId: item.agentId,
          };
        }
      });
      // emptyAgentField === false &&
      //   hasEmptyUserId === false &&
      //   hasInvalidAgentId === false &&
      //   hasAgentIdUnique === false &&

      AddOzonetelAgents(updatedAgentsArray);
    } catch (error) {}
  };

  const filteredAgents = agentsArray?.filter(
    (item: any) => item.agentId.trim() !== "" && item.agentIdValid === true
  );
  const filteredInvalidAgents = agentsArray?.filter(
    (item: any) => item.agentId.trim() !== "" && item.agentIdValid === false
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const hasEmptyAgentId = agentsArray?.some(
      (item: any) => item.agentId.trim() === ""
    );

    const hasEmptyUserId = agentsArray?.some(
      (item: any) => item.userId.trim() === ""
    );

    const hasInvalidAgentId = agentsArray?.some(
      (item: any) => item.agentIdValid === false
    );

    const hasAgentIdUnique = combinedArray?.some(
      (item: any) => item.agentIdUnique === false
    );

    // if (hasEmptyAgentId) {
    //   setEmptyAgentField(true);
    //   return;
    // }

    if (filteredAgents?.length === 0) {
      setEmptyAgentField(true);
      return; // Stop the submission if there's no agentId
    }

    if (filteredInvalidAgents?.length > 0) {
      setInvalidAgentsError(true);
      return;
    }

    try {
      const updatedAgentsArray = combinedArray?.map((item: any) => ({
        userId: item.userId,
        agentId: item.agentId,
      }));
      // emptyAgentField === false &&
      //   hasEmptyUserId === false &&
      //   hasInvalidAgentId === false &&
      //   hasAgentIdUnique === false &&

      emptyAgentField === false &&
        hasAgentIdUnique === false &&
        AddOzonetelAgents(updatedAgentsArray);
    } catch (error) {}
  };

  const handleDeactivateOzonetel = () => {
    DeactivateOzonetel(instituteDetails?.id);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(ozonetelCallbackUrl);
    setIsCopied(true);

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    // Save the timeout ID for cleanup
    setTooltipTimeout(timeout);
  };

  React.useEffect(() => {
    return () => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, [tooltipTimeout]);

  React.useEffect(() => {
    if (deactivateOzonetelSuccess) {
      setShowUninstallPopup(false);
    }
  }, [deactivateOzonetelSuccess]);

  React.useEffect(() => {
    if (employees) {
      const defaultOzonetelEmployees = employees?.filter(
        (item: any) => !item.ozonetelConfig
      );
      const defaultAgentsArray = defaultOzonetelEmployees?.map((item: any) => ({
        userId: item.id,
        agentId: "",
        agentIdValid: false,
        userName: item.firstName + " " + item.lastName,
        agentIdUnique: true,
      }));
      setAgentsArray(defaultAgentsArray);
    }
  }, [employees]);

  React.useEffect(() => {
    if (ozonetelEmployees) {
      const defaultAgentsArray = ozonetelConfigEmployees?.map((item: any) => ({
        userId: item.id,
        agentId: item?.ozonetelConfig?.agentId,
        agentIdValid: item?.ozonetelConfig?.agentId !== "" ? true : false,
        userName: item.firstName + " " + item.lastName,
        agentIdUnique: true,
      }));
      setDeletableAgentsArray(defaultAgentsArray);
    }
  }, [ozonetelEmployees]);

  React.useEffect(() => {
    if (filteredInvalidAgents?.length === 0) {
      setInvalidAgentsError(false);
    }
  }, [filteredInvalidAgents]);

  React.useEffect(() => {
    if (filteredAgents?.length > 0) {
      setEmptyAgentField(false);
    }
  }, [filteredAgents]);

  React.useEffect(() => {
    if (agentAddedSuccess !== true && agentAddedSuccess !== false) {
      setEditMode(false);
      setShowDeletePopup(false);
    }
  }, [agentAddedSuccess]);

  return (
    <>
      <Box
        style={{
          // width: "calc(100% - 456px)",
          paddingBottom: "100px",
        }}
      >
        <div
          style={{
            color: "#667085",
            fontSize: "16px",
            fontWeight: "500",
            marginBottom: "12px",
            textAlign: "left",
          }}
        >
          Ozonetel Integration
        </div>
        <Box className={styles.AgentsListingWrapper}>
          <Box
            style={{
              display: "flex",
              borderBottom: "1px solid #E3E3E3",
              padding: "24px 24px 22px 24px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              <div>
                <img src={ozonetel} alt="ozonetel" />
              </div>
              <div className={styles.installedHead}>Ozonetel Installed</div>
            </div>

            <IconButton
              aria-label="menu"
              id="long-button"
              onClick={toggleUninstallPopup}
            >
              <MoreVertIcon />
            </IconButton>

            <Popover
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "4PX",
                  border: "1px solid #F2F2F2",
                },
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: 5,
                horizontal: 25,
              }}
            >
              <MenuItem
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "21px",
                  color: "#EC3939",
                  padding: "10px 18px",
                }}
                onClick={() => {
                  handleClose();
                  handleUninstallPopupOpen();
                }}
              >
                Uninstall
              </MenuItem>
            </Popover>
          </Box>

          <Box style={{ padding: "24px" }}>
            <div className={styles.ozonetelAccountDetailsWrap}>
              <div className={styles.ozonetelAccountDetailsItem}>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className={styles.ozonetelDetailsItemHeading}
                >
                  Callback URL
                  <div onClick={handleCallbackUrlHelpClick}>
                    <img src={help} alt="help" style={{ cursor: "pointer" }} />
                  </div>
                </div>

                <div style={{ display: "flex", gap: "4px" }}>
                  <div className={styles.ozonetelDetailsItemContent}>
                    {ozonetelCallbackUrl}
                  </div>
                  <div
                    style={{ cursor: "pointer", position: "relative" }}
                    onClick={handleCopyClick}
                  >
                    <ContentCopyRoundedIcon
                      sx={{ color: "#667085" }}
                      fontSize="small"
                    />
                    {isCopied && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "18px",
                          left: "-58px",
                          background: "rgb(122 122 122 / 70%)",
                          color: "#fff",
                          padding: "6px 8px",
                          borderRadius: "3px",
                          fontSize: "11px",
                        }}
                      >
                        Copied
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.ozonetelAccountDetailsItem}>
                <div className={styles.ozonetelDetailsItemHeading}>
                  Username
                </div>
                <div className={styles.ozonetelDetailsItemContent}>
                  {ozonetelAppUserName}
                </div>
              </div>
              <div className={styles.ozonetelAccountDetailsItem}>
                <div className={styles.ozonetelDetailsItemHeading}>API Key</div>
                <div className={styles.ozonetelDetailsItemContent}>
                  {ozonetelAppApiKey}
                </div>
              </div>
            </div>

            <DeletePopup
              isOpen={showDeletePopup}
              onClose={handleDeleteAgentPopupClose}
              yesBtnClick={handleDeleteSubmit}
              agentAddedLoading={agentAddedLoading}
            />
            <UninstallOzontelPopup
              isOpen={showUninstallPopup}
              onClose={handleUninstallPopupClose}
              yesBtnClick={handleDeactivateOzonetel}
              loader={deactivateOzonetelLoading}
              appName="Ozonetel"
            />
            <CallbackUrlPopup
              isOpen={showCallbackUrlPopup}
              onClose={() => setShowCallbackUrlPopup(false)}
            />
          </Box>
        </Box>
        <div className={styles.agentListingBox}>
          <div className={styles.agentListingBoxHead}>
            <div className={styles.agent}>
              {editMode ? "Edit Agents" : "Agents"}
            </div>
            {!editMode && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  cursor: "pointer",
                  fontSize: "13px",
                  color: "#667085",
                }}
                onClick={handleEditMode}
              >
                <div style={{ marginTop: "2px" }}>Edit</div>
                <FiEdit size={12} color="#667085" />
              </div>
            )}
          </div>
          <div
            style={{
              padding: "16px 22px",
            }}
          >
            {!editMode &&
              ozonetelConfigEmployees?.map((agent: any, index: number) => (
                <div
                  key={index}
                  style={{
                    borderRadius: "6px",
                    border: "1px solid #F2F2F2",
                    background: "#FFF",
                    marginBottom: "16px",
                    padding: "12px 14px",
                  }}
                >
                  <div className={styles.addedAgentsListWrap}>
                    <div className={styles.agentCardDetailsWrap}>
                      <div className={styles.agentCardDetailsWrapLeft}>
                        <div className={styles.agentCardImg}>
                          <Avatar
                            alt="avatar"
                            sx={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              fontSize: "17px",
                              background: "#F5F5F7",
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              border: "1px solid #EEEEF4",
                              color: "#667085",
                            }}
                            className={styles.agentAvatar}
                          >
                            <div>{agent?.firstName?.charAt(0)}</div>
                          </Avatar>
                        </div>
                        <div className={styles.agentName}>
                          {agent?.firstName + " " + agent?.lastName}
                        </div>
                      </div>

                      <div className={styles.userName}>
                        {`ID: ${agent?.ozonetelConfig?.agentId}`}
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {editMode && (
              <>
                {deletableAgentsArray?.map((agent: any, index: number) => (
                  <div
                    key={index}
                    style={{
                      borderRadius: "6px",
                      border: "1px solid #F2F2F2",
                      background: "#FFF",
                      marginTop: "16px",
                      padding: "12px 14px",
                    }}
                  >
                    <div className={styles.editAgentsFieldItemsWrap}>
                      <div className={styles.editAgentsFieldItemsWrapLeft}>
                        <div
                          className={styles.editAgentsFieldItemsWrapLeftItem}
                        >
                          <div className={styles.addAgentsFieldItemUserWrap}>
                            <div
                              className={
                                styles.addAgentsFieldItemUserWrapAvatar
                              }
                            >
                              {agent?.userName?.charAt(0)}
                            </div>
                            <div className={styles.addAgentsFieldItemUserName}>
                              {agent?.userName}
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles.editAgentsFieldItemsWrapLeftItem}
                        >
                          <div
                            style={{
                              padding: "8px 18px",
                              height: "40px",
                              color: "#142C44",
                              fontSize: "14px",
                              minWidth: "366px",
                              maxWidth: "366px",
                              fontWeight: "500",
                              border: "1px solid #D4D4D8",
                              borderRadius: "6PX",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>{agent?.agentId}</div>
                            <button
                              className={styles.removeAgentBtn}
                              onClick={() => handleDeleteClick(agent)}
                            >
                              <img
                                src={removeAgentIcon}
                                alt="removeAgentIcon"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {agentsArray?.map((agent: any, index: number) => (
                  <div
                    key={index}
                    style={{
                      borderRadius: "6px",
                      border: "1px solid #F2F2F2",
                      background: "#FFF",
                      marginTop: "16px",
                      padding: "12px 14px",
                    }}
                  >
                    <div className={styles.editAgentsFieldItemsWrap}>
                      <div className={styles.editAgentsFieldItemsWrapLeft}>
                        <div
                          className={styles.editAgentsFieldItemsWrapLeftItem}
                        >
                          <div className={styles.addAgentsFieldItemUserWrap}>
                            <div
                              className={
                                styles.addAgentsFieldItemUserWrapAvatar
                              }
                            >
                              {agent?.userName?.charAt(0)}
                            </div>
                            <div className={styles.addAgentsFieldItemUserName}>
                              {agent?.userName}
                            </div>
                          </div>
                        </div>
                        <div
                          className={styles.editAgentsFieldItemsWrapLeftItem}
                        >
                          <div style={{ position: "relative" }}>
                            <TextField
                              fullWidth
                              inputProps={{
                                style: {
                                  padding: "9.5px 12px 9.5px 12px",
                                  color: "#142C44",
                                  fontSize: "14px",
                                  minWidth: "342px",
                                  fontWeight: "500",
                                },
                              }}
                              placeholder="Enter Agent ID"
                              value={agent?.agentId}
                              onChange={(e) =>
                                handleAgentIdChange(e.target.value, index)
                              }
                              // onChange={(e) =>
                              //   handleAgentIdChange(
                              //     e.target.value,
                              //     agent?.userId
                              //   )
                              // }
                              onBlur={(e) =>
                                handleAgentIdBlur(e.target.value, index)
                              }
                            />
                            {/* <button
                              className={styles.removeAgentBtn}
                              onClick={() => handleDeleteNewlyAddedAgent(index)}
                            >
                              x
                            </button> */}
                          </div>

                          {!agent?.agentIdValid &&
                            agent?.agentId !== "" &&
                            agent?.agentIdUnique && (
                              <FormHelperText error>
                                Invalid agent id.
                              </FormHelperText>
                            )}
                          {agent?.agentIdValid &&
                            agent?.agentId !== "" &&
                            agent?.agentIdUnique && (
                              <FormHelperText sx={{ color: "#11c89b" }}>
                                Valid agent id.
                              </FormHelperText>
                            )}
                          {!agent?.agentIdUnique && (
                            <FormHelperText error>
                              Agent ID should be unique.
                            </FormHelperText>
                          )}
                        </div>
                      </div>
                      {/* <IconButton
                        // onClick={() => handleDeleteClick(agent)}
                        onClick={() => handleDeleteNewlyAddedAgent(index)}
                      >
                        <img src={deleteImg} alt="deleteImg" />
                      </IconButton> */}
                    </div>
                  </div>
                ))}
              </>
            )}

            {editMode && (
              <>
                {emptyAgentField && (
                  <FormHelperText error>
                    Please fill atleast one valid agent id.
                  </FormHelperText>
                )}
                {!emptyAgentField && invalidAgentsError && (
                  <FormHelperText error>
                    Please fill valid agent id or clear invalid agent ids.
                  </FormHelperText>
                )}
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "6px",
                  }}
                >
                  <Button
                    component="label"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                      display: "flex",
                      gap: "4px",
                    }}
                    startIcon={<AddIcon />}
                    onClick={handleAddAgent}
                  >
                    Add another agent
                  </Button>
                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "18px",
                    gap: "12px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      color: "#344054",
                      textTransform: "capitalize",
                      fontWeight: "500",
                      display: "flex",
                      gap: "4px",
                      minWidth: "170px",
                      border: "1px solid #D0D5DD",
                      "&:hover": {
                        border: "1px solid #D0D5DD",
                        background: "#FFF",
                      },
                    }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                      display: "flex",
                      gap: "4px",
                      minWidth: "170px",
                    }}
                    onClick={handleSubmit}
                    disabled={!showDeletePopup && editMode && agentAddedLoading}
                  >
                    {!showDeletePopup && editMode && agentAddedLoading ? (
                      <CircularProgress size={20} color={"inherit"} />
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Box>
    </>
  );
}

export default AgentsListingComponent;
