import { RootState, TypedDispatch } from "../../../../../redux/store";
import { connect } from "react-redux";

import {
  createCustomFieldWebform,
  createWebform,
  deleteWebform,
  getAllWebForm,
  getPrimaryFieldsByInstitute,
  getPrimaryFieldsByInstituteForBranch,
  getWebformByInstitute,
  updateWebform,
} from "../service/webformSlice";
import {
  WebformInitialStateDTO,
  WebformDTO,
  WebformCustomfieldDTO,
} from "../service/types";
import Webform from "../components/Webform";
import WebFormBuilding from "../components/WebFormBuilding/WebFormBuilding";
import { getNurtureLeadBoard } from "../../../Nurture/service/nurtureSlice";

interface StateProps extends WebformInitialStateDTO {}

interface DispatchProps {
  CreateWebform(data: WebformDTO): void;
  UpdateWebform(data: WebformDTO): void;
  GetAllWebform(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string,
    roleInfo: any,
    formType: any
  ): void;
  GetWebformForInstitute(instituteId: string, webformId: string): void;
  GetPrimaryFiledsByInstitute(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string
  ): void;
  CreateWebformCustomField(data: WebformCustomfieldDTO): void;
  DeleteWebform(id: string): void;
  GetPrimaryFiledsByInstituteForBranch(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string,
    branchId: string
  ): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    CreateWebformSuccess: state.webform.CreateWebformSuccess,
    CreateWebformError: state.webform.CreateWebformError,
    CreateWebformLoading: state.webform.CreateWebformLoading,
    UpdateWebformSuccess: state.webform.UpdateWebformSuccess,
    UpdateWebformError: state.webform.UpdateWebformError,
    UpdateWebformLoading: state.webform.UpdateWebformLoading,
    CreateCustomFieldWebformSuccess:
      state.webform.CreateCustomFieldWebformSuccess,
    CreateCustomFieldWebformError: state.webform.CreateCustomFieldWebformError,
    CreateCustomFieldWebformLoading:
      state.webform.CreateCustomFieldWebformLoading,
    GetAllWebformSuccess: state.webform.GetAllWebformSuccess,
    GetAllWebformError: state.webform.GetAllWebformError,
    GetAllWebformLoading: state.webform.GetAllWebformLoading,
    getAllFormByInstituteData: state.webform.getAllFormByInstituteData,
    CreateWebformId: state.webform.CreateWebformId,
    getFormByInstituteSuccess: state.webform.getFormByInstituteSuccess,
    getFormByInstituteLoading: state.webform.getFormByInstituteLoading,
    getFormByInstituteError: state.webform.getFormByInstituteError,
    getFormByInstituteData: state.webform.getFormByInstituteData,
    getPrimaryFieldsSuccess: state.webform.getPrimaryFieldsSuccess,
    getPrimaryFieldsLoading: state.webform.getPrimaryFieldsLoading,
    getPrimaryFieldsError: state.webform.getPrimaryFieldsError,
    getPrimaryFieldsData: state.webform.getPrimaryFieldsData,
    getFormResponseSuccess: state.webform.getFormResponseSuccess,
    getFormResponseLoading: state.webform.getFormResponseLoading,
    getFormResponseError: state.webform.getFormResponseError,
    getFormResponseData: state.webform.getFormResponseData,
    getStudentContactByIdSuccess: state.webform.getStudentContactByIdSuccess,
    getStudentContactByIdLoading: state.webform.getStudentContactByIdLoading,
    getStudentContactByIdError: state.webform.getStudentContactByIdError,
    getStudentContactByIdData: state.webform.getStudentContactByIdData,
    bulkLeadCreationSuccess: state.webform.bulkLeadCreationSuccess,
    bulkLeadCreationLoading: state.webform.bulkLeadCreationLoading,
    bulkLeadCreationError: state.webform.bulkLeadCreationError,
    deleteWebformResponseSuccess: state.webform.deleteWebformResponseSuccess,
    deleteWebformResponseLoading: state.webform.deleteWebformResponseLoading,
    deleteWebformResponseError: state.webform.deleteWebformResponseError,
    deleteWebformSuccess: state.webform.deleteWebformSuccess,
    deleteWebformLoading: state.webform.deleteWebformLoading,
    deleteWebformError: state.webform.deleteWebformError,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateWebform: (data: any) => {
      dispatch(createWebform(data));
    },
    UpdateWebform: (data: any) => {
      dispatch(updateWebform(data));
    },
    GetAllWebform: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string,
      roleInfo: any,
      formType: any
    ) => {
      dispatch(
        getAllWebForm({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          roleInfo,
          formType,
        })
      );
    },
    GetWebformForInstitute: (instituteId: string, webformId: string) => {
      dispatch(getWebformByInstitute({ instituteId, webformId }));
    },
    GetPrimaryFiledsByInstitute: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string
    ) => {
      dispatch(
        getPrimaryFieldsByInstitute({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
        })
      );
    },
    CreateWebformCustomField: (data: WebformCustomfieldDTO) => {
      dispatch(createCustomFieldWebform(data));
    },
    DeleteWebform: (id: string) => {
      dispatch(deleteWebform(id));
    },
    GetPrimaryFiledsByInstituteForBranch: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string,
      branchId: string
    ) => {
      dispatch(
        getPrimaryFieldsByInstituteForBranch({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          branchId,
        })
      );
    },
  };
};
export interface EmailListProps extends StateProps, DispatchProps {}
export const WebformBuildingComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFormBuilding);
