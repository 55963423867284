import React from "react";
import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function StudentListSkeleton() {
  return (
    <div className={styles.batchBasicDetailsWrap} style={{ padding: "10px" }}>
      <div style={{ padding: "10px 20px" }}>
        <div
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>
    </div>
  );
}

export default StudentListSkeleton;
