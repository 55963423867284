export default function formatTimestamp(timestamp: number): string {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  let hour = date.getHours();
  const minute = date.getMinutes();
  const ampm = hour >= 12 ? "pm" : "am";

  // Adjust hour to 12-hour format
  hour = hour % 12;
  hour = hour ? hour : 12; // '0' should be displayed as '12'

  // Add leading zeros to minute if necessary
  const paddedMinute = minute < 10 ? `0${minute}` : minute;

  // Format the final string
  const formattedTimestamp = `${day}/${month}/${year}, ${hour}:${paddedMinute} ${ampm}`;

  return formattedTimestamp;
}

export function formatRelativeTime(timestamp: any) {
  const now = Date.now();
  const diff = now - timestamp;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (seconds < 60) {
    return "just now";
  } else if (minutes < 30) {
    return `${minutes}m ago`;
  } else if (minutes < 60) {
    return "1h ago";
  } else if (hours < 24) {
    return `${hours}h ago`;
  } else if (days < 7) {
    return `${days}d ago`;
  } else {
    return `${weeks}w ago`;
  }
}
export function timeZoneConverter(timestamp: number): any {
  if (timestamp?.toString()?.length === 10) {
    var date = new Date(
      timestamp * 1000 -
        new Date(timestamp * 1000).getTimezoneOffset() * 60 * 1000
    );
    return date?.getTime();
  } else {
    var date = new Date(
      timestamp - new Date(timestamp).getTimezoneOffset() * 60 * 1000
    );
    return date?.getTime();
  }
}

export function timeSecondsConverter(timestamp: number, type: string): any {
  if (timestamp?.toString()?.length === 10) {
    var date = new Date(
      timestamp * 1000 -
        new Date(timestamp * 1000).getTimezoneOffset() * 60 * 1000
    );
    type === "startTime" ? date.setSeconds(0) : date?.setSeconds(59);
    type === "startTime" ? date.setMilliseconds(0) : date?.setMilliseconds(59);
    return date?.getTime();
  } else {
    var date = new Date(
      timestamp - new Date(timestamp).getTimezoneOffset() * 60 * 1000
    );
    type === "startTime" ? date.setSeconds(0) : date?.setSeconds(59);
    type === "startTime" ? date.setMilliseconds(0) : date?.setMilliseconds(59);
    return date?.getTime();
  }
}

export function convertToAdjustedTimestamp(timestamp: any) {
  var localDate = new Date(timestamp);
  var adjustedTimestamp =
    localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000;
  return adjustedTimestamp;
}
