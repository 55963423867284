import { isSameDay, format, compareAsc } from "date-fns";
import React, { useState } from "react";
import startOfDay from "date-fns/startOfDay";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalenderTimingsModel from "../CalenderTimingsModel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Event from "./Event";
import UnavailabelModel from "../UnavailabelModel";
import { timeZoneConverter } from "../../../../../../components/Formatter/Formatter";
import IndividualTimingsModel from "../IndividualTimingsModel";
import AddIcon from "@mui/icons-material/Add";

const DayCard = ({
  date,
  events,
  batchDetails,
  setBatchDetails,
  add,
  setIsDateChanged,
}: any) => {
  const month = format(date, "MMMM");
  const [openTimingsModel, setOpenTimingsModel] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const day = date.getDate();
  const [openUnavailabelModel, setOpenUnavailableModel] = useState(false);
  const [unavailabelDate, setUnavailabelDate] = useState<any>();
  const [openIndividualDateModel, setOpenIndividualDateModel] = useState(false);
  const [individualDate, setIndividualDate] = useState<any>();

  const dateofDayName = new Date(date);
  const options: any = { weekday: "long" };

  const dayName = dateofDayName.toLocaleString("en-US", options);

  const isFutureOrToday =
    compareAsc(startOfDay(date), startOfDay(new Date())) >= 0;

  let sortedEvents = [...events].sort((a, b) => {
    const timeA = a.startTime.split(" ")[0];
    const timeB = b.startTime.split(" ")[0];

    const [hourA, minuteA] = timeA.split(":").map(Number);
    const [hourB, minuteB] = timeB.split(":").map(Number);

    const isPMA = a.startTime.includes("PM");
    const isPMB = b.startTime.includes("PM");

    const adjustedHourA = isPMA && hourA !== 12 ? hourA + 12 : hourA;
    const adjustedHourB = isPMB && hourB !== 12 ? hourB + 12 : hourB;

    if (adjustedHourA === adjustedHourB) {
      return minuteA - minuteB;
    } else {
      return adjustedHourA - adjustedHourB;
    }
  });

  const saveTimings = () => {
    setOpenTimingsModel(false);
  };

  const cancelTimings = () => {
    setOpenTimingsModel(false);
  };

  const saveIndividualTimings = () => {
    setOpenIndividualDateModel(false);
  };

  const cancelIndividualTimings = () => {
    setOpenIndividualDateModel(false);
  };

  const saveUnavailabel = () => {
    setOpenUnavailableModel(false);
  };

  const cancelUnavailabel = () => {
    setOpenUnavailableModel(false);
  };

  const open = Boolean(anchorEl1);

  const handleClick = (event: any) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl1(null);
  };

  const isBeforeStartDate =
    batchDetails?.startDate?.toString()?.length === 10
      ? startOfDay(date) <
        startOfDay(
          new Date(timeZoneConverter(parseInt(batchDetails.startDate)))
        )
      : startOfDay(date) < startOfDay(new Date(batchDetails?.startDate));

  const offset = new Date(batchDetails.endDate)?.getTimezoneOffset() * -1;
  const endDate: any = new Date(batchDetails.endDate - offset * 60000);

  const isAfterEndDate = startOfDay(date) > startOfDay(endDate);

  const handleUnavailable = (val: any) => {
    const newExemptedDate = { date: val.getTime(), day: val.getDay() };
    events.push({
      _id: "generated_id",
      status: "ACTIVE",
      rsvpList: [],
      title: "Batch Session",
      description: "Batch Session",
      location: "Your Location",
      groupId: null,
      startAt: new Date(val).toISOString(),
      endAt: new Date(val).toISOString(),
      user: {
        _id: "batch_user_id",
        displayName: "Batch",
      },
      __v: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      exemptedDates: [newExemptedDate],
      startTime: "",
      endTime: "",
    });
  };

  const isExemptedDatesExist = events.some(
    (event: any) => event.exemptedDates.length > 0
  );

  const handleIndividualTimings = (val: any) => {
    events.push({
      _id: "generated_id",
      status: "ACTIVE",
      rsvpList: [],
      title: "Batch Session",
      description: "Batch Session",
      location: "Your Location",
      groupId: null,
      startAt: new Date(val).toISOString(),
      endAt: new Date(val).toISOString(),
      user: {
        _id: "batch_user_id",
        displayName: "Batch",
      },
      __v: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      exemptedDates: [],
      startTime: "",
      endTime: "",
    });
  };

  const handleAvailable = () => {
    setBatchDetails((prevBatchDetails: any) => ({
      ...prevBatchDetails,
      exemptedDates: prevBatchDetails.exemptedDates.filter(
        (exemptedDate: any) =>
          exemptedDate.exemptedDate !== timeZoneConverter(unavailabelDate)
      ),
    }));
    setIsDateChanged(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background:
            events[0]?.exemptedDates?.length > 0
              ? "#f5c4c4"
              : isBeforeStartDate || isAfterEndDate
              ? "#FAFAFA"
              : "white",
          pointerEvents:
            !isFutureOrToday && (isBeforeStartDate || isAfterEndDate)
              ? "none"
              : "auto",
        }}
        onClick={() => {
          // setOpenTimingsModel(true);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "block",
              padding: "4px 8px",
            }}
          >
            {day} {day === 1 && month}
          </span>
          {isFutureOrToday &&
            !(isBeforeStartDate || isAfterEndDate) &&
            sortedEvents?.length > 0 && (
              <MoreVertIcon
                onClick={handleClick}
                style={{
                  color: "#BDBFC3",
                  cursor: "pointer",
                }}
              />
            )}
        </div>
        <div style={{ position: "relative", top: "20px", textAlign: "center" }}>
          {isFutureOrToday &&
            sortedEvents?.length === 0 &&
            !(isBeforeStartDate || isAfterEndDate) && (
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  color: "#667085",
                  justifyContent: "center",
                }}
                onClick={handleClick}
              >
                <AddIcon
                  sx={{
                    fontSize: "18px",
                    color: "#667085",
                  }}
                />
                Add
              </div>
            )}
        </div>

        <div className="flex flex-col px-1 py-1 overflow-auto">
          {sortedEvents.map((event: any, i: any) => (
            <Event event={event} key={i} />
          ))}
        </div>
      </div>
      {openIndividualDateModel && (
        <IndividualTimingsModel
          open={openIndividualDateModel}
          noButtonClick={cancelIndividualTimings}
          yesButtonClick={saveIndividualTimings}
          setOpenIndicidualDateModel={setOpenIndividualDateModel}
          date={date}
          batchDetails={batchDetails}
          setBatchDetails={setBatchDetails}
          add={add}
          handleIndividualTimings={handleIndividualTimings}
          events={events}
          individualDate={individualDate}
          setIsDateChanged={setIsDateChanged}
          sortedEvents={sortedEvents}
        />
      )}
      {openTimingsModel && (
        <CalenderTimingsModel
          open={openTimingsModel}
          noButtonClick={cancelTimings}
          yesButtonClick={saveTimings}
          setOpenTimingsModel={setOpenTimingsModel}
          date={date}
          batchDetails={batchDetails}
          setBatchDetails={setBatchDetails}
          add={add}
          setIsDateChanged={setIsDateChanged}
        />
      )}
      {openUnavailabelModel && (
        <UnavailabelModel
          open={openUnavailabelModel}
          noButtonClick={cancelUnavailabel}
          yesButtonClick={saveUnavailabel}
          setOpenUnavailableModel={setOpenUnavailableModel}
          date={date}
          batchDetails={batchDetails}
          setBatchDetails={setBatchDetails}
          add={add}
          unavailabelDate={unavailabelDate}
          handleUnavailable={handleUnavailable}
          events={events}
          setIsDateChanged={setIsDateChanged}
        />
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl1}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          marginTop: "5px",
          padding: "2px 2px",
          "& .MuiList-root": {
            padding: "2px 2px",
          },
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "4PX",
            border: "1px solid #EEE",
            minWidth: "108PX",
          },
        }}
      >
        {!isExemptedDatesExist && (
          <>
            <MenuItem
              onClick={() => {
                setOpenIndividualDateModel(true);
                setIndividualDate(date);
                setAnchorEl1(null);
              }}
              sx={{
                fontSize: "13px",
                color: "#142C44",
                padding: "6px 8px",
                lineHeight: "22px",
                fontFamily: "poppins",
                borderRadius: "2px",
                "&:hover": {
                  color: "#fff",
                  bgcolor: "#11C89B",
                  fontWeight: "500",
                },
              }}
            >
              {sortedEvents?.length === 0 ? "Add Date" : "Edit Date"}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenTimingsModel(true);
                setAnchorEl1(null);
              }}
              sx={{
                fontSize: "13px",
                color: "#142C44",
                padding: "6px 8px",
                lineHeight: "22px",
                fontFamily: "poppins",
                borderRadius: "2px",
                "&:hover": {
                  color: "#fff",
                  bgcolor: "#11C89B",
                  fontWeight: "500",
                },
              }}
            >
              {sortedEvents?.length === 0
                ? `Add all ${dayName}`
                : `Edit all ${dayName}`}
            </MenuItem>
          </>
        )}
        {isExemptedDatesExist ? (
          <MenuItem
            onClick={() => {
              handleAvailable();
              setUnavailabelDate(date);
              setAnchorEl1(null);
            }}
            sx={{
              fontSize: "13px",
              color: "#142C44",
              padding: "6px 8px",
              lineHeight: "22px",
              fontFamily: "poppins",
              borderRadius: "2px",
              "&:hover": {
                color: "#fff",
                bgcolor: "#11C89B",
                fontWeight: "500",
              },
            }}
          >
            Mark as Available
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setOpenUnavailableModel(true);
              setUnavailabelDate(date);
              setAnchorEl1(null);
            }}
            sx={{
              fontSize: "13px",
              color: "#142C44",
              padding: "6px 8px",
              lineHeight: "22px",
              fontFamily: "poppins",
              borderRadius: "2px",
              "&:hover": {
                color: "#fff",
                bgcolor: "#11C89B",
                fontWeight: "500",
              },
            }}
          >
            Mark as Unavailable
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DayCard;
