import {
  Autocomplete,
  Avatar,
  Chip,
  FormControl,
  FormHelperText,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import React from "react";

function MultiAssigneeSelectField(props: any) {
  const {
    options,
    onChange,
    disableClearable,
    value,
    required,
    label,
    id,
    validState,
  } = props;
  return (
    <>
      <FormControl fullWidth variant="standard" className="formControl">
        <div
          style={{
            fontSize: "12px",
            color: "#142C44",
            fontWeight: "500",
            fontFamily: "Poppins",
            display: "flex",
            margin: "0px 0px 5px",
          }}
        >
          {label}
          {required === true && (
            <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
          )}
        </div>

        {options && (
          <Autocomplete
            // disablePortal
            multiple
            id={id}
            options={options}
            getOptionLabel={(option: any) =>
              option?.firstName + " " + option?.lastName || ""
            }
            fullWidth
            onChange={onChange}
            value={value || []}
            disableClearable={disableClearable}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                size="small"
                error={!validState}
              />
            )}
            renderTags={(value, getTagProps) =>
              value?.map((option, index) => (
                <Chip
                  label={`${option.firstName} ${option.lastName}`}
                  {...getTagProps({ index })}
                  avatar={
                    <Avatar
                      sx={{
                        background: "#F5F5F7",
                        border: "1px solid #EEEEF4",
                        textTransform: "capitalize",
                        fontSize: "16PX",
                        fontWeight: "500",
                        color: "#667085",
                      }}
                    >
                      {option?.firstName?.charAt(0)}
                    </Avatar>
                  }
                  style={{
                    background: "transparent",
                    textTransform: "capitalize",
                    fontSize: "14PX",
                    fontWeight: "500",
                    color: "#142C44",
                    // border: "1px solid #F3F3F3",
                    borderRadius: "4PX",
                  }}
                />
              ))
            }
            renderOption={(props: any, option: any) => (
              <ListItem {...props} key={option.firstName + option.lastName}>
                <ListItemText
                  primary={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textTransform: "capitalize",
                        color: "#667085",
                        fontSize: "14PX",
                      }}
                    >
                      <div
                        style={{
                          width: 24,
                          height: 24,
                          backgroundColor: "#F5F5F7",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 6,
                          textTransform: "uppercase",
                          color: "#667085",
                          fontSize: "10px",
                          fontWeight: "500",
                          padding: "5px 7px 3px 7px",
                        }}
                      >
                        {option?.firstName?.[0] + option?.lastName?.[0]}
                      </div>

                      {`${option?.firstName + " " + option?.lastName}`}
                    </div>
                  }
                />
              </ListItem>
            )}
            ListboxProps={{
              style: {
                maxHeight: "230px",
                fontSize: "14px",
                color: "142C44",
                fontFamily: "poppins",
                textTransform: "capitalize",
              },
            }}
          />
        )}
        {validState === false && (
          <FormHelperText error={true}>Field required</FormHelperText>
        )}
      </FormControl>
    </>
  );
}

export default MultiAssigneeSelectField;
