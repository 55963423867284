import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import Campaign from "../components/campaign";
import { CampaignStateDTO, StudentContactStateDTO } from "../service/types";
import { createContact, updateContact } from "../service/campaignSlice";

interface StateProps extends CampaignStateDTO {}

interface DispatchProps {
  CreateContact(data: StudentContactStateDTO): void;
  UpdateContact(data: { id: string; demoTiming: number }): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.campaign.success,
    error: state.campaign.error,
    loading: state.campaign.loading,
    contactUpdateLoading: state.campaign.contactUpdateLoading,
    contactUpdateError: state.campaign.contactUpdateError,
    contactUpdateSuccess: state.campaign.contactUpdateSuccess,
    contactDetails: state.campaign.contactDetails,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateContact: (data: any) => {
      dispatch(createContact(data));
    },
    UpdateContact: (data: any) => {
      dispatch(updateContact(data));
    },
  };
};
export interface CampaignProps extends StateProps, DispatchProps {}
export const CampaignComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaign);
