import {
  Grid,
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  Button,
  Checkbox,
  Menu,
} from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styles from "../../Managebatches.module.css";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import sessionAdd from "../../../../../../../assets/images/session-add.svg";
import sessionDelete from "../../../../../../../assets/images/session-delete.svg";
import sessionCopy from "../../../../../../../assets/images/session-copy.svg";
import SwitchInput from "../../../../../../../components/Switch/SwitchInput";

export default function WeekDuration(props: any) {
  const {
    batchSchedule,
    batchDetails,
    setBatchDetails,
    formDisabled,
    add,
    setIsDateChanged,
  } = props;

  const [disabledFields, setDisabledFields] = useState(
    new Array(batchSchedule.length).fill(true)
  );

  useEffect(() => {
    if (!add) {
      const updatedDisabledFields = batchDetails.batchSchedule.map(
        (schedule: any) =>
          schedule.batchSessions.every(
            (session: any) => session.startTime === 0 && session.endTime === 0
          )
      );
      setDisabledFields(updatedDisabledFields);
    }
  }, [add, batchSchedule]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [copyDay, setCopyDay] = useState("");
  const [selectedDays, setSelectedDays] = useState<any>([]);

  const open = Boolean(anchorEl);
  useEffect(() => {
    const defaultDisabledArray = disabledFields;
    batchSchedule.forEach((obj: any, key: number) => {
      if (
        (typeof obj?.batchSessions[0]?.startTime === "string"
          ? parseInt(obj?.batchSessions[0]?.startTime)
          : obj?.batchSessions[0]?.startTime) === 0
      ) {
        defaultDisabledArray[key] = true;
      } else {
        defaultDisabledArray[key] = false;
      }
    });
  }, [formDisabled]);

  const handleButtonClick = React.useCallback(
    (index: number) => {
      setIsDateChanged(true);
      const newDisabledFields = [...disabledFields];
      newDisabledFields[index] = !newDisabledFields[index];
      setDisabledFields(newDisabledFields);
      const newBatchSchedule = [...batchSchedule];
      const itemToUpdate = {
        ...newBatchSchedule[index],
      };
      if (!newDisabledFields[index]) {
        const now = moment().unix();
        if (
          itemToUpdate.batchSessions &&
          itemToUpdate.batchSessions.length > 0
        ) {
          const updatedBatchSession = {
            ...itemToUpdate.batchSessions[index],
            startTime: now,
            endTime: now,
          };
          itemToUpdate.batchSessions = [updatedBatchSession];
        }
      } else {
        if (
          itemToUpdate.batchSessions &&
          itemToUpdate.batchSessions.length > 0
        ) {
          const updatedBatchSession = {
            ...itemToUpdate.batchSessions[index],
            startTime: 0,
            endTime: 0,
          };
          itemToUpdate.batchSessions = [updatedBatchSession];
        }
      }
      newBatchSchedule[index] = itemToUpdate;
      setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
    },
    [batchDetails, disabledFields, batchSchedule, setBatchDetails]
  );

  const handleTimeChange = React.useCallback(
    (event: any, index: number, timingIndex: number, key: string) => {
      setIsDateChanged(true);
      const unixTime = event.unix();
      const newBatchSchedule = [...batchDetails.batchSchedule];
      const updatedItem = {
        ...newBatchSchedule[index],
        batchSessions: [...newBatchSchedule[index].batchSessions],
      };
      updatedItem.batchSessions[timingIndex] = {
        ...updatedItem.batchSessions[timingIndex],
        [key]: unixTime,
        sessionName: "",
      };
      newBatchSchedule[index] = updatedItem;
      setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
    },
    [batchDetails, setBatchDetails]
  );

  const handleAddTiming = React.useCallback(
    (index: number) => {
      setIsDateChanged(true);
      const now = moment().unix();
      const newBatchSchedule = batchSchedule.map(
        (scheduleItem: any, scheduleIndex: number) => {
          if (scheduleIndex === index) {
            const updatedBatchSessions = [
              ...scheduleItem.batchSessions,
              // { sessionName: "", startTime: 0, endTime: 0 },
              { sessionName: "", startTime: now, endTime: now },
            ];
            return { ...scheduleItem, batchSessions: updatedBatchSessions };
          }
          return scheduleItem;
        }
      );

      const updatedDisabledFields = [...disabledFields];
      updatedDisabledFields[index] = false; // Enable the fields after adding a new session
      setDisabledFields(updatedDisabledFields);

      setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
    },
    [
      batchDetails,
      batchSchedule,
      disabledFields,
      setBatchDetails,
      setDisabledFields,
    ]
  );

  // const handleRemoveTiming = React.useCallback(
  //   (index: number, timingIndex: number) => {
  //     setIsDateChanged(true);
  //     const newBatchSchedule = batchSchedule.map(
  //       (item: any, batchIndex: any) => {
  //         if (batchIndex === index) {
  //           const updatedBatchSessions = item.batchSessions.filter(
  //             (_: any, sessionIndex: number) => sessionIndex !== timingIndex
  //           );
  //           return {
  //             ...item,
  //             batchSessions: updatedBatchSessions,
  //           };
  //         }
  //         return item;
  //       }
  //     );

  //     setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
  //   },
  //   [batchDetails, batchSchedule, setBatchDetails]
  // );

  const handleRemoveTiming = React.useCallback(
    (index: number, timingIndex: number) => {
      setIsDateChanged(true);
      const newBatchSchedule = batchSchedule.map(
        (item: any, batchIndex: any) => {
          if (batchIndex === index) {
            const updatedBatchSessions = item.batchSessions.filter(
              (_: any, sessionIndex: number) => sessionIndex !== timingIndex
            );

            // If no sessions left, keep one empty session
            if (updatedBatchSessions.length === 0) {
              updatedBatchSessions.push({
                sessionName: "",
                startTime: 0,
                endTime: 0,
              });
            }

            return {
              ...item,
              batchSessions: updatedBatchSessions,
            };
          }
          return item;
        }
      );

      setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
    },
    [batchDetails, batchSchedule, setBatchDetails]
  );

  const handleApplyCopy = () => {
    setIsDateChanged(true);
    if (copyDay && selectedDays.length > 0) {
      const newBatchSchedule = batchSchedule.map((day: any) => ({ ...day }));
      const sourceDayIndex = newBatchSchedule.findIndex(
        (day: any) => day.day === copyDay
      );
      const newDisabledFields = [...disabledFields];

      selectedDays.forEach((selectedDay: any) => {
        if (selectedDay !== copyDay) {
          const targetDayIndex = newBatchSchedule.findIndex(
            (day: any) => day.day === selectedDay
          );
          if (targetDayIndex !== -1) {
            newDisabledFields[targetDayIndex] =
              newDisabledFields[sourceDayIndex];
            newBatchSchedule[targetDayIndex] = {
              ...newBatchSchedule[targetDayIndex],
              batchSessions: newBatchSchedule[sourceDayIndex].batchSessions.map(
                (session: any) => ({
                  ...session,
                })
              ),
            };
          }
        }
      });

      setDisabledFields(newDisabledFields);
      setBatchDetails({ ...batchDetails, batchSchedule: newBatchSchedule });
    }
    setAnchorEl(null);
  };

  const handleDayCheckboxChange = (day: string) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay: any) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <div>
      {batchSchedule &&
        batchSchedule?.map((item: any, index: any) => {
          return (
            <div className={styles.scheduleItemsWrap} key={item.day + index}>
              <Grid
                container
                sx={{
                  display: "flex",
                  // alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "100%",
                    marginTop: "10PX",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "140px",
                    }}
                  >
                    <p style={{ marginRight: "10px", fontSize: "16px" }}>
                      {item?.day === "MON"
                        ? "Monday"
                        : item?.day === "TUE"
                        ? "Tuesday"
                        : item?.day === "WED"
                        ? "Wednesday"
                        : item?.day === "THU"
                        ? "Thursday"
                        : item?.day === "FRI"
                        ? "Friday"
                        : item?.day === "SAT"
                        ? "Saturday"
                        : "Sunday"}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "140px",
                    }}
                  >
                    <Grid item xs={12} lg={2}>
                      <div>
                        <SwitchInput
                          switchStatus={item.batchSessions.some(
                            (session: any) => session.startTime !== 0
                          )}
                          handleSwitch={() => handleButtonClick(index)}
                          disabled={formDisabled}
                          label={
                            item.batchSessions.some(
                              (session: any) => session.startTime !== 0
                            )
                              ? "Open"
                              : "Closed"
                          }
                          labelPlacement={"end"}
                        />
                        {/* <FormControlLabel
                          control={
                            <Switch
                              onChange={() => handleButtonClick(index)}
                              checked={item.batchSessions.some(
                                (session: any) => session.startTime !== 0
                              )}
                              disabled={formDisabled}
                            />
                          }
                          label={
                            item.batchSessions.some(
                              (session: any) => session.startTime !== 0
                            )
                              ? "Open"
                              : "Closed"
                          }
                        /> */}
                      </div>
                    </Grid>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "60%",
                  }}
                >
                  {item.batchSessions.some(
                    (session: any) => session.startTime !== 0
                  ) && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "10px",
                        }}
                      >
                        {item.batchSessions.map(
                          (timing: any, timingIndex: number) => (
                            <div
                              key={timingIndex}
                              className={styles.timingSlot}
                            >
                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  marginTop: "5px",
                                }}
                              >
                                <Grid item xs={12} lg={5}>
                                  <FormControl>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      {add && (
                                        <TimePicker
                                          onChange={(event) =>
                                            handleTimeChange(
                                              event,
                                              index,
                                              timingIndex,
                                              "startTime"
                                            )
                                          }
                                          value={
                                            timing.startTime !== 0
                                              ? dayjs.unix(timing.startTime)
                                              : dayjs()
                                          }
                                          // disabled={
                                          //   disabledFields[index] ||
                                          //   formDisabled
                                          // }
                                          sx={{
                                            width: "140px",
                                            "& .MuiInputBase-input": {
                                              height: "15px",
                                            },
                                          }}
                                          closeOnSelect={false}
                                        />
                                      )}

                                      {!add && (
                                        <TimePicker
                                          onChange={(event) =>
                                            handleTimeChange(
                                              event,
                                              index,
                                              timingIndex,
                                              "startTime"
                                            )
                                          }
                                          value={
                                            timing.startTime !== 0
                                              ? dayjs.unix(timing.startTime)
                                              : dayjs()
                                          }
                                          // disabled={
                                          //   formDisabled ||
                                          //   (typeof timing?.startTime ===
                                          //   "string"
                                          //     ? parseInt(timing?.startTime)
                                          //     : timing?.startTime) === 0
                                          // }
                                          sx={{
                                            width: "140px",
                                            "& .MuiInputBase-input": {
                                              height: "15px",
                                            },
                                          }}
                                          closeOnSelect={false}
                                        />
                                      )}
                                    </LocalizationProvider>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={1}
                                  sx={{ textAlign: "center" }}
                                >
                                  <p
                                    style={{
                                      color: "#C7C7C7",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    to
                                  </p>
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                  <FormControl>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      {add && (
                                        <TimePicker
                                          onChange={(event) =>
                                            handleTimeChange(
                                              event,
                                              index,
                                              timingIndex,
                                              "endTime"
                                            )
                                          }
                                          value={
                                            timing.endTime !== 0
                                              ? dayjs.unix(timing.endTime)
                                              : dayjs()
                                          }
                                          // disabled={
                                          //   disabledFields[index] ||
                                          //   formDisabled
                                          // }
                                          sx={{
                                            width: "140px",
                                            "& .MuiInputBase-input": {
                                              height: "15px",
                                            },
                                          }}
                                          closeOnSelect={false}
                                        />
                                      )}

                                      {!add && (
                                        <TimePicker
                                          onChange={(event) =>
                                            handleTimeChange(
                                              event,
                                              index,
                                              timingIndex,
                                              "endTime"
                                            )
                                          }
                                          value={
                                            timing.endTime !== 0
                                              ? dayjs.unix(timing.endTime)
                                              : dayjs()
                                          }
                                          // disabled={
                                          //   formDisabled ||
                                          //   (timing?.endTime === "string"
                                          //     ? parseInt(timing?.endTime)
                                          //     : timing?.endTime) === 0
                                          // }
                                          sx={{
                                            width: "140px",
                                            "& .MuiInputBase-input": {
                                              height: "15px",
                                            },
                                          }}
                                          closeOnSelect={false}
                                        />
                                      )}
                                    </LocalizationProvider>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  lg={1}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <button
                                    className={styles.sessionDeleteBtn}
                                    onClick={() =>
                                      handleRemoveTiming(index, timingIndex)
                                    }
                                  >
                                    <img
                                      src={sessionDelete}
                                      alt="sessionDelete"
                                    />
                                  </button>
                                  {/* <DeleteIcon
                                    style={{
                                      fontSize: "25px",
                                      color: "#667085",
                                      cursor: "pointer",
                                      marginBottom: "10px",
                                    }}
                                    onClick={() =>
                                      handleRemoveTiming(index, timingIndex)
                                    }
                                  /> */}
                                </Grid>
                              </Grid>
                            </div>
                          )
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-end",
                          height: "100%",
                          marginTop: "45px",
                        }}
                      >
                        <button
                          className={styles.sessionDeleteBtn}
                          onClick={() => handleAddTiming(index)}
                        >
                          <img src={sessionAdd} alt="sessionAdd" />
                        </button>
                        <button
                          className={styles.sessionDeleteBtn}
                          onClick={(event: any) => {
                            setAnchorEl(anchorEl ? null : event.currentTarget);
                            setCopyDay(batchSchedule[index]?.day);
                            setSelectedDays([]);
                          }}
                        >
                          <img src={sessionCopy} alt="sessionCopy" />
                        </button>
                        {/* <AddIcon
                          style={{
                            fontSize: "25px",
                            color: "#667085",
                            cursor: "pointer",
                          }}
                          onClick={() => handleAddTiming(index)}
                        />
                        <ContentCopyIcon
                          style={{
                            fontSize: "25px",
                            color: "#667085",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                          onClick={(event: any) => {
                            setAnchorEl(anchorEl ? null : event.currentTarget);
                            setCopyDay(batchSchedule[index]?.day);
                            setSelectedDays([]);
                          }}
                        /> */}
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={() => setAnchorEl(null)}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            marginTop: "5px",
                            "& .MuiList-root": {
                              padding: "15px",
                            },
                          }}
                          PaperProps={{
                            sx: {
                              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                              borderRadius: "4PX",
                              border: "1px solid #EEE",
                              minWidth: "108PX",
                              marginRight: "100px",
                            },
                          }}
                        >
                          <div
                            style={{ color: "#667085", marginBottom: "10px" }}
                          >
                            Copy times to
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {batchSchedule.map(
                              (dayItem: any, dayIndex: number) => {
                                return (
                                  <FormControlLabel
                                    value={dayItem?.day}
                                    control={
                                      <Checkbox
                                        disabled={dayItem?.day === copyDay}
                                        checked={selectedDays.includes(
                                          dayItem.day
                                        )}
                                        onChange={() =>
                                          handleDayCheckboxChange(dayItem.day)
                                        }
                                        sx={{
                                          strokeWidth: 1,
                                          color: "#D0D5DD",
                                          "&:hover": {
                                            backgroundColor: "transparent",
                                          },
                                          "&:focus": {
                                            backgroundColor: "transparent",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      dayItem?.day === "MON"
                                        ? "Monday"
                                        : dayItem?.day === "TUE"
                                        ? "Tuesday"
                                        : dayItem?.day === "WED"
                                        ? "Wednesday"
                                        : dayItem?.day === "THU"
                                        ? "Thursday"
                                        : dayItem?.day === "FRI"
                                        ? "Friday"
                                        : dayItem?.day === "SAT"
                                        ? "Saturday"
                                        : "Sunday"
                                    }
                                    labelPlacement="end"
                                  />
                                );
                              }
                            )}
                            <Button
                              variant="outlined"
                              onClick={handleApplyCopy}
                            >
                              Apply
                            </Button>
                          </div>
                        </Menu>
                      </div>
                    </>
                  )}
                </div>
              </Grid>
            </div>
          );
        })}
    </div>
  );
}
