import {
  Box,
  Checkbox,
  FormControlLabel,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  FormGroup,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { CiViewColumn } from "react-icons/ci";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import { useSelector } from "react-redux";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import ShowAllBtn from "../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import TableSkeleton from "../../../../../components/Skeletons/TableSkeleton";
import { useAuthUser } from "react-auth-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import { PuffLoader } from "react-spinners";
import { GetStudentByInstituteIdRequest } from "../service/student.request";
import moment from "moment";
import { setLeadModalState } from "../../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import {
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import OpenSearch from "../../../Nurture/components/OpenSearch/OpenSearch";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0px 12px",
  fontSize: "12px",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "@media (max-width: 600px)": {
    fontSize: "12px",
  },
  "@media only screen and (min-width: 992px)": {
    fontSize: "12px",
  },
  "@media only screen and (min-width: 1200px)": {
    fontSize: "14px",
  },
}));

const CustomTableHeaderCell = styled(TableCell)(({ theme }) => ({
  padding: "16px 12px",
  fontSize: "13px",
  color: "#69696B",
  fontWeight: "500",
}));

interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedCourse {
  id: string;
  courseName: string;
}

interface CheckedBatch {
  id: string;
  batchName: string;
}

interface CheckedPaymentStatus {
  paymentStatus: string;
}

interface CheckedBatchStatus {
  batchStatus: string;
}

interface CheckedPaymentType {
  paymentType: string;
}

const paymentStatusArray = [
  {
    paymentStatus: "Fully Paid",
  },
  {
    paymentStatus: "Partly Paid",
  },
  {
    paymentStatus: "EMI Paid",
  },
  {
    paymentStatus: "EMI Due",
  },
  {
    paymentStatus: "EMI Fully Paid",
  },
  {
    paymentStatus: "Membership Fees Paid",
  },
  {
    paymentStatus: "Membership Fees Due",
  },
  {
    paymentStatus: "Membership Fully Paid",
  },
  {
    paymentStatus: "Membership Paused",
  },
];

export default function StudentListing(props: any) {
  const {
    GetStudentByInstituteId,
    studentDetails,
    studentListLoading,
    GetLeadListByInstituteAndContact,
    GetOpenSearchStudentListing,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch<any>();
  const loginUserBranch = authUser?.institute?.[0]?.branchId;
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const {
    leadListLoading,
    createPaymentSuccess,
    recordPaymentUpdateSuccess,
    leadLostUpdateSuccess,
    leadUpdateSuccess,
    leadEmiDetailsSuccess,
  } = useSelector((state: any) => state.nurture);
  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const batches = useSelector((state: any) => state.batch.batchList);
  const batchList = batches?.filter(
    (item: any) => item?.batchConductType === "REGULAR_BATCH"
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [searchStudent, setSearchStudent] = React.useState<any>("");

  const [columns, setColumns] = useState([
    { id: "hash", label: "#", minWidth: 30, align: "inherit", show: true },
    {
      id: "studentId",
      label: "Student ID",
      minWidth: 140,
      align: "inherit",
      show: true,
    },
    {
      id: "name",
      label: "Student Name",
      minWidth: 170,
      align: "inherit",
      show: true,
    },
    {
      id: "status",
      label: "Batch status",
      minWidth: 30,
      align: "inherit",
      show: false,
    },
    {
      id: "courseName",
      label: "Course Name",
      minWidth: 200,
      align: "inherit",
      show: true,
    },
    {
      id: "batch",
      label: "Batch",
      minWidth: 170,
      align: "left",
      show: true,
    },
    {
      id: "branch",
      label: "Branch",
      minWidth: 180,
      align: "left",
      show: true,
    },
    {
      id: "paymentType",
      label: "Payment Type",
      minWidth: 150,
      align: "left",
      show: false,
    },
    {
      id: "paymentStatus",
      label: "Payment status",
      minWidth: 150,
      align: "left",
      show: true,
    },
    {
      id: "paymentDetails",
      label: "Payment Details",
      minWidth: 150,
      align: "left",
      show: true,
    },
  ]);

  const [studentItems, setStudentItems] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const [searchCourse, setSearchCourse] = useState<any>("");
  const [searchBatch, setSearchBatch] = useState<any>("");
  const [searchBranch, setSearchBranch] = useState<any>("");

  const [showAllCourses, setShowAllCourses] = useState<boolean>(false);
  const [showAllBatch, setShowAllBatch] = useState<boolean>(false);
  const [showAllBranches, setShowAllBranches] = useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);
  const [showAllPaymentStatuses, setShowAllPaymentStatuses] =
    useState<boolean>(false);

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);

  const [checkedCourses, setCheckedCourses] = useState<CheckedCourse[]>([]);
  const [filterCheckedCourses, setFilterCheckedCourses] = useState<
    CheckedCourse[]
  >([]);
  const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);
  const [filterCheckedBranches, setFilterCheckedBranches] = useState<
    CheckedBranch[]
  >([]);

  const [checkedBatches, setCheckedBatches] = useState<CheckedBatch[]>([]);
  const [filterCheckedBatches, setFilterCheckedBatches] = useState<
    CheckedBatch[]
  >([]);

  const [checkedPaymentStatus, setCheckedPaymentStatus] = useState<
    CheckedPaymentStatus[]
  >([]);
  const [filterCheckedPaymentStatus, setFilterCheckedPaymentStatus] = useState<
    CheckedPaymentStatus[]
  >([]);

  const [checkedBatchStatus, setCheckedBatchStatus] = useState<
    CheckedBatchStatus[]
  >([]);
  const [filterCheckedBatchStatus, setFilterCheckedBatchStatus] = useState<
    CheckedBatchStatus[]
  >([]);

  const [checkedPaymentType, setCheckedPaymentType] = useState<
    CheckedPaymentType[]
  >([]);
  const [filterCheckedPaymentType, setFilterCheckedPaymentType] = useState<
    CheckedPaymentType[]
  >([]);

  const handleCourseSearch = (event: any) => {
    setSearchCourse(event.target.value);
  };
  const handleBatchSearch = (event: any) => {
    setSearchBatch(event.target.value);
  };
  const handleBranchSearch = (event: any) => {
    setSearchBranch(event.target.value);
  };

  const filteredCourses =
    courses &&
    courses?.filter((item: any) => {
      const searchTermMatch =
        item.courseName.toLowerCase().indexOf(searchCourse.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedCourses = showAllCourses
    ? courses && filteredCourses
    : courses && filteredCourses.slice(0, 5);

  const handleShowMoreCourses = () => {
    setShowAllCourses(!showAllCourses);
  };

  const filteredBatches =
    batchList &&
    batchList
      ?.filter((item: any) =>
        !hasAllBranchAccess ? item.branchId === loginUserBranch : true
      )
      ?.filter(
        (item: any) =>
          item?.batchConductType === "REGULAR_BATCH" &&
          item?.batchStatus !== "COMPLETED" &&
          item?.batchStatus !== "CANCELLED" &&
          item?.status !== "INACTIVE"
      )
      ?.filter((batch: any) => {
        const searchTermMatch =
          batch.batchName.toLowerCase().indexOf(searchBatch.toLowerCase()) !==
          -1;

        return searchTermMatch;
      });

  const displayedBatches = showAllBatch
    ? batchList && filteredBatches
    : batchList && filteredBatches.slice(0, 5);

  const handleShowMoreBatches = () => {
    setShowAllBatch(!showAllBatch);
  };

  const filteredBranches =
    branches &&
    branches?.filter((branch: any) => {
      const searchTermMatch =
        branch.branchName.toLowerCase().indexOf(searchBranch.toLowerCase()) !==
        -1;

      return searchTermMatch;
    });

  const displayedBranch = showAllBranches
    ? branches && filteredBranches
    : branches && filteredBranches.slice(0, 5);

  const handleShowMoreBranches = () => {
    setShowAllBranches(!showAllBranches);
  };

  const displayedPaymentStatuses = showAllPaymentStatuses
    ? paymentStatusArray && paymentStatusArray
    : paymentStatusArray && paymentStatusArray.slice(0, 5);

  const handleShowMorePaymentStatuses = () => {
    setShowAllPaymentStatuses(!showAllPaymentStatuses);
  };

  const toggleColumn = (changeEvent: any) => {
    const { name } = changeEvent.target;
    const NewColumns = columns.map((column: any) => {
      if (column.label === name) {
        column.show = !column.show;
        return column;
      } else {
        return column;
      }
    });
    setColumns(NewColumns);
  };

  const handleFilterCourseChecked = (id: string, courseName: string) => {
    const courseToAdd: CheckedCourse = { id, courseName };
    if (checkedCourses.some((item) => item.id === id)) {
      setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
    } else {
      setCheckedCourses([...checkedCourses, courseToAdd]);
    }
  };

  const clearCheckedCourse = (id: string) => {
    setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
  };

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    const branchToAdd: CheckedBranch = { id, branchName };
    if (checkedBranches.some((branch) => branch.id === id)) {
      setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBranches([...checkedBranches, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
  };

  const handleFilterBatchChecked = (id: string, batchName: string) => {
    const batchToAdd: CheckedBatch = { id, batchName };
    if (checkedBatches.some((branch) => branch.id === id)) {
      setCheckedBatches(checkedBatches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBatches([...checkedBatches, batchToAdd]);
    }
  };

  const clearCheckedBatch = (id: string) => {
    setCheckedBatches(checkedBatches.filter((item) => item.id !== id));
  };

  const handleFilterPaymentStatusChecked = (paymentStatus: string) => {
    const paymentStatusToAdd: CheckedPaymentStatus = { paymentStatus };

    if (
      checkedPaymentStatus.some(
        (item) =>
          item.paymentStatus.toLowerCase() === paymentStatus.toLowerCase()
      )
    ) {
      setCheckedPaymentStatus(
        checkedPaymentStatus.filter(
          (item) =>
            item.paymentStatus.toLowerCase() !== paymentStatus.toLowerCase()
        )
      );
    } else {
      setCheckedPaymentStatus([...checkedPaymentStatus, paymentStatusToAdd]);
    }
  };

  const clearCheckedPaymentStatus = (paymentStatus: string) => {
    setCheckedPaymentStatus(
      checkedPaymentStatus.filter(
        (item) => item.paymentStatus !== paymentStatus
      )
    );
  };

  const handleFilterPaymentTypeChecked = (paymentType: string) => {
    const paymentTypeToAdd: CheckedPaymentType = { paymentType };

    if (
      checkedPaymentType.some(
        (item) =>
          item.paymentType.toLowerCase().replace(/_/g, " ") ===
          paymentType.toLowerCase().replace(/_/g, " ")
      )
    ) {
      setCheckedPaymentType(
        checkedPaymentType.filter(
          (item) =>
            item.paymentType.toLowerCase().replace(/_/g, " ") !==
            paymentType.toLowerCase().replace(/_/g, " ")
        )
      );
    } else {
      setCheckedPaymentType([...checkedPaymentType, paymentTypeToAdd]);
    }
  };

  const clearCheckedPaymentType = (paymentType: string) => {
    setCheckedPaymentType(
      checkedPaymentType.filter((item) => item.paymentType !== paymentType)
    );
  };

  const handleFilterBatchStatusChecked = (batchStatus: string) => {
    const batchStatusToAdd: CheckedBatchStatus = { batchStatus };

    if (
      checkedBatchStatus.some(
        (item) => item.batchStatus.toLowerCase() === batchStatus.toLowerCase()
      )
    ) {
      setCheckedBatchStatus(
        checkedBatchStatus.filter(
          (item) => item.batchStatus.toLowerCase() !== batchStatus.toLowerCase()
        )
      );
    } else {
      setCheckedBatchStatus([...checkedBatchStatus, batchStatusToAdd]);
    }
  };

  const clearCheckedBatchStatus = (batchStatus: string) => {
    setCheckedBatchStatus(
      checkedBatchStatus.filter((item) => item.batchStatus !== batchStatus)
    );
  };

  const filteredStudentList =
    studentDetails && studentDetails?.returnData
      ? studentItems?.returnData?.filter((student: any) => {
          const searchTermLower = searchStudent.toLowerCase();
          const nameMatch = student?.firstName
            .toLowerCase()
            .includes(searchTermLower);
          const emailMatch = student?.email
            .toLowerCase()
            .includes(searchTermLower);
          const phoneMatch =
            searchTermLower === "" ||
            (student?.contactNumber &&
              student?.contactNumber?.length > 0 &&
              student?.contactNumber?.includes(searchTermLower));

          const batchStatusIsChecked =
            filterCheckedBatchStatus.length === 0 ||
            filterCheckedBatchStatus.some(
              (item) =>
                item.batchStatus.toLowerCase() ===
                student.batchStatus.toLowerCase()
            );

          const paymentStatusIsChecked =
            filterCheckedPaymentStatus.length === 0 ||
            filterCheckedPaymentStatus.some(
              (item) =>
                item.paymentStatus.toLowerCase() ===
                student?.mappingData[0]?.paymentStatus.toLowerCase()
            );

          const courseIsChecked =
            filterCheckedCourses.length === 0 ||
            filterCheckedCourses.some(
              (item) => item.id === student.mappingData[0].courseId
            );

          const branchIsChecked =
            filterCheckedBranches.length === 0 ||
            filterCheckedBranches.some(
              (item) => item.id === student.mappingData[0].branchId
            );

          const batchIsChecked =
            filterCheckedBatches.length === 0 ||
            filterCheckedBatches.some(
              (item) => item.id === student.mappingData[0].batchId
            );

          const paymentTypeIsChecked =
            filterCheckedPaymentType.length === 0 ||
            filterCheckedPaymentType.some(
              (item) =>
                item.paymentType.toLowerCase().replace(/_/g, " ") ===
                student.mappingData[0].paymentType
                  .toLowerCase()
                  .replace(/_/g, " ")
            );

          return (
            (nameMatch || emailMatch || phoneMatch) &&
            batchStatusIsChecked &&
            paymentStatusIsChecked &&
            courseIsChecked &&
            branchIsChecked &&
            batchIsChecked &&
            paymentTypeIsChecked
          );
        })
      : [];

  const studentListApiCall = useCallback(
    (
      batchStatus: any,
      paymentType: any,
      paymentStatus: any,
      courses: any,
      branches: any,
      batches: any
    ) => {
      const userFromLocalStorage = authUser?.institute?.[0]?.instituteDetails;

      const branchId = hasAllBranchAccess
        ? "All"
        : authUser?.institute?.[0]?.branchId;

      const assigneeId =
        userById?.roleCode === "COURSE_COUNSELOR"
          ? authUser?.institute?.[0]?.id
          : "";

      const body = {
        entityName: "BATCHSTUDENTMAPPING",
        instituteId: userFromLocalStorage?.id,
        isFilter: true,
        searchText: "",
        enquiryAssignedToId: assigneeId,
        batchStatus:
          batchStatus?.length > 0
            ? batchStatus.map((item: any) => item?.batchStatus?.toUpperCase())
            : [],
        paymentType:
          paymentType?.length > 0
            ? paymentType.map((item: any) => item?.paymentType?.toUpperCase())
            : [],
        paymentStatus:
          paymentStatus?.length > 0
            ? paymentStatus.map((item: any) => item?.paymentStatus)
            : [],
        courseId:
          courses?.length > 0 ? courses.map((item: any) => item?.id) : [],
        branchId:
          branchId === "All"
            ? branches?.length > 0
              ? branches.map((item: any) => item?.id)
              : []
            : [branchId],
        batchId:
          batches?.length > 0 ? batches.map((item: any) => item?.id) : [],
      };

      if (userFromLocalStorage) {
        GetOpenSearchStudentListing(body);
      }
    },
    [authUser, userById, hasAllBranchAccess]
  );

  const handleFilterStudents = React.useCallback(
    (
      batchStatusArray: any,
      paymentStatusArray: any,
      branchArray: any,
      courseArray: any,
      batchArray: any,
      paymentTypeArray: any
    ) => {
      setFilterCheckedCourses(courseArray);
      setFilterCheckedBranches(branchArray);
      setFilterCheckedBatches(batchArray);
      setFilterCheckedPaymentStatus(paymentStatusArray);
      setFilterCheckedBatchStatus(batchStatusArray);
      setFilterCheckedPaymentType(paymentTypeArray);
      studentListApiCall(
        batchStatusArray,
        paymentTypeArray,
        paymentStatusArray,
        courseArray,
        branchArray,
        batchArray
      );
      const queryParams = new URLSearchParams(window.location.search);

      if (branchArray && branchArray?.length > 0) {
        const branchParam = branchArray
          ?.map((branch: any) => branch.id)
          .join("|");
        queryParams.set("filteredBranches", branchParam);
      } else {
        queryParams.delete("filteredBranches");
      }

      if (batchArray && batchArray?.length > 0) {
        const batchParam = batchArray?.map((item: any) => item.id).join("|");
        queryParams.set("filteredBatches", batchParam);
      } else {
        queryParams.delete("filteredBatches");
      }
      if (courseArray && courseArray?.length > 0) {
        const courseParam = courseArray?.map((item: any) => item.id).join("|");
        queryParams.set("filteredCourses", courseParam);
      } else {
        queryParams.delete("filteredCourses");
      }
      if (batchStatusArray && batchStatusArray?.length > 0) {
        const batchStatusParam = batchStatusArray
          ?.map((item: any) => item.batchStatus)
          .join("|");
        queryParams.set("filteredBatchStatus", batchStatusParam);
      } else {
        queryParams.delete("filteredBatchStatus");
      }

      if (paymentStatusArray && paymentStatusArray?.length > 0) {
        const paymentStatusParam = paymentStatusArray
          ?.map((item: any) => item.paymentStatus)
          .join("|");
        queryParams.set("filteredPaymentStatus", paymentStatusParam);
      } else {
        queryParams.delete("filteredPaymentStatus");
      }

      if (paymentTypeArray && paymentTypeArray?.length > 0) {
        const paymentTypeParam = paymentTypeArray
          ?.map((item: any) => item.paymentType?.toUpperCase())
          .join("|");
        queryParams.set("filteredPaymentType", paymentTypeParam);
      } else {
        queryParams.delete("filteredPaymentType");
      }
      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },
    [
      setFilterCheckedCourses,
      setFilterCheckedBranches,
      setFilterCheckedBatches,
      setFilterCheckedPaymentStatus,
      setFilterCheckedBatchStatus,
      setFilterCheckedPaymentType,
    ]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterStudents(
      checkedBatchStatus,
      checkedPaymentStatus,
      checkedBranches,
      checkedCourses,
      checkedBatches,
      checkedPaymentType
    );

    setFilterApplied(
      checkedBatchStatus?.length === 0 &&
        checkedPaymentStatus?.length === 0 &&
        checkedBranches?.length === 0 &&
        checkedCourses?.length === 0 &&
        checkedBatches?.length === 0 &&
        checkedPaymentType?.length === 0
        ? false
        : true
    );
  }, [
    handleFilterStudents,
    setFilterApplied,
    checkedPaymentStatus,
    checkedBranches,
    checkedCourses,
    checkedBatches,
    checkedBatchStatus,
    checkedPaymentType,
  ]);

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setCheckedBranches([]);
    setCheckedCourses([]);
    setCheckedBatches([]);
    setCheckedBatchStatus([]);
    setCheckedPaymentStatus([]);
    setCheckedPaymentType([]);
    handleFilterStudents([], [], [], [], [], []);
    setFilterCheckedBranches([]);
    setFilterCheckedCourses([]);
    setFilterCheckedBatchStatus([]);
    setFilterCheckedPaymentStatus([]);
    setFilterCheckedBatches([]);
    setFilterCheckedPaymentType([]);
  }, [
    setFilterApplied,
    setCheckedBranches,
    setCheckedCourses,
    setCheckedBatches,
    setCheckedBatchStatus,
    setCheckedPaymentStatus,
    setCheckedPaymentType,
    setCheckedCourses,
    setFilterCheckedBranches,
    setFilterCheckedCourses,
    setFilterCheckedBatchStatus,
    setFilterCheckedPaymentStatus,
    setFilterCheckedBatches,
    setFilterCheckedPaymentType,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedBranches(filterCheckedBranches);
    setCheckedBatchStatus(filterCheckedBatchStatus);
    setCheckedCourses(filterCheckedCourses);
    setCheckedPaymentStatus(filterCheckedPaymentStatus);
    setCheckedPaymentType(filterCheckedPaymentType);
    setCheckedBatches(filterCheckedBatches);
  }, [
    filterCheckedBranches,
    filterCheckedCourses,
    filterCheckedPaymentType,
    filterCheckedBatchStatus,
    filterCheckedPaymentStatus,
    filterCheckedBatches,
  ]);

  const filteredValue = (
    <>
      {filterCheckedBranches?.length > 0 &&
        filterCheckedBranches?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.branchName}
          </div>
        ))}
      {filterCheckedCourses?.length > 0 &&
        filterCheckedCourses?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.courseName}
          </div>
        ))}
      {filterCheckedBatches?.length > 0 &&
        filterCheckedBatches?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.batchName}
          </div>
        ))}
      {filterCheckedBatchStatus?.length > 0 &&
        filterCheckedBatchStatus?.map((item) => (
          <div key={item.batchStatus} className="filteredDiv">
            {item.batchStatus.toLowerCase()}
          </div>
        ))}
      {filterCheckedPaymentStatus?.length > 0 &&
        filterCheckedPaymentStatus?.map((item) => (
          <div key={item.paymentStatus} className="filteredDiv">
            {item.paymentStatus}
          </div>
        ))}
      {filterCheckedPaymentType?.length > 0 &&
        filterCheckedPaymentType?.map((item) => (
          <div key={item.paymentType} className="filteredDiv">
            {item.paymentType}
          </div>
        ))}
    </>
  );

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          GetLeadListByInstituteAndContact(data);
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          GetLeadListByInstituteAndContact(data);
        }
      }
    },
    [GetLeadListByInstituteAndContact]
  );

  const openLeadExpandModal = useCallback(
    (instituteId: string, phone: string, email: string, id: string) => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("leadModal", "true");
      queryParams.set("leadInstituteId", instituteId);
      queryParams.set("leadId", id);
      if (email) {
        queryParams.set("leadEmail", email);
      } else {
        queryParams.delete("leadEmail");
      }
      if (phone) {
        queryParams.set("leadPhone", phone);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.set("tabOpen", "overview");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      dispatch(setLeadModalState());
      if (instituteId && (phone || email)) {
        leadPopupApiCall(instituteId, phone, email);
      }
    },
    []
  );

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      studentItems?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      studentItems?.lastEvaluatedKeyReceived?.GSI3SK?.S
    );
    const id = userFromLocalStorage.instituteId;

    const branchId = hasAllBranchAccess
      ? "All"
      : authUser?.institute?.[0]?.branchId;
    const assigneeId =
      userById?.roleCode === "COURSE_COUNSELOR"
        ? authUser?.institute?.[0]?.id
        : "";

    const endOfView = window.scrollY + window.innerHeight;
    if (lastEvaluatedKey && endOfView) {
      GetStudentByInstituteIdRequest(
        id,
        lastEvaluatedKey,
        15,
        lastEvaluatedSortKey,
        branchId,
        assigneeId
      )
        ?.then((res: any) => {
          setHasMoreData(true);
          setStudentItems((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const branchParam = urlSearchParams.get("filteredBranches");
    const batchParam = urlSearchParams.get("filteredBatches");
    const courseParam = urlSearchParams.get("filteredCourses");
    const paymentTypeParam = urlSearchParams.get("filteredPaymentType");
    const paymentStatusParam = urlSearchParams.get("filteredPaymentStatus");
    const batchStatusParam = urlSearchParams.get("filteredBatchStatus");

    const batchStatusInUrl = batchStatusParam
      ? batchStatusParam.split("|").map((item: string) => ({
          batchStatus: item,
        }))
      : [];
    const paymentTypeInUrl = paymentTypeParam
      ? paymentTypeParam.split("|").map((item: string) => ({
          paymentType: item,
        }))
      : [];
    const paymentStatusInUrl = paymentStatusParam
      ? paymentStatusParam.split("|").map((item: string) => ({
          paymentStatus: item,
        }))
      : [];
    const courseIdInUrl = courseParam
      ? courseParam.split("|").map((item: string) => ({
          id: item,
        }))
      : [];
    const branchIdInUrl = branchParam
      ? branchParam.split("|").map((item: string) => ({
          id: item,
        }))
      : [];
    const batchIdInUrl = batchParam
      ? batchParam.split("|").map((item: string) => ({ id: item }))
      : [];

    if (branchParam && branches) {
      const branchIds = branchParam.split("|");

      const selectedBranches = branchIds
        .map((id) => {
          const branch = branches?.find((branch: any) => branch.id === id);
          return branch ? { id, branchName: branch.branchName } : null;
        })
        .filter(Boolean) as CheckedBranch[];

      setFilterCheckedBranches(selectedBranches);
      setCheckedBranches(selectedBranches);
    }

    if (batchParam && batchList) {
      const batchIds = batchParam.split("|");

      const selectedBatches = batchIds
        .map((id) => {
          const batch = batchList?.find((batch: any) => batch.id === id);
          return batch ? { id, batchName: batch.batchName } : null;
        })
        .filter(Boolean) as CheckedBatch[];

      setFilterCheckedBatches(selectedBatches);
      setCheckedBatches(selectedBatches);
    }

    if (courseParam && courses) {
      const courseIds = courseParam.split("|");

      const selectedCourses = courseIds
        .map((id) => {
          const course = courses?.find((course: any) => course.id === id);
          return course ? { id, courseName: course.courseName } : null;
        })
        .filter(Boolean) as CheckedCourse[];

      setFilterCheckedCourses(selectedCourses);
      setCheckedCourses(selectedCourses);
    }

    if (paymentTypeParam) {
      const paymentTypes = paymentTypeParam.split("|");

      const selectedPaymentTypes = paymentTypes
        .map((value) => {
          const paymentTypes = [
            { paymentType: "ACTIVE" },
            { paymentType: "LOST" },
          ].find(
            (type: any) =>
              type.paymentType.toLowerCase() === value.toLowerCase()
          );
          return paymentTypes
            ? {
                paymentType: paymentTypes.paymentType,
              }
            : null;
        })
        .filter(Boolean) as CheckedPaymentType[];

      setFilterCheckedPaymentType(selectedPaymentTypes);
      setCheckedPaymentType(selectedPaymentTypes);
    }

    if (batchStatusParam) {
      const batchStatus = batchStatusParam.split("|");

      const selectedBatchStatuses = batchStatus
        .map((value) => {
          const batchStatuses = [
            { batchStatus: "ONGOING" },
            { batchStatus: "UPCOMING" },
            { batchStatus: "CANCELLED" },
            { batchStatus: "COMPLETED" },
          ].find(
            (type: any) =>
              type.batchStatus.toLowerCase() === value.toLowerCase()
          );
          return batchStatuses
            ? {
                batchStatus: batchStatuses.batchStatus,
              }
            : null;
        })
        .filter(Boolean) as CheckedBatchStatus[];

      setFilterCheckedBatchStatus(selectedBatchStatuses);
      setCheckedBatchStatus(selectedBatchStatuses);
    }

    if (paymentStatusParam) {
      const paymentStatus = paymentStatusParam.split("|");

      const selectedPaymentStatuses = paymentStatus
        .map((value) => {
          const foundStatus = paymentStatusArray.find(
            (type) => type.paymentStatus === value
          );
          return foundStatus
            ? { paymentStatus: foundStatus.paymentStatus }
            : null;
        })
        .filter(Boolean) as CheckedPaymentStatus[];

      setFilterCheckedPaymentStatus(selectedPaymentStatuses);
      setCheckedPaymentStatus(selectedPaymentStatuses);
    }

    studentListApiCall(
      batchStatusInUrl,
      paymentTypeInUrl,
      paymentStatusInUrl,
      courseIdInUrl,
      branchIdInUrl,
      batchIdInUrl
    );
    if (
      branchParam ||
      batchParam ||
      courseParam ||
      paymentTypeParam ||
      batchStatusParam ||
      paymentStatusParam
    ) {
      setFilterApplied(true);
    }
    // if (userFromLocalStorage) {
    //   const userFromLocalStorage = authUser?.institute?.[0]?.instituteDetails;

    //   if (userFromLocalStorage) {
    //     GetStudentByInstituteId(
    //       userFromLocalStorage?.id,
    //       lastEvaluatedKey,
    //       limit,
    //       lastEvaluatedSortKey,
    //       branchId,
    //       assigneeId
    //     );
    //   }
    // }
  }, []);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const modalDisplay = urlSearchParams.get("leadModal");
    const leadInstituteId = urlSearchParams.get("leadInstituteId");
    const leadPhone = urlSearchParams.get("leadPhone");
    const leadEmail = urlSearchParams.get("leadEmail");

    if (modalDisplay === "true" && leadListLoading === false) {
      if (leadInstituteId) {
        if (leadPhone || leadEmail) {
          leadPopupApiCall(leadInstituteId, leadPhone, leadEmail);
        }
      }
    }
  }, []);

  React.useEffect(() => {
    setStudentItems(studentDetails);
  }, [studentDetails]);

  useEffect(() => {
    if (
      studentItems &&
      studentItems?.lastEvaluatedKeyReceived &&
      studentItems?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      if (filterApplied === true || searchStudent !== "") {
        if (filteredStudentList?.length >= 15) {
          setHasMoreData(true);
        } else {
          setHasMoreData(false);
        }
      } else {
        setHasMoreData(true);
      }
    } else {
      setHasMoreData(false);
    }
  }, [studentItems, filteredStudentList, searchStudent, filterApplied]);

  React.useEffect(() => {
    if (
      (createPaymentSuccess !== true && createPaymentSuccess !== false) ||
      (recordPaymentUpdateSuccess !== true &&
        recordPaymentUpdateSuccess !== false) ||
      (leadLostUpdateSuccess !== true && leadLostUpdateSuccess !== false) ||
      (leadUpdateSuccess !== true && leadUpdateSuccess !== false) ||
      (leadEmiDetailsSuccess !== true && leadEmiDetailsSuccess !== false)
    ) {
      // const userFromLocalStorage = JSON.parse(
      //   localStorage.getItem("ACTIVE_INSTITUTE") || "{}"
      // );

      // if (userFromLocalStorage) {
      //   const lastEvaluatedKey = "";
      //   const limit = 15;
      //   const lastEvaluatedSortKey = "";
      //   const instituteDetails = authUser?.institute?.[0]?.instituteDetails;

      //   const branchId = hasAllBranchAccess
      //     ? "All"
      //     : authUser?.institute?.[0]?.branchId;
      //   const assigneeId =
      //     userById?.roleCode === "COURSE_COUNSELOR"
      //       ? authUser?.institute?.[0]?.id
      //       : "";
      //   if (instituteDetails) {
      //     GetStudentByInstituteId(
      //       instituteDetails.id,
      //       lastEvaluatedKey,
      //       limit,
      //       lastEvaluatedSortKey,
      //       branchId,
      //       assigneeId
      //     );
      //   }
      // }

      const urlSearchParams = new URLSearchParams(window.location.search);
      const branchParam = urlSearchParams.get("filteredBranches");
      const batchParam = urlSearchParams.get("filteredBatches");
      const courseParam = urlSearchParams.get("filteredCourses");
      const paymentTypeParam = urlSearchParams.get("filteredPaymentType");
      const paymentStatusParam = urlSearchParams.get("filteredPaymentStatus");
      const batchStatusParam = urlSearchParams.get("filteredBatchStatus");

      const batchStatusInUrl = batchStatusParam
        ? batchStatusParam.split("|").map((item: string) => ({
            batchStatus: item,
          }))
        : [];
      const paymentTypeInUrl = paymentTypeParam
        ? paymentTypeParam.split("|").map((item: string) => ({
            paymentType: item,
          }))
        : [];
      const paymentStatusInUrl = paymentStatusParam
        ? paymentStatusParam.split("|").map((item: string) => ({
            paymentStatus: item,
          }))
        : [];
      const courseIdInUrl = courseParam
        ? courseParam.split("|").map((item: string) => ({
            id: item,
          }))
        : [];
      const branchIdInUrl = branchParam
        ? branchParam.split("|").map((item: string) => ({
            id: item,
          }))
        : [];
      const batchIdInUrl = batchParam
        ? batchParam.split("|").map((item: string) => ({ id: item }))
        : [];

      studentListApiCall(
        batchStatusInUrl,
        paymentTypeInUrl,
        paymentStatusInUrl,
        courseIdInUrl,
        branchIdInUrl,
        batchIdInUrl
      );
    }
  }, [
    createPaymentSuccess,
    recordPaymentUpdateSuccess,
    leadUpdateSuccess,
    leadLostUpdateSuccess,
    leadEmiDetailsSuccess,
  ]);

  const allBranches = useSelector((state: any) => state.branch);
  console.log('AllBranches', allBranches);

const handleSelectCurrency = (id: string) => {
  if (id !== '') {
    const currency = allBranches?.branchList
      .filter((branch: any) => branch.id == id)
      .map((item: any) => item.currency);
    return currency[0] ? currency[0] : '₹';
  } else return '₹';
};


  return (
    <div
      style={{
        padding: "14px 16px",
        overflowY: "hidden",
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        position: "relative",
        background: "rgb(245, 245, 245)",
      }}
    >
      <div
        style={{
          background: "#fff",
          padding: "22px 30px 22px 30px",
          width: "100%",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          overflowY: "auto",
          minHeight: "calc(100vh - 90px)",
          maxHeight: "calc(100vh - 90px)",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
          }}
        >
          {studentDetails == null ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="150px"
              height={38}
              style={{
                borderRadius: "2px",
                marginBottom: "16px",
                marginTop: "16px",
              }}
            />
          ) : (
            <>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    margin: "0px",
                    color: "#142C44",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  Students
                </p>
              </Box>
            </>
          )}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            {studentDetails == null ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="250px"
                height={38}
                style={{
                  borderRadius: "2px",
                  marginRight: "10px",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <OpenSearch fromNurture={false} />
                </div>
              </>
            )}

            {studentDetails == null ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="50px"
                height={38}
                style={{
                  borderRadius: "2px",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              />
            ) : (
              <>
                <Box sx={{ width: "fit-content" }}>
                  <button
                    style={{
                      padding: "8px 12px",
                      backgroundColor: anchorEl ? "#ECF7F4" : "transparent",
                      border: anchorEl
                        ? "1px solid #68DBBF"
                        : "1px solid #D0D5DD",
                      borderRadius: "4px",
                      margin: "0px 14px",
                      cursor: "pointer",
                    }}
                    id={id}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CiViewColumn
                        size={22}
                        color={anchorEl ? "#11C89B" : "#667085"}
                      />
                    </div>
                  </button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{ display: "flex", boxShadow: "none" }}
                    PaperProps={{
                      sx: {
                        boxShadow:
                          "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
                        border: "1px solid #F2F4F7",
                        borderRadius: "8px",
                        minWidth: "200px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {columns
                        .slice(2, columns.length)
                        .filter((item: any) => item.label !== "Student Name")
                        .map((ele) => (
                          <FormControlLabel
                            onChange={toggleColumn}
                            value={ele.show}
                            control={
                              <Checkbox
                                checked={ele.show}
                                sx={{
                                  strokeWidth: 1,
                                  color: "#D0D5DD",
                                  "&:hover": {
                                    backgroundColor: "transparent",
                                  },
                                  "&:focus": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                              />
                            }
                            label={ele.label}
                            name={ele.label}
                            disabled={ele.label === "Student Name"}
                            sx={{
                              fontSize: "13px",
                              color: "#667085",
                            }}
                          />
                        ))}
                    </Box>
                  </Popover>
                </Box>

                <FilterDrawer
                  handleFilterApply={handleFilterApply}
                  handleResetClick={handleResetClick}
                  activeFilter={filterApplied}
                  cancelClick={cancelClick}
                  closeSetFilter={handleFilterClose}
                >
                  <div
                    style={{
                      padding: "22px 32px",
                      overflowY: "auto",
                      maxHeight: "calc(100% - 110px)",
                    }}
                  >
                    <FilterChipsWrap>
                      {checkedBatchStatus.length > 0 &&
                        checkedBatchStatus.map((item) => (
                          <FilterChips
                            keyVal={item.batchStatus}
                            chipName={item.batchStatus.toLowerCase()}
                            clearValue={item.batchStatus}
                            closeFunction={clearCheckedBatchStatus}
                          />
                        ))}
                      {checkedPaymentStatus.length > 0 &&
                        checkedPaymentStatus.map((item) => (
                          <FilterChips
                            keyVal={item.paymentStatus}
                            chipName={item.paymentStatus}
                            clearValue={item.paymentStatus}
                            closeFunction={clearCheckedPaymentStatus}
                          />
                        ))}

                      {checkedPaymentType.length > 0 &&
                        checkedPaymentType.map((item) => (
                          <FilterChips
                            keyVal={item.paymentType}
                            chipName={item.paymentType}
                            clearValue={item.paymentType}
                            closeFunction={clearCheckedPaymentType}
                          />
                        ))}

                      {checkedBranches.length > 0 &&
                        checkedBranches.map((item) => (
                          <FilterChips
                            keyVal={item.id}
                            chipName={item.branchName}
                            clearValue={item.id}
                            closeFunction={clearCheckedBranch}
                          />
                        ))}

                      {checkedCourses.length > 0 &&
                        checkedCourses.map((item) => (
                          <FilterChips
                            keyVal={item.id}
                            chipName={item.courseName}
                            clearValue={item.id}
                            closeFunction={clearCheckedCourse}
                          />
                        ))}
                      {checkedBatches.length > 0 &&
                        checkedBatches.map((item) => (
                          <FilterChips
                            keyVal={item.id}
                            chipName={item.batchName}
                            clearValue={item.id}
                            closeFunction={clearCheckedBatch}
                          />
                        ))}
                    </FilterChipsWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Batch Status" />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {[
                            { batchStatus: "ONGOING" },
                            { batchStatus: "UPCOMING" },
                            { batchStatus: "CANCELLED" },
                            { batchStatus: "COMPLETED" },
                          ].map((item: any) => (
                            <FilterCheckbox
                              keyVal={item.batchStatus}
                              checked={checkedBatchStatus.some(
                                (data) =>
                                  data.batchStatus.toLowerCase() ===
                                  item.batchStatus.toLowerCase()
                              )}
                              checkboxFunction={() =>
                                handleFilterBatchStatusChecked(item.batchStatus)
                              }
                              label={item.batchStatus.toLowerCase()}
                            />
                          ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                    </FilterTypeWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Payment Type" />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {[
                            { paymentType: "Regular" },
                            { paymentType: "Membership" },
                            { paymentType: "Session" },
                          ].map((item: any) => (
                            <FilterCheckbox
                              keyVal={item.paymentType}
                              checked={checkedPaymentType.some(
                                (data) =>
                                  data.paymentType
                                    .toLowerCase()
                                    .replace(/_/g, " ") ===
                                  item.paymentType
                                    .toLowerCase()
                                    .replace(/_/g, " ")
                              )}
                              checkboxFunction={() =>
                                handleFilterPaymentTypeChecked(item.paymentType)
                              }
                              label={item.paymentType
                                .toLowerCase()
                                .replace(/_/g, " ")}
                            />
                          ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                    </FilterTypeWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Payment Status" />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {displayedPaymentStatuses?.map((item: any) => (
                            <FilterCheckbox
                              keyVal={item?.paymentStatus}
                              checked={checkedPaymentStatus.some(
                                (data) =>
                                  data?.paymentStatus?.toLowerCase() ===
                                  item?.paymentStatus?.toLowerCase()
                              )}
                              checkboxFunction={() =>
                                handleFilterPaymentStatusChecked(
                                  item?.paymentStatus
                                )
                              }
                              label={item?.paymentStatus?.toLowerCase()}
                            />
                          ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                      <ShowAllBtn
                        clickFunction={handleShowMorePaymentStatuses}
                        showAll={showAllPaymentStatuses}
                        filteredState={paymentStatusArray}
                      />
                    </FilterTypeWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Course" />
                      <FilterSearchField
                        value={searchCourse}
                        onChange={handleCourseSearch}
                        placeholder="Search Course"
                      />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {courses &&
                            displayedCourses?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.id}
                                checked={checkedCourses.some(
                                  (data) => data.id === item.id
                                )}
                                checkboxFunction={() =>
                                  handleFilterCourseChecked(
                                    item.id,
                                    item.courseName
                                  )
                                }
                                label={item.courseName.toLowerCase()}
                              />
                            ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                      <ShowAllBtn
                        clickFunction={handleShowMoreCourses}
                        showAll={showAllCourses}
                        filteredState={courses}
                      />
                    </FilterTypeWrap>

                    <FilterTypeWrap>
                      <FilterLabel labelValue="Batch" />
                      <FilterSearchField
                        value={searchBatch}
                        onChange={handleBatchSearch}
                        placeholder="Search Batch"
                      />
                      <FilterCheckBoxWrap>
                        <FormGroup>
                          {batchList &&
                            displayedBatches?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item.id}
                                checked={checkedBatches.some(
                                  (data) => data.id === item.id
                                )}
                                checkboxFunction={() =>
                                  handleFilterBatchChecked(
                                    item.id,
                                    item.batchName
                                  )
                                }
                                label={item.batchName.toLowerCase()}
                              />
                            ))}
                        </FormGroup>
                      </FilterCheckBoxWrap>
                      <ShowAllBtn
                        clickFunction={handleShowMoreBatches}
                        showAll={showAllBatch}
                        filteredState={filteredBatches}
                      />
                    </FilterTypeWrap>

                    {hasAllBranchAccess && (
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Branch" />

                        <FilterSearchField
                          value={searchBranch}
                          onChange={handleBranchSearch}
                          placeholder="Search Branch"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {branches &&
                              displayedBranch?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedBranches.some(
                                    (data) => data.id === item.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBranchChecked(
                                      item.id,
                                      item.branchName
                                    )
                                  }
                                  label={item.branchName.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreBranches}
                          showAll={showAllBranches}
                          filteredState={filteredBranches}
                        />
                      </FilterTypeWrap>
                    )}
                  </div>
                </FilterDrawer>
              </>
            )}
          </Box>
        </Box>

        <div
          style={{
            minHeight: "calc(100vh - 210px)",
            overflowY: "hidden",
          }}
        >
          {studentListLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <div
                style={{
                  border: "1px solid #EAECF0",
                  borderRadius: "8px",
                  minHeight: "calc(100vh - 210px)",
                  position: "relative",
                  overflowY: "hidden",
                  marginTop: "5px",
                }}
              >
                {studentItems?.length > 0 ? (
                  <TableContainer
                    sx={{
                      maxHeight: "calc(100vh - 215px)",
                      minHeight: "calc(100vh - 215px)",
                      overflowY: "auto",
                    }}
                  >
                    {/* <InfiniteScroll
                      dataLength={
                        studentItems?.returnData?.length
                          ? studentItems?.returnData?.length
                          : studentDetails?.returnData?.length
                      }
                      next={handleNextPage}
                      hasMore={hasMoreData}
                      loader={
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <PuffLoader color="#36d7b7" />
                        </Box>
                      }
                      height={"calc(100vh - 225px)"}
                      style={{ scrollBehavior: "smooth" }}
                    > */}
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow sx={{ height: "49px" }}>
                          {columns.map((column: any) => {
                            return (
                              <>
                                {column.show && (
                                  <CustomTableHeaderCell
                                    key={column.id}
                                    align={column?.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{
                                      background: "#F9FAFB",
                                      color: "#667085",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {column.label}
                                  </CustomTableHeaderCell>
                                )}
                              </>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentDetails?.length > 0 &&
                          studentItems?.map((student: any, index: any) => {
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                                sx={{
                                  padding: "0px",
                                  height: "72px",
                                  cursor: "pointer",
                                }}
                                className="student_table_body"
                                onClick={() =>
                                  hasLeadCardPermission
                                    ? openLeadExpandModal(
                                        student?._source?.instituteId,
                                        student?._source?.contactNumber,
                                        student?._source?.email,
                                        student?.inner_hits?.batchstudentmapping
                                          ?.hits?.hits?.[0]?._source?.enquiryId
                                      )
                                    : dispatch(
                                        setToastNotification({
                                          message: String(
                                            "You do not have permission to access the lead card"
                                          ),
                                          type: "error",
                                          snackOpen: true,
                                        })
                                      )
                                }
                              >
                                <CustomTableCell>{index + 1}</CustomTableCell>
                                <CustomTableCell
                                  style={{
                                    color: "#667085",
                                    margin: "0px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {student?._source?.studentId}
                                </CustomTableCell>
                                {columns[2].show && (
                                  <CustomTableCell sx={{}}>
                                    <div>
                                      <p
                                        style={{
                                          color: "#101828",
                                          margin: "0px",
                                          fontSize: "12px",
                                          fontWeight: "400",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {student?._source?.firstName}{" "}
                                        {student?._source?.lastName}
                                      </p>
                                      <p
                                        style={{
                                          color: "#667085",
                                          margin: "0px",
                                          fontSize: "13px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {`${student?._source?.email}`}
                                      </p>
                                      <p
                                        style={{
                                          color: "#667085",
                                          margin: "0px",
                                          fontSize: "13px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {/* {student?._source?.contactNumber &&
                                        typeof student?._source
                                          ?.contactNumber === "string"
                                          ? `  +${
                                              student?._source
                                                ?.contactCountryCode || "91"
                                            } ${
                                              student?._source?.contactNumber?.startsWith(
                                                student?.contactCountryCode ||
                                                  "91"
                                              )
                                                ? student?._source?.contactNumber?.replace(
                                                    student?.contactCountryCode ||
                                                      "91",
                                                    ""
                                                  )
                                                : student?._source
                                                    ?.contactNumber
                                            }`
                                          : ""} */}
                                        {student?._source?.contactNumber
                                          ? student?._source?.contactNumber?.startsWith(
                                              "971"
                                            )
                                            ? `+971 ${student?._source?.contactNumber.slice(
                                                3
                                              )}`
                                            : student?._source?.contactNumber?.startsWith(
                                                "91"
                                              )
                                            ? `+91 ${student?._source?.contactNumber.slice(
                                                2
                                              )}`
                                            : ""
                                          : ""}
                                      </p>
                                    </div>
                                  </CustomTableCell>
                                )}

                                {columns[3].show && (
                                  <CustomTableCell sx={{}}>
                                    <Box>
                                      <>
                                        {student?.inner_hits
                                          ?.batchstudentmapping?.hits?.hits?.[0]
                                          ?._source?.batchStatus && (
                                          <p
                                            style={{
                                              color:
                                                student?.inner_hits
                                                  ?.batchstudentmapping?.hits
                                                  ?.hits?.[0]?._source
                                                  ?.batchStatus === "ONGOING"
                                                  ? "#3DB985"
                                                  : student?.inner_hits
                                                      ?.batchstudentmapping
                                                      ?.hits?.hits?.[0]?._source
                                                      ?.batchStatus ===
                                                    "UPCOMING"
                                                  ? "#EBA418"
                                                  : student?.inner_hits
                                                      ?.batchstudentmapping
                                                      ?.hits?.hits?.[0]?._source
                                                      ?.batchStatus ===
                                                    "COMPLETED"
                                                  ? "#027A48"
                                                  : "#3DB985",
                                              backgroundColor:
                                                student?.inner_hits
                                                  ?.batchstudentmapping?.hits
                                                  ?.hits?.[0]?._source
                                                  ?.batchStatus === "ONGOING"
                                                  ? "#ECFDF3"
                                                  : student?.inner_hits
                                                      ?.batchstudentmapping
                                                      ?.hits?.hits?.[0]?._source
                                                      ?.batchStatus ===
                                                    "UPCOMING"
                                                  ? "#FFFAEB"
                                                  : student?.inner_hits
                                                      ?.batchstudentmapping
                                                      ?.hits?.hits?.[0]?._source
                                                      ?.batchStatus ===
                                                    "COMPLETED"
                                                  ? "#ECFDF3"
                                                  : "#ECFDF3",
                                              textAlign: "center",
                                              borderRadius: "16px",
                                              padding: "2px 5px",
                                              fontSize: "12px",
                                              lineHeight: "18px",
                                              fontWeight: "500",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {student?.inner_hits?.batchstudentmapping?.hits?.hits?.[0]?._source?.batchStatus?.toLowerCase()}
                                          </p>
                                        )}
                                      </>
                                    </Box>
                                  </CustomTableCell>
                                )}

                                {columns[4].show && (
                                  <CustomTableCell sx={{}}>
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: "#667085",
                                            margin: "0px",
                                            textTransform: "capitalize",
                                            fontSize: "13px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {
                                            student?.inner_hits
                                              ?.batchstudentmapping?.hits
                                              ?.hits?.[0]?._source?.courseName
                                          }
                                        </p>
                                        {student?.inner_hits
                                          ?.batchstudentmapping?.hits?.hits
                                          ?.length > 1 && (
                                          <p
                                            style={{
                                              padding: "4px",
                                              borderRadius: "100%",
                                              backgroundColor:
                                                "rgba(17, 200, 155, 0.06)",
                                              width: "24px",
                                              height: "24px",
                                              margin: "0px 4px",
                                              fontSize: "12px",
                                              textAlign: "center",
                                              color: "#11C89B",
                                            }}
                                          >
                                            +
                                            {student?.inner_hits
                                              ?.batchstudentmapping?.hits?.hits
                                              ?.length - 1}
                                          </p>
                                        )}
                                      </Box>
                                    </>
                                  </CustomTableCell>
                                )}

                                {columns[5].show && (
                                  <CustomTableCell sx={{}}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "#667085",
                                          margin: "0px",
                                          textTransform: "capitalize",
                                          fontSize: "13px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {
                                          student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source?.batchName
                                        }
                                      </p>
                                    </Box>
                                  </CustomTableCell>
                                )}

                                {columns[6].show && (
                                  <CustomTableCell sx={{}}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        style={{
                                          color: "#667085",
                                          margin: "0px",
                                          textTransform: "capitalize",
                                          fontSize: "13px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {
                                          student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source?.branchName
                                        }
                                      </p>
                                    </Box>
                                  </CustomTableCell>
                                )}

                                {columns[7].show && (
                                  <CustomTableCell
                                    align="left"
                                    sx={{ alignItems: "center" }}
                                  >
                                    <p
                                      style={{
                                        margin: "0px",
                                        color: "#667085",
                                        textAlign: "center",
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        lineHeight: "18px",
                                        borderRadius: "4px",
                                        background: "#F3F3F3",
                                        padding: "3px 8px",
                                        display: "inline-flex",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {student?.inner_hits?.batchstudentmapping?.hits?.hits?.[0]?._source?.paymentType
                                        ?.toLowerCase()
                                        .replace(/_/g, " ")}
                                    </p>
                                  </CustomTableCell>
                                )}

                                {columns[8].show && (
                                  <CustomTableCell
                                    align="left"
                                    sx={{ alignItems: "center" }}
                                  >
                                    <p
                                      style={{
                                        color:
                                          student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source
                                            ?.paymentStatus === "Partly Paid" ||
                                          student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source
                                            ?.paymentStatus === "EMI Paid" ||
                                          student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source
                                            ?.paymentStatus ===
                                            "Membership Fees Paid"
                                            ? "#EBA418"
                                            : student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentStatus ===
                                                "Fully Paid" ||
                                              student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentStatus ===
                                                "Emi Fully Paid" ||
                                              student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentStatus ===
                                                "Membership Fully Paid"
                                            ? "#27AF77"
                                            : student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentStatus ===
                                                "Membership Fees Due" ||
                                              student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentStatus === "EMI Due" ||
                                              student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentStatus === "Not Paid"
                                            ? "#EC3939"
                                            : "#EBA418",
                                        margin: "0px",
                                        fontSize: "143x",
                                        fontWeight: "500",
                                        alignItems: "center",
                                        gap: "2px",
                                        display: "list-item",
                                      }}
                                    >
                                      {
                                        student?.inner_hits?.batchstudentmapping
                                          ?.hits?.hits?.[0]?._source
                                          ?.paymentStatus
                                      }
                                    </p>
                                  </CustomTableCell>
                                )}

                                {columns[9].show && (
                                  <CustomTableCell
                                    align="left"
                                    sx={{ alignItems: "center" }}
                                  >
                                    <div
                                      style={{
                                        margin: "0px",
                                        color: "#667085",
                                        fontFamily: "Poppins",
                                        fontSize: "11px",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                      }}
                                    >
                                      {student?.inner_hits?.batchstudentmapping
                                        ?.hits?.hits?.[0]?._source
                                        ?.paymentType === "REGULAR" ? (
                                        <div>
                                          {student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source?.emiExist ? (
                                            <>
                                              {student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentStatus !==
                                                "Emi Fully Paid" && (
                                                <>
                                                  {student?.inner_hits
                                                    ?.batchstudentmapping?.hits
                                                    ?.hits?.[0]?._source
                                                    ?.paymentStatus ===
                                                  "EMI Due" ? (
                                                    <>
                                                      Due On:{" "}
                                                      {student?.inner_hits
                                                        ?.batchstudentmapping
                                                        ?.hits?.hits?.[0]
                                                        ?._source
                                                        ?.paymentDetails?.dueOn
                                                        ? moment
                                                            .unix(
                                                              student
                                                                ?.inner_hits
                                                                ?.batchstudentmapping
                                                                ?.hits
                                                                ?.hits?.[0]
                                                                ?._source
                                                                ?.paymentDetails
                                                                ?.dueOn / 1000
                                                            )
                                                            .format(
                                                              "DD/MM/YYYY"
                                                            )
                                                        : "N/A"}
                                                    </>
                                                  ) : (
                                                    <>
                                                      Next Due On:{" "}
                                                      {student?.inner_hits
                                                        ?.batchstudentmapping
                                                        ?.hits?.hits?.[0]
                                                        ?._source
                                                        ?.paymentDetails
                                                        ?.nextDueOn
                                                        ? moment
                                                            .unix(
                                                              student
                                                                ?.inner_hits
                                                                ?.batchstudentmapping
                                                                ?.hits
                                                                ?.hits?.[0]
                                                                ?._source
                                                                ?.paymentDetails
                                                                ?.nextDueOn /
                                                                1000
                                                            )
                                                            .format(
                                                              "DD/MM/YYYY"
                                                            )
                                                        : "N/A"}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                              <br />
                                              Paid EMIs:{" "}
                                              {
                                                student?.inner_hits
                                                  ?.batchstudentmapping?.hits
                                                  ?.hits?.[0]?._source
                                                  ?.paymentDetails?.paidEmi
                                              }
                                              <br />
                                              Remaining EMIs:{" "}
                                              {
                                                student?.inner_hits
                                                  ?.batchstudentmapping?.hits
                                                  ?.hits?.[0]?._source
                                                  ?.paymentDetails?.remainingEmi
                                              }
                                            </>
                                          ) : (
                                            <>
                                            Total:{' '}
                                            <span
                                              style={{
                                                fontFamily: 'Roboto',
                                              }}>
                                              {handleSelectCurrency(
                                                student?.inner_hits
                                                  ?.batchstudentmapping
                                                  ?.hits?.hits?.[0]?._source
                                                  ?.branchId
                                              )}
                                            </span>
                                            {
                                              student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentDetails
                                                ?.totalAmount
                                            }
                                            <br />
                                            Remaining:{' '}
                                            <span
                                              style={{
                                                fontFamily: 'Roboto',
                                              }}>
                                              {handleSelectCurrency(
                                                student?.inner_hits
                                                  ?.batchstudentmapping
                                                  ?.hits?.hits?.[0]?._source
                                                  ?.branchId
                                              )}
                                            </span>
                                            {
                                              student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentDetails
                                                ?.remainingAmount
                                            }
                                          </>
                                          )}
                                        </div>
                                      ) : student?.inner_hits
                                          ?.batchstudentmapping?.hits?.hits?.[0]
                                          ?._source?.paymentType ===
                                          "MEMBERSHIP" &&
                                        student?.inner_hits?.batchstudentmapping
                                          ?.hits?.hits?.[0]?._source
                                          ?.paymentStatus !==
                                          "Membership Fully Paid" ? (
                                        <>
                                          {student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source
                                            ?.paymentStatus ===
                                            "Membership Fees Paid" ||
                                          student?.inner_hits
                                            ?.batchstudentmapping?.hits
                                            ?.hits?.[0]?._source
                                            ?.paymentStatus ===
                                            "Membership Paused" ? (
                                            <>
                                              Next Due On:{" "}
                                              {student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentDetails?.nextDueOn
                                                ? moment
                                                    .unix(
                                                      student?.inner_hits
                                                        ?.batchstudentmapping
                                                        ?.hits?.hits?.[0]
                                                        ?._source
                                                        ?.paymentDetails
                                                        ?.nextDueOn / 1000
                                                    )
                                                    .format("DD/MM/YYYY")
                                                : "N/A"}
                                            </>
                                          ) : (
                                            <>
                                              Due On:{" "}
                                              {student?.inner_hits
                                                ?.batchstudentmapping?.hits
                                                ?.hits?.[0]?._source
                                                ?.paymentDetails?.dueOn
                                                ? moment
                                                    .unix(
                                                      student?.inner_hits
                                                        ?.batchstudentmapping
                                                        ?.hits?.hits?.[0]
                                                        ?._source
                                                        ?.paymentDetails
                                                        ?.dueOn / 1000
                                                    )
                                                    .format("DD/MM/YYYY")
                                                : "N/A"}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </CustomTableCell>
                                )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {/* </InfiniteScroll> */}
                  </TableContainer>
                ) : (
                  <>
                    {filterApplied === false && (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "absolute",
                          fontSize: "14px",
                          color: "#667085",
                          fontWeight: "600",
                        }}
                      >
                        No data Available
                      </div>
                    )}
                  </>
                )}

                {filterApplied === true &&
                studentItems &&
                studentItems?.length <= 0 ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        color: "#142C44",
                      }}
                    >
                      No results found.{" "}
                      <span
                        style={{
                          color: "#667085",
                        }}
                      >
                        {filterApplied === true
                          ? "Try another filter"
                          : "Try another search"}
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
        <ClearFilter
          open={isOpenFilter}
          noButtonClick={noButtonClick}
          yesButtonClick={yesButtonClick}
          onClose={closeClearFilter}
          filteredValue={filteredValue}
        />
      </div>
    </div>
  );
}
