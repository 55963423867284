import React from "react";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { Dialog, MenuItem, Select, TextField } from "@mui/material";
import closeIcon from "../../../../../../assets/images/close-icon.svg";
import styles from "./EditPopup.module.css";

function EditPriceDiscount(props: any) {
  const { isOpen, onClose, loader, selectedCurrency } = props;
	const [price, setPrice] = React.useState<string>('');
	return (
		<>
			<Dialog
				open={isOpen}
				sx={{
					zIndex: '99999',
					minWidth: '408px',
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '408px',
						maxHeight: '400px',
						minHeight: '200px',
						padding: '16px',
						borderRadius: '8px',
						boxShadow:
							'0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
						left: '10%',
						background: '#FFFFFF',
						overflow: 'hidden',
					},
				}}>
				<div>
					<div
						className={styles.popupHead}
						style={{ padding: '0px', marginBottom: '14PX' }}>
						<div className={styles.popupHeadTitle}>Add Discount</div>
						<button className={styles.popupHeadClsBtn} onClick={onClose}>
							<img src={closeIcon} alt='closeIcon' />
						</button>
					</div>
					<div className={styles.membershipEditBody}>
						<div className={styles.membershipEditBodyTop}>
							<div className={styles.membershipEditTypeLabel}>Final Price</div>
							<div
								className={styles.membershipTypeFinalPrice}
								style={{ fontFamily: 'Roboto' }}>
								{selectedCurrency} 1,000.00
							</div>
						</div>
						<div className={styles.membershipEditBodyBottom}>
							<div>
								<div className={styles.membershipEditTypeFieldLabel}>
									Discount Type
								</div>
								<div>
									<Select
										id='emi-mode-select'
										fullWidth
										MenuProps={{
											style: {
												zIndex: 999999,
												fontSize: '14px',
											},
											PaperProps: {
												style: {
													boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
												},
											},
										}}
										sx={{
											maxHeight: '39PX',

											'& .MuiSelect-outlined': {
												fontSize: '14px',
												color: '#142C44',
											},
										}}
										// value={selectedEmiMode}
										// onChange={(event) =>
										//   handleEmiMode(event.target.value as string)
										// }
										//   disabled={
										//     (downPaymentAmount === finalPrice ? true : false) ||
										//     hasLeadAccess === false
										//       ? true
										//       : false
										//   }
									>
										{[
											{ discountType: 'AMOUNT' },
											{ discountType: 'PERCENTAGE' },
										]?.map((item, index) => (
											<MenuItem
												key={index}
												value={item.discountType}
												sx={{
													fontSize: '12PX',
													color: '#667085',
													textTransform: 'capitalize',
													fontWeight: '400',
												}}>
												{item?.discountType?.toLowerCase()}
											</MenuItem>
										))}
									</Select>
								</div>
							</div>
							<div>
								<div className={styles.membershipEditTypeFieldLabel}>
									Discount
								</div>
								<div>
									<TextField
										id='price'
										variant='outlined'
										type='number'
										inputProps={{
											sx: {
												width: '240px',
												'&::placeholder': {
													fontWeight: '400',
													fontSize: '14px',
												},

												'&.MuiOutlinedInput-input': {
													padding: '8px',
												},
											},
										}}
										value={price}
										//   onChange={(event) => handlePrice(event.target.value)}
										//   error={priceError}
										//   helperText={priceError ? "Please fill valid amount" : ""}
										fullWidth
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.popupBtnSplit}>
						<CancelBtn
						// onClick={() => cancelButtonClick()}
						/>
						<SaveBtn
						// onClick={handleSubmit}
						// disabled={
						//   loading_updateCourse || (isSyllabusSame && isDescriptionSame)
						// }
						// loader={loading_updateCourse}
						/>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default EditPriceDiscount;
