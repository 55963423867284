import React, { useCallback, useState } from "react";
import help from "../Assets/help.svg";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";
import UserAssignField from "../../../../../components/UserAssign/UserAssignField";
import styles from "./OzonetelDetails.module.css";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { ValidateAgentId } from "../../service/integrations.request";
import AgentIdPopup from "./HelpPopup/AgentIdPopup";

function AddAgents(props: any) {
  const {
    employees,
    // ValidateAgentId,
    appsList,
    validatedAgent,
    instituteDetails,
    AddOzonetelAgents,
  } = props;
  const { validateAgentError, validateAgentSuccess, agentAddedLoading } =
    useSelector((state: any) => state.appIntegrations);

  const ozonetelApp = appsList?.find((app: any) => app.appCode === "OZONETEL");
  const ozonetelAppApiKey = ozonetelApp?.appConfigData?.apiKey;
  const ozonetelAppUserName = ozonetelApp?.appConfigData?.userName;

  const [agentsArray, setAgentsArray] = useState<any>([
    { userId: "", agentId: "", agentIdValid: false, agentIdUnique: true },
  ]);
  const [emptyAgentField, setEmptyAgentField] = React.useState<boolean>(false);
  const [invalidAgentsError, setInvalidAgentsError] =
    React.useState<boolean>(false);

  const [showAgentIdPopup, setShowAgentIdPopup] = useState(false);

  const filteredEmployees = employees?.filter((employee: any) => {
    return !agentsArray?.some((agent: any) => agent.userId === employee.id);
  });

  const handleAddAgent = useCallback(() => {
    setAgentsArray([
      ...agentsArray,
      { userId: "", agentId: "", agentIdValid: false, agentIdUnique: true },
    ]);
  }, [agentsArray]);

  const handleAgentIdChange = useCallback(
    (value: string, index: number) => {
      // if (value) {
      //   let data = {
      //     agentId: value,
      //     instituteId: instituteDetails?.id,
      //     apiKey: ozonetelAppApiKey,
      //     userName: ozonetelAppUserName,
      //   };
      //   ValidateAgentId(data);
      // }

      const isAgentIdUnique = agentsArray.every(
        (agent: any, i: number) => i === index || agent.agentId !== value
      );

      const updatedAgentsArray = [...agentsArray];
      // updatedAgentsArray[index].agentId = value;
      updatedAgentsArray[index] = {
        ...updatedAgentsArray[index],
        agentId: value,
        agentIdUnique: isAgentIdUnique,
        agentIdValid: false,
      };
      setAgentsArray(updatedAgentsArray);
    },
    [agentsArray]
  );

  // const handleAgentIdBlur = useCallback(
  //   async (value: string, index: number) => {
  //     if (value) {
  //       let data = {
  //         agentId: value,
  //         instituteId: instituteDetails?.id,
  //         apiKey: ozonetelAppApiKey,
  //         userName: ozonetelAppUserName,
  //       };

  //       try {
  //         const response: any = await ValidateAgentId(
  //           value,
  //           instituteDetails?.id,
  //           ozonetelAppApiKey,
  //           ozonetelAppUserName
  //         );

  //         // Assuming ValidateAgentId returns a promise
  //         // const response = await ValidateAgentId(data);

  //         // Check if the API call was successful
  //         if (response && response?.data?.message === "Agent Id is valid") {
  //           const updatedAgentsArray = [...agentsArray];
  //           updatedAgentsArray[index] = {
  //             ...updatedAgentsArray[index],
  //             agentIdValid: true,
  //           };
  //           setAgentsArray(updatedAgentsArray);
  //         } else {
  //           // If the API call is not successful, set agentIdValid to false
  //           const updatedAgentsArray = [...agentsArray];
  //           updatedAgentsArray[index] = {
  //             ...updatedAgentsArray[index],
  //             agentIdValid: false,
  //           };
  //           setAgentsArray(updatedAgentsArray);

  //           // Handle the case when the API call is not successful
  //           console.error("API call failed");
  //         }
  //       } catch (error) {
  //         // If an error occurs during the API call, set agentIdValid to false
  //         const updatedAgentsArray = [...agentsArray];
  //         updatedAgentsArray[index] = {
  //           ...updatedAgentsArray[index],
  //           agentIdValid: false,
  //         };
  //         setAgentsArray(updatedAgentsArray);

  //         // Handle any errors that may occur during the API call
  //         console.error("Error during API call:", error);
  //       }
  //     }
  //   },
  //   [
  //     instituteDetails?.id,
  //     ozonetelAppApiKey,
  //     ozonetelAppUserName,
  //     ValidateAgentId,
  //     agentsArray,
  //   ]
  // );

  const handleAgentIdBlur = useCallback(
    async (value: string, index: number) => {
      const valueExist = agentsArray?.some(
        (agent: any) => agent.agentId.toString() === value.toString()
      );

      if (value) {
        try {
          const response: any = await ValidateAgentId(
            value,
            instituteDetails?.id,
            ozonetelAppApiKey,
            ozonetelAppUserName
          );

          // Check if the API call was successful
          if (response && response?.data?.message === "Agent Id is valid") {
            const updatedAgentsArray = [...agentsArray];
            updatedAgentsArray[index] = {
              ...updatedAgentsArray[index],
              agentIdValid: true,
            };
            setAgentsArray(updatedAgentsArray);
          } else {
            // If the API call is not successful, set agentIdValid to false
            const updatedAgentsArray = [...agentsArray];
            updatedAgentsArray[index] = {
              ...updatedAgentsArray[index],
              agentIdValid: false,
            };
            setAgentsArray(updatedAgentsArray);

            // Handle the case when the API call is not successful
            console.error("API call failed");
          }
        } catch (error) {
          // If an error occurs during the API call, set agentIdValid to false
          const updatedAgentsArray = [...agentsArray];
          updatedAgentsArray[index] = {
            ...updatedAgentsArray[index],
            agentIdValid: false,
          };
          setAgentsArray(updatedAgentsArray);

          // Handle any errors that may occur during the API call
          console.error("Error during API call:", error);
        }
      }
    },
    [
      instituteDetails?.id,
      ozonetelAppApiKey,
      ozonetelAppUserName,
      ValidateAgentId,
      agentsArray,
    ]
  );

  const handleAgentLeadAssignee = useCallback(
    (user: any, index: number) => {
      if (user) {
        // Update the agentsArray when a user is selected
        const updatedAgentsArray = [...agentsArray];
        updatedAgentsArray[index].userId = user.id;
        setAgentsArray(updatedAgentsArray);
      }
    },
    [agentsArray]
  );

  const filteredAgents = agentsArray?.filter(
    (item: any) => item.agentId.trim() !== "" && item.agentIdValid === true
  );
  const filteredInvalidAgents = agentsArray?.filter(
    (item: any) => item.agentId.trim() !== "" && item.agentIdValid === false
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // const filteredAgents = agentsArray?.filter(
    //   (item: any) => item.agentId.trim() !== "" && item.agentIdValid === true
    // );

    const hasEmptyAgentId = agentsArray?.some(
      (item: any) => item.agentId.trim() === ""
    );

    const hasEmptyUserId = agentsArray?.some(
      (item: any) => item.userId.trim() === ""
    );

    const hasInvalidAgentId = agentsArray?.some(
      (item: any) => item.agentIdValid === false
    );

    const hasAgentIdUnique = agentsArray?.some(
      (item: any) => item.agentIdUnique === false
    );

    if (filteredAgents?.length === 0) {
      setEmptyAgentField(true);
      return; // Stop the submission if there's no agentId
    }

    if (filteredInvalidAgents?.length > 0) {
      setInvalidAgentsError(true);
      return;
    }

    try {
      const updatedAgentsArray = agentsArray?.map((item: any) => ({
        userId: item.userId,
        agentId: item.agentId,
      }));
      emptyAgentField === false &&
        hasAgentIdUnique === false &&
        AddOzonetelAgents(updatedAgentsArray);
    } catch (error) {}
  };

  const handleAgentIdHelpClick = () => {
    setShowAgentIdPopup(true);
  };

  React.useEffect(() => {
    if (filteredInvalidAgents?.length === 0) {
      setInvalidAgentsError(false);
    }
  }, [filteredInvalidAgents]);

  React.useEffect(() => {
    if (filteredAgents?.length > 0) {
      setEmptyAgentField(false);
    }
  }, [filteredAgents]);

  React.useEffect(() => {
    if (employees) {
      const defaultAgentsArray = employees?.map((item: any) => ({
        userId: item.id,
        agentId: "",
        agentIdValid: false,
        userName: item.firstName + " " + item.lastName,
        agentIdUnique: true,
      }));
      setAgentsArray(defaultAgentsArray);
    }
  }, [employees]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.addAgentsSubHead}>
        Map nurture users to their corresponding Ozonetel Agent IDs. This will
        enable the Ozonetel widget on their nurture account.
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexBasis: "100%",
            color: "#667085",
            fontSize: "13px",
            fontWeight: "400",
            textAlign: "left",
          }}
        >
          Users
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#667085",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "22px",
            textTransform: "capitalize",
            marginBottom: "2px",
            flexBasis: "100%",
            textAlign: "left",
          }}
        >
          Agent ID <span style={{ color: "#EC3939" }}>*</span>
          <div onClick={handleAgentIdHelpClick}>
            <img src={help} alt="help" style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>

      {showAgentIdPopup && (
        <AgentIdPopup
          isOpen={showAgentIdPopup}
          onClose={() => setShowAgentIdPopup(false)}
        />
      )}
      <div
        style={{ maxHeight: "211PX", overflowY: "auto", paddingRight: "4px" }}
      >
        {agentsArray?.map((item: any, index: number) => (
          <div key={index} className={styles.addAgentsFieldWrap}>
            <div className={styles.addAgentsFieldItem}>
              <div className={styles.addAgentsFieldItemUserWrap}>
                <div className={styles.addAgentsFieldItemUserWrapAvatar}>
                  {item?.userName?.charAt(0)}
                </div>
                <div className={styles.addAgentsFieldItemUserName}>
                  {item?.userName}
                </div>
              </div>
            </div>
            <div className={styles.addAgentsFieldItem}>
              <TextField
                placeholder="Enter Agent ID"
                fullWidth
                inputProps={{
                  style: {
                    padding: "9.4px 12px",
                  },
                }}
                value={item?.agentId}
                onChange={(e) => handleAgentIdChange(e.target.value, index)}
                onBlur={(e) => handleAgentIdBlur(e.target.value, index)}
              />
              {!item?.agentIdValid &&
                item?.agentId !== "" &&
                item?.agentIdUnique && (
                  <FormHelperText error>Invalid agent id.</FormHelperText>
                )}
              {item?.agentIdValid &&
                item?.agentId !== "" &&
                item?.agentIdUnique && (
                  <FormHelperText sx={{ color: "#11c89b" }}>
                    Valid agent id.
                  </FormHelperText>
                )}
              {!item?.agentIdUnique && (
                <FormHelperText error>
                  Agent ID should be unique.
                </FormHelperText>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* {agentsArray?.map((item: any, index: number) => (
        <div key={index} className={styles.addAgentsFieldWrap}>
          <div className={styles.addAgentsFieldItem}>
            <TextField
              placeholder="Enter Agent ID"
              fullWidth
              inputProps={{
                style: {
                  padding: "8.2px 12px",
                },
              }}
              value={item?.agentId}
              onChange={(e) => handleAgentIdChange(e.target.value, index)}
              onBlur={(e) => handleAgentIdBlur(e.target.value, index)}
            />
            {!item?.agentIdValid &&
              item?.agentId !== "" &&
              item?.agentIdUnique && (
                <FormHelperText error>Invalid agent id.</FormHelperText>
              )}
            {item?.agentIdValid &&
              item?.agentId !== "" &&
              item?.agentIdUnique && (
                <FormHelperText sx={{ color: "#11c89b" }}>
                  Valid agent id.
                </FormHelperText>
              )}
            {!item?.agentIdUnique && (
              <FormHelperText error>Agent ID should be unique.</FormHelperText>
            )}
          </div>
          <div className={styles.addAgentsFieldItem}>
            <UserAssignField
              staffs={filteredEmployees}
              selectedAssignyId={item?.userId}
              // handleLeadAssignee={handleAgentLeadAssignee}
              handleLeadAssignee={(user: any) =>
                handleAgentLeadAssignee(user, index)
              }
            />
            {item?.agentIdValid &&
              item?.agentId !== "" &&
              item?.agentIdUnique &&
              item?.userId !== "" && (
                <FormHelperText error>Please select user.</FormHelperText>
              )}
          </div>
        </div>
      ))} */}

      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          component="label"
          color="primary"
          sx={{
            fontSize: 13,
            textTransform: "capitalize",
            boxShadow: "none",
          }}
          size={"small"}
          onClick={handleAddAgent}
        >
          <AddIcon sx={{ marginRight: "5px", fontSize: "18px" }} />
          Add another agent
        </Button>
      </div> */}

      <div>
        {emptyAgentField && (
          <FormHelperText error>
            Please fill atleast one valid agent id.
          </FormHelperText>
        )}
        {!emptyAgentField && invalidAgentsError && (
          <FormHelperText error>
            Please fill valid agent id or clear invalid agent ids.
          </FormHelperText>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          sx={{
            textTransform: "capitalize",
            maxWidth: "100%",
            mainWidth: "100%",
            "&.Mui-disabled": {
              backgroundColor: "#11C89B ",
              color: "#fff",
            },
          }}
          fullWidth
          onClick={handleSubmit}
          disabled={agentAddedLoading}
        >
          {agentAddedLoading ? (
            <CircularProgress size={20} color={"inherit"} />
          ) : (
            "Continue"
          )}
        </Button>
      </div>
    </div>
  );
}

export default AddAgents;
