import { Button, CircularProgress } from "@mui/material";
import React from "react";

function SaveBtn(props: any) {
  const { onClick, disabled, loader, buttonText } = props;
  return (
    <>
      <Button
        sx={{ textTransform: "capitalize", minWidth: "100px" }}
        variant="contained"
        onClick={onClick}
        disabled={disabled}
      >
        {loader ? (
          <CircularProgress size={20} color={"inherit"} />
        ) : buttonText ? (
          buttonText
        ) : (
          "Save"
        )}
      </Button>
    </>
  );
}

export default SaveBtn;
