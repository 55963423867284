import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import Inbound from "../components/Inbound";
import {
  AddEnquiryDataFromContact,
  ContactInitialStateDTO,
  DeleteContactData,
  ImportCSVData,
  ImportInitialStateDTO,
} from "../service/types";
import {
  importCsvFile,
  deleteContactList,
  addEnquiryForContact,
  getContactList,
  getCsvTemplate,
  addEnquiryForZapier,
} from "../service/inboundImportSlice";
import { CreateSourceDTO, NurtureLead } from "../../Nurture/service/types";
import {
  createSource,
  getLeadListByInstituteAndContact,
  getNurtureLeadBoard,
  nurtureLead,
} from "../../Nurture/service/nurtureSlice";
import {
  getAllCourseByInstituteRequest,
  getCourseByIdRequest,
} from "../../Manage/ManageCourse/service/courseSlice";
import { getBranchList } from "../../Manage/ManageBranch/service/Branchslice";
import {
  getBatchById,
  getBatchList,
} from "../../Manage/ManageBatch/service/Batchslice";
import {
  bulkLeadCreation,
  deleteWebformResponse,
  getAllWebForm,
  getStudentContactById,
  getWebformByInstitute,
  getWebformResponse,
} from "../Webform/service/webformSlice";
import { getInstituteById } from "../../Manage/InstituteProfile/service/instituteSlice";
import { getMailConfigsList } from "../service/inboundSlice";
import Contact from "../components/SyncedDataTab/Contact";

interface StateProps extends ContactInitialStateDTO {}

interface DispatchProps {
  GetNurtureLeadBoard(id: string): void;
  DownloadCSVTemplate(data: any): void;
  ImportCSVFile(body: ImportCSVData): void;
  GetCourseList(id: string): void;
  GetContactLists(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string,
    enquirySource: string
  ): void;
  DeleteContactList(data: DeleteContactData): void;
  GetBranchList(id: string): void;
  GetCourseList(id: string): void;
  GetBatchList(data: any): void;
  GetNurtureLeadBoard(id: string): void;
  AddEnquiryForContact(data: AddEnquiryDataFromContact): void;
  GetInstituteById(id: string): void;
  GetCourseById(id: string): void;
  GetBatchById(id: string): void;
  GetMailConfigList(id: string): void;
  CreateSource(data: CreateSourceDTO): void;
  GetAllWebform(data: string): void;
  AddEnquiryForZapier(data: AddEnquiryDataFromContact): void;

  // GetAllWebform(
  //   id: string,
  //   lastEvaluatedKey: string,
  //   limit: number,
  //   lastEvaluatedSortKey: string,
  //   roleInfo: any,
  //   formType: any
  // ): void;
  // GetFormResponse(
  //   id: string,
  //   lastEvaluatedKey: string,
  //   limit: number,
  //   lastEvaluatedSortKey: string,
  //   formId: string,
  //   roleInfo: any
  // ): void;
  GetFormResponse(data: string): void;
  GetWebformForInstitute(instituteId: string, webformId: string): void;
  GetStudentContactById(id: string): void;
  GetLeadListByInstituteAndContact(data: any): void;
  CreateEnquiry(data: NurtureLead): void;
  BulkLeadCreation(data: any): void;
  DeleteWebformResponse(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    templateDownloadError: state.import.templateDownloadError,
    templateDownloadLoading: state.import.templateDownloadLoading,
    templateDownloadSuccess: state.import.templateDownloadSuccess,
    templateData: state.import.templateData,
    importCsvLoading: state.import.importCsvLoading,
    importCsvSuccess: state.import.importCsvSuccess,
    importCsvError: state.import.importCsvError,
    importCsvData: state.import.importCsvData,
    contactListLoading: state.import.contactListLoading,
    contactListSuccess: state.import.contactListSuccess,
    contactListError: state.import.contactListError,
    contactList: state.import.contactList,
    deleteContactLoading: state.import.deleteContactLoading,
    deleteContactSuccess: state.import.deleteContactSuccess,
    deleteContactError: state.import.deleteContactError,
    addedEnquiryContactLoading: state.import.addedEnquiryContactLoading,
    addedEnquiryContactSuccess: state.import.addedEnquiryContactSuccess,
    addedEnquiryContactError: state.import.addedEnquiryContactError,
    mailConfigsList: state.import.mailConfigsList,
    mailConfigListLoading: state.import.mailConfigListLoading,
    mailConfigListSuccess: state.import.mailConfigListSuccess,
    mailConfigListError: state.import.mailConfigListError,
    getAllFormByInstituteData: state.webform.getAllFormByInstituteData,
    getFormResponseSuccess: state.webform.getFormResponseSuccess,
    getFormResponseLoading: state.webform.getFormResponseLoading,
    getFormResponseError: state.webform.getFormResponseError,
    getFormResponseData: state.webform.getFormResponseData,
    getFormByInstituteData: state.webform.getFormByInstituteData,
    getStudentContactByIdData: state.webform.getStudentContactByIdData,
    leadCreatedId: state.import.leadCreatedId,
    addedEnquiryZapierLoading: state.import.addedEnquiryZapierLoading,
    addedEnquiryZapierSuccess: state.import.addedEnquiryZapierSuccess,
    addedEnquiryZapierError: state.import.addedEnquiryZapierError,
    leadCreatedIdForZapier: state.import.leadCreatedIdForZapier,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetNurtureLeadBoard: (id: string) => {
      dispatch(getNurtureLeadBoard(id));
    },
    DownloadCSVTemplate: (data: any) => {
      dispatch(getCsvTemplate(data));
    },
    ImportCSVFile: (body: ImportCSVData) => {
      dispatch(importCsvFile(body));
    },
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    GetContactLists: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string,
      enquirySource: string
    ) => {
      dispatch(
        getContactList({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
          enquirySource,
        })
      );
    },
    DeleteContactList: (data: DeleteContactData) => {
      dispatch(deleteContactList(data));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetBatchList: (data: any) => {
      dispatch(getBatchList(data));
    },
    AddEnquiryForContact: (data: AddEnquiryDataFromContact) => {
      dispatch(addEnquiryForContact(data));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
    GetCourseById: (id: string) => {
      dispatch(getCourseByIdRequest(id));
    },
    GetBatchById: (id: string) => {
      dispatch(getBatchById(id));
    },
    GetMailConfigList: (id: string) => {
      dispatch(getMailConfigsList(id));
    },
    CreateSource: (data: CreateSourceDTO) => {
      dispatch(createSource(data));
    },
    GetAllWebform: (data: string) => {
      dispatch(getAllWebForm(data));
    },
    GetFormResponse: (data: string) => {
      dispatch(getWebformResponse(data));
    },
    GetWebformForInstitute: (instituteId: string, webformId: string) => {
      dispatch(getWebformByInstitute({ instituteId, webformId }));
    },
    GetStudentContactById: (id: string) => {
      dispatch(getStudentContactById(id));
    },
    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
    CreateEnquiry: (data: NurtureLead) => {
      dispatch(nurtureLead(data));
    },
    BulkLeadCreation: (data: any) => {
      dispatch(bulkLeadCreation(data));
    },
    DeleteWebformResponse: (data: any) => {
      dispatch(deleteWebformResponse(data));
    },
    AddEnquiryForZapier: (data: AddEnquiryDataFromContact) => {
      dispatch(addEnquiryForZapier(data));
    },
  };
};
export interface InboundProps extends StateProps, DispatchProps {}
export const ContactComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
