import React from "react";

function FilterChipsWrap(props: any) {
  const { children } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "8PX",
        marginBottom: "16px",
      }}
    >
      {children}
    </div>
  );
}

export default FilterChipsWrap;
