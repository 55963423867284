import React from "react";
import styles from "./LeadCustomPopup.module.css";
import CloseIcon from "@mui/icons-material/Close";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";

function LeadConfirmPopup(props: any) {
  const {
    children,
    isOpen,
    onClose,
    cancelClick,
    submitClick,
    title,
    description,
    submitBtnText,
    submitLoader,
    submitDisbaled,
  } = props;
  return (
    <>
      {isOpen && (
        <div className={styles.customPopupWrapper}>
          <div className={styles.customPopupWrap}>
            <div className={styles.clsBtn} onClick={onClose}>
              <CloseIcon />
            </div>
            <div className={styles.popupTitle}>{title}</div>
            <div className={styles.popupDesc}>{description}</div>
            <div className={styles.popupChild}>{children}</div>
            <div className={styles.customPopupBottom}>
              <CancelBtn onClick={cancelClick} />
              <SaveBtn
                onClick={submitClick}
                buttonText={submitBtnText}
                loader={submitLoader}
                disabled={submitDisbaled}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LeadConfirmPopup;
