import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../Inbound/components/ImportModal/ImportModal.module.css";
import { timeZoneConverter } from "../../../../../components/Formatter/Formatter";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 4,
};
const UnavailabelModel = ({
  open,
  setOpenUnavailableModel,
  yesButtonClick,
  noButtonClick,
  title = "Select the week day",
  date,
  setBatchDetails,
  add,
  unavailabelDate,
  setIsDateChanged,
}: any) => {
  const dateofDayName = new Date(date);
  const options: any = { weekday: "long" };

  const dayName = dateofDayName.toLocaleString("en-US", options);

  const updateBatchDetails = () => {
    if (unavailabelDate) {
      const newExemptedDate = {
        exemptedDate: timeZoneConverter(unavailabelDate),
        status: "False",
        description: "Closed",
      };
      setBatchDetails((prevBatchDetails: any) => ({
        ...prevBatchDetails,
        exemptedDates: [...prevBatchDetails.exemptedDates, newExemptedDate],
        customDays: prevBatchDetails.customDays.filter(
          (customDay: any) =>
            customDay.date !== new Date(unavailabelDate).getTime()
        ),
      }));
      setIsDateChanged(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpenUnavailableModel(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div
            onClick={() => {
              setOpenUnavailableModel(false);
            }}
            className={styles.closeModalButton}
          >
            <CloseIcon />
          </div>
          <div className={styles.modalTitle}>{title}</div>
          <div className={styles.modalDes}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ fontWeight: 600 }}>{dayName}</div>
              <div
                className={styles.timingSlot}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  height: "100%",
                  color: "red",
                  marginTop: "20px",
                }}
              >
                Unavailable
              </div>
            </div>
          </div>
          <div className={styles.buttonSection}>
            <button onClick={noButtonClick} className={styles.noButton}>
              Cancel
            </button>
            <button
              onClick={() => {
                yesButtonClick();
                updateBatchDetails();
              }}
              style={{
                background: "#11c89b",
                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                borderRadius: "4px",
                padding: "10px 18px",
                gap: "8px",
                width: "100%",
                color: " #ffffff",
                border: "0",
                cursor: "pointer",
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default UnavailabelModel;
