import React, { CSSProperties, useState } from "react";
import { Typography, Box, IconButton } from "@mui/material";
import { BiArrowBack } from "react-icons/bi";
import { OtpForm } from "../../../../auth/ForgetPassword/components/OtpForm";
import { ChangePasswordProps } from "../container/ChangePasswordContainer";
import {
  resetPasswordDTO,
  verifyOTPDTO,
} from "../../../../auth/ForgetPassword/service/types";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import { Onboard } from "../../../../auth/ForgetPassword/components/Onboard";
import { SnackBar } from "./../../../../../components/SnackBar/SnackBar";
import CloseIcon from "@mui/icons-material/Close";
import ClipLoader from "react-spinners/ClipLoader";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#11C89B",
};

interface otpFormProps extends ChangePasswordProps {
  setShowOtpForm?: any;
  email: string;
  showOtpForm: boolean;
  setShowPasswordPage: any;
  showPasswordPage: boolean;
  showMainPage: boolean;
  setShowMainPage: any;
}

function OtpFormForChangePassword(props: otpFormProps) {
  const {
    setShowOtpForm,
    loading_verifyOTP,
    email,
    error_requestOTP,
    error_verifyOTP,
    error_resetPassword,
    success_requestOTP,
    success_verifyOTP,
    success_resetPassword,
    RequestOTPforForgetPassword,
    VerifyOTPforForgetPassword,
    ResetPasswordforForgetPassword,
    loading_resetPassword,
    showOtpForm,
    setShowPasswordPage,
    showPasswordPage,
    setShowMainPage,
  } = props;

  const [otp, setOTP] = React.useState<string>("");
  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "info",
  });
  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    if (error_requestOTP || error_verifyOTP || error_resetPassword) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(
          error_requestOTP || error_verifyOTP || error_resetPassword
        ),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
  }, [error_requestOTP, error_resetPassword, error_verifyOTP]);

  React.useEffect(() => {
    if (error_verifyOTP) {
      setShowPasswordPage(false);
      setShowOtpForm(true);
      setShowMainPage(false);
    }
  }, [error_verifyOTP]);

  React.useEffect(() => {
    if (success_requestOTP) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "OTP sent successfully",
        snackOpen: true,
        snackSeverity: "success",
      }));
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [success_requestOTP]);

  React.useEffect(() => {
    if (success_verifyOTP) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "OTP Verification Successfull !",
        snackOpen: true,
        snackSeverity: "success",
      }));
    }
  }, [success_verifyOTP]);

  React.useEffect(() => {
    if (success_resetPassword) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "Password is successfully set!",
        snackOpen: true,
        snackSeverity: "success",
      }));
    }
  }, [success_resetPassword]);

  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;

  const VerifyOTP = React.useCallback(
    (data: verifyOTPDTO) => {
      setOTP(data?.otp);
      VerifyOTPforForgetPassword(data);
      setShowPasswordPage(true);
      setShowOtpForm(false);
      setShowMainPage(false);
    },
    [VerifyOTPforForgetPassword]
  );

  const ResetPassword = React.useCallback(
    (data: resetPasswordDTO) => {
      ResetPasswordforForgetPassword(data);
    },
    [ResetPasswordforForgetPassword]
  );

  const resendOtp = React.useCallback(() => {
    RequestOTPforForgetPassword({ email: email, resend: true });
    setSnackState((snackState: SnackStateProp) => ({
      ...snackState,
      message: "OTP sent successfully",
      snackOpen: true,
      snackSeverity: "success",
    }));
  }, [RequestOTPforForgetPassword, email]);

  const isOTP =
    success_requestOTP &&
    !success_verifyOTP &&
    !success_resetPassword &&
    !error_requestOTP;

  const isSetpassword =
    success_requestOTP && success_verifyOTP && !error_verifyOTP;

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color={snackSeverity}
      onClick={closeSnack}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <ClipLoader
            color={"#11C89B"}
            loading={loading}
            cssOverride={override}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* <SnackBar
            snackOpen={snackOpen}
            closeSnack={closeSnack}
            vertical={vertical}
            horizontal={horizontal}
            snackSeverity={snackSeverity}
            action={action}
            message={message}
          /> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              paddingTop: "22px",
              paddingLeft: "22px",
            }}
            onClick={() => {
              setShowOtpForm(false);
              setShowMainPage(true);
              setShowPasswordPage(false);
            }}
          >
            <BiArrowBack />
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginLeft: "5px",
              }}
            >
              Back
            </Typography>
          </Box>
          <Box>
            {showOtpForm && isOTP && (
              <OtpForm
                loading={loading_verifyOTP}
                email={email}
                onSubmit={VerifyOTP}
                message={snackState.message}
                onResendClick={resendOtp}
              />
            )}
          </Box>
          {showPasswordPage && isSetpassword && (
            <Box>
              <Onboard
                loading={loading_resetPassword}
                email={email}
                onSubmit={ResetPassword}
                otp={otp}
                success_resetPassword={success_resetPassword}
                isFromChangePassword={true}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default OtpFormForChangePassword;
