import React from "react";
import { Mention } from "react-mentions";
import { MentionsInput } from "react-mentions";
import LeadDatePicker from "../../../pages/app/Nurture/components/LeadDatePicker/LeadDatePicker";
import FlagAssign from "../../FlagAssign/FlagAssign";
import UserAssign from "../../UserAssign/UserAssign";
import TaskTypeSelect from "../../../pages/app/Nurture/components/TaskTypeSelect/TaskTypeSelect";
import styles from "../LeadPopup.module.css";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../RolePermissionAccess/RolePermissionAccess";
import { getCombinedAssignees } from "../../CommonFunctions/CommonFunctions";

function LeadCallDispositionTask(props: any) {
  const {
    employees,
    setAddTask,
    setAssigny,
    setPriority,
    setDate,
    setTaskType,
    setTaskDetails,
    addTask,
    date,
    taskTypes,
    assigny,
    taskType,
    priority,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const branchEmployees = employees?.filter(
    (item: any) => item?.branchId === authUser?.institute?.[0]?.branchId
  );
  const otherBranchEmployees = employees?.filter(
    (item: any) =>
      item?.branchId !== authUser?.institute?.[0]?.branchId &&
      item?.allBranchAccess === true
  );
  const combinedEmployees = [...branchEmployees, ...otherBranchEmployees];
  // const combinedEmployees = getCombinedAssignees(employees, leadById?.branchId);

  const activeEmployees = combinedEmployees?.filter((item: any) => {
    const isActiveAndHasLeadCardAccess =
      item.status === "ACTIVE" && item?.leadCardAccess === true;

    return isActiveAndHasLeadCardAccess;
  });

  const handleAddTask = (event: any) => {
    setAddTask(event.target.value);
  };

  const handleLeadAssignee = React.useCallback(
    (leadId: any, user: any) => {
      setAssigny(user);
    },

    [setAssigny]
  );

  const handleLeadPriority = React.useCallback(
    (leadId: any, leadPriority: any) => {
      setPriority(leadPriority);
    },

    [setPriority]
  );

  function getNameById(id: any) {
    const employee = activeEmployees?.find((e: any) => e.id === id);

    return employee
      ? `${
          employee.firstName.charAt(0).toUpperCase() +
          employee.firstName.slice(1)
        } ${
          employee.lastName.charAt(0).toUpperCase() + employee.lastName.slice(1)
        }`
      : "Unknown";
  }

  return (
    <div style={{ border: "1px solid #E8E8E8", borderRadius: "4px" }}>
      <div style={{ padding: "5px" }}>
        <MentionsInput
          value={addTask}
          onChange={handleAddTask}
          placeholder="Add Description"
          style={{
            control: {
              backgroundColor: "#fff",
              fontSize: "14px",
              color: "#69696B",
              height: "auto",
              minHeight: "105px",
              maxHeight: "250px",
              overflow: "auto",
              border: "none",
            },
            highlighter: {
              overflow: "hidden",
            },
            input: {
              margin: 0,
              border: "none",
              outline: "none",
            },
            "&singleLine": {
              margin: 0,
            },
            suggestions: {
              width: "222px",
              zIndex: 2,
              overflow: "auto",
              maxHeight: "140px",
              backgroundColor: "#fff",
              border: "1px solid #F0F0F0",
              borderRadius: "6px",
              boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
              fontSize: "14px",
              position: "absolute",
              padding: "10px",
              color: "#142C44",
              fontWeight: "500",
              lineHeight: "16px",
            },
          }}
        >
          <Mention
            data={activeEmployees}
            trigger="@"
            markup="@{{__id__}}"
            style={{
              backgroundColor: "#F5F5F7",
              padding: "5px",
              borderRadius: "20px",
            }}
            displayTransform={(id: any, firstName: any) => {
              return "@" + getNameById(id);
            }}
            onAdd={(id: any) => {
              setTaskDetails((prevDetails: any) => {
                if (!prevDetails.mentionedIds.includes(id)) {
                  return {
                    ...prevDetails,
                    mentionedIds: [...prevDetails.mentionedIds, id],
                  };
                }
                return prevDetails;
              });
            }}
            renderSuggestion={(suggestion: any) => (
              <div
                style={{
                  paddingBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    width: "34px",
                    height: "34px",
                    fontSize: "16px",
                    alignItems: "center",
                    textTransform: "capitalize",
                    fontWeight: "500",
                    background: "#F5F5F7",
                    border: "1px solid #EEEEF4",
                    color: "#667085",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "50%",
                  }}
                >
                  {suggestion?.firstName?.charAt(0)}
                </div>
                {suggestion.firstName} {suggestion.lastName}
              </div>
            )}
          />
        </MentionsInput>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #F5F5F7",
          padding: "10px 8px",
          gap: "10px",
        }}
      >
        <TaskTypeSelect
          taskType={(type: string) => {
            setTaskType(type);
          }}
          taskTypes={taskTypes}
          selectedTaskType={taskType}
          isForDispositionForm={true}
        />

        <div className={styles.iconImage}>
          <UserAssign
            users={employees}
            handleLeadAssignee={handleLeadAssignee}
            selectedAssignyId={assigny?.id}
            isForEnquiryModal={false}
            isForDispositionForm={true}
          />
        </div>

        <div className={styles.iconImage}>
          <LeadDatePicker
            setDate={setDate}
            date={date}
            //   setErrorDatePast={setErrorDatePast}
          />
        </div>
        <div className={styles.iconImage}>
          <FlagAssign
            handleLeadPriority={handleLeadPriority}
            selectedPriority={priority}
            isForEnquiryModal={false}
            isForDispositionForm={true}
          />
        </div>
      </div>
    </div>
  );
}

export default LeadCallDispositionTask;
