import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useCallback, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styles from "../../Dashboard.module.css";
import { FormControl, MenuItem, Select } from "@mui/material";
import dropArrowIcon from "../Assets/selectArrow.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import noResults from "../../../../../assets/images/no-results.svg";
import { formatAmount } from "../../../../../components/CommonFunctions/CommonFunctions";
import { RevenueDisplay } from '../../../../../components/CommonFunctions/CommonFunctions';
import ToolTip from '../../../../../components/ToolTip/ToolTip';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

function RevenueAnalyticsGraph(props: any) {
	const { revenuGraphData, RevenueAnalyticsGraphApiCall, selectedCurrency } =
		props;
	const auth = useAuthUser();
	const user = auth();
	const employees = useSelector((state: any) => state.staff.staffList);

	const [leadGraphDuration, setLeadGraphDuration] = useState<number>(7);

	const handleChange = useCallback(
		(event: any) => {
			setLeadGraphDuration(event.target.value);
			const instituteId = user?.institute[0]?.instituteId;
			let branchId: any;
			const queryParams = new URLSearchParams(window.location.search);
			const branchParam = queryParams.get('selectedBranch');
			if (branchParam) {
				branchId = branchParam;
			} else if (user?.institute?.[0]?.branchId) {
				branchId = user?.institute?.[0]?.branchId;
			} else {
				const loginTeamember = employees?.find(
					(item: any) => item?.email === user?.email
				);
				if (loginTeamember) {
					branchId = loginTeamember?.branchId;
				}
			}
			RevenueAnalyticsGraphApiCall(instituteId, event.target.value, branchId);
			queryParams.set('revenueAnalyticsTimeline', event.target.value);
			const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
			window.history.pushState({}, '', newUrl);
		},
		[RevenueAnalyticsGraphApiCall, employees]
	);

	const [chartData, setChartData] = React.useState([
		{
			label: 'Payments Received',
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			borderColor: '#11c89b',
			backgroundColor: '#11c89b',
		},
		{
			label: 'Payments Overdue',
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			borderColor: '#11C89B',
			backgroundColor: '#11C89B',
		},
	]);

	const labels = revenuGraphData?.revenueAnalyticGraphData?.labels
		? revenuGraphData?.revenueAnalyticGraphData?.labels
		: [
				'01Th',
				'02Fr',
				'03Sa',
				'04Su',
				'05Mo',
				'06Tu',
				'07We',
				'08Th',
				'09Fr',
				'10Sa',
				'11Su',
		  ];
	const data = {
		labels,
		datasets: chartData,
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		tooltip: {
			mode: 'index',
			// intersect: false,
			// callbacks: {
			//   label: function (context: any) {
			//     let label = context.dataset.label || "";
			//     if (label) {
			//       label += ": ";
			//     }
			//     label += context.raw;
			//     return label;
			//   },
			// },
		},
		plugins: {
			legend: {
				position: 'top' as const,
				align: 'end' as const,
				fullSize: false,
				labels: {
					boxWidth: 10,
					boxHeight: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded',
				},
				maxWidth: 150,
				display: false,
			},
			title: {
				display: false,
				text: 'Revenue Analytics',
				align: 'start' as const,
				color: '#142C44',
				font: {
					size: 16,
					weight: '500',
					family: 'Poppins',
				},
			},
		},
		scales: {
			y: {
				min: revenuGraphData?.revenueAnalyticGraphData?.yMinValue ?? 0,
				max: revenuGraphData?.revenueAnalyticGraphData?.yMaxValue ?? 4,
				ticks: {
					stepSize: 1,
					maxTicksLimit: 7,
					minTicksLimit: 7,
					callback: function (value: number | string) {
						if (typeof value === 'number' && value >= 1000) {
							return (value / 1000)?.toFixed(1) + 'k';
						}
						if (typeof value === 'number') {
							return value.toFixed(1);
						}
						return value;
					},
				},
				beginAtZero: true,
				grid: {
					display: true,
				},
			},
			x: {
				grid: {
					display: false,
				},
				ticks: {
					maxTicksLimit: 7,
				},
			},
		},
		elements: {
			line: {
				cubicInterpolationMode: () => 'monotone' as const,
			},
			point: {
				// radius: 2,
				// radius: 0,
				// hoverRadius: 5,
			},
		},
	};

	useEffect(() => {
		let dataArray = revenuGraphData?.revenueAnalyticGraphData?.datasets;

		if (revenuGraphData?.revenueAnalyticGraphData?.datasets) {
			let data: any = [];
			dataArray &&
				dataArray?.map((obj: any, key: number) => {
					const newObj = {
						label: obj.label,
						data: obj.data,
						backgroundColor: key % 2 === 0 ? '#11c89b' : '#ec3939',
						borderColor: key % 2 === 0 ? '#11c89b' : '#ec3939',
					};
					data.push(newObj);
					setChartData(data);
					return null;
				});
		}
	}, [revenuGraphData]);

	React.useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const revenueTimelineParam = queryParams.get('revenueAnalyticsTimeline');

		if (revenueTimelineParam) {
			setLeadGraphDuration(parseInt(revenueTimelineParam));
		} else {
			setLeadGraphDuration(7);
		}
	}, []);

	return (
		<div className={styles.revenueGraphBox} style={{ maxWidth: '100%' }}>
			<div className={styles.revenueGraphTop}>
				<div className={styles.revenueGraphTitle}>Revenue Analytics</div>
				<div className={styles.revenueGraphSelect}>
					<FormControl size='small'>
						<Select
							id='demo-simple-select'
							value={leadGraphDuration}
							onChange={handleChange}
							sx={{
								width: '120px',
								'& .MuiSelect-outlined': {
									fontSize: '14px',
									color: '#667085',
									fontFamily: 'poppins',
									borderRadius: '4px',
								},
								'&& fieldset': {
									color: '#667085',
									borderColor: 'rgba(217, 226, 234, 0.70)',
									borderRadius: '4px',
									fontFamily: 'poppins',
								},
								'& .MuiOutlinedInput-notchedOutline': {
									borderRadius: '4px !important',
								},
							}}
							IconComponent={() => (
								<KeyboardArrowDownIcon sx={{ color: '#CCCCCC' }} />
							)}
							displayEmpty
							MenuProps={{
								PaperProps: {
									sx: {
										boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.06)',
										borderRadius: '4px',
										border: '1px solid  #E4E4E7',
										fontSize: '13PX',
									},
								},
								style: {
									//   zIndex: 999999,
									boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.06)',
									border: '1px solid  #E4E4E7',
									fontSize: '13PX',
								},
							}}>
							<MenuItem value={7} sx={{ fontSize: '13PX', color: '667085' }}>
								7 days
							</MenuItem>
							<MenuItem value={15} sx={{ fontSize: '13PX', color: '667085' }}>
								15 days
							</MenuItem>
							<MenuItem value={30} sx={{ fontSize: '13PX', color: '667085' }}>
								30 days
							</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			<div className={styles.revenueGraphDataWrap}>
				<div className={styles.revenueGraphDataItem}>
					<div className={styles.revenueGraphDataItemValue}>
						{selectedCurrency +
							' ' +
							formatAmount(revenuGraphData?.totalRecordedPayment)}
						{RevenueDisplay(revenuGraphData?.totalRecordedPayment) && (
							<ToolTip title={revenuGraphData?.totalRecordedPayment} />
						)}
					</div>
					<div className={styles.revenueGraphDataItemLabelWrap}>
						<div
							className={styles.revenueGraphDataItemLabelColor}
							style={{ background: '#11C89B' }}></div>
						<div className={styles.revenueGraphDataItemLabel}>
							Payments Received
						</div>
					</div>
				</div>
				<div className={styles.revenueGraphDataItem}>
					<div className={styles.revenueGraphDataItemValue}>
						{selectedCurrency +
							' ' +
							formatAmount(revenuGraphData?.totalOverduePayment)}
						{RevenueDisplay(revenuGraphData?.totalOverduePayment) && (
							<ToolTip title={revenuGraphData?.totalOverduePayment} />
						)}
					</div>
					<div className={styles.revenueGraphDataItemLabelWrap}>
						<div
							className={styles.revenueGraphDataItemLabelColor}
							style={{ background: '#EC3939' }}></div>
						<div className={styles.revenueGraphDataItemLabel}>
							Payments Overdue
						</div>
					</div>
				</div>
			</div>
			<div style={{ height: '270px' }}>
				{revenuGraphData?.revenueAnalyticGraphData?.yMaxValue === 0 ? (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								margin: 'auto',
								height: '100%',
								flexDirection: 'column',
							}}>
							<img src={noResults} alt='noResults' />
							<div
								style={{
									fontSize: '13px',
									color: '#667085',
									fontWeight: '500',
									marginTop: '12PX',
								}}>
								No data available
							</div>
						</div>
					</>
				) : (
					<>
						<Line options={options} data={data} />
					</>
				)}
			</div>
		</div>
	);
}

export default RevenueAnalyticsGraph;
