import React from "react";

function FilterCheckBoxWrap(props: any) {
  const { children, scroll } = props;
  return (
    <div
      style={{
        maxHeight: scroll === false ? "auto" : "220px",
        overflowY: "auto",
        paddingLeft: "9px",
      }}
    >
      {children}
    </div>
  );
}

export default FilterCheckBoxWrap;
