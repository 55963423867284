import { Grid, Tab, Tabs, Box, Skeleton } from "@mui/material";
import React, { Suspense } from "react";
import Payment from "../Payment/Payment";
import LeadOverview from "../../pages/app/Nurture/components/Overview/LeadOverview";
import DemoCancelPopup from "../../pages/app/Nurture/components/DemoBooking/components/DemoCancelPopup";
import { useAuthUser } from "react-auth-kit";
import LeadActivityTab from "./LeadActivityTab";
import LeadNotesTab from "./LeadNotesTab";
import LeadTasksTab from "./LeadTasksTab";
import { useSelector } from "react-redux";
import {
  getBatchWithCalendar,
  getBookedSessions,
  getLeadAdditionalDetails,
  resetLeadSession,
  resetPaymentStatuses,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import LeadSessionTab from "./LeadSessionTab";
import LeadAdditionalInfoTab from "./LeadAdditionalInfoTab";
import { getBranchById } from "../../pages/app/Manage/ManageBranch/service/Branchslice";

const Inbox = React.lazy(
  () => import("../../pages/app/Nurture/components/Inbox/Inbox")
);

const ListTab = (props: any) => {
  const {
    CreateNote,
    CreateTask,
    employees,
    leadActivityList,
    RecordPayment,
    ComposeEmailSent,
    GetInboxByLeadId,
    composeEmailSuccess,
    leadInbox,
    leadTaskList,
    leadNoteList,
    leadPaymentList,
    demoTypes,
    GetLeadNoteList,
    GetLeadPaymentList,
    GetLeadTaskList,
    taskTypes,
    GetLeadActivityList,
    CreateDemo,
    leadActivityLoading,

    leadConvertionError,
    leadStatusUpdateError,
    selectedLeadData,
    UpdateLead,

    DeleteNote,
    EditNote,
    DeleteTask,
    EditTask,
    UpdateTaskStatus,
    demoByCourseAndInstitute,
    createDemoLoading,
    CreateBatch,
    DeleteDemoBookedForLead,
    deleteDemoBookedLoading,
    deleteDemoBookedSuccess,
    createDemoSuccess,
    EditDemo,
    leadTasksloading,
    paymentListLoading,
    noteListLoading,
    inboxLoading,
    leadListLoading,
    leadStages,
    UpdateLeadEmiDetails,
    leadEmiDetailsLoading,
    GetEmiDataOfLead,
    leadEmiData,
    SendPaymentLink,
    getEmiDataloading,
    DownloadPaymentReceipt,
    value,
    setValue,
    EditRecordPayment,
    CreateComment,
    DeleteComment,
    EditComment,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const {
    randomPaymentSuccess,
    sendPaymentLinkSuccess,
    recordPaymentUpdateSuccess,
    createPaymentSuccess,
    pauseSuccess,
    leadUpdateSuccess,
    deleteAdditionalPaySuccess,
    sessionBookSuccess,
    sessionPayRecordSuccess,
    sessionPayLinkSuccess,
    bookedSessionLoading,
    batchWithCalendarLoading,
    leadAdditionalDetailsLoading,
    leadAdditionalDetails,
  } = useSelector((state: any) => state.nurture);
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const handleTabClickQuery = (tabType: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("tabOpen", tabType);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  // React.useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const leadId = urlSearchParams.get("leadId");
  //   const modalDisplay = urlSearchParams.get("leadModal");
  //   const modalTabOpen = urlSearchParams.get("tabOpen");
  //   if (leadId) {
  //     if (modalTabOpen) {
  //       if (modalTabOpen === "overview") {
  //         setValue(0);
  //       } else if (modalTabOpen === "notes") {
  //         setValue(1);
  //       } else if (modalTabOpen === "task") {
  //         setValue(2);
  //       } else if (modalTabOpen === "inbox") {
  //         setValue(3);
  //       } else if (modalTabOpen === "payment") {
  //         setValue(4);
  //       }
  //       else if (modalTabOpen === "activity") {
  //         setValue(5);
  //       }
  //     }
  //   }
  // }, [setValue, window.location.search]);

  // React.useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);

  //   const modalTabOpen = urlSearchParams.get("tabOpen");
  //   if (selectedLeadData?.id) {
  //     if (modalTabOpen) {
  //       if (modalTabOpen === "overview") {
  //       } else if (modalTabOpen === "notes") {
  //         GetLeadNoteList(selectedLeadData?.id);
  //       } else if (modalTabOpen === "task") {
  //         GetLeadTaskList(selectedLeadData?.id);
  //       } else if (modalTabOpen === "inbox") {
  //         GetInboxByLeadId(selectedLeadData?.id);
  //       } else if (modalTabOpen === "payment") {
  //         GetLeadPaymentList(selectedLeadData?.id);
  //       } else if (modalTabOpen === "activity") {
  //         GetLeadActivityList(selectedLeadData?.id);
  //       }
  //     }
  //   }
  // }, [selectedLeadData]);

  // React.useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);

  //   const modalTabOpen = urlSearchParams.get("tabOpen");
  //   console.log("modalTabOpen", modalTabOpen);
  //   if (selectedLeadData?.id) {
  //     if (modalTabOpen) {
  //       if (modalTabOpen === "overview") {
  //         setValue(0);
  //       } else if (modalTabOpen === "notes") {
  //         GetLeadNoteList(selectedLeadData?.id);
  //         setValue(1);
  //       } else if (modalTabOpen === "task") {
  //         GetLeadTaskList(selectedLeadData?.id);
  //         setValue(2);
  //       } else if (modalTabOpen === "inbox") {
  //         GetInboxByLeadId(selectedLeadData?.id);
  //         setValue(3);
  //       } else if (modalTabOpen === "payment") {
  //         GetLeadPaymentList(selectedLeadData?.id);
  //         setValue(4);
  //       } else if (modalTabOpen === "activity") {
  //         if (selectedLeadData?.paymentDetails?.paymentType === "SESSION") {
  //           setValue(6);
  //         } else {
  //           setValue(5);
  //         }
  //         GetLeadActivityList(selectedLeadData?.id);
  //       }
  //     } else if (modalTabOpen === "session") {
  //       setValue(5);
  //       dispatch<any>(getBatchWithCalendar(selectedLeadData?.batchId));
  //       dispatch<any>(getBookedSessions(selectedLeadData?.id));
  //     }
  //   }
  // }, [selectedLeadData]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const modalTabOpen = urlSearchParams.get("tabOpen");

    if (selectedLeadData?.id) {
      // Default to overview if no specific tab is open
      if (modalTabOpen) {
        switch (modalTabOpen) {
          case "overview":
            setValue(0);
            break;
          case "notes":
            GetLeadNoteList(selectedLeadData.id);
            setValue(1);
            break;
          case "task":
            GetLeadTaskList(selectedLeadData.id);
            setValue(2);
            break;
          case "inbox":
            GetInboxByLeadId(selectedLeadData.id);
            setValue(3);
            break;
          case "payment":
            GetLeadPaymentList(selectedLeadData.id);
            setValue(4);
            break;
          case "activity":
            const activityTabValue =
              selectedLeadData.paymentDetails?.paymentType === "SESSION"
                ? 6
                : 5;
            setValue(activityTabValue);
            GetLeadActivityList(selectedLeadData.id);
            break;
          case "additional":
            const additionalTabValue =
              selectedLeadData.paymentDetails?.paymentType === "SESSION"
                ? 7
                : 6;
            setValue(additionalTabValue);
            dispatch<any>(
              getLeadAdditionalDetails({
                leadId: selectedLeadData?.id,
                id: activeInstitute?.id,
              })
            );
            break;
          case "session":
            setValue(5);
            dispatch<any>(getBatchWithCalendar(selectedLeadData.batchId));
            dispatch<any>(getBookedSessions(selectedLeadData.id));
            break;
          default:
            setValue(0); // Default to overview tab if no match
            break;
        }
      }
    }
  }, [selectedLeadData]);

  const [openCancelModal, setOpenCancelModal] = React.useState<boolean>(false);
  const [deleteDemo, setDeleteDemo] = React.useState<any>({
    batchId: "",
    demoId: "",
    enquiryId: "",
  });
  const handleCancelModalOpen = (
    demoId: string,
    batchId: string,
    enquiryId: string
  ) => {
    setOpenCancelModal(true);
    setDeleteDemo({
      ...deleteDemo,
      batchId: batchId,
      demoId: demoId,
      enquiryId: enquiryId,
      createdById: authUser?.institute?.[0]?.id,
      createdByName: authUser?.firstName + " " + authUser?.lastName,
      instituteId: authUser?.institute[0]?.instituteId,
    });
  };
  const handleCancelModalClose = () => {
    setOpenCancelModal(false);
  };
  const demoNoButtonClick = () => {
    setOpenCancelModal(false);
  };

  const demoYesButtonClick = async (event: any) => {
    event.preventDefault();

    try {
      deleteDemo.demoId &&
        deleteDemo.batchId &&
        DeleteDemoBookedForLead(deleteDemo);
    } catch (error) {}
  };

  const GetEmiData = () => {
    if (
      selectedLeadData?.paymentDetails?.EMIDetails?.EMIS?.length > 0 ||
      selectedLeadData?.paymentDetails?.randomPayments?.length > 0 ||
      selectedLeadData?.paymentDetails?.paymentType === "SESSION"
    ) {
      GetEmiDataOfLead({
        id: selectedLeadData?.id,
        loadSessionPayment:
          selectedLeadData?.paymentDetails?.paymentType === "SESSION"
            ? true
            : false,
      });
    }
  };

  React.useEffect(() => {
    if (deleteDemoBookedSuccess) {
      setOpenCancelModal(false);
    }
  }, [deleteDemoBookedSuccess]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const modalTabOpen = urlSearchParams.get("tabOpen");
    if (
      (randomPaymentSuccess !== true && randomPaymentSuccess !== false) ||
      (sendPaymentLinkSuccess !== true && sendPaymentLinkSuccess !== false) ||
      (recordPaymentUpdateSuccess !== true &&
        recordPaymentUpdateSuccess !== false) ||
      (createPaymentSuccess !== true && createPaymentSuccess !== false) ||
      (pauseSuccess !== true && pauseSuccess !== false) ||
      (deleteAdditionalPaySuccess !== true &&
        deleteAdditionalPaySuccess !== false) ||
      (leadUpdateSuccess !== true &&
        leadUpdateSuccess !== false &&
        (modalTabOpen === "overview" || modalTabOpen === "payment")) ||
      (sessionPayLinkSuccess !== true &&
        sessionPayLinkSuccess !== false &&
        modalTabOpen === "payment") ||
      (sessionPayRecordSuccess !== true &&
        sessionPayRecordSuccess !== false &&
        modalTabOpen === "payment")
    ) {
      GetEmiDataOfLead({
        id: selectedLeadData?.id,
        loadSessionPayment:
          selectedLeadData?.paymentDetails?.paymentType === "SESSION"
            ? true
            : false,
      });
      dispatch(resetPaymentStatuses());
      dispatch(resetLeadSession());
    }
  }, [
    randomPaymentSuccess,
    selectedLeadData,
    sendPaymentLinkSuccess,
    recordPaymentUpdateSuccess,
    createPaymentSuccess,
    pauseSuccess,
    leadUpdateSuccess,
    deleteAdditionalPaySuccess,
    sessionPayRecordSuccess,
    sessionPayLinkSuccess,
  ]);
  const branchDetails = useSelector((state: any) => state.branch);
  const [selectedCurrency, setSelectedCurrency] = React.useState(
    branchDetails?.branchById?.currency || "₹"
  );
  React.useEffect(() => {
    selectedLeadData &&
      dispatch<any>(getBranchById(selectedLeadData?.branchId));
  }, [selectedLeadData]);

  React.useEffect(() => {
    if (branchDetails)
      setSelectedCurrency(branchDetails?.branchById?.currency || "₹");
  }, [branchDetails]);
  React.useEffect(() => {
    if (sessionBookSuccess !== true && sessionBookSuccess !== false) {
      dispatch<any>(getBookedSessions(selectedLeadData?.id));
    }
  }, [sessionBookSuccess, selectedLeadData]);

  return (
    <Grid>
      <Box>
        {leadListLoading || leadStages === null ? (
          <>
            <Skeleton
              animation="wave"
              variant="rounded"
              height={40}
              sx={{ marginBottom: "6px" }}
            />
          </>
        ) : (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                marginBottom: "12PX",
                backgroundColor: "#EAEEF6",
                borderRadius: "4PX",
                padding: "4px 4px",
                fontSize: "14px",
                textTransform: "capitalize",
                minHeight: "33px",
                color: "#8A94A6",
                "& .Mui-selected": {
                  background: "#fff",
                  color: "#142C44 !Important",
                  fontWeight: "500",
                  borderBottom: "none",
                },
              }}
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <Tab
                label="overview"
                {...a11yProps(0)}
                onClick={() => {
                  handleTabClickQuery("overview");
                }}
                sx={{
                  padding: "6px 16px",
                  minHeight: "33px !important",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  color: "#8A94A6",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              />
              <Tab
                label="Notes"
                {...a11yProps(1)}
                onClick={() => {
                  GetLeadNoteList(selectedLeadData && selectedLeadData?.id);
                  handleTabClickQuery("notes");
                }}
                sx={{
                  padding: "6px 16px",
                  minHeight: "33px !important",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  color: "#8A94A6",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              />
              <Tab
                label="Task"
                {...a11yProps(2)}
                onClick={() => {
                  GetLeadTaskList(selectedLeadData && selectedLeadData?.id);
                  handleTabClickQuery("task");
                }}
                sx={{
                  padding: "6px 16px",
                  minHeight: "33px !important",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  color: "#8A94A6",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              />
              <Tab
                label="Inbox"
                {...a11yProps(3)}
                onClick={() => {
                  GetInboxByLeadId(selectedLeadData && selectedLeadData?.id);
                  handleTabClickQuery("inbox");
                }}
                sx={{
                  padding: "6px 16px",
                  minHeight: "33px !important",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  color: "#8A94A6",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              />
              <Tab
                label="Payment"
                {...a11yProps(4)}
                onClick={() => {
                  GetLeadPaymentList(selectedLeadData?.id);
                  handleTabClickQuery("payment");
                  GetEmiData();
                }}
                sx={{
                  padding: "6px 16px",
                  minHeight: "33px !important",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  color: "#8A94A6",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              />
              {selectedLeadData?.paymentDetails?.paymentType === "SESSION" && (
                <Tab
                  label="Session"
                  {...a11yProps(5)}
                  onClick={() => {
                    dispatch<any>(
                      getBatchWithCalendar(selectedLeadData?.batchId)
                    );
                    dispatch<any>(getBookedSessions(selectedLeadData?.id));
                    handleTabClickQuery("session");
                  }}
                  sx={{
                    padding: "6px 16px",
                    minHeight: "33px !important",
                    borderRadius: "3px",
                    textTransform: "capitalize",
                    color: "#8A94A6",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                />
              )}

              <Tab
                label="Activity"
                {...a11yProps(
                  selectedLeadData?.paymentDetails?.paymentType === "SESSION"
                    ? 6
                    : 5
                )}
                onClick={() => {
                  GetLeadActivityList(selectedLeadData?.id);
                  handleTabClickQuery("activity");
                }}
                sx={{
                  padding: "6px 16px",
                  minHeight: "33px !important",
                  borderRadius: "3px",
                  textTransform: "capitalize",
                  color: "#8A94A6",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              />
              {selectedLeadData?.additionalInfoAdded && (
                <Tab
                  label="Additional Details"
                  {...a11yProps(
                    selectedLeadData?.paymentDetails?.paymentType === "SESSION"
                      ? 7
                      : 6
                  )}
                  onClick={() => {
                    dispatch<any>(
                      getLeadAdditionalDetails({
                        leadId: selectedLeadData?.id,
                        id: activeInstitute?.id,
                      })
                    );
                    handleTabClickQuery("additional");
                  }}
                  sx={{
                    padding: "6px 16px",
                    minHeight: "33px !important",
                    borderRadius: "3px",
                    textTransform: "capitalize",
                    color: "#8A94A6",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                />
              )}
            </Tabs>
          </>
        )}
      </Box>
      {/* OVERVIEW  */}
      <DemoCancelPopup
        openCancelModal={openCancelModal}
        handleCancelModalClose={handleCancelModalClose}
        noButtonClick={demoNoButtonClick}
        yesButtonClick={demoYesButtonClick}
        deleteDemoBookedLoading={deleteDemoBookedLoading}
      />
      {value === 0 && (
        <LeadOverview
          employees={employees}
          CreateDemo={CreateDemo}
          demoTypes={demoTypes}
          selectedLeadData={selectedLeadData}
          leadPaymentList={leadPaymentList}
          RecordPayment={RecordPayment}
          leadConvertionError={leadConvertionError}
          leadStatusUpdateError={leadStatusUpdateError}
          UpdateLead={UpdateLead}
          demoByCourseAndInstitute={demoByCourseAndInstitute}
          createDemoLoading={createDemoLoading}
          CreateBatch={CreateBatch}
          DeleteDemoBookedForLead={DeleteDemoBookedForLead}
          deleteDemoBookedLoading={deleteDemoBookedLoading}
          deleteDemoBookedSuccess={deleteDemoBookedSuccess}
          EditDemo={EditDemo}
          createDemoSuccess={createDemoSuccess}
          handleCancelModalOpen={handleCancelModalOpen}
          leadListLoading={leadListLoading}
          leadStages={leadStages}
          paymentListLoading={paymentListLoading}
          UpdateLeadEmiDetails={UpdateLeadEmiDetails}
          leadEmiDetailsLoading={leadEmiDetailsLoading}
          SendPaymentLink={SendPaymentLink}
          leadEmiData={leadEmiData}
          GetEmiDataOfLead={GetEmiDataOfLead}
          getEmiDataloading={getEmiDataloading}
          DownloadPaymentReceipt={DownloadPaymentReceipt}
          EditRecordPayment={EditRecordPayment}
        />
      )}

      {/* Notes */}
      {value === 1 && (
        <>
          {!noteListLoading && !leadListLoading ? (
            <>
              <LeadNotesTab
                CreateNote={CreateNote}
                EditNote={EditNote}
                DeleteNote={DeleteNote}
                selectedLeadData={selectedLeadData}
                leadNoteList={leadNoteList}
                employees={employees}
              />
            </>
          ) : (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={100}
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "2px",
                  borderBottomRightRadius: "2px",
                }}
              />
            </>
          )}
        </>
      )}
      {/* Task */}
      {value === 2 && (
        <>
          {!leadTasksloading && !leadListLoading ? (
            <>
              <LeadTasksTab
                CreateTask={CreateTask}
                EditTask={EditTask}
                DeleteTask={DeleteTask}
                UpdateTaskStatus={UpdateTaskStatus}
                selectedLeadData={selectedLeadData}
                leadTaskList={leadTaskList}
                employees={employees}
                taskTypes={taskTypes}
                CreateComment={CreateComment}
                DeleteComment={DeleteComment}
                EditComment={EditComment}
              />
            </>
          ) : (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={100}
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "2px",
                  borderBottomRightRadius: "2px",
                }}
              />
            </>
          )}
        </>
      )}
      {/* inbox */}
      {value === 3 && (
        <>
          {inboxLoading && leadListLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              height={100}
              style={{
                borderTopRightRadius: "0px",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "2px",
                borderBottomRightRadius: "2px",
              }}
            />
          ) : (
            <>
              <Box
                sx={{
                  maxHeight: "calc(98vh - 174px)",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <Suspense
                  fallback={
                    <Skeleton animation="wave" variant="rounded" height={100} />
                  }
                >
                  <Inbox
                    GetInboxByLeadId={GetInboxByLeadId}
                    ComposeEmailSent={ComposeEmailSent}
                    composeEmailSuccess={composeEmailSuccess}
                    leadInbox={leadInbox}
                    leadById={selectedLeadData}
                  />
                </Suspense>
              </Box>
            </>
          )}
        </>
      )}
      {/* Payment */}
      {value === 4 && (
        <>
          {paymentListLoading && getEmiDataloading && leadListLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={100}
                style={{
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "2px",
                  borderBottomRightRadius: "2px",
                }}
              />
            </>
          ) : (
            <>
              <Box
                sx={{
                  maxHeight: "calc(98vh - 174px)",
                  height: "100%",
                  overflowY: "auto",
                  pt: 1,
                }}
              >
                <Payment
                  leadById={selectedLeadData}
                  overviewTab={false}
                  RecordPayment={RecordPayment}
                  SendPaymentLink={SendPaymentLink}
                  EditRecordPayment={EditRecordPayment}
                  selectedCurrency={selectedCurrency}
                />
              </Box>
            </>
          )}
        </>
      )}
      {/* Activity List */}
      {((selectedLeadData?.paymentDetails?.paymentType === "SESSION" &&
        value === 6) ||
        (selectedLeadData?.paymentDetails?.paymentType !== "SESSION" &&
          value === 5)) && (
        <>
          {!leadActivityLoading && !leadListLoading ? (
            <>
              <LeadActivityTab
                leadActivityList={leadActivityList}
                employees={employees}
                selectedCurrency={selectedCurrency}
              />
            </>
          ) : (
            <>
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={50}
                style={{
                  borderTopRightRadius: "2px",
                  borderTopLeftRadius: "2px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
              />
            </>
          )}
        </>
      )}

      {selectedLeadData?.additionalInfoAdded &&
        ((selectedLeadData?.paymentDetails?.paymentType === "SESSION" &&
          value === 7) ||
          (selectedLeadData?.paymentDetails?.paymentType !== "SESSION" &&
            value === 6)) && (
          <>
            {!leadAdditionalDetailsLoading && !leadListLoading ? (
              <>
                <LeadAdditionalInfoTab
                  leadAdditionalDetails={leadAdditionalDetails}
                />
              </>
            ) : (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={50}
                  style={{
                    borderTopRightRadius: "2px",
                    borderTopLeftRadius: "2px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                />
              </>
            )}
          </>
        )}

      {value === 5 &&
        selectedLeadData?.paymentDetails?.paymentType === "SESSION" && (
          <>
            {!bookedSessionLoading &&
            !batchWithCalendarLoading &&
            !leadListLoading ? (
              <>
                <LeadSessionTab
                  leadActivityList={leadActivityList}
                  employees={employees}
                  leadEmiData={leadEmiData}
                  leadData={selectedLeadData}
                />
              </>
            ) : (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  height={100}
                  style={{
                    borderTopRightRadius: "2px",
                    borderTopLeftRadius: "2px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                />
              </>
            )}
          </>
        )}
    </Grid>
  );
};

export default ListTab;
