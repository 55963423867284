import { forwardRef } from 'react';
type Props = {
	text: string;
	maxLength?: number;
};
const TruncatedText = forwardRef<HTMLDivElement, Props>(
	({ text, maxLength }, ref) => {
		return <div ref={ref}>{text.slice(0, maxLength) + '...'}</div>;
	}
);
export default TruncatedText;
