import React from "react";
import styles from "../../PaymentDetails/PaymentDetails.module.css";
import PaymentLinkSent from "../../PaymentLinkPopup/PaymentLinkSent";
import paidCheck from "../../../../../../assets/images/paid-check.svg";

function BasicSessionDetails(props: any) {
  const {
		hasLeadAccess,
		sessionPayments,
		leadData,
		EditRecordPayment,
		SendPaymentLink,
		isFirstPaymentRequestedOrFailed,
		filteredPaymentRequests,
		selectedCurrency,
	} = props;
	console.log('sessionPayments', sessionPayments);
	return (
		<div className={styles.basicRegularWrap}>
			<div className={styles.basicRegularLeft}>
				<div className={styles.basicRegularAmount}>
					{/*   {sessionPayments?.amount?.toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })} */}
					{selectedCurrency + ' ' + sessionPayments?.amount}
				</div>
			</div>
			<div className={styles.basicRegularRight}>
				{sessionPayments?.paymentStatus !== 'PAID' ? (
					<>
						{hasLeadAccess && (
							<>
								<PaymentLinkSent
									filteredPaymentRequests={filteredPaymentRequests}
									EditRecordPayment={EditRecordPayment}
									SendPaymentLink={SendPaymentLink}
									//   finalBatchPrice={sessionPayments?.amount}
									//   totalPaidAmount={sessionPayments?.amount}
									finalBatchPrice={leadData?.paymentDetails?.finalAmount}
									totalPaidAmount={leadData?.batchAmountPaid}
									isOneTime={false}
									paymentType={'SESSION_PAYMENT'}
									leadData={leadData}
									isFirstPaymentRequestedOrFailed={
										isFirstPaymentRequestedOrFailed
									}
									amountToBePaid={sessionPayments?.amount}
									sessionPaymentId={sessionPayments?.id}
								/>
							</>
						)}
					</>
				) : (
					<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						<div
							style={{
								background: ' rgba(17, 200, 155, 0.1)',
								borderRadius: '4px',
								padding: '4px 10PX',
								color: '#3DB985',
								fontSize: '12PX',
								fontWeight: '500',
							}}>
							Paid
						</div>
						<img src={paidCheck} alt='paidCheck' />
					</div>
				)}
			</div>
		</div>
	);
}

export default BasicSessionDetails;
