import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import styles from "../../Dashboard.module.css";
import noResults from "../../../../../assets/images/no-results.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: "y" as const,
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 0,
      borderRadius: 6,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Lead Source",
      align: "start" as const,
      color: "#142C44",
      font: {
        size: 14,
        weight: "500",
        family: "Poppins",
      },
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      },
    },
    x: {
      border: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        maxTicksLimit: 7,
      },
    },
  },
};

function StageVsLeads(props: any) {
  const [chartData, setChartData] = React.useState([]);

  useEffect(() => {
    let dataArray = props.data;
    if (props.data) {
      let data: any = [];
      dataArray &&
        dataArray?.map((obj: any) => {
          const newObj = {
            label: obj.label,
            data: obj.data,
            backgroundColor: "#2283CA",
            barThickness: 10,
          };
          data.push(newObj);
          setChartData(data);
          return null;
        });
    }
  }, [props.data]);

  const labels = props.labels;

  const data = {
    labels,
    datasets: chartData,
  };

  return (
    <div className={styles.barChartWrapper}>
      <div className={styles.barChartTitle}>Stage Vs Leads</div>
      <div style={{ height: "230px" }}>
        {props?.yMaxValue === 0 ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                margin: "auto",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <img src={noResults} alt="noResults" />
              <div
                style={{
                  fontSize: "13px",
                  color: "#667085",
                  fontWeight: "500",
                  marginTop: "12PX",
                }}
              >
                No data available
              </div>
            </div>
          </>
        ) : (
          <>
            <Bar options={options} data={data} />
          </>
        )}
      </div>
    </div>
  );
}

export default StageVsLeads;
