import { RootState, TypedDispatch } from "../../../../../redux/store";
import { connect } from "react-redux";

import {
  configWhatsapp,
  deleteGupShup,
  getMailConfigsList,
  getWhatsappConfig,
  getWhatsappConfigInstitute,
} from "../service/whatsappSlice";
import { WhatsAppInitialStateDTO, WhatsappConfigDTO } from "../service/types";
import WhatsAppConfig from "../components/WhatsAppConfig";

interface StateProps extends WhatsAppInitialStateDTO {}

interface DispatchProps {
  GetWhatsappConfig(): void;
  SaveWhatsappConfiguration(data: WhatsappConfigDTO): void;
  GetWhatsappConfigInstitute(id: string, status: string): void;
  GetMailConfigList(id: string): void;
  DeleteGupshup(data:WhatsappConfigDTO):void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.inbound.success,
    error: state.whatsapp.error,
    loading: state.whatsapp.loading,
    gupshupConfig: state.whatsapp.gupshupConfig,
    gupshupConfigSuccess: state.whatsapp.gupshupConfigSuccess,
    gupshupConfigLoading: state.whatsapp.gupshupConfigLoading,
    gupshupConfigError: state.whatsapp.gupshupConfigError,
    mailConfigsList: state.whatsapp.mailConfigsList,
    mailConfigListLoading: state.whatsapp.mailConfigListLoading,
    mailConfigListSuccess: state.whatsapp.mailConfigListSuccess,
    mailConfigListError: state.whatsapp.mailConfigListError,
    deleteGupshupLoading:state.whatsapp.deleteGupshupLoading,
    deleteGupshupSuccess:state.whatsapp.deleteGupshupSuccess,
    deleteGupshupError:state.whatsapp.deleteGupshupSuccess
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetWhatsappConfig: () => {
      dispatch(getWhatsappConfig());
    },
    SaveWhatsappConfiguration: (data: any) => {
      dispatch(configWhatsapp(data));
    },
    GetWhatsappConfigInstitute: (id: string, status: string) => {
      dispatch(getWhatsappConfigInstitute({ id, status }));
    },
    GetMailConfigList: (id: string) => {
      dispatch(getMailConfigsList(id));
    },
    DeleteGupshup:(data:any)=>{
      dispatch(deleteGupShup(data));
    }
  };
};
export interface EmailListProps extends StateProps, DispatchProps {}
export const WhatsappComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(WhatsAppConfig);
