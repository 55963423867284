import React from "react";
import styles from "./LeadMainDetailsCard.module.css";
import High from "../../pages/app/Tasks/components/Assets/High.svg";
import Normal from "../../pages/app/Tasks/components/Assets/Normal.svg";
import Low from "../../pages/app/Tasks/components/Assets/Low.svg";
import Urgent from "../../pages/app/Tasks/components/Assets/Urgent.svg";
import getTeamMember from "../CommonFunctions/CommonFunctions";
import { useSelector } from "react-redux";

function LeadMainDetailsBox(props: any) {
  const { leadById } = props;
  const employees = useSelector((state: any) => state.staff.staffList);

  return (
    <>
      <div>
        <div className={styles.leadDetailsHead}>
          <div className={styles.leadDetailsAvatar}>
            {leadById &&
              leadById?.studentFirstName &&
              leadById?.studentFirstName?.charAt(0)}
          </div>
          {leadById?.studentFirstName !== undefined &&
          leadById?.studentLastName !== undefined ? (
            <div className={styles.leadName}>
              {`${leadById && leadById?.studentFirstName} ${
                leadById && leadById?.studentLastName
              }`}
            </div>
          ) : (
            ""
          )}
          <div className={styles.leadContactDetails}>
            <div>
              {leadById?.studentEmail}{" "}
              {leadById?.studentEmail && leadById?.studentContact && " | "}
            </div>
            {leadById?.studentContact && (
              <div>
                {`+${leadById?.contactCountryCode || "91"} ${
                  leadById?.contactCountryCode
                    ? leadById?.studentContact.replace(
                        leadById?.contactCountryCode,
                        ""
                      )
                    : leadById?.studentContact?.startsWith("91")
                    ? leadById?.studentContact?.replace("91", "")
                    : leadById?.studentContact
                }`}
              </div>
            )}
          </div>
          <div className={styles.leadOtherDetailsWrap}>
            <div className={styles.leadSourceDetails}>
              Source:{" "}
              {leadById?.enquirySource?.toLowerCase().replace(/_/g, " ")}
            </div>
            <div className={styles.leadAssigneeDetails}>
              {getTeamMember(leadById?.assignedToId, employees)
                ?.firstName?.charAt(0)
                ?.toUpperCase()}
              {getTeamMember(leadById?.assignedToId, employees)
                ?.lastName?.charAt(0)
                ?.toUpperCase()}
              {/* {leadById?.assignedToName
                ?.split(" ")
                ?.slice(0, 2)
                ?.map((word: any, index: number) => (
                  <span key={index}>{word?.charAt(0)}</span>
                ))} */}
            </div>
            <div className={styles.leadAssigneeDetails}>
              {leadById?.enquiryPriority === "HIGH" && (
                <img src={High} alt="High" />
              )}
              {leadById?.enquiryPriority === "NORMAL" && (
                <img src={Normal} alt="Normal" />
              )}
              {leadById?.enquiryPriority === "LOW" && (
                <img src={Low} alt="Low" />
              )}
              {leadById?.enquiryPriority === "URGENT" && (
                <img src={Urgent} alt="Urgent" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadMainDetailsBox;
