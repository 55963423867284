import {
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./LeadSession.module.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import DiscountInput from "../../../../../../components/FormTextInput/DiscountInput";
import {
  addSessionRecordPayment,
  addSessionSendPaymentLink,
} from "../../../service/nurtureSlice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function AddMoreSessionPopup(props: any) {
  const { leadData, selectedCurrency } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const {
    sessionPayRecordLoading,
    sessionPayLinkLoading,
    sessionPayLinkSuccess,
    sessionPayRecordSuccess,
  } = useSelector((state: any) => state.nurture);

  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const [open, setOpen] = useState<boolean>(false);
  const [finalPrice, setFinalPrice] = React.useState<string>("");
  const [sessionCount, setSessionCount] = useState<number>(1);
  const [discountMetric, setDiscountMetric] = React.useState<string>("");
  const [discount, setDiscount] = React.useState<string>("");
  const pricePerSession = leadData?.paymentDetails?.finalAmount || 0;
  const totalPrice = sessionCount * pricePerSession;

  const openPaymentRecordPopup = () => {
    setOpen(true);
  };

  const closePaymentRecordPopup = () => {
    setOpen(false);
  };

  const incrementSession = () => {
    setSessionCount((prevCount) => prevCount + 1);
  };

  const decrementSession = () => {
    setSessionCount((prevCount) => (prevCount > 1 ? prevCount - 1 : 1)); // Prevent going below 1
  };

  const handleDiscountMetric = (value: string) => {
    setDiscountMetric(value);
    setDiscount("");
    setFinalPrice(leadData?.paymentDetails?.finalAmount);
  };

  const handleDiscount = (value: string) => {
    if (value !== "") {
      if (discountMetric === "AMOUNT") {
        if (parseFloat(value) > totalPrice) {
          setFinalPrice((totalPrice - totalPrice).toString());
          setDiscount(totalPrice.toString());
        } else {
          setDiscount(value);
          setFinalPrice((totalPrice - parseFloat(value)).toString());
        }
      } else if (discountMetric === "PERCENTAGE") {
        if (parseFloat(value) > 100) {
          setFinalPrice((totalPrice * (1 - 100 / 100)).toString());
          setDiscount("100");
        } else {
          setDiscount(value);
          setFinalPrice(
            (totalPrice * (1 - parseFloat(value) / 100)).toString()
          );
        }
      }
    } else {
      setFinalPrice(totalPrice.toString());
      setDiscount("");
    }
  };

  const recordSessionPayment = async (event: any) => {
    event.preventDefault();

    try {
      let discountError = false;
      if (discountMetric !== "" && parseFloat(discount) === 0) {
        discountError = true;
      }
      const body = {
        enquiryId: leadData?.id,
        instituteId: activeInstitute?.id,
        amountPerSession: leadData?.paymentDetails?.finalAmount,
        numberofSession: sessionCount,
        totalAmount: totalPrice,
        amount: parseFloat(finalPrice),
        captureMethod: "recordPayment",
        discountMetric:
          discountMetric !== "" && parseFloat(discount) > 0
            ? discountMetric
            : "",
        discount:
          discountMetric !== "" && parseFloat(discount) > 0
            ? parseFloat(discount)
            : 0,
        createdById: authUser?.institute[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
      };

      if (
        !discountError
        //  &&
        // selectedSession?.sessionPrice > 0 &&
        // selectedSession?.sessionMode !== ""
      ) {
        dispatch<any>(addSessionRecordPayment(body));
      }
    } catch (error) {}
  };

  const sendSessionPayment = async (event: any) => {
    event.preventDefault();

    try {
      let discountError = false;
      if (discountMetric !== "" && parseFloat(discount) === 0) {
        discountError = true;
      }
      const body = {
        enquiryId: leadData?.id,
        instituteId: activeInstitute?.id,
        amountPerSession: leadData?.paymentDetails?.finalAmount,
        numberofSession: sessionCount,
        totalAmount: totalPrice,
        amount: parseFloat(finalPrice),
        captureMethod: "sendPaymentLink",
        discountMetric:
          discountMetric !== "" && parseFloat(discount) > 0
            ? discountMetric
            : "",
        discount:
          discountMetric !== "" && parseFloat(discount) > 0
            ? parseFloat(discount)
            : 0,
        createdById: authUser?.institute[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
      };

      if (
        !discountError
        //  &&
        // selectedSession?.sessionPrice > 0 &&
        // selectedSession?.sessionMode !== ""
      ) {
        dispatch<any>(addSessionSendPaymentLink(body));
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (leadData) {
      setFinalPrice(leadData?.paymentDetails?.finalAmount);
    }
  }, [leadData, open]);

  React.useEffect(() => {
    const calculateFinalPrice = (): string => {
      let calculatedPrice = sessionCount * pricePerSession;

      if (discountMetric === "AMOUNT" && parseFloat(discount) > 0) {
        calculatedPrice -= parseFloat(discount);
      } else if (discountMetric === "PERCENTAGE" && parseFloat(discount) > 0) {
        calculatedPrice *= 1 - parseFloat(discount) / 100;
      }

      return calculatedPrice > 0 ? calculatedPrice.toFixed(2) : "0.00";
    };

    setFinalPrice(calculateFinalPrice());
  }, [sessionCount, discount, discountMetric, pricePerSession]);

  React.useEffect(() => {
    if (
      (sessionPayLinkSuccess !== true && sessionPayLinkSuccess !== false) ||
      (sessionPayRecordSuccess !== true && sessionPayRecordSuccess !== false)
    ) {
      setOpen(false);
    }
  }, [sessionPayLinkSuccess, sessionPayRecordSuccess]);

  React.useEffect(() => {
    if (!open) {
      setDiscountMetric("");
      setDiscount("");
      setSessionCount(1);
    }
  }, [open]);

  return (
		<div>
			<button
				className={styles.addMoreSessionBtn}
				onClick={openPaymentRecordPopup}>
				<AddIcon sx={{ marginRight: '6px', fontSize: '18px' }} />
				Add More Session
			</button>
			<Dialog
				open={open}
				onClose={closePaymentRecordPopup}
				sx={{
					zIndex: '9999999',
					minWidth: '408px',
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '400px',
						maxHeight: '535PX',
						borderRadius: '12px',
						boxShadow:
							'0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
						left: 0,
						background: '#FFFFFF',
						overflow: 'hidden',
						padding: '0px',
					},
				}}>
				<div>
					<div
						className={styles.recordPaymentForm}
						style={{ padding: '28px 28px 20px 28px' }}>
						<div className={styles.popupHeadWrap}>
							<div className={styles.formHeading}>Add More Session</div>
							<div className={styles.cross_button}>
								<Button
									sx={{
										color: '#667085',
										padding: '0px',
										display: 'flex',
										width: 'fit-content',
										minWidth: '0px',
									}}
									onClick={() => closePaymentRecordPopup()}>
									<CloseOutlinedIcon />
								</Button>
							</div>
						</div>

						<form
							action='
            '>
							<div className={styles.addSessionFields}>
								<div className={styles.addSessionFieldsChild}>
									<FormControl sx={{ m: 1, margin: '6px 0', width: '100%' }}>
										<Typography className={styles.addSessionFieldLabel}>
											Price Per Session
										</Typography>
										<NewFormTextInput
											type={'text'}
											id={'sessionPrice'}
											value={leadData?.paymentDetails?.finalAmount}
											disabled
										/>
									</FormControl>
									<div>
										<Typography className={styles.addSessionFieldLabel}>
											Number Of Sessions
										</Typography>
										<div className={styles.countIncrementField}>
											<div>{sessionCount}</div>
											<div className={styles.countIncrementBtnsWrap}>
												<button
													className={styles.countIncrementBtn}
													onClick={decrementSession}
													type='button'>
													-
												</button>
												<div className={styles.countIncrementBtnDivider}></div>
												<button
													className={styles.countIncrementBtn}
													onClick={incrementSession}
													type='button'>
													+
												</button>
											</div>
										</div>
									</div>

									<FormControl sx={{ m: 1, margin: '6px 0', width: '100%' }}>
										<Typography className={styles.addSessionFieldLabel}>
											Total Price
										</Typography>
										<NewFormTextInput
											type={'text'}
											id={'totalPrice'}
											value={totalPrice}
											disabled
										/>
									</FormControl>
									<div style={{ margin: '6px 0' }}>
										<Typography className={styles.addSessionFieldLabel}>
											Discount
										</Typography>
										<DiscountInput
											place='SESSION'
											discountMetric={discountMetric}
											discount={discount}
											handleDiscountMetric={handleDiscountMetric}
											handleDiscount={handleDiscount}
											totalPrice={totalPrice}
											selectedCurrency={selectedCurrency}
										/>
									</div>
								</div>

								<div className={styles.addSessionFinalPriceWrap}>
									<div className={styles.addSessionFinalPriceLabel}>
										Final Price
									</div>
									<div className={styles.addSessionFinalPrice}>
										{/* 			{parseFloat(finalPrice)?.toLocaleString('en-IN', {
											style: 'currency',
											currency: 'INR',
										})} */}
										{selectedCurrency + ' ' + parseFloat(finalPrice)}
									</div>
								</div>
							</div>

							<div className={styles.addSessionsBtnsWrap}>
								<Button
									variant='outlined'
									type='reset'
									sx={{
										color: '#BFBFBF',
										borderColor: '#BFBFBF',
										textTransform: 'capitalize',
										minWidth: '100px',
										height: '44px',
									}}
									onClick={recordSessionPayment}
									disabled={sessionPayLinkLoading || sessionPayRecordLoading}>
									{sessionPayRecordLoading ? (
										<CircularProgress size={20} color={'inherit'} />
									) : (
										'Record Payment'
									)}
								</Button>
								<Button
									sx={{
										textTransform: 'capitalize',
										minWidth: '100px',
										height: '44px',
									}}
									variant='contained'
									onClick={sendSessionPayment}
									disabled={sessionPayLinkLoading || sessionPayRecordLoading}>
									{sessionPayLinkLoading ? (
										<CircularProgress size={20} color={'inherit'} />
									) : (
										'Send payment link'
									)}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Dialog>
		</div>
	);
}

export default AddMoreSessionPopup;
