import React, { Suspense, lazy } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FilterSettings from "../pages/app/Inbound/components/Settings/ImportSettings/FilterSettings";
import { CircularProgress } from "@mui/material";
import { StudentListing, DemoTaskDetailsPage } from "../pages/index";
import { ImportPreview } from "../pages/index";
import ManageInboundRoot from "../pages/app/Inbound/components/ManageInbound/ManageInboundRoot";
import ManageSettingsRoot from "../pages/app/Settings/components/ManageSettings/ManageSettingsRoot";
import ManageIntegrationRouter from "./ManageIntegrationRouter";
import ManageInboundSettingsRoot from "../pages/app/Inbound/components/ManageInbound/ManageInboundSettingsRoot";
import { checkAccess } from "../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import NoAccess from "../components/NoAccess/NoAccess";
import ReportRoot from "../pages/app/Reports/ReportRoot/ReportRoot";
import ReportTable from "../pages/app/Reports/components/RecentReport/ReportTable";
import RevenueReportCreation from "../pages/app/Reports/components/ReportCreation/RevenueReportCreation";
import AttendanceReportCreation from "../pages/app/Reports/components/ReportCreation/AttendanceReportCreation";
import DetailedAttendancePage from "../pages/app/Reports/components/ReportCreation/DetailedAttendancePage";

const Dashboard = lazy(() =>
  import("../pages").then((module) => ({ default: module.Dashboard }))
);
const Home = lazy(() =>
  import("../pages").then((module) => ({ default: module.Home }))
);
const NotFound = lazy(() =>
  import("../pages").then((module) => ({ default: module.NotFound }))
);

const Nurture = lazy(() =>
  import("../pages").then((module) => ({ default: module.Nurture }))
);
const ManageRoot = lazy(() =>
  import("../pages").then((module) => ({ default: module.ManageRoot }))
);
const Tasks = lazy(() =>
  import("../pages").then((module) => ({ default: module.Tasks }))
);
const EmailDetails = lazy(() =>
  import("../pages").then((module) => ({ default: module.EmailDetails }))
);
const CompletedTasks = lazy(() =>
  import("../pages").then((module) => ({ default: module.CompletedTasks }))
);

const EmailSyncing = lazy(() =>
  import("../pages").then((module) => ({ default: module.EmailSyncing }))
);

const Integrations = lazy(() =>
  import("../pages").then((module) => ({ default: module.Integrations }))
);
// const Reports =lazy(() =>
//   import("../pages").then((module) => ({ default: module.RecentReports }))
// );

const Attendance = lazy(() =>
  import("../pages").then((module) => ({ default: module.Attendance }))
);

const AttendanceDetails = lazy(() =>
  import("../pages").then((module) => ({ default: module.AttendanceDetails }))
);

const LeadReportCreation = lazy(() =>
  import("../pages").then((module) => ({ default: module.LeadReportCreation }))
);

export default function AppRouter(props: any) {
  const { handleCompress } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;
  const profileCompletedStatus = instituteData?.isProfileCompleted;
  const batchCreated = instituteData?.batchCreated;
  const courseCreated = instituteData?.courseCreated;
  const branchCreated = instituteData?.branchCreated;
  const teamCreated = instituteData?.teamAdded;
  const profileDetailsCompleted = instituteData?.profileDetailsCompleted;

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const inboundAccess = checkAccess("INBOUND", userById?.rolePermission);
  const nurtureAccess = checkAccess("NURTURE_BOARD", userById?.rolePermission);
  const studentsAccess = checkAccess("STUDENTS", userById?.rolePermission);
  const integrationsAccess = checkAccess(
    "INTEGRATIONS",
    userById?.rolePermission
  );
  // const reportAccess = checkAccess("REPORTS", userById?.rolePermission);
  const settingsAccess = checkAccess("SETTINGS", userById?.rolePermission);

  const shouldDisableGeneralSetup =
    accountStatus === "ARCHIVED" ||
    accountStatus === "LOCKED" ||
    accountStatus === "UNARCHIVED" ||
    accountStatus === "INACTIVE";

  if (profileCompletedStatus) {
    if (
      (location.pathname.includes("Inbound") ||
        location.pathname.includes("Nurture") ||
        location.pathname.includes("Tasks") ||
        location.pathname.includes("Students") ||
        location.pathname.includes("Attendance") ||
        location.pathname.includes("Integrations") ||
        location.pathname.includes("Settings")) &&
      shouldDisableGeneralSetup
    ) {
      navigate("/app/dashboard");
      return null;
    }
  } else if (!profileCompletedStatus) {
    if (
      location.pathname.includes("Inbound") ||
      location.pathname.includes("Nurture") ||
      location.pathname.includes("Tasks") ||
      location.pathname.includes("Students") ||
      location.pathname.includes("Attendance") ||
      location.pathname.includes("Integrations") ||
      location.pathname.includes("Settings")
    ) {
      navigate("/app/home");
      return null;
    }
  }

  // if (
  //   instituteData &&
  //   !location.pathname.includes("/app/home") &&
  //   !profileCompletedStatus
  // ) {
  //   if (
  //     !courseCreated &&
  //     !batchCreated &&
  //     !branchCreated &&
  //     !teamCreated &&
  //     !profileDetailsCompleted
  //   ) {
  //     navigate("/app/manage/InstituteProfile/:id");
  //     return null;
  //   }

  //   if (
  //     !location.pathname.includes("createBranch") &&
  //     !courseCreated &&
  //     !batchCreated &&
  //     !branchCreated &&
  //     !teamCreated &&
  //     profileDetailsCompleted
  //   ) {
  //     navigate("/app/manage/createBranch");
  //     return null;
  //   }

  //   if (
  //     !location.pathname.includes("createCourse") &&
  //     !courseCreated &&
  //     !batchCreated &&
  //     branchCreated &&
  //     !teamCreated &&
  //     profileDetailsCompleted
  //   ) {
  //     navigate("/app/manage/createCourse");
  //     return null;
  //   }

  //   if (
  //     !location.pathname.includes("createTeam") &&
  //     courseCreated &&
  //     !batchCreated &&
  //     branchCreated &&
  //     !teamCreated &&
  //     profileDetailsCompleted
  //   ) {
  //     navigate("/app/manage/createTeam");
  //     return null;
  //   }

  //   if (
  //     !location.pathname.includes("createBatch") &&
  //     courseCreated &&
  //     !batchCreated &&
  //     branchCreated &&
  //     teamCreated &&
  //     profileDetailsCompleted
  //   ) {
  //     navigate("/app/manage/createBatch");
  //     return null;
  //   }
  // }

  return (
    <Routes>
      <Route
        path="/manage/*"
        element={
          <Suspense fallback={<CircularProgress />}>
            <ManageRoot />
          </Suspense>
        }
      />
      <Route
        path="/home"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="/dashboard"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Dashboard />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<CircularProgress />}>
            <NotFound />
          </Suspense>
        }
      />
      <Route
        path="/inbound/*"
        element={
          inboundAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ManageInboundRoot />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />

      <Route
        path={"/Inbound/Email/EmailDetail/:id"}
        element={
          inboundAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <EmailDetails />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />

      <Route
        path="/Inbound/ImportPreview"
        element={
          inboundAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ImportPreview />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Inbound/EmailIntegration/EmailSyncing"
        element={
          inboundAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <EmailSyncing />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Inbound/Settings/*"
        element={
          inboundAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ManageInboundSettingsRoot />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />

      <Route
        path="/Inbound/Settings/Import/filter"
        element={
          inboundAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <FilterSettings />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Inbound/Settings/ImportSetting/filter"
        element={
          inboundAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <FilterSettings />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Nurture"
        element={
          nurtureAccess ? (
            <Nurture handleCompress={handleCompress} />
          ) : (
            <NoAccess />
          )
        }
      />

      <Route
        path="/Tasks"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Tasks />
          </Suspense>
        }
      />
      <Route
        path="/Tasks/TaskCompleted"
        element={
          <Suspense fallback={<CircularProgress />}>
            <CompletedTasks />
          </Suspense>
        }
      />
      <Route
        path="/Tasks/DemoTaskDetails/:id"
        element={
          <Suspense fallback={<CircularProgress />}>
            <DemoTaskDetailsPage />
          </Suspense>
        }
      />

      <Route
        path="/Students"
        element={
          studentsAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <StudentListing />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />

      <Route
        path="/Integrations/*"
        element={
          integrationsAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ManageIntegrationRouter />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Integrations"
        element={
          integrationsAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <Integrations />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />

      <Route path="/Reports/*" element={<ReportRoot />} />
      <Route
        path={"/Reports/LeadReportCreation"}
        element={
          <Suspense fallback={<CircularProgress />}>
            <LeadReportCreation />
          </Suspense>
        }
      />
      <Route
        path={"/Reports/RevenueReportCreation"}
        element={
          <Suspense fallback={<CircularProgress />}>
            <RevenueReportCreation />
          </Suspense>
        }
      />
      <Route
        path={"/Reports/AttendanceReportCreation"}
        element={
          <Suspense fallback={<CircularProgress />}>
            <AttendanceReportCreation />
          </Suspense>
        }
      />
      <Route
        path={"/Reports/AttendanceReportCreation/DetailedAttendancePage"}
        element={
          <Suspense fallback={<CircularProgress />}>
            <DetailedAttendancePage />
          </Suspense>
        }
      />
      <Route
        path="/Settings/*"
        element={
          settingsAccess ? (
            <Suspense fallback={<CircularProgress />}>
              <ManageSettingsRoot />
            </Suspense>
          ) : (
            <NoAccess />
          )
        }
      />
      <Route
        path="/Attendance/*"
        element={
          <Suspense fallback={<CircularProgress />}>
            <Attendance />
          </Suspense>
        }
      />
      <Route
        path={"/Attendance/:id"}
        element={
          <Suspense fallback={<CircularProgress />}>
            <AttendanceDetails />
          </Suspense>
        }
      />
    </Routes>
  );
}
