import React, { useEffect, useState } from "react";
import { Dialog, TextField, Button, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./DuplicateFormPopup.module.css";
import { useSelector } from "react-redux";

const DuplicateFormPopup = (props: any) => {
  const { showDuplicatePopup, onClose, duplicateFormData, CreateWebform } =
    props;
  const { CreateWebformLoading } = useSelector((state: any) => state.webform);

  const [newFormName, setNewFormName] = useState("");

  useEffect(() => {
    // Set the default value when the popup opens
    if (duplicateFormData?.formName) {
      setNewFormName(`${duplicateFormData.formName} (Clone)`);
    }
  }, [duplicateFormData]);

  const handleFormNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFormName(event.target.value);
  };

  const handleNextClick = () => {
    const { id, ...formDataWithoutId } = duplicateFormData;
    const updatedFormData = {
      ...formDataWithoutId,
      formName: newFormName,
      formCreationComplete: formDataWithoutId?.formCreationComplete,
      type: formDataWithoutId?.type,
      branchId: formDataWithoutId?.branchId,
    };

    CreateWebform(updatedFormData);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={showDuplicatePopup}
      onClose={onClose}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "400px",
          maxHeight: "290px",
          minHeight: "165px",
          padding: "24px",
          borderRadius: "12px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "8%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#555555" }} onClick={handleClose} />
        </div>
        <div className={styles.modalTitle}>Duplicate</div>
        <TextField
          variant="outlined"
          value={newFormName}
          onChange={handleFormNameChange}
          placeholder="Enter name for your form"
          fullWidth
          inputProps={{
            style: {
              padding: "10px 12px",
              backgroundColor: "#FFF",
              borderRadius: "4px",
            },
          }}
        />
        <div className={styles.buttonSection}>
          <Button
            variant="outlined"
            sx={{
              color: "#BFBFBF",
              fontWeight: "500",
              lineHeight: "10px 24px 24px 24px",
              textTransform: "capitalize",
              borderRadius: "4px",
              border: "1px solid #BFBFBF",
              backgroundColor: "#FFF",
              "&:hover": {
                backgroundColor: "#FFF",
                border: "1px solid #BFBFBF",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={handleNextClick}
            disabled={CreateWebformLoading}
          >
            {CreateWebformLoading ? (
              <CircularProgress size={18} color={"inherit"} />
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default DuplicateFormPopup;
