import React, { useState } from "react";
import styles from "./Managebatches.module.css";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import AutoCompleteField from "../../../../../components/AutoCompleteField/AutoCompleteField";
import IconDropdown from "../../../../../components/Dropdowns/IconDropdown";
import editIcon from "../../../../../assets/images/edit-icon.svg";
import cloneIcon from "../../../../../assets/images/clone-icon.svg";
import CloneBatchPopup from "../BatchEditor/CloneBatchPopup";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MultiAssigneeSelectField from "../../../../../components/AutoCompleteField/MultiAssigneeSelectField";
import {
  checkObjectsEqual,
  getCombinedAssignees,
} from "../../../../../components/CommonFunctions/CommonFunctions";
import { updateBatch } from "../service/Batchslice";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import CancelPopup from "../../../../../components/CommonPopups/CancelPopup";
import BatchTypeManager from "./Manager/BatchTypeManager";
import SwitchInput from "../../../../../components/Switch/SwitchInput";
import DeactivationModal from "../../../../../components/DeactivationModal/DeactivationModal";

function EditBatchBasicDetails(props: any) {
  const { batchData, hasBatchAccess, setEditScreen, editScreen } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const assignees = useSelector((state: any) => state.staff.staffList);
  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const activeCourses = courses?.filter(
    (item: any) => item?.status === "ACTIVE"
  );
  const batchTypeList = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseType
  );
  const { batchUpdateLoading, batchCreateSuccess, batchUpdateSuccess } =
    useSelector((state: any) => state.batch);
  const activeInstituteData = authUser?.institute[0]?.instituteDetails;

  const [batchDetails, setBatchDetails] = React.useState<any>({
    batchName: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    minimumSeats: "",
    maximumSeats: "",
    batchType: [],
    status: "ACTIVE",
    isFirstBatch: false,
    id: "",
    assignees: [],
  });
  const combinedAssignees = getCombinedAssignees(
    assignees,
    batchDetails?.branchId
  );
  const [selectedAssignees, setSelectedAssignees] = useState<any>([]);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [errorMszs, setErrorMszs] = useState<string>("");
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);
  const [batchStatus, setBatchStatus] = React.useState<boolean>(false);
  const [showDeactivationModal, setShowDeactivationModal] =
    useState<boolean>(false);

  const [validStateBatchName, setValidStateBatchName] =
    React.useState<boolean>(true);

  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);
  const [validStateCourse, setValidStateCourse] = React.useState<boolean>(true);
  const [validateAssignee, setValidateAssignee] = React.useState<boolean>(true);
  const [validStateMinimumSeats, setValidStateMinimumSeats] =
    React.useState<boolean>(true);
  const [validStateMaximumSeats, setValidStateMaximumSeats] =
    React.useState<boolean>(true);
  const [errorMaxSeatMsg, setErrorMaxSeatMsg] = useState<boolean>(false);
  const [cloneBatchModal, setCloneBatchModal] = useState<boolean>(false);

  const setValidateStatusBatchName = React.useCallback((val: boolean) => {
    setValidStateBatchName(val);
  }, []);

  const setValidateStatusMinimumSeats = React.useCallback((val: boolean) => {
    setValidStateMinimumSeats(val);
  }, []);
  const setValidateStatusMaximumSeats = React.useCallback((val: boolean) => {
    setValidStateMaximumSeats(val);
  }, []);

  const selectedBranch = branches
    ? branches?.find(
        (branch: any) => branch.branchName === batchDetails.branchName
      )
    : "";

  const selectedCourse = courses
    ? courses?.find(
        (course: any) => course.courseName === batchDetails.courseName
      )
    : "";

  const checkAssigneeConditions = (assigneeIds: any, branchId: any) => {
    const combinedAssignees = getCombinedAssignees(assignees, branchId);

    const matchingAssignees = combinedAssignees?.filter((assignee: any) =>
      assigneeIds?.includes(assignee.id)
    );
    const filteredAssignees = matchingAssignees.filter(
      (assignee: any) =>
        checkSubDomainAccess("MANAGE", "BATCHES", assignee.rolePermission) ===
          true &&
        assignee.status === "ACTIVE" &&
        checkSubDomainAccess(
          "NURTURE_BOARD",
          "LEAD_CARD",
          assignee.rolePermission
        ) === true
    );
    const mappedAssignees = filteredAssignees?.map((assignee: any) => ({
      assignedToId: assignee.id,
      assignedToName: `${assignee.firstName} ${assignee.lastName}`,
    }));

    return mappedAssignees;
  };

  const openCloneBatchPopup = () => {
    setCloneBatchModal(true);
  };
  const closeCloneBatchPopup = (type: any) => {
    setCloneBatchModal(false);
  };

  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const cancelYesBtn = () => {
    setCancelModal(false);
    setEditScreen(false);
  };

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateBatchName(true);
      }
      setErrorType("client");
      setBatchDetails({
        ...batchDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [batchDetails]
  );

  const onBranchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setBatchDetails({
          ...batchDetails,
          branchId: value.id,
          branchName: value.branchName,
          assignees: [],
        });
        setValidStateBranch(true);
        setSelectedAssignees([]);
      } else {
        setBatchDetails({
          ...batchDetails,
          branchId: "",
          branchName: "",
          assignees: [],
        });
        setValidStateBranch(false);
        setSelectedAssignees([]);
      }
    },
    [batchDetails]
  );

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value) {
        const batchType = value.courseType.map((type: any) => ({
          batchType: {
            courseTypeName: type?.courseType?.courseTypeName,
            id: type.courseType.id,
          },
        }));
        setBatchDetails({
          ...batchDetails,
          courseId: value.id,
          courseName: value.courseName,
          coursePrice: value.coursePrice,
          batchPrice: value.coursePrice,
          batchType: batchType,
        });
      }
      setValidStateCourse(true);
    },
    [batchDetails]
  );

  const handleChangeSeats = React.useCallback(
    (event: any) => {
      const seat: any = parseInt(event.target.value);
      if (event.target.id === "maximumSeats") {
        batchDetails.minimumSeats >= seat
          ? setErrorMaxSeatMsg(true)
          : setErrorMaxSeatMsg(false);
      }
      if (event.target.id === "minimumSeats") {
        seat >= batchDetails.maximumSeats
          ? setErrorMaxSeatMsg(true)
          : setErrorMaxSeatMsg(false);
      }
      if (seat > 0 && seat <= 10000) {
        setBatchDetails({
          ...batchDetails,
          [event.target.id]: seat,
        });
      } else {
        setBatchDetails({
          ...batchDetails,
          [event.target.id]: "",
        });
      }
    },
    [batchDetails]
  );

  const handleTypeChange = React.useCallback(
    (batchType: any) => {
      setBatchDetails({
        ...batchDetails,
        batchType: batchType,
      });
    },
    [batchDetails]
  );

  const onAssigneeChange = React.useCallback(
    (event: any, newAssignees: any) => {
      const mappedAssignees = newAssignees?.map((assignee: any) => ({
        assignedToId: assignee.id,
        assignedToName: `${assignee.firstName} ${assignee.lastName}`,
      }));
      setBatchDetails({
        ...batchDetails,
        assignees: mappedAssignees,
      });
      setSelectedAssignees(newAssignees);
      if (newAssignees?.length > 0) {
        setValidateAssignee(true);
      } else {
        setValidateAssignee(false);
      }
    },
    [batchDetails]
  );

  const handleCheckChange = (event: any) => {
    if (event.target.checked === false) {
      setShowDeactivationModal(true);
    } else if (event.target.checked === true) {
      setBatchStatus(event.target.checked);
      setBatchDetails({
        ...batchDetails,
        status: "ACTIVE",
      });
    }
  };

  const handlePopupYesButton = (event: any) => {
    setBatchStatus(false);
    setBatchDetails({
      ...batchDetails,
      status: "INACTIVE",
    });
  };

  const handlePopupNoButton = (event: any) => {
    setShowDeactivationModal(false);
  };

  const filterBatchData = (data: any, reference: any) => {
    const filteredData: any = {};
    Object?.keys(reference)?.forEach((key) => {
      if (data?.hasOwnProperty(key)) {
        filteredData[key] = data[key];
      }
    });
    return filteredData;
  };
  const filteredBatchData = filterBatchData(batchData, batchDetails);
  const isObjectsEqual = checkObjectsEqual(batchDetails, filteredBatchData);

  const cancelButtonClick = () => {
    if (isObjectsEqual) {
      setEditScreen(false);
    } else {
      setCancelModal(true);
    }
  };

  const activeInstituteinLocal = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      !batchDetails.batchName && setValidStateBatchName(false);
      !batchDetails.courseId && setValidStateCourse(false);
      !batchDetails.branchId && setValidStateBranch(false);
      !batchDetails.minimumSeats && setValidStateMinimumSeats(false);
      !batchDetails.maximumSeats && setValidStateMaximumSeats(false);

      if (batchDetails?.assignees?.length > 0) {
        setValidateAssignee(true);
      } else {
        setValidateAssignee(false);
      }
      batchDetails.batchType.length === 0
        ? setErrorMszs("Select atleast one")
        : setErrorMszs("");

      batchDetails.batchName &&
        batchDetails.batchType.length > 0 &&
        batchDetails.minimumSeats !== "" &&
        batchDetails.maximumSeats !== "" &&
        batchDetails.minimumSeats < batchDetails.maximumSeats &&
        batchDetails.courseId !== "" &&
        batchDetails.branchId !== "" &&
        batchDetails?.assignees?.length > 0 &&
        dispatch<any>(
          updateBatch({
            ...batchDetails,
            instituteId: activeInstituteinLocal?.id,
            instituteName: activeInstituteinLocal?.instituteName,
            // createdById: batchData?.createdById,
            // createdByName: batchData?.createdByName,
            updatedById: authUser?.institute[0]?.id,
            updatedByName: authUser?.firstName + " " + authUser?.lastName,
            basicInfoChanged: true,
          })
        );
    } catch (error) {}
  };

  React.useEffect(() => {
    if (batchData) {
      setBatchDetails({
        batchName: batchData?.batchName,
        courseName: batchData?.courseName,
        courseId: batchData?.courseId,
        branchName: batchData?.branchName,
        branchId: batchData?.branchId,
        minimumSeats: batchData?.minimumSeats,
        maximumSeats: batchData?.maximumSeats,
        batchType: batchData?.batchType,
        status: batchData?.status,
        isFirstBatch: batchData?.isFirstBatch,
        id: batchData?.id,
        assignees: batchData?.assignees
          ? checkAssigneeConditions(
              batchData?.assignees?.map((a: any) => a.assignedToId),
              batchData?.branchId
            )
          : [],
      });
      const checkedAssignee = batchData?.assignees
        ? checkAssigneeConditions(
            batchData?.assignees?.map((a: any) => a.assignedToId),
            batchData?.branchId
          )
        : [];

      const setAssignees = assignees?.filter((item: any) =>
        checkedAssignee?.some(
          (checkedItem: any) => checkedItem.assignedToId === item.id
        )
      );
      setSelectedAssignees(setAssignees ? setAssignees : []);
      setBatchStatus(batchData.status === "ACTIVE" ? true : false);
    }
  }, [batchData, assignees, editScreen]);
  console.log("batchStatus", batchStatus);
  React.useEffect(() => {
    if (batchCreateSuccess !== true && batchCreateSuccess !== false) {
      setCloneBatchModal(false);
      setEditScreen(false);
    }
  }, [batchCreateSuccess]);

  React.useEffect(() => {
    if (batchUpdateSuccess !== true && batchUpdateSuccess !== false) {
      setEditScreen(false);
    }
  }, [batchUpdateSuccess]);

  return (
    <div className={styles.batchBasicDetailsWrapper}>
      {!editScreen ? (
        <div className={styles.batchBasicDetailsWrap}>
          <div className={styles.batchBasicDetailsHead}>
            <div className={styles.batchBasicDetailsHeadLeft}>
              <div className={styles.batchBasicDetailsBatchName}>
                {batchData?.batchName}
              </div>
              <div className={styles.batchBasicDetailsBranchName}>
                {batchData?.branchName}
              </div>
            </div>
            <div className={styles.batchBasicDetailsHeadRight}>
              <div
                className={styles.batchBasicDetailsStatus}
                style={{
                  background:
                    batchData?.status === "INACTIVE"
                      ? "rgba(236, 57, 57, 0.06)"
                      : batchData?.status === "ACTIVE"
                      ? "#ECFDF3"
                      : "#f1f1f1",
                  color:
                    batchData?.status === "INACTIVE"
                      ? "#EC3939"
                      : batchData?.status === "ACTIVE"
                      ? "#0CB78D"
                      : "#494747",
                }}
              >
                <FiberManualRecordIcon sx={{ fontSize: "10px" }} />
                {batchData?.status?.toLowerCase()}
              </div>
              {hasBatchAccess && (
                <IconDropdown
                  options={[
                    {
                      label: "Edit",
                      onClick: () => {
                        setEditScreen(true);
                      },
                      icon: editIcon,
                      style: { color: "#667085" },
                    },
                    ...(batchData?.status !== "DRAFT"
                      ? [
                          {
                            label: "Clone",
                            onClick: () => openCloneBatchPopup(),
                            icon: cloneIcon,
                            style: { color: "#667085" },
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            </div>
          </div>
          <div style={{ padding: "20px 39px" }}>
            <div style={{ marginBottom: "26px" }}>
              <div className={styles.batchBasicDetailsLabel}>Course</div>
              <div
                className={styles.batchBasicDetailsValue}
                style={{ textTransform: "capitalize" }}
              >
                {batchData?.courseName}
              </div>
            </div>
            <div style={{ marginBottom: "26px", display: "flex", gap: "48px" }}>
              <div>
                <div className={styles.batchBasicDetailsLabel}>Type</div>
                <div
                  className={styles.batchBasicDetailsValue}
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  {batchData?.batchType
                    ? batchData?.batchType
                        ?.filter(
                          (item: any) =>
                            item?.batchType?.courseTypeName?.toLowerCase() ===
                            "classroom"
                        )
                        ?.map(
                          (batch: any) => `${batch?.batchType?.courseTypeName}`
                        )
                        ?.join(" | ")
                    : "-"}
                </div>
              </div>
              <div>
                <div className={styles.batchBasicDetailsLabel}>Status</div>
                <div className={styles.batchBasicDetailsValue}>
                  <div
                    className={styles.batchBasicDetailsBatchStatus}
                    style={{
                      background:
                        batchData?.batchStatus === "UPCOMING"
                          ? "#F1A000"
                          : "#11C89B",
                    }}
                  >
                    {batchData?.batchStatus?.toLowerCase()}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.batchBasicDetailsLabel}>
                  Conduct Type
                </div>
                <div className={styles.batchBasicDetailsValue}>
                  <div className={styles.batchBasicDetailsConductType}>
                    {batchData?.batchConductType === "REGULAR_BATCH"
                      ? "Regular"
                      : "Demo"}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "48px" }}>
              <div>
                <div className={styles.batchBasicDetailsLabel}>Min Seats</div>
                <div
                  className={styles.batchBasicDetailsValue}
                  style={{ textTransform: "capitalize" }}
                >
                  {batchData?.minimumSeats ? batchData?.minimumSeats : "-"}
                </div>
              </div>
              <div>
                <div className={styles.batchBasicDetailsLabel}>Max Seats</div>
                <div className={styles.batchBasicDetailsValue}>
                  {batchData?.maximumSeats ? batchData?.maximumSeats : "-"}
                </div>
              </div>
              <div>
                <div className={styles.batchBasicDetailsLabel}>Assignee</div>
                <div
                  className={styles.batchBasicDetailsValue}
                  style={{ textTransform: "capitalize" }}
                >
                  {batchData?.assignees
                    ? batchData?.assignees
                        ?.map((assignee: any) => `${assignee?.assignedToName}`)
                        ?.join(", ")
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.batchBasicDetailsEditWrap}>
          <div className={styles.batchBasicDetailsEditSectionTitle}>
            Edit Basic Details
          </div>
          <NewFormTextInput
            validate={true}
            setValidStatus={setValidateStatusBatchName}
            label="Batch Name"
            type="text"
            id={"batchName"}
            error={!validStateBatchName}
            value={batchDetails.batchName}
            onChange={handleChange}
            showErrorMessage={showErrMsz}
            errorType={errorType}
            autoComplete="true"
            require={true}
            inputProps={{ maxLength: 50 }}
          />

          <div style={{ display: "flex", gap: "27px", margin: "24px 0" }}>
            <div style={{ flexBasis: "100%" }}>
              <AutoCompleteField
                id="branchId"
                label="Branch"
                options={branches}
                optionLabel={"branchName"}
                onChange={onBranchChange}
                value={selectedBranch}
                disableClearable={batchDetails?.branchId === "" ? true : false}
                required={true}
                validState={validStateBranch}
              />
            </div>
            <div style={{ flexBasis: "100%" }}>
              <AutoCompleteField
                id="courseId"
                label="Course"
                options={activeCourses}
                optionLabel={"courseName"}
                onChange={onCourseChange}
                value={selectedCourse}
                disableClearable={batchDetails?.courseId === "" ? true : false}
                required={true}
                validState={validStateCourse}
              />
            </div>
          </div>

          <div style={{ display: "flex", gap: "27px", margin: "24px 0" }}>
            <div style={{ display: "flex", gap: "14px", flexBasis: "100%" }}>
              <div style={{ flexBasis: "100%" }}>
                <NewFormTextInput
                  validate={true}
                  setValidStatus={setValidateStatusMinimumSeats}
                  label="Minimum Seats"
                  type="number"
                  id={"minimumSeats"}
                  error={!validStateMinimumSeats}
                  value={batchDetails.minimumSeats}
                  onChange={handleChangeSeats}
                  showErrorMessage={showErrMsz}
                  errorType={errorType}
                  autoComplete="true"
                  require={true}
                  inputProps={{ min: 1, max: 10000 }}
                  fullWidth
                />
              </div>
              <div style={{ flexBasis: "100%" }}>
                <NewFormTextInput
                  validate={true}
                  setValidStatus={setValidateStatusMaximumSeats}
                  label="Maximum Seats"
                  type="number"
                  id={"maximumSeats"}
                  error={!validStateMaximumSeats}
                  value={batchDetails.maximumSeats}
                  onChange={handleChangeSeats}
                  showErrorMessage={showErrMsz}
                  errorType={errorType}
                  autoComplete="true"
                  require={true}
                  inputProps={{ min: 1, max: 10000 }}
                  fullWidth
                />
                {batchDetails.maximumSeats !== "" && errorMaxSeatMsg ? (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0",
                      fontSize: "12px",
                    }}
                  >
                    Max Seat should be greater than Min seat
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div style={{ flexBasis: "100%" }}>
              <MultiAssigneeSelectField
                id="assgneeId"
                label="Assignee"
                options={combinedAssignees}
                onChange={onAssigneeChange}
                value={selectedAssignees}
                disableClearable={selectedAssignees?.length > 0 ? true : false}
                required={true}
                validState={validateAssignee}
              />
            </div>
          </div>
          <div
            style={{
              margin: "24px 0",
              display: "flex",
              alignItems: "flex-end",
              gap: "30px",
              flexWrap: "wrap",
            }}
          >
            <BatchTypeManager
              handleTypeChange={handleTypeChange}
              errorMszs={errorMszs}
              selectedType={batchDetails?.batchType}
              batchTypeList={batchTypeList?.filter(
                (item: any) =>
                  item.courseTypeName?.toLowerCase() === "classroom"
              )}
              setBatchDetails={setBatchDetails}
              batchDetails={batchDetails}
              batchData={batchData}
            />
            {activeInstituteData?.isProfileCompleted &&
              batchDetails.status !== "DRAFT" && (
                <div>
                  <SwitchInput
                    switchStatus={batchStatus}
                    handleSwitch={handleCheckChange}
                    disabled={
                      batchData?.batchStatus === "ONGOING" ||
                      (batchData?.studentCount !== 0 &&
                        batchData?.batchConductType === "REGULAR_BATCH") ||
                      (batchData?.demoStudents !== 0 &&
                        batchData?.batchConductType === "DEMO_BATCH")
                    }
                    id={"status"}
                    label={"Active"}
                    labelPlacement={"start"}
                    tooltipTitle={
                      batchData?.batchStatus === "ONGOING"
                        ? "An Ongoing batch cannot be deactivated"
                        : (batchData?.studentCount !== 0 &&
                            batchData?.batchConductType === "REGULAR_BATCH") ||
                          (batchData?.demoStudents !== 0 &&
                            batchData?.batchConductType === "DEMO_BATCH")
                        ? "Batch cannot be deactivated with active Students in it."
                        : ""
                    }
                  />

                  {showDeactivationModal && (
                    <DeactivationModal
                      type={"batch"}
                      handlePopupYesButton={handlePopupYesButton}
                      handlePopupNoButton={handlePopupNoButton}
                    />
                  )}
                </div>
              )}
          </div>
          <CancelPopup
            open={cancelModal}
            onClose={closeCancelModal}
            noBtnClick={closeCancelModal}
            yesBtnClick={cancelYesBtn}
          />
          <div className={styles.editSectionBtnWrap}>
            <CancelBtn onClick={() => cancelButtonClick()} />
            <SaveBtn
              onClick={handleSubmit}
              disabled={batchUpdateLoading || isObjectsEqual}
              loader={batchUpdateLoading}
            />
          </div>
        </div>
      )}
      <CloneBatchPopup
        isOpen={cloneBatchModal}
        onClose={closeCloneBatchPopup}
        batchData={batchData}
      />
    </div>
  );
}

export default EditBatchBasicDetails;
