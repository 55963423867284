import { Tab, Tabs, Tooltip } from "@mui/material";
import React, { useState } from "react";
import SessionsTabItem from "./SessionsTabItem";
import AddIcon from "@mui/icons-material/Add";
import styles from "./LeadSession.module.css";

function SessionsTab(props: any) {
  const {
		bookedSessions,
		setEditSchedule,
		setEditScheduleData,
		setCancelPopup,
		markAttendance,
		setSchedule,
		hasLeadAccess,
		schedule,
		editSchedule,
		setAttendancePopup,
	} = props;

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      {bookedSessions?.upcomingSessionList?.length === 0 &&
      bookedSessions?.completedSessionList?.length === 0 &&
      bookedSessions?.overdueSessionList?.length === 0 ? (
        <div className={styles.noDataWrap}>
          <div className={styles.noDataText}>No Scheduled Session yet </div>
          <div>
            {hasLeadAccess && !schedule && !editSchedule && (
              <Tooltip
                arrow
                title={
                  bookedSessions?.userSessionData?.balanceSession === 0
                    ? "Please add session"
                    : ""
                }
                PopperProps={{ style: { zIndex: 9999999 } }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <button
                    className={styles.addMoreSessionBtn}
                    onClick={() => setSchedule(true)}
                    disabled={
                      bookedSessions?.userSessionData?.balanceSession === 0
                    }
                  >
                    <AddIcon sx={{ marginRight: "6px", fontSize: "18px" }} />{" "}
                    Schedule Session
                  </button>
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      ) : (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            style={{ borderBottom: "1px solid #E9E9E9", marginBottom: "16px" }}
          >
            <Tab
              label={"Upcoming"}
              sx={{
                textTransform: "capitalize",
                color: "#B4B4B4",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                paddingLeft: "0",
                paddingRight: "0",
              }}
            />
            <Tab
              label={"Completed"}
              sx={{
                textTransform: "capitalize",
                color: "#B4B4B4",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                paddingLeft: "0",
                paddingRight: "0",
                marginLeft: "20px",
              }}
            />
            <Tab
              label={"Overdue"}
              sx={{
                textTransform: "capitalize",
                color: "#B4B4B4",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                paddingLeft: "0",
                paddingRight: "0",
                marginLeft: "20px",
              }}
            />
          </Tabs>
          <div>
            {value === 0 && (
              <>
                {bookedSessions?.upcomingSessionList?.length > 0 ? (
                  <>
                    {bookedSessions?.upcomingSessionList?.map((item: any) => (
                      <SessionsTabItem
                        sessionData={item}
                        setEditSchedule={setEditSchedule}
                        setEditScheduleData={setEditScheduleData}
                        setCancelPopup={setCancelPopup}
                        markAttendance={markAttendance}
                        hasLeadAccess={hasLeadAccess}
                        setAttendancePopup={setAttendancePopup}
                      />
                    ))}
                  </>
                ) : (
                  <div className={styles.noDataWrap}>
                    <div className={styles.noDataText}>No data available</div>
                  </div>
                )}
              </>
            )}
            {value === 1 && (
              <>
                {bookedSessions?.completedSessionList?.length > 0 ? (
                  <>
                    {bookedSessions?.completedSessionList?.map((item: any) => (
                      <SessionsTabItem
                        sessionData={item}
                        setEditSchedule={setEditSchedule}
                        setEditScheduleData={setEditScheduleData}
                        setCancelPopup={setCancelPopup}
                        markAttendance={markAttendance}
                        hasLeadAccess={hasLeadAccess}
                        setAttendancePopup={setAttendancePopup}
                      />
                    ))}
                  </>
                ) : (
                  <div className={styles.noDataWrap}>
                    <div className={styles.noDataText}>No data available</div>
                  </div>
                )}
              </>
            )}
            {value === 2 && (
              <>
                {bookedSessions?.overdueSessionList?.length > 0 ? (
                  <>
                    {bookedSessions?.overdueSessionList?.map((item: any) => (
                      <SessionsTabItem
                        sessionData={item}
                        setEditSchedule={setEditSchedule}
                        setEditScheduleData={setEditScheduleData}
                        setCancelPopup={setCancelPopup}
                        markAttendance={markAttendance}
                        hasLeadAccess={hasLeadAccess}
                        setAttendancePopup={setAttendancePopup}
                      />
                    ))}
                  </>
                ) : (
                  <div className={styles.noDataWrap}>
                    <div className={styles.noDataText}>No data available</div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SessionsTab;
