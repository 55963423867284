import React, { CSSProperties, useState, useEffect } from "react";
import { Typography, Box, Tab, Tabs } from "@mui/material";
import Subscription from "./subscription/Subscription";
import Address from "./Address/Address";
import BillingAdmins from "./BillingAdmins/BillingAdmins";
import BillingHistory from "./BillingHistory/BillingHistory";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import {
  resetAutoDebitStatuses,
  resetCancellation,
  resetPaymentInvoice,
} from "../service/Billingslice";
import { useDispatch } from "react-redux";
import AutoDebit from "./AutoDebit/AutoDebit";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#11C89B",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function Billing(props: any) {
  const {
    GetStaffList,
    GetSubscriptionByInstituteId,
    instituteSubscriptionData,
    GetBillingByInstituteId,
    instituteBillingData,
    UpdateInstituteSubscriptionStatus,
    UpdateBillingAdmins,
    GetBillingAdmins,
    billingAdmins,
    RemoveBillingAdmins,
    billingAdminError,
    billingAdminSuccess,
    billingAddress,
    UpdateBillingAddress,
    GetBillingAddress,
    paymentHistory,
    GetPaymentHistoryByPagination,
    subscriptionStatussuccess,
    subscriptionStatusError,
    GetPaymentInvoice,
    paymentInvoice,
    GetInstituteById,
    Invoicesuccess,
    billingDataLoading,
    BillingAutoDebit,
    autoDebitSuccess,
    autoDebitError,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const billingStatus =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails.billingStatus;

  const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

  const staffs = useSelector((state: any) => state.staff.staffList);

  const [value, setValue] = useState(0);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabSelect = (value: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("billingTab", value);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };
  const selectBillingAdminTab = () => {
    if (userFromLocalStorage) {
      GetBillingAdmins(userFromLocalStorage.id);
      GetStaffList(userFromLocalStorage.id && userFromLocalStorage.id);
    }
  };

  const selectBillingAddressTab = () => {
    if (userFromLocalStorage) {
      GetBillingAddress(userFromLocalStorage.id);
    }
  };

  const selectPaymentHistoryTab = () => {
    const lastEvaluatedKey = "";
    const limit = 10;
    const lastEvaluatedSortKey = "";
    if (userFromLocalStorage) {
      GetPaymentHistoryByPagination(
        userFromLocalStorage.id,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey
      );
    }
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const billingTab = urlSearchParams.get("billingTab");

    if (billingTab) {
      if (billingTab === "subscriptions") {
        setValue(0);
      } else if (billingTab === "address") {
        setValue(1);
        if (userFromLocalStorage) {
          GetBillingAddress(userFromLocalStorage.id);
        }
      }
      //  else if (billingTab === "billingAdmins") {
      //   setValue(2);
      //   if (userFromLocalStorage) {
      //     GetBillingAdmins(userFromLocalStorage.id);
      //     GetStaffList(userFromLocalStorage.id && userFromLocalStorage.id);
      //   }
      // }
      else if (billingTab === "billingHistory") {
        setValue(2);
        if (userFromLocalStorage) {
          const lastEvaluatedKey = "";
          const limit = 10;
          const lastEvaluatedSortKey = "";

          GetPaymentHistoryByPagination(
            userFromLocalStorage.id,
            lastEvaluatedKey,
            limit,
            lastEvaluatedSortKey
          );
        }
      } else if (billingTab === "autoDebit") {
        setValue(3);
      }
    }
  }, [
    setValue,
    GetBillingAdmins,
    GetStaffList,
    userFromLocalStorage,
    GetBillingAddress,
    GetPaymentHistoryByPagination,
  ]);

  const instituteDetails = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );
  const instituteBillingStatus =
    instituteDetails && instituteDetails.billingStatus;

  useEffect(() => {
    if (userFromLocalStorage) {
      GetInstituteById(userFromLocalStorage.id && userFromLocalStorage.id);
    }
  }, [GetInstituteById, userFromLocalStorage]);

  useEffect(() => {
    if (userFromLocalStorage && instituteBillingStatus) {
      GetBillingByInstituteId(
        userFromLocalStorage.id && userFromLocalStorage.id,
        instituteBillingStatus === "ARCHIVED" ? "DEACTIVATED" : "ACTIVE"
      );
    }
  }, [GetBillingByInstituteId, userFromLocalStorage, instituteBillingStatus]);

  React.useEffect(() => {
    if (billingAdminError) {
    }
  }, [billingAdminError]);

  React.useEffect(() => {
    if (billingAdminSuccess !== true && billingAdminSuccess !== false) {
      if (userFromLocalStorage) {
        GetBillingAdmins(userFromLocalStorage.id);
        GetStaffList(userFromLocalStorage.id && userFromLocalStorage.id);
      }
    }
  }, [
    billingAdminSuccess,
    userFromLocalStorage,
    GetBillingAdmins,
    GetStaffList,
  ]);

  React.useEffect(() => {
    if (subscriptionStatusError) {
      dispatch(resetCancellation());
    }
  }, [subscriptionStatusError]);

  React.useEffect(() => {
    if (
      subscriptionStatussuccess !== true &&
      subscriptionStatussuccess !== false
    ) {
      if (userFromLocalStorage) {
        GetInstituteById(userFromLocalStorage.id && userFromLocalStorage.id);
        GetBillingByInstituteId(
          userFromLocalStorage.id && userFromLocalStorage.id,
          instituteBillingStatus === "ARCHIVED" ? "DEACTIVATED" : "ACTIVE"
        );
      }
      dispatch(resetCancellation());
    }
  }, [
    subscriptionStatussuccess,
    userFromLocalStorage,
    GetBillingByInstituteId,
  ]);

  useEffect(() => {
    if (Invoicesuccess === true && paymentInvoice) {
      dispatch(resetPaymentInvoice());

      const decodedData = atob(paymentInvoice);
      const byteArray = new Uint8Array(decodedData.length);

      for (let i = 0; i < decodedData.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "downloaded.pdf"; // Specify the file name here
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  }, [Invoicesuccess]);

  React.useEffect(() => {
    if (autoDebitSuccess !== true && autoDebitSuccess !== false) {
      if (userFromLocalStorage) {
        GetInstituteById(userFromLocalStorage?.id);
      }
      dispatch(resetAutoDebitStatuses());
    }
  }, [autoDebitSuccess]);

  React.useEffect(() => {
    if (autoDebitError !== true && autoDebitError !== false) {
      dispatch(resetAutoDebitStatuses());
    }
  }, [autoDebitError]);
  return (
    <Box
      sx={{
        padding: "28px 28px",
        // maxHeight: "calc(100vh - 94px)",
        // overflowY: "auto",
      }}
    >
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="billing tabs"
        sx={{
          mt: 1,
          mb: 1,
          backgroundColor: "white",
          borderBottom: "1px solid #E9E9E9",
          "& .MuiTabs-flexContainer": {
            gap: "24px",
          },
          "& .MuiTabs-indicator": {
            height: "1px", // Adjust the height as desired
          },
        }}
      >
        <Tab
          label="Subscriptions"
          {...a11yProps(0)}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",

            "& .Mui-selected": {
              color: "#11C89B",
            },
          }}
          onClick={() => {
            handleTabSelect("subscriptions");
            GetInstituteById(userFromLocalStorage?.id);
          }}
        />
        <Tab
          label="Address"
          {...a11yProps(1)}
          sx={{
            textTransform: "capitalize",
            padding: "0",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
          }}
          onClick={() => {
            handleTabSelect("address");
            selectBillingAddressTab();
          }}
        />
        {/* <Tab
          label="Billing Admins"
          {...a11yProps(2)}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
          }}
          onClick={() => {
            handleTabSelect("billingAdmins");
            selectBillingAdminTab();
          }}
        /> */}
        <Tab
          label="Billing History"
          {...a11yProps(2)}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
          }}
          onClick={() => {
            handleTabSelect("billingHistory");
            selectPaymentHistoryTab();
          }}
        />
        <Tab
          label="Auto Debit"
          {...a11yProps(3)}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
          }}
          onClick={() => {
            handleTabSelect("autoDebit");
          }}
        />
      </Tabs>
      <div
        style={{
          padding: "28px 28px",
          maxHeight: "calc(100vh - 165px)",
          overflowY: "auto",
        }}
      >
        <TabPanel value={value} index={0}>
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
            }}
          >
            <Subscription
              instituteBillingData={instituteBillingData}
              UpdateInstituteSubscriptionStatus={
                UpdateInstituteSubscriptionStatus
              }
              billingDataLoading={billingDataLoading}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
            }}
          >
            <Address billingAddress={billingAddress} />
          </Box>
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
            }}
          >
            <BillingAdmins
              staffs={staffs}
              UpdateBillingAdmins={UpdateBillingAdmins}
              billingAdmins={billingAdmins}
              RemoveBillingAdmins={RemoveBillingAdmins}
              authUser={authUser}
              billingAdminSuccess={billingAdminSuccess}
            />
          </Box>
        </TabPanel> */}
        <TabPanel value={value} index={2}>
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
            }}
          >
            <BillingHistory
              paymentHistory={paymentHistory}
              GetPaymentHistoryByPagination={GetPaymentHistoryByPagination}
              GetPaymentInvoice={GetPaymentInvoice}
              paymentInvoice={paymentInvoice}
              Invoicesuccess={Invoicesuccess}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
            }}
          >
            <AutoDebit BillingAutoDebit={BillingAutoDebit} />
          </Box>
        </TabPanel>
      </div>
    </Box>
  );
}

export default Billing;
