import React, { useEffect } from "react";
import gupshuplogo from "./assets/gupshuplogo.svg";
import styles from "./Whatsapp.module.css";
import { Button } from "@mui/material";
import GupshupConfigComponent from "./GupshupConfigComponent";
import { useAuthUser } from "react-auth-kit";
import GupshupInfo from "./GupshupInfo";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";

const WhatsAppConfig = (props: any) => {
  const {
    GetWhatsappConfig,
    gupshupConfig,
    SaveWhatsappConfiguration,
    GetWhatsappConfigInstitute,
    gupshupConfigSuccess,
    gupshupConfigError,
    mailConfigsList,
    GetMailConfigList,
    DeleteGupshup,
    deleteGupshupSuccess,
    deleteGupshupError,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const [whatsAppConfig, setWhatsAppConfig] = React.useState({
    instituteId: authUser?.institute?.[0]?.instituteId,
    apiKey: "",
    phoneNumber: "",
    appName: "",
    appId: "",
    status: "ACTIVE",
    notificationsControl: {
      paymentReminderNotification: false,
      demoNotification: false,
      batchEditNotification: false,
      sendPaymentReceiptNotification: false,
    },
  });

  const [showConfigComponent, setShowConfigComponent] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage) {
      GetMailConfigList(userFromLocalStorage.instituteId);
    }
  }, [GetMailConfigList]);

  useEffect(() => {
    const instituteInfo = authUser?.institute[0]?.instituteId;
    if (instituteInfo) {
      GetWhatsappConfigInstitute(instituteInfo, "ACTIVE");
    }
  }, [GetWhatsappConfigInstitute, gupshupConfigSuccess, deleteGupshupSuccess]);

  React.useEffect(() => {
    if (copied) {
      // setSnackState((snackState: SnackStateProp) => ({
      //   ...snackState,
      //   message: "Copied!!!",
      //   snackOpen: true,
      //   snackSeverity: "success",
      // }));
      dispatch(
        setToastNotification({
          message: String("copied!"),
          type: "success",
          snackOpen: true,
        })
      );
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  // React.useEffect(() => {
  //   if (gupshupConfigSuccess) {
  //     setSnackState((snackState: SnackStateProp) => ({
  //       ...snackState,
  //       message: "Whatsapp Integrated Successfully",
  //       snackOpen: true,
  //       snackSeverity: "success",
  //     }));
  //   }
  // }, [gupshupConfigSuccess]);

  // React.useEffect(() => {
  //   if (gupshupConfigError) {
  //     setSnackState((snackState: SnackStateProp) => ({
  //       ...snackState,
  //       message: String(gupshupConfigError),
  //       snackOpen: true,
  //       snackSeverity: "error",
  //     }));
  //   }
  // }, [gupshupConfigError]);

  // React.useEffect(() => {
  //   if (deleteGupshupSuccess) {
  //     setSnackState((snackState: SnackStateProp) => ({
  //       ...snackState,
  //       message: "Whatsapp Deleted Successfully",
  //       snackOpen: true,
  //       snackSeverity: "success",
  //     }));
  //   }
  // }, [deleteGupshupSuccess]);

  // React.useEffect(() => {
  //   if (deleteGupshupError) {
  //     setSnackState((snackState: SnackStateProp) => ({
  //       ...snackState,
  //       message: String(gupshupConfigError),
  //       snackOpen: true,
  //       snackSeverity: "error",
  //     }));
  //   }
  // }, [deleteGupshupError]);

  return (
    <>
      {gupshupConfig[0]?.status !== "ACTIVE" ? (
        <div style={{ height: "100%", overflowY: "scroll" }}>
          {showConfigComponent === false ? (
            <div className={styles.whatsappContainer}>
              <div className="logo-section">
                <img src={gupshuplogo} alt="Gupshup Logo" />
              </div>
              <h2>WhatsApp Messaging by Gupshup</h2>
              <p>
                Level up your lead interactions with Gupshup's WhatsApp
                messaging integration and take your lead engagement to new
                heights!
              </p>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => setShowConfigComponent(true)}
              >
                Let's start
              </Button>
              <div className={styles.registerSection}>
                Not a Gupshup user yet?{" "}
                <a href="https://www.gupshup.io/">Register Now</a>
              </div>
            </div>
          ) : (
            <GupshupConfigComponent
              GetWhatsappConfig={GetWhatsappConfig}
              gupshupConfig={gupshupConfig}
              SaveWhatsappConfiguration={SaveWhatsappConfiguration}
              setShowConfigComponent={setShowConfigComponent}
              setCopied={setCopied}
              whatsAppConfig={whatsAppConfig}
              setWhatsAppConfig={setWhatsAppConfig}
            />
          )}
        </div>
      ) : (
        <>
          <GupshupInfo
            gupshupConfig={gupshupConfig}
            mailConfigsList={mailConfigsList}
            DeleteGupshup={DeleteGupshup}
            SaveWhatsappConfiguration={SaveWhatsappConfiguration}
            whatsAppConfig={whatsAppConfig}
            setWhatsAppConfig={setWhatsAppConfig}
          />
        </>
      )}
    </>
  );
};

export default WhatsAppConfig;
