import { Button, Tooltip } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import styles from "../DemoBooking.module.css";

function PickTime(props: any) {
  const { setStage, setWay, handleWaySelect, hasLeadAccess } = props;
  return (
    <div className={styles.pickTimeWrapper}>
      <div className={styles.noDemoScreen}>
        <div className={styles.noDemoScreenDesc}>
          No Existing batch or demo available on Selected date
        </div>

        <Tooltip
          arrow
          title={hasLeadAccess === false ? "Oops! No permission." : ""}
          PopperProps={{ style: { zIndex: 9999999 } }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#101828",
                color: "#fff",
                fontSize: "12px",
                fontWeight: "500",
                padding: "8px 12px",
                zIndex: "9999999",
                "& .MuiTooltip-arrow": {
                  color: "#101828",
                  zIndex: "9999999",
                },
              },
            },
          }}
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: 14,
                textTransform: "capitalize",
                boxShadow: "none",
                height: "38px",
              }}
              size={"small"}
              onClick={() => {
                setWay(2);
                setStage(2);
                handleWaySelect();
              }}
              disabled={hasLeadAccess === false ? true : false}
            >
              <AddIcon sx={{ marginRight: "10px", fontSize: "18px" }} />
              Pick a New Time
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default PickTime;
