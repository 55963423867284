import { Box, CircularProgress, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../../../../../components/CancelModal/CancelModal.module.css";
import { useSelector } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};

function CancelPaymentLinkPopup(props: any) {
  const { open, onClose, noButtonClick, yesButtonClick } = props;
  const { recordPaymentUpdateLoading } = useSelector(
    (state: any) => state.nurture
  );
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div onClick={onClose} className={styles.closeModalButton}>
              <CloseIcon />
            </div>
            <div className={styles.modalTitle}>
              Are you sure you want to cancel the payment Link?
            </div>
            <div className={styles.modalDes}>
              It will invalidate the link sent to the student and no payment
              will be further recorded using that link.
            </div>
            <div className={styles.buttonSection}>
              <button onClick={noButtonClick} className={styles.noButton}>
                No
              </button>
              <button
                onClick={yesButtonClick}
                className={styles.yesButton}
                disabled={recordPaymentUpdateLoading}
              >
                {recordPaymentUpdateLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Yes"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CancelPaymentLinkPopup;
