import React from "react";
import { Box } from "@mui/material";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import getTeamMember from "../CommonFunctions/CommonFunctions";

function ActivityNegotiation(props: any) {
  const { data, employees, selectedCurrency } = props;

	const discountAmount =
		data?.negotiationData?.discountMetric === 'AMOUNT'
			? data?.negotiationData?.discount
			: (data?.negotiationData?.discount / 100) *
			  data?.negotiationData?.basePrice;

	const finalBatchPrice = data?.negotiationData?.basePrice - discountAmount;
	return (
		<>
			<Box
				sx={{
					width: '100%',
					margin: '0 15px 22px 15px',
				}}>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<div className={styles.activityTop}>
						<div className={styles.activityDateStyle}>
							{moment(data?.creationDate).format('DD MMM YYYY, hh:mm A')}
						</div>
						<div className={styles.activityHeadStage}>
							<div className={styles.activityHeadStageIcon}>
								<img src={stageDot} alt='' />
							</div>
							<div>{data?.currentStage}</div>
						</div>
						<div className={styles.activityHeadName}>
							<div className={styles.activityHeadStageIcon}>
								<img src={nameLine} alt='' />
							</div>
							<div style={{ textTransform: 'capitalize' }}>
								{/* {data?.updatedByName} */}
								{data?.updatedByName === ''
									? getTeamMember(data?.createdById, employees)?.firstName +
									  ' ' +
									  getTeamMember(data?.createdById, employees)?.lastName
									: getTeamMember(data?.updatedById, employees)?.firstName +
									  ' ' +
									  getTeamMember(data?.updatedById, employees)?.lastName}
							</div>
						</div>
					</div>
					<div className={styles.activityDemoTitle}>
						{data?.activityType === 'NEGOTIATION_EDITED'
							? 'Negotiation Edited'
							: 'Negotiation Saved'}
					</div>
					<div className={styles.activityPaymentStatementBox}>
						<div className={styles.activityPaymentStatementItem}>
							<div className={styles.activityPaymentStatementLabel}>
								Batch Price:
							</div>
							<div className={styles.activityPaymentStatementValue}>
								{selectedCurrency + ' ' + data?.negotiationData?.basePrice}
							</div>
						</div>
						<div className={styles.activityPaymentStatementItem}>
							<div className={styles.activityPaymentStatementLabel}>
								Discount:
							</div>
							<div className={styles.activityPaymentStatementValue}>
								{selectedCurrency + ' ' + discountAmount}
							</div>
						</div>
						<div className={styles.activityPaymentStatementItem}>
							<div className={styles.activityPaymentStatementLabel}>
								Final Price:
							</div>
							<div className={styles.activityPaymentStatementValue}>
								{selectedCurrency + ' ' + finalBatchPrice}
							</div>
						</div>
					</div>
				</Box>
			</Box>
		</>
	);
}

export default ActivityNegotiation;
