import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
} from "@mui/material";
import React from "react";
import styles from "../Managebatches.module.css";
import DeleteBtn from "../../../../../../components/Buttons/DeleteBtn";
import DeletePopup from "../../../../../../components/CommonPopups/DeletePopup";
import { deleteDemoStudent, resetBatchStatus } from "../../service/Batchslice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function BatchStudents(props: any) {
  const { batchData, hasBatchAccess } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { demoStudentDeleteSuccess, demoStudentDeleteLoading } = useSelector(
    (state: any) => state.batch
  );

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [deleteStudent, setDeleteStudent] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleDelete = (item: any) => {
    setDeleteStudent(item);
    setDeleteModal(true);
  };
  const handleDeleteClosePopup = () => {
    setDeleteStudent("");
    setDeleteModal(false);
  };

  const handleDeleteStudent = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        batchId: batchData?.id,
        demoId: deleteStudent?.id,
        enquiryId: deleteStudent?.enquiryId,
        createdById: authUser?.institute?.[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
        instituteId: authUser?.institute[0]?.instituteId,
      };
      dispatch<any>(deleteDemoStudent(body));
    } catch (error) {}
  };

  React.useEffect(() => {
    if (
      demoStudentDeleteSuccess !== true &&
      demoStudentDeleteSuccess !== false
    ) {
      dispatch(resetBatchStatus());
      setDeleteStudent("");
      setDeleteModal(false);
    }
  }, [demoStudentDeleteSuccess]);

  return (
    <div className={styles.studentSec}>
      <Accordion
        expanded={expanded === `panel1`}
        onChange={handleChange(`panel1`)}
        sx={{
          border: "1px solid #E9E9E9",
          boxShadow: "none",
          borderRadius: "6PX",
          marginBottom: "10PX",

          transition: "0.1s ease-in-out",
          "&:before": {
            display: "none !important",
          },
        }}
      >
        <AccordionSummary
          sx={{
            color: "#142C44",
            borderRadius: "6px 6px 0px 0",
            border: "none",
          }}
          expandIcon={null}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={styles.studentAccordHeadTitle}>
            {`Students (${
              batchData?.batchConductType === "DEMO_BATCH"
                ? batchData?.demoStudents?.length
                : batchData?.students?.length
            })`}
          </div>
        </AccordionSummary>

        <AccordionDetails
          className={styles.summaryDesc}
          sx={{
            borderRadius: "0 0 6px 6px",
            border: "none",
          }}
        >
          <div className={styles.studentItemsWrap}>
            {(batchData?.batchConductType === "DEMO_BATCH"
              ? batchData?.demoStudents
              : batchData?.students
            )?.map((item: any) => (
              <div key={item?.id} className={styles.studentItemRow}>
                <div className={styles.studentItemRowLeft}>
                  <Avatar
                    alt="avatar"
                    sx={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                      fontSize: "12px",
                      background: "#4E647A",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {batchData?.batchConductType === "DEMO_BATCH"
                      ? item?.studentFullName?.charAt(0)
                      : item?.studentFirstName?.charAt(0)}
                  </Avatar>
                  {batchData?.batchConductType === "DEMO_BATCH"
                    ? item?.studentFullName
                    : item?.studentFirstName + " " + item?.studentLastName}
                </div>
                {batchData?.batchConductType === "DEMO_BATCH" &&
                  hasBatchAccess && (
                    <DeleteBtn onClick={() => handleDelete(item)} />
                  )}
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      {deleteModal && (
        <DeletePopup
          open={deleteModal}
          onClose={handleDeleteClosePopup}
          noBtnClick={handleDeleteClosePopup}
          yesBtnClick={handleDeleteStudent}
          loader={demoStudentDeleteLoading}
          title={"Do you really want to delete this student from the batch?"}
        />
      )}
    </div>
  );
}

export default BatchStudents;
