import React from "react";
import { CircularProgress, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./DeletePopup.module.css";

function UninstallOzontelPopup(props: any) {
  const { isOpen, onClose, yesBtnClick, loader, appName } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        zIndex: "99999",
        minWidth: "400px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "408px",
          maxHeight: "180px",
          minHeight: "179px",
          padding: "24px",
          borderRadius: "12px",
          boxShadow:
            "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
          left: "5%",
          top: "4%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#555555" }} onClick={onClose} />
        </div>
        <div className={styles.modalHead}>
          Are you sure you want to uninstall {appName} app?
        </div>
        <div className={styles.buttonSection}>
          <button className={styles.noButton} onClick={onClose}>
            No
          </button>

          <button
            className={styles.yesButton}
            onClick={yesBtnClick}
            disabled={loader}
          >
            {loader ? <CircularProgress size={20} color={"inherit"} /> : "Yes"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default UninstallOzontelPopup;
