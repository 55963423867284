import React, { useState } from "react";
import styles from "./ViewCourseDetailsPopup.module.css";
import searchClose from "../Assets/closeIcon.svg";
import Dialog from "@mui/material/Dialog";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Skeleton,
} from "@mui/material";
import { useSelector } from "react-redux";
import courseAccordionIcon from "../Assets/courseAccordionIcon.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiBorderRadius } from "react-icons/bi";
import ReactPDF from "@react-pdf/renderer";
import CoursePdf from "../CoursePdf/CoursePdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { getCourseByIdRequest } from "../../../Manage/ManageCourse/service/courseSlice";
import { getInstituteById } from "../../../Manage/InstituteProfile/service/instituteSlice";

function ViewCourseDetailsPopup(props: any) {
  const { selectedCourse, addLead, leadSelectedCourse, isFromContacts } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const courseDetails = useSelector((state: any) => state?.courses?.courseById);
  const courseByIdLoading = useSelector(
    (state: any) => state.courses.courseByIdLoading
  );

  const instituteDetails = useSelector(
    (state: any) => state?.institute?.instituteDetails
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (!isFromContacts) {
      dispatch<any>(
        getInstituteById(authUser?.institute[0]?.instituteDetails?.id)
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDescriptionCopied(false);
    setSyllabusCopied(false);
    setCourseDetailsCopied(false);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("boardCourseModal");
    queryParams.delete("courseId");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const [expanded, setExpanded] = React.useState<string | false>(
    courseDetails && courseDetails?.courseSyllabus[0]
      ? courseDetails?.courseSyllabus[0]?.chapterName + 0
      : ""
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const courseId = urlSearchParams.get("courseId");
    const boardCourseModal = urlSearchParams.get("boardCourseModal");

    if (courseId) {
      if (boardCourseModal) {
        dispatch<any>(getCourseByIdRequest(courseId));
        setOpen(true);
      }
    }
  }, [setOpen]);

  React.useEffect(() => {
    if (open && !addLead) {
      const queryParams = new URLSearchParams(window.location.search);
      if (courseDetails) {
        queryParams.set("boardCourseModal", "true");
        queryParams.set("courseId", courseDetails.id);

        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    }
  }, [open, courseDetails]);
  const theme = useTheme();
  const isTabSize = useMediaQuery(theme.breakpoints.down("lg"));

  const [descriptionCopied, setDescriptionCopied] = useState(false);
  const [syllabusCopied, setSyllabusCopied] = useState(false);
  const [courseDetailsCopied, setCourseDetailsCopied] = useState(false);

  const [downloadClicked, setDownloadClicked] = useState(false);

  const handleDownloadPDF = () => {
    setDownloadClicked(true);
  };

  const plainTextDescription = courseDetails
    ? courseDetails?.courseDescription.replace(/<[^>]+>/g, "")
    : "";

  function generateSyllabusText(courseSyllabus: any) {
    const syllabusText = courseSyllabus
      .map((item: any) => {
        const cleanedChapterDetails = item.chapterDetails.replace(
          /<[^>]+>/g,
          ""
        );

        const chapterText = `${item.chapterName}\n${cleanedChapterDetails}\n`;
        return chapterText;
      })
      .join("\n");

    return syllabusText;
  }

  function generateCourseDetails() {
    if (!courseDetails) {
      return "";
    }

    const formattedCourseDetails = `
𝗖𝗼𝘂𝗿𝘀𝗲 𝗡𝗮𝗺𝗲: ${courseDetails?.courseName}
𝗖𝗼𝘂𝗿𝘀𝗲 𝗗𝗲𝘀𝗰𝗿𝗶𝗽𝘁𝗶𝗼𝗻:
${plainTextDescription}
𝗖𝗼𝘂𝗿𝘀𝗲 𝗦𝘆𝗹𝗹𝗮𝗯𝘂𝘀:
${generateSyllabusText(courseDetails?.courseSyllabus)}
  `;

    return formattedCourseDetails;
  }

  const queryParams = new URLSearchParams(window.location.search);
  const batchModalOpen = queryParams.get("batchDrawer");
  const courseModalOpen = queryParams.get("courseDrawer");

  return (
    <div>
      {addLead ? (
        <>
          <button
            className={styles.blueBtn}
            onClick={() => {
              dispatch<any>(getCourseByIdRequest(leadSelectedCourse?.id));
              handleOpen();
            }}
          >
            View Course Details
          </button>
        </>
      ) : (
        <>
          <button
            className={styles.viewCourseDetailsPopupBtn}
            onClick={() => {
              dispatch<any>(getCourseByIdRequest(selectedCourse?.courseId));
              handleOpen();
            }}
            style={{
              fontSize:
                batchModalOpen === "true" && courseModalOpen === "true"
                  ? "12px"
                  : "14px",
              transition: "0.1s ease-in-out",
            }}
          >
            {isTabSize
              ? "View Course"
              : batchModalOpen === "true" && courseModalOpen === "true"
              ? "View Course"
              : "View Course Details"}
          </button>
        </>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "99999",
          minWidth: {
            xs: "85%",
            sm: "85%",
            md: "85%",
            lg: "960px",
            xl: "960px",
          },

          "& .MuiDialog-paper": {
            width: "100%",

            padding: "30px 30px",
            overflow: "hidden",
            maxWidth: {
              xs: "85%",
              sm: "85%",
              md: "85%",
              lg: "900px",
              xl: "900px",
            },
            maxHeight: {
              xs: "600px",
              sm: "600px",
              md: "600px",
              lg: "600px",
              xl: "600px",
            },
            minHeight: {
              xs: "600px",
              sm: "600px",
              md: "600px",
              lg: "600px",
              xl: "600px",
            },
          },
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            zIndex: "999999999",
            width: "100%",
          }}
        >
          <button
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <img src={searchClose} alt="" />
          </button>
        </div>

        <div className={styles.CourseDetailsPopupWrap}>
          {courseByIdLoading ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "25px",
                  marginBottom: "25px",
                }}
              >
                <div>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={230}
                    width={300}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={230}
                    width="100%"
                  />
                </div>
              </div>
              <div>
                <Skeleton animation="wave" variant="rounded" height={200} />
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "10px",
                }}
              >
                <div className={styles.CourseDetailsPopupSplitTop}>
                  <div className={styles.CourseDetailsPopupImg}>
                    {courseDetails && (
                      <img src={courseDetails?.courseImage} alt="" />
                    )}
                  </div>
                  <div>
                    <div className={styles.CourseDetailsPopupName}>
                      {courseDetails && courseDetails?.courseName}
                    </div>
                    <div className={styles.CourseDetailsPopupLevel}>
                      {courseDetails &&
                        courseDetails?.courseLevel?.courseLevelName}
                    </div>
                    {courseDetails && courseDetails?.isCertificateProvided && (
                      <div className={styles.certificateTagWrapper}>
                        <div className={styles.certificateTag}>
                          Certificate Provided
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginRight: "10px",
                  }}
                >
                  {courseDetailsCopied && (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "green",
                        marginRight: "4px",
                      }}
                    >
                      Copied!
                    </p>
                  )}
                  <CopyToClipboard
                    text={generateCourseDetails()}
                    onCopy={() => {
                      setCourseDetailsCopied(true);
                    }}
                  >
                    <IconButton
                      sx={{
                        position: "relative",
                        borderRadius: "4px",
                        border: "1px solid #E9E9E9",
                        background: "#FFFFFF",
                        marginRight: "4px",
                        transition: "background 0.3s",
                        "&:hover": {
                          background: "#E8FAF5",
                          "&:before": {
                            display: "block",
                          },
                          "&::after": {
                            display: "block",
                          },
                        },
                        "&:before": {
                          content: '"Copy to clipboard"',
                          position: "absolute",
                          top: "110%",
                          right: 0,
                          width: "max-content",
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "18px",
                          color: "#fff",
                          background: "#101828",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          display: "none",
                          cursor: "none",
                        },
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          zIndex: "9999",
                          top: "100%",
                          right: "12px",
                          transform: "rotate(45deg)",
                          width: "8px",
                          height: "8px",
                          backgroundColor: "#101828",
                          display: "none",
                          cursor: "none",
                        },
                      }}
                    >
                      <ContentCopyRoundedIcon fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                  {courseDetails && instituteDetails && (
                    <div>
                      <PDFDownloadLink
                        document={
                          <CoursePdf
                            courseDetails={courseDetails}
                            instituteDetails={instituteDetails}
                          />
                        }
                        fileName={`${courseDetails?.courseName}__PDF`}
                      >
                        <IconButton
                          sx={{
                            position: "relative",
                            borderRadius: "4px",
                            border: "1px solid #E9E9E9",
                            background: "#FFFFFF",
                            transition: "background 0.3s",
                            "&:hover": {
                              background: "#E8FAF5",
                              "&:before": {
                                display: "block",
                              },
                              "&::after": {
                                display: "block",
                              },
                            },
                            "&:before": {
                              content: '"Download"',
                              position: "absolute",
                              top: "110%",
                              right: 0,
                              width: "max-content",
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "18px",
                              color: "#fff",
                              background: "#101828",
                              borderRadius: "4px",
                              padding: "8px 12px",
                              display: "none",
                              cursor: "none",
                            },
                            "&::after": {
                              content: '""',
                              position: "absolute",
                              zIndex: "9999",
                              top: "100%",
                              right: "12px",
                              transform: "rotate(45deg)",
                              width: "8px",
                              height: "8px",
                              backgroundColor: "#101828",
                              display: "none",
                              cursor: "none",
                            },
                          }}
                          onClick={handleDownloadPDF}
                        >
                          <SouthSharpIcon fontSize="small" />
                        </IconButton>
                      </PDFDownloadLink>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.CourseDetailsPopupDescription}>
                <h5 className={styles.CourseDetailsPopupHeading}>
                  Course Description
                  <CopyToClipboard
                    text={plainTextDescription}
                    onCopy={() => setDescriptionCopied(true)}
                  >
                    <IconButton>
                      <ContentCopyRoundedIcon fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                  {descriptionCopied && (
                    <p style={{ fontSize: "12px", color: "green" }}>Copied!</p>
                  )}
                </h5>
                {courseDetails && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: courseDetails?.courseDescription,
                    }}
                  />
                )}
              </div>
              {courseDetails && courseDetails?.courseSyllabus[0] && (
                <>
                  <h5
                    style={{ marginBottom: "12px" }}
                    className={styles.CourseDetailsPopupHeading}
                  >
                    Course Syllabus
                    <CopyToClipboard
                      text={generateSyllabusText(courseDetails?.courseSyllabus)}
                      onCopy={() => setSyllabusCopied(true)}
                    >
                      <IconButton>
                        <ContentCopyRoundedIcon fontSize="small" />
                      </IconButton>
                    </CopyToClipboard>
                    {syllabusCopied && (
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "green",
                        }}
                      >
                        Copied!
                      </p>
                    )}
                  </h5>
                </>
              )}
              <div className={styles.CourseDetailsPopupSyllabus}>
                {courseDetails &&
                  courseDetails?.courseSyllabus[0] &&
                  courseDetails?.courseSyllabus.map((item: any, index: any) => (
                    <Accordion
                      expanded={expanded === `${item.chapterName + index}`}
                      onChange={handleChange(`${item.chapterName + index}`)}
                      className={styles.accordion}
                      key={item.chapterName + index}
                      sx={{
                        color: "#142C44",
                        backgroundColor: "transparent",
                        border: "none",
                        boxShadow: "none",
                        padding: "0px 16px",
                        borderBottom: "1px solid #e9e9e9 !important",

                        "&.Mui-expanded": {
                          marginBottom: "0",
                          marginTop: "0",
                        },
                        "&:before": {
                          display: "none",
                        },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          color: "#667085",
                          backgroundColor: "transparent",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "24px",
                          padding: "0",
                          "& .MuiAccordionSummary-content ": {
                            margin: "0 !important",
                          },
                          "&.Mui-expanded": {
                            minHeight: 42,
                          },
                        }}
                        expandIcon={<img src={courseAccordionIcon} alt="" />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {item.chapterName}
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: "0 2px",
                          border: "none",

                          "& .MuiCollapse-wrapperInner": {
                            minHeight: 25,
                          },
                        }}
                      >
                        <div
                          className={styles.chapterContent}
                          dangerouslySetInnerHTML={{
                            __html: item.chapterDetails,
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default ViewCourseDetailsPopup;
