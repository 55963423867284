import { connect } from "react-redux";

import CompletedTasks from "../components/CompletedTasksPage/CompletedTasks";
import { TasksInitialStateDTO } from "../service/types";
import { RootState, TypedDispatch } from "../../../../redux/store";
import {
  gettasksBoard,
  gettasksByDateForAssignee,
} from "../service/tasksSlice";
import { getStaffList } from "../../Manage/ManageStaff/service/Staffslice";

interface StateProps extends TasksInitialStateDTO {}

interface DispatchProps {
  GetTasksByDateForAssignee(
    id: string,
    taskType: string,
    startDate: string,
    endDate: string
  ): void;
  GetStaffsByInstitute(id: string): void;
  GetTasksBoard(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.tasks.success,
    error: state.tasks.error,
    loading: state.tasks.loading,
    tasksByAssignee: state.tasks.tasksByAssignee,
    tasksTypes: state.tasks.tasksTypes,
    tasksboard: state.tasks.tasksboard,
    taskById: state.tasks.taskById,
    tasksByDateForAssignee: state.tasks.tasksByDateForAssignee,
    taskStatusUpdateSuccess: state.tasks.taskStatusUpdateSuccess,
    taskStatusUpdateError: state.tasks.taskStatusUpdateError,
    taskStatusUpdateLoading: state.tasks.taskStatusUpdateLoading,
    taskPriorityUpdateSuccess: state.tasks.taskPriorityUpdateSuccess,
    taskPriorityUpdateError: state.tasks.taskPriorityUpdateError,
    taskPriorityUpdateLoading: state.tasks.taskPriorityUpdateLoading,
    taskAssigneeUpdateSuccess: state.tasks.taskAssigneeUpdateSuccess,
    taskAssigneeUpdateError: state.tasks.taskAssigneeUpdateError,
    taskAssigneeUpdateLoading: state.tasks.taskAssigneeUpdateLoading,
    taskByIdLoading: state.tasks.taskByIdLoading,
    taskBoardloading: state.tasks.taskBoardloading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetTasksByDateForAssignee: (
      id: string,
      taskType: string,
      startDate: string,
      endDate: string
    ) => {
      dispatch(
        gettasksByDateForAssignee({
          id: id,
          taskType: taskType,
          startDate: startDate,
          endDate: endDate,
        })
      );
    },
    GetStaffsByInstitute: (id: string) => {
      dispatch(getStaffList(id));
    },
    GetTasksBoard: (data: any) => {
      dispatch(gettasksBoard(data));
    },
  };
};

export interface CompletedTasksProps extends StateProps, DispatchProps {}
export const CompletedTasksComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedTasks);
