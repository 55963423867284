import React from "react";
import styles from "./SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function InstituteSkeleton() {
  return (
    <div
      className={styles.smallFormPopupSkeletonWrap}
      style={{ paddingTop: "30px" }}
    >
      <div
        className={styles.smallFormPopupHead}
        style={{ marginBottom: "30px" }}
      >
        <Skeleton animation="wave" variant="text" width="100px" height={25} />
      </div>
      <div className={styles.smallFormPopupHead}>
        <Skeleton
          animation="wave"
          variant="circular"
          width="100px"
          height="100px"
        />
      </div>

      <div className={styles.smallFormPopupHeadSplit}>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>

      <div className={styles.smallFormPopupHeadSplit}>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>

      <div>
        <Skeleton animation="wave" variant="text" width="100%" height={250} />
      </div>

      <div className={styles.smallFormPopupHeadSplit}>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>

      <div className={styles.smallFormPopupHeadSplit}>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>

      <div className={styles.smallFormPopupHeadSplit}>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div className={styles.smallFormPopupHeadSplitItem}>
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>

      <div className={styles.buttonSplit}>
        <div className={styles.smallFormPopupHead}>
          <Skeleton animation="wave" variant="text" width="100px" height={50} />
        </div>
        <div className={styles.smallFormPopupHead}>
          <Skeleton animation="wave" variant="text" width="100px" height={50} />
        </div>
      </div>
    </div>
  );
}

export default InstituteSkeleton;
