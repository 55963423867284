import React from "react";

interface Permission {
  permissionAttributeName: string;
  accessStatus: boolean;
}

interface RoleDomain {
  roleDomainName: string;
  accessPermissions: Permission[];
}

export default function getAccessStatus(
  roleDomainName: string,
  permissionAttributeName: string,
  roleData: any
): boolean | null {
  // const { userRoleById } = useSelector((state: any) => state.userDetails);
  const domain = roleData?.find(
    (domain: any) => domain.roleDomainCode === roleDomainName
  );

  if (domain) {
    const permission = domain?.accessPermissions?.find(
      (permission: any) =>
        permission.permissionAttributeCode === permissionAttributeName
    );

    if (permission) {
      return permission.accessStatus;
    }
  }
  return false;
}

export function getSubDomainAccessStatus(
  roleDomainName: string,
  roleSubDomainName: string,
  permissionAttributeName: string,
  roleData: any
): boolean | null {
  const domain = roleData?.find(
    (domain: any) => domain.roleDomainCode === roleDomainName
  );
  if (domain) {
    const subDomain = domain?.subDomain?.find(
      (subDomain: any) => subDomain.roleDomainCode === roleSubDomainName
    );

    if (subDomain) {
      const permission = subDomain?.accessPermissions?.find(
        (permission: any) =>
          permission.permissionAttributeCode === permissionAttributeName
      );

      if (permission) {
        return permission.accessStatus;
      }
    }
  }

  return false;
}

export function checkAccess(roleDomainCode: any, roleData: any) {
  const role = roleData?.find(
    (role: any) => role?.roleDomainCode === roleDomainCode
  );

  if (!role) return false;

  const hasAccess = role?.accessPermissions?.some(
    (permission: any) => permission?.accessStatus
  );

  if (hasAccess) {
    return true;
  } else if (role?.subDomain?.length > 0) {
    for (const subRole of role?.subDomain) {
      const subDomainAccess = subRole?.accessPermissions?.some(
        (permission: any) => permission?.accessStatus
      );

      if (subDomainAccess) {
        return true;
      }
    }
  }

  return false;
}

export function checkSubDomainAccess(
  roleDomainCode: any,
  subDomainCode: any,
  roleData: any
) {
  const role = roleData?.find(
    (role: any) => role?.roleDomainCode === roleDomainCode
  );

  if (!role) return false;

  const roleSubDomain = role?.subDomain?.find(
    (subDomain: any) => subDomain?.roleDomainCode === subDomainCode
  );

  if (!roleSubDomain) return false;

  const hasAccess = roleSubDomain?.accessPermissions?.some(
    (permission: any) => permission?.accessStatus
  );
  return hasAccess;
}
