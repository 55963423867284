import React from "react";
import styles from "./SyncedDataTab.module.css";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetSaveImportedDataSuccess } from "../../service/importSlice";
import ImportTab from "./ContactTabs/ImportTab";

function ImportData(props: any) {
  const {
    contactList,
    contactListLoading,
    GetContactLists,
    DeleteContactList,
    deleteContactLoading,
    deleteContactSuccess,
    deleteContactError,
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetNurtureLeadBoard,
    CreateEnquiry,
    addedEnquiryContactSuccess,
    addedEnquiryContactError,
    addedEnquiryContactLoading,
    AddEnquiryForContact,
    emailConfigLoading,
    emailConfigSuccess,
    GetInstituteById,
    GetCourseById,
    GetBatchById,
    mailConfigsList,
    GetMailConfigList,
    saveImportedDataSuccess,
    CreateSource,
    type,
    leadCreatedId,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const importedSuccess = useSelector(
    (state: any) => state.importData.saveImportedDataSuccess
  );

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";
    const enquirySource = "Excel Import";
    if (userFromLocalStorage) {
      GetContactLists(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        enquirySource
      );
    }
  }, [GetContactLists]);

  React.useEffect(() => {
    if (importedSuccess) {
      dispatch(resetSaveImportedDataSuccess());
    }
  }, [importedSuccess]);

  return (
    <div style={{ width: "100%", padding: "30px 20px" }}>
      <ImportTab
        contactList={contactList}
        contactListLoading={contactListLoading}
        DeleteContactList={DeleteContactList}
        deleteContactLoading={deleteContactLoading}
        deleteContactSuccess={deleteContactSuccess}
        deleteContactError={deleteContactError}
        CreateEnquiry={CreateEnquiry}
        AddEnquiryForContact={AddEnquiryForContact}
        addedEnquiryContactSuccess={addedEnquiryContactSuccess}
        addedEnquiryContactError={addedEnquiryContactError}
        GetContactLists={GetContactLists}
        GetInstituteById={GetInstituteById}
        GetCourseById={GetCourseById}
        GetBatchById={GetBatchById}
        page="IMPORT"
        leadCreatedId={leadCreatedId}
      />
    </div>
  );
}

export default ImportData;
