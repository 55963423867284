import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  styled,
  Avatar,
  Button,
  AvatarGroup,
  Tooltip,
} from "@mui/material";
import styles from "../components/RolesAndPermission.module.css";
import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const systemRole = [
  {
    role: "Super Admin",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    users: ["John", "Abhi", "Mintu"],
  },
  {
    role: "Admin",
    description:
      "Phasellus id malesuada mauris, at gravida libero. Vivamus sodales.",
    users: ["Pranav", "John", "Swetha", "Mintu", "Greeshma", "Silpa"],
  },
  {
    role: "Accounts",
    description:
      "Nulla tincidunt gravida eros et ornare. Integer a finibus leo.",
    users: ["John"],
  },
  {
    role: "Course Counselor",
    description:
      "Phasellus id malesuada mauris, at gravida libero. Vivamus sodales.",
    users: ["John", "Abhi"],
  },
];

function SystemRole(props: any) {
  const { staffList, systemRoles } = props;
  const navigate = useNavigate();
  return (
    <Box>
      <div className={styles.systemRole}>System Role</div>

      <TableContainer
        sx={{
          minWidth: 700,
          borderRadius: "8PX",
          border: "1px solid  #EAECF0",
        }}
      >
        <Table
          sx={{
            minWidth: 700,
          }}
          aria-label="customized table"
        >
          <TableHead className={styles.batchPopupHead}>
            <TableRow>
              <StyledTableCell>Role</StyledTableCell>

              <StyledTableCell align="left" sx={{ maxWidth: "370px" }}>
                Description
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ minWidth: "200px" }}>
                Users
              </StyledTableCell>
              <StyledTableCell align="left"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {systemRoles?.map((item: any, index: any) => (
              <TableRow key={index}>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                    minWidth: "260px",
                  }}
                >
                  {item?.roleName}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    minWidth: "420px",
                  }}
                  className={styles.roleDescriptionTable}
                >
                  {item?.description}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <Box className={styles.userCardImg}>
                      <AvatarGroup>
                        {staffList
                          ?.filter((d: any) => d.roleId === item?.id)
                          ?.slice(0, 4)
                          ?.map((user: any, userIndex: any) => (
                            <Tooltip
                              title={user?.firstName + " " + user?.lastName}
                              placement="bottom"
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: "#101828",
                                    color: "#fff",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    padding: "8px 12px",
                                    textTransform: "capitalize",
                                    "& .MuiTooltip-arrow": {
                                      color: "#101828",
                                    },
                                  },
                                },
                              }}
                              key={userIndex}
                            >
                              <Avatar
                                alt="avatar"
                                sx={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "50%",
                                  fontSize: "15px",
                                  background: "#667085",
                                  border: "2px solid #FFF",
                                  fontFamily: "Poppins",
                                  fontWeight: "400",
                                  color: "#FFF",
                                  position: "relative",
                                  zIndex: userIndex,
                                }}
                                className={styles.userAvatar}
                              >
                                <Box>{user?.firstName?.charAt(0)}</Box>
                              </Avatar>
                            </Tooltip>
                          ))}
                      </AvatarGroup>
                    </Box>
                    {staffList?.filter((d: any) => d.roleId === item?.id)
                      ?.length > 4 && (
                      <Box
                        sx={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          border: "2px solid #FFF",
                          background: "rgba(17, 200, 155, 0.06)",
                          padding: "4px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          position: "relative",
                        }}
                        className={styles.staffExtraRoleCount}
                      >
                        <Box
                          sx={{
                            color: "#11C89B",
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "18px",
                          }}
                        >
                          +
                          {staffList?.filter((d: any) => d.roleId === item?.id)
                            ?.length - 4}
                        </Box>
                        <ul className={styles.staffPopupWrap}>
                          {staffList
                            ?.filter((d: any) => d.roleId === item?.id)
                            ?.slice(4)
                            ?.map((user: any, userIndex: any) => (
                              <li
                                className={styles.staffPopupItem}
                                key={userIndex}
                              >
                                <div className={styles.staffPopupItemAvatar}>
                                  {user?.firstName?.charAt(0)}
                                </div>
                                <div className={styles.staffPopupItemName}>
                                  {user?.firstName + " " + user?.lastName}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </Box>
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "4px",
                      border: "1px solid #CFCFCF",
                      color: "#667085",
                      fontSize: "12px",
                      fontWeight: "400",
                      "&:hover": {
                        backgroundColor: "#FFF",
                        border: "1px solid #CFCFCF",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        `/app/Settings/Rolesandpermission/SystemRoleDetails/${item?.id}`
                      );
                    }}
                  >
                    View
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
            {/* {systemRole.map((item: any, index: any) => (
              <TableRow key={index}>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                    minWidth: "260px",
                  }}
                >
                  {item.role}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                    minWidth: "420px",
                  }}
                >
                  {item.description}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    <Box className={styles.userCardImg}>
                      {item.users.map((user: any, userIndex: any) => (
                        <Avatar
                          alt="avatar"
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            fontSize: "15px",
                            background: "#667085",
                            border: "2px solid #FFF",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            color: "#FFF",
                            position: "relative",
                            zIndex: userIndex,
                            left: `${userIndex * -6}px`,
                          }}
                          className={styles.userAvatar}
                        >
                          <Box>{user.charAt(0)}</Box>
                        </Avatar>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        border: "2px solid #FFF",
                        background: "rgba(17, 200, 155, 0.06)",
                        padding: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          color: "#11C89B",
                          textAlign: "center",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          fontWeight: "400",
                          lineHeight: "18px",
                        }}
                      >
                        +3
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#667085",
                    textTransform: "capitalize",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "4px",
                      border: "1px solid #CFCFCF",
                      color: "#667085",
                      fontSize: "12px",
                      fontWeight: "400",
                      "&:hover": {
                        backgroundColor: "#FFF",
                        border: "1px solid #CFCFCF",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        `/app/Settings/Rolesandpermission/SystemRoleDetails/${item.id}`
                      );
                    }}
                  >
                    View
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SystemRole;
