import { TextField } from "@mui/material";
import React from "react";
import styles from "../Manegecourses.module.css";
import deleteIcon from "../../../../../../assets/images/delete-dustbin.svg";
import AddIcon from "@mui/icons-material/Add";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { updateCoursePayment } from "../../service/courseSlice";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";

function AddCustomPayment(props: any) {
  const {
    courseById,
    loading_updateCourse,
    success_updateCourse,
    setCustomPayEnable,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const [rows, setRows] = React.useState([
    {
      id: 1,
      paymentName: "",
      price: 0,
      isNameValid: true,
      isPriceValid: true,
    },
  ]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        paymentName: "",
        price: 0,
        isNameValid: true,
        isPriceValid: true,
      },
    ]);
  };

  const handleRemoveRow = (id: number) => {
    const updatedRows = rows
      .filter((row) => row.id !== id)
      ?.map((row: any, index: number) => ({
        ...row,
        id: index + 1,
      }));
    setRows(updatedRows);
  };

  const handlePaymentName = (id: number, value: string) => {
    const updatedRows = rows?.map((row) => {
      if (row.id === id) {
        const isNameUnique = !rows?.some(
          (r) =>
            r.id !== id && r.paymentName?.toLowerCase() === value?.toLowerCase()
        );
        return {
          ...row,
          paymentName: value,
          isNameValid: value !== "" && isNameUnique ? true : false,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handlePriceChange = (id: number, value: string) => {
    const updatedRows = rows?.map((row) =>
      row.id === id
        ? {
            ...row,
            price: parseFloat(value),
            isPriceValid: value !== "" && parseFloat(value) > 0 ? true : false,
          }
        : row
    );
    setRows(updatedRows);
  };

  const cancelButtonClick = () => {
    setCustomPayEnable(false);
    setRows([
      {
        id: 1,
        paymentName: "",
        price: 0,
        isNameValid: true,
        isPriceValid: true,
      },
    ]);
  };

  const isNameUniqueCheck = (rows: any, row: any) => {
    return !rows?.some(
      (r: any) =>
        r?.id !== row?.id &&
        r?.paymentName?.toLowerCase() === row?.paymentName?.toLowerCase()
    );
  };
  const hasEmptyPaymentName = rows?.some((row) => row.paymentName === "");
  const hasPriceNotGreaterThanZero = rows.every((row) => row.price > 0);

  const handleRandomPayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows?.map((item: any) => ({
        paymentName: item.paymentName,
        amount: parseFloat(item.price),
      }));

      const body = {
        courseId: courseById?.id,
        paymentType: "RANDOM_PAYMENT",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      if (!hasEmptyPaymentName && hasPriceNotGreaterThanZero) {
        dispatch<any>(updateCoursePayment(body));
      }
    } catch (error) {}
  };

  return (
    <div>
      {rows?.map((item: any, index: number) => (
        <div className={styles.membershipTypeRow} key={index}>
          <div>
            <div className={styles.membershipTypeFieldLabel}>Payment Name </div>
            <TextField
              id="paymentName"
              variant="outlined"
              type="text"
              inputProps={{
                sx: {
                  width: "240px",
                  "&::placeholder": {
                    fontWeight: "400",
                    fontSize: "14px",
                  },

                  "&.MuiOutlinedInput-input": {
                    padding: "8px",
                  },
                },
              }}
              value={item.paymentName}
              onChange={(event) =>
                handlePaymentName(item.id, event.target.value)
              }
              error={!item?.isNameValid || !isNameUniqueCheck(rows, item)}
              helperText={
                !isNameUniqueCheck(rows, item) && !item?.isNameValid
                  ? "Payment name already exist"
                  : isNameUniqueCheck(rows, item) && !item?.isNameValid
                  ? "Please fill the field"
                  : ""
              }
            />
          </div>
          <div>
            <div className={styles.membershipTypeFieldLabel}>Amount</div>

            <TextField
              id="price"
              variant="outlined"
              type="number"
              inputProps={{
                sx: {
                  width: "240px",
                  "&::placeholder": {
                    fontWeight: "400",
                    fontSize: "14px",
                  },

                  "&.MuiOutlinedInput-input": {
                    padding: "8px",
                  },
                },
              }}
              value={item.price}
              onChange={(event) =>
                handlePriceChange(item.id, event.target.value)
              }
              error={!item?.isPriceValid}
              helperText={!item?.isPriceValid ? "Please fill valid amount" : ""}
            />
          </div>
          <div>
            {rows?.length > 1 && (
              <button
                className={styles.deleteTypeBtn}
                onClick={() => handleRemoveRow(item.id)}
                style={{ marginTop: "40PX" }}
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            )}
          </div>
        </div>
      ))}
      <div className={styles.addMoreTypeBtnWrap}>
        <button className={styles.addMoreTypeBtn} onClick={handleAddRow}>
          <AddIcon sx={{ fontSize: "18px", marginRight: "6PX" }} /> Add more
        </button>
      </div>
      <div className={styles.editSectionBtnWrap}>
        <CancelBtn onClick={() => cancelButtonClick()} />
        <SaveBtn
          onClick={handleRandomPayment}
          disabled={
            hasEmptyPaymentName ||
            !hasPriceNotGreaterThanZero ||
            loading_updateCourse
          }
          loader={loading_updateCourse}
        />
      </div>
    </div>
  );
}

export default AddCustomPayment;
