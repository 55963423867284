import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import styles from "./ForgetPassword.module.css";
export const ListItemComp = (listProps: { isError: boolean; copy: string }) => {
  const { isError, copy } = listProps;
  return (
    <ListItem className={styles.listItem} disablePadding>
      <ListItemIcon
        className={styles.passwordCriteriaList_icons}
        style={isError ? { color: "#23A67B" } : { color: "" }}
      >
        <CheckCircleIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography className={styles.passwordCriteria}>{copy}</Typography>
        }
      />
    </ListItem>
  );
};
