import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "../PaymentType.module.css";
import CloseIcon from "@mui/icons-material/Close";
import RandomRecordPay from "./RandomRecordPay";
import RandomPayLink from "./RandomPayLink";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  // zIndex: 9999999,
};

function LeadRandomPaymentPopup(props: any) {
  const { leadData, randomPayments, hasNurturePay } = props;
  const { randomPaymentLoading, randomPaymentSuccess } = useSelector(
    (state: any) => state.nurture
  );
  const [open, setOpen] = useState<boolean>(false);
  const [state, setState] = useState<number>(0);
  const [paymentName, setPaymentName] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [amountError, setAmountError] = useState<boolean>(false);
  const [paymentNameError, setPaymentNameError] = useState<boolean>(false);

  const closePaymentLinkPopup = () => {
    setOpen(false);
    setPaymentNameError(false);
    setAmountError(false);
    setAmount("");
    setPaymentName("");
    setState(0);
  };

  const openPaymentLinkPopup = () => {
    setOpen(true);
  };

  const handleNameChange = React.useCallback((value: any) => {
    setPaymentName(value);
    if (value) {
      setPaymentNameError(false);
    } else {
      setPaymentNameError(true);
    }
  }, []);

  const handleAmount = React.useCallback((value: any) => {
    setAmount(value);
    if (parseFloat(value) > 0) {
      setAmountError(false);
    } else {
      setAmountError(true);
    }
  }, []);

  const isNameUniqueCheck = () => {
    return !randomPayments?.some(
      (r: any) => r?.paymentName?.toLowerCase() === paymentName?.toLowerCase()
    );
  };

  React.useEffect(() => {
    if (randomPaymentSuccess !== true && randomPaymentSuccess !== false) {
      closePaymentLinkPopup();
    }
  }, [randomPaymentSuccess]);

  return (
    <div>
      <Button
        color="primary"
        // variant={"outlined"}
        sx={{
          fontSize: "13px",
          fontWeight: "500",
          textTransform: "capitalize",
          boxShadow: "none",
          lineHeight: "24px",
          // padding: "4px 10px",
          background: "#fff",
          color: "#667085",
          border: "none",
        }}
        size={"small"}
        onClick={openPaymentLinkPopup}
        // disabled={
        //   hasLeadAccess === false
        //     ? true
        //     : false || autoDebitRaised
        //     ? autoDebitRaised
        //     : false
        // }
      >
        <AddIcon sx={{ marginRight: "6px", fontSize: "18px" }} />
        Collect
      </Button>
      <Modal
        open={open}
        onClose={closePaymentLinkPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style} style={{ padding: state === 0 ? "20px" : "0px" }}>
          {state === 0 && (
            <>
              <div
                onClick={closePaymentLinkPopup}
                className={styles.closeModalButton}
              >
                <CloseIcon />
              </div>
              <div>
                <div className={styles.customTypeFieldLabel}>Payment Name</div>
                <TextField
                  id="paymentName"
                  variant="outlined"
                  type="text"
                  fullWidth
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontWeight: "400",
                        fontSize: "14px",
                      },

                      "&.MuiOutlinedInput-input": {
                        padding: "8px",
                      },
                    },
                  }}
                  value={paymentName}
                  onChange={(event) => handleNameChange(event.target.value)}
                  error={paymentNameError || !isNameUniqueCheck()}
                  helperText={
                    !isNameUniqueCheck() && !paymentNameError
                      ? "Payment name already exist"
                      : isNameUniqueCheck() && paymentNameError
                      ? "Please fill the field"
                      : ""
                  }
                />
              </div>
              <div>
                <div className={styles.customTypeFieldLabel}>Amount</div>

                <TextField
                  id="price"
                  variant="outlined"
                  type="number"
                  fullWidth
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        fontWeight: "400",
                        fontSize: "14px",
                      },

                      "&.MuiOutlinedInput-input": {
                        padding: "8px",
                      },
                    },
                  }}
                  value={amount}
                  onChange={(event) => handleAmount(event.target.value)}
                  error={amountError}
                  helperText={amountError ? "Please fill valid amount" : ""}
                />
              </div>
              <div className={styles.leadRandomPayPopup}>
                <Button
                  variant="outlined"
                  type="reset"
                  sx={{
                    color: "#BFBFBF",
                    borderColor: "#BFBFBF",
                    textTransform: "capitalize",
                    minWidth: "100px",
                  }}
                  onClick={() => setState(1)}
                  disabled={
                    amountError ||
                    paymentNameError ||
                    !isNameUniqueCheck() ||
                    parseFloat(amount) <= 0 ||
                    paymentName === "" ||
                    amount === ""
                  }
                >
                  Send payment link
                </Button>
                <Button
                  sx={{ textTransform: "capitalize", minWidth: "100px" }}
                  variant="contained"
                  onClick={() => setState(2)}
                  disabled={
                    amountError ||
                    paymentNameError ||
                    !isNameUniqueCheck() ||
                    parseFloat(amount) <= 0 ||
                    paymentName === "" ||
                    amount === ""
                  }
                >
                  Record Payment
                </Button>
              </div>
            </>
          )}
          {state === 1 && (
            <RandomPayLink
              leadData={leadData}
              onClose={closePaymentLinkPopup}
              selectedPay={{
                paymentName: paymentName,
                price: amount,
                randomId: "",
              }}
              setSelectedPay={setPaymentName}
              hasNurturePay={hasNurturePay}
              add={true}
              loader={randomPaymentLoading}
            />
          )}
          {state === 2 && (
            <RandomRecordPay
              leadData={leadData}
              onClose={closePaymentLinkPopup}
              selectedPay={{
                paymentName: paymentName,
                price: amount,
                randomId: "",
              }}
              setSelectedPay={setPaymentName}
              add={true}
              loader={randomPaymentLoading}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default LeadRandomPaymentPopup;
