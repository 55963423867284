import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import logo from "../../../../assets/images/logo.png";
import styles from "./Registration.module.css";
import { FORMINFOTXTOTP } from "../container/SignupString";

export function EmailOtpVerification(props: any) {
  const [seconds, setSeconds] = useState(60);
  const { OTP, setOTP, email } = props;
  const [isError, setIsError] = useState<boolean>(false);

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      if (
        props.message === "Invalid OTP" ||
        // props.message === "OTP sent successfully" ||
        OTP.length < 4
      ) {
        setIsError(true);
      }
      props.onSubmit({ email: props.email, otp: OTP });
    },
    [OTP, props]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setSeconds(60);
    props.onResendClick();
  };

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Box
          className={styles.logo_box}
          sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
        >
          <img src={logo} height="35" width="150" alt="" />
        </Box>
        <Box className={styles.formHeading}>
          <Typography
            sx={{
              fontSize: {
                lg: 30,
                md: 30,
                sm: 25,
                xs: 19,
              },
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#142C44",
              textTransform: "capitalize",
            }}
          >
            Please verify your email
          </Typography>
        </Box>
        {isError ? (
          <div className={styles.formDanger}>
            Incorrect code. Please verify the 4 digit code sent on your email.
          </div>
        ) : (
          <div className={styles.formInfo}>
            {FORMINFOTXTOTP} <br /> {email}
          </div>
        )}

        <div className={styles.otpInputWrapper}>
          <OtpInput
            value={OTP}
            onChange={setOTP}
            numInputs={4}
            isInputNum={true}
            inputStyle={styles.inputStyle}
            focusStyle={styles.borderHover}
            hasErrored={isError}
            errorStyle={{
              border: "1px solid #D32F2F",
              borderRadius: "4px",
            }}
          />
        </div>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{ textTransform: "capitalize" }}
        >
          {props.loading ? (
            <CircularProgress size={25} color={"inherit"} />
          ) : (
            "Continue"
          )}
        </Button>
        {seconds > 0 && (
          <>
            <p className={styles.count_text_main}>
              Resend passcode in
              <span className={styles.count_text}> {seconds} seconds</span>
            </p>
          </>
        )}

        {seconds === 0 && (
          <>
            <p className={styles.sendotpTxt}>
              <Button
                onClick={resendOTP}
                className={styles.link}
                color="secondary"
                style={{ textTransform: "lowercase" }}
              >
                Resend
              </Button>
            </p>
          </>
        )}
      </form>
    </>
  );
}
