import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Box } from "@mui/system";

export default function CategoryManager(props: any) {
  const {
    createCategory,
    categoriesList,
    handleSetCategory,
    disabled,
    validStateCategory,
    courseCategory,
    courseDetail,
    handleRemoveCategory,
  } = props;
  const [category, setCategory] = React.useState(false);
  const [categoryInput, setCategoryInput] = React.useState<any>("");
  const [addCategory, setAddCategory] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>("");

  const defaultOption: any = categoriesList
    ? categoriesList.find(
        (category: any) => category.courseCategoryName === courseCategory
      )
    : "";

  useEffect(() => {
    const filter = categoriesList?.filter((obj: any) =>
      obj?.courseCategoryName?.includes(categoryInput)
    );
    if (categoryInput !== undefined && filter && filter.length === 0) {
      setAddCategory(true);
    } else {
      setAddCategory(false);
    }
  }, [categoryInput]);

  useEffect(() => {
    if (categoriesList) {
      if (
        courseDetail.courseCategoryId &&
        courseDetail.courseCategoryId !== ""
      ) {
        setSelected(
          categoriesList.find(
            (category: any) => category.courseCategoryName === courseCategory
          )
        );
      } else {
        if (courseCategory !== "") {
          setSelected(
            categoriesList?.find(
              (category: any) => category.courseCategoryName === courseCategory
            )
          );
        } else {
          setSelected({
            courseCategoryName: selected?.courseCategoryName
              ? selected?.courseCategoryName
              : "",
            courseCategoryId: selected?.id ? selected?.id : "",
          });
        }
      }
    }
  }, [categoriesList, courseDetail]);
  useEffect(() => {
    const inputField: any = document.querySelector("#category-dropdown");

    if (selected) {
      if (
        selected?.status === "REVIEW_REJECTED" ||
        selected?.status === "UNDER_REVIEW"
      ) {
        inputField.style.color = "red";
      } else {
        inputField.style.color = "inherit";
      }
    }
  }, [selected]);

  return (
    <>
      <FormControl fullWidth variant="outlined" className="formControl" sx={{}}>
        <p
          style={{
            fontSize: "12px",
            color: "#142C44",
            fontWeight: "500",
            fontFamily: "Poppins",
            margin: "0px 0px 5px",
          }}
        >
          Category<span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
        </p>
        <Box
          sx={{
            position: "relative",
            ".css-yoi7t9-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
              {
                padding: "0px 0px 0px 0px",
              },
          }}
        >
          {categoriesList ? (
            <FormControl
              variant="outlined"
              className="formControl"
              sx={{
                ".css-yoi7t9-MuiAutocomplete-root .MuiOutlinedInput-root": {
                  borderColor: !validStateCategory ? "rgb(244 67 54)" : "",
                  ":hover": {
                    borderColor: !validStateCategory ? "rgb(244 67 54)" : "",
                  },
                },
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {
                  borderColor: !validStateCategory ? "rgb(244 67 54)" : "",
                  ":hover": {
                    borderColor: !validStateCategory ? "rgb(244 67 54)" : "",
                  },
                },
                ".css-1oz3c3t-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: !validStateCategory ? "rgb(244 67 54)" : "",
                    ":hover": {
                      borderColor: !validStateCategory ? "rgb(244 67 54)" : "",
                    },
                  },
              }}
              fullWidth
            >
              <Autocomplete
                disabled={disabled}
                fullWidth
                disablePortal
                disableClearable
                id="category-dropdown"
                options={categoriesList}
                size="small"
                getOptionLabel={(option: any) =>
                  // option.courseCategoryName
                  `${option.courseCategoryName}${
                    option.status === "REVIEW_REJECTED" ||
                    option.status === "UNDER_REVIEW"
                      ? ` (${option.status
                          .replace("_", " ")
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (s: any) =>
                              s.charAt(0).toUpperCase() + s.substring(1)
                          )
                          .join(" ")})`
                      : ""
                  }`
                }
                freeSolo
                value={selected}
                renderInput={(params) => {
                  if (!defaultOption) {
                    params.InputProps.endAdornment = null;
                  }
                  return (
                    <TextField
                      variant="outlined"
                      placeholder=""
                      className="categoryInput"
                      sx={{
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "1px !important",
                          },
                        ".css-lczfih-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                          {
                            padding: "1px !important",
                          },
                      }}
                      name="categoryInput"
                      onChange={(e) => {
                        setCategoryInput(e.target.value);
                        setCategory(false);
                        const inputField: any =
                          document.querySelector("#category-dropdown");
                        inputField.style.color = "inherit";
                      }}
                      onKeyDown={(e: any) => {
                        const isBackspace = e.keyCode === 8; // Backspace key
                        const isDelete = e.keyCode === 46; // Delete key

                        if (e.keyCode === 13 && e.target.value) {
                          const array =
                            (categoriesList &&
                              categoriesList?.filter(
                                (obj: any) =>
                                  obj.courseCategoryName.toLowerCase() ===
                                  categoryInput.toLowerCase()
                              )) ||
                            [];

                          if (array.length === 0) {
                            createCategory(categoryInput);
                            setCategory(true);
                          }
                          setAddCategory(false);

                          handleSetCategory(
                            array.length > 0 ? array[0] : categoryInput
                          );
                          setCategory(false);
                          setCategoryInput("");
                        } else if (
                          (isBackspace || isDelete) &&
                          categoryInput === "" &&
                          selected
                        ) {
                          // Handle Backspace or Delete key when input is empty
                          handleRemoveCategory(selected);
                          setSelected(null); // Clear the selected value
                          setCategoryInput(""); // Clear the input field
                        }
                      }}
                      {...params}
                    />
                  );
                }}
                onChange={(event, newValue) => {
                  const array =
                    (categoriesList &&
                      categoriesList?.filter(
                        (obj: any) =>
                          obj.courseCategoryName.toLowerCase() ===
                          (newValue?.courseCategoryName
                            ? newValue?.courseCategoryName.toLowerCase()
                            : newValue.toLowerCase())
                      )) ||
                    [];

                  handleSetCategory(array.length > 0 ? array[0] : newValue);
                }}
                sx={{ padding: "0px", color: category ? "red" : "" }}
              />
              {validStateCategory === false && (
                <FormHelperText
                  error={true}
                  sx={{ marginLeft: "0px !important" }}
                >
                  Field required
                </FormHelperText>
              )}
            </FormControl>
          ) : null}
          {addCategory && (
            <Box
              padding={"1.25px 18px"}
              boxShadow={"0px 2px 6px rgba(0, 0, 0, 0.06)"}
              marginTop={"7px"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              borderRadius={"8px"}
              border={"1px solid #E4E4E7"}
              width={"calc(100% - 0px)"}
            >
              <Box
                width={"calc(100% - 140px)"}
                overflow={"hidden"}
                display={"inline-block"}
                sx={{ whiteSpace: "nowrap" }}
              >
                {categoryInput}
              </Box>
              <Button
                onClick={() => {
                  createCategory(categoryInput);
                  setAddCategory(false);
                  setCategory(true);

                  handleSetCategory(categoryInput);
                }}
                variant="text"
                id="course-category"
                sx={{ width: "140px" }}
              >
                Add Category
              </Button>
            </Box>
          )}
        </Box>
      </FormControl>
    </>
  );
}
