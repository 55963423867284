import { Button } from "@mui/material";
import React from "react";

function CancelBtn(props: any) {
  const { onClick, disabled, loader, buttonText } = props;

  return (
    <>
      <Button
        variant="outlined"
        type="reset"
        sx={{
          color: "#BFBFBF",
          borderColor: "#BFBFBF",
          textTransform: "capitalize",
          minWidth: "100px",
        }}
        onClick={onClick}
      >
        {buttonText ? buttonText : "Cancel"}
      </Button>
    </>
  );
}

export default CancelBtn;
