import React from "react";
import styles from "./PaymentBars.module.css";
import amountEditedIcon from "../../../../../../assets/images/bar-i-icon.svg";
import moment from "moment";

function AmountEdited(props: any) {
  const { activity } = props;
  return (
    <div
      className={styles.membershipBarWrap}
      style={{
        background: "rgba(5, 112, 219, 0.06)",
        border: "1px solid rgba(5, 112, 219, 0.08)",
      }}
    >
      <div className={styles.membershipBarWrapLeft}>
        <div className={styles.membershipBarIcon}>
          <img src={amountEditedIcon} alt="amountEditedIcon" />
        </div>
        <div className={styles.membershipBarDetails}>
          <div className={styles.membershipBarDetailsTop}>
            <div
              className={styles.membershipBarTitle}
              style={{ color: "#0570DB" }}
            >
              Amount Edited
            </div>
            <div className={styles.membershipBarDate}>
              {moment(activity?.creationDate).format("DD MMM YYYY")}
            </div>
          </div>
          <div className={styles.membershipBarDesc}>
            {activity?.description}
          </div>
        </div>
      </div>
      <div className={styles.membershipBarWrapRight}></div>
    </div>
  );
}

export default AmountEdited;
