import React, { useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import logo from "../components/Assets/ibflogo.svg";
import nurtureLogo from "../components/Assets/nurtureLogo.svg";
import nurtureLogo44 from "../components/Assets/nurtureLogo44.svg";
import NewFormTextInput from "../../../../components/FormTextInput/NewFormTextInput";
import CountryCodePhoneInput from "../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Container,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { BiFontSize } from "react-icons/bi";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  status: string;
  instituteId: string;
  instituteName: string;
  assignedToName: string;
  assignedToId: string;
  enquiryPriority: string;
  branchName: string;
  branchId: string;
  enquirySource: string;
  campaignId: string;
  demoTiming: number;
  voucher: string;
  otherDetails: { [key: string]: string }[];
  createdById: string;
  createdByName: string;
  place?: string;
  school?: string;
};

const CampaignForm = (props: any) => {
  const { CreateContact, error, loading } = props;
  const [fullName, setFullName] = useState<string>("");
  const [fullNameError, setFullNameError] = useState<string | null>(null);

  const [contactNumberError, setContactNumberError] = useState("");

  const [fullNameValid, setFullNameValid] = useState(false);
  const [contactNumberValid, setContactNumberValid] = useState(true);
  const [contactNumberTouched, setContactNumberTouched] = useState(false);

  const defaultFormData: FormData = {
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    status: "ACTIVE",
    instituteId: "",
    instituteName: "",
    assignedToName: "",
    assignedToId: "",
    enquiryPriority: "NORMAL",
    branchName: "",
    branchId: "",
    enquirySource: "CAMPAIGN",
    campaignId: "",
    demoTiming: 0,
    voucher: "",
    otherDetails: [],
    createdById: "",
    createdByName: "",
  };

  // Then initialize the formData state
  const [formData, setFormData] = useState<FormData>(() => {
    let newFormData = { ...defaultFormData }; // create a copy of defaultFormData

    switch (process.env.REACT_APP_ENV) {
      case "dev":
        newFormData.instituteId = "8dc0d8d0-b2c0-4279-9f8d-0d736389bc09";
        newFormData.instituteName = "Sak_Inst";
        break;
      case "uat":
        newFormData.instituteId = "8abe55f7-aa46-4b25-85f4-0df0aca785bd";
        newFormData.instituteName = "Education Hub";
        break;
      case "preprod":
        newFormData.instituteId = "40a7ad03-d73a-4c0b-81d8-a40fa4b33d3b";
        newFormData.instituteName = "Education Hub";
        break;
      case "prod":
        newFormData.instituteId = "561c255c-b5c7-4e9b-9d8a-da8b582546e3";
        newFormData.instituteName = "Learn Nurture";
        break;
      default:
        break;
    }
    return newFormData;
  });

  const [isAvailableOnWhatsapp, setIsAvailableOnWhatsapp] = useState(false);

  const schools = [
    "AKASGVHSS PAYANNUR",
    "AKGM GOVT. HSS, PINARAYI",
    "AKGS GHSS, PERALASSERY",
    "AKSGHSS, MALAPPATTOM",
    "ANJARAKANDY HSS, ANJARAKANDY",
    "AVS GOVT. HSS, KARIVELLUR",
    "AZHEKKODU HSS, AZHEKKODE",
    "AZHIKODE HIGHER SECONDARY SCHOOL",
    "B E M P HIGHER SECONDARY SCHOOL, THALASSERRY",
    "B.V.J.M HIGHER SECONDARY SCHOOL, PERUMPADAVE",
    "C H MUHAMMED KOYA MEMMORIAL H S S, KAVUMPADY",
    "C.P. NARAYANAN MEMORIAL GOVT. HSS, MATHAMANGALAM",
    "CHAPPARAPADAVA HIGHER SECONDARY SCHOOL",
    "CHEMBILODE HSS",
    "CHERUPUSHPAM HSS, CHANDANAKKAMPA RA",
    "CHM HIGHER SECONDARY SCHOOL, ELAYAVOOR",
    "CHMKS GOVT. HSS, MATTOOL",
    "CHMM HSS, VADAKKUMPAD, RAMANTHALI",
    "CHOTHAVOOR HIGHER SECONDARY SCHOOL",
    "CHOVVA HSS, CHOVVA",
    "D I SABHA GIRLS HSS",
    "DEENUL ISLAM SABHA HSS, CHIRAKULAM",
    "DEVA MATHA HSS, PAISAKKARY, PAYYAVOOR",
    "E.K.NAYANAR SMARAKA GOVT. HSS, VENGAD",
    "GGHSS, THIRUVANGAD, THALASSERY",
    "GHSS AZHIKKODE, AZHIKKODE",
    "GHSS FOR GIRLS MADAYI, MADAYI",
    "GHSS KOTTAYAM MALABAR",
    "GHSS KOYYAM",
    "GHSS PATTUVAM, PATTUVAM",
    "GHSS PERINGOME, PERINGOME",
    "GHSS, CHERUKUNNU, KANNUR",
    "GHSS, NEDUNGOM",
    "GHSS, PRAPPOYIL",
    "GOVT. BRENNEN HSS, THALASSERY",
    "GOVT. CITY HSS",
    "GOVT. GIRLS HSS, PAYYANNUR, KANNUR",
    "GOVT. GIRLS HSS, THALASSERY, KANNUR",
    "GOVT. HIGHER SECONDARY SCHOOL, AROLI",
    "GOVT. HIGHER SECONDARY SCHOOL, PADIYOOR",
    "GOVT. HIGHER SECONDARY SCHOOL, THIRUMENI",
    "GOVT. HSS FOR BOYS, MADAI",
    "GOVT. HSS, ARALAM",
    "GOVT. HSS, CHALA",
    "GOVT. HSS, CHATTUKAPARA",
    "GOVT. HSS, CHAVASSERY",
    "GOVT. HSS, CHELORA",
    "GOVT. HSS, CHERUTHAZHAM",
    "GOVT. HSS, CHIRAKKARA, THALASSERY",
    "GOVT. HSS, CHITTARIPPARAMBA",
    "GOVT. HSS, CHUNDANGA POIL",
    "GOVT. HSS, CHUZHALI",
    "GOVT. HSS, IRIKKUR",
    "GOVT. HSS, KADANNAPPALLY",
    "GOVT. HSS, KANNADIPPARAMBA",
    "GOVT. HSS, KOOTHUPARAMBA",
    "GOVT. HSS, KOROM, PAYYANNOOR",
    "GOVT. HSS, KOTTILA",
    "GOVT. HSS, KOZHICHAL",
    "GOVT. HSS, KUNJIMANGALAM",
    "GOVT. HSS, MAMBRAM",
    "GOVT. HSS, MANATHANA",
    "GOVT. HSS, MATHIL",
    "GOVT. HSS, MORAZHA",
    "GOVT. HSS, MUNDERI",
    "GOVT. HSS, MUZHAPPILANGAD",
    "GOVT. HSS, PALA",
    "GOVT. HSS, PALAYAD",
    "GOVT. HSS, PALLIKKUNNU",
    "GOVT. HSS, PATTIAM",
    "GOVT. HSS, PUZHATHI",
    "GOVT. HSS, RAMANTHALY",
    "GOVT. HSS, SREEKANDAPURAM",
    "GOVT. HSS, SREEPURAM",
    "GOVT. HSS, THOTTADA",
    "GOVT. HSS, ULICKAL",
    "GOVT. HSS, VADAKKUMPAD",
    "GOVT. HSS, VALAPATTANAM",
    "GOVT. HSS, VAYAKKARA",
    "GOVT. HSS, VELLUR",
    "GOVT. HSSS, KAVUMBHAGAM",
    "GOVT. TOWN HSS",
    "GOVT. VHSS, EDAYANUR",
    "GOVT. WELFARE HSS, CHERUKUNNU",
    "GOVT.VHSS KURUMATHUR",
    "GVHSS, KATHIRUR",
    "I.J.M HSS, KOTTIYOOR",
    "IMNS GOVT. HSS, MAYYIL",
    "IRITTY HIGHER SECONDARY SCHOOL",
    "KADACHIRA HIGHER SECONDARY SCHOOL",
    "KADAMBUR HSS, EDAKKAD",
    "KAMBIL MOPLA HIGHER SECONDARY SCHOOL",
    "KANIANCHAL GOVT. HSS",
    "KARAKKUND DON- BOSCO SPEECH & HEARING HSS, PARIYARAM",
    "KARIYAD NAMBIARS HIGHER SECONDARY SCHOOL",
    "KHAYIDE MILLATH HSS, KAVVAYI",
    "KKNPM GOVT.VHSS PARIYARAM",
    "KKV MEMORIAL HSS, PANOOR",
    "KOODALI HIGHER SECONDARY SCHOOL",
    "KOOTHPARAMBA HSS, KOOTHUPARAMBA",
    "KPC HSS, PATTANUR",
    "KPRGS GOVT. HSS, KALLIASSERY",
    "M.A.S.S.GOVT. HSS, ETTIKKULAM",
    "M.M. HSS, TEMPLE GATE",
    "MALUR GHSS, THOLAMBARA",
    "MAMBRAM HSS, MAMBRAM",
    "MARY GIRI HSS, THERTHALLY",
    "MARY QUEENS HSS, KODIYANMALA",
    "MATTANNUR HSS, MATTANNUR",
    "MM HIGHER SECONDARY SCHOOL, NEW MAHE",
    "MODEL RESIDENTIAL SCHOOL, PATTUVAM",
    "MOOTHEDATH HIGHER SECONDARY SCHOOL, THALIPARAMBA",
    "NADUVIL HIGHER SECONDARY SCHOOL, NADUVIL",
    "NAJATH GIRLS HSS, MATTOOL NORTH",
    "NAM MEMORIAL HSS, PERINGATHUR",
    "NIRMALA HSS, CHEMPERI, CHEMPERI",
    "NSS HSS, ALAKKODE",
    "P.R.M. KOLAVALLOOR HSS, THUVAKKUNNU",
    "PANCHAYATH HSS, PAPPINISSERY",
    "PARASSINIKADAVU HIGHER SECONDARY SCHOOL",
    "PGM PANCHAYATH HIGHER SECONDARY SCHOOL, CHERUVANCHERY",
    "PR MEMORIAL HSS, PANOOR",
    "PUTHIYANGADI JAMA-ATH HIGHER SECONDARY SCHOOL",
    "RAHMANIYA ORPHANAGE HSS, IRRIKKUR",
    "RAJAS HIGHER SECONDARY SCHOOL, CHIRAKKAL",
    "RAJEEV GANDHI MEMMORIAL HSS, MOKERI",
    "RAMAVILASOM HSS, CHOKLI",
    "RANI JAI HSS, NIRMALAGIRI",
    "S.A.B.T.M. HSS, THAYINERI",
    "SABTM HSS, THAYINERI",
    "SACRED HEART GIRLS HIGHER SECONDARY SCHOOL, THALASSERY",
    "SACRED HEART HIGHER SECONDARY SCHOOL",
    "SANTHOME HSS, KOLAKKAD",
    "SEETHI SAHIB HSS, THALIPARAMBA",
    "SHENOY SMARAKA GOVERNMENT HSS",
    "SIR SYED HSS, KARIMBAM",
    "SIVAPURAM H S S",
    "SN TRUST HSS, THOTTADA",
    "ST MARYS HSS, EDOOR",
    "ST. CORNELIUS HIGHER SECONDARY SCHOOL",
    "ST. JOHNS BAPTIST HSS, KADATHUMKADAVU",
    "ST. JOSEPH S HSS, THALASSERY",
    "ST. JOSEPH S HSS, VAYATTUPARAMBA",
    "ST. JOSEPH'S HSS , KUNNOTH",
    "ST. MICHAEL S AI BHSS",
    "ST. SEBASTIAN S HSS, VELIMANOM",
    "ST. THERESA'S AI GHSS",
    "ST. THOMAS HIGHER SECONDARY SCHOOL, KILIANTHARA",
    "ST.JOSEPH HSS, PERAVUR",
    "ST.JOSEPH'S HSS, VIDYANAGAR",
    "ST.THOMAS HIGHER SECONDARY SCHOOL MANIKADAVU",
    "ST.THOMAS HIGHER SECONDARY SCHOOL, KELAKAM",
    "TAGORE MEMORIAL HSS, VELLORA",
    "TAGORE VIDYANIKETHAN GVHSS",
    "THAJUL ULOOM EM HSS, VALAPATTANAM",
    "VALAPATTANAM, MUSLIM WELFARE ASSOCIATION, HIGHER SECONDARY SCHOOL",
    "VHSS KADAVATHOOR",
    "WADIHUDA HSS, PAYANGADI",
    "ZAHRA HSS, THANGALPEEDIKA, MOKERI",
    "Other",
  ];

  const handleChange = (e: any) => {
    if (e.target.name === "fullName") {
      const fullNameValue = e.target.value;

      // Check for numeric characters
      if (/\d/.test(fullNameValue)) {
        setFullNameError("Full name should not contain numbers");
        return; // Exit early
      }

      setFullName(fullNameValue);

      if (fullNameValue.trim()) {
        setFullNameError(null); // clear error if there is a value
      } else {
        setFullNameError("Full name is required");
      }

      const splitName = fullNameValue.split(" ");
      const firstName = splitName.shift();
      const lastName = splitName.join(" ");

      setFormData({
        ...formData,
        firstName: firstName,
        lastName: lastName,
      });
    } else if (e.target.name === "place" || e.target.name === "school") {
      const key = e.target.name;
      const value = e.target.value;
      setFormData((prevState) => {
        let newOtherDetails = [...prevState.otherDetails];
        const index = newOtherDetails.findIndex(
          (detail) => detail[key] !== undefined
        );

        if (index !== -1) {
          newOtherDetails[index][key] = value;
        } else {
          newOtherDetails.push({ [key]: value });
        }

        return { ...prevState, otherDetails: newOtherDetails };
      });
    } else if (e.target.name === "availableOnWhatsApp") {
      setIsAvailableOnWhatsapp(e.target.checked);
      setFormData((prevState) => {
        let newOtherDetails = [...prevState.otherDetails];
        const index = newOtherDetails.findIndex(
          (detail) => detail["whatsapp"] !== undefined
        );

        if (index !== -1) {
          newOtherDetails[index]["whatsapp"] = e.target.value;
        } else {
          newOtherDetails.push({ whatsapp: e.target.value });
        }

        return { ...prevState, otherDetails: newOtherDetails };
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handlePhoneNumberChange = (value: string) => {
    setContactNumberTouched(true);
    if (value.length !== 12) {
      // only allow exactly 10 digits
      setContactNumberError("Contact number must be exactly 10 digits");
      setContactNumberValid(false);
    } else {
      setContactNumberError(""); // Clears the error
      setContactNumberValid(true); // Sets the field as valid
    }
    setFormData({
      ...formData,
      contactNumber: value,
    });
  };

  // const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsAvailableOnWhatsapp(event.target.checked);
  //   setFormData((prevState) => {
  //     let newOtherDetails = [...prevState.otherDetails];
  //     const index = newOtherDetails.findIndex(
  //       (detail) => detail[key] !== undefined
  //     );

  //     if (index !== -1) {
  //       newOtherDetails[index][key] = value;
  //     } else {
  //       newOtherDetails.push({ [key]: value });
  //     }

  //     return { ...prevState, otherDetails: newOtherDetails };
  //   });
  // };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    let isValid = true;

    if (!fullName.trim()) {
      setFullNameError("Full name is required");
      isValid = false;
    }

    if (formData.contactNumber.length !== 12) {
      // check the exact length
      setContactNumberError("Contact number must be exactly 10 digits");
      setContactNumberValid(false);
      isValid = false;
    }

    if (isValid) {
      CreateContact(formData);
    }
  };

  return (
    <Container
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        textAlign: "left",
        overflow: "auto",
        width: "100vw",
        overflowY: "scroll",
        paddingTop: "100px",
      }}
      onSubmit={handleSubmit}
    >
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          paddingBottom: "15px",
        }}
      >
        <img src={nurtureLogo44} />
      </Box>
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          fontWeight: "600",
          fontSize: "20px",
        }}
      >
        Nurture Institute
      </Box>
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          paddingBottom: "30px",
          fontWeight: "400",
          fontSize: "14px",
        }}
      >
        Explore the most trending courses in Bangalore.
      </Box>

      <Box sx={{ marginBottom: 2, width: "100%" }}>
        <NewFormTextInput
          name="fullName"
          label="Full Name"
          value={fullName}
          onChange={handleChange}
          placeholder="Enter your full name"
          error={!!fullNameError}
        />
      </Box>
      <Box sx={{ marginBottom: 2, width: "100%" }}>
        <NewFormTextInput
          name="email"
          label="Email Address (Optional)"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your email address"
          type="email"
        />
      </Box>

      <Box sx={{ marginBottom: 2, width: "100%", textAlign: "left" }}>
        <InputLabel
          id="phone-label"
          style={{ fontSize: "14px", fontWeight: "500", color: "#142C44" }}
        >
          Phone
        </InputLabel>
        <CountryCodePhoneInput
          value={formData.contactNumber}
          onChange={handlePhoneNumberChange}
          error={contactNumberValid}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isAvailableOnWhatsapp}
              onChange={handleChange}
              name="availableOnWhatsApp"
              size="small"
            />
          }
          label={
            <Typography
              sx={{ fontSize: "12px", fontWeight: "400", color: "#142C44" }}
            >
              I'm reachable on Whatsapp
            </Typography>
          }
        />
      </Box>

      {
        // <Box sx={{ marginBottom: 2, width: "100%" }}>
        //   <NewFormTextInput
        //     name="place"
        //     label="Place"
        //     value={formData.place}
        //     onChange={handleChange}
        //     placeholder="Enter your place"
        //   />
        // </Box>
        // <Box sx={{ marginBottom: 2, width: "100%" }}>
        //   <InputLabel
        //     id="school-label"
        //     sx={{
        //       fontSize: "14px",
        //       color: "#142C44",
        //       fontWeight: "500",
        //       marginBottom: "5px",
        //       fontFamily: "Poppins",
        //       display: "flex",
        //     }}
        //   >
        //     School
        //   </InputLabel>
        //   <FormControl fullWidth>
        //     <Autocomplete
        //       id="school"
        //       options={schools}
        //       getOptionLabel={(option) => option}
        //       fullWidth
        //       value={
        //         formData.otherDetails.find(
        //           (detail) => detail.school !== undefined
        //         )?.school || ""
        //       }
        //       onChange={(event, newValue) => {
        //         handleChange({
        //           target: { name: "school", value: newValue || "" },
        //         });
        //       }}
        //       renderInput={(params) => (
        //         <TextField {...params} variant="outlined" size="small" />
        //       )}
        //     />
        //   </FormControl>
        // </Box>
      }
      <Box sx={{ marginBottom: 2, width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <>
              I'm Interested
              <CircularProgress
                size="1rem"
                color="info"
                sx={{ marginLeft: "30px", color: "#fff" }}
              />
            </>
          ) : (
            "I'm Interested"
          )}
        </Button>

        <Box sx={{ textAlign: "center", marginTop: "30px", fontSize: "12px" }}>
          <img src={nurtureLogo} />
          <Typography
            sx={{ color: "#7C7C7C", lineHeight: 1, fontSize: "12px" }}
          >
            Powered by
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>nurturecrm.in</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default CampaignForm;
