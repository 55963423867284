import {
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";
import {
  CreateCustomRoleDTO,
  CreateLeadFormDTO,
  CreateStudentIdDTO,
  EditCustomFieldsDTO,
  EditLeadFormDTO,
  NotificationSettingsDTO,
  SettingsInitialStateDTO,
  TaxInvoiceSettingsDTO,
} from "./types";
import {
  CreateCustomRole,
  CreateLeadForm,
  CreateStudentId,
  CreateStudentInvoiceSettings,
  DeleteRole,
  EditCustomFields,
  EditLeadForm,
  GetAddLeadFormById,
  GetAddLeadFormForInstitute,
  GetAddLeadForms,
  GetCustomFields,
  GetNotificationSettings,
  GetRoleById,
  GetRoleDomainStructure,
  GetRolesByInstitute,
  GetStudentIdSettings,
  GetStudentInvoiceSettings,
  UpdateCustomRole,
  UpdateNotificationSettings,
  UpdateStudentId,
  UpdateStudentInvoiceSettings,
} from "./settings.request";

export const initialState: SettingsInitialStateDTO = {
  notifySettings: null,
  invoiceSettings: null,
  rolesList: null,
  roleDomainStructure: null,
  roleById: null,
  studentIdData: null,
  customFields: null,
  activeLeadForm: null,
  leadFormById: null,
  leadForms: null,
  createdLeadFormId: null,
  success: false,
  error: false,
  loading: false,
  notifySettingLoading: false,
  notifySettingUpdateLoading: false,
  notifySettingUpdateSuccess: false,
  notifySettingUpdateError: false,
  invoiceSettingLoading: false,
  invoiceSettingCreateLoading: false,
  invoiceSettingCreateSuccess: false,
  invoiceSettingCreateError: false,
  invoiceSettingUpdateLoading: false,
  invoiceSettingUpdateSuccess: false,
  invoiceSettingUpdateError: false,
  createCustomRoleError: false,
  createCustomRoleSuccess: false,
  createCustomRoleLoading: false,
  rolesListLoading: false,
  roleDomainStructureLoading: false,
  updateCustomRoleError: false,
  updateCustomRoleSuccess: false,
  updateCustomRoleLoading: false,
  roleByIdLoading: false,
  deleteCustomRoleError: false,
  deleteCustomRoleSuccess: false,
  deleteCustomRoleLoading: false,
  createStudentIdError: false,
  createStudentIdSuccess: false,
  createStudentIdLoading: false,
  updateStudentIdError: false,
  updateStudentIdSuccess: false,
  updateStudentIdLoading: false,
  StudentIdDataLoading: false,
  customFieldsLoading: false,

  createLeadFormSuccess: false,
  createLeadFormLoading: false,
  updateLeadFormSuccess: false,
  updateLeadFormLoading: false,
  updateCustomFieldsSuccess: false,
  updateCustomFieldsLoading: false,
  activeLeadFormLoading: false,
  leadFormByIdLoading: false,
  leadFormsLoading: false,
};

export const getNotificationSettings = createAsyncThunk(
  "settings/getNotificationSettings",
  async (id: string) => {
    return GetNotificationSettings(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const updateNotificationSettings = createAsyncThunk(
  "settings/updateNotificationSettings",
  async (data: NotificationSettingsDTO, thunkAPI: any) => {
    return UpdateNotificationSettings(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getStudentInvoiceSettings = createAsyncThunk(
  "settings/getStudentInvoiceSettings",
  async (id: string) => {
    return GetStudentInvoiceSettings(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const createStudentInvoiceSettings = createAsyncThunk(
  "settings/createStudentInvoiceSettings",
  async (data: TaxInvoiceSettingsDTO, thunkAPI: any) => {
    return CreateStudentInvoiceSettings(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateStudentInvoiceSettings = createAsyncThunk(
  "settings/updateStudentInvoiceSettings",
  async (data: TaxInvoiceSettingsDTO, thunkAPI: any) => {
    return UpdateStudentInvoiceSettings(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createCustomRole = createAsyncThunk(
  "roles/createCustomRole",
  async (data: CreateCustomRoleDTO, thunkAPI: any) => {
    return CreateCustomRole(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateCustomRole = createAsyncThunk(
  "settings/updateCustomRole",
  async (data: CreateCustomRoleDTO, thunkAPI: any) => {
    return UpdateCustomRole(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const deleteRole = createAsyncThunk(
  "settings/deleteRole",
  async (data: any, thunkAPI: any) => {
    return DeleteRole(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getRolesByInstitute = createAsyncThunk(
  "settings/getRolesByInstitute",
  async (id: string) => {
    return GetRolesByInstitute(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getRoleDomainStructure = createAsyncThunk(
  "settings/getRoleDomainStructure",
  async () => {
    return GetRoleDomainStructure()
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getRoleById = createAsyncThunk(
  "settings/getRoleById",
  async (id: string) => {
    return GetRoleById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const createStudentId = createAsyncThunk(
  "roles/CreateStudentId",
  async (data: CreateStudentIdDTO, thunkAPI: any) => {
    return CreateStudentId(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const updateStudentId = createAsyncThunk(
  "settings/UpdateStudentId",
  async (data: CreateStudentIdDTO, thunkAPI: any) => {
    return UpdateStudentId(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getStudentIdSettings = createAsyncThunk(
  "settings/getStudentIdSettings",
  async (id: string) => {
    return GetStudentIdSettings(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getCustomFields = createAsyncThunk(
  "settings/getCustomFields",
  async (id: string) => {
    return GetCustomFields(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const editCustomFields = createAsyncThunk(
  "settings/editCustomFields",
  async (data: EditCustomFieldsDTO, thunkAPI: any) => {
    return EditCustomFields(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const createLeadForm = createAsyncThunk(
  "settings/createLeadForm",
  async (data: CreateLeadFormDTO, thunkAPI: any) => {
    return CreateLeadForm(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const editLeadForm = createAsyncThunk(
  "settings/editLeadForm",
  async (data: EditLeadFormDTO, thunkAPI: any) => {
    return EditLeadForm(data)
      .then((response: any) => {
        if (response) {
          thunkAPI.dispatch(
            setToastNotification({
              message: response?.data?.message,
              type: "success",
              snackOpen: true,
            })
          );
        }
        return response?.data;
      })
      .catch(function (e: any) {
        thunkAPI.dispatch(
          setToastNotification({
            message: e?.response?.data?.message,
            type: "error",
            snackOpen: true,
          })
        );
        throw e.response.data;
      });
  }
);

export const getLeadFormById = createAsyncThunk(
  "settings/getLeadFormById",
  async (id: string) => {
    return GetAddLeadFormById(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getAddLeadFormForInstitute = createAsyncThunk(
  "settings/addLeadFormForInstitute",
  async (id: string) => {
    return GetAddLeadFormForInstitute(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const getAddLeadForms = createAsyncThunk(
  "settings/getAddLeadForms",
  async (id: string) => {
    return GetAddLeadForms(id)
      .then((response: any) => {
        return response?.data?.data;
      })
      .catch(function (e: any) {
        throw e.response.data;
      });
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    reset: () => initialState,
    resetSettingsStatuses: (state) => {
      state.notifySettingUpdateSuccess = false;
      state.notifySettingUpdateError = false;
      state.invoiceSettingCreateSuccess = false;
      state.invoiceSettingUpdateSuccess = false;
      state.invoiceSettingCreateError = false;
      state.invoiceSettingUpdateError = false;

      state.createCustomRoleError = false;
      state.createCustomRoleSuccess = false;
      state.updateCustomRoleError = false;
      state.updateCustomRoleSuccess = false;
      state.deleteCustomRoleSuccess = false;
      state.deleteCustomRoleError = false;

      state.createStudentIdError = false;
      state.createStudentIdSuccess = false;
      state.updateStudentIdSuccess = false;
      state.updateStudentIdError = false;

      state.createLeadFormSuccess = false;
      state.updateLeadFormSuccess = false;
      state.updateCustomFieldsSuccess = false;
      state.createdLeadFormId = null;
    },
    resetRoleById: (state) => {
      state.roleById = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNotificationSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.notifySettingLoading = false;
        state.success = true;
        state.error = false;
        state.notifySettings = action?.payload;
      }
    );
    builder.addCase(
      getNotificationSettings.rejected,
      (state, action: AnyAction) => {
        state.notifySettingLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getNotificationSettings.pending, (state) => {
      state.notifySettingLoading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(updateNotificationSettings.pending, (state) => {
      state.notifySettingUpdateLoading = true;
      state.notifySettingUpdateSuccess = false;
      state.notifySettingUpdateError = false;
    });
    builder.addCase(
      updateNotificationSettings.fulfilled,
      (state, action: AnyAction) => {
        state.notifySettingUpdateLoading = false;
        state.notifySettingUpdateSuccess =
          action?.payload?.message ||
          "Notification settings updated successfully!";
        state.notifySettingUpdateError = false;
      }
    );
    builder.addCase(
      updateNotificationSettings.rejected,
      (state, action: AnyAction) => {
        state.notifySettingUpdateLoading = false;
        state.notifySettingUpdateSuccess = false;
        state.notifySettingUpdateError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      getStudentInvoiceSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.invoiceSettingLoading = false;
        state.success = true;
        state.error = false;
        state.invoiceSettings = action?.payload;
      }
    );
    builder.addCase(
      getStudentInvoiceSettings.rejected,
      (state, action: AnyAction) => {
        state.invoiceSettingLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getStudentInvoiceSettings.pending, (state) => {
      state.invoiceSettingLoading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(createStudentInvoiceSettings.pending, (state) => {
      state.invoiceSettingCreateLoading = true;
      state.invoiceSettingCreateSuccess = false;
      state.invoiceSettingCreateError = false;
    });
    builder.addCase(
      createStudentInvoiceSettings.fulfilled,
      (state, action: AnyAction) => {
        state.invoiceSettingCreateLoading = false;
        state.invoiceSettingCreateSuccess =
          action?.payload?.message ||
          "Tax invoice settings updated successfully!";
        state.invoiceSettingCreateError = false;
      }
    );
    builder.addCase(
      createStudentInvoiceSettings.rejected,
      (state, action: AnyAction) => {
        state.invoiceSettingCreateLoading = false;
        state.invoiceSettingCreateSuccess = false;
        state.invoiceSettingCreateError =
          action?.error?.message || "Something went wrong";
      }
    );

    builder.addCase(updateStudentInvoiceSettings.pending, (state) => {
      state.invoiceSettingUpdateLoading = true;
      state.invoiceSettingUpdateSuccess = false;
      state.invoiceSettingUpdateError = false;
    });
    builder.addCase(
      updateStudentInvoiceSettings.fulfilled,
      (state, action: AnyAction) => {
        state.invoiceSettingUpdateLoading = false;
        state.invoiceSettingUpdateSuccess =
          action?.payload?.message ||
          "Tax invoice settings updated successfully!";
        state.invoiceSettingUpdateError = false;
      }
    );
    builder.addCase(
      updateStudentInvoiceSettings.rejected,
      (state, action: AnyAction) => {
        state.invoiceSettingUpdateLoading = false;
        state.invoiceSettingUpdateSuccess = false;
        state.invoiceSettingUpdateError =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(createCustomRole.pending, (state) => {
      state.createCustomRoleLoading = true;
      state.createCustomRoleSuccess = false;
      state.createCustomRoleError = false;
    });
    builder.addCase(createCustomRole.fulfilled, (state, action: AnyAction) => {
      state.createCustomRoleLoading = false;
      state.createCustomRoleSuccess =
        action?.payload?.message || "Custom role created";
      state.createCustomRoleError = false;
    });
    builder.addCase(createCustomRole.rejected, (state, action: AnyAction) => {
      state.createCustomRoleLoading = false;
      state.success = false;
      state.createCustomRoleError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getRolesByInstitute.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.rolesListLoading = false;
        state.success = true;
        state.error = false;
        state.rolesList = action?.payload;
      }
    );
    builder.addCase(
      getRolesByInstitute.rejected,
      (state, action: AnyAction) => {
        state.rolesListLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getRolesByInstitute.pending, (state) => {
      state.rolesListLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getRoleDomainStructure.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.roleDomainStructureLoading = false;
        state.success = true;
        state.error = false;
        state.roleDomainStructure = action?.payload;
      }
    );
    builder.addCase(
      getRoleDomainStructure.rejected,
      (state, action: AnyAction) => {
        state.roleDomainStructureLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getRoleDomainStructure.pending, (state) => {
      state.roleDomainStructureLoading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(updateCustomRole.pending, (state) => {
      state.updateCustomRoleLoading = true;
      state.updateCustomRoleSuccess = false;
      state.updateCustomRoleError = false;
    });
    builder.addCase(updateCustomRole.fulfilled, (state, action: AnyAction) => {
      state.updateCustomRoleLoading = false;
      state.updateCustomRoleSuccess =
        action?.payload?.message || "Custom role updated successfully!";
      state.updateCustomRoleError = false;
    });
    builder.addCase(updateCustomRole.rejected, (state, action: AnyAction) => {
      state.updateCustomRoleLoading = false;
      state.updateCustomRoleSuccess = false;
      state.updateCustomRoleError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(
      getRoleById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.roleByIdLoading = false;
        state.success = true;
        state.error = false;
        state.roleById = action?.payload;
      }
    );
    builder.addCase(getRoleById.rejected, (state, action: AnyAction) => {
      state.roleByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getRoleById.pending, (state) => {
      state.roleByIdLoading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(deleteRole.pending, (state) => {
      state.deleteCustomRoleLoading = true;
      state.deleteCustomRoleSuccess = false;
      state.deleteCustomRoleError = false;
    });
    builder.addCase(deleteRole.fulfilled, (state, action: AnyAction) => {
      state.deleteCustomRoleLoading = false;
      state.deleteCustomRoleSuccess =
        action?.payload?.message || "Comment deleted Successfully";
      state.deleteCustomRoleError = false;
    });
    builder.addCase(deleteRole.rejected, (state, action: AnyAction) => {
      state.deleteCustomRoleLoading = false;
      state.deleteCustomRoleSuccess = false;
      state.deleteCustomRoleError =
        action?.error?.message || "Something went wrong";
    });

    builder.addCase(createStudentId.pending, (state) => {
      state.createStudentIdLoading = true;
      state.createStudentIdSuccess = false;
      state.createStudentIdError = false;
    });
    builder.addCase(createStudentId.fulfilled, (state, action: AnyAction) => {
      state.createStudentIdLoading = false;
      state.createStudentIdSuccess =
        action?.payload?.message || "Student id created";
      state.createStudentIdError = false;
    });
    builder.addCase(createStudentId.rejected, (state, action: AnyAction) => {
      state.createStudentIdLoading = false;
      state.createStudentIdSuccess = false;
      state.createStudentIdError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(updateStudentId.pending, (state) => {
      state.updateStudentIdLoading = true;
      state.updateStudentIdSuccess = false;
      state.updateStudentIdError = false;
    });
    builder.addCase(updateStudentId.fulfilled, (state, action: AnyAction) => {
      state.updateStudentIdLoading = false;
      state.updateStudentIdSuccess =
        action?.payload?.message || "Student id updated successfully!";
      state.updateStudentIdError = false;
    });
    builder.addCase(updateStudentId.rejected, (state, action: AnyAction) => {
      state.updateStudentIdLoading = false;
      state.updateStudentIdSuccess = false;
      state.updateStudentIdError =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      getStudentIdSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.StudentIdDataLoading = false;
        state.success = true;
        state.error = false;
        state.studentIdData = action?.payload;
      }
    );
    builder.addCase(
      getStudentIdSettings.rejected,
      (state, action: AnyAction) => {
        state.StudentIdDataLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getStudentIdSettings.pending, (state) => {
      state.StudentIdDataLoading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(
      getCustomFields.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customFieldsLoading = false;
        state.success = true;
        state.error = false;
        state.customFields = action?.payload;
      }
    );
    builder.addCase(getCustomFields.rejected, (state, action: AnyAction) => {
      state.customFieldsLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getCustomFields.pending, (state) => {
      state.customFieldsLoading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(createLeadForm.pending, (state) => {
      state.createLeadFormLoading = true;
      state.createLeadFormSuccess = false;
      state.error = false;
    });
    builder.addCase(createLeadForm.fulfilled, (state, action: AnyAction) => {
      state.createLeadFormLoading = false;
      state.createLeadFormSuccess =
        action?.payload?.message || "Student id created";
      state.error = false;
      state.createdLeadFormId = action?.payload?.data?.id;
    });
    builder.addCase(createLeadForm.rejected, (state, action: AnyAction) => {
      state.createLeadFormLoading = false;
      state.createLeadFormSuccess = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(editLeadForm.pending, (state) => {
      state.updateLeadFormLoading = true;
      state.updateLeadFormSuccess = false;
      state.error = false;
    });
    builder.addCase(editLeadForm.fulfilled, (state, action: AnyAction) => {
      state.updateLeadFormLoading = false;
      state.updateLeadFormSuccess =
        action?.payload?.message || "Student id updated successfully!";
      state.error = false;
    });
    builder.addCase(editLeadForm.rejected, (state, action: AnyAction) => {
      state.updateLeadFormLoading = false;
      state.updateLeadFormSuccess = false;
      state.error = action?.error?.message || "Something went wrong";
    });

    builder.addCase(editCustomFields.pending, (state) => {
      state.updateCustomFieldsLoading = true;
      state.updateCustomFieldsSuccess = false;
      state.error = false;
    });
    builder.addCase(editCustomFields.fulfilled, (state, action: AnyAction) => {
      state.updateCustomFieldsLoading = false;
      state.updateCustomFieldsSuccess =
        action?.payload?.message || "Student id updated successfully!";
      state.error = false;
    });
    builder.addCase(editCustomFields.rejected, (state, action: AnyAction) => {
      state.updateCustomFieldsLoading = false;
      state.updateCustomFieldsSuccess = false;
      state.error = action?.error?.message || "Something went wrong";
    });

    builder.addCase(
      getAddLeadFormForInstitute.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.activeLeadFormLoading = false;
        state.success = true;
        state.error = false;
        state.activeLeadForm = action?.payload;
      }
    );
    builder.addCase(
      getAddLeadFormForInstitute.rejected,
      (state, action: AnyAction) => {
        state.activeLeadFormLoading = false;
        state.success = false;
        state.error = action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(getAddLeadFormForInstitute.pending, (state) => {
      state.activeLeadFormLoading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(
      getLeadFormById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadFormByIdLoading = false;
        state.success = true;
        state.error = false;
        state.leadFormById = action?.payload;
      }
    );
    builder.addCase(getLeadFormById.rejected, (state, action: AnyAction) => {
      state.leadFormByIdLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getLeadFormById.pending, (state) => {
      state.leadFormByIdLoading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(
      getAddLeadForms.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.leadFormsLoading = false;
        state.success = true;
        state.error = false;
        state.leadForms = action?.payload;
      }
    );
    builder.addCase(getAddLeadForms.rejected, (state, action: AnyAction) => {
      state.leadFormsLoading = false;
      state.success = false;
      state.error = action?.error?.message || "Something went wrong";
    });
    builder.addCase(getAddLeadForms.pending, (state) => {
      state.leadFormsLoading = true;
      state.success = false;
      state.error = false;
    });
  },
});

export default settingsSlice.reducer;
export const { reset, resetSettingsStatuses, resetRoleById } =
  settingsSlice.actions;
