import React from "react";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { Button, Dialog, TextField } from "@mui/material";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import AddIcon from "@mui/icons-material/Add";
import deleteIcon from "../../../../../../assets/images/delete-dustbin.svg";
import styles from "../PaymentType.module.css";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";
import LeadRandomPaymentPopup from "./LeadRandomPaymentPopup";
import {
  deleteAdditionalPayment,
  resetPaymentLinkStatus,
  updateRandomPayments,
} from "../../../service/nurtureSlice";
import RandomRecordPay from "./RandomRecordPay";
import { useSelector } from "react-redux";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import moment from "moment";
import paidCheck from "../../../../../../assets/images/paid-check.svg";
import RandomPayLink from "./RandomPayLink";
import RandomPayResend from "./RandomPayResend";
import DeleteBtn from "../../../../../../components/Buttons/DeleteBtn";
import DeletePopup from "../../../../../../components/CommonPopups/DeletePopup";

function LeadRandomPayments(props: any) {
  const {
		leadById,
		randomPayments,
		filteredRandomPayRequests,
		hasLeadAccess,
		selectedCurrency,
	} = props;
	const auth = useAuthUser();
	const authUser = auth();
	const dispatch = useDispatch();
	const instituteData = useSelector(
		(state: any) => state.institute.instituteDetails
	);
	const {
		sendPaymentLinkSuccess,
		createPaymentSuccess,
		createPaymentLoading,
		sendPaymentLinkLoading,
		randomPaymentSuccess,
		randomPaymentLoading,
		deleteAdditionalPayLoading,
		deleteAdditionalPaySuccess,
	} = useSelector((state: any) => state.nurture);
	const hasNurturePay =
		instituteData &&
		Array.isArray(instituteData.integrationsOpted) &&
		instituteData?.integrationsOpted?.includes('NURTURE_PAY_EASEBUZZ');

	const [rows, setRows] = React.useState([
		{
			id: 1,
			paymentName: '',
			price: 0,
			isNameValid: true,
			isPriceValid: true,
			randomId: '',
		},
	]);
	const [customPayEdit, setCustomPayEdit] = React.useState<boolean>(false);
	const [recordPayOpen, setRecordPayOpen] = React.useState<boolean>(false);
	const [payLinkOpen, setPayLinkOpen] = React.useState<boolean>(false);
	const [selectedPay, setSelectedPay] = React.useState<any>('');
	const [deletePayment, setDeletePayment] = React.useState<any>('');
	const [deleteModal, setDeleteModal] = React.useState<boolean>(false);

	const handlePaySelected = (item: any) => {
		const selected = {
			randomId: item.id,
			paymentName: item.paymentName,
			price: item.amount,
		};
		setSelectedPay(selected);
	};

	const openPayRecordPopup = () => {
		setRecordPayOpen(true);
	};

	const closePayRecordPopup = () => {
		setRecordPayOpen(false);
	};

	const openPayLinkPopup = () => {
		setPayLinkOpen(true);
	};

	const closePayLinkPopup = () => {
		setPayLinkOpen(false);
	};

	const handleCustomPayEdit = () => {
		setCustomPayEdit(true);
	};

	const handleAddRow = () => {
		setRows([
			...rows,
			{
				id: rows.length + 1,
				paymentName: '',
				price: 0,
				isNameValid: true,
				isPriceValid: true,
				randomId: '',
			},
		]);
	};

	const handleRemoveRow = (id: number) => {
		const updatedRows = rows
			.filter((row) => row.id !== id)
			?.map((row: any, index: number) => ({
				...row,
				id: index + 1,
			}));
		setRows(updatedRows);
	};

	const handlePaymentName = (id: number, value: string) => {
		const updatedRows = rows?.map((row) => {
			if (row.id === id) {
				const isNameUnique = !rows?.some(
					(r) =>
						r.id !== id && r.paymentName?.toLowerCase() === value?.toLowerCase()
				);
				return {
					...row,
					paymentName: value,
					isNameValid: value !== '' && isNameUnique ? true : false,
				};
			}
			return row;
		});
		setRows(updatedRows);
	};

	const handlePriceChange = (id: number, value: string) => {
		const updatedRows = rows?.map((row) =>
			row.id === id
				? {
						...row,
						price: parseFloat(value),
						isPriceValid: value !== '' && parseFloat(value) > 0 ? true : false,
				  }
				: row
		);
		setRows(updatedRows);
	};

	const cancelButtonClick = () => {
		setCustomPayEdit(false);
		if (randomPayments?.length === 0) {
			setRows([
				{
					id: 1,
					paymentName: '',
					price: 0,
					isNameValid: true,
					isPriceValid: true,
					randomId: '',
				},
			]);
		}
	};

	const isNameUniqueCheck = (rows: any, row: any) => {
		return !rows?.some(
			(r: any) =>
				r?.id !== row?.id &&
				r?.paymentName?.toLowerCase() === row?.paymentName?.toLowerCase()
		);
	};
	const hasEmptyPaymentName = rows?.some((row) => row.paymentName === '');
	const hasPriceNotGreaterThanZero = rows.every((row) => row.price > 0);
	const hasDuplicatePaymentName = rows?.some(
		(item, index) =>
			rows?.findIndex(
				(i) => i.paymentName.toLowerCase() === item.paymentName.toLowerCase()
			) !== index
	);

	const handleRandomPayment = async (event: any) => {
		event.preventDefault();

		try {
			const updatedPaymentStructure = rows?.map((item: any) => ({
				paymentName: item.paymentName,
				amount: parseFloat(item.price),
				id: item?.randomId ? item?.randomId : '',
			}));

			const body = {
				enquiryId: leadById?.id,
				randomPaymentData: updatedPaymentStructure,
				instituteId: authUser?.institute?.[0]?.instituteId,
			};
			if (!hasEmptyPaymentName && hasPriceNotGreaterThanZero) {
				dispatch<any>(
					updateRandomPayments({
						...body,
						updatedById: authUser?.institute[0]?.id,
						updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
					})
				);
			}
		} catch (error) {}
	};

	const handleDelete = (item: any) => {
		setDeletePayment(item);
		setDeleteModal(true);
	};
	const handleDeleteClosePopup = () => {
		setDeletePayment('');
		setDeleteModal(false);
	};

	const handleDeletePayment = async (event: any) => {
		event.preventDefault();

		try {
			dispatch<any>(deleteAdditionalPayment(deletePayment?.id));
		} catch (error) {}
	};

	React.useEffect(() => {
		if (
			randomPayments?.filter((item: any) => item?.paymentStatus === 'UPCOMING')
				?.length > 0
		) {
			const defaultPaymentStructure = randomPayments
				?.filter((item: any) => item?.paymentStatus === 'UPCOMING')
				?.map((item: any, index: number) => ({
					id: index + 1,
					paymentName: item.paymentName,
					price: item.amount,
					isNameValid: true,
					isPriceValid: true,
					randomId: item?.id,
				}));
			setRows(defaultPaymentStructure);
		} else {
			setRows([
				{
					id: 1,
					paymentName: '',
					price: 0,
					isNameValid: true,
					isPriceValid: true,
					randomId: '',
				},
			]);
		}
	}, [randomPayments, customPayEdit]);

	React.useEffect(() => {
		if (sendPaymentLinkSuccess !== true && sendPaymentLinkSuccess !== false) {
			setPayLinkOpen(false);
			dispatch(resetPaymentLinkStatus());
		}
	}, [sendPaymentLinkSuccess]);

	React.useEffect(() => {
		if (createPaymentSuccess !== true && createPaymentSuccess !== false) {
			setRecordPayOpen(false);
		}
	}, [createPaymentSuccess]);

	React.useEffect(() => {
		if (randomPaymentSuccess !== true && randomPaymentSuccess !== false) {
			setCustomPayEdit(false);
		}
	}, [randomPaymentSuccess]);

	React.useEffect(() => {
		if (
			deleteAdditionalPaySuccess !== true &&
			deleteAdditionalPaySuccess !== false
		) {
			setCustomPayEdit(false);
		}
	}, [deleteAdditionalPaySuccess]);

	return (
		<div>
			<div className={styles.randomPayWrap}>
				<div className={styles.leadPaySecTitle}>Additional Payments</div>
				{!customPayEdit && hasLeadAccess && (
					<div className={styles.randomPayBtns}>
						<LeadRandomPaymentPopup
							leadData={leadById}
							randomPayments={randomPayments}
							hasNurturePay={hasNurturePay}
						/>
						{randomPayments?.length > 0 && (
							<EditIconBtn onClick={() => handleCustomPayEdit()} />
						)}
					</div>
				)}
			</div>
			<div>
				{!customPayEdit ? (
					<div>
						{randomPayments?.length > 0 ? (
							<>
								{randomPayments?.map((item: any, index: number) => (
									<div className={styles.definedCustomPayRow} key={index}>
										<div>
											<div
												className={styles.definedCustomPayItem}
												style={{ width: '180px' }}>
												<div className={styles.definedCustomPayItemLabel}>
													Payment Name:
												</div>
												<div
													className={styles.definedCustomPayItemValue}
													style={{ textTransform: 'capitalize' }}>
													{item?.paymentName}
												</div>
											</div>
											<div className={styles.definedCustomPayItem}>
												<div className={styles.definedCustomPayItemLabel}>
													Amount:
												</div>
												<div
													className={styles.definedCustomPayItemValue}
													style={{ fontFamily: 'Roboto' }}>
													{/* {item?.amount?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
													{selectedCurrency + " " + item?.amount}
												</div>
											</div>
										</div>
										<div>
											{item?.paymentStatus === 'PAID' ? (
												<>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															gap: '12px',
														}}>
														<div
															style={{
																background: ' rgba(17, 200, 155, 0.1)',
																borderRadius: '4px',
																padding: '4px 10PX',
																color: '#3DB985',
																fontSize: '12PX',
																fontWeight: '500',
															}}>
															Paid on:
															{moment(item.paymentDate).format(' DD/MM/YYYY')}
														</div>
														<img src={paidCheck} alt='paidCheck' />
													</div>
												</>
											) : (
												<>
													{hasLeadAccess && (
														<>
															<Button
																color='primary'
																variant='contained'
																sx={{
																	fontSize: '13px',
																	fontWeight: '500',
																	textTransform: 'capitalize',
																	boxShadow: 'none',
																	lineHeight: '24px',
																	padding: '7px 12px',
																	borderRadius: '4PX',
																}}
																size={'small'}
																onClick={() => {
																	handlePaySelected(item);
																	openPayRecordPopup();
																}}
																disabled={
																	hasLeadAccess === false ? true : false
																}>
																<AddIcon
																	sx={{ marginRight: '10px', fontSize: '18px' }}
																/>
																Record Payment
															</Button>
															{item?.paymentLinkSent === false ? (
																<Button
																	color='primary'
																	variant='contained'
																	sx={{
																		fontSize: '13px',
																		fontWeight: '500',
																		textTransform: 'capitalize',
																		boxShadow: 'none',
																		lineHeight: '24px',
																		padding: '7px 12px',
																		borderRadius: '4PX',
																	}}
																	size={'small'}
																	onClick={() => {
																		handlePaySelected(item);
																		openPayLinkPopup();
																	}}
																	disabled={
																		hasLeadAccess === false ? true : false
																	}>
																	<NearMeOutlinedIcon
																		sx={{
																			marginRight: '10px',
																			fontSize: 'small',
																		}}
																	/>
																	{hasNurturePay
																		? 'Send Payment Link'
																		: 'Send Payment Reminder'}
																</Button>
															) : (
																<RandomPayResend
																	leadData={leadById}
																	onClose={closePayLinkPopup}
																	selectedPay={item}
																	setSelectedPay={setSelectedPay}
																	hasNurturePay={hasNurturePay}
																	filteredRandomPayRequests={
																		filteredRandomPayRequests
																	}
																/>
															)}
															<DeleteBtn onClick={() => handleDelete(item)} />
														</>
													)}
												</>
											)}
										</div>
									</div>
								))}
							</>
						) : (
							<>
								<div className={styles.noDetailsWrap}>
									<div className={styles.noDetailsText}>
										No additional payments yet
									</div>
									{hasLeadAccess && (
										<div
											className={styles.noDetailsBtn}
											onClick={() => {
												setCustomPayEdit(true);
											}}>
											+ Add
										</div>
									)}
								</div>
							</>
						)}
					</div>
				) : (
					<div className={styles.customTypeWrap}>
						<div className={styles.leadPaySecTitle}>
							{randomPayments?.length > 0 ? 'Edit' : 'Add'}
						</div>
						{rows?.map((item: any, index: number) => (
							<div className={styles.customTypeRow} key={index}>
								<div>
									<div className={styles.customTypeFieldLabel}>
										Payment Name
									</div>
									<TextField
										id='paymentName'
										variant='outlined'
										type='text'
										inputProps={{
											sx: {
												width: '240px',
												'&::placeholder': {
													fontWeight: '400',
													fontSize: '14px',
												},

												'&.MuiOutlinedInput-input': {
													padding: '8px',
												},
											},
										}}
										value={item.paymentName}
										onChange={(event) =>
											handlePaymentName(item.id, event.target.value)
										}
										error={!item?.isNameValid || !isNameUniqueCheck(rows, item)}
										helperText={
											!isNameUniqueCheck(rows, item) && !item?.isNameValid
												? 'Payment name already exist'
												: isNameUniqueCheck(rows, item) && !item?.isNameValid
												? 'Please fill the field'
												: ''
										}
									/>
								</div>
								<div>
									<div className={styles.customTypeFieldLabel}>Amount</div>

									<TextField
										id='price'
										variant='outlined'
										type='number'
										inputProps={{
											sx: {
												width: '240px',
												'&::placeholder': {
													fontWeight: '400',
													fontSize: '14px',
												},

												'&.MuiOutlinedInput-input': {
													padding: '8px',
												},
											},
										}}
										value={item.price}
										onChange={(event) =>
											handlePriceChange(item.id, event.target.value)
										}
										error={!item?.isPriceValid}
										helperText={
											!item?.isPriceValid ? 'Please fill valid amount' : ''
										}
									/>
								</div>
								<div>
									{rows?.length > 1 && (
										<button
											className={styles.deleteTypeBtn}
											onClick={() => handleRemoveRow(item.id)}
											style={{ marginTop: '40PX' }}>
											<img src={deleteIcon} alt='delete' />
										</button>
									)}
								</div>
							</div>
						))}
						<div className={styles.addMoreTypeBtnWrap}>
							<button className={styles.addMoreTypeBtn} onClick={handleAddRow}>
								<AddIcon sx={{ fontSize: '18px', marginRight: '6PX' }} />
								Add more
							</button>
						</div>
						<div
							className={styles.editSectionBtnWrap}
							style={{ marginTop: '18PX' }}>
							<CancelBtn onClick={() => cancelButtonClick()} />
							<SaveBtn
								onClick={handleRandomPayment}
								disabled={
									hasEmptyPaymentName ||
									!hasPriceNotGreaterThanZero ||
									randomPaymentLoading ||
									hasDuplicatePaymentName
								}
								loader={randomPaymentLoading}
							/>
						</div>
					</div>
				)}
			</div>
			<Dialog
				open={recordPayOpen}
				onClose={closePayRecordPopup}
				sx={{
					zIndex: '9999999',
					minWidth: '408px',
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '400px',
						maxHeight: '535PX',
						borderRadius: '12px',
						boxShadow:
							'0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
						left: 0,
						background: '#FFFFFF',
						overflow: 'hidden',
						padding: '0px',
					},
				}}>
				<RandomRecordPay
					leadData={leadById}
					onClose={closePayRecordPopup}
					selectedPay={selectedPay}
					setSelectedPay={setSelectedPay}
					add={false}
					loader={createPaymentLoading}
				/>
			</Dialog>
			<Dialog
				open={payLinkOpen}
				onClose={closePayLinkPopup}
				sx={{
					zIndex: '9999999',
					minWidth: '408px',
					'& .MuiDialog-paper': {
						width: '100%',
						maxWidth: '400px',
						maxHeight: '535PX',
						borderRadius: '12px',
						boxShadow:
							'0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
						left: 0,
						background: '#FFFFFF',
						overflow: 'hidden',
						padding: '0px',
					},
				}}>
				<RandomPayLink
					leadData={leadById}
					onClose={closePayLinkPopup}
					selectedPay={selectedPay}
					setSelectedPay={setSelectedPay}
					hasNurturePay={hasNurturePay}
					add={false}
					loader={sendPaymentLinkLoading}
				/>
			</Dialog>
			{deleteModal && (
				<DeletePopup
					open={deleteModal}
					onClose={handleDeleteClosePopup}
					noBtnClick={handleDeleteClosePopup}
					yesBtnClick={handleDeletePayment}
					loader={deleteAdditionalPayLoading}
				/>
			)}
		</div>
	);
}

export default LeadRandomPayments;
