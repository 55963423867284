import { InputType } from "./types";
export const RequiredEmptyFieldText = "Field required";
export const InvalidNameText = "Field required";

// export const RequiredEmptyFieldText = "*This is a required filed";
export const ErrorMessageBasedOnType = (type: InputType) => {
  const errorMessage = {
    email: "Invalid E-mail address",
    password: "Invalid Password",
    text: "Field cannot be empty",
    phone: "Invalid Phone number",
    textarea: "Field cannot be empty",
    number: "Field cannot be empty",
    name: "Name must be grater than 50 characters.",
    contactName: "Invalid Name",
    pin: "Invalid PIN code",
  }[type];
  return errorMessage;
};
