import React from "react";
import styles from "../DemoBooking.module.css";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function DemoCancelPopup(props: any) {
  const {
    openCancelModal,
    noButtonClick,
    yesButtonClick,
    deleteDemoBookedLoading,
    handleCancelModalClose,
  } = props;
  return (
    <>
      {openCancelModal && (
        <div className={styles.demoCancelPopupWrapper}>
          <div className={styles.demoCancelPopupWrap}>
            <div
              onClick={handleCancelModalClose}
              className={styles.closeModalButton}
            >
              <CloseIcon />
            </div>
            <div className={styles.modalTitle}>
              Are you sure you want to Cancel this Demo?
            </div>
            <div className={styles.buttonSection}>
              <button onClick={noButtonClick} className={styles.noButton}>
                No
              </button>
              <button onClick={yesButtonClick} className={styles.yesButton}>
                {deleteDemoBookedLoading ? (
                  <CircularProgress size={25} color={"inherit"} />
                ) : (
                  "Yes"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DemoCancelPopup;
