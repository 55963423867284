import React, { useState } from "react";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  List,
  ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./GeneralSetup.module.css";
import lockIcon from "../../assets/images/lockIcon.svg";

export function GeneralSetup(props: any) {
  const { item, handleStatus, index, isActive, disabled, itemAllAccess } =
    props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Box
      sx={{
        pointerEvents: disabled || itemAllAccess === false ? "none" : "auto",
      }}
    >
      <Link
        key={item}
        to={
          item === "Email Integration"
            ? item?.slice(0, 5)
            : item === "Roles and Permission"
            ? "Rolesandpermission"
            : item === "Student ID"
            ? "Student"
            : item === "Recent Reports"
            ? "/app/Reports/RecentReports" // Corrected navigation paths for reports
            : item === "Created By Me"
            ? "/app/Reports/CreatedByMe"
            : item === "All Reports"
            ? "/app/Reports/AllReports"
            : item?.replace(" ", "") // Default fallback
        }
        className={styles.link}
        onClick={() => {
          if (disabled === false) {
            handleStatus(index);
            setIsExpanded(!isExpanded);
          }
        }}
      >
        <ListItem
          disablePadding
          className={`${index === isActive ? styles.active : ""} `}
          sx={{
            alignItems:
              itemAllAccess === false ? "space-between" : "flex-start",
          }}
        >
          <ListItemButton sx={{ textAlign: { xs: "center", sm: "left" } }}>
            <ListItemText primary={item} />
          </ListItemButton>
          {itemAllAccess === false && (
            <ListItemIcon
              sx={{
                overflow: "hidden",
                justifyContent: "flex-end",
                minWidth: "25px",
              }}
            >
              <img src={lockIcon} alt="lockIcon" />
            </ListItemIcon>
          )}
        </ListItem>
      </Link>
    </Box>
  );
}
