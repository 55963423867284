import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import nurtureLogo from "../components/Assets/nurtureLogo.svg";
import nurtureLogo44 from "../components/Assets/nurtureLogo44.svg";
import submitted from "../components/Assets/submitted.svg";

const SuccessPage: React.FC = () => {
  const phoneNumber = "+919895114640"; // Replace with your actual phone number

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      padding={3}
    >
      <Box
        sx={{
          marginBottom: "100px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={submitted} alt="Logo" width={165} />

        <Box marginTop={1}>
          <Typography
            textAlign="center"
            sx={{
              color: "#142C44",
              fontSize: "14px",
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: "500px",
              lineHeight: "22px",
              marginBottom: "10px",
              maxWidth: "320px",
            }}
          >
            Thank you for submitting the form! Your information has been
            successfully submitted.
          </Typography>
          <Typography
            sx={{
              color: "#667085",
              fontSize: "13px",
              fontFamily: "Poppins",
              fontWeight: "400px",
              lineHeight: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            We appreciate your time and will be in touch shortly.
          </Typography>
        </Box>
      </Box>

      <Box sx={{ textAlign: "center", marginTop: "30px", fontSize: "12px" }}>
        <img src={nurtureLogo} alt="Nurture Logo" />
        <Typography sx={{ color: "#7C7C7C", lineHeight: 1, fontSize: "12px" }}>
          Powered by
        </Typography>
        <Typography sx={{ fontSize: "12px" }}>nurturecrm.in</Typography>
      </Box>
    </Box>
  );
};

export default SuccessPage;
