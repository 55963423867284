import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import styles from "./Registration.module.css";
import { useNavigate, useLocation } from "react-router-dom";

const DomainAndURLSetup = (props: any) => {
  const [domain, setDomain] = useState<string>("");
  const { email } = props;
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (props.success && !showLoader) {
      setShowLoader(true);
      setLoadingPercentage(0); // Reset the loading percentage if needed
      const interval = setInterval(() => {
        setLoadingPercentage((prev) => (prev + 10 <= 100 ? prev + 10 : prev));
      }, 2000);

      const timeout = setTimeout(() => {
        setShowLoader(false);
        clearInterval(interval);
        setLoadingPercentage(0); // Reset the loading percentage
      }, 20000); // 20 seconds

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [props.success]);

  // const onHandleChange = (event: any) => {
  //   setDomain(event.target.value);
  // };
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(event.target.value);

    // Regex to check for any non-alphanumeric character
    const regex = /[^a-zA-Z0-9]/;
    if (regex.test(event.target.value)) {
      setErrorMessage("Please avoid using special characters.");
    } else {
      setErrorMessage(null); // Reset error message when valid input is provided
    }
  };

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   if (domain.toString().length >= 4 && domain.toString().length <= 8) {
  //     let data = {
  //       email: email,
  //       subdomain: domain,
  //     };
  //     props.onSubmit(data);

  //     // // Remove query parameters from URL
  //     // const newLocation = {
  //     //   ...location,
  //     //   search: "", // Remove the query parameters
  //     // };

  //     // navigate(newLocation, { replace: true });
  //   }
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Regex to check for any non-alphanumeric character
    const regex = /[^a-zA-Z0-9]/;
    if (regex.test(domain)) {
      setErrorMessage("Please avoid using special characters.");
      return; // Do not proceed with form submission
    }

    if (domain.toString().length >= 4 && domain.toString().length <= 12) {
      let data = {
        email: email,
        subdomain: domain,
      };
      props.onSubmit(data);
    }
  };

  return (
    <Box
      sx={{
        px: {
          lg: 10,
          md: 6,
        },
      }}
    >
      {showLoader ? (
        <Box
          display="flex"
          textAlign="center"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          p={3}
        >
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="determinate"
              value={loadingPercentage}
              size={100}
              color={"primary"}
            />
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                position: "absolute",
                fontSize: "1.2rem", // Bigger font size to match the larger loader
              }}
            >{`${Math.round(loadingPercentage)}%`}</Typography>
          </Box>
          <Typography component="h5" fontWeight="bold" variant="h5" mt={2}>
            Hang in there!
          </Typography>
          <Typography mt={1}>
            We are carefully building your custom domain, just as one would
            nurture customer loyalty.
          </Typography>
        </Box>
      ) : (
        <form onSubmit={handleSubmit} className={styles.form_body}>
          <Typography
            sx={{
              fontSize: {
                lg: 30,
                md: 26,
                sm: 25,
                xs: 19,
              },
              fontFamily: "Poppins",
              fontWeight: "600",
              color: "#142C44",
            }}
          >
            Choose your subdomain
          </Typography>
          <Box>
            <p
              style={{
                margin: "0px 0px 25px",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                color: "#71717A",
                lineHeight: 2,
              }}
            >
              Setup your Domain & URL
            </p>
          </Box>
          <Box>
            <InputLabel
              sx={{
                fontSize: "14px",
                color: "#142C44",
                fontWeight: "500",
                fontFamily: "Poppins",
                my: {
                  lg: "10px",
                  md: "6px",
                  sm: "5px",
                  xs: "5px",
                },
              }}
            >
              Enter Domain
            </InputLabel>
            <TextField
              style={{ width: "100%" }}
              sx={{
                ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  py: {
                    lg: "14px",
                    md: "12px",
                    sm: "8px",
                    xs: "8px",
                  },
                  fontSize: "16px",
                  fontFamily: "Poppins",
                },
              }}
              placeholder="Checkout your domain"
              required
              id={"domain"}
              variant="outlined"
              type="text"
              inputProps={{
                maxLength: 12,
                style: {
                  letterSpacing: "4px",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                },
              }}
              defaultValue={domain}
              value={domain}
              error={domain.toString().length < 4}
              onChange={onHandleChange}
              size="small"
            />
            {!props.message && !errorMessage ? (
              <FormHelperText
                sx={{
                  alignItems: "center",
                  display: "flex",
                  color: "#667085",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                }}
              >
                <AiOutlineInfoCircle size={18} style={{ marginRight: "5px" }} />{" "}
                Minimum 4 and Maximum 12 characters
              </FormHelperText>
            ) : (
              <FormHelperText
                sx={{
                  alignItems: "center",
                  display: "flex",
                  color: "#D32F2F",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                }}
              >
                {props.message || errorMessage}
              </FormHelperText>
            )}
          </Box>
          <Box
            sx={{
              my: {
                lg: "24px",
                md: "24px",
                sm: "18px",
                xs: "18px",
              },
            }}
          >
            <InputLabel
              sx={{
                fontSize: "14px",
                color: "#142C44",
                fontWeight: "500",
                fontFamily: "Poppins",
              }}
            >
              Your URL
            </InputLabel>
            <Box
              sx={{
                background: "#E2F8F3",
                border: "1px solid #91E1CE",
                borderRadius: "8px",
                display: "flex",
                justifyContent: domain ? "unset" : "space-between",
                py: {
                  lg: "24px",
                  md: "18px",
                  sm: "18px",
                  xs: "18px",
                },
                px: {
                  lg: "38px",
                  md: "18px",
                  sm: "18px",
                  xs: "18px",
                },
                my: {
                  lg: "10px",
                  md: "10px",
                  sm: "8px",
                  xs: "8px",
                },
                width: {
                  lg: "410px",
                  md: "380px",
                },
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#142C44",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  https://
                </Typography>
                <Typography
                  sx={{
                    color: "#11C89B",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: "18px",
                  }}
                >
                  {domain}
                </Typography>
              </Box>
              <Typography
                sx={{
                  color: "#11C89B",
                  fontFamily: "Poppins",
                  fontWeight: "500",
                  fontSize: "18px",
                }}
              >
                .nurturecrm.in
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              fullWidth
              className={styles.btn_continue}
              type="submit"
              sx={{ textTransform: "capitalize" }}
            >
              {props.loading ? (
                <CircularProgress size={25} color={"inherit"} />
              ) : (
                "Continue"
              )}
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default DomainAndURLSetup;
