import React from "react";
import { Typography, FormControlLabel, Checkbox } from "@mui/material";

function FilterCheckbox(props: any) {
  const { keyVal, checked, checkboxFunction, label } = props;
  return (
    <>
      <FormControlLabel
        key={keyVal}
        control={
          <Checkbox
            sx={{
              strokeWidth: 1,
              color: "#D0D5DD",
              // zIndex: "99999999999",
              "&:hover": {
                backgroundColor: "transparent",
              },
              "&:focus": {
                backgroundColor: "transparent",
              },
            }}
            checked={checked}
            onChange={checkboxFunction}
          />
        }
        label={
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#667085",
              lineHeight: "20px",
              textTransform: "capitalize",
            }}
          >
            {label}
          </Typography>
        }
      />
    </>
  );
}

export default FilterCheckbox;
