import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import SignIn from "../components/SignIn";
import { login } from "../service/signinSlice";
import { LoginDTO, UserDetailsDTO } from "../service/types";
import { appEntery } from "../../../app/Home/service/homeSlice";
import { SetActiveInstitute } from "../../../../service/activeInstituteSlice";
// import GetTnc from "../../../app/TermsOfServices/service/termsofservicesSlice"
import { TncDTO } from "../../../app/TermsOfServices/service/types";
import { GetTnc } from "../../../app/TermsOfServices/service/termsofservicesSlice";
interface StateProps {
  isLogin: boolean;
  error: string | boolean | undefined;
  loading: boolean;
  userDetails: UserDetailsDTO | null;
  activeInstitute: any;
  isLogout: boolean;
  tnc: any;
}

interface DispatchProps {
  registrationStep(data: string): void;
  userLogin(data: LoginDTO): void;
  setActiveInsitute(data: string): void;
  GetTnc(): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    isLogin: state.login.isLogin,
    error: state.login.error,
    loading: state.login.loading,
    userDetails: state.login.userDetails,
    activeInstitute: state.activeInstitute.data,
    isLogout: state.login.isLogout,
    tnc: state.tnc.tnc,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    userLogin: (data: LoginDTO) => {
      dispatch(login(data));
    },

    GetTnc: () => {
      dispatch(GetTnc()); // Corrected dispatch call
    },
    registrationStep: (data: string) => {
      dispatch(appEntery(data));
    },
    setActiveInsitute: (data: string) => {
      dispatch(SetActiveInstitute(data));
    },
  };
};

export interface LoginProps extends StateProps, DispatchProps {}
export const LoginComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
