import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import InstituteIcon from "../../../../assets/images/Union.svg";
import logo2 from "../../../../assets/images/logo.png";
import {
  ACCOUNT,
  INSTITUTE,
  INSTITUTEPART,
} from "../../signup/container/SignupString";
import styles from "./Registration.module.css";

export function InstituteIndividual(props: any) {
  const { setPageState, setStatus } = props;

  const handleChangeInst = () => {
    setPageState(2);
    setStatus(2);
    localStorage.setItem("pageState", String(2));
  };

  return (
    <Box className={styles.form_body}>
      <Box
        className={styles.logo_box}
        sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
      >
        <img src={logo2} height="35" width="150" alt="wnjdwnj" />
      </Box>
      <Box>
        <Typography
          className={styles.password_text_head}
          sx={{
            fontSize: {
              lg: 30,
              md: 30,
              sm: 25,
              xs: 19,
            },
            textAlign: "center",
          }}
        >
          {ACCOUNT}
        </Typography>
      </Box>

      <br />
      <Box className={styles.Individual_tutor}>
        <Grid container spacing={2} className={styles.containerCard}>
          <Grid item xs={3} className={styles.containerLeft}>
            <div className={styles.background_icon}>
              <img src={InstituteIcon} height={37} width={36} alt="sbchqsb" />
            </div>
          </Grid>
          <Grid
            item
            xs={9}
            onClick={handleChangeInst}
            className={styles.containerRight}
          >
            <Typography className={styles.Individual_tutor_text}>
              {INSTITUTE}
            </Typography>
            <Typography className={styles.Individual_sub_text}>
              {INSTITUTEPART}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
