import React, { useEffect, useState } from "react";
import { resetSingleFormData } from "../service/webformSlice";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { Box, Tab, Tabs } from "@mui/material";
import WebformTab from "../../components/SyncedDataTab/ContactTabs/WebformTab";
import FormsTab from "./WebformTabs/FormsTab";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type webformsData = {
  instituteId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

type formResponseData = {
  instituteId: string;
  formId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

const Webform = (props: any) => {
  const {
    CreateWebform,
    GetAllWebform,
    CreateWebformSuccess,
    CreateWebformId,
    getAllFormByInstituteData,
    UpdateWebform,
    UpdateWebformSuccess,
    GetAllWebformLoading,
    GetWebformForInstitute,
    getFormByInstituteData,
    GetFormResponse,
    BulkLeadCreation,
    DeleteWebformResponse,
    deleteWebformSuccess,

    getStudentContactByIdData,
    GetStudentContactById,
    getFormResponseSuccess,
    getFormResponseData,
    bulkLeadCreationSuccess,
    deleteWebformResponseSuccess,
    bulkLeadCreationError,
    deleteWebformResponseError,
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetNurtureLeadBoard,
    CreateEnquiry,
    GetMailEntriesByInstitute,
    GetInstituteById,
    GetCourseById,
    GetBatchById,
    GetLeadListByInstituteAndContact,
    CreateSource,
  } = props;
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const authUser = auth();
  const [copied, setCopied] = React.useState(false);

  const [value, setValue] = useState<number>(0);
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabSelect = (value: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("webformTab", value);
    const userFromLocalStorage = authUser?.institute[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformBranch = urlSearchParams.get("webformBranch");

    if (value !== "webformResponses") {
      queryParams.delete("webformSearchTerm");
      queryParams.delete("filteredCourses");
      queryParams.delete("filteredBranches");
      queryParams.delete("filteredBatches");
      queryParams.delete("filteredTags");
      queryParams.delete("webformId");
      queryParams.delete("formType");
      queryParams.delete("formBranch");
      dispatch(resetSingleFormData());
    }
    if (value === "webformResponses") {
      queryParams.delete("searchTerm");
      queryParams.delete("webformSharePopup");
      queryParams.delete("webformType");
      // queryParams.delete("webformBranch");
    }
    let branchId: any;
    if (value === "webformResponses") {
      branchId = "ALL";
    } else {
      if (!hasAllBranchAccess) {
        branchId = webformBranch;
      } else {
        branchId = "";
      }
    }
    const data: webformsData = {
      instituteId: userFromLocalStorage?.instituteId,
      limit: 8,
      branchId: branchId,
      lastEvaluatedKey: "",
      lastEvaluatedSortKey: "",
    };
    GetAllWebform(data);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    );
  }

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformTab = urlSearchParams.get("webformTab");
    const webformType = urlSearchParams.get("webformType");
    const webformBranch = urlSearchParams.get("webformBranch");
    const formType = urlSearchParams.get("formType");
    const formBranch = urlSearchParams.get("formBranch");
    if (userFromLocalStorage) {
      if (webformTab === "forms") {
        setValue(0);
      } else if (webformTab === "webformResponses") {
        setValue(1);
      }
      let branchId: string;
      if (webformTab === "webformResponses") {
        if (formType === "BRANCH_SPECIFIC" && formBranch) {
          branchId = formBranch;
        } else {
          branchId = "ALL";
        }
      } else {
        if (webformType === "UNIVERSAL") {
          branchId = "ALL";
        } else if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
          branchId = webformBranch;
        } else {
          if (!hasAllBranchAccess) {
            branchId = "ALL";
          } else {
            branchId = "";
          }
        }
      }

      const data: webformsData = {
        instituteId: userFromLocalStorage?.instituteId,
        limit: 8,
        branchId: branchId,
        lastEvaluatedKey: "",
        lastEvaluatedSortKey: "",
      };
      GetAllWebform(data);
    }
  }, [setValue]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const urlSearchParams = new URLSearchParams(window.location.search);
    const webformTab = urlSearchParams.get("webformTab");
    const webformId = urlSearchParams.get("webformId");
    const webformType = urlSearchParams.get("formType");
    const webformBranch = urlSearchParams.get("formBranch");
    let branchId: string;
    if (webformType === "BRANCH_SPECIFIC" && webformBranch) {
      branchId = webformBranch;
    } else {
      branchId = "";
    }
    const data: formResponseData = {
      instituteId: userFromLocalStorage?.instituteId,
      formId: webformId ? webformId : "",
      limit: 15,
      branchId: "",
      lastEvaluatedKey: "",
      lastEvaluatedSortKey: "",
    };

    if (
      userFromLocalStorage &&
      webformId &&
      webformTab === "webformResponses"
    ) {
      GetWebformForInstitute(userFromLocalStorage?.instituteId, webformId);
      GetFormResponse(data);
    } else {
      dispatch(resetSingleFormData());
    }
  }, []);

  React.useEffect(() => {
    if (copied) {
      dispatch(
        setToastNotification({
          message: String("URL copied!"),
          type: "success",
          snackOpen: true,
        })
      );
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <div
      style={{
        padding: "20px 0",
        overflow: "hidden",
        height: "calc(100vh - 89px)",
      }}
    >
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="billing tabs"
        sx={{
          mt: 0,
          mb: 1,
          ml: 2,
          backgroundColor: "white",
          borderBottom: "1px solid #E9E9E9",
          "& .MuiTabs-flexContainer": {
            gap: "24px",
          },
          "& .MuiTabs-indicator": {
            height: "1px",
          },
        }}
      >
        <Tab
          label="Webform"
          {...a11yProps(0)}
          sx={{
            padding: "0",
            textTransform: "capitalize",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",

            "& .Mui-selected": {
              color: "#11C89B",
            },
          }}
          onClick={() => {
            handleTabSelect("forms");
          }}
        />
        <Tab
          label="Webform Responses"
          {...a11yProps(1)}
          sx={{
            textTransform: "capitalize",
            padding: "0",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#B4B4B4",
            minWidth: "fit-content",
          }}
          onClick={() => {
            handleTabSelect("webformResponses");
          }}
        />
      </Tabs>
      <div
        style={{
          maxHeight: "calc(100vh - 180px)",
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            pt: 2,
            backgroundColor: "white",
            maxHeight: "calc(100vh - 190px)",
            pr: 2,
            pl: 2,
          }}
        >
          {value === 0 && (
            <FormsTab
              CreateWebform={CreateWebform}
              CreateWebformSuccess={CreateWebformSuccess}
              CreateWebformId={CreateWebformId}
              getAllFormByInstituteData={getAllFormByInstituteData}
              GetAllWebformLoading={GetAllWebformLoading}
              UpdateWebform={UpdateWebform}
              GetWebformForInstitute={GetWebformForInstitute}
              getFormByInstituteData={getFormByInstituteData}
              setCopied={setCopied}
              setValue={setValue}
              GetFormResponse={GetFormResponse}
              UpdateWebformSuccess={UpdateWebformSuccess}
              GetAllWebform={GetAllWebform}
              deleteWebformSuccess={deleteWebformSuccess}
            />
          )}
        </Box>
        {value === 1 && (
          <Box
            sx={{
              height: "100%",
              overflowY: "auto",
              pt: 2,
              backgroundColor: "white",
              maxHeight: "calc(100vh - 160px)",
              pr: 2,
              pl: 2,
            }}
          >
            <WebformTab
              GetWebformForInstitute={GetWebformForInstitute}
              GetFormResponse={GetFormResponse}
              BulkLeadCreation={BulkLeadCreation}
              DeleteWebformResponse={DeleteWebformResponse}
              page={"WEBFORM"}
              GetAllWebform={GetAllWebform}
              CreateEnquiry={CreateEnquiry}
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default Webform;
