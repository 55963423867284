import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./LeadExpand.module.css";
import UserAssign from "../../../../../components/UserAssign/UserAssign";
import FlagAssign from "../../../../../components/FlagAssign/FlagAssign";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import CountryCodePhoneInput from "../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import closeIcon from "../../../../../components/LeadPopup/Assets/popup-close.svg";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { resetLeadById } from "../../service/nurtureSlice";
import { useDispatch } from "react-redux";
import SmallFormPopupSkeleton from "../../../../../components/Skeletons/SmallFormPopupSkeleton";
import SourceManager from "../../../../../components/SourceManager/SourceManager";
import { getCombinedAssignees } from "../../../../../components/CommonFunctions/CommonFunctions";

export default function LeadDetailsEdit(props: any) {
  const {
    toggleLeadEditModal,
    leadDetails,
    UpdateLead,
    isLeadEditOpen,
    users,
    assignyId,
    editDefaultPriority,
    setLeadEditOpen,
    onPhoneChangePopup,
    leadListByContact,
    leadPopupApiCall,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const {
    leadUpdateSuccess,
    leadUpdateLoading,
    leadByIdLoading,
    createSourceLoading,
    leadBoard,
  } = useSelector((state: any) => state.nurture);
  const sourceList = leadBoard?.sourceList;

  const [contactCountryCode, setContactCountryCode] = React.useState("91");

  const [lead, setLead] = React.useState({
    id: "",
    instituteId: "",
    instituteName: "",
    studentEmail: "",
    studentFirstName: "",
    studentLastName: "",
    studentContact: "",
    enquirySource: "",
    courseName: "",
    courseId: "",
    branchId: "",
    batchId: "",
    batchName: "",
    assignedToName: "",
    assignedToId: "",
    enquiryStatus: "",
    enquiryStatusId: "",
    enquiryPriority: "",
    status: "",
    contactCountryCode: contactCountryCode,
    updatedById: "",
    updatedByName: "",
  });

  const [source, setSource] = React.useState<any>("");
  const [sourceError, setSourceError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isLeadEditOpen && leadDetails !== null && leadDetails !== undefined) {
      setLead(leadDetails);
      const leadSource = sourceList?.find(
        (item: any) => item.key === leadDetails?.enquirySource
      );
      if (leadSource) {
        setSource(leadSource);
      }
      setContactCountryCode(leadDetails?.contactCountryCode);
    }
  }, [setLead, leadDetails]);

  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStatePhone, setValidStatePhone] = React.useState<boolean>(true);
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateFirstName, setValidStateFirstName] =
    React.useState<boolean>(true);
  const [minRequireError, setMinRequireError] = React.useState<boolean>(false);

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
      }
      setErrorType("client");

      setLead({
        ...lead,
        [event.target.id]: event.target.value,
      });
    },
    [lead, errorType]
  );

  const handleLeadPriority = React.useCallback(
    (leadId: any, leadPriority: any) => {
      setLead({ ...lead, enquiryPriority: leadPriority });
    },

    [lead]
  );

  const handleLeadAssignee = React.useCallback(
    (leadId: any, user: any) => {
      setLead({
        ...lead,
        assignedToId: user?.id,
        assignedToName: user?.firstName + " " + user?.lastName,
      });
    },

    [lead]
  );

  const onSourceChange = React.useCallback(
    (value: any | null) => {
      if (value !== null) {
        setSource(value);
      } else {
        setSource("");
      }
    },
    [setSource]
  );

  const isValueInList = sourceList?.some(
    (item: any) => item.key === source?.key
  );

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);

  const setValidateStatusPhone = React.useCallback((val: boolean) => {
    setValidStatePhone(val);
  }, []);

  const setValidateStatusFirstName = React.useCallback((val: boolean) => {
    setValidStateFirstName(val);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      lead.studentContact?.startsWith("971") &&
      lead?.studentContact?.length !== 12
    ) {
      setValidStatePhone(false);
      return;
    }
    try {
      if (source === "") {
        setSourceError(true);
      } else {
        setSourceError(false);
      }

      if (source && isValueInList) {
        if (!lead.studentContact) {
          UpdateLead({
            ...lead,
            updatedById: authUser?.institute[0]?.id,
            updatedByName: authUser?.firstName + " " + authUser?.lastName,
            enquirySource: source?.key,
          });
        } else {
          if (!validStatePhone) {
            setValidStatePhone(false);
          } else {
            UpdateLead({
              ...lead,
              updatedById: authUser?.institute[0]?.id,
              updatedByName: authUser?.firstName + " " + authUser?.lastName,
              enquirySource: source?.key,
            });
          }
        }
      }
    } catch (error) {}
  };

  const handleResetLeadById = React.useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("addLeadPayment");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    dispatch(resetLeadById());
  }, []);

  const handlePhoneChange = React.useCallback(
    (event: any) => {
      event.preventDefault();
      if (
        lead.studentContact?.startsWith("971") &&
        lead?.studentContact?.length !== 12
      ) {
        setValidStatePhone(false);
        return;
      }
      if (source === "") {
        setSourceError(true);
      } else {
        setSourceError(false);
      }

      if (source && isValueInList) {
        if (!lead.studentContact) {
          onPhoneChangePopup({
            ...lead,
            updatedById: authUser?.institute[0]?.id,
            updatedByName: authUser?.firstName + " " + authUser?.lastName,
            enquirySource: source?.key,
          });
          setLeadEditOpen(false);
        } else {
          if (!validStatePhone) {
            setValidStatePhone(false);
          } else {
            onPhoneChangePopup({
              ...lead,
              updatedById: authUser?.institute[0]?.id,
              updatedByName: authUser?.firstName + " " + authUser?.lastName,
              enquirySource: source?.key,
            });
            setLeadEditOpen(false);
          }
        }
      }
    },
    [onPhoneChangePopup, source, lead]
  );

  const combinedAssignees = getCombinedAssignees(users, leadDetails?.branchId);

  React.useEffect(() => {
    if (
      leadUpdateSuccess !== true &&
      leadUpdateSuccess !== false &&
      isLeadEditOpen === true
    ) {
      const queryParams = new URLSearchParams(window.location.search);

      if (lead.studentEmail) {
        queryParams.set("leadEmail", lead.studentEmail);
      } else {
        queryParams.delete("leadEmail");
      }
      if (lead.studentContact) {
        queryParams.set("leadPhone", lead?.studentContact);
      } else {
        queryParams.delete("leadPhone");
      }
      queryParams.delete("addLeadPayment");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
      // if (leadInstituteId) {
      //   leadPopupApiCall(
      //     leadInstituteId,
      //     lead.studentContact,
      //     lead.studentEmail
      //   );
      // }

      setLeadEditOpen(false);
    }
  }, [leadUpdateSuccess]);

  React.useEffect(() => {
    if (source) {
      setSourceError(false);
    }
  }, [source]);

  return (
    <div className={styles.leadEditWrapper}>
      <div className={styles.leadEditWrapperChild}>
        <div className={styles.popupClsWrap}>
          <button
            className={styles.clsBtn}
            onClick={() => {
              toggleLeadEditModal();
              handleResetLeadById();
            }}
          >
            <img src={closeIcon} alt="closeIcon" />
          </button>
        </div>
        {leadByIdLoading ? (
          <SmallFormPopupSkeleton />
        ) : (
          <>
            <div className={styles.popupTitle}>Edit Details </div>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
              onSubmit={
                leadDetails?.studentContact !== lead?.studentContact &&
                leadListByContact?.length > 1
                  ? handlePhoneChange
                  : handleSubmit
              }
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                container
                spacing={2}
              >
                <Grid item xs={12} lg={5.5}>
                  <NewFormTextInput
                    validate={true}
                    setValidStatus={setValidateStatusFirstName}
                    type={"text"}
                    id="studentFirstName"
                    label="First Name"
                    value={lead.studentFirstName}
                    onChange={(event: any) => {
                      setLead({
                        ...lead,
                        [event.target.id]: event.target.value.replace(/\d/, ""),
                      });
                    }}
                    error={!validStateFirstName}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    autoComplete="true"
                    require={true}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                  <NewFormTextInput
                    validate={true}
                    type={"text"}
                    id="studentLastName"
                    label="Last Name"
                    value={lead.studentLastName}
                    onChange={(event: any) => {
                      setLead({
                        ...lead,
                        [event.target.id]: event.target.value.replace(/\d/, ""),
                      });
                    }}
                    showErrorMessage={showErrMsz}
                    errorType={errorType}
                    autoComplete="true"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ marginTop: "14PX", marginBottom: "14px" }}>
                <NewFormTextInput
                  disabled={
                    leadDetails && leadDetails.studentEmail === ""
                      ? false
                      : true
                  }
                  validate={true}
                  setValidStatus={setValidateStatusEmail}
                  label="Email Address"
                  type="email"
                  id={"studentEmail"}
                  error={!validStateEmail}
                  value={lead.studentEmail}
                  onChange={handleChange}
                  showErrorMessage={showErrMsz}
                  errorType={errorType}
                  autoComplete="true"
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <InputLabel
                  sx={{
                    fontSize: "14px",
                    color: "#142C44",
                    fontWeight: "500",
                    marginBottom: "5px",
                    fontFamily: "Poppins",
                    display: "flex",
                  }}
                >
                  Phone Number
                </InputLabel>
                <CountryCodePhoneInput
                  value={lead.studentContact}
                  onChange={(event: any, country: any) => {
                    if (event !== "") {
                      setValidStatePhone(
                        isValidPhoneNumber(
                          event.replace(country.dialCode, ""),
                          country.countryCode.toUpperCase()
                        )
                      );
                    }
                    setLead({
                      ...lead,
                      studentContact: event,
                      contactCountryCode: country.dialCode,
                    });
                  }}
                  error={validStatePhone}
                />
                {minRequireError && (
                  <FormHelperText error={true}>
                    Please enter either phone number or email
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ marginBottom: "14px", textAlign: "center" }}>
                <InputLabel
                  sx={{
                    fontSize: "14px",
                    color: "#142C44",
                    fontWeight: "500",
                    marginBottom: "5px",
                    fontFamily: "Poppins",
                    display: "flex",
                  }}
                >
                  Source
                  <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
                </InputLabel>
                <SourceManager
                  sourceList={sourceList}
                  onSourceChange={onSourceChange}
                  source={source}
                  addLead={false}
                />

                {sourceError === true && (
                  <FormHelperText error={true}>
                    Please select a source.
                  </FormHelperText>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "14px",
                }}
              >
                {leadDetails && (
                  <UserAssign
                    selectedAssignyId={leadDetails && assignyId}
                    users={users && combinedAssignees}
                    handleLeadAssignee={handleLeadAssignee}
                    isForEnquiryModal={true}
                  />
                )}

                {leadDetails && (
                  <FlagAssign
                    selectedPriority={editDefaultPriority}
                    handleLeadPriority={handleLeadPriority}
                    isForEnquiryModal={true}
                  />
                )}
              </Box>

              <div className={styles.courseEditBtns}>
                <Button
                  fullWidth
                  onClick={() => {
                    toggleLeadEditModal();
                    handleResetLeadById();
                  }}
                  sx={{
                    textTransform: "capitalize",
                    color: "#9A9EA6",
                    borderColor: "#D0D5DD",
                    border: "1px solid",
                    borderRadius: "4PX",
                    ":hover": {
                      color: "#9A9EA6",
                      borderColor: "#D0D5DD",
                      backgroundColor: "#9a9ea612",
                    },
                  }}
                  variant="outlined"
                >
                  cancel
                </Button>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  sx={{
                    border: "1px solid",
                    borderRadius: "4PX",
                    marginBottom: "0",
                    textTransform: "capitalize",
                  }}
                  disabled={leadUpdateLoading || createSourceLoading}
                >
                  {leadUpdateLoading ? (
                    <CircularProgress size={20} color={"inherit"} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
