import { getStaffList, updateStaff } from "../service/Staffslice";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import { createStaffDTO, staffInitialStateDTO } from "../service/types";
import StaffListing from "../StaffListing/StaffListing";
import { connect } from "react-redux";
import { getBranchList } from "../../ManageBranch/service/Branchslice";
import { getInstituteById } from "../../InstituteProfile/service/instituteSlice";

interface StateProps extends staffInitialStateDTO {}

interface DispatchProps {
  GetStaffList(id: string): void;
  GetBranchList(id: string): void;
  UpdateStaffStatus(data: createStaffDTO): void;
  GetInstituteById(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    staffList: state.staff.staffList,
    staffById: state.staff.staffById,
    staffListWithCount: state.staff.staffListWithCount,
    success: state.staff.success,
    error: state.staff.error,
    loading: state.staff.loading,
    staffListLoading: state.staff.staffListLoading,
    createStaffLoading: state.staff.createStaffLoading,
    updateStaffLoading: state.staff.updateStaffLoading,
    staffByIdLoading: state.staff.staffByIdLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetStaffList: (id: string) => {
      dispatch(getStaffList(id));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    UpdateStaffStatus: (data: createStaffDTO) => {
      dispatch(updateStaff(data));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
  };
};

export interface BranchListProps extends StateProps, DispatchProps {}

export const StaffListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffListing);
