import { Alert, Button, ButtonGroup } from "@mui/material";
import toggleRadio from "../../assets/images/toggle-radio.svg";
import toggleRadioSelected from "../../assets/images/toggle-radio-selected.svg";
import Beginner from "../../assets/images/begin.svg";
import Intermediate from "../../assets/images/inter.svg";
import Expert from "../../assets/images/expert.svg";

export function BasicButtonGroup(props: {
  buttonList: any;
  className?: string;
  handleSelected: any;
  selected: any;
  errorMsg: any;
  disabled?: boolean;
  isFromCourseSection?: boolean;
}) {
  const {
    buttonList,
    className,
    handleSelected,
    selected,
    errorMsg,
    disabled,
    isFromCourseSection,
  } = props;

  return (
    <>
      <ButtonGroup
        disabled={disabled}
        variant="outlined"
        aria-label="outlined primary button group"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: 20,
          boxShadow: "none",
          flexWrap: "wrap",
          height: "10%",
        }}
      >
        {buttonList?.map((e: any, index: never) => (
          <Button
            disabled={disabled}
            key={index}
            className={className}
            style={{
              opacity: disabled ? 0.8 : 1,
              // backgroundColor: `${
              //   !selected.includes(index) ? "#fff" : "#11C89B"
              // }`,
              backgroundColor: "rgba(243, 243, 248, 0.44)",
              border: `${
                selected?.includes(index)
                  ? "1px solid rgba(17, 200, 155, 0.5"
                  : "none"
              }`,
              // color: `${!selected.includes(index) ? "#BDBFC3" : "#fff"}`,
              color: "#667085",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "500",
              borderRadius: "6PX",
              textTransform: "capitalize",
            }}
            onClick={() => handleSelected(index)}
          >
            <img
              src={
                selected?.includes(index) ? toggleRadioSelected : toggleRadio
              }
              alt="toggle"
              style={{ marginRight: "6PX" }}
            />
            {isFromCourseSection && (
              <img
                src={
                  e.label === "Beginner"
                    ? Beginner
                    : e.label === "Intermediate"
                    ? Intermediate
                    : Expert
                }
                alt={"level"}
                style={{
                  margin: " 0 5px",
                  height: "15px",
                }}
              />
            )}
            {e.label}
          </Button>
        ))}
      </ButtonGroup>
      {errorMsg && (
        <Alert
          sx={{
            // margin: "10px",
            backgroundColor: "unset",
            padding: "0px",
            color: "rgb(244 67 54)",
            fontSize: "12px",
            "& .MuiAlert-icon": {
              fontSize: 14,
              alignItems: "center",
              marginRight: "4PX",
            },
          }}
          severity="error"
        >
          {errorMsg}
        </Alert>
      )}
    </>
  );
}
