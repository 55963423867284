import React from "react";
import { useAuthUser } from "react-auth-kit";
import ZapierTab from "./ContactTabs/ZapierTab";

function ZapierData(props: any) {
  const {
    contactList,
    contactListLoading,
    GetContactLists,
    DeleteContactList,
    deleteContactLoading,
    deleteContactSuccess,
    deleteContactError,
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetNurtureLeadBoard,
    CreateEnquiry,
    addedEnquiryContactSuccess,
    addedEnquiryContactError,
    addedEnquiryContactLoading,
    AddEnquiryForContact,
    emailConfigLoading,
    emailConfigSuccess,
    GetInstituteById,
    GetCourseById,
    GetBatchById,
    mailConfigsList,
    GetMailConfigList,
    saveImportedDataSuccess,
    CreateSource,
    type,
    zapierActive,
    leadCreatedId,
    AddEnquiryForZapier,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";
    const enquirySource = "Zapier";
    if (userFromLocalStorage) {
      GetContactLists(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        enquirySource
      );
    }
  }, [GetContactLists]);

  return (
    <div style={{ width: "100%", padding: "30px 20px" }}>
      {contactListLoading ? (
        <></>
      ) : (
        <>
          <ZapierTab
            contactList={contactList}
            contactListLoading={contactListLoading}
            DeleteContactList={DeleteContactList}
            deleteContactLoading={deleteContactLoading}
            deleteContactSuccess={deleteContactSuccess}
            deleteContactError={deleteContactError}
            CreateEnquiry={CreateEnquiry}
            AddEnquiryForContact={AddEnquiryForContact}
            addedEnquiryContactSuccess={addedEnquiryContactSuccess}
            addedEnquiryContactError={addedEnquiryContactError}
            GetContactLists={GetContactLists}
            GetInstituteById={GetInstituteById}
            GetCourseById={GetCourseById}
            GetBatchById={GetBatchById}
            page={"ZAPIER"}
            leadCreatedId={leadCreatedId}
            AddEnquiryForZapier={AddEnquiryForZapier}
          />
        </>
      )}
    </div>
  );
}

export default ZapierData;
