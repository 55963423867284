import { FormControl } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import styles from "../Manegecourses.module.css";
import { BasicButtonGroup } from "../../../../../../components/BasicButtonGroup/BasicButtonGroup";

export default function CourseTypeManager(props: any) {
  const {
    courseType,
    handleTypeChange,
    selectedType,
    disabled,
    errorMsg,
    isFromCourseSection,
    setCourseDetails,
    courseDetails,
  } = props;
  const [selected, setSelected] = useState<any[]>([]);
  const handleSelected = useCallback(
    (value: never) => {
      if (selected.includes(value)) {
        setSelected((prevSelected) => prevSelected?.filter((s) => s !== value));
      } else {
        setSelected((prevSelected) => [...prevSelected, value]);
      }

      setSelected((prevSelected) => {
        handleTypeChange(
          prevSelected.map((value) => ({
            courseType: courseType[value],
          }))
        );
        return prevSelected;
      });
    },
    [selected, handleTypeChange]
  );

  function getIndex(selectedType: any[]) {
    const indexes =
      selectedType &&
      selectedType?.map((type: any) => {
        return courseType?.findIndex(
          (obj: any) => obj?.id === type?.courseType?.id
        );
      });
    return indexes;
  }

  const mappedData = courseType
    ? courseType.map((item: any, index: number) => {
        return {
          label: item.courseTypeName,
          value: index,
        };
      })
    : [];

  useEffect(() => {
    // if (selected?.length === 0 && selectedType) {
    //   setSelected(getIndex(selectedType));
    // }
    const courseTypes = courseType?.filter(
      (item: any) => item.courseTypeName?.toLowerCase() === "classroom"
    );
    setCourseDetails({
      ...courseDetails,
      courseType: [{ courseType: courseTypes[0] }],
    });
    setSelected([0]);
  }, []);

  return (
    <FormControl
      fullWidth
      variant="standard"
      className="formControl"
      sx={{ mb: 1 }}
    >
      <div
        style={{
          fontSize: "12px",
          color: "#142C44",
          fontWeight: "500",
          fontFamily: "Poppins",
          display: "flex",
          margin: "0px 0px 5px",
        }}
      >
        Course Type{" "}
        <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span>
      </div>
      <BasicButtonGroup
        className={isFromCourseSection ? "" : styles?.courseType}
        buttonList={mappedData}
        handleSelected={handleSelected}
        selected={selected}
        errorMsg={errorMsg}
        disabled={disabled}
      />
    </FormControl>
  );
}
