import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./AgentsListing.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ozonetel from "../Assets/ozonetel.svg";
import help from "../Assets/help.svg";
import AgentsListingComponent from "./AgentsListingComponent";

function AgentsListing() {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          padding: "14px 16px",
          background: "#EEEEEE",
          maxHeight: "calc(100vh - 65px)",
          position: "relative",
        }}
      >
        <Box
          sx={{
            overflow: "auto",
            background: "#fff",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
            minHeight: "calc(100vh - 93px)",
            maxHeight: "calc(100vh - 93px)",
          }}
        >
          <div
            style={{
              minHeight: "calc(100vh - 93px)",
              maxHeight: "calc(100vh - 93px)",
            }}
          >
            <div>
              <div className={styles.PageBackBtnWrap}>
                <button
                  className={styles.PageBackBtn}
                  onClick={() => navigate(`/app/Integrations`)}
                >
                  <ArrowBackIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "15PX",
                      color: "#667085",
                    }}
                  />
                  Back
                </button>
              </div>
              <div
                style={{
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  paddingLeft: "20px",
                  marginBottom: "12px",
                }}
              >
                Ozonetel Integration
              </div>
              <Box sx={{ paddingLeft: "20px" }}>
                <AgentsListingComponent />
              </Box>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default AgentsListing;
