import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

const CourseDescriptionSkeleton = () => {
  return (
    <div className={styles.rightSection}>
      <div>
        <Skeleton animation="wave" variant="text" width="20%" height={50} />
        <Skeleton animation="wave" variant="text" width="25%" height={20} />
      </div>
      <div style={{ padding: "20px 30px" }}>
        <div
          style={{
            width: "30%",
            marginBottom: "20px",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
        </div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={25}
            />
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={50}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDescriptionSkeleton;
