import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import getTeamMember, {
  getDescriptionWithMentionedNames,
} from "../CommonFunctions/CommonFunctions";
import { useSelector } from "react-redux";
import clockIcon from "../../assets/images/clockTask.svg";
import dateIcon from "../../assets/images/date.svg";

function ActivitySession(props: any) {
  const { data } = props;
  const employees = useSelector((state: any) => state.staff.staffList);

  const modifiedDescription = getDescriptionWithMentionedNames(
    data?.activityData?.description,
    employees
  ).replace(/\n/g, "<br>");

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityTop}>
            <div className={styles.activityDateStyle}>
              {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
            </div>
            <div className={styles.activityHeadStage}>
              <div className={styles.activityHeadStageIcon}>
                <img src={stageDot} alt="" />
              </div>
              <div>{data?.currentStage}</div>
            </div>
            <div className={styles.activityHeadName}>
              <div className={styles.activityHeadStageIcon}>
                <img src={nameLine} alt="" />
              </div>
              <div style={{ textTransform: "capitalize" }}>
                {data.activityType === "SESSION_ADDED"
                  ? getTeamMember(data?.createdById, employees)?.firstName +
                    " " +
                    getTeamMember(data?.createdById, employees)?.lastName
                  : getTeamMember(data?.updatedById, employees)?.firstName +
                    " " +
                    getTeamMember(data?.updatedById, employees)?.lastName}
              </div>
            </div>
          </div>

          <div
            className={styles.activityDemoTitle}
            style={{
              color:
                data.activityType === "SESSION_CANCELLED"
                  ? "#EC3939"
                  : "#142C44",
            }}
          >
            {data.activityType === "SESSION_ADDED"
              ? "Session Booked"
              : data.activityType === "SESSION_COMPLETED"
              ? "Session Completed"
              : data.activityType === "SESSION_CANCELLED"
              ? "Session Cancelled"
              : ""}
          </div>
          {data.activityType === "SESSION_COMPLETED" && (
            <div
              className={styles.activityDescription}
              dangerouslySetInnerHTML={{ __html: modifiedDescription }}
            />
          )}

          <div className={styles.activityDemoBottom}>
            <div className={styles.activityDemoBatchName}>
              {data?.enquiryData?.batchName}
            </div>
            <div className={styles.activityDemoDate}>
              <div className={styles.activityDemoDateIcon}>
                <img src={dateIcon} alt="dateIcon" style={{ width: "12px" }} />
              </div>
              <div className={styles.activityDemoDateValue}>
                {moment(data?.activityData?.sessionDate).format(
                  "DD MMM YYYY, ddd"
                )}
              </div>
            </div>
            <div className={styles.activityDemoDate}>
              <div className={styles.activityDemoDateIcon}>
                <img
                  src={clockIcon}
                  alt="clockIcon"
                  style={{ width: "12px" }}
                />
              </div>
              <div className={styles.activityDemoDateValue}>
                {data?.activityData?.startTime?.toString()?.length === 10
                  ? moment.unix(data?.activityData?.startTime).format("hh:mm A")
                  : moment(data?.activityData?.startTime).format("hh:mm A")}
                {" - "}
                {data?.activityData?.endTime?.length === 10
                  ? moment.unix(data?.activityData?.endTime).format("hh:mm A")
                  : moment(data?.activityData?.endTime).format("hh:mm A")}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ActivitySession;
