import React from "react";
import backIcon from "../../assets/images/back-btn-arrow.svg";

function PageBackBtn(props: any) {
  const { onClick } = props;
  return (
    <>
      <button
        style={{
          border: "none",
          background: "#fff",
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          fontSize: "14px",
          fontWeight: "500",
          color: "#667085",
          lineHeight: "20px",
          gap: "8px",
        }}
        onClick={onClick}
      >
        <img src={backIcon} alt="back" />
        <div>Back</div>
      </button>
    </>
  );
}

export default PageBackBtn;
