import mixpanel from "mixpanel-browser";
mixpanel.init("5373f1fec74262e51475f2d1c7c903a3", { track_pageview: true });

let env_check = (process.env.NODE_ENV as "prod") === "prod";

let actions = {
  identify: (id: any) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: any, props: any) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
