import React from "react";
import { Box, Button, Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import filterBtnIcon from "../../pages/app/Nurture/components/Assets/filterIcon.svg";
import greenFilter from "../../pages/app/Nurture/components/Assets/greenFilter.svg";
import styles from "./FilterDrawer.module.css";

function FilterDrawer(props: any) {
  const {
    children,
    handleFilterApply,
    handleResetClick,
    activeFilter,
    cancelClick,
    closeSetFilter,
    insidePopup,
  } = props;
  const [isDrawerOpen, setIsDrawerOPen] = React.useState<boolean>(false);
  const handleDrawerOpen = () => {
    setIsDrawerOPen(true);
  };

  const handleDrawerClose = () => {
    closeSetFilter();
    setIsDrawerOPen(false);
  };

  return (
    <div>
      <div
        className={styles.filterDrawerBtn}
        style={{
          border: activeFilter ? "1px solid #11c89b" : "1px solid #d0d5dd",
          background: activeFilter && "#ECF7F4",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingTop={"7px"}
          paddingBottom={"7px"}
          paddingLeft={"7px"}
          paddingRight={activeFilter ? "" : "7px"}
          onClick={handleDrawerOpen}
        >
          {activeFilter ? (
            <img src={greenFilter} alt="" />
          ) : (
            <img src={filterBtnIcon} alt="" />
          )}
        </Box>
        {activeFilter && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            paddingTop={"7px"}
            paddingBottom={"7px"}
            paddingRight={"11px"}
            onClick={() => {
              cancelClick();
            }}
          >
            <CloseIcon
              sx={{
                fill: "#667085",
                width: "0.8em",
                marginLeft: "5px",
              }}
            />
          </Box>
        )}
      </div>
      <Drawer
        className="filter_drawer"
        anchor="right"
        open={isDrawerOpen}
        onClose={() => handleDrawerClose()}
        PaperProps={{
          sx: {
            width: 350,
            backgroundColor: "#ffff",
          },
        }}
        sx={{
          zIndex: insidePopup === true ? "9999999" : "99999999",
        }}
      >
        <button
          className={styles.filterCloeBtn}
          onClick={() => {
            handleDrawerClose();
          }}
        >
          x
        </button>
        <div className={styles.filterDrawerHead}>
          <div className={styles.filterDrawerTilte}>Filters</div>
          <div>
            <button
              className={styles.filterDrawerClearBtn}
              onClick={() => {
                handleResetClick();
              }}
            >
              Clear All
            </button>
          </div>
        </div>
        {children}
        <div
          style={{
            position: "absolute",
            width: "100%",
            bottom: 0,
            left: 0,
            right: 0,
            padding: "15px 32px",
            borderTop: "1px solid #e4e4e4",
            background: "#fff",
            zIndex: "999999999",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            sx={{
              width: "100%",
              boxShadow: "none",
              textTransform: "capitalize",
            }}
            onClick={() => {
              handleFilterApply();
              setIsDrawerOPen(false);
            }}
          >
            Apply Filter
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default FilterDrawer;
