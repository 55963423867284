import { Box, Button, Skeleton } from "@mui/material";
import React, { useCallback, useState } from "react";
import NurtureKanbanHeader from "./NurtureKanbanHeader";
import styles from "./Nurture.module.css";
import NurtureKanbanBatchList from "./NurtureKanbanBatchList";
import rightDIcon from "./Assets/rightdIcon.svg";
import leftDIcon from "./Assets/leftDIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import { useAuthUser } from "react-auth-kit";
import { GetNurtureLeads } from "../service/nurture.request";
import {
  resetLeadActivityList,
  resetLeadById,
  resetLeadConvertion,
  resetLeadModalState,
  resetLeadStatusUpdateError,
  setLeadModalState,
} from "../service/nurtureSlice";
import { useDispatch } from "react-redux";
import NurtureList from "./NurtureList";
import { useSelector } from "react-redux";
import noFilteredLeadsImg from "../components/Assets/noLeadsImg.svg";
import noLeadsImg from "../components/Assets/noFilteredLeadsImg.svg";
import { getSubDomainAccessStatus } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import AddLeadPayment from "./AddLeadPayment";
import { setToastNotification } from "../../../../components/ToastNotification/service/toastNotificationSlice";

type Data = {
  instituteId: any;
  courseId: any;
  nurtureBoard: boolean;
  limit: number;
  branchId?: any;
  status: string;
  assigneeId?: string;
  lastEvaluatedKey?: string;
  lastEvaluatedSortKey?: string;
};

interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedBatch {
  id: string;
  batchName: string;
}

interface CheckedSources {
  source: string;
}

interface CheckedAssignees {
  id: string;
  firstName: string;
  lastName: string;
}
interface CheckedPriorities {
  priority: string;
}

interface CheckedLeadStages {
  leadStage: string;
}

const NurtureListView = (props: any) => {
  const {
    nurtureAddEnquiry,
    branchList,
    courseList,
    batchList,
    UpdateLeadSwimlaneStatus,
    selectedCourse,
    employees,
    batchTypes,
    leadNoteList,
    GetLeadTaskList,
    GetLeadNoteList,
    CreateNote,
    CreateTask,
    GetBatchById,
    ConvertLeadToStudent,
    createTaskSuccess,
    createNoteSuccess,

    leadTaskList,
    leadCreateSuccess,
    UpdateLeadEnquiyStatus,
    GetCourseById,
    courseTypes,
    sourceList,
    taskTypes,
    handleCompress,
    leadConvertionError,
    leadStatusUpdateError,
    setLeadItems,
    leadItems,
    onLeadsData,
    leadCreateLoading,
    GetLeadListByInstituteAndContact,
    DeleteNote,
    EditNote,
    DeleteTask,
    EditTask,
    UpdateTaskStatus,
    GetInstituteById,

    isKanbanView,
    setIsKanbanView,
    editTaskSuccess,
    deleteTaskSuccess,
    deleteNoteSuccess,
    editNoteSuccess,
    leadCreateError,

    setNurtureLeadStatus,
    nurtureLeadStatus,
    GetLeadsForBoard,
    defaultLeadsCall,
    leadListItems,
    setLeadListItems,
    setleadPaginationLoad,
    leadPaginationLoad,
    filterLeadsCall,
    setAddBatchModal,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasBatchAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BATCHES",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const { taskStatusUpdateSuccess } = useSelector((state: any) => state.tasks);
  const { leadListLoading, leadBoard, leadModalState } = useSelector(
    (state: any) => state.nurture
  );
  const swimlaneDatas = leadBoard?.swimlaneData;
  const leadStages =
    swimlaneDatas &&
    [...swimlaneDatas]?.sort((a: any, b: any) => {
      return a?.orderIndex - b?.orderIndex;
    });

  const [showEnquiryModal, setShowEnquiryModal] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [leadSearchTerm, setLeadSearchTerm] = React.useState<string>("");
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [draggedColumn, setdraggedColumn] = useState<string>("");
  const [draggedLead, setdraggedLead] = useState<string>("");
  const [dropZoneActive, setDropZoneActive] = useState<boolean>(false);
  const [draggedLeadData, setdraggedLeadData] = useState<any>(null);
  const [expandedLead, setExpandedLead] = useState<any>(null);
  const [batchListDrawerOpen, setBatchListDrawerOpen] =
    useState<boolean>(false);

  const [leadIdInUrl, setLeadIdInUrl] = useState<string>("");
  const [paginatedLeadId, setPaginatedLeadId] = useState<any>();
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<{
    SK: { S: string };
    GSI4SK: { S: string };
  } | null>(null);

  const [showAddLeadWideView, setAddLeadWideView] = useState(false);
  const { leadCreatedId } = useSelector((state: any) => state.nurture);

  React.useEffect(() => {
    if (leadCreateSuccess !== true && leadCreateSuccess !== false) {
      if (leadCreatedId === null || leadCreatedId === undefined) {
        setShowEnquiryModal(false);
      }
    }
  }, [leadCreateSuccess, setShowEnquiryModal]);

  React.useEffect(() => {
    if (leadCreatedId && leadCreateSuccess === "Converted lead is created.") {
      setAddLeadWideView(true);
      dispatch(
        setToastNotification({
          message: String("Please add the payment details for conversion"),
          type: "success",
          snackOpen: true,
        })
      );
    }
  }, [leadCreateSuccess, setAddLeadWideView, leadCreatedId]);

  const [filterCheckedBranches, setFilterCheckedBranches] = useState<
    CheckedBranch[]
  >([]);
  const [filterCheckedBatches, setFilterCheckedBatches] = useState<
    CheckedBatch[]
  >([]);
  const [filterCheckedPriorities, setFilterCheckedPriorities] = useState<
    CheckedPriorities[]
  >([]);
  const [filterCheckedSources, setFilterCheckedSources] = useState<
    CheckedSources[]
  >([]);

  const [filterCheckedAssignees, setFilterCheckedAssignees] = useState<
    CheckedAssignees[]
  >([]);
  const [filterCheckedStages, setFilterCheckedStages] = useState<
    CheckedLeadStages[]
  >([]);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);

  const allLeadsEmpty =
    onLeadsData && leadListItems && leadListItems?.length === 0;

  const handleLeadSearch = (event: any) => {
    setLeadSearchTerm(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("leadSearchTerm", event.target.value);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const toggleBatchDrawer = () => {
    setBatchListDrawerOpen(!batchListDrawerOpen);

    const queryParams = new URLSearchParams(window.location.search);
    if (batchListDrawerOpen) {
      queryParams.delete("batchDrawer");
    } else {
      queryParams.set("batchDrawer", "true");
    }
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          GetLeadListByInstituteAndContact(data);
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          GetLeadListByInstituteAndContact(data);
        }
      }
    },
    [GetLeadListByInstituteAndContact]
  );

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    columnId: any,
    taskId: any,
    lead: any
  ) => {
    event.dataTransfer.setData("columnId", columnId.toString());
    event.dataTransfer.setData("taskId", taskId.toString());
    setdraggedLead(taskId);
    setdraggedColumn(columnId);
    setIsDragging(true);
    event.currentTarget.style.zIndex = "999";
    setdraggedLeadData(lead);
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.add(styles.dragoverColumn);
    setDropZoneActive(true);
  };

  const bdwidth = 300;

  const handleFilterLeads = React.useCallback(
    (
      branchesArray: any,
      batchArray: any,
      prioritiesArray: any,
      assigneesArray: any,
      sourcesArray: any,
      stagesArray: any
    ) => {
      const queryParams = new URLSearchParams(window.location.search);
      const nurtureView = queryParams.get("nurtureView");

      filterLeadsCall(
        nurtureView !== "LIST" ? false : true,
        nurtureLeadStatus,
        selectedCourse?.courseId,
        branchesArray,
        sourcesArray,
        prioritiesArray,
        batchArray,
        assigneesArray,
        stagesArray
      );
      setFilterCheckedBranches(branchesArray);
      setFilterCheckedBatches(batchArray);
      setFilterCheckedPriorities(prioritiesArray);
      setFilterCheckedSources(sourcesArray);
      setFilterCheckedAssignees(assigneesArray);
      setFilterCheckedStages(stagesArray);

      if (branchesArray && branchesArray.length > 0) {
        const branchParam = branchesArray
          .map((branch: any) => branch.id)
          .join("|");
        queryParams.set("filteredBranches", branchParam);
      } else {
        queryParams.delete("filteredBranches");
      }

      if (batchArray && batchArray.length > 0) {
        const batchParam = batchArray.map((item: any) => item.id).join("|");
        queryParams.set("filteredBatches", batchParam);
      } else {
        queryParams.delete("filteredBatches");
      }

      if (assigneesArray && assigneesArray.length > 0) {
        const assigneeParam = assigneesArray
          .map((item: any) => item.id)
          .join("|");
        queryParams.set("filteredAssignees", assigneeParam);
      } else {
        queryParams.delete("filteredAssignees");
      }

      if (prioritiesArray && prioritiesArray.length > 0) {
        const priorityParam = prioritiesArray
          .map((item: any) => item.priority)
          .join("|");
        queryParams.set("filteredPriorities", priorityParam);
      } else {
        queryParams.delete("filteredPriorities");
      }

      if (sourcesArray && sourcesArray.length > 0) {
        const sourceParam = sourcesArray
          .map((item: any) => item.source)
          .join("|");
        queryParams.set("filteredSources", sourceParam);
      } else {
        queryParams.delete("filteredSources");
      }

      if (stagesArray && stagesArray.length > 0) {
        const stagesParam = stagesArray
          .map((item: any) => item.leadStage)
          .join("|");
        queryParams.set("filteredStages", stagesParam);
      } else {
        queryParams.delete("filteredStages");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;
      window.history.pushState({}, "", newUrl);
    },
    [
      setFilterCheckedBranches,
      setFilterCheckedBatches,
      setFilterCheckedPriorities,
      setFilterCheckedSources,
      setFilterCheckedAssignees,
      setFilterCheckedStages,
      selectedCourse,
      nurtureLeadStatus,
    ]
  );

  const ClearLeadExpandModalQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("leadModal");
    queryParams.delete("leadId");
    queryParams.delete("tabOpen");
    queryParams.delete("recordPayment");
    queryParams.delete("leadEmail");
    queryParams.delete("leadPhone");
    queryParams.delete("leadInstituteId");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    setLeadIdInUrl("");
    dispatch(resetLeadById());
    dispatch(resetLeadActivityList());
  };

  const openAddBatchPopup = () => {
    setAddBatchModal(true);
  };

  const filteredValue = (
    <>
      {filterCheckedBranches?.length > 0 &&
        filterCheckedBranches?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.branchName}
          </div>
        ))}
      {filterCheckedBatches?.length > 0 &&
        filterCheckedBatches?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.batchName}
          </div>
        ))}

      {filterCheckedPriorities?.length > 0 &&
        filterCheckedPriorities?.map((item) => (
          <div key={item.priority} className="filteredDiv">
            {item.priority?.toLowerCase()}
          </div>
        ))}

      {filterCheckedSources?.length > 0 &&
        filterCheckedSources?.map((item) => (
          <div key={item.source} className="filteredDiv">
            {item.source}
          </div>
        ))}

      {filterCheckedStages?.length > 0 &&
        filterCheckedStages?.map((item) => (
          <div key={item.leadStage} className="filteredDiv">
            {item?.leadStage?.toLowerCase()?.replace(/_/g, " ")}
          </div>
        ))}

      {filterCheckedAssignees?.length > 0 &&
        filterCheckedAssignees?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.firstName + " " + item.lastName}
          </div>
        ))}
    </>
  );

  React.useEffect(() => {
    if (onLeadsData?.lastEvaluatedKeyReceived) {
      setLastEvaluatedKey(onLeadsData?.lastEvaluatedKeyReceived);
    } else {
      setLastEvaluatedKey(null);
    }
  }, [onLeadsData]);

  React.useEffect(() => {
    if (lastEvaluatedKey && leadSearchTerm === "") {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [lastEvaluatedKey, leadSearchTerm]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const batchIdinUrl = urlSearchParams.get("boardBatchId");
    const boardBatchModalInUrl = urlSearchParams.get("boardBatchModal");
    const leadSearchTermInUrl = urlSearchParams.get("leadSearchTerm");
    const batchDrawer = urlSearchParams.get("batchDrawer");

    if (batchDrawer) {
      setBatchListDrawerOpen(true);
    }

    if (batchIdinUrl && boardBatchModalInUrl) {
      GetBatchById(batchIdinUrl);
    }

    if (leadSearchTermInUrl && leadSearchTermInUrl !== "") {
      setLeadSearchTerm(leadSearchTermInUrl);
    }
  }, [GetBatchById, setLeadSearchTerm, setBatchListDrawerOpen]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const branchParam = urlSearchParams.get("filteredBranches");
    const batchParam = urlSearchParams.get("filteredBatches");
    const assigneeParam = urlSearchParams.get("filteredAssignees");
    const priorityParam = urlSearchParams.get("filteredPriorities");
    const sourceParam = urlSearchParams.get("filteredSources");
    const stagesParam = urlSearchParams.get("filteredStages");

    if (branchParam && branchList) {
      const branchIds = branchParam.split("|");

      const selectedBranches = branchIds
        .map((id) => {
          const branch = branchList?.find((branch: any) => branch.id === id);
          return branch ? { id, branchName: branch.branchName } : null;
        })
        .filter(Boolean) as CheckedBranch[];

      setFilterCheckedBranches(selectedBranches);
    }

    if (batchParam && batchList) {
      const batchIds = batchParam.split("|");

      const selectedBatches = batchIds
        .map((id) => {
          const batch = batchList?.find((batch: any) => batch.id === id);
          return batch ? { id, batchName: batch.batchName } : null;
        })
        .filter(Boolean) as CheckedBatch[];

      setFilterCheckedBatches(selectedBatches);
    }

    if (assigneeParam && employees) {
      const assigneeIds = assigneeParam.split("|");

      const selectedAssignees = assigneeIds
        .map((id) => {
          const assignee = employees?.find((type: any) => type.id === id);
          return assignee
            ? {
                id,
                firstName: assignee.firstName,
                lastName: assignee.lastName,
              }
            : null;
        })
        .filter(Boolean) as CheckedAssignees[];

      setFilterCheckedAssignees(selectedAssignees);
    }

    if (sourceParam && sourceList) {
      const sources = sourceParam.split("|");

      const selectedSources = sources
        .map((value) => {
          const source = sourceList?.find(
            (type: any) => type.value?.toLowerCase() === value?.toLowerCase()
          );
          return source
            ? {
                source: source.value,
              }
            : null;
        })
        .filter(Boolean) as CheckedSources[];

      setFilterCheckedSources(selectedSources);
    }

    if (priorityParam) {
      const priorities = priorityParam.split("|");

      const selectedPriorities = priorities
        .map((value) => {
          const priority = [
            { priority: "URGENT" },
            { priority: "HIGH" },
            { priority: "NORMAL" },
            { priority: "LOW" },
          ].find(
            (type: any) => type.priority.toLowerCase() === value.toLowerCase()
          );
          return priority
            ? {
                priority: priority.priority,
              }
            : null;
        })
        .filter(Boolean) as CheckedPriorities[];

      setFilterCheckedPriorities(selectedPriorities);
    }

    if (stagesParam) {
      const stages = stagesParam.split("|");

      const selectedStages = stages
        .map((value) => {
          const status = leadStages?.find(
            (type: any) => type.stateName === value
          );
          return status
            ? {
                leadStage: status.stateName,
              }
            : null;
        })
        .filter(Boolean) as CheckedLeadStages[];

      setFilterCheckedStages(selectedStages);
    }
  }, [branchList, batchList, employees, sourceList]);

  React.useEffect(() => {
    if (batchListDrawerOpen === true) {
      handleCompress(true);
    }
  }, [batchListDrawerOpen, handleCompress]);

  React.useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        ClearLeadExpandModalQuery();
        dispatch(resetLeadModalState());
      }
    };

    if (leadModalState) {
      window.addEventListener("keydown", handleKeyPress);
    } else {
      window.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [leadModalState]);

  React.useEffect(() => {
    if (leadCreateError === "Lead already exist") {
      setShowEnquiryModal(false);
    }
  }, [leadCreateError, setShowEnquiryModal]);

  React.useEffect(() => {
    if (
      leadConvertionError === " No payment done for the enquiry" ||
      leadConvertionError === "Please record a payment to continue." ||
      leadStatusUpdateError === " No payment done for the enquiry" ||
      leadStatusUpdateError === "Please record a payment to continue."
    ) {
      const queryParams = new URLSearchParams(window.location.search);
      if (draggedLead) {
        queryParams.set("leadModal", "true");
        queryParams.set("leadId", draggedLead);
        queryParams.set("tabOpen", "payment");
        queryParams.set("recordPayment", "Open");

        queryParams.set("leadInstituteId", draggedLeadData?.instituteId);
        queryParams.set("leadEmail", draggedLeadData?.studentEmail);
        queryParams.set("leadPhone", draggedLeadData?.studentContact);
        dispatch(setLeadModalState());
        leadPopupApiCall(
          draggedLeadData?.instituteId,
          draggedLeadData?.studentContact,
          draggedLeadData?.studentEmail
        );

        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    }
    dispatch(resetLeadConvertion());
    dispatch(resetLeadStatusUpdateError());
  }, [draggedLead, leadConvertionError, leadStatusUpdateError]);

  React.useEffect(() => {
    if (
      (editTaskSuccess !== true && editTaskSuccess !== false) ||
      (deleteTaskSuccess !== true && deleteTaskSuccess !== false) ||
      (createTaskSuccess !== true && createTaskSuccess !== false) ||
      taskStatusUpdateSuccess
    ) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const leadId = urlSearchParams.get("leadId");
      if (!leadId) {
        GetLeadTaskList(expandedLead?.id);
      }
    }
  }, [
    editTaskSuccess,
    deleteTaskSuccess,
    createTaskSuccess,
    taskStatusUpdateSuccess,
  ]);

  React.useEffect(() => {
    if (
      (editNoteSuccess !== true && editNoteSuccess !== false) ||
      (deleteNoteSuccess !== true && deleteNoteSuccess !== false) ||
      (createNoteSuccess !== true && createNoteSuccess !== false)
    ) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const leadId = urlSearchParams.get("leadId");
      if (!leadId) {
        GetLeadNoteList(expandedLead?.id);
      }
    }
  }, [editNoteSuccess, deleteNoteSuccess, createNoteSuccess]);

  return (
    <>
      <div
        style={{
          transition: ".5s ease-in-out",
          width: batchListDrawerOpen
            ? `calc(100% - ${bdwidth}px)`
            : `calc(100% - 25px)`,
        }}
      >
        <Box sx={{ backgroundColor: "#F5F5F5" }} component="div">
          <NurtureKanbanHeader
            nurtureAddEnquiry={nurtureAddEnquiry}
            courseList={courseList}
            branchList={branchList}
            batchList={batchList}
            employees={employees && employees}
            sortedSwimLane={leadStages}
            handleFilterLeads={handleFilterLeads}
            showEnquiryModal={showEnquiryModal}
            setShowEnquiryModal={setShowEnquiryModal}
            GetCourseById={GetCourseById}
            courseTypes={courseTypes}
            sourceList={sourceList}
            handleLeadSearch={handleLeadSearch}
            leadSearchTerm={leadSearchTerm}
            selectedCourse={selectedCourse}
            allLeadsEmpty={allLeadsEmpty}
            filteredValue={filteredValue}
            GetInstituteById={GetInstituteById}
            isKanbanView={isKanbanView}
            setIsKanbanView={setIsKanbanView}
            setLeadItems={setLeadItems}
            GetLeadsForBoard={GetLeadsForBoard}
            setNurtureLeadStatus={setNurtureLeadStatus}
            defaultLeadsCall={defaultLeadsCall}
            setLeadListItems={setLeadListItems}
            setleadPaginationLoad={setleadPaginationLoad}
            filterCheckedStages={filterCheckedStages}
            filterCheckedPriorities={filterCheckedPriorities}
            filterCheckedBranches={filterCheckedBranches}
            filterCheckedBatches={filterCheckedBatches}
            filterCheckedSources={filterCheckedSources}
            filterCheckedAssignees={filterCheckedAssignees}
            filterLeadsCall={filterLeadsCall}
            nurtureLeadStatus={nurtureLeadStatus}
            filterApplied={filterApplied}
            setFilterApplied={setFilterApplied}
          />

          <Box
            sx={{
              padding: "16px 11px 16px 11px",
              overflowX: "auto",
              position: "relative",
            }}
            component="div"
          >
            {(allLeadsEmpty === true || selectedCourse?.leadcount === 0) &&
              !filterApplied && (
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "999",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ maxWidth: "290px", marginBottom: "10px" }}
                      // src={
                      //   filterCheckedStatus?.length === 0
                      //     ? noLeadsImg
                      //     : noFilteredLeadsImg
                      // }
                      src={noLeadsImg}
                      alt="noLeadsImg"
                    />
                    <div
                      style={{
                        color: "#667085",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: "8px",
                        textTransform: "capitalize",
                      }}
                    >
                      No data available
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: 14,
                        textTransform: "capitalize",
                        boxShadow: "none",
                        height: "38px",
                      }}
                      size={"small"}
                      onClick={() => setShowEnquiryModal(!showEnquiryModal)}
                    >
                      Add Lead
                    </Button>

                    {/* {filterCheckedStatus?.length === 0 ? (
                    <>
                      {nurtureLeadStatus === "ACTIVE" && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              fontSize: 14,
                              textTransform: "capitalize",
                              boxShadow: "none",
                              height: "38px",
                            }}
                            size={"small"}
                            onClick={() =>
                              setShowEnquiryModal(!showEnquiryModal)
                            }
                          >
                            Add Lead
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        color: "#667085",
                        fontSize: "13px",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      Try applying a different filter
                    </div>
                  )} */}
                  </div>
                </div>
              )}

            <div
              style={{
                overflowX: "auto",
                overflowY: "hidden",
                minHeight: "78vh",
                minWidth: "max-content",
                // maxHeight: "calc(100vh - 180px)",
                // minHeight: "calc(100vh - 180px)",
              }}
            >
              <NurtureList
                handleDragStart={handleDragStart}
                hasMoreData={hasMoreData}
                setHasMoreData={setHasMoreData}
                GetLeadNoteList={GetLeadNoteList}
                CreateTask={CreateTask}
                EditTask={EditTask}
                DeleteTask={DeleteTask}
                UpdateTaskStatus={UpdateTaskStatus}
                leadTaskList={leadTaskList}
                employees={employees}
                taskTypes={taskTypes}
                CreateNote={CreateNote}
                EditNote={EditNote}
                DeleteNote={DeleteNote}
                leadNoteList={leadNoteList}
                leadItems={leadItems?.enquiryListData}
                GetLeadTaskList={GetLeadTaskList}
                createTaskSuccess={createTaskSuccess}
                setExpandedLead={setExpandedLead}
                expandedLead={expandedLead}
                selectedCourse={selectedCourse}
                leadSearchTerm={leadSearchTerm}
                setLeadSearchTerm={setLeadSearchTerm}
                filterCheckedBranches={filterCheckedBranches}
                filterCheckedBatches={filterCheckedBatches}
                filterCheckedPriorities={filterCheckedPriorities}
                filterCheckedSources={filterCheckedSources}
                filterCheckedAssignees={filterCheckedAssignees}
                courseLeadCount={selectedCourse?.leadcount}
                UpdateLeadSwimlaneStatus={UpdateLeadSwimlaneStatus}
                UpdateLeadEnquiyStatus={UpdateLeadEnquiyStatus}
                filterCheckedStages={filterCheckedStages}
                leadPaginationLoad={leadPaginationLoad}
                paginatedLeadId={paginatedLeadId}
                setPaginatedLeadId={setPaginatedLeadId}
                lastEvaluatedKey={lastEvaluatedKey}
                leadListItems={leadListItems}
                allLeadsEmpty={allLeadsEmpty}
                nurtureLeadStatus={nurtureLeadStatus}
                filterApplied={filterApplied}
              />
            </div>
          </Box>
        </Box>
      </div>

      {showEnquiryModal && (
        <AddLeadPayment
          show={showEnquiryModal}
          onHide={() => {
            setShowEnquiryModal(false);
          }}
          nurtureAddEnquiry={nurtureAddEnquiry}
          leadCreateSuccess={leadCreateSuccess}
          leadCreateLoading={leadCreateLoading}
          page={"NURTURE"}
          showAddLeadWideView={showAddLeadWideView}
          setAddLeadWideView={setAddLeadWideView}
          leadCreatedId={leadCreatedId}
        />
      )}

      <div
        style={{
          borderLeft: "1px solid rgba(177, 177, 177, 0.3)",
          backgroundColor: "#fff",
          transition: ".5s ease-in-out",
          position: "relative",
          minWidth: batchListDrawerOpen ? bdwidth : "25px",
          height: "calc(100vh - 64px)",
        }}
      >
        <button onClick={toggleBatchDrawer} className={styles.batchDrawerBtn}>
          <img src={batchListDrawerOpen ? leftDIcon : rightDIcon} alt="" />
        </button>
        {batchListDrawerOpen && (
          <NurtureKanbanBatchList
            handleDragOver={handleDragOver}
            batchList={batchList}
            batchTypes={batchTypes}
            branchList={branchList}
            courseList={courseList}
            ConvertLeadToStudent={ConvertLeadToStudent}
            draggedLead={draggedLead}
            selectedCourse={selectedCourse}
            GetBatchById={GetBatchById}
            setBatchListDrawerOpen={setBatchListDrawerOpen}
            dropZoneActive={dropZoneActive}
            setDropZoneActive={setDropZoneActive}
          />
        )}

        {batchListDrawerOpen && (
          <Box
            sx={{
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              bottom: "0px",
              width: "-webkit-fill-available",
              boxShadow: "10px 4px 14px rgba(0, 0, 0, 0.15)",
              left: "0",
            }}
          >
            <>
              {courseList === null ||
              batchList === null ||
              selectedCourse === null ? (
                <Skeleton
                  variant="rectangular"
                  width="90%"
                  animation="wave"
                  height={35}
                  style={{ marginBottom: "10px", borderRadius: "2PX" }}
                />
              ) : (
                <>
                  {hasBatchAccess && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        my: "8px",
                        fontSize: 14,
                        textTransform: "capitalize",
                        borderRadius: "4px",
                        height: "36px",
                        width: "90%",
                        boxShadow: "none",
                      }}
                      size={"small"}
                      onClick={(e) => {
                        e.preventDefault();
                        openAddBatchPopup();
                      }}
                      disabled={!hasBatchAccess}
                    >
                      <AddIcon sx={{ marginRight: "10px", fontSize: "18px" }} />
                      Add Batch
                    </Button>
                  )}
                </>
              )}
            </>
          </Box>
        )}
      </div>
    </>
  );
};

export default NurtureListView;
