import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
interface Tag {
  key: number;
  label: string;
}
let tagArray: any = [];
export default function TagManager(props: any) {
  const {
    validStateTag,
    tagsList,
    selectedTags,
    handleTagsChange,
    CreateTag,
    disabled,
  } = props;
  const autocompleteRef = React.useRef<HTMLInputElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [tag, setTag] = React.useState<any>();
  const [isRemoveInput, setIsRemoveInput] = useState<boolean>(false);
  const [tagInput, setTagInput] = React.useState<any>();
  const [addTag, setAddTag] = React.useState<boolean>(false);

  const handleAddTag = (tagToAdd: any) => {
    typeof tagToAdd !== "string" &&
      handleTagsChange([...selectedTags, tagToAdd]);
  };

  const mappedData = selectedTags
    ? selectedTags.map((item: any, index: number) => {
        return {
          key: index,
          label: item,
        };
      })
    : [];

  useEffect(() => {
    if (tagsList && tagsList?.length !== 0) {
      const array = tagInput?.split(",");
      array?.map(async (obj1: any) => {
        const data = tagsList?.filter((obj: any) => obj.tagname === obj1);
        (await data) && data.length !== 0 && handleAddTag(data[0]);
      });
    }
  }, [tagsList]);

  useEffect(() => {
    const filter = tagsList?.filter((obj: any) =>
      obj.tagname.includes(tagInput)
    );
    if (tagInput !== undefined && filter && filter.length === 0) {
      setAddTag(true);
    } else {
      setAddTag(false);
    }
  }, [tagInput]);

  const addTagFun = () => {
    CreateTag(tagInput);
    setAddTag(false);
  };

  const newValue = (value: any, getTagProps: any) => {
    return value.map((option: any, index: any) => {
      if (typeof option === "object") {
        return (
          <Chip
            key={index}
            variant="outlined"
            deleteIcon={<CloseIcon sx={{ fontSize: "18px" }} />}
            label={`${option.tagname}${
              option.status === "REVIEW_REJECTED" ||
              option.status === "UNDER_REVIEW"
                ? ` (${option.status
                    .replace("_", " ")
                    .toLowerCase()
                    .split(" ")
                    .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")})`
                : ""
            }`}
            {...getTagProps({ index })}
            sx={{
              color:
                !option.status ||
                option.status === "REVIEW_REJECTED" ||
                option.status === "UNDER_REVIEW"
                  ? "red"
                  : "#667085",
              background: "#F3F3F3",
              borderRadius: "4PX",
              border: "none",
              fontSize: "13px",
            }}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (!addTag) {
      setTagInput("");
      setTag("");
    }
  }, [setAddTag]);

  return (
    <div className={""}>
      <div className={""}>
        <p
          style={{
            fontSize: "12px",
            color: "#142C44",
            fontWeight: "500",
            fontFamily: "Poppins",
            display: "flex",
            margin: "0px 0px 5px",
          }}
        >
          Add Tags
          {/* <span style={{ margin: "0px 4px", color: "#D32F2F" }}>*</span> */}
        </p>
      </div>
      <Box
        sx={{
          display: "flex",
          ".css-1l8zgq4-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
            {
              padding: "0px",
            },
        }}
      >
        <>
          <FormControl
            variant="outlined"
            className="formControl"
            sx={{
              mb: 2,
              position: "relative",
              width: "1000px",
              ".css-yoi7t9-MuiAutocomplete-root .MuiOutlinedInput-root": {
                borderColor: !validStateTag ? "rgb(244 67 54)" : "",
                ":hover": {
                  borderColor: !validStateTag ? "rgb(244 67 54)" : "",
                },
              },
              ".css-1d3z3hw-MuiOutlinedInput-notchedOutline ": {
                borderColor: !validStateTag ? "rgb(244 67 54)" : "",
                ":hover": {
                  borderColor: !validStateTag ? "rgb(244 67 54)" : "",
                },
              },
              ".css-1oz3c3t-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: !validStateTag ? "rgb(244 67 54)" : "",
                  ":hover": {
                    borderColor: !validStateTag ? "rgb(244 67 54)" : "",
                  },
                },
            }}
          >
            <Autocomplete
              multiple
              id="tags-filled-couse"
              size="small"
              ref={autocompleteRef}
              options={tagsList}
              getOptionLabel={(option: any) => option.tagname || ""}
              freeSolo
              renderTags={(value, getTagProps) => newValue(value, getTagProps)}
              value={selectedTags}
              disabled={disabled}
              renderInput={(params) => {
                if (!mappedData) {
                  params.InputProps.endAdornment = null;
                  params.inputProps.value = "";
                }
                if (isRemoveInput) {
                  params.inputProps.value = "";
                }
                return (
                  <TextField
                    variant="outlined"
                    placeholder=""
                    name="tagInput"
                    ref={inputRef}
                    onChange={(e) => {
                      setIsRemoveInput(false);
                      setTagInput(e.target.value);
                      setTag(e.target.value);
                    }}
                    value={tagInput}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 13 && e.target.value) {
                        const array =
                          (tagsList &&
                            tagsList?.filter(
                              (obj: any) =>
                                obj.tagname.toLowerCase() ===
                                e.target.value.toLowerCase()
                            )) ||
                          [];

                        if (array.length === 0) {
                          CreateTag(array[0]?.tagname);
                          setAddTag(true);
                        }

                        // CreateTag(e.target.value);
                        setAddTag(false);
                        setIsRemoveInput(true);
                      }
                    }}
                    {...params}
                  />
                );
              }}
              onChange={(event, newValue) => {
                const a = newValue.map((obj) => {
                  if (
                    typeof obj === "string" &&
                    !tagsList?.filter(
                      (obj1: any) =>
                        obj1.tagname.toLowerCase() === obj.toLowerCase()
                    )[0]
                  ) {
                    addTagFun();
                    setIsRemoveInput(true);
                    document
                      .querySelector("#tags-filled-couse")
                      ?.dispatchEvent(new Event("change"));
                  }

                  return typeof obj === "string"
                    ? tagsList?.filter(
                        (obj1: any) =>
                          obj1.tagname.toLowerCase() === obj.toLowerCase()
                      )[0] || obj
                    : obj;
                });
                const b = selectedTags.map((obj: any) => {
                  return a.filter((tes) => tes.id === obj.id)[0];
                });

                handleTagsChange(a);
              }}
            />
            {validStateTag === false && (
              <FormHelperText
                error={true}
                sx={{ marginLeft: "0px !important" }}
              >
                Field required
              </FormHelperText>
            )}
            {addTag && (
              <Box
                padding={"1.25px 18px"}
                boxShadow={"0px 2px 6px rgba(0, 0, 0, 0.06)"}
                marginTop={"7px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                borderRadius={"8px"}
                border={"1px solid #E4E4E7"}
                marginRight={5}
                width={"calc(100% - 40px)"}
                maxWidth={"320px"}
              >
                <Box width={"calc(100% - 80px)"} overflow={"scroll"}>
                  {tagInput}
                </Box>
                <Button
                  onClick={() => {
                    addTagFun();
                    setIsRemoveInput(true);
                    document
                      .querySelector("#tags-filled-couse")
                      ?.dispatchEvent(new Event("change"));
                  }}
                  variant="text"
                >
                  Add tag
                </Button>
              </Box>
            )}
          </FormControl>
        </>
      </Box>
    </div>
  );
}
