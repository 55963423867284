import React from "react";
import CalenderHeader from "./CalenderHeader";
import useDate from "../../../../../../components/CalenderComponents/useDate";
import Calendar from "./Calender";

function CalenderPage({
  batchDetails,
  setBatchDetails,
  add,
  setIsDateChanged,
  errorStartDayMsg,
  errorTimeMsg,
  errorEndDayMsg,
}: any) {
  const date = useDate();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CalenderHeader date={date} />
      <Calendar
        date={date}
        batchDetails={batchDetails}
        setBatchDetails={setBatchDetails}
        add={add}
        setIsDateChanged={setIsDateChanged}
      />
      {errorStartDayMsg && !errorTimeMsg && (
        <div style={{ color: "red", padding: "5px 0", fontSize: "14px" }}>
          Oopps! Request can not be processed <br /> Seems like you have not
          scheduled time for the selected start date.
        </div>
      )}
      {errorEndDayMsg && !errorTimeMsg && (
        <div style={{ color: "red", padding: "5px 0", fontSize: "14px" }}>
          Oopps! Request can not be processed <br /> Seems like you have not
          scheduled time for the selected end date.
        </div>
      )}
      {errorTimeMsg && (
        <div
          style={{
            color: "red",
            padding: "5px 0",
            fontSize: "14px",
          }}
        >
          Oopps! Request can not be processed
          <br />
          The start time and end time can not be the same and the end time
          should be greater than the start time.
        </div>
      )}
    </div>
  );
}

export default CalenderPage;
