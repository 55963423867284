import React, { useEffect, useState } from "react";
import styles from "./CustomizedField.module.css";
import {
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import deleteImage from "./Images/delete.svg";
import DeleteQuestionPopup from "../DeleteQuestionPopup/DeleteQuestionPopup";
import { useAuthUser } from "react-auth-kit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function CustomizedDateField(props: any) {
  const {
    field,
    onDelete,
    CreateWebformCustomField,
    onFieldChange,
    isDateFieldFocused,
    setIsDateFieldFocused,
    leadsFromResponseChecked,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const auth = useAuthUser();
  const authUser = auth();
  const [saveForLater, setSaveForLater] = useState(false);
  const [question, setQuestion] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [mandatory, setMandatory] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      const dateUnix = date.unix();
      setSelectedDate(dateUnix);

      if (id === "startDate") {
        setSelectedDate(selectedDate);
      }
    }
  };

  const handleDeleteConfirmed = () => {
    // Close the delete confirmation popup
    setShowDeletePopup(false);
    // Call the onDelete callback passed from the parent component
    onDelete(field);
  };

  const handleDeleteCancelled = () => {
    // Close the delete confirmation popup
    setShowDeletePopup(false);
  };

  const handleSaveClick = (e: any) => {
    onFieldChange({
      ...field,
      fieldName: question,
    });
    const userFromLocalStorage = authUser?.institute[0];
    const data = {
      instituteId: userFromLocalStorage?.instituteId,
      status: "ACTIVE",
      field: {
        fieldType: field?.fieldType,
        fieldName: field?.fieldName,
        fieldPlaceholder: field?.fieldPlaceholder,
        options: [],
        default: "",
        required: field?.required,
        index: 1,
        loadDatafromDB: false,
        DBModel: "",
        isCustom: true,
        isDeletable: true,
      },
      createdById: userFromLocalStorage?.id,
      createdByName:
        userFromLocalStorage?.firstName + " " + userFromLocalStorage?.lastName,
    };
    if (saveForLater === true) {
      CreateWebformCustomField(data);
    }
    setIsEditing(false);
  };

  const handleQuestionChange = (e: any) => {
    setQuestion(e.target.value);
    onFieldChange({
      ...field,
      fieldName: e.target.value,
    });
  };

  const handleSaveForLater = (e: any) => {
    setSaveForLater(e.target.checked);
  };

  const handleMandatoryChange = (e: any) => {
    const isMandatory = e.target.checked;
    setMandatory(isMandatory);
    onFieldChange({
      ...field,
      required: isMandatory,
    });
  };

  const capitalizeSentences = (text: string) => {
    let lowercasedText = text.toLowerCase();

    let capitalizedText = lowercasedText.replace(
      /(^\s*\w|[\.\!\?]\s*\w)/g,
      function (match) {
        return match.toUpperCase();
      }
    );

    return capitalizedText;
  };

  return (
    <Box
      className={styles.customizedFieldWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <div className={styles.label}>
          {capitalizeSentences(field.fieldName)}
          {field.required === true && <span style={{ color: "red" }}>*</span>}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <FiEdit
            size={16}
            color="#667085"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick}
          />
          <img
            src={deleteImage}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeletePopup(!showDeletePopup)}
          />
          {showDeletePopup && (
            <DeleteQuestionPopup
              onClose={handleDeleteCancelled}
              showDeletePopup={showDeletePopup}
              onConfirm={handleDeleteConfirmed}
            />
          )}
        </div>
      </div>
      <TextField
        placeholder="Write a question"
        value={question}
        fullWidth
        variant="outlined"
        disabled={!isEditing}
        onChange={handleQuestionChange}
        size="small"
        inputProps={{
          style: {
            padding: "8px 12px",
            backgroundColor: isEditing ? "#FFFFFF" : "#FAFAFA",
            borderRadius: "4px",
          },
        }}
        draggable={isDateFieldFocused === false}
        onFocus={() => {
          setIsDateFieldFocused(true);
        }}
        onBlur={() => {
          setIsDateFieldFocused(false);
        }}
      />

      {isEditing && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={handleMandatoryChange}
                checked={mandatory}
              />
            }
            label="Mandatory"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "13px",
                color: "#667085",
                fontFamily: "Poppins",
                fontWeight: "400",
                lineHeight: "20px",
              },
            }}
          />
          {field?.isFromCustom === true && (
            <FormControlLabel
              control={<Checkbox size="small" onChange={handleSaveForLater} />}
              label="Save for later"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "20px",
                },
              }}
            />
          )}

          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                borderRadius: "4px",
                border: "1px solid #BFBFBF",
                background: "#FFF",
                color: "#BFBFBF",
                "&:hover": {
                  border: "1px solid #BFBFBF",
                  background: "#FFF",
                },
              }}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize" }}
              onClick={handleSaveClick}
              disabled={!question.trim()} // Disable if the question is empty or contains only whitespaces
            >
              Save
            </Button>
          </div>
        </div>
      )}
      {isHovered && (
        <div
          style={{
            display: "flex",
            color: "#667085",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DragIndicatorIcon style={{ transform: "rotate(90deg)" }} />
        </div>
      )}
    </Box>
  );
}
export default CustomizedDateField;
