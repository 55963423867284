import { Box, CircularProgress, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./CommonPopups.module.css";
import React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function ConfirmPopup(props: any) {
  const {
    open,
    onClose,
    noBtnClick,
    yesBtnClick,
    title,
    description,
    loader,
    buttonText,
  } = props;
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div onClick={onClose} className={styles.closeModalButton}>
              <CloseIcon />
            </div>
            <div className={styles.modalTitle}>
              {title ? title : "Are you sure?"}
            </div>
            {description && (
              <div className={styles.modalDes}>
                {description
                  ? description
                  : "The changes you made will be discarded."}
              </div>
            )}

            <div className={styles.buttonSection}>
              <button onClick={noBtnClick} className={styles.noButton}>
                No
              </button>
              <button
                onClick={yesBtnClick}
                className={styles.yesButton}
                style={{ background: "#11C89B" }}
              >
                {loader ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : buttonText ? (
                  buttonText
                ) : (
                  "Yes"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ConfirmPopup;
