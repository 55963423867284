import React, { useState } from "react";
import styles from "./RemovePopup.module.css";
import {
  Box,
  Modal,
  Checkbox,
  FormControlLabel,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserAssignField from "../../../../../../../components/UserAssign/UserAssignField";
import { useAuthUser } from "react-auth-kit";
import { getCombinedAssignees } from "../../../../../../../components/CommonFunctions/CommonFunctions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function RemoveUserPopup(props: any) {
  const {
    open,
    onClose,
    staffs,
    removeButtonClick,
    userEditSelect,
    handleLeadAssignee,
    handleRemoveLicenseCheck,
    removeUserDetails,
    assignyError,
    userRemovalLoading,
    billing,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const selectedUserData = billing
    ? staffs &&
      staffs?.find((user: any) => user.id === userEditSelect?.licensedEntityId)
    : staffs?.find((user: any) => user.id === userEditSelect?.id);

  const branchEmployees =
    staffs?.filter(
      (item: any) => item?.branchId === authUser?.institute?.[0]?.branchId
    ) || [];
  const otherBranchEmployees =
    staffs?.filter(
      (item: any) =>
        item?.branchId !== authUser?.institute?.[0]?.branchId &&
        item?.allBranchAccess === true
    ) || [];
  // const combinedEmployees = [...branchEmployees, ...otherBranchEmployees];
  const combinedEmployees = getCombinedAssignees(
    staffs,
    userEditSelect?.branchId
  );

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.closeModalBtnWrap}>
              <button onClick={onClose} className={styles.closeModalBtn}>
                <CloseIcon />
              </button>
            </div>
            <div className={styles.closeModalTitle}>
              Are you sure you want to deactivate the user “
              {billing
                ? `${userEditSelect && userEditSelect.licensedEntityName}`
                : `${
                    userEditSelect?.firstName + " " + userEditSelect?.lastName
                  }`}
              ” ?
            </div>
            {selectedUserData?.leadCount > 0 ||
            selectedUserData?.taskCount > 0 ? (
              <div className={styles.closeModalDesStatus}>
                There are
                {selectedUserData?.leadCount > 0 &&
                selectedUserData?.taskCount > 0
                  ? " leads and tasks "
                  : selectedUserData?.leadCount > 0 &&
                    selectedUserData?.taskCount === 0
                  ? " leads "
                  : selectedUserData?.taskCount > 0 &&
                    selectedUserData?.leadCount === 0
                  ? " tasks "
                  : ""}
                assigned to this user. Choose the new assignee before
                deactivating the user.
              </div>
            ) : (
              <></>
            )}

            {selectedUserData?.leadCount > 0 ||
            selectedUserData?.taskCount > 0 ? (
              <div style={{ marginBottom: "10PX" }}>
                <UserAssignField
                  staffs={combinedEmployees
                    ?.filter((item: any) => item?.id !== userEditSelect?.id)
                    ?.filter(
                      (item: any) =>
                        item?.status === "ACTIVE" &&
                        item?.leadCardAccess === true
                    )}
                  selectedAssignyId={
                    userEditSelect && userEditSelect?.licensedEntityId
                  }
                  handleLeadAssignee={handleLeadAssignee}
                />
              </div>
            ) : (
              <></>
            )}

            {assignyError ? (
              <FormHelperText error sx={{ marginBottom: "4px" }}>
                Please assign lead or task to other user.
              </FormHelperText>
            ) : (
              <></>
            )}

            {userEditSelect?.benefitType === "ADDON" && (
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      stroke: 1,
                      color: " rgba(102, 112, 133, 0.30)",
                      "&.Mui-checked": {
                        color: "red",
                      },
                    }}
                    size="small"
                    checked={removeUserDetails?.deleteLicense}
                    onChange={(event) => handleRemoveLicenseCheck(event)}
                  />
                }
                label={
                  <Typography style={{ color: "#667085", fontSize: "14px" }}>
                    Delete User license too
                  </Typography>
                }
              />
            )}

            <div className={styles.buttonSection}>
              <button onClick={onClose} className={styles.cancelBtn}>
                cancel
              </button>
              <button
                onClick={removeButtonClick}
                className={styles.removeBtn}
                disabled={userRemovalLoading}
              >
                {userRemovalLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "deactivate"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default RemoveUserPopup;
