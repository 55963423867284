import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../../redux/store";
import {
  BillingAutoDebitDTO,
  BillingInitialStateDTO,
  CreateBillingAddressDTO,
  UpdateBillingAdminsDTO,
  UpdateSubscriptionStatusDTO,
} from "../service/types";
import Billing from "../components/Billing";
import { getStaffList } from "../../ManageStaff/service/Staffslice";
import {
  billingAutoDebit,
  getBillingAddress,
  getBillingAdmins,
  getBillingByInstituteId,
  getPaymentHistoryByPagination,
  getPaymentInvoice,
  getSubscriptionByInstituteId,
  removeBillingAdmin,
  updateBillingAddress,
  updateBillingAdmins,
  updateInstituteSubscriptionStatus,
} from "../service/Billingslice";
import { setActiveInsitute } from "../../../../../service/activeInstituteSlice";
import { getInstituteById } from "../../InstituteProfile/service/instituteSlice";

interface DispatchProps {
  GetBillingByInstituteId(id: string, status: string): void;
  GetSubscriptionByInstituteId(id: string): void;
  GetStaffList(id: string): void;
  UpdateInstituteSubscriptionStatus(data: UpdateSubscriptionStatusDTO): void;
  UpdateBillingAdmins(data: UpdateBillingAdminsDTO): void;
  RemoveBillingAdmins(data: string): void;
  GetBillingAdmins(id: string): void;
  GetBillingAddress(id: string): void;
  UpdateBillingAddress(data: CreateBillingAddressDTO): void;
  GetPaymentHistoryByPagination(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string
  ): void;
  GetPaymentInvoice(id: string): void;
  GetInstituteById(id: string): void;
  BillingAutoDebit(data: BillingAutoDebitDTO, status: string): void;
}

const mapStateToProps = (state: RootState): BillingInitialStateDTO => {
  return {
    error: state.billing.error,
    success: state.billing.success,
    loading: state.billing.loading,
    instituteBillingData: state.billing.instituteBillingData,
    instituteSubscriptionData: state.billing.instituteSubscriptionData,
    promocodeByName: state.billing.promocodeByName,
    billingAdmins: state.billing.billingAdmins,
    billingAddress: state.billing.billingAddress,
    paymentHistory: state.billing.paymentHistory,
    paymentInvoice: state.billing.paymentInvoice,
    billingPaymentLink: state.billing.billingPaymentLink,
    billingAdminError: state.billing.billingAdminError,
    billingAdminSuccess: state.billing.billingAdminSuccess,
    billingAdminLoading: state.billing.billingAdminLoading,
    userRemovalError: state.billing.userRemovalError,
    userRemovalSuccess: state.billing.userRemovalSuccess,
    userRemovalLoading: state.billing.userRemovalLoading,
    branchRemovalError: state.billing.branchRemovalError,
    branchRemovalSuccess: state.billing.branchRemovalSuccess,
    branchRemovalLoading: state.billing.branchRemovalLoading,
    errorBilling: state.billing.errorBilling,
    successBilling: state.billing.successBilling,
    loadingBilling: state.billing.loadingBilling,
    subscriptionStatusError: state.billing.subscriptionStatusError,
    subscriptionStatussuccess: state.billing.subscriptionStatussuccess,
    subscriptionStatusloading: state.billing.subscriptionStatusloading,
    promocodeError: state.billing.promocodeError,
    promocodesuccess: state.billing.promocodesuccess,
    promocodeloading: state.billing.promocodeloading,
    Invoicesuccess: state.billing.Invoicesuccess,
    billingDataLoading: state.billing.billingDataLoading,
    autoDebitError: state.billing.autoDebitError,
    autoDebitSuccess: state.billing.autoDebitSuccess,
    autoDebitLoading: state.billing.autoDebitLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetSubscriptionByInstituteId: (id: string) => {
      dispatch(getSubscriptionByInstituteId(id));
    },
    GetBillingByInstituteId: (id: string, status: string) => {
      dispatch(getBillingByInstituteId({ id: id, status: status }));
    },
    GetStaffList: (id: string) => {
      dispatch(getStaffList(id));
    },
    UpdateInstituteSubscriptionStatus: (data: UpdateSubscriptionStatusDTO) => {
      dispatch(updateInstituteSubscriptionStatus(data));
    },
    UpdateBillingAdmins: (data: UpdateBillingAdminsDTO) => {
      dispatch(updateBillingAdmins(data));
    },
    RemoveBillingAdmins: (data: string) => {
      dispatch(removeBillingAdmin(data));
    },
    GetBillingAdmins: (id: string) => {
      dispatch(getBillingAdmins(id));
    },
    GetBillingAddress: (id: string) => {
      dispatch(getBillingAddress(id));
    },
    UpdateBillingAddress: (data: CreateBillingAddressDTO) => {
      dispatch(updateBillingAddress(data));
    },
    // GetPaymentHistory: (id: string) => {
    //   dispatch(getPaymentHistory(id));
    // },
    GetPaymentHistoryByPagination: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string
    ) => {
      dispatch(
        getPaymentHistoryByPagination({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
        })
      );
    },
    GetPaymentInvoice: (id: string) => {
      dispatch(getPaymentInvoice(id));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
      dispatch(setActiveInsitute(id));
    },
    BillingAutoDebit: (data: BillingAutoDebitDTO, status: string) => {
      dispatch(billingAutoDebit({ data, actionType: status }));
    },
  };
};

export interface BillingProps extends BillingInitialStateDTO, DispatchProps {}
export const BillingComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);
