import { connect } from "react-redux";
import {
  AddAgentsDTO,
  ConnectOzonetelDTO,
  IntegrationsInitialStateDTO,
} from "../service/types";
import {
  addOzonetelAgents,
  callLead,
  connectOzonetel,
  deactivateOzonetel,
  getAppsByInstitute,
  validateAgentId,
} from "../service/IntegrationsSlice";
import OzonetelStages from "../components/OzonetelDetails/OzonetelStages";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { getStaffList } from "../../Manage/ManageStaff/service/Staffslice";
import { getInstituteById } from "../../Manage/InstituteProfile/service/instituteSlice";

interface DispatchProps {
  ConnectOzonetel(data: ConnectOzonetelDTO): void;
  GetStaffList(id: string): void;
  GetInstituteById(id: string): void;
  DeactivateOzonetel(id: string): void;
  ValidateAgentId(data: any): void;
  GetAppsByInstitute(id: string): void;
  AddOzonetelAgents(data: AddAgentsDTO): void;
  CallLead(data: any): void;
}

const mapStateToProps = (state: RootState): IntegrationsInitialStateDTO => {
  return {
    appsList: state.appIntegrations.appsList,
    validatedAgent: state.appIntegrations.validatedAgent,
    error: state.appIntegrations.error,
    success: state.appIntegrations.success,
    loading: state.appIntegrations.loading,
    ozonetelConnectError: state.appIntegrations.ozonetelConnectError,
    ozonetelConnectLoading: state.appIntegrations.ozonetelConnectLoading,
    ozonetelConnectSuccess: state.appIntegrations.ozonetelConnectSuccess,
    deactivateOzonetelError: state.appIntegrations.deactivateOzonetelError,
    deactivateOzonetelLoading: state.appIntegrations.deactivateOzonetelLoading,
    deactivateOzonetelSuccess: state.appIntegrations.deactivateOzonetelSuccess,
    agentAddedError: state.appIntegrations.agentAddedError,
    agentAddedLoading: state.appIntegrations.agentAddedLoading,
    agentAddedSuccess: state.appIntegrations.agentAddedSuccess,
    validateAgentError: state.appIntegrations.validateAgentError,
    validateAgentLoading: state.appIntegrations.validateAgentLoading,
    validateAgentSuccess: state.appIntegrations.validateAgentSuccess,
    agentUpdatedError: state.appIntegrations.agentUpdatedError,
    agentUpdatedLoading: state.appIntegrations.agentUpdatedLoading,
    agentUpdatedSuccess: state.appIntegrations.agentUpdatedSuccess,
    callLeadError: state.appIntegrations.callLeadError,
    callLeadLoading: state.appIntegrations.callLeadLoading,
    callLeadSuccess: state.appIntegrations.callLeadSuccess,
    nurturePayInstalledError: state.appIntegrations.nurturePayInstalledError,
    nurturePayInstalledLoading:
      state.appIntegrations.nurturePayInstalledLoading,
    nurturePayInstalledSuccess:
      state.appIntegrations.nurturePayInstalledSuccess,
    ozonetelWidgetShow: state.appIntegrations.ozonetelWidgetShow,
    callDispositionError: state.appIntegrations.callDispositionError,
    callDispositionLoading: state.appIntegrations.callDispositionLoading,
    callDispositionSuccess: state.appIntegrations.callDispositionSuccess,
    appTokenLoading: state.appIntegrations.appTokenLoading,
    appTokenSuccess: state.appIntegrations.appTokenSuccess,
    appTokenError: state.appIntegrations.appTokenError,
    appTokenData: state.appIntegrations.appTokenData,
    appTokenGetLoading: state.appIntegrations.appTokenGetLoading,
    appIntegrationloading: state.appIntegrations.appIntegrationloading,
    appIntegrationError: state.appIntegrations.appIntegrationError,
    appIntegrationSuccess: state.appIntegrations.appIntegrationSuccess,
    uninstallLoading: state.appIntegrations.uninstallLoading,
    uninstallSuccess: state.appIntegrations.uninstallSuccess,
    uninstallError: state.appIntegrations.uninstallError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    ConnectOzonetel: (data: ConnectOzonetelDTO) => {
      dispatch(connectOzonetel(data));
    },
    GetStaffList: (id: string) => {
      dispatch(getStaffList(id));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
    DeactivateOzonetel: (id: string) => {
      dispatch(deactivateOzonetel(id));
    },
    ValidateAgentId: (data: any) => {
      dispatch(validateAgentId(data));
    },
    GetAppsByInstitute: (id: string) => {
      dispatch(getAppsByInstitute(id));
    },
    AddOzonetelAgents: (data: any) => {
      dispatch(addOzonetelAgents(data));
    },
    CallLead: (data: any) => {
      dispatch(callLead(data));
    },
  };
};

export interface OzonetelStagesProps
  extends IntegrationsInitialStateDTO,
    DispatchProps {}
export const OzontelStagesComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(OzonetelStages);
