import React, { useEffect, useState } from "react";
import styles from "./WebFormResponse.module.css";
import {
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TextField,
  styled,
  Box,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import InfiniteScroll from "react-infinite-scroll-component";
import PuffLoader from "react-spinners/PuffLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GetWebformResponse } from "../../service/webform.request";
import ResponseDetail from "../ResponseDetail/ResponseDetail";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

type formResponseData = {
  instituteId: string;
  formId: string;
  limit: number;
  branchId: string;
  lastEvaluatedKey: string;
  lastEvaluatedSortKey: string;
};

function WebFormResponse(props: any) {
  const {
    GetFormResponse,
    getFormResponseData,
    getFormResponseLoading,
    getFormByInstituteData,
    GetWebformForInstitute,
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetNurtureLeadBoard,
    CreateEnquiry,
    GetMailEntriesByInstitute,
    GetInstituteById,
    GetCourseById,
    GetBatchById,
    GetLeadListByInstituteAndContact,
    CreateSource,
    getStudentContactByIdData,
    GetStudentContactById,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [filterArray, setFilterArray] = useState<any>();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [fieldsName, setFieldsName] = useState<any>();
  const [openResponseDetail, setOpenResponseDetails] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [emailForQuery, setEmailForQuery] = useState("");
  const branchList = useSelector((state: any) => state.branch.branchList);
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const batchList = useSelector((state: any) => state.batch.batchList);

  const auth = useAuthUser();
  const authUser = auth();

  const navigate = useNavigate();

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const formId = pathname.substring(pathname.lastIndexOf("/") + 1);
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage && formId) {
      GetWebformForInstitute(userFromLocalStorage?.instituteId, formId);
    }
  }, [GetWebformForInstitute]);

  const toCamelCase = (str: any) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match: any, index: any) => {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };

  React.useEffect(() => {
    if (getFormByInstituteData) {
      setFieldsName(
        getFormByInstituteData?.fields?.map((d: any) => {
          return d?.fieldName;
        })
      );
    }
  }, [getFormByInstituteData]);

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    const filteredData = getFormResponseData?.returnData?.filter(
      (item: any) => {
        if (
          item?.email
            ?.toLowerCase()
            ?.includes(event.target.value.toLowerCase()) ||
          item?.firstName
            ?.toLowerCase()
            ?.includes(event.target.value.toLowerCase()) ||
          item?.contactNumber?.toString()?.includes(event.target.value)
        ) {
          return true;
        }
        return false;
      }
    );
    setFilterArray({ ...filterArray, returnData: filteredData });
  };

  React.useEffect(() => {
    setFilterArray(getFormResponseData);
  }, [getFormResponseData]);

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const formId = pathname.substring(pathname.lastIndexOf("/") + 1);
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";
    if (userFromLocalStorage && formId) {
      GetFormResponse(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        formId
      );
    }
  }, [GetFormResponse, openResponseDetail]);

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.GSI6SK?.S
    );
    const id = userFromLocalStorage.instituteId;
    const pathname = window.location.pathname;
    const formId = pathname.substring(pathname.lastIndexOf("/") + 1);
    const data: formResponseData = {
      instituteId: userFromLocalStorage?.instituteId,
      formId: formId,
      limit: 15,
      branchId: "",
      lastEvaluatedKey: lastEvaluatedKey,
      lastEvaluatedSortKey: lastEvaluatedSortKey,
    };
    if (lastEvaluatedKey) {
      GetWebformResponse(
        data
        // id,
        // lastEvaluatedKey,
        // 15,
        // lastEvaluatedSortKey,
        // formId,
        // userFromLocalStorage
      )
        .then((res: any) => {
          setHasMoreData(true);
          setFilterArray((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  React.useEffect(() => {
    if (
      filterArray &&
      filterArray?.lastEvaluatedKeyReceived &&
      filterArray?.lastEvaluatedKeyReceived?.SK !== null
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [filterArray]);

  const pathname = window.location.pathname;
  const formId = pathname.substring(pathname.lastIndexOf("/") + 1);

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    if (userFromLocalStorage) {
      GetBranchList(
        userFromLocalStorage.instituteId && userFromLocalStorage.instituteId
      );
      GetCourseList(
        userFromLocalStorage.instituteId && userFromLocalStorage.instituteId
      );
      GetBatchList({
        id:
          userFromLocalStorage.instituteId && userFromLocalStorage.instituteId,
        batchConductType: "REGULAR_BATCH",
      });
    }
  }, [GetBranchList, GetCourseList, GetBatchList]);

  const findNamesbyId = (fieldName: any, id: string) => {
    if (fieldName === "course") {
      const courseName =
        courseList &&
        courseList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return courseName && courseName[0]?.courseName;
    } else if (fieldName === "batch") {
      const batchName =
        batchList &&
        batchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return batchName && batchName[0]?.batchName;
    } else if (fieldName === "branch") {
      const branchName =
        branchList &&
        branchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return branchName && branchName[0]?.branchName;
    }
  };

  const findItembyId = (fieldName: any, id: string) => {
    if (fieldName === "course") {
      const courseName =
        courseList &&
        courseList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return courseName && courseName[0];
    } else if (fieldName === "batch") {
      const batchName =
        batchList &&
        batchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return batchName && batchName[0];
    } else if (fieldName === "branch") {
      const branchName =
        branchList &&
        branchList?.filter(
          (d: any) =>
            d?.id.toString().toLowerCase() === id.toString().toLowerCase()
        );
      return branchName && branchName[0];
    }
  };

  const validFieldsName = fieldsName?.filter(
    (d: any) => d !== "name" && d !== "phone" && d !== "email"
  );

  return (
    <>
      <div style={{ display: openResponseDetail ? "none" : "" }}>
        <div style={{ padding: "20px" }}>
          <button
            className={styles.completedTaskPageBackBtn}
            onClick={() =>
              navigate(`/app/Inbound/Webforms/`, {
                state: 2,
              })
            }
          >
            <ArrowBackIcon
              sx={{
                marginRight: "8px",
                fontSize: "15PX",
                color: "#667085",
              }}
            />
            Back
          </button>
          {filterArray &&
          getFormResponseData &&
          filterArray?.returnData?.length === 0 &&
          getFormResponseData?.returnData?.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {" "}
              {getFormResponseLoading ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PuffLoader color="#36d7b7" />
                </Box>
              ) : (
                <Box
                  style={{
                    border: "1px solid #E3E3E3",
                    width: "40%",
                    padding: "30px",
                    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                    borderRadius: "6px",
                    marginTop: "200px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: "#667085",
                      marginBottom: "22px",
                      fontFamily: "Poppins",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ fontSize: "20px", fontWeight: "600" }}>
                      Webform
                    </div>
                    <br />
                    No Data
                  </div>
                </Box>
              )}
            </div>
          ) : (
            <>
              <div className={styles.funnelHeadSplit}>
                <div className={styles.funnelHeadSplitTitle}>
                  {filterArray &&
                    filterArray?.returnData[0] &&
                    filterArray?.returnData[0]?.webformName}
                </div>
                <div className={styles.funnelHeadSplitSearch}>
                  <TextField
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearch}
                    inputProps={{
                      style: {
                        background: "#ffffff",
                        padding: "8px 12px",
                        width: "300px",
                      },
                    }}
                  />
                </div>
              </div>
              <TableContainer
                sx={{
                  minWidth: 700,
                  borderRadius: "8PX",
                  border: "1px solid  #EAECF0",
                }}
              >
                <InfiniteScroll
                  dataLength={
                    filterArray?.returnData
                      ? filterArray?.returnData?.length
                      : getFormResponseData &&
                        getFormResponseData?.returnData?.length
                  }
                  next={handleNextPage}
                  hasMore={hasMoreData}
                  loader={
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PuffLoader color="#36d7b7" />
                    </Box>
                  }
                  height={500}
                >
                  <Table
                    sx={{
                      minWidth: 700,
                    }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        {validFieldsName &&
                          ["name", "phone", "email", ...validFieldsName]?.map(
                            (d: any) => {
                              return (
                                <StyledTableCell
                                  sx={{
                                    minWidth: d === "name" ? "250px" : "150px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {d}
                                </StyledTableCell>
                              );
                            }
                          )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterArray?.returnData
                        // ?.filter((d: any) => d?.enquirySource === "WEBFORM")
                        ?.map((item: any, index: any) => (
                          <TableRow key={index}>
                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                // navigate(
                                //   `/app/Inbound/Webforms/webformId/${formId}/response/responsedetail/${item.id}`
                                // )
                                {
                                  setResponseId(item?.id);
                                  setOpenResponseDetails(true);
                                  setEmailForQuery(item?.email);
                                }
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "200px !important",
                                }}
                              >
                                {item?.firstName !== "" &&
                                item?.lastName !== "" &&
                                item?.firstName?.toString()?.length > 15 &&
                                item?.lastName?.toString()?.length > 15
                                  ? `${item?.firstName?.slice(
                                      0,
                                      15
                                    )}... ${item?.lastName?.slice(0, 15)}...`
                                  : item?.firstName !== "" &&
                                    item?.lastName === "" &&
                                    item?.firstName?.toString()?.length > 15
                                  ? `${item?.firstName?.slice(0, 15)}...`
                                  : item?.firstName !== "" &&
                                    item?.lastName !== "" &&
                                    item?.firstName?.toString()?.length < 15 &&
                                    item?.lastName?.toString()?.length < 15
                                  ? item?.firstName + " " + item?.lastName
                                  : item?.firstName !== "" &&
                                    item?.lastName === "" &&
                                    item?.firstName?.toString()?.length > 15
                                  ? `${item?.firstName?.slice(0, 15)}...`
                                  : item?.firstName !== "" &&
                                    item?.lastName === "" &&
                                    item?.firstName?.toString()?.length <= 15
                                  ? `${item?.firstName}`
                                  : ""}
                                {item?.enquiryId && (
                                  <span
                                    style={{
                                      color: "#EBA418",
                                      fontSize: "10px",
                                      background: "#FBECD0",
                                      padding: "3px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    Lead Exists
                                  </span>
                                )}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.contactNumber}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              sx={{
                                color: "#667085",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.email?.toString()?.length > 15
                                ? `${item?.email?.slice(0, 15)}...`
                                : item?.email
                                ? item?.email
                                : "-"}
                            </StyledTableCell>
                            <>
                              {item?.otherDetails &&
                              item?.otherDetails?.length > 0
                                ? fieldsName
                                    ?.filter((extras: any) => {
                                      return ![
                                        "name",
                                        "phone",
                                        "email",
                                      ].includes(extras);
                                    })
                                    ?.map(
                                      (fieldName: string, index: number) => (
                                        <StyledTableCell
                                          key={index}
                                          align="left"
                                          sx={{
                                            color: "#667085",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item?.otherDetails?.map((d: any) => {
                                            return d[fieldName]?.toString()
                                              ?.length > 15 &&
                                              (fieldName === "course" ||
                                                fieldName === "branch" ||
                                                fieldName === "batch")
                                              ? `${findNamesbyId(
                                                  fieldName,
                                                  d[fieldName]
                                                )
                                                  ?.toString()
                                                  ?.slice(0, 15)}...`
                                              : d[fieldName]?.toString()
                                                  ?.length <= 15 &&
                                                (fieldName === "course" ||
                                                  fieldName === "branch" ||
                                                  fieldName === "batch")
                                              ? `${findNamesbyId(
                                                  fieldName,
                                                  d[fieldName]
                                                )}`
                                              : d[fieldName]?.toString()
                                                  ?.length > 15
                                              ? `${d[fieldName]
                                                  ?.toString()
                                                  ?.slice(0, 15)}...`
                                              : d[fieldName]?.toString()
                                                  ?.length <= 15 &&
                                                (fieldName === "course" ||
                                                  fieldName === "branch" ||
                                                  fieldName === "batch")
                                              ? `${findNamesbyId(
                                                  fieldName,
                                                  d[fieldName]
                                                )}`
                                              : d[fieldName];
                                          })}
                                        </StyledTableCell>
                                      )
                                    )
                                : ""}
                            </>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </InfiniteScroll>
              </TableContainer>
            </>
          )}
        </div>
      </div>
      {openResponseDetail && (
        <div style={{ padding: "20px" }}>
          <ResponseDetail
            GetBranchList={GetBranchList}
            GetCourseList={GetCourseList}
            GetBatchList={GetBatchList}
            GetNurtureLeadBoard={GetNurtureLeadBoard}
            CreateEnquiry={CreateEnquiry}
            GetMailEntriesByInstitute={GetMailEntriesByInstitute}
            GetInstituteById={GetInstituteById}
            GetCourseById={GetCourseById}
            GetBatchById={GetBatchById}
            getFormResponseData={getFormResponseData}
            GetFormResponse={GetFormResponse}
            GetLeadListByInstituteAndContact={GetLeadListByInstituteAndContact}
            CreateSource={CreateSource}
            getStudentContactByIdData={getStudentContactByIdData}
            GetStudentContactById={GetStudentContactById}
            formId={formId}
            responseId={responseId}
            setOpenResponseDetails={setOpenResponseDetails}
            emailForQuery={emailForQuery}
            findNamesbyId={findNamesbyId}
            findItembyId={findItembyId}
            setEmailForQuery={setEmailForQuery}
          />
        </div>
      )}
    </>
  );
}

export default WebFormResponse;
