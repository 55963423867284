import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Avatar,
  Link,
  styled,
  // IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import styles from "../RecentReport/Report.module.css";
import NewReportModal from "../NewReport/NewReportModal";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../../../../components/Skeletons/TableSkeleton";
import {
  getDownloadedReport,
  resetDownloadReportSuccess,
  resetSaveReportSuccess,
} from "../../service/reportCreationSlice";
import { useDispatch } from "react-redux";
import { clearGetLatestReports } from "../../../../../service/websocketNotificationsSlice";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "500",
  lineHeight: "18px",
  fontSize: "16px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: 150,
}));

const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "12px",
  color: "#667085",
  padding: theme.spacing(1),
  // minWidth: '150px'
  paddingTop: "15px",
  paddingBottom: "10px",
}));

const ReportTable = (props: any) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const { userRoleById } = useSelector((state: any) => state.userDetails);
  const { loading, GetReports, reportData, ViewReports, viewReportSuccess } =
    props;
  const auth = useAuthUser();
  const user = auth();
  const navigate = useNavigate();
  const saveReportSuccess = useSelector(
    (state: any) => state?.reportCreation?.saveReportSuccess
  );
  const dispatch = useDispatch();
  const { downloadReportSuccess, downloadReportData } = useSelector(
    (state: any) => state?.reportCreation
  );
  const [nameForDownloadedReport, setNameForDownloadedReport] = useState("");
  const { getLatestReports } = useSelector(
    (state: any) => state?.websocketNotifications
  );

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    if (getLatestReports) {
      dispatch(
        setToastNotification({
          message: "Report Saved Successfully.",
          type: "success",
          snackOpen: true,
        })
      );
      getReport();
      dispatch(clearGetLatestReports());
      dispatch(resetSaveReportSuccess());
    }
  }, [getLatestReports]);

  const getReport = () => {
    const superAdmin = user?.institute[0]?.role === "Super Admin";
    const admin = user?.institute[0]?.role === "Admin";

    const superAdminPayload = {
      instituteId: user?.institute[0]?.instituteId,
      isRecentReports: true,
      getReports: true,
    };
    const adminPayload = {
      branchId: user?.institute[0]?.branchId,
      isRecentReports: true,
      getReports: true,
    };
    const otherPayloadPayload = {
      createdById: user?.institute[0]?.id,
      isRecentReports: true,
      getReports: true,
    };

    GetReports(
      superAdmin
        ? superAdminPayload
        : admin
        ? adminPayload
        : otherPayloadPayload
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleNewReportClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const filteredReports =
    reportData && reportData?.length > 0
      ? reportData?.filter(
          (report: any) =>
            report?._source?.name
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()) ||
            report?._source?.category
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase()) ||
            report?._source?.createdBy
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())
        )
      : [];

  const updateActivity = (value: string) => {
    ViewReports(value);
  };

  const base64ToBlob = (base64Data: string, contentType: any) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  useEffect(() => {
    if (downloadReportSuccess) {
      const blob = base64ToBlob(
        downloadReportData?.fileBase64String,
        "application/vnd.ms-excel"
      );
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${nameForDownloadedReport}.xlsx`;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      dispatch(resetDownloadReportSuccess());
      setNameForDownloadedReport("");
    }
  }, [downloadReportSuccess]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        overflow: "hidden",
        boxSizing: "border-box",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{ flexShrink: 0 }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "poppins",
            padding: "16px",
            lineHeight: "24px",
            fontSize: "18px",
            fontWeight: "500",
            paddingLeft: "20px",
            color: "#142C44",
          }}
        >
          Recent Reports
        </Typography>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          fontFamily={"poppins"}
          fontWeight={400}
        >
          <TextField
            placeholder="Search Reports"
            // variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              width: "306px",
              height: "36px",
              borderRadius: "4px",
              border: "1px",
              fontFamily: "poppins",
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            sx={{ background: "#11C89B", border: "1px", borderRadius: "4px" }}
            onClick={handleNewReportClick}
          >
            New Report
          </Button>
        </Box>
      </Box>

      {loading ? (
        <TableSkeleton />
      ) : (
        <>
          {filteredReports?.length > 0 && (
            <TableContainer
              component={Paper}
              sx={{
                // minWidth: 700,
                borderRadius: "8px",
                border: "1px solid #EAECF0",
                // padding: ' 0px 12px 0px 12px',
                margin: " 0px 18px 0px 18px",
                height: "calc(100vh - 100px)",
                overflowY: "auto",
                boxShadow: "none",
                flexGrow: 1,
                width: "98%",
                maxHeight: "calc(100vh - 220px)",
                minHeight: "calc(100vh - 220px)",
                scrollbarWidth: "none",
              }}
            >
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ background: "#f5f5f5" }}>
                  <TableRow>
                    <StyledTableCellHead
                      sx={{ minWidth: 300, padding: "16px" }}
                    >
                      Reports Name
                    </StyledTableCellHead>
                    <StyledTableCellHead sx={{ minWidth: 150 }}>
                      Category
                    </StyledTableCellHead>
                    <StyledTableCellHead sx={{ minWidth: 450 }}>
                      Description
                    </StyledTableCellHead>
                    <StyledTableCellHead sx={{ minWidth: 180 }}>
                      Created By
                    </StyledTableCellHead>

                    <StyledTableCellHead sx={{ minWidth: 200 }}>
                      Created On
                    </StyledTableCellHead>
                    <StyledTableCellHead>Actions</StyledTableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredReports?.map((report: any, index: any) => (
                    <TableRow key={index}>
                      <StyledTableCellBody
                        sx={{
                          color: "#142C44",
                          textTransform: "capitalize",
                          fontSize: "14px",
                        }}
                      >
                        {report?._source?.name}
                      </StyledTableCellBody>
                      <StyledTableCellBody
                        sx={{ textTransform: "capitalize", fontSize: "14px" }}
                      >
                        {report?._source?.category === "LEAD" && "Lead"}
                      </StyledTableCellBody>
                      <StyledTableCellBody
                        sx={{ textTransform: "capitalize", fontSize: "14px" }}
                      >
                        {report?._source?.description}
                      </StyledTableCellBody>
                      <StyledTableCellBody
                        sx={{ textTransform: "capitalize", fontSize: "14px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                          }}
                        >
                          <Box className={styles.userCardImg}>
                            <Avatar
                              sx={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                fontSize: "12px",
                                background: "#EEEEF4",
                                border: "2px solid #FFF",
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                color: "#667085",
                                position: "relative",
                              }}
                            >
                              {report?._source?.createdByName?.charAt(0)}
                            </Avatar>
                          </Box>
                          <Box
                            ml={1}
                            sx={{
                              fontSize: "14px",
                              textTransform: "capitalize",
                            }}
                          >
                            {report?._source?.createdByName}
                          </Box>
                        </Box>
                      </StyledTableCellBody>
                      <StyledTableCellBody
                        sx={{
                          fontSize: "14px",
                        }}
                      >
                        {moment(report?._source?.createdDate).format(
                          "DD MMM YYYY, hh:mm A"
                        )}
                      </StyledTableCellBody>
                      <StyledTableCellBody>
                        <Box display="flex" gap={2}>
                          <div
                            style={{
                              color: "#456DFB",
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              updateActivity(report?._source?.id);
                              navigate(
                                report?._source?.category === "LEAD"
                                  ? "/app/Reports/LeadReportCreation"
                                  : report?._source?.category === "REVENUE"
                                  ? "/app/Reports/RevenueReportCreation"
                                  : "/app/Reports/AttendanceReportCreation",
                                {
                                  state: {
                                    from: "RecentReports",
                                    data: report?._source?.reportData,
                                    isFromView: true,
                                    reportName: report?._source?.name,
                                  },
                                }
                              );
                            }}
                          >
                            View
                          </div>
                          <div
                            style={{
                              color: "#456DFB",
                              cursor: "pointer",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              const flatttenedArray =
                                report?._source?.reportData?.result.flatMap(
                                  (item: any) => item
                                );
                              dispatch<any>(
                                getDownloadedReport({
                                  action: "DOWNLOAD",
                                  reportData: flatttenedArray,
                                })
                              );
                              setNameForDownloadedReport(report?._source?.name);
                            }}
                          >
                            Download
                          </div>
                        </Box>
                      </StyledTableCellBody>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      {filteredReports?.length === 0 &&
        loading === false &&
        searchQuery === "" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            No Reports Created yet
          </div>
        )}
      {filteredReports?.length === 0 &&
        loading === false &&
        searchQuery !== "" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            No Data Found
          </div>
        )}
      <NewReportModal
        open={isModalOpen}
        onClose={handleCloseModal}
        page={"RECENTREPORTS"}
      />
    </Box>
  );
};

export default ReportTable;
