import React from "react";
import styles from "../Managebatches.module.css";
import blueAdd from "../../../../../../assets/images/blue-add.svg";
import AddPriceDiscount from "../EditPopup/AddPriceDiscount";
import EditPriceDiscount from "../EditPopup/EditPriceDiscount";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";

function BatchPaymentSession(props: any) {
  const { batchData, hasBatchAccess, batchPayUpdateLoading, selectedCurrency } =
		props;

  const [addDiscountPopup, setAddDiscountPopup] =
    React.useState<boolean>(false);
  const [editDiscountPopup, setEditDiscountPopup] =
    React.useState<boolean>(false);

  const openAddDiscountPopup = (type: any) => {
    setAddDiscountPopup(true);
  };
  const closeAddDiscountPopup = (type: any) => {
    setAddDiscountPopup(false);
  };

  const openEditDiscountPopup = () => {
    setEditDiscountPopup(true);
  };
  const closeEditDiscountPopup = (type: any) => {
    setEditDiscountPopup(false);
  };

  return (
		<div>
			<div className={styles.batchRegularPayDetailsBox}>
				<div className={styles.batchRegularPayDetailsBoxLeft}>
					<div>
						<div className={styles.batchRegularPayDetailsBoxLabel}>Price</div>
						<div className={styles.batchRegularPayDetailsBoxValue}>
							{/*    {batchData?.paymentDetails?.paymentData?.totalAmount?.toLocaleString(
                "en-IN",
                {
                  style: "currency",
                  currency: "INR",
                }
              )} */}
							{selectedCurrency +
								' ' +
								batchData?.paymentDetails?.paymentData?.totalAmount}
						</div>
					</div>
					<div>
						<div className={styles.batchRegularPayDetailsBoxLabel}>Session</div>
						<div
							className={styles.batchRegularPayDetailsBoxValue}
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '5px',
							}}>
							1
						</div>
					</div>
					<div>
						<div className={styles.batchRegularPayDetailsBoxLabel}>
							Discount
						</div>
						<div
							className={styles.batchRegularPayDetailsBoxValue}
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '5px',
							}}>
							{batchData?.paymentDetails?.paymentData?.discount > 0 ? (
								<>
									<>
										{/* 		{(
											batchData?.paymentDetails?.paymentData?.totalAmount -
											batchData?.paymentDetails?.paymentData?.finalPrice
										)?.toLocaleString('en-IN', {
											style: 'currency',
											currency: 'INR',
										})} */}
										{selectedCurrency}{' '}
										{batchData?.paymentDetails?.paymentData?.totalAmount -
											batchData?.paymentDetails?.paymentData?.finalPrice}
									</>
									{/* {hasBatchAccess && (
                    <EditIconBtn
                      onClick={() => {
                        openEditDiscountPopup();
                        // batchData?.paymentDetails?.paymentType === "REGULAR"
                        //   ? setRegularPayEdit(true)
                        //   : batchData?.paymentDetails?.paymentType ===
                        //     "MEMBERSHIP"
                        //   ? setMembershipPayEdit(true)
                        //   : setSessionPayEdit(true);
                      }}
                      disabled={hasBatchAccess === false}
                    />
                  )} */}
								</>
							) : (
								<>
									{hasBatchAccess && (
										<button
											className={styles.batchRegularAddDiscountBtn}
											onClick={openAddDiscountPopup}>
											<img
												src={blueAdd}
												alt='blueAdd'
												style={{
													marginRight: '4px',
													marginBottom: '2px',
												}}
											/>
											Add Discount
										</button>
									)}
								</>
							)}
						</div>
					</div>
				</div>
				<div className={styles.batchRegularPayDetailsBoxRight}>
					<div className={styles.batchRegularPayDetailsBoxLabel}>
						Final Price
					</div>
					<div className={styles.batchRegularPayFinalPrice}>
						{/* 		{batchData?.paymentDetails?.paymentData?.finalPrice?.toLocaleString(
							'en-IN',
							{
								style: 'currency',
								currency: 'INR',
							}
						)} */}
						{selectedCurrency +
							' ' +
							batchData?.paymentDetails?.paymentData?.finalPrice}
					</div>
				</div>
			</div>
			<AddPriceDiscount
				isOpen={addDiscountPopup}
				onClose={closeAddDiscountPopup}
				batchData={batchData}
				loader={batchPayUpdateLoading}
				paymentType='SESSION'
				selectedCurrency={selectedCurrency}
			/>
			<EditPriceDiscount
				isOpen={editDiscountPopup}
				onClose={closeEditDiscountPopup}
				batchData={batchData}
				loader={batchPayUpdateLoading}
				selectedCurrency={selectedCurrency}
			/>
		</div>
	);
}

export default BatchPaymentSession;
