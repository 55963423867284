import styles from "./TaskTypeSelect.module.css";
import React, { useEffect, useRef, useState } from "react";
import call from "./call.svg";
import email from "./email.svg";
import meetings from "./meetings.svg";
import taskIcon from "./taskIcon.svg";
import { IconButton } from "@mui/material";

export default function TaskTypeSelect(props: any) {
  const { taskType, taskTypes, selectedTaskType, isForDispositionForm } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any | null>(null);
  const popupWrapRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (type: any) => {
    setSelectedTask(type);
    setIsOpen(!isOpen);
    taskType(type?.taskTypeName);
  };

  const getTaskIcon = () => {
    switch (selectedTask?.taskTypeName) {
      case "CALL":
        return <img src={call} alt="taskicon" />;
      case "MEETING":
        return <img src={meetings} alt="taskicon" />;
      case "EMAIL":
        return <img src={email} alt="taskicon" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupWrapRef.current &&
        !popupWrapRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popupWrapRef]);

  useEffect(() => {
    if (selectedTaskType !== null && selectedTaskType !== undefined) {
      const defaultTask =
        taskTypes &&
        taskTypes?.find((item: any) => item?.taskTypeName === selectedTaskType);
      setSelectedTask(defaultTask);
    }
  }, [selectedTaskType, taskTypes]);

  return (
    <div className={styles.taskPopupWrap} ref={popupWrapRef}>
      <IconButton
        onClick={toggleDropdown}
        sx={{
          minWidth: "30px",
          borderRadius: "50%",
          // background: selectedUser === "" ? "#fff" : "#f5f5f7",
          border: selectedTask === "" ? "1px solid #fff" : "1px solid #EEEEF4",
          // border: "none",
          width: "30px",
          height: "30px",
          padding: "0",
          overflow: "hidden",
        }}
      >
        {selectedTask ? (
          <div className={styles.selectedIconImage}>{getTaskIcon()}</div>
        ) : (
          <div className={styles.iconImage}>
            <img src={taskIcon} alt="taskicon" />
          </div>
        )}
      </IconButton>

      {isOpen && (
        <ul
          className={
            isForDispositionForm
              ? styles.isForDispositionForm
              : styles.taskPopup
          }
        >
          {taskTypes &&
            taskTypes
              ?.filter((action: any) => action.taskTypeName !== "DEMO")
              .map((action: any) => (
                <li
                  key={action.id}
                  onClick={() => handleSelect(action)}
                  className={styles.taskAssignItem}
                >
                  <div style={{ display: "flex" }}>
                    {action.taskTypeName === "CALL" && (
                      <img src={call} alt="taskicon" />
                    )}
                    {action.taskTypeName === "MEETING" && (
                      <img src={meetings} alt="taskicon" />
                    )}
                    {action.taskTypeName === "EMAIL" && (
                      <img src={email} alt="taskicon" />
                    )}
                  </div>
                  <div className={styles.taskAssignName}>
                    {action.taskTypeName.toLowerCase()}
                  </div>
                </li>
              ))}
        </ul>
      )}
    </div>
  );
}
