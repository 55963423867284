import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  TextField,
  InputAdornment,
  FormGroup,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import styles from "../components/Managebatches.module.css";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import { useAuthUser } from "react-auth-kit";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import ShowAllBtn from "../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import {
  getBatchBookedSession,
  getBatchByIdWithCalander,
  resetBatchUpdateSuccess,
} from "../service/Batchslice";
import { useDispatch } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import BatchDetails from "../components/BatchDetails";
import AddBatchPopup from "../BatchEditor/AddBatchPopup";
import dayjs from "dayjs";
import { getStaffList } from "../../ManageStaff/service/Staffslice";
interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedCourse {
  id: string;
  courseName: string;
}

interface batchType {
  id: string;
  batchType: string;
}

interface modeType {
  id: string;
  modeType: string;
}
interface CheckedBatchStatus {
  batchStatus: string;
}

export default function BatchListing(props: any) {
  const {
    GetBatchList,
    batchList,
    GetBranchList,
    GetCourseList,
    BatchConfig,
    batchListLoading,
    batchUpdateSuccess,
    GetBatchById,
    batchById,
    batchPayUpdateSuccess,
    batchByIdLoading,
    demoStudentDeleteSuccess,
    batchByIdWithCalander,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const location = useLocation();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasBatchAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BATCHES",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const batchTypeList = useSelector(
    (state: any) => state.courseConfig.courseConfigs.courseType
  );
  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const modeOfBatch = [
    { id: 0, name: "All" },
    { id: 1, name: "Regular" },
    { id: 2, name: "Demo" },
  ];

  const batchListWrapperRef = useRef<HTMLDivElement | null>(null);

  const [searchText, setSearchText] = useState<string>("");
  const [selectedBatchId, setSelectedBatchId] = useState<string | null>(null);
  const [addBatchModal, setAddBatchModal] = useState<boolean>(false);
  const [defaultSelectedBatchId, setDefaultSelectedBatchId] = useState<
    string | null
  >(null);

  const [branchSearchTerm, setBranchSearchTerm] = React.useState<string>("");
  const [showAllBranches, setShowAllBranches] = useState<boolean>(false);
  const [courseSearchTerm, setCourseSearchTerm] = React.useState<string>("");
  const [showAllCourses, setShowAllCourses] = useState<boolean>(false);

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  const [checkedCourses, setCheckedCourses] = useState<CheckedCourse[]>([]);
  const [filterCheckedCourses, setFilterCheckedCourses] = useState<
    CheckedCourse[]
  >([]);
  const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);
  const [filterCheckedBranches, setFilterCheckedBranches] = useState<
    CheckedBranch[]
  >([]);

  const [checkedBatchType, setCheckedBatchType] = useState<batchType[]>([]);
  const [filterCheckedBatchType, setFilterCheckedBatchType] = useState<
    batchType[]
  >([]);

  const [checkedBatchStatuses, setCheckedBatchStatuses] = useState<
    CheckedBatchStatus[]
  >([]);
  const [filterCheckedBatchStatuses, setFilterCheckedBatchStatuses] = useState<
    CheckedBatchStatus[]
  >([]);

  const [checkedBatchRuningStatuses, setCheckedBatchRuningStatuses] = useState<
    CheckedBatchStatus[]
  >([]);
  const [
    filterCheckedBatchRuningStatuses,
    setFilterCheckedRuningBatchStatuses,
  ] = useState<CheckedBatchStatus[]>([]);

  const [checkedModeType, setCheckedModeType] = useState<modeType[]>([]);
  const [filterCheckedModeType, setFilterCheckedModeType] = useState<
    modeType[]
  >([]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const openAddBatchPopup = () => {
    setAddBatchModal(true);
  };
  const closeAddBatchPopup = (type: any) => {
    setAddBatchModal(false);
  };

  const handleBatchQuery = (batchId: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    if (batchId) {
      // GetBatchById(batchId);
      dispatch<any>(getBatchByIdWithCalander(batchId));
      queryParams.set("batchId", batchId);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  };

  const handleBranchSearch = (event: any) => {
    setBranchSearchTerm(event.target.value);
  };
  const filteredBranches =
    branches &&
    branches?.filter((branch: any) => {
      const searchTermMatch =
        branch.branchName
          .toLowerCase()
          .indexOf(branchSearchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const displayedBranches = showAllBranches
    ? branches && filteredBranches
    : branches && filteredBranches.slice(0, 5);

  const handleShowMoreBranches = () => {
    setShowAllBranches(!showAllBranches);
  };

  const handleCourseSearch = (event: any) => {
    setCourseSearchTerm(event.target.value);
  };
  const filteredCourses =
    courses &&
    courses?.filter((item: any) => {
      const searchTermMatch =
        item.courseName
          .toLowerCase()
          .indexOf(courseSearchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const displayedCourses = showAllCourses
    ? courses && filteredCourses
    : courses && filteredCourses.slice(0, 5);

  const handleShowMoreCourses = () => {
    setShowAllCourses(!showAllCourses);
  };

  const handleFilterCourseChecked = (id: string, courseName: string) => {
    const courseToAdd: CheckedCourse = { id, courseName };
    if (checkedCourses.some((item) => item.id === id)) {
      setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
    } else {
      setCheckedCourses([...checkedCourses, courseToAdd]);
    }
  };

  const clearCheckedCourse = (id: string) => {
    setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
  };

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    const branchToAdd: CheckedBranch = { id, branchName };
    if (checkedBranches.some((branch) => branch.id === id)) {
      setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBranches([...checkedBranches, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
  };

  const handleFilterBatchTypeChecked = (id: string, batchType: string) => {
    const batchTypeToAdd: batchType = { id, batchType };
    if (checkedBatchType.some((item) => item.id === id)) {
      setCheckedBatchType(checkedBatchType.filter((item) => item.id !== id));
    } else {
      setCheckedBatchType([...checkedBatchType, batchTypeToAdd]);
    }
  };

  const handleFilterBatchModeTypeChecked = (id: string, modeType: string) => {
    const modeTypeToAdd: modeType = { id, modeType };
    if (checkedModeType.some((item) => item.id === id)) {
      setCheckedModeType(checkedModeType.filter((item) => item.id !== id));
    } else {
      setCheckedModeType([...checkedModeType, modeTypeToAdd]);
    }
  };

  const clearCheckedBatchType = (id: string) => {
    setCheckedBatchType(checkedBatchType.filter((item) => item.id !== id));
  };

  const handleFilterBatchStatusChecked = (batchStatus: string) => {
    const batchStatusToAdd: CheckedBatchStatus = { batchStatus };

    if (
      checkedBatchStatuses.some(
        (item) => item.batchStatus.toLowerCase() === batchStatus.toLowerCase()
      )
    ) {
      setCheckedBatchStatuses(
        checkedBatchStatuses.filter(
          (item) => item.batchStatus.toLowerCase() !== batchStatus.toLowerCase()
        )
      );
    } else {
      setCheckedBatchStatuses([...checkedBatchStatuses, batchStatusToAdd]);
    }
  };

  const clearCheckedBatchStatus = (batchStatus: string) => {
    setCheckedBatchStatuses(
      checkedBatchStatuses.filter((item) => item.batchStatus !== batchStatus)
    );
  };

  const handleFilterRuningStatusChecked = (batchStatus: string) => {
    const batchStatusToAdd: CheckedBatchStatus = { batchStatus };

    if (
      checkedBatchRuningStatuses.some(
        (item) => item.batchStatus.toLowerCase() === batchStatus.toLowerCase()
      )
    ) {
      setCheckedBatchRuningStatuses(
        checkedBatchRuningStatuses.filter(
          (item) => item.batchStatus.toLowerCase() !== batchStatus.toLowerCase()
        )
      );
    } else {
      setCheckedBatchRuningStatuses([
        ...checkedBatchRuningStatuses,
        batchStatusToAdd,
      ]);
    }
  };

  const clearCheckedRuningStatus = (batchStatus: string) => {
    setCheckedBatchRuningStatuses(
      checkedBatchRuningStatuses.filter(
        (item) => item.batchStatus !== batchStatus
      )
    );
  };

  const clearCheckedBatchMode = (batchMode: string) => {
    setCheckedModeType(
      checkedModeType.filter((item) => item.modeType !== batchMode)
    );
  };

  const filteredBatchList = batchList?.filter((batch: any) => {
    const nameMatch = batch.batchName
      ?.toLowerCase()
      ?.includes(searchText?.toLowerCase());

    const courseIsChecked =
      filterCheckedCourses?.length === 0 ||
      filterCheckedCourses?.some((item) => item?.id === batch?.courseId);

    const branchIsChecked =
      filterCheckedBranches?.length === 0 ||
      filterCheckedBranches?.some((item) => item?.id === batch?.branchId);

    const batchTypeIsChecked =
      filterCheckedBatchType?.length === 0 ||
      batch.batchType?.some((type: any) =>
        filterCheckedBatchType?.some(
          (checkedType) => checkedType?.id === type?.batchType?.id
        )
      );

    const batchStatusIsChecked =
      filterCheckedBatchStatuses?.length === 0 ||
      filterCheckedBatchStatuses?.some(
        (item) =>
          item?.batchStatus?.toLowerCase() === batch?.status?.toLowerCase()
      );

    const batchRuningStatusIsChecked =
      filterCheckedBatchRuningStatuses?.length === 0 ||
      filterCheckedBatchRuningStatuses?.some(
        (item) =>
          item?.batchStatus?.toLowerCase() === batch?.batchStatus?.toLowerCase()
      );

    const batchModeIsChecked =
      filterCheckedModeType?.length === 0 ||
      filterCheckedModeType?.some((item) => {
        if (item?.modeType === "All") {
          return item;
        } else {
          return (
            item?.modeType?.toLowerCase() ===
            batch?.batchConductType?.toLowerCase()?.split("_")[0]
          );
        }
      });

    const isActiveWhenNoStatusFilters =
      filterCheckedBatchStatuses?.length === 0 && batch?.status === "ACTIVE";

    return (
      nameMatch &&
      courseIsChecked &&
      branchIsChecked &&
      batchTypeIsChecked &&
      batchStatusIsChecked &&
      batchModeIsChecked &&
      batchRuningStatusIsChecked
    );
  });

  const handleFilterBatches = React.useCallback(
    (
      branchArray: any,
      courseArray: any,
      batchTypeArray: any,
      batchStatusArray: any,
      batchModeArray: any,
      batchRunningStatusArray: any
    ) => {
      setFilterCheckedCourses(courseArray);
      setFilterCheckedBranches(branchArray);
      setFilterCheckedBatchType(batchTypeArray);
      setFilterCheckedBatchStatuses(batchStatusArray);
      setFilterCheckedModeType(batchModeArray);
      setFilterCheckedRuningBatchStatuses(batchRunningStatusArray);

      const queryParams = new URLSearchParams();

      if (batchStatusArray && batchStatusArray.length > 0) {
        const batchStatusParam = batchStatusArray
          .map((item: any) => item.batchStatus)
          .join("|");
        queryParams.set("filteredStatus", batchStatusParam);
      } else {
        queryParams.delete("filteredStatus");
      }

      if (batchRunningStatusArray && batchRunningStatusArray.length > 0) {
        const batchRunningStatusParam = batchRunningStatusArray
          .map((item: any) => item.batchStatus)
          .join("|");
        queryParams.set("filteredBatchStatus", batchRunningStatusParam);
      } else {
        queryParams.delete("filteredBatchStatus");
      }

      if (batchModeArray && batchModeArray.length > 0) {
        const batchModeParam = batchModeArray
          .map((item: any) => item.modeType)
          .join("|");
        queryParams.set("filteredMode", batchModeParam);
      } else {
        queryParams.delete("filteredMode");
      }

      if (branchArray && branchArray.length > 0) {
        const branchParam = branchArray
          .map((branch: any) => branch.id)
          .join("|");
        queryParams.set("filteredBranches", branchParam);
      } else {
        queryParams.delete("filteredBranches");
      }

      if (courseArray && courseArray.length > 0) {
        const courseParam = courseArray
          .map((course: any) => course.id)
          .join("|");
        queryParams.set("filteredCourses", courseParam);
      } else {
        queryParams.delete("filteredCourses");
      }

      if (batchTypeArray && batchTypeArray.length > 0) {
        const batchTypeParam = batchTypeArray
          .map((item: any) => item.id)
          .join("|");
        queryParams.set("filteredBatchType", batchTypeParam);
      } else {
        queryParams.delete("filteredBatchType");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },
    [
      setFilterCheckedCourses,
      setFilterCheckedBranches,
      setFilterCheckedBatchType,
      setFilterCheckedBatchStatuses,
      setFilterCheckedModeType,
    ]
  );

  const handleFilterApply = React.useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("batchId");
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    if (filteredBatchList && filteredBatchList?.length > 0) {
      setSelectedBatchId(filteredBatchList?.[0].id);
    }
    handleFilterBatches(
      checkedBranches,
      checkedCourses,
      checkedBatchType,
      checkedBatchStatuses,
      checkedModeType,
      checkedBatchRuningStatuses
    );

    setFilterApplied(
      checkedBranches?.length === 0 &&
        checkedCourses?.length === 0 &&
        checkedBatchType?.length === 0 &&
        checkedBatchStatuses?.length === 0 &&
        checkedModeType?.length === 0 &&
        checkedBatchRuningStatuses?.length === 0
        ? false
        : true
    );
  }, [
    handleFilterBatches,
    setFilterApplied,
    checkedBranches,
    checkedCourses,
    checkedBatchType,
    checkedBatchStatuses,
    checkedModeType,
    checkedBatchRuningStatuses,
  ]);

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setCheckedBranches([]);
    setFilterCheckedBranches([]);
    setCheckedBatchType([]);
    setFilterCheckedBatchType([]);
    setFilterCheckedCourses([]);
    setCheckedCourses([]);
    setFilterCheckedBatchStatuses([]);
    setCheckedBatchStatuses([]);
    setCheckedModeType([]);
    setFilterCheckedModeType([]);
    handleFilterBatches([], [], [], [], [], []);
    setCheckedBatchRuningStatuses([]);
    setFilterCheckedRuningBatchStatuses([]);
    if (filteredBatchList && filteredBatchList.length > 0) {
      setSelectedBatchId(filteredBatchList[0].id);
    } else {
      setSelectedBatchId(null);
    }
  }, [
    setFilterApplied,
    setCheckedBranches,
    setFilterCheckedBranches,
    setCheckedBatchType,
    setFilterCheckedBatchType,
    setFilterCheckedCourses,
    setCheckedCourses,
    setFilterCheckedBatchStatuses,
    setCheckedBatchStatuses,
    setCheckedModeType,
    setFilterCheckedModeType,
    setCheckedBatchRuningStatuses,
    setFilterCheckedRuningBatchStatuses,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedBranches(filterCheckedBranches);
    setCheckedBatchType(filterCheckedBatchType);
    setCheckedCourses(filterCheckedCourses);
    setCheckedBatchStatuses(filterCheckedBatchStatuses);
    setCheckedModeType(filterCheckedModeType);
    setCheckedBatchRuningStatuses(filterCheckedBatchRuningStatuses);
  }, [
    filterCheckedBranches,
    filterCheckedCourses,
    filterCheckedBatchType,
    filterCheckedBatchStatuses,
    filterCheckedBatchRuningStatuses,
    filterCheckedModeType,
  ]);

  const areObjectsEqual = () => {
    const str1 = JSON.stringify(filteredBatchList);
    const str2 = JSON.stringify(batchList);
    return str1 === str2;
  };
  const isEqual = areObjectsEqual();

  const filteredValue = (
    <>
      {filterCheckedBranches?.length > 0 &&
        filterCheckedBranches?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.branchName}
          </div>
        ))}
      {filterCheckedCourses?.length > 0 &&
        filterCheckedCourses?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.courseName}
          </div>
        ))}
      {filterCheckedBatchType?.length > 0 &&
        filterCheckedBatchType?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.batchType}
          </div>
        ))}
      {filterCheckedBatchStatuses?.length > 0 &&
        filterCheckedBatchStatuses?.map((item) => (
          <div key={item.batchStatus} className="filteredDiv">
            {item.batchStatus}
          </div>
        ))}
      {filterCheckedBatchRuningStatuses?.length > 0 &&
        filterCheckedBatchRuningStatuses?.map((item) => (
          <div key={item.batchStatus} className="filteredDiv">
            {item.batchStatus}
          </div>
        ))}
      {filterCheckedModeType?.length > 0 &&
        filterCheckedModeType?.map((item) => (
          <div key={item.modeType} className="filteredDiv">
            {item.modeType}
          </div>
        ))}
    </>
  );

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  useEffect(() => {
    if (filteredBatchList?.length > 0) {
      const defaultBatch = filteredBatchList?.find((item: any) => {
        return (
          filterCheckedBatchStatuses?.length > 0 ||
          (item.status !== "INACTIVE" && item.batchStatus !== "COMPLETED")
        );
      });

      setDefaultSelectedBatchId(defaultBatch?.id);
    }
  }, [batchList]);

  const defaultBatch = filteredBatchList?.find((item: any) => {
    return (
      filterCheckedBatchStatuses?.length > 0 ||
      (item.status !== "INACTIVE" && item.batchStatus !== "COMPLETED")
    );
  });

  const currentSelectedExistsInFiltered = filteredBatchList?.some(
    (batch: any) => batch.id === selectedBatchId
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const batchId = urlSearchParams.get("batchId");
    if (filteredBatchList?.length > 0) {
      if (filterApplied && !currentSelectedExistsInFiltered) {
        setSelectedBatchId(filteredBatchList?.[0]?.id);
        // GetBatchById(filteredBatchList?.[0]?.id);
        dispatch<any>(getBatchByIdWithCalander(filteredBatchList?.[0]?.id));
      } else {
        if (batchId) {
          setSelectedBatchId(batchId);
          // GetBatchById(batchId);
          dispatch<any>(getBatchByIdWithCalander(batchId));
        } else {
          if (defaultBatch) {
            setSelectedBatchId(defaultBatch?.id);
            // GetBatchById(defaultBatch?.id);
            dispatch<any>(getBatchByIdWithCalander(defaultBatch?.id));
          }
        }
      }
    }
  }, [filterApplied, batchList]);

  useEffect(() => {
    // Get batchId from the URL params
    const urlParams = new URLSearchParams(location.search);
    const batchIdParam = urlParams.get("batchId");

    // If batchId is present, find the corresponding element and scroll to it
    if (batchIdParam && batchListWrapperRef.current) {
      const batchElement = batchListWrapperRef.current.querySelector(
        `[data-batch-id="${batchIdParam}"]`
      );
      if (batchElement) {
        batchElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [location.search, batchList]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const batchStatusParam = urlSearchParams.get("filteredStatus");
    const batchTypeParam = urlSearchParams.get("filteredBatchType");
    const branchParam = urlSearchParams.get("filterBranch");
    const courseParam = urlSearchParams.get("filteredCourses");
    const modeParam = urlSearchParams.get("filteredMode");
    const batchRunningStatusParam = urlSearchParams.get("filteredBatchStatus");

    if (branchParam) {
      const branchIds = branchParam.split("|");

      const selectedBranches = branchIds
        .map((id) => {
          const branch = branches?.find((branch: any) => branch.id === id);
          return branch ? { id, branchName: branch.branchName } : null;
        })
        .filter(Boolean) as CheckedBranch[];

      setFilterCheckedBranches(selectedBranches);
      setCheckedBranches(selectedBranches);
    }

    if (courseParam) {
      const courseIds = courseParam.split("|");

      const selectedCourses = courseIds
        .map((id) => {
          const course = courses?.find((course: any) => course.id === id);
          return course ? { id, courseName: course.courseName } : null;
        })
        .filter(Boolean) as CheckedCourse[];

      setFilterCheckedCourses(selectedCourses);
      setCheckedCourses(selectedCourses);
    }

    if (batchTypeParam && batchTypeList) {
      const batchTypeIds = batchTypeParam.split("|");

      const selectedBatchTypes = batchTypeIds
        .map((id) => {
          const batchType = batchTypeList?.find(
            (category: any) => category.id === id
          );
          return batchType ? { id, batchType: batchType.courseTypeName } : null;
        })
        .filter(Boolean) as batchType[];
      setCheckedBatchType(selectedBatchTypes);
      setFilterCheckedBatchType(selectedBatchTypes);
    }

    if (modeParam && modeOfBatch) {
      const modes = modeParam.split("|");
      const selectedModes: modeType[] = modes
        .map((value: string) => {
          const mode = modeOfBatch?.find(
            (type: any) => type.name?.toLowerCase() === value?.toLowerCase()
          );
          return mode
            ? {
                id: String(mode.id),
                modeType: mode.name,
              }
            : null;
        })
        .filter(Boolean) as modeType[];

      setCheckedModeType(selectedModes);
      setFilterCheckedModeType(selectedModes);
    }

    if (batchStatusParam) {
      const statuses = batchStatusParam.split("|");

      const selectedStatuses = statuses
        .map((value) => {
          const status = [{ status: "ACTIVE" }, { status: "INACTIVE" }].find(
            (type: any) => type.status.toLowerCase() === value.toLowerCase()
          );
          return status
            ? {
                batchStatus: status.status,
              }
            : null;
        })
        .filter(Boolean) as CheckedBatchStatus[];

      setFilterCheckedBatchStatuses(selectedStatuses);
      setCheckedBatchStatuses(selectedStatuses);
    }

    if (batchRunningStatusParam) {
      const statuses = batchRunningStatusParam.split("|");

      const selectedStatuses = statuses
        .map((value) => {
          const status = [
            { status: "ONGOING" },
            { status: "UPCOMING" },
            { status: "COMPLETED" },
            { status: "CANCELLED" },
          ].find(
            (type: any) => type.status.toLowerCase() === value.toLowerCase()
          );
          return status
            ? {
                batchStatus: status.status,
              }
            : null;
        })
        .filter(Boolean) as CheckedBatchStatus[];

      setCheckedBatchRuningStatuses(selectedStatuses);
      setFilterCheckedRuningBatchStatuses(selectedStatuses);
    }

    if (
      modeParam ||
      batchTypeParam ||
      batchStatusParam ||
      branchParam ||
      courseParam ||
      batchRunningStatusParam
    ) {
      setFilterApplied(true);
    }
  }, [courses, branches, batchTypeList]);

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    if (instituteData?.id) {
      GetBatchList({
        id: instituteData?.id,
        batchConductType: "ALL",
      });
      GetBranchList(instituteData?.id);
      GetCourseList({
        id: instituteData?.id,
      });
      BatchConfig();
      if (!instituteData?.isProfileCompleted) {
        dispatch<any>(getStaffList(instituteData?.id));
      }
      localStorage.setItem("ShowFullSection", JSON.stringify(false));
    }
  }, [GetBatchList]);
  useEffect(() => {
    if (batchByIdWithCalander?.paymentType === "SESSION") {
      const date = dayjs().unix() * 1000;
      const now = new Date();
      const dateForAttendance = now.setUTCHours(0, 0, 0, 0);
      dispatch<any>(
        getBatchBookedSession({
          id: batchByIdWithCalander?.id,
        })
      );
    }
  }, [batchByIdWithCalander]);

  React.useEffect(() => {
    if (batchUpdateSuccess !== true && batchUpdateSuccess !== false) {
      dispatch(resetBatchUpdateSuccess());
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        GetBatchList({
          id: userFromLocalStorage?.id,
          batchConductType: "ALL",
        });
      }
    }
  }, [batchUpdateSuccess]);

  React.useEffect(() => {
    if (
      (batchUpdateSuccess !== true && batchUpdateSuccess !== false) ||
      (batchPayUpdateSuccess !== true && batchPayUpdateSuccess !== false) ||
      (demoStudentDeleteSuccess !== true && demoStudentDeleteSuccess !== false)
    ) {
      dispatch(resetBatchUpdateSuccess());
      const urlSearchParams = new URLSearchParams(window.location.search);
      const batchId = urlSearchParams.get("batchId");
      if (batchId) {
        // GetBatchById(batchId);
        dispatch<any>(getBatchByIdWithCalander(batchId));
      } else {
        // GetBatchById(selectedBatchId);
        if (selectedBatchId)
          dispatch<any>(getBatchByIdWithCalander(selectedBatchId));
      }
    }
  }, [
    batchUpdateSuccess,
    batchPayUpdateSuccess,
    selectedBatchId,
    demoStudentDeleteSuccess,
  ]);

  return (
    <div>
      <Box className={styles.batchMainWrapper}>
        <Box className={styles.leftSection}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "20px",
              marginBottom: "14px",
              marginTop: "30px",
            }}
          >
            {batchListLoading ? (
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height="40px"
                style={{
                  borderRadius: "2px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <>
                <Box className={styles.batchHead}>Batches</Box>
                {hasBatchAccess && (
                  <Button
                    component="label"
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                      display: "flex",
                      gap: "4px",
                    }}
                    startIcon={<AddIcon />}
                    onClick={openAddBatchPopup}
                    disabled={!hasBatchAccess}
                  >
                    Add new
                  </Button>
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
              gap: "4px",
            }}
          >
            {batchListLoading ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height="40px"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  placeholder="Search Batches"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={handleSearch}
                  size="small"
                  sx={{
                    width: "75%",
                    "&.MuiOutlinedInput-root": {
                      paddingLeft: "0 !important",
                    },
                  }}
                  type="search"
                />
                <div style={{ paddingRight: "20px" }}>
                  <FilterDrawer
                    handleFilterApply={handleFilterApply}
                    handleResetClick={handleResetClick}
                    activeFilter={filterApplied}
                    cancelClick={cancelClick}
                    closeSetFilter={handleFilterClose}
                  >
                    <div
                      style={{
                        padding: "22px 32px",
                        overflowY: "auto",
                        maxHeight: "calc(100% - 110px)",
                      }}
                    >
                      <FilterChipsWrap>
                        {checkedBatchStatuses?.length > 0 &&
                          checkedBatchStatuses?.map((item) => (
                            <FilterChips
                              keyVal={item?.batchStatus}
                              chipName={item?.batchStatus?.toLowerCase()}
                              clearValue={item?.batchStatus}
                              closeFunction={clearCheckedBatchStatus}
                            />
                          ))}
                        {checkedBatchRuningStatuses?.length > 0 &&
                          checkedBatchRuningStatuses?.map((item) => (
                            <FilterChips
                              keyVal={item?.batchStatus}
                              chipName={item?.batchStatus?.toLowerCase()}
                              clearValue={item?.batchStatus}
                              closeFunction={clearCheckedRuningStatus}
                            />
                          ))}
                        {checkedBranches?.length > 0 &&
                          checkedBranches?.map((item) => (
                            <FilterChips
                              keyVal={item?.id}
                              chipName={item?.branchName}
                              clearValue={item?.id}
                              closeFunction={clearCheckedBranch}
                            />
                          ))}

                        {checkedCourses?.length > 0 &&
                          checkedCourses?.map((item) => (
                            <FilterChips
                              keyVal={item?.id}
                              chipName={item?.courseName}
                              clearValue={item?.id}
                              closeFunction={clearCheckedCourse}
                            />
                          ))}
                        {checkedBatchType?.length > 0 &&
                          checkedBatchType?.map((item) => (
                            <FilterChips
                              keyVal={item?.id}
                              chipName={item?.batchType}
                              clearValue={item?.id}
                              closeFunction={clearCheckedBatchType}
                            />
                          ))}
                        {checkedModeType?.length > 0 &&
                          checkedModeType?.map((item) => (
                            <FilterChips
                              keyVal={item?.modeType}
                              chipName={item?.modeType?.toLowerCase()}
                              clearValue={item?.modeType}
                              closeFunction={clearCheckedBatchMode}
                            />
                          ))}
                      </FilterChipsWrap>

                      <FilterTypeWrap>
                        <FilterLabel labelValue="Status" />

                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {[
                              { status: "ACTIVE" },
                              { status: "INACTIVE" },
                            ]?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item?.status}
                                checked={checkedBatchStatuses?.some(
                                  (data) =>
                                    data?.batchStatus?.toLowerCase() ===
                                    item?.status?.toLowerCase()
                                )}
                                checkboxFunction={() =>
                                  handleFilterBatchStatusChecked(item?.status)
                                }
                                label={item?.status?.toLowerCase()}
                              />
                            ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </FilterTypeWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Batch Status" />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {[
                              { status: "ONGOING" },
                              { status: "UPCOMING" },
                              { status: "COMPLETED" },
                              { status: "CANCELLED" },
                            ]?.map((item: any) => (
                              <FilterCheckbox
                                keyVal={item?.status}
                                checked={checkedBatchRuningStatuses?.some(
                                  (data) =>
                                    data?.batchStatus?.toLowerCase() ===
                                    item?.status?.toLowerCase()
                                )}
                                checkboxFunction={() =>
                                  handleFilterRuningStatusChecked(item?.status)
                                }
                                label={item?.status?.toLowerCase()}
                              />
                            ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </FilterTypeWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Type" />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {batchTypeList &&
                              batchTypeList?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item?.id}
                                  checked={checkedBatchType?.some(
                                    (data) => data?.id === item?.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBatchTypeChecked(
                                      item?.id,
                                      item?.courseTypeName
                                    )
                                  }
                                  label={item?.courseTypeName?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </FilterTypeWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Mode" />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {modeOfBatch &&
                              modeOfBatch?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item?.id}
                                  checked={checkedModeType?.some(
                                    (data) => data?.id === item?.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBatchModeTypeChecked(
                                      item?.id,
                                      item?.name
                                    )
                                  }
                                  label={item?.name?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                      </FilterTypeWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Course" />
                        <FilterSearchField
                          value={courseSearchTerm}
                          onChange={handleCourseSearch}
                          placeholder="Search Course"
                        />

                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {courses &&
                              displayedCourses?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item?.id}
                                  checked={checkedCourses?.some(
                                    (data) => data?.id === item?.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterCourseChecked(
                                      item?.id,
                                      item?.courseName
                                    )
                                  }
                                  label={item?.courseName?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>

                        <ShowAllBtn
                          clickFunction={handleShowMoreCourses}
                          showAll={showAllCourses}
                          filteredState={filteredCourses}
                        />
                      </FilterTypeWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Branch" />
                        <FilterSearchField
                          value={branchSearchTerm}
                          onChange={handleBranchSearch}
                          placeholder="Search Branch"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {branches &&
                              displayedBranches?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item?.id}
                                  checked={checkedBranches?.some(
                                    (data) => data?.id === item?.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBranchChecked(
                                      item?.id,
                                      item?.branchName
                                    )
                                  }
                                  label={item?.branchName?.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>

                        <ShowAllBtn
                          clickFunction={handleShowMoreBranches}
                          showAll={showAllBranches}
                          filteredState={filteredBranches}
                        />
                      </FilterTypeWrap>
                    </div>
                  </FilterDrawer>
                </div>
              </>
            )}
          </Box>

          {batchListLoading ? (
            Array.from(new Array(4)).map((_, index) => (
              <div key={index} className={styles.batchCard}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="25px"
                  width="100%"
                  style={{
                    marginBottom: "10px",
                    borderRadius: "3px",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="15px"
                  width="100%"
                  style={{
                    borderRadius: "3px",
                  }}
                />
              </div>
            ))
          ) : (
            <>
              {batchList && filteredBatchList.length === 0 && (
                <Box
                  sx={{ textAlign: "center", position: "relative", top: "50%" }}
                >
                  <div className={styles.NoResult}>No results found</div>
                  <div className={styles.TryAnother}>Try another search</div>
                </Box>
              )}

              <Box
                className={styles.batchListWrapper}
                ref={batchListWrapperRef}
              >
                {batchList &&
                  filteredBatchList
                    .filter((item: any) => {
                      if (filterCheckedBatchStatuses.length === 0) {
                        return item.status !== "INACTIVE";
                      }
                      return true;
                    })
                    .filter((item: any) => {
                      if (filterCheckedBatchRuningStatuses.length === 0) {
                        return item.batchStatus !== "COMPLETED";
                      }
                      return true;
                    })
                    .map((item: any, index: number) => {
                      const key = moment().format("x") + Math.random();
                      return (
                        <div
                          key={index}
                          className={`${styles.batchCard} ${
                            item.id === selectedBatchId
                              ? styles.selectedBatchCard
                              : ""
                          }`}
                          onClick={() => {
                            setSelectedBatchId(item.id);
                            handleBatchQuery(item.id);
                          }}
                          data-batch-id={item.id}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box className={styles.batchName}>
                              {item && item.batchName}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip
                                arrow
                                title={
                                  item.status === "DRAFT" &&
                                  item?.batchConductType === "REGULAR_BATCH"
                                    ? "Please add payment to make batch active"
                                    : item.status === "DRAFT" &&
                                      item?.batchConductType === "DEMO_BATCH"
                                    ? "Please add schedule to make batch active"
                                    : ""
                                }
                                sx={{ width: "100%" }}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: "#101828",
                                      color: "#fff",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      padding: "8px 12px",
                                      "& .MuiTooltip-arrow": {
                                        color: "#101828",
                                      },
                                    },
                                  },
                                }}
                              >
                                <span
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <Box
                                    className={styles.statusInActive}
                                    style={{
                                      color:
                                        item.status === "DRAFT"
                                          ? "rgb(140 147 163)"
                                          : "#d32f2f",
                                    }}
                                  >
                                    {(item.status === "INACTIVE" ||
                                      item.status === "DRAFT") && (
                                      <RadioButtonCheckedIcon />
                                    )}
                                  </Box>
                                </span>
                              </Tooltip>

                              {item?.batchConductType === "REGULAR_BATCH" ? (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    backgroundColor: "#66708514",
                                    color: "#667085",
                                    width: "25px",
                                    height: "25px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  R
                                </div>
                              ) : item?.batchConductType === "DEMO_BATCH" ? (
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    backgroundColor: "#66708514",
                                    color: "#667085",
                                    width: "25px",
                                    height: "25px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  D
                                </div>
                              ) : (
                                ""
                              )}
                            </Box>
                          </Box>

                          <Box className={styles.branchName}>
                            {item?.branchName}
                          </Box>
                        </div>
                      );
                    })}
              </Box>
            </>
          )}
        </Box>
        <Box className={styles.rightSection}>
          {selectedBatchId &&
          filteredBatchList &&
          filteredBatchList?.length > 0 ? (
            <BatchDetails
              hasBatchAccess={hasBatchAccess}
              batchData={batchByIdWithCalander}
              batchByIdLoading={batchByIdLoading}
              batchListLoading={batchListLoading}
            />
          ) : (
            <div
              className={styles.NoCourse}
              onClick={() => {
                openAddBatchPopup();
              }}
            >
              Add Batches
            </div>
          )}
        </Box>
      </Box>
      <ClearFilter
        open={isOpenFilter}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
        onClose={closeClearFilter}
        filteredValue={filteredValue}
      />
      {addBatchModal && (
        <AddBatchPopup isOpen={addBatchModal} onClose={closeAddBatchPopup} />
      )}
    </div>
  );
}
