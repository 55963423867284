import React from "react";
import styles from "./LeadSession.module.css";
import seesionIcon from "../../../../../../assets/images/session-icon.svg";
import AddMoreSessionPopup from "./AddMoreSessionPopup";

function SessionBoxes(props: any) {
  const { leadSessionInfo, leadData, hasLeadAccess, selectedCurrency } = props;
	return (
		<div>
			<div className={styles.sessionBoxHeadSplit}>
				<div className={styles.leadPayHeadTitle}>Session Details</div>
				{hasLeadAccess && (
					<AddMoreSessionPopup
						leadData={leadData}
						selectedCurrency={selectedCurrency}
					/>
				)}
			</div>
			<div className={styles.sessionBoxes}>
				<div
					className={styles.sessionBox}
					style={{ background: '#5C7DEE', border: '1px solid #EEEEEE' }}>
					<div className={styles.sessionBoxTop}>
						<div className={styles.sessionBoxTopIcon}>
							<img src={seesionIcon} alt='session' />
						</div>

						<div className={styles.sessionBoxTopTitle}>Total Session</div>
					</div>
					<div className={styles.sessionBoxCount}>
						{leadSessionInfo?.totalSession}
					</div>
				</div>
				<div
					className={styles.sessionBox}
					style={{ background: '#71C3A8', border: '1px solid #EEEEEE' }}>
					<div className={styles.sessionBoxTop}>
						<div className={styles.sessionBoxTopIcon}>
							<img src={seesionIcon} alt='session' />
						</div>

						<div className={styles.sessionBoxTopTitle}>Used Session</div>
					</div>
					<div className={styles.sessionBoxCount}>
						{leadSessionInfo?.usedSession}
					</div>
				</div>
				<div
					className={styles.sessionBox}
					style={{ background: '#B47AFF', border: '1px solid #EEEEEE' }}>
					<div className={styles.sessionBoxTop}>
						<div className={styles.sessionBoxTopIcon}>
							<img src={seesionIcon} alt='session' />
						</div>

						<div className={styles.sessionBoxTopTitle}>Balance Session</div>
					</div>
					<div className={styles.sessionBoxCount}>
						{leadSessionInfo?.balanceSession}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SessionBoxes;
