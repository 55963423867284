export const getGoogleUrl = (from: string) => {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
  let googleClientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
  let redirectUrl: string = process.env
    .REACT_APP_GOOGLE_GMAIL_REDIRECT_URL as string;
  // let redirectUrl: string = `${window.location.origin}/gauth`;

  // let redirectUrl: string = `${window.location.origin}/gauth`;

  const options = {
    redirect_uri: redirectUrl,
    client_id: googleClientId,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/gmail.readonly",
    ].join(" "),
    state: from,
  };
  const qs = new URLSearchParams(options);
  return `${rootUrl}?${qs.toString()}`;
};
