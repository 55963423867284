import React from "react";

interface Permission {
  permissionAttributeName: string;
  accessStatus: boolean;
}

export default function getTeamMember(
  teamMemberId: string,
  teamsData: any
): any {
  const teamMember = teamsData?.find((team: any) => team?.id === teamMemberId);
  return teamMember || null;
}

export function getCourse(courseId: string, courseData: any): any {
  const course = courseData?.find((item: any) => item?.id === courseId);
  return course || null;
}

export function getDescriptionWithMentionedNames(
  description: any,
  employees: any
): any {
  const employeeNameMap: Record<string, string> = {};

  employees?.forEach((employee: any) => {
    const formattedFirstName =
      employee?.firstName?.charAt(0)?.toUpperCase() +
      employee?.firstName?.slice(1);
    const formattedLastName =
      employee?.lastName?.charAt(0)?.toUpperCase() +
      employee?.lastName?.slice(1);
    employeeNameMap[employee.id] = `${formattedFirstName} ${formattedLastName}`;
  });

  const replacedDescription = description?.replace(
    /@{{([a-zA-Z0-9-]+)}}/g,
    (match: any, employeeId: any) => {
      const employeeName = employeeNameMap[employeeId];
      return employeeName
        ? `<span style='background-color:#F5F5F7;color: #667085;border-radius: 10px;padding: 0 10px;'>@${employeeName}</span>`
        : match;
    }
  );

  return replacedDescription || "";
}

export function getCombinedAssignees(assignees: any[], branchId: string) {
  const branchEmployees =
    assignees?.filter((item: any) => item?.branchId === branchId) || [];

  const otherBranchEmployees =
    assignees?.filter(
      (item: any) =>
        item?.branchId !== branchId && item?.allBranchAccess === true
    ) || [];

  const combinedEmployees = [...branchEmployees, ...otherBranchEmployees];
  const filteredCombinedEmployees =
    combinedEmployees?.filter(
      (item: any) => item?.status === "ACTIVE" && item?.leadCardAccess === true
    ) || [];

  return filteredCombinedEmployees;
}

export function checkObjectsEqual(obj1: any, obj2: any) {
  const str1 = JSON.stringify(obj1);
  const str2 = JSON.stringify(obj2);

  return str1 === str2;
}

// export function formatAmount(revenue: number): string {
//   if (revenue >= 1_00_00_000) {
//     return (revenue / 1_00_00_000).toFixed(2) + " Crore";
//   } else if (revenue >= 1_00_000) {
//     return (revenue / 1_00_000).toFixed(2) + " Lakh";
//   } else {
//     return revenue.toLocaleString("en-IN", {
//       style: "currency",
//       currency: "INR",
//       maximumFractionDigits: 2,
//     });
//   }
// }

export function formatAmount(revenue: number): string {
  if (revenue >= 1_00_00_000) {
    return (
			(revenue / 1_00_00_000)?.toLocaleString('en-IN', {
				maximumFractionDigits: 2,
			}) + ' Crore'
		);
  } else if (revenue >= 1_00_000) {
    return (
			(revenue / 1_00_000)?.toLocaleString('en-IN', {
				maximumFractionDigits: 2,
			}) + ' Lakh'
		);
  } else {
   
    return revenue?.toLocaleString('en-IN', {
			maximumFractionDigits: 2,
		});
  }
}

export function RevenueDisplay(revenue: number) {
   
  const formattedAmount = formatAmount(revenue) || '';
  if (
    formattedAmount.toString().includes('Lakh') ||
    formattedAmount.toString().includes('Crore')
  ) {
    return revenue?.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
    });
  } else {
    return;
  }
};