import React from "react";
import styles from "../Subscription.module.css";
import graceIcon from "./radio-yellow.svg";
import moment from "moment";

function AccountGracePeriod(props: any) {
  const { instituteBillingData } = props;
  const dueDate = instituteBillingData && instituteBillingData[0]?.startDate;
  const gracePeriod =
    instituteBillingData?.[0]?.instituteSubscriptionDetails?.gracePeriod;
  const formattedDueDate = dueDate && moment(dueDate).format("DD MMM, YYYY");

  const dueDatePlus7Days = moment(formattedDueDate, "DD MMM, YYYY")?.add(
    gracePeriod ? gracePeriod : 7,
    "days"
  );

  // Calculate the remaining days until the last date of those 7 days
  const currentDate = moment();
  const lastDateOf7Days = dueDatePlus7Days?.clone()?.endOf("day");

  const remainingDaysInGracePeriod = lastDateOf7Days?.diff(currentDate, "days");
  return (
    <div className={styles.gracePeriodWarningWrap}>
      <div className={styles.gracePeriodWarningWrapLeft}>
        <div className={styles.gracePeriodWarningIcon}>
          <img src={graceIcon} alt="graceIcon" />
        </div>
        <div className={styles.gracePeriodWarningLabel}>Grace Period</div>
      </div>

      <div className={styles.gracePeriodWarningWrapRight}>
        {`${remainingDaysInGracePeriod} ${
          remainingDaysInGracePeriod === 1 ? "day" : "days"
        } left`}
      </div>
    </div>
  );
}

export default AccountGracePeriod;
