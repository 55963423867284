import React, { useState } from "react";
import { Box, Modal, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../PaymentType.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 430,
    xl: 430,
    md: 430,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: "20px",
  zIndex: 9999999,
};

function PaymentDisablePopup(props: any) {
  const { open, onClose, yesButtonClick, loader } = props;
  const [stage, setStage] = useState<number>(0);
  const handleSecondStage = () => {
    setStage(1);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div
              onClick={() => {
                onClose();
                setStage(0);
              }}
              className={styles.closeModalButton}
            >
              <CloseIcon />
            </div>
            {stage == 0 && (
              <div className={styles.disableAutoDebitStage}>
                <div className={styles.enableAutoDebitTitle}>
                  Disable Auto Debit
                </div>
                <div className={styles.enableAutoDebitDesc}>
                  Disabling auto debit for your recurring payments can result in
                  missed payments and potential service interruptions. Ensure
                  you have alternative payment methods in place and carefully
                  consider the consequences before proceeding. Are you sure you
                  want to disable auto debit?
                  {/* <span>activate the auto debit facility</span> */}
                </div>

                <div className={styles.buttonSection}>
                  <button
                    onClick={handleSecondStage}
                    className={styles.warnBtn}
                    disabled={loader}
                  >
                    {loader ? (
                      <CircularProgress size={20} color={"inherit"} />
                    ) : (
                      "Turn off Auto Debit"
                    )}
                  </button>
                </div>
              </div>
            )}

            {stage === 1 && (
              <div className={styles.disableAutoDebitStage}>
                <div
                  className={styles.enableAutoDebitTitle}
                  style={{ textAlign: "left" }}
                >
                  Are you sure you want to disable auto debit?
                </div>

                <div className={styles.buttonSection}>
                  <button
                    onClick={() => {
                      onClose();
                      setStage(0);
                    }}
                    className={styles.cancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={yesButtonClick}
                    className={styles.warnBtn}
                    disabled={loader}
                  >
                    {loader ? (
                      <CircularProgress size={20} color={"inherit"} />
                    ) : (
                      "Confirm"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default PaymentDisablePopup;
