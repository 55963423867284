import React, { useState } from "react";
import EditIconBtn from "../../../../../../components/Buttons/EditIconBtn";
import moment from "moment";
import { convertToAdjustedTimestamp } from "../../../../../../components/Formatter/Formatter";
import styles from "../Managebatches.module.css";
import calanderIcon from "../../../../../../assets/images/blue-calender.svg";
import clockIcon from "../../../../../../assets/images/blue-clock.svg";
import { useNavigate } from "react-router-dom";
import EditDemoBatchTiming from "./EditDemoBatchTiming";
import eyeIcon from "../Assets/eye.svg";
import noSchedule from "../../../../../../assets/images/schedule-time.svg";
import BookedBatchSession from "./components/BookedBatchSession";

function BatchTimingDetails(props: any) {
  const { batchData, hasBatchAccess, setEditScreen, editScreen } = props;
  const navigate = useNavigate();

  const validBatchSessions = batchData?.batchSchedule?.filter((item: any) => {
    if (
      item?.batchSessions?.length > 0 &&
      item?.batchSessions[0]?.startTime !== 0
    ) {
      return item;
    }
  });
  const [showEdit, setShowEdit] = useState<boolean>(false);
  return (
    <div className={styles.batchTimingWrapper}>
      <div className={styles.batchSecHeadSplit}>
        <div className={styles.batchSecTitle}>Timing</div>
        {batchData?.batchType?.length > 0 &&
          batchData?.minimumSeats > 0 &&
          batchData?.maximumSeats > 0 &&
          batchData?.assignees?.length > 0 &&
          ((validBatchSessions && validBatchSessions?.length > 0) ||
            batchData?.customDays?.length > 0) &&
          hasBatchAccess && (
            <EditIconBtn
              onClick={() => {
                batchData?.batchConductType === "REGULAR_BATCH"
                  ? navigate(`/app/manage/Batches/Schedule/${batchData?.id}`)
                  : setShowEdit(true);
              }}
            />
          )}
      </div>
      {(validBatchSessions && validBatchSessions?.length > 0) ||
      batchData?.customDays?.length > 0 ? (
        <>
          {((!showEdit && batchData?.batchConductType === "DEMO_BATCH") ||
            batchData?.batchConductType === "REGULAR_BATCH") && (
            <div className={styles.batchTimingWrap}>
              <div
                style={{ marginBottom: "26px", display: "flex", gap: "48px" }}
              >
                <div>
                  <div className={styles.batchBasicDetailsLabel}>
                    Start Date
                  </div>
                  <div className={styles.batchBasicDetailsValue}>
                    {moment
                      .unix(
                        convertToAdjustedTimestamp(batchData?.startDate) / 1000
                      )
                      .format("DD/MM/YYYY")}
                  </div>
                </div>
                {batchData?.batchConductType === "REGULAR_BATCH" && (
                  <div>
                    <div className={styles.batchBasicDetailsLabel}>
                      End Date
                    </div>
                    <div className={styles.batchBasicDetailsValue}>
                      {moment
                        .unix(
                          convertToAdjustedTimestamp(batchData?.endDate) / 1000
                        )
                        .format("DD/MM/YYYY")}
                    </div>
                  </div>
                )}
              </div>
              {batchData?.paymentType === "SESSION" ? (
                <BookedBatchSession batchData={batchData} />
              ) : (
                <>
                  {validBatchSessions?.map((item: any, index: number) => (
                    <div className={styles.batchTimingDayRow} key={index}>
                      <div
                        className={styles.batchTimingDay}
                        style={{ minWidth: "106px" }}
                      >
                        <img src={calanderIcon} alt="calanderIcon" />
                        {item?.day && moment(item?.day, "ddd").format("dddd")}
                      </div>
                      <div className={styles.batchTimingDaySessionWrap}>
                        <div>
                          <img src={clockIcon} alt="clockIcon" />
                        </div>
                        <div className={styles.batchTimingDaySessionItems}>
                          {[...item?.batchSessions]
                            ?.sort((a: any, b: any) => {
                              return a?.startTime - b?.startTime;
                            })
                            ?.map((d: any) => {
                              return (
                                <div
                                  className={styles.batchTimingDaySessionItem}
                                >
                                  {moment
                                    ?.unix(d?.startTime)
                                    ?.format("hh:mm A") +
                                    " " +
                                    "to" +
                                    " " +
                                    moment?.unix(d?.endTime)?.format("hh:mm A")}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  ))}

                  {batchData?.customDays?.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <div className={styles.batchTimingSubSecTitle}>
                        Custom Days
                      </div>
                      {batchData?.customDays
                        ?.slice()
                        ?.sort((a: any, b: any) => a?.date - b?.date)
                        ?.map((item: any, index: number) => (
                          <div className={styles.batchTimingDayRow} key={index}>
                            <div
                              className={styles.batchTimingDay}
                              style={{ minWidth: "106px" }}
                            >
                              <img src={calanderIcon} alt="calanderIcon" />
                              {item?.day &&
                                moment(item?.day, "ddd").format("dddd")}
                            </div>
                            <div className={styles.batchTimingDay}>
                              {item &&
                                moment
                                  .unix(
                                    convertToAdjustedTimestamp(item.date) / 1000
                                  )
                                  .format("DD/MM/YYYY")}
                            </div>
                            <div className={styles.batchTimingDaySessionWrap}>
                              <div>
                                <img src={clockIcon} alt="clockIcon" />
                              </div>
                              <div
                                className={styles.batchTimingDaySessionItems}
                              >
                                {item?.batchSessions?.map((d: any) => {
                                  return (
                                    <div
                                      className={
                                        styles.batchTimingDaySessionItem
                                      }
                                    >
                                      {moment
                                        ?.unix(d?.startTime)
                                        ?.format("hh:mm A") +
                                        " " +
                                        "to" +
                                        " " +
                                        moment
                                          ?.unix(d?.endTime)
                                          ?.format("hh:mm A")}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {batchData?.exemptedDates?.length > 0 && (
                    <div style={{ marginTop: "28px" }}>
                      <div className={styles.batchTimingSubSecTitle}>
                        Exempted Dates
                      </div>
                      {batchData?.exemptedDates
                        ?.slice()
                        ?.sort((a: any, b: any) => a?.date - b?.date)
                        ?.map((item: any, index: number) => (
                          <div className={styles.batchTimingDayRow} key={index}>
                            <div
                              className={styles.batchTimingDay}
                              style={{ minWidth: "106px" }}
                            >
                              <img src={calanderIcon} alt="calanderIcon" />
                              {item?.exemptedDate &&
                                moment(
                                  moment.unix(
                                    convertToAdjustedTimestamp(
                                      item.exemptedDate
                                    ) / 1000
                                  ),
                                  "ddd"
                                ).format("dddd")}
                            </div>
                            <div className={styles.batchTimingDay}>
                              {item &&
                                moment
                                  .unix(
                                    convertToAdjustedTimestamp(
                                      item.exemptedDate
                                    ) / 1000
                                  )
                                  .format("DD/MM/YYYY")}
                            </div>
                            <div
                              className={styles.batchTimingDaySessionWrap}
                            ></div>
                          </div>
                        ))}
                    </div>
                  )}
                </>
              )}

              {batchData?.demoTaskList?.length > 0 && (
                <div style={{ marginTop: "20px" }}>
                  <div className={styles.batchTimingSubSecTitle}>
                    Scheduled Demos
                  </div>
                  {batchData?.demoTaskList?.map((item: any, index: number) => (
                    <div
                      className={styles.batchTimingDayRow}
                      key={index}
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className={styles.batchTimingDayRowLeft}>
                        <div
                          className={styles.batchTimingDay}
                          style={{ minWidth: "106px" }}
                        >
                          <img src={calanderIcon} alt="calanderIcon" />
                          {moment(item?.demoData?.[0]?.demoTiming).format(
                            "dddd"
                          )}
                        </div>
                        <div className={styles.batchTimingDay}>
                          {moment(item?.demoData?.[0]?.demoTiming).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                        <div className={styles.batchTimingDaySessionWrap}>
                          <div>
                            <img src={clockIcon} alt="clockIcon" />
                          </div>
                          <div className={styles.batchTimingDaySessionItems}>
                            <div className={styles.batchTimingDaySessionItem}>
                              {moment(
                                item?.demoData?.[0]?.demoStartTime
                              ).format("hh:mm A") +
                                " " +
                                "to" +
                                " " +
                                moment(item?.demoData?.[0]?.demoEndTime).format(
                                  "hh:mm A"
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className={styles.demoStudentCardLeadBtn}
                          onClick={() => {
                            navigate(`/app/Tasks/DemoTaskDetails/${item?.id}`);
                          }}
                        >
                          <img
                            style={{ marginRight: "4px" }}
                            src={eyeIcon}
                            alt="eyeIcon"
                          />
                          View Demo Task
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {batchData?.batchType?.length > 0 &&
          batchData?.minimumSeats > 0 &&
          batchData?.maximumSeats > 0 &&
          batchData?.assignees?.length > 0 ? (
            <>
              {!showEdit && (
                <div className={styles.noBatchTimingContainer}>
                  <div>
                    <img
                      style={{ marginRight: "4px" }}
                      src={noSchedule}
                      alt="noSchedule"
                    />
                  </div>
                  <div className={styles.noDetailsText}>No Details Yet</div>
                  {hasBatchAccess && (
                    <div
                      className={styles.noSchedulesText}
                      onClick={() => {
                        batchData?.batchConductType === "REGULAR_BATCH"
                          ? navigate(
                              `/app/manage/Batches/Schedule/${batchData?.id}`
                            )
                          : setShowEdit(true);
                      }}
                    >
                      + Schedule Now
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className={styles.noBatchTimingContainer}>
              <div className={styles.noDetailsText}>
                Please complete Batch info details to proceed with Schedule
              </div>
              {hasBatchAccess && !editScreen && (
                <div
                  className={styles.noSchedulesText}
                  onClick={() => {
                    setEditScreen(true);
                  }}
                >
                  + Add
                </div>
              )}
            </div>
          )}
        </>
      )}
      {showEdit && batchData?.batchConductType === "DEMO_BATCH" && (
        <EditDemoBatchTiming setShowEdit={setShowEdit} showEdit={showEdit} />
      )}
    </div>
  );
}

export default BatchTimingDetails;
