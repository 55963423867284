import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import demoDate from "./demoDate.svg";
import moment from "moment";
import demoTime from "./demoTime.svg";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import getTeamMember from "../CommonFunctions/CommonFunctions";
import { useSelector } from "react-redux";

function ActivityDemo(props: any) {
  const { data } = props;
  const employees = useSelector((state: any) => state.staff.staffList);

  const urlParams = new URLSearchParams(window.location.search);
  const leadActivityId = urlParams.get("leadActivityId");

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
          background: leadActivityId === data?.id ? "#e8faf6" : "transparent",
          padding: leadActivityId === data?.id ? "0 5px 5px 5px" : "0px",
          borderRadius: leadActivityId === data?.id ? "4px" : "0px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityTop}>
            <div className={styles.activityDateStyle}>
              {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
            </div>
            <div className={styles.activityHeadStage}>
              <div className={styles.activityHeadStageIcon}>
                <img src={stageDot} alt="" />
              </div>
              <div>{data?.currentStage}</div>
            </div>
            <div className={styles.activityHeadName}>
              <div className={styles.activityHeadStageIcon}>
                <img src={nameLine} alt="" />
              </div>
              <div style={{ textTransform: "capitalize" }}>
                {data.activityType === "DEMO" ||
                data.activityType === "DEMO_BOOKED"
                  ? getTeamMember(data?.createdById, employees)?.firstName +
                    " " +
                    getTeamMember(data?.createdById, employees)?.lastName
                  : getTeamMember(data?.updatedById, employees)?.firstName +
                    " " +
                    getTeamMember(data?.updatedById, employees)?.lastName}
              </div>
            </div>
          </div>

          <div
            className={styles.activityDemoTitle}
            style={{
              color:
                data.activityType === "DEMO_CANCELLED" ? "#EC3939" : "#142C44",
            }}
          >
            {data.activityType === "DEMO_BOOKED"
              ? "Demo Booked"
              : data.activityType === "DEMO_RESCHEDULED"
              ? "Demo Rescheduled"
              : data.activityType === "DEMO_CANCELLED"
              ? "Demo Cancelled"
              : ""}
          </div>
          <div className={styles.activityDemoBottom}>
            <div className={styles.activityDemoBatchName}>
              {" "}
              {data?.activityData?.batchName}
            </div>
            <div className={styles.activityDemoDate}>
              <div className={styles.activityDemoDateIcon}>
                <img src="" alt="" />
              </div>
              <div className={styles.activityDemoDateValue}>
                {moment(data?.activityData?.demoTiming).format(
                  "DD MMM YYYY, ddd"
                )}
              </div>
            </div>
            <div className={styles.activityDemoDate}>
              <div className={styles.activityDemoDateIcon}>
                <img src="" alt="" />
              </div>
              <div className={styles.activityDemoDateValue}>
                {data?.activityData?.demoStartTime?.toString()?.length === 10
                  ? moment
                      .unix(data?.activityData?.demoStartTime)
                      .format("hh:mm A")
                  : moment(data?.activityData?.demoStartTime).format("hh:mm A")}
                {" - "}
                {data?.activityData?.demoEndTime?.length === 10
                  ? moment
                      .unix(data?.activityData?.demoEndTime)
                      .format("hh:mm A")
                  : moment(data?.activityData?.demoEndTime).format("hh:mm A")}
              </div>
            </div>
            <div className={styles.activityDemoClassType}>
              {data?.activityData?.demoType}
            </div>
            <div className={styles.activityBottomAssign}>
              {getTeamMember(data?.activityData?.assignedToId, employees)
                ?.firstName?.charAt(0)
                ?.toUpperCase()}
              {getTeamMember(data?.activityData?.assignedToId, employees)
                ?.lastName?.charAt(0)
                ?.toUpperCase()}
              {/* {data?.activityData?.assignedToName
                ?.split(" ")
                ?.slice(0, 2)
                ?.map((word: any, index: number) => (
                  <span key={index}>{word?.charAt(0)}</span>
                ))} */}
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ActivityDemo;
