import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import {
  EmailConfigDTO,
  EmailConfigData,
  ImapSyncData,
  InboundInitialStateDTO,
} from "../service/types";
import { imapSyncEmail, saveEmailConfigDetails } from "../service/inboundSlice";

import EmailSyncing from "../components/Syncing/EmailSyncing";

interface StateProps extends InboundInitialStateDTO {}

interface DispatchProps {
  SaveEmailConfigurationDetails(data: EmailConfigData): void;
  ImapEmailSync(data: ImapSyncData): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.inbound.success,
    error: state.inbound.error,
    loading: state.inbound.loading,
    mailList: state.inbound.mailList,
    mailById: state.inbound.mailById,
    mailConfigsList: state.inbound.mailConfigsList,
    importCsvData: state.inbound.importCsvData,
    templateData: state.inbound.templateData,
    importHistoryData: state.inbound.importHistoryData,
    contactList: state.inbound.contactList,
    downloadHistoryData: state.inbound.downloadHistoryData,

    emailConfigSuccess: state.inbound.emailConfigSuccess,
    emailConfigError: state.inbound.emailConfigError,
    emailConfigLoading: state.inbound.emailConfigLoading,
    deleteEmailSuccess: state.inbound.deleteEmailSuccess,
    deleteEmailError: state.inbound.deleteEmailError,
    deleteEmailLoading: state.inbound.deleteEmailLoading,
    imapSyncLoading: state.inbound.imapSyncLoading,
    imapSyncSuccess: state.inbound.imapSyncSuccess,
    imapSyncError: state.inbound.imapSyncError,
    emailDetailsLoading: state.inbound.emailDetailsLoading,
    emailDetailsSuccess: state.inbound.emailDetailsSuccess,
    emailDetailsError: state.inbound.emailDetailsError,
    templateDownloadError: state.inbound.templateDownloadError,
    templateDownloadLoading: state.inbound.templateDownloadLoading,
    templateDownloadSuccess: state.inbound.templateDownloadSuccess,
    importCsvLoading: state.inbound.importCsvLoading,
    importCsvSuccess: state.inbound.importCsvSuccess,
    importCsvError: state.inbound.importCsvError,
    contactListLoading: state.inbound.contactListLoading,
    contactListSuccess: state.inbound.contactListSuccess,
    contactListError: state.inbound.contactListError,
    deleteContactLoading: state.inbound.deleteContactLoading,
    deleteContactSuccess: state.inbound.deleteContactSuccess,
    deleteContactError: state.inbound.deleteContactError,
    addedEnquiryContactLoading: state.inbound.addedEnquiryContactLoading,
    addedEnquiryContactSuccess: state.inbound.addedEnquiryContactSuccess,
    addedEnquiryContactError: state.inbound.addedEnquiryContactError,
    otherEmailConfigLoading: state.inbound.otherEmailConfigLoading,
    otherEmailConfigSuccess: state.inbound.otherEmailConfigSuccess,
    otherEmailConfigError: state.inbound.otherEmailConfigError,
    importHistoryLoading: state.inbound.importHistoryLoading,
    downloadHistoryDataSuccess: state.inbound.downloadHistoryDataSuccess,
    downloadHistoryDataError: state.inbound.downloadHistoryDataError,
    downloadHistoryDataLoading: state.inbound.downloadHistoryDataLoading,
    inboundDeactivateError: state.inbound.inboundDeactivateError,
    inboundDeactivateSuccess: state.inbound.inboundDeactivateSuccess,
    inboundDeactivateLoading: state.inbound.inboundDeactivateLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    SaveEmailConfigurationDetails: (data: any) => {
      dispatch(saveEmailConfigDetails(data));
    },
    ImapEmailSync: (data: ImapSyncData) => {
      dispatch(imapSyncEmail(data));
    },
  };
};
export interface EmailSyncingProps extends StateProps, DispatchProps {}
export const EmailSyncingComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailSyncing);
