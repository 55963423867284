import React from "react";
import styles from "./AddComment.module.css";
import { Avatar, Button, Box, CircularProgress } from "@mui/material";
import { Mention, MentionsInput } from "react-mentions";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../RolePermissionAccess/RolePermissionAccess";
import { getCombinedAssignees } from "../../CommonFunctions/CommonFunctions";

function AddComment(props: any) {
  const {
    handleAddComment,
    leadById,
    handleCancelComment,
    employees,
    fromCommentEdit,
    CommentForEditing,
    CreateComment,
    EditComment,
    taskId,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const { createCommentLoading, updateCommentLoading } = useSelector(
    (state: any) => state.nurture
  );
  const branchEmployees = employees?.filter(
    (item: any) => item?.branchId === authUser?.institute?.[0]?.branchId
  );
  const otherBranchEmployees = employees?.filter(
    (item: any) =>
      item?.branchId !== authUser?.institute?.[0]?.branchId &&
      item?.allBranchAccess === true
  );
  // const combinedEmployees = [...branchEmployees, ...otherBranchEmployees];
  const combinedEmployees = getCombinedAssignees(employees, leadById?.branchId);

  const activeEmployees = combinedEmployees?.filter((item: any) => {
    const isActiveAndHasLeadCardAccess =
      item.status === "ACTIVE" && item?.leadCardAccess === true;

    return isActiveAndHasLeadCardAccess;
  });

  const [noteDetails, setNoteDetails] = React.useState({
    description: fromCommentEdit ? CommentForEditing?.description : "",
    enquiryId: "",
    mentionedIds: [],
    createdById: "",
    createdByName: "",
    instituteId: "",
    instituteName: "",
    taskId: "",
  });

  const handleChange = React.useCallback(
    (event: any) => {
      setNoteDetails({
        ...noteDetails,
        description: event.target.value,
      });
    },
    [noteDetails]
  );

  const handleSaveClick = async (event: any) => {
    event.preventDefault();
    const data = {
      id: CommentForEditing?.id,
      enquiryId: leadById.id,
      swimlaneState: leadById.enquirySwimlaneStatus,
      mentionedIds: noteDetails?.mentionedIds,
      updatedById: authUser?.institute?.[0]?.id,
      updatedByName: authUser?.firstName + " " + authUser?.lastName,
      description: noteDetails?.description,
      instituteId: authUser?.institute[0]?.instituteDetails?.id,
      instituteName: authUser?.institute[0]?.instituteDetails?.instituteName,
      taskId: taskId,
    };
    try {
      fromCommentEdit ? EditComment(data) : CreateComment(noteDetails);
    } catch (error) {}
  };
  function getNameById(id: any) {
    const employee = activeEmployees?.find((e: any) => e.id === id);

    return employee
      ? `${
          employee.firstName.charAt(0).toUpperCase() +
          employee.firstName.slice(1)
        } ${
          employee.lastName.charAt(0).toUpperCase() + employee.lastName.slice(1)
        }`
      : "Unknown";
  }

  React.useEffect(() => {
    if (leadById) {
      setNoteDetails({
        ...noteDetails,
        enquiryId: leadById.id,
        createdById: authUser?.institute?.[0]?.id,
        createdByName: authUser?.firstName + " " + authUser?.lastName,
        instituteId: authUser?.institute[0]?.instituteDetails?.id,
        instituteName: authUser?.institute[0]?.instituteDetails?.instituteName,
        taskId: taskId,
      });
    }
  }, [leadById]);

  React.useEffect(() => {
    if (fromCommentEdit) {
      setNoteDetails({
        ...noteDetails,
        mentionedIds: CommentForEditing?.mentionedIds,
      });
    }
  }, [CommentForEditing, fromCommentEdit]);

  const modifiedActiveEmployees = activeEmployees?.map((employee: any) => ({
    id: employee.id, // Unique identifier
    display: `${employee.firstName} ${employee.lastName}`,
  }));

  return (
    <div>
      <form onSubmit={handleSaveClick}>
        <div
          style={{
            padding: "10px 0px 10px 0",
            position: "relative",
          }}
        >
          <MentionsInput
            value={noteDetails.description}
            onChange={handleChange}
            style={{
              control: {
                backgroundColor: "#fff",
                border: "1px solid #E8E8E8",
                fontSize: "14px",
                color: "#69696B",
                height: "auto",
                minHeight: "79px",
                maxHeight: "150px",
                overflow: "auto",
                borderRadius: "4px",
                padding: "10px 10px",
              },
              highlighter: {
                overflow: "hidden",
              },
              input: {
                margin: 0,
                border: "none",
                top: "10px",
                left: "10px",
                bottom: "10px",
                width: "calc(100% - 22px)",
                height: "auto",
                outline: "none",
              },
              "&singleLine": {
                margin: 0,
              },
              suggestions: {
                width: "222px",
                zIndex: 2,
                overflow: "auto",
                maxHeight: "140px",
                backgroundColor: "#fff",
                border: "1px solid #F0F0F0",
                borderRadius: "6px",
                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.06)",
                fontSize: "14px",
                position: "absolute",
                padding: "10px",
                color: "#142C44",
                fontWeight: "500",
                lineHeight: "16px",
                top:
                  noteDetails.description?.toString()?.split("\n")?.length <= 1
                    ? 0
                    : noteDetails.description?.toString()?.split("\n")
                        ?.length === 2
                    ? "10%"
                    : noteDetails.description?.toString()?.split("\n")
                        ?.length === 3
                    ? "30%"
                    : noteDetails.description?.toString()?.split("\n")
                        ?.length === 4
                    ? "40%"
                    : noteDetails.description?.toString()?.split("\n")
                        ?.length === 5
                    ? "50%"
                    : noteDetails.description?.toString()?.split("\n")
                        ?.length === 6
                    ? "60%"
                    : "100%",
              },
            }}
          >
            <Mention
              // data={activeEmployees}
              data={modifiedActiveEmployees}
              trigger="@"
              markup="@{{__id__}}"
              // markup="@{{firstName}}"
              style={{
                backgroundColor: "#F5F5F7",
                // color: "#667085",
                padding: "5px",
                marginRight: "5px",
                borderRadius: "20px",
              }}
              displayTransform={(id: any, firstName: any) => {
                return "@" + getNameById(id);
              }}
              onAdd={(id: any) => {
                setNoteDetails((prevDetails: any) => {
                  if (!prevDetails?.mentionedIds?.includes(id)) {
                    return {
                      ...prevDetails,
                      mentionedIds: [...prevDetails?.mentionedIds, id],
                    };
                  }
                  return prevDetails;
                });
              }}
              renderSuggestion={(suggestion: any) => (
                <div
                  style={{
                    paddingBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "34px",
                      height: "34px",
                      fontSize: "16px",
                      alignItems: "center",
                      textTransform: "capitalize",
                      fontWeight: "500",
                      background: "#F5F5F7",
                      border: "1px solid #EEEEF4",
                      color: "#667085",
                    }}
                  >
                    {/* <Box>{suggestion.firstName.charAt(0)}</Box> */}
                    <Box>{suggestion?.display?.charAt(0)}</Box>
                  </Avatar>
                  {suggestion?.display}
                  {/* {suggestion.firstName} {suggestion.lastName} */}
                </div>
              )}
            />
          </MentionsInput>
        </div>

        <div className={styles.activityArea}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div className={styles.cancelSave}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancelComment}
                sx={{
                  textTransform: "capitalize",
                  color: "#9A9EA6",
                  border: "1px solid #E3E3E3",
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  marginLeft: 1,
                  textTransform: "capitalize",
                }}
                disabled={
                  (!fromCommentEdit && createCommentLoading) ||
                  (fromCommentEdit && updateCommentLoading) ||
                  noteDetails?.description === ""
                }
              >
                {(!fromCommentEdit && createCommentLoading) ||
                (fromCommentEdit && updateCommentLoading) ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddComment;
