import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import styles from "./Home.module.css";
import { logout } from "../../auth/signout/service/signout";
import { useLocation, useNavigate } from "react-router-dom";
import { resetState } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import ProfileMenuPopover from "./components/ProfileMenuPopover";
import { LogoutRequest } from "../../auth/signin/service/signin.request";
import { useSignOut, useAuthUser } from "react-auth-kit";
import NotificationPopover from "./components/NotificatonPopover/NotificationPopover";
import { getNotificationsByPagination } from "../Manage/Notification/service/Notificationslice";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import OzonetelWidget from "../Integrations/Ozonetel/OzonetelWidget";
import PcfRandom from "../../../components/PcfFormRandom/PcfRandom";
import PcfLead from "../../../components/PcfFormLead/PcfLead";
import ToastNotification from "../../../components/ToastNotification/ToastNotification";
import ActionableNotification from "../../../components/ToastNotification/ActionableNotification";
import {
  getInoxByLeadId,
  getLeadActivityList,
  getLeadListByInstituteAndContact,
  getLeadNoteList,
  getLeadPaymentList,
  getLeadTaskList,
  getNurtureLeadBoard,
  resetCommentStatuses,
  resetLeadError,
  resetLeadStatusUpdateError,
  resetNoteStatuses,
  resetSourceStatuses,
  resetTaskStatuses,
  setLeadModalState,
} from "../Nurture/service/nurtureSlice";
import { getBatchList } from "../Manage/ManageBatch/service/Batchslice";
import {
  getAllTasksType,
  gettasksByAssignee,
  resetTaskErrors,
  resetTaskUpdateSuccess,
} from "../Tasks/service/tasksSlice";
import {
  getUserById,
  getUserRoleById,
} from "../Manage/UserDetails/service/userDetailsSlice";
import { getSubDomainAccessStatus } from "../../../components/RolePermissionAccess/RolePermissionAccess";
import { closeSocket, initSocket } from "../../../api/socket";
import { getBranchList } from "../Manage/ManageBranch/service/Branchslice";
import { getAllCourseByInstituteRequest } from "../Manage/ManageCourse/service/courseSlice";
import { getStaffList } from "../Manage/ManageStaff/service/Staffslice";
import { getAppsByInstitute } from "../Integrations/service/IntegrationsSlice";
import {
  getPaymentReminder,
  getPaymentReminderCount,
} from "../../../service/websocketNotificationsSlice";
import { getInstituteById } from "../Manage/InstituteProfile/service/instituteSlice";
import LogoutModal from "./components/Logout/LogoutModal";
import AddNewMenu from "./components/AddNewMenu/AddNewMenu";
import { getAddLeadFormForInstitute } from "../Settings/service/settingsSlice";
interface Props {
  handleDrawerToggle: () => void;
  name: string;
  loggedIn: boolean;
}

export default function AppHeader(props: any) {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const location = useLocation();

  const userData = useSelector((state: any) => state.userDetails?.userById?.id);

  const paymentReminders = useSelector(
    (state: any) => state?.websocketNotifications?.paymentReminderList
  );
  const clearPaymentReminderSuccess = useSelector(
    (state: any) => state?.websocketNotifications?.clearPaymentReminderSuccess
  );
  const { paymentReminderCount } = useSelector(
    (state: any) => state?.websocketNotifications
  );
  const markAsRead = useSelector(
    (state: any) => state.notification.markAsReadSuccess
  );

  const markAllAsRead = useSelector(
    (state: any) => state.notification.markAllAsReadSuccess
  );
  const notifications = useSelector(
    (state: any) => state.notification.notifications
  );
  const { taskReminders } = useSelector(
    (state: any) => state.websocketNotifications
  );
  const {
    leadListLoading,
    leadBoard,
    createNoteSuccess,
    createTaskSuccess,
    leadCreateError,
    createSourceError,
    createSourceSuccess,
    createCommentSuccess,
    createCommentError,
    deleteCommentSuccess,
    deleteCommentError,
    updateCommentSuccess,
    updateCommentError,
    leadStatusUpdateError,
  } = useSelector((state: any) => state.nurture);
  const { taskStatusUpdateSuccess, taskStatusUpdateError } = useSelector(
    (state: any) => state.tasks
  );
  const employees = useSelector((state: any) => state.staff.staffList);

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const profileCompletedStatus = instituteData?.isProfileCompleted;

  const userFromLocalStorage = authUser;
  const notificationIconRef = useRef<any>(null);
  const hasOzontel =
    instituteData &&
    Array.isArray(instituteData.integrationsOpted) &&
    instituteData?.integrationsOpted?.includes("OZONETEL");

  const showBadgeIcon = notifications?.returnData?.filter((d: any) => {
    return d?.readStatus === "UNREAD";
  });

  const ozonetelEmployee = employees?.find(
    (item: any) => item.employee === authUser?.institute[0]?.employee
  );
  const hasOzonetelConfig = ozonetelEmployee?.ozonetelConfig !== undefined;

  const [isUserprofileMenuOpen, setIsUserprofileMenuOpen] =
    React.useState<boolean>(false);
  const [openLogoutModal, setOpenLogoutModal] = React.useState<boolean>(false);
  const [openNotificationPopup, setOpenNotificationPopup] =
    React.useState<boolean>(false);

  const handleLogout = () => {
    closeSocket();
    if (authUser) {
      LogoutRequest({ email: authUser.email });
    }
    const importString: any = localStorage?.getItem("ImportedContacts");
    if (importString) {
      localStorage?.setItem("ImportedContacts", "");
    }
    logout();
    signOut();
    dispatch(resetState());
    navigate("/");
  };

  const handleLogoutModalOpen = () => setOpenLogoutModal(true);
  const handleLogoutModalClose = () => setOpenLogoutModal(false);

  const handleNotificationIconClick = () => {
    setOpenNotificationPopup(!openNotificationPopup);
  };

  const handleGlobalClick = (event: any) => {
    if (
      notificationIconRef.current &&
      !notificationIconRef.current.contains(event.target)
    ) {
      setOpenNotificationPopup(false);
    }
  };

  const leadPopupApiCall = React.useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          dispatch<any>(getLeadListByInstituteAndContact(data));
        }
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener("click", handleGlobalClick);

    return () => {
      window.removeEventListener("click", handleGlobalClick);
    };
  }, []);

  React.useEffect(() => {
    if (createNoteSuccess !== true && createNoteSuccess !== false) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const leadId = urlSearchParams.get("leadId");
      if (leadId) {
        dispatch<any>(getLeadNoteList(leadId));
      }
      dispatch(resetNoteStatuses());
    }
  }, [createNoteSuccess]);

  React.useEffect(() => {
    if (createTaskSuccess !== true && createTaskSuccess !== false) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const leadId = urlSearchParams.get("leadId");
      if (leadId) {
        dispatch<any>(getLeadTaskList(leadId));
      }
      dispatch(resetTaskStatuses());
    }
  }, [createTaskSuccess]);

  React.useEffect(() => {
    if (leadCreateError !== true && leadCreateError !== false) {
      dispatch(resetLeadError());
    }
  }, [leadCreateError]);

  React.useEffect(() => {
    if (createSourceError !== true && createSourceError !== false) {
      dispatch(resetSourceStatuses());
    }
  }, [createSourceError]);

  React.useEffect(() => {
    if (createSourceSuccess !== true && createSourceSuccess !== false) {
      if (instituteData?.id)
        dispatch<any>(getNurtureLeadBoard(instituteData?.id));
    }
    dispatch(resetSourceStatuses());
  }, [createSourceSuccess]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const leadId = urlSearchParams.get("leadId");
  React.useEffect(() => {
    if (
      (createCommentSuccess !== true && createCommentSuccess !== false) ||
      (deleteCommentSuccess !== true && deleteCommentSuccess !== false) ||
      (updateCommentSuccess !== true && updateCommentSuccess !== false)
    ) {
      if (leadId) {
        dispatch<any>(getLeadTaskList(leadId));
      }
      dispatch(resetCommentStatuses());
    }
  }, [createCommentSuccess, deleteCommentSuccess, updateCommentSuccess]);

  React.useEffect(() => {
    if (
      (createCommentError !== true && createCommentError !== false) ||
      (deleteCommentError !== true && deleteCommentError !== false) ||
      (updateCommentError !== true && updateCommentError !== false)
    ) {
      dispatch(resetCommentStatuses());
    }
  }, [createCommentError, deleteCommentError, updateCommentError]);

  React.useEffect(() => {
    if (taskStatusUpdateSuccess !== true && taskStatusUpdateSuccess !== false) {
      const urlContainsTasks = window.location.href.includes("Tasks");
      if (urlContainsTasks) {
        dispatch<any>(gettasksByAssignee(authUser?.institute?.[0]?.id));
      }

      dispatch(resetTaskUpdateSuccess());
    }
  }, [taskStatusUpdateSuccess]);

  React.useEffect(() => {
    if (taskStatusUpdateError) {
      dispatch(resetTaskErrors());
    }
  }, [taskStatusUpdateError]);

  React.useEffect(() => {
    if (leadStatusUpdateError !== true && leadStatusUpdateError !== false) {
      dispatch(resetLeadStatusUpdateError());
    }
  }, [leadStatusUpdateError]);

  useEffect(() => {
    if (instituteData?.id) {
      const userId = authUser?.institute?.[0]?.id;
      // const instituteId = authUser?.institute?.[0]?.instituteId;
      const socket = initSocket(userId, instituteData?.id);

      return () => {
        socket.close();
      };
    }
  }, [authUser]);

  // React.useEffect(() => {
  //   if (authUser) {
  //     dispatch<any>(getUserById(authUser?.institute?.[0]?.id));
  //     dispatch<any>(getUserRoleById(authUser?.institute?.[0]?.roleId));
  //   }
  // }, []);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     if (authUser) {
  //       await dispatch<any>(getUserById(authUser?.institute?.[0]?.id));

  //       await dispatch<any>(getUserRoleById(authUser?.institute?.[0]?.roleId));
  //     }
  //   };

  //   fetchData();
  // }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (authUser) {
        await dispatch<any>(getUserById(authUser?.institute?.[0]?.id));
        // await dispatch<any>(getUserRoleById(authUser?.institute?.[0]?.roleId));
      }

      if (
        authUser?.institute[0] &&
        instituteData?.id &&
        instituteData?.isProfileCompleted
      ) {
        // First set of API calls
        await dispatch<any>(getInstituteById(instituteData?.id));
        await dispatch<any>(getBranchList(instituteData?.id));

        // Wait 3 seconds before calling staff and nurture APIs
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await dispatch<any>(getStaffList(instituteData.id));
        await dispatch<any>(getNurtureLeadBoard(instituteData.id));

        // Wait 5 more seconds before calling course and batch APIs
        await new Promise((resolve) => setTimeout(resolve, 5000));
        if (!location.pathname.endsWith("/Courses")) {
          await dispatch<any>(
            getAllCourseByInstituteRequest({
              id: instituteData.id,
            })
          );
        }

        if (!location.pathname.includes("Nurture")) {
          await dispatch<any>(
            getBatchList({
              id: instituteData.id,
              batchConductType: "ALL",
            })
          );
        }
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (
        authUser?.institute[0] &&
        instituteData?.id &&
        instituteData?.isProfileCompleted &&
        userData
        // &&
        // instituteData?.addLeadFormEnabled
      ) {
        await dispatch<any>(getAddLeadFormForInstitute(instituteData.id));
      }
    };

    fetchData();
  }, [userData]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (
        authUser?.institute[0] &&
        instituteData?.id &&
        instituteData?.isProfileCompleted &&
        userData
      ) {
        const userId = userData;

        // Wait 10 seconds before starting the API calls
        await new Promise((resolve) => setTimeout(resolve, 14000));

        await dispatch<any>(
          getNotificationsByPagination({
            id: userId,
            lastEvaluatedKey: "",
            limit: 10,
            lastEvaluatedSortKey: "",
          })
        );
        await dispatch<any>(
          getPaymentReminderCount({
            userId: userId,
          })
        );

        if (hasOzontel) {
          await dispatch<any>(getAppsByInstitute(instituteData?.id));
        }
      }
    };

    fetchData();
  }, [userData]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (
        authUser?.institute[0] &&
        instituteData?.id &&
        instituteData?.isProfileCompleted &&
        userData &&
        paymentReminderCount?.count > 0
      ) {
        const userId = userData;

        await dispatch<any>(
          getPaymentReminder({
            userId: userId,
            lastEvaluatedKey: "",
            limit: 2,
            lastEvaluatedSortKey: "",
          })
        );
      }
    };

    fetchData();
  }, [userData, paymentReminderCount]);

  // React.useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const leadId = urlSearchParams.get("leadId");
  //   const modalDisplay = urlSearchParams.get("leadModal");
  //   const modalTabOpen = urlSearchParams.get("tabOpen");

  //   if (modalDisplay === "true") {
  //     if (modalTabOpen && leadId) {
  //       if (modalTabOpen === "activity") {
  //         getLeadActivityList(leadId);
  //       } else if (modalTabOpen === "notes") {
  //         getLeadNoteList(leadId);
  //       } else if (modalTabOpen === "task") {
  //         getLeadTaskList(leadId);
  //       } else if (modalTabOpen === "inbox") {
  //         getInoxByLeadId(leadId);
  //       } else if (modalTabOpen === "payment") {
  //         getLeadPaymentList(leadId);
  //       }
  //     }
  //   }
  // }, [props?.setLeadExpandOpen]);

  // React.useEffect(() => {
  //   if (
  //     authUser?.institute[0] &&
  //     instituteData?.id &&
  //     instituteData?.isProfileCompleted
  //   ) {
  //     dispatch<any>(getInstituteById(instituteData?.id));
  //     dispatch<any>(getBranchList(instituteData?.id));
  //     const staffAndNurtureTimeout = setTimeout(() => {
  //       dispatch<any>(getStaffList(instituteData.id));
  //       dispatch<any>(getNurtureLeadBoard(instituteData.id));
  //     }, 3000);

  //     const courseAndBatchTimeout = setTimeout(() => {
  //       dispatch<any>(
  //         getAllCourseByInstituteRequest({
  //           id: instituteData.id,
  //         })
  //       );

  //       if (!location.pathname.includes("Nurture")) {
  //         dispatch<any>(
  //           getBatchList({
  //             id: instituteData.id,
  //             batchConductType: "ALL",
  //           })
  //         );
  //       }
  //     }, 5000);

  //     return () => {
  //       clearTimeout(staffAndNurtureTimeout);
  //       clearTimeout(courseAndBatchTimeout);
  //     };
  //   }
  // }, []);

  // React.useEffect(() => {
  //   if (
  //     authUser?.institute[0] &&
  //     instituteData?.id &&
  //     instituteData?.isProfileCompleted &&
  //     userData
  //   ) {
  //     const userId = userData;

  //     const notificationsTimeout = setTimeout(() => {
  //       dispatch<any>(
  //         getNotificationsByPagination({
  //           id: userId,
  //           lastEvaluatedKey: "",
  //           limit: 10,
  //           lastEvaluatedSortKey: "",
  //         })
  //       );
  //     }, 6000);

  //     const paymentReminderTimeout = setTimeout(() => {
  //       dispatch<any>(
  //         getPaymentReminder({
  //           userId: userId,
  //           lastEvaluatedKey: "",
  //           limit: 2,
  //           lastEvaluatedSortKey: "",
  //         })
  //       );
  //       if (hasOzontel) {
  //         dispatch<any>(getAppsByInstitute(instituteData?.id));
  //       }
  //     }, 8000);

  //     return () => {
  //       clearTimeout(notificationsTimeout);
  //       clearTimeout(paymentReminderTimeout);
  //     };
  //   }
  // }, [userData]);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const userFromLocalStorage = authUser?.institute[0];
  //     if (userFromLocalStorage) {
  //       const instituteId = userFromLocalStorage?.instituteId;

  //       await dispatch<any>(getInstituteById(instituteId));
  //       await dispatch<any>(getBranchList(instituteId));
  //       await dispatch<any>(getStaffList(instituteId));
  //       await dispatch<any>(getNurtureLeadBoard(instituteId));
  //       await dispatch<any>(getAllCourseByInstituteRequest(instituteId));
  //       await dispatch<any>(
  //         getBatchList({
  //           id: instituteId,
  //           batchConductType: "ALL",
  //         })
  //       );
  //       await dispatch<any>(getAppsByInstitute(instituteId));
  //       await dispatch<any>(getAllTasksType(instituteId));
  //     }
  //   };

  //   fetchData();
  // }, []);

  React.useEffect(() => {
    if (
      authUser?.institute[0] &&
      instituteData?.id &&
      instituteData?.isProfileCompleted &&
      userData &&
      (markAllAsRead === true || markAsRead === true)
    ) {
      const userId = userData;
      dispatch<any>(
        getNotificationsByPagination({
          id: userId,
          lastEvaluatedKey: "",
          limit: 10,
          lastEvaluatedSortKey: "",
        })
      );
    }
  }, [userData, markAsRead, markAllAsRead]);

  React.useEffect(() => {
    if (
      authUser?.institute[0] &&
      instituteData?.id &&
      instituteData?.isProfileCompleted &&
      userData &&
      clearPaymentReminderSuccess === true
    ) {
      const userId = userData;
      dispatch<any>(
        getPaymentReminder({
          userId: userId,
          lastEvaluatedKey: "",
          limit: 2,
          lastEvaluatedSortKey: "",
        })
      );
      // dispatch<any>(
      //   getPaymentReminderCount({
      //     userId: userId,
      //   })
      // );
    }
  }, [userData, clearPaymentReminderSuccess]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const modalDisplay = urlSearchParams.get("leadModal");
    const leadInstituteId = urlSearchParams.get("leadInstituteId");
    const leadPhone = urlSearchParams.get("leadPhone");
    const leadEmail = urlSearchParams.get("leadEmail");

    let apiCallMade = false;

    if (modalDisplay === "true" && leadListLoading === false) {
      dispatch(setLeadModalState());
      if (leadInstituteId) {
        if (leadPhone || leadEmail) {
          if (!apiCallMade) {
            leadPopupApiCall(leadInstituteId, leadPhone, leadEmail);
            apiCallMade = true;
          }
        }
      }
    }
  }, [leadPopupApiCall]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {hasOzontel && hasOzonetelConfig && (
        <OzonetelWidget
          url="https://in-ccaas.ozonetel.com/toolbar_widget/index.html#login"
          buttonText="Open Ozonetel Widget"
        />
      )}
      <ToastNotification />
      {(taskReminders?.length > 0 || paymentReminders?.emiList?.length > 0) && (
        <ActionableNotification />
      )}

      {/* <PcfRandom />
      <PcfLead /> */}

      <AppBar
        position="static"
        elevation={0}
        sx={{ borderBottom: "1px solid #EBEBF0" }}
      >
        <Toolbar className={styles.navBar}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          {profileCompletedStatus && <AddNewMenu />}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {profileCompletedStatus && (
              <IconButton
                onClick={handleNotificationIconClick}
                size="large"
                aria-label="show 17 new notifications"
                ref={notificationIconRef}
              >
                {showBadgeIcon?.length > 0 ? (
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    color="primary"
                    overlap="circular"
                    badgeContent=" "
                    variant="dot"
                  >
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )}
              </IconButton>
            )}
            {openNotificationPopup && (
              <NotificationPopover
                setOpenNotificationPopup={setOpenNotificationPopup}
              />
            )}

            <ProfileMenuPopover
              setIsUserprofileMenuOpen={setIsUserprofileMenuOpen}
              isUserprofileMenuOpen={isUserprofileMenuOpen}
              handleLogoutModalOpen={handleLogoutModalOpen}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <LogoutModal
        open={openLogoutModal}
        onClosePopup={handleLogoutModalClose}
        logoutCall={handleLogout}
      />
    </Box>
  );
}
