import React, { useState } from "react";
import styles from "./RemovePopup.module.css";
import {
  Box,
  Modal,
  Button,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  InputLabel,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserAssignField from "../../../../../../../components/UserAssign/UserAssignField";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function RemoveBranchPopup(props: any) {
  const {
    open,
    onClose,
    removeButtonClick,
    branches,
    staffs,
    setRemoveAction,
    removeAction,
    branchEditSelect,
    removeBranchData,
    handleBranchLeadAssignee,
    handleRemoveBranchLicenseCheck,
    removeBranchDetails,
    setRemoveBranchDetails,
    removeBranchError,
    userBranchError,
    branchAssignyError,
    branchRemovalLoading,
  } = props;
  // const [removeAction, setRemoveAction] = useState<string>("");
  const [removeLicense, setRemoveLicense] = useState(false);

  const filteredStaffs =
    removeBranchData &&
    staffs
      ?.filter((staff: any) => staff.branchId !== removeBranchData?.id)
      ?.filter((user: any) => {
        return user?.leadCardAccess === true;
      })
      ?.filter((item: any) => item?.status === "ACTIVE");

  const filteredBranches =
    removeBranchData &&
    branches?.filter((item: any) => item.id !== removeBranchData?.id);

  const removeActionSelect = React.useCallback(
    (event: any) => {
      setRemoveAction(event.target.value);
      setRemoveBranchDetails({
        ...removeBranchDetails,
        branchId: null,
        branchName: null,
        assigedToId: "",
        assignedToName: "",
      });
    },
    [removeAction, removeBranchDetails]
  );

  const defaultOption = branches
    ? branches?.find(
        (branch: any) => branch.id === removeBranchDetails?.branchId
      )
    : "";

  const onBranchSelectChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setRemoveBranchDetails({
          ...removeBranchDetails,
          branchId: value.id,
          branchName: value.branchName,
          assigedToId: "",
          assignedToName: "",
        });

        // setValidStateBranch(true);
      } else {
        setRemoveBranchDetails({
          ...removeBranchDetails,
          branchId: null,
          branchName: null,
          assigedToId: "",
          assignedToName: "",
        });
        // setValidStateBranch(false);
      }
    },
    [removeBranchDetails]
  );

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {branchEditSelect && (
            <div>
              <div className={styles.closeModalBtnWrap}>
                <button onClick={onClose} className={styles.closeModalBtn}>
                  <CloseIcon />
                </button>
              </div>

              {removeBranchData?.batchCount &&
              removeBranchData?.batchCount > 0 ? (
                <>
                  <div className={styles.closeModalTitle}>
                    There
                    {removeBranchData?.batchCount &&
                    removeBranchData?.batchCount === 1
                      ? " is "
                      : removeBranchData?.batchCount > 1
                      ? " are "
                      : " are "}
                    {removeBranchData?.batchCount &&
                      removeBranchData?.batchCount}{" "}
                    ongoing
                    {removeBranchData?.batchCount &&
                    removeBranchData?.batchCount === 1
                      ? " batch "
                      : removeBranchData?.batchCount > 1
                      ? " batches "
                      : " batches "}
                    in the branch “{removeBranchData.branchName}
                    ”.
                  </div>
                  {removeBranchData?.batchCount &&
                    removeBranchData?.batchCount > 0 && (
                      <div className={styles.closeModalDesRed}>
                        The branch cannot be removed until the batches are
                        completed. Please contact us for assistance.
                      </div>
                    )}
                  <div className={styles.buttonSectionOk}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "capitalize" }}
                      onClick={onClose}
                    >
                      ok
                    </Button>
                  </div>
                </>
              ) : removeBranchData?.upcomingBatchLeadCount &&
                removeBranchData?.upcomingBatchLeadCount > 0 ? (
                <>
                  <div className={styles.closeModalTitle}>
                    There
                    {removeBranchData?.upcomingBatchLeadCount &&
                    removeBranchData?.upcomingBatchLeadCount === 1
                      ? " is "
                      : removeBranchData?.upcomingBatchLeadCount > 1
                      ? " are "
                      : " are "}
                    {removeBranchData?.upcomingBatchLeadCount &&
                      removeBranchData?.upcomingBatchLeadCount}{" "}
                    {removeBranchData?.upcomingBatchLeadCount &&
                    removeBranchData?.upcomingBatchLeadCount === 1
                      ? " lead "
                      : removeBranchData?.upcomingBatchLeadCount > 1
                      ? " leads "
                      : " leads "}
                    added to an upcoming{" "}
                    {removeBranchData?.upcomingBatchCount &&
                    removeBranchData?.upcomingBatchCount === 1
                      ? " batch "
                      : removeBranchData?.upcomingBatchCount > 1
                      ? " batches "
                      : " batches "}
                    in the branch “{removeBranchData.branchName}
                    ”.
                  </div>
                  {removeBranchData &&
                    removeBranchData?.upcomingBatchCount > 0 && (
                      <div className={styles.closeModalDesRed}>
                        The branch cannot be deactivated until the leads are
                        assigned to another batch.
                      </div>
                    )}
                  <div className={styles.buttonSectionOk}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ textTransform: "capitalize" }}
                      onClick={onClose}
                    >
                      ok
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.closeModalTitle}>
                    Are you sure you want to Remove the Branch “
                    {removeBranchData?.branchName}”?
                  </div>

                  {removeBranchData && removeBranchData?.teamCount > 0 && (
                    <>
                      <div className={styles.closeModalDesStatus}>
                        There are {removeBranchData?.teamCount} active users in
                        this branch.
                      </div>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="formControl"
                        size="small"
                        sx={{ marginBottom: "9px" }}
                      >
                        <InputLabel
                          id="remove-action-label"
                          sx={{ background: "#fff", paddingRight: "5px" }}
                        >
                          Choose an action to continue
                        </InputLabel>
                        <Select
                          labelId="remove-action-label"
                          id="remove-action"
                          value={removeAction}
                          label="Choose an action to continue"
                          onChange={removeActionSelect}
                          sx={{
                            color:
                              removeAction === "Remove all users"
                                ? "#ec3939"
                                : "#142c44",
                            fontSize: "14px",
                          }}
                        >
                          <MenuItem
                            className={styles.removeActionItem}
                            value={"Move to another branch"}
                            sx={{ fontSize: "14px", color: "#142c44" }}
                          >
                            Move to another branch
                          </MenuItem>
                          <MenuItem
                            className={styles.removeUserItem}
                            value={"Remove all users"}
                            sx={{ fontSize: "14px", color: "#ec3939" }}
                          >
                            Remove all users
                          </MenuItem>
                        </Select>
                      </FormControl>

                      {removeAction === "Move to another branch" ? (
                        <div style={{ marginBottom: "11PX" }}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            className="formControl"
                            size="small"
                          >
                            {branches && filteredBranches && (
                              <Autocomplete
                                disablePortal
                                id="branchId"
                                options={filteredBranches}
                                getOptionLabel={(option: any) =>
                                  option?.branchName || ""
                                }
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: option.selected
                                          ? "bold"
                                          : "normal",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {option.branchName}
                                    </Typography>
                                  </li>
                                )}
                                sx={{
                                  "& .MuiInputBase-input": {
                                    textTransform: "capitalize",
                                    color: "#142C44",
                                    fontSize: "14px",
                                  },
                                }}
                                fullWidth
                                onChange={onBranchSelectChange}
                                size="small"
                                value={defaultOption ? defaultOption : null}
                                renderInput={(params) => {
                                  if (!defaultOption) {
                                    params.InputProps.endAdornment = null;
                                  }
                                  return (
                                    <TextField
                                      label="Select Branch"
                                      variant="outlined"
                                      {...params}
                                      sx={{
                                        color: "#142C44",
                                        fontSize: "14px",
                                        textTransform: "capitalize",
                                      }}
                                    />
                                  );
                                }}
                              />
                            )}
                          </FormControl>
                        </div>
                      ) : removeAction === "Remove all users" ? (
                        <div style={{ marginBottom: "11PX" }}>
                          <div className={styles.leadwithCurrentUsers}>
                            {removeBranchData?.leadCount > 0 ? (
                              <>
                                <>
                                  There are {removeBranchData?.leadCount} leads
                                  assigned to these users. Reassign them before
                                  removing the users.
                                </>
                                <UserAssignField
                                  staffs={filteredStaffs && filteredStaffs}
                                  selectedAssignyId={
                                    branchEditSelect &&
                                    branchEditSelect?.licensedEntityId
                                  }
                                  handleLeadAssignee={handleBranchLeadAssignee}
                                />
                              </>
                            ) : (
                              <>The users will be removed along with branch.</>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {branchAssignyError ? (
                    <FormHelperText error sx={{ marginBottom: "4px" }}>
                      Please assign lead or task to an user.
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                  {userBranchError ? (
                    <FormHelperText error sx={{ marginBottom: "4px" }}>
                      Please assign users to another branch.
                    </FormHelperText>
                  ) : (
                    <></>
                  )}
                  {removeBranchError ? (
                    <FormHelperText error sx={{ marginBottom: "4px" }}>
                      Please choose an action.
                    </FormHelperText>
                  ) : (
                    <></>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          stroke: 1,
                          color: " rgba(102, 112, 133, 0.30)",
                          "&.Mui-checked": {
                            color: "red",
                          },
                        }}
                        size="small"
                        checked={removeBranchDetails?.deleteLicense}
                        onChange={(event) =>
                          handleRemoveBranchLicenseCheck(event)
                        }
                      />
                    }
                    label={
                      <Typography
                        style={{ color: "#667085", fontSize: "14px" }}
                      >
                        Delete Branch License too
                      </Typography>
                    }
                  />

                  <div className={styles.buttonSection}>
                    <button onClick={onClose} className={styles.cancelBtn}>
                      cancel
                    </button>
                    <button
                      onClick={removeButtonClick}
                      className={styles.removeBtn}
                      disabled={branchRemovalLoading}
                    >
                      {/* remove */}
                      {branchRemovalLoading ? (
                        <CircularProgress size={20} color={"inherit"} />
                      ) : (
                        "remove"
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default RemoveBranchPopup;
