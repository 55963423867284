import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import note from "./assets/note.svg";
import ActivityNote from "./ActivityNote";

function NoteList(props: any) {
  const {
    data,
    employees,
    showAddNote,
    setShowAddNote,
    DeleteNote,
    selectedLeadData,
    setFromNotesEdit,
    setNotesForEditing,
  } = props;

  return (
    <Box sx={{ width: "100%", p: 0, bgcolor: "white" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            paddingLeft: 2,
          }}
        >
          <Box
            sx={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              border: "1px solid #F0F0F0",
              textAlign: "center",
              backgroundColor: "#FFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <div className={styles.activitySideIcon}>
              <img src={note} alt="note" />
            </div>
          </Box>
        </Box>

        <ActivityNote
          data={data}
          activityList={false}
          employees={employees}
          showAddNote={showAddNote}
          setShowAddNote={setShowAddNote}
          DeleteNote={DeleteNote}
          selectedLeadData={selectedLeadData}
          setFromNotesEdit={setFromNotesEdit}
          setNotesForEditing={setNotesForEditing}
          isNoteTab={true}
        />
      </Box>
    </Box>
  );
}

export default NoteList;
