import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

interface modalProps {
  body?: any;
  isModalOpen?: any;
  setIsModalOpen?: any;
  width: any;
}

export default function BasicModal(props: modalProps) {
  const { body, isModalOpen, setIsModalOpen, width } = props;
  const handleClose = () => setIsModalOpen(false);

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{borderRadius:"8px"}}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width,
            bgcolor: "secondary.contrastText",
            border: "1px solid #DCDCDC",
            boxShadow: 24,
            p: 3,
          }}
        >
          {body}
        </Box>
      </Modal>
    </div>
  );
}
