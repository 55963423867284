import React from "react";
import { Dialog } from "@mui/material";
import styles from "./OzonetelDetails.module.css";
import npay from "../Assets/npaysucces.svg";
import ozonepay from "../Assets/ozonesuccess.svg";
import lines from "../Assets/connectinglines.gif";

function ConnectSuccessPopup(props: any) {
  const { isOpen, onClose } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        sx={{
          zIndex: "99999",
          minWidth: "400px",
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "408px",
            maxHeight: "280px",
            minHeight: "179px",
            padding: "24px",
            borderRadius: "12px",
            boxShadow:
              "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
            left: "5%",
            top: "4%",
            background: "#FFFFFF",
            overflow: "hidden",
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className={styles.connectSuccessPopupImgs}>
            <div className={styles.connectSuccessPopupImg}>
              <img src={npay} alt="npay" />
            </div>
            <div
              className={styles.connectSuccessPopupImg}
              style={{ width: "100px", height: "50px", marginBottom: "25PX" }}
            >
              <img src={lines} alt="lines" />
            </div>
            <div className={styles.connectSuccessPopupImg}>
              <img src={ozonepay} alt="ozonepay" />
            </div>
          </div>
          <div className={styles.connectSuccessPopupHeading}>
            Authentication Successful!
          </div>
          <div className={styles.connectSuccessPopupDesc}>
            Congratulations! Your Ozonetel account is now connected to nurture.
            Proceed to map Agents to activate calling feature within nurture.
          </div>

          <button className={styles.connectSuccessPopupOkBtn} onClick={onClose}>
            ok
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default ConnectSuccessPopup;
