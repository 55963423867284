import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import styles from "./FlagAssign.module.css";
import defaultFlag from "./assets/flag-default.svg";
import highFlagIcon from "./assets/High.svg";
import urgentFlagIcon from "./assets/Urgent.svg";
import normalFlagIcon from "./assets/Normal.svg";
import lowFlagIcon from "./assets/Low.svg";

interface Flag {
  id: string;
  priority: string;
  icon: any;
  color: string;
}

const flags = [
  {
    id: "ad14",
    priority: "URGENT",
    icon: urgentFlagIcon,
    color: "#D32F2F",
  },
  {
    id: "ad16",
    priority: "HIGH",
    icon: highFlagIcon,
    color: "#FFB41F",
  },
  {
    id: "ad17",
    priority: "NORMAL",
    icon: normalFlagIcon,
    color: "#1A73E8",
  },
  {
    id: "ad18",
    priority: "LOW",
    icon: lowFlagIcon,
    color: "#B1B1B1",
  },
];

export default function FlagAssign(props: any) {
  const {
    isForEnquiryModal,
    disabled,
    enquiryPriority,
    selectedPriority,
    handleLeadPriority,
    leadId,
    size,
    isForDispositionForm,
    // isAddEnquiry,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState<any>("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSelect = React.useCallback(
    (flag: Flag) => {
      handleLeadPriority(leadId, flag?.priority);
      setSelectedFlag(flag);
    },

    [setSelectedFlag, enquiryPriority, handleLeadPriority, setIsOpen]
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (selectedPriority !== null && selectedPriority !== undefined) {
      const defaultPriority = flags.find(
        (priority: any) => priority.priority === selectedPriority
      );
      setSelectedFlag(defaultPriority);
    }
  }, [setSelectedFlag, selectedPriority]);

  // useEffect(() => {
  //   if (isAddEnquiry && value === "") {
  //     let temp = flags.find((flag: any) => flag.priority === value);
  //     setSelectedFlag(temp);
  //   }
  // }, [value]);

  return (
    <div className={styles.userAssignWrap} ref={wrapperRef}>
      <IconButton
        className={styles.flagAssignBtn}
        onClick={toggleDropdown}
        disabled={disabled ? true : false}
        sx={{
          minWidth: "30px",
          borderRadius: "50%",
          background: selectedFlag === "" ? "fff" : "#f5f5f7",
          border: selectedFlag === "" ? "1px solid #fff" : "1px solid #eeeef4",
          width: size === "large" ? "42px" : size === "xl" ? "44px" : "30px",
          height: size === "large" ? "42px" : size === "xl" ? "44px" : "30px",
        }}
        style={{ background: isHovered ? "#ECECEC" : "#F5F5F7" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {selectedFlag !== "" && selectedFlag !== undefined ? (
          <div
            className={styles.flagAssignIcon}
            style={{
              color: `${selectedFlag?.color}`,
            }}
          >
            <img
              src={selectedFlag?.icon}
              alt=""
              style={{
                marginLeft: "3px",
              }}
            />
          </div>
        ) : (
          <img src={defaultFlag} alt="" />
        )}
      </IconButton>

      {isOpen && (
        <ul
          className={
            isForEnquiryModal
              ? styles.isForEnquirymodal
              : isForDispositionForm
              ? styles.isForDispositionForm
              : styles.flagAssignPopup
          }
        >
          {flags?.map((flag) => (
            <div key={flag.id}>
              <li
                onClick={() => {
                  handleSelect(flag);
                  setIsOpen(!isOpen);
                }}
                className={styles.flagAssignItem}
              >
                <div
                  className={styles.flagAssignIcon}
                  style={{ color: `${flag.color}` }}
                >
                  <img src={flag.icon} alt="" />
                </div>
                <div className={styles.flagAssignName}>
                  {flag.priority?.toLowerCase()}
                </div>
              </li>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
}
