import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import { getReports, reportSlice, viewReports } from "../service/reportsSlice";
import { ReportsInitialStateDTO } from "../service/types";
import ReportTable from "../components/RecentReport/ReportTable";

interface StateProps extends ReportsInitialStateDTO {}
interface DispatchProps {
  GetReports(body: any): void;
  ViewReports(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.reports.success,
    error: state.reports.error,
    loading: state.reports.loading,
    reportData: state.reports.reportData,
    viewReportSuccess: state.reports.viewReportSuccess,
    viewReportLoading: state.reports.viewReportLoading,
    viewReportError: state.reports.viewReportError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetReports: (body: any) => {
      dispatch(getReports(body));
    },
    ViewReports: (id: string) => {
      dispatch(viewReports(id));
    },
  };
};

export interface RecentReportsProps extends StateProps, DispatchProps {}
export const RecentReportComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportTable);
