import React, { useEffect, useState } from "react";
import styles from "./CustomizedField.module.css";
import {
  Box,
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import deleteImage from "./Images/delete.svg";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteQuestionPopup from "../DeleteQuestionPopup/DeleteQuestionPopup";
import { useAuthUser } from "react-auth-kit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

function CustomizedDropdownField(props: any) {
  const {
    field,
    onFieldChange,
    onDelete,
    CreateWebformCustomField,
    isDropdownFieldFocused,
    setIsDropdownFieldFocused,
    leadsFromResponseChecked,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const auth = useAuthUser();
  const authUser = auth();
  const [saveForLater, setSaveForLater] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [mandatory, setMandatory] = useState(false);

  useEffect(() => {
    if (
      leadsFromResponseChecked &&
      (field?.DBModel === "COURSE" || field?.DBModel === "BRANCH")
    ) {
      setMandatory(true);
      onFieldChange({
        ...field,
        required: true,
      });
    } else {
      setMandatory(false);
      onFieldChange({
        ...field,
        required: false,
      });
    }
  }, [leadsFromResponseChecked]);

  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([
    {
      answerId: "a1",
      answer: "Answer 1",
    },
    {
      answerId: "a2",
      answer: "Answer 2",
    },
    {
      answerId: "a3",
      answer: "Answer 3",
    },
  ]);
  const [answerTexts, setAnswerTexts] = useState(answers.map(() => ""));

  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [isDropdown, setIsDropdown] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setQuestion("");
    setAnswerTexts([""]);
    onFieldChange({
      ...field,
      fieldName: field?.fieldName,
    });
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setIsDropdown(true);
    setIsSave(true);
    setAnswerTexts([""]);
    updateOptions(); // Update options when saving
  };

  const handleAddAnswer = () => {
    const newAnswerId = `${answers.length + 1}`;
    const newAnswer = `Answer ${answers.length + 1}`;

    setAnswers([...answers, { answerId: "", answer: "" }]);
  };

  const handleQuestionChange = (e: any) => {
    setQuestion(e.target.value);
    onFieldChange({
      ...field,
      fieldName: e.target.value,
      options: field?.options,
    });
  };

  const updateOptions = () => {
    let newOptions;
    if (
      field.DBModel === "COURSE" ||
      field.DBModel === "BATCH" ||
      field.DBModel === "BRANCH"
    ) {
      newOptions = answers.map((answer, index) => ({
        name: answerTexts[index] || answer.answer,
        id: answer.answerId,
      }));
    } else {
      newOptions = answers.map((answer, index) => ({
        name: answerTexts[index] || answer.answer,
        id: generateId(answerTexts[index] || answer.answer),
      }));
    }
    onFieldChange({
      ...field,
      options: field.DBModel === "BATCH" ? field?.options : newOptions,
    });
    const userFromLocalStorage = authUser?.institute[0];
    const data = {
      instituteId: userFromLocalStorage?.instituteId,
      status: "ACTIVE",
      field: {
        fieldType: field?.fieldType,
        fieldName: field?.fieldName,
        fieldPlaceholder: field?.fieldPlaceholder,
        options: newOptions,
        default: "",
        required: field?.required,
        index: 1,
        loadDatafromDB: false,
        DBModel: "",
        isCustom: true,
        isDeletable: true,
      },
      createdById: userFromLocalStorage?.id,
      createdByName:
        userFromLocalStorage?.firstName + " " + userFromLocalStorage?.lastName,
    };
    if (saveForLater === true) {
      CreateWebformCustomField(data);
    }
  };

  // Function to generate an ID from the given name
  const generateId = (name: any) => {
    // Remove spaces and convert to lowercase
    const withoutSpaces = name.replace(/\s+/g, "_").toLowerCase();
    return withoutSpaces;
  };

  const handleMandatoryChange = (e: any) => {
    const isMandatory = e.target.checked;
    setMandatory(isMandatory);
    onFieldChange({
      ...field,
      required: isMandatory,
    });
  };

  const handleDeleteConfirmed = () => {
    // Close the delete confirmation popup
    setShowDeletePopup(false);
    // Call the onDelete callback passed from the parent component
    onDelete(field);
  };

  const handleDeleteCancelled = () => {
    // Close the delete confirmation popup
    setShowDeletePopup(false);
  };

  const handleRemoveAnswerField = (index: any) => {
    const newAnswerTexts = [...answerTexts];
    newAnswerTexts.splice(index, 1); // Remove the text in the specified answer field
    setAnswerTexts(newAnswerTexts);

    const newAnswers = [...answers];
    newAnswers.splice(index, 1); // Remove the corresponding answer object
    setAnswers(newAnswers);
  };

  const handleSaveForLater = (e: any) => {
    setSaveForLater(e.target.checked);
  };

  React.useEffect(() => {
    setAnswers(
      field?.options?.map((d: any) => {
        return { answerId: d?.id, answer: d?.name };
      })
    );
    setQuestion(field?.fieldName);
  }, [field]);

  const capitalizeSentences = (text: string) => {
    let lowercasedText = text.toLowerCase();

    let capitalizedText = lowercasedText.replace(
      /(^\s*\w|[\.\!\?]\s*\w)/g,
      function (match) {
        return match.toUpperCase();
      }
    );

    return capitalizedText;
  };

  return (
    <Box
      className={styles.customizedFieldWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <div className={styles.label}>
          {capitalizeSentences(field.fieldName)}
          {mandatory === true && <span style={{ color: "red" }}>*</span>}
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <FiEdit
            size={16}
            color="#667085"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick}
          />

          <img
            src={deleteImage}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeletePopup(!showDeletePopup)}
          />
          {showDeletePopup && (
            <DeleteQuestionPopup
              onClose={handleDeleteCancelled}
              showDeletePopup={showDeletePopup}
              onConfirm={handleDeleteConfirmed}
            />
          )}
        </div>
      </div>

      {/* {isEditing || isSave ? ( */}
      <TextField
        placeholder="Write a question"
        disabled={!isEditing}
        fullWidth
        variant="outlined"
        size="small"
        value={question}
        onChange={handleQuestionChange}
        inputProps={{
          style: {
            padding: "8px 12px",
            backgroundColor: isEditing ? "#FFFFFF" : "#FAFAFA",
            borderRadius: "4px",
          },
        }}
        draggable={isDropdownFieldFocused === false}
        onFocus={() => {
          setIsDropdownFieldFocused(true);
        }}
        onBlur={() => {
          setIsDropdownFieldFocused(false);
        }}
      />
      {/* ) : null} */}
      {isEditing ? (
        <div>
          {answers.map((item, index) => (
            <TextField
              fullWidth
              key={item.answerId}
              placeholder={item.answer}
              variant="outlined"
              size="small"
              value={answerTexts[index]}
              onChange={(e) => {
                const newAnswerTexts = [...answerTexts];
                newAnswerTexts[index] = e.target.value;
                setAnswerTexts(newAnswerTexts);
              }}
              InputProps={{
                endAdornment: (
                  <ClearIcon
                    fontSize="small"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleRemoveAnswerField(index)}
                  />
                ),
                style: {
                  padding: "8px 12px",
                  backgroundColor: isEditing ? "#FFFFFF" : "#FAFAFA",
                  borderRadius: "4px",
                },
              }}
              sx={{
                "&.MuiTextField-root": {
                  marginTop: "12px",
                },
              }}
              disabled={
                field?.DBModel === "COURSE" ||
                field?.DBModel === "BRANCH" ||
                field?.DBModel === "BATCH"
                  ? true
                  : false
              }
            />
          ))}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "8px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      onChange={handleMandatoryChange}
                      checked={mandatory}
                      disabled={
                        leadsFromResponseChecked === true &&
                        (field?.DBModel === "BRANCH" ||
                          field?.DBModel === "COURSE")
                      }
                    />
                  }
                  label="Mandatory"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "13px",
                      color: "#667085",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      lineHeight: "20px",
                    },
                  }}
                />
                {field?.isFromCustom === true && (
                  <FormControlLabel
                    control={
                      <Checkbox size="small" onChange={handleSaveForLater} />
                    }
                    label="Save for later"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                        color: "#667085",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "20px",
                      },
                    }}
                  />
                )}
              </div>

              <Button
                component="label"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "500",
                  display: "flex",
                }}
                startIcon={<AddIcon />}
                onClick={handleAddAnswer}
                disabled={
                  field?.fieldName === "course" ||
                  field?.fieldName === "branch" ||
                  field?.fieldName === "batch"
                    ? true
                    : false
                }
              >
                Add an answer
              </Button>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  border: "1px solid #BFBFBF",
                  background: "#FFF",
                  color: "#BFBFBF",
                  "&:hover": {
                    border: "1px solid #BFBFBF",
                    background: "#FFF",
                  },
                }}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={handleSaveClick}
                disabled={!question.trim()} // Disable if the question is empty or contains only whitespaces
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Box>
          {answerTexts[0] !== "" && (
            <FormControl fullWidth>
              <InputLabel id="demo-select-small-label">{question}</InputLabel>

              <Select
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
                label={question}
              >
                {answers.map((item, index) => (
                  <MenuItem key={item.answerId} value={item.answer}>
                    {answerTexts[index]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      )}
      {isHovered && (
        <div
          style={{
            display: "flex",
            color: "#667085",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DragIndicatorIcon style={{ transform: "rotate(90deg)" }} />
        </div>
      )}
    </Box>
  );
}
export default CustomizedDropdownField;
