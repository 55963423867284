import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  InputAdornment,
  Modal,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from "./components/InstituteProfile.module.css";
import facebook from "../../../../assets/images/facebook.svg";
import instagram from "../../../../assets/images/insta.svg";
import linkedin from "../../../../assets/images/linkedIn.svg";
import twitter from "../../../../assets/images/twitter.svg";
import { FiEdit } from "react-icons/fi";
import React from "react";
import CancelModal from "../../../../components/CancelModal/CancelModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 413,
    xl: 413,
    md: 413,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "8px",
  p: 3.5,
};
const AddSocialMediaModal = (props: any) => {
  const {
    open,
    closeModal,
    handleChecked,
    displaySocialMedia,
    handleSubmitSocial,
    handleChangeSocial,
    instituteDetails,
    resetSocial,
    facebookError,
    instagramError,
    twitterError,
    linkedInError,
    instituteUpdateloading,
  } = props;

  // const [isEditFacebook, setIsEditFacebook] = React.useState<boolean>(
  //   instituteDetails?.socialMediaHandles[0]?.url !== "" ||
  //     instituteDetails?.socialMediaHandles?.length === 0
  //     ? true
  //     : false
  // );
  const [isEditFacebook, setIsEditFacebook] = React.useState<boolean>(
    instituteDetails?.socialMediaHandles?.length === 0
      ? false
      : instituteDetails?.socialMediaHandles[0]?.url !== ""
      ? true
      : false
  );
  const [isEditTwitter, setIsEditTwitter] = React.useState<boolean>(
    instituteDetails?.socialMediaHandles?.length === 0
      ? false
      : instituteDetails?.socialMediaHandles[2]?.url !== ""
      ? true
      : false
  );
  const [isEditInstagram, setIsEditInstagram] = React.useState<boolean>(
    instituteDetails?.socialMediaHandles?.length === 0
      ? false
      : instituteDetails?.socialMediaHandles[1]?.url !== ""
      ? true
      : false
  );
  const [isEditLinkedIn, setIsEditLinkedIn] = React.useState<boolean>(
    instituteDetails?.socialMediaHandles?.length === 0
      ? false
      : instituteDetails?.socialMediaHandles[3]?.url !== ""
      ? true
      : false
  );

  const [openCancelModal, setOpenCancelModal] = React.useState<boolean>(false);

  const handleCancelModalClose = () => {
    setOpenCancelModal(false);
  };
  const noButtonClick = () => {
    setOpenCancelModal(false);
  };
  const yesButtonClick = () => {
    setOpenCancelModal(false);
    resetSocial();
    closeModal();
  };
  const onSubmit = () => {
    if (facebookError || instagramError || twitterError || linkedInError) {
      handleSubmitSocial();
    }
  };
  const onEditSocialChange = (socialName: any) => {
    switch (socialName) {
      case "Facebook":
        setIsEditFacebook(!isEditFacebook);
        break;
      case "Linkedin":
        setIsEditLinkedIn(!isEditLinkedIn);
        break;
      case "Twitter":
        setIsEditTwitter(!isEditTwitter);
        break;
      case "Instagram":
        setIsEditInstagram(!isEditInstagram);
        break;
      default:
        setIsEditFacebook(!isEditFacebook);
        break;
    }
  };
  const areObjectsEqual = (obj1: any, obj2: any) => {
    const str1 = JSON.stringify(obj1);
    const str2 = JSON.stringify(obj2);

    return str1 === str2;
  };
  const cancelButtonClick = () => {
    const isSame = areObjectsEqual(
      instituteDetails.socialMediaHandles,
      displaySocialMedia
    );
    if (isSame) {
      closeModal();
    } else {
      setOpenCancelModal(true);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box className={styles.modalTitle}>Social links</Box>
            <Box
              onClick={() => cancelButtonClick()}
              style={{ cursor: "pointer" }}
            >
              <Close />
            </Box>
          </Box>
          <Box
            className={styles.modalDes}
            marginTop={"6px"}
            marginBottom={"14px"}
          >
            Add your social links that will be visible on the institute profile
            page.
          </Box>
          <Box>
            {displaySocialMedia &&
              displaySocialMedia.length !== 0 &&
              displaySocialMedia?.map((obj: any, index: number) => {
                const socialName =
                  obj.socialMedia.charAt(0) +
                  obj.socialMedia.slice(1).toLowerCase();

                let imgName: any;
                let disable: any;
                let errorMessge: any;
                let error: any;

                switch (socialName) {
                  case "Facebook":
                    imgName = facebook;
                    disable = isEditFacebook;
                    errorMessge = !facebookError && (
                      <FormHelperText error={true}>
                        Please enter valid {socialName} url
                      </FormHelperText>
                    );
                    error = facebookError;
                    break;
                  case "Linkedin":
                    imgName = linkedin;
                    disable = isEditLinkedIn;
                    errorMessge = !linkedInError && (
                      <FormHelperText error={true}>
                        Please enter valid {socialName} url
                      </FormHelperText>
                    );
                    error = linkedInError;
                    break;
                  case "Twitter":
                    imgName = twitter;
                    disable = isEditTwitter;
                    errorMessge = !twitterError && (
                      <FormHelperText error={true}>
                        Please enter valid {socialName} url
                      </FormHelperText>
                    );
                    error = twitterError;
                    break;
                  case "Instagram":
                    imgName = instagram;
                    disable = isEditInstagram;
                    errorMessge = !instagramError && (
                      <FormHelperText error={true}>
                        Please enter valid {socialName} url
                      </FormHelperText>
                    );
                    error = instagramError;
                    break;
                  default:
                    imgName = facebook;
                    disable = isEditFacebook;
                    errorMessge = !facebookError && (
                      <FormHelperText error={true}>
                        Please enter valid {socialName} url
                      </FormHelperText>
                    );
                    error = facebookError;
                    break;
                }

                return (
                  <Box key={index}>
                    <Box className={styles.socialModalTitle}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box marginRight={"7px"}>{socialName}</Box>
                        {obj.url !== "" && (
                          <FiEdit
                            size={14}
                            color="#667085"
                            cursor={"pointer"}
                            onClick={() => {
                              onEditSocialChange(socialName);
                            }}
                          />
                        )}
                      </Box>
                      <Checkbox
                        size="small"
                        value={socialName}
                        sx={{
                          px: 1,
                          py: 0,
                          color: "#D4D4D8",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        checked={obj.isVisible}
                        onChange={handleChecked}
                      />
                    </Box>
                    <Box className={styles.socialTextMainDiv}>
                      <TextField
                        size="small"
                        disabled={disable}
                        id="iconText"
                        name={socialName}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#D4D4D8",
                            },
                            "& .Mui-disabled": {
                              borderColor: "#D4D4D8 !important",
                            },
                          },
                          input: {
                            marginLeft: "-8px",
                            paddingLeft: "8px",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "20px",
                            textTransform: "lowercase",
                            color: "#888888",
                          },
                          "input[type='text']:disabled": {
                            background: "#F7F7F7",
                            borderRadius: "0 7px 7px 0",
                            color: "#888888",
                            borderColor: "#D4D4D8",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Box className={styles.iconSection}>
                                <img src={imgName} alt="" />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChangeSocial}
                        value={obj.url}
                        error={obj.url !== "" && !error}
                      />
                      {obj.url !== "" && errorMessge}
                    </Box>
                  </Box>
                );
              })}
          </Box>

          <Box style={{ textAlign: "end" }} sx={{ mt: 1 }}>
            <Button
              sx={{
                width: "106px",
                padding: "11px 26px",
                marginRight: "8px",
                color: "#BFBFBF",
                border: "1px solid #BFBFBF",
                "&:hover": {
                  border: "1px solid #BFBFBF",
                  backgroundColor: "rgb(180 181 181 / 4%)",
                },
              }}
              variant="outlined"
              onClick={cancelButtonClick}
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: "106px",
                padding: "11px 34px",
              }}
              variant="contained"
              type="submit"
              onClick={() => {
                onSubmit();
              }}
            >
              {instituteUpdateloading ? (
                <CircularProgress size={25} color={"inherit"} />
              ) : (
                <>Save</>
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
      <CancelModal
        open={openCancelModal}
        onClose={handleCancelModalClose}
        noButtonClick={noButtonClick}
        yesButtonClick={yesButtonClick}
      />
    </>
  );
};
export default AddSocialMediaModal;
