import { connect } from "react-redux";
import { RootState, TypedDispatch } from "../../../../redux/store";
import { UserDetailsDTO } from "../../../auth/signin/service/types";
import Home from "../components/Home";
import { appEntery } from "../service/homeSlice";
import { setActiveInsitute } from "../../../../service/activeInstituteSlice";
import {
  PrivacyPolicyData,
  TermsAndConditionData,
} from "../../Dashboard/service/types";
import {
  privacyPolicy,
  termsAndConditions,
} from "../../Dashboard/service/dashboardSlice";
import { getInstituteById } from "../../Manage/InstituteProfile/service/instituteSlice";
interface StateProps {
  userDetails: UserDetailsDTO | null;
  error: boolean | string | undefined;
  loading: boolean;
  success: boolean;
  isProfileComplete: boolean;
  isCourseSetup: boolean;
  isBranchAdded: boolean;
  isTeamAdded: boolean;
  isBatchAdded: boolean;
  profileDetailsCompleted: boolean;
}
interface DispatchProps {
  registrationStep(data: string): void;
  AcceptTnC(data: TermsAndConditionData): void;
  AcceptPrivacyPolicy(data: PrivacyPolicyData): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    error: state.home.error,
    loading: state.home.loading,
    userDetails: state.login.userDetails,
    success: state.home.success,
    isProfileComplete: state.home.isProfileComplete,
    profileDetailsCompleted: state.home.profileDetailsCompleted,
    isCourseSetup: state.home.isCourseSetup,
    isBranchAdded: state.home.isBranchAdded,
    isTeamAdded: state.home.isTeamAdded,
    isBatchAdded: state.home.isBatchAdded,
  };
};
const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    registrationStep: (data: string) => {
      // dispatch(appEntery(data));
      // dispatch(setActiveInsitute(data));
      dispatch(getInstituteById(data));
    },
    AcceptTnC: (data: TermsAndConditionData) => {
      dispatch(termsAndConditions(data));
    },
    AcceptPrivacyPolicy: (data: PrivacyPolicyData) => {
      dispatch(privacyPolicy(data));
    },
  };
};

export interface HomeProps extends StateProps, DispatchProps {}
export const HomeComponent = connect(mapStateToProps, mapDispatchToProps)(Home);
