import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import Nurture from "../components/Nurture";
import {
  createDemo,
  createNote,
  createTask,
  getBatchByInstitute,
  getLeadActivityList,
  getLeadById,
  getLeadNoteList,
  getLeadTaskList,
  getLeadPaymentList,
  getNurtureLeadBoard,
  nurtureLead,
  recordPayment,
  updateLead,
  updateLeadSwimlaneStatus,
  convertLeadToStudent,
  updateLeadPriority,
  updateLeadAssignee,
  getDemoByBatchId,
  getDemoByBatchAndDate,
  getDemoByEnquiryId,
  editDemo,
  getInoxByLeadId,
  composeEmail,
  updateLeadEnquiryStatus,
  getBatchTimeByBatchId,
  getCourseDataWithCategoryForInstitute,
  getLeadsForBoard,
  getLeadListByInstituteAndContact,
  deleteNote,
  editNote,
  deleteTask,
  editTask,
  // updateTaskStatus,
  getDemoCalanderByCourseAndInstitute,
  deleteDemoBookedForLead,
  updateLeadEmiDetails,
  getEmiDataOfLead,
  sendPaymentLink,
  downloadPaymentReceipt,
  editRecordPayment,
  createSource,
  createComment,
  deleteComment,
  editComment,
} from "../service/nurtureSlice";
import { getBranchList } from "../../Manage/ManageBranch/service/Branchslice";
import {
  getAllCourseByInstituteRequest,
  getCourseByIdRequest,
} from "../../Manage/ManageCourse/service/courseSlice";
import {
  createBatch,
  getBatchById,
  getBatchList,
} from "../../Manage/ManageBatch/service/Batchslice";
import {
  ComposeEmailDTO,
  ConvertLeadToStudentDTO,
  CreateCommentDTO,
  CreateDemoDTO,
  CreateNoteDTO,
  CreateSourceDTO,
  CreateTaskDTO,
  DeleteCommentDTO,
  DeleteDemoBookedDTO,
  DeleteNoteDTP,
  DeleteTaskDTO,
  DownloadReceiptDTO,
  EditDemoDTO,
  EditNoteDTO,
  EditTaskDTO,
  GetDemoByDateAndBAtchDTO,
  LeadStatusUpdateDTO,
  LeadSwimlaneStatusUpdateDTO,
  NurtureInitialStateDTO,
  NurtureLead,
  RecordPaymentDTO,
  UpdateAssigneeDTO,
  UpdatePriorityDTO,
  UpdateTaskStatusDTO,
  leadEmiDTO,
  sendEmailDTO,
} from "../service/types";
import { GetCourseConfig } from "../../Manage/ManageCourse/service/courseConfigSlice";
import {
  getAllTasksType,
  updateTaskStatus,
} from "../../Tasks/service/tasksSlice";
import { getInstituteById } from "../../Manage/InstituteProfile/service/instituteSlice";
import { createBatchDTO } from "../../Manage/ManageBatch/service/types";
import {
  callLead,
  getAppsByInstitute,
  leadCallDispositionForm,
} from "../../Integrations/service/IntegrationsSlice";
import { CallDispositionDTO } from "../../Integrations/service/types";

interface StateProps extends NurtureInitialStateDTO {}
interface DispatchProps {
  GetBranchList(id: string): void;
  GetCourseList(id: string): void;
  GetBatchList(data: any): void;
  CourseConfig(): void;
  GetNurtureLeadBoard(id: string): void;
  UpdateLeadSwimlaneStatus(data: LeadSwimlaneStatusUpdateDTO): void;
  nurtureAddEnquiry(data: NurtureLead): void;
  GetLeadActivityList(id: string): void;
  GetLeadTaskList(id: string): void;
  GetLeadNoteList(id: string): void;
  GetLeadPaymentList(id: string): void;
  UpdateLead(data: NurtureLead): void;
  GetLeadById(id: string): void;
  CreateNote(data: CreateNoteDTO): void;
  CreateTask(data: CreateTaskDTO): void;
  RecordPayment(data: RecordPaymentDTO): void;
  CreateDemo(data: CreateDemoDTO): void;
  GetBatchByInstitute(id: string): void;
  GetBatchById(id: string): void;
  ConvertLeadToStudent(data: ConvertLeadToStudentDTO): void;
  UpdateLeadPriority(data: UpdatePriorityDTO): void;
  UpdateLeadAssignee(data: UpdateAssigneeDTO): void;
  GetDemoByBatchId(id: string): void;
  GetDemoByEnquiryId(id: string): void;
  GetDemoByDateAndBatch(data: GetDemoByDateAndBAtchDTO): void;
  EditDemo(data: EditDemoDTO): void;
  GetInboxByLeadId(id: string): void;
  ComposeEmailSent(data: ComposeEmailDTO): void;
  UpdateLeadEnquiyStatus(data: LeadStatusUpdateDTO): void;
  GetCourseById(id: string): void;
  GetBatchTimeByBatchId(id: string): void;
  GetCourseByCategoryByInstitute(data: any): void;
  GetAllTasksType(id: string): void;
  GetLeadsForBoard(data: any): void;
  GetLeadListByInstituteAndContact(data: any): void;
  DeleteNote(data: DeleteNoteDTP): void;
  EditNote(data: EditNoteDTO): void;
  DeleteTask(data: DeleteTaskDTO): void;
  EditTask(data: EditTaskDTO): void;
  UpdateTaskStatus(data: UpdateTaskStatusDTO): void;
  GetDemoCalanderByCourseAndInstitute(data: any): void;
  GetInstituteById(id: string): void;
  CreateBatch(data: createBatchDTO): void;
  DeleteDemoBookedForLead(data: DeleteDemoBookedDTO): void;
  UpdateLeadEmiDetails(data: leadEmiDTO): void;
  GetEmiDataOfLead(data: any): void;
  SendPaymentLink(data: sendEmailDTO): void;
  DownloadPaymentReceipt(data: DownloadReceiptDTO): void;
  EditRecordPayment(data: RecordPaymentDTO): void;
  CallLead(data: any): void;
  GetAppsByInstitute(id: string): void;
  CreateSource(data: CreateSourceDTO): void;
  CallDispositionForm(data: CallDispositionDTO): void;
  CreateComment(data: CreateCommentDTO): void;
  DeleteComment(data: DeleteCommentDTO): void;
  EditComment(data: CreateCommentDTO): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.nurture.success,
    error: state.nurture.error,
    loading: state.nurture.loading,
    leadBoard: state.nurture.leadBoard,
    onLeadsData: state.nurture.onLeadsData,
    leadListByContact: state.nurture.leadListByContact,
    demoByCourseAndInstitute: state.nurture.demoByCourseAndInstitute,
    leadActivityList: state.nurture.leadActivityList,
    leadNoteList: state.nurture.leadNoteList,
    leadTaskList: state.nurture.leadTaskList,
    leadPaymentList: state.nurture.leadPaymentList,
    leadById: state.nurture.leadById,
    batchWithCalendar: state.nurture.batchWithCalendar,
    bookedSessions: state.nurture.bookedSessions,
    leadAdditionalDetails: state.nurture.leadAdditionalDetails,

    leadCreateSuccess: state.nurture.leadCreateSuccess,
    leadCreateError: state.nurture.leadCreateError,
    leadCreateLoading: state.nurture.leadCreateLoading,
    leadStatusUpdateSuccess: state.nurture.leadStatusUpdateSuccess,
    leadStatusUpdateError: state.nurture.leadStatusUpdateError,
    leadStatusUpdateLoading: state.nurture.leadStatusUpdateLoading,
    leadInbox: state.nurture.leadInbox,
    courseDataWithCategory: state.nurture.courseDataWithCategory,
    leadEmiData: state.nurture.leadEmiData,
    leadCreateErrorData: state.nurture.leadCreateErrorData,
    leadUpdateErrorData: state.nurture.leadUpdateErrorData,

    leadUpdateSuccess: state.nurture.leadUpdateSuccess,
    leadUpdateError: state.nurture.leadUpdateError,
    leadUpdateLoading: state.nurture.leadUpdateLoading,
    createDemoLoading: state.nurture.createDemoLoading,
    createDemoSuccess: state.nurture.createDemoSuccess,
    createDemoError: state.nurture.createDemoError,
    batchByInstitute: state.nurture.batchByInstitute,
    leadConvertionSuccess: state.nurture.leadConvertionSuccess,
    leadConvertionError: state.nurture.leadConvertionError,
    leadConvertionLoading: state.nurture.leadConvertionLoading,
    leadPriorityUpdateSuccess: state.nurture.leadPriorityUpdateSuccess,
    leadPriorityUpdateError: state.nurture.leadPriorityUpdateError,
    leadPriorityUpdateLoading: state.nurture.leadPriorityUpdateLoading,
    leadAssigneeUpdateSuccess: state.nurture.leadAssigneeUpdateSuccess,
    leadAssigneeUpdateError: state.nurture.leadAssigneeUpdateError,
    leadAssigneeUpdateLoading: state.nurture.leadAssigneeUpdateLoading,
    demoByBatchId: state.nurture.demoByBatchId,
    demoByEnquiryId: state.nurture.demoByEnquiryId,
    demoByBatchAndDate: state.nurture.demoByBatchAndDate,
    editDemoLoading: state.nurture.editDemoLoading,
    editDemoSuccess: state.nurture.editDemoSuccess,
    editDemoError: state.nurture.editDemoError,
    composeEmailLoading: state.nurture.composeEmailLoading,
    composeEmailSuccess: state.nurture.composeEmailSuccess,
    composeEmailError: state.nurture.composeEmailError,
    createNoteLoading: state.nurture.createNoteLoading,
    createNoteSuccess: state.nurture.createNoteSuccess,
    createNoteError: state.nurture.createNoteError,
    createTaskLoading: state.nurture.createTaskLoading,
    createTaskSuccess: state.nurture.createTaskSuccess,
    createTaskError: state.nurture.createTaskError,
    createPaymentLoading: state.nurture.createPaymentLoading,
    createPaymentSuccess: state.nurture.createPaymentSuccess,
    createPaymentError: state.nurture.createPaymentError,
    leadLostUpdateLoading: state.nurture.leadLostUpdateLoading,
    leadLostUpdateSuccess: state.nurture.leadLostUpdateSuccess,
    leadLostUpdateError: state.nurture.leadLostUpdateError,
    batchTimeByBatchId: state.nurture.batchTimeByBatchId,
    leadActivityLoading: state.nurture.leadActivityLoading,
    onLeadsLoading: state.nurture.onLeadsLoading,
    onLeadsSuccess: state.nurture.onLeadsSuccess,
    onLeadsError: state.nurture.onLeadsError,

    leadListLoading: state.nurture.leadListLoading,
    leadListSuccess: state.nurture.leadListSuccess,
    leadListError: state.nurture.leadListError,
    deleteNoteLoading: state.nurture.deleteNoteLoading,
    deleteNoteSuccess: state.nurture.deleteNoteSuccess,
    deleteNoteError: state.nurture.deleteNoteError,
    editNoteLoading: state.nurture.editNoteLoading,
    editNoteSuccess: state.nurture.editNoteSuccess,
    editNoteError: state.nurture.editNoteError,
    deleteTaskLoading: state.nurture.deleteTaskLoading,
    deleteTaskSuccess: state.nurture.deleteTaskSuccess,
    deleteTaskError: state.nurture.deleteTaskError,
    editTaskLoading: state.nurture.editTaskLoading,
    editTaskSuccess: state.nurture.editTaskSuccess,
    editTaskError: state.nurture.editTaskError,
    // updateTaskStatusLoading: state.nurture.updateTaskStatusLoading,
    // updateTaskStatusSuccess: state.nurture.updateTaskStatusSuccess,
    // updateTaskStatusError: state.nurture.updateTaskStatusError,
    deleteDemoBookedLoading: state.nurture.deleteDemoBookedLoading,
    deleteDemoBookedSuccess: state.nurture.deleteDemoBookedSuccess,
    deleteDemoBookedError: state.nurture.deleteDemoBookedError,
    leadTasksloading: state.nurture.leadTasksloading,
    paymentListLoading: state.nurture.paymentListLoading,
    noteListLoading: state.nurture.noteListLoading,
    inboxLoading: state.nurture.inboxLoading,
    leadBoardLoading: state.nurture.leadBoardLoading,
    leadEmiDetailsLoading: state.nurture.leadEmiDetailsLoading,
    leadEmiDetailsSuccess: state.nurture.leadEmiDetailsSuccess,
    leadEmiDetailsError: state.nurture.leadEmiDetailsError,
    sendPaymentLinkLoading: state.nurture.sendPaymentLinkLoading,
    sendPaymentLinkSuccess: state.nurture.sendPaymentLinkSuccess,
    sendPaymentLinkError: state.nurture.sendPaymentLinkError,
    getEmiDataloading: state.nurture.getEmiDataloading,
    downloadPaymentReceiptLoading: state.nurture.downloadPaymentReceiptLoading,
    downloadPaymentReceiptSuccess: state.nurture.downloadPaymentReceiptSuccess,
    downloadPaymentReceiptError: state.nurture.downloadPaymentReceiptError,
    leadByIdLoading: state.nurture.leadByIdLoading,
    recordPaymentUpdateLoading: state.nurture.recordPaymentUpdateLoading,
    recordPaymentUpdateSuccess: state.nurture.recordPaymentUpdateSuccess,
    recordPaymentUpdateError: state.nurture.recordPaymentUpdateError,
    createSourceLoading: state.nurture.createSourceLoading,
    createSourceSuccess: state.nurture.createSourceSuccess,
    createSourceError: state.nurture.createSourceError,

    createCommentLoading: state.nurture.createCommentLoading,
    createCommentSuccess: state.nurture.createCommentSuccess,
    createCommentError: state.nurture.createCommentError,
    updateCommentLoading: state.nurture.updateCommentLoading,
    updateCommentSuccess: state.nurture.updateCommentSuccess,
    updateCommentError: state.nurture.updateCommentError,
    deleteCommentLoading: state.nurture.deleteCommentLoading,
    deleteCommentSuccess: state.nurture.deleteCommentSuccess,
    deleteCommentError: state.nurture.deleteCommentError,
    leadModalState: state.nurture.leadModalState,
    leadModalId: state.nurture.leadModalId,
    autoDebitError: state.nurture.autoDebitError,
    autoDebitSuccess: state.nurture.autoDebitSuccess,
    autoDebitLoading: state.nurture.autoDebitLoading,
    openSearchError: state.nurture.openSearchError,
    openSearchSuccess: state.nurture.openSearchSuccess,
    openSearchLoading: state.nurture.openSearchLoading,
    openSearchData: state.nurture.openSearchData,
    leadDiscountError: state.nurture.leadDiscountError,
    leadDiscountSuccess: state.nurture.leadDiscountSuccess,
    leadDiscountLoading: state.nurture.leadDiscountLoading,
    randomPaymentError: state.nurture.randomPaymentError,
    randomPaymentSuccess: state.nurture.randomPaymentSuccess,
    randomPaymentLoading: state.nurture.randomPaymentLoading,
    pauseError: state.nurture.pauseError,
    pauseSuccess: state.nurture.pauseSuccess,
    pauseLoading: state.nurture.pauseLoading,
    deleteAdditionalPayError: state.nurture.deleteAdditionalPayError,
    deleteAdditionalPaySuccess: state.nurture.deleteAdditionalPaySuccess,
    deleteAdditionalPayLoading: state.nurture.deleteAdditionalPayLoading,
    leadCreatedId: state.nurture.leadCreatedId,
    randomPaymentCollectError: state.nurture.randomPaymentCollectError,
    randomPaymentCollectSuccess: state.nurture.randomPaymentCollectSuccess,
    randomPaymentCollectLoading: state.nurture.randomPaymentCollectLoading,
    updateNoPaymentCollectedLoading:
      state.nurture.updateNoPaymentCollectedLoading,
    updateNoPaymentCollectedSuccess:
      state.nurture.updateNoPaymentCollectedSuccess,
    updateNoPaymentCollectedError: state.nurture.updateNoPaymentCollectedError,
    batchWithCalendarLoading: state.nurture.batchWithCalendarLoading,
    sessionPayRecordLoading: state.nurture.sessionPayRecordLoading,
    sessionPayRecordSuccess: state.nurture.sessionPayRecordSuccess,
    sessionPayLinkLoading: state.nurture.sessionPayLinkLoading,
    sessionPayLinkSuccess: state.nurture.sessionPayLinkSuccess,
    sessionBookLoading: state.nurture.sessionBookLoading,
    sessionBookSuccess: state.nurture.sessionBookSuccess,
    bookedSessionLoading: state.nurture.bookedSessionLoading,
    leadAdditionalDetailsLoading: state.nurture.leadAdditionalDetailsLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    GetBatchList: (data: any) => {
      dispatch(getBatchList(data));
    },
    CourseConfig: () => {
      dispatch(GetCourseConfig());
    },
    GetNurtureLeadBoard: (id: string) => {
      dispatch(getNurtureLeadBoard(id));
    },
    UpdateLeadSwimlaneStatus: (data: LeadSwimlaneStatusUpdateDTO) => {
      dispatch(updateLeadSwimlaneStatus(data));
    },
    nurtureAddEnquiry: (data: NurtureLead) => {
      dispatch(nurtureLead(data));
    },
    GetLeadActivityList: (id: string) => {
      dispatch(getLeadActivityList(id));
    },
    GetLeadTaskList: (id: string) => {
      dispatch(getLeadTaskList(id));
    },
    GetLeadNoteList: (id: string) => {
      dispatch(getLeadNoteList(id));
    },
    GetLeadPaymentList: (id: string) => {
      dispatch(getLeadPaymentList(id));
    },
    UpdateLead: (data: NurtureLead) => {
      dispatch(updateLead(data));
    },
    GetLeadById: (id: string) => {
      dispatch(getLeadById(id));
    },
    CreateNote: (data: CreateNoteDTO) => {
      dispatch(createNote(data));
    },
    CreateTask: (data: CreateTaskDTO) => {
      dispatch(createTask(data));
    },
    RecordPayment: (data: RecordPaymentDTO) => {
      dispatch(recordPayment(data));
    },
    CreateDemo: (data: CreateDemoDTO) => {
      dispatch(createDemo(data));
    },
    GetBatchByInstitute: (id: string) => {
      dispatch(getBatchByInstitute(id));
    },
    GetBatchById: (id: string) => {
      dispatch(getBatchById(id));
    },
    ConvertLeadToStudent: (data: ConvertLeadToStudentDTO) => {
      dispatch(convertLeadToStudent(data));
    },
    UpdateLeadPriority: (data: UpdatePriorityDTO) => {
      dispatch(updateLeadPriority(data));
    },
    UpdateLeadAssignee: (data: UpdateAssigneeDTO) => {
      dispatch(updateLeadAssignee(data));
    },
    GetDemoByBatchId: (id: string) => {
      dispatch(getDemoByBatchId(id));
    },
    GetDemoByEnquiryId: (id: string) => {
      dispatch(getDemoByEnquiryId(id));
    },
    GetDemoByDateAndBatch: (data: GetDemoByDateAndBAtchDTO) => {
      dispatch(getDemoByBatchAndDate(data));
    },
    EditDemo: (data: EditDemoDTO) => {
      dispatch(editDemo(data));
    },
    GetInboxByLeadId: (id: string) => {
      dispatch(getInoxByLeadId(id));
    },
    ComposeEmailSent: (data: ComposeEmailDTO) => {
      dispatch(composeEmail(data));
    },
    UpdateLeadEnquiyStatus: (data: LeadStatusUpdateDTO) => {
      dispatch(updateLeadEnquiryStatus(data));
    },
    GetCourseById: (id: string) => {
      dispatch(getCourseByIdRequest(id));
    },
    GetBatchTimeByBatchId: (id: string) => {
      dispatch(getBatchTimeByBatchId(id));
    },
    GetCourseByCategoryByInstitute: (data: any) => {
      dispatch(getCourseDataWithCategoryForInstitute(data));
    },
    GetAllTasksType: (id: string) => {
      dispatch(getAllTasksType(id));
    },
    GetLeadsForBoard: (data: any) => {
      dispatch(getLeadsForBoard(data));
    },
    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
    DeleteNote: (data: DeleteNoteDTP) => {
      dispatch(deleteNote(data));
    },
    EditNote: (data: EditNoteDTO) => {
      dispatch(editNote(data));
    },
    DeleteTask: (data: DeleteTaskDTO) => {
      dispatch(deleteTask(data));
    },
    EditTask: (data: EditTaskDTO) => {
      dispatch(editTask(data));
    },
    UpdateTaskStatus: (data) => {
      dispatch(updateTaskStatus(data));
    },
    GetDemoCalanderByCourseAndInstitute: (data: any) => {
      dispatch(getDemoCalanderByCourseAndInstitute(data));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
    CreateBatch: (data: createBatchDTO) => {
      dispatch(createBatch(data));
    },
    DeleteDemoBookedForLead: (data: DeleteDemoBookedDTO) => {
      dispatch(deleteDemoBookedForLead(data));
    },
    UpdateLeadEmiDetails: (data: leadEmiDTO) => {
      dispatch(updateLeadEmiDetails(data));
    },
    GetEmiDataOfLead: (data: any) => {
      dispatch(getEmiDataOfLead(data));
    },
    SendPaymentLink: (data: sendEmailDTO) => {
      dispatch(sendPaymentLink(data));
    },
    DownloadPaymentReceipt: (data: DownloadReceiptDTO) => {
      dispatch(downloadPaymentReceipt(data));
    },
    EditRecordPayment: (data: RecordPaymentDTO) => {
      dispatch(editRecordPayment(data));
    },
    CallLead: (data: any) => {
      dispatch(callLead(data));
    },
    GetAppsByInstitute: (id: string) => {
      dispatch(getAppsByInstitute(id));
    },
    CreateSource: (data: CreateSourceDTO) => {
      dispatch(createSource(data));
    },
    CallDispositionForm: (data: CallDispositionDTO) => {
      dispatch(leadCallDispositionForm(data));
    },
    CreateComment: (data: CreateCommentDTO) => {
      dispatch(createComment(data));
    },
    DeleteComment: (data: DeleteCommentDTO) => {
      dispatch(deleteComment(data));
    },
    EditComment: (data: CreateCommentDTO) => {
      dispatch(editComment(data));
    },
  };
};

export interface NurtureProps extends StateProps, DispatchProps {}
export const NurtureComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Nurture);
