import { Button, TextField, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import styles from "../StudentSettings.module.css";
import { useAuthUser } from "react-auth-kit";

function CreateStudentPrefix(props: any) {
  const { CreateStudentId, createStudentIdLoading } = props;
  const auth = useAuthUser();
  const authUser = auth();

  const [prefix, setPrefix] = useState<String>("");
  const [nextNumber, setNextNumber] = useState<String>("");
  const PREFIX_REGEX = /^[a-zA-Z_]+$/;
  const NUM_REGEX = /^[0-9]*$/;

  const [prefixError, setPrefixError] = React.useState<boolean>(false);
  const [numberError, setNumberError] = React.useState<boolean>(false);

  const handleCancel = () => {
    setPrefix("");
    setNextNumber("");
  };
  const handlePrefixChange = (e: any) => {
    const inputValue = e.target.value;
    if (PREFIX_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setPrefixError(true);
      } else {
        setPrefixError(false);
      }
      setPrefix(inputValue);
    }
  };

  const handleNumberChange = (e: any) => {
    const inputValue = e.target.value;

    if (NUM_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setNumberError(true);
      } else {
        setNumberError(false);
      }
      setNextNumber(inputValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 8) {
      return;
    }
    if (!NUM_REGEX.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        nextNumber: nextNumber,
        prefix: prefix?.toUpperCase(),
      };
      CreateStudentId(body);
    } catch (error) {}
  };
  return (
    <div>
      <div className={styles.editPrefixWrap}>
        <div className={styles.editPrefixTitle}>
          Customize your student ID by setting a prefix and starting number.
        </div>
        <div className={styles.editPrefixDesc}>
          The system will generate them sequentially, ensuring a unique
          identification for each student.
        </div>
        <div style={{ display: "flex", gap: "18px" }}>
          <div>
            <div className={styles.editPrefixLabel}>
              Prefix{" "}
              <span style={{ color: "#EC3939", fontWeight: "400" }}>*</span>
            </div>
            <TextField
              sx={{
                maxWidth: "138px",
                "& .MuiOutlinedInput-input": {
                  padding: "11px",
                },
              }}
              value={prefix?.toUpperCase()}
              onChange={handlePrefixChange}
              onKeyDown={(event: any) => {
                if (!PREFIX_REGEX.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputProps={{ maxLength: 4 }}
              error={prefixError}
              helperText={prefixError ? "Field Required" : ""}
            />
          </div>
          <div>
            <div className={styles.editPrefixLabel}>
              Suffix{" "}
              <span style={{ color: "#EC3939", fontWeight: "400" }}>*</span>
            </div>
            <TextField
              sx={{
                maxWidth: "200PX",
                "& .MuiOutlinedInput-input": {
                  padding: "11px",
                },
              }}
              value={nextNumber}
              onChange={handleNumberChange}
              inputProps={{ maxLength: 4 }}
              error={numberError}
              helperText={numberError ? "Field Required" : ""}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div style={{ marginTop: "24px", display: "flex", gap: "12px" }}>
          <Button
            sx={{
              color: "#BFBFBF",
              border: "1px solid #BFBFBF",
              background: "#FFF",
              textTransform: "capitalize",
            }}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            onClick={handleSaveClick}
            disabled={numberError || prefixError || createStudentIdLoading}
          >
            {createStudentIdLoading ? (
              <CircularProgress size={20} color={"inherit"} />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreateStudentPrefix;
