import { connect } from "react-redux";
import { IntegrationsListInitialStateDTO } from "../service/types";

import { RootState, TypedDispatch } from "../../../../redux/store";

import Integrations from "../components/Integrations";
import { getAppsByInstitute } from "../service/IntegrationsSlice";

interface DispatchProps {
  GetAppsByInstitute(id: string): void;
}

const mapStateToProps = (state: RootState): IntegrationsListInitialStateDTO => {
  return { appsList: state.appIntegrations.appsList };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAppsByInstitute: (id: string) => {
      dispatch(getAppsByInstitute(id));
    },
  };
};

export interface OzonetelStagesProps
  extends IntegrationsListInitialStateDTO,
    DispatchProps {}
export const IntegrationsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Integrations);
