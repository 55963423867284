import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateRangePicker(props: any) {
  const { startDate, endDate, onDateChange, minDate } = props;
  return (
    <div style={{ paddingTop: "10px" }}>
      <DatePicker
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        minDate={minDate}
        // showMonthYearDropdown
        // showMonthYearPicker
      />
    </div>
  );
}

export default DateRangePicker;
