import React from "react";
import { Button, Dialog, Checkbox, CircularProgress } from "@mui/material";
import styles from "./EditPopup.module.css";
import CloseIcon from "@mui/icons-material/Close";

function EditPopup(props: any) {
  const {
    onClose,
    type,
    onConfirm,
    showEditPopup,
    notifyText,
    handleNotificationCheckboxChange,
    isCheckedNotification,
    loader,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSaveClick = () => {
    if (isCheckedNotification) {
      onConfirm(true);
    } else {
      onConfirm(false);
    }
  };

  const studentNotification = () => {
    if (type === "course") {
      return "Notify the students in this Course via Mobile app.";
    } else if (type === "batch") {
      return "Notify the students in this Batch via Mobile app.";
    }
  };

  return (
    <Dialog
      open={showEditPopup}
      onClose={onClose}
      sx={{
        zIndex: "99999",
        minWidth: "414px",
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "440px",
          maxHeight: "290px",
          minHeight: "165px",
          padding: "18px 28px 28px 28px",
          borderRadius: "8px",
          boxShadow:
            "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
          left: "10%",
          background: "#FFFFFF",
          overflow: "hidden",
        },
      }}
    >
      <div className={styles.deactivationPopup}>
        <div className={styles.closeModalButton}>
          <CloseIcon sx={{ color: "#667085" }} onClick={handleClose} />
        </div>
        <div
          className={styles.popupHead}
          style={{
            marginBottom: notifyText ? "10px" : "20px",
          }}
        >
          Are you sure you want to save the changes?
        </div>
        {notifyText && (
          <div>
            <div>
              <p className={styles.popupPara}>{notifyText}</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <Checkbox
                checked={isCheckedNotification}
                onChange={handleNotificationCheckboxChange}
              />
              <div className={styles.notifyPara}>{studentNotification()}</div>
            </div>
          </div>
        )}

        <div className={styles.popupButtonDiv}>
          <Button
            sx={{
              textTransform: "capitalize",
              border: "1px solid #D0D5DD",
              color: "#344054",
              "&:hover": {
                border: "1px solid #D0D5DD",
                color: "#344054",
                backgroundColor: "#FFF",
              },
            }}
            variant="outlined"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>

          <Button
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            onClick={handleSaveClick}
            fullWidth
            disabled={loader}
          >
            {loader ? <CircularProgress size={18} color={"inherit"} /> : "Save"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default EditPopup;
