import React from "react";
import styles from "./SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function TableSkeleton() {
  return (
    <div className={styles.tableSkeletonWrap}>
      <div className={styles.tableSkeletonHead}>
        <div className={styles.tableSkeletonHeadItem}>
          <Skeleton animation="wave" variant="text" width="15px" height={23} />
        </div>
        <div className={styles.tableSkeletonHeadItem}>
          <Skeleton animation="wave" variant="text" width="80px" height={23} />
        </div>
        <div className={styles.tableSkeletonHeadItem}>
          <Skeleton animation="wave" variant="text" width="80px" height={23} />
        </div>
        <div className={styles.tableSkeletonHeadItem}>
          <Skeleton animation="wave" variant="text" width="80px" height={23} />
        </div>
        <div className={styles.tableSkeletonHeadItem}>
          <Skeleton animation="wave" variant="text" width="80px" height={23} />
        </div>
      </div>
      <div className={styles.tableSkeletonBody}>
        {Array.from(new Array(4)).map((_, index) => (
          <div key={index} className={styles.tableSkeletonBodyRow}>
            <div className={styles.tableSkeletonBodyItem}>
              <Skeleton
                animation="wave"
                variant="text"
                width="15px"
                height={18}
              />
            </div>
            {Array.from(new Array(4)).map((_, index) => (
              <div key={index} className={styles.tableSkeletonBodyItem}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="80px"
                  height={18}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TableSkeleton;
