import {
  Avatar,
  Box,
  Button,
  InputAdornment,
  TextField,
  FormGroup,
  Skeleton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../components/Managestaffs.module.css";
import edit from "./edit.svg";
import FilterDrawer from "../../../../../components/FilterDrawer/FilterDrawer";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { useAuthUser } from "react-auth-kit";
import ClearFilter from "../../../../../components/ClearFilter/ClearFilter";
import FilterChips from "../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterCheckbox from "../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterChipsWrap from "../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterTypeWrap from "../../../../../components/FilterDrawer/FilterTypeWrap";
import FilterLabel from "../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterCheckBoxWrap from "../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterSearchField from "../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import ShowAllBtn from "../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import UserLimitPopup from "./UserLimitPopup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StaffReactivatePopup from "./StaffReactivatePopup";
import { getSubDomainAccessStatus } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useDispatch } from "react-redux";
import { getRolesByInstitute } from "../../../Settings/service/settingsSlice";

interface CheckedBranch {
  id: string;
  branchName: string;
}

interface CheckedRole {
  id: string;
  roleName: string;
}
interface CheckedStaffStatus {
  staffStatus: string;
}

const roles = [
  { id: "ad0", role: "Admin" },
  { id: "ad1", role: "Tutor" },
  { id: "ad2", role: "Manager Business development" },
  { id: "ad3", role: "Accounts" },
  { id: "ad4", role: "Course counsellor" },
  { id: "ad5", role: "Business Development Executive" },
];

export default function StaffListing(props: any) {
  const {
    GetStaffList,
    staffList,
    success,
    GetBranchList,
    backBtn,
    inBilling,
    UpdateStaffStatus,
    GetInstituteById,
    staffListLoading,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasTeamAccess = getSubDomainAccessStatus(
    "MANAGE",
    "TEAMS",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const branchList = useSelector((state: any) => state.branch.branchList);
  const { rolesList } = useSelector((state: any) => state.appSettings);
  const systemRoles = rolesList?.systemRoles?.filter(
    (item: any) => item.roleType === "DEFAULT"
  );
  const customRoles = rolesList?.customRoles?.filter(
    (item: any) => item.roleType === "CUSTOM"
  );
  const combinedRoles = rolesList?.systemRoles?.concat(rolesList?.customRoles);
  const [searchText, setSearchText] = useState("");
  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(null);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const [defaultSelectedStaffId, setDefaultSelectedStaffId] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (authUser) {
      GetStaffList(authUser.institute && authUser.institute[0].instituteId);
      GetBranchList(authUser.institute && authUser.institute[0].instituteId);
      GetInstituteById(authUser.institute && authUser.institute[0].instituteId);
      dispatch<any>(getRolesByInstitute(authUser.institute[0].instituteId));
    }
  }, [GetStaffList, GetBranchList, GetInstituteById]);

  const [branchSearchTerm, setBranchSearchTerm] = React.useState("");
  const [roleSearchTerm, setRoleSearchTerm] = React.useState("");
  const [showAllRoles, setShowAllRoles] = useState<boolean>(false);
  const [showAllBranches, setShowAllBranches] = useState<boolean>(false);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);

  const handleBranchSearch = (event: any) => {
    setBranchSearchTerm(event.target.value);
  };

  const filteredBranches = branchList?.filter((branch: any) => {
    const searchTermMatch =
      branch.branchName
        .toLowerCase()
        .indexOf(branchSearchTerm.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const displayedBranches = showAllBranches
    ? branchList && filteredBranches
    : branchList && filteredBranches.slice(0, 5);

  const handleShowMoreBranches = () => {
    setShowAllBranches(!showAllBranches);
  };

  const handleRoleSearch = (event: any) => {
    setRoleSearchTerm(event.target.value);
  };

  const filteredRoles = combinedRoles?.filter((item: any) => {
    const searchTermMatch =
      item?.roleName?.toLowerCase().indexOf(roleSearchTerm.toLowerCase()) !==
      -1;

    return searchTermMatch;
  });

  const displayedRoles = showAllRoles
    ? combinedRoles && filteredRoles
    : combinedRoles && filteredRoles.slice(0, 5);

  const handleShowMore = () => {
    setShowAllRoles(!showAllRoles);
  };

  const branches = useSelector((state: any) => state.branch.branchList);

  const [reactivatePopup, setReactivatePopup] = useState<boolean>(false);

  const closeReactivatePopup = () => {
    setReactivatePopup(false);
  };

  const openReactivatePopup = () => {
    setReactivatePopup(true);
  };

  const StaffDetails: React.FC<{ item: any }> = ({ item }) => {
    const phoneNumber = item && item.contact ? item.contact : "-";

    return (
      <div className={styles.rightSubSection}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #EBEBED",
            padding: "20px",
          }}
        >
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box className={styles.staffCardImg}>
              {item?.userProfileImage === "" ? (
                <Avatar
                  alt="avatar"
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    fontSize: "30px",
                    background: "#4E647A",
                    fontFamily: "Poppins",
                    fontWeight: "400",
                  }}
                  className={styles.staffAvatar}
                >
                  <Box>
                    {item && item.firstName && item.firstName.charAt(0)}
                  </Box>
                </Avatar>
              ) : (
                <Avatar
                  src={item?.userProfileImage}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Box>

            <Box>
              <div className={styles.staffNameWrap}>
                <div className={styles.staffNameDetail}>
                  {item && item.firstName} {item && item.lastName}
                </div>
                {item?.status === "INACTIVE" && (
                  <div className={styles.staffStatusDeactivated}>
                    Deactivated
                  </div>
                )}
              </div>
              <div className={styles.staffRoleDetail}>{item && item.role}</div>
            </Box>
          </Box>
          {item?.status === "ACTIVE" ? (
            <>
              <Tooltip
                arrow
                title={hasTeamAccess === false ? "Oops! No permission." : ""}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span>
                  {hasTeamAccess === false ? (
                    <Button
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#667085",
                        textTransform: "capitalize",
                        gap: "10px",
                      }}
                      disabled={hasTeamAccess === false}
                    >
                      Edit
                      <img src={edit} alt="edit" />
                    </Button>
                  ) : (
                    <Link to={`/app/manage/Teams/${item && item?.id}`}>
                      <Button
                        sx={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#667085",
                          textTransform: "capitalize",
                          gap: "10px",
                        }}
                      >
                        Edit
                        <img src={edit} alt="edit" />
                      </Button>
                    </Link>
                  )}
                </span>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip
                arrow
                title={hasTeamAccess === false ? "Oops! No permission." : ""}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span>
                  <button
                    onClick={openReactivatePopup}
                    className={styles.reactivateBtn}
                    disabled={hasTeamAccess === false}
                  >
                    Reactivate
                  </button>
                </span>
              </Tooltip>
            </>
          )}
        </Box>
        <Box sx={{ padding: "20px" }}>
          <div style={{ display: "flex", gap: "40px" }}>
            <div>
              <div className={styles.key}>Email</div>
              <div className={styles.value}>{item && item.email}</div>
            </div>
            <div>
              <div className={styles.key}>Phone number</div>
              <div className={styles.value}>
                {item?.contact !== "" ? (
                  <>
                    {phoneNumber !== "-"
                      ? `+${item?.contactCountryCode || "91"} ${
                          item?.contactCountryCode
                            ? item?.contact.replace(
                                item?.contactCountryCode,
                                ""
                              )
                            : item?.contact.startsWith("91")
                            ? item?.contact.replace("91", "")
                            : item?.contact
                        }`
                      : "-"}
                  </>
                ) : (
                  <>{"-"}</>
                )}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className={styles.key}>Branch</div>
            <div
              style={{ textTransform: "capitalize" }}
              className={styles.value}
            >
              {item && item.branchName}
            </div>
          </div>
        </Box>
      </div>
    );
  };

  const [clearFilterPopup, setClearFilterPopup] = useState<boolean>(false);

  const [checkedBranches, setCheckedBranches] = useState<CheckedBranch[]>([]);
  const [filterCheckedBranches, setFilterCheckedBranches] = useState<
    CheckedBranch[]
  >([]);
  const [checkedRoles, setCheckedRoles] = useState<CheckedRole[]>([]);
  const [filterCheckedRoles, setFilterCheckedRoles] = useState<CheckedRole[]>(
    []
  );
  const [checkedStaffStatuses, setCheckedStaffStatuses] = useState<
    CheckedStaffStatus[]
  >([]);
  const [filterCheckedStaffStatuses, setFilterCheckedStaffStatuses] = useState<
    CheckedStaffStatus[]
  >([]);

  const filteredStaffList =
    staffList &&
    staffList?.filter((staff: any) => {
      const nameMatch =
        staff.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        staff.lastName.toLowerCase().includes(searchText.toLowerCase());

      const branchIsChecked =
        filterCheckedBranches.length === 0 ||
        filterCheckedBranches.some((item) => item.id === staff.branchId);

      const roleIsChecked =
        filterCheckedRoles.length === 0 ||
        filterCheckedRoles.some(
          (item) => item.roleName.toLowerCase() === staff.role.toLowerCase()
        );

      const isInBilling = inBilling && staff.status === "INACTIVE";

      const staffStatusIsChecked =
        filterCheckedStaffStatuses.length === 0 ||
        filterCheckedStaffStatuses.some(
          (item) =>
            item.staffStatus.toLowerCase() === staff.status.toLowerCase()
        );

      return inBilling
        ? nameMatch && isInBilling
        : nameMatch && branchIsChecked && roleIsChecked && staffStatusIsChecked;
    });

  const handleFilterBranchChecked = (id: string, branchName: string) => {
    const branchToAdd: CheckedBranch = { id, branchName };
    if (checkedBranches.some((branch) => branch.id === id)) {
      setCheckedBranches(checkedBranches.filter((branch) => branch.id !== id));
    } else {
      setCheckedBranches([...checkedBranches, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedBranches(checkedBranches.filter((item) => item.id !== id));
  };

  const handleFilterRolesChecked = (id: string, roleName: string) => {
    const roleToAdd: CheckedRole = { id, roleName };
    if (checkedRoles.some((item) => item.id === id)) {
      setCheckedRoles(checkedRoles.filter((item) => item.id !== id));
    } else {
      setCheckedRoles([...checkedRoles, roleToAdd]);
    }
  };

  const clearCheckedRoles = (id: string) => {
    setCheckedRoles(checkedRoles.filter((item) => item.id !== id));
  };

  const handleFilterStaffStatusChecked = (staffStatus: string) => {
    const staffStatusToAdd: CheckedStaffStatus = { staffStatus };

    if (
      checkedStaffStatuses.some(
        (item) => item.staffStatus.toLowerCase() === staffStatus.toLowerCase()
      )
    ) {
      setCheckedStaffStatuses(
        checkedStaffStatuses.filter(
          (item) => item.staffStatus.toLowerCase() !== staffStatus.toLowerCase()
        )
      );
    } else {
      setCheckedStaffStatuses([...checkedStaffStatuses, staffStatusToAdd]);
    }
  };

  const clearCheckedStaffStatus = (staffStatus: string) => {
    setCheckedStaffStatuses(
      checkedStaffStatuses.filter((item) => item.staffStatus !== staffStatus)
    );
  };

  const clearFiltersPopupOpen = () => {
    setClearFilterPopup(true);
  };

  const clearFiltersPopupClose = () => {
    setClearFilterPopup(false);
  };

  const handleFilterStaffs = React.useCallback(
    (branchArray: any, rolesArray: any, staffStatusArray: any) => {
      setFilterCheckedBranches(branchArray);
      setFilterCheckedRoles(rolesArray);
      setFilterCheckedStaffStatuses(staffStatusArray);

      const queryParams = new URLSearchParams();
      if (branchArray && branchArray.length > 0) {
        const branchParam = branchArray
          .map((branch: any) => branch.id)
          .join("|");
        queryParams.set("filterBranch", branchParam);
      } else {
        queryParams.delete("filterBranch");
      }

      if (rolesArray && rolesArray.length > 0) {
        const roleParam = rolesArray.map((role: any) => role.id).join("|");
        queryParams.set("filterRole", roleParam);
      } else {
        queryParams.delete("filterRole");
      }

      if (staffStatusArray && staffStatusArray.length > 0) {
        const staffStatusParam = staffStatusArray
          .map((item: any) => item.staffStatus)
          .join("|");
        queryParams.set("filteredStatus", staffStatusParam);
      } else {
        queryParams.delete("filteredStatus");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;
      window.history.pushState({}, "", newUrl);
    },
    [setFilterCheckedBranches, setFilterCheckedRoles]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterStaffs(checkedBranches, checkedRoles, checkedStaffStatuses);

    setFilterApplied(
      checkedBranches?.length === 0 &&
        checkedRoles?.length === 0 &&
        checkedStaffStatuses?.length === 0
        ? false
        : true
    );
  }, [
    handleFilterStaffs,
    setFilterApplied,
    checkedBranches,
    checkedRoles,
    checkedStaffStatuses,
  ]);

  const handleResetClick = React.useCallback(() => {
    setCheckedBranches([]);
    setCheckedRoles([]);
    setFilterCheckedRoles([]);
    setFilterCheckedBranches([]);
    setCheckedStaffStatuses([]);
    setFilterCheckedStaffStatuses([]);
    setFilterApplied(false);
    handleFilterStaffs([], [], []);
  }, [
    setFilterApplied,
    setCheckedBranches,
    setCheckedRoles,
    setFilterCheckedRoles,
    setFilterCheckedBranches,
    handleFilterStaffs,
  ]);

  const handleFilterClose = React.useCallback(() => {
    setCheckedBranches(filterCheckedBranches);
    setCheckedRoles(filterCheckedRoles);
  }, [filterCheckedBranches, filterCheckedRoles]);

  const removeFilters = React.useCallback(() => {
    setCheckedBranches([]);
    setCheckedRoles([]);
    setFilterCheckedRoles([]);
    setFilterCheckedBranches([]);
    setCheckedStaffStatuses([]);
    setFilterCheckedStaffStatuses([]);
    setFilterApplied(false);
    setClearFilterPopup(false);
    handleFilterStaffs([], [], []);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("filterBranch");
    queryParams.delete("filterRole");
    queryParams.delete("filteredStatus");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  }, [
    setCheckedBranches,
    setCheckedRoles,
    setFilterCheckedRoles,
    setFilterCheckedBranches,
    setFilterApplied,
    setClearFilterPopup,
  ]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const branchParam = urlSearchParams.get("filterBranch");
    const roleParam = urlSearchParams.get("filterRole");
    const staffStatusParam = urlSearchParams.get("filteredStatus");

    if (branchParam) {
      const branchIds = branchParam.split("|"); // Change the separator if you used something other than '|'

      // Convert branchIds to an array of objects with only id and branchName
      const selectedBranches = branchIds
        .map((id) => {
          const branch = branchList?.find((branch: any) => branch.id === id);
          return branch ? { id, branchName: branch.branchName } : null;
        })
        .filter(Boolean) as CheckedBranch[];

      setFilterCheckedBranches(selectedBranches);
      setCheckedBranches(selectedBranches);
    }

    if (roleParam) {
      const roleIds = roleParam.split("|"); // Change the separator if you used something other than '|'

      // Convert roleNames to an array of objects with only roleName
      const selectedRoles = roleIds
        .map((id) => {
          const role = combinedRoles?.find((item: any) => item.id === id);
          return role ? { id, roleName: role.roleName } : null;
        })
        .filter(Boolean) as CheckedRole[];
      // // Convert roleNames to an array of objects with only roleName
      // const selectedRoles = roleNames.map((roleName) => ({
      //   roleName,
      // })) as CheckedRole[]; // Cast to CheckedRole[] type

      setFilterCheckedRoles(selectedRoles);
      setCheckedRoles(selectedRoles);
    }

    if (staffStatusParam) {
      const statuses = staffStatusParam.split("|");

      const selectedStatuses = statuses
        .map((value) => {
          const status = [{ status: "ACTIVE" }, { status: "INACTIVE" }].find(
            (type: any) => type.status.toLowerCase() === value.toLowerCase()
          );
          return status
            ? {
                staffStatus: status.status,
              }
            : null;
        })
        .filter(Boolean) as CheckedStaffStatus[];

      setFilterCheckedStaffStatuses(selectedStatuses);
      setCheckedStaffStatuses(selectedStatuses);
    }

    if (branchParam || roleParam || staffStatusParam) {
      setFilterApplied(true);
    }
  }, [branchList, rolesList]);

  const filteredValue = (
    <>
      {filterCheckedBranches &&
        filterCheckedBranches.length > 0 &&
        filterCheckedBranches.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.branchName}
          </div>
        ))}
      {filterCheckedRoles &&
        filterCheckedRoles.length > 0 &&
        filterCheckedRoles.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.roleName}
          </div>
        ))}
      {filterCheckedStaffStatuses?.length > 0 &&
        filterCheckedStaffStatuses?.map((item) => (
          <div key={item.staffStatus} className="filteredDiv">
            {item?.staffStatus === "INACTIVE"
              ? "Deactivated"
              : item?.staffStatus}
          </div>
        ))}
    </>
  );

  useEffect(() => {
    if (filteredStaffList && filteredStaffList.length > 0) {
      setDefaultSelectedStaffId(filteredStaffList[0].id);
    }
  }, [filteredStaffList]);

  const [userLimitPopup, setUserLimitPopup] = useState<boolean>(false);

  const closeUserLimitPopup = () => {
    setUserLimitPopup(false);
  };

  const openUserLimitPopup = () => {
    setUserLimitPopup(true);
  };

  React.useEffect(() => {
    if (success !== true && success !== false) {
      if (authUser) {
        GetStaffList(authUser.institute && authUser.institute[0].instituteId);
      }
      setReactivatePopup(false);
      if (window.location.pathname.includes("Billing")) {
        navigate("/app/manage/Billing/ManageSubscription");
      }
    }
  }, [success, GetStaffList, navigate]);

  const instituteDetails = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const userLicenseCount = instituteDetails?.totalUserLicense;
  const userCount = instituteDetails?.teamCount;
  const isBillingAdmin = authUser?.institute[0]?.isBillingAdmin;

  const handleTabClickQuery = (tabId: any) => {
    setSelectedStaffId(tabId);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("selectedStaff", tabId);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const selectedStaffUrlId = urlSearchParams.get("selectedStaff");

  useEffect(() => {
    if (selectedStaffUrlId) {
      setSelectedStaffId(selectedStaffUrlId);
    } else {
      setSelectedStaffId(defaultSelectedStaffId);
    }
  }, [selectedStaffUrlId, defaultSelectedStaffId]);

  return (
    <div>
      <UserLimitPopup
        isOpen={userLimitPopup}
        onClose={closeUserLimitPopup}
        isBillingAdmin={isBillingAdmin}
      />

      <Box className={styles.staffMainWrapper}>
        <Box className={styles.leftSection}>
          {backBtn ? (
            <div style={{ margin: "22px 18px 0 18px" }}>
              <button
                className={styles.PageBackBtn}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon
                  sx={{
                    marginRight: "8px",
                    fontSize: "15PX",
                    color: "#667085",
                  }}
                />
                Back
              </button>
            </div>
          ) : (
            <></>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "20px",
              marginBottom: "14px",
              marginTop: "30px",
            }}
          >
            {staffListLoading ? (
              <Skeleton
                animation="wave"
                variant="text"
                width="100%"
                height="40px"
                style={{
                  borderRadius: "2px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <>
                <Box className={styles.teamHead}>Teams</Box>

                {inBilling ? (
                  <>
                    {userLicenseCount > userCount ? (
                      <>
                        {hasTeamAccess === false ? (
                          <Tooltip
                            arrow
                            title={
                              hasTeamAccess === false
                                ? "Oops! No permission."
                                : ""
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "#101828",
                                  color: "#fff",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  padding: "8px 12px",
                                  "& .MuiTooltip-arrow": {
                                    color: "#101828",
                                  },
                                },
                              },
                            }}
                          >
                            <span>
                              <Button
                                component="label"
                                sx={{
                                  textTransform: "capitalize",
                                  fontWeight: "500",
                                  display: "flex",
                                  gap: "4px",
                                  paddingRight: 0,
                                }}
                                startIcon={<AddIcon />}
                                disabled={hasTeamAccess === false}
                              >
                                Add new
                              </Button>
                            </span>
                          </Tooltip>
                        ) : (
                          <Link
                            className={styles.link}
                            to="/app/manage/Billing/ManageSubscription/Create-Team"
                          >
                            <Button
                              component="label"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                display: "flex",
                                gap: "4px",
                                paddingRight: 0,
                              }}
                              startIcon={<AddIcon />}
                            >
                              Add new
                            </Button>
                          </Link>
                        )}
                      </>
                    ) : (
                      <Tooltip
                        arrow
                        title={
                          hasTeamAccess === false ? "Oops! No permission." : ""
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <Button
                            component="label"
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: "500",
                              display: "flex",
                              gap: "4px",
                              paddingRight: 0,
                            }}
                            startIcon={<AddIcon />}
                            onClick={openUserLimitPopup}
                            disabled={hasTeamAccess === false}
                          >
                            Add new
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <>
                    {userLicenseCount > userCount ? (
                      <>
                        {hasTeamAccess === false ? (
                          <Tooltip
                            arrow
                            title={
                              hasTeamAccess === false
                                ? "Oops! No permission."
                                : ""
                            }
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  bgcolor: "#101828",
                                  color: "#fff",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  padding: "8px 12px",
                                  "& .MuiTooltip-arrow": {
                                    color: "#101828",
                                  },
                                },
                              },
                            }}
                          >
                            <span>
                              <Button
                                component="label"
                                sx={{
                                  textTransform: "capitalize",
                                  fontWeight: "500",
                                  display: "flex",
                                  gap: "4px",
                                  paddingRight: 0,
                                }}
                                startIcon={<AddIcon />}
                                disabled={hasTeamAccess === false}
                              >
                                Add new
                              </Button>
                            </span>
                          </Tooltip>
                        ) : (
                          <Link
                            className={styles.link}
                            to="/app/manage/CreateTeam"
                          >
                            <Button
                              component="label"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "500",
                                display: "flex",
                                gap: "4px",
                                paddingRight: 0,
                              }}
                              startIcon={<AddIcon />}
                            >
                              Add new
                            </Button>
                          </Link>
                        )}
                      </>
                    ) : (
                      <Tooltip
                        arrow
                        title={
                          hasTeamAccess === false ? "Oops! No permission." : ""
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#101828",
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "500",
                              padding: "8px 12px",
                              "& .MuiTooltip-arrow": {
                                color: "#101828",
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <Button
                            component="label"
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: "500",
                              display: "flex",
                              gap: "4px",
                              paddingRight: 0,
                            }}
                            startIcon={<AddIcon />}
                            onClick={openUserLimitPopup}
                            disabled={hasTeamAccess === false}
                          >
                            Add new
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
              gap: "2px",
              paddingRight: !inBilling ? "0" : "20px",
            }}
          >
            {staffListLoading ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height="40px"
                  style={{
                    borderRadius: "2px",
                    marginBottom: "10px",
                    marginRight: "20px",
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  placeholder="Search Teams"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={handleSearch}
                  size="small"
                  sx={{
                    width: !inBilling ? "75%" : "100%",
                    "&.MuiOutlinedInput-root": {
                      paddingLeft: "0 !important",
                    },
                  }}
                />
                {!inBilling ? (
                  <>
                    <div style={{ paddingRight: "20px" }}>
                      <FilterDrawer
                        handleFilterApply={handleFilterApply}
                        handleResetClick={handleResetClick}
                        activeFilter={filterApplied}
                        cancelClick={clearFiltersPopupOpen}
                        closeSetFilter={handleFilterClose}
                      >
                        <div
                          style={{
                            padding: "22px 32px",
                            overflowY: "auto",
                            maxHeight: "calc(100% - 110px)",
                          }}
                        >
                          <FilterChipsWrap>
                            {checkedStaffStatuses.length > 0 &&
                              checkedStaffStatuses.map((item) => (
                                <FilterChips
                                  keyVal={item?.staffStatus}
                                  chipName={
                                    item?.staffStatus === "INACTIVE"
                                      ? "Deactivated"
                                      : item?.staffStatus.toLowerCase()
                                  }
                                  clearValue={item?.staffStatus}
                                  closeFunction={clearCheckedStaffStatus}
                                />
                              ))}
                            {checkedBranches.length > 0 &&
                              checkedBranches.map((item) => (
                                <FilterChips
                                  keyVal={item.id}
                                  chipName={item.branchName}
                                  clearValue={item.id}
                                  closeFunction={clearCheckedBranch}
                                />
                              ))}
                            {checkedRoles.length > 0 &&
                              checkedRoles.map((item, index) => (
                                <FilterChips
                                  keyVal={item.id}
                                  chipName={item.roleName}
                                  clearValue={item.id}
                                  closeFunction={clearCheckedRoles}
                                />
                              ))}
                          </FilterChipsWrap>
                          <FilterTypeWrap>
                            <FilterLabel labelValue="Status" />

                            <FilterCheckBoxWrap>
                              <FormGroup>
                                {[
                                  { status: "ACTIVE" },
                                  { status: "INACTIVE" },
                                ].map((item: any) => (
                                  <FilterCheckbox
                                    keyVal={item.status}
                                    checked={checkedStaffStatuses?.some(
                                      (data) =>
                                        data.staffStatus.toLowerCase() ===
                                        item.status.toLowerCase()
                                    )}
                                    checkboxFunction={() =>
                                      handleFilterStaffStatusChecked(
                                        item.status
                                      )
                                    }
                                    label={
                                      item?.status === "INACTIVE"
                                        ? "Deactivated"
                                        : item.status.toLowerCase()
                                    }
                                  />
                                ))}
                              </FormGroup>
                            </FilterCheckBoxWrap>
                          </FilterTypeWrap>
                          <FilterTypeWrap>
                            <FilterLabel labelValue="Branch" />

                            <FilterSearchField
                              value={branchSearchTerm}
                              onChange={handleBranchSearch}
                              placeholder="Search Branch"
                            />
                            <FilterCheckBoxWrap>
                              <FormGroup>
                                {branchList &&
                                  displayedBranches.map((item: any) => (
                                    <FilterCheckbox
                                      keyVal={item.id}
                                      checked={checkedBranches.some(
                                        (data) => data.id === item.id
                                      )}
                                      checkboxFunction={() =>
                                        handleFilterBranchChecked(
                                          item.id,
                                          item.branchName
                                        )
                                      }
                                      label={item.branchName.toLowerCase()}
                                    />
                                  ))}
                              </FormGroup>
                            </FilterCheckBoxWrap>

                            <ShowAllBtn
                              clickFunction={handleShowMoreBranches}
                              showAll={showAllBranches}
                              filteredState={filteredBranches}
                            />
                          </FilterTypeWrap>

                          <FilterTypeWrap>
                            <FilterLabel labelValue="Role" />

                            <FilterSearchField
                              value={roleSearchTerm}
                              onChange={handleRoleSearch}
                              placeholder="Search Role"
                            />
                            <FilterCheckBoxWrap>
                              <FormGroup>
                                {combinedRoles &&
                                  displayedRoles?.map((item: any) => (
                                    <FilterCheckbox
                                      keyVal={item.id}
                                      checked={checkedRoles.some(
                                        (role) => role.id === item.id
                                      )}
                                      checkboxFunction={() =>
                                        handleFilterRolesChecked(
                                          item.id,
                                          item.roleName
                                        )
                                      }
                                      label={item?.roleName.toLowerCase()}
                                    />
                                  ))}
                              </FormGroup>
                            </FilterCheckBoxWrap>

                            <ShowAllBtn
                              clickFunction={handleShowMore}
                              showAll={showAllRoles}
                              filteredState={filteredRoles}
                            />
                          </FilterTypeWrap>
                        </div>
                      </FilterDrawer>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Box>

          {staffListLoading ? (
            Array.from(new Array(4)).map((_, index) => (
              <div key={index} className={styles.staffCard}>
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="25px"
                  width="100%"
                  style={{
                    marginBottom: "10px",
                    borderRadius: "3px",
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  height="15px"
                  width="100%"
                  style={{
                    // marginBottom: "10px",
                    borderRadius: "3px",
                  }}
                />
              </div>
            ))
          ) : (
            <>
              {staffList && filteredStaffList.length === 0 && (
                <Box
                  sx={{ textAlign: "center", position: "relative", top: "50%" }}
                >
                  <div className={styles.NoResult}>No results found</div>
                  <div className={styles.TryAnother}>Try another search</div>
                </Box>
              )}
              <Box className={styles.staffListWrapper}>
                {filteredStaffList &&
                  filteredStaffList.map((item: any, index: any) => (
                    <div
                      key={index}
                      className={`${styles.staffCard} ${
                        selectedStaffUrlId && item?.id === selectedStaffUrlId
                          ? styles.selectedStaffCard
                          : !selectedStaffUrlId && item.id === selectedStaffId
                          ? styles.selectedStaffCard
                          : ""
                      }`}
                      onClick={() => handleTabClickQuery(item?.id)}
                    >
                      <Box className={styles.staffName}>
                        {item.firstName} {item.lastName}
                      </Box>
                      <Box className={styles.staffDesignation}>{item.role}</Box>
                    </div>
                  ))}
              </Box>{" "}
            </>
          )}
        </Box>
        <Box className={styles.rightSection}>
          {staffListLoading ? (
            <div className={styles.rightSubSection}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #EBEBED",
                  padding: "20px",
                }}
              >
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width="47px"
                    height="47px"
                  />

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="25px"
                      style={{
                        borderRadius: "2px",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="15px"
                      style={{
                        borderRadius: "2px",
                      }}
                    />
                  </div>
                </Box>
              </Box>
              <Box sx={{ padding: "20px" }}>
                <div style={{ display: "flex", gap: "40px" }}>
                  <div>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="25px"
                      style={{
                        borderRadius: "2px",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="15px"
                      style={{
                        borderRadius: "2px",
                      }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="25px"
                      style={{
                        borderRadius: "2px",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="120px"
                      height="15px"
                      style={{
                        borderRadius: "2px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="120px"
                    height="25px"
                    style={{
                      borderRadius: "2px",
                      marginBottom: "10px",
                      display: "block",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="120px"
                    height="15px"
                    style={{
                      borderRadius: "2px",
                    }}
                  />
                </div>
              </Box>
            </div>
          ) : (
            <Box>
              {selectedStaffId &&
              filteredStaffList &&
              filteredStaffList.length > 0 ? (
                <StaffDetails
                  item={filteredStaffList.find((item: any) =>
                    selectedStaffUrlId
                      ? item?.id === selectedStaffUrlId
                      : item.id === selectedStaffId
                  )}
                />
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
      <ClearFilter
        open={clearFilterPopup}
        noButtonClick={clearFiltersPopupClose}
        yesButtonClick={removeFilters}
        onClose={clearFiltersPopupClose}
        filteredValue={filteredValue}
      />
      <StaffReactivatePopup
        isOpen={reactivatePopup}
        onClose={closeReactivatePopup}
        branches={branches}
        staff={filteredStaffList?.find(
          (item: any) => item.id === selectedStaffId
        )}
        UpdateStaffStatus={UpdateStaffStatus}
      />
    </div>
  );
}
