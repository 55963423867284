import { Dialog, TextField } from "@mui/material";
import React from "react";
import closeIcon from "../../../../../../assets/images/close-icon.svg";
import styles from "./EditPopup.module.css";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { updateBatchPayment } from "../../service/Batchslice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";

function TypeEditPopup(props: any) {
  const { isOpen, onClose, typeSelected, batchData, loader } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const [price, setPrice] = React.useState<string>("");
  const [priceError, setPriceError] = React.useState<boolean>(false);
  const payStructure = batchData?.paymentDetails?.paymentData?.paymentStructure;
  const handlePrice = (value: string) => {
    setPrice(value);
    if (value === "") {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
  };

  //  const updatedPaymentStructure = rows?.map((item: any) => ({
  //    frequency: item.emiModeValue,
  //    emiAmount: parseFloat(item.emiPrice),
  //    discountMetric: "",
  //    discount: 0,
  //  }));

  const cancelButtonClick = () => {
    setPrice(typeSelected?.emiAmount);
    setPriceError(false);
    onClose();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = payStructure?.map((item: any) => {
        if (item.frequency === typeSelected?.frequency) {
          let finalFee = parseFloat(price);
          if (item?.discountMetric === "AMOUNT") {
            finalFee = parseFloat(price) - item?.discount;
          } else if (item?.discountMetric === "PERCENTAGE") {
            finalFee =
              parseFloat(price) -
              parseFloat(price) * (1 - item?.discount / 100);
          } else {
            finalFee = parseFloat(price);
          }
          return {
            ...item,
            emiAmount: parseFloat(price),
            finalEmiAmount: finalFee,
          };
        }
        return item;
      });

      const body = {
        batchId: batchData?.id,
        paymentType: "MEMBERSHIP",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        totalAmount: 0,
        scheduleInstallment: false,
        enableInstallment: false,
        numberOfInstallment: 0,
        discountAdded: false,
      };
      if (!priceError) {
        dispatch<any>(updateBatchPayment(body));
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (typeSelected) {
      setPrice(typeSelected?.emiAmount);
    }
  }, [typeSelected]);

  return (
    <>
      <Dialog
        open={isOpen}
        sx={{
          zIndex: "99999",
          minWidth: "408px",
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "408px",
            maxHeight: "400px",
            minHeight: "200px",
            padding: "16px",
            borderRadius: "8px",
            boxShadow:
              "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
            left: "10%",
            background: "#FFFFFF",
            overflow: "hidden",
          },
        }}
      >
        <div>
          <div
            className={styles.popupHead}
            style={{ padding: "0px", marginBottom: "14PX" }}
          >
            <div className={styles.popupHeadTitle}>Edit</div>
            <button className={styles.popupHeadClsBtn} onClick={onClose}>
              <img src={closeIcon} alt="closeIcon" />
            </button>
          </div>
          <div className={styles.membershipEditBody}>
            <div className={styles.membershipEditBodyTop}>
              <div className={styles.membershipEditTypeLabel}>Frequency</div>
              <div className={styles.membershipEditTypeValue}>
                {typeSelected?.frequency?.toLowerCase()?.replace(/_/g, " ")}
              </div>
            </div>
            <div className={styles.membershipEditBodyBottom}>
              <div className={styles.membershipEditTypeFieldLabel}>
                Membership Fees
              </div>
              <div>
                <TextField
                  id="price"
                  variant="outlined"
                  type="number"
                  inputProps={{
                    sx: {
                      width: "240px",
                      "&::placeholder": {
                        fontWeight: "400",
                        fontSize: "14px",
                      },

                      "&.MuiOutlinedInput-input": {
                        padding: "8px",
                      },
                    },
                  }}
                  value={price}
                  onChange={(event) => handlePrice(event.target.value)}
                  error={priceError}
                  helperText={priceError ? "Please fill valid amount" : ""}
                  fullWidth
                />
              </div>
            </div>
          </div>
          <div className={styles.popupBtnSplit}>
            <CancelBtn onClick={() => cancelButtonClick()} />
            <SaveBtn
              onClick={handleSubmit}
              disabled={loader || priceError || parseFloat(price) <= 0}
              loader={loader}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default TypeEditPopup;
