import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import styles from "../components/RolesAndPermission.module.css";
import CustomRole from "./CustomRole";
import SystemRole from "./SystemRole";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import TableSkeleton from "../../../../../../components/Skeletons/TableSkeleton";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

function RolesAndPermission(props: any) {
  const {
    GetRolesByInstitute,
    GetStaffList,
    rolesList,
    DeleteRole,
    deleteCustomRoleSuccess,
    deleteCustomRoleLoading,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { rolesListLoading } = useSelector((state: any) => state.appSettings);
  const { staffList } = useSelector((state: any) => state.staff);

  const systemRoles = rolesList?.systemRoles?.filter(
    (item: any) => item.roleType === "DEFAULT"
  );
  const customRoles = rolesList?.customRoles?.filter(
    (item: any) => item.roleType === "CUSTOM"
  );
  const combinedRoles = rolesList?.systemRoles?.concat(rolesList?.customRoles);

  React.useEffect(() => {
    if (authUser) {
      const userFromLocalStorage = authUser?.institute?.[0]?.instituteDetails;
      GetStaffList(userFromLocalStorage?.id);
      GetRolesByInstitute(userFromLocalStorage?.id);
    }
  }, []);

  React.useEffect(() => {
    if (deleteCustomRoleSuccess !== true && deleteCustomRoleSuccess !== false) {
      if (authUser) {
        const userFromLocalStorage = authUser?.institute?.[0]?.instituteDetails;
        GetStaffList(userFromLocalStorage?.id);
        GetRolesByInstitute(userFromLocalStorage?.id);
      }
    }
  }, [deleteCustomRoleSuccess]);

  return (
    <Box
      sx={{
        padding: "26px 20px 20px 20px",
        height: "calc(100vh - 100px)",
        overflowY: "auto",
      }}
    >
      {rolesListLoading ? (
        <>
          <Box>
            <Skeleton
              animation="wave"
              variant="text"
              height={30}
              width={150}
              sx={{ marginBottom: "6px" }}
            />
            <Skeleton
              animation="wave"
              variant="text"
              height={15}
              width={250}
              sx={{ marginBottom: "10px" }}
            />
          </Box>
        </>
      ) : (
        <>
          <div className={styles.rolesAndPermissionHead}>
            Roles and Permissions
          </div>
          <div className={styles.rolesAndPermissionSubHead}>
            Configure roles to define the different groups of authorities for
            your project.
          </div>
        </>
      )}

      {rolesListLoading ? (
        <>
          <Box>
            <Skeleton
              animation="wave"
              variant="text"
              height={30}
              width={150}
              sx={{ marginBottom: "6px" }}
            />
            <TableSkeleton />
          </Box>
        </>
      ) : (
        <>
          <SystemRole staffList={staffList} systemRoles={systemRoles} />
        </>
      )}

      {rolesListLoading ? (
        <>
          <Box sx={{ marginTop: "30px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              height={30}
              width={150}
              sx={{ marginBottom: "6px" }}
            />
            <TableSkeleton />
          </Box>
        </>
      ) : (
        <Box sx={{ marginTop: "30px" }}>
          <CustomRole
            staffList={staffList}
            customRoles={customRoles}
            DeleteRole={DeleteRole}
            deleteCustomRoleSuccess={deleteCustomRoleSuccess}
            deleteCustomRoleLoading={deleteCustomRoleLoading}
            combinedRoles={combinedRoles}
          />
        </Box>
      )}
    </Box>
  );
}

export default RolesAndPermission;
