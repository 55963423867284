import React, { useState } from "react";
import styles from "./CustomizedField.module.css";
import {
  Box,
  Select,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  MenuItem,
} from "@mui/material";
import { FiEdit } from "react-icons/fi";
import deleteImage from "./Images/delete.svg";
import AddIcon from "@mui/icons-material/Add";
import DeleteQuestionPopup from "../DeleteQuestionPopup/DeleteQuestionPopup";

const courses = [
  {
    courseId: "c1",
    courseName: "Python Basics",
  },

  {
    courseId: "c2",
    courseName: "Sales & Marketing",
  },
  {
    courseId: "c3",
    courseName: "Management",
  },

  {
    courseId: "c4",
    courseName: "Development",
  },
];

function CustomizedCourseField(props: any) {
  const { onFieldChange, field } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleMandatoryChange = (e: any) => {
    const isMandatory = e.target.checked;
    onFieldChange({
      ...field,
      required: isMandatory,
    });
  };

  return (
    <Box className={styles.customizedCourseWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
          padding: "22px 22px 0 22px",
        }}
      >
        <div className={styles.label}>
          {field.fieldName}
          {field.required === true && <span style={{ color: "red" }}>*</span>}
        </div>
        {isEditing ? null : (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FiEdit
              size={16}
              color="#667085"
              style={{ cursor: "pointer" }}
              onClick={handleEditClick}
            />
            <img
              src={deleteImage}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => setShowDeletePopup(!showDeletePopup)}
            />
            {showDeletePopup && (
              <DeleteQuestionPopup
                onClose={() => setShowDeletePopup(false)}
                showDeletePopup={showDeletePopup}
              />
            )}
          </div>
        )}
      </div>
      <FormControl
        fullWidth
        variant="outlined"
        className="formControl"
        size="small"
        disabled={!isEditing}
        sx={{ padding: "0px 22px 22px 22px" }}
      >
        <Select placeholder="Select" label="">
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      {isEditing && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #E6E6E6",
              padding: "0px 22px 6px 22px",
            }}
          >
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Select all Course"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "20px",
                },
              }}
            />
            <Button
              component="label"
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
                display: "flex",
              }}
              startIcon={<AddIcon />}
            >
              Add new
            </Button>
          </div>

          {courses.map((course) => (
            <FormControlLabel
              key={course.courseId}
              control={<Checkbox size="small" />}
              label={course.courseName}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "20px",
                },
                "&.MuiFormControlLabel-root": {
                  padding: "0px 22px",
                },
              }}
            />
          ))}

          <div
            style={{
              borderTop: "1px solid #E6E6E6",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox size="small" onChange={handleMandatoryChange} />
              }
              label="Mandatory"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  color: "#667085",
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  lineHeight: "20px",
                },
                "&.MuiFormControlLabel-root": {
                  padding: "0px 22px",
                },
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
                padding: "14px 22px 22px 22px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "4px",
                  border: "1px solid #BFBFBF",
                  background: "#FFF",
                  color: "#BFBFBF",
                  "&:hover": {
                    border: "1px solid #BFBFBF",
                    background: "#FFF",
                  },
                }}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button variant="contained" sx={{ textTransform: "capitalize" }}>
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
}
export default CustomizedCourseField;
