import { axios_institute_private } from "../../../../../api/setup.intersepter";

import {
  CreateCourseDTO,
  UpdateCourseDescDTO,
  UpdateCoursePaymentDTO,
} from "./types";
export const CreateCourseRequest = (body: CreateCourseDTO) => {
  return axios_institute_private({
    method: "POST",
    url: `/course`,
    data: body,
  });
};

export const GetAllCourseByInstituteRequest = (data: any) => {
  let url = `/course/?instituteId=${data?.id}&status=ALL`;
  if (data?.loadExtraInfo) {
    url += `&loadExtraInfo=${data?.loadExtraInfo}`;
  }
  return axios_institute_private({
    method: "GET",
    url: url,
  });
};

export const UpdateCourseRequest = (body: CreateCourseDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/course`,
    data: body,
  });
};

export const UpdateCourseDescriptionRequest = (body: UpdateCourseDescDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/updateCourseDescription`,
    data: body,
  });
};

export const UpdateCoursePaymentRequest = (body: UpdateCoursePaymentDTO) => {
  return axios_institute_private({
    method: "PUT",
    url: `/course/updatePaymentDetails`,
    data: body,
  });
};

export const GetCourseByIdRequest = (Id: string) => {
  return axios_institute_private({
    method: "GET",
    url: `course/id/${Id}`,
  });
};
