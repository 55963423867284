import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import styles from "./Customize.module.css";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import CountryCodePhoneInput from "../../../../../components/CountryCodePhoneInput/CountryCodePhoneInput";
import FieldFileDropZone from "../../../../../components/FileDropZone/FieldFileDropZone";
import assigneeIcon from "../../..//Nurture/components/Assets/AssigneeDefault.svg";
import defaultFlag from "../../../../../components/FlagAssign/assets/flag-default.svg";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "95%",
    sm: "50%",
    lg: 408,
    xl: 408,
    md: 408,
  },
  bgcolor: "background.paper",
  border: "none",
  boxShadow:
    "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  borderRadius: "12px",
  p: 3,
};

function LeadFormPreview(props: any) {
  const { open, onClose, fields } = props;
  console.log("fields", fields);
  const nameArray = [
    {
      fieldType: "text",
      fieldName: "First name",
      fieldPlaceholder: "Enter",
      options: [],
      required: false,
      isValid: true,
    },
    {
      fieldType: "text",
      fieldName: "Last name",
      fieldPlaceholder: "Enter",
      options: [],
      required: false,
      isValid: true,
    },
  ];

  const contactArray = [
    {
      fieldType: "email",
      fieldName: "Email Address",
      fieldPlaceholder: "Enter",
      options: [],
      required: false,
      isValid: true,
    },
    {
      fieldType: "phone",
      fieldName: "Phone Number",
      fieldPlaceholder: "Enter",
      options: [],
      required: false,
      isValid: true,
    },
  ];

  const courseDetails = [
    {
      fieldType: "select",
      fieldName: "Course",
      fieldPlaceholder: "",
      options: [],
      required: true,
      isValid: true,
    },
    {
      fieldType: "select",
      fieldName: "Branch",
      fieldPlaceholder: "",
      options: [],
      required: true,
      isValid: true,
    },
    {
      fieldType: "select",
      fieldName: "Batch",
      fieldPlaceholder: "",
      options: [],
      required: false,
      isValid: true,
    },
  ];

  const stageData = [
    {
      fieldType: "select",
      fieldName: "",
      fieldPlaceholder: "",
      options: [],
      required: false,
      isValid: true,
    },
  ];

  const sourceData = [
    {
      fieldType: "select",
      fieldName: "",
      fieldPlaceholder: "",
      options: [],
      required: true,
      isValid: true,
    },
  ];

  const renderField = (field: any) => {
    switch (field.fieldType) {
      case "text":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <NewFormTextInput
              require={field.required}
              value={field.fieldValue || ""}
              variant="standard"
              fullWidth
              error={field.required && !field.isValid}
              disabled
            />
          </div>
        );
      case "email":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <NewFormTextInput
              require={field.required}
              value={field.fieldValue || ""}
              variant="standard"
              fullWidth
              error={field.required && !field.isValid}
              disabled
            />
          </div>
        );

      case "phone":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <CountryCodePhoneInput
              key={field.id}
              value={field.fieldValue || ""}
              error={field.isValid}
              disabled
            />
          </div>
        );

      case "radio":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FormControl component="fieldset" key={field.id} disabled>
              <RadioGroup value={field.fieldValue || ""}>
                {field?.options?.map((option: any) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.id}
                    control={
                      <Radio
                        sx={{
                          strokeWidth: 1,
                          color: "#D0D5DD",
                        }}
                      />
                    }
                    label={
                      <div
                        style={{
                          fontSize: "13PX",
                          textTransform: "capitalize",
                        }}
                      >
                        {option?.name}
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "date":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} key={field.id}>
              <DesktopDatePicker
                sx={{
                  zIndex: "99999999 !important",
                  width: "100%",
                  fontSize: "14px",
                  "& .MuiOutlinedInput-input": {
                    fontSize: "14px",
                    color: "#142C44",
                    padding: "9px",
                  },
                }}
                format="DD/MM/YYYY"
                value={field.fieldValue ? dayjs(field.fieldValue) : null}
                disabled
              />
            </LocalizationProvider>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "checkbox":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}{" "}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FormControl component="fieldset" key={field.id} disabled>
              {field?.options?.map((option: any) => (
                <FormControlLabel
                  key={option.id}
                  control={
                    <Checkbox
                      checked={field.fieldValue?.includes(option.id) || false}
                      sx={{
                        strokeWidth: 1,
                        color: "#D0D5DD",
                      }}
                    />
                  }
                  label={
                    <div
                      style={{
                        fontSize: "13PX",
                        textTransform: "capitalize",
                      }}
                    >
                      {option?.name}
                    </div>
                  }
                />
              ))}
            </FormControl>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "select":
        return (
          <div className={styles.additionalFieldWrap}>
            <FormControl key={field.id} fullWidth size="small" disabled>
              <div className={styles.demoFieldLabel}>
                {field.fieldName}
                {field.required === true && (
                  <span style={{ color: "red", margin: "0 4PX" }}>*</span>
                )}
              </div>
              <Select
                value={field.fieldValue || ""}
                sx={{
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                  },
                }}
                error={!field.isValid}
              >
                {field?.options?.map((option: any) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    sx={{
                      color: "#667085",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "file":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FieldFileDropZone field={field} disabled={true} />
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      case "profilePic":
        return (
          <div className={styles.additionalFieldWrap}>
            <div className={styles.demoFieldLabel}>
              {field.fieldName}
              {field.required === true && (
                <span style={{ color: "red", margin: "0 4PX" }}>*</span>
              )}
            </div>
            <FieldFileDropZone field={field} disabled={true} />
            {!field.isValid && field.required && (
              <FormHelperText error>Please fill the field</FormHelperText>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "99999999" }}
      >
        <Box sx={style}>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className={styles.modalTitle}>Preview</div>
              <div className={styles.closeModalButton}>
                <CloseIcon
                  sx={{ color: "#555555", cursor: "pointer" }}
                  onClick={onClose}
                />
              </div>
            </div>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <div className={styles.demoFieldSplit}>
                {nameArray?.map((field: any) => renderField(field))}
              </div>
              <div>{contactArray?.map((field: any) => renderField(field))}</div>
              <div>
                <div className={styles.demoFieldLabel}>Course Details</div>
                {courseDetails?.map((field: any) => renderField(field))}
              </div>
              <div>
                <div className={styles.demoFieldLabel}>Choose stage</div>
                {stageData?.map((field: any) => renderField(field))}
              </div>
              <div className={styles.demoFieldLabel}>Additional Details</div>
              <div>{fields?.map((field: any) => renderField(field))}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "14px",
                padding: "20px  0 0 0",
              }}
            >
              <div
                className={styles.demoSelectField}
                style={{ background: "#fff" }}
              >
                Type Source
              </div>
              <div className={styles.demoCircleField}>
                <img src={assigneeIcon} alt="icon" />
              </div>
              <div className={styles.demoCircleField}>
                <img style={{ width: "50%" }} src={defaultFlag} alt="icon" />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default LeadFormPreview;
