import { useEffect, useState } from 'react';
import { useCountries } from 'use-react-countries';
import { Select, MenuItem } from '@mui/material';

type countryOptions = {
	label: '';
	flag: '';
	currency: '';
};

export default function CurrencyDropDown(props: any) {
	const { initialCurrency, handleUpdateCurrency } = props;
	const { countries } = useCountries();

	const defaultCountry = countries.find(
		(country: any) => country.name == 'India'
	);
	const [selectedCurrency, setSelectedCurrency] = useState<string>(
		initialCurrency || defaultCountry.currencies[0]?.symbol
	);
	const options = countries
		.map((country: any) => {
			const currencySymbol = country.currencies[0]?.symbol;
			console.log('country', country);
			const rtlLanguages = ['Arabic', 'Hebrew', 'Urdu', 'Farsi'].some((lang) =>
				country.languages?.includes(lang)
			);

			return currencySymbol
				? {
						label: country.name,
						flag: country.flags.png,
						currency: rtlLanguages
							? `${currencySymbol}\u200F `
							: currencySymbol,
				  }
				: null;
		})
		.filter((country: any) => country); 

	const handleChange = (event: any) => {
		const selected = options.find(
			(option: any) => option.currency === event.target.value
		);
		setSelectedCurrency(selected.currency);
		handleUpdateCurrency(selected.currency);
	};
	useEffect(() => {
		setSelectedCurrency(
			initialCurrency || defaultCountry.currencies[0]?.symbol
		);
	}, [initialCurrency]);

	return (
		<div>
			<Select
				value={selectedCurrency}
				onChange={handleChange}
				variant='outlined'
				fullWidth
				sx={{
					height: '40px',
				}}
				MenuProps={{
					style: {
						fontSize: '16px',
					},
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					PaperProps: {
						style: {
							marginTop: '10px',
							boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.06)',
							border: '1px solid #D4D4D8',
							borderRadius: '6PX',
							overflowY: 'scroll',
							height: '120px',
							maxHeight: '120px',
						},
					},
				}}>
				{options?.map((option: countryOptions, index: number) => (
					<MenuItem
						key={`${index}${option?.currency}`}
						value={option?.currency}>
						<img
							src={option.flag}
							width='20px'
							height='15px'
							style={{ marginRight: '12px' }}
						/>{' '}
						{option.currency}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}
