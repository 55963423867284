import React, { useRef, useState } from "react";
import UploadFile from "../../UploadFile/UploadFile";
import ClearFilter from "../../../../../../components/ClearFilter/ClearFilter";
import TableSkeleton from "../../../../../../components/Skeletons/TableSkeleton";
import styles from "../SyncedDataTab.module.css";
import setting from "../../../../../../assets/images/setting.svg";
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  FormGroup,
  Checkbox,
  Grid,
  Divider,
  Button,
  Drawer,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useAuthUser } from "react-auth-kit";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import FilterDrawer from "../../../../../../components/FilterDrawer/FilterDrawer";
import FilterChipsWrap from "../../../../../../components/FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../../../../../../components/FilterDrawer/FilterChips/FilterChips";
import FilterSearchField from "../../../../../../components/FilterDrawer/FilterSearchField/FilterSearchField";
import FilterCheckBoxWrap from "../../../../../../components/FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../../../../../../components/FilterDrawer/FilterCheckbox/FilterCheckbox";
import ShowAllBtn from "../../../../../../components/FilterDrawer/ShowAll/ShowAllBtn";
import FilterLabel from "../../../../../../components/FilterDrawer/FilterLabel/FilterLabel";
import FilterTypeWrap from "../../../../../../components/FilterDrawer/FilterTypeWrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetContactList } from "../../../service/inbound.request";
import { setToastNotification } from "../../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useSelector } from "react-redux";
import { resetDeleteContactSuccess } from "../../../service/inboundSlice";
import { resetSaveImportedDataSuccess } from "../../../service/importSlice";
import { PuffLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import CourseMapModel from "../CourseMapModel";
import AddEnquiryModal from "../../../../Nurture/components/AddEnquiryModal";
import deleteIcon from "../../../../../../assets/images/delete.svg";
import ListPopover from "../../../../../../components/ListPopover/ListPopover";
import RemoveContactsModal from "../Remove/RemoveContactsModal";
import {
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";
import getTeamMember, {
  getCombinedAssignees,
} from "../../../../../../components/CommonFunctions/CommonFunctions";
import LeadSidePopup from "../../../../../../components/LeadPopup/LeadSidePopup";
import { getLeadById } from "../../../../Nurture/service/nurtureSlice";
import AddLeadPayment from "../../../../Nurture/components/AddLeadPayment";
import { resetAddEnquirySuccess } from "../../../service/inboundImportSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#fff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ImportTab(props: any) {
  const {
    contactList,
    contactListLoading,
    GetContactLists,
    DeleteContactList,
    deleteContactLoading,
    deleteContactSuccess,
    AddEnquiryForContact,
    page,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  interface CheckedCourse {
    id: string;
    courseName: string;
  }

  interface CheckedPeople {
    id: string;
    name: string;
  }

  const importedSuccess = useSelector(
    (state: any) => state.importData.saveImportedDataSuccess
  );
  const branchList = useSelector((state: any) => state.branch.branchList);
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const batches = useSelector((state: any) => state.batch.batchList);
  const teamMembers = useSelector((state: any) => state.staff.staffList);

  const batchList = batches?.filter(
    (item: any) =>
      item?.batchConductType === "REGULAR_BATCH" && item?.status === "ACTIVE"
  );
  const coursesList = courseList?.filter(
    (item: any) => item?.status === "ACTIVE"
  );

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasImportAccess = getSubDomainAccessStatus(
    "INBOUND",
    "IMPORT",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const hasContactsAccess = getSubDomainAccessStatus(
    "INBOUND",
    "CONTACTS",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const filteredBranchList = hasAllBranchAccess
    ? branchList
    : branchList?.filter(
        (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
      );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F9FAFB",
      color: "#667085",
      padding: hasImportAccess === true ? "10px" : "19px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: hasImportAccess === true ? "10px" : "19px",
    },
  }));

  const [showLeadSidePopup, setShowLeadSidePopup] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterArray, setFilterArray] = useState<any>();
  const importedContacts = filterArray?.returnData?.filter(
    (item: any) =>
      item?.enquirySource === "EXCEL_IMPORT" ||
      item?.enquirySource === "Excel Import"
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [openImportModal, setImportModal] = useState<boolean>(false);

  const [openAddEnquiryModalForImport, setOpenAddEnquiryModalForImport] =
    useState<boolean>(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState<any>();

  const [selectedCourseForMoving, setSelectedCourseForMoving] = useState<any>(
    []
  );
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const [selectedBranch, setSelectedBranch] = useState<any>();
  const [seletedAssignee, setSelectedAssignee] = useState<any>();
  const [selectedBatch, setSelectedBatch] = useState<any>();

  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  const [courseSearchTerm, setCourseSearchTerm] = React.useState("");
  const [showAllCourses, setShowAllCourses] = React.useState<boolean>(false);
  const [importedSearchTerm, setImportedSearchTerm] = useState("");
  const [showAllImportPerson, setShowAllImportPerson] =
    React.useState<boolean>(false);

  const [checkedCourses, setCheckedCourses] = useState<CheckedCourse[]>([]);
  const [checkedImportedByPerson, setCheckedImportedByPerson] = useState<any>(
    []
  );

  const [filterCheckedCourses, setFilterCheckedCourses] = useState<
    CheckedCourse[]
  >([]);
  const [filterCheckedPeople, setFilterCheckedPeople] = useState<any>([]);

  const [openDifferentCourseMappingPopup, setOpenDifferentCourseMappingPopup] =
    useState(false);

  const [courseAnchorEl, setCourseAnchorEl] = useState<any>(null);
  const openCourse = Boolean(courseAnchorEl);
  const [searchCourse, setSearchCourse] = useState<string>("");

  //branch
  const [branchAnchorEl, setBranchAnchorEl] = useState<any>(null);
  const openBranch = Boolean(branchAnchorEl);
  const [searchBranch, setSearchBranch] = useState<string>("");

  //batch
  const [batchAnchorEl, setBatchAnchorEl] = useState<any>(null);
  const openBatch = Boolean(batchAnchorEl);
  const [searchBatch, setSearchBatch] = useState<string>("");

  //assignee
  const [assigneeAnchorEl, setAssigneeAnchorEl] = useState<any>(null);
  const openAssignee = Boolean(assigneeAnchorEl);
  const [searchAssignee, setSearchAssignee] = useState<string>("");

  const [showAddLeadWideView, setAddLeadWideView] = useState(false);

  const combinedAssignees = getCombinedAssignees(
    teamMembers,
    selectedBranch?.id
  );

  const { leadCreatedId, addedEnquiryContactSuccess } = useSelector(
    (state: any) => state.import
  );
  const leadLoading = useSelector(
    (state: any) => state.import.addedEnquiryContactLoading
  );

  React.useEffect(() => {
    if (
      addedEnquiryContactSuccess !== true &&
      addedEnquiryContactSuccess !== false
    ) {
      if (leadCreatedId === undefined || leadCreatedId === null) {
        setOpenAddEnquiryModalForImport(false);
      }
    }
  }, [addedEnquiryContactSuccess, setOpenAddEnquiryModalForImport]);

  React.useEffect(() => {
    if (
      leadCreatedId &&
      addedEnquiryContactSuccess === "Converted lead is created."
    ) {
      setOpenAddEnquiryModalForImport(true);
      setAddLeadWideView(true);
      dispatch(
        setToastNotification({
          message: String("Please add the payment details for conversion"),
          type: "success",
          snackOpen: true,
        })
      );
    }
  }, [addedEnquiryContactSuccess, setAddLeadWideView, leadCreatedId]);

  React.useEffect(() => {
    if (
      addedEnquiryContactSuccess === "Contacts moved!" ||
      addedEnquiryContactSuccess ===
        "OOps! Lead already exists. Please select a new course to continue."
    ) {
      const userFromLocalStorage = authUser?.institute[0];
      const lastEvaluatedKey = "";
      const limit = 15;
      const lastEvaluatedSortKey = "";
      const enquirySource = "Excel Import";
      GetContactLists(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        enquirySource
      );
      dispatch(resetAddEnquirySuccess());
    }
  }, [addedEnquiryContactSuccess]);

  const handleOpenCoursePopover = (event: any) => {
    setCourseAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseCoursePopover = () => {
    setCourseAnchorEl(null);
    setSearchCourse("");
  };

  const handleOpenBranchPopover = (event: any) => {
    setBranchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseBranchPopover = () => {
    setBranchAnchorEl(null);
    setSearchBranch("");
  };
  const courseFieldRef = useRef<HTMLInputElement | null>(null);
  const branchFieldRef = useRef<HTMLInputElement | null>(null);
  const batchFieldRef = useRef<HTMLInputElement | null>(null);
  const assigneeFieldRef = useRef<HTMLInputElement | null>(null);

  const handleOpenBatchPopover = (event: any) => {
    setBatchAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseBatchPopover = () => {
    setBatchAnchorEl(null);
    setSearchBatch("");
  };

  const handleOpenAssigneePopover = (event: any) => {
    setAssigneeAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleCloseAssigneePopover = () => {
    setAssigneeAnchorEl(null);
    setSearchAssignee("");
  };

  const handleCourseSearch = (event: any) => {
    setSearchCourse(event.target.value);
  };

  const handleBranchSearch = (event: any) => {
    setSearchBranch(event.target.value);
  };

  const handleBatchSearch = (event: any) => {
    setSearchBatch(event.target.value);
  };

  const handleAssigneeSearch = (event: any) => {
    setSearchAssignee(event.target.value);
  };

  const handleCourseSelect = (emp: any) => {
    setSelectedCourse(emp);
    handleCloseCoursePopover();
    if (selectedBatch && selectedBatch?.courseId !== emp.id) {
      setSelectedBatch(null);
    }
    if (selectedBranch && selectedBranch?.branchId !== emp.id) {
      setSelectedBatch(null);
    }
  };

  const handleBranchSelect = (emp: any) => {
    setSelectedBranch(emp);
    setSelectedAssignee(null);
    handleCloseBranchPopover();
    if (selectedBatch && selectedBatch?.branchId !== emp.id) {
      setSelectedBatch(null);
    }
  };

  const handleBatchSelect = (emp: any) => {
    setSelectedBatch(emp);
    handleCloseBatchPopover();
  };

  const handleAssigneeSelect = (emp: any) => {
    setSelectedAssignee(emp);
    handleCloseAssigneePopover();
  };

  const filteredCourses = courseList?.filter((item: any) => {
    const searchTermMatch =
      item?.courseName
        ?.toLowerCase()
        ?.indexOf(courseSearchTerm?.toLowerCase()) !== -1;

    return searchTermMatch;
  });

  const displayedCourses = showAllCourses
    ? courseList && filteredCourses
    : courseList && filteredCourses.slice(0, 5);

  const handleShowMoreCourses = () => {
    setShowAllCourses(!showAllCourses);
  };

  const unique = importedContacts?.filter(
    (obj: any, index: any, self: any) =>
      index ===
      self?.findIndex((el: any) => el?.createdByName === obj?.createdByName)
  );

  const filteredImported =
    filterArray &&
    unique?.filter((item: any) => {
      const searchTermMatch =
        item?.createdByName
          ?.toLowerCase()
          .indexOf(importedSearchTerm.toLowerCase()) !== -1;

      return searchTermMatch;
    });

  const displayedImportedByPeople = showAllImportPerson
    ? unique && filteredImported
    : unique && filteredImported?.slice(0, 5);

  const handleShowMoreImportPerson = () => {
    setShowAllImportPerson(!showAllImportPerson);
  };

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
    const queryParams = new URLSearchParams(window.location.search);
    if (event.target.value.trim() === "") {
      queryParams.delete("importSearchTerm");
    } else {
      queryParams.set("importSearchTerm", event.target.value);
    }

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const handleCheckboxToggle = (contactId: string) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        const updatedContacts = prevSelectedContacts.filter(
          (id) => id !== contactId
        );
        setIsCheckboxChecked(updatedContacts.length > 0);
        return updatedContacts;
      } else {
        setIsCheckboxChecked(true);
        return [...prevSelectedContacts, contactId];
      }
    });
  };

  const handleCourseToggle = (row: any) => {
    setSelectedCourseForMoving((prevSelectedCourse: any) => {
      if (prevSelectedCourse.includes(row)) {
        const updatedCourses = prevSelectedCourse?.filter(
          (ds: any) => ds?.id !== row?.id
        );
        setIsCheckboxChecked(updatedCourses.length > 0);
        return updatedCourses;
      } else {
        setIsCheckboxChecked(true);
        return [...prevSelectedCourse, row];
      }
    });
  };

  const deleteContacts = () => {
    const userFromLocalStorage = authUser?.institute[0];
    if (selectedContacts?.length <= 25) {
      const data = {
        instituteId:
          userFromLocalStorage?.instituteId &&
          userFromLocalStorage?.instituteId,
        studentContactEntryIds: selectedContacts,
        enquirySource: "EXCEL_IMPORT",
      };
      DeleteContactList(data);
    } else {
      dispatch(
        setToastNotification({
          message: String("You cannot Delete more than 25 contacts"),
          type: "error",
          snackOpen: true,
        })
      );
    }
  };

  const handleSelectedRow = (val: any) => {
    // if (val?.tag === "LEAD_EXISTS") {
    //   dispatch<any>(getLeadById("22ba777f-3360-48ea-8346-7d46ceef1b0e"));
    //   setShowLeadSidePopup(true);
    // } else {
    //   setSelectedRowDetails(val);
    //   setOpenAddEnquiryModal(true);
    // }
    setSelectedRowDetails(val);
    setOpenAddEnquiryModalForImport(true);
  };

  const handleMoveCourse = () => {
    const studentData = selectedCourseForMoving?.map((item: any) => {
      return {
        studentFirstName: item?.firstName?.replace(/[^a-zA-Z]/g, ""),
        studentEmail: item?.email,
        studentLastName: item?.lastName?.replace(/[^a-zA-Z]/g, ""),
        studentContact: `${item?.contactNoCountryCode}${item?.contactNumber}`,
        contactCountryCode: item?.contactNoCountryCode,
        studentContactId: item?.id,
        otherDetails: [],
        assignedToId: seletedAssignee?.id,
        assignedToName: `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`,
      };
    });
    const userFromLocalStorage = authUser?.institute[0];
    const payloadForContactsAddEnquiry = {
      studentList: studentData,
      deleteContact: true,
      createdByName: `${authUser?.firstName} ${authUser?.lastName}`,
      status: "CREATED",
      createdById: authUser?.institute?.[0]?.id,
      messageId: "",
      courseId: selectedCourse?.id,
      courseName: selectedCourse?.courseName,
      instituteId: userFromLocalStorage?.instituteId,
      instituteName: userFromLocalStorage?.instituteName,
      assignedToId: seletedAssignee?.id,
      assignedToName: `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`,
      createdTime: 0,
      enquiryPriority: "NORMAL",
      enquirySource: "EXCEL_IMPORT",
      enquiryStatus: "NEW_ENQUIRY",
      branchName: selectedBranch?.branchName,
      batchId: selectedBatch?.id,
      branchId: selectedBranch?.id,
      batchName: selectedBatch?.batchName,
    };
    AddEnquiryForContact(payloadForContactsAddEnquiry);
  };

  const handleNextPage = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.SK?.S
    );
    const lastEvaluatedSortKey = encodeURIComponent(
      filterArray?.lastEvaluatedKeyReceived?.GSI3SK?.S
    );
    const id = userFromLocalStorage.instituteId;
    const enquirySource = "Excel Import";

    if (lastEvaluatedKey) {
      GetContactList(
        id,
        lastEvaluatedKey,
        20,
        lastEvaluatedSortKey,
        enquirySource
      )
        .then((res) => {
          setHasMoreData(true);
          setFilterArray((prevItems: any) => ({
            returnData: [
              ...prevItems?.returnData,
              ...res?.data?.data?.returnData,
            ],
            lastEvaluatedKeyReceived: res?.data?.data?.lastEvaluatedKeyReceived,
          }));
        })
        .catch((err: any) => {});
    }
  };

  React.useEffect(() => {
    setFilterArray(contactList);
  }, [contactList]);

  React.useEffect(() => {
    if (
      filterArray &&
      filterArray?.lastEvaluatedKeyReceived &&
      filterArray?.lastEvaluatedKeyReceived?.SK !== null &&
      checkedCourses?.length === 0 &&
      checkedImportedByPerson?.length === 0 &&
      searchTerm?.length === 0
    ) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [filterArray, checkedCourses, checkedImportedByPerson, searchTerm]);

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const handleFilterLeads = React.useCallback(
    (courseArray: any, importedPeopleArray: any) => {
      setFilterCheckedPeople(importedPeopleArray);
      setFilterCheckedCourses(courseArray);

      const queryParams = new URLSearchParams(window.location.search);
      if (courseArray && courseArray.length > 0) {
        const courseParam = courseArray
          .map((course: any) => course.id)
          .join("|");
        queryParams.set("filteredCourses", courseParam);
      } else {
        queryParams.delete("filteredCourses");
      }

      if (importedPeopleArray && importedPeopleArray.length > 0) {
        const importedByParam = importedPeopleArray
          .map((item: any) => item.id)
          .join("|");
        queryParams.set("filteredImportedBy", importedByParam);
      } else {
        queryParams.delete("filteredImportedBy");
      }

      const queryString = queryParams.toString();
      const newUrl = `${window.location.pathname}${
        queryString ? `?${queryString}` : ""
      }`;

      window.history.pushState({}, "", newUrl);
    },

    [setFilterCheckedPeople, setFilterCheckedCourses]
  );

  const handleResetClick = React.useCallback(() => {
    handleFilterLeads([], []);
    setCheckedImportedByPerson([]);
    setCheckedCourses([]);
    setFilterApplied(false);
  }, [
    handleFilterLeads,
    setCheckedCourses,
    setFilterApplied,
    setCheckedImportedByPerson,
  ]);

  const filteredValue = (
    <>
      {filterCheckedCourses?.length > 0 &&
        filterCheckedCourses?.map((item) => (
          <div key={item.id} className="filteredDiv">
            {item.courseName}
          </div>
        ))}
      {filterCheckedPeople?.length > 0 &&
        filterCheckedPeople?.map((item: any) => (
          <div key={item.id} className="filteredDiv">
            {item.name}
          </div>
        ))}
    </>
  );

  const handleFilterApply = () => {
    handleFilterLeads(checkedCourses, checkedImportedByPerson);
    setFilterApplied(
      checkedCourses?.length === 0 && checkedImportedByPerson?.length === 0
        ? false
        : true
    );
  };

  const handleFilterBranchChecked = (id: string, courseName: string) => {
    const branchToAdd: CheckedCourse = { id, courseName };
    if (checkedCourses.some((branch) => branch.id === id)) {
      setCheckedCourses(checkedCourses.filter((branch) => branch.id !== id));
    } else {
      setCheckedCourses([...checkedCourses, branchToAdd]);
    }
  };

  const clearCheckedBranch = (id: string) => {
    setCheckedCourses(checkedCourses.filter((item) => item.id !== id));
  };

  const handleFilterPeopleChecked = (id: string, name: string) => {
    const branchToAdd: CheckedPeople = { id, name };
    if (checkedImportedByPerson.some((branch: any) => branch.id === id)) {
      setCheckedImportedByPerson(
        checkedImportedByPerson.filter((branch: any) => branch.id !== id)
      );
    } else {
      setCheckedImportedByPerson([...checkedImportedByPerson, branchToAdd]);
    }
  };

  const clearCheckedPeople = (id: string) => {
    setCheckedImportedByPerson(
      checkedImportedByPerson.filter((item: any) => item.id !== id)
    );
  };

  const filteredContactList =
    contactList && filterArray?.returnData
      ? importedContacts?.filter((contact: any) => {
          const searchTermLower = searchTerm?.toLowerCase();
          const fullName = `${contact?.firstName} ${contact?.lastName}`;
          const nameMatch = fullName?.toLowerCase().includes(searchTermLower);
          const emailMatch = contact?.email
            .toLowerCase()
            .includes(searchTermLower);
          const phoneMatch =
            searchTermLower === "" ||
            (contact?.contactNumber &&
              contact?.contactNumber?.toString()?.includes(searchTermLower));

          const courseStatusIsChecked =
            filterCheckedCourses.length === 0 ||
            filterCheckedCourses.some((item) => item.id === contact.courseId);

          const importedByUserIsChecked =
            filterCheckedPeople.length === 0 ||
            filterCheckedPeople.some(
              (item: any) =>
                item?.name?.toLowerCase() ===
                contact?.createdByName?.toLowerCase()
            );

          return (
            (nameMatch || emailMatch || phoneMatch) &&
            courseStatusIsChecked &&
            importedByUserIsChecked
          );
        })
      : [];

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleFilterLeads([], []);
    setCheckedImportedByPerson([]);
    setCheckedCourses([]);
    setFilterApplied(false);
    setIsOpenFilter(false);
  };

  const handleFilterClose = React.useCallback(() => {
    setCheckedImportedByPerson(filterCheckedPeople);
    setCheckedCourses(filterCheckedCourses);
  }, [filterCheckedPeople, filterCheckedCourses]);

  const noMoveCourseButtonClick = () => {
    setOpenDifferentCourseMappingPopup(false);
  };

  const yesMoveCourseButtonClick = () => {
    handleMoveCourse();
    setOpenDifferentCourseMappingPopup(false);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const verifyChangeCourse = () => {
    const validationCOurses = selectedCourseForMoving?.map((d: any) => {
      return selectedCourse?.id === d?.courseId;
    });
    if (
      !validationCOurses?.includes(false) ||
      selectedCourseForMoving[0]?.courseId === "" ||
      selectedCourse?.id === selectedCourseForMoving[0]?.courseId
    ) {
      handleMoveCourse();
    } else {
      setOpenDifferentCourseMappingPopup(true);
    }
  };

  const filteredBatches = batchList?.filter((batch: any) => {
    const courseMatch =
      selectedCourse?.id === "" || batch.courseId === selectedCourse?.id;
    const branchMatch =
      selectedBranch?.id === "" || batch.branchId === selectedBranch?.id;
    const regularBatch =
      batch.batchConductType === "REGULAR_BATCH" &&
      batch.batchStatus !== "COMPLETED" &&
      batch.status !== "INACTIVE";

    return courseMatch && branchMatch && regularBatch;
  });

  function getFormattedDate(date: any) {
    return moment(date)?.format("MMM Do YYYY, hh:mm A");
  }

  const handleSettingsClick = () => {
    const currentPath = window.location.pathname;
    const redirectUrl = currentPath.includes("Contacts")
      ? "/app/inbound/Settings/ImportSetting"
      : "/app/inbound/Settings/Import";
    navigate(redirectUrl);
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const courseParam = urlSearchParams.get("filteredCourses");
    const importedByParam = urlSearchParams.get("filteredImportedBy");
    const importSearchParam = urlSearchParams.get("importSearchTerm");

    if (importSearchParam) {
      setSearchTerm(importSearchParam);
    }

    if (courseParam && courseList) {
      const courseIds = courseParam.split("|");

      const selectedCourse = courseIds
        .map((id) => {
          const course = coursesList?.find((course: any) => course.id === id);
          return course ? { id, courseName: course.courseName } : null;
        })
        .filter(Boolean) as CheckedCourse[];

      setFilterCheckedCourses(selectedCourse);
    }

    if (importedByParam && importedContacts) {
      const importedByIds = importedByParam.split("|");

      const selectedPeople = importedByIds
        .map((id) => {
          const importedByPerson = importedContacts?.find(
            (people: any) => people.id === id
          );
          return importedByPerson ? { id, name: importedByPerson.name } : null;
        })
        .filter(Boolean) as CheckedPeople[];

      setFilterCheckedPeople(selectedPeople);
    }
    if (courseParam || importedByParam) {
      setFilterApplied(true);
    }
  }, [courseList, filterArray]);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 15;
    const lastEvaluatedSortKey = "";
    const enquirySource = "Excel Import";
    if (deleteContactSuccess) {
      GetContactLists(
        userFromLocalStorage?.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey,
        enquirySource
      );
    }
  }, [deleteContactSuccess]);

  React.useEffect(() => {
    if (addedEnquiryContactSuccess) {
      // setOpenAddEnquiryModal(false);
      setIsCheckboxChecked(false);
      setSelectedBatch(null);
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedAssignee(null);
      setSelectedCourseForMoving([]);
      setSelectedContacts([]);
      // dispatch(resetAddEnquirySuccess());
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [addedEnquiryContactSuccess]);

  React.useEffect(() => {
    if (deleteContactSuccess) {
      setOpenDeleteModal(false);
      setIsCheckboxChecked(false);
      setSelectedContacts([]);
      setIsCheckboxChecked(false);
      setSelectedCourseForMoving([]);
      setSelectedBatch(null);
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedAssignee(null);
      dispatch(resetDeleteContactSuccess());
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [deleteContactSuccess]);

  React.useEffect(() => {
    if (importedSuccess) {
      dispatch(resetSaveImportedDataSuccess());
    }
  }, [importedSuccess]);

  React.useEffect(() => {
    if (selectedCourseForMoving?.length === 0) {
      setSelectedCourse(null);
      setSelectedBranch(null);
      setSelectedBatch(null);
      setSelectedAssignee(null);
      setSearchCourse("");
      setSearchBranch("");
      setSearchBatch("");
      setSearchAssignee("");
    }
  }, [selectedCourseForMoving]);

  const contactTags = importedContacts?.filter(
    (item: any) => item?.tag === "CONTACT" || item?.tag === ""
  );

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "0px 20px 30px 20px",
        }}
      >
        {contactList?.returnData?.length === 0 ? (
          <>
            <Box
              style={{
                border: "1px solid #E3E3E3",
                width: "100%",
                padding: "30px",
                boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
                borderRadius: "6px",
              }}
            >
              <div
                style={{
                  border: "1px solid #d0d5dd",
                  background: "#FFFFFF",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  cursor: "pointer",
                  width: "50px",
                }}
              >
                <Tooltip
                  arrow
                  title={
                    (page === "IMPORT" && hasImportAccess === false) ||
                    (page === "CONTACTS" && hasContactsAccess === false)
                      ? "Oops! No permission."
                      : ""
                  }
                  sx={{ width: "100%" }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      paddingTop="7px"
                      paddingBottom="7px"
                      paddingLeft="7px"
                      onClick={() =>
                        (page === "IMPORT" && hasImportAccess === false) ||
                        (page === "CONTACTS" && hasContactsAccess === false)
                          ? null
                          : handleSettingsClick()
                      }
                      paddingRight="7px"
                    >
                      <img src={setting} alt="" />
                    </Box>
                  </span>
                </Tooltip>
              </div>
              <div
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#667085",
                  marginBottom: "22px",
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                <div style={{ fontSize: "20px", fontWeight: "600" }}>
                  Import
                </div>
                <br />
                View and map all your non-associated enquiries from contacts
              </div>
              <div
                className={styles.editFormPopupBtnsSplit}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip
                  arrow
                  title={
                    (page === "IMPORT" && hasImportAccess === false) ||
                    (page === "CONTACTS" && hasContactsAccess === false)
                      ? "Oops! No permission."
                      : ""
                  }
                  sx={{ width: "100%" }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span
                    style={{
                      width: "100%",
                      display: "block",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      sx={{
                        maxWidth: "200px",
                        boxShadow: "none",
                        textTransform: "capitalize",
                      }}
                      onClick={() => setImportModal(true)}
                      disabled={
                        (page === "IMPORT" && hasImportAccess === false) ||
                        (page === "CONTACTS" && hasContactsAccess === false)
                      }
                    >
                      Import Contacts
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </Box>
          </>
        ) : (
          <>
            {!isCheckboxChecked ? (
              <div
                className={styles.funnelHeadSplit}
                style={{ justifyContent: "space-between" }}
              >
                <div className={styles.funnelHeadSplitTitle}>Import</div>
                <div className={styles.funnelHeadSplitRight}>
                  <div className={styles.funnelHeadSplitSearch}>
                    <TextField
                      className={styles.importSearch}
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearch}
                      inputProps={{
                        style: {
                          background: "#ffffff",
                          padding: "8px 12px",
                        },
                      }}
                      type="search"
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid #d0d5dd",
                      background: "#FFFFFF",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      height: "38px",
                    }}
                  >
                    <Tooltip
                      arrow
                      title={
                        (page === "IMPORT" && hasImportAccess === false) ||
                        (page === "CONTACTS" && hasContactsAccess === false)
                          ? "Oops! No permission."
                          : ""
                      }
                      sx={{ width: "100%" }}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#101828",
                            color: "#fff",
                            fontSize: "12px",
                            fontWeight: "500",
                            padding: "8px 12px",
                            "& .MuiTooltip-arrow": {
                              color: "#101828",
                            },
                          },
                        },
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          display: "block",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          paddingTop="7px"
                          paddingBottom="7px"
                          paddingLeft="7px"
                          onClick={() =>
                            (page === "IMPORT" && hasImportAccess === false) ||
                            (page === "CONTACTS" && hasContactsAccess === false)
                              ? null
                              : handleSettingsClick()
                          }
                          paddingRight="7px"
                        >
                          <img src={setting} alt="" />
                        </Box>
                      </span>
                    </Tooltip>
                  </div>
                  <FilterDrawer
                    handleFilterApply={handleFilterApply}
                    handleResetClick={handleResetClick}
                    activeFilter={filterApplied}
                    cancelClick={cancelClick}
                    closeSetFilter={handleFilterClose}
                  >
                    <div
                      style={{
                        padding: "22px 32px",
                        overflowY: "auto",
                        maxHeight: "calc(100% - 110px)",
                      }}
                    >
                      <FilterChipsWrap>
                        {checkedCourses.length > 0 &&
                          checkedCourses.map((item: any) => (
                            <>
                              <FilterChips
                                keyVal={item.id}
                                chipName={item.courseName}
                                clearValue={item.id}
                                closeFunction={clearCheckedBranch}
                              />
                            </>
                          ))}
                        {checkedImportedByPerson.length > 0 &&
                          checkedImportedByPerson.map((item: any) => (
                            <>
                              <FilterChips
                                keyVal={item.id}
                                chipName={item.name}
                                clearValue={item.id}
                                closeFunction={clearCheckedPeople}
                              />
                            </>
                          ))}
                      </FilterChipsWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Course" />

                        <FilterSearchField
                          value={courseSearchTerm}
                          onChange={(e: any) => {
                            setCourseSearchTerm(e.target.value);
                          }}
                          placeholder="Search Course"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {courseList &&
                              displayedCourses?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedCourses.some(
                                    (data) => data.id === item.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterBranchChecked(
                                      item.id,
                                      item.courseName
                                    )
                                  }
                                  label={item.courseName.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreCourses}
                          showAll={showAllCourses}
                          filteredState={filteredCourses}
                        />
                      </FilterTypeWrap>
                      <FilterTypeWrap>
                        <FilterLabel labelValue="Imported by" />

                        <FilterSearchField
                          value={importedSearchTerm}
                          onChange={(e: any) => {
                            setImportedSearchTerm(e.target.value);
                          }}
                          placeholder="Search name"
                        />
                        <FilterCheckBoxWrap>
                          <FormGroup>
                            {filterArray &&
                              displayedImportedByPeople?.map((item: any) => (
                                <FilterCheckbox
                                  keyVal={item.id}
                                  checked={checkedImportedByPerson.some(
                                    (data: any) => data.id === item.id
                                  )}
                                  checkboxFunction={() =>
                                    handleFilterPeopleChecked(
                                      item.id,
                                      item.createdByName
                                    )
                                  }
                                  label={item.createdByName.toLowerCase()}
                                />
                              ))}
                          </FormGroup>
                        </FilterCheckBoxWrap>
                        <ShowAllBtn
                          clickFunction={handleShowMoreImportPerson}
                          showAll={showAllImportPerson}
                          filteredState={filteredImported}
                        />
                      </FilterTypeWrap>
                    </div>
                  </FilterDrawer>

                  <Tooltip
                    arrow
                    title={
                      (page === "IMPORT" && hasImportAccess === false) ||
                      (page === "CONTACTS" && hasContactsAccess === false)
                        ? "Oops! No permission."
                        : ""
                    }
                    sx={{ width: "100%" }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => setImportModal(true)}
                        disabled={
                          (page === "IMPORT" && hasImportAccess === false) ||
                          (page === "CONTACTS" && hasContactsAccess === false)
                        }
                      >
                        Import Contacts
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div className={styles.funnelHeadSplit}>
                <div className={styles.funnelHeadSplitRight}>
                  <div style={{ color: "#667085", fontSize: "14px" }}>
                    Mapping:
                  </div>
                  <Box
                    sx={{
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "4px",
                      borderRadius: "8px",
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      sx={{
                        "&.MuiGrid-root": {
                          width: "calc(100% + 12px )",
                        },
                      }}
                    >
                      <Grid item>
                        <>
                          <TextField
                            onClick={handleOpenCoursePopover}
                            value={
                              selectedCourse ? selectedCourse?.courseName : ""
                            }
                            variant="outlined"
                            size="small"
                            placeholder="Choose Course"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              textTransform: "capitalize",
                              "& .MuiOutlinedInput-root input": {
                                textTransform: "capitalize",
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                              "& .MuiOutlinedInput-root fieldset": {
                                border: "none",
                                outline: "none",
                                textTransform: "capitalize",
                                "&:hover fieldset": {
                                  border: "none",
                                },
                              },
                              width: "150px",
                            }}
                            inputRef={courseFieldRef}
                          />
                          <ListPopover
                            openState={openCourse}
                            anchorState={courseAnchorEl}
                            handleClosePopover={handleCloseCoursePopover}
                            handleListSearch={handleCourseSearch}
                            popupList={coursesList?.filter((item: any) =>
                              item?.courseName
                                ?.toLowerCase()
                                ?.includes(searchCourse?.toLowerCase())
                            )}
                            handleItemSelect={handleCourseSelect}
                            listType={"COURSE"}
                          />
                        </>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        sx={{
                          width: "1px",
                          height: "25px",
                          marginTop: "10px",
                        }}
                      />
                      <Grid item>
                        <>
                          <TextField
                            onClick={handleOpenBranchPopover}
                            value={
                              selectedBranch ? selectedBranch?.branchName : ""
                            }
                            variant="outlined"
                            size="small"
                            placeholder="Choose Branch"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root input": {
                                textTransform: "capitalize",
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                              "& .MuiOutlinedInput-root fieldset": {
                                border: "none",
                                outline: "none",
                                textTransform: "capitalize",
                                "&:hover fieldset": {
                                  border: "none",
                                },
                              },
                              width: "150px",
                            }}
                            inputRef={branchFieldRef}
                          />
                          <ListPopover
                            openState={openBranch}
                            anchorState={branchAnchorEl}
                            handleClosePopover={handleCloseBranchPopover}
                            handleListSearch={handleBranchSearch}
                            popupList={filteredBranchList?.filter((form: any) =>
                              form?.branchName
                                .toLowerCase()
                                .includes(searchBranch?.toLowerCase())
                            )}
                            handleItemSelect={handleBranchSelect}
                            listType={"BRANCH"}
                          />
                        </>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        sx={{
                          width: "1px",
                          height: "25px",
                          marginTop: "10px",
                        }}
                      />
                      <Grid item>
                        <>
                          <TextField
                            onClick={handleOpenBatchPopover}
                            value={
                              selectedBatch ? selectedBatch?.batchName : ""
                            }
                            variant="outlined"
                            size="small"
                            placeholder="Choose Batch"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              ),
                            }}
                            inputRef={batchFieldRef}
                            sx={{
                              "& .MuiOutlinedInput-root input": {
                                textTransform: "capitalize",
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                              "& .MuiOutlinedInput-root fieldset": {
                                border: "none",
                                outline: "none",
                                textTransform: "capitalize",
                                "&:hover fieldset": {
                                  border: "none",
                                },
                              },
                              width: "150px",
                            }}
                          />
                          <ListPopover
                            openState={openBatch}
                            anchorState={batchAnchorEl}
                            handleClosePopover={handleCloseBatchPopover}
                            handleListSearch={handleBatchSearch}
                            popupList={filteredBatches?.filter((item: any) =>
                              item?.batchName
                                .toLowerCase()
                                .includes(searchBatch.toLowerCase())
                            )}
                            handleItemSelect={handleBatchSelect}
                            listType={"BATCH"}
                          />
                        </>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        sx={{
                          width: "1px",
                          height: "25px",
                          marginTop: "10px",
                        }}
                      />
                      <Grid item>
                        <>
                          <TextField
                            onClick={handleOpenAssigneePopover}
                            value={
                              seletedAssignee
                                ? `${seletedAssignee?.firstName} ${seletedAssignee?.lastName}`
                                : ""
                            }
                            variant="outlined"
                            size="small"
                            placeholder="Choose Assignee"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root input": {
                                textTransform: "capitalize",
                                color: "#667085",
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                              "& .MuiOutlinedInput-root fieldset": {
                                border: "none",
                                outline: "none",
                                "&:hover fieldset": {
                                  border: "none",
                                },
                              },
                              width: "150px",
                            }}
                            inputRef={assigneeFieldRef}
                          />
                          <ListPopover
                            openState={openAssignee}
                            anchorState={assigneeAnchorEl}
                            handleClosePopover={handleCloseAssigneePopover}
                            handleListSearch={handleAssigneeSearch}
                            popupList={
                              combinedAssignees?.filter((item: any) => {
                                const fullName = `${item?.firstName ?? ""} ${
                                  item?.lastName ?? ""
                                }`.toLowerCase();
                                return fullName?.includes(
                                  searchAssignee?.toLowerCase()
                                );
                              })
                              // ?.filter((user: any) => {
                              //   return (
                              //     user?.leadCardAccess === true &&
                              //     user?.status === "ACTIVE"
                              //   );
                              // })
                            }
                            handleItemSelect={handleAssigneeSelect}
                            listType={"ASSIGNEE"}
                          />
                        </>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={verifyChangeCourse}
                          disabled={
                            selectedCourse === undefined ||
                            selectedBranch === undefined ||
                            selectedCourse === null ||
                            selectedBranch === null ||
                            seletedAssignee === undefined ||
                            seletedAssignee === null ||
                            leadLoading
                          }
                          sx={{ textTransform: "capitalize" }}
                        >
                          {leadLoading ? (
                            <CircularProgress size={18} color={"inherit"} />
                          ) : (
                            "Move"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Tooltip
                    arrow
                    title={
                      (page === "IMPORT" && hasImportAccess === false) ||
                      (page === "CONTACTS" && hasContactsAccess === false)
                        ? "Oops! No permission."
                        : ""
                    }
                    sx={{ width: "100%" }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <button
                        onClick={() => {
                          setOpenDeleteModal(true);
                        }}
                        style={{
                          cursor: "pointer",
                          border: "1px solid #D0D5DD",
                          padding: "4px",
                          borderRadius: "8px",
                          marginLeft: "10px",
                          height: "50px",
                          width: "50px",
                          display: "flex",
                          justifyContent: "center",
                          background: "#fff",
                          alignItems: "center",
                        }}
                        disabled={
                          (page === "IMPORT" && hasImportAccess === false) ||
                          (page === "CONTACTS" && hasContactsAccess === false)
                        }
                      >
                        <img src={deleteIcon} width="20px" />
                      </button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
            <div style={{ marginTop: "20px" }}>
              {contactListLoading ? (
                <TableSkeleton />
              ) : (
                <>
                  {contactList &&
                  contactList?.returnData &&
                  contactList?.returnData?.length > 0 &&
                  filterArray &&
                  importedContacts?.length > 0 ? (
                    <TableContainer
                      className={styles.importTableContainer}
                      sx={{
                        minWidth: 700,
                        borderRadius: "8PX",
                        border: "1px solid  #EAECF0",
                        maxHeight: "calc(100vh - 200px)",
                        minHeight: "calc(100vh - 200px)",
                        overflowY: "hidden",
                      }}
                    >
                      <InfiniteScroll
                        dataLength={
                          filterArray?.returnData
                            ? importedContacts?.length
                            : contactList?.returnData?.length
                        }
                        next={handleNextPage}
                        hasMore={hasMoreData}
                        loader={
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <PuffLoader color="#36d7b7" />
                          </Box>
                        }
                        // height={navigator.userAgent.includes("Windows") ? 530 : 700}
                        //   height={
                        //     navigator.userAgent.includes("Windows")
                        //       ? "calc(100vh - 200px)"
                        //       : 700
                        //   }
                        height={"calc(100vh - 215px)"}
                      >
                        <Table
                          sx={{
                            minWidth: 700,
                          }}
                          aria-label="customized table"
                        >
                          <TableHead
                            className={styles.batchPopupHead}
                            sx={{
                              position: "sticky",
                              top: 0,
                              zIndex:
                                openDeleteModal || openAddEnquiryModalForImport
                                  ? 1
                                  : 999,
                            }}
                          >
                            <TableRow>
                              <StyledTableCell
                                className={styles.batchPopupHeadCell}
                              >
                                <Checkbox
                                  sx={{
                                    strokeWidth: 1,
                                    color: "rgba(102, 112, 133, 0.30)",
                                  }}
                                  checked={
                                    selectedContacts?.length ===
                                    importedContacts?.length
                                  }
                                  onChange={() => {
                                    if (
                                      selectedContacts?.length ===
                                      importedContacts?.length
                                    ) {
                                      setSelectedContacts([]);
                                      setIsCheckboxChecked(false);
                                      setSelectedCourseForMoving([]);
                                      setSelectedCourse(null);
                                      setSelectedBranch(null);
                                      setSelectedBatch(null);
                                    } else {
                                      const allEmailIds = importedContacts?.map(
                                        (item: any) => item.id
                                      );
                                      const allContacts = importedContacts?.map(
                                        (item: any) => item
                                      );
                                      setSelectedContacts(allEmailIds);
                                      setIsCheckboxChecked(true);
                                      setSelectedCourseForMoving(allContacts);
                                    }
                                  }}
                                  // disabled={contactTags?.length === 0}
                                />
                              </StyledTableCell>
                              <StyledTableCell
                                className={styles.batchPopupHeadCell}
                              >
                                Name
                              </StyledTableCell>

                              <StyledTableCell
                                className={styles.cellEmail}
                                align="left"
                                sx={{ maxWidth: "370px" }}
                              >
                                Email Address
                              </StyledTableCell>
                              <StyledTableCell
                                className={styles.cellPhone}
                                align="left"
                              >
                                Phone number
                              </StyledTableCell>
                              <StyledTableCell
                                className={styles.batchPopupHeadCell}
                                align="left"
                              >
                                Course
                              </StyledTableCell>
                              {/* <StyledTableCell
                              className={styles.batchPopupHeadCell}
                              align="left"
                            >
                              Source
                            </StyledTableCell> */}
                              <StyledTableCell
                                className={styles.cellImport}
                                align="left"
                                style={{ fontSize: "13px" }}
                              >
                                Imported by
                              </StyledTableCell>

                              <StyledTableCell
                                className={styles.cellDate}
                                align="left"
                              >
                                Date & Time
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filterArray?.returnData !== null &&
                              filteredContactList?.map(
                                (row: any, index: any) => (
                                  <StyledTableRow
                                    key={index}
                                    className={styles.batchPopupColumnCells}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <StyledTableCell component="th" scope="row">
                                      <Checkbox
                                        sx={{
                                          strokeWidth: 1,
                                          color: "rgba(102, 112, 133, 0.30)",
                                        }}
                                        checked={selectedContacts?.includes(
                                          row.id
                                        )}
                                        onChange={() => {
                                          handleCheckboxToggle(row.id);
                                          handleCourseToggle(row);
                                        }}
                                        // disabled={row?.tag === "LEAD_EXIST"}
                                      />
                                    </StyledTableCell>

                                    <StyledTableCell
                                      align="left"
                                      sx={{
                                        color: "#667085",
                                        minWidth: "110px",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={() => {
                                        handleSelectedRow(row);
                                      }}
                                    >
                                      {row?.firstName || row?.lastName
                                        ? row?.firstName + " " + row?.lastName
                                        : "-"}

                                      {row?.tag === "LEAD" ? (
                                        <span
                                          style={{
                                            color: "#11C89B",
                                            fontSize: "10px",
                                            background:
                                              "rgba(17, 200, 155, 0.08)",
                                            padding: "3px",
                                            borderRadius: "4px",
                                            marginLeft: "2px",
                                          }}
                                        >
                                          Lead
                                        </span>
                                      ) : row?.tag === "LEAD_EXISTS" ||
                                        row?.tag === "CONTACT_EXISTS" ? (
                                        <span
                                          style={{
                                            color: "#11C89B",
                                            fontSize: "10px",
                                            background:
                                              "rgba(17, 200, 155, 0.08)",
                                            padding: "3px",
                                            borderRadius: "4px",
                                            marginLeft: "2px",
                                          }}
                                        >
                                          Lead Exists
                                        </span>
                                      ) : row?.tag === "DUPLICATE" ? (
                                        <span
                                          style={{
                                            color: "#EBA418",
                                            fontSize: "10px",
                                            background: "#FBECD0",
                                            padding: "3px",
                                            borderRadius: "4px",
                                            marginLeft: "2px",
                                          }}
                                        >
                                          Duplicate
                                        </span>
                                      ) : row?.tag === "TWIN_LEAD" ? (
                                        <span
                                          style={{
                                            color: "#11C89B",
                                            fontSize: "10px",
                                            background:
                                              "rgba(17, 200, 155, 0.08)",
                                            padding: "3px",
                                            borderRadius: "4px",
                                            marginLeft: "2px",
                                          }}
                                        >
                                          Twin Lead
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {/* {row?.tag === "LEAD_EXISTS" && (
                                        <span
                                          style={{
                                            color: "#EBA418",
                                            fontSize: "10px",
                                            background: "#FBECD0",
                                            padding: "3px",
                                            borderRadius: "4px",
                                            marginLeft: "2px",
                                          }}
                                        >
                                          Lead Exists
                                        </span>
                                      )} */}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      sx={{
                                        color: "#667085",
                                        //   maxWidth: "300px",
                                      }}
                                      onClick={() => {
                                        handleSelectedRow(row);
                                      }}
                                    >
                                      {row?.email ? row?.email : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      sx={{
                                        color: "#667085",
                                      }}
                                      onClick={() => {
                                        handleSelectedRow(row);
                                      }}
                                    >
                                      {/* {row?.contactNumber?.toString()?.length >
                                      10
                                        ? row?.contactNumber
                                        : row?.contactNumber?.toString()
                                            ?.length === 10
                                        ? `+91 ${row?.contactNumber}`
                                        : "-"} */}
                                      {row?.contactNumber
                                        ? `+${row?.contactNoCountryCode} ${row?.contactNumber}`
                                        : ""}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      sx={{
                                        color: "#667085",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={() => {
                                        handleSelectedRow(row);
                                      }}
                                    >
                                      {row?.courseName ? row?.courseName : "-"}
                                    </StyledTableCell>
                                    {/* <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                    textTransform: "capitalize",
                                  }}
                                  onClick={() => {
                                    handleSelectedRow(row);
                                  }}
                                >
                                  {row?.enquirySource
                                    ? row?.enquirySource
                                    : "-"}
                                </StyledTableCell> */}
                                    <StyledTableCell
                                      align="left"
                                      sx={{
                                        color: "#667085",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={() => {
                                        handleSelectedRow(row);
                                      }}
                                    >
                                      {row?.createdByName
                                        ? getTeamMember(
                                            row?.createdById,
                                            teamMembers
                                          )?.firstName +
                                          " " +
                                          getTeamMember(
                                            row?.createdById,
                                            teamMembers
                                          )?.lastName
                                        : "-"}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                      sx={{
                                        color: "#667085",
                                      }}
                                      onClick={() => {
                                        handleSelectedRow(row);
                                      }}
                                    >
                                      {row?.createdTime
                                        ? getFormattedDate(row?.createdTime)
                                        : "-"}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                        {filteredContactList &&
                        filteredContactList?.length <= 0 ? (
                          <>
                            {searchTerm?.length > 0 ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                    color: " #667085",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "2px",
                                    justifyContent: "center",
                                    marginTop: "50px",
                                  }}
                                >
                                  <div style={{ color: " #142C44" }}>
                                    No results found.
                                  </div>
                                  Try another search
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                  color: " #667085",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "2px",
                                  justifyContent: "center",
                                  marginTop: "50px",
                                }}
                              >
                                <div style={{ color: " #142C44" }}>
                                  No results found.
                                </div>
                                Try another Filter
                              </div>
                            )}
                          </>
                        ) : (
                          <> </>
                        )}
                      </InfiniteScroll>
                    </TableContainer>
                  ) : (
                    <></>
                  )}
                  {/* Render null if conditions are not met */}
                </>
              )}
            </div>
          </>
        )}

        <RemoveContactsModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          deleteConfirm={deleteContacts}
          loading={deleteContactLoading}
        />
        {openImportModal && (
          <UploadFile
            setImportModal={setImportModal}
            openImportModal={openImportModal}
            page={page}
            {...props}
          />
        )}
        <ClearFilter
          open={isOpenFilter}
          noButtonClick={noButtonClick}
          yesButtonClick={yesButtonClick}
          onClose={closeClearFilter}
          filteredValue={filteredValue}
        />
        <CourseMapModel
          open={openDifferentCourseMappingPopup}
          noButtonClick={noMoveCourseButtonClick}
          yesButtonClick={yesMoveCourseButtonClick}
          setOpenDifferentCourseMappingPopup={
            setOpenDifferentCourseMappingPopup
          }
        />
        <LeadSidePopup
          show={showLeadSidePopup}
          onHide={() => setShowLeadSidePopup(false)}
        />
      </div>
      {openAddEnquiryModalForImport && (
        <AddLeadPayment
          show={openAddEnquiryModalForImport}
          selectedValue={selectedRowDetails && selectedRowDetails?.email}
          messageId={selectedRowDetails && selectedRowDetails?.id}
          onHide={() => {
            setOpenAddEnquiryModalForImport(false);
          }}
          nurtureAddEnquiry={AddEnquiryForContact}
          isEmailDetail={false}
          leadCreateSuccess={
            addedEnquiryContactSuccess && addedEnquiryContactSuccess
          }
          isSourceWidth={false}
          isFromContacts={true}
          selectedRowDetails={selectedRowDetails}
          page={"CONTACT"}
          showAddLeadWideView={showAddLeadWideView}
          setAddLeadWideView={setAddLeadWideView}
          leadCreatedId={leadCreatedId}
          leadCreateLoading={leadLoading}
        />
      )}
    </>
  );
}

export default ImportTab;
