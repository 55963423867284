import React from "react";
import styles from "../PaymentType.module.css";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  FormControlLabel,
  Switch,
  SwitchProps,
  Tooltip,
} from "@mui/material";
import PaymentEnablePopup from "./PaymentEnablePopup";
import PaymentDisablePopup from "./PaymentDisablePopup";
import { studentAutoDebit } from "../../../service/nurtureSlice";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#23A67B",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function LeadPaymentAutoDebit(props: any) {
  const { leadData, paymentType } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { autoDebitSuccess, autoDebitLoading } = useSelector(
    (state: any) => state.nurture
  );
  const instituteData = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const hasNurturePay =
    instituteData &&
    Array.isArray(instituteData.integrationsOpted) &&
    instituteData?.integrationsOpted?.includes("NURTURE_PAY_EASEBUZZ");

  const [autoDebitEnablePopup, setAutoDebitEnablePopup] =
    React.useState<boolean>(false);
  const [autoDebitDisablePopup, setAutoDebitDisablePopup] =
    React.useState<boolean>(false);
  const [autoDebitStatus, setAutoDebitStatus] = React.useState<boolean>(false);

  const openAutoEnablePopup = () => {
    setAutoDebitEnablePopup(true);
  };
  const closeAutoEnablePopup = () => {
    setAutoDebitEnablePopup(false);
  };

  const openAutoDebitDisablePopup = () => {
    setAutoDebitDisablePopup(true);
  };
  const closeAutoDebitDisablePopup = () => {
    setAutoDebitDisablePopup(false);
  };

  const handleChangeCheck = React.useCallback(
    async (event: any) => {
      // setAutoDebitStatus(!autoDebitStatus);
      const value = event.target.checked;
      if (!value) {
        openAutoDebitDisablePopup();
      } else {
        openAutoEnablePopup();
      }
    },

    []
  );

  const studentAutoDebitEnableSubmit = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      const data = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        enquiryId: leadData?.id,
      };
      dispatch<any>(studentAutoDebit({ data, actionType: "enabled" }));
    },
    [leadData]
  );
  const studentAutoDebitDisableSubmit = React.useCallback(
    async (event: any) => {
      event.preventDefault();
      const data = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        enquiryId: leadData?.id,
      };

      dispatch<any>(studentAutoDebit({ data, actionType: "disabled" }));
    },
    [leadData]
  );

  React.useEffect(() => {
    if (autoDebitSuccess !== true && autoDebitSuccess !== false) {
      setAutoDebitEnablePopup(false);
      setAutoDebitDisablePopup(false);
    }
  }, [autoDebitSuccess]);

  React.useEffect(() => {
    if (leadData?.autoDebit) {
      if (
        leadData?.autoDebit === "ENABLED" ||
        leadData?.autoDebit === "UNDER_PROCESSING"
      ) {
        setAutoDebitStatus(true);
      } else {
        setAutoDebitStatus(false);
      }
    }
  }, [leadData]);

  return (
    <>
      <div
        className={styles.leadAutoDebitBox}
        style={{
          padding: paymentType === "RECURRING" ? "20PX 0PX" : "20PX 30PX",
        }}
      >
        <div className={styles.leadAutoDebitBoxChild}>
          <div className={styles.leadAutoDebitBoxLeft}>
            <div className={styles.leadAutoDebitTitle}>Auto Debit</div>
            <div className={styles.leadAutoDebitDesc}>
              You need to first activate the auto debit facility from the
              payment issuer’s app.
            </div>
          </div>
          <div className={styles.leadAutoDebitBoxRight}>
            <FormControl
              fullWidth
              variant="standard"
              className="formControl"
              sx={{ width: "auto" }}
            >
              <Tooltip
                title={
                  hasLeadAccess === false
                    ? "Oops! No permission."
                    : !hasNurturePay
                    ? "Please complete nurture pay integration"
                    : leadData?.studentContact === ""
                    ? "Please add Phone number to get this feature"
                    : leadData?.studentEmail === ""
                    ? "Please add email to get this feature"
                    : leadData?.autoDebit === "UNDER_PROCESSING"
                    ? "Pending"
                    : ""
                }
                placement="bottom"
                arrow
                PopperProps={{ style: { zIndex: 9999999 } }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      zIndex: "9999999",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                        zIndex: "9999999",
                      },
                    },
                  },
                }}
              >
                <FormControlLabel
                  style={{
                    justifyContent: "flex-end",
                    marginLeft: 0,
                    color: "#3F3E43",
                    marginTop: "0px",
                    gap: 20,
                  }}
                  control={
                    <IOSSwitch
                      sx={{ margin: "1px" }}
                      checked={autoDebitStatus}
                      id="studentAutoDebit"
                      onChange={(e) => {
                        handleChangeCheck(e);
                      }}
                      disabled={
                        hasLeadAccess === false
                          ? true
                          : false ||
                            !hasNurturePay ||
                            leadData?.studentEmail === "" ||
                            leadData?.autoDebit === "UNDER_PROCESSING" ||
                            leadData?.studentContact === ""
                      }
                    />
                  }
                  label=""
                  labelPlacement="start"
                />
              </Tooltip>
            </FormControl>
          </div>
        </div>
      </div>
      <PaymentEnablePopup
        open={autoDebitEnablePopup}
        onClose={closeAutoEnablePopup}
        yesButtonClick={studentAutoDebitEnableSubmit}
        loader={autoDebitLoading}
      />
      <PaymentDisablePopup
        open={autoDebitDisablePopup}
        onClose={closeAutoDebitDisablePopup}
        yesButtonClick={studentAutoDebitDisableSubmit}
        loader={autoDebitLoading}
      />
    </>
  );
}

export default LeadPaymentAutoDebit;
