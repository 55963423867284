import React, { useEffect, useRef, useState } from "react";
import styles from "./LeadCardDropdown.module.css";
import lostIcon from "./Assets/lost-red.svg";
import threeDot from "../Assets/3dot.svg";
import threeActiveDot from "../Assets/3dotActive.svg";
import activeIcon from "./Assets/active-profile.svg";

export default function LeadCardDropdown(props: any) {
  const {
    UpdateLeadSwimlaneStatus,
    leadDetails,
    UpdateLeadEnquiyStatus,
    authUser,
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownChildOpen, setIsDropdownChildOpen] = useState(false);
  const [selectedOptionState, setSelectedOptionState] = useState(
    leadDetails && leadDetails.enquirySwimlaneStatus
  );
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLeadStatusSelect = React.useCallback(
    (event: any, status: any, leadId: string) => {
      UpdateLeadEnquiyStatus({
        id: leadId,
        status: status,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        updatedById: authUser?.institute[0]?.id,
      });
    },
    [UpdateLeadEnquiyStatus]
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
        setIsDropdownChildOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={styles.LeadCardDropdownWrap} ref={dropdownRef}>
      <button
        onClick={() => toggleDropdown()}
        className={styles.LeadCardDropdownBtn}
      >
        <img
          src={isHovered ? threeActiveDot : threeDot}
          alt=""
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </button>

      {isDropdownOpen && (
        <ul className={styles.LeadCardDropdownListWrap}>
          {leadDetails.status === "ACTIVE" ? (
            <li
              className={styles.LeadCardDropdownListItem}
              onClick={(event) => {
                handleLeadStatusSelect(event, "LOST", leadDetails?.id);
                setIsDropdownChildOpen(false);
                setIsDropdownOpen(false);
              }}
            >
              <div className={styles.LeadCardDropdownListItemLeft}>
                <div className={styles.LeadCardDropdownListItemLeftIcon}>
                  <img src={lostIcon} alt="" />
                </div>
                <div className={styles.LeadCardDropdownListItemLeftLabel}>
                  Lost
                </div>
              </div>
            </li>
          ) : (
            <li
              className={styles.LeadCardDropdownListItem}
              onClick={(event) => {
                handleLeadStatusSelect(event, "ACTIVE", leadDetails?.id);
                setIsDropdownChildOpen(false);
                setIsDropdownOpen(false);
              }}
            >
              <div className={styles.LeadCardDropdownListItemLeft}>
                <div className={styles.LeadCardDropdownListItemLeftIcon}>
                  <img src={activeIcon} alt="" />
                </div>
                <div
                  style={{ color: "#11C89B" }}
                  className={styles.LeadCardDropdownListItemLeftLabel}
                >
                  Active
                </div>
              </div>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
