import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Modal,
  CircularProgress,
} from "@mui/material";
import React from "react";
import styles from "../../ManageBranch/components/Managebranches.module.css";
import { Box } from "@mui/system";
import closeIcon from "../../ManageBranch/components/close.svg";
import { useSelector } from "react-redux";

function StaffReactivatePopup(props: any) {
  const { isOpen, onClose, branches, staff, UpdateStaffStatus } = props;
  const { updateStaffLoading } = useSelector((state: any) => state.staff);

  const [staffDetails, setStaffDetails] = React.useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    role: "",
    roleId: "",
    branchName: "",
    branchId: "",
    instituteId: "",
    instituteName: "",
    status: "ACTIVE",
    isFirstTeamMember: false,
    id: "",
    createdUser: "",
    createdUserId: "",
    contactCountryCode: "91",
    isBillingAdmin: false,
  });

  const defaultOption = branches
    ? branches?.find(
        (branch: any) => branch?.branchName === staffDetails?.branchName
      )
    : "";

  const onSelectChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setStaffDetails({
          ...staffDetails,
          branchId: value.id,
          branchName: value.branchName,
          status: "ACTIVE",
        });
        setValidStateBranch(true);
      } else {
        setStaffDetails({
          ...staffDetails,
          branchId: "",
          branchName: "",
          status: "ACTIVE",
        });
        setValidStateBranch(false);
      }
    },
    [staffDetails]
  );

  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      !staffDetails.branchId && setValidStateBranch(false);
      staffDetails.role &&
        staffDetails.branchId &&
        staffDetails.firstName &&
        staffDetails.lastName &&
        staffDetails.status === "ACTIVE" &&
        UpdateStaffStatus(staffDetails);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (staff) {
      setStaffDetails(staff);
    }
  }, [staff]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: "95%",
      sm: "50%",
      lg: 408,
      xl: 408,
      md: 408,
    },
    bgcolor: "background.paper",
    border: "none",
    boxShadow:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
    borderRadius: "12px",
    p: 3,
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.branchLimitPopupWrap}>
            <div className={styles.closeModalBtnWrap}>
              <button onClick={onClose} className={styles.closeModalBtn}>
                <img src={closeIcon} alt="closeIcon" />
              </button>
            </div>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "28px",
                color: "#101828",
                marginBottom: "10px",
              }}
            >
              Please choose a branch to continue
            </div>
            <FormControl
              fullWidth
              variant="outlined"
              className="formControl"
              size="small"
              error={!validStateBranch}
            >
              {branches && (
                <Autocomplete
                  disablePortal
                  id="branchId"
                  options={branches}
                  getOptionLabel={(option: any) => option.branchName || ""}
                  fullWidth
                  onChange={onSelectChange}
                  size="small"
                  value={defaultOption || null}
                  renderInput={(params) => {
                    if (!defaultOption) {
                      params.InputProps.endAdornment = null;
                    }
                    return (
                      <TextField
                        label="Select Branch"
                        variant="outlined"
                        error={!validStateBranch}
                        {...params}
                      />
                    );
                  }}
                  sx={{
                    zIndex: 99999999,
                  }}
                  componentsProps={{
                    popper: {
                      sx: {
                        zIndex: 99999999,
                      },
                    },
                  }}
                />
              )}
            </FormControl>
            {validStateBranch === false && (
              <FormHelperText error={true}>Field required</FormHelperText>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6px",
                marginTop: "24px",
              }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "#BFBFBF",
                  border: "1px solid #BFBFBF",
                }}
                variant="outlined"
                onClick={onClose}
                fullWidth
              >
                Cancel
              </Button>

              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                disabled={updateStaffLoading}
              >
                {updateStaffLoading ? (
                  <CircularProgress size={20} color={"inherit"} />
                ) : (
                  "Reactivate"
                )}
              </Button>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default StaffReactivatePopup;
