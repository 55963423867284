import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import {
  EmailConfigData,
  ImapSyncData,
  InboundInitialStateDTO,
} from "../service/types";
import {
  getMailDetailsById,
  getMailEntriesByInstitute,
  imapSyncEmail,
} from "../service/inboundSlice";
import { CreateSourceDTO, NurtureLead } from "../../Nurture/service/types";
import {
  createSource,
  getLeadById,
  getLeadListByInstituteAndContact,
  getNurtureLeadBoard,
  nurtureLead,
} from "../../Nurture/service/nurtureSlice";
import EmailDetails from "../components/EmailDetails/EmailDetails";
import { getBranchList } from "../../Manage/ManageBranch/service/Branchslice";
import {
  getAllCourseByInstituteRequest,
  getCourseByIdRequest,
} from "../../Manage/ManageCourse/service/courseSlice";
import {
  getBatchById,
  getBatchList,
} from "../../Manage/ManageBatch/service/Batchslice";
import { getInstituteById } from "../../Manage/InstituteProfile/service/instituteSlice";

interface StateProps extends InboundInitialStateDTO {}

interface DispatchProps {
  CreateEnquiry(data: NurtureLead): void;
  GetMailDetailsById(id: string): void;
  GetBranchList(id: string): void;
  GetCourseList(id: string): void;
  GetBatchList(data: any): void;
  GetNurtureLeadBoard(id: string): void;
  GetMailEntriesByInstitute(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string
  ): void;
  GetLeadById(id: string): void;
  ImapEmailSync(data: ImapSyncData): void;
  GetInstituteById(id: string): void;
  GetCourseById(id: string): void;
  GetBatchById(id: string): void;
  CreateSource(data: CreateSourceDTO): void;
  GetLeadListByInstituteAndContact(data: any): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.inbound.success,
    error: state.inbound.error,
    loading: state.inbound.loading,
    mailList: state.inbound.mailList,
    mailById: state.inbound.mailById,
    mailConfigsList: state.inbound.mailConfigsList,
    importCsvData: state.inbound.importCsvData,
    templateData: state.inbound.templateData,
    importHistoryData: state.inbound.importHistoryData,
    contactList: state.inbound.contactList,
    downloadHistoryData: state.inbound.downloadHistoryData,

    emailConfigSuccess: state.inbound.emailConfigSuccess,
    emailConfigError: state.inbound.emailConfigError,
    emailConfigLoading: state.inbound.emailConfigLoading,
    deleteEmailSuccess: state.inbound.deleteEmailSuccess,
    deleteEmailError: state.inbound.deleteEmailError,
    deleteEmailLoading: state.inbound.deleteEmailLoading,
    imapSyncLoading: state.inbound.imapSyncLoading,
    imapSyncSuccess: state.inbound.imapSyncSuccess,
    imapSyncError: state.inbound.imapSyncError,
    emailDetailsLoading: state.inbound.emailDetailsLoading,
    emailDetailsSuccess: state.inbound.emailDetailsSuccess,
    emailDetailsError: state.inbound.emailDetailsError,
    templateDownloadError: state.inbound.templateDownloadError,
    templateDownloadLoading: state.inbound.templateDownloadLoading,
    templateDownloadSuccess: state.inbound.templateDownloadSuccess,
    importCsvLoading: state.inbound.importCsvLoading,
    importCsvSuccess: state.inbound.importCsvSuccess,
    importCsvError: state.inbound.importCsvError,
    contactListLoading: state.inbound.contactListLoading,
    contactListSuccess: state.inbound.contactListSuccess,
    contactListError: state.inbound.contactListError,
    deleteContactLoading: state.inbound.deleteContactLoading,
    deleteContactSuccess: state.inbound.deleteContactSuccess,
    deleteContactError: state.inbound.deleteContactError,
    addedEnquiryContactLoading: state.inbound.addedEnquiryContactLoading,
    addedEnquiryContactSuccess: state.inbound.addedEnquiryContactSuccess,
    addedEnquiryContactError: state.inbound.addedEnquiryContactError,
    otherEmailConfigLoading: state.inbound.otherEmailConfigLoading,
    otherEmailConfigSuccess: state.inbound.otherEmailConfigSuccess,
    otherEmailConfigError: state.inbound.otherEmailConfigError,
    importHistoryLoading: state.inbound.importHistoryLoading,
    downloadHistoryDataSuccess: state.inbound.downloadHistoryDataSuccess,
    downloadHistoryDataError: state.inbound.downloadHistoryDataError,
    downloadHistoryDataLoading: state.inbound.downloadHistoryDataLoading,
    inboundDeactivateError: state.inbound.inboundDeactivateError,
    inboundDeactivateSuccess: state.inbound.inboundDeactivateSuccess,
    inboundDeactivateLoading: state.inbound.inboundDeactivateLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateEnquiry: (data: NurtureLead) => {
      dispatch(nurtureLead(data));
    },

    GetMailDetailsById: (id: string) => {
      dispatch(getMailDetailsById(id));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    GetBatchList: (data: string) => {
      dispatch(getBatchList(data));
    },
    GetNurtureLeadBoard: (id: string) => {
      dispatch(getNurtureLeadBoard(id));
    },
    GetMailEntriesByInstitute: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string
    ) => {
      dispatch(
        getMailEntriesByInstitute({
          id,
          lastEvaluatedKey,
          limit,
          lastEvaluatedSortKey,
        })
      );
    },
    GetLeadById: (id: string) => {
      dispatch(getLeadById(id));
    },
    ImapEmailSync: (data: ImapSyncData) => {
      dispatch(imapSyncEmail(data));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
    GetCourseById: (id: string) => {
      dispatch(getCourseByIdRequest(id));
    },
    GetBatchById: (id: string) => {
      dispatch(getBatchById(id));
    },
    CreateSource: (data: CreateSourceDTO) => {
      dispatch(createSource(data));
    },
    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
  };
};
export interface EmailDetailsProps extends StateProps, DispatchProps {}
export const EmailDetailsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailDetails);
