import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

const CourseDetailSkeleton = () => {
  return (
    <div className={styles.courseDetailsWrap}>
      <div className={styles.courseHeader}>
        <Skeleton animation="wave" variant="text" width="20%" height={50} />
      </div>
      <div className={styles.courseBasicInfo}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: 10,
          }}
        >
          <Skeleton animation="wave" variant="text" width="33%" height={50} />
          <Skeleton animation="wave" variant="text" width="33%" height={50} />
          <Skeleton animation="wave" variant="text" width="33%" height={50} />
        </div>
      </div>
    </div>
  );
};

export default CourseDetailSkeleton;
