import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import { DeactivateInboundData } from "../service/types";
import {
  deactivateInbound,
  downloadHistoryData,
  getImportHistory,
  getMailConfigsList,
  getWhatsappConfigInstitute,
  whatsappNotificationChange,
} from "../service/deactivateInboundSlice";
import Settings from "../components/Settings/Settings";

interface StateProps {
  inboundDeactivateError: string | boolean | undefined;
  inboundDeactivateSuccess: boolean;
  inboundDeactivateLoading: boolean;
  importHistorySuccess: boolean;
  importHistoryError: boolean;
  importHistoryLoading: boolean;
  importHistoryData: [];
  downloadHistoryDataSuccess: boolean;
  downloadHistoryDataError: boolean;
  downloadHistoryDataLoading: boolean;
  downloadHistoryData: [];
  whatsappNotificationSuccess: boolean;
  whatsappNotificationLoading: boolean;
  whatsappNotificationError: boolean;
  whatsappConfigInstituteSuccess: boolean;
  whatsappConfigInstituteLoading: boolean;
  whatsappConfigInstituteError: boolean;
  whatsappConfigInstituteData: [];
}

interface DispatchProps {
  DeactivateInbound(data: DeactivateInboundData): void;
  GetMailConfigList(id: string): void;
  GetImportHistory(
    id: string,
    lastEvaluatedKey: string,
    limit: number,
    lastEvaluatedSortKey: string
  ): void;
  DownloadHistoryData(data: any): void;
  WhatsappNotificationChange(data: any): void;
  GetWhatsappConfigInstitute(id: string, status: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    inboundDeactivateError: state.settings.inboundDeactivateError,
    inboundDeactivateSuccess: state.settings.inboundDeactivateSuccess,
    inboundDeactivateLoading: state.settings.inboundDeactivateLoading,
    importHistorySuccess: state.settings.importHistorySuccess,
    importHistoryError: state.settings.importHistoryError,
    importHistoryLoading: state.settings.importHistoryLoading,
    importHistoryData: state.settings.importHistoryData,
    downloadHistoryDataSuccess: state.settings.downloadHistoryDataSuccess,
    downloadHistoryDataError: state.settings.downloadHistoryDataError,
    downloadHistoryDataLoading: state.settings.downloadHistoryDataLoading,
    downloadHistoryData: state.settings.downloadHistoryData,
    whatsappNotificationSuccess: state.settings.whatsappNotificationSuccess,
    whatsappNotificationLoading: state.settings.whatsappNotificationLoading,
    whatsappNotificationError: state.settings.whatsappNotificationError,
    whatsappConfigInstituteSuccess:
      state.settings.whatsappConfigInstituteSuccess,
    whatsappConfigInstituteLoading:
      state.settings.whatsappConfigInstituteLoading,
    whatsappConfigInstituteError: state.settings.whatsappConfigInstituteError,
    whatsappConfigInstituteData: state.settings.whatsappConfigInstituteData,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    DeactivateInbound: (data: DeactivateInboundData) => {
      dispatch(deactivateInbound(data));
    },
    GetMailConfigList: (data: any) => {
      dispatch(getMailConfigsList(data));
    },
    GetImportHistory: (
      id: string,
      lastEvaluatedKey: string,
      limit: number,
      lastEvaluatedSortKey: string
    ) => {
      dispatch(
        getImportHistory({ id, lastEvaluatedKey, limit, lastEvaluatedSortKey })
      );
    },
    DownloadHistoryData: (data: any) => {
      dispatch(downloadHistoryData(data));
    },
    WhatsappNotificationChange: (data: any) => {
      dispatch(whatsappNotificationChange(data));
    },
    GetWhatsappConfigInstitute: (id: string, status: string) => {
      dispatch(getWhatsappConfigInstitute({ id, status }));
    },
  };
};

export interface SettingsProps extends StateProps, DispatchProps {}
export const SettingsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
