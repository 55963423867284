import { Box, Modal } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../../Home.module.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "5px",
  p: 3,
  zIndex: 999999999,
};

function LogoutModal(props: any) {
  const { open, onClosePopup, logoutCall } = props;
  return (
    <>
      <Modal
        open={open}
        onClose={onClosePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 9999999999 }}
      >
        <Box sx={style}>
          <div>
            <div className={styles.editFormPopupCloseWrap}>
              <button
                className={styles.editFormPopupCloseBtn}
                onClick={onClosePopup}
              >
                <CancelIcon />
              </button>
            </div>

            <div className={styles.editFormPopupTitle}>
              Are you sure you want to logout ?
            </div>

            <div className={styles.editFormPopupBtnsSplit}>
              <button
                className={styles.editFormPopupDiscardBtn}
                onClick={() => {
                  onClosePopup();
                }}
              >
                cancel
              </button>
              <button
                className={styles.editFormPopupSaveBtn}
                onClick={() => {
                  logoutCall();
                  onClosePopup();
                }}
              >
                logout
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default LogoutModal;
