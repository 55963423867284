import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import getTeamMember from "../CommonFunctions/CommonFunctions";

function ActivityStage(props: any) {
  const { data, employees } = props;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityTop}>
            <div className={styles.activityDateStyle}>
              {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
            </div>
            <div className={styles.activityHeadStage}>
              <div className={styles.activityHeadStageIcon}>
                <img src={stageDot} alt="" />
              </div>
              <div>{data?.currentStage}</div>
            </div>
            <div className={styles.activityHeadName}>
              <div className={styles.activityHeadStageIcon}>
                <img src={nameLine} alt="" />
              </div>
              <div style={{ textTransform: "capitalize" }}>
                {data?.updatedByName === ""
                  ? getTeamMember(data?.createdById, employees)?.firstName +
                    " " +
                    getTeamMember(data?.createdById, employees)?.lastName
                  : getTeamMember(data?.updatedById, employees)?.firstName +
                    " " +
                    getTeamMember(data?.updatedById, employees)?.lastName}
              </div>
            </div>
          </div>
          <div className={styles.activityDemoTitle}>Stage Changed</div>

          <div
            className={styles.activityStageDesc}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <div className={styles.activityStageDesc}>
              {data?.userName + " "} changed from
            </div>
            <div className={styles.activityStageBox}>{data?.previousStage}</div>
            <div>to</div>
            <div className={styles.activityStageBox}>{data?.currentStage}</div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ActivityStage;
