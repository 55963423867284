import styles from "./CustomFilterAccordion.module.css";
import accordionUp from "../../assets/images/accordion-up.svg";
import accordionDown from "../../assets/images/accordion-down.svg";

const CustomFilterAccordion = ({
  title,
  isOpen,
  setIsOpen,
  children,
  count,
  disabled,
}: any) => {
  return (
    <>
      <div
        onClick={() => (disabled ? "" : setIsOpen(!isOpen))}
        className={styles.accordionCOntainer}
        style={{
          opacity: disabled ? "0.5" : "1",
        }}
      >
        <div className={styles.accordionHeader}>
          <div className={styles.accordionTitle}>{title}</div>
          <div className={styles.countContainer}>
            {count > 0 && (
              <div className={styles.appliedFilter}>{`+${count}`}</div>
            )}
            <img src={isOpen ? accordionUp : accordionDown} alt="" />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={styles.accordionDetailsContainer}>{children}</div>
      )}
    </>
  );
};

export default CustomFilterAccordion;
