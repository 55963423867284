import {
  Button,
  CircularProgress,
  Dialog,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Subscription.module.css";
import cross from "./cross.svg";
import NewFormTextInput from "../../../../../../components/FormTextInput/NewFormTextInput";
import tick from "./tick.svg";
import moment from "moment";
import {
  resetBillingSuccess,
  resetPromoCode,
  resetPromocodeSuccess,
} from "../../service/Billingslice";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import "moment-timezone";

function PayNowPopup(props: any) {
  const {
    subscriptionPg,
    extraUsersCount,
    branchCount,
    branchLicenseDue,
    userLicenseDue,
    trialPlan,
    dueDate,
    CreateBilling,
    instituteBillingData,
    billingStatus,
    filteredBranchCount,
    filteredExtraUsersCount,
    CheckPromocodeByName,
    promocodeByName,
    paymentUserCountData,
    paymentBranchCountData,
    successBilling,
    loadingBilling,
    addOnUserLicenseCount,
    userCount,
    outstandingAmount,
    addedBranchLicensePrice,
    addedUserLicensePrice,
    promocodeloading,
    promocodeError,
    startDateFromBillingData,
    daysInLockedTrialPlan,
    unUsedBranchRemoveCount,
    unUsedUserRemoveCount,
    convertedAddOnUserCount,
    upcomingMonthBillDate,
  } = props;

  const dispatch = useDispatch();
  const auth = useAuthUser();
  const user = auth();

  const cartBranchCount =
    (billingStatus === "ACTIVE" || billingStatus === "LOCKED") && trialPlan
      ? 1 + branchCount
      : billingStatus === "GRACE_PERIOD" && !trialPlan
      ? branchCount
      : billingStatus === "LOCKED" && !trialPlan
      ? filteredBranchCount + branchCount
      : billingStatus === "ARCHIVED" && (!trialPlan || trialPlan)
      ? filteredBranchCount + branchCount - unUsedBranchRemoveCount
      : branchCount;

  const cartUserCount =
    (billingStatus === "ACTIVE" || billingStatus === "LOCKED") && trialPlan
      ? userCount
      : billingStatus === "GRACE_PERIOD" && !trialPlan
      ? userCount
      : billingStatus === "LOCKED" && !trialPlan
      ? addOnUserLicenseCount + userCount
      : billingStatus === "ARCHIVED" && (!trialPlan || trialPlan)
      ? addOnUserLicenseCount +
        userCount -
        unUsedUserRemoveCount +
        convertedAddOnUserCount
      : userCount;

  const [promocode, setPromocode] = useState("");

  // Subscription Status

  const billingId = instituteBillingData && instituteBillingData[0]?.id;

  const gst = instituteBillingData && instituteBillingData[0]?.gstPercentage;

  const currentDate = moment();
  const dueDateFormat = dueDate && moment(dueDate).format("DD/MM/YYYY");
  const remainingDays = moment(dueDateFormat, "DD/MM/YYYY").diff(
    currentDate,
    "days"
  );
  const remainingDaysInCurrentMonth =
    currentDate.daysInMonth() - currentDate.date();
  const billableDays = moment().endOf("month").diff(moment(), "days") + 1;

  const currentUnixTimestamp = Date.now();
  const lastDayOfMonth = moment().endOf("month").valueOf();
  const lastDayOfNextMonth = moment().add(1, "M").endOf("month").valueOf();
  const nextMonthMoment = moment(lastDayOfNextMonth);
  const numberOfDaysInNextMonth = nextMonthMoment?.daysInMonth();

  const formattedUpcomingMonthBillDate =
    upcomingMonthBillDate &&
    moment(upcomingMonthBillDate)?.format("DD/MM/YYYY");

  // const firstDayOfMonth = moment().startOf("month").tz("Asia/Kolkata");
  // const firstDayOfMonthUnix = firstDayOfMonth.unix();
  // const firstDayOfMonthUnix = firstDayOfMonth.valueOf();
  // const istUnixTimestamp = moment.tz(Date.now(), "Asia/Kolkata").valueOf();

  const firstDayOfMonth = moment().startOf("month").tz("Asia/Kolkata");
  const istUnixTimestamp = moment.tz("Asia/Kolkata").valueOf();

  const utcOffsetInMilliseconds = 5.5 * 60 * 60 * 1000; // GMT+0530 in milliseconds
  const firstDayOfMonthUnix = firstDayOfMonth.valueOf();
  const unixTimestampInSeconds = firstDayOfMonth.unix() * 1000;

  const billingStartDate =
    (trialPlan && (billingStatus === "ACTIVE" || billingStatus === "LOCKED")) ||
    (!trialPlan && billingStatus === "ACTIVE") ||
    ((!trialPlan || trialPlan) && billingStatus === "ARCHIVED")
      ? currentUnixTimestamp
      : firstDayOfMonthUnix;

  // Due Calculation
  const discount =
    promocodeByName && Object.keys(promocodeByName).length !== 0
      ? (
          ((parseFloat(branchLicenseDue) + parseFloat(userLicenseDue)) *
            promocodeByName.discountInPercentage) /
          100
        ).toFixed(2)
      : "0";

  const subTotal = (
    parseFloat(branchLicenseDue) +
    parseFloat(userLicenseDue) -
    (promocodeByName ? parseFloat(discount) : 0)
  ).toFixed(2);

  const gstCalculation = (parseFloat(subTotal) * (gst / 100)).toFixed(2);

  const total = (parseFloat(subTotal) + parseFloat(gstCalculation)).toFixed(2);

  const [billingDetails, setBillingDetails] = React.useState({
    instituteBillingId: "",
    amountReceived: 0,
    balance: 0,
    gstAmount: 0,
    discountAmount: 0,
    invoiceNumber: "#00001",
    totalUserLicense: 0,
    totalBranchLicense: 0,
    paymentDate: 0,
    paymentSource: "CASH",
    promocode: "",
    createdBy: "",
    startDate: 0,
    endDate: 0,
    instituteId: user?.institute[0]?.instituteId,
  });

  const [manageBillingModal, setManageBillingModal] = React.useState(false);

  const closeManageBillingModal = () => {
    setManageBillingModal(false);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("manageBillingModal");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    dispatch(resetPromoCode());
    setPromocode("");
    setBillingDetails({
      ...billingDetails,
      promocode: "",
    });
  };

  const openManageBillingModal = (batchId: number) => {
    setManageBillingModal(true);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("manageBillingModal", "true");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const promocodeApply = async (event: any) => {
    event.preventDefault();

    try {
      promocode !== "" && CheckPromocodeByName(promocode);
    } catch (error) {}
  };

  const promocodeRemove = async (event: any) => {
    event.preventDefault();
    dispatch(resetPromoCode());
    setPromocode("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      billingDetails.instituteBillingId !== "" &&
        CreateBilling({
          ...billingDetails,
          isUpcomingBillingPaid: upcomingMonthBillDate !== 0 ? true : false,
          // endDate:
          //   upcomingMonthBillDate !== 0
          //     ? lastDayOfNextMonth
          //     : lastDayOfMonth || 0,
          endDate:
            upcomingMonthBillDate !== 0
              ? lastDayOfNextMonth
              : !trialPlan &&
                (billingStatus === "GRACE_PERIOD" || billingStatus === "LOCKED")
              ? instituteBillingData?.[0]?.endDate
              : lastDayOfMonth || 0,
        });
    } catch (error) {}
  };

  const billStartDate = moment(instituteBillingData?.[0]?.startDate);
  const billEndDate = moment(instituteBillingData?.[0]?.endDate);
  const billDays = billEndDate?.diff(billStartDate, "days") + 1;

  React.useEffect(() => {
    if (user) {
      setBillingDetails({
        ...billingDetails,
        createdBy: user.userId && user.userId,
      });
    }
  }, [setBillingDetails]);

  const branchLicenseCount =
    branchCount +
    (trialPlan === true ? 1 : 0) +
    (billingStatus !== "ACTIVE" ? filteredBranchCount : 0);

  const extraUserLicenseCount =
    extraUsersCount +
    (billingStatus !== "ACTIVE" ? filteredExtraUsersCount : 0);

  React.useEffect(() => {
    setBillingDetails((prevBillingDetails) => {
      return {
        ...prevBillingDetails,
        amountReceived: total ? Number(total) : 0,
        gstAmount: gstCalculation ? Number(gstCalculation) : 0,
        discountAmount: discount ? Number(discount) : 0,
        totalUserLicense: paymentUserCountData || 0,
        totalBranchLicense: paymentBranchCountData || 0,
        startDate:
          billingStatus === "LOCKED"
            ? startDateFromBillingData || 0
            : billingStartDate || 0,
        endDate: lastDayOfMonth || 0,
        paymentDate: currentUnixTimestamp || 0,
        instituteBillingId: billingId || "",
        promocode:
          promocodeByName && Object.keys(promocodeByName).length !== 0
            ? promocode
            : "",
        instituteId: user?.institute[0]?.instituteId,
      };
    });
  }, [
    total,
    gstCalculation,
    discount,
    paymentUserCountData,
    paymentBranchCountData,
    billingStatus,
    billingId,
    promocode,
    promocodeByName,
    startDateFromBillingData,
  ]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const manageBillingModalInUrl = urlSearchParams.get("manageBillingModal");

    if (manageBillingModalInUrl === "true") {
      setManageBillingModal(true);
    }
  }, [setManageBillingModal]);

  React.useEffect(() => {
    if (successBilling !== true && successBilling !== false) {
      setManageBillingModal(false);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete("manageBillingModal");

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);

      setTimeout(() => {
        dispatch(resetBillingSuccess());
        dispatch(resetPromocodeSuccess());
      }, 3000);
    }
    setPromocode("");
  }, [successBilling]);

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        sx={{ textTransform: "capitalize" }}
        onClick={() => openManageBillingModal(14)}
      >
        {subscriptionPg ? "pay now" : "pay"}
      </Button>
      <Dialog
        open={manageBillingModal}
        onClose={closeManageBillingModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          zIndex: "99999",
          minWidth: "414px",
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "414px", // Set your width here
            maxHeight: "598px",
            minHeight: "400px",
            overflow: "hidden",
          },
        }}
      >
        <div className={styles.addBillingModalWrap}>
          <div className={styles.addBillingModalWrapChild}>
            <div className={styles.addBillingModalCloseWrap}>
              <button
                className={styles.addBillingModalCloseBtn}
                onClick={closeManageBillingModal}
              >
                <img src={cross} alt="" />
              </button>
            </div>
            <div className={styles.manageBillingModalHeading}>Due Now</div>
            <div className={styles.manageBillingModalSubHeading}>
              {trialPlan ||
              billingStatus !== "ACTIVE" ||
              billingStatus !== "GRACE_PERIOD" ? (
                <>
                  Your Subscription starts today. Your Bill Date will be 1st of
                  every month. Current billable days are{" "}
                  {billingStatus === "LOCKED" ||
                  billingStatus === "GRACE_PERIOD"
                    ? billDays
                    : billableDays +
                      (upcomingMonthBillDate !== 0
                        ? numberOfDaysInNextMonth
                        : 0)}
                  {/* {billingStatus === "LOCKED" ||
                  billingStatus === "GRACE_PERIOD"
                    ? daysInLockedTrialPlan
                    : billableDays +
                      (upcomingMonthBillDate !== 0
                        ? numberOfDaysInNextMonth
                        : 0)} */}
                  .
                </>
              ) : billingStatus === "GRACE_PERIOD" ? (
                ""
              ) : (
                <>
                  Your next Bill Date is{" "}
                  {dueDate &&
                    (upcomingMonthBillDate !== 0
                      ? formattedUpcomingMonthBillDate
                      : dueDateFormat)}
                  . Current Billable days are{" "}
                  {billableDays &&
                    billableDays +
                      (upcomingMonthBillDate !== 0
                        ? numberOfDaysInNextMonth
                        : 0)}
                  .
                </>
              )}
            </div>
            <div className={styles.manageBillingBranchList}>
              <div className={styles.manageBillingBranchListItem}>
                <div className={styles.manageBillingBranchListItemLeft}>
                  <div className={styles.manageBillingBranchItemName}>
                    Branches
                  </div>
                </div>

                <div className={styles.manageBillingBranchListItemRight}>
                  {cartBranchCount}
                </div>
              </div>
              <div className={styles.manageBillingBranchListItem}>
                <div className={styles.manageBillingBranchListItemLeft}>
                  <div className={styles.manageBillingBranchItemName}>
                    Extra Users
                  </div>
                </div>

                <div className={styles.manageBillingBranchListItemRight}>
                  {cartUserCount}
                </div>
              </div>
            </div>

            <div className={styles.manageBillingPromoCodeWrap}>
              <div className={styles.manageBillingPromoCodeTitle}>
                promo code
              </div>
              {promocodeByName === null ||
              Object.keys(promocodeByName).length === 0 ? (
                <div className={styles.manageBillingPromoCodeFormWrap}>
                  <div className={styles.manageBillingPromoCodeFormField}>
                    <NewFormTextInput
                      validate={true}
                      type={"text"}
                      id="promo"
                      value={promocode}
                      onChange={(event: any) => {
                        setPromocode(event.target.value);
                      }}
                      autoComplete="true"
                      placeholder="Enter here"
                    />
                  </div>
                  <div className={styles.manageBillingPromoCodeFormBtn}>
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        border: "1px solid #BFBFBF",
                        width: "81px",
                        height: "38px",
                        marginTop: "4px",
                        "&.MuiButtonBase-root.MuiButton-root.Mui-disabled": {
                          background: "#fff",
                          borderColor: "#C1C1C1",
                          color: "#C1C1C1",
                        },
                      }}
                      color="primary"
                      variant="contained"
                      disabled={promocode === "" ? true : false}
                      onClick={promocodeApply}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              ) : (
                <div className={styles.manageBillingPromoCodeAppliedWrap}>
                  <div className={styles.manageBillingPromoCodeAppliedWrapLeft}>
                    <div className={styles.manageBillingPromoCodeAppliedIcon}>
                      <img src={tick} alt="tick" />
                    </div>
                    <div
                      className={styles.manageBillingPromoCodeAppliedDetails}
                    >
                      <div
                        className={styles.manageBillingPromoCodeAppliedLabel}
                      >
                        {promocodeByName && promocodeByName?.promocode}
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.manageBillingPromoCodeAppliedWrapRight}
                  >
                    <button
                      className={styles.manageBillingPromoCodeAppliedRemove}
                      onClick={promocodeRemove}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}

              {promocodeByName &&
              Object?.keys(promocodeByName)?.length === 0 ? (
                <FormHelperText error={true}>
                  No Promocode found with the provided name!
                </FormHelperText>
              ) : (
                <></>
              )}

              {/* <div className={styles.manageBillingPromoCodeAppliedOff}>
                      -₹ 5,000 (10% off)
                    </div> */}
              <div className={styles.manageBillingSummaryWrap}>
                <div className={styles.manageBillingSummaryTitle}>Summary</div>
                {billingStatus === "GRACE_PERIOD" && (
                  <div className={styles.manageBillingSummaryItem}>
                    <div className={styles.manageBillingSummaryItemLeft}>
                      <div className={styles.manageBillingSummaryItemLabel}>
                        Outstanding Amount
                      </div>
                    </div>
                    <div className={styles.manageBillingSummaryItemRight}>
                      <div className={styles.manageBillingSummaryItemPrice}>
                        ₹ {outstandingAmount.toFixed(2)}
                      </div>
                    </div>
                  </div>
                )}

                <div className={styles.manageBillingSummaryItem}>
                  <div className={styles.manageBillingSummaryItemLeft}>
                    <div className={styles.manageBillingSummaryItemLabel}>
                      Branch License
                    </div>
                  </div>
                  <div className={styles.manageBillingSummaryItemRight}>
                    <div className={styles.manageBillingSummaryItemPrice}>
                      ₹
                      {billingStatus === "GRACE_PERIOD"
                        ? addedBranchLicensePrice
                        : branchLicenseDue}
                    </div>
                  </div>
                </div>
                <div className={styles.manageBillingSummaryItem}>
                  <div className={styles.manageBillingSummaryItemLeft}>
                    <div className={styles.manageBillingSummaryItemLabel}>
                      Extra User License
                    </div>
                  </div>
                  <div className={styles.manageBillingSummaryItemRight}>
                    <div className={styles.manageBillingSummaryItemPrice}>
                      ₹
                      {billingStatus === "GRACE_PERIOD"
                        ? addedUserLicensePrice
                        : userLicenseDue}
                    </div>
                  </div>
                </div>
                {discount !== "0" ? (
                  <div className={styles.manageBillingSummaryItem}>
                    <div className={styles.manageBillingSummaryItemLeft}>
                      <div className={styles.manageBillingSummaryItemLabel}>
                        Discount
                      </div>
                    </div>
                    <div className={styles.manageBillingSummaryItemRight}>
                      <div className={styles.manageBillingSummaryItemPrice}>
                        - ₹ {discount}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className={styles.manageBillingSummaryItem}>
                  <div className={styles.manageBillingSummaryItemLeft}>
                    <div className={styles.manageBillingSummaryItemLabel}>
                      Sub Total
                    </div>
                  </div>
                  <div className={styles.manageBillingSummaryItemRight}>
                    <div className={styles.manageBillingSummaryItemPrice}>
                      ₹ {subTotal}
                    </div>
                  </div>
                </div>
                <div className={styles.manageBillingSummaryItem}>
                  <div className={styles.manageBillingSummaryItemLeft}>
                    <div className={styles.manageBillingSummaryItemLabel}>
                      GST {gst}%
                    </div>
                  </div>
                  <div className={styles.manageBillingSummaryItemRight}>
                    <div className={styles.manageBillingSummaryItemPrice}>
                      ₹ {gstCalculation}
                    </div>
                  </div>
                </div>
                <div className={styles.manageBillingSummaryFooter}>
                  <div className={styles.manageBillingSummaryFooterLabel}>
                    Total
                  </div>
                  <div className={styles.manageBillingSummaryFooterPrice}>
                    ₹ {total}
                  </div>
                </div>
              </div>

              <div className={styles.manageBillingModalBottom}>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    color: "#BFBFBF",
                    border: "1px solid #BFBFBF",
                  }}
                  variant="outlined"
                  onClick={closeManageBillingModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    textTransform: "capitalize",
                    minWidth: "96px",
                    "&.Mui-disabled": {
                      backgroundColor: "#11C89B ",
                      color: "#fff",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loadingBilling}
                >
                  {loadingBilling ? (
                    <CircularProgress size={25} color={"inherit"} />
                  ) : (
                    "Continue"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default PayNowPopup;
