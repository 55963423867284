import React from "react";
import styles from "../PaymentType.module.css";
import IconDropdown from "../../../../../../components/Dropdowns/IconDropdown";
import editIcon from "../../../../../../assets/images/edit-icon.svg";
import LeadConfirmPopup from "../../LeadCustomPopup/LeadConfirmPopup";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import MembershipDetails from "./MembershipDetails";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { pauseAndResume } from "../../../service/nurtureSlice";
import pauseIcon from "../../../../../../assets/images/pause-icon.svg";
import LeadPaymentAutoDebit from "../LeadAutoDebit/LeadPaymentAutoDebit";
import MembershipPaused from "../Bars/MembershipPaused";

function LeadMembershipDetails(props: any) {
  const {
		leadData,
		setIsPayEditOpen,
		leadEmiData,
		RecordPayment,
		SendPaymentLink,
		EditRecordPayment,
		filteredPaymentRequests,
		isFirstPaymentRequestedOrFailed,
		lastTransaction,
		activityData,
		hasLeadAccess,
		selectedCurrency,
	} = props;
	const auth = useAuthUser();
	const authUser = auth();
	const dispatch = useDispatch();
	const { pauseLoading, pauseSuccess } = useSelector(
		(state: any) => state.nurture
	);
	const selectedMembership = leadData?.paymentDetails?.paymentStructure?.find(
		(item: any) => item?.selected === true
	);
	const batchStartDateMilliSeconds =
		leadData?.batchStartDate?.toString()?.length !== 13
			? leadData?.batchStartDate * 1000
			: leadData?.batchStartDate;
	const batchEndDateMilliSeconds =
		leadData?.bacthEndDate?.toString()?.length !== 13
			? leadData?.bacthEndDatebatchData?.endDate * 1000
			: leadData?.bacthEndDate;
	const discountAmount =
		leadData?.discountMetric !== ''
			? leadData?.discountMetric === 'AMOUNT'
				? leadData?.discount
				: (leadData?.discount * selectedMembership?.emiAmount) / 100
			: 0;

	const remainingEmiAmount = leadEmiData
		?.filter(
			(item: any) =>
				item?.paymentStatus === 'OVERDUE' || item?.paymentStatus === 'UPCOMING'
		)
		?.reduce((total: any, item: any) => {
			return total + Number(item.emiAmount);
		}, 0);

	const latestPaidEmi = leadEmiData?.find(
		(emi: any) => emi.paymentStatus === 'PAID'
	);

	const currentDate = dayjs().valueOf();

	const daysDifference = () => {
		if (!latestPaidEmi?.expiryDate) return 0;

		const batchStartDateMilliSeconds =
			leadData?.batchStartDate?.toString()?.length !== 13
				? leadData?.batchStartDate * 1000
				: leadData?.batchStartDate;

		if (batchStartDateMilliSeconds < currentDate) {
			return dayjs(latestPaidEmi?.expiryDate).diff(currentDate, 'day') + 2;
		} else {
			return (
				dayjs(latestPaidEmi?.expiryDate).diff(
					batchStartDateMilliSeconds,
					'day'
				) + 2
			);
		}
	};

	const numberOfDays = daysDifference();

	const paidEmis = leadEmiData?.filter(
		(item: any) =>
			item?.emiPaymentStatus === 'FULLY_PAID' ||
			item?.emiPaymentStatus === 'PARTIALLY_PAID'
	);
	const [pauseOpen, setPauseOpen] = React.useState<boolean>(false);
	const [checkResumeDate, setCheckResumeDate] = React.useState<boolean>(false);

	const [resumeDate, setResumeDate] = React.useState<any>(null);
	const [resumeDateError, setResumeDateError] = React.useState<boolean>(false);

	const pausPopupeOpen = () => {
		setPauseOpen(true);
	};
	const pausePopupClose = () => {
		setPauseOpen(false);
	};

	const handleSetupResume = (event: any) => {
		setCheckResumeDate(event.target.checked);
		setResumeDate(null);
	};

	const handleDateChange = React.useCallback((date: any) => {
		const dateUnix = date.unix() * 1000;
		setResumeDate(dateUnix);
		setResumeDateError(false);
	}, []);

	const isSameDate = (timestamp1: number, timestamp2: number) => {
		const date1 = new Date(timestamp1).setHours(0, 0, 0, 0);
		const date2 = new Date(timestamp2).setHours(0, 0, 0, 0);
		return date1 === date2;
	};

	const pauseMembership = () => {
		const body = {
			actionType: leadData?.pausedMembership ? 'RESUME' : 'PAUSE',
			enquiryId: leadData?.id,
			recurringEMIAmount: selectedMembership?.finalEmiAmount,
			startDate: resumeDate ? new Date(resumeDate).setHours(23, 59, 59, 0) : 0,
			endDate: batchEndDateMilliSeconds,
			emiMode: selectedMembership?.frequency,
			hasResumeDate: checkResumeDate,
			updatedById: authUser?.institute?.[0]?.id,
			updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
			createdById: authUser?.institute?.[0]?.id,
			createdByName: authUser?.firstName + ' ' + authUser?.lastName,
		};

		if (checkResumeDate && resumeDate === null) {
			setResumeDateError(true);
			return;
		}

		if (body) {
			dispatch<any>(pauseAndResume(body));
		}
	};

	React.useEffect(() => {
		if (pauseSuccess !== true && pauseSuccess !== false) {
			setPauseOpen(false);
		}
	}, [pauseSuccess]);

	React.useEffect(() => {
		if (
			leadData?.resumedMembershipDate &&
			leadData.resumedMembershipDate.toString().length === 13
		) {
			setResumeDate(leadData?.resumedMembershipDate);
			setCheckResumeDate(true);
		}
	}, [leadData]);

	return (
		<div>
			<div>
				<div
					style={{
						border: '1px solid rgba(102, 112, 133, 0.12)',
						borderRadius: '6px',
					}}>
					<div className={styles.paymentDetailsHead}>
						<div className={styles.paymentDetailsHeadLeft}>
							<div className={styles.paymentDetailsHeadLeftItem}>
								<div className={styles.paymentDetailsHeadItemLabel}>
									Frequency
								</div>
								<div className={styles.membershipDetailsType}>
									{selectedMembership?.frequency
										?.toLowerCase()
										.replace(/_/g, ' ')}
								</div>
							</div>

							<div className={styles.paymentDetailsHeadLeftItem}>
								<div className={styles.paymentDetailsHeadItemLabel}>
									Membership Fees
								</div>
								<div
									className={styles.paymentDetailsHeadItemValue}
									style={{ fontFamily: 'Roboto' }}>
									{/* 	{selectedMembership?.emiAmount?.toLocaleString('en-IN', {
										style: 'currency',
										currency: 'INR',
									})} */}
									{selectedCurrency + ' ' + selectedMembership?.emiAmount}
								</div>
							</div>
							<div className={styles.paymentDetailsHeadLeftItem}>
								<div className={styles.paymentDetailsHeadItemLabel}>
									Discount
								</div>
								<div
									className={styles.paymentDetailsHeadItemValue}
									style={{ fontFamily: 'Roboto' }}>
									{/* 	{discountAmount?.toLocaleString('en-IN', {
										style: 'currency',
										currency: 'INR',
									})} */}
									{selectedCurrency + ' ' + discountAmount}
								</div>
							</div>
						</div>
						<div className={styles.paymentDetailsHeadRight}>
							<div className={styles.paymentDetailsHeadFinalPriceWrap}>
								<div className={styles.paymentDetailsHeadItemLabel}>
									Final Membership Fees
								</div>
								<div
									className={styles.paymentDetailsHeadFinalPrice}
									style={{ fontFamily: 'Roboto' }}>
									{/* 		{leadData?.paymentDetails?.finalAmount?.toLocaleString(
										'en-IN',
										{
											style: 'currency',
											currency: 'INR',
										}
									)} */}

									{selectedCurrency +
										' ' +
										leadData?.paymentDetails?.finalAmount}

									<span className={styles.paymentDetailsHeadFinalPriceType}>
										/
										{selectedMembership?.frequency
											?.toLowerCase()
											.replace(/_/g, ' ')}
									</span>
								</div>
							</div>
							<div>
								{hasLeadAccess && leadData?.balanceAmount > 0 && (
									<IconDropdown
										options={[
											{
												label: 'Edit',
												onClick: () => {
													setIsPayEditOpen(true);
												},
												icon: editIcon,
												style: { color: '#667085' },
											},
											...(!leadData?.pausedMembership && paidEmis?.length > 0
												? [
														{
															label: 'Pause',
															onClick: () => {
																pausPopupeOpen();
															},
															icon: pauseIcon,
															style: { color: '#667085' },
														},
												  ]
												: []),
										]}
									/>
								)}
							</div>
						</div>
					</div>
					{leadEmiData?.length === 0 && (
						<div style={{ padding: '0 10px' }}>
							{activityData?.length > 0 &&
								activityData
									?.filter(
										(item: any) => item.activityType === 'MEMBERSHIP_PAUSED'
									)
									?.map((activity: any, i: number) => (
										<div key={i}>
											{activity.activityType === 'MEMBERSHIP_PAUSED' && (
												<MembershipPaused
													activity={activity}
													setPauseOpen={setPauseOpen}
												/>
											)}
										</div>
									))}
						</div>
					)}
					{leadEmiData?.length > 1 &&
						leadEmiData?.[0]?.paymentActivityData?.length > 0 && (
							<div style={{ padding: '0 10px' }}>
								<>
									{leadEmiData?.[0]?.paymentActivityData?.map(
										(activity: any, i: number) => (
											<div key={i}>
												{activity.activityType === 'MEMBERSHIP_PAUSED' && (
													<MembershipPaused
														activity={activity}
														setPauseOpen={setPauseOpen}
													/>
												)}
											</div>
										)
									)}
								</>
							</div>
						)}
					{leadEmiData?.length === 1 && (
						<div style={{ padding: '0 10px' }}>
							{leadEmiData?.map((item: any) => (
								<>
									{item?.paymentActivityData?.map(
										(activity: any, i: number) => (
											<div key={i}>
												{activity.activityType === 'MEMBERSHIP_PAUSED' && (
													<MembershipPaused
														activity={activity}
														setPauseOpen={setPauseOpen}
													/>
												)}
											</div>
										)
									)}
								</>
							))}
						</div>
					)}

					{leadEmiData?.length > 0 && (
						<>
							<div style={{ padding: '0 10px' }}>
								<MembershipDetails
									membershipData={leadEmiData}
									leadData={leadData}
									finalBatchPrice={selectedMembership?.finalEmiAmount}
									totalPaidAmount={leadData?.batchAmountPaid}
									discountMetric={selectedMembership?.discountMetric}
									discount={selectedMembership?.discount}
									RecordPayment={RecordPayment}
									SendPaymentLink={SendPaymentLink}
									filteredPaymentRequests={filteredPaymentRequests}
									EditRecordPayment={EditRecordPayment}
									isFirstPaymentRequestedOrFailed={
										isFirstPaymentRequestedOrFailed
									}
									lastTransaction={lastTransaction}
									remainingEmiAmount={remainingEmiAmount}
									activityData={activityData}
									setPauseOpen={setPauseOpen}
									hasLeadAccess={hasLeadAccess}
									selectedCurrency={selectedCurrency}
								/>
							</div>

							<LeadPaymentAutoDebit
								leadData={leadData}
								paymentType={'MEMBERSHIP'}
							/>
						</>
					)}
				</div>
			</div>
			<LeadConfirmPopup
				isOpen={pauseOpen}
				onClose={pausePopupClose}
				title={
					leadData?.pausedMembership ? 'Resume Membership' : 'Pause Membership'
				}
				description={
					numberOfDays !== 0
						? `${
								leadData?.studentFirstName + ' ' + leadData?.studentLastName
						  }  has ${numberOfDays} days remaining in the current membership period.`
						: ''
				}
				cancelClick={pausePopupClose}
				submitClick={pauseMembership}
				submitBtnText={'ok'}
				submitLoader={pauseLoading}
				submitDisbaled={
					pauseLoading ||
					(leadData?.pausedMembership &&
						isSameDate(leadData?.resumedMembershipDate, resumeDate))
				}>
				<FormControlLabel
					control={
						<Checkbox
							sx={{
								strokeWidth: 1,
								color: '#D0D5DD',
								'&:hover': {
									backgroundColor: 'transparent',
								},
								'&:focus': {
									backgroundColor: 'transparent',
								},
							}}
							checked={checkResumeDate}
							onChange={handleSetupResume}
						/>
					}
					label={
						<Typography
							style={{
								fontSize: '14px',
								fontWeight: '400',
								color: '#667085',
								lineHeight: '20px',
							}}>
							Set membership resume date{' '}
							{leadData?.pausedMembership
								? `(if not then will resume now)`
								: ''}
						</Typography>
					}
				/>
				{checkResumeDate && (
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DesktopDatePicker
							sx={{
								zIndex: '99999999 !important',
								width: '100%',
								fontSize: '14px',
								marginTop: '10px',
								'& .MuiOutlinedInput-input': {
									fontSize: '14px',
									color: '#142C44',
									padding: '9px',
								},
							}}
							format='DD/MM/YYYY'
							onChange={(date: any) => {
								setResumeDate(date);
								handleDateChange(date);
							}}
							value={resumeDate ? dayjs(resumeDate) : null}
							shouldDisableDate={(date) => {
								const adjustedLastPaidEmiDate =
									paidEmis?.length > 0 &&
									batchStartDateMilliSeconds < currentDate
										? dayjs(currentDate)
										: batchStartDateMilliSeconds;

								const isBeforeStart = dayjs(date).isBefore(
									adjustedLastPaidEmiDate,
									'day'
								);

								const oneDayABeforeBatchEnd = dayjs(
									batchEndDateMilliSeconds
								).subtract(1, 'day');
								const isAfterEnd = dayjs(date).isAfter(oneDayABeforeBatchEnd);
								return isBeforeStart || isAfterEnd;
							}}
						/>
					</LocalizationProvider>
				)}
				{resumeDateError && (
					<FormHelperText error>Please fill date</FormHelperText>
				)}
			</LeadConfirmPopup>
		</div>
	);
}

export default LeadMembershipDetails;
