import { RootState, TypedDispatch } from "../../../../../../redux/store";
import { connect } from "react-redux";
import {
  PublicWebformStateDTO,
  StudentContactStateDTO,
} from "../service/types";
import {
  createContact,
  getPublicWebformById,
} from "../service/publicwebformSlice";
import PublicWebForm from "../components/publicWebForm";

interface StateProps extends PublicWebformStateDTO {}

interface DispatchProps {
  CreateContact(data: StudentContactStateDTO): void;
  GetPublicWebformById(data: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success: state.publicWebform.success,
    error: state.publicWebform.error,
    loading: state.publicWebform.loading,
    contactUpdateLoading: state.publicWebform.contactUpdateLoading,
    contactUpdateError: state.publicWebform.contactUpdateError,
    contactUpdateSuccess: state.publicWebform.contactUpdateSuccess,
    publicWebform: state.publicWebform.publicWebform,
    contactCreateLoading: state.publicWebform.contactCreateLoading,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    CreateContact: (data: any) => {
      dispatch(createContact(data));
    },
    GetPublicWebformById: (data: any) => {
      dispatch(getPublicWebformById(data));
    },
  };
};
export interface CampaignProps extends StateProps, DispatchProps {}
export const PublicWebFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicWebForm);
