import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import styles from "./Receipt.module.css";
import instituteLogo from "../../assets/images/instituteLogo.png";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import moment from "moment";

function Receipt(props: any) {
  const {
		showReceipt,
		setShowReceipt,
		paymentDetails,
		enteredAmount,
		leadById,
		finalPrice,
		totalPaidAmount,
		selectedCurrency,
	} = props;

  const instituteFromLocal = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  const handleCloseReceipt = () => {
    setShowReceipt(false);
  };

  return (
		<Dialog
			open={showReceipt}
			onClose={handleCloseReceipt}
			sx={{
				zIndex: '9999999',
				minWidth: '550px',
				'& .MuiDialog-paper': {
					width: '100%',
					maxWidth: '595px',
					padding: '32px',
					left: 0,
					background: '#FFFFFF',
					overflow: 'hidden',
					borderRadius: 'unset',
					boxShadow: 'unset',
					height: '842px',
				},
			}}>
			<Box>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginTop: '-20px',
						marginRight: '-20px',
					}}>
					<Button
						sx={{
							color: '#667085',
							padding: '0px',
							display: 'flex',
							width: 'fit-content',
							minWidth: '0px',
						}}
						onClick={() => handleCloseReceipt()}>
						<CloseOutlinedIcon />
					</Button>
				</div>

				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box>
						<div className={styles.instituteLogo}>
							{instituteFromLocal?.logo && (
								<img src={instituteFromLocal?.logo} alt='logo' width='87px' />
							)}
						</div>

						<Box className={styles.instituteName}>
							{instituteFromLocal?.instituteName}
						</Box>
						<Box className={styles.instituteAddress}>
							{instituteFromLocal?.buildingNumber},
							{instituteFromLocal?.locality},
							{instituteFromLocal?.city ? `${instituteFromLocal?.city},` : ''}
							{instituteFromLocal?.state}, {instituteFromLocal?.pin} <br />
							GSTIN:
						</Box>
					</Box>
					<Box className={styles.receiptDetails}>
						<Box className={styles.receiptHead}>Receipt</Box>

						{/* <Box sx={{ display: "flex" }}>
              <div className={styles.receiptDetailsLabel}>Receipt Number: </div>
              <div className={styles.receiptDetailsValue}> 0012</div>
            </Box> */}

						<Box sx={{ display: 'flex' }}>
							<div className={styles.receiptDetailsLabel}>Receipt Date: </div>
							<div className={styles.receiptDetailsValue}>
								{moment().format('D MMMM YYYY')}
							</div>
						</Box>

						<Box sx={{ display: 'flex' }}>
							<div className={styles.receiptDetailsLabel}>Student name: </div>
							<div className={styles.receiptDetailsValue}>
								{leadById?.studentFirstName + ' ' + leadById?.studentLastName}{' '}
							</div>
						</Box>

						{paymentDetails?.paymentSource !== '' ? (
							<Box sx={{ display: 'flex' }}>
								<div className={styles.receiptDetailsLabel}>
									Payment source:{' '}
								</div>
								<div className={styles.receiptDetailsValue}>
									{paymentDetails?.paymentSource}{' '}
								</div>
							</Box>
						) : (
							<></>
						)}

						{paymentDetails?.gstNumber !== '' ? (
							<Box sx={{ display: 'flex' }}>
								<div className={styles.receiptDetailsLabel}>GST Number: </div>
								<div className={styles.receiptDetailsValue}>
									{' '}
									{paymentDetails?.gstNumber}
								</div>
							</Box>
						) : (
							<></>
						)}

						{paymentDetails?.gstCompanyDetails !== '' ? (
							<Box sx={{ display: 'flex' }}>
								<div className={styles.receiptDetailsLabel}>
									Registered Company Name:{' '}
								</div>
								<div className={styles.receiptDetailsValue}>
									{' '}
									{paymentDetails?.gstCompanyDetails}
								</div>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</Box>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '8px',
										fontWeight: '500',
										textTransform: 'capitalize',
										maxWidth: '50px',
										padding: '10px 15px 8px 0',
										borderColor: '#000',
									}}
									width='50px'>
									#
								</TableCell>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '8px',
										fontWeight: '500',
										textTransform: 'capitalize',
										padding: '10px  0px 8px 0',
										borderColor: '#000',
									}}>
									Item & Description
								</TableCell>
								<TableCell
									sx={{
										color: '#142C44',
										fontSize: '8px',
										fontWeight: '500',
										textTransform: 'capitalize',
										padding: '10px  0px 8px 0',
										textAlign: 'right',
										borderColor: '#000',
									}}
									width='90px'>
									Amount
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '12px',
										fontWeight: '400',
										textTransform: 'capitalize',
										padding: '10px 0 16px 0px',
									}}>
									1
								</TableCell>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '9px',
										fontWeight: '400',
										textTransform: 'capitalize',
										padding: '10px 0 16px 0px',
									}}>
									{leadById?.courseName}
								</TableCell>
								<TableCell
									sx={{
										color: '#667085',
										fontSize: '9px',
										fontWeight: '400',
										textTransform: 'capitalize',
										textAlign: 'right',
										padding: '10px 0 16px 5px',
									}}
									width='90px'>
									{enteredAmount}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<Box className={styles.amountDetailsWrapper}>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box className={styles.amountDetails}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div className={styles.receivedAmountLabel}>
									Amount Received:
								</div>
								<div className={styles.receivedAmountValue}>
									{selectedCurrency + ' ' + enteredAmount}
								</div>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div className={styles.courseFeeLabel}>Total Course Fee:</div>
								<div className={styles.courseFeeValue}>₹ {finalPrice}</div>
							</Box>
						</Box>
						<Box className={styles.amountDetails}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: '5px',
								}}>
								<div
									className={styles.receivedAmountLabel}
									style={{ fontWeight: '500' }}>
									Balance Due:
								</div>
								<div
									className={styles.receivedAmountValue}
									style={{ fontWeight: '500' }}>
									{/* ₹{paymentDetails?.balance?.toFixed(2) - enteredAmount} */}
									{selectedCurrency}{' '}
									{finalPrice - (totalPaidAmount + enteredAmount)}
								</div>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
}

export default Receipt;
