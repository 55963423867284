import React from "react";
import styles from "../../Dashboard.module.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import helpIcon from "../../../../../assets/images/helpIcon.svg";
import { convertToAdjustedTimestamp } from "../../../../../components/Formatter/Formatter";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { formatAmount } from "../../../../../components/CommonFunctions/CommonFunctions";
import { RevenueDisplay } from "../../../../../components/CommonFunctions/CommonFunctions";
import ToolTip from "../../../../../components/ToolTip/ToolTip";

function DataTiles(props: any) {
  const { batchSlideData, selectedCurrency } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userById } = useSelector((state: any) => state.userDetails);

  const hasBatchPermission = checkSubDomainAccess(
    "MANAGE",
    "BATCHES",
    userById?.rolePermission
  );
  return (
    <div className={styles.dataTilesBox}>
      <div className={styles.dataTilesBoxChild}>
        <Carousel
          className="dashboardBatchCarousel"
          autoPlay
          swipeable
          stopOnHover
          showIndicators={false}
          infiniteLoop
          showThumbs={false}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                onClick={clickHandler}
                style={{
                  position: "absolute",
                  top: "22px",
                  right: "46px",
                  zIndex: 99,
                  cursor: "pointer",
                  display: hasPrev ? "block" : "none",
                }}
              >
                <ArrowBackIosIcon
                  style={{ fontSize: "18px", color: "#8A94A6" }}
                />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                onClick={clickHandler}
                style={{
                  position: "absolute",
                  top: "22px",
                  right: "20px",
                  cursor: "pointer",
                  display: hasNext ? "block" : "none",
                }}
              >
                <ArrowForwardIosIcon
                  style={{ fontSize: "18px", color: "#8A94A6" }}
                />
              </div>
            );
          }}
        >
          {batchSlideData?.map((item: any, index: number) => (
            <div key={index}>
              {item?.firstSlide === true ? (
                <>
                  <div className={styles.totalBatchTopWrap}>
                    <div className={styles.totalBatchTopCount}>
                      {item?.totalBatch}
                    </div>
                    <div className={styles.totalBatchTopLabel}>
                      Total Batches
                    </div>
                    <div className={styles.totalBatchTopStatusWrap}>
                      <div className={styles.totalBatchTopStatus}>
                        {`ongoing - ${item?.totalOngoingBatch}`}
                      </div>
                      <div className={styles.totalBatchTopStatus}>
                        {`Upcoming - ${item?.totalUpcomingBatch}`}
                      </div>
                    </div>
                  </div>
                  <div className={styles.totalBatchBottomWrap}>
                    <div className={styles.totalBatchStudentsWrap}>
                      <div className={styles.totalBatchStudentsCount}>
                        {item?.studentCount}
                      </div>
                      <div className={styles.totalBatchStudentsLabel}>
                        Enrolled students
                      </div>
                    </div>
                    <div className={styles.totalBatchAmountWrap}>
                      <div className={styles.totalBatchAmountItem}>
                        <div className={styles.totalBatchAmount}>
                          {/* {item?.revenueCollected?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
                          {selectedCurrency +
                            " " +
                            formatAmount(item?.revenueCollected)}
                          {RevenueDisplay(item?.revenueCollected) && (
                            <ToolTip
                              title={RevenueDisplay(item?.revenueCollected)}
                            />
                          )}
                        </div>
                        <div className={styles.totalBatchLabel}>
                          Revenue collected
                        </div>
                      </div>
                      <div className={styles.totalBatchAmountItem}>
                        <div className={styles.totalBatchAmount}>
                          {/* {item?.revenueRemaining?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
                          {selectedCurrency +
                            " " +
                            formatAmount(item?.revenueRemaining)}
                          {RevenueDisplay(item?.revenueRemaining) && (
                            <ToolTip
                              title={RevenueDisplay(item?.revenueRemaining)}
                            />
                          )}
                        </div>
                        <div className={styles.totalBatchLabel}>
                          Revenue Remaining
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.batchSliderTop}>
                    <div className={styles.batchSliderTopChild}>
                      <div className={styles.batchSliderTopLeft}>
                        <div className={styles.batchSliderStatus}>
                          {item?.batchStatus?.toLowerCase()}
                        </div>
                        <div className={styles.batchSliderBatchName}>
                          {item?.batchName}
                        </div>
                        <div className={styles.batchSliderMainDetailsWrap}>
                          <div className={styles.batchSliderMainDetailsItem}>
                            {item?.branchName}
                          </div>
                        </div>
                      </div>
                      <div className={styles.batchSliderTopRight}>
                        <div
                          className={styles.batchSliderViewBtn}
                          onClick={() => {
                            hasBatchPermission
                              ? navigate(
                                  `/app/manage/Batches?batchId=${item?.batchId}`
                                )
                              : dispatch(
                                  setToastNotification({
                                    message: String(
                                      "You do not have permission to access the batch"
                                    ),
                                    type: "error",
                                    snackOpen: true,
                                  })
                                );
                          }}
                        >
                          View Details
                        </div>
                      </div>
                    </div>
                    <div className={styles.batchSliderCourseWrap}>
                      <div className={styles.batchSliderCourseLabel}>
                        Course
                      </div>
                      <div className={styles.batchSliderCourseValue}>
                        {item?.courseName}
                      </div>
                    </div>
                    <div className={styles.batchSliderScheduleWrap}>
                      <div className={styles.batchSliderScheduleItem}>
                        <div className={styles.batchSliderScheduleItemLabel}>
                          Start Date
                        </div>
                        <div className={styles.batchSliderScheduleItemValue}>
                          {item &&
                            moment
                              .unix(
                                convertToAdjustedTimestamp(item.startDate) /
                                  1000
                              )
                              .format("DD/MM/YYYY")}
                        </div>
                      </div>
                      <div className={styles.batchSliderScheduleItem}>
                        <div className={styles.batchSliderScheduleItemLabel}>
                          End Date
                        </div>
                        <div className={styles.batchSliderScheduleItemValue}>
                          {item &&
                            moment
                              .unix(
                                convertToAdjustedTimestamp(item.endDate) / 1000
                              )
                              .format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.batchSliderBottom}>
                    <div className={styles.batchSliderBottomWrap}>
                      <div className={styles.batchSliderBottomValue}>
                        {/* {item?.potentialRevenue?.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })} */}
                        {selectedCurrency +
                          " " +
                          formatAmount(item?.potentialRevenue)}
                        {RevenueDisplay(item?.potentialRevenue) && (
                          <ToolTip
                            title={RevenueDisplay(item?.potentialRevenue)}
                          />
                        )}
                      </div>
                      <div className={styles.batchSliderBottomLabel}>
                        Potential Revenue{"  "}
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            item?.paymentType === "MEMBERSHIP"
                              ? "Total no of months in the batch x Batch price x Max students"
                              : "Batch price x Max students"
                          }
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#101828",
                                color: "#fff",
                                fontSize: "12px",
                                fontWeight: "500",
                                padding: "8px 12px",

                                "& .MuiTooltip-arrow": {
                                  color: "#101828",
                                },
                              },
                            },
                          }}
                        >
                          <span>
                            <img
                              src={helpIcon}
                              alt="helpIcon"
                              style={{ width: "15px", height: "15px" }}
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className={styles.batchSliderItems}>
                      <div className={styles.batchSliderItem}>
                        <div className={styles.batchSliderItemValue}>
                          {item?.studentCount}
                        </div>
                        <div className={styles.batchSliderItemLabel}>
                          Enrolled students
                        </div>
                      </div>
                      <div className={styles.batchSliderItem}>
                        <div className={styles.batchSliderItemValue}>
                          {/* {item?.revenueCollected?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
                          {selectedCurrency +
                            " " +
                            formatAmount(item?.revenueCollected)}
                          {RevenueDisplay(item?.revenueCollected) && (
                            <ToolTip
                              title={RevenueDisplay(item?.revenueCollected)}
                            />
                          )}
                        </div>
                        <div className={styles.batchSliderItemLabel}>
                          Revenue Collected
                        </div>
                      </div>
                      <div className={styles.batchSliderItem}>
                        <div className={styles.batchSliderItemValue}>
                          {/* {item?.revenueRemaining?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
                          {selectedCurrency +
                            " " +
                            formatAmount(item?.revenueRemaining)}
                          {RevenueDisplay(item?.revenueRemaining) && (
                            <ToolTip
                              title={RevenueDisplay(item?.revenueRemaining)}
                            />
                          )}
                        </div>
                        <div className={styles.batchSliderItemLabel}>
                          Revenue Remaining
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default DataTiles;
