import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import searchFilterIcon from "../../../assets/images/search-icon-f.svg";

export default function FilterSearchField(props: any) {
  const { placeholder, value, onChange } = props;
  return (
    <TextField
      type="search"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      fullWidth
      style={{
        padding: "0px",
        borderRadius: "4px",
        border: "none",
        outline: "none",
        height: "38px",
        marginTop: "6px",
        marginBottom: "14px",
      }}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={searchFilterIcon} alt="" />
          </InputAdornment>
        ),
      }}
    />
  );
}
