import React, { useCallback, useEffect, useState } from "react";
import styles from "./LeadPopup.module.css";
import closeIcon from "./Assets/popup-close.svg";
import ListTab from "../ListTab/ListTab";
import LeadDetailsTop from "./LeadDetailsTop";
import LeadCourseDetails from "./LeadCourseDetails";
import LeadAddCourse from "./LeadAddCourse";
import {
  resetDemoState,
  resetLeadLEmiData,
  resetLeadListByContact,
  resetEmailStatuses,
  resetPaymentSuccess,
  resetLeadLEmiStatus,
  resetLeadPaymentData,
  resetDownloadStatuses,
  resetLeadById,
  editDemo,
  editNote,
  deleteTask,
  editTask,
  deleteNote,
  editRecordPayment,
  deleteDemoBookedForLead,
  createDemo,
  recordPayment,
  createTask,
  createNote,
  composeEmail,
  nurtureLead,
  sendPaymentLink,
  downloadPaymentReceipt,
  createSource,
  createComment,
  deleteComment,
  editComment,
  updateLead,
  updateLeadEmiDetails,
  updateLeadSwimlaneStatus,
  updateLeadEnquiryStatus,
  getLeadById,
  getLeadPaymentList,
  getLeadNoteList,
  getLeadTaskList,
  getLeadActivityList,
  getInoxByLeadId,
  getDemoCalanderByCourseAndInstitute,
  getEmiDataOfLead,
  getLeadListByInstituteAndContact,
  resetLeadModalState,
  setLeadModalLeadId,
  resetPaymentStatuses,
  resetLeadStatuses,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router";
import {
  resetTaskUpdateSuccess,
  updateTaskStatus,
} from "../../pages/app/Tasks/service/tasksSlice";
import {
  callLead,
  leadCallDispositionForm,
  resetCallDispositionFormStatuses,
} from "../../pages/app/Integrations/service/IntegrationsSlice";
import {
  ComposeEmailDTO,
  CreateCommentDTO,
  CreateDemoDTO,
  CreateNoteDTO,
  CreateSourceDTO,
  CreateTaskDTO,
  DeleteCommentDTO,
  DeleteDemoBookedDTO,
  DeleteNoteDTP,
  DeleteTaskDTO,
  DownloadReceiptDTO,
  EditDemoDTO,
  EditNoteDTO,
  EditTaskDTO,
  LeadStatusUpdateDTO,
  LeadSwimlaneStatusUpdateDTO,
  NurtureLead,
  RecordPaymentDTO,
  leadEmiDTO,
  sendEmailDTO,
} from "../../pages/app/Nurture/service/types";
import { createBatchDTO } from "../../pages/app/Manage/ManageBatch/service/types";
import { createBatch } from "../../pages/app/Manage/ManageBatch/service/Batchslice";
import { CallDispositionDTO } from "../../pages/app/Integrations/service/types";

function LeadPopup(props: any) {
  const {} = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const GetLeadListByInstituteAndContact = (data: any) => {
    dispatch<any>(getLeadListByInstituteAndContact(data));
  };

  const leadPopupApiCall = useCallback(
    (instituteId: string, phone: string | null, email: string | null) => {
      let data: any;
      if (instituteId) {
        if (phone) {
          data = {
            id: instituteId,
            phone: phone,
          };
          GetLeadListByInstituteAndContact(data);
        } else if (email) {
          data = {
            id: instituteId,
            email: email,
          };
          GetLeadListByInstituteAndContact(data);
        }
      }
    },
    [GetLeadListByInstituteAndContact]
  );

  const GetLeadActivityList = (id: string) => {
    dispatch<any>(getLeadActivityList(id));
  };
  const GetLeadTaskList = (id: string) => {
    dispatch<any>(getLeadTaskList(id));
  };
  const GetLeadNoteList = (id: string) => {
    dispatch<any>(getLeadNoteList(id));
  };
  const GetLeadPaymentList = (id: string) => {
    dispatch<any>(getLeadPaymentList(id));
  };
  const GetLeadById = (id: string) => {
    dispatch<any>(getLeadById(id));
  };
  const GetInboxByLeadId = (id: string) => {
    dispatch<any>(getInoxByLeadId(id));
  };
  const GetDemoCalanderByCourseAndInstitute = (data: any) => {
    dispatch<any>(getDemoCalanderByCourseAndInstitute(data));
  };
  const GetEmiDataOfLead = (data: any) => {
    dispatch<any>(getEmiDataOfLead(data));
  };

  const SendPaymentLink = (data: sendEmailDTO) => {
    dispatch<any>(sendPaymentLink(data));
  };
  const DownloadPaymentReceipt = (data: DownloadReceiptDTO) => {
    dispatch<any>(downloadPaymentReceipt(data));
  };

  const CallLead = (data: any) => {
    dispatch<any>(callLead(data));
  };

  const CreateSource = (data: CreateSourceDTO) => {
    dispatch<any>(createSource(data));
  };
  const CallDispositionForm = (data: CallDispositionDTO) => {
    dispatch<any>(leadCallDispositionForm(data));
  };
  const CreateComment = (data: CreateCommentDTO) => {
    dispatch<any>(createComment(data));
  };

  const nurtureAddEnquiry = (data: NurtureLead) => {
    dispatch<any>(nurtureLead(data));
  };
  const CreateBatch = (data: createBatchDTO) => {
    dispatch<any>(createBatch(data));
  };
  const CreateNote = (data: CreateNoteDTO) => {
    dispatch<any>(createNote(data));
  };
  const CreateTask = (data: CreateTaskDTO) => {
    dispatch<any>(createTask(data));
  };
  const RecordPayment = (data: RecordPaymentDTO) => {
    dispatch<any>(recordPayment(data));
  };
  const CreateDemo = (data: CreateDemoDTO) => {
    dispatch<any>(createDemo(data));
  };
  const ComposeEmailSent = (data: ComposeEmailDTO) => {
    dispatch<any>(composeEmail(data));
  };

  const EditDemo = (data: EditDemoDTO) => {
    dispatch<any>(editDemo(data));
  };
  const EditNote = (data: EditNoteDTO) => {
    dispatch<any>(editNote(data));
  };

  const EditTask = (data: EditTaskDTO) => {
    dispatch<any>(editTask(data));
  };
  const EditRecordPayment = (data: RecordPaymentDTO) => {
    dispatch<any>(editRecordPayment(data));
  };
  const EditComment = (data: CreateCommentDTO) => {
    dispatch<any>(editComment(data));
  };

  const UpdateLead = (data: NurtureLead) => {
    dispatch<any>(updateLead(data));
  };

  const UpdateLeadEmiDetails = (data: leadEmiDTO) => {
    dispatch<any>(updateLeadEmiDetails(data));
  };
  const UpdateLeadSwimlaneStatus = (data: LeadSwimlaneStatusUpdateDTO) => {
    dispatch<any>(updateLeadSwimlaneStatus(data));
  };
  const UpdateLeadEnquiyStatus = (data: LeadStatusUpdateDTO) => {
    dispatch<any>(updateLeadEnquiryStatus(data));
  };
  const UpdateTaskStatus = (data: any) => {
    dispatch<any>(updateTaskStatus(data));
  };
  const DeleteTask = (data: DeleteTaskDTO) => {
    dispatch<any>(deleteTask(data));
  };

  const DeleteNote = (data: DeleteNoteDTP) => {
    dispatch<any>(deleteNote(data));
  };

  const DeleteDemoBookedForLead = (data: DeleteDemoBookedDTO) => {
    dispatch<any>(deleteDemoBookedForLead(data));
  };
  const DeleteComment = (data: DeleteCommentDTO) => {
    dispatch<any>(deleteComment(data));
  };

  const batchCreateSuccess = useSelector(
    (state: any) => state.batch.batchCreateSuccess
  );
  const {
    editNoteSuccess,
    deleteNoteSuccess,
    deleteTaskSuccess,
    editTaskSuccess,
    sendPaymentLinkSuccess,
    downloadPaymentReceiptError,
    downloadPaymentReceiptSuccess,
    leadEmiDetailsError,
    leadEmiDetailsSuccess,
    editDemoSuccess,
    createDemoSuccess,
    composeEmailSuccess,
    leadLostUpdateSuccess,
    createPaymentSuccess,
    leadConvertionError,
    leadStatusUpdateError,
    leadStatusUpdateSuccess,
    leadUpdateSuccess,
    leadCreateSuccess,
    deleteDemoBookedSuccess,
    recordPaymentUpdateSuccess,
    recordPaymentUpdateError,

    getEmiDataloading,
    leadEmiDetailsLoading,
    leadBoardLoading,
    leadListLoading,
    createDemoLoading,
    deleteDemoBookedLoading,
    noteListLoading,
    leadActivityLoading,
    inboxLoading,
    paymentListLoading,
    leadTasksloading,
    leadListByContact,
    leadBoard,
    autoDebitSuccess,
    leadDiscountSuccess,
    pauseSuccess,
    randomPaymentSuccess,
    deleteAdditionalPaySuccess,
    sessionPayRecordSuccess,
  } = useSelector((state: any) => state.nurture);
  const swimlaneData = leadBoard?.swimlaneData;
  const leadStages =
    swimlaneData &&
    [...swimlaneData]?.sort((a: any, b: any) => {
      return a?.orderIndex - b?.orderIndex;
    });
  // const employees = leadBoard?.employees;
  const taskTypes = leadBoard?.taskType;
  const courseTypes = leadBoard?.courseType;

  const employees = useSelector((state: any) => state.staff.staffList);
  // Data states starts
  // const taskTypes = useSelector((state: any) => state.tasks.tasksTypes);
  const { taskStatusUpdateSuccess } = useSelector((state: any) => state.tasks);
  const batchList = useSelector((state: any) => state.batch.batchList);
  const courseList = useSelector((state: any) => state.courses.courses);
  const branchList = useSelector((state: any) => state.branch.branchList);
  // const courseTypes = useSelector(
  //   (state: any) => state.courseConfig.courseConfigs.courseType
  // );

  const leadById = useSelector((state: any) => state.nurture.leadById);
  const leadActivityList = useSelector(
    (state: any) => state.nurture.leadActivityList
  );
  const leadPaymentList = useSelector(
    (state: any) => state.nurture.leadPaymentList
  );
  const leadNoteList = useSelector((state: any) => state.nurture.leadNoteList);
  const leadTaskList = useSelector((state: any) => state.nurture.leadTaskList);
  const leadInbox = useSelector((state: any) => state.nurture.leadInbox);
  const demoByCourseAndInstitute = useSelector(
    (state: any) => state.nurture.demoByCourseAndInstitute
  );
  const leadEmiData = useSelector((state: any) => state.nurture.leadEmiData);
  const { callDispositionError, callDispositionSuccess } = useSelector(
    (state: any) => state.appIntegrations
  );

  // Data states ends
  const filteredPaymentList = leadPaymentList?.filter(
    (item: any) => item?.paymentStatus === "PAID"
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLeadId, setSelectedLeadId] = React.useState<string>("");
  const [selectedLeadData, setSelectedLeadData] = React.useState<any>("");
  const [value, setValue] = useState<number>(0);
  const ClearLeadPopupQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("leadModal");
    queryParams.delete("leadId");
    queryParams.delete("tabOpen");
    queryParams.delete("recordPayment");
    queryParams.delete("leadInstituteId");
    queryParams.delete("leadEmail");
    queryParams.delete("leadPhone");
    queryParams.delete("leadTaskId");
    queryParams.delete("leadNoteId");
    queryParams.delete("leadPaymentId");
    queryParams.delete("taskTabOpen");
    queryParams.delete("leadEmiId");

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    dispatch(resetLeadModalState());
    dispatch(setLeadModalLeadId(null));
  };

  const handleResetLeadById = () => {
    dispatch(resetLeadListByContact());
    dispatch(resetLeadLEmiData());
    dispatch(resetLeadPaymentData());
    dispatch(resetLeadById());
  };

  useEffect(() => {
    if (leadListByContact) {
      setSelectedLeadId(leadListByContact[0]?.id);
      setSelectedLeadData(leadListByContact[0]);
    } else if (leadListByContact === null) {
      setSelectedLeadId("");
      setSelectedLeadData("");
    } else {
      setSelectedLeadId("");
      setSelectedLeadData("");
    }
  }, [setSelectedLeadId, setSelectedLeadData, leadListByContact]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const leadInstituteId = urlSearchParams.get("leadInstituteId");
    const leadPhone = urlSearchParams.get("leadPhone");
    const leadEmail = urlSearchParams.get("leadEmail");
    if (leadInstituteId) {
      if (
        (leadLostUpdateSuccess !== true && leadLostUpdateSuccess !== false) ||
        (leadUpdateSuccess !== true && leadUpdateSuccess !== false) ||
        (leadStatusUpdateSuccess !== true &&
          leadStatusUpdateSuccess !== false) ||
        (leadCreateSuccess !== true && leadCreateSuccess !== false) ||
        (createDemoSuccess !== true && createDemoSuccess !== false) ||
        (batchCreateSuccess !== true && batchCreateSuccess !== false) ||
        (editDemoSuccess !== true && editDemoSuccess !== false) ||
        (deleteDemoBookedSuccess !== true &&
          deleteDemoBookedSuccess !== false) ||
        (createPaymentSuccess !== true && createPaymentSuccess !== false) ||
        (leadEmiDetailsSuccess !== true && leadEmiDetailsSuccess !== false) ||
        (autoDebitSuccess !== true && autoDebitSuccess !== false) ||
        (leadDiscountSuccess !== true && leadDiscountSuccess !== false) ||
        (pauseSuccess !== true && pauseSuccess !== false) ||
        (sessionPayRecordSuccess !== true && sessionPayRecordSuccess !== false)
      ) {
        leadPopupApiCall(leadInstituteId, leadPhone, leadEmail);
        dispatch(resetPaymentStatuses());
        dispatch(resetLeadStatuses());
      }
    }
  }, [
    leadLostUpdateSuccess,
    leadStatusUpdateSuccess,
    leadUpdateSuccess,
    leadCreateSuccess,
    createDemoSuccess,
    editDemoSuccess,
    deleteDemoBookedSuccess,
    batchCreateSuccess,
    createPaymentSuccess,
    leadEmiDetailsSuccess,
    autoDebitSuccess,
    leadDiscountSuccess,
    pauseSuccess,
    sessionPayRecordSuccess,
  ]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const leadModalOpen = urlSearchParams.get("leadModal");
    const leadInstituteId = urlSearchParams.get("leadInstituteId");
    const leadId = urlSearchParams.get("leadId");

    if (
      leadModalOpen === "true" &&
      leadInstituteId &&
      leadId &&
      leadListByContact
    ) {
      setSelectedLeadId(leadId);

      const leadData = leadListByContact?.find(
        (item: any) => item.id === leadId
      );
      if (leadData) {
        setSelectedLeadData(leadData);
      }
    }
  }, [leadListByContact]);

  React.useEffect(() => {
    if (
      leadListByContact &&
      typeof leadListByContact[0]?.studentFirstName === "string" &&
      typeof leadListByContact[0]?.studentLastName === "string" &&
      typeof leadListByContact[0]?.studentEmail === "string" &&
      typeof leadListByContact[0]?.studentContact === "string"
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [leadListByContact]);

  React.useEffect(() => {
    if (selectedLeadData) {
      if (selectedLeadData?.enquirySwimlaneStatus === "DEMO") {
        let data = {
          courseId: selectedLeadData?.courseId,
          id: selectedLeadData?.instituteId,
        };
        if (selectedLeadData?.courseId && selectedLeadData?.instituteId) {
          GetDemoCalanderByCourseAndInstitute(data);
        }
      }
    }
  }, [selectedLeadData]);

  React.useEffect(() => {
    if (
      (createPaymentSuccess !== true && createPaymentSuccess !== false) ||
      (recordPaymentUpdateSuccess !== true &&
        recordPaymentUpdateSuccess !== false) ||
      (sendPaymentLinkSuccess !== true && sendPaymentLinkSuccess !== false) ||
      (randomPaymentSuccess !== true && randomPaymentSuccess !== false) ||
      (deleteAdditionalPaySuccess !== true &&
        deleteAdditionalPaySuccess !== false)
    ) {
      if (selectedLeadData) GetLeadPaymentList(selectedLeadData?.id);
      dispatch(resetPaymentStatuses());
    }
  }, [
    createPaymentSuccess,
    recordPaymentUpdateSuccess,
    sendPaymentLinkSuccess,
    randomPaymentSuccess,
    deleteAdditionalPaySuccess,
  ]);

  React.useEffect(() => {
    if (batchCreateSuccess !== true && batchCreateSuccess !== false) {
      if (selectedLeadData?.enquirySwimlaneStatus === "DEMO") {
        let data = {
          courseId: selectedLeadData?.courseId,
          id: selectedLeadData?.instituteId,
        };
        if (selectedLeadData?.courseId && selectedLeadData?.instituteId) {
          GetDemoCalanderByCourseAndInstitute(data);
        }
      }
    }
  }, [batchCreateSuccess, selectedLeadData]);

  React.useEffect(() => {
    if (selectedLeadData && !getEmiDataloading) {
      if (
        selectedLeadData?.enquirySwimlaneStatus === "NEGOTIATION" ||
        selectedLeadData?.enquirySwimlaneStatus === "CONVERTED"
      ) {
        GetLeadPaymentList(selectedLeadData?.id);
        GetEmiDataOfLead({
          id: selectedLeadData?.id,
          loadSessionPayment:
            selectedLeadData?.paymentDetails?.paymentType === "SESSION"
              ? true
              : false,
        });
      }
    }
  }, [selectedLeadData]);

  const filteredAddNewCourseList = courseList
    ?.filter((item: any) => item?.status === "ACTIVE")
    ?.filter(
      (course: any) =>
        !leadListByContact?.some((lead: any) => lead.courseId === course.id)
    );

  React.useEffect(() => {
    if (leadEmiDetailsSuccess !== true && leadEmiDetailsSuccess !== false) {
      if (selectedLeadData)
        GetEmiDataOfLead({
          id: selectedLeadData?.id,
          loadSessionPayment:
            selectedLeadData?.paymentDetails?.paymentType === "SESSION"
              ? true
              : false,
        });
    }
  }, [leadEmiDetailsSuccess]);

  React.useEffect(() => {
    if (
      (leadEmiDetailsSuccess !== true && leadEmiDetailsSuccess !== false) ||
      (leadEmiDetailsError !== true && leadEmiDetailsError !== false)
    ) {
      dispatch(resetLeadLEmiStatus());
      dispatch(resetPaymentStatuses());
    }
  }, [leadEmiDetailsSuccess, leadEmiDetailsError]);

  React.useEffect(() => {
    if (createDemoSuccess !== true && createDemoSuccess !== false) {
      dispatch(resetDemoState());
    }
  }, [createDemoSuccess]);

  React.useEffect(() => {
    if (deleteDemoBookedSuccess !== true && deleteDemoBookedSuccess !== false) {
      dispatch(resetDemoState());
    }
  }, [deleteDemoBookedSuccess]);

  React.useEffect(() => {
    if (editDemoSuccess !== true && editDemoSuccess !== false) {
      dispatch(resetDemoState());
    }
  }, [editDemoSuccess]);

  React.useEffect(() => {
    if (taskStatusUpdateSuccess !== true && taskStatusUpdateSuccess !== false) {
      GetLeadTaskList(selectedLeadData?.id);
      dispatch(resetTaskUpdateSuccess());
    }
  }, [taskStatusUpdateSuccess]);

  React.useEffect(() => {
    if (deleteTaskSuccess !== true && deleteTaskSuccess !== false) {
      GetLeadTaskList(selectedLeadData?.id);
    }
  }, [deleteTaskSuccess]);

  React.useEffect(() => {
    if (editTaskSuccess !== true && editTaskSuccess !== false) {
      GetLeadTaskList(selectedLeadData?.id);
    }
  }, [editTaskSuccess]);

  React.useEffect(() => {
    if (deleteNoteSuccess !== true && deleteNoteSuccess !== false) {
      const urlSearchParams = new URLSearchParams(window.location.search);

      const leadId = urlSearchParams.get("leadId");
      if (leadId) {
        GetLeadNoteList(leadId);
      }
    }
  }, [deleteNoteSuccess]);

  React.useEffect(() => {
    if (editNoteSuccess !== true && editNoteSuccess !== false) {
      GetLeadNoteList(selectedLeadData?.id);
    }
  }, [editNoteSuccess]);

  React.useEffect(() => {
    if (createPaymentSuccess !== true && createPaymentSuccess !== false) {
      dispatch(resetPaymentSuccess());
    }
  }, [createPaymentSuccess]);

  React.useEffect(() => {
    if (composeEmailSuccess !== true && composeEmailSuccess !== false) {
      dispatch(resetEmailStatuses());
    }
  }, [composeEmailSuccess]);

  React.useEffect(() => {
    if (
      downloadPaymentReceiptSuccess !== true &&
      downloadPaymentReceiptSuccess !== false
    ) {
      dispatch(resetDownloadStatuses());
    }
  }, [downloadPaymentReceiptSuccess]);

  React.useEffect(() => {
    if (
      downloadPaymentReceiptError !== true &&
      downloadPaymentReceiptError !== false
    ) {
      dispatch(resetDownloadStatuses());
    }
  }, [downloadPaymentReceiptError]);

  React.useEffect(() => {
    if (
      recordPaymentUpdateSuccess !== true &&
      recordPaymentUpdateSuccess !== false
    ) {
      dispatch(resetPaymentSuccess());
    }
  }, [recordPaymentUpdateSuccess]);

  React.useEffect(() => {
    if (
      recordPaymentUpdateError !== true &&
      recordPaymentUpdateError !== false
    ) {
      dispatch(resetPaymentSuccess());
    }
  }, [recordPaymentUpdateError]);

  React.useEffect(() => {
    if (callDispositionSuccess !== true && callDispositionSuccess !== false) {
      dispatch(resetCallDispositionFormStatuses());
    }
  }, [callDispositionSuccess]);

  React.useEffect(() => {
    if (callDispositionError !== true && callDispositionError !== false) {
      dispatch(resetCallDispositionFormStatuses());
    }
  }, [callDispositionError]);

  useEffect(() => {
    const handleBrowserBack = () => {
      const searchParams = new URLSearchParams(location.search);
      if (!searchParams.has("leadModal")) {
        dispatch(resetLeadModalState());
      }
    };

    window.addEventListener("popstate", handleBrowserBack);

    return () => {
      window.removeEventListener("popstate", handleBrowserBack);
    };
  }, [location.search]);
  const theme = useTheme();
  const isTabSize = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className={styles.leadPopupWrapper}>
      <div className={styles.leadPopupWrapperChild}>
        <div className={styles.leadPopupSplit}>
          <div className={styles.leadPopupSplitLeft}>
            <div className={styles.leadPopupCloseWrapTab}>
              <button
                className={styles.closeBtn}
                onClick={() => {
                  ClearLeadPopupQuery();
                  handleResetLeadById();
                }}
              >
                <img src={closeIcon} alt="closeIcon" />
              </button>
            </div>
            <div className={styles.leadProfileDetailsWrap}>
              {!leadListLoading &&
              leadStages !== null &&
              leadListByContact !== null ? (
                <>
                  <LeadDetailsTop
                    leadById={leadById}
                    employees={employees}
                    GetLeadById={GetLeadById}
                    UpdateLead={UpdateLead}
                    selectedLeadData={selectedLeadData}
                    CallLead={CallLead}
                    taskTypes={taskTypes}
                    leadListByContact={leadListByContact}
                    leadPopupApiCall={leadPopupApiCall}
                    CallDispositionForm={CallDispositionForm}
                  />
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginBottom: "6px",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={40}
                      height={40}
                    />
                  </div>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={30}
                    sx={{ marginBottom: "6px" }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    height={40}
                    sx={{ marginBottom: "6px" }}
                  />
                </>
              )}
            </div>

            <div className={styles.leadCourseStatusWrap}>
              {!leadListLoading && leadStages !== null ? (
                <>
                  <LeadCourseDetails
                    branchList={branchList}
                    batchList={batchList?.filter(
                      (item: any) =>
                        item?.batchConductType === "REGULAR_BATCH" &&
                        item?.status === "ACTIVE"
                    )}
                    courseList={courseList?.filter(
                      (item: any) => item?.status === "ACTIVE"
                    )}
                    leadListByContact={leadListByContact}
                    leadStages={leadStages}
                    selectedLeadId={selectedLeadId}
                    selectedLeadData={selectedLeadData}
                    setSelectedLeadId={setSelectedLeadId}
                    setSelectedLeadData={setSelectedLeadData}
                    UpdateLeadEnquiyStatus={UpdateLeadEnquiyStatus}
                    UpdateLeadSwimlaneStatus={UpdateLeadSwimlaneStatus}
                    UpdateLead={UpdateLead}
                    leadUpdateSuccess={leadUpdateSuccess}
                    GetLeadPaymentList={GetLeadPaymentList}
                    GetEmiDataOfLead={GetEmiDataOfLead}
                    setValue={setValue}
                    leadPaymentList={filteredPaymentList}
                    isFromWebform={false}
                  />
                  {filteredAddNewCourseList?.length > 0 && (
                    <LeadAddCourse
                      leadById={leadById}
                      branchList={branchList}
                      batchList={batchList?.filter(
                        (item: any) =>
                          item?.batchConductType === "REGULAR_BATCH" &&
                          item?.batchStatus !== "COMPLETED" &&
                          item?.batchStatus !== "CANCELLED"
                      )}
                      courseList={filteredAddNewCourseList}
                      selectedLeadData={selectedLeadData}
                      employees={employees}
                      nurtureAddEnquiry={nurtureAddEnquiry}
                      leadCreateSuccess={leadCreateSuccess}
                    />
                  )}
                </>
              ) : (
                Array.from(new Array(3)).map((_, index) => (
                  <div key={index} style={{ marginBottom: "8px" }}>
                    <div className={styles.leadCourseSkeletonWrap}>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        height={60}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div
            className={styles.leadPopupSplitRight}
            style={{
              height: !isTabSize ? "calc(100vh - 2% ) " : "calc(-14% + 100vh)",
              maxHeight: !isTabSize
                ? "calc(100vh - 2% ) "
                : "calc(-14% + 100vh)",
              minHeight: !isTabSize
                ? "calc(100vh - 2% ) "
                : "calc(-14% + 100vh)",
              overflow: "auto",
            }}
          >
            <div className={styles.leadPopupCloseWrap}>
              <button
                className={styles.closeBtn}
                onClick={() => {
                  ClearLeadPopupQuery();
                  handleResetLeadById();
                }}
              >
                <img src={closeIcon} alt="closeIcon" />
              </button>
            </div>
            <div className={styles.leadPopupTabWrapper}>
              <ListTab
                employees={employees && employees}
                leadActivityList={leadActivityList && leadActivityList}
                leadInbox={leadInbox}
                leadTaskList={leadTaskList}
                leadNoteList={leadNoteList}
                leadPaymentList={filteredPaymentList}
                taskTypes={taskTypes && taskTypes}
                demoTypes={courseTypes}
                CreateNote={CreateNote}
                CreateTask={CreateTask}
                RecordPayment={RecordPayment}
                GetInboxByLeadId={GetInboxByLeadId}
                ComposeEmailSent={ComposeEmailSent}
                GetLeadNoteList={GetLeadNoteList}
                GetLeadPaymentList={GetLeadPaymentList}
                GetLeadTaskList={GetLeadTaskList}
                GetLeadActivityList={GetLeadActivityList}
                CreateDemo={CreateDemo}
                leadActivityLoading={leadActivityLoading}
                composeEmailSuccess={composeEmailSuccess}
                leadConvertionError={leadConvertionError}
                leadStatusUpdateError={leadStatusUpdateError}
                selectedLeadData={selectedLeadData}
                UpdateLead={UpdateLead}
                DeleteNote={DeleteNote}
                EditNote={EditNote}
                DeleteTask={DeleteTask}
                EditTask={EditTask}
                UpdateTaskStatus={UpdateTaskStatus}
                demoByCourseAndInstitute={demoByCourseAndInstitute}
                createDemoLoading={createDemoLoading}
                CreateBatch={CreateBatch}
                DeleteDemoBookedForLead={DeleteDemoBookedForLead}
                deleteDemoBookedLoading={deleteDemoBookedLoading}
                deleteDemoBookedSuccess={deleteDemoBookedSuccess}
                EditDemo={EditDemo}
                UpdateLeadEmiDetails={UpdateLeadEmiDetails}
                createDemoSuccess={createDemoSuccess}
                leadTasksloading={leadTasksloading}
                paymentListLoading={paymentListLoading}
                noteListLoading={noteListLoading}
                inboxLoading={inboxLoading}
                leadListLoading={leadListLoading}
                leadStages={leadStages}
                leadEmiDetailsLoading={leadEmiDetailsLoading}
                GetEmiDataOfLead={GetEmiDataOfLead}
                leadEmiData={leadEmiData}
                SendPaymentLink={SendPaymentLink}
                getEmiDataloading={getEmiDataloading}
                DownloadPaymentReceipt={DownloadPaymentReceipt}
                setValue={setValue}
                value={value}
                EditRecordPayment={EditRecordPayment}
                CreateComment={CreateComment}
                DeleteComment={DeleteComment}
                EditComment={EditComment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadPopup;
