import React from "react";
import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function BatchStudentSkeleton() {
  return (
    <div className={styles.batchBasicDetailsWrap} style={{ padding: "16px" }}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={30}
      />
    </div>
  );
}

export default BatchStudentSkeleton;
