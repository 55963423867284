import React, { useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  Button,
  Select,
  MenuItem,
  SnackbarOrigin,
  CircularProgress,
} from "@mui/material";
import styles from "./LeadPopup.module.css";
import dateIcon from "./Assets/date.svg";
import clockIcon from "./Assets/clock.svg";
import dropDownIcon from "./Assets/dropdown.svg";
import Paper from "@mui/material/Paper";
import radioIcon from "./Assets/radio.svg";
import moment from "moment";
import greenRadioIcon from "./Assets/green.svg";
import redRadioIcon from "./Assets/red.svg";
import editIcon from "./Assets/edit-icon.svg";
import { useAuthUser } from "react-auth-kit";
import {
  resetDemoBatchesData,
  resetLeadLEmiData,
  resetLeadPaymentData,
} from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import greenTick from "../../assets/images/greenTick.svg";
import BatchChangeWarning from "../../pages/app/Nurture/components/BatchChangeWarning/BatchChangeWarning";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

function LeadCourseDetails(props: any) {
  const {
    branchList,
    batchList,
    courseList,
    leadListByContact,
    leadStages,
    selectedLeadData,
    setSelectedLeadId,
    setSelectedLeadData,
    UpdateLeadEnquiyStatus,
    UpdateLeadSwimlaneStatus,
    UpdateLead,
    leadUpdateSuccess,
    GetEmiDataOfLead,
    GetLeadPaymentList,
    isFromWebform,
    setValue,
    leadPaymentList,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const filteredBranchList = hasAllBranchAccess
    ? branchList
    : branchList?.filter(
        (branch: any) => branch.id === authUser?.institute?.[0]?.branchId
      );

  const { leadUpdateLoading, leadStatusUpdateLoading } = useSelector(
    (state: any) => state.nurture
  );
  const filterLeadList = leadListByContact?.filter(
    (item: any) => item.courseId !== selectedLeadData?.courseId
  );

  let leadPopupOpen = true;
  const [leadDetails, setLeadDetails] = React.useState<any>(selectedLeadData);
  const filteredCourseList = courseList?.filter(
    (course: any) =>
      !filterLeadList?.some((lead: any) => lead.courseId === course.id)
  );
  const defaultCourse =
    courseList && selectedLeadData
      ? filteredCourseList?.find(
          (item: any) => item.id === leadDetails?.courseId
        )
      : "";

  const defaultBranches =
    branchList && selectedLeadData
      ? branchList?.find((branch: any) => branch.id === leadDetails?.branchId)
      : "";

  const defaultBatch =
    batchList && selectedLeadData
      ? batchList?.find((batch: any) => batch.id === leadDetails?.batchId)
      : "";
  const [validStateCourse, setValidStateCourse] = React.useState<boolean>(true);
  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);

  let isLeadBranchEdit = true;
  const [validStateBatch, setValidStateBatch] = React.useState<boolean>(true);
  const [branchAndBatchEdit, setBranchAndBatchEdit] =
    React.useState<boolean>(false);

  const [expanded, setExpanded] = React.useState<string | false>(
    leadListByContact && leadListByContact[0] ? leadListByContact[0].id + 0 : ""
  );
  const [stage, setStage] = React.useState<any>(
    leadListByContact && leadListByContact[0]
      ? leadListByContact[0].enquirySwimlaneStatus
      : ""
  );

  const filteredBatchList = batchList?.filter((batch: any) => {
    const courseIsChecked =
      leadDetails?.courseId === "" || leadDetails?.courseId === batch.courseId;

    const branchIsChecked =
      leadDetails?.branchId === "" || leadDetails?.branchId === batch.branchId;

    const isActive = batch.status === "ACTIVE";
    const isActiveBatchStatus = batch.batchStatus !== "COMPLETED";
    const isBatchConductType = batch.batchConductType === "REGULAR_BATCH";

    return (
      courseIsChecked &&
      branchIsChecked &&
      isActive &&
      isActiveBatchStatus &&
      isBatchConductType
    );
  });

  const branchAndBatchEditCancel = () => {
    setBranchAndBatchEdit(false);
    if (selectedLeadData) {
      setLeadDetails({
        ...leadDetails,
        branchId: selectedLeadData?.branchId,
        branchName: selectedLeadData?.branchName,
      });
    }
  };

  const handleStageChange = React.useCallback(
    (leadId: any, leadStage: any | null, leadData: any) => {
      setSelectedLeadId(leadId);
      setStage(leadStage);
      setSelectedLeadData(leadData);

      const queryParams = new URLSearchParams(window.location.search);

      const leadModal = queryParams.get("leadModal");
      if (leadModal) {
        if (leadId) {
          queryParams.set("leadId", leadId);
        } else {
          queryParams.delete("leadId");
        }
      }
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    },
    [setSelectedLeadId, setStage, setSelectedLeadData]
  );

  const handleChange = useCallback(
    (panel: string, id: string, status: string, leadData: any) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {
        if (!newExpanded) {
          // If the panel is already expanded and clicked again, keep it expanded
        } else {
          setExpanded(panel);
          handleStageChange(id, status, leadData);
          setBranchAndBatchEdit(false);
          if (status !== "DEMO") {
            dispatch(resetDemoBatchesData());
          }
          if (status !== "NEGOTIATION" && status !== "CONVERTED") {
            dispatch(resetLeadPaymentData());
            dispatch(resetLeadLEmiData());
          }
          const queryParams = new URLSearchParams(window.location.search);

          const leadTabOpen = queryParams.get("tabOpen");
          if (
            status === "NEGOTIATION" ||
            status === "CONVERTED" ||
            leadTabOpen === "payment"
          ) {
            GetEmiDataOfLead({
              id: id,
              loadSessionPayment:
                leadData?.paymentDetails?.paymentType === "SESSION"
                  ? true
                  : false,
            });
            GetLeadPaymentList(id);
          }
        }
      },

    [handleStageChange, setExpanded, setBranchAndBatchEdit]
  );

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setLeadDetails({
          ...leadDetails,
          courseId: value.id,
          courseName: value.courseName,
          batchId: "",
          batchName: "",
        });
        setValidStateCourse(true);
      } else {
        setLeadDetails({
          ...leadDetails,
          courseId: "",
          courseName: "",
          batchId: "",
          batchName: "",
        });
        setValidStateCourse(false);
      }
    },
    [leadDetails]
  );

  const onBranchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setLeadDetails({
          ...leadDetails,
          branchId: value.id,
          branchName: value.branchName,
          batchId: "",
          batchName: "",
        });
        setValidStateBranch(true);
      } else {
        setLeadDetails({
          ...leadDetails,
          branchId: "",
          branchName: "",
        });
        setValidStateBranch(false);
      }
    },
    [leadDetails]
  );

  const onBatchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setLeadDetails({
          ...leadDetails,
          batchId: value.id,
          batchName: value.batchName,
        });
        if (selectedLeadData?.actionPending?.studentCreated === true) {
          setValidStateBatch(true);
        }
      } else {
        setLeadDetails({
          ...leadDetails,
          batchId: "",
          batchName: "",
        });
        if (selectedLeadData?.actionPending?.studentCreated === true) {
          setValidStateBatch(false);
        }
      }
    },
    [leadDetails]
  );

  const onStageChange = React.useCallback(
    (event: any) => {
      setStage(event.target.value);
    },
    [setStage]
  );

  const onStageCancelChange = React.useCallback(
    (leadStage: any) => {
      setStage(leadStage);
    },
    [setStage]
  );

  const handleLeadStatusSelect = React.useCallback(
    (leadId: any, status: any) => {
      UpdateLeadEnquiyStatus({
        id: leadId,
        status: status,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      });
    },
    [UpdateLeadEnquiyStatus]
  );

  const handleLeadStageChange = React.useCallback(
    (leadId: any, stage: any) => {
      UpdateLeadSwimlaneStatus({
        enquiryId: leadId,
        swimlaneStatus: stage,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      });
    },
    [UpdateLeadSwimlaneStatus]
  );

  const handleLeadUpdateSubmit = async (event: any) => {
    event.preventDefault();
    try {
      !leadDetails.courseId && setValidStateCourse(false);
      !leadDetails.branchId && setValidStateBranch(false);
      if (
        leadDetails &&
        !leadDetails.updatedById &&
        !leadDetails.updatedByName
      ) {
        leadDetails.updatedById = authUser?.institute[0]?.id;
        leadDetails.updatedByName =
          authUser?.firstName + " " + authUser?.lastName;
      }
      validStateBatch &&
        leadDetails.branchId &&
        leadDetails.courseId &&
        UpdateLead({
          ...leadDetails,
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
          instituteId: authUser?.institute[0]?.instituteId,
        });
    } catch (error) {}
  };

  const [openBatchWarning, setopenBatchWarning] =
    React.useState<boolean>(false);

  const openBatchWarnPopup = (event: any) => {
    event.preventDefault();
    validStateBatch && setopenBatchWarning(true);
  };
  const closeBatchWarnPopup = () => {
    setopenBatchWarning(false);
  };

  const yesButtonClick = () => {
    try {
      !leadDetails.courseId && setValidStateCourse(false);
      !leadDetails.branchId && setValidStateBranch(false);

      leadDetails.courseId &&
        leadDetails.branchId &&
        UpdateLead({
          ...leadDetails,
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
          instituteId: authUser?.institute[0]?.instituteId,
        });
    } catch (error) {}
  };

  const handleTabClickQuery = (tabType: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("tabOpen", tabType);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
    setValue(4);
    setStage(selectedLeadData?.enquirySwimlaneStatus);
  };

  useEffect(() => {
    if (leadListByContact && selectedLeadData) {
      setLeadDetails(selectedLeadData);
      if (isFromWebform) {
        const leadData = leadListByContact.find(
          (item: any) => item.id === selectedLeadData?.id
        );
        const leadDataIndex = leadListByContact.findIndex(
          (item: any) => item.id === selectedLeadData?.id
        );
        if (leadData) {
          setStage(leadData?.enquirySwimlaneStatus);
          setExpanded(leadData?.id + leadDataIndex);
        }
      }
    }
  }, [leadListByContact, setStage, selectedLeadData, branchAndBatchEdit]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const leadModalOpen = urlSearchParams.get("leadModal");
    const leadInstituteId = urlSearchParams.get("leadInstituteId");
    const leadId = urlSearchParams.get("leadId");

    if (
      leadModalOpen === "true" &&
      leadInstituteId &&
      leadId &&
      leadListByContact
    ) {
      const leadData = leadListByContact.find(
        (item: any) => item.id === leadId
      );
      const leadDataIndex = leadListByContact.findIndex(
        (item: any) => item.id === leadId
      );
      if (leadData) {
        setStage(leadData?.enquirySwimlaneStatus);
        setExpanded(leadData?.id + leadDataIndex);
      }
    }
  }, [leadListByContact]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const leadModalOpen = urlSearchParams.get("leadModal");

    if (leadUpdateSuccess !== true && leadUpdateSuccess !== false) {
      if (leadModalOpen) {
        setBranchAndBatchEdit(false);
        setopenBatchWarning(false);
      }
    }
  }, [leadUpdateSuccess]);

  return (
    <div>
      {leadListByContact &&
        leadListByContact[0] &&
        leadListByContact?.map((lead: any, index: any) => (
          <Accordion
            expanded={expanded === `${lead.id + index}`}
            onChange={handleChange(
              `${lead.id + index}`,
              lead.id,
              lead.enquirySwimlaneStatus,
              lead
            )}
            className={styles.accordion}
            key={lead.id + index}
            sx={{
              color: "#142C44",
              backgroundColor:
                expanded === `${lead.id + index}` && lead?.status === "ACTIVE"
                  ? "rgba(17, 200, 155, 0.02)"
                  : expanded === `${lead.id + index}` && lead?.status === "LOST"
                  ? "rgba(236, 57, 57, 0.02)"
                  : "transparent",
              border:
                expanded === `${lead.id + index}` && lead?.status === "ACTIVE"
                  ? "1px solid rgba(17, 200, 155, 0.50)"
                  : expanded === `${lead.id + index}` && lead?.status === "LOST"
                  ? "1px solid rgba(236, 57, 57, 0.50)"
                  : "1px solid #E3E3E3",
              boxShadow: "none",
              padding: "0px 16px",
              borderRadius: "4PX",
              marginBottom: "10PX",

              "&.Mui-expanded": {
                marginBottom: "10PX !important",
                marginTop: "0",
              },
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                color: "#667085",
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                padding: "0",
                "& .MuiAccordionSummary-content ": {
                  margin: "0 !important",
                },
                "&.Mui-expanded": {
                  minHeight: 42,
                },
              }}
              expandIcon={
                leadListByContact?.length > 1 ? (
                  <img src={dropDownIcon} alt="" />
                ) : (
                  <></>
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={styles.accordionCourseWrap}>
                <div className={styles.accordionCourseIcon}>
                  <img
                    src={
                      expanded === `${lead.id + index}` &&
                      lead?.status === "ACTIVE"
                        ? greenRadioIcon
                        : expanded === `${lead.id + index}` &&
                          lead?.status === "LOST"
                        ? redRadioIcon
                        : radioIcon
                    }
                    alt="radio"
                  />
                </div>
                <div className={styles.accordionCourseName}>
                  {lead?.courseName}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0 2px",
                border: "none",

                "& .MuiCollapse-wrapperInner": {
                  minHeight: 25,
                },
              }}
            >
              <div>
                <div className={styles.stageProgressBar}>
                  <div className={styles.stageProgressBarLine}>
                    <div
                      className={` ${
                        lead?.status === "ACTIVE"
                          ? styles.stageProgressBarLineChildActive
                          : lead?.status === "LOST"
                          ? styles.stageProgressBarLineChildLost
                          : ""
                      }`}
                      style={{
                        width:
                          lead?.enquirySwimlaneStatus === "NEW_ENQUIRY"
                            ? "0%"
                            : lead?.enquirySwimlaneStatus === "CONTACTED"
                            ? "30%"
                            : lead?.enquirySwimlaneStatus === "DEMO"
                            ? "50%"
                            : lead?.enquirySwimlaneStatus === "NEGOTIATION"
                            ? "70%"
                            : lead?.enquirySwimlaneStatus === "CONVERTED"
                            ? "100%"
                            : "0%",
                      }}
                    ></div>
                  </div>
                  <div
                    className={`${styles.stageProgressBarItem} ${
                      lead?.enquirySwimlaneStatus === "NEW_ENQUIRY"
                        ? styles.stageProgressBarItemAfter
                        : ""
                    }`}
                  >
                    <div
                      className={`${styles.stageProgressBarItemCount} ${
                        (lead?.enquirySwimlaneStatus === "NEW_ENQUIRY" ||
                          lead?.enquirySwimlaneStatus === "CONTACTED" ||
                          lead?.enquirySwimlaneStatus === "DEMO" ||
                          lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                          lead?.enquirySwimlaneStatus === "CONVERTED") &&
                        lead?.status === "ACTIVE"
                          ? styles.stageGreenBackground
                          : (lead?.enquirySwimlaneStatus === "NEW_ENQUIRY" ||
                              lead?.enquirySwimlaneStatus === "CONTACTED" ||
                              lead?.enquirySwimlaneStatus === "DEMO" ||
                              lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                              lead?.enquirySwimlaneStatus === "CONVERTED") &&
                            lead?.status === "LOST"
                          ? styles.stageGreyBackground
                          : ""
                      }`}
                    >
                      1
                    </div>
                  </div>
                  <div
                    className={`${styles.stageProgressBarItem} ${
                      lead?.enquirySwimlaneStatus === "CONTACTED"
                        ? styles.stageProgressBarItemAfter
                        : ""
                    }`}
                  >
                    <div
                      className={`${styles.stageProgressBarItemCount} ${
                        (lead?.enquirySwimlaneStatus === "CONTACTED" ||
                          lead?.enquirySwimlaneStatus === "DEMO" ||
                          lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                          lead?.enquirySwimlaneStatus === "CONVERTED") &&
                        lead?.status === "ACTIVE"
                          ? styles.stageGreenBackground
                          : (lead?.enquirySwimlaneStatus === "CONTACTED" ||
                              lead?.enquirySwimlaneStatus === "DEMO" ||
                              lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                              lead?.enquirySwimlaneStatus === "CONVERTED") &&
                            lead?.status === "LOST"
                          ? styles.stageGreyBackground
                          : ""
                      }`}
                    >
                      2
                    </div>
                  </div>
                  <div
                    className={`${styles.stageProgressBarItem} ${
                      lead?.enquirySwimlaneStatus === "DEMO"
                        ? styles.stageProgressBarItemAfter
                        : ""
                    }`}
                  >
                    <div
                      className={`${styles.stageProgressBarItemCount} ${
                        (lead?.enquirySwimlaneStatus === "DEMO" ||
                          lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                          lead?.enquirySwimlaneStatus === "CONVERTED") &&
                        lead?.status === "ACTIVE"
                          ? styles.stageGreenBackground
                          : (lead?.enquirySwimlaneStatus === "DEMO" ||
                              lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                              lead?.enquirySwimlaneStatus === "CONVERTED") &&
                            lead?.status === "LOST"
                          ? styles.stageGreyBackground
                          : ""
                      }`}
                    >
                      3
                    </div>
                  </div>
                  <div
                    className={`${styles.stageProgressBarItem} ${
                      lead?.enquirySwimlaneStatus === "NEGOTIATION"
                        ? styles.stageProgressBarItemAfter
                        : ""
                    }`}
                  >
                    <div
                      className={`${styles.stageProgressBarItemCount} ${
                        (lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                          lead?.enquirySwimlaneStatus === "CONVERTED") &&
                        lead?.status === "ACTIVE"
                          ? styles.stageGreenBackground
                          : (lead?.enquirySwimlaneStatus === "NEGOTIATION" ||
                              lead?.enquirySwimlaneStatus === "CONVERTED") &&
                            lead?.status === "ACTIVE"
                          ? styles.stageGreyBackground
                          : ""
                      }`}
                    >
                      4
                    </div>
                  </div>
                  <div
                    className={`${styles.stageProgressBarItem} ${
                      lead?.enquirySwimlaneStatus === "CONVERTED"
                        ? styles.stageProgressBarItemAfter
                        : ""
                    }`}
                  >
                    <div
                      className={`${styles.stageProgressBarItemCount} ${
                        lead?.enquirySwimlaneStatus === "CONVERTED" &&
                        lead?.status === "ACTIVE"
                          ? styles.stageGreenBackground
                          : lead?.enquirySwimlaneStatus === "CONVERTED" &&
                            lead?.status === "ACTIVE"
                          ? styles.stageGreyBackground
                          : ""
                      }`}
                    >
                      5
                    </div>
                  </div>
                </div>

                <FormControl
                  fullWidth
                  variant="outlined"
                  className="formControl"
                  size="small"
                  sx={{ marginBottom: "18px" }}
                >
                  <Select
                    labelId="stage"
                    id={""}
                    defaultValue={lead?.enquirySwimlaneStatus}
                    value={stage ? stage : lead?.enquirySwimlaneStatus}
                    label=""
                    onChange={onStageChange}
                    sx={{
                      color: "#667085",
                      background: "#F9F9F9",
                      borderRadius: "4PX",
                      overflow: "hidden",

                      "&& fieldset": {
                        border: "1px solid rgba(17, 200, 155, 0.10)",
                        borderRadius: "2PX",
                        minHeight: "44px",
                        color: "667085",
                      },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid rgba(17, 200, 155, 0.10)",
                          borderRadius: "2PX",
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid rgba(17, 200, 155, 0.10)",
                          borderRadius: "2PX",
                        },
                      "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid rgba(17, 200, 155, 0.10)",
                          borderRadius: "2PX",
                        },

                      "&.MuiOutlinedInput-root img": {
                        display: "none",
                      },
                    }}
                    MenuProps={{
                      style: { zIndex: 999999, fontSize: "14px" },
                      PaperProps: {
                        style: {
                          boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                        },
                      },
                    }}
                    disabled={
                      lead?.actionPending?.studentCreated ||
                      isFromWebform === true
                        ? true
                        : false || hasLeadAccess === false
                        ? true
                        : false
                    }
                  >
                    {leadStages?.map((stage: any) => (
                      <MenuItem
                        key={stage.id}
                        value={stage.stateName}
                        sx={{
                          fontSize: "13PX",
                          color:
                            lead?.enquirySwimlaneStatus === stage?.stateName
                              ? "#11C89B"
                              : "#18181B",
                          textTransform: "capitalize",
                          fontWeight: "400",
                          "&.MuiButtonBase-root.Mui-selected": {
                            backgroundColor: "#fff",
                          },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{stage.displayStateName}</div>
                          {lead?.enquirySwimlaneStatus === stage?.stateName && (
                            <div className={styles.stageMenuItem}>
                              <img src={greenTick} alt="" />
                            </div>
                          )}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {lead?.enquirySwimlaneStatus !== stage && (
                  <div className={styles.courseEditBtns}>
                    <Button
                      fullWidth
                      onClick={() =>
                        onStageCancelChange(lead?.enquirySwimlaneStatus)
                      }
                      sx={{
                        textTransform: "capitalize",
                        color: "#9A9EA6",
                        borderColor: "#D0D5DD",
                        border: "1px solid",
                        borderRadius: "4PX",
                        ":hover": {
                          color: "#9A9EA6",
                          borderColor: "#D0D5DD",
                          backgroundColor: "#9a9ea612",
                        },
                      }}
                      variant="outlined"
                    >
                      cancel
                    </Button>
                    {stage === "CONVERTED" && leadPaymentList?.length === 0 ? (
                      <>
                        <Button
                          fullWidth
                          onClick={() => handleTabClickQuery("payment")}
                          sx={{
                            border: "1px solid",
                            borderRadius: "4PX",
                            marginBottom: "0",
                            textTransform: "capitalize",
                          }}
                          color="primary"
                          variant="contained"
                        >
                          record payment
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          sx={{
                            border: "1px solid",
                            borderRadius: "4PX",
                            marginBottom: "0",
                            textTransform: "capitalize",
                          }}
                          onClick={() => handleLeadStageChange(lead?.id, stage)}
                          disabled={leadPopupOpen && leadStatusUpdateLoading}
                        >
                          {leadPopupOpen && leadStatusUpdateLoading ? (
                            <CircularProgress size={25} color={"inherit"} />
                          ) : (
                            "confirm"
                          )}
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
              {!branchAndBatchEdit ? (
                <>
                  <div className={styles.defaultCourseDetailsWrap}>
                    <div className={styles.defaultCourseDetailsItem}>
                      <div className={styles.defaultCourseDetailsLabel}>
                        Branch
                      </div>
                      <div className={styles.defaultCourseDetailsValue}>
                        {lead?.branchName}
                      </div>
                    </div>
                    {lead?.batchId ? (
                      <div className={styles.defaultCourseDetailsItem}>
                        <div className={styles.defaultCourseDetailsLabel}>
                          Batch
                        </div>
                        <div className={styles.defaultCourseBatchWrap}>
                          <div className={styles.defaultCourseBatchName}>
                            {lead?.batchName}
                          </div>
                          <div className={styles.defaultCourseBatchBottom}>
                            <div className={styles.defaultCourseBatchItem}>
                              <div
                                className={styles.defaultCourseBatchItemIcon}
                              >
                                <img src={dateIcon} alt="" />
                              </div>
                              <div
                                className={styles.defaultCourseBatchItemValue}
                              >
                                {lead?.batchStartDate?.toString()?.length === 13
                                  ? moment(lead.batchStartDate).format(
                                      "DD MMM YYYY"
                                    )
                                  : moment
                                      .unix(lead.batchStartDate)
                                      .format("DD MMM YYYY")}
                              </div>
                            </div>
                            <div className={styles.defaultCourseBatchItem}>
                              <div
                                className={styles.defaultCourseBatchItemIcon}
                              >
                                <img src={clockIcon} alt="" />
                              </div>
                              <div
                                className={styles.defaultCourseBatchItemValue}
                              >
                                {lead?.batchId &&
                                  lead?.batchStartTime !== 0 &&
                                  moment
                                    .unix(lead?.batchStartTime)
                                    .format("hh:mm A")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.defaultCourseDetailsItem}>
                        <div className={styles.defaultCourseDetailsLabel}>
                          Batch
                        </div>
                        <div className={styles.defaultCourseDetailsValue}>
                          No Batch assigned
                        </div>
                      </div>
                    )}
                  </div>
                  {isFromWebform === false && hasLeadAccess === true && (
                    <div>
                      <button
                        className={styles.editBranchBtn}
                        onClick={() =>
                          setBranchAndBatchEdit(!branchAndBatchEdit)
                        }
                        disabled={lead?.status === "LOST"}
                        style={{
                          color:
                            lead?.status === "LOST" ? "#667085" : "#667085",
                          cursor:
                            lead?.status === "LOST" ? "default" : "poiter",
                        }}
                      >
                        <img src={editIcon} alt="editIcon" />
                        Edit Branch and Batch
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <form
                    className={styles.courseRelatedEditForm}
                    onSubmit={
                      selectedLeadData?.batchId &&
                      (selectedLeadData?.negotiationData?.length > 0 ||
                        selectedLeadData?.paymentDetails?.EMIDetails !== null)
                        ? (event) => openBatchWarnPopup(event)
                        : handleLeadUpdateSubmit
                    }
                  >
                    {selectedLeadData?.amountPaid === 0 && (
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="formControl"
                        sx={{
                          mb: "18px",
                        }}
                      >
                        <div className={styles.defaultCourseDetailsLabel}>
                          Courses
                        </div>

                        {courseList && (
                          <Autocomplete
                            disablePortal={true}
                            id=""
                            options={filteredCourseList?.filter(
                              (item: any) => item.id !== leadDetails?.courseId
                            )}
                            getOptionLabel={(option: any) =>
                              option.courseName || ""
                            }
                            fullWidth
                            onChange={onCourseChange}
                            value={defaultCourse || null}
                            PaperComponent={(props) => (
                              <Paper
                                sx={{
                                  background: "#FFF",
                                  border: "1px solid #E4E4E7",
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  color: "142C44",
                                  fontWeight: "400",
                                  padding: "0 4px !important",
                                  margin: "0",
                                  boxShadow:
                                    "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  zIndex: 99999999,
                                  transform: "translate3d(2px, 2px, 2px)",
                                  width: "100%",
                                }}
                                {...props}
                              />
                            )}
                            componentsProps={{
                              popper: {
                                sx: {
                                  zIndex: 99999999,
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                  overflowX: "none",
                                  transform: "translateY(72px) !important",
                                },
                              },
                            }}
                            disableClearable={
                              leadDetails && leadDetails?.courseId === ""
                                ? true
                                : false
                            }
                            sx={{
                              border: "1px solid  #D4D4D8",
                              borderRadius: "6px",
                              textTransform: "capitalize",

                              "&& fieldset": {
                                border: "none",
                              },
                              "& .MuiAutocomplete-popper": {
                                transform: "none !important",
                                width: "100%",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                {...params}
                                size="small"
                                sx={{ textTransform: "capitalize" }}
                              />
                            )}
                          />
                        )}
                        {validStateCourse === false &&
                          leadDetails?.courseId === "" && (
                            <FormHelperText error={true}>
                              Field required
                            </FormHelperText>
                          )}
                      </FormControl>
                    )}

                    <FormControl
                      fullWidth
                      variant="standard"
                      className="formControl"
                      sx={{
                        mb: "18px",
                      }}
                    >
                      <div className={styles.defaultCourseDetailsLabel}>
                        Branches
                      </div>

                      {branchList && (
                        <Autocomplete
                          disablePortal={true}
                          id=""
                          options={filteredBranchList}
                          getOptionLabel={(option: any) =>
                            option.branchName || ""
                          }
                          fullWidth
                          onChange={onBranchChange}
                          value={defaultBranches || null}
                          PaperComponent={(props) => (
                            <Paper
                              sx={{
                                background: "#FFF",
                                border: "1px solid #E4E4E7",
                                fontFamily: "poppins",
                                fontSize: "14px",
                                textTransform: "capitalize",
                                color: "142C44",
                                fontWeight: "400",
                                padding: "0 4px !important",
                                margin: "0",
                                boxShadow:
                                  "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                                maxHeight: "300px",
                                overflowY: "auto",
                                zIndex: 99999999,
                                transform: "translate3d(2px, 2px, 2px)",
                                width: "100%",
                              }}
                              {...props}
                            />
                          )}
                          componentsProps={{
                            popper: {
                              sx: {
                                zIndex: 99999999,
                                maxHeight: "300px",
                                overflowY: "scroll",
                                overflowX: "none",
                                transform: "translateY(72px) !important",
                              },
                            },
                          }}
                          disableClearable={
                            leadDetails && leadDetails?.branchId === ""
                              ? true
                              : false
                          }
                          sx={{
                            border: "1px solid  #D4D4D8",
                            borderRadius: "6px",
                            textTransform: "capitalize",

                            "&& fieldset": {
                              border: "none",
                            },
                            "& .MuiAutocomplete-popper": {
                              transform: "none !important",
                              width: "100%",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              size="small"
                              sx={{ textTransform: "capitalize" }}
                            />
                          )}
                        />
                      )}
                      {validStateBranch === false &&
                        leadDetails?.branchId === "" && (
                          <FormHelperText error={true}>
                            Field required
                          </FormHelperText>
                        )}
                    </FormControl>

                    <div>
                      <FormControl
                        fullWidth
                        variant="standard"
                        className="formControl"
                        sx={{
                          mb: "18px",
                        }}
                      >
                        <div className={styles.defaultCourseDetailsLabel}>
                          Batches
                        </div>
                        {batchList && (
                          <Autocomplete
                            disablePortal={true}
                            PaperComponent={(props) => (
                              <Paper
                                sx={{
                                  background: "#FFF",
                                  border: "1px solid #E4E4E7",
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  textTransform: "capitalize",
                                  color: "142C44",
                                  fontWeight: "400",
                                  padding: "0 4px",
                                  margin: "0",
                                  boxShadow:
                                    "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                  zIndex: 99999999,
                                }}
                                {...props}
                              />
                            )}
                            componentsProps={{
                              popper: {
                                sx: {
                                  zIndex: 99999999,
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                  overflowX: "none",
                                  transform: "translateY(72px) !important",
                                },
                              },
                            }}
                            id=""
                            options={filteredBatchList}
                            getOptionLabel={(option: any) =>
                              option.batchName || ""
                            }
                            fullWidth
                            onChange={onBatchChange}
                            value={defaultBatch || null}
                            disableClearable={
                              leadDetails && leadDetails?.batchId === ""
                                ? true
                                : false
                            }
                            sx={{
                              border: "1px solid  #D4D4D8",
                              borderRadius: "6px",

                              "&& fieldset": {
                                border: "none",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                {...params}
                                size="small"
                              />
                            )}
                          />
                        )}
                        {validStateBatch === false && (
                          <FormHelperText error={true}>
                            Field required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className={styles.courseEditBtns}>
                      <Button
                        fullWidth
                        onClick={() => branchAndBatchEditCancel()}
                        sx={{
                          textTransform: "capitalize",
                          color: "#9A9EA6",
                          borderColor: "#D0D5DD",
                          border: "1px solid",
                          borderRadius: "4PX",
                          ":hover": {
                            color: "#9A9EA6",
                            borderColor: "#D0D5DD",
                            backgroundColor: "#9a9ea612",
                          },
                        }}
                        variant="outlined"
                      >
                        cancel
                      </Button>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        type="submit"
                        sx={{
                          border: "1px solid",
                          borderRadius: "4PX",
                          marginBottom: "0",
                          textTransform: "capitalize",
                        }}
                        disabled={
                          isLeadBranchEdit &&
                          branchAndBatchEdit &&
                          leadUpdateLoading &&
                          !openBatchWarning
                        }
                      >
                        {branchAndBatchEdit &&
                        isLeadBranchEdit &&
                        leadUpdateLoading &&
                        !openBatchWarning ? (
                          <CircularProgress size={25} color={"inherit"} />
                        ) : (
                          "save"
                        )}
                      </Button>
                    </div>
                  </form>
                </>
              )}
              <>
                {isFromWebform === false && hasLeadAccess === true && (
                  <>
                    {lead?.status === "ACTIVE" ? (
                      <button
                        className={styles.leadLostBtn}
                        onClick={() => handleLeadStatusSelect(lead?.id, "LOST")}
                      >
                        Move to Lost
                      </button>
                    ) : (
                      <button
                        className={styles.leadActiveBtn}
                        onClick={() =>
                          handleLeadStatusSelect(lead?.id, "ACTIVE")
                        }
                      >
                        Move to Active
                      </button>
                    )}
                  </>
                )}
              </>
            </AccordionDetails>
          </Accordion>
        ))}
      <BatchChangeWarning
        open={openBatchWarning}
        noButtonClick={closeBatchWarnPopup}
        yesButtonClick={yesButtonClick}
        onClose={closeBatchWarnPopup}
      />
    </div>
  );
}

export default LeadCourseDetails;
