import React from "react";
import { Container } from "@mui/system";
import { useAuthUser } from "react-auth-kit";
import {
  Button,
  Snackbar,
  SnackbarOrigin,
  IconButton,
  Alert,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import checkCircle from "../../../../assets/images/check-circle.svg";

export interface SnackStateProp extends SnackbarOrigin {
  snackOpen: boolean;
  message: string;
  snackSeverity?: "error" | "info" | "success" | "warning";
}

export function PrivacyPolicy(props: any) {
  const {
    tnc,
    isVersionMatching,
    privacyPolicyLoading,
    privacyPolicySuccess,
    privacyPolicyError,
    AcceptPrivacyPolicy,
    setOpenPrivacy,
  } = props;

  const auth = useAuthUser();
  const user = auth();
  const privacyPolicy =
    tnc && tnc?.filter((d: any) => d?.tncType === "PRIVACY_POLICY");

  const [snackState, setSnackState] = React.useState<SnackStateProp>({
    snackOpen: false,
    vertical: "top",
    horizontal: "right",
    message: "Something went wrong",
    snackSeverity: "info",
  });
  const { vertical, horizontal, snackOpen, message, snackSeverity } =
    snackState;

  const [privacyChecked, setPrivacyChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyChecked(event.target.checked);
  };

  const closeSnack = React.useCallback(() => {
    setSnackState({ ...snackState, snackOpen: false });
  }, [snackState]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="error"
        onClick={closeSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleAcceptPrivacyPolicy = () => {
    const data = {
      tncType: "PRIVACY_POLICY",
      version: privacyPolicy[0]?.version,
      userId: user?.institute[0]?.id,
    };
    AcceptPrivacyPolicy(data);
  };

  React.useEffect(() => {
    if (privacyPolicySuccess) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: "Privacy Policy Accepted",
        snackOpen: true,
        snackSeverity: "success",
      }));
      setTimeout(() => {
        setOpenPrivacy(false);
      }, 3000);
    }
  }, [privacyPolicySuccess]);

  React.useEffect(() => {
    if (privacyPolicyError) {
      setSnackState((snackState: SnackStateProp) => ({
        ...snackState,
        message: String(privacyPolicyError),
        snackOpen: true,
        snackSeverity: "error",
      }));
    }
  }, [privacyPolicyError]);

  return (
    <div style={{ overflow: "auto", maxHeight: "100vh" }}>
      {/* <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackOpen}
        onClose={closeSnack}
        key={vertical + horizontal}
        autoHideDuration={3000}
        sx={{
          zIndex: "99999999",
          borderRadius: "8PX",
          background:
            snackState?.snackSeverity === "success" ? "#DCFCE7" : "inherit",
          color:
            snackState?.snackSeverity === "success" ? "#14532D" : "inherit",
          fontSize: "14px",
        }}
      >
        <Alert
          severity={snackSeverity}
          action={action}
          icon={
            snackState?.snackSeverity === "success" ? (
              <img src={checkCircle} alt="check circle" />
            ) : null
          }
        >
          {message}
        </Alert>
      </Snackbar> */}
      <Container style={{ paddingBottom: "100px" }}>
        <h1>Privacy Policy</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: tnc?.[1]?.termsNConditionsString,
          }}
        />
      </Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 225px",
        }}
      >
        <>
          {false && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={privacyChecked} onChange={handleChange} />
                }
                label="By checking this box, I agree to the Privacy Policy"
              />
            </FormGroup>
          )}
          {isVersionMatching?.privacy === false && (
            <Button
              sx={{ textTransform: "capitalize" }}
              onClick={handleAcceptPrivacyPolicy}
              variant="contained"
              color="primary"
              disabled={privacyChecked === false}
            >
              Continue
            </Button>
          )}
        </>
      </div>
    </div>
  );
}
