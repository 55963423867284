import { Box, CircularProgress } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/Nurture Logo.png";
import leftPanalimg from "../../../../assets/images/leftimg.svg";
import ButtonLog from "../../../../components/Button/ButtonLog";
import { ErrorType } from "../../../../components/FormTextInput/types";
import {
  ACCOUNT,
  SIGNUP,
  FORGETPASSWORD,
  SIGNIN,
} from "../container/SigninString";
import { TermsAndCondition } from "../../../../components/TermsCondition/TermsCondition";
import { LoginProps as Iprops } from "../container/signinConatiner";
import styles from "./Signin.module.css";
import NewFormTextInput from "../../../../components/FormTextInput/NewFormTextInput";
import { useSignIn, useAuthUser } from "react-auth-kit";
import CompareTncPrivacyPolicy from "../../../../components/TncComparision/TncComparision";
import { useSelector } from "react-redux";
import { resetSignInSliceErrors } from "../service/signinSlice";
import { useDispatch } from "react-redux";
import { Mixpanel } from "../../../../tracking/Mixpanel";
import ToastNotification from "../../../../components/ToastNotification/ToastNotification";

export default function SignIn(props: Iprops) {
  const navigate = useNavigate();
  const signIn = useSignIn();
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const {
    userLogin,
    error,
    isLogin,
    loading,
    userDetails,
    activeInstitute,
    registrationStep,
    GetTnc,
    tnc,
    setActiveInsitute,
  } = props;

  const [credential, setCredential] = React.useState({
    email: "",
    password: "",
  });
  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [validStateEmail, setValidStateEmail] = React.useState<boolean>(true);
  const [validStatePassword, setValidStatePassword] =
    React.useState<boolean>(true);

  const termsAndConditionsSuccess = useSelector(
    (state: any) => state.dashboard.termsAndConditionsSuccess
  );
  const privacyPolicySuccess = useSelector(
    (state: any) => state.dashboard.privacyPolicySuccess
  );

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateEmail(true);
        setValidStatePassword(true);
      }
      setErrorType("client");

      const { id, value } = event.target;
      const lowercaseValue = id === "email" ? value.toLowerCase() : value;

      setCredential({ ...credential, [id]: lowercaseValue });
    },
    [credential, errorType]
  );

  const setValidateStatusEmail = React.useCallback((val: boolean) => {
    setValidStateEmail(val);
  }, []);
  const setValidateStatusPassword = React.useCallback((val: boolean) => {
    setValidStatePassword(val);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      !credential.email && setValidStateEmail(false);
      !credential.password && setValidStatePassword(false);
      if (
        validStateEmail &&
        validStatePassword &&
        credential.email &&
        credential.password
      ) {
        await userLogin({
          email: credential.email,
          password: credential.password,
        });
        Mixpanel.identify(credential.email);
        Mixpanel.track("Sign in", {
          "Signin Type": "Web",
        });
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (error && typeof error === "string" && error !== "false") {
      setShowErrMsz(false);
      setErrorType("server");
      setValidStateEmail(false);
      setValidStatePassword(false);
      dispatch(resetSignInSliceErrors());
    }
  }, [error]);

  const isVersionsMatching = CompareTncPrivacyPolicy(userDetails);

  React.useEffect(() => {
    if (isLogin && userDetails) {
      if (window.location.hostname === "localhost") {
        signIn?.({
          authState: userDetails,
          token: userDetails.token,
          expiresIn: 1439,
          tokenType: "Bearer",
        });
        if (authUser) {
          if (
            userDetails.institute &&
            userDetails.institute[0].instituteDetails?.appURL
          ) {
            if (userDetails.institute[0].instituteDetails.isProfileCompleted) {
              navigate(`/app/dashboard`, {
                state: {
                  tnc: tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            } else {
              navigate(`/app/home`, {
                state: {
                  tnc: tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            }
          } else {
            navigate(
              `/signup?pageState=5&subdomain=null&email=${userDetails.email}`
            );
          }
        }
      } else if (window.location.hostname === activeInstitute?.appURL) {
        signIn?.({
          authState: userDetails,
          token: userDetails.token,
          expiresIn: 1439,
          tokenType: "Bearer",
        });
        if (authUser) {
          if (
            userDetails.institute &&
            userDetails.institute[0].instituteDetails?.appURL
          ) {
            if (userDetails.institute[0].instituteDetails.isProfileCompleted) {
              navigate(`/app/dashboard`, {
                state: {
                  tnc: tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            } else {
              navigate(`/app/home`, {
                state: {
                  tnc: tnc,
                  isVersionMatching: isVersionsMatching,
                },
              });
            }
          }
        }
      } else if (
        userDetails.institute &&
        userDetails.institute[0].instituteDetails?.appURL
      ) {
        window.location.href = `https://${userDetails.institute[0].instituteDetails?.appURL}?token=${userDetails.token}`;
      } else {
        navigate(
          `/signup?pageState=5&subdomain=null&email=${userDetails.email}`
        );
      }
    }
  }, [
    isLogin,
    userDetails,
    signIn,
    termsAndConditionsSuccess,
    privacyPolicySuccess,
  ]);

  React.useEffect(() => {
    GetTnc();
  }, [GetTnc]);

  return (
    <div className={styles.page}>
      <ToastNotification />
      <Box
        className={styles.Header}
        sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" } }}
      >
        <img src={logo} className={styles.appLogo} alt="Logo" />
      </Box>

      <Box
        className={styles.leftPanal}
        style={{ border: "1px solid #142c44" }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            src={leftPanalimg}
            alt="bcksbckab"
            style={{ height: 384, width: 447 }}
          />

          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Poppins",
              fontWeight: "700",
              fontSize: { lg: "32px", md: "30px", sm: "26px", xs: "18px" },
              color: "#FFFFFF",
              textAlign: "center",
              mt: 2,
            }}
          >
            You’re in Trusted hands
          </Box>

          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: { lg: "14px", md: "12px", sm: "10px", xs: "10px" },
              color: "#FFFFFF",
              textAlign: "center",
              mt: 1,
              marginBottom: "30px",
              px: { sm: "60px", xl: "160px" },
              ml: "5px",
              mr: "5px",
            }}
          >
            Hundreds of Institutions rely on Nurture to help run their
            institutes.
            <br /> The right process always helps you scale.
          </Box>
        </Box>
      </Box>

      <div className={styles.rightPanal}>
        <form className={styles.form} onSubmit={handleSubmit} noValidate>
          <div className={styles.formHeading}>{SIGNIN}</div>

          <Box>
            <NewFormTextInput
              validate={true}
              label="Email address"
              setValidStatus={setValidateStatusEmail}
              type={"email"}
              id="email"
              value={credential.email}
              onChange={handleChange}
              error={!validStateEmail}
              showErrorMessage={showErrMsz}
              errorType={errorType}
              autoComplete="true"
              require={true}
            />
          </Box>

          <Box sx={{ marginTop: "15px" }}>
            <NewFormTextInput
              validate={true}
              label="Password"
              setValidStatus={setValidateStatusPassword}
              type={"password"}
              id="password"
              value={credential.password}
              onChange={handleChange}
              error={!validStatePassword}
              showErrorMessage={showErrMsz}
              errorType={errorType}
              autoComplete="true"
              require={true}
            />
          </Box>

          <div className={styles.linkWrapper}>
            <Link to="/forget-password" className={styles.link}>
              {FORGETPASSWORD}
            </Link>
          </div>

          <ButtonLog
            text={
              loading ? (
                <CircularProgress size={25} color={"inherit"} />
              ) : (
                "Login"
              )
            }
            variant="contained"
            type="submit"
          />
          <p className={styles.signUpText}>
            {ACCOUNT}
            <Link to="/signup" className={styles.signUpLink}>
              {SIGNUP}
            </Link>
          </p>

          <TermsAndCondition tnc={tnc} isVersionMatching={isVersionsMatching} />
        </form>
      </div>
    </div>
  );
}
