import { Checkbox, Tooltip } from "@mui/material";
import styles from "./TaskStatusPopup.module.css";
import React, { useRef } from "react";
import checkIcon from "../../assets/images/check-icon.svg";
import checkedIcon from "../../assets/images/checked-icon.svg";
import checkComplete from "../../assets/images/check-complete.svg";
import { useAuthUser } from "react-auth-kit";
// import checkCircle from "../../assets/images/check-circle.svg";
import checkCircleOutline from "../../assets/images/round-check.svg";
import checkCircle from "../../assets/images/lightCheckIcon.svg";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../RolePermissionAccess/RolePermissionAccess";

function TaskStatusPopup(props: any) {
  const { currentStatus, data, UpdateTaskStatus, updateTaskStatusSuccess } =
    props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const auth = useAuthUser();
  const authUser = auth();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const [progressArray, setProgressArray] = React.useState<any>([
    {
      name: "to do",
      icon: false,
      value: "TO_DO",
    },
    {
      name: "in progress",
      icon: false,
      value: "IN_PROGRESS",
    },
    {
      name: "blocked",
      icon: false,
      value: "BLOCKED",
    },
    {
      name: "completed",
      icon: false,
      value: "COMPLETED",
    },
  ]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    if (data) {
      const updatedStatusArray = progressArray.map((status: any) => {
        if (status.value === data.status) {
          return { ...status, icon: true };
        } else {
          return { ...status, icon: false };
        }
      });
      setProgressArray(updatedStatusArray);
    }
  }, [data, updateTaskStatusSuccess]);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleStatusUpdate = (val: any) => {
    const body = {
      taskId: data?.id,
      taskStatus: val?.value,
      updatedById: authUser?.institute[0]?.id,
      updatedByName: authUser?.firstName + " " + authUser?.lastName,
      instituteId: authUser?.institute[0]?.instituteId,
      taskType: data?.taskType,
    };
    setIsOpen(false);
    UpdateTaskStatus(body);
  };
  return (
    <div className={styles.taskStatusPopupWrap} ref={wrapperRef}>
      {/* <Tooltip
        arrow
        title={hasLeadAccess === false ? "Oops! No permission." : ""}
        PopperProps={{ style: { zIndex: 9999999 } }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#101828",
              color: "#fff",
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 12px",
              "& .MuiTooltip-arrow": {
                color: "#101828",
                zIndex: "99999999",
              },
            },
          },
        }}
      >
        <span
          style={{
            width: "100%",
            display: "block",
            textAlign: "center",
          }}
        >
          <button
            className={styles.taskStatusBtn}
            onClick={toggleDropdown}
            disabled={hasLeadAccess === false ? true : false}
            style={{
              background:
                data?.status === "TO_DO"
                  ? "rgba(102, 112, 133, 0.10)"
                  : data?.status === "IN_PROGRESS"
                  ? "rgba(38, 100, 220, 0.07)"
                  : data?.status === "BLOCKED"
                  ? "rgba(236, 57, 57, 0.07)"
                  : data?.status === "COMPLETED"
                  ? "rgba(35, 166, 123, 0.06)"
                  : "rgba(35, 166, 123, 0.06)",
            }}
          >
            <img
              src={
                data?.status !== "COMPLETED" ? checkCircleOutline : checkCircle
              }
              alt="check"
              style={{ marginRight: "6px" }}
            />
            <div
              className={styles.taskStatusBtnLabel}
              style={{
                color:
                  data?.status === "TO_DO"
                    ? "#667085"
                    : data?.status === "IN_PROGRESS"
                    ? "#2664DC"
                    : data?.status === "BLOCKED"
                    ? "#EC3939"
                    : data?.status === "COMPLETED"
                    ? "#23A67B"
                    : "#2664DC",
              }}
            >
              {data?.status?.toLowerCase()?.replace(/_/g, " ")}
            </div>
          </button>
        </span>
      </Tooltip> */}
      <button
        className={styles.taskStatusBtn}
        onClick={toggleDropdown}
        style={{
          background:
            data?.status === "TO_DO"
              ? "rgba(102, 112, 133, 0.10)"
              : data?.status === "IN_PROGRESS"
              ? "rgba(38, 100, 220, 0.07)"
              : data?.status === "BLOCKED"
              ? "rgba(236, 57, 57, 0.07)"
              : data?.status === "COMPLETED"
              ? "rgba(35, 166, 123, 0.06)"
              : "rgba(35, 166, 123, 0.06)",
        }}
      >
        <img
          src={data?.status !== "COMPLETED" ? checkCircleOutline : checkCircle}
          alt="check"
          style={{ marginRight: "6px" }}
        />
        <div
          className={styles.taskStatusBtnLabel}
          style={{
            color:
              data?.status === "TO_DO"
                ? "#667085"
                : data?.status === "IN_PROGRESS"
                ? "#2664DC"
                : data?.status === "BLOCKED"
                ? "#EC3939"
                : data?.status === "COMPLETED"
                ? "#23A67B"
                : "#2664DC",
          }}
        >
          {data?.status?.toLowerCase()?.replace(/_/g, " ")}
        </div>
      </button>
      {isOpen && (
        <ul className={styles.taskStatusPopupWrapper}>
          {progressArray
            ?.filter((item: any) => item.value !== data?.status)
            ?.map((d: any) => {
              return (
                <li
                  className={styles.taskStatusPopupItem}
                  onClick={() => handleStatusUpdate(d)}
                  style={{
                    color:
                      d?.value === "TO_DO"
                        ? "#667085"
                        : d?.value === "IN_PROGRESS"
                        ? "#2664DC"
                        : d?.value === "BLOCKED"
                        ? "#EC3939"
                        : d?.value === "COMPLETED"
                        ? "#23A67B"
                        : "#2664DC",
                  }}
                >
                  <div>{d?.name}</div>
                  {d?.icon && (
                    <div className={styles.taskStatusPopupItemTick}>
                      <img src={checkComplete} alt="checkComplete" />
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

export default TaskStatusPopup;
