import React from "react";
import DiscountInput from "../../../../../../components/FormTextInput/DiscountInput";
import IconDropdown from "../../../../../../components/Dropdowns/IconDropdown";
import editIcon from "../../../../../../assets/images/edit-icon.svg";
import { CircularProgress, TextField } from "@mui/material";
import uncheckIcon from "../../../../../../assets/images/memb-fee-uncheck.svg";
import checkIcon from "../../../../../../assets/images/memb-fee-check.svg";
import closeIcon from "../../../../../../assets/images/close-white-svg.svg";
import addIcon from "../../../../../../assets/images/add-white-icon.svg";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import styles from "./LeadMembership.module.css";
import whiteEditIcon from "../../../../../../assets/images/white-edit-icon.svg";
import {
  updateLeadDiscount,
  updateLeadEmiDetails,
} from "../../../service/nurtureSlice";
import { useSelector } from "react-redux";

function EditLeadMembership(props: any) {
  const {
		leadData,
		setIsPayEditOpen,
		leadEmiDetailsLoading,
		selectedCurrency,
	} = props;
	const auth = useAuthUser();
	const authUser = auth();
	const dispatch = useDispatch();
	const { leadDiscountSuccess, leadDiscountLoading } = useSelector(
		(state: any) => state.nurture
	);

	const payStructure = leadData?.paymentDetails?.paymentStructure;

	const batchStartDateMilliSeconds =
		leadData?.batchStartDate?.toString()?.length !== 13
			? leadData?.batchStartDate * 1000
			: leadData?.batchStartDate;
	const batchEndDateMilliSeconds =
		leadData?.bacthEndDate?.toString()?.length !== 13
			? leadData?.bacthEndDate * 1000
			: leadData?.bacthEndDate;
	const paidEmis = leadData?.paymentDetails?.EMIDetails?.EMIS?.filter(
		(item: any) =>
			item?.emiPaymentStatus === 'FULLY_PAID' ||
			item?.emiPaymentStatus === 'PARTIALLY_PAID'
	);
	const lastPaidEmi = paidEmis?.[paidEmis.length - 1];

	const [selectedType, setSelectedType] = React.useState<any>('');
	const [typePriceEdit, setTypePriceEdit] = React.useState<any>('');
	const [discountOpen, setDiscountOpen] = React.useState<string>('');
	const [membershipFee, setMembershipFee] = React.useState<string>('');
	const [membershipFeeError, setMembershipFeeError] =
		React.useState<boolean>(false);
	const [finalFee, setFinalFee] = React.useState<string>('');
	const [startDate, setStartDate] = React.useState<any>(null);
	const [discountMetric, setDiscountMetric] = React.useState<string>('');
	const [discount, setDiscount] = React.useState<string>('');
	const [discountError, setDiscountError] = React.useState<boolean>(false);

	const handleSelect = (type: any) => {
		if (selectedType !== type) {
			setSelectedType(type);
			setFinalFee(type?.finalEmiAmount);
			setDiscountOpen('');
			setTypePriceEdit('');
			setDiscountMetric('');
			setDiscount('');
		}
	};
	const handleFeeEdit = (e: any, type: any) => {
		// e.stopPropagation();

		if (type) {
			setTypePriceEdit(type?.frequency);
			setMembershipFee(type?.emiAmount);
			setFinalFee(type?.finalEmiAmount);
		} else {
			setTypePriceEdit('');
			setMembershipFee('');
			setFinalFee('');
		}
		setDiscountOpen('');
	};

	const handleAddDiscount = (e: any, type: any) => {
		e.stopPropagation();
		setTypePriceEdit('');
		if (type) {
			setFinalFee(type?.finalEmiAmount);
			setDiscountOpen(type?.frequency);
			if (type?.discount > 0) {
				setDiscountMetric(type?.discountMetric);
				setDiscount(type?.discount);
			}
		} else {
			setFinalFee('');
			setDiscountOpen('');
			setDiscountMetric('');
			setDiscount('');
		}
	};

	const handleDiscountMetric = (value: string) => {
		setDiscountMetric(value);
		setDiscount('');
		const discountType = payStructure?.find(
			(item: any) => item?.frequency === discountOpen
		);
		setFinalFee(discountType?.finalEmiAmount);
	};

	const handleDiscount = (value: string) => {
		setDiscount(value);

		if (value) {
			if (discountMetric === 'AMOUNT') {
				setFinalFee((selectedType?.emiAmount - parseFloat(value)).toString());
			} else if (discountMetric === 'PERCENTAGE') {
				setFinalFee(
					(selectedType?.emiAmount * (1 - parseFloat(value) / 100)).toString()
				);
			}
		} else {
			setFinalFee(selectedType?.emiAmount);
		}
		if (value === '' && selectedType?.discount === 0) {
			setDiscountError(true);
		} else {
			setDiscountError(false);
		}
	};

	const handleMembershipPrice = (value: string) => {
		setMembershipFee(value);
		if (value === '') {
			setMembershipFeeError(true);
		} else {
			setMembershipFeeError(false);
		}
		if (selectedType?.discountMetric === 'AMOUNT') {
			setFinalFee((parseFloat(value) - selectedType?.discount).toString());
		} else if (selectedType?.discountMetric === 'PERCENTAGE') {
			setFinalFee(
				(parseFloat(value) * (1 - selectedType?.discount / 100)).toString()
			);
		}
	};

	const handleDateChange = React.useCallback((date: any) => {
		const dateUnix = date.unix() * 1000;
		setStartDate(dateUnix);
	}, []);

	const handleSubmitDiscount = async (event: any) => {
		event.preventDefault();

		try {
			const updatedPaymentStructure = payStructure?.map((item: any) => {
				if (item.frequency === selectedType?.frequency) {
					return {
						...item,
						discount: parseFloat(discount) > 0 ? parseFloat(discount) : 0,
						discountMetric: parseFloat(discount) > 0 ? discountMetric : '',
						finalEmiAmount: parseFloat(finalFee),
					};
				}
				return item;
			});

			const body = {
				id: leadData?.id,
				paymentType: 'MEMBERSHIP',
				paymentStructure: updatedPaymentStructure,
				updatedById: authUser?.institute[0]?.id,
				updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
				discountAdded: true,
			};
			if (!discountError) {
				dispatch<any>(updateLeadDiscount(body));
			}
		} catch (error) {}
	};

	const handleSubmitPrice = async (event: any) => {
		event.preventDefault();

		try {
			const updatedPaymentStructure = payStructure?.map((item: any) => {
				if (item.frequency === selectedType?.frequency) {
					return {
						...item,
						emiAmount: parseFloat(membershipFee),
						finalEmiAmount: parseFloat(finalFee),
					};
				}
				return item;
			});

			const body = {
				id: leadData?.id,
				paymentType: 'MEMBERSHIP',
				paymentStructure: updatedPaymentStructure,
				updatedById: authUser?.institute[0]?.id,
				updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
				discountAdded: false,
			};
			if (body) {
				dispatch<any>(updateLeadDiscount(body));
			}
		} catch (error) {}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		try {
			const updatedPaymentStructure = payStructure?.map((item: any) => {
				return {
					...item,
					selected: item.frequency === selectedType?.frequency,
				};
			});

			const body = {
				id: leadData?.id,
				paymentType: 'MEMBERSHIP',
				paymentStructure: updatedPaymentStructure,
				updatedById: authUser?.institute[0]?.id,
				updatedByName: authUser?.firstName + ' ' + authUser?.lastName,
				discountAdded: false,
				startDate:
					startDate !== null ? new Date(startDate).setHours(0, 0, 0, 0) : 0,
				endDate: batchEndDateMilliSeconds,
			};
			if (body) {
				dispatch<any>(updateLeadEmiDetails(body));
			}
		} catch (error) {}
	};

	React.useEffect(() => {
		if (
			leadData?.paymentDetails?.paymentStructure?.some(
				(item: any) => item.hasOwnProperty('selected') && item.selected === true
			)
		) {
			const selectedType = leadData?.paymentDetails?.paymentStructure?.find(
				(item: any) => item.selected === true
			);
			setSelectedType(selectedType);
			if (paidEmis?.length > 0) {
				setStartDate(lastPaidEmi?.expiryDate + 86400000);
			} else {
				setStartDate(leadData?.paymentDetails?.startDate);
			}
		} else {
			setSelectedType(payStructure?.[0]);
		}
	}, [leadData]);

	React.useEffect(() => {
		if (leadDiscountSuccess !== true && leadDiscountSuccess !== false) {
			setTypePriceEdit('');
			setMembershipFee('');
			setFinalFee('');
			setDiscountOpen('');
			setDiscountMetric('');
			setDiscount('');
		}
	}, [leadDiscountSuccess]);

	return (
		<div>
			<ul className={styles.membershipTypes}>
				{leadData?.paymentDetails?.paymentStructure?.map(
					(item: any, index: number) => (
						<li
							key={index}
							className={`${styles.membershipTypeWrap} ${
								selectedType?.frequency === item?.frequency
									? styles.typeSelected
									: ''
							}`}
							style={{
								marginLeft:
									selectedType?.frequency === item?.frequency && index !== 0
										? '10px'
										: '0PX',
								marginRight:
									selectedType?.frequency === item?.frequency && index !== 3
										? '10px'
										: '0PX',
							}}
							onClick={() => handleSelect(item)}>
							<div className={styles.membershipTypeTop}>
								<div className={styles.membershipTypeTopHead}>
									<div className={styles.membershipTypeVal}>
										{item?.frequency?.toLowerCase()?.replace(/_/g, ' ')}
									</div>
									<div className={styles.membershipTypeTopHeadRight}>
										<img
											src={
												selectedType?.frequency === item?.frequency
													? checkIcon
													: uncheckIcon
											}
											alt='uncheckIcon'
										/>
										{/* <IconDropdown
                      options={[
                        {
                          label: "Edit",
                          onClick: (e: any) => {
                            handleFeeEdit(e, item);
                          },
                          icon: editIcon,
                          style: { color: "#667085" },
                        },
                      ]}
                      disabled={selectedType?.frequency !== item?.frequency}
                    /> */}
									</div>
								</div>
								<div className={styles.membershipTypeTopLabel}>
									Membership Fees
								</div>
								{typePriceEdit !== item?.frequency ? (
									<div
										className={styles.membershipTypeTopValue}
										style={{ fontFamily: 'Roboto', marginBottom: '10px' }}>
										{/* 		{item?.emiAmount?.toLocaleString('en-IN', {
											style: 'currency',
											currency: 'INR',
										})} */}
										{selectedCurrency + ' ' + item?.emiAmount}
									</div>
								) : (
									<div style={{ maxWidth: '168PX', margin: '4px 0' }}>
										<TextField
											id='membershipFee'
											variant='outlined'
											type='number'
											fullWidth
											inputProps={{
												sx: {
													width: '200px',
													background: '#fff',
													borderRadius: '6px',
													overFlowY: 'hidden',
													'&::placeholder': {
														fontWeight: '400',
														fontSize: '14px',
													},

													'&.MuiOutlinedInput-input': {
														padding: '8px',
													},
												},
											}}
											value={membershipFee}
											onChange={(event) =>
												handleMembershipPrice(event.target.value)
											}
											error={membershipFeeError}
											helperText={
												membershipFeeError ? 'Please fill valid amount' : ''
											}
										/>
										<div className={styles.membershipTypeUpdateBtnWrap}>
											<button
												className={styles.membershipSaveBtn}
												onClick={handleSubmitPrice}
												disabled={
													leadDiscountLoading || parseFloat(finalFee) <= 0
												}>
												{leadDiscountLoading ? (
													<CircularProgress size={16} color={'inherit'} />
												) : (
													'save'
												)}
											</button>
											<button
												className={styles.membershipCancelBtn}
												onClick={(e: any) => handleFeeEdit(e, '')}>
												<img src={closeIcon} alt='closeIcon' />
											</button>
										</div>
									</div>
								)}

								<div
									className={styles.membershipTypeTopLabel}
									style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
									Discount{' '}
									{item?.discount > 0 && discountOpen === '' && (
										<button
											className={styles.membershipAddDiscountBtn}
											onClick={(e) => handleAddDiscount(e, item)}
											style={{
												pointerEvents:
													selectedType?.frequency !== item?.frequency
														? 'none'
														: 'auto',
											}}>
											<img
												src={whiteEditIcon}
												alt='icon'
												style={{ marginRight: '5PX' }}
											/>
										</button>
									)}
								</div>
								<div className={styles.membershipTypeDiscountWrap}>
									{discountOpen === item?.frequency ? (
										<>
											<div style={{ maxWidth: '168PX', margin: '4px 0' }}>
												<DiscountInput
													discountMetric={discountMetric}
													discount={discount}
													handleDiscountMetric={handleDiscountMetric}
													handleDiscount={handleDiscount}
                          totalPrice={item?.emiAmount}
                          selectedCurrency  = {selectedCurrency}
												/>
												<div className={styles.membershipTypeUpdateBtnWrap}>
													<button
														className={styles.membershipSaveBtn}
														disabled={
															(discount === '' && item?.discount === '') ||
															leadDiscountLoading ||
															parseFloat(finalFee) <= 0
														}
														onClick={handleSubmitDiscount}>
														{leadDiscountLoading ? (
															<CircularProgress size={16} color={'inherit'} />
														) : (
															'save'
														)}
													</button>
													<button
														className={styles.membershipCancelBtn}
														onClick={(e) => handleAddDiscount(e, '')}>
														<img src={closeIcon} alt='closeIcon' />
													</button>
												</div>
											</div>
										</>
									) : (
										<>
											{item?.discount > 0 ? (
												<div
													className={styles.membershipTypeTopValue}
													style={{ fontFamily: 'Roboto' }}>
													{/*   {(
                            item?.emiAmount - item?.finalEmiAmount
                          )?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })} */}
													{selectedCurrency}{' '}
													{item?.emiAmount - item?.finalEmiAmount}
												</div>
											) : (
												<button
													className={styles.membershipAddDiscountBtn}
													onClick={(e) => handleAddDiscount(e, item)}
													// disabled={selectedType?.frequency !== item?.frequency}
													style={{
														pointerEvents:
															selectedType?.frequency !== item?.frequency
																? 'none'
																: 'auto',
													}}>
													<img
														src={addIcon}
														alt='addIcon'
														style={{ marginRight: '5PX' }}
													/>
													add discount
												</button>
											)}
										</>
									)}
								</div>
							</div>
							<div className={styles.membershipTypeBottom}>
								<div className={styles.membershipTypeBottomLabel}>
									Final Membership Fees
								</div>

								<div
									className={styles.membershipTypeBottomValue}
									style={{ fontFamily: 'Roboto' }}>
									{typePriceEdit === item?.frequency ||
									discountOpen === item?.frequency ? (
										<>
											{/* 	{parseFloat(finalFee).toLocaleString('en-IN', {
												style: 'currency',
												currency: 'INR',
											})} */}
											{selectedCurrency} {parseFloat(finalFee)}
										</>
									) : (
										<>
											{/* 		{item?.finalEmiAmount?.toLocaleString('en-IN', {
												style: 'currency',
												currency: 'INR',
											})} */}
											{selectedCurrency} {item?.finalEmiAmount}
										</>
									)}
								</div>
							</div>
						</li>
					)
				)}
			</ul>
			<div className={styles.membershipDateBox} style={{ marginTop: '25px' }}>
				<div className={styles.membershipDateTitle}>Schedule Payments</div>
				<div className={styles.membershipDateDesc}>
					You will need to select the start date and click on the save button
					for schedule payments.
				</div>
				<div style={{ minWidth: '180px', maxWidth: '180px' }}>
					<div className={styles.membershipDateLabel}>Select Start Date</div>
					<div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DesktopDatePicker
								sx={{
									zIndex: '99999999 !important',
									width: '270px',
									fontSize: '14px',
									'& .MuiOutlinedInput-input': {
										fontSize: '14px',
										color: '#142C44',
										padding: '9px',
									},
								}}
								format='DD/MM/YYYY'
								onChange={(date: any) => {
									setStartDate(date);
									handleDateChange(date);
								}}
								value={startDate ? dayjs(startDate) : null}
								shouldDisableDate={(date) => {
									const adjustedLastPaidEmiDate =
										paidEmis?.length > 0
											? dayjs(lastPaidEmi?.expiryDate).add(1, 'day')
											: batchStartDateMilliSeconds;

									const isBeforeStart = dayjs(date).isBefore(
										adjustedLastPaidEmiDate,
										'day'
									);

									const oneDayABeforeBatchEnd = dayjs(
										batchEndDateMilliSeconds
									).subtract(1, 'day');
									const isAfterEnd = dayjs(date).isAfter(oneDayABeforeBatchEnd);
									return isBeforeStart || isAfterEnd;
								}}
							/>
						</LocalizationProvider>
					</div>
				</div>
			</div>
			<div className={styles.membershipBtnWrap}>
				<CancelBtn onClick={() => setIsPayEditOpen(false)} />
				<SaveBtn
					onClick={handleSubmit}
					disabled={startDate === null || leadEmiDetailsLoading}
					loader={leadEmiDetailsLoading}
				/>
			</div>
		</div>
	);
}

export default EditLeadMembership;
