import React from "react";
import styles from "../Manegecourses.module.css";
import RichTextEditor from "../../../../../../components/RichTextEditor/RichTextEditor";
import { useDispatch } from "react-redux";
import { updateCourseDescription } from "../../service/courseSlice";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import { checkObjectsEqual } from "../../../../../../components/CommonFunctions/CommonFunctions";
import CancelPopup from "../../../../../../components/CommonPopups/CancelPopup";
import EditPopup from "../../../ManageBatch/components/EditPopup/EditPopup";
import CourseChapterManager from "../Manager/CourseChapterManager";

function CourseDescription(props: any) {
  const { setIsDescEditOpen } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { courseById, loading_updateCourse } = useSelector(
    (state: any) => state.courses
  );

  const [description, setDescription] = React.useState<any>("");
  const [syllabus, setSyllabus] = React.useState<any>([]);
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = React.useState<boolean>(false);
  const [isCheckedNotification, setIsCheckedNotification] =
    React.useState<boolean>(false);

  let notifyText = "";

  const handleCourseDescriptionChange = React.useCallback(
    (event: string, editorRef: any) => {
      const updatedDescription = event;
      setDescription(updatedDescription);
      // setCourseDetails({
      //   ...courseDetails,
      //   courseDescription: updatedDescription,
      // });
    },
    []
  );

  const handleUpdateSyllabus = React.useCallback((courseSyllabus: []) => {
    setSyllabus(courseSyllabus);
    // setCourseDetails({
    //   ...courseDetails,
    //   courseSyllabus: courseSyllabus,
    // });
  }, []);

  const handleAddChapter = React.useCallback(() => {
    const newelement = {
      chapterName: "Chapter " + (syllabus?.length + 1),
      chapterDetails: "",
    };
    setSyllabus([...syllabus, newelement]);
    // setCourseDetails({
    //   ...courseDetails,
    //   courseSyllabus: [...courseDetails.courseSyllabus, newelement],
    // });
  }, [syllabus]);

  const isSyllabusSame = checkObjectsEqual(
    syllabus,
    courseById?.courseSyllabus
  );
  const isDescriptionSame = checkObjectsEqual(
    description,
    courseById?.courseDescription
  );

  const cancelButtonClick = () => {
    if (isSyllabusSame && isDescriptionSame) {
      setIsDescEditOpen(false);
    } else {
      setCancelModal(true);
    }
  };
  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const cancelYesBtn = () => {
    setCancelModal(false);
    setIsDescEditOpen(false);
  };

  const handleEditPopupCancelButton = (event: any) => {
    setShowEditPopup(false);
  };

  const handleNotificationCheckboxChange = (event: any) => {
    setIsCheckedNotification(event.target.checked);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        courseId: courseById?.id,
        courseDescription: description,
        courseSyllabus: syllabus,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };

      (description !== "" || syllabus?.length > 0) &&
        dispatch<any>(updateCourseDescription(body));

      let changes: string[] = [];

      if (!isSyllabusSame) {
        changes.push("Syllabus");
      } else {
        changes = changes.filter((change) => change !== "Syllabus");
      }

      if (changes.length === 1) {
        notifyText = `There is a change in ${changes[0]}.`;
      } else {
        notifyText = "";
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (courseById) {
      setDescription(courseById?.courseDescription);
      setSyllabus(courseById?.courseSyllabus);
    }
  }, [courseById]);

  return (
    <div className={styles.sectionWrapper}>
      <div className={styles.secChildWrap}>
        <div className={styles.courseEditSectionWrap}>
          <div className={styles.courseEditSectionTitle}>
            Course Description
          </div>
          <RichTextEditor
            defaultText={description}
            handleTextChange={(event: any, editorRef: any) =>
              handleCourseDescriptionChange(event, editorRef)
            }
            inputProps={{
              maxLength: 50,
            }}
          />
        </div>

        <div className={styles.courseEditSectionWrap}>
          <div className={styles.courseEditSectionTitle}>
            {courseById?.courseSyllabus?.length === 0 ? "Add " : "Edit "}
            syllabus
          </div>
          <CourseChapterManager
            courseSyllabus={syllabus}
            handleAddChapter={handleAddChapter}
            handleUpdateSyllabus={handleUpdateSyllabus}
          />
        </div>

        <div className={styles.editSectionBtnWrap}>
          <CancelBtn onClick={() => cancelButtonClick()} />
          <SaveBtn
            onClick={handleSubmit}
            disabled={
              loading_updateCourse || (isSyllabusSame && isDescriptionSame)
            }
            loader={loading_updateCourse}
          />
        </div>
        <CancelPopup
          open={cancelModal}
          onClose={closeCancelModal}
          noBtnClick={closeCancelModal}
          yesBtnClick={cancelYesBtn}
        />
        {showEditPopup && (
          <EditPopup
            type={"course"}
            onClose={handleEditPopupCancelButton}
            onConfirm={handleSubmit}
            showEditPopup={showEditPopup}
            notifyText={notifyText}
            handleNotificationCheckboxChange={handleNotificationCheckboxChange}
            isCheckedNotification={isCheckedNotification}
          />
        )}
      </div>
    </div>
  );
}

export default CourseDescription;
