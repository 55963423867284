import { RootState, TypedDispatch } from "../../../../../redux/store";
import { getInstituteById } from "../../InstituteProfile/service/instituteSlice";
import BranchListing from "../BranchListing/BranchListing";
import { getBranchList } from "../service/Branchslice";
import { branchInitialStateDTO } from "../service/types";
import { connect } from "react-redux";

interface StateProps extends branchInitialStateDTO {}

interface DispatchProps {
  GetBranchList(id: string): void;
  GetInstituteById(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    branchList: state.branch.branchList,
    branchById: state.branch.branchById,
    success: state.branch.success,
    error: state.branch.error,
    loading: state.branch.loading,
    branchListLoading: state.branch.branchListLoading,
    branchByIdLoading:state.branch.branchByIdLoading

  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetInstituteById: (id: string) => {
      dispatch(getInstituteById(id));
    },
  };
};

export interface BranchListProps extends StateProps, DispatchProps {}

export const BranchListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BranchListing);
