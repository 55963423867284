import MonthAndYear from "../../../../../../components/CalenderComponents/MonthAndYear";

function CalenderHeader({ date }: any) {
  return (
    <div style={{ marginTop: "20px" }}>
      <MonthAndYear
        month={date?.month}
        year={date?.year}
        handleNextMonth={date?.getNextMonth}
        handlePreviousMonth={date?.getPreviousMonth}
      />
    </div>
  );
}

export default CalenderHeader;
