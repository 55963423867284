import React, { useRef, useState } from "react";
import styles from "../Managebatches.module.css";
import moment from "moment";
import {
  convertToAdjustedTimestamp,
  timeZoneConverter,
} from "../../../../../../components/Formatter/Formatter";
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Skeleton,
  TextField,
} from "@mui/material";
import CalenderPage from "../../BatchEditor/Calender/CalenderPage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Fade from "@mui/material/Fade";
import btnDownArrow from "../../../../../../assets/images/btn-down-arrow.svg";
import btnListIcon from "../../../../../../assets/images/btn-list-icon.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { getBatchById, updateBatch } from "../../service/Batchslice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkObjectsEqual } from "../../../../../../components/CommonFunctions/CommonFunctions";
import CancelPopup from "../../../../../../components/CommonPopups/CancelPopup";
import { useAuthUser } from "react-auth-kit";
import EditPopup from "../EditPopup/EditPopup";
import RemoveSchedulerModal from "../../BatchEditor/RemoveSchedulerModal";
import FixedSchedule from "./components/FixedSchedule";

function EditBatchTiming(props: any) {
  const {} = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    batchById,
    batchUpdateSuccess,
    batchUpdateLoading,
    UpdateBatch,
    batchByIdLoading,
  } = useSelector((state: any) => state?.batch);

  const [batchDetails, setBatchDetails] = React.useState<any>({
    instituteName: "",
    instituteId: "",
    batchName: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    startDate: `${moment().unix()}`,
    endDate: `${moment().unix()}`,
    startTime: 0,
    endTime: 0,
    minimumSeats: "",
    maximumSeats: "",
    batchType: [],
    batchSchedule: [
      {
        day: "SUN",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "MON",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "TUE",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "WED",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "THU",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "FRI",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
      {
        day: "SAT",
        batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
      },
    ],
    status: "ACTIVE",
    isFirstBatch: false,
    batchId: "",
    batchPrice: 0,
    coursePrice: 0,
    discountMetric: "",
    discount: 0,
    // batchStatus: "",
    studentCount: 0,
    createdById: "",
    createdByName: "",
    updatedById: "",
    updatedByName: "",
    assignees: [],
    exemptedDates: [],
    customDays: [],
    isFixed: true,
    occurencessSessionDeletion: [],
  });

  const isWholeCalenderEmpty =
    batchDetails?.batchSchedule?.every((day: any) =>
      day.batchSessions.every(
        (session: any) => session.startTime === 0 && session.endTime === 0
      )
    ) &&
    batchDetails.customDays.length === 0 &&
    batchDetails.exemptedDates.length === 0;

  const removeScheduleFieldRef = useRef<HTMLInputElement | null>(null);

  const [batchView, SetBatchView] = useState("List View");

  const [startDate, setStartDate] = React.useState<number | null>(
    moment().unix()
  );

  const [endDate, setEndDate] = React.useState<number | null>(moment().unix());
  const [errorStartDateMszs, setErrorStartDateMszs] = useState<boolean>(false);
  const [errorEndDateMsg, setErrorEndDateMsg] = useState<boolean>(false);
  const [errorStartDayMsg, setErrorStartDayMsg] = useState<boolean>(false);
  const [errorTimeMsg, setErrorTimeMsg] = useState<boolean>(false);
  const [errorEndDatePastMsg, setErrorEndDatePastMsg] =
    useState<boolean>(false);
  const [errorEndDayMsg, setErrorEndDayMsg] = useState<boolean>(false);

  const [isDateChanged, setIsDateChanged] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [scheduleAnchorEl, setScheduleAnchorEl] = useState(null);
  const openRemoveScheduler = Boolean(scheduleAnchorEl);
  const [selecteRemoveSchedulerType, setSelectedRemoveSchedulerType] =
    useState("");
  const [cancelModal, setCancelModal] = React.useState<boolean>(false);
  // let notifyText = "";
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [validationSuccessData, setValidationSuccessData] = useState({});
  const [isCheckedNotification, setIsCheckedNotification] = useState(false);
  const [notifyText, setNotifyText] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date: any, id: string) => {
    if (date !== null) {
      if (id === "startDate") {
        const newStartDate: any = new Date(date).setHours(0, 0, 0, 0);
        setStartDate(date.unix());
        setBatchDetails({
          ...batchDetails,
          startDate: timeZoneConverter(newStartDate),
        });
        setIsDateChanged(true);
      } else if (id === "endDate") {
        const newEndDate: any = new Date(date).setHours(23, 59, 59, 0);
        setEndDate(date.unix());

        setBatchDetails({
          ...batchDetails,
          endDate: timeZoneConverter(newEndDate),
        });
        setIsDateChanged(true);
      }
    }
  };

  const handleOpenRemoveScedulerPopover = (event: any) => {
    setScheduleAnchorEl(event.currentTarget);
    const tempElement = document.createElement("input");
    document.body.appendChild(tempElement);
    tempElement.focus();
    document.body.removeChild(tempElement);
  };

  const handleScheduleChange = React.useCallback(
    (batchSchedule: []) => {
      setBatchDetails({
        ...batchDetails,
        batchSchedule: batchSchedule,
      });
    },
    [batchDetails]
  );
  const isScheduleSame = checkObjectsEqual(
    batchDetails?.batchSchedule,
    batchById?.batchSchedule
  );

  const cancelButtonClick = () => {
    if (isScheduleSame) {
      navigate(`/app/manage/Batches?batchId=${batchById.id}`);
    } else {
      setCancelModal(true);
    }
  };
  const closeCancelModal = () => {
    setCancelModal(false);
  };

  const cancelYesBtn = () => {
    setCancelModal(false);
    navigate(`/app/manage/Batches?batchId=${batchById.id}`);
  };

  React.useEffect(() => {
    const pathname = window.location.pathname;
    const batchId = pathname.substring(pathname.lastIndexOf("/") + 1);
    dispatch<any>(getBatchById(batchId));
    //    setFormDisabled(true);
  }, []);

  React.useEffect(() => {
    if (batchById) {
      const { students, courseImage, ...filteredBatchById } = batchById;
      const updatedBatchSchedule =
        batchById &&
        batchById?.batchSchedule.map((schedule: any) => {
          if (schedule.batchSessions.length === 0) {
            return {
              ...schedule,
              batchSessions: [{ sessionName: "", startTime: 0, endTime: 0 }],
            };
          } else {
            const updatedBatchSessions = schedule.batchSessions.map(
              (session: any) => ({
                ...session,
                startTime: session.startTime,
                endTime: session.endTime,
              })
            );

            return {
              ...schedule,
              batchSessions: updatedBatchSessions,
            };
          }
        });

      if (batchById?.paymentDetails?.paymentType === "SESSION") {
        SetBatchView("Calender View");
      } else {
        (batchById?.customDays && batchById?.customDays?.length > 0) ||
        (batchById?.exemptedDate && batchById?.exemptedDate?.length > 0)
          ? SetBatchView("Calender View")
          : SetBatchView("List View");
      }

      if (batchById?.endDate === 0) {
        setEndDate(moment().unix());
        setBatchDetails({ ...batchDetails, endDate: moment().unix() });
      } else {
        setEndDate(convertToAdjustedTimestamp(batchById?.endDate) / 1000);
        setBatchDetails({
          ...batchDetails,
          endDate: convertToAdjustedTimestamp(batchById?.endDate) / 1000,
        });
      }

      if (batchById?.startDate === 0) {
        setStartDate(moment().unix());
        setBatchDetails({ ...batchDetails, startDate: moment().unix() });
      } else {
        setStartDate(convertToAdjustedTimestamp(batchById?.startDate) / 1000);
        setBatchDetails({
          ...batchDetails,
          startDate: convertToAdjustedTimestamp(batchById?.startDate) / 1000,
        });
      }
      setBatchDetails({
        ...filteredBatchById,
        batchSchedule: updatedBatchSchedule,
        batchId: batchById?.id,
        batchName: batchById?.batchName,
        instituteName: batchById?.instituteName,
        instituteId: batchById?.instituteId,
      });
    }
  }, [batchById]);

  React.useEffect(() => {
    if (batchUpdateSuccess !== true && batchUpdateSuccess !== false) {
      navigate(`/app/manage/Batches?batchId=${batchById.id}`);
    }
  }, [batchUpdateSuccess, batchById]);

  const areObjectsEqual = (oldData: any, newData: any) => {
    const str1 = JSON.stringify(oldData);
    const str2 = JSON.stringify(newData);
    return str1 === str2;
  };

  const areArraysOfObjectsEqual = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = JSON.stringify(arr1[i]);
      const obj2 = JSON.stringify(arr2[i]);

      if (obj1 !== obj2) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    try {
      batchDetails.startDate > batchDetails.endDate
        ? setErrorEndDatePastMsg(true)
        : setErrorEndDatePastMsg(false);

      batchDetails.startDate === ""
        ? setErrorStartDateMszs(true)
        : setErrorStartDateMszs(false);

      batchDetails.endDate === ""
        ? setErrorEndDateMsg(true)
        : setErrorEndDateMsg(false);

      let daysName: any =
        batchDetails.startDate?.toString()?.length === 13
          ? convertToAdjustedTimestamp(batchDetails.startDate) / 1000
          : batchDetails?.startDate === 0
          ? moment().unix()
          : batchDetails?.startDate;

      const startDay = moment
        .unix(parseInt(daysName))
        .format("ddd")
        .toUpperCase();

      const matchingDay = batchDetails.batchSchedule.find(
        (day: any) => day.day === startDay
      );
      let errorStartDate: boolean = false;
      let errorTime: boolean = false;
      if (batchView === "List View") {
        if (
          matchingDay &&
          (matchingDay.batchSessions[0].startTime === 0 ||
            matchingDay.batchSessions[0].endTime === 0)
        ) {
          setErrorStartDayMsg(true);
          errorStartDate = true;
        } else {
          setErrorStartDayMsg(false);
          errorStartDate = false;
        }
      }

      let endDaysName: any =
        batchDetails.endDate?.toString()?.length === 13
          ? convertToAdjustedTimestamp(batchDetails.endDate) / 1000
          : batchDetails?.endDate;

      const endDay = moment
        .unix(parseInt(endDaysName))
        .format("ddd")
        .toUpperCase();

      const matchingEndDay = batchDetails.batchSchedule.find(
        (day: any) => day.day === endDay
      );

      let errorEndDate: boolean = false;
      if (batchView === "List View") {
        if (
          matchingEndDay &&
          (matchingEndDay.batchSessions[0].startTime === 0 ||
            matchingEndDay.batchSessions[0].endTime === 0)
        ) {
          setErrorEndDayMsg(true);
          errorEndDate = true;
        } else {
          setErrorEndDayMsg(false);

          errorEndDate = false;
        }
      }

      const array = batchDetails.batchSchedule.filter((obj: any) => {
        return obj.batchSessions[0].startTime !== 0;
      });

      const containsSameStartAndEndTime = (array: any) => {
        for (const obj of array) {
          if (obj.batchSessions && Array.isArray(obj.batchSessions)) {
            for (const session of obj.batchSessions) {
              if (session.startTime === session.endTime) {
                return true;
              }
            }
          }
        }
        return false;
      };

      const hasMatchingTime = array && containsSameStartAndEndTime(array);

      if (hasMatchingTime === true) {
        setErrorTimeMsg(true);
        errorTime = true;
      } else {
        setErrorTimeMsg(false);
        errorTime = false;
      }

      const updatedBatchSchedule = batchDetails.batchSchedule.map(
        (schedule: any) => {
          if (schedule.batchSessions[0].startTime === 0) {
            return {
              ...schedule,
              batchSessions: [],
            };
          }
          return schedule;
        }
      );

      const newArray: any = batchDetails;
      newArray.batchPrice =
        typeof batchDetails.batchPrice === "string"
          ? parseInt(batchDetails.batchPrice)
          : batchDetails.batchPrice;
      newArray.endTime =
        typeof batchDetails.endTime === "string"
          ? parseInt(batchDetails.endTime)
          : batchDetails.endTime;
      newArray.startTime =
        typeof batchDetails.startTime === "string"
          ? parseInt(batchDetails.startTime)
          : batchDetails.startTime;
      newArray.endDate = parseInt(batchDetails.endDate);
      newArray.startDate = parseInt(batchDetails.startDate);
      newArray.coursePrice =
        typeof batchDetails.coursePrice === "string"
          ? parseInt(batchDetails.coursePrice)
          : batchDetails.coursePrice;
      let newstartDate: any = new Date(batchDetails?.startDate * 1000).setHours(
        0,
        0,
        0,
        0
      );
      let newEndDate: any = new Date(batchDetails?.endDate * 1000).setHours(
        23,
        59,
        59,
        0
      );

      delete newArray.batchStatus;
      batchDetails.startDate <= batchDetails.endDate &&
        batchDetails.startDate !== "" &&
        batchDetails.endDate !== "" &&
        !errorStartDate &&
        !errorEndDate &&
        !errorTime &&
        handleUpdatePopupOpen({
          ...newArray,
          batchConductType: "REGULAR_BATCH",
          batchSchedule: updatedBatchSchedule,
          createDemo: false,
          demoRequest: {},
          batchScheduleChanged: isDateChanged ? true : false,
          updatedById: authUser?.institute[0]?.id,
          updatedByName: authUser?.firstName + " " + authUser?.lastName,
          isFixed:
            batchDetails?.customDays?.length > 0 ||
            batchDetails?.exemptedDates?.length > 0
              ? false
              : true,
          startDate:
            batchDetails?.startDate?.toString()?.length === 10
              ? timeZoneConverter(newstartDate)
              : batchDetails?.startDate === 0
              ? timeZoneConverter(moment().unix())
              : batchDetails?.startDate,
          endDate:
            batchDetails?.endDate?.toString()?.length === 10
              ? timeZoneConverter(newEndDate)
              : batchDetails?.endDate,
          id: batchDetails?.batchId,
        });

      let changes: string[] = [];

      if (!areObjectsEqual(batchById.batchType, batchDetails.batchType)) {
        changes.push("Type");
      } else {
        changes = changes.filter((change) => change !== "Type");
      }

      const isScheduleEqual = areObjectsEqual(
        batchById.batchSchedule,
        updatedBatchSchedule
      );
      const isExemptedEqual = areArraysOfObjectsEqual(
        batchById.exemptedDates,
        batchDetails.exemptedDates
      );
      const isCustomDaysEqual = areArraysOfObjectsEqual(
        batchById.customDays,
        batchDetails.customDays
      );

      if (
        !isScheduleEqual ||
        batchById.startDate !== batchDetails.startDate ||
        batchById.endDate !== batchDetails.endDate ||
        !isExemptedEqual ||
        !isCustomDaysEqual
      ) {
        changes.push("Schedule");
      } else {
        changes = changes.filter((change) => change !== "Schedule");
      }

      if (batchById.branchName !== batchDetails.branchName) {
        changes.push("Branch");
      } else {
        changes = changes.filter((change) => change !== "Branch");
      }

      if (changes.length === 1) {
        setNotifyText(`There is a change in ${changes[0]}.`);
      } else if (changes.length === 2) {
        setNotifyText(`There is a change in ${changes[0]} and ${changes[1]}.`);
      } else if (changes.length === 3) {
        setNotifyText(
          `There is a change in ${changes[0]}, ${changes[1]} and ${changes[2]}.`
        );
      } else {
        setNotifyText("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePopupOpen = (event: any) => {
    setValidationSuccessData(event);
    batchById?.students?.length > 0
      ? setShowEditPopup(true)
      : dispatch<any>(
          updateBatch({
            ...event,
            notifyStudent: isCheckedNotification,
            batchScheduleChanged: true,
            basicInfoChanged: false,
          })
        );
  };

  const handleUpdateBatch = () => {
    dispatch<any>(
      updateBatch({
        ...validationSuccessData,
        notifyStudent: isCheckedNotification,
        batchScheduleChanged: true,
        basicInfoChanged: false,
      })
    );
  };

  const handleNotificationCheckboxChange = (event: any) => {
    setIsCheckedNotification(event.target.checked);
  };

  const handleEditPopupCancelButton = (event: any) => {
    setShowEditPopup(false);
  };

  const handleRemoveScheudler = () => {
    if (selecteRemoveSchedulerType === "Remove only custom dates") {
      setBatchDetails({
        ...batchDetails,
        customDays: [],
        exemptedDates: [],
      });
      setSelectedRemoveSchedulerType("");
    } else if (selecteRemoveSchedulerType === "Remove whole calender") {
      const updatedBatchDetails = {
        ...batchDetails,
        batchSchedule: batchDetails.batchSchedule.map((schedule: any) => {
          return {
            ...schedule,
            batchSessions: schedule.batchSessions.map((session: any) => {
              return {
                ...session,
                startTime: 0,
                endTime: 0,
              };
            }),
          };
        }),
      };

      setBatchDetails({
        ...updatedBatchDetails,
        customDays: [],
        exemptedDates: [],
      });
      SetBatchView("List View");
      setSelectedRemoveSchedulerType("");
    }
  };
  console.log("startDate", startDate, batchDetails.startDate);
  return (
    <div className={styles.pageWrapper} style={{ overflowY: "scroll" }}>
      {batchByIdLoading ? (
        <div>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={100}
            style={{
              borderTopRightRadius: "2px",
              borderTopLeftRadius: "2px",
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
          />
        </div>
      ) : (
        <>
          <div
            className={styles.pageWrapperHead}
            style={{ marginBottom: "10PX" }}
          >
            <div
              className={styles.batchBasicDetailsEditSectionTitle}
              style={{ marginBottom: "0PX" }}
            >
              Schedule Batch
            </div>
            <div></div>
          </div>
          <div className={styles.batchTimingEditChildWrap}>
            <div className={styles.batchTimingEditHead}>
              <div
                className={styles.batchSecSubTitle}
                style={{ marginBottom: "0PX" }}
              >
                Batch Duration
              </div>
              <div>
                <Button
                  id="fade-button"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  style={{
                    textTransform: "capitalize",
                    color: "#667085",
                    display: "flex",
                  }}
                >
                  <img
                    src={btnListIcon}
                    alt="btnListIcon"
                    style={{ marginRight: "8PX" }}
                  />

                  {batchView}
                  <img
                    src={btnDownArrow}
                    alt="btnDownArrow"
                    style={{ marginLeft: "8PX" }}
                  />
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                  PaperProps={{
                    sx: {
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                      borderRadius: "4PX",
                      border: "1px solid #EEE",
                      minWidth: "108PX",
                    },
                  }}
                >
                  {["List View", "Calender View"]
                    .filter(
                      (view) =>
                        !(
                          batchDetails?.paymentDetails?.paymentType ===
                            "SESSION" && view === "List View"
                        )
                    )
                    ?.map((view: any) => (
                      <MenuItem
                        value={view}
                        sx={{
                          fontSize: "12px !important",
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          SetBatchView(view);
                          handleClose();
                          setBatchDetails({
                            ...batchDetails,
                            startDate: timeZoneConverter(moment().unix()),
                          });
                        }}
                      >
                        {view}
                      </MenuItem>
                    ))}
                </Menu>
              </div>
            </div>
            <div
              className={styles.batchSecSubLabel}
              style={{ marginBottom: "10PX" }}
            >
              Select Date
            </div>
            <div className={styles.batchTimingDatePickerWrap}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ zIndex: "99999999 !import" }}
                    label="Start Date"
                    format="DD MMM YYYY"
                    onChange={(date: any) => {
                      handleDateChange(date, "startDate");
                    }}
                    value={startDate ? dayjs.unix(startDate) : dayjs()}
                  />
                </LocalizationProvider>
                {errorStartDateMszs ? (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0",
                      fontSize: "12px",
                    }}
                  >
                    Select start date
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    format="DD MMM YYYY"
                    value={endDate ? dayjs.unix(endDate) : dayjs()}
                    onChange={(date: any) => {
                      handleDateChange(date, "endDate");
                    }}
                  />
                </LocalizationProvider>

                {errorEndDateMsg ? (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0",
                      fontSize: "14px",
                    }}
                  >
                    Select end date
                  </div>
                ) : (
                  <></>
                )}
                {errorEndDatePastMsg ? (
                  <div
                    style={{
                      color: "red",
                      padding: "5px 0",
                      fontSize: "14px",
                    }}
                  >
                    End date should be after or same as start date
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {batchById?.batchStatus !== "ONGOING" && (
              <div
                style={{
                  marginBottom: "30px",
                }}
              >
                {isWholeCalenderEmpty === false &&
                  batchDetails?.customDays &&
                  batchDetails?.customDays?.length > 0 && (
                    <>
                      <div
                        className={styles.batchSecSubLabel}
                        style={{ marginBottom: "10PX" }}
                      >
                        Remove Scheduler
                      </div>

                      <>
                        <TextField
                          onClick={handleOpenRemoveScedulerPopover}
                          value={selecteRemoveSchedulerType}
                          variant="outlined"
                          size="small"
                          placeholder="Select"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon
                                  style={{
                                    color: "#d4d4d8",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root fieldset": {
                              "&:hover fieldset": {
                                height: "40px",
                                fontSize: "12px !important",
                              },
                            },
                            width: "260px",
                          }}
                          inputRef={removeScheduleFieldRef}
                        />

                        <Popover
                          open={openRemoveScheduler}
                          anchorEl={scheduleAnchorEl}
                          onClose={() => {
                            setScheduleAnchorEl(null);
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          sx={{ width: "100%" }}
                          PaperProps={{
                            sx: {
                              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
                              borderRadius: "4PX",
                              border: "1px solid #EEE",
                            },
                          }}
                        >
                          <div
                            style={{
                              height: "100px",
                              overflowY: "scroll",
                            }}
                          >
                            <List>
                              {[
                                "Remove only custom dates",
                                "Remove whole calender",
                              ]?.map((d: any) => {
                                return (
                                  <ListItem
                                    key={d}
                                    button
                                    onClick={() => {
                                      setSelectedRemoveSchedulerType(d);
                                      setScheduleAnchorEl(null);
                                    }}
                                  >
                                    <ListItemText
                                      primary={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {`${d}`}
                                        </div>
                                      }
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </div>
                        </Popover>
                      </>
                    </>
                  )}
              </div>
            )}

            <div
              className={styles.batchSecSubLabel}
              style={{ marginBottom: "10PX" }}
            >
              Set your weekly hours
            </div>

            <div>
              {batchView === "List View" ? (
                <>
                  <FixedSchedule
                    add={false}
                    batchDetails={batchDetails}
                    setBatchDetails={setBatchDetails}
                    handleScheduleChange={handleScheduleChange}
                    setIsDateChanged={setIsDateChanged}
                    errorStartDayMsg={errorStartDayMsg}
                    errorTimeMsg={errorTimeMsg}
                    errorEndDayMsg={errorEndDayMsg}
                  />
                </>
              ) : (
                <div>
                  <CalenderPage
                    batchDetails={batchDetails}
                    setBatchDetails={setBatchDetails}
                    add={false}
                    startingDate={startDate}
                    endingDate={endDate}
                    handleDateChange={handleDateChange}
                    errorStartDateMszs={errorStartDateMszs}
                    errorEndDatePastMsg={errorEndDatePastMsg}
                    errorEndDateMsg={errorEndDateMsg}
                    setIsDateChanged={setIsDateChanged}
                    errorStartDayMsg={errorStartDayMsg}
                    errorTimeMsg={errorTimeMsg}
                    errorEndDayMsg={errorEndDayMsg}
                    isWholeCalenderEmpty={isWholeCalenderEmpty}
                  />
                </div>
              )}
            </div>
          </div>
          <CancelPopup
            open={cancelModal}
            onClose={closeCancelModal}
            noBtnClick={closeCancelModal}
            yesBtnClick={cancelYesBtn}
          />
          <div className={styles.editSectionBtnWrap}>
            <CancelBtn onClick={() => cancelButtonClick()} />
            <SaveBtn
              onClick={handleSubmit}
              //   disabled={
              //     loading_updateCourse || (isSyllabusSame && isDescriptionSame)
              //   }
              loader={
                batchById?.students?.length > 0 ? false : batchUpdateLoading
              }
            />
          </div>
        </>
      )}

      {showEditPopup && (
        <EditPopup
          type={"batch"}
          onClose={handleEditPopupCancelButton}
          onConfirm={handleUpdateBatch}
          showEditPopup={showEditPopup}
          notifyText={notifyText}
          handleNotificationCheckboxChange={handleNotificationCheckboxChange}
          isCheckedNotification={isCheckedNotification}
          loader={batchUpdateLoading}
        />
      )}
      <RemoveSchedulerModal
        open={selecteRemoveSchedulerType}
        noButtonClick={() => {
          setSelectedRemoveSchedulerType("");
        }}
        setSelectedRemoveSchedulerType={setSelectedRemoveSchedulerType}
        yesButtonClick={handleRemoveScheudler}
        selecteRemoveSchedulerType={selecteRemoveSchedulerType}
      />
    </div>
  );
}

export default EditBatchTiming;
