import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import copyIcon from "../../assets/images/copy-icon.svg";

function CopyBtn(props: any) {
  const { copyText, variant } = props;
  const [textCopied, setTextCopied] = React.useState<boolean>(false);
  const [tooltipTimeout, setTooltipTimeout] =
    React.useState<NodeJS.Timeout | null>(null);
  const handleCopyClick = () => {
    setTextCopied(true);

    const timeout = setTimeout(() => {
      setTextCopied(false);
    }, 1000);

    setTooltipTimeout(timeout);
  };

  React.useEffect(() => {
    return () => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, [tooltipTimeout]);
  return (
    <>
      <CopyToClipboard text={copyText} onCopy={handleCopyClick}>
        <div
          style={{
            cursor: "pointer",
            position: "relative",
            border: variant === "OUTLINED" ? "1px solid #E9E9E9" : "none",
            padding: variant === "OUTLINED" ? "7px 8px" : "0px",
            borderRadius: variant === "OUTLINED" ? "3PX" : "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxHeight: "28PX",
            maxWidth: "28PX",
          }}
        >
          <img src={copyIcon} alt="edit" />
          <div
            style={{
              position: "absolute",
              bottom: "30px",
              left: "-24px",
              background: "rgb(122 122 122 / 70%)",
              color: "#fff",
              padding: "6px 8px",
              borderRadius: "3px",
              fontSize: "11px",
              display: textCopied ? "block" : "none",
              transition: "0.1s ease-in-out",
            }}
          >
            Copied!
          </div>
        </div>
      </CopyToClipboard>
    </>
  );
}

export default CopyBtn;
