import styles from "./ZapierDetails.module.css";
import { Box, Button, CircularProgress, InputLabel } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import ZapierInstalledStage from "./ZapierInstalledStage";
import zapierFav from "../Assets/zapierFav.svg";
import {
  appIntegrateToNurture,
  getAppToken,
} from "../../service/IntegrationsSlice";
import { useSelector } from "react-redux";
import { getInstituteById } from "../../../Manage/InstituteProfile/service/instituteSlice";

function ZapierIntegration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuthUser();
  const authUser = auth();

  const { appIntegrationloading, appTokenData } = useSelector(
    (state: any) => state.appIntegrations
  );
  const zapierCallbackUrl = appTokenData ? appTokenData?.token : "";
  const zapierApiKey = process.env.REACT_APP_OZONETEL_CALLBACK_URL || "";
  const [isCopied, setIsCopied] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [isCopiedApi, setIsCopiedApi] = useState(false);
  const [tooltipTimeoutApi, setTooltipTimeoutApi] =
    useState<NodeJS.Timeout | null>(null);

  const copyCallbackUrlToClipboard = () => {
    navigator.clipboard.writeText(zapierCallbackUrl);
    setIsCopied(true);

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    // Save the timeout ID for cleanup
    setTooltipTimeout(timeout);
  };

  const copyApiToClipboard = () => {
    navigator.clipboard.writeText(zapierApiKey);
    setIsCopiedApi(true);

    const timeout = setTimeout(() => {
      setIsCopiedApi(false);
    }, 1000);

    setTooltipTimeoutApi(timeout);
  };

  const appInstallation = () => {
    const data = {
      instituteId: authUser?.institute[0]?.instituteId,
      appName: "ZAPIER",
      appCode: "ZAPIER",
      createdByName:
        authUser?.institute[0].firstName +
        " " +
        authUser?.institute[0].lastName,
      createdById: authUser?.institute[0]?.id,
      appConfigData: {
        apiKey: "",
        userName: "",
      },
      updatedById: "",
      updatedByName: "",
    };
    dispatch<any>(appIntegrateToNurture(data));
  };

  const instituteData = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const hasZapier =
    instituteData &&
    Array.isArray(instituteData.integrationsOpted) &&
    instituteData?.integrationsOpted?.includes("ZAPIER");

  React.useEffect(() => {
    return () => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }
    };
  }, [tooltipTimeout]);

  React.useEffect(() => {
    return () => {
      if (tooltipTimeoutApi) {
        clearTimeout(tooltipTimeoutApi);
      }
    };
  }, [tooltipTimeoutApi]);
  React.useEffect(() => {
    if (authUser && hasZapier) {
      const data = {
        id: authUser?.institute?.[0]?.instituteId,
        appName: "ZAPIER",
      };
      dispatch<any>(getAppToken(data));
    }
  }, [authUser, hasZapier]);

  React.useEffect(() => {
    dispatch<any>(getInstituteById(authUser?.institute?.[0]?.instituteId));
  }, []);

  const zapier = true;
  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          padding: "14px 16px",
          background: "#EEEEEE",
          maxHeight: "calc(100vh - 65px)",
          position: "relative",
        }}
      >
        {/* <ConnectSuccessPopup
          isOpen={successPopup}
          onClose={handleSuccessPopupClose}
        /> */}
        <Box
          sx={{
            overflow: "hidden",
            background: "#fff",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
            minHeight: "calc(100vh - 93px)",
            maxHeight: "calc(100vh - 93px)",
          }}
        >
          <div
            className={styles.ozonetelDetailsSplit}
            style={{
              minHeight: "calc(100vh - 93px)",
              maxHeight: "calc(100vh - 93px)",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <div className={styles.PageBackBtnWrap}>
                <button
                  className={styles.PageBackBtn}
                  onClick={() => navigate(`/app/Integrations`)}
                >
                  <ArrowBackIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "15PX",
                      color: "#667085",
                    }}
                  />
                  Back
                </button>
              </div>

              <div
                className={styles.ozonetelDetailsSplitLeftBody}
                style={{
                  maxHeight: !zapier
                    ? "calc(100vh - 160px)"
                    : "calc(100vh - 120px)",
                  minHeight: "calc(100vh - 160px)",
                  overflowY: "auto",
                }}
              >
                {!zapier ? (
                  <>
                    <div>
                      <img src={zapierFav} alt="zapierFav" />
                    </div>
                    <Box className={styles.ozonetelHead}>
                      Setup Zapier integration
                    </Box>

                    <Box
                      sx={{
                        maxWidth: "55%",
                        margin: "0 auto 20px auto",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <InputLabel
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#667085",
                            fontFamily: "Poppins",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "22px",
                            textTransform: "capitalize",
                          }}
                        >
                          Webhook URL
                        </InputLabel>

                        <div
                          style={{
                            borderRadius: "6px",
                            border: "1px solid #EAEAEA",
                            background: "#F1F5F8",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                            marginBottom: "20px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              color: "#667085",
                              fontSize: "13px",
                              fontWeight: "400",
                              lineHeight: "26px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {zapierCallbackUrl}
                          </div>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <button
                              style={{
                                background: "#FFF",
                                borderRadius: "4px",
                                border: "1px solid #F0F0F0",
                                cursor: "pointer",
                                padding: "10px",
                                color: "#667085",
                                fontSize: "13px",
                                fontWeight: "400",
                                lineHeight: "18px",
                                height: "36px",
                                width: "70px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={copyCallbackUrlToClipboard}
                            >
                              Copy
                            </button>
                            {isCopied && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "40px",
                                  left: "6px",
                                  background: "rgb(122 122 122 / 70%)",
                                  color: "#fff",
                                  padding: "6px 8px",
                                  borderRadius: "3px",
                                  fontSize: "11px",
                                }}
                              >
                                Copied
                              </div>
                            )}
                          </div>
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <InputLabel
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#667085",
                            fontFamily: "Poppins",
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "22px",
                            textTransform: "capitalize",
                          }}
                        >
                          API Key
                        </InputLabel>

                        <div
                          style={{
                            borderRadius: "6px",
                            border: "1px solid #EAEAEA",
                            background: "#F1F5F8",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px",
                            marginBottom: "20px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              color: "#667085",
                              fontSize: "13px",
                              fontWeight: "400",
                              lineHeight: "26px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {zapierApiKey}
                          </div>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <button
                              style={{
                                background: "#FFF",
                                borderRadius: "4px",
                                border: "1px solid #F0F0F0",
                                cursor: "pointer",
                                padding: "10px",
                                color: "#667085",
                                fontSize: "13px",
                                fontWeight: "400",
                                lineHeight: "18px",
                                height: "36px",
                                width: "70px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              onClick={copyApiToClipboard}
                            >
                              Copy
                            </button>
                            {isCopiedApi && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "40px",
                                  left: "6px",
                                  background: "rgb(122 122 122 / 70%)",
                                  color: "#fff",
                                  padding: "6px 8px",
                                  borderRadius: "3px",
                                  fontSize: "11px",
                                }}
                              >
                                Copied
                              </div>
                            )}
                          </div>
                        </div>
                      </Box>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                          width: "100%",
                        }}
                      >
                        <Button
                          fullWidth
                          color="primary"
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            maxWidth: "100%",
                            mainWidth: "100%",
                            "&.Mui-disabled": {
                              backgroundColor: "#11C89B ",
                              color: "#fff",
                            },
                          }}
                          onClick={appInstallation}
                          disabled={appIntegrationloading}
                        >
                          {appIntegrationloading ? (
                            <CircularProgress size={20} color={"inherit"} />
                          ) : (
                            "Continue"
                          )}
                          {/* Continue */}
                        </Button>
                      </div>
                    </Box>
                  </>
                ) : (
                  <>
                    <div style={{ padding: "20px 20px" }}>
                      <ZapierInstalledStage />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ZapierIntegration;
