import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./Nurture.module.css";
import rightDIcon from "./Assets/rightdIcon.svg";
import leftDIcon from "./Assets/leftDIcon.svg";
import { useAuthUser } from "react-auth-kit";
import InactiveAccountPopup from "../../../../components/CustomPopupBilling/InactiveAccountPopup";
import NurtureListView from "./NurtureListView";
import { getSubDomainAccessStatus } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import {
  resetleadConvertionSuccess,
  resetLeadCreateSuccess,
  resetLeadLostSuccess,
  resetLeadUpdateSuccess,
  resetUpdateNoPaymentCollection,
} from "../service/nurtureSlice";
import { useDispatch } from "react-redux";
import AddBatchPopup from "../../Manage/ManageBatch/BatchEditor/AddBatchPopup";
import { clearLeadBoardRefresh } from "../../../../service/websocketNotificationsSlice";
import { getBatchList } from "../../Manage/ManageBatch/service/Batchslice";

const NurtureKanban = lazy(() => import("./NurtureKanban"));
const SearchableCourseList = lazy(() => import("./SearchableCourseList"));

export default function Nurture(props: any) {
  const {
    GetBranchList,
    GetCourseList,
    GetBatchList,
    GetLeadById,
    GetBatchByInstitute,
    GetBatchById,
    GetDemoByBatchId,
    GetDemoByDateAndBatch,
    GetDemoByEnquiryId,
    GetInboxByLeadId,
    GetCourseById,
    GetInstituteById,
    GetBatchTimeByBatchId,
    GetCourseByCategoryByInstitute,
    GetNurtureLeadBoard,
    GetLeadActivityList,
    GetLeadNoteList,
    GetLeadTaskList,
    GetLeadPaymentList,
    GetLeadListByInstituteAndContact,
    GetDemoCalanderByCourseAndInstitute,
    GetEmiDataOfLead,
    GetAppsByInstitute,
    CreateSource,

    // Api call status
    loading,
    error,
    success,

    leadCreateSuccess,
    leadCreateError,

    leadStatusUpdateSuccess,
    leadStatusUpdateError,
    leadStatusUpdateLoading,

    leadUpdateSuccess,

    leadConvertionSuccess,
    leadConvertionError,

    leadPriorityUpdateSuccess,

    composeEmailSuccess,

    CreateTask,
    createTaskSuccess,

    CreateNote,
    createNoteSuccess,

    CreateDemo,
    createDemoSuccess,

    RecordPayment,
    createPaymentSuccess,

    EditDemo,
    editDemoSuccess,

    leadAssigneeUpdateSuccess,

    leadLostUpdateSuccess,

    nurtureAddEnquiry,
    UpdateLeadSwimlaneStatus,
    UpdateLead,
    CourseConfig,
    leadBoard,
    leadActivityList,
    leadNoteList,
    leadById,
    ConvertLeadToStudent,
    UpdateLeadPriority,
    UpdateLeadAssignee,
    ComposeEmailSent,
    leadInbox,
    leadTaskList,
    leadPaymentList,
    UpdateLeadEnquiyStatus,
    courseDataWithCategory,
    GetAllTasksType,
    handleCompress,
    leadConvertionLoading,
    GetLeadsForBoard,
    onLeadsSuccess,
    onLeadsData,
    leadCreateLoading,
    onLeadsLoading,

    leadListByContact,
    DeleteNote,
    deleteNoteSuccess,
    EditNote,
    editNoteSuccess,
    DeleteTask,
    deleteTaskSuccess,
    EditTask,
    editTaskSuccess,
    UpdateTaskStatus,
    CreateBatch,
    DeleteDemoBookedForLead,
    deleteDemoBookedSuccess,
    leadTasksloading,
    paymentListLoading,
    noteListLoading,
    inboxLoading,
    UpdateLeadEmiDetails,
    leadEmiData,
    SendPaymentLink,
    DownloadPaymentReceipt,
    EditRecordPayment,
    CallLead,
    CallDispositionForm,

    CreateComment,
    DeleteComment,
    EditComment,
    updateNoPaymentCollectedSuccess,
    randomPaymentCollectSuccess,
    sessionPayRecordSuccess,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasAllBranchAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "BOARD",
    "ALL_BRANCHES",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const branchList = useSelector((state: any) => state.branch.branchList);
  const courseList = useSelector((state: any) => state.courses.courses);
  const batches = useSelector((state: any) => state.batch.batchList);
  const batchList = batches?.filter((item: any) => item?.status !== "DRAFT");
  const batchCreateSuccess = useSelector(
    (state: any) => state.batch.batchCreateSuccess
  );
  // const courseTypes = useSelector(
  //   (state: any) => state.courseConfig.courseConfigs.courseType
  // );
  // const taskTypes = useSelector((state: any) => state.tasks.tasksTypes);
  const taskTypes = leadBoard?.taskType;

  const { leadUpdateNotify } = useSelector(
    (state: any) => state?.websocketNotifications
  );
  const employees = useSelector((state: any) => state.staff.staffList);
  const courseTypes = leadBoard?.courseType;

  const [selectedCourse, setSelectedCourse] = useState<any>(null);
  const [isKanbanView, setIsKanbanView] = useState<boolean>(true);
  const [nurtureLeadStatus, setNurtureLeadStatus] = useState<any>("ACTIVE");
  const [leadItems, setLeadItems] = useState<any>();
  const [leadListItems, setLeadListItems] = useState<any>();
  const [leadPaginationLoad, setleadPaginationLoad] = useState<boolean>(false);
  const [addBatchModal, setAddBatchModal] = React.useState<boolean>(false);

  const [courseListDrawerOpen, setCourseListDrawerOpen] =
    useState<boolean>(false);
  const [gridAbsolutePosition, setGridAbsolutePosition] =
    useState<boolean>(false);

  const toggleCourseDrawer = () => {
    setCourseListDrawerOpen(!courseListDrawerOpen);
    const queryParams = new URLSearchParams(window.location.search);

    if (courseListDrawerOpen) {
      queryParams.delete("courseDrawer");
    } else {
      queryParams.set("courseDrawer", "true");
    }
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  const defaultLeadsCall = useCallback(
    (isListView: boolean, statusType: string, courseId: string) => {
      console.log("isListView", isListView);
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      const urlSearchParams = new URLSearchParams(window.location.search);

      const branchParam = urlSearchParams.get("filteredBranches");
      const batchParam = urlSearchParams.get("filteredBatches");
      const assigneeParam = urlSearchParams.get("filteredAssignees");
      const priorityParam = urlSearchParams.get("filteredPriorities");
      const sourceParam = urlSearchParams.get("filteredSources");
      const stagesParam = urlSearchParams.get("filteredStages");

      const prioritiesInUrl = priorityParam
        ? priorityParam.split("|").map((item: string) => item)
        : [];
      const sourcesInUrl = sourceParam
        ? sourceParam
            .split("|")
            .map((item: string) => item?.toUpperCase().replace(/\s+/g, "_"))
        : [];
      const stagesInUrl = stagesParam
        ? stagesParam.split("|").map((item: string) => item)
        : [];
      const assigneeIdInUrl = assigneeParam
        ? assigneeParam.split("|").map((item: string) => item)
        : [];
      const branchIdInUrl = branchParam
        ? branchParam.split("|").map((item: string) => item)
        : [];
      const batchIdInUrl = batchParam
        ? batchParam.split("|").map((item: string) => item)
        : [];

      if (userFromLocalStorage && courseId) {
        type Data = {
          instituteId: string;
          isListView: boolean;
          isFilter: boolean;
          entityName: string;
          courseId: string;
          enquiryStatus: string;
          branchId?: any[];
          enquirySource?: any[];
          enquiryPriority?: any[];
          batchId?: any[];
          assignedToId?: any[];
          enquirySwimlaneStatus?: any[];
        };

        const data: Data = {
          instituteId: userFromLocalStorage?.id,
          isFilter: true,
          isListView: isListView,
          entityName: "ENQUIRY",
          enquiryStatus: statusType,
          courseId: courseId,
        };

        if (!hasAllBranchAccess) {
          data.branchId = [authUser?.institute?.[0]?.branchId];
        } else {
          data.branchId = branchIdInUrl?.length > 0 ? branchIdInUrl : [];
        }

        if (userById?.roleCode === "COURSE_COUNSELOR") {
          data.assignedToId = [authUser?.institute?.[0]?.id];
        } else {
          data.assignedToId =
            assigneeIdInUrl?.length > 0 ? assigneeIdInUrl : [];
        }

        if (sourcesInUrl) {
          data.enquirySource = sourcesInUrl?.length > 0 ? sourcesInUrl : [];
        }
        if (prioritiesInUrl) {
          data.enquiryPriority =
            prioritiesInUrl?.length > 0 ? prioritiesInUrl : [];
        }

        if (batchIdInUrl) {
          data.batchId = batchIdInUrl?.length > 0 ? batchIdInUrl : [];
        }

        if (isListView && stagesInUrl) {
          data.enquirySwimlaneStatus =
            stagesInUrl?.length > 0 ? stagesInUrl : [];
        }

        GetLeadsForBoard(data);
      }
    },
    []
  );

  const filterLeadsCall = useCallback(
    (
      isListView: boolean,
      statusType: string,
      courseId: string,
      branches: any[],
      enquirySource: any[],
      enquiryPriority: any[],
      batchIds: any[],
      assignedToIds: any[],
      swimlaneStages: any[]
    ) => {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      console.log("enquirySource", enquirySource);
      if (userFromLocalStorage && courseId) {
        type Data = {
          instituteId: string;
          isListView: boolean;
          isFilter: boolean;
          entityName: string;
          courseId: string;
          enquiryStatus: string;
          branchId?: any[];
          enquirySource?: any[];
          enquiryPriority?: any[];
          batchId?: any[];
          assignedToId?: any[];
          enquirySwimlaneStatus?: any[];
        };

        const data: Data = {
          instituteId: userFromLocalStorage?.id,
          isFilter: true,
          isListView: isListView,
          entityName: "ENQUIRY",
          enquiryStatus: statusType,
          courseId: courseId,
        };

        if (!hasAllBranchAccess) {
          data.branchId = [authUser?.institute?.[0]?.branchId];
        } else {
          data.branchId =
            branches?.length > 0 ? branches?.map((item: any) => item?.id) : [];
        }

        if (userById?.roleCode === "COURSE_COUNSELOR") {
          data.assignedToId = [authUser?.institute?.[0]?.id];
        } else {
          data.assignedToId =
            assignedToIds?.length > 0
              ? assignedToIds?.map((item: any) => item?.id)
              : [];
        }

        if (enquirySource) {
          data.enquirySource =
            enquirySource?.length > 0
              ? enquirySource?.map((item: any) =>
                  item?.source?.toUpperCase().replace(/\s+/g, "_")
                )
              : [];
        }

        if (enquiryPriority) {
          data.enquiryPriority =
            enquiryPriority?.length > 0
              ? enquiryPriority?.map((item: any) => item?.priority)
              : [];
        }

        if (batchIds) {
          data.batchId =
            batchIds?.length > 0 ? batchIds?.map((item: any) => item?.id) : [];
        }

        if (isListView && swimlaneStages) {
          data.enquirySwimlaneStatus =
            swimlaneStages?.length > 0
              ? swimlaneStages?.map((item: any) => item?.leadStage)
              : [];
        }

        GetLeadsForBoard(data);
      }
    },
    []
  );

  // const defaultLeadsCall = useCallback(
  //   (nurtureView: boolean, statusType: string, courseId: string) => {
  //     const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

  //     if (userFromLocalStorage && courseId) {
  //       type Data = {
  //         instituteId: any;
  //         courseId: any;
  //         nurtureBoard: boolean;
  //         limit: number;
  //         branchId?: any;
  //         status: string;
  //         assigneeId?: string;
  //         lanesToFetch?: {
  //           stateName: string;
  //           lastEvaluatedKey: string;
  //           lastEvaluatedSortKey: string;
  //         }[];
  //       };

  //       const data: Data = {
  //         instituteId: userFromLocalStorage?.id,
  //         courseId: courseId,
  //         nurtureBoard: true,
  //         limit: 15,
  //         status: statusType,
  //       };

  //       if (!hasAllBranchAccess) {
  //         data.branchId = authUser?.institute?.[0]?.branchId;
  //       }

  //       if (userById?.roleCode === "COURSE_COUNSELOR") {
  //         data.assigneeId = authUser?.institute?.[0]?.id;
  //       }

  //       if (nurtureView) {
  //         data.lanesToFetch = [
  //           {
  //             stateName: "NEW_ENQUIRY",
  //             lastEvaluatedKey: "",
  //             lastEvaluatedSortKey: "",
  //           },
  //           {
  //             stateName: "DEMO",
  //             lastEvaluatedKey: "",
  //             lastEvaluatedSortKey: "",
  //           },
  //           {
  //             stateName: "CONTACTED",
  //             lastEvaluatedKey: "",
  //             lastEvaluatedSortKey: "",
  //           },
  //           {
  //             stateName: "NEGOTIATION",
  //             lastEvaluatedKey: "",
  //             lastEvaluatedSortKey: "",
  //           },
  //           {
  //             stateName: "CONVERTED",
  //             lastEvaluatedKey: "",
  //             lastEvaluatedSortKey: "",
  //           },
  //         ];
  //       }

  //       GetLeadsForBoard(data);
  //     }
  //   },
  //   []
  // );

  const handleSelectedCourse = React.useCallback(
    (course: any) => {
      setSelectedCourse(course);
      setLeadItems("");
      setLeadListItems("");
      setleadPaginationLoad(false);
      defaultLeadsCall(
        isKanbanView ? false : true,
        nurtureLeadStatus,
        course?.courseId
      );
    },
    [
      isKanbanView,
      nurtureLeadStatus,
      setSelectedCourse,
      setleadPaginationLoad,
      defaultLeadsCall,
    ]
  );

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

    if (userFromLocalStorage) {
      let data = {
        branchId: !hasAllBranchAccess ? authUser?.institute?.[0]?.branchId : "",
        id: userFromLocalStorage?.id,
        assigneeId:
          userById?.roleCode === "COURSE_COUNSELOR"
            ? authUser?.institute?.[0]?.id
            : "",
      };

      if (data) {
        GetCourseByCategoryByInstitute(data);
      }
      dispatch<any>(
        getBatchList({
          id: instituteData?.id,
          batchConductType: "ALL",
          loadStudent: true,
        })
      );
      // CourseConfig();
      dispatch<any>(resetUpdateNoPaymentCollection());
    }
  }, [userById, updateNoPaymentCollectedSuccess]);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const courseDrawer = urlSearchParams.get("courseDrawer");

    if (courseDrawer) {
      setCourseListDrawerOpen(true);
    }
  }, [setCourseListDrawerOpen]);

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const nurtureView = urlSearchParams.get("nurtureView");
    const leadStatusParam = urlSearchParams.get("nurtureLeadStatus");
    const selectedCourseinUrl = urlSearchParams.get("selectedCourse");

    if (nurtureView === "LIST") {
      setIsKanbanView(false);
    }

    // Lead type set
    let leadStatusFilter = "ACTIVE";
    if (leadStatusParam === "LOST") {
      leadStatusFilter = "LOST";
      setNurtureLeadStatus("LOST");
    } else {
      setNurtureLeadStatus("ACTIVE");
    }

    // Course set
    let defaultCourse = null;

    if (courseDataWithCategory) {
      const filteredCourses = courseDataWithCategory?.filter(
        (category: any) =>
          category?.courseDetails && category?.courseDetails?.length > 0
      );

      if (selectedCourseinUrl && filteredCourses?.length > 0) {
        const filteredCategory = filteredCourses?.find(
          (category: any) =>
            category?.courseDetails &&
            category?.courseDetails?.some(
              (course: any) => course?.courseId === selectedCourseinUrl
            )
        );
        if (filteredCategory) {
          const courseFromUrl = filteredCategory?.courseDetails?.find(
            (course: any) => course?.courseId === selectedCourseinUrl
          );
          if (courseFromUrl) {
            setSelectedCourse(courseFromUrl);
            defaultCourse = courseFromUrl;
            if (courseFromUrl && courseFromUrl?.leadcount > 0) {
              urlSearchParams.set("selectedCourse", courseFromUrl?.courseId);
              const newUrl = `${
                window.location.pathname
              }?${urlSearchParams.toString()}`;
              window.history.pushState({}, "", newUrl);
            }
          }
        }
      } else if (selectedCourseinUrl === null && filteredCourses?.length > 0) {
        setSelectedCourse(filteredCourses?.[0]?.courseDetails?.[0]);
        defaultCourse = filteredCourses?.[0]?.courseDetails?.[0];

        urlSearchParams.set(
          "selectedCourse",
          filteredCourses?.[0]?.courseDetails?.[0]?.courseId
        );
        const newUrl = `${
          window.location.pathname
        }?${urlSearchParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    }

    if (userFromLocalStorage && defaultCourse) {
      setleadPaginationLoad(false);

      // GetLeadsForBoard(data);

      defaultLeadsCall(
        nurtureView !== "LIST" ? false : true,
        leadStatusParam === "LOST" ? "LOST" : "ACTIVE",
        defaultCourse?.courseId
      );
    }
  }, [courseDataWithCategory]);

  useEffect(() => {
    if (onLeadsData) {
      if (isKanbanView) {
        setLeadItems(onLeadsData);
        setLeadListItems("");
        setleadPaginationLoad(false);
      } else {
        setLeadListItems(onLeadsData);
        setLeadItems("");
        setleadPaginationLoad(false);
      }
    }
  }, [onLeadsData, isKanbanView]);

  // React.useEffect(() => {
  //   if (
  //     // leadStatusUpdateSuccess ||
  //     // leadCreateSuccess ||
  //     leadUpdateSuccess ||
  //     // leadConvertionSuccess ||
  //     leadPriorityUpdateSuccess ||
  //     leadAssigneeUpdateSuccess ||
  //     composeEmailSuccess ||
  //     // leadLostUpdateSuccess ||
  //     createTaskSuccess ||
  //     createPaymentSuccess ||
  //     createDemoSuccess ||
  //     editDemoSuccess ||
  //     deleteDemoBookedSuccess ||
  //     batchCreateSuccess
  //   ) {
  //     const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
  //     const urlSearchParams = new URLSearchParams(window.location.search);
  //     const nurtureView = urlSearchParams.get("nurtureView");

  //     setleadPaginationLoad(false);
  //     if (userFromLocalStorage && selectedCourse) {
  //       defaultLeadsCall(
  //         nurtureView !== "LIST" ? false : true,
  //         nurtureLeadStatus,
  //         selectedCourse?.courseId
  //       );
  //     }
  //   }
  // }, [
  //   // leadStatusUpdateSuccess,
  //   // leadCreateSuccess,
  //   leadUpdateSuccess,
  //   // leadConvertionSuccess,
  //   leadPriorityUpdateSuccess,
  //   leadAssigneeUpdateSuccess,
  //   composeEmailSuccess,
  //   // leadLostUpdateSuccess,
  //   createTaskSuccess,
  //   createPaymentSuccess,
  //   GetNurtureLeadBoard,
  //   createDemoSuccess,
  //   editDemoSuccess,
  //   deleteDemoBookedSuccess,
  //   batchCreateSuccess,
  // ]);

  React.useEffect(() => {
    if (leadUpdateNotify === true) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const nurtureView = urlSearchParams.get("nurtureView");

      setleadPaginationLoad(false);
      if (userFromLocalStorage && selectedCourse) {
        defaultLeadsCall(
          nurtureView !== "LIST" ? false : true,
          nurtureLeadStatus,
          selectedCourse?.courseId
        );
      }

      const timeoutId = setTimeout(() => {
        dispatch(clearLeadBoardRefresh());
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [leadUpdateNotify]);

  React.useEffect(() => {
    if (
      (leadConvertionSuccess !== true && leadConvertionSuccess !== false) ||
      (createPaymentSuccess !== true && createPaymentSuccess !== false) ||
      (randomPaymentCollectSuccess !== true &&
        randomPaymentCollectSuccess !== false) ||
      (sessionPayRecordSuccess !== true && sessionPayRecordSuccess !== false)
    ) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;

      if (userFromLocalStorage) {
        GetBatchList({
          id: userFromLocalStorage?.id,
          batchConductType: "REGULAR_BATCH",
          loadStudent: true,
        });
      }
      dispatch(resetleadConvertionSuccess());
    }
  }, [
    leadConvertionSuccess,
    createPaymentSuccess,
    randomPaymentCollectSuccess,
    sessionPayRecordSuccess,
  ]);

  React.useEffect(() => {
    if (
      (leadCreateSuccess !== true && leadCreateSuccess !== false) ||
      (leadUpdateSuccess !== true && leadUpdateSuccess !== false) ||
      (leadLostUpdateSuccess !== true && leadLostUpdateSuccess !== false) ||
      (leadConvertionSuccess !== true && leadConvertionSuccess !== false) ||
      (leadStatusUpdateSuccess !== true && leadStatusUpdateSuccess !== false) ||
      (createPaymentSuccess !== true && createPaymentSuccess !== false) ||
      (randomPaymentCollectSuccess !== true &&
        randomPaymentCollectSuccess !== false) ||
      (sessionPayRecordSuccess !== true && sessionPayRecordSuccess !== false)
    ) {
      const urlContainsNurture = window.location.href.includes("Nurture");
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage && urlContainsNurture) {
        let data = {
          branchId: !hasAllBranchAccess
            ? authUser?.institute?.[0]?.branchId
            : "",
          id: userFromLocalStorage.id,
          assigneeId:
            userById?.roleCode === "COURSE_COUNSELOR"
              ? authUser?.institute?.[0]?.id
              : "",
        };
        if (data) {
          GetCourseByCategoryByInstitute(data);
        }
      }
      // dispatch(resetLeadCreateSuccess());
      dispatch(resetLeadUpdateSuccess());
      dispatch(resetLeadLostSuccess());
    }
  }, [
    leadCreateSuccess,
    leadLostUpdateSuccess,
    leadUpdateSuccess,
    leadConvertionSuccess,
    leadStatusUpdateSuccess,
    createPaymentSuccess,
    randomPaymentCollectSuccess,
    sessionPayRecordSuccess,
  ]);

  const cdwidth = 300;
  const isBillingAdmin = authUser?.institute[0]?.isBillingAdmin;
  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;

  const closeAddBatchPopup = (type: any) => {
    setAddBatchModal(false);
  };
  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <div
        style={{
          minHeight: "calc( 100vh - 65px )",
          maxHeight: "calc( 100vh - 65px )",
          backgroundColor: "#F5F5F5",
          overflow: "hidden",
          display: "flex",
          position: "relative",
        }}
      >
        <InactiveAccountPopup
          type={accountStatus}
          billingAdmin={isBillingAdmin}
        />
        <div
          style={{
            backgroundColor: "#fff",
            transition: ".5s ease-in-out",
            minWidth: courseListDrawerOpen ? cdwidth : "25px",

            position: "relative",
          }}
          onMouseEnter={() => {
            setGridAbsolutePosition(true);
          }}
          onMouseLeave={() => {
            setGridAbsolutePosition(false);
          }}
        >
          <div
            className={styles.courseDrawerWrapper}
            style={{
              transition: ".5s ease-in-out",
              width:
                courseListDrawerOpen || gridAbsolutePosition ? cdwidth : "100%",
              height: "100%",
              position: gridAbsolutePosition ? "absolute" : "relative",
              backgroundColor: "#fff",
              zIndex: "999",
              borderRight: "1px solid rgba(177, 177, 177, 0.3)",
            }}
          >
            <button
              onClick={() => {
                toggleCourseDrawer();
                setGridAbsolutePosition(false);
              }}
              onMouseEnter={() => {
                setGridAbsolutePosition(gridAbsolutePosition ? true : false);
              }}
              className={styles.courseDrawerBtn}
            >
              <img src={courseListDrawerOpen ? rightDIcon : leftDIcon} alt="" />
            </button>
            <div
              className={styles.courseDrawerWrap}
              style={{
                overflow: "hidden",
                transition: ".5s ease-in-out",
                height: "100%",
              }}
            >
              {courseListDrawerOpen || gridAbsolutePosition ? (
                <SearchableCourseList
                  courseList={courseDataWithCategory && courseDataWithCategory}
                  handleSelectedCourse={handleSelectedCourse}
                  selectedCourse={selectedCourse}
                  setSelectedCourse={setSelectedCourse}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            transition: ".5s ease-in-out",
            width: courseListDrawerOpen
              ? `calc(100% - ${cdwidth}px)`
              : `calc(100% - 25px)`,
            display: "flex",
          }}
        >
          <Suspense
            fallback={
              <Skeleton animation="wave" variant="rounded" height={100} />
            }
          >
            {isKanbanView ? (
              <NurtureKanban
                loading={loading}
                nurtureAddEnquiry={nurtureAddEnquiry}
                branchList={branchList && branchList}
                courseList={courseList && courseList}
                batchList={batchList && batchList}
                swimlaneData={onLeadsData && leadItems}
                UpdateLeadSwimlaneStatus={UpdateLeadSwimlaneStatus}
                selectedCourse={selectedCourse}
                employees={employees && employees}
                batchTypes={courseTypes && courseTypes}
                GetLeadListByInstituteAndContact={
                  GetLeadListByInstituteAndContact
                }
                CreateNote={CreateNote}
                CreateTask={CreateTask}
                GetBatchById={GetBatchById}
                ConvertLeadToStudent={ConvertLeadToStudent}
                UpdateLeadPriority={UpdateLeadPriority}
                UpdateLeadAssignee={UpdateLeadAssignee}
                createNoteSuccess={createNoteSuccess}
                createTaskSuccess={createTaskSuccess}
                leadCreateSuccess={leadCreateSuccess}
                UpdateLeadEnquiyStatus={UpdateLeadEnquiyStatus}
                GetCourseById={GetCourseById}
                courseTypes={courseTypes && courseTypes}
                sourceList={leadBoard && leadBoard.sourceList}
                handleCompress={handleCompress}
                leadConvertionError={leadConvertionError}
                leadStatusUpdateError={leadStatusUpdateError}
                setLeadItems={setLeadItems}
                leadItems={leadItems}
                onLeadsData={onLeadsData}
                leadCreateLoading={leadCreateLoading}
                GetInstituteById={GetInstituteById}
                leadTasksloading={leadTasksloading}
                paymentListLoading={paymentListLoading}
                noteListLoading={noteListLoading}
                inboxLoading={inboxLoading}
                GetEmiDataOfLead={GetEmiDataOfLead}
                leadEmiData={leadEmiData}
                leadCreateError={leadCreateError}
                isKanbanView={isKanbanView}
                setIsKanbanView={setIsKanbanView}
                nurtureLeadStatus={nurtureLeadStatus}
                setNurtureLeadStatus={setNurtureLeadStatus}
                onLeadsLoading={onLeadsLoading}
                GetLeadsForBoard={GetLeadsForBoard}
                defaultLeadsCall={defaultLeadsCall}
                setLeadListItems={setLeadListItems}
                setleadPaginationLoad={setleadPaginationLoad}
                courseDataWithCategory={courseDataWithCategory}
                filterLeadsCall={filterLeadsCall}
                setAddBatchModal={setAddBatchModal}
              />
            ) : (
              <NurtureListView
                loading={loading}
                nurtureAddEnquiry={nurtureAddEnquiry}
                branchList={branchList && branchList}
                courseList={courseList && courseList}
                batchList={batchList && batchList}
                UpdateLeadSwimlaneStatus={UpdateLeadSwimlaneStatus}
                selectedCourse={selectedCourse}
                employees={employees && employees}
                batchTypes={courseTypes && courseTypes}
                GetLeadNoteList={GetLeadNoteList}
                GetLeadTaskList={GetLeadTaskList}
                GetLeadListByInstituteAndContact={
                  GetLeadListByInstituteAndContact
                }
                leadNoteList={leadNoteList}
                CreateNote={CreateNote}
                CreateTask={CreateTask}
                GetBatchById={GetBatchById}
                ConvertLeadToStudent={ConvertLeadToStudent}
                createNoteSuccess={createNoteSuccess}
                createTaskSuccess={createTaskSuccess}
                leadTaskList={leadTaskList}
                leadCreateSuccess={leadCreateSuccess}
                UpdateLeadEnquiyStatus={UpdateLeadEnquiyStatus}
                GetCourseById={GetCourseById}
                courseTypes={courseTypes && courseTypes}
                sourceList={leadBoard && leadBoard.sourceList}
                taskTypes={taskTypes}
                handleCompress={handleCompress}
                leadConvertionError={leadConvertionError}
                leadStatusUpdateError={leadStatusUpdateError}
                setLeadItems={setLeadItems}
                leadItems={leadItems}
                onLeadsData={onLeadsData}
                leadCreateLoading={leadCreateLoading}
                DeleteNote={DeleteNote}
                EditNote={EditNote}
                DeleteTask={DeleteTask}
                EditTask={EditTask}
                UpdateTaskStatus={UpdateTaskStatus}
                GetInstituteById={GetInstituteById}
                leadTasksloading={leadTasksloading}
                paymentListLoading={paymentListLoading}
                noteListLoading={noteListLoading}
                inboxLoading={inboxLoading}
                leadEmiData={leadEmiData}
                isKanbanView={isKanbanView}
                setIsKanbanView={setIsKanbanView}
                editTaskSuccess={editTaskSuccess}
                deleteTaskSuccess={deleteTaskSuccess}
                deleteNoteSuccess={deleteNoteSuccess}
                editNoteSuccess={editNoteSuccess}
                leadCreateError={leadCreateError}
                setNurtureLeadStatus={setNurtureLeadStatus}
                nurtureLeadStatus={nurtureLeadStatus}
                GetLeadsForBoard={GetLeadsForBoard}
                defaultLeadsCall={defaultLeadsCall}
                setLeadListItems={setLeadListItems}
                leadListItems={leadListItems}
                setleadPaginationLoad={setleadPaginationLoad}
                leadPaginationLoad={leadPaginationLoad}
                filterLeadsCall={filterLeadsCall}
                setAddBatchModal={setAddBatchModal}
              />
            )}
          </Suspense>
        </div>
      </div>
      {addBatchModal && (
        <AddBatchPopup isOpen={addBatchModal} onClose={closeAddBatchPopup} />
      )}
    </Box>
  );
}
