import { Box, Button } from "@mui/material";
import React from "react";
import styles from "./OzonetelDetails.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ozonetel from "../Assets/ozonetel.svg";
import ozoneDetailPageImg from "../Assets/ozoneDetailPageImg.svg";
import interaction from "../Assets/interaction.svg";
import management from "../Assets/management.svg";
import call from "../Assets/call.svg";
import update from "../Assets/update.svg";
import contactInfo from "../Assets/contact-info.svg";

function OzonetelDetails() {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          padding: "14px 16px",
          background: "#EEEEEE",
          maxHeight: "calc(100vh - 65px)",
          position: "relative",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            background: "#fff",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
            minHeight: "calc(100vh - 93px)",
            maxHeight: "calc(100vh - 93px)",
          }}
        >
          <div
            className={styles.ozonetelDetailsSplit}
            style={{
              minHeight: "calc(100vh - 93px)",
              maxHeight: "calc(100vh - 93px)",
            }}
          >
            <div
              style={{
                width: "calc(100% - 430px)",
              }}
            >
              <div className={styles.PageBackBtnWrap}>
                <button
                  className={styles.PageBackBtn}
                  onClick={() => navigate(`/app/Integrations`)}
                >
                  <ArrowBackIcon
                    sx={{
                      marginRight: "8px",
                      fontSize: "15PX",
                      color: "#667085",
                    }}
                  />
                  Back
                </button>
              </div>

              <div
                className={styles.ozonetelDetailsSplitLeftBody}
                style={{
                  maxHeight: "calc(100vh - 160px)",
                  overflowY: "auto",
                }}
              >
                <div>
                  <img src={ozonetel} alt="ozonetel" />
                </div>
                <Box className={styles.ozonetelHead}>Ozonetel</Box>
                <Box className={styles.ozonetelDescription}>
                  Integrate Ozonetel's cloud communication solutions into
                  nurture to elevate lead engagement, leveraging call center
                  tools and virtual phone numbers for streamlined communication.
                </Box>
                <Box>
                  <img src={ozoneDetailPageImg} alt="ozoneDetailPageImg" />
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: "capitalize", maxWidth: "150px" }}
                    fullWidth
                    onClick={() => navigate(`/app/Integrations/OzonetelStages`)}
                  >
                    Let’s Start
                  </Button>
                </div>
              </div>
            </div>

            <div
              className={styles.ozonetelDetailsSplitRight}
              style={{
                width: "430px",
                maxHeight: "calc(100vh - 94px)",
                overflowY: "auto",
              }}
            >
              <div className={styles.ozonetelDetailsSplitRightHead}>
                Key Features:
              </div>
              <ul style={{ paddingLeft: "0px", listStyle: "none" }}>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={interaction}
                      alt="interaction"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      Effortless Integration
                    </span>
                    <br />
                    nurture seemlessly integrates with Ozonetel, automating lead
                    capture to prevent missed opportunities.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={management}
                      alt="management"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      Enhanced Lead Management
                    </span>
                    <br />
                    Create unified leads card by consolidating data from
                    Ozonetel's communication tools directly into nurture.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={call}
                      alt="call"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      Smart Call Routing
                    </span>
                    <br />
                    Increase efficiency with intelligent call routing within
                    nurture, directing leads to the right agents based on
                    predefined criteria.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={update}
                      alt="update"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>Real-time Updates</span>{" "}
                    <br />
                    Keep your team informed with real-time updates on lead
                    interactions, enabling timely responses and informed
                    decision-making within the nurture.
                  </div>
                </li>
                <li className={styles.keyFeaturesListing}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={contactInfo}
                      alt="contactInfo"
                      style={{ marginRight: "10px" }}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      Comprehensive Communication History
                    </span>
                    <br />
                    Access a holistic view of lead interactions across various
                    communication channels through a comprehensive communication
                    history within nurture.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default OzonetelDetails;
