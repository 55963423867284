import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useCallback, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from "../../Dashboard.module.css";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import noResults from "../../../../../assets/images/no-results.svg";

function LeadSourceData(props: any) {
  const { leadSourceData, LeadSourceGraphApiCall } = props;
  const auth = useAuthUser();
  const user = auth();
  const employees = useSelector((state: any) => state.staff.staffList);

  const [sourceData, setSourceData] = React.useState<any>(null);
  const [leadGraphDuration, setLeadGraphDuration] = useState<number>(7);
  const handleChange = useCallback(
    (event: any) => {
      setLeadGraphDuration(event.target.value);
      const instituteId = user?.institute[0]?.instituteId;
      let branchId: any;
      const queryParams = new URLSearchParams(window.location.search);
      const branchParam = queryParams.get("selectedBranch");
      if (branchParam) {
        branchId = branchParam;
      } else if (user?.institute?.[0]?.branchId) {
        branchId = user?.institute?.[0]?.branchId;
      } else {
        const loginTeamember = employees?.find(
          (item: any) => item?.email === user?.email
        );
        if (loginTeamember) {
          branchId = loginTeamember?.branchId;
        }
      }
      queryParams.set("leadSourceTimeline", event.target.value);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);

      LeadSourceGraphApiCall(instituteId, event.target.value, branchId);
    },
    [LeadSourceGraphApiCall]
  );

  function calculatePercentage(
    count: number,
    totalCount: number,
    byValue: number
  ) {
    if (totalCount === 0) {
      return "0%";
    }

    const percentage = (count / totalCount) * byValue;

    return `${percentage}%`;
  }
  React.useEffect(() => {
    if (leadSourceData) {
      if (leadSourceData !== null) {
        setSourceData(leadSourceData);
      } else {
        setSourceData(null);
      }
    }
  }, [leadSourceData]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const leadSourceParam = queryParams.get("leadSourceTimeline");

    if (leadSourceParam) {
      setLeadGraphDuration(parseInt(leadSourceParam));
    } else {
      setLeadGraphDuration(7);
    }
  }, []);

  return (
    <div className={styles.leadSourceDataBox}>
      <div className={styles.leadSourceDataTop}>
        <div className={styles.leadSourceDataTitle}>Lead Source</div>
        <div className={styles.leadSourceDataSelect}>
          <FormControl size="small">
            <Select
              id="demo-simple-select"
              value={leadGraphDuration}
              onChange={handleChange}
              sx={{
                width: "120px",
                "& .MuiSelect-outlined": {
                  fontSize: "14px",
                  color: "#667085",
                  fontFamily: "poppins",
                  borderRadius: "4px",
                },
                "&& fieldset": {
                  color: "#667085",
                  borderColor: "rgba(217, 226, 234, 0.70)",
                  borderRadius: "4px",
                  fontFamily: "poppins",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "4px !important",
                },
              }}
              IconComponent={() => (
                <KeyboardArrowDownIcon sx={{ color: "#CCCCCC" }} />
              )}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                    borderRadius: "4px",
                    border: "1px solid  #E4E4E7",
                    fontSize: "13PX",
                  },
                },
                style: {
                  //   zIndex: 999999,
                  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                  border: "1px solid  #E4E4E7",
                  fontSize: "13PX",
                },
              }}
            >
              <MenuItem value={7} sx={{ fontSize: "13PX", color: "667085" }}>
                7 days
              </MenuItem>
              <MenuItem value={15} sx={{ fontSize: "13PX", color: "667085" }}>
                15 days
              </MenuItem>
              <MenuItem value={30} sx={{ fontSize: "13PX", color: "667085" }}>
                30 days
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={styles.leadSourceDataMain}>
        <div className={styles.leadSourceDataMainLeft}>
          <div className={styles.leadSourceDataMainCount}>
            {sourceData?.totalLeadCount}
          </div>
          <div className={styles.leadSourceDataMainLabel}>Total Leads</div>
        </div>
        <div className={styles.leadSourceDataMainRight}>
          <div className={styles.leadSourceDataType}>
            <div
              className={styles.leadSourceDataTypeColor}
              style={{ background: "#11C89B" }}
            ></div>
            <div className={styles.leadSourceDataTypeLabel}>Converted</div>
          </div>
          <div className={styles.leadSourceDataType}>
            <div
              className={styles.leadSourceDataTypeColor}
              style={{ background: "#EC3939" }}
            ></div>
            <div className={styles.leadSourceDataTypeLabel}>Lost</div>
          </div>
          <div className={styles.leadSourceDataType}>
            <div
              className={styles.leadSourceDataTypeColor}
              style={{ background: "#F8BB6C" }}
            ></div>
            <div className={styles.leadSourceDataTypeLabel}>Others</div>
          </div>
        </div>
      </div>
      <div style={{ height: "280px", overflowY: "auto", paddingRight: "3px" }}>
        {sourceData?.totalLeadCount === 0 ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                margin: "auto",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <img src={noResults} alt="noResults" />
              <div
                style={{
                  fontSize: "13px",
                  color: "#667085",
                  fontWeight: "500",
                  marginTop: "12PX",
                }}
              >
                No data available
              </div>
            </div>
          </>
        ) : (
          <>
            {sourceData?.leadSourceGraphData?.map((item: any, index: any) => (
              <div className={styles.leadSourceDataListItem} key={index}>
                <div className={styles.leadSourceDataListItemTop}>
                  <div className={styles.leadSourceDataListItemSource}>
                    {item?.type}
                  </div>
                  <div className={styles.leadSourceDataListItemCount}>
                    {item?.totalLeads}
                  </div>
                </div>
                <div className={styles.leadSourceDataListItemStripWrap}>
                  <div
                    className={styles.leadSourceDataListItemStripGreen}
                    style={{
                      width: calculatePercentage(
                        item?.convertedLeads,
                        item?.convertedLeads + item?.lostLeads + item?.other,
                        item?.convertedLeads > 0 &&
                          item?.lostLeads > 0 &&
                          item?.other > 0
                          ? 98
                          : (item?.convertedLeads > 0 &&
                              item?.lostLeads > 0 &&
                              item?.other === 0) ||
                            (item?.convertedLeads > 0 &&
                              item?.lostLeads === 0 &&
                              item?.other > 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads > 0 &&
                              item?.other > 0)
                          ? 99
                          : (item?.convertedLeads > 0 &&
                              item?.lostLeads === 0 &&
                              item?.other === 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads === 0 &&
                              item?.other > 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads > 0 &&
                              item?.other === 0)
                          ? 100
                          : 100
                      ),
                      display: item?.convertedLeads > 0 ? "block" : "none",
                      borderTopRightRadius:
                        item?.lostLeads === 0 && item?.other === 0
                          ? "5px"
                          : "0PX",
                      borderBottomRightRadius:
                        item?.lostLeads === 0 && item?.other === 0
                          ? "5px"
                          : "0PX",
                    }}
                  ></div>
                  <div
                    className={styles.leadSourceDataListItemStripRed}
                    style={{
                      width: calculatePercentage(
                        item?.lostLeads,
                        item?.convertedLeads + item?.lostLeads + item?.other,
                        item?.convertedLeads > 0 &&
                          item?.lostLeads > 0 &&
                          item?.other > 0
                          ? 98
                          : (item?.convertedLeads > 0 &&
                              item?.lostLeads > 0 &&
                              item?.other === 0) ||
                            (item?.convertedLeads > 0 &&
                              item?.lostLeads === 0 &&
                              item?.other > 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads > 0 &&
                              item?.other > 0)
                          ? 99
                          : (item?.convertedLeads > 0 &&
                              item?.lostLeads === 0 &&
                              item?.other === 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads === 0 &&
                              item?.other > 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads > 0 &&
                              item?.other === 0)
                          ? 100
                          : 100
                      ),
                      display: item?.lostLeads > 0 ? "block" : "none",
                      borderTopLeftRadius:
                        item?.convertedLeads === 0 ? "5px" : "0PX",
                      borderBottomLeftRadius:
                        item?.convertedLeads === 0 ? "5px" : "0PX",
                      borderTopRightRadius: item?.other === 0 ? "5px" : "0PX",
                      borderBottomRightRadius:
                        item?.other === 0 ? "5px" : "0PX",
                    }}
                  ></div>
                  <div
                    className={styles.leadSourceDataListItemStripOrange}
                    style={{
                      width: calculatePercentage(
                        item?.other,
                        item?.convertedLeads + item?.lostLeads + item?.other,
                        item?.convertedLeads > 0 &&
                          item?.lostLeads > 0 &&
                          item?.other > 0
                          ? 98
                          : (item?.convertedLeads > 0 &&
                              item?.lostLeads > 0 &&
                              item?.other === 0) ||
                            (item?.convertedLeads > 0 &&
                              item?.lostLeads === 0 &&
                              item?.other > 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads > 0 &&
                              item?.other > 0)
                          ? 99
                          : (item?.convertedLeads > 0 &&
                              item?.lostLeads === 0 &&
                              item?.other === 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads === 0 &&
                              item?.other > 0) ||
                            (item?.convertedLeads === 0 &&
                              item?.lostLeads > 0 &&
                              item?.other === 0)
                          ? 100
                          : 100
                      ),
                      display: item?.other > 0 ? "block" : "none",
                      borderTopLeftRadius:
                        item?.convertedLeads === 0 && item?.lostLeads === 0
                          ? "5px"
                          : "0PX",
                      borderBottomLeftRadius:
                        item?.convertedLeads === 0 && item?.lostLeads === 0
                          ? "5px"
                          : "0PX",
                    }}
                  ></div>
                </div>
                <div className={styles.leadSourceDataListItemLeadStage}>
                  <div className={styles.leadSourceDataListItemLeadStageItem}>
                    Converted {item?.convertedLeads}
                  </div>
                  <div className={styles.leadSourceDataListItemLeadStageItem}>
                    Lost {item?.lostLeads}
                  </div>
                  <div className={styles.leadSourceDataListItemLeadStageItem}>
                    Others {item?.other}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default LeadSourceData;
