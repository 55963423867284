import React from "react";
import styles from "./SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function ProfileBannerSkeleton() {
  return (
    <div className={styles.skeletonBannerWrap}>
      <div className={styles.skeletonBannerImg}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={110}
        />
      </div>
      <div className={styles.skeletonProfileDetailsWrap}>
        <Skeleton
          animation="wave"
          variant="text"
          width="95px"
          height={23}
          style={{ marginBottom: "20px" }}
        />
        <div
          className={styles.skeletonProfileMainDetailsWrap}
          style={{ marginBottom: "10px" }}
        >
          <Skeleton animation="wave" variant="text" width="150px" height={23} />
          <Skeleton animation="wave" variant="text" width="150px" height={23} />
          <Skeleton animation="wave" variant="text" width="150px" height={23} />
        </div>
        <div className={styles.skeletonProfileMainDetailsWrap}>
          <Skeleton animation="wave" variant="text" width="150px" height={23} />
          <Skeleton animation="wave" variant="text" width="150px" height={23} />
          <Skeleton animation="wave" variant="text" width="150px" height={23} />
        </div>
      </div>
    </div>
  );
}

export default ProfileBannerSkeleton;
