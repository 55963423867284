import { RootState, TypedDispatch } from "../../../../../redux/store";
import { connect } from "react-redux";

import {
  createWebform,
  getAllWebForm,
  getStudentContactById,
  getWebformByInstitute,
  getWebformResponse,
  updateWebform,
} from "../service/webformSlice";
import {
  WebformInitialStateDTO,
  WebformDTO,
  WebformResponseInitialStateDTO,
} from "../service/types";
import Webform from "../components/Webform";
import { getContactList } from "../../service/inboundSlice";
import WebFormResponse from "../components/WebFormResponse/WebFormResponse";
import { CreateSourceDTO, NurtureLead } from "../../../Nurture/service/types";
import {
  createSource,
  getLeadListByInstituteAndContact,
  getNurtureLeadBoard,
  nurtureLead,
} from "../../../Nurture/service/nurtureSlice";
import { getBranchList } from "../../../Manage/ManageBranch/service/Branchslice";
import { getBatchList } from "../../../Manage/ManageBatch/service/Batchslice";
import { getAllCourseByInstituteRequest } from "../../../Manage/ManageCourse/service/courseSlice";

interface StateProps extends WebformResponseInitialStateDTO {}

interface DispatchProps {
  // GetFormResponse(
  //   id: string,
  //   lastEvaluatedKey: string,
  //   limit: number,
  //   lastEvaluatedSortKey: string,
  //   formId: string,
  //   roleInfo: any
  // ): void;
  GetFormResponse(data: string): void;
  GetWebformForInstitute(instituteId: string, webformId: string): void;
  CreateEnquiry(data: NurtureLead): void;
  GetNurtureLeadBoard(id: string): void;
  GetBranchList(id: string): void;
  GetCourseList(id: string): void;
  GetBatchList(data: any): void;
  GetLeadListByInstituteAndContact(data: any): void;
  CreateSource(data: CreateSourceDTO): void;
  GetStudentContactById(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    getFormResponseSuccess: state.webform.getFormResponseSuccess,
    getFormResponseLoading: state.webform.getFormResponseLoading,
    getFormResponseError: state.webform.getFormResponseError,
    getFormResponseData: state.webform.getFormResponseData,
    getFormByInstituteData: state.webform.getFormByInstituteData,
    getStudentContactByIdData: state.webform.getStudentContactByIdData,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    // GetFormResponse: (
    //   id: string,
    //   lastEvaluatedKey: string,
    //   limit: number,
    //   lastEvaluatedSortKey: string,
    //   formId: string,
    //   roleInfo: any
    // ) => {
    //   dispatch(
    //     getWebformResponse({
    //       id,
    //       lastEvaluatedKey,
    //       limit,
    //       lastEvaluatedSortKey,
    //       formId,
    //       roleInfo,
    //     })
    //   );
    // },
    GetFormResponse: (data: string) => {
      dispatch(getWebformResponse(data));
    },
    GetWebformForInstitute: (instituteId: string, webformId: string) => {
      dispatch(getWebformByInstitute({ instituteId, webformId }));
    },
    CreateEnquiry: (data: NurtureLead) => {
      dispatch(nurtureLead(data));
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
    GetBatchList: (data: any) => {
      dispatch(getBatchList(data));
    },
    GetCourseList: (id: string) => {
      dispatch(getAllCourseByInstituteRequest(id));
    },
    GetNurtureLeadBoard: (id: string) => {
      dispatch(getNurtureLeadBoard(id));
    },
    GetLeadListByInstituteAndContact: (data: any) => {
      dispatch(getLeadListByInstituteAndContact(data));
    },
    CreateSource: (data: CreateSourceDTO) => {
      dispatch(createSource(data));
    },
    GetStudentContactById: (id: string) => {
      dispatch(getStudentContactById(id));
    },
  };
};
export interface EmailListProps extends StateProps, DispatchProps {}
export const WebformResponseComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFormResponse);
