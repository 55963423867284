import React, { useState } from "react";
import {
  Dialog,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import styles from "./TaxInvoices.module.css";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";

function EditGst(props: any) {
  const { onCancelEdit, UpdateStudentInvoiceSettings } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { invoiceSettings, invoiceSettingUpdateLoading } = useSelector(
    (state: any) => state.appSettings
  );
  const [gstin, setGstin] = useState("");
  const [gstError, setGstError] = React.useState<boolean>(false);

  const gstNumberRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
  const handleCancel = () => {
    onCancelEdit();
  };

  const handleChange = React.useCallback((event: any) => {
    setGstin(event.target.value);
    if (!gstNumberRegex.test(event.target.value)) {
      setGstError(true);
    } else {
      setGstError(false);
    }
  }, []);

  const handleSaveClick = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        nextNumber: invoiceSettings?.nextNumber,
        prefix: invoiceSettings?.prefix,
        gstNumber: gstin,
        isStudentTaxInvoiceEnabled: true,
      };
      UpdateStudentInvoiceSettings(body);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (invoiceSettings) {
      setGstin(invoiceSettings?.gstNumber);
    }
  }, [invoiceSettings]);

  return (
    <Box>
      <div className={styles.editGst}>Edit GSTIN</div>
      <div className={styles.gstIn}>
        GSTIN <span style={{ color: "#EC3939", fontWeight: "400" }}>*</span>
      </div>
      <TextField
        sx={{
          width: "80%",
          "& .MuiOutlinedInput-input": {
            padding: "11px",
          },
        }}
        value={gstin}
        error={gstError}
        helperText={gstError ? "Invalid GST Number" : ""}
        onChange={handleChange}
      />
      <Box sx={{ marginTop: "24px", display: "flex", gap: "12px" }}>
        <Button
          sx={{
            color: "#BFBFBF",
            border: "1px solid #BFBFBF",
            background: "#FFF",
            textTransform: "capitalize",
          }}
          variant="outlined"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          onClick={handleSaveClick}
          disabled={invoiceSettingUpdateLoading || gstError}
        >
          {invoiceSettingUpdateLoading ? (
            <CircularProgress size={20} color={"inherit"} />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
}
export default EditGst;
