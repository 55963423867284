import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import {
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import { resetLeadModalState } from "../../../Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { GetInstituteByIdRequest } from "../../../Manage/InstituteProfile/service/institute.request";
import { useAuthUser } from "react-auth-kit";
import UserLimitPopup from "../../../Manage/ManageStaff/StaffListing/UserLimitPopup";
import BranchLimitPopup from "../../../Manage/ManageBranch/BranchListing/BranchLimitPopup";
import AddBatchPopup from "../../../Manage/ManageBatch/BatchEditor/AddBatchPopup";
import AddCoursePopup from "../../../Manage/ManageCourse/components/AddCoursePopup";
import { GetCourseConfig } from "../../../Manage/ManageCourse/service/courseConfigSlice";

const menuLink = {
  fontSize: "13px",
  color: "#142C44",
  padding: "6px 8px",
  lineHeight: "22px",
  fontFamily: "poppins",
  borderRadius: "2px",
  "&:hover": {
    color: "#fff",
    bgcolor: "#11C89B",
    fontWeight: "500",
  },
};

function AddNewMenu() {
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  // const courseLavel = useSelector(
  //   (state: any) => state.courseConfig.courseConfigs.courseLevel
  // );
  const { leadBoard } = useSelector((state: any) => state.nurture);
  const courseLevel = leadBoard?.courseLevel;
  console.log("courseLevel", courseLevel);
  const branchAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BRANCHES",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const courseAccess = getSubDomainAccessStatus(
    "MANAGE",
    "COURSES",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const teamAccess = getSubDomainAccessStatus(
    "MANAGE",
    "TEAMS",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const batchAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BATCHES",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const billingAccess = checkSubDomainAccess(
    "MANAGE",
    "BILLING",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const instituteDataFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;
  const [addBatchModal, setAddBatchModal] = React.useState<boolean>(false);
  const [addCourseModal, setAddCourseModal] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>();

  const branchLicenseCount = data && data?.totalBranchLicense;
  const branchCount = data && data?.branchCount;

  const userLicenseCount = data && data?.totalUserLicense;
  const userCount = data && data?.teamCount;
  const [userLimitPopup, setUserLimitPopup] = React.useState<boolean>(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl1);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl1(null);
    dispatch(resetLeadModalState());
  };

  // useEffect(() => {
  //   dispatch<any>(GetCourseConfig());
  // }, [addCourseModal]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const closeUserLimitPopup = () => {
    setUserLimitPopup(false);
  };

  const openUserLimitPopup = () => {
    setUserLimitPopup(true);
  };

  const [branchLimitPopup, setBranchLimitPopup] =
    React.useState<boolean>(false);

  const closeBranchLimitPopup = () => {
    setBranchLimitPopup(false);
  };

  const openBranchLimitPopup = () => {
    setBranchLimitPopup(true);
  };

  const openAddBatchPopup = () => {
    setAddBatchModal(true);
  };
  const closeAddBatchPopup = (type: any) => {
    setAddBatchModal(false);
  };

  const openAddCoursePopup = () => {
    setAddCourseModal(true);
  };

  const closeAddCoursePopup = (type: any) => {
    setAddCourseModal(false);
  };

  const menuItems = [
    {
      label: "Course",
      onClick: () => {
        openAddCoursePopup();
        handleClose();
      },
      access: courseAccess,
    },
    {
      label: "Batch",
      onClick: () => {
        openAddBatchPopup();
        handleClose();
      },
      access: batchAccess,
    },
    {
      label: "Branch",
      onClick: () => {
        if (branchLicenseCount > branchCount) {
          window.location.href = "/app/manage/CreateBranch";
        } else {
          openBranchLimitPopup();
        }
        handleClose();
      },
      access: branchAccess,
    },
    {
      label: "Team",
      onClick: () => {
        if (userLicenseCount > userCount) {
          window.location.href = "/app/manage/CreateTeam";
        } else {
          openUserLimitPopup();
        }
        handleClose();
      },
      access: teamAccess,
    },
  ];

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    if (open) {
      GetInstituteByIdRequest(userFromLocalStorage.id)
        .then((res) => {
          let result = res?.data?.data;
          setData(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open]);

  return (
    <>
      {accountStatus === "ACTIVE" || accountStatus === "GRACE_PERIOD" ? (
        <>
          {instituteDataFromRedux?.isProfileCompleted && (
            <Box
              sx={{
                display: { md: "flex" },
                alignItems: "center",
              }}
            >
              <Tooltip
                arrow
                title={
                  courseAccess === false &&
                  branchAccess === false &&
                  teamAccess === false &&
                  batchAccess === false
                    ? "Oops! No permission."
                    : ""
                }
                PopperProps={{ style: { zIndex: 9999999 } }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#101828",
                      color: "#fff",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "8px 12px",
                      "& .MuiTooltip-arrow": {
                        color: "#101828",
                      },
                    },
                  },
                }}
              >
                <span
                  style={{
                    width: "100%",
                    display: "block",
                    textAlign: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      marginRight: "10px",
                      marginLeft: "10px",
                      textTransform: "capitalize",
                      padding: "6px 18px",
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ background: isHovered ? "#E8FAF5" : "" }}
                    disabled={
                      courseAccess === false &&
                      branchAccess === false &&
                      teamAccess === false &&
                      batchAccess === false
                    }
                  >
                    Add New
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl1}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          marginTop: "5px",
          padding: "2px 2px",
          "& .MuiList-root": {
            padding: "2px 2px",
          },
          "& .MuiPopover-root": {
            zIndex: "999999",
          },
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
            borderRadius: "4PX",
            border: "1px solid #EEE",
            minWidth: "108PX",
          },
        }}
      >
        {menuItems
          ?.filter((item) => item.access)
          ?.map((item, index) => (
            <MenuItem key={index} onClick={item.onClick} sx={menuLink}>
              {item.label}
            </MenuItem>
          ))}
      </Menu>
      <UserLimitPopup
        isOpen={userLimitPopup}
        onClose={closeUserLimitPopup}
        isBillingAdmin={billingAccess}
        handleClose={closeUserLimitPopup}
      />
      <BranchLimitPopup
        isOpen={branchLimitPopup}
        onClose={closeBranchLimitPopup}
        isBillingAdmin={billingAccess}
        handleClose={closeBranchLimitPopup}
      />
      {addCourseModal && (
        <AddCoursePopup isOpen={addCourseModal} onClose={closeAddCoursePopup} />
      )}

      {addBatchModal && (
        <AddBatchPopup isOpen={addBatchModal} onClose={closeAddBatchPopup} />
      )}
    </>
  );
}

export default AddNewMenu;
