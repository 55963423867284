import {
  Box,
  CssBaseline,
  Drawer,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/Nurture Logo.png";
import smallLogo from "../../../assets/images/smallLogo.svg";
import AppRouter from "../../../router/AppRouter";
import { SideBar } from "./../../../components/SideBar/SideBar";
import AppHeader from "./AppHeader";
import styles from "./Home.module.css";
import selectedHomeIcon from "../../../assets/images/selectedHomeIcon.svg";
import unselectedHomeIcon from "../../../assets/images/unselectedHomeIcon.svg";
import selectedInboundIcon from "../../../assets/images/selectedInboundIcon.svg";
import unselectedInboundIcon from "../../../assets/images/unselectedInboundIcon.svg";
import selectedReportIcon from "../../../assets/images/selectedReportIcon.svg";
import unselectedReportIcon from "../../../assets/images/unselectedReportIcon.svg";
import selectedNurtureIcon from "../../../assets/images/selectedNurtureIcon.svg";
import unselectedNurtureIcon from "../../../assets/images/unselectedNurtureIcon.svg";
import selectedTaskIcon from "../../../assets/images/selectedTaskIcon.svg";
import unselectedTaskIcon from "../../../assets/images/unselectedTaskIcon.svg";
import navBarButton from "../../../assets/images/navBarButton.svg";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useMediaQuery, useTheme } from "@mui/material";
import selectedIntegrationIcon from "../../../assets/images/selectedIntegrationIcon.svg";
import unselectedIntegrationIcon from "../../../assets/images/unselectedIntegrationIcon.svg";
import selectedStudent from "../../../assets/images/studentActive.svg";
import unselectedStudent from "../../../assets/images/studentInactive.svg";
import selectedSettingsIcon from "../../../assets/images/selectedSettingsIcon.svg";
import unselectedSettingsIcon from "../../../assets/images/unselectedSettingsIcon.svg";
import getAccessStatus, {
  checkAccess,
  checkSubDomainAccess,
  getSubDomainAccessStatus,
} from "../../../components/RolePermissionAccess/RolePermissionAccess";
import LeadPopup from "../../../components/LeadPopup/LeadPopup";
import MainLoader from "../../../components/MainLoader/MainLoader";
import attendanceSelected from "../../../assets/images/attendanceSelected.svg";
import { useDispatch } from "react-redux";
import { appEntery } from "../Home/service/homeSlice";
import { setCompress, toggleCompresser } from "./service/compressorSlice";
import attendanceNotSelected from "../../../assets/images/attendanceNotSelected.svg";

const drawerWidth = 200;
const compressDrawerWidth = 70;
const appVersion = process.env.REACT_APP_VERSION;
const appEnv = process.env.REACT_APP_ENV;

export default function Main(props: any) {
  const auth = useAuthUser();
  const authUser = auth();
  const theme = useTheme();
  const isTabSize = useMediaQuery(theme.breakpoints.down("md"));
  const loggedIn = useSelector((state: any) => state?.activeInstitute?.success);
  const { leadModalState } = useSelector((state: any) => state.nurture);
  const userFromLocalStorage = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );
  const { batchCreateSuccess } = useSelector((state: any) => state.batch);

  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [menuData, setMenuData] = React.useState<string>("Home");
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [isActive, setIsActive] = useState(0);
  const dispatch = useDispatch();
  const isCompress = useSelector((state: any) => state.compress.isCompress);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const isMobile = width <= 500;
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const sideBarNavItem = [
    {
      text: "Home",
      icon: isActive === 0 ? selectedHomeIcon : unselectedHomeIcon,
      to:
        userFromLocalStorage?.isProfileCompleted ||
        userFromLocalStorage?.instituteDetails?.isProfileCompleted
          ? "dashboard"
          : "home",
      color: isActive === 0 ? "#11C89B" : "#8A94A6",
      access: true,
    },
    {
      text: "Inbound",
      icon: isActive === 1 ? selectedInboundIcon : unselectedInboundIcon,
      to: getSubDomainAccessStatus(
        "INBOUND",
        "CONTACTS",
        "VIEW_ONLY",
        // userRoleById?.permissions
        userById?.rolePermission
      )
        ? "Inbound"
        : getSubDomainAccessStatus(
            "INBOUND",
            "EMAIL",
            "VIEW_ONLY",
            // userRoleById?.permissions
            userById?.rolePermission
          )
        ? "Inbound/Email"
        : getSubDomainAccessStatus(
            "INBOUND",
            "IMPORT",
            "VIEW_ONLY",
            // userRoleById?.permissions
            userById?.rolePermission
          )
        ? "Inbound/Import"
        : getSubDomainAccessStatus(
            "INBOUND",
            "WEBFORMS",
            "VIEW_ONLY",
            // userRoleById?.permissions
            userById?.rolePermission
          )
        ? "Inbound/Webforms"
        : getSubDomainAccessStatus(
            "INBOUND",
            "ZAPIER",
            "VIEW_ONLY",
            // userRoleById?.permissions
            userById?.rolePermission
          )
        ? "Inbound/Zapier"
        : "Inbound",
      color: isActive === 1 ? "#11C89B" : "#8A94A6",
      access: checkAccess("INBOUND", userById?.rolePermission),
    },
    {
      text: "Nurture",
      icon: isActive === 2 ? selectedNurtureIcon : unselectedNurtureIcon,
      to: "Nurture",
      color: isActive === 2 ? "#11C89B" : "#8A94A6",
      access: checkAccess("NURTURE_BOARD", userById?.rolePermission),
    },
    {
      text: "Tasks",
      icon: isActive === 3 ? selectedTaskIcon : unselectedTaskIcon,
      to: getAccessStatus(
        "TASKS",
        "ALL_OTHER_USERS'_TASK",
        // userRoleById?.permissions
        userById?.rolePermission
      )
        ? `Tasks?selectedUsers=${authUser?.institute?.[0]?.id}`
        : `Tasks`,
      color: isActive === 3 ? "#11C89B" : "#8A94A6",
      access: true,
    },
    {
      text: "Students",
      icon: isActive === 4 ? selectedStudent : unselectedStudent,
      to: "Students",
      color: isActive === 4 ? "#11C89B" : "#8A94A6",
      access: checkAccess("STUDENTS", userById?.rolePermission),
    },
    {
      text: "Attendance",
      icon: isActive === 5 ? attendanceSelected : attendanceNotSelected,
      to: "Attendance",
      color: isActive === 5 ? "#11C89B" : "#8A94A6",
      access: checkSubDomainAccess(
        "MANAGE",
        "BATCHES",
        // userRoleById?.permissions
        userById?.rolePermission
      ),
    },
    // {
    //   text: "Reports",
    //   icon: isActive === 6 ? selectedReportIcon : unselectedReportIcon,
    //   to: "Reports",
    //   color: isActive === 6 ? "#11C89B" : "#8A94A6",
    //   access:
    //     authUser?.institute[0]?.role === "Super Admin" ||
    //     authUser?.institute[0]?.role === "Admin",
    // },
    {
      text: "Integrations",
      icon:
        isActive === 6 ? selectedIntegrationIcon : unselectedIntegrationIcon,
      to: "Integrations",
      color: isActive === 6 ? "#11C89B" : "#8A94A6",
      access: checkAccess("INTEGRATIONS", userById?.rolePermission),
    },

    {
      text: "Settings",
      icon: isActive === 7 ? selectedSettingsIcon : unselectedSettingsIcon,
      to: "Settings",
      color: isActive === 7 ? "#11C89B" : "#8A94A6",
      access: checkAccess("SETTINGS", userById?.rolePermission),
    },
  ];
  const toggleCompress = () => {
    dispatch<any>(toggleCompresser());
  };

  const handleCompress = React.useCallback(
    (item: boolean) => {
      setCompress(item);
    },
    [setCompress]
  );

  // useEffect(() => {
  //   const user = authUser?.institute[0]?.instituteDetails;
  //   if (user) {
  //     dispatch<any>(appEntery(user && user.id));
  //   }
  // }, [batchCreateSuccess]);

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);
  const drawer = (
    <div>
      <Toolbar />
      <List style={{ color: "#ffffff" }}>
        {sideBarNavItem.map((item, index) => (
          <SideBar
            key={Math.random()}
            setIsActive={setIsActive}
            index={index}
            setMenuData={setMenuData}
            isActive={isActive}
            item={item}
            isCompress={isCompress}
            itemAccess={item?.access}
          />
        ))}
      </List>
    </div>
  );

  useEffect(() => {
    if (window.location.href.includes("Nurture")) {
      setIsActive(2);
      setCompress(true);
    } else {
      setCompress(false);
    }
  }, []);

  useEffect(() => {
    const { href } = window.location;
    if (
      href.includes("inbound") ||
      href.includes("Inbound/EmailIntegration/EmailSyncing") ||
      href.includes("Inbound/Contacts") ||
      href.includes("Inbound/Email") ||
      href.includes("Inbound/Webforms")
    ) {
      setIsActive(1);
    } else if (href.includes("tasks")) {
      setIsActive(3);
    } else if (href.includes("students")) {
      setIsActive(4);
    } else if (href.includes("Attendance")) {
      setIsActive(5);
    }
    // else if (href.includes("Reports")) {
    //   setIsActive(6);
    // }
    else if (href.includes("Integrations")) {
      setIsActive(6);
    } else if (
      href.includes("app/Settings") ||
      href.includes("Settings/Invoices") ||
      href.includes("Settings/Notifications") ||
      href.includes("Settings/EmailTemplates")
    ) {
      setIsActive(7);
    } else if (href.includes("manage") || href.includes("dashboard")) {
      setIsActive(0);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (isTabSize) {
      setCompress(true);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />

      <Box
        className={styles.menuDrawer}
        component="nav"
        sx={{
          width: {
            sm: isTabSize
              ? compressDrawerWidth
              : !isCompress
              ? drawerWidth
              : compressDrawerWidth,
          },

          position: "relative",
          flexShrink: { sm: 0 },
          backgroundColor: "#1A3235",
          transition: ".3s ease-in-out",
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          PaperProps={{ sx: { backgroundColor: "#142C44" } }}
          variant={"permanent"}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: !isCompress ? drawerWidth : compressDrawerWidth,
              transition: ".3s ease-in-out",
              display: "flex",
              justifyContent: "space-between",
            },
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {!isCompress ? (
            <Box className={styles.logo_box}>
              <img src={logo} alt="" />
            </Box>
          ) : (
            <Box className={styles.logo_box_small} sx={{ ml: "7px" }}>
              <img src={smallLogo} height="32" width="32" alt="" />
            </Box>
          )}

          {drawer}

          {appEnv !== "prod" && (
            <Typography
              variant="h6"
              sx={{
                fontSize: 10,
                color: "#fff",
                paddingLeft: "10px",
              }}
            >
              {appEnv}
              {appVersion}
            </Typography>
          )}
        </Drawer>
        <button
          className={
            isCompress
              ? styles.mainSidebarCompressBtnisCompress
              : styles.mainSidebarCompressBtn
          }
          onClick={toggleCompress}
          style={{ transition: ".3s ease-in-out" }}
        >
          <img src={navBarButton} height="30" width="30" alt="" />
        </button>
      </Box>

      <Box sx={{ flexGrow: 1, overflowX: "hidden" }}>
        <AppHeader
          handleDrawerToggle={handleDrawerToggle}
          name={menuData}
          loggedIn={loggedIn}
        />
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            maxHeight: "calc(100vh - 65px)",
            minHeight: "calc(100vh - 65px)",
            position: "relative",
          }}
        >
          {/* {userById === null && <MainLoader />} */}

          {userById === null ? (
            <MainLoader />
          ) : (
            <>
              {leadModalState && <LeadPopup />}
              <AppRouter handleCompress={handleCompress} />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
