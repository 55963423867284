import React from "react";
import styles from "./CompletedCard.module.css";
import High from "../../components/Assets/High.svg";
import Normal from "../../components/Assets/Normal.svg";
import Low from "../../components/Assets/Low.svg";
import Urgent from "../../components/Assets/Urgent.svg";
import moment from "moment";
import { useNavigate } from "react-router";
import { setToastNotification } from "../../../../../components/ToastNotification/service/toastNotificationSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { checkSubDomainAccess } from "../../../../../components/RolePermissionAccess/RolePermissionAccess";
import getTeamMember from "../../../../../components/CommonFunctions/CommonFunctions";
import { getDescriptionWithMentionedNames } from "../../../../../components/CommonFunctions/CommonFunctions";

function CompletedCard(props: any) {
  const {
    taskCompletedPage,
    task,
    expandDisabled,
    employees,
    openLeadExpandModal,
    page,
    handleDragStart,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasLeadCardPermission = checkSubDomainAccess(
    "NURTURE_BOARD",
    "LEAD_CARD",
    // userRoleById?.permissions
    userById?.rolePermission
  );

  const modifiedDescription = getDescriptionWithMentionedNames(
    task?.description,
    employees
  );
  const demoPageNavigate = () => {
    if (page === "COMPLETED_TASKS") {
      const currentUrl = window.location.href;
      const pathAfterTaskCompleted = currentUrl.split("Tasks")[1];
      navigate(`/app/Tasks/DemoTaskDetails/${task.id}`, {
        state: { fromCompletedPageUrl: pathAfterTaskCompleted },
      });
    } else {
      navigate(`/app/Tasks/DemoTaskDetails/${task.id}`);
    }
  };

  return (
    <div
      className={styles.taskCompletedCard}
      key={task.id}
      draggable={page === "TASK_BOARD" ? true : false}
      onDragStart={(event) => {
        if (page === "TASK_BOARD") {
          handleDragStart(event, "COMPLETED", task.id, task.taskType, task);
        }
      }}
      onClick={() => {
        if (!expandDisabled) {
          task.taskType === "DEMO"
            ? demoPageNavigate()
            : hasLeadCardPermission
            ? openLeadExpandModal(
                task?.instituteId
                  ? task?.instituteId
                  : task?.enquiryData?.instituteId,
                task?.studentContact
                  ? task?.studentContact
                  : task?.enquiryData?.studentContact,
                task?.studentEmail
                  ? task?.studentEmail
                  : task?.enquiryData?.studentEmail,
                task?.enquiryId,
                task.id,
                "COMPLETED"
              )
            : dispatch(
                setToastNotification({
                  message: String(
                    "You do not have permission to access the lead card"
                  ),
                  type: "error",
                  snackOpen: true,
                })
              );
        }
      }}
      style={{ cursor: !expandDisabled ? "pointer" : "default" }}
    >
      <div
        className={styles.taskCompletedCardSplitTop}
        style={{
          justifyContent: taskCompletedPage ? "flex-start" : "space-between",
        }}
      >
        <div className={styles.taskCompletedCardDate}>
          {moment(task?.taskCompletedDate).format("DD MMM YYYY, hh:mm A")}
        </div>
        <div className={styles.taskFlag}>
          <div className={styles.taskCompletedCardType}>
            {task.taskType?.toLowerCase()}
          </div>
          <div className={styles.userCircle}>
            {getTeamMember(task?.assignedToId, employees)?.firstName ===
            undefined
              ? ""
              : getTeamMember(task?.assignedToId, employees)?.firstName?.charAt(
                  0
                ) +
                "" +
                getTeamMember(task?.assignedToId, employees)?.lastName?.charAt(
                  0
                )}
          </div>
          <div className={styles.flagAssign}>
            {task.priority === "HIGH" && <img src={High} alt="High" />}
            {task.priority === "NORMAL" && <img src={Normal} alt="Normal" />}
            {task.priority === "LOW" && <img src={Low} alt="Low" />}
            {task.priority === "URGENT" && <img src={Urgent} alt="Urgent" />}
          </div>
        </div>
      </div>
      <div className={styles.taskCompletedCardLeadName}>
        {task?.taskType === "DEMO" ? (
          <>{task?.demoData?.[0]?.batchName}</>
        ) : (
          <>{task?.studentName}</>
        )}
      </div>
      <div
        className={
          taskCompletedPage
            ? styles.taskCompletedCardDesc
            : styles.taskCompletedCardDescShort
        }
        dangerouslySetInnerHTML={{ __html: modifiedDescription }}
      ></div>
    </div>
  );
}

export default CompletedCard;
