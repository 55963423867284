import React from "react";
import styles from "../DemoBooking.module.css";
import { Button } from "@mui/material";
import dateIcon from "../Assets/calander.svg";
import clockIcon from "../Assets//clock.svg";
import moment from "moment";
import dayjs from "dayjs";

function DemoCard(props: any) {
  const {
    setWay,
    setStage,
    handleWaySelect,
    demoCreated,
    item,
    selectedDate,
    handleBook,
    reschedule,
    editDemoId,
    editDemoBatchId,
    editDemoDate,
    demosByCourse,
  } = props;

  const currentCalendar = item?.batchCalendar?.find((calendarItem: any) => {
    if (!calendarItem || !calendarItem.date) {
      return false; // Handle null or undefined values
    }

    const demoTimestamp = calendarItem.date; // Assuming date is in Unix timestamp (milliseconds)
    const demoDate = new Date(demoTimestamp);
    const selectedDateObj = new Date(selectedDate);

    return (
      demoDate?.getFullYear() === selectedDateObj?.getFullYear() &&
      demoDate?.getMonth() === selectedDateObj?.getMonth() &&
      demoDate?.getDate() === selectedDateObj?.getDate()
    );
  });

  const batchSheduleItem =
    currentCalendar &&
    item?.batchSchedule
      ?.filter((item: any) => item?.batchSessions?.length > 0)
      ?.find((item: any) => {
        return (
          item?.day ===
          moment
            .unix(currentCalendar?.date / 1000)
            .format("ddd")
            ?.toUpperCase()
        );
      });
  const batchScheduleItems: any = [];

  const batchSheduleItemCustom =
    currentCalendar &&
    item?.customDays
      ?.filter((item: any) => item?.batchSessions?.length > 0)
      ?.find((item: any) => {
        return item?.date === currentCalendar?.date;
      });
  if (batchSheduleItem) {
    batchScheduleItems.push(batchSheduleItem);
  }

  if (batchSheduleItemCustom) {
    batchScheduleItems.push(batchSheduleItemCustom);
  }

  function isSameDate(timestamp1: number, timestamp2: number): boolean {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    // Compare year, month, and day
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  function calculateDemoBookedCount(batchId: string, batchCalendar: any) {
    const batch = batchCalendar?.find(
      (batch: any) => batch.date === selectedDate
    );
    // const demosArray = demosByCourse?.filter(
    //   (item: any) =>
    //     item.batchId === batchId && item.demoTiming === selectedDate
    // );
    const demosArray = demosByCourse?.filter(
      (item: any) =>
        item.batchId === batchId && isSameDate(item.demoTiming, selectedDate)
    );

    return demosArray?.length;
  }

  return (
    <div
      className={styles.demoCardWrap}
      style={{
        border:
          reschedule &&
          editDemoId !== "" &&
          editDemoBatchId === item?.id &&
          editDemoDate === currentCalendar?.date
            ? "1px solid rgba(17, 200, 155, 0.40)"
            : "1px solid #EEE",
        background:
          reschedule &&
          editDemoId !== "" &&
          editDemoBatchId === item?.id &&
          editDemoDate === currentCalendar?.date
            ? "rgba(17, 200, 155, 0.02)"
            : "#fff",
      }}
    >
      <div className={styles.demoCardChildWrap}>
        <div>
          <div className={styles.demoCardHead}>
            <div className={styles.demoCardName}>{item?.batchName}</div>
          </div>

          <div className={styles.demoCardBatchDetails}>
            <div className={styles.demoCardBatchItem}>
              <div className={styles.demoCardBatchItemIcon}>
                <img src={dateIcon} alt="dateIcon" />
              </div>
              <div className={styles.demoCardBatchItemValue}>
                {moment
                  .unix(currentCalendar?.date / 1000)
                  .format("DD MMM YYYY, ddd")}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.demoCardType}>
            {item?.batchConductType?.toLowerCase()?.replace(/_/g, " ")}
            {" | "}
            {demoCreated
              ? item?.batchType[0]?.batchType?.courseTypeName
              : "classroom"}
          </div>

          <div className={styles.demoCardScheduleWrap}>
            {batchScheduleItems?.map(
              (scheduleItem: any, scheduleIndex: number) => (
                <div key={scheduleIndex}>
                  {scheduleItem?.batchSessions?.map(
                    (session: any, sessionIndex: number) => (
                      <div
                        className={styles.demoCardBatchItem}
                        key={sessionIndex}
                      >
                        <div className={styles.demoCardBatchItemIcon}>
                          <img src={clockIcon} alt="clockIcon" />
                        </div>
                        <div className={styles.demoCardBatchItemValue}>
                          {demoCreated
                            ? moment.unix(session?.startTime).format("hh:mm A")
                            : moment
                                .unix(session?.startTime)
                                .format("hh:mm A")}{" "}
                          -{" "}
                          {demoCreated
                            ? moment.unix(session?.endTime).format("hh:mm A")
                            : moment.unix(session?.endTime).format("hh:mm A")}
                        </div>
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className={styles.demoCardBottom}>
        <div className={styles.demoCardCount}>
          <div className={styles.demoCardCountLabel}>Demo Booked</div>
          <div className={styles.demoCardCountValue}>
            {calculateDemoBookedCount(item.id, item?.batchCalendar)}
          </div>
        </div>
        <div className={styles.demoCardBook}>
          {reschedule &&
          editDemoId !== "" &&
          editDemoBatchId === item?.id &&
          editDemoDate === currentCalendar?.date &&
          batchSheduleItem?.batchSessions?.length === 1 ? (
            <></>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  fontSize: 14,
                  textTransform: "capitalize",
                  boxShadow: "none",
                  height: "38px",
                  padding: "4px 18px",
                }}
                size={"small"}
                onClick={() => {
                  setWay(1);
                  setStage(2);
                  handleWaySelect();
                  handleBook(
                    item,
                    batchSheduleItem?.batchSessions?.length === 1
                      ? batchSheduleItem?.batchSessions[0]?.startTime
                      : dayjs().unix(),
                    batchSheduleItem?.batchSessions?.length === 1
                      ? batchSheduleItem?.batchSessions[0]?.endTime
                      : dayjs().unix(),
                    currentCalendar?.date
                  );
                }}
              >
                Book
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DemoCard;
