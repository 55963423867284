import React from "react";
import IconDropdown from "../../../../../../components/Dropdowns/IconDropdown";
import editIcon from "../../../../../../assets/images/edit-icon.svg";
import styles from "./LeadSession.module.css";
import moment from "moment";
import markAttendanceIcon from "../../../../../../assets/images/markAttendance.svg";
import cancelIcon from "../../../../../../assets/images/cancelCross.svg";
import clockIcon from "../../../../../../assets/images/clockTask.svg";
import { getDescriptionWithMentionedNames } from "../../../../../../components/CommonFunctions/CommonFunctions";
import { useSelector } from "react-redux";
import helpIcon from "../../../../../../assets/images/helpIcon.svg";

function SessionsTabItem(props: any) {
  const {
    sessionData,
    setEditSchedule,
    setEditScheduleData,
    setCancelPopup,
    markAttendance,
    hasLeadAccess,
    setAttendancePopup,
  } = props;
  const employees = useSelector((state: any) => state.staff.staffList);

  const modifiedDescription = getDescriptionWithMentionedNames(
    sessionData?.description,
    employees
  ).replace(/\n/g, "<br>");

  const truncatedText =
    modifiedDescription?.length > 16
      ? `${modifiedDescription.substring(0, 16)}...`
      : modifiedDescription;

  return (
    <div className={styles.sessionTabItemWrap}>
      <div className={styles.sessionTabItemDateWrap}>
        <div className={styles.sessionTabItemDate}>
          {moment(sessionData?.sessionDate).format("DD MMM")}
        </div>
        <div className={styles.sessionTabItemDay}>
          {moment(sessionData?.sessionDate).format("ddd")}
        </div>
      </div>
      <div className={styles.sessionTabItemRight}>
        <div style={{ maxWidth: "160PX", width: "160PX" }}>
          <div className={styles.sessionTabItemName}>
            {sessionData?.sessionName?.length > 20
              ? `${sessionData?.sessionName.substring(0, 20)}...`
              : sessionData?.sessionName}
          </div>
          <div
            className={styles.sessionTabItemStatus}
            style={{
              background:
                sessionData?.sessionStatus === "OVERDUE"
                  ? "#ff3b3b"
                  : sessionData?.sessionStatus === "UPCOMING"
                  ? "#F9AA0F"
                  : "#11C89B",
            }}
          >
            {sessionData?.sessionStatus?.toLowerCase()}
          </div>
        </div>

        <div className={styles.sessionTabItemTiming}>
          <img
            src={clockIcon}
            alt="clockIcon"
            style={{ marginBottom: "2px" }}
          />
          {moment.unix(sessionData?.startTime).format("hh:mm A") +
            " - " +
            moment.unix(sessionData?.endTime).format("hh:mm A")}
        </div>
        <div className={styles.sessionTabItemEditWrap}>
          {sessionData.sessionStatus === "COMPLETED" ? (
            <div
              style={{ width: "170px", position: "relative" }}
              className={styles.sessionTabDescWrap}
            >
              <div
                className={styles.sessionTabDesc}
                dangerouslySetInnerHTML={{
                  __html: truncatedText,
                }}
              ></div>
              {modifiedDescription.length > 16 && (
                <div
                  style={{
                    width: "20px",
                    marginLeft: "6px",
                    cursor: "pointer",
                  }}
                  className={styles.helpIconContainer}
                >
                  <img src={helpIcon} alt="helpIcon" />
                  {modifiedDescription.length > 16 && (
                    <div
                      className={styles.sessionTabDescWrapPop}
                      dangerouslySetInnerHTML={{
                        __html: modifiedDescription,
                      }}
                    ></div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className={styles.sessionTabItemPayStatus}
              style={{ color: "#3DB985", background: "#11C89B1A" }}
            >
              Paid
            </div>
          )}

          {sessionData.sessionStatus !== "COMPLETED" && hasLeadAccess && (
            <IconDropdown
              options={[
                {
                  label: "Edit",
                  onClick: () => {
                    setEditSchedule(true);
                    setEditScheduleData(sessionData);
                  },
                  icon: editIcon,
                  style: { color: "#667085" },
                },
                {
                  label: "Mark as attended",
                  onClick: () => {
                    setAttendancePopup(true);
                    setEditScheduleData(sessionData);
                  },
                  icon: markAttendanceIcon,
                  style: { color: "#667085" },
                },
                {
                  label: "Cancel Session",
                  onClick: () => {
                    setCancelPopup(true);
                    setEditScheduleData(sessionData);
                  },
                  icon: cancelIcon,
                  style: { color: "#667085" },
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SessionsTabItem;
