import React from "react";
import styles from "../Manegecourses.module.css";

function PaymentTypeBox(props: any) {
  const { courseById } = props;

  return (
		<div className={styles.paymentTypeBox}>
			{courseById?.paymentDetails?.paymentType === 'REGULAR' && (
				<div className={styles.paymentTypeRegularWrap}>
					<div
						className={styles.paymentTypeRegularItem}
						style={{ width: '150px' }}>
						<div className={styles.paymentTypeLabel}>Total Price</div>
						<div className={styles.paymentTypeValue}>
							{courseById?.paymentDetails?.paymentData?.totalAmount}
						</div>
					</div>
					<div className={styles.paymentTypeRegularItem}>
						<div className={styles.paymentTypeLabel}>Installment</div>
						<div className={styles.paymentTypeValue}>
							{courseById?.paymentDetails?.paymentData.enableInstallment
								? 'Enabled'
								: 'Disabled'}
						</div>
					</div>
				</div>
			)}

			{courseById?.paymentDetails?.paymentType === 'MEMBERSHIP' &&
				courseById?.paymentDetails?.paymentData?.paymentStructure?.length >
					0 && (
					<div className={styles.paymentTypeMembershipWrap}>
						{courseById?.paymentDetails?.paymentData?.paymentStructure?.map(
							(item: any, index: number) => (
								<div className={styles.paymentTypeMembershipItem} key={index}>
									<div className={styles.courseMembershipType}>
										{item?.frequency?.toLowerCase().replace(/_/g, ' ')}
									</div>
									<div className={styles.paymentTypeValue}>
										{item?.emiAmount}
									</div>
									<div className={styles.paymentTypeLabel}>Membership fees</div>
								</div>
							)
						)}
					</div>
				)}

			{courseById?.paymentDetails?.paymentType === 'SESSION' &&
				courseById?.paymentDetails?.paymentData?.paymentStructure?.length >
					0 && (
					<div className={styles.paymentTypeMembershipWrap}>
						{courseById?.paymentDetails?.paymentData?.paymentStructure?.map(
							(item: any, index: number) => (
								<div className={styles.paymentTypeRegularWrap} key={index}>
									<div
										className={styles.paymentTypeRegularItem}
										style={{ width: '150px' }}>
										<div className={styles.paymentTypeLabel}>Frequency</div>
										<div
											className={styles.paymentTypeValue}
											style={{ textTransform: 'capitalize' }}>
											{item?.frequency?.toLowerCase().replace(/_/g, ' ')}
										</div>
									</div>
									<div className={styles.paymentTypeRegularItem}>
										<div className={styles.paymentTypeLabel}>Price</div>
										<div className={styles.paymentTypeValue}>
											{item?.session_fee}
										</div>
									</div>
								</div>
							)
						)}
					</div>
				)}
		</div>
	);
}

export default PaymentTypeBox;
