import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import styles from "./Settings.module.css";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailSettings from "./EmailSettings/EmailSettings";
import ImportSettings from "./ImportSettings/ImportSettings";
import { useAuthUser } from "react-auth-kit";
import { resetInboundDeactivateSuccess } from "../../service/deactivateInboundSlice";
import { useDispatch } from "react-redux";
import WhatsAppSettings from "./WhatsAppSettings/WhatsAppSettings";

function Settings(props: any) {
  const {
    GetImportHistory,
    GetWhatsappConfigInstitute,
    whatsappConfigInstituteData,
  } = props;
  const navigate = useNavigate();
  const [tab, setTab] = useState("EmailIntegration");
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();

  function handleGoBack() {
    navigate("/app/inbound/Contacts", { state: 0 });
  }

  // useEffect(() => {
  //   const instituteInfo = authUser?.institute[0]?.instituteId;
  //   if (instituteInfo) {
  //     GetWhatsappConfigInstitute(instituteInfo, "ACTIVE");
  //   }
  // }, [GetWhatsappConfigInstitute]);

  React.useEffect(() => {
    dispatch(resetInboundDeactivateSuccess());
  }, []);

  const handleTabClickQuery = (tabType: any) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("inboundSettingsTabType", tabType);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({}, "", newUrl);
  };

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const inboundSettingsTab = urlSearchParams.get("inboundSettingsTabType");

    if (inboundSettingsTab === "emailIntegration") {
      setTab("EmailIntegration");
    } else if (inboundSettingsTab === "contacts") {
      setTab("Contacts");
    }
  }, []);

  React.useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0];
    const lastEvaluatedKey = "";
    const limit = 20;
    const lastEvaluatedSortKey = "";
    if (userFromLocalStorage) {
      GetImportHistory(
        userFromLocalStorage.instituteId,
        lastEvaluatedKey,
        limit,
        lastEvaluatedSortKey
      );
    }
  }, [GetImportHistory]);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "calc(100% - 65px)",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#F5F5F5",
          padding: "14px",
          overflowY: "auto",
          minHeight: "calc(100vh - 65px)",
        }}
      >
        <div
          style={{
            background: "#fff",
            padding: "0px",
            overflowY: "auto",
            width: "100%",
            border: "1px solid #E9E9E9",
            borderRadius: "8px",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "17%",
              backgroundColor: "#FFFFFF",
              padding: "18px 15px 18px 15px",
              borderRight: "1px solid #EDEDED",
            }}
          >
            <button className={styles.PageBackBtn} onClick={handleGoBack}>
              <ArrowBackIcon
                sx={{ marginRight: "8px", fontSize: "15PX", color: "#667085" }}
              />
              Back
            </button>
            <div className={styles.inboundSettingsTitle}>Settings</div>
            <ul className={styles.inboundSettingsTabs}>
              <li className={styles.inboundSettingsTab}>
                <button
                  className={` ${
                    tab === "EmailIntegration"
                      ? styles.inboundSettingsTabBtnActive
                      : styles.inboundSettingsTabBtn
                  }`}
                  onClick={() => {
                    setTab("EmailIntegration");
                    handleTabClickQuery("emailIntegration");
                  }}
                >
                  Email Inetgration
                </button>
              </li>
              <li className={styles.inboundSettingsTab}>
                <button
                  className={`${styles.inboundSettingsTabBtn} ${
                    tab === "Contacts" ? styles.inboundSettingsTabBtnActive : ""
                  }`}
                  onClick={() => {
                    setTab("Contacts");
                    handleTabClickQuery("contacts");
                  }}
                >
                  Contacts
                </button>
              </li>
              {/* <li className={styles.inboundSettingsTab}>
                <button
                  className={`${styles.inboundSettingsTabBtn} ${
                    tab === "WhatsApp" ? styles.inboundSettingsTabBtnActive : ""
                  }`}
                  onClick={() => {
                    setTab("WhatsApp");
                    handleTabClickQuery("whatsapp");
                  }}
                >
                  WhatsApp
                </button>
              </li> */}
            </ul>
          </div>

          <div
            style={{
              width: "83%",
              maxHeight: "CALC(100vh - 95px )",
              overflowY: "auto",
            }}
          >
            <div
              className={` ${
                tab === "EmailIntegration"
                  ? styles.inboundSettingsTabPanelActive
                  : styles.inboundSettingsTabPanel
              }`}
            >
              <EmailSettings {...props} />
            </div>

            <div
              className={` ${
                tab === "Contacts"
                  ? styles.inboundSettingsTabPanelActive
                  : styles.inboundSettingsTabPanel
              }`}
            >
              <ImportSettings {...props} />
            </div>
            <div
              className={` ${
                tab === "WhatsApp"
                  ? styles.inboundSettingsTabPanelActive
                  : styles.inboundSettingsTabPanel
              }`}
            >
              {/* <WhatsAppSettings
                whatsappConfigInstituteData={whatsappConfigInstituteData}
                {...props}
              /> */}
            </div>
          </div>
          {/* <FilterSettings /> */}
        </div>
      </Box>
    </div>
  );
}

export default Settings;
