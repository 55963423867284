import { RootState, TypedDispatch } from "../../../../redux/store";
import { connect } from "react-redux";
import { EmailConfigData, ImapInitialConfigDTO } from "../service/types";
import Settings from "../components/Settings/Settings";
import { saveImapEmailConfigDetails } from "../service/inboundImapSlice";
import EmailIntegration from "../components/EmailIntegration/EmailIntegration";

interface StateProps extends ImapInitialConfigDTO {}

interface DispatchProps {
  SaveImapConfigDeatils(data: EmailConfigData): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    imapConfigLoading: state.imap.imapConfigLoading,
    imapConfigSuccess: state.imap.imapConfigSuccess,
    imapConfigError: state.imap.imapConfigError,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    SaveImapConfigDeatils: (data: any) => {
      dispatch(saveImapEmailConfigDetails(data));
    },
  };
};

export interface ImapProps extends StateProps, DispatchProps {}
export const ImapComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailIntegration);
