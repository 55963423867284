import { TextField } from "@mui/material";
import React from "react";
import styles from "../Manegecourses.module.css";
import deleteIcon from "../../../../../../assets/images/delete-dustbin.svg";
import AddIcon from "@mui/icons-material/Add";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import { updateCoursePayment } from "../../service/courseSlice";
import DeletePopup from "../../../../../../components/CommonPopups/DeletePopup";
import DeleteBtn from "../../../../../../components/Buttons/DeleteBtn";

function EditCustomPayment(props: any) {
  const {
    customPayEdit,
    setCustomPayEdit,
    courseById,
    loading_updateCourse,
    success_updateCourse,
    hasCourseAccess,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([
    {
      id: 1,
      paymentName: "",
      price: 0,
      isNameValid: true,
      isPriceValid: true,
    },
  ]);
  const [deletePayment, setDeletePayment] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        paymentName: "",
        price: 0,
        isNameValid: true,
        isPriceValid: true,
      },
    ]);
  };

  const handleRemoveRow = (id: number) => {
    const updatedRows = rows
      .filter((row) => row.id !== id)
      ?.map((row: any, index: number) => ({
        ...row,
        id: index + 1,
      }));
    setRows(updatedRows);
  };

  const handlePaymentName = (id: number, value: string) => {
    const updatedRows = rows?.map((row) => {
      if (row.id === id) {
        const isNameUnique = !rows?.some(
          (r) =>
            r.id !== id && r.paymentName?.toLowerCase() === value?.toLowerCase()
        );
        return {
          ...row,
          paymentName: value,
          isNameValid: value !== "" && isNameUnique ? true : false,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handlePriceChange = (id: number, value: string) => {
    const updatedRows = rows?.map((row) =>
      row.id === id
        ? {
            ...row,
            price: parseFloat(value),
            isPriceValid: value !== "" && parseFloat(value) > 0 ? true : false,
          }
        : row
    );
    setRows(updatedRows);
  };

  const cancelButtonClick = () => {
    if (courseById?.randomPayments?.length > 0) {
      const defaultPaymentStructure = courseById?.randomPayments?.map(
        (item: any, index: number) => ({
          id: index + 1,
          paymentName: item.paymentName,
          price: item.amount,
          isNameValid: true,
          isPriceValid: true,
        })
      );
      setRows(defaultPaymentStructure);
    }
    setCustomPayEdit(false);
  };

  const isNameUniqueCheck = (rows: any, row: any) => {
    return !rows?.some(
      (r: any) =>
        r?.id !== row?.id &&
        r?.paymentName?.toLowerCase() === row?.paymentName?.toLowerCase()
    );
  };
  const hasEmptyPaymentName = rows?.some((row) => row.paymentName === "");
  const hasPriceNotGreaterThanZero = rows.every((row) => row.price > 0);

  const handleRandomPayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows?.map((item: any) => ({
        paymentName: item.paymentName,
        amount: parseFloat(item.price),
      }));

      const body = {
        courseId: courseById?.id,
        paymentType: "RANDOM_PAYMENT",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      if (!hasEmptyPaymentName && hasPriceNotGreaterThanZero) {
        dispatch<any>(updateCoursePayment(body));
      }
    } catch (error) {}
  };

  const handleDelete = (item: any) => {
    setDeletePayment(item);
    setDeleteModal(true);
  };
  const handleDeleteClosePopup = () => {
    setDeletePayment("");
    setDeleteModal(false);
  };

  const handleDeletePayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows
        ?.filter((item: any) => item?.id !== deletePayment?.id)
        ?.map((item: any) => ({
          paymentName: item.paymentName,
          amount: parseFloat(item.price),
        }));

      const body = {
        courseId: courseById?.id,
        paymentType: "RANDOM_PAYMENT",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      dispatch<any>(updateCoursePayment(body));
    } catch (error) {}
  };

  React.useEffect(() => {
    if (courseById?.randomPayments?.length > 0) {
      const defaultPaymentStructure = courseById?.randomPayments?.map(
        (item: any, index: number) => ({
          id: index + 1,
          paymentName: item.paymentName,
          price: item.amount,
          isNameValid: true,
          isPriceValid: true,
        })
      );
      setRows(defaultPaymentStructure);
    }
  }, [courseById]);

  React.useEffect(() => {
    if (success_updateCourse !== true && success_updateCourse !== false) {
      setDeletePayment("");
      setDeleteModal(false);
    }
  }, [success_updateCourse]);

  return (
		<div>
			{!customPayEdit ? (
				<>
					{rows?.map((item: any, index: number) => (
						<div className={styles.definedMembershipRow} key={index}>
							<div className={styles.definedMembershipRowLeft}>
								<div
									className={styles.definedMembershipItem}
									style={{ width: '150px' }}>
									<div className={styles.definedMembershipItemLabel}>
										Payment Name:
									</div>
									<div
										className={styles.definedMembershipItemValue}
										style={{ textTransform: 'capitalize' }}>
										{item?.paymentName}
									</div>
								</div>
								<div className={styles.definedMembershipItem}>
									<div className={styles.definedMembershipItemLabel}>
										Amount:
									</div>
									<div
										className={styles.definedMembershipItemValue}
										style={{ fontFamily: 'Roboto' }}>
										{item?.price}
									</div>
								</div>
							</div>
							{hasCourseAccess && (
								<DeleteBtn onClick={() => handleDelete(item)} />
							)}
						</div>
					))}
				</>
			) : (
				<div>
					{rows?.map((item: any, index: number) => (
						<div className={styles.membershipTypeRow} key={index}>
							<div>
								<div className={styles.membershipTypeFieldLabel}>
									Payment Name
								</div>
								<TextField
									id='paymentName'
									variant='outlined'
									type='text'
									inputProps={{
										sx: {
											width: '240px',
											'&::placeholder': {
												fontWeight: '400',
												fontSize: '14px',
											},

											'&.MuiOutlinedInput-input': {
												padding: '8px',
											},
										},
									}}
									value={item.paymentName}
									onChange={(event) =>
										handlePaymentName(item.id, event.target.value)
									}
									error={!item?.isNameValid || !isNameUniqueCheck(rows, item)}
									helperText={
										!isNameUniqueCheck(rows, item) && !item?.isNameValid
											? 'Payment name already exist'
											: isNameUniqueCheck(rows, item) && !item?.isNameValid
											? 'Please fill the field'
											: ''
									}
								/>
							</div>
							<div>
								<div className={styles.membershipTypeFieldLabel}>Amount</div>

								<TextField
									id='price'
									variant='outlined'
									type='number'
									inputProps={{
										sx: {
											width: '240px',
											'&::placeholder': {
												fontWeight: '400',
												fontSize: '14px',
											},

											'&.MuiOutlinedInput-input': {
												padding: '8px',
											},
										},
									}}
									value={item.price}
									onChange={(event) =>
										handlePriceChange(item.id, event.target.value)
									}
									error={!item?.isPriceValid}
									helperText={
										!item?.isPriceValid ? 'Please fill valid amount' : ''
									}
								/>
							</div>
							<div>
								{rows?.length > 1 && (
									<button
										className={styles.deleteTypeBtn}
										onClick={() => handleRemoveRow(item.id)}
										style={{ marginTop: '40PX' }}>
										<img src={deleteIcon} alt='delete' />
									</button>
								)}
							</div>
						</div>
					))}
					<div className={styles.addMoreTypeBtnWrap}>
						<button className={styles.addMoreTypeBtn} onClick={handleAddRow}>
							<AddIcon sx={{ fontSize: '18px', marginRight: '6PX' }} />
							Add more
						</button>
					</div>
					<div className={styles.editSectionBtnWrap}>
						<CancelBtn onClick={() => cancelButtonClick()} />
						<SaveBtn
							onClick={handleRandomPayment}
							disabled={
								hasEmptyPaymentName ||
								!hasPriceNotGreaterThanZero ||
								loading_updateCourse
							}
							loader={loading_updateCourse}
						/>
					</div>
				</div>
			)}
			{deleteModal && (
				<DeletePopup
					open={deleteModal}
					onClose={handleDeleteClosePopup}
					noBtnClick={handleDeleteClosePopup}
					yesBtnClick={handleDeletePayment}
					loader={loading_updateCourse}
				/>
			)}
		</div>
	);
}

export default EditCustomPayment;
