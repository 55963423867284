import { Route, Routes } from "react-router-dom";
import CreatedByMeTable from "../pages/app/Reports/components/CreatedByMe/CreatedByMeTable";
import ReportTable from "../pages/app/Reports/components/RecentReport/ReportTable";
import AllReportsTable from "../pages/app/Reports/components/AllReports/AllReportsTable";
import { Suspense, lazy } from "react";
import { CircularProgress } from "@mui/material";

const RecentReport = lazy(() =>
  import("../pages").then((module) => ({ default: module.RecentReport }))
);
const CreatedByMeReport = lazy(() =>
  import("../pages").then((module) => ({ default: module.CreatedByMeReport }))
);
const AllReport = lazy(() =>
  import("../pages").then((module) => ({ default: module.AllReport }))
);

export default function ManageReportRouter(props: any) {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Suspense fallback={<CircularProgress />}>
            <RecentReport />
          </Suspense>
        }
      />
      <Route
        path="/RecentReports"
        element={
          <Suspense fallback={<CircularProgress />}>
            <RecentReport />
          </Suspense>
        }
      />
      <Route
        path="/CreatedByMe"
        element={
          <Suspense fallback={<CircularProgress />}>
            <CreatedByMeReport />
          </Suspense>
        }
      />
      <Route
        path="/AllReports"
        element={
          <Suspense fallback={<CircularProgress />}>
            <AllReport />
          </Suspense>
        }
      />
    </Routes>
  );
}
