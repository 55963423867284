import React from "react";
import styles from "./ListTab.module.css";
import moment from "moment";

function LeadAdditionalInfoTab(props: any) {
  const { leadAdditionalDetails } = props;
  const capitalizeFirstLetter = (text: any) => {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  };

  return (
    <div className={styles.additionalInfoTabWrap}>
      <div className={styles.additionalInfoTitle}>Additional Details</div>

      <div>
        <div className={styles.additionalInfoTableHead}>
          <div className={styles.additionalInfoTableHeadLeft}>Fields</div>
          <div>Response</div>
        </div>
        <div>
          {leadAdditionalDetails?.additionalInfo
            ?.filter(
              (item: any) =>
                item.fieldValue !== "" &&
                item.fieldType !== "file" &&
                item.fieldType !== "profilePic"
            )
            ?.map((item: any) => (
              <div className={styles.additionalInfoTableRow}>
                <div className={styles.additionalInfoTableRowLeft}>
                  {item?.fieldLabel}
                </div>
                <div>
                  {item.fieldType === "date"
                    ? moment(item.fieldValue).format("DD/MM/YYYY")
                    : item.fieldType === "checkbox"
                    ? Array.isArray(item.fieldValue)
                      ? item.fieldValue
                          .map((value: any) => capitalizeFirstLetter(value))
                          .join(", ")
                      : capitalizeFirstLetter(item.fieldValue)
                    : capitalizeFirstLetter(item.fieldValue)}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default LeadAdditionalInfoTab;
