import React, { useState } from "react";
import { Box, TextField, Button, CircularProgress } from "@mui/material";
import styles from "./TaxInvoices.module.css";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";

function EditInvoiceNumber(props: any) {
  const { onCancelEdit, UpdateStudentInvoiceSettings } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { invoiceSettings, invoiceSettingUpdateLoading } = useSelector(
    (state: any) => state.appSettings
  );
  const [prefix, setPrefix] = useState<String>("");
  const [nextNumber, setNextNumber] = useState<String>("");
  const PREFIX_REGEX = /^[a-zA-Z0-9_]+$/;
  const NUM_REGEX = /^[0-9]*$/;

  const [prefixError, setPrefixError] = React.useState<boolean>(false);
  const [numberError, setNumberError] = React.useState<boolean>(false);
  const handleCancel = () => {
    onCancelEdit();
  };

  const handlePrefixChange = (e: any) => {
    const inputValue = e.target.value;
    if (PREFIX_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setPrefixError(true);
      } else {
        setPrefixError(false);
      }
      setPrefix(inputValue);
    }
  };

  const handleNumberChange = (e: any) => {
    const inputValue = e.target.value;

    if (NUM_REGEX.test(inputValue) || inputValue === "") {
      if (!inputValue) {
        setNumberError(true);
      } else {
        setNumberError(false);
      }
      setNextNumber(inputValue);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 8) {
      return;
    }
    if (!NUM_REGEX.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleSaveClick = async (event: any) => {
    event.preventDefault();

    try {
      const body = {
        instituteId: authUser?.institute?.[0]?.instituteId,
        nextNumber: nextNumber,
        prefix: prefix,
        gstNumber: invoiceSettings?.gstNumber,
        isStudentTaxInvoiceEnabled: true,
      };
      UpdateStudentInvoiceSettings(body);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (invoiceSettings && invoiceSettings?.prefix !== "") {
      setPrefix(invoiceSettings?.prefix);
      setNextNumber(invoiceSettings?.nextNumber);
    }
  }, [invoiceSettings]);

  return (
    <Box>
      <div className={styles.editInvoice}>Edit Prefix and starting number</div>
      <div className={styles.invoiceSectionSubHead}>
        The system will generate them sequentially, ensuring a unique
        identification for each invoice.
      </div>
      <Box sx={{ display: "flex", maxWidth: "86%" }}>
        <Box>
          <div className={styles.prefix}>
            Prefix{" "}
            <span style={{ color: "#EC3939", fontWeight: "400" }}>*</span>
          </div>
          <TextField
            sx={{
              width: "72%",
              "& .MuiOutlinedInput-input": {
                padding: "11px",
              },
            }}
            value={prefix}
            onChange={handlePrefixChange}
            onKeyDown={(event: any) => {
              if (!PREFIX_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputProps={{ maxLength: 10 }}
            error={prefixError}
            helperText={prefixError ? "Field Required" : ""}
          />
        </Box>
        <Box>
          <div className={styles.nextNumber}>
            Next Number{" "}
            <span style={{ color: "#EC3939", fontWeight: "400" }}>*</span>
          </div>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-input": {
                padding: "11px",
              },
            }}
            value={nextNumber}
            onChange={handleNumberChange}
            inputProps={{ maxLength: 10 }}
            error={numberError}
            helperText={numberError ? "Field Required" : ""}
            onKeyDown={handleKeyDown}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: "24px", display: "flex", gap: "12px" }}>
        <Button
          sx={{
            color: "#BFBFBF",
            border: "1px solid #BFBFBF",
            background: "#FFF",
            textTransform: "capitalize",
          }}
          variant="outlined"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="contained"
          onClick={handleSaveClick}
          disabled={numberError || prefixError || invoiceSettingUpdateLoading}
        >
          {invoiceSettingUpdateLoading ? (
            <CircularProgress size={20} color={"inherit"} />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
}
export default EditInvoiceNumber;
