import React from "react";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import styles from "../Managebatches.module.css";
import { TextField } from "@mui/material";
import CancelBtn from "../../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../../components/Buttons/SaveBtn";
import AddIcon from "@mui/icons-material/Add";
import deleteIcon from "../../../../../../assets/images/delete-dustbin.svg";
import { updateBatchPayment } from "../../service/Batchslice";
import DeleteBtn from "../../../../../../components/Buttons/DeleteBtn";
import DeletePopup from "../../../../../../components/CommonPopups/DeletePopup";

function EditBatchCustomPay(props: any) {
  const {
		customPayEdit,
		setCustomPayEdit,
		batchData,
		loader,
		success,
		hasBatchAccess,
		selectedCurrency,
	} = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState([
    {
      id: 1,
      paymentName: "",
      price: 0,
      isNameValid: true,
      isPriceValid: true,
    },
  ]);
  const [deletePayment, setDeletePayment] = React.useState<any>("");
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        paymentName: "",
        price: 0,
        isNameValid: true,
        isPriceValid: true,
      },
    ]);
  };

  const handleRemoveRow = (id: number) => {
    const updatedRows = rows
      .filter((row) => row.id !== id)
      ?.map((row: any, index: number) => ({
        ...row,
        id: index + 1,
      }));
    setRows(updatedRows);
  };

  const handlePaymentName = (id: number, value: string) => {
    const updatedRows = rows?.map((row) => {
      if (row.id === id) {
        const isNameUnique = !rows?.some(
          (r) =>
            r.id !== id && r.paymentName?.toLowerCase() === value?.toLowerCase()
        );
        return {
          ...row,
          paymentName: value,
          isNameValid: value !== "" && isNameUnique ? true : false,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handlePriceChange = (id: number, value: string) => {
    const updatedRows = rows?.map((row) =>
      row.id === id
        ? {
            ...row,
            price: parseFloat(value),
            isPriceValid: value !== "" && parseFloat(value) > 0 ? true : false,
          }
        : row
    );
    setRows(updatedRows);
  };

  const cancelButtonClick = () => {
    setCustomPayEdit(false);
    if (batchData?.randomPayments?.length > 0) {
      const defaultPaymentStructure = batchData?.randomPayments?.map(
        (item: any, index: number) => ({
          id: index + 1,
          paymentName: item.paymentName,
          price: item.amount,
          isNameValid: true,
          isPriceValid: true,
        })
      );
      setRows(defaultPaymentStructure);
    }
  };

  const isNameUniqueCheck = (rows: any, row: any) => {
    return !rows?.some(
      (r: any) =>
        r?.id !== row?.id &&
        r?.paymentName?.toLowerCase() === row?.paymentName?.toLowerCase()
    );
  };
  const hasEmptyPaymentName = rows?.some((row) => row.paymentName === "");
  const hasPriceNotGreaterThanZero = rows.every((row) => row.price > 0);
  const hasDuplicatePaymentName = rows?.some(
    (item, index) =>
      rows?.findIndex(
        (i) => i.paymentName.toLowerCase() === item.paymentName.toLowerCase()
      ) !== index
  );

  const handleRandomPayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows?.map((item: any) => ({
        paymentName: item.paymentName,
        amount: parseFloat(item.price),
      }));

      const hasDuplicatePaymentName = updatedPaymentStructure?.some(
        (item, index) =>
          updatedPaymentStructure?.findIndex(
            (i) =>
              i.paymentName.toLowerCase() === item.paymentName.toLowerCase()
          ) !== index
      );

      const body = {
        batchId: batchData?.id,
        paymentType: "RANDOM_PAYMENT",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      if (!hasEmptyPaymentName && hasPriceNotGreaterThanZero) {
        dispatch<any>(updateBatchPayment(body));
      }
    } catch (error) {}
  };

  const handleDelete = (item: any) => {
    setDeletePayment(item);
    setDeleteModal(true);
  };
  const handleDeleteClosePopup = () => {
    setDeletePayment("");
    setDeleteModal(false);
  };

  const handleDeletePayment = async (event: any) => {
    event.preventDefault();

    try {
      const updatedPaymentStructure = rows
        ?.filter((item: any) => item?.id !== deletePayment?.id)
        ?.map((item: any) => ({
          paymentName: item.paymentName,
          amount: parseFloat(item.price),
        }));

      const body = {
        batchId: batchData?.id,
        paymentType: "RANDOM_PAYMENT",
        paymentStructure: updatedPaymentStructure,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
      };
      dispatch<any>(updateBatchPayment(body));
    } catch (error) {}
  };

  React.useEffect(() => {
    if (batchData?.randomPayments?.length > 0) {
      const defaultPaymentStructure = batchData?.randomPayments?.map(
        (item: any, index: number) => ({
          id: index + 1,
          paymentName: item.paymentName,
          price: item.amount,
          isNameValid: true,
          isPriceValid: true,
        })
      );
      setRows(defaultPaymentStructure);
    }
  }, [batchData]);

  React.useEffect(() => {
    if (success !== true && success !== false) {
      setDeletePayment("");
      setDeleteModal(false);
    }
  }, [success]);

  return (
		<div>
			{!customPayEdit ? (
				<div>
					{rows?.map((item: any, index: number) => (
						<div className={styles.definedCustomPayRow} key={index}>
							<div className={styles.definedCustomPayRowLeft}>
								<div
									className={styles.definedCustomPayItem}
									style={{ width: '180px' }}>
									<div className={styles.definedCustomPayItemLabel}>
										Payment Name:
									</div>
									<div
										className={styles.definedCustomPayItemValue}
										style={{ textTransform: 'capitalize' }}>
										{item?.paymentName}
									</div>
								</div>
								<div className={styles.definedCustomPayItem}>
									<div className={styles.definedCustomPayItemLabel}>
										Amount:
									</div>
									<div
										className={styles.definedCustomPayItemValue}
										style={{ fontFamily: 'Roboto' }}>
										{selectedCurrency + ' ' + item?.price}
									</div>
								</div>
							</div>
							{hasBatchAccess && (
								<DeleteBtn onClick={() => handleDelete(item)} />
							)}
						</div>
					))}
				</div>
			) : (
				<div>
					{rows?.map((item: any, index: number) => (
						<div className={styles.customTypeRow} key={index}>
							<div>
								<div className={styles.customTypeFieldLabel}>Payment Name</div>
								<TextField
									id='paymentName'
									variant='outlined'
									type='text'
									inputProps={{
										sx: {
											width: '240px',
											'&::placeholder': {
												fontWeight: '400',
												fontSize: '14px',
											},

											'&.MuiOutlinedInput-input': {
												padding: '8px',
											},
										},
									}}
									value={item.paymentName}
									onChange={(event) =>
										handlePaymentName(item.id, event.target.value)
									}
									error={!item?.isNameValid || !isNameUniqueCheck(rows, item)}
									helperText={
										!isNameUniqueCheck(rows, item) && !item?.isNameValid
											? 'Payment name already exist'
											: isNameUniqueCheck(rows, item) && !item?.isNameValid
											? 'Please fill the field'
											: ''
									}
								/>
							</div>
							<div>
								<div className={styles.customTypeFieldLabel}>Amount</div>

								<TextField
									id='price'
									variant='outlined'
									type='number'
									inputProps={{
										sx: {
											width: '240px',
											'&::placeholder': {
												fontWeight: '400',
												fontSize: '14px',
											},

											'&.MuiOutlinedInput-input': {
												padding: '8px',
											},
										},
									}}
									value={item.price}
									onChange={(event) =>
										handlePriceChange(item.id, event.target.value)
									}
									error={!item?.isPriceValid}
									helperText={
										!item?.isPriceValid ? 'Please fill valid amount' : ''
									}
								/>
							</div>
							<div>
								{rows?.length > 1 && (
									<button
										className={styles.deleteTypeBtn}
										onClick={() => handleRemoveRow(item.id)}
										style={{ marginTop: '40PX' }}>
										<img src={deleteIcon} alt='delete' />
									</button>
								)}
							</div>
						</div>
					))}
					<div className={styles.addMoreTypeBtnWrap}>
						<button className={styles.addMoreTypeBtn} onClick={handleAddRow}>
							<AddIcon sx={{ fontSize: '18px', marginRight: '6PX' }} />
							Add more
						</button>
					</div>
					<div
						className={styles.editSectionBtnWrap}
						style={{ marginTop: '18PX' }}>
						<CancelBtn onClick={() => cancelButtonClick()} />
						<SaveBtn
							onClick={handleRandomPayment}
							disabled={
								hasEmptyPaymentName ||
								!hasPriceNotGreaterThanZero ||
								loader ||
								hasDuplicatePaymentName
							}
							loader={loader}
						/>
					</div>
				</div>
			)}
			{deleteModal && (
				<DeletePopup
					open={deleteModal}
					onClose={handleDeleteClosePopup}
					noBtnClick={handleDeleteClosePopup}
					yesBtnClick={handleDeletePayment}
					loader={loader}
				/>
			)}
		</div>
	);
}

export default EditBatchCustomPay;
