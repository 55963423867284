import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  styled,
  Box,
  Checkbox,
  TextField,
  FormControl,
  Autocomplete,
  Paper,
  Tooltip,
} from "@mui/material";
import styles from "./CustomRolePermissions.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tableCellClasses } from "@mui/material/TableCell";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import helpIcon from "../../../../../../../assets/images/helpIcon.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F9FAFB",
    color: "#667085",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function CustomRolePermissions(props: any) {
  const {
    editMode,
    handleEditClick,
    branchList,
    treeStructure,
    handleDomainCheckBoxChange,
    handleSubDomainCheckBoxChange,
    editTreeStructure,
  } = props;
  const { userRoleById } = useSelector((state: any) => state.userDetails);

  // const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selectedBranch, setSelectedBranch] = useState<any>("");

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  const [expandedPanels, setExpandedPanels] = React.useState<string[]>([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanels((prevExpanded) =>
        isExpanded
          ? [...prevExpanded, panel]
          : prevExpanded.filter((p) => p !== panel)
      );
    };

  React.useEffect(() => {
    if (editMode) {
      setExpandedPanels(
        treeStructure?.map((_: any, index: number) => `panel${index + 1}`) || []
      );
    } else {
      setExpandedPanels([]);
    }
  }, [editMode, treeStructure]);

  return (
    <Box
      sx={{
        padding: "0px 20px 0px 20px",
      }}
    >
      {treeStructure?.map((item: any, index: any) => (
        <Box sx={{ mt: "15px" }} key={index}>
          <Accordion
            style={{
              background: "#ffffff",
              boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
              border: "1px solid #E3E3E3 ",
            }}
            // expanded={expanded === `panel${index + 1}`}
            expanded={expandedPanels.includes(`panel${index + 1}`)}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "6px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    flexShrink: 0,
                    color: "#142C44",
                    fontSize: "15px",
                    fontWeight: "500",
                    marginRight: "5PX",
                  }}
                >
                  {item?.roleDomainName}
                </Typography>
                {item?.roleDomainCode === "TASKS" && (
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      "Users will have All access to own tasks. Use this section to grant all access to other User's task."
                    }
                    PopperProps={{ style: { zIndex: 9999999 } }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span>
                      <img src={helpIcon} alt="helpIcon" />
                    </span>
                  </Tooltip>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px" }}>
              <TableContainer
                sx={{
                  minWidth: 700,
                  borderRadius: "0px 0px 8px 8PX",
                  border: "1px solid  #EAECF0",
                }}
              >
                <Table
                  sx={{
                    minWidth: 700,
                  }}
                  aria-label="customized table"
                >
                  <>
                    <>
                      <TableBody>
                        {item?.accessPermissions?.length !== 0 &&
                          item?.accessPermissions?.map(
                            (parameter: any, parameterIndex: number) => (
                              <TableRow>
                                <StyledTableCell
                                  align="left"
                                  sx={{
                                    color: "#667085",
                                    textTransform: "capitalize",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "12px",
                                    maxWidth: "400px",
                                    justifyContent: "space-between",
                                    borderBottom: "none",
                                    padding: "10px 16px",
                                  }}
                                >
                                  {parameter?.permissionAttributeName}
                                  {editMode ? (
                                    <Checkbox
                                      sx={{
                                        strokeWidth: 1,
                                        color: "#D0D5DD",
                                      }}
                                      checked={parameter?.accessStatus}
                                      disabled={
                                        parameter?.permissionAttributeCode ===
                                          "VIEW_ONLY" &&
                                        item?.accessPermissions?.find(
                                          (item: any) =>
                                            item.permissionAttributeCode ===
                                            "ALL_ACCESS"
                                        )?.accessStatus === true
                                      }
                                      onChange={(e) =>
                                        handleDomainCheckBoxChange(
                                          e.target.checked,
                                          !editTreeStructure
                                            ? item?.id
                                            : item?.roleDomainId,
                                          parameter?.permissionAttributeId,
                                          parameter?.permissionAttributeCode
                                        )
                                      }
                                    />
                                  ) : (
                                    <>
                                      {parameter?.accessStatus ? (
                                        <CheckIcon sx={{ color: "#11C89B" }} />
                                      ) : (
                                        <ClearIcon />
                                      )}
                                    </>
                                  )}{" "}
                                </StyledTableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </>
                    <>
                      {item?.subDomain?.length !== 0 &&
                        item?.subDomain?.map(
                          (detail: any, detailIndex: any) => (
                            <React.Fragment key={detailIndex}>
                              {/* <TableHead key={detailIndex}>
                                <TableRow>
                                  <StyledTableCell
                                    align="left"
                                    sx={{
                                      maxWidth: "400px",
                                      borderBottom: "none",
                                    }}
                                  >
                                    {detail?.roleDomainName}we
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead> */}
                              <div
                                style={{
                                  backgroundColor: "#F9FAFB",
                                  color: "#667085",
                                  padding: "16px",
                                  width: "100%",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                {detail?.roleDomainName}
                              </div>
                              <TableBody>
                                {detail?.accessPermissions?.length !== 0 &&
                                  detail?.accessPermissions?.map(
                                    (
                                      parameter: any,
                                      parameterIndex: number
                                    ) => (
                                      <TableRow>
                                        <StyledTableCell
                                          align="left"
                                          sx={{
                                            color: "#667085",
                                            textTransform: "capitalize",
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "12px",
                                            maxWidth: "400px",
                                            justifyContent: "space-between",
                                            borderBottom: "none",
                                            padding: "10px 16px",
                                          }}
                                        >
                                          {parameter?.permissionAttributeName}
                                          {editMode ? (
                                            <Checkbox
                                              sx={{
                                                strokeWidth: 1,
                                                color: "#D0D5DD",
                                              }}
                                              disabled={
                                                (parameter?.permissionAttributeCode ===
                                                  "VIEW_ONLY" &&
                                                  detail?.accessPermissions?.find(
                                                    (item: any) =>
                                                      item.permissionAttributeCode ===
                                                      "ALL_ACCESS"
                                                  )?.accessStatus === true) ||
                                                (parameter?.permissionAttributeCode ===
                                                  "OWN_BRANCH" &&
                                                  detail?.accessPermissions?.find(
                                                    (item: any) =>
                                                      item.permissionAttributeCode ===
                                                      "ALL_BRANCHES"
                                                  )?.accessStatus === true)
                                              }
                                              checked={parameter?.accessStatus}
                                              onChange={(e) =>
                                                handleSubDomainCheckBoxChange(
                                                  e.target.checked,
                                                  !editTreeStructure
                                                    ? item?.id
                                                    : item?.roleDomainId,
                                                  !editTreeStructure
                                                    ? detail?.id
                                                    : detail?.roleDomainId,
                                                  parameter?.permissionAttributeId,
                                                  parameter?.permissionAttributeCode
                                                )
                                              }
                                            />
                                          ) : (
                                            <>
                                              {parameter?.accessStatus ? (
                                                <CheckIcon
                                                  sx={{ color: "#11C89B" }}
                                                />
                                              ) : (
                                                <ClearIcon />
                                              )}
                                            </>
                                          )}
                                        </StyledTableCell>
                                      </TableRow>
                                    )
                                  )}
                              </TableBody>
                              {/* {item?.roleDomainCode === "NURTURE_BOARD" &&
                            editMode && (
                              <Box
                                sx={{
                                  padding: "26px 16px",
                                  borderTop: "1px solid #EAECF0",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div className={styles.newBranch}>
                                  Add New Branch
                                </div>
                                <FormControl
                                  fullWidth
                                  variant="standard"
                                  className="formControl"
                                  sx={{
                                    maxWidth: "25%",
                                  }}
                                >
                                  <Autocomplete
                                    id="branchID"
                                    options={branchList}
                                    getOptionLabel={(option: any) =>
                                      option.branchName || ""
                                    }
                                    fullWidth
                                    // onChange={onBranchChange}
                                    //   value={selectedBranch}
                                    PaperComponent={(props) => (
                                      <Paper
                                        sx={{
                                          background: "#FFF",
                                          border: "1px solid #E4E4E7",
                                          fontFamily: "poppins",
                                          fontSize: "14px",
                                          textTransform: "capitalize",
                                          color: "142C44",
                                          fontWeight: "400",
                                          padding: "0 4px !important",
                                          margin: "0",
                                          boxShadow:
                                            "0px 2px 6px 0px rgba(0, 0, 0, 0.06)",
                                          maxHeight: "300px",
                                          overflowY: "auto",
                                          zIndex: 99999999,
                                          width: "100%",
                                        }}
                                        {...props}
                                      />
                                    )}
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          zIndex: 99999999,
                                          maxHeight: "300px",
                                          overflowY: "scroll",
                                        },
                                      },
                                    }}
                                    sx={{
                                      border: "1px solid  #D4D4D8",
                                      borderRadius: "6px",
                                      textTransform: "capitalize",

                                      "&& fieldset": {
                                        border: "none",
                                      },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        variant="outlined"
                                        label="Select"
                                        {...params}
                                        size="small"
                                        sx={{
                                          textTransform: "capitalize",
                                          fontSize: "14px",
                                          color: "#142C44",
                                          "& .MuiInputLabel-root": {
                                            fontSize: "13px",
                                            background: "#fff",
                                            padding: "0 5px",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                                <div className={styles.info}>
                                  Select a Branch to Manage access to it's
                                  Nurture Board.
                                </div>
                              </Box>
                            )} */}
                            </React.Fragment>
                          )
                        )}{" "}
                    </>
                  </>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  );
}

export default CustomRolePermissions;
