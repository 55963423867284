import React from "react";
import IconDropdown from "../../../../../../components/Dropdowns/IconDropdown";
import styles from "./LeadSession.module.css";
import editIcon from "../../../../../../assets/images/edit-icon.svg";
import SessionBoxes from "./SessionBoxes";
import BasicSessionDetails from "./BasicSessionDetails";

function LeadSessionDetail(props: any) {
  const {
		hasLeadAccess,
		leadData,
		setIsPayEditOpen,
		leadEmiData,
		EditRecordPayment,
		SendPaymentLink,
		isFirstPaymentRequestedOrFailed,
		filteredPaymentRequests,
		selectedCurrency,
	} = props;
	const discountAmount =
		leadData?.paymentDetails?.totalAmount -
		leadData?.paymentDetails?.finalAmount;

	return (
		<div>
			<div
				style={{
					border: '1px solid rgba(102, 112, 133, 0.12)',
					borderRadius: '6PX',
					marginBottom: '15px',
				}}>
				<div className={styles.paymentDetailsHead}>
					<div className={styles.paymentDetailsHeadLeft}>
						<div className={styles.paymentDetailsHeadLeftItem}>
							<div className={styles.paymentDetailsHeadItemLabel}>Price</div>
							<div
								className={styles.paymentDetailsHeadItemValue}
								style={{ fontFamily: 'Roboto' }}>
								{selectedCurrency + ' ' + leadData?.paymentDetails?.totalAmount}
							</div>
						</div>

						<div className={styles.paymentDetailsHeadLeftItem}>
							<div className={styles.paymentDetailsHeadItemLabel}>Session</div>
							<div className={styles.paymentDetailsHeadItemValue}>1</div>
						</div>
						<div className={styles.paymentDetailsHeadLeftItem}>
							<div className={styles.paymentDetailsHeadItemLabel}>Discount</div>
							<div
								className={styles.paymentDetailsHeadItemValue}
								style={{ fontFamily: 'Roboto' }}>
								{selectedCurrency + ' ' + discountAmount}
							</div>
						</div>
					</div>
					<div className={styles.paymentDetailsHeadRight}>
						<div className={styles.paymentDetailsHeadFinalPriceWrap}>
							<div className={styles.paymentDetailsHeadItemLabel}>
								Final Price
							</div>
							<div
								className={styles.paymentDetailsHeadFinalPrice}
								style={{ fontFamily: 'Roboto' }}>
								{selectedCurrency + ' ' + leadData?.paymentDetails?.finalAmount}
							</div>
						</div>
						<div>
							{hasLeadAccess && (
								<IconDropdown
									options={[
										{
											label: 'Edit',
											onClick: () => {
												setIsPayEditOpen(true);
											},
											icon: editIcon,
											style: { color: '#667085' },
										},
									]}
								/>
							)}
						</div>
					</div>
				</div>
				{leadEmiData?.sessionPayments?.id && (
					<div style={{ margin: '0px 10px ' }}>
						<BasicSessionDetails
							hasLeadAccess={hasLeadAccess}
							sessionPayments={leadEmiData?.sessionPayments}
							leadData={leadData}
							SendPaymentLink={SendPaymentLink}
							EditRecordPayment={EditRecordPayment}
							isFirstPaymentRequestedOrFailed={isFirstPaymentRequestedOrFailed}
							filteredPaymentRequests={filteredPaymentRequests}
							selectedCurrency={selectedCurrency}
						/>
					</div>
				)}
			</div>

			<SessionBoxes
				leadSessionInfo={leadEmiData?.userSessionInfo}
				leadData={leadData}
				hasLeadAccess={hasLeadAccess}
				selectedCurrency={selectedCurrency}
			/>
		</div>
	);
}

export default LeadSessionDetail;
