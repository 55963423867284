import { Box, FormGroup } from "@mui/material";
import React, { useState } from "react";
import ActivityList from "../ActivityList/ActivityList";
import styles from "../ActivityList/ActivityList.module.css";
import FilterDrawer from "../FilterDrawer/FilterDrawer";
import FilterTypeWrap from "../FilterDrawer/FilterTypeWrap";
import FilterCheckBoxWrap from "../FilterDrawer/FilterCheckBoxWrap";
import FilterCheckbox from "../FilterDrawer/FilterCheckbox/FilterCheckbox";
import FilterChipsWrap from "../FilterDrawer/FilterChips/FilterChipsWrap";
import FilterChips from "../FilterDrawer/FilterChips/FilterChips";
import ClearFilter from "../ClearFilter/ClearFilter";

const activityFilters = [
  {
    activityType: "STAGE",
    labelName: "Stage",
  },
  {
    activityType: "STATUS",
    labelName: "Status",
  },
  {
    activityType: "BATCH",
    labelName: "Batch",
  },
  {
    activityType: "BRANCH",
    labelName: "Branch",
  },
  {
    activityType: "DEMO",
    labelName: "Demo",
  },
  {
    activityType: "NEGOTIATION",
    labelName: "Negotiation",
  },
  {
    activityType: "NOTES",
    labelName: "Notes",
  },
  {
    activityType: "TASKS",
    labelName: "Tasks",
  },
  {
    activityType: "PAYMENT",
    labelName: "Payment",
  },
  {
    activityType: "EMAILS",
    labelName: "Emails",
  },
  {
    activityType: "CALL",
    labelName: "Call",
  },
  {
    activityType: "COMMENT",
    labelName: "Comment",
  },
];

function LeadActivityTab(props: any) {
  const { leadActivityList, employees, selectedCurrency } = props;

  interface CheckedActivityType {
    activityType: string;
  }

  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = React.useState<boolean>(false);

  const [checkedActivityTypes, setCheckedActivityTypes] = useState<
    CheckedActivityType[]
  >([]);
  const [filterActivityTypes, setFilterActivityTypes] = useState<
    CheckedActivityType[]
  >([]);

  const filteredActivityList = leadActivityList?.filter((item: any) => {
    const noteTypes = [
      "NOTES",
      "NOTE_ADDED",
      "NOTE_EDITED",
      "NOTE_DELETED",
      "NOTE_ADDED_POST_CALL_FEEDBACK",
    ];
    const taskTypes = [
      "TASK",
      "TASK_ADDED",
      "TASK_EDITED",
      "TASK_DELETED",
      "TASK_COMPLETED",
      "TASK_STATE_CHANGED",
      "LEAD_ADDED",
      "CREATED",
      "TASK_ADDED_POST_CALL_FEEDBACK",
    ];
    const demoTypes = [
      "DEMO",
      "DEMO_BOOKED",
      "DEMO_RESCHEDULED",
      "DEMO_CANCELLED",
    ];
    const statusTypes = ["STATUS_CHANGE_TO_LOST", "STATUS_CHANGE_TO_ACTIVE"];
    const stageTypes = ["STATUS_CHANGE", "STAGE_CHANGED"];
    const branchTypes = ["BRANCH_EDITED"];
    const batchTypes = ["BATCH_ADDED", "BATCH_EDITED"];
    const paymentTypes = [
      "PAYMENT",
      "PAYMENT_RECORDED",
      "REFUND_RECORDED",
      "PAYMENT_CANCELLED",
      "PAYMENT_REQUESTED",
      "PAYMENT_EXPIRED",
      "PAYMENT_FAILED",
    ];
    const negotiationTypes = ["NEGOTIATION_SAVED", "NEGOTIATION_EDITED"];
    const inboxTypes = ["INBOX_EMAIL_SENT", "INBOX_EMAIL_RECEIVED"];
    const callTypes = [
      "OZONE_INITIATED_CALL",
      "OZONE_MISSED_CALL",
      "OZONETEL_RECORDED",
      "OZONETEL_MISSED_CALL",
      "OZONETEL_INCOMING",
    ];
    const commentTypes = [
      "COMMENT_ADDED_TO_TASK",
      "COMMENT_EDITED_ON_TASK",
      "COMMENT_DELETED_FROM_TASK",
    ];

    const isNotesFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "NOTES"
    );

    const isTaskFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "TASKS"
    );

    const isDemoFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "DEMO"
    );

    const isStatusFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "STATUS"
    );

    const isStageFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "STAGE"
    );

    const isBranchFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "BRANCH"
    );

    const isBatchFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "BATCH"
    );

    const isPaymentFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "PAYMENT"
    );

    const isNegotiationFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "NEGOTIATION"
    );

    const isInboxFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "EMAIL"
    );

    const isCallFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "CALL"
    );

    const isCommentFilterActive = filterActivityTypes.some(
      (type) => type.activityType === "COMMENT"
    );

    const isActive =
      (isNotesFilterActive && noteTypes.includes(item.activityType)) ||
      (isTaskFilterActive && taskTypes.includes(item.activityType)) ||
      (isDemoFilterActive && demoTypes.includes(item.activityType)) ||
      (isStatusFilterActive && statusTypes.includes(item.activityType)) ||
      (isStageFilterActive && stageTypes.includes(item.activityType)) ||
      (isBranchFilterActive && branchTypes.includes(item.activityType)) ||
      (isBatchFilterActive && batchTypes.includes(item.activityType)) ||
      (isPaymentFilterActive && paymentTypes.includes(item.activityType)) ||
      (isNegotiationFilterActive &&
        negotiationTypes.includes(item.activityType)) ||
      (isInboxFilterActive && inboxTypes.includes(item.activityType)) ||
      (isCallFilterActive && callTypes.includes(item.activityType)) ||
      (isCommentFilterActive && commentTypes.includes(item.activityType));

    return filterActivityTypes.length === 0 || isActive;
  });

  const handleFilterActivityType = React.useCallback(
    (activityType: any) => {
      // Check if the activity type is already in the checkedActivityTypes array
      const isActivityTypeChecked = checkedActivityTypes.some(
        (item) => item.activityType.toLowerCase() === activityType.toLowerCase()
      );

      if (isActivityTypeChecked) {
        // If the activity type is already checked, remove it from the array
        setCheckedActivityTypes((prevCheckedActivityTypes) =>
          prevCheckedActivityTypes.filter(
            (item) =>
              item.activityType.toLowerCase() !== activityType.toLowerCase()
          )
        );
      } else {
        // If the activity type is not checked, add it to the array
        setCheckedActivityTypes((prevCheckedActivityTypes) => [
          ...prevCheckedActivityTypes,
          { activityType },
        ]);
      }
    },
    [checkedActivityTypes]
  );

  const clearCheckedActivityType = (type: string) => {
    setCheckedActivityTypes(
      checkedActivityTypes.filter((item) => item?.activityType !== type)
    );
  };

  const handleFilterActivities = React.useCallback(
    (typeArray: any) => {
      setFilterActivityTypes(typeArray);
    },
    [setFilterActivityTypes]
  );

  const handleFilterApply = React.useCallback(() => {
    handleFilterActivities(checkedActivityTypes);

    setFilterApplied(checkedActivityTypes?.length === 0 ? false : true);
  }, [handleFilterActivities, setFilterApplied, checkedActivityTypes]);

  const handleResetClick = React.useCallback(() => {
    setFilterApplied(false);
    setFilterActivityTypes([]);
    setCheckedActivityTypes([]);
  }, [setFilterApplied, setCheckedActivityTypes, setFilterActivityTypes]);

  const filteredValue = (
    <>
      {filterActivityTypes?.length > 0 &&
        filterActivityTypes?.map((item) => (
          <div key={item.activityType} className="filteredDiv">
            {item.activityType?.toLowerCase()}
          </div>
        ))}
    </>
  );

  const cancelClick = () => {
    setIsOpenFilter(true);
  };

  const closeClearFilter = () => {
    setIsOpenFilter(false);
  };

  const yesButtonClick = () => {
    handleResetClick();
    setIsOpenFilter(false);
  };

  const noButtonClick = () => {
    setIsOpenFilter(false);
  };

  const handleFilterClose = React.useCallback(() => {
    setCheckedActivityTypes(filterActivityTypes);
  }, [filterActivityTypes]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadActivityId = urlParams.get("leadActivityId");
    if (leadActivityId) {
      const element = document.getElementById(leadActivityId);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [leadActivityList]);

  return (
		<>
			<div className={styles.leadTabActivityHead}>
				<div className={styles.leadTabActivityTitle}>Activity</div>

				<FilterDrawer
					handleFilterApply={handleFilterApply}
					handleResetClick={handleResetClick}
					activeFilter={filterApplied}
					cancelClick={cancelClick}
					closeSetFilter={handleFilterClose}
					// insidePopup={true}
				>
					<div
						style={{
							padding: '25px 30px',
							overflowY: 'auto',
							maxHeight: 'calc(100% - 110px) ',
						}}>
						<FilterChipsWrap>
							{checkedActivityTypes?.length > 0 &&
								checkedActivityTypes?.map((item) => (
									<FilterChips
										keyVal={item.activityType}
										chipName={item.activityType?.toLowerCase()}
										clearValue={item.activityType}
										closeFunction={clearCheckedActivityType}
									/>
								))}
						</FilterChipsWrap>
						<FilterTypeWrap>
							<FilterCheckBoxWrap scroll={false}>
								<FormGroup>
									{activityFilters?.map((item: any) => (
										<FilterCheckbox
											keyVal={item.activityType}
											checked={checkedActivityTypes?.some(
												(data) =>
													data.activityType.toLowerCase() ===
													item.activityType.toLowerCase()
											)}
											checkboxFunction={() =>
												handleFilterActivityType(item.activityType)
											}
											label={item?.labelName?.toLowerCase()}
										/>
									))}
								</FormGroup>
							</FilterCheckBoxWrap>
						</FilterTypeWrap>
					</div>
				</FilterDrawer>
				<ClearFilter
					open={isOpenFilter}
					noButtonClick={noButtonClick}
					yesButtonClick={yesButtonClick}
					onClose={closeClearFilter}
					filteredValue={filteredValue}
				/>
			</div>
			{filteredActivityList?.length > 0 && (
				<Box
					sx={{
						maxHeight: 'calc(98vh - 224px)',
						height: '100%',
						overflowY: 'auto',
						pt: 2,
						backgroundColor: 'white',
						borderRadius: '3PX',
						paddingBottom: '20px',
					}}>
					{filteredActivityList?.map((item: any, index: any) => (
						<div key={index}>
							<ActivityList
								employees={employees}
								activeTab={'activityList'}
								data={item}
								index={index}
								listData={filteredActivityList}
								selectedCurrency={selectedCurrency}
							/>
						</div>
					))}
				</Box>
			)}
		</>
	);
}

export default LeadActivityTab;
