import React, { useEffect } from "react";
import CampaignForm from "../components/campaignForm";
import ScratchCardComponent from "../components/scratchCardComponent";
import { CircularProgress, Box } from "@mui/material";
import SuccessPage from "./successPage";
import ToastNotification from "../../../../components/ToastNotification/ToastNotification";

const Campaign = (props: any) => {
  const {
    success,
    loading,
    error,
    contactUpdateSuccess,
    contactUpdateLoading,
    contactUpdateError,
    contactDetails,
    CreateContact,
    UpdateContact,
  } = props;

  useEffect(() => {
    // Add the 'hide-widget' class to the body when the component mounts
    document.body.classList.add("hide-widget");

    // Clean up function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove("hide-widget");
    };
  }, []);

  return (
    <>
      <ToastNotification />
      {!success && (
        <CampaignForm
          CreateContact={CreateContact}
          error={error}
          loading={loading}
        />
      )}
      {success && <SuccessPage />}
    </>
  );
};

export default Campaign;
