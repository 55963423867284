import React, { useState } from "react";
import { Box, Tooltip } from "@mui/material";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import highFlagIcon from "../FlagAssign/assets/High.svg";
import urgentFlagIcon from "../FlagAssign/assets/Urgent.svg";
import normalFlagIcon from "../FlagAssign/assets/Normal.svg";
import lowFlagIcon from "../FlagAssign/assets/Low.svg";
import TaskStatusPopup from "../TaskStatusCheckboxPopup/TaskStatusPopup";
import DeletePopup from "../DeletePopup/DeletePopup";
import { useAuthUser } from "react-auth-kit";
import { resetDeleteTaskSuccess } from "../../pages/app/Nurture/service/nurtureSlice";
import { useDispatch } from "react-redux";
import { convertToAdjustedTimestamp } from "../Formatter/Formatter";
import TaskEditPopup from "./TaskEditPopup/TaskEditPopup";
import checkCircle from "../../assets/images/check-circle.svg";
import checkCircleOutline from "../../assets/images/round-check.svg";
import commentIcon from "../../assets/images/commentIcon.svg";
import AddComment from "./AddComment/AddComment";
import CommentListItem from "./AddComment/CommentListItem";
import { useSelector } from "react-redux";
import getAccessStatus, {
  getSubDomainAccessStatus,
} from "../RolePermissionAccess/RolePermissionAccess";
import getTeamMember from "../CommonFunctions/CommonFunctions";
import { getDescriptionWithMentionedNames } from "../CommonFunctions/CommonFunctions";

function TaskListItem(props: any) {
  const {
    data,
    DeleteTask,
    setFromTaskEdit,
    setTaskForEditing,
    setShowAddTask,
    showAddTask,
    UpdateTaskStatus,
    employees,
    activityList,
    selectedLeadData,
    updateTaskStatusSuccess,
    CreateComment,
    DeleteComment,
    EditComment,
    leadById,
    taskIdForComment,
    setTaskIdForComment,
    listView,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const hasLeadAccess = getSubDomainAccessStatus(
    "NURTURE_BOARD",
    "LEAD_CARD",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const hasAllTaskAccess = getAccessStatus(
    "TASKS",
    "ALL_OTHER_USERS'_TASK",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [dataForDelete, setDataForDelete] = useState<any>();
  const { deleteTaskLoading } = useSelector((state: any) => state.nurture);

  const handleDeleteButtonClick = () => {
    setDataForDelete(data);
    setShowDeletePopup(true);
  };
  const handleEditButtonClick = (val: any) => {
    setShowAddTask(!showAddTask);
    setTaskForEditing(val);
    setFromTaskEdit(true);
  };

  const handlePopupYesButton = () => {
    if (authUser && authUser?.institute[0]) {
      const data = {
        idsToDelete: [dataForDelete?.id],
        instituteId: authUser?.institute[0]?.instituteId,
        enquiryId: selectedLeadData?.id,
        studentName:
          selectedLeadData?.studentFirstName +
          selectedLeadData?.studentLastName,
        studentEmail: selectedLeadData?.studentEmail,
        swimlaneState: selectedLeadData?.enquirySwimlaneStatus,
        updatedById: authUser?.institute[0]?.id,
        updatedByName: authUser?.firstName + " " + authUser?.lastName,
        taskData: dataForDelete,
        actionToPerform: "DELETE_TASK",
      };
      DeleteTask(data);
      setTimeout(() => {
        dispatch(resetDeleteTaskSuccess());
      }, 3000);
    }
    setShowDeletePopup(false);
  };

  const handlePopupNoButton = () => {
    setShowDeletePopup(false);
  };

  const modifiedDescription = getDescriptionWithMentionedNames(
    activityList ? (data?.taskData?.description, employees) : data?.description,
    employees
  ).replace(/\n/g, "<br>");

  const creatorId = authUser?.institute?.[0]?.id;

  const [taskId, setTaskId] = useState<string>("");
  const [commentIdFromUrl, setCommentIdFromUrl] = useState<string>("");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadTaskId = urlParams.get("leadTaskId");
    if (leadTaskId) {
      setTaskId(leadTaskId);
    }
    const leadCommentId = urlParams.get("leadTaskCommentId");
    if (leadCommentId) {
      setCommentIdFromUrl(leadCommentId);
    }
    const timeoutId = setTimeout(() => {
      setTaskId("");
      setCommentIdFromUrl("");
      urlParams.delete("leadTaskId");
      urlParams.delete("leadTaskCommentId");

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }, 5000); // 15 seconds

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const [showAddComment, setShowAddComment] = useState(false);
  const [fromCommentEdit, setFromCommentEdit] = useState<boolean>(false);
  const [CommentForEditing, setCommentForEditing] = useState<any>();

  const handleAddComment = () => {
    setShowAddComment(!showAddComment);
    setFromCommentEdit(false);
    setTaskIdForComment(data?.id);
  };

  const handleCancelComment = () => {
    setShowAddComment(false);
    setFromCommentEdit(false);
    setTaskIdForComment("");
  };

  React.useEffect(() => {
    if (taskIdForComment) {
      if (data?.id !== taskIdForComment) {
        setShowAddComment(false);
      }
    }
  }, [taskIdForComment]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
          background:
            taskId === data?.id && commentIdFromUrl === ""
              ? "#e8faf6"
              : "transparent",
          padding:
            taskId === data?.id && commentIdFromUrl === ""
              ? "0 5px 5px 5px"
              : "0px",
          borderRadius:
            taskId === data?.id && commentIdFromUrl === "" ? "4px" : "0px",
          transition: "0.1s ease-in-out",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityHead}>
            <div className={styles.activityTop}>
              <div className={styles.activityHeadTaskStatus}>
                {(creatorId === data?.assignedToId || hasAllTaskAccess) &&
                  data?.taskType !== "DEMO" && (
                    <div className={styles.activityHeadTaskStatusCheckbox}>
                      <TaskStatusPopup
                        data={data}
                        UpdateTaskStatus={UpdateTaskStatus}
                        updateTaskStatusSuccess={updateTaskStatusSuccess}
                      />
                    </div>
                  )}
                {(data?.taskType === "DEMO" ||
                  (creatorId !== data?.assignedToId && !hasAllTaskAccess)) && (
                  <div
                    className={styles.activityHeadTaskStatusValue}
                    style={{
                      color:
                        data?.status === "TO_DO"
                          ? "#667085"
                          : data?.status === "IN_PROGRESS"
                          ? "#2664DC"
                          : data?.status === "BLOCKED"
                          ? "#EC3939"
                          : data?.status === "COMPLETED"
                          ? "#23A67B"
                          : "#2664DC",
                      background:
                        data?.status === "TO_DO"
                          ? "rgba(102, 112, 133, 0.10)"
                          : data?.status === "IN_PROGRESS"
                          ? "rgba(38, 100, 220, 0.07)"
                          : data?.status === "BLOCKED"
                          ? "rgba(236, 57, 57, 0.07)"
                          : data?.status === "COMPLETED"
                          ? "rgba(35, 166, 123, 0.06)"
                          : "rgba(35, 166, 123, 0.06)",
                    }}
                  >
                    {/* <img
                      src={
                        data?.status !== "COMPLETED"
                          ? checkCircleOutline
                          : checkCircle
                      }
                      alt="check"
                      style={{ marginRight: "6px" }}
                    /> */}
                    {data?.status?.toLowerCase()?.replace(/_/g, " ")}
                  </div>
                )}
              </div>
              <div className={styles.activityHeadStage}>
                <div className={styles.activityHeadStageIcon}>
                  <img src={stageDot} alt="" />
                </div>
                <div>
                  {data?.enquirySwimlaneStatus
                    .toLowerCase()
                    ?.replace(/_/g, " ")}
                </div>
              </div>
              <div className={styles.activityHeadName}>
                <div className={styles.activityHeadStageIcon}>
                  <img src={nameLine} alt="" />
                </div>
                <div style={{ textTransform: "capitalize" }}>
                  {/* {data?.createdByName} */}
                  {getTeamMember(data?.createdById, employees)?.firstName ===
                  undefined
                    ? data?.createdByName
                    : getTeamMember(data?.createdById, employees)?.firstName +
                      " " +
                      getTeamMember(data?.createdById, employees)?.lastName}
                </div>
              </div>
            </div>
            {data?.taskType !== "DEMO" && (
              <>
                {data?.status === "COMPLETED" && (
                  <div style={{ color: "#11C89B", fontSize: "12px" }}>
                    Completed On{" "}
                    {data?.taskCompletedDate &&
                      moment(data?.taskCompletedDate).format(
                        "DD MMM YYYY, hh:mm A"
                      )}
                  </div>
                )}
                {data?.status !== "COMPLETED" &&
                (creatorId === data?.createdById ||
                  creatorId === data?.assignedToId ||
                  hasAllTaskAccess) ? (
                  <>
                    {showDeletePopup && (
                      <DeletePopup
                        type={"task"}
                        handlePopupYesButton={handlePopupYesButton}
                        handlePopupNoButton={handlePopupNoButton}
                        loading={deleteTaskLoading}
                      />
                    )}
                    <TaskEditPopup
                      handleDeleteButtonClick={handleDeleteButtonClick}
                      handleEditButtonClick={handleEditButtonClick}
                      data={data}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          {/* <div className={styles.activityDescription}>{data?.description}</div> */}
          <div
            className={styles.activityDescription}
            dangerouslySetInnerHTML={{ __html: modifiedDescription }}
          />

          <div className={styles.activityBottom}>
            <div className={styles.activityBottomLeft}>
              {data && data.assignedToName && (
                <div className={styles.activityBottomAssign}>
                  {getTeamMember(data?.assignedToId, employees)
                    ?.firstName?.charAt(0)
                    ?.toUpperCase()}
                  {getTeamMember(data?.assignedToId, employees)
                    ?.lastName?.charAt(0)
                    ?.toUpperCase()}
                  {/* {data?.assignedToName
                    ?.split(" ")
                    ?.slice(0, 2)
                    ?.map((word: any, index: number) => (
                      <span key={index}>{word?.charAt(0)}</span>
                    ))} */}
                </div>
              )}
              {data?.taskType !== "DEMO" && (
                <div className={styles.activityBottomFlag}>
                  <div className={styles.activityBottomFlagImg}>
                    <img
                      src={
                        data?.priority === "URGENT"
                          ? urgentFlagIcon
                          : data?.priority === "HIGH"
                          ? highFlagIcon
                          : data?.priority === "NORMAL"
                          ? normalFlagIcon
                          : data?.priority === "LOW"
                          ? lowFlagIcon
                          : lowFlagIcon
                      }
                      alt=""
                    />
                  </div>
                </div>
              )}
              <div
                className={styles.activityDateStyle}
                // style={{
                //   color:
                //     data?.taskType !== "DEMO"
                //       ? moment(data.date) < moment()
                //         ? "red"
                //         : "inherit"
                //       : moment(data?.demoData[0]?.demoTiming).format("DD MMM") <
                //         moment()
                //       ? "red"
                //       : "inherit",
                // }}
                style={{
                  color:
                    data?.taskType !== "DEMO"
                      ? moment(data?.date).isBefore(moment()) // Use isBefore for date comparison
                        ? "red"
                        : "inherit"
                      : moment(data?.demoData?.[0]?.demoTiming).isBefore(
                          moment()
                        ) &&
                        (data?.demoData?.[0]?.demoStartTime?.toString()
                          ?.length === 10
                          ? moment()
                              .startOf("day")
                              .add({
                                hours: moment
                                  .unix(data?.demoData?.[0]?.demoStartTime)
                                  .hour(),
                                minutes: moment
                                  .unix(data?.demoData?.[0]?.demoStartTime)
                                  .minute(),
                              })
                              .add(30, "minutes")
                              .isBefore(moment())
                          : moment()
                              .startOf("day")
                              .add({
                                hours: moment(
                                  data?.demoData?.[0]?.demoStartTime
                                ).hour(),
                                minutes: moment(
                                  data?.demoData?.[0]?.demoStartTime
                                ).minute(),
                              })
                              .add(30, "minutes")
                              .isBefore(moment()))
                      ? "red"
                      : "inherit",
                  //  : moment(data?.demoData[0]?.demoTiming).isBefore(moment())
                  // ? "red"
                  // : "inherit",
                }}
              >
                Due on{" "}
                {data?.taskType !== "DEMO"
                  ? moment(data?.date).format("DD MMM YYYY,hh:mm A")
                  : moment(data?.demoData[0]?.demoTiming).format("DD MMM") +
                    " " +
                    (data?.demoData[0]?.demoStartTime?.toString()?.length === 10
                      ? moment
                          .unix(data?.demoData[0]?.demoStartTime)
                          .add(30, "minutes")
                          .format("hh:mm A")
                      : moment(data?.demoData[0]?.demoStartTime)
                          .add(30, "minutes")
                          .format("hh:mm A"))}
              </div>
            </div>
            {!listView && (
              <div>
                {!showAddComment && (
                  <Tooltip
                    arrow
                    title={
                      hasLeadAccess === false
                        ? "Oops! No permission."
                        : "Add comment"
                    }
                    // title={"Add comment"}
                    PopperProps={{ style: { zIndex: 9999999 } }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#101828",
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "8px 12px",
                          "& .MuiTooltip-arrow": {
                            color: "#101828",
                          },
                        },
                      },
                    }}
                  >
                    <span
                      style={{
                        width: "100%",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <button
                        onClick={handleAddComment}
                        className={styles.commentBtn}
                        disabled={hasLeadAccess === false ? true : false}
                      >
                        <img src={commentIcon} alt="commentIcon" />
                        {/* <div className={styles.commentBtnPopover}>
                          <div className={styles.commentBtnPopoverChild}>
                            Add comment
                          </div>
                        </div> */}
                      </button>
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>

          {!listView && (
            <>
              {showAddComment && data?.id === taskIdForComment && (
                <AddComment
                  handleAddComment={handleAddComment}
                  employees={employees}
                  CreateComment={CreateComment}
                  EditComment={EditComment}
                  leadById={leadById}
                  taskId={data?.id}
                  fromCommentEdit={fromCommentEdit}
                  CommentForEditing={CommentForEditing}
                  handleCancelComment={handleCancelComment}
                />
              )}
              {!showAddComment && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {data?.commentList?.map((item: any, index: any) => (
                    <CommentListItem
                      key={index}
                      employees={employees}
                      DeleteComment={DeleteComment}
                      commentData={item}
                      setShowAddComment={setShowAddComment}
                      setFromCommentEdit={setFromCommentEdit}
                      setCommentForEditing={setCommentForEditing}
                      leadById={leadById}
                      setTaskIdForComment={setTaskIdForComment}
                      taskId={data?.id}
                      commentIdFromUrl={commentIdFromUrl}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default TaskListItem;
