import React, { useCallback, useState } from "react";
import {
  Typography,
  Box,
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@mui/material";
import styles from "./AddCustomRole.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import CustomRolePermissions from "../CustomRolePermissions/CustomRolePermissions";
import RoleCancelPopup from "../RoleCancelPopup/RoleCancelPopup";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import RoleBackPopup from "../RoleCancelPopup/RoleBackPopup";
import { resetSettingsStatuses } from "../../../../service/settingsSlice";
import { useDispatch } from "react-redux";
import e from "express";

function AddCustomRole(props: any) {
  const {
    GetBranchList,
    CreateCustomRole,
    GetRoleDomainStructure,
    rolesList,
    roleDomainStructure,
    createCustomRoleLoading,
    GetRolesByInstitute,
    createCustomRoleSuccess,
  } = props;

  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const combinedRoles = rolesList?.systemRoles?.concat(rolesList?.customRoles);

  const [roleDetails, setRoleDetails] = React.useState({
    roleName: "",
    description: "",
    roleStatus: "ACTIVE",
    roleFor: "NURTURE", //MICO,NURTURE,MARKET_PLACE
    roleType: "CUSTOM", // DEFAULT,CUSTOM,
    permissions: [],
    instituteId: "",
    createdByName: "",
    createdById: "",
    updatedByName: "",
    updatedById: "",
  });
  const [treeStructure, setTreeStructure] = useState<any>();
  const [roleError, setRoleError] = React.useState<boolean>(false);
  const [roleErrorDesc, setRoleErrorDesc] = React.useState<boolean>(false);

  const branchList = useSelector((state: any) => state.branch.branchList);

  const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
  const [showBackPopup, setShowBackPopup] = useState<boolean>(false);

  const handleCancelConfirmed = () => {
    setShowCancelPopup(false);
    setRoleDetails({
      ...roleDetails,
      roleName: "",
      description: "",
      roleStatus: "ACTIVE",
      roleFor: "NURTURE",
      roleType: "CUSTOM",
      permissions: [],
      instituteId: "",
      createdByName: "",
      createdById: "",
      updatedByName: "",
      updatedById: "",
    });
  };

  const handleCancelCancelled = () => {
    setShowCancelPopup(false);
  };

  const handleBackConfirmed = () => {
    setShowBackPopup(false);
    navigate(`/app/Settings/RolesandPermission`);
  };

  const handleBackCancelled = () => {
    setShowBackPopup(false);
  };

  const handleChange = React.useCallback(
    (event: any) => {
      const modifiedValue = event.target.value
        .toUpperCase()
        .replace(/\s/g, "_");

      setRoleDetails({
        ...roleDetails,
        [event.target.id]: event.target.value,
      });

      if (event.target.id === "roleName") {
        const matchedRole = combinedRoles?.find(
          (role: any) => modifiedValue === role.roleCode
        );

        if (matchedRole) {
          setRoleError(true);
        } else {
          setRoleError(false);
        }
      }

      if (event.target.id === "description") {
        if (event.target.value === "") {
          setRoleErrorDesc(true);
        } else {
          setRoleErrorDesc(false);
        }
      }
    },
    [roleDetails, combinedRoles]
  );

  const handleDomainCheckBoxChange = useCallback(
    (
      checked: any,
      domainId: any,
      permissionAttributeId: any,
      permissionAttributeCode: any
    ) => {
      // Find the domain object in the tree structure

      const updatedData = treeStructure?.map((item: any) => {
        if (item.id === domainId) {
          // Find the permission object in the accessPermissions array
          const updatedAccessPermissions = item?.accessPermissions?.map(
            (permission: any) => {
              if (permission.permissionAttributeId === permissionAttributeId) {
                return {
                  ...permission,
                  accessStatus: checked,
                };
              }
              return permission;
            }
          );

          let newData: any;

          if (checked && permissionAttributeCode === "ALL_ACCESS") {
            newData = updatedAccessPermissions?.map((permission: any) => {
              if (permission.permissionAttributeCode === "VIEW_ONLY") {
                return {
                  ...permission,
                  accessStatus: true,
                };
              }

              return permission;
            });
          } else if (checked && permissionAttributeCode === "VIEW_ONLY") {
            newData = updatedAccessPermissions?.map((permission: any) => {
              if (permission.permissionAttributeCode === "ALL_ACCESS") {
                return {
                  ...permission,
                  accessStatus: false,
                };
              }

              return permission;
            });
          } else {
            newData = updatedAccessPermissions;
          }

          return {
            ...item,
            accessPermissions: newData,
          };
        }
        return item;
      });

      setTreeStructure(updatedData);
    },
    [treeStructure]
  );

  const handleSubDomainCheckBoxChange = useCallback(
    (
      checked: any,
      domainId: any,
      subDomainId: any,
      permissionAttributeId: any,
      permissionAttributeCode: any
    ) => {
      const updatedData = treeStructure?.map((item: any) => {
        if (item.id === domainId) {
          const updatedSubDomainData = item?.subDomain?.map((subItem: any) => {
            if (subItem.id === subDomainId) {
              const updatedAccessPermissions = subItem?.accessPermissions?.map(
                (permission: any) => {
                  if (
                    permission.permissionAttributeId === permissionAttributeId
                  ) {
                    return {
                      ...permission,
                      accessStatus: checked,
                    };
                  }
                  return permission;
                }
              );

              let newData: any;

              if (checked && permissionAttributeCode === "ALL_ACCESS") {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "VIEW_ONLY") {
                    return {
                      ...permission,
                      accessStatus: true,
                    };
                  }

                  return permission;
                });
              } else if (checked && permissionAttributeCode === "VIEW_ONLY") {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "ALL_ACCESS") {
                    return {
                      ...permission,
                      accessStatus: false,
                    };
                  }

                  return permission;
                });
              } else if (checked && permissionAttributeCode === "OWN_BRANCH") {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "ALL_BRANCHES") {
                    return {
                      ...permission,
                      accessStatus: false,
                    };
                  }

                  return permission;
                });
              } else if (
                checked &&
                permissionAttributeCode === "ALL_BRANCHES"
              ) {
                newData = updatedAccessPermissions?.map((permission: any) => {
                  if (permission.permissionAttributeCode === "OWN_BRANCH") {
                    return {
                      ...permission,
                      accessStatus: true,
                    };
                  }

                  return permission;
                });
              } else {
                newData = updatedAccessPermissions;
              }

              return {
                ...subItem,
                accessPermissions: newData,
              };
            }
            return subItem;
          });

          return {
            ...item,
            subDomain: updatedSubDomainData,
          };
        }
        return item;
      });

      setTreeStructure(updatedData);
    },
    [treeStructure]
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const updatedTreeStructure = treeStructure?.map((item: any) => ({
        roleDomainId: item.id,
        roleDomainName: item.roleDomainName,
        roleDomainParentId: item?.roleDomainParentId,
        roleDomainParentName: item.roleDomainParentName,
        accessPermissions: item?.accessPermissions,
        roleDomainCode: item?.roleDomainCode,
        domainIndex: item?.domainIndex,
        subDomain:
          item?.subDomain?.length > 0
            ? item?.subDomain?.map((subItem: any) => ({
                roleDomainId: subItem.id,
                roleDomainName: subItem.roleDomainName,
                roleDomainParentId: subItem?.roleDomainParentId,
                roleDomainParentName: subItem.roleDomainParentName,
                roleDomainCode: subItem?.roleDomainCode,
                accessPermissions: subItem?.accessPermissions,
                domainIndex: subItem?.domainIndex,
              }))
            : [],
      }));
      let descriptionError = false;
      if (roleDetails?.description === "") {
        descriptionError = true;
        setRoleErrorDesc(true);
      } else {
        descriptionError = false;
        setRoleErrorDesc(false);
      }
      !descriptionError &&
        roleDetails.roleName &&
        !roleError &&
        CreateCustomRole({
          ...roleDetails,
          createdById: authUser?.institute?.[0]?.id,
          createdByName: authUser?.firstName + " " + authUser?.lastName,
          instituteId: authUser?.institute[0]?.instituteDetails?.id,
          permissions: updatedTreeStructure,
        });
    } catch (error) {}
  };

  const areObjectsEqual = () => {
    const str1 = JSON.stringify(treeStructure);
    const str2 = JSON.stringify(roleDomainStructure);
    return str1 === str2;
  };
  const isEqual = areObjectsEqual();

  React.useEffect(() => {
    if (authUser) {
      const userFromLocalStorage = authUser?.institute?.[0]?.instituteDetails;
      GetRolesByInstitute(userFromLocalStorage?.id);
      GetRoleDomainStructure();
    }
  }, []);

  React.useEffect(() => {
    if (roleDomainStructure) {
      setTreeStructure(roleDomainStructure);
    }
  }, [roleDomainStructure]);

  React.useEffect(() => {
    if (createCustomRoleSuccess !== true && createCustomRoleSuccess !== false) {
      navigate(`/app/Settings/Rolesandpermission`);
      dispatch(resetSettingsStatuses());
    }
  }, [createCustomRoleSuccess]);

  return (
    <Box sx={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>
      <div className={styles.PageBackBtnWrap}>
        <button
          className={styles.PageBackBtn}
          onClick={() => {
            !isEqual ||
            roleDetails?.roleName !== "" ||
            roleDetails?.description !== ""
              ? setShowBackPopup(true)
              : navigate(`/app/Settings/RolesandPermission`);
          }}
        >
          <ArrowBackIcon
            sx={{
              marginRight: "8px",
              fontSize: "15PX",
              color: "#667085",
            }}
          />
          Back
        </button>
      </div>
      <Box sx={{ padding: "0px 20px 0px 20px", maxWidth: "40%" }}>
        <div className={styles.roleHead}>New Role</div>
        <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
          <Typography
            sx={{
              color: "#142C44",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "18px",
              marginBottom: "6px",
            }}
          >
            Role Name
          </Typography>
          <TextField
            id="roleName"
            variant="outlined"
            sx={{
              "& .MuiInputLabel-root": {
                color: "#B1B1B1",
                fontWeight: "300",
                fontSize: "12px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "10px 16px",
              },
            }}
            value={roleDetails.roleName}
            inputProps={{
              maxLength: 50,
            }}
            error={roleError}
            helperText={
              roleError
                ? "The role name already exists, change it to continue."
                : ""
            }
            onChange={handleChange}
          />
        </FormControl>

        <FormControl sx={{ m: 1, margin: "6px 0", width: "100%" }}>
          <Typography
            sx={{
              color: "#142C44",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "18px",
              marginBottom: "6px",
            }}
          >
            Description
          </Typography>
          <TextField
            id="description"
            placeholder="Max 200 Characters"
            multiline
            variant="outlined"
            onChange={handleChange}
            inputProps={{ maxLength: 200 }}
            value={roleDetails.description}
            sx={{
              "& .MuiInputLabel-root": {
                color: "#B1B1B1",
                fontWeight: "300",
                fontSize: "12px",
              },
              "& .MuiOutlinedInput-root": {
                padding: "14px 10px 58px 14px",
              },
            }}
            error={roleErrorDesc}
            helperText={roleErrorDesc ? "Please add description." : ""}
          />
        </FormControl>
      </Box>
      <CustomRolePermissions
        branchList={branchList && branchList}
        editMode={true}
        treeStructure={treeStructure}
        handleDomainCheckBoxChange={handleDomainCheckBoxChange}
        handleSubDomainCheckBoxChange={handleSubDomainCheckBoxChange}
        editTreeStructure={false}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "20px",
          gap: "6px",
        }}
      >
        {(!isEqual ||
          roleDetails?.roleName !== "" ||
          roleDetails?.description !== "") && (
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #BFBFBF",
              color: "#BFBFBF",
              textTransform: "capitalize",
              "&:hover": {
                border: "1px solid #BFBFBF",
                backgroundColor: "#FFF",
              },
            }}
            onClick={() => setShowCancelPopup(!showCancelPopup)}
          >
            Cancel
          </Button>
        )}

        {showCancelPopup && (
          <RoleCancelPopup
            onClose={handleCancelCancelled}
            showDeletePopup={showCancelPopup}
            onConfirm={handleCancelConfirmed}
          />
        )}
        {showBackPopup && (
          <RoleBackPopup
            onClose={handleBackCancelled}
            showDeletePopup={showBackPopup}
            onConfirm={handleBackConfirmed}
          />
        )}

        <Button
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          onClick={handleSubmit}
          disabled={createCustomRoleLoading || roleDetails?.roleName === ""}
        >
          {createCustomRoleLoading ? (
            <CircularProgress size={20} color={"inherit"} />
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default AddCustomRole;
