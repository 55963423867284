import React, { useEffect, useState } from "react";
import closeIcon from "../../../../../assets/images/close-icon.svg";
import styles from "../components/EditPopup/EditPopup.module.css";
import CancelBtn from "../../../../../components/Buttons/CancelBtn";
import SaveBtn from "../../../../../components/Buttons/SaveBtn";
import AutoCompleteField from "../../../../../components/AutoCompleteField/AutoCompleteField";
import NewFormTextInput from "../../../../../components/FormTextInput/NewFormTextInput";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useDispatch } from "react-redux";
import { ErrorType } from "../../../../../components/FormTextInput/types";
import BatchConductTypeManager from "../components/Manager/BatchConductTypeManager";
import {
  createBatch,
  getBatchList,
  resetBatchStatus,
} from "../service/Batchslice";
import { GetInstituteByIdRequest } from "../../InstituteProfile/service/institute.request";
import { appEntery } from "../../../Home/service/homeSlice";
import { useNavigate } from "react-router-dom";
import { getInstituteById } from "../../InstituteProfile/service/instituteSlice";

function AddBatchPopup(props: any) {
  const { isOpen, onClose } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const branches = useSelector((state: any) => state.branch.branchList);
  const courses = useSelector((state: any) => state.courses.courses);
  const activeCourses = courses?.filter(
    (item: any) => item?.status === "ACTIVE"
  );
  const { batchCreateLoading, batchCreateSuccess, batchId } = useSelector(
    (state: any) => state.batch
  );
  const activeInstitute = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);

  const [batchDetails, setBatchDetails] = React.useState<any>({
    batchName: "",
    courseName: "",
    courseId: "",
    branchName: "",
    branchId: "",
    batchConductType: "",
    status: "DRAFT",
    isFirstBatch: false,
    batchStatus: "",
  });

  const [showErrMsz, setShowErrMsz] = React.useState<boolean>(true);
  const [errorType, setErrorType] = React.useState<ErrorType>("client");
  const [conductType, setConductType] = React.useState<string>("");
  const [conductErrorMszs, setConductErrorMszs] = useState<string>("");

  const [validStateBatchName, setValidStateBatchName] =
    React.useState<boolean>(true);

  const [validStateBranch, setValidStateBranch] = React.useState<boolean>(true);
  const [validStateCourse, setValidStateCourse] = React.useState<boolean>(true);

  const [
    validatingFirstTimeBranchCreation,
    setValidatingFirstTimeBranchCreation,
  ] = useState<any>();

  useEffect(() => {
    const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
    if (activeInstitute?.isProfileCompleted === false && activeInstitute?.id) {
      GetInstituteByIdRequest(activeInstitute?.id)
        ?.then((res: any) => {
          setValidatingFirstTimeBranchCreation(res?.data?.data);
        })
        .catch((err: any) => {});
    }
  }, []);

  const setValidateStatusBatchName = React.useCallback((val: boolean) => {
    setValidStateBatchName(val);
  }, []);

  const selectedBranch = branches
    ? branches?.find(
        (branch: any) => branch.branchName === batchDetails.branchName
      )
    : "";

  const selectedCourse = courses
    ? courses?.find(
        (course: any) => course.courseName === batchDetails.courseName
      )
    : "";

  const handleChange = React.useCallback(
    (event: any) => {
      setShowErrMsz(true);
      if (errorType === "server") {
        setValidStateBatchName(true);
      }
      setErrorType("client");
      setBatchDetails({
        ...batchDetails,
        [event.target.id]: event.target.value.trimStart(),
      });
    },
    [batchDetails]
  );

  const onBranchChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value !== null) {
        setBatchDetails({
          ...batchDetails,
          branchId: value.id,
          branchName: value.branchName,
        });
        setValidStateBranch(true);
      } else {
        setBatchDetails({
          ...batchDetails,
          branchId: "",
          branchName: "",
        });
        setValidStateBranch(false);
      }
    },
    [batchDetails]
  );

  const onCourseChange = React.useCallback(
    (event: object, value: any | null) => {
      if (value) {
        const batchType = value.courseType.map((type: any) => ({
          batchType: {
            courseTypeName: type?.courseType?.courseTypeName,
            id: type.courseType.id,
          },
        }));
        setBatchDetails({
          ...batchDetails,
          courseId: value.id,
          courseName: value.courseName,
          batchType: batchType,
        });
      }
      setValidStateCourse(true);
    },
    [batchDetails]
  );

  const handleBatchConduct = React.useCallback((type: any) => {
    setConductType(type);
    if (type) {
      setConductErrorMszs("");
    } else {
      setConductErrorMszs("Select atleast one");
    }
  }, []);

  const cancelButtonClick = () => {
    setBatchDetails({
      ...batchDetails,
      batchName: "",
      courseName: "",
      courseId: "",
      branchName: "",
      branchId: "",
      batchConductType: "",
      status: "DRAFT",
    });
    setConductType("");
    setConductErrorMszs("");
    setValidStateBranch(true);
    setValidStateBatchName(true);
    setValidStateCourse(true);
    onClose();
  };

  const activeInstituteinLocal = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      !batchDetails.batchName && setValidStateBatchName(false);
      !batchDetails.courseId && setValidStateCourse(false);
      !batchDetails.branchId && setValidStateBranch(false);
      conductType === ""
        ? setConductErrorMszs("Select atleast one")
        : setConductErrorMszs("");

      batchDetails.batchName &&
        batchDetails.courseId !== "" &&
        batchDetails.branchId !== "" &&
        conductType !== "" &&
        dispatch<any>(
          createBatch({
            ...batchDetails,
            batchConductType:
              conductType === "REGULAR" ? "REGULAR_BATCH" : "DEMO_BATCH",
            instituteId: activeInstituteinLocal?.id,
            instituteName: activeInstituteinLocal?.instituteName,
            createdById: authUser?.institute[0]?.id,
            createdByName: authUser?.firstName + " " + authUser?.lastName,
            updatedById: "",
            updatedByName: "",
            createDemo: false,
            demoRequest: {},
            cloneBatch: false,
            isFirstBatch:
              validatingFirstTimeBranchCreation &&
              validatingFirstTimeBranchCreation?.batchCreated === true
                ? false
                : true,
          })
        );
    } catch (error) {}
  };

  React.useEffect(() => {
    if (batchCreateSuccess !== true && batchCreateSuccess !== false) {
      const userFromLocalStorage = authUser?.institute[0]?.instituteDetails;
      if (userFromLocalStorage) {
        dispatch<any>(
          getBatchList({
            id: userFromLocalStorage.id && userFromLocalStorage.id,
            batchConductType: "ALL",
          })
        );
      }
      if (
        userFromLocalStorage &&
        validatingFirstTimeBranchCreation?.batchCreated === false
      ) {
        dispatch<any>(getInstituteById(activeInstitute?.id));
        // dispatch<any>(
        //   appEntery(userFromLocalStorage && userFromLocalStorage.id)
        // );
        navigate("/app/home");
      } else if (batchId) {
        navigate(`/app/manage/Batches?batchId=${batchId}`);
      }
      setBatchDetails({
        ...batchDetails,
        batchName: "",
        courseName: "",
        courseId: "",
        branchName: "",
        branchId: "",
        batchConductType: "",
        status: "DRAFT",
      });
      setConductType("");

      dispatch(resetBatchStatus());

      onClose();
    }
  }, [batchCreateSuccess]);

  return (
    <>
      <div
        className={styles.addBatchWrapper}
        style={{
          display: isOpen ? "flex" : "none",
          transition: "0.1s ease-in-out",
        }}
      >
        <div className={styles.addBatchWrapperChild}>
          <div
            className={styles.popupHead}
            style={{ padding: "0px", marginBottom: "14PX" }}
          >
            <div className={styles.popupHeadTitle}>Add New Batch</div>
            <button
              className={styles.popupHeadClsBtn}
              onClick={cancelButtonClick}
            >
              <img src={closeIcon} alt="closeIcon" />
            </button>
          </div>
          <div>
            <div style={{ marginBottom: "18PX" }}>
              <NewFormTextInput
                validate={true}
                setValidStatus={setValidateStatusBatchName}
                label="Batch Name"
                type="text"
                id={"batchName"}
                error={!validStateBatchName}
                value={batchDetails.batchName}
                onChange={handleChange}
                showErrorMessage={showErrMsz}
                errorType={errorType}
                autoComplete="true"
                require={true}
                inputProps={{ maxLength: 50 }}
              />
            </div>

            <div style={{ marginBottom: "18PX" }}>
              <AutoCompleteField
                id="branchId"
                label="Branch"
                options={branches}
                optionLabel={"branchName"}
                onChange={onBranchChange}
                value={selectedBranch}
                disableClearable={batchDetails?.branchId === "" ? true : false}
                required={true}
                validState={validStateBranch}
              />
            </div>
            <div style={{ marginBottom: "18PX" }}>
              <AutoCompleteField
                id="courseId"
                label="Course"
                options={activeCourses}
                optionLabel={"courseName"}
                onChange={onCourseChange}
                value={selectedCourse}
                disableClearable={batchDetails?.courseId === "" ? true : false}
                required={true}
                validState={validStateCourse}
              />
            </div>
          </div>
          <BatchConductTypeManager
            handleConductType={handleBatchConduct}
            errorMsg={conductErrorMszs}
            selectedConductType={conductType}
            clone={false}
            validatingFirstTimeBranchCreation={
              validatingFirstTimeBranchCreation
            }
            setConductType={setConductType}
            batchCreateSuccess={batchCreateSuccess}
          />
          <div className={styles.popupBtnSplit}>
            <CancelBtn onClick={() => cancelButtonClick()} />
            <SaveBtn
              onClick={handleSubmit}
              disabled={batchCreateLoading}
              loader={batchCreateLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBatchPopup;
