import React from "react";
import styles from "./LeadOverview.module.css";
import LeadGraph from "./LeadGraph";
import moment from "moment";

function LeadOverviewGraph(props: any) {
  const { selectedLeadData } = props;
  const createdTimeMillis = selectedLeadData?.createdTime;
  const createdTimeSeconds = createdTimeMillis / 1000;

  const formattedDate = createdTimeSeconds
    ? moment.unix(createdTimeSeconds).format("DD/MM/YYYY")
    : "N/A";

  const lastActivityTimeMillis =
    selectedLeadData?.overViewData?.lastActivityDate;
  const lastActivityTimeSeconds = lastActivityTimeMillis / 1000;

  const formattedLastActivityDate = lastActivityTimeSeconds
    ? moment.unix(lastActivityTimeSeconds).format("DD/MM/YYYY")
    : "N/A";

  return (
    <div className={styles.overviewDetailsWrap}>
      <div className={styles.overviewDetailsLeft}>
        <div className={styles.overviewDetailsLeftBox}>
          <div className={styles.overviewDetailsLeftLabel}>Created Date</div>
          <div className={styles.overviewDetailsLeftValue}>{formattedDate}</div>
        </div>
        <div className={styles.overviewDetailsLeftBox}>
          <div className={styles.overviewDetailsLeftLabel}>In this stage</div>
          <div className={styles.overviewDetailsLeftValue}>
            {parseInt(selectedLeadData?.overViewData?.inCurrentStage)} Days
          </div>
        </div>
        <div className={styles.overviewDetailsLeftBox}>
          <div className={styles.overviewDetailsLeftLabel}>Upcoming Tasks</div>
          <div className={styles.overviewDetailsLeftValue}>
            {selectedLeadData?.overViewData?.upcomingTasks}
          </div>
        </div>
        <div className={styles.overviewDetailsLeftBox}>
          <div className={styles.overviewDetailsLeftLabel}>
            Last Activity Date
          </div>
          <div className={styles.overviewDetailsLeftValue}>
            {formattedLastActivityDate}
          </div>
        </div>
      </div>
      <div className={styles.overviewDetailsRight}>
        <LeadGraph selectedLeadData={selectedLeadData} />
      </div>
    </div>
  );
}

export default LeadOverviewGraph;
