import React, { useState } from "react";
import styles from "./Customize.module.css";
import dropIcon from "../../../../../assets/images/select-drop.svg";
import assigneeIcon from "../../..//Nurture/components/Assets/AssigneeDefault.svg";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import defaultFlag from "../../../../../components/FlagAssign/assets/flag-default.svg";

function DefaultForm() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  console.log("expanded", expanded);
  return (
    <div className={styles.demoBox}>
      <Accordion
        style={{
          background: "#ffffff",
          boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5)",
          border: "1px solid #E3E3E3 ",
        }}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "6px",
          }}
        >
          <div
            className={styles.territoryTitle}
            style={{ marginBottom: expanded === "panel1" ? "10px" : "0px" }}
          >
            Default{" "}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.demoFieldSplit}>
            <div className={styles.demoFieldWrap}>
              <div className={styles.demoFieldLabel}>First name </div>
              <div className={styles.demoTextField}></div>
            </div>
            <div className={styles.demoFieldWrap}>
              <div className={styles.demoFieldLabel}>Last name </div>
              <div className={styles.demoTextField}></div>
            </div>
          </div>
          <div className={styles.demoFieldWrap}>
            <div className={styles.demoFieldLabel}>Email address </div>
            <div className={styles.demoTextField}></div>
          </div>
          <div className={styles.demoFieldWrap}>
            <div className={styles.demoFieldLabel}>Phone number </div>
            <div className={styles.demoTextField}>
              <div className={styles.demoPhoneCode}>+91</div>
            </div>
          </div>
          <div>
            <div className={styles.demoFieldLabel}>Course Details </div>
            <div
              className={styles.demoSelectField}
              style={{ marginBottom: "12px" }}
            >
              Select a Course * <img src={dropIcon} alt="icon" />
            </div>
            <div
              className={styles.demoSelectField}
              style={{ marginBottom: "12px" }}
            >
              Select a Branch *<img src={dropIcon} alt="icon" />
            </div>
          </div>
          <div style={{ marginBottom: "12px" }}>
            <div className={styles.demoFieldLabel}>Choose stage</div>
            <div className={styles.demoSelectField}>
              Select <img src={dropIcon} alt="icon" />
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
            <div className={styles.demoSelectField}>Type Source</div>
            <div className={styles.demoCircleField}>
              <img src={assigneeIcon} alt="icon" />
            </div>
            <div className={styles.demoCircleField}>
              <img style={{ width: "50%" }} src={defaultFlag} alt="icon" />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default DefaultForm;
