import { Box } from "@mui/material";
import React from "react";
import styles from "./ActivityList.module.css";
import moment from "moment";
import stageDot from "../../assets/images/stage-dot.svg";
import nameLine from "../../assets/images/name-line.svg";
import highFlagIcon from "../FlagAssign/assets/High.svg";
import urgentFlagIcon from "../FlagAssign/assets/Urgent.svg";
import normalFlagIcon from "../FlagAssign/assets/Normal.svg";
import lowFlagIcon from "../FlagAssign/assets/Low.svg";
import redDot from "./assets/red-dot.svg";
import greenDot from "./assets/dot-green.svg";
import getTeamMember, {
  getDescriptionWithMentionedNames,
} from "../CommonFunctions/CommonFunctions";

function ActivityTaskStatusChange(props: any) {
  const { data, employees } = props;

  const activityData =
    data && data?.activityData && Object?.keys(data?.activityData).length !== 0
      ? data?.activityData
      : data?.taskData;
  const modifiedDescription = getDescriptionWithMentionedNames(
    activityData?.description,
    employees
  );
  return (
    <>
      <Box
        sx={{
          width: "100%",
          margin: "0 15px 22px 15px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.activityTop}>
            <div className={styles.activityDateStyle}>
              {moment(data?.creationDate).format("DD MMM YYYY, hh:mm A")}
            </div>
            <div className={styles.activityHeadStage}>
              <div className={styles.activityHeadStageIcon}>
                <img src={stageDot} alt="" />
              </div>
              <div>{data?.currentStage}</div>
            </div>
            <div className={styles.activityHeadName}>
              <div className={styles.activityHeadStageIcon}>
                <img src={nameLine} alt="" />
              </div>
              <div>
                {/* {data.createdByName && data.createdByName} */}
                {getTeamMember(data?.createdById, employees)?.firstName +
                  " " +
                  getTeamMember(data?.createdById, employees)?.lastName}
              </div>
            </div>
          </div>

          <div
            className={styles.activityStageDesc}
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <div
              className={styles.activityDemoTitle}
              style={{ marginBottom: 0 }}
            >
              Task Stage Changed from
            </div>
            <div
              className={styles.activityStatusBox}
              style={{
                background:
                  data?.taskPreviousStage === "Blocked"
                    ? "#FEF3F3"
                    : "rgba(17, 200, 155, 0.06)",
                color:
                  data?.taskPreviousStage === "Blocked" ? "#EC3939" : "#11C89B",
              }}
            >
              <div className={styles.activityStatusIcon}>
                <img
                  style={{ display: "flex" }}
                  src={
                    data?.taskPreviousStage === "Blocked" ? redDot : greenDot
                  }
                  alt=""
                />
              </div>
              <div>{data?.taskPreviousStage}</div>
            </div>
            <div>to</div>
            <div
              className={styles.activityStatusBox}
              style={{
                background:
                  data?.taskCurrentStage === "Blocked"
                    ? "#FEF3F3"
                    : "rgba(17, 200, 155, 0.06)",
                color:
                  data?.taskCurrentStage === "Blocked" ? "#EC3939" : "#11C89B",
              }}
            >
              <div className={styles.activityStatusIcon}>
                <img
                  style={{ display: "flex" }}
                  src={data?.taskCurrentStage === "Blocked" ? redDot : greenDot}
                  alt=""
                />
              </div>
              <div>{data?.taskCurrentStage}</div>
            </div>
          </div>

          <div
            className={styles.activityDescription}
            dangerouslySetInnerHTML={{ __html: modifiedDescription }}
          />

          <div className={styles.activityBottom}>
            <div className={styles.activityBottomLeft}>
              {activityData?.assignedToName && (
                <div className={styles.activityBottomAssign}>
                  {getTeamMember(activityData?.assignedToId, employees)
                    ?.firstName?.charAt(0)
                    ?.toUpperCase()}
                  {getTeamMember(activityData?.assignedToId, employees)
                    ?.lastName?.charAt(0)
                    ?.toUpperCase()}
                  {/* {activityData?.assignedToName
                    ?.split(" ")
                    ?.slice(0, 2)
                    ?.map((word: any, index: number) => (
                      <span key={index}>{word?.charAt(0)}</span>
                    ))} */}
                </div>
              )}
              {activityData?.taskType !== "DEMO" && (
                <div className={styles.activityBottomFlag}>
                  {activityData?.priority === "URGENT" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={urgentFlagIcon} alt="" />
                    </div>
                  )}
                  {activityData?.priority === "HIGH" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={highFlagIcon} alt="" />
                    </div>
                  )}
                  {activityData?.priority === "NORMAL" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={normalFlagIcon} alt="" />
                    </div>
                  )}
                  {activityData?.priority === "LOW" && (
                    <div className={styles.activityBottomFlagImg}>
                      <img src={lowFlagIcon} alt="" />
                    </div>
                  )}
                </div>
              )}

              {data &&
              data?.activityData &&
              Object?.keys(data?.activityData).length !== 0 ? (
                <>
                  <div className={styles.activityDateStyle}>
                    Due on{" "}
                    {activityData?.taskType !== "DEMO"
                      ? moment(data?.activityData?.date).format(
                          "DD MMM YYYY, hh:mm A"
                        )
                      : moment(data?.activityData?.date).format("DD MMM") +
                        " " +
                        (data?.activityData?.demoData[0]?.demoStartTime.toString()
                          ?.length === 10
                          ? moment
                              .unix(
                                data?.activityData?.demoData[0]?.demoStartTime
                              )
                              .format("hh:mm A")
                          : moment(
                              data?.activityData?.demoData[0]?.demoStartTime
                            ).format("hh:mm A"))}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.activityDateStyle}>
                    Due on{" "}
                    {data?.taskData?.taskType !== "DEMO"
                      ? moment(data?.date).format("DD MMM YYYY, hh:mm A")
                      : moment(data?.taskData?.date).format("DD MMM") +
                        " " +
                        (data?.taskData?.demoData[0]?.demoStartTime.toString()
                          ?.length === 10
                          ? moment
                              .unix(data?.taskData?.demoData[0]?.demoStartTime)
                              .format("hh:mm A")
                          : moment(
                              data?.taskData?.demoData[0]?.demoStartTime
                            ).format("hh:mm A"))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ActivityTaskStatusChange;
