import { useEffect, useState } from "react";
import styles from "./ReportRoot.module.css";
import { GeneralSetup } from "../../../../components/GeneralSetup/GeneralSetup";
import { Outlet, useLocation } from "react-router-dom";
import { Box, Grid, List, Typography } from "@mui/material";
import { checkSubDomainAccess } from "../../../../components/RolePermissionAccess/RolePermissionAccess";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import ManageReportRouter from "../../../../router/ManageReportRouter";

export default function ReportRoot(props: any) {
  const auth = useAuthUser();
  const authUser = auth();
  const location = useLocation();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );

  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const userFromLocalStorage = JSON.parse(
    localStorage.getItem("ACTIVE_INSTITUTE")!
  );

  const instituteData = JSON.parse(localStorage.getItem("ACTIVE_INSTITUTE")!);
  const accountStatus = instituteData?.billingStatus;

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes("RecentReports")) {
      setIsActive(0);
    } else if (currentPath.includes("CreatedByMe")) {
      setIsActive(1);
    } else if (currentPath.includes("AllReports")) {
      setIsActive(2);
    }
  }, [location.pathname]);

  const handleStatus = (index: number) => {
    setIsActive(index);
    if (index === 0) {
      navigate("/app/Reports/RecentReports");
    } else if (index === 1) {
      navigate("/app/Reports/CreatedByMe");
    } else if (index === 2) {
      navigate("/app/Reports/AllReports");
    }
  };

  useEffect(() => {
    if (
      userFromLocalStorage &&
      userFromLocalStorage.isProfileCompleted === false
    ) {
      setButtonDisabled(true);
    }
  }, [setButtonDisabled, userFromLocalStorage]);

  useEffect(() => {
    const element = document.getElementById("manage");
    if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  }, []);

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{
        overflow: "auto",
        padding: "14px 16px",
        background: "#F5F5F5",
        maxHeight: "calc(100vh -65px)",
        position: "relative",
      }}
    >
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          overflow: "hidden",
          background: "#fff",
          border: "1px solid #E9E9E9",
          borderRadius: "8px",
          minHeight: "calc(100vh - 94px)",
        }}
        id="manage"
      >
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
          >
            <Grid
              className={styles.managerGridLeft}
              item
              xs={12}
              sm={12}
              md={2.5}
              lg={2}
              sx={{
                maxHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh -94px)",
                  md: !userFromLocalStorage.isProfileCompleted
                    ? "calc(100vh - 208px)"
                    : "calc(100vh - 94px)",
                },
                minHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
                overflowY: "auto",
                borderRight: "1px solid #c7c7c7",
              }}
            >
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    paddingX: "34px",
                    fontSize: "18px",
                    marginTop: "30px",
                    marginBottom: "10px",
                    fontWeight: "500",
                    paddingRight: "45px",
                  }}
                  // onClick={handleGoBack}
                >
                  Reports
                </Typography>
                <Box
                  className={styles.manageList}
                  sx={{
                    width: { xs: "95vw", sm: 550, lg: "auto", md: "auto" },
                    height: {
                      lg: "auto",
                    },
                    overflowY: {
                      xs: "auto",
                      lg: "hidden",
                      md: "auto",
                      sm: "auto",
                    },
                    paddingLeft: "20px",
                    paddingRight: "30px",
                  }}
                >
                  <List
                    sx={{
                      fontSize: { xs: 2 },

                      display: {
                        xs: "-webkit-box",
                        sm: "flex",
                        md: "block",
                        lg: "block",
                      },
                      alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                      },
                      overflowY: {
                        xs: "auto",
                        lg: "hidden",
                      },
                      padding: { xs: 0 },
                      pointerEvents: buttonDisabled ? "none" : "auto",
                      ".MuiTypography-root": {
                        fontSize: "0.8rem !important",
                      },
                    }}
                  >
                    {[
                      {
                        sectionName: "Recent Reports",
                        allAccess: checkSubDomainAccess(
                          "REPORTS",
                          "RECENT_REPORTS",
                          // userRoleById?.permissions
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "Created By Me",
                        allAccess: checkSubDomainAccess(
                          "REPORTS",
                          "CREATED_BY_ME",
                          // userRoleById?.permissions
                          userById?.rolePermission
                        ),
                      },
                      {
                        sectionName: "All Reports",
                        allAcess: checkSubDomainAccess(
                          "REPORTS",
                          "ALL_REPORTS",
                          // userRoleById?.permissions
                          userById?.rolePermission
                        ),
                      },
                    ]
                      .filter((item) => item !== null)
                      .map((item, index) => (
                        <GeneralSetup
                          key={index}
                          handleStatus={handleStatus}
                          index={index}
                          isActive={isActive}
                          item={item?.sectionName}
                          hideLockIcon={true}
                        />
                      ))}
                  </List>
                </Box>
              </Box>
            </Grid>

            <Grid
              className={styles.gridInboundRootBottom}
              item
              lg={10}
              md={9.5}
              xs={12}
              sm={12}
              xl={10}
              style={{
                // overflow: "auto",
                paddingLeft: "0px",
                paddingRight: "15px",
                padding: !userFromLocalStorage.isProfileCompleted ? "" : "0px ",
                paddingTop: "20px",
              }}
              sx={{
                maxHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
                minHeight: {
                  xs: "auto",
                  sm: "auto",
                  lg: "calc(100vh - 94px)",
                  md: "calc(100vh - 94px)",
                },
              }}
            >
              <ManageReportRouter />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
