import { RootState, TypedDispatch } from "../../../../../redux/store";
import { connect } from "react-redux";
import { GetAllCourseByInstituteRequestInitialStateDTO } from "../service/types";
import { getAllCourseByInstituteRequest } from "../service/courseSlice";
import CourseListing from "../components/CourseListing";
import { GetCourseConfig } from "../service/courseConfigSlice";
import { resetNewAddedSelectedTag } from "../service/tagSlice";
import { getBranchList } from "../../ManageBranch/service/Branchslice";

interface StateProps extends GetAllCourseByInstituteRequestInitialStateDTO {}

interface DispatchProps {
  GetAllCourseByInstituteRequest(data: any): void;
  CourseConfig(): void;
  GetBranchList(id: string): void;
}

const mapStateToProps = (state: RootState): StateProps => {
  return {
    success_getAllCourseByInstituteRequest:
      state.courses.success_getAllCourseByInstituteRequest,
    error_getAllCourseByInstituteRequest:
      state.courses.error_getAllCourseByInstituteRequest,
    loading_getAllCourseByInstituteRequest:
      state.courses.loading_getAllCourseByInstituteRequest,
    courses: state.courses.courses,
  };
};

const mapDispatchToProps = (dispatch: TypedDispatch): DispatchProps => {
  return {
    GetAllCourseByInstituteRequest: (data) => {
      dispatch(getAllCourseByInstituteRequest(data));
      dispatch(resetNewAddedSelectedTag());
    },
    CourseConfig: () => {
      dispatch(GetCourseConfig());
    },
    GetBranchList: (id: string) => {
      dispatch(getBranchList(id));
    },
  };
};

export interface CourseListingProps extends StateProps, DispatchProps {}
export const CourseListingComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseListing);
