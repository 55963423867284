import React from "react";
import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

function BatchPaySkeleton() {
  return (
    <div
      className={styles.batchBasicDetailsWrap}
      style={{ padding: "20px 30px" }}
    >
      <div
        style={{
          width: "20%",
          marginBottom: "20px",
        }}
      >
        <Skeleton animation="wave" variant="text" width="100%" height={25} />
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          padding: "14px 20px",
          background: "#fafafc",
          border: "1px solid #f0f0f5",
        }}
      >
        <div
          style={{
            width: "25%",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div
          style={{
            width: "25%",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>
      <div
        style={{
          width: "20%",
          marginBottom: "20px",
        }}
      >
        <Skeleton animation="wave" variant="text" width="100%" height={25} />
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginBottom: "20px",
          padding: "14px 20px",
          background: "#fafafc",
          border: "1px solid #f0f0f5",
        }}
      >
        <div
          style={{
            width: "25%",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
        <div
          style={{
            width: "25%",
          }}
        >
          <Skeleton animation="wave" variant="text" width="100%" height={25} />
          <Skeleton animation="wave" variant="text" width="100%" height={50} />
        </div>
      </div>
    </div>
  );
}

export default BatchPaySkeleton;
