import React from "react";
import styles from "./PaymentBars.module.css";
import amountPauseIcon from "../../../../../../assets/images/bar-pause-icon.svg";
import moment from "moment";

function MembershipPaused(props: any) {
  const { activity, setPauseOpen } = props;
  return (
    <div
      className={styles.membershipBarWrap}
      style={{
        background: "rgba(255, 180, 31, 0.06)",
        border: "1px solid rgba(255, 180, 31, 0.14)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      <div className={styles.membershipBarWrapLeft}>
        <div className={styles.membershipBarIcon}>
          <img src={amountPauseIcon} alt="amountPauseIcon" />
        </div>
        <div className={styles.membershipBarDetails}>
          <div className={styles.membershipBarDetailsTop}>
            <div
              className={styles.membershipBarTitle}
              style={{ color: "#F9AA0F" }}
            >
              Paused
            </div>
            <div className={styles.membershipBarDate}>
              {moment(activity?.creationDate).format("DD MMM YYYY")}
            </div>
          </div>
          <div className={styles.membershipBarDesc}>
            {activity?.description}
          </div>
        </div>
      </div>
      <div className={styles.membershipBarWrapRight}>
        <button className={styles.resumeBtn} onClick={() => setPauseOpen(true)}>
          Resume Now
        </button>
      </div>
    </div>
  );
}

export default MembershipPaused;
