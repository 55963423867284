import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import TableComponent from "../../../../../components/TableComponent/TableComponent";
import CancelModal from "../../../../../components/CancelModal/CancelModal";
import ImportModal from "../ImportModal/ImportModal";
import EditContact from "./EditContact";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

const column = [
  "Id",
  "Name",
  "Email",
  "Country Code",
  "Phone Number",
  "Course Name",
];
const column2 = [
  "Id",
  "Name",
  "Email",
  "Country Code",
  "Phone Number",
  "Course Name",
  "Validation Errors",
];

const ImportPreview = (props: any) => {
  const {
    GetCourseList,
    SaveImportedFile,
    saveImportedDataError,
    saveImportedDataSuccess,
    saveImportedDataLoading,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const navigate = useNavigate();
  const location = useLocation();
  const { userById } = useSelector((state: any) => state.userDetails);
  const courseList: any = useSelector((state: any) => state.courses.courses);
  const filteredCourseList = courseList?.filter(
    (item: any) => item?.status === "ACTIVE"
  );

  const [searchText, setSearchText] = useState<any>("");
  const [searchText1, setSearchText1] = useState<any>("");
  const [open, isOpen] = useState<boolean>(false);
  const [discardCancel, setDiscardCancel] = useState<boolean>(false);
  const [openImport, isOpenImport] = useState(false);
  const [editObj, setEditObj] = useState<any>();
  const [openEditDrawer, isOpenEditDrawer] = useState<boolean>(false);
  const [dataWithError, setDataWithErrors] = useState<any>();
  const [dataWithoutError, setDataWithoutErrors] = useState<any>();
  const [selectedCourse, setSelectedCourse] = useState<any>("");
  const [zIndexValue, setZIndexValue] = useState<boolean>(true);

  const modalTitle = (
    <Box display={"flex"} alignItems={"center"}>
      <Typography>
        Contacts ({dataWithoutError && dataWithoutError?.length}){" "}
      </Typography>
      &nbsp;
      <Typography color={"error"}>
        Discard ({dataWithError && dataWithError?.length})
      </Typography>
    </Box>
  );

  const filteredArrayWithoutValidation = dataWithoutError?.map((ds: any) => {
    return {
      id: ds?.id,
      name: ds?.name,
      email: ds?.email,
      countryCode: ds?.countryCode,
      phoneNumber: ds?.phoneNumber,
      course: ds?.course,
    };
  });

  const filteredDataWithErrors = dataWithError?.filter((item: any) => {
    if (!searchText) {
      return true;
    }

    const searchLower = searchText?.toLowerCase();

    const fullName = item.name?.toLowerCase();
    const phoneNumber =
      typeof item.phoneNumber === "string"
        ? item.phoneNumber.toLowerCase()
        : item.phoneNumber?.toString();

    const email = item.email?.toLowerCase();

    return (
      (fullName && fullName.includes(searchLower)) ||
      (phoneNumber && phoneNumber.includes(searchLower)) ||
      (email && email.includes(searchLower))
    );
  });

  const filteredDataWithoutErrors = filteredArrayWithoutValidation?.filter(
    (item: any) => {
      if (!searchText1) {
        return true;
      }

      const searchLower = searchText1?.toLowerCase();

      const fullName = item.name?.toLowerCase();
      const phoneNumber =
        typeof item.phoneNumber === "string"
          ? item.phoneNumber.toLowerCase()
          : item.phoneNumber?.toString();

      const email = item.email?.toLowerCase();

      return (
        (fullName && fullName.includes(searchLower)) ||
        (phoneNumber && phoneNumber.includes(searchLower)) ||
        (email && email.includes(searchLower))
      );
    }
  );

  const onEdit = (obj: any) => {
    if (courseList) {
      let selectedValue = courseList?.find((d: any) => {
        if (obj?.course === d?.courseName) {
          return d;
        }
      });
      if (selectedValue) {
        setSelectedCourse(selectedValue);
      } else {
        setSelectedCourse("");
      }
    }
    setEditObj({
      ...obj,
      phoneNumber: `${obj?.countryCode}${obj?.phoneNumber
        ?.toString()
        ?.slice(0, 10)}`,
    });
    isOpenEditDrawer(true);
  };

  const onEditClose = () => {
    isOpenEditDrawer(false);
    setZIndexValue(true);
  };

  const onDelete = (obj: any) => {
    if (obj?.validation?.length > 0) {
      let filteredArray = [...dataWithError];
      let finalResult = filteredArray?.filter(
        (item: any) => item?.id !== obj?.id
      );
      setDataWithErrors(finalResult);
    } else {
      let filteredArray = [...dataWithoutError];
      let finalResult = filteredArray?.filter(
        (item: any) => item?.id !== obj?.id
      );
      setDataWithoutErrors(finalResult);
    }
  };

  const onClose = () => {
    isOpen(false);
  };
  const noButtonClick = () => {
    isOpen(false);
  };
  const yesButtonClick = () => {
    isOpen(false);
    const importString: any = localStorage?.getItem("ImportedContacts");
    if (importString) {
      localStorage?.setItem("ImportedContacts", "");
    }
    if (location?.state?.importFromPage === "IMPORT") {
      navigate("/app/Inbound/Import", { state: 2 });
    } else {
      navigate("/app/Inbound/Contacts", { state: 0 });
    }
  };
  const onDiscardClose = () => {
    setDiscardCancel(false);
  };
  const noDiscardButtonClick = () => {
    setDiscardCancel(false);
  };
  const yesDiscardButtonClick = () => {
    setDiscardCancel(false);
    setDataWithErrors([]);
  };
  const onCloseImport = () => {
    isOpenImport(false);
  };
  const cancelButtonClick = () => {
    isOpenImport(false);
  };

  const importButtonClick = () => {
    const userFromLocalStorage = authUser?.institute[0];
    const modifiedDataWithError = dataWithError?.map((item: any) => {
      return { ...item, courseId: "" };
    });
    const modifiedDataWithoutError = dataWithoutError?.map((item: any) => {
      return { ...item, courseId: "" };
    });
    const importString: any = localStorage?.getItem("ImportedContacts");
    const result = JSON.parse(importString);

    const data = {
      excelPreviewDataWithErrors: modifiedDataWithError,
      excelPreviewDataWithoutErrors: modifiedDataWithoutError,
      instituteId: userFromLocalStorage && userFromLocalStorage?.instituteId,
      instituteName:
        userFromLocalStorage && userFromLocalStorage?.instituteName,
      branchName: userFromLocalStorage && userFromLocalStorage?.branchName,
      branchId: userFromLocalStorage && userFromLocalStorage?.branchId,
      assigneeName: userById && `${userById?.firstName} ${userById?.lastName}`,
      assigneeId: userById?.id,
      fileName: result && result?.fileName,
    };
    SaveImportedFile(data);
  };

  useEffect(() => {
    const importString: any = localStorage?.getItem("ImportedContacts");
    const result = JSON.parse(importString);
    if (result) {
      setDataWithErrors(result?.contactsWithError?.data);
      setDataWithoutErrors(result?.contactsWithoutError?.data);
    }
  }, []);

  React.useEffect(() => {
    if (dataWithoutError?.length > 0 || dataWithoutError?.length > 0) {
      const importString: any = localStorage?.getItem("ImportedContacts");
      const result = JSON.parse(importString);
      const data = {
        contactsWithError: {
          count: dataWithError?.length,
          data: dataWithError,
        },
        contactsWithoutError: {
          count: dataWithoutError?.length,
          data: dataWithoutError,
        },
        fileName: result && result?.fileName,
      };

      localStorage.setItem("ImportedContacts", JSON.stringify(data));
    }
  }, [dataWithError, dataWithoutError]);

  React.useEffect(() => {
    if (saveImportedDataSuccess) {
      if (location?.state?.importFromPage === "IMPORT") {
        navigate("/app/Inbound/Import", { state: 2 });
      } else {
        navigate("/app/Inbound/Contacts", { state: 0 });
      }

      const importString: any = localStorage?.getItem("ImportedContacts");
      if (importString) {
        localStorage?.setItem("ImportedContacts", "");
      }
      isOpenImport(false);
    }
  }, [saveImportedDataSuccess]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F5F5F5",
          padding: "14px 16px",
          overflow: "hidden",
          maxHeight: "calc(100vh - 65px)",
          minHeight: "calc(100vh - 65px)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#FFF",
            padding: 5,
            borderRadius: "8px",
            overflow: saveImportedDataLoading ? "hidden" : "AUTO",
            maxHeight: "calc(100vh - 93px)",
            minHeight: "calc(100vh - 93px)",
            border: "1px solid #E9E9E9",
            position: "relative",
          }}
        >
          <Box>
            <>
              <Box
                sx={{
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#142C44",
                }}
              >
                Import Preview
              </Box>
              <>
                <Box marginTop={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    marginBottom={2}
                  >
                    <Box>
                      Contacts ({dataWithError && dataWithError?.length} with
                      errors)
                    </Box>
                    <Box>
                      <TextField
                        placeholder="Search"
                        type="search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        size="small"
                        sx={{
                          width: "380px",
                          "&.MuiOutlinedInput-root": {
                            paddingLeft: "0 !important",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  {filteredDataWithErrors &&
                  filteredDataWithErrors?.length > 0 ? (
                    <Box>
                      <TableComponent
                        array={filteredDataWithErrors?.sort(
                          (a: any, b: any) => a?.id - b?.id
                        )}
                        column={column2}
                        action={true}
                        id={true}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        headerColor={"#FBE1E1"}
                        backgroundColor={"#FFF5F5"}
                        borderColor={"#FBE1E1"}
                        maximumHeight={
                          dataWithError?.length > 0 &&
                          dataWithoutError?.length > 0
                            ? "278px"
                            : null
                        }
                        height={
                          dataWithError?.length > 0 &&
                          dataWithoutError?.length === 0
                            ? "400px"
                            : null
                        }
                        zIndexValue={zIndexValue}
                        setZIndexValue={setZIndexValue}
                        dataWithError={filteredDataWithErrors}
                      />
                      {/* <TableComponent
                        array={dataWithError?.sort(
                          (a: any, b: any) => a?.id - b?.id
                        )}
                        column={column2}
                        action={true}
                        id={true}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        headerColor={"#FBE1E1"}
                        backgroundColor={"#FFF5F5"}
                        borderColor={"#FBE1E1"}
                        maximumHeight={
                          dataWithError?.length > 0 &&
                          dataWithoutError?.length > 0
                            ? "278px"
                            : null
                        }
                        height={
                          dataWithError?.length > 0 &&
                          dataWithoutError?.length === 0
                            ? "400px"
                            : null
                        }
                        zIndexValue={zIndexValue}
                        setZIndexValue={setZIndexValue}
                        dataWithError={dataWithError}
                      /> */}
                    </Box>
                  ) : (
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        color: " #667085",
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                        marginRight: "100px",
                        justifyContent: "center",
                        marginTop: "50px",
                      }}
                    >
                      <div style={{ color: " #142C44" }}>
                        {dataWithError?.length > 0
                          ? filteredDataWithErrors?.length === 0
                            ? "No Matches Found"
                            : ""
                          : "Hurray! Your Imported File got 0 Errors"}
                      </div>
                    </div>
                  )}
                </Box>
                {dataWithError && dataWithError?.length > 0 && (
                  <Box>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      marginTop={1}
                    >
                      <Box
                        sx={{ cursor: "pointer", padding: "0px 10px" }}
                        fontSize={"13px"}
                        fontWeight={500}
                        lineHeight={"30px"}
                        color={"#EC3939"}
                        onClick={() => {
                          setDiscardCancel(true);
                          setZIndexValue(false);
                        }}
                      >
                        Discard All
                      </Box>
                    </Box>
                  </Box>
                )}
              </>

              <>
                <Box marginTop={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    marginBottom={2}
                  >
                    <Box>
                      Contacts ({dataWithoutError && dataWithoutError?.length}{" "}
                      without errors)
                    </Box>
                    <Box>
                      <TextField
                        placeholder="Search"
                        type="search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        value={searchText1}
                        onChange={(e) => {
                          setSearchText1(e.target.value);
                        }}
                        size="small"
                        sx={{
                          width: "380px",
                          "&.MuiOutlinedInput-root": {
                            paddingLeft: "0 !important",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  {filteredDataWithoutErrors &&
                  filteredDataWithoutErrors?.length > 0 ? (
                    <Box>
                      <TableComponent
                        array={filteredDataWithoutErrors?.sort(
                          (a: any, b: any) => a?.id - b?.id
                        )}
                        column={column}
                        action={true}
                        id={true}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        maximumHeight={
                          dataWithError?.length > 0 &&
                          dataWithoutError?.length > 0
                            ? "278px"
                            : null
                        }
                        height={
                          dataWithoutError?.length > 0 &&
                          dataWithError?.length === 0
                            ? "400px"
                            : null
                        }
                        zIndexValue={zIndexValue}
                        setZIndexValue={setZIndexValue}
                      />
                      {/* <TableComponent
                        array={filteredArrayWithoutValidation?.sort(
                          (a: any, b: any) => a?.id - b?.id
                        )}
                        column={column}
                        action={true}
                        id={true}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        maximumHeight={
                          dataWithError?.length > 0 &&
                          dataWithoutError?.length > 0
                            ? "278px"
                            : null
                        }
                        height={
                          dataWithoutError?.length > 0 &&
                          dataWithError?.length === 0
                            ? "400px"
                            : null
                        }
                        zIndexValue={zIndexValue}
                        setZIndexValue={setZIndexValue}
                      /> */}
                    </Box>
                  ) : (
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        color: " #667085",
                        display: "flex",
                        alignItems: "center",
                        gap: "2px",
                        marginRight: "100px",
                        justifyContent: "center",
                        marginTop: "50px",
                      }}
                    >
                      <div style={{ color: " #142C44" }}>
                        {dataWithoutError?.length > 0
                          ? filteredDataWithoutErrors?.length === 0
                            ? "No Matches Found"
                            : ""
                          : "Oops! Please Click on Cancel to Import again"}
                      </div>
                    </div>
                  )}
                </Box>
                <Box marginTop={2} display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "#9A9EA6",
                      borderColor: "#9A9EA6",
                      ":hover": {
                        color: "#9A9EA6",
                        borderColor: "#9A9EA6",
                        backgroundColor: "#9a9ea612",
                      },
                    }}
                    variant="outlined"
                    onClick={() => {
                      isOpen(true);
                      setZIndexValue(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{ textTransform: "capitalize", marginLeft: 2 }}
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      isOpenImport(true);
                      setZIndexValue(false);
                    }}
                    disabled={
                      dataWithoutError && dataWithoutError?.length === 0
                    }
                  >
                    Continue
                  </Button>
                </Box>
              </>
            </>
            <CancelModal
              open={open}
              onClose={onClose}
              noButtonClick={noButtonClick}
              yesButtonClick={yesButtonClick}
            />
            <CancelModal
              open={discardCancel}
              onClose={onDiscardClose}
              noButtonClick={noDiscardButtonClick}
              yesButtonClick={yesDiscardButtonClick}
              subTitle=""
              title={`Are you sure you want to Discard All ${dataWithError?.length} Contacts with error?`}
            />
            <ImportModal
              open={openImport}
              closeModal={onCloseImport}
              cancelButtonClick={cancelButtonClick}
              importButtonClick={importButtonClick}
              subTitle={modalTitle}
              saveImportedDataLoading={saveImportedDataLoading}
            />
            <EditContact
              obj={editObj}
              open={openEditDrawer}
              onClose={onEditClose}
              setEditObj={setEditObj}
              courseList={filteredCourseList}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              setDataWithErrors={setDataWithErrors}
              dataWithError={dataWithError}
              dataWithoutError={dataWithoutError}
              setDataWithoutErrors={setDataWithoutErrors}
              isOpenEditDrawer={isOpenEditDrawer}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ImportPreview;
