import {
  Button,
  IconButton,
  MenuItem,
  Popover,
  Skeleton,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./Subscription.module.css";
import logo from "./fav-icon.svg";
import dots from "./dots-icon.svg";
import CustomPopupBilling from "../../../../../../components/CustomPopupBilling/CustomPopupBilling";
import { Link } from "react-router-dom";
import greenRadio from "./radio-green.svg";
import orangeRadio from "./orange-radio.svg";
import CancelSubscriptionPopup from "./CancelSubscriptionPopup";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import accountArchived from "../subscription/WarningMessages/account-archived.svg";
import accountLocked from "../subscription/WarningMessages/account-locked.svg";
import unarchivedIcon from "../subscription/WarningMessages/account-unarchived.svg";
import { useSelector } from "react-redux";
import { getSubDomainAccessStatus } from "../../../../../../components/RolePermissionAccess/RolePermissionAccess";

function Subscription(props: any) {
  const {
    instituteBillingData,
    UpdateInstituteSubscriptionStatus,
    billingDataLoading,
  } = props;
  const auth = useAuthUser();
  const authUser = auth();
  const { userRoleById, userById } = useSelector(
    (state: any) => state.userDetails
  );
  const hasBillingAccess = getSubDomainAccessStatus(
    "MANAGE",
    "BILLING",
    "ALL_ACCESS",
    // userRoleById?.permissions
    userById?.rolePermission
  );
  // Subscription Status
  const billingStatus =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails.billingStatus;

  const trialPlan =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails.isTrail;

  const subscriptionStatus =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails.status;
  const billingCycle =
    instituteBillingData?.[0]?.instituteSubscriptionDetails?.billingCycle;

  const dueDate = instituteBillingData && instituteBillingData[0]?.startDate;
  const formattedDueDate = dueDate && moment(dueDate).format("DD MMM, YYYY");

  const dueDatePlus7Days = moment(formattedDueDate, "DD MMM, YYYY")?.add(
    7,
    "days"
  );

  const endDate = instituteBillingData && instituteBillingData[0]?.endDate;
  const formattedEndDate = endDate && moment(endDate).format("DD MMM, YYYY");
  // Calculate the remaining days until the last date of those 7 days
  const currentDate = moment();
  const lastDateOf7Days = dueDatePlus7Days?.clone()?.endOf("day"); // Assuming you want to consider the end of the day

  const remainingDaysInGracePeriod = lastDateOf7Days?.diff(currentDate, "days");

  const planBenefits =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails &&
    instituteBillingData[0]?.instituteSubscriptionDetails?.plans[0]
      .planBenefits;

  const addOns =
    instituteBillingData &&
    instituteBillingData[0]?.instituteSubscriptionDetails &&
    instituteBillingData[0]?.instituteSubscriptionDetails?.addOns;

  // const billingCycle =
  //   instituteBillingData &&
  //   instituteBillingData[0]?.instituteSubscriptionDetails &&
  //   instituteBillingData[0]?.instituteSubscriptionDetails?.plans[0]
  //     .billingCycle;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleCancelOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCancelClose = () => {
    setAnchorEl(null);
  };

  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const handleCancelModalClose = () => setOpenCancelModal(false);
  const handleCancelModalOpen = () => setOpenCancelModal(true);
  const cancelModalSubscriptionConfirm = () => setOpenCancelModal(false);

  const [subscriptionCancel, setSubscriptionCancel] = useState({
    id: "",
    status: "CANCELLED",
  });
  // Current User Data

  const filteredUsersCount =
    planBenefits &&
    planBenefits?.filter((item: any) => item.benefitCode === "USER").length;

  const filteredExtraUsersCount =
    addOns &&
    addOns?.filter(
      (item: any) => item.benefitCode === "USER" && item.benefitCost !== 0
    ).length;

  const filteredExtraUsers =
    addOns &&
    addOns?.filter(
      (item: any) => item.benefitCode === "USER" && item.benefitCost !== 0
    );

  const filteredExtraUsersPrice = filteredExtraUsers
    ? filteredExtraUsers?.reduce(
        (total: number, item: any) => total + item.benefitCost,
        0
      )
    : 0;

  // Current Branch Data
  const filteredBranchCount =
    planBenefits &&
    planBenefits?.filter((item: any) => item.benefitCode === "BRANCH").length;

  const filteredBranch =
    planBenefits &&
    planBenefits?.filter((item: any) => item.benefitCode === "BRANCH");

  const totalBranchPrice = filteredBranch
    ? filteredBranch?.reduce(
        (total: number, item: any) => total + item.benefitCost,
        0
      )
    : 0;

  const totalMonthlyBilling = (
    parseFloat(totalBranchPrice.toFixed(2)) +
    parseFloat(filteredExtraUsersPrice.toFixed(2))
  ).toFixed(2);

  const handleCancelation = () => {
    subscriptionCancel.id !== "" &&
      subscriptionCancel.status !== "" &&
      UpdateInstituteSubscriptionStatus(subscriptionCancel);

    setOpenCancelModal(false);
  };

  useEffect(() => {
    if (
      instituteBillingData &&
      instituteBillingData[0]?.instituteSubscriptionDetails
    ) {
      if (
        instituteBillingData[0]?.instituteSubscriptionDetails?.status ===
        "ACTIVE"
      ) {
        setSubscriptionCancel({
          ...subscriptionCancel,
          id: instituteBillingData[0]?.instituteSubscriptionDetails.id,
        });
      } else if (
        instituteBillingData[0]?.instituteSubscriptionDetails?.status ===
        "CANCELLED"
      ) {
        setSubscriptionCancel({
          ...subscriptionCancel,
          id: instituteBillingData[0]?.instituteSubscriptionDetails.id,
          status: "ACTIVE",
        });
      }
    }
  }, [instituteBillingData, setSubscriptionCancel]);

  const instituteFromRedux = useSelector(
    (state: any) => state.institute.instituteDetails
  );
  const upcomingMonthBillDate = instituteFromRedux?.upcomingBillingPaidEndDate
    ? instituteFromRedux?.upcomingBillingPaidEndDate
    : 0;
  const formattedUpcomingMonthBillDate =
    upcomingMonthBillDate &&
    moment(upcomingMonthBillDate)?.format("DD MMM, YYYY");

  const billingAutoDebit = instituteFromRedux?.autoDebit;

  return (
    <div className={open ? styles.blurredBackground : undefined}>
      {billingDataLoading || instituteBillingData === null ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="160px"
          style={{
            marginBottom: "32px",
            borderRadius: "3px",
          }}
        />
      ) : (
        <>
          <div className={styles.subscriptionPlanBox}>
            <div className={styles.subscriptionPlanStatusWrap}>
              <div className={styles.subscriptionPlanStatusRadio}>
                <div className={styles.subscriptionPlanStatusRadioIcon}>
                  {billingStatus && billingStatus === "ACTIVE" ? (
                    <img src={greenRadio} alt="" />
                  ) : billingStatus && billingStatus === "GRACE_PERIOD" ? (
                    <img src={orangeRadio} alt="" />
                  ) : billingStatus && billingStatus === "ARCHIVED" ? (
                    <img src={accountArchived} alt="" />
                  ) : billingStatus && billingStatus === "LOCKED" ? (
                    <img src={accountLocked} alt="" />
                  ) : billingStatus && billingStatus === "UNARCHIVED" ? (
                    <img src={unarchivedIcon} alt="" />
                  ) : (
                    <img src={greenRadio} alt="" />
                  )}
                </div>
                <div
                  className={styles.subscriptionPlanStatusRadioLabel}
                  style={{
                    color:
                      billingStatus && billingStatus === "ACTIVE"
                        ? "#11C89B"
                        : billingStatus && billingStatus === "GRACE_PERIOD"
                        ? "#FAAB10"
                        : billingStatus === "LOCKED"
                        ? "#EC3939"
                        : billingStatus === "ARCHIVED"
                        ? "#EC3939"
                        : billingStatus === "UNARCHIVED"
                        ? "#11C89B"
                        : "#11C89B",
                  }}
                >
                  {billingStatus && billingStatus === "ACTIVE"
                    ? "Active"
                    : billingStatus && billingStatus === "GRACE_PERIOD"
                    ? "Grace Period"
                    : billingStatus && billingStatus === "LOCKED"
                    ? "Account Locked"
                    : billingStatus && billingStatus === "ARCHIVED"
                    ? "Account Archived"
                    : billingStatus && billingStatus === "UNARCHIVED"
                    ? "Account Unarchived"
                    : ""}
                </div>
                {trialPlan && billingStatus === "ACTIVE" && (
                  <div className={styles.subscriptionPlanStatusRadioSubLabel}>
                    (Trial Plan)
                  </div>
                )}
                {subscriptionStatus && subscriptionStatus === "CANCELLED" && (
                  <div style={{ color: "#EC3939", fontSize: "18px" }}>
                    {" "}
                    {trialPlan
                      ? "(Trial Cancelled)"
                      : "(Subscription Cancelled)"}
                  </div>
                )}
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {billingCycle && (
                  <div className={styles.subscriptionPlanStatusCycle}>
                    Billing cycle :
                    <span className={styles.subscriptionPlanStatusCycleValue}>
                      {" " + billingCycle}
                    </span>
                  </div>
                )}
                {billingAutoDebit === "ENABLED" && (
                  <div className={styles.subscriptionAutoDebit}>Auto Debit</div>
                )}
              </div>
            </div>

            <div className={styles.subscriptionPlanExpiryWrap}>
              <div>
                <div
                  className={styles.subscriptionPlanExpiryDate}
                  style={{
                    color:
                      billingStatus === "ACTIVE" &&
                      subscriptionStatus === "ACTIVE"
                        ? "#142C44"
                        : (billingStatus === "GRACE_PERIOD" ||
                            billingStatus === "LOCKED" ||
                            billingStatus === "INACTIVE") &&
                          subscriptionStatus === "ACTIVE"
                        ? "#EC3939"
                        : billingStatus === "ACTIVE" &&
                          subscriptionStatus === "CANCELLED"
                        ? "#EC3939"
                        : billingStatus === "ARCHIVED" &&
                          (subscriptionStatus === "CANCELLED" ||
                            subscriptionStatus === "ACTIVE")
                        ? "#EC3939"
                        : billingStatus === "UNARCHIVED" &&
                          subscriptionStatus === "ACTIVE"
                        ? "#11C89B"
                        : (billingStatus === "GRACE_PERIOD" ||
                            billingStatus === "LOCKED" ||
                            billingStatus === "UNARCHIVED" ||
                            billingStatus === "INACTIVE") &&
                          subscriptionStatus === "CANCELLED"
                        ? "#EC3939"
                        : "inherit",
                  }}
                >
                  {billingStatus === "ACTIVE" && trialPlan
                    ? "Expires on :"
                    : billingStatus === "ACTIVE" &&
                      !trialPlan &&
                      subscriptionStatus === "CANCELLED"
                    ? "Expires on :"
                    : billingStatus === "ACTIVE" && !trialPlan
                    ? "Renews on :"
                    : billingStatus === "GRACE_PERIOD" && !trialPlan
                    ? `Hurry up! Only ${
                        remainingDaysInGracePeriod && remainingDaysInGracePeriod
                      } days left`
                    : billingStatus === "LOCKED" && (!trialPlan || trialPlan)
                    ? "Oops! Your account is locked!"
                    : billingStatus === "ARCHIVED" && !trialPlan
                    ? "Oops! Your account is archived!"
                    : billingStatus === "ARCHIVED" && trialPlan
                    ? "Oops! Your account is archived!"
                    : billingStatus === "UNARCHIVED" && !trialPlan
                    ? "Hurray! Your Account is Unarchived."
                    : ""}{" "}
                  {billingStatus == "ACTIVE" ? (
                    <>
                      {upcomingMonthBillDate !== 0
                        ? formattedUpcomingMonthBillDate
                        : formattedEndDate && formattedEndDate}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <p className={styles.subscriptionPlanExpiryDesc}>
                  {billingStatus === "ACTIVE" && trialPlan
                    ? `Your "Trial Plan" of nurture will expire soon. Upgrade to "Starter Plan" to continue using nurture.`
                    : billingStatus === "ACTIVE" && !trialPlan
                    ? "Don't forget to renew your plan before it expires to avoid any service disruptions."
                    : billingStatus === "ACTIVE" &&
                      subscriptionStatus === "CANCELLED" &&
                      !trialPlan
                    ? `You have 8 days remaining in the current plan. Upgrade to "Starter Plan" to continue using nurture.`
                    : billingStatus === "GRACE_PERIOD" && !trialPlan
                    ? `There are only ${
                        remainingDaysInGracePeriod && remainingDaysInGracePeriod
                      } days remaining in the grace period, so please make the necessary payment before it expires.`
                    : billingStatus === "LOCKED" && (!trialPlan || trialPlan)
                    ? `Please make the payment to activate it.`
                    : billingStatus === "ARCHIVED" && (!trialPlan || trialPlan)
                    ? `Please make the payment to activate it.`
                    : billingStatus === "UNARCHIVED" && !trialPlan
                    ? `Your account will be activated soon.`
                    : ""}
                </p>
              </div>
              <div>
                {((!trialPlan &&
                  (billingStatus === "LOCKED" ||
                    billingStatus === "ARCHIVED" ||
                    billingStatus === "GRACE_PERIOD" ||
                    billingStatus === "INACTIVE")) ||
                  (trialPlan && billingStatus === "ACTIVE") ||
                  (trialPlan && billingStatus === "ARCHIVED") ||
                  (trialPlan && billingStatus === "LOCKED")) && (
                  <Link to={`/app/manage/Billing/ManageSubscription`}>
                    <Button
                      sx={{
                        textTransform: "capitalize",
                      }}
                      color="primary"
                      variant="contained"
                    >
                      pay now
                    </Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {billingDataLoading || instituteBillingData === null ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="36px"
          style={{
            marginBottom: "9px",
            borderRadius: "3px",
          }}
        />
      ) : (
        <>
          <div className={styles.subscriptionDetailsHead}>
            <div className={styles.subscriptionDetailsHeadTitle}>
              Subscription Details
            </div>
            <div>
              {billingStatus !== "ARCHIVED" && (
                <Tooltip
                  arrow
                  title={
                    hasBillingAccess === false ? "Oops! No permission." : ""
                  }
                  PopperProps={{ style: { zIndex: 9999999 } }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#101828",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "8px 12px",
                        "& .MuiTooltip-arrow": {
                          color: "#101828",
                        },
                      },
                    },
                  }}
                >
                  <span>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleCancelOpen}
                      sx={{
                        width: "36px",
                        height: "36px",
                        border: "1px solid #D0D5DD",
                        borderRadius: "4PX",
                        background: "#fff",
                      }}
                      disabled={!hasBillingAccess}
                    >
                      <img src={dots} alt="" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              <Popover
                anchorEl={anchorEl}
                open={open}
                onClose={handleCancelClose}
                PaperProps={{
                  style: {
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.06)",
                    borderRadius: "4PX",
                    border: "1px solid #E4E4E7",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: -5,
                  horizontal: "right",
                }}
              >
                {subscriptionStatus === "ACTIVE" && (
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#EC3939",
                    }}
                    onClick={() => {
                      handleCancelClose();
                      handleCancelModalOpen();
                    }}
                  >
                    Deactivate
                  </MenuItem>
                )}
                {subscriptionStatus === "CANCELLED" && (
                  <MenuItem
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "21px",
                      color: "#667085",
                    }}
                    onClick={() => {
                      handleCancelClose();
                      handleCancelModalOpen();
                    }}
                  >
                    Reactivate
                  </MenuItem>
                )}
              </Popover>
              <CancelSubscriptionPopup
                open={openCancelModal}
                onClose={handleCancelModalClose}
                noButtonClick={handleCancelModalClose}
                yesButtonClick={handleCancelation}
                subscriptionStatus={subscriptionStatus}
              />
            </div>
          </div>
        </>
      )}

      {billingDataLoading || instituteBillingData === null ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          height="160px"
          style={{
            marginBottom: "32px",
            borderRadius: "3px",
          }}
        />
      ) : (
        <>
          <div className={styles.subscriptionDetailsBox}>
            <div className={styles.subscriptionDetailsBoxHead}>
              <div className={styles.subscriptionDetailsBoxHeadLeft}>
                <div className={styles.subscriptionDetailsBoxHeadLogo}>
                  <img src={logo} alt="" />
                </div>
                <div className={styles.subscriptionDetailsBoxHeadDetail}>
                  <div className={styles.subscriptionDetailsBoxHeadName}>
                    nurture CRM
                  </div>
                  <div className={styles.subscriptionDetailsBoxHeadLink}>
                    {authUser?.institute[0]?.instituteDetails?.appURL}
                  </div>
                </div>
              </div>
              <div className={styles.subscriptionDetailsBoxHeadRight}>
                <Link to={`/app/manage/Billing/ManageSubscription`}>
                  <Button
                    sx={{
                      color: "#9A9A9A",
                      fontSize: "14px",
                      padding: "6px 16px",
                      border: "1px solid #D5D5D5",
                      borderRadius: "4px",
                      textTransform: "capitalize",
                      fontWeight: "400",
                    }}
                    variant="outlined"
                  >
                    Manage Subscription
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.subscriptionDetailsBoxHeadDetails}>
              <div className={styles.subscriptionDetailsBoxHeadDetailsLeft}>
                <div
                  className={styles.subscriptionDetailsBoxHeadDetailsLeftItem}
                >
                  <div className={styles.subscriptionHeadDetailsLeftItemLabel}>
                    Branches
                  </div>
                  <div className={styles.subscriptionHeadDetailsLeftItemCount}>
                    {filteredBranchCount && filteredBranchCount}
                  </div>
                </div>
                <div
                  className={styles.subscriptionDetailsBoxHeadDetailsLeftItem}
                >
                  <div className={styles.subscriptionHeadDetailsLeftItemLabel}>
                    Users
                  </div>
                  <div className={styles.subscriptionHeadDetailsLeftItemCount}>
                    {filteredUsersCount &&
                      filteredUsersCount + filteredExtraUsersCount}
                    {}
                  </div>
                </div>
              </div>
              <div className={styles.subscriptionDetailsBoxHeadDetailsRight}>
                <div className={styles.subscriptionBoxHeadDetailsRightItem}>
                  <div className={styles.subscriptionHeadDetailsRightItemLabel}>
                    Billing
                  </div>
                  <div className={styles.subscriptionHeadDetailsRightItemValue}>
                    ₹ {totalMonthlyBilling && totalMonthlyBilling}/Monthly
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <CustomPopupBilling userType={"admin"} type={"locked"} />
    </div>
  );
}

export default Subscription;
