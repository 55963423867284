import styles from "../SkeletonStyles.module.css";
import { Skeleton } from "@mui/material";

const CourseImageSkeleton = () => {
  return (
    <div className={styles.courseDetailsSectionContainer}>
      <Skeleton animation="wave" variant="text" width="100%" height={"200px"} />
    </div>
  );
};

export default CourseImageSkeleton;
