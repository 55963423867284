import React, { useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import styles from "./TaxInvoices.module.css";
import { FiEdit } from "react-icons/fi";
import EditGst from "./EditGst";
import EditInvoiceNumber from "./EditInvoiceNumber";

function InvoiceDetails(props: any) {
  const {
    UpdateStudentInvoiceSettings,
    invoiceSettingUpdateSuccess,
    invoiceSettings,
    blurrEffect,
    hasTaxInvoiceAccess,
  } = props;
  const [isEditingGst, setIsEditingGst] = useState<boolean>(false);
  const [isEditingInvoiceNumber, setIsEditingInvoiceNumber] =
    useState<boolean>(false);
  const handleCancelEdit = () => {
    setIsEditingGst(false);
    setIsEditingInvoiceNumber(false);
  };

  React.useEffect(() => {
    if (
      invoiceSettingUpdateSuccess !== true &&
      invoiceSettingUpdateSuccess !== false
    ) {
      setIsEditingGst(false);
      setIsEditingInvoiceNumber(false);
    }
  }, [invoiceSettingUpdateSuccess]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: " 100%",
          background: "rgb(241 241 241 / 78%)",
          display: blurrEffect ? "block" : "none",
        }}
      ></div>
      {!isEditingGst && !isEditingInvoiceNumber && (
        <Box
          sx={{
            padding: " 24px 20px 20px 20px",
          }}
          className={styles.invoiceDetailsWrapper}
        >
          <div className={styles.invoiceDetailsMainHead}>Invoice Details</div>
          <div
            style={{
              borderBottom: "1px solid #E2E2E2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              paddingBottom: "12px",
            }}
          >
            <div>
              <div className={styles.invoiceDetailsSubHead}>GSTIN</div>
              <div className={styles.value}>{invoiceSettings?.gstNumber}</div>
            </div>
            <Tooltip
              arrow
              title={
                hasTaxInvoiceAccess === false ? "Oops! No permission." : ""
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#101828",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "8px 12px",
                    zIndex: "9999999",
                    "& .MuiTooltip-arrow": {
                      color: "#101828",
                      zIndex: "9999999",
                    },
                  },
                },
              }}
            >
              <span>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant="contained"
                  disabled={hasTaxInvoiceAccess === false}
                  onClick={() => setIsEditingGst(!isEditingGst)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#667085",
                    background: "transparent",
                  }}
                >
                  <div style={{ marginTop: "2px" }}>Edit</div>
                  <FiEdit size={14} color="#667085" />
                </Button>
              </span>
            </Tooltip>
          </div>

          <Box
            sx={{
              display: "flex",
              paddingTop: "26px",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div style={{ display: "flex", gap: "35px" }}>
              <div>
                <div className={styles.invoiceDetailsSubHead}>Prefix</div>
                <div className={styles.value}>{invoiceSettings?.prefix}</div>
              </div>

              <div>
                <div className={styles.invoiceDetailsSubHead}>Next Number</div>
                <div className={styles.value}>
                  {invoiceSettings?.nextNumber}
                </div>
              </div>
            </div>
            <Tooltip
              arrow
              title={
                hasTaxInvoiceAccess === false ? "Oops! No permission." : ""
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#101828",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "8px 12px",
                    zIndex: "9999999",
                    "& .MuiTooltip-arrow": {
                      color: "#101828",
                      zIndex: "9999999",
                    },
                  },
                },
              }}
            >
              <span>
                <Button
                  sx={{ textTransform: "capitalize" }}
                  variant="contained"
                  disabled={hasTaxInvoiceAccess === false}
                  onClick={() =>
                    setIsEditingInvoiceNumber(!isEditingInvoiceNumber)
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#667085",
                    background: "transparent",
                  }}
                >
                  <div style={{ marginTop: "2px" }}>Edit</div>
                  <FiEdit size={14} color="#667085" />
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
      )}
      {isEditingGst && (
        <EditGst
          onCancelEdit={handleCancelEdit}
          UpdateStudentInvoiceSettings={UpdateStudentInvoiceSettings}
        />
      )}
      {isEditingInvoiceNumber && (
        <EditInvoiceNumber
          onCancelEdit={handleCancelEdit}
          UpdateStudentInvoiceSettings={UpdateStudentInvoiceSettings}
        />
      )}
    </Box>
  );
}

export default InvoiceDetails;
