import { useRef } from "react";
import DropZoneCom from "../../../../../../components/DragCrop/DropZoneCom";
import styles from "../../components/Manegecourses.module.css";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

const CourseImage = ({
  courseDetails,
  handleImageUpload,
  image,
  setImage,
  courseById,
  hasCourseAccess,
}: any) => {
  const inputRef = useRef<any>(null);
  const fileUploadLoading = useSelector(
    (state: any) =>
      state.fileUpload.fileUploadLoading && state.fileUpload.fileUploadLoading
  );

  return (
    <>
      <div
        className={styles.courseDetailsSectionContainer}
        style={{
          border: courseDetails.courseImage ? "" : "2px dotted #d0d5dd",
          height: courseDetails.courseImage ? "" : "90%",
          alignItems: courseDetails.courseImage ? "" : "center",
          pointerEvents: hasCourseAccess ? "auto" : "none",
          cursor: hasCourseAccess ? "auto" : "default",
        }}
      >
        {fileUploadLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              width: "100%",
              marginTop: "30px",
            }}
          >
            <CircularProgress size={24} />
          </div>
        ) : (
          <DropZoneCom
            image={
              <img
                src={courseById?.courseImage ? courseById?.courseImage : image}
                alt="Course Image"
                style={{
                  width: courseById?.courseImage ? "300px" : "50%",
                  height: courseById?.courseImage ? "180px" : "50%",
                  cursor: "pointer",
                  borderRadius: "2PX",
                  backgroundSize: courseById?.courseImage ? "cover" : "contain",
                }}
              />
            }
            setImage={setImage}
            handleImageUpload={handleImageUpload}
            editImage={courseById?.courseImage}
            inputRef={inputRef}
            hasCourseAccess={hasCourseAccess}
          />
        )}
        {courseDetails?.courseImage ? (
          ""
        ) : (
          <>
            <div className={styles.dragDropText}>
              Drag and drop an image, or{" "}
              <span
                style={{ color: "#1A73E8" }}
                onClick={() => {
                  hasCourseAccess && inputRef.current?.click();
                }}
              >
                Browse
              </span>
            </div>
            <div className={styles.dragDropRequirement}>
              JPG or PNG (Max size: 2MB)
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CourseImage;
